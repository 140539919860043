import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import RentalItemPopup from "../Popups/RentalItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ProductionPlanDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ProductionPlanTable,
  setProductionPlanTable,
  CurrencyDropDown,
  onchangegldate,
  rc_TR_TY,
  rc_TR_CO,
  rc_BS_CR,
  rc_EX_RT,
  setrc_EX_RT,
  rc_CO_CT,
  setrc_CO_CT,
  rc_PY_TR,
  setrc_PY_TR,
  rc_BL_FR,
  setrc_BL_FR,
  rc_TX_AP,
  setrc_TX_AP,
  setrc_LO_CN,
  setrc_TR_UM,
  setselectedItem,
  setselectedDesc,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOC,
  setSelectedemEMNOC,
  SelectedemEMNOD,
  setSelectedemEMNOD,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  selectedAbAdNM,
  setSelectedAbAdNM,
  rc_SH_TO,
  setrc_SH_TO,
  rc_HD_ST,
  setrc_HD_ST,
  rc_ST_CD,
  rc_LO_CN,
  rc_ZN_CD,
  setrc_ZN_CD,
  rc_MD_TR,
  setrc_MD_TR,
  rc_RT_CD,
  setrc_RT_CD,
  handleConfirm,
  price_AMOUNT,
  discount_AMOUNT,
  net_AMOUNT,
  tax_AMOUNT,
  total,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  fr_TOTAL,
  setselectedSeNO,
  setselectedLoc,
  CostCentreDropDown,
  StatusDropdown,
  setrc_DV_RT,
  rc_DV_RT,
}) {
  const {
    sd_UK_NO,
    sd_IT_NM,
    sd_ST_TY,
    sd_PD_GP,
    sd_TR_UM,
    rc_CU_NO,
    rc_TR_NO,
    rc_RL_NO,
    rc_TR_DT,
    rc_RQ_DT,
    rc_ST_DT,
    rc_EN_DT,
    rc_DS_PE,
    rc_DS_AM,
    rc_RF_NC,
    rc_SL_PR,
    rc_OR_QT,
    rc_CR_NO,
    rc_IN_TR,
    rc_TR_CR,
    rc_RL_CO,
    rc_RL_TY,
    rc_IT_NM,
    rc_UN_PR,
    sd_OR_QT,
  } = data;

  const { footergridstyle, footerfieldstyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [rc_PY_TROptions, setrc_PY_TROptions] = useState([]);
  const [rc_BL_FROptions, setrc_BL_FROptions] = useState([]);
  const [rc_CO_CTOptions, setrc_CO_CTOptions] = useState([]);
  const [rc_TR_CROptions, setrc_TR_CROptions] = useState([]);
  const [rc_HD_STOptions, setrc_HD_STOptions] = useState([]);
  const [bd_DO_TYOptions, setbd_DO_TYOptions] = useState([]);
  const [rc_LO_CNOptions, setrc_LO_CNOptions] = useState([]);
  const [rc_TR_UMOptions, setrc_TR_UMOptions] = useState([]);
  const [rc_TX_APOptions, setrc_TX_APOptions] = useState([]);

  const [rc_ZN_CDOptions, setrc_ZN_CDOptions] = useState([]);
  const [rc_MD_TROptions, setrc_MD_TROptions] = useState([]);
  const [rc_RT_CDOptions, setrc_RT_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");



  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_PY_TR(value);
  };


  const BillingFrequencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_BL_FR(value);
  };

  const FrequencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_BL_FR(value);
  };

  const ApplicableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TX_AP(value);
  };

  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TR_UM(value);
  };



  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_LO_CN(value);
  };


  const TaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TX_AP(value);
  };

  const ZoneDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_ZN_CD(value);
  };

  const TransportDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_MD_TR(value);
  };


  const RouteDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_RT_CD(value);
  };


  useEffect(() => {
    CostCentre();
    Currency();
    PaymentTerm();
    HoldCode();
    Frequency();
    Applicable();
    UM();
    Status();
    Type();
    Tax();
    Zone();
    Transport();
    Route();

  }, []);


  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setrc_CO_CTOptions(Options);
  };

  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setrc_TR_CROptions(Options);
  };

  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    setrc_PY_TROptions(Options);
  };

  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setrc_BL_FROptions(Options);
  };

  const Frequency = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setrc_BL_FROptions(Options);
  };

  const Applicable = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setrc_TX_APOptions(Options);
  };

  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrc_TR_UMOptions(Options);
  };


  const Status = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setrc_HD_STOptions(Options);
  };

  const Type = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setrc_LO_CNOptions(Options);
  };

  const Tax = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setrc_TX_APOptions(Options);
  };

  const Zone = async () => {
    const Options = await fetchClassCodeDS("ZO/NO");
    setrc_ZN_CDOptions(Options);
  };

  const Transport = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setrc_MD_TROptions(Options);
  };

  const Route = async () => {
    const Options = await fetchClassCodeDS("FO/RC");
    setrc_RT_CDOptions(Options);
  };


  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const addRow = () => {
    setProductionPlanTable([...ProductionPlanTable, {}]);


  };
  const deleteRow = () => {
    if (ProductionPlanTable.length > 0) {
      const updatedData = [...ProductionPlanTable];
      updatedData.pop();
      setProductionPlanTable(updatedData);
    }
  };





  const isUpdateMode = Boolean(sd_UK_NO);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );

  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sd_UK_NO ? "Revise Production Plan" : "Add Production Plan"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >

      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          overflow: "auto",
        }}
      >
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
            <label htmlFor="sd_UK_NO" className="font-bold">
              UK NO
            </label>
            <InputText disabled
              id="sd_UK_NO"
              name="sd_UK_NO"
              defaultValue={sd_UK_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_TR_NO" className="font-bold">
              Item Name
            </label>
            <InputText disabled
              id="rc_TR_NO"
              name="rc_TR_NO"
              value={rc_TR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_TR_TY" className="font-bold">
              Product Group
            </label>
            <InputText disabled
              id="rc_TR_TY"
              name="rc_TR_TY"
              value={rc_TR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_TR_CO" className="font-bold">
              Stocking Type
            </label>
            <InputText disabled
              id="rc_TR_CO"
              name="rc_TR_CO"
              value={rc_TR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_RL_TY" className="font-bold">
              UM
            </label>
            <InputText disabled
              id="rc_RL_TY"
              name="rc_RL_TY"
              value={rc_RL_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>

        </div>
      </div>
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
      >

        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{ display: "flex", overflow: "auto" }}>
          <table className="custom-table" id="ProductionPlanTable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>So No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Line No</th>
                <th>Order Date</th>
                <th>Requested Date</th>
                <th>Order Quantity</th>
                <th>UM</th>
                <th>Scheduled</th>
                <th>Cost Center</th>
                <th>Work Center</th>
                <th>To Produce</th>
                <th>Scheduled Date</th>
                <th>Scheduled Time</th>
                <th>Promised Date</th>
                <th>Customer</th>
              </tr>
            </thead>
            <tbody>
              {ProductionPlanTable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`sd_UK_NO_${index}`}
                      name={`sd_UK_NO_${index}`}
                      defaultValue={row.sd_UK_NO}
                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_LN_NO_${index}`}
                      name={`rc_LN_NO_${index}`}
                      value={index + 1}
                      readOnly
                    />
                  </td>

                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_SR_NO_${index}`}
                      name={`rc_SR_NO_${index}`}
                      defaultValue={row.rc_SR_NO ? row.rc_SR_NO : row[`${"rc_SR_NO"}_${index}`]}
                      onChange={(e) => onchangedata(e)}

                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`itemDES_${index}`}
                      name={`itemDES_${index}`}
                      defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`rc_LO_CN_${index}`}
                      name={`rc_LO_CN_${index}`}
                      value={data[`rc_LO_CN_${index}`] ? data[`rc_LO_CN_${index}`] : row.rc_LO_CN}
                      options={rc_LO_CNOptions}
                      onChange={(e) => LocationDropdown(`rc_LO_CN_${index}`, e.value)}
                      placeholder="Select Location"
                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_SR_NO_${index}`}
                      name={`rc_SR_NO_${index}`}
                      defaultValue={row.rc_SR_NO ? row.rc_SR_NO : row[`${"rc_SR_NO"}_${index}`]}
                      onChange={(e) => onchangedata(e)}

                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`rc_OR_QT_${index}`}
                      name={`rc_OR_QT_${index}`}
                      defaultValue={row.rc_OR_QT}
                      onChange={(e) => onchangedata(e, index)}

                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`rc_TR_UM_${index}`}
                      name={`rc_TR_UM_${index}`}
                      value={data[`rc_TR_UM_${index}`] ? data[`rc_TR_UM_${index}`] : row.rc_TR_UM}
                      options={rc_TR_UMOptions}
                      onChange={(e) => UMDropdown(`rc_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      disabled={data.rc_TR_CR == rc_BS_CR ? false : true}
                      id={`rc_UN_PR_${index}`}
                      name={`rc_UN_PR_${index}`}
                      defaultValue={data.rc_TR_CR == rc_BS_CR ? row.rc_UN_PR : 0}
                      // onChange={(e) => onchangedata(e)}
                      onChange={(e) => {
                        onchangedata(e, index);

                      }}

                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_UP_AM_${index}`}
                      name={`rc_UP_AM_${index}`}
                      defaultValue={data.rc_TR_CR == rc_BS_CR ? row.rc_UP_AM : 0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`rc_UN_CT_${index}`}
                      name={`rc_UN_CT_${index}`}
                      defaultValue={row.rc_UN_CT}
                      onChange={(e) => {
                        onchangedata(e, index);

                      }}

                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_UC_AM_${index}`}
                      name={`rc_UC_AM_${index}`}
                      defaultValue={data.rc_TR_CR == rc_BS_CR ? row.rc_UC_AM : 0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`rc_DS_PE_${index}`}
                      name={`rc_DS_PE_${index}`}
                      defaultValue={row.rc_DS_PE}
                      onChange={(e) => onchangedata(e, index)}

                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_DS_AM_${index}`}
                      name={`rc_DS_AM_${index}`}
                      defaultValue={data.rc_TR_CR == rc_BS_CR ? row.rc_DS_AM : 0}
                      // onChange={(e) => onchangedata(e)}
                      readOnly
                    />
                  </td>

                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_NT_MT_${index}`}
                      name={`rc_NT_MT_${index}`}
                      defaultValue={row.rc_NT_MT}
                      readOnly
                    />
                  </td>

                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_NT_MT_${index}`}
                      name={`rc_NT_MT_${index}`}
                      defaultValue={row.rc_NT_MT}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText disabled style={{ width: "90px" }}
                      id={`rc_NT_MT_${index}`}
                      name={`rc_NT_MT_${index}`}
                      defaultValue={row.rc_NT_MT}
                      readOnly
                    />
                  </td>




                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Open SO
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              defaultValue={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Open WO
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              defaultValue={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Available Stock
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              defaultValue={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Planned Qty
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              defaultValue={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="total" className="font-bold">
              To Produce
            </label>
            <InputText disabled
              id="total"
              name="total"
              defaultValue={total}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Instruction
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                defaultValue={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
