import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ClassCodePopup from "views/admin/Popups/ClassCodePopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function DocumentNumberDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  dn_DO_CO,
  FormData,
  setFormData,
  bn_DO_CO,
  dn_AU_RE,
  setdn_AU_RE,
  dn_CT_RY,
  setdn_CT_RY,
  dn_FY_YR,
  setdn_FY_YR,
  SelectedType,
  setselectedType,
  SelectedDesc,
  setselectedDesc,
}) {
  const {
    dnUkNo,
    dn_DS_PN,
    dn_DO_TY,
    dn_DO_NO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [dn_AU_REOptions, setdn_AU_REOptions] = useState([]);
  const [dn_CT_RYOptions, setdn_CT_RYOptions] = useState([]);
  const [dn_FY_YROptions, setdn_FY_YROptions] = useState([]);
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdn_CT_RY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdn_FY_YR(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdn_AU_RE(value);
  };
  useEffect(() => {
    fetchDropdownLN();
    fetchDropdownCN();
    fetchDropdownLYR();
  }, []);

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeCD("CM/TY");
    setdn_CT_RYOptions(Options);
  };
  const fetchDropdownCN = async () => {
    const Options = await fetchClassCodeCD("YE/AR");
    setdn_FY_YROptions(Options);
  };
  const fetchDropdownLYR= async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setdn_AU_REOptions(Options);
  };
  const [TypeVisible, setTypeVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleType =async (data) => {
    setselectedType(data.cl_CL_CD)
    setselectedDesc(data.cl_CL_DS)
    onchangeFormData("dn_DO_TY", data.cl_CL_CD);
    onchangeFormData("dn_DS_PN", data.cl_CL_DS);
    setTypeVisible(false);
    setShowSearchIcon(false);
  };
  const openTypePopup = (e) => {
    setTypeVisible(true);
    setSelectedRowIndex(e);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{dnUkNo ? "Revise Document Number" : "Add Document Number"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
            <div className="formgrid grid">
                <div style={{display:"none"}}>
                <label htmlFor="dnUkNo" className="font-bold">
                UK_NO
                </label>
                <InputText disabled
                  id="dnUkNo"
                  name="dnUkNo"
                  value={dnUkNo}
                />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_DO_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="dn_DO_CO"
                    name="dn_DO_CO"
                    value={dn_DO_CO}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_DO_TY" className="font-bold">
                  Document Type <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="dn_DO_TY"
                      name="dn_DO_TY"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedType}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openTypePopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_DS_PN" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="dn_DS_PN"
                    name="dn_DS_PN"
                    value={SelectedDesc}
                  />
                </div>
            </div>
            <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_CT_RY" className="font-bold">
                    Century
                  </label>
                  <Dropdown
                    id="dn_CT_RY"
                    name="dn_CT_RY"
                    value={dn_CT_RY}
                    options={dn_CT_RYOptions}
                    onChange={(e) => onchangeDropDownFormData1("dn_CT_RY", e.value)}
                    placeholder="Select Century"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_FY_YR" className="font-bold">
                    Fiscal Year
                  </label>
                  <Dropdown
                    id="dn_FY_YR"
                    name="dn_FY_YR"
                    value={dn_FY_YR}
                    options={dn_FY_YROptions}
                    onChange={(e) => onchangeDropDownFormData2("dn_FY_YR", e.value)}
                    placeholder="Select Fiscal Year"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_DO_NO" className="font-bold">
                  Next Number
                  </label>
                  <InputText
                    id="dn_DO_NO"
                    name="dn_DO_NO"
                    value={dn_DO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>  
              </div>
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dn_AU_RE" className="font-bold">
                  Auto Reset
                  </label>
                  <Dropdown
                    name="dn_AU_RE"
                    value={dn_AU_RE}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => onchangeDropDownFormData3("dn_AU_RE", e.value)}
                    placeholder="Select  Auto Reset"
                  />
                </div>
            </div>
        </div>
      </div> 
      <ClassCodePopup
        visible={TypeVisible}
        onClose={() => setTypeVisible(false)}
        onDataSelect={handleType}
        formData={data}
      />
    </Dialog>
  );
}
