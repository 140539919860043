import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
export default function LocationMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  lm_CO_CT,
  setlm_CO_CT,
  lm_PA_LN,
  setlm_PA_LN,
  lm_PT_ZN,
  setlm_PT_ZN,
  lm_PK_ZN,
  setlm_PK_ZN,
  lm_RP_ZN,
  setlm_RP_ZN,
  lm_CH_CS,
  setlm_CH_CS,
  lm_CL_01,
  setlm_CL_01,
  lm_CL_02,
  setlm_CL_02,
  lm_CL_03,
  setlm_CL_03,
  lm_CL_04,
  setlm_CL_04,
  lm_CL_05,
  setlm_CL_05,
  lm_CL_06,
  setlm_CL_06,
  lm_CL_07,
  setlm_CL_07,
  lm_CL_08,
  setlm_CL_08,
  lm_CL_09,
  setlm_CL_09,
  lm_CL_10,
  setlm_CL_10,
  eg_CO_NO,
}) {
  const {
    lmUkNo,
    lm_LO_CN,
    lm_AS_IL,
    lm_BI_IN,
    lm_MI_PU,
    lm_MI_PK,
  } = data;
  const {borderbox,TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  // dropdowns
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [lm_CO_CTOptions, setlm_CO_CTOptions] = useState([]);
  const [lm_PA_LNOptions, setlm_PA_LNOptions] = useState([]);
  const [lm_PT_ZNOptions, setlm_PT_ZNOptions] = useState([]);
  const [lm_PK_ZNOptions, setlm_PK_ZNOptions] = useState([]);
  const [lm_RP_ZNOptions, setlm_RP_ZNOptions] = useState([]);
  const [lm_CH_CSOptions, setlm_CH_CSOptions] = useState([]);
  const [lm_CL_01Options, setlm_CL_01Options] = useState([]);
  const [lm_CL_02Options, setlm_CL_02Options] = useState([]);
  const [lm_CL_03Options, setlm_CL_03Options] = useState([]);
  const [lm_CL_04Options, setlm_CL_04Options] = useState([]);
  const [lm_CL_05Options, setlm_CL_05Options] = useState([]);
  const [lm_CL_06Options, setlm_CL_06Options] = useState([]);
  const [lm_CL_07Options, setlm_CL_07Options] = useState([]);
  const [lm_CL_08Options, setlm_CL_08Options] = useState([]);
  const [lm_CL_09Options, setlm_CL_09Options] = useState([]);
  const [lm_CL_10Options, setlm_CL_10Options] = useState([]);
// CostCentreDropDown
  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CO_CT(value);
  };
// ParentDropDown
  const ParentDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_PA_LN(value);
  };
// PutAwayZoneDropDown
  const PutAwayZoneDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_PT_ZN(value);
  };
// PickingZoneDropDown
  const PickingZoneDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_PK_ZN(value);
  };
// ReplenishmentDropDown
  const ReplenishmentDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_RP_ZN(value);
  };
  // ReplenishmentDropDown
  const CharacteristicsDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CH_CS(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_01(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_02(value);
  };
   // ClassCodeDropDown
  const ClassCodeDropDown3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_03(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_04(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_05(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_06(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_07(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_08(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_09(value);
  };
  // ClassCodeDropDown
  const ClassCodeDropDown10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_CL_10(value);
  };
  useEffect(() => {
    CostCentre();
    ParentLocation();
    PutAwayZone();
    PickingZone();
    ReplenishmentZone();
    Characteristics();
    ClassCode1();
    ClassCode2();
    ClassCode3();
    ClassCode4();
    ClassCode5();
    ClassCode6();
    ClassCode7();
    ClassCode8();
    ClassCode9();
    ClassCode10();

  }, []);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setlm_CO_CTOptions(Options);
  };
  const ParentLocation = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    setlm_PA_LNOptions(Options);
  };
  const PutAwayZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setlm_PT_ZNOptions(Options);
  };
  const PickingZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setlm_PK_ZNOptions(Options);
  };
  const ReplenishmentZone = async () => {
    const Options = await fetchClassCodeDS("LC/ZN");
    setlm_RP_ZNOptions(Options);
  };
  const Characteristics = async () => {
    const Options = await fetchClassCodeDS("IN/W2");
    setlm_CH_CSOptions(Options);
  };
  const ClassCode1 = async () => {
    const Options = await fetchClassCodeDS("LO/01");
    setlm_CL_01Options(Options);
  };
  const ClassCode2 = async () => {
    const Options = await fetchClassCodeDS("LO/02");
    setlm_CL_02Options(Options);
  };
  const ClassCode3 = async () => {
    const Options = await fetchClassCodeDS("LO/03");
    setlm_CL_03Options(Options);
  };
  const ClassCode4 = async () => {
    const Options = await fetchClassCodeDS("LO/04");
    setlm_CL_04Options(Options);
  };
  const ClassCode5 = async () => {
    const Options = await fetchClassCodeDS("LO/05");
    setlm_CL_05Options(Options);
  };
  const ClassCode6 = async () => {
    const Options = await fetchClassCodeDS("LO/06");
    setlm_CL_06Options(Options);
  };
  const ClassCode7 = async () => {
    const Options = await fetchClassCodeDS("LO/07");
    setlm_CL_07Options(Options);
  };
  const ClassCode8 = async () => {
    const Options = await fetchClassCodeDS("LO/08");
    setlm_CL_08Options(Options);
  };
  const ClassCode9 = async () => {
    const Options = await fetchClassCodeDS("LO/09");
    setlm_CL_09Options(Options);
  };
  const ClassCode10 = async () => {
    const Options = await fetchClassCodeDS("LO/10");
    setlm_CL_10Options(Options);
  };
  const isUpdateMode = Boolean(lmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{lmUkNo ? "Revise Location Master" : "Add Location Master"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />)}
        {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />)}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Location">
            {/* tab1 general */}
            <div style={inboxstyle}>
                <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_LO_CN" className="font-bold">
                  Location
                  </label>
                  <InputText 
                    id="lm_LO_CN"
                    name="lm_LO_CN"
                    value={lm_LO_CN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_CO_CT" className="font-bold">
                     Cost Centre
                    </label>
                    <Dropdown
                    name="lm_CO_CT"
                    value={lm_CO_CT}
                    options={lm_CO_CTOptions}
                    onChange={(e) => CostCentreDropDown("lm_CO_CT", e.value)}
                    placeholder="Select  Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_PA_LN" className="font-bold">
                     Parent Location
                    </label>
                    <Dropdown
                    name="lm_PA_LN"
                    value={lm_PA_LN}
                    options={lm_PA_LNOptions}
                    onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                    placeholder="Select Parent Location"
                  />
                  </div>

                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_PT_ZN" className="font-bold">
                    Put Away Zone
                    </label>
                    <Dropdown
                    name="lm_PT_ZN"
                    value={lm_PT_ZN}
                    options={lm_PT_ZNOptions}
                    onChange={(e) => PutAwayZoneDropDown("lm_PT_ZN", e.value)}
                    placeholder="Select   Put Away Zone"
                  />
                  </div>
                  
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_PK_ZN" className="font-bold">
                    Picking Zone
                    </label>
                    <Dropdown
                    name="lm_PK_ZN"
                    value={lm_PK_ZN}
                    options={lm_PK_ZNOptions}
                    onChange={(e) => PickingZoneDropDown("lm_PK_ZN", e.value)}
                    placeholder="Select Picking Zone"
                  />
                  </div>

                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_RP_ZN" className="font-bold">
                    Replenishment Zone
                    </label>
                    <Dropdown
                    name="lm_RP_ZN"
                    value={lm_RP_ZN}
                    options={lm_RP_ZNOptions}
                    onChange={(e) => ReplenishmentDropDown("lm_RP_ZN", e.value)}
                    placeholder="Select  Replenishment Zone"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="lm_AS_IL" className="font-bold">
              Aisle
              </label>
              <InputText 
                id="lm_AS_IL"
                name="lm_AS_IL"
                value={lm_AS_IL}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="lm_BI_IN" className="font-bold">
              Bin
              </label>
              <InputText 
                id="lm_BI_IN"
                name="lm_BI_IN"
                value={lm_BI_IN}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_CH_CS" className="font-bold">
                    Characteristics
                    </label>
                    <Dropdown
                    name="lm_CH_CS"
                    value={lm_CH_CS}
                    options={lm_CH_CSOptions}
                    onChange={(e) => CharacteristicsDropDown("lm_CH_CS", e.value)}
                    placeholder="Select  Characteristics"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="lm_MI_PU" className="font-bold">
              Minimum Put %
              </label>
              <InputText 
                id="lm_MI_PU"
                name="lm_MI_PU"
                value={lm_MI_PU}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="lm_MI_PK" className="font-bold">
              Minimum Pick%
              </label>
              <InputText 
                id="lm_MI_PK"
                name="lm_MI_PK"
                value={lm_MI_PK}
                onChange={(e) => onchangedata(e)}
              />
          </div>
         
                </div>
            </div>
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Dimension">
             {/* 1st box */}
          <div class="row">
            <div class="col">
              <div style={inboxstyle}>
                {/* <label className="font-bold">Gross Dimension</label> */}
                <div className="formgrid grid">
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                </div>
              
              </div>
            </div>
            <div class="col">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                </div>
              </div>
            </div>
          </div>
        {/* 2nd box */}
          <div class="row">
            <div class="col">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                      
                </div>
              </div>
            </div>
            <div class="col">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                        <label htmlFor="lm_PA_LN" className="font-bold">
                        Parent Location
                        </label>
                        <Dropdown
                        name="lm_PA_LN"
                        value={lm_PA_LN}
                        options={lm_PA_LNOptions}
                        onChange={(e) => ParentDropDown("lm_PA_LN", e.value)}
                        placeholder="Select Parent Location"
                      />
                      </div>
                </div>
              </div>
            </div>
          </div>
          </TabPanel>
          <TabPanel header="Class Code">
            <div style={inboxstyle} >
             <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_01" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="lm_CL_01"
                    value={lm_CL_01}
                    options={lm_CL_01Options}
                    onChange={(e) =>ClassCodeDropDown1("lm_CL_01", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="lm_CL_02"
                    value={lm_CL_02}
                    options={lm_CL_02Options}
                    onChange={(e) =>ClassCodeDropDown2("lm_CL_02", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="lm_CL_03"
                    value={lm_CL_03}
                    options={lm_CL_03Options}
                    onChange={(e) => ClassCodeDropDown3("lm_CL_03", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="lm_CL_04"
                    value={lm_CL_04}
                    options={lm_CL_04Options}
                    onChange={(e) => ClassCodeDropDown4("lm_CL_04", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="lm_CL_05"
                    value={lm_CL_05}
                    options={lm_CL_05Options}
                    onChange={(e) => ClassCodeDropDown5("lm_CL_05", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_06" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="lm_CL_06"
                    value={lm_CL_06}
                    options={lm_CL_06Options}
                    onChange={(e) => ClassCodeDropDown6("lm_CL_06", e.value)}
                    placeholder="Select Class Code 06"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="lm_CL_07"
                    value={lm_CL_07}
                    options={lm_CL_07Options}
                    onChange={(e) => ClassCodeDropDown7("lm_CL_07", e.value)}
                    placeholder="Select Class Code 07"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="lm_CL_08"
                    value={lm_CL_08}
                    options={lm_CL_08Options}
                    onChange={(e) => ClassCodeDropDown8("lm_CL_08", e.value)}
                    placeholder="Select Class Code 08"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="lm_CL_09"
                    value={lm_CL_09}
                    options={lm_CL_09Options}
                    onChange={(e) => ClassCodeDropDown9("lm_CL_09", e.value)}
                    placeholder="Select Class Code 09"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="lm_CL_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="lm_CL_10"
                    value={lm_CL_10}
                    options={lm_CL_10Options}
                    onChange={(e) => ClassCodeDropDown10("lm_CL_10", e.value)}
                    placeholder="Select Class Code 10"
                  />
                </div>
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div> 
    </Dialog>
  );
}
