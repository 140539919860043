import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import Pagination from "views/common/Pagination";
import AddressDialog from "views/admin/AddressBook/AddressDialog";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
const initialFilters = {
  abAdNo: { value: "", operation: "" },
  ab_AD_NM: { value: "", operation: "" },
};
const AddressBookPopup = ({ 
  visible,
  onClose,
  onDataSelect,
  onchangedata,
  addressType,
  addStatus,
  elmId
}) => {
  const {PopupStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'abAdNo') {
          constructedColumn = 'abAdNo';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClose = () => {
      onClose();
      setOpen(false);
      handleSearch();
  };
  // useEffect(() => {
  //   setOpen(visible);
  //   handleSearch();
  //   setCurrentPage(0);
  // }, [currentPage, pageSize, filters, visible]);


  useEffect(() => {
    if (visible) {
      setOpen(true);
      setCurrentPage(0);
    } else {
      setOpen(false); 
    }
  }, [visible]);
  
  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open, currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();
    const requestBody = {
      searchRequestDto,
    };
    const Url = `${domain}/api/AddressBook/All/${currentPage}/${pageSize}`;
    const parameter = addressType;
    const paginationUrl = `${Url}/${parameter}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
// for addressbook dialog
  const initialValue = {
    abAdNo: "",
    ab_CL_12: "",
    ab_AD_CT: "",
    ab_AD_CY: "",
    ab_AD_P1: "",
    ab_AD_NM: "",
    ab_AD_EM: "",
    ab_CL_11: "",
    ab_AC_IN: "",
  };
  const [FormData, setFormData] = useState(initialValue);
  // const [abAdNo, setab_AD_NO] = useState("");
  const [abAdNo, setAbAdNo] = useState("");

  const [ab_AD_CT, setab_AD_CT] = useState("");
  const [ab_AD_CY, setab_AD_CY] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [ab_CL_07, setab_CL_07] = useState("");
  const [ab_AD_TY, setab_AD_TY] = useState("");
  const [ab_AC_IN, setab_AC_IN] = useState("");
  const [ab_AD_NM, setab_AD_NM] = useState("");
  const [nextNumType, setnextNumType] = useState("E");
  const [showaddressdialog, setshowaddressdialog] = useState(false);
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AD_TY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AC_IN(value);
  };
  const opendialog = () => {
    setshowaddressdialog(true);
  };
  // addresbookdialog
  return (
    <Dialog id="dialog-popup-style"
      visible={open}
      onHide={handleClose}
      style={PopupStyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="AddressBook Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <Button style={{ width: "6.5%",border: "none",  backgroundColor: "#a0c1cdcf",}}
            icon="pi pi-plus"
            className="p-button-secondary"
            onClick={opendialog}
          />

          <table style={{borderCollapse: "collapse", width: "100%",  marginTop: "20px", }} >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["abAdNo"] ? filters["abAdNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "abAdNo",
                        e.target.value,
                        filters["abAdNo"]
                          ? filters["abAdNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"]
                          ? filters["ab_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Name</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.cc_UK_NO}
                      onClick={() => onDataSelect(rowData,elmId)}
                      style={{ backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff", }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.abAdNo}</td>
                      <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <AddressDialog
        open={showaddressdialog}
        handleClose={() => setshowaddressdialog(false)}
        data={FormData}
        onchangedata={onchangedata}
        handleSearch={handleSearch}
        ab_AD_CT={ab_AD_CT}
        ab_AD_CY={ab_AD_CY}
        ab_CL_12={ab_CL_12}
        ab_CL_11={ab_CL_11}
        ab_CL_13={ab_CL_13}
        ab_CL_07={ab_CL_07}
        ab_AD_TY={addressType}
        // ab_AC_IN={addStatus}
        ab_AC_IN={ab_AC_IN}
        abAdNo={abAdNo}
        ab_AD_NM = {ab_AD_NM}
        setOpen={() => {setshowaddressdialog(false);   handleSearch();}}
        initialValue={initialValue}
        setab_AD_CT={setab_AD_CT}
        setab_AD_CY={setab_AD_CY}
        setab_CL_12={setab_CL_12}
        setab_CL_11={setab_CL_11}
        setab_CL_13={setab_CL_13}
        setab_CL_07={setab_CL_07}
        setab_AD_TY={setab_AD_TY}
        setab_AC_IN={setab_AC_IN}
        setAbAdNo={setAbAdNo}
        setab_AD_NM= {setab_AD_NM}
        FormData={FormData}
        setFormData={setFormData}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        nextNumType={nextNumType}
      />
    </Dialog>
  );
};

AddressBookPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default AddressBookPopup;
