import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function CrossReferenceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CrossReferenceTableData,
  setCrossReferenceTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  selectedAddress,
  setselectedAddress,
  selectedName,
  setselectedName,
  cr_CR_TY,
  setcr_CR_TY,
  cr_CO_NO,
}) {
  const {
    crItNo,
    cr_IT_NM,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  const [AddresstypeC, setAddresstype] = useState("C");
  // Dropdown
  const {fetchClassCodeCD } = ClassCode();
  const [cr_CR_TYOptions, setcr_CR_TYOptions] = useState([]);

// Cross Type
const CrosstypeDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setcr_CR_TY(value);
  };
  useEffect(() => {
    CrossType();
  }, []);
  const CrossType = async () => {
    const Options = await fetchClassCodeCD("IN/CR");
    setcr_CR_TYOptions(Options);
  };
  const addRow = () => {
    setCrossReferenceTableData([...CrossReferenceTableData, {}]);
  };
  const deleteRow = () => {
    if (CrossReferenceTableData.length > 0) {
      const updatedData = [...CrossReferenceTableData];
      updatedData.pop();
      setCrossReferenceTableData(updatedData);
    }
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("cr_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  // Address
  const [AddressVisible, setAddressVisible] = useState(false);
  const handleAddress =async (data) => {
    setselectedAddress(data.abAdNo)
    setselectedName(data.ab_AD_NM)
    onchangeFormData("cr_AD_NO", data.abAdNo, SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    setAddressVisible(false);
    setShowSearchIcon(false);
  };
  const openAddressPopup = (e) => {
    setAddressVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(crItNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{crItNo ? "Revise Cross Reference" : "Add Cross Reference"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cr_IT_NM" className="font-bold">
                  Item No <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText 
                    id="cr_IT_NM"
                    name="cr_IT_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedItem}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="itemDES" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="itemDES"
                name="itemDES"
                value={SelectedDesc}
                readOnly
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cr_CO_NO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="cr_CO_NO"
                  name="cr_CO_NO"
                  value={cr_CO_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
        </div>  
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
          <table className="custom-table" id="CrossReferencetable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>CR Name</th>
                <th>CR Description</th>
                <th>CR Type</th>
                <th>Address No <span style={{ color: 'red' }}>*</span> </th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {CrossReferenceTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`crItNo_${index}`}
                      name={`crItNo_${index}`}
                      value={row.crItNo}
                    />
                  </td>
                    <td >
                    <InputText 
                      id={`cr_CR_NM_${index}`}
                      name={`cr_CR_NM_${index}`}
                      defaultValue={row.cr_CR_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText 
                      id={`cr_CR_DS_${index}`}
                      name={`cr_CR_DS_${index}`}
                      defaultValue={row.cr_CR_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`cr_CR_TY_${index}`}
                      name={`cr_CR_TY_${index}`}
                      value={ data[`cr_CR_TY_${index}`] ? data[`cr_CR_TY_${index}`]: row.cr_CR_TY}
                      options={cr_CR_TYOptions}
                      onChange={(e) =>CrosstypeDropdown(`cr_CR_TY_${index}`, e.value)}
                      placeholder="Select Cross Type"
                    />
                    </td>
                    <td >
                    <div style={{ display: "flex" }}> 
                    <InputText
                    id={`cr_AD_NO_${index}`}
                    name={`cr_AD_NO_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.cr_AD_NO ? row.cr_AD_NO: row[`${"cr_AD_NO"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openAddressPopup(index)}
                  ></i>
                  )}
                  </div>
                </td>
                <td>
                <InputText disabled
                id={`name_${index}`}
                name={`name_${index}`}
                defaultValue={row.name ? row.name : row[`${"name"}_${index}`]}
                />
                </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
        <AddressBookPopup
        visible={AddressVisible}
        onClose={() => setAddressVisible(false)}
        onDataSelect={handleAddress}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
