import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";

const initialValue = {
  glUkNo: "",
  gl_DO_NO: "",
  gl_DO_CO: "",
  gl_GL_DT: "",
  gl_EX_PL: "",
  gl_AC_AM: "",
  gl_AC_AM: "",
  gl_CR_CD: "",
  gl_LE_TY: "",
  gl_SB_TY: "",
  gl_BA_NO: "",
  gl_BA_TY: "",
  gl_AN_NO: "",
  address_DESC: "",
  gl_AC_NO: "",
  gl_GL_PO: "",
};

const initialFilters = {
  gl_DO_NO: { value: "", operation: "" },
  gl_DO_CO: { value: "", operation: "" },
  gl_GL_DT: { value: "", operation: "" },
  gl_EX_PL: { value: "", operation: "" },
  gl_AC_AM: { value: "", operation: "" },
  gl_AC_AM: { value: "", operation: "" },
  gl_CR_CD: { value: "", operation: "" },
  gl_LE_TY: { value: "", operation: "" },
  gl_SB_TY: { value: "", operation: "" },
  gl_BA_NO: { value: "", operation: "" },
  gl_BA_TY: { value: "", operation: "" },
  gl_AN_NO: { value: "", operation: "" },
  address_DESC: { value: "", operation: "" },
  gl_AC_NO: { value: "", operation: "" },
  gl_GL_PO: { value: "", operation: "" },
};

const AccountLedgerMain = () => {
  // buttonstyles
  const { formatDate, checkboxStyle, tableHeaderStyle,
     inputStyle, tableCellStyle, inboxstyle, ListDesc ,ListStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // AdvanceSearch
  const [SelectedItem, setSelectedItem] = useState("");
  const [SelectedDesc, setselectedDesc] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [item, setitem] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const [gl_GL_DT, setgl_GL_DT] = useState("");
  const [rh_RC_DT, setrh_RC_DT] = useState("");
  const [gl_LE_TY, setgl_LE_TY] = useState("");
  const [gl_SB_TY, setgl_SB_TY] = useState("");
  const [pr_RL_TY, setpr_RL_TY] = useState("");
  const [gl_AN_NO, setgl_AN_NO] = useState("");

  
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [bankFilter, setbankFilter] = useState("BA");

  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");
  
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [gl_LE_TYOptions, setgl_LE_TYOptions] = useState([]);
  const [gl_SB_TYOptions, setgl_SB_TYOptions] = useState([]);

  const onchangeDropDown1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setgl_LE_TY(value);
    updateFilters2(value);
  };
  const onchangeDropDown2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setgl_SB_TY(value);
    updateFilters3(value);
  };


  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };


  const accapi = `${domain}/api/JournalLedger/GetSummaryFromGABL`
  const changeaccount = async (data) => {
    const request = {
      "account": data.account,
      "gl_DATE": gl_GL_DT
    }
    try {
      const response = await axios.post(accapi, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      const dataacc = response.data.Result;
      if (dataacc) {
        onchangeFormData("rh_AC_BL", dataacc.gb_BG_BL);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const [AddresstypeE, setAddresstypeE] = useState("S");

  const [SalesPersonPopupVisible, setSalesPersonPopupVisible] = useState(false);
  const openSalesPersonPopup = () => {
    setSalesPersonPopupVisible(true);
  };
  const handleDataSelectE = async (data) => {
    setSelectedemEMNOE(data.ab_AD_NM);
    onchangeFormData("gl_AN_NO", data.abAdNo);
    setSalesPersonPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters1(data.abAdNo);
  };

  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const openitemPopup = (e) => {
    setItemPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedItem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters(data.im_IT_NM);
  };


  const handleDataSelect1 = async (data) => {
    setSelectedemEMNOA(data.am_AC_DS);
    onchangeFormData("gl_AC_NO", data.account);
    changeaccount(data);
    setAccountPopupVisible(false);
    updateFilters(data.account);
  };

  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
    if (cus !== null) {
      updatedFilters = {
        ...updatedFilters,
        gl_AC_NO: {
          value: cus,
          operation: "EQUAL",
        },
      };
    }

    setFilters(updatedFilters);


  };
  const updateFilters1 = (cus) => {
    let updatedFilters = { ...filters };
    if (cus !== null) {
      updatedFilters = {
        ...updatedFilters,
        gl_AN_NO: {
          value: cus,
          operation: "EQUAL",
        },
      };
    }

    setFilters(updatedFilters);


  };


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClearButton = () => {
    setitem("");
    setSelectedemEMNOA("");
    setselectedDesc("");
    setSelectedItem(null);
    setgl_LE_TY("");
    setgl_SB_TY("");
    setgl_AN_NO("");
    setSelectedemEMNOE("");
    setFilters(initialFilters);
  }
  useEffect(() => {
    handleDefault();
    DropDownDate();
    DropDownDate1();
  }, [currentPage, pageSize, filters]);

  const DropDownDate1 = async () => {
    const Options = await fetchClassCodeDS("LO/LE");
    setgl_SB_TYOptions(Options);
  };
  const DropDownDate = async () => {
    const Options = await fetchClassCodeDS("FO/LT");
    setgl_LE_TYOptions(Options);
  };
  const [eg_CO_NO, seteg_CO_NO] = useState("");
  const handleDefault = async () => {
    seteg_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;

          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JournalEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        gl_LE_TY: {
          value: sts,
          operation: "LIKE",
        },
      };
    }
    setFilters(updatedFilters);
  };
  const updateFilters3 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        gl_SB_TY: {
          value: sts,
          operation: "LIKE",
        },
      };
    }
    setFilters(updatedFilters);
  };
  // const onchangeDropDown1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setgl_LE_TY(value);
  //   updateFilters2(value);
  //   console.log(value);
  // };
  const AdvanceSearch = (
    <div className="formgrid grid" id="headerfilters" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    {/* First Row */}
    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="accountDes" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>
        Account <span style={{ color: 'red' }}>*</span>
      </label>
      <div>
        <InputText  style={{ flex: '1' }}
          id="accountDes"
          name="accountDes"
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={SelectedemEMNOA}
        />
        {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginLeft: '0.5rem',
              cursor: "pointer",
            }}
            onClick={openAccountPopup}
          ></i>
        )}
      </div>
    </div>

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="gl_LE_TY" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>Ledger Type</label>
      <Dropdown
        style={{ flex: '1' }}
        id="gl_LE_TY"
        name="gl_LE_TY"
        value={gl_LE_TY}
        options={gl_LE_TYOptions}
        onChange={(e) => onchangeDropDown1("gl_LE_TY", e.value)}
        placeholder="Ledger Type"
      />
    </div>

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="gl_SB_TY" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>Sub Ledger Type</label>
      <Dropdown
        style={{ flex: '1' }}
        id="gl_SB_TY"
        name="gl_SB_TY"
        value={gl_SB_TY}
        options={[
          { label: "C", value: "C" },
          { label: "S", value: "S" },
          { label: "E", value: "E" },
          { label: "F", value: "F" },
        ]}
        onChange={(e) => onchangeDropDown2("gl_SB_TY", e.value)}
        placeholder="Sub Ledger Type"
      />
    </div>

    {/* Second Row */}

    <div className="field col " style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="pd_DD_DT" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>
        From Date
      </label>
      <Calendar
        id="rh_RC_DT"
        name="rh_RC_DT"
        defaultValue={moment(rh_RC_DT, "YYYYMMDD").toDate()}
        onChange={(e) => onchangedata({ target: { name: "rh_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
        dateFormat="dd/mm/yy"
        style={{ flex: '1' }}
      />
    </div>

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="pd_DD_DT" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>To Date</label>
      <Calendar
        id="rh_RC_DT"
        name="rh_RC_DT"
        defaultValue={moment(rh_RC_DT, "YYYYMMDD").toDate()}
        onChange={(e) => onchangedata({ target: { name: "rh_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
        dateFormat="dd/mm/yy"
        style={{ flex: '1' }}
      />
    </div> 

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="pr_RL_TY" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>Beginning Balance</label>
      <InputText
        id="pr_RL_TY"
        name="pr_RL_TY"
        value={pr_RL_TY}
        onChange={(e) => onchangedata(e)}
        style={{ flex: '1' }}
      />
    </div>

    {/* Third Row */}
    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="pr_RL_TY" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>YTD</label>
      <InputText
        id="pr_RL_TY"
        name="pr_RL_TY"
        value={pr_RL_TY}
        onChange={(e) => onchangedata(e)}
        style={{ flex: '1' }}
      />
    </div>

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="gl_AN_NO" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>
        Sub Ledger Name <span style={{ color: 'red' }}>*</span>
      </label>
      <div >
        <InputText style={{ flex: '1' }}
          id="sales_PERSON"
          name="sales_PERSON"
          onChange={(e) => onchangedata(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={SelectedemEMNOE}
        />
        {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginLeft: '0.5rem',
              cursor: "pointer",
            }}
            onClick={openSalesPersonPopup}
          ></i>
        )}
      </div>
      <InputText
        style={{ display: "none" }}
        id="gl_AN_NO"
        name="gl_AN_NO"
        value={gl_AN_NO}
        onChange={(Event) => onchangedata(Event)}
      />
    </div>

    <div className="field col" style={{ flex: '1 1 30%', display: 'flex', alignItems: 'center', margin: '0.5rem' }}>
      <label htmlFor="pr_RL_TY" className="font-bold" style={{ width: '30%', marginRight: '1rem' }}>Closing Balance</label>
      <InputText
        id="pr_RL_TY"
        name="pr_RL_TY"
        value={pr_RL_TY}
        onChange={(e) => onchangedata(e)}
        style={{ flex: '1' }}
      />
    </div>

    </div>
  );
  
  const rightClear = (
    <React.Fragment>
      <Tooltip target=".clear-button" position="bottom" mouseTrack>
        <div>Clear Filter</div>
      </Tooltip>

      <Button
        style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
        // style={{ height: "35px" }}
        icon="pi pi-times"
        className="mr-2 clear-button"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%" }}>
          <Toolbar
            className="p-mb-4"
            left={AdvanceSearch}
            right={rightClear}
          />
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
              <thead>
                <tr>
                  <td style={{ width: '40px' }}>
                    <input
                      type="text"
                      style={checkboxStyle}
                      readOnly
                      onMouseDown={(e) => e.preventDefault()}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_DO_NO"] ? filters["gl_DO_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_DO_NO",
                          e.target.value,
                          filters["gl_DO_NO"]
                            ? filters["gl_DO_NO"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_DO_CO"] ? filters["gl_DO_CO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_DO_CO",
                          e.target.value,
                          filters["gl_DO_CO"] ? filters["gl_DO_CO"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_GL_DT"] ? filters["gl_GL_DT"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_GL_DT",
                          e.target.value,
                          filters["gl_GL_DT"]
                            ? filters["gl_GL_DT"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_EX_PL"] ? filters["gl_EX_PL"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_EX_PL",
                          e.target.value,
                          filters["gl_EX_PL"]
                            ? filters["gl_EX_PL"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_AC_AM"] ? filters["gl_AC_AM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_AC_AM",
                          e.target.value,
                          filters["gl_AC_AM"]
                            ? filters["gl_AC_AM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_AC_AM"] ? filters["gl_AC_AM"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_AC_AM",
                          e.target.value,
                          filters["gl_AC_AM"]
                            ? filters["gl_AC_AM"].operation
                            : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_CR_CD"] ? filters["gl_CR_CD"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_CR_CD",
                          e.target.value,
                          filters["gl_CR_CD"] ? filters["gl_CR_CD"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_LE_TY"] ? filters["gl_LE_TY"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_LE_TY",
                          e.target.value,
                          filters["gl_LE_TY"] ? filters["gl_LE_TY"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_SB_TY"] ? filters["gl_SB_TY"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_SB_TY",
                          e.target.value,
                          filters["gl_SB_TY"] ? filters["gl_SB_TY"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      value={filters["gl_BA_NO"] ? filters["gl_BA_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_BA_NO",
                          e.target.value,
                          filters["gl_BA_NO"] ? filters["gl_BA_NO"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_BA_TY"] ? filters["gl_BA_TY"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_BA_TY",
                          e.target.value,
                          filters["gl_BA_TY"] ? filters["gl_BA_TY"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_AN_NO"] ? filters["gl_AN_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_AN_NO",
                          e.target.value,
                          filters["gl_AN_NO"] ? filters["gl_AN_NO"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>
                  <td>
                    <input disabled
                      type="text"
                      value={filters["address_DESC"] ? filters["address_DESC"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "address_DESC",
                          e.target.value,
                          filters["address_DESC"] ? filters["address_DESC"].operation : "equals"
                        )
                      }
                      style={ListDesc}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_AC_NO"] ? filters["gl_AC_NO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_AC_NO",
                          e.target.value,
                          filters["gl_AC_NO"] ? filters["gl_AC_NO"].operation : "equals"
                        )
                      }
                      style={inputStyleH}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={filters["gl_GL_PO"] ? filters["gl_GL_PO"].value : ""}
                      onChange={(e) =>
                        handleFilterChange(
                          "gl_GL_PO",
                          e.target.value,
                          filters["gl_GL_PO"] ? filters["gl_GL_PO"].operation : "equals"
                        )
                      }
                      style={inputStyle}
                    />
                  </td>


                </tr>
                <tr>
                  <th style={tableHeaderStyle}></th>
                  <th style={tableHeaderStyle}>Doc No</th>
                  <th style={tableHeaderStyle}>Company</th>
                  <th style={tableHeaderStyle}>GL Date</th>
                  <th style={tableHeaderStyle}>Explanation</th>
                  <th style={tableHeaderStyle}>Debit Amount</th>
                  <th style={tableHeaderStyle}>Credit Amount</th>
                  <th style={tableHeaderStyle}>Currency</th>
                  <th style={tableHeaderStyle}>Sub Ledger</th>
                  <th style={ListStyle}>Sub Ledger Type</th>
                  <th style={tableHeaderStyle}>Batch Number</th>
                  <th style={tableHeaderStyle}>Batch Type</th>
                  <th style={tableHeaderStyle}>Address No</th>
                  <th style={tableHeaderStyle}>Description</th>
                  <th style={tableHeaderStyle}>Account</th>
                  <th style={tableHeaderStyle}>Posted</th>
                </tr>
              </thead>
              <tbody>
                {tableData &&
                  tableData.map((rowData, index) => (
                    <tr
                      key={rowData.glUkNo}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>
                        <input
                          style={{ width: "10% !important" }}
                          type="checkbox"
                          value={rowData.glUkNo}
                          checked={selectedRows.includes(rowData.glUkNo)}
                          onChange={() => handleRowSelect(rowData.glUkNo)}
                        />
                      </td>
                      <td style={tableCellStyle}>{rowData.gl_DO_NO}</td>
                      <td style={tableCellStyle}>{rowData.gl_DO_CO}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.gl_GL_DT)}</td>
                      <td style={tableCellStyle}>{rowData.gl_EX_PL}</td>
                      <td style={tableCellStyle}>{rowData.gl_AC_AM}</td>
                      <td style={tableCellStyle}>{rowData.gl_AC_AM}</td>
                      <td style={tableCellStyle}>{rowData.gl_CR_CD}</td>
                      <td style={tableCellStyle}>{rowData.gl_LE_TY}</td>
                      <td style={tableCellStyle}>{rowData.gl_SB_TY}</td>
                      <td style={tableCellStyle}>{rowData.gl_BA_NO}</td>
                      <td style={tableCellStyle}>{rowData.gl_BA_TY}</td>
                      <td style={tableCellStyle}>{rowData.gl_AN_NO}</td>
                      <td style={tableCellStyle}>{rowData.address_DESC}</td>
                      <td style={tableCellStyle}>{rowData.gl_AC_NO}</td>
                      <td style={tableCellStyle}>{rowData.gl_GL_PO}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* pagination */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <ItemPopup
          visible={ItemPopupVisible}
          onClose={() => setItemPopupVisible(false)}
          onDataSelect={handleDataSelect}
        />
        <AccountNoPopup
          visible={AccountPopupVisible}
          onClose={() => setAccountPopupVisible(false)}
          onDataSelect={handleDataSelect1}
          // formData={data}
          bankFilter={bankFilter}
        />
        <AddressBookPopup
          visible={SalesPersonPopupVisible}
          onClose={() => setSalesPersonPopupVisible(false)}
          onDataSelect={handleDataSelectE}
          //formData={data}
          addressType={AddresstypeE}
        />
      </div>
    </div>
  );
};
export default AccountLedgerMain;