import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
const initialFilters = {
    os_OP_SQ: { value: "", operation: "" },
    os_SQ_DS: { value: "", operation: "" },
};

const OperationSequencePopup = ({ visible, onClose, onDataSelect }) => {
  const {tableHeaderStyle,tableCellStyle,PopupDailogStyle,PopupStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === "im_IT_NM") {
          constructedColumn = "im_IT_NM";
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
    const updatedFilters = {
      ...filters,
      [column]: {
        value: updatedValue,
        operation: updatedOperation,
      },
    };
    setFilters(updatedFilters);
  };
  

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  // useEffect(() => {
  //   setOpen(visible);
  //   handleSearch();
  //   setCurrentPage(0);
  // }, [currentPage, pageSize, filters, visible]);
  useEffect(() => {
    if (visible) {
      setOpen(true);
      setCurrentPage(0);
    } else {
      setOpen(false); 
    }
  }, [visible]);
  
  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open, currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/OperationSequence/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  return (
    <Dialog id="dialog-popup-style" 
      visible={open} 
      onHide={handleClose}  
      style={PopupStyle} >
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={
                      filters["os_OP_SQ"] ? filters["os_OP_SQ"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "os_OP_SQ",
                        e.target.value,
                        filters["os_OP_SQ"]
                          ? filters["os_OP_SQ"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={
                      filters["os_SQ_DS"] ? filters["os_SQ_DS"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "os_SQ_DS",
                        e.target.value,
                        filters["os_SQ_DS"]
                          ? filters["os_SQ_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}> Operation Sequence</th>
                <th style={tableHeaderStyle}> Description </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData)}
                      style={{
                              backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",
                            }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.os_OP_SQ}</td>
                      <td style={tableCellStyle}>{rowData.os_SQ_DS}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Dialog>
  );
};

export default OperationSequencePopup;
