import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ARAPNettingDialog from "./ARAPNettingDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import '../../../components/ListStyle/ListStyle.css';
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  nd_NT_ID: "",
  nd_NT_TY: "",
  nd_NT_CO: "",
  nd_GL_DT: "",
  nd_CU_NO: "",
  address_NAME: "",
  nd_SU_NO: "",  
  address_NAMES: "",
  nd_TR_CR: "",
  nd_DO_NO: "",
  nd_DO_TY: "",
  nd_DO_PI: "",
  nd_GR_AM: "",
  nd_OP_AM: "",
  nd_GR_FR: "",
  nd_OP_FR: "",
  nd_FL_AG: "",
};

const initialFilters = {
  nd_NT_ID: { value: "", operation: "" },
  nd_NT_TY: { value: "", operation: "" },
  nd_NT_CO: { value: "", operation: "" },
  nd_GL_DT: { value: "", operation: "" },
  nd_CU_NO: { value: "", operation: "" },
  address_NAME: { value: "", operation: "" },
  nd_SU_NO: { value: "", operation: "" },
  address_NAMES: { value: "", operation: "" },
  nd_TR_CR: { value: "", operation: "" },
  nd_DO_NO: { value: "", operation: "" },
  nd_DO_TY: { value: "", operation: "" },
  nd_DO_PI: { value: "", operation: "" },
  nd_GR_AM: { value: "", operation: "" },
  nd_OP_AM: { value: "", operation: "" },
  nd_GR_FR: { value: "", operation: "" },
  nd_OP_FR: { value: "", operation: "" },
  nd_FL_AG: { value: "", operation: "" },
};
const ARAPNettingMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
          inputStyle,tableCellStyle,ListDesc,
         } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [ARTableData1, setARTableData1] = useState([{}]);
  const [APTableData2, setAPTableData2] = useState([{}]);
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOS, setSelectedemEMNOS] = useState("");
  const [SelectedemEMNOP, setSelectedemEMNOP] = useState("");
  const [nd_NT_ID , setnd_NT_ID] = useState("");

  const [sqQfNm, setsqQfNm] = useState([]);
  const [ndUkNo, setndUkNo] = useState([]);
  const [sq_LD_TH, setsq_LD_TH] = useState([]);
  const [sq_OP_TH, setsq_OP_TH] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setARTableData1([{}]);
    setAPTableData2([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNOS(null);
    setndUkNo(null);
    setsqQfNm(null);
    setsq_LD_TH(null);
    setsq_OP_TH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setndUkNo(null);
    setsqQfNm(null);
    setsq_LD_TH(null);
    setsq_OP_TH(null);
    setOpen(false);
  };

  const addARTable1Row = () => {
    setARTableData1([...ARTableData1, {}]);
  };

  const deleteARTable1Row = () => {
    if (ARTableData1.length > 0) {
      const updatedData = [...ARTableData1];
      updatedData.pop();
      setARTableData1(updatedData);
    }
  };

  const addAPTable2Row = () => {
    setAPTableData2([...APTableData2, {}]);
  };

  const deleteAPTable2Row = () => {
    if (APTableData2.length > 0) {
      const updatedData = [...APTableData2];
      updatedData.pop();
      setAPTableData2(updatedData);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ARAPNetting/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ARAPNettingData = response.data;
          delete ARAPNettingData.ndUkNo;
          setFormData(ARAPNettingData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const url = "http://localhost:8182/api/le_qu/getAlLLead_Qualification";
  const [nd_NT_CO , setnd_NT_CO] = useState("");
  const [nd_NT_TY , setnd_NT_TY] = useState("");
    const handleDefault = async () => {
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              setnd_NT_CO(`${company}`)
              if(fName === "ND_NT_TY"){
                setnd_NT_TY(dataDefault.versionIds[i].default_VALUE)
              }
  
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
     // handleSearch();
     handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ARAPNetting/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ARAPNetting/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ARAPNettingData = response.data;
        setARTableData1(ARAPNettingData.arNettingList);
        setAPTableData2(ARAPNettingData.apNettingLists);
        setFormData(ARAPNettingData);
        setSelectedemEMNOA(response.data.address_NAME);
        setSelectedemEMNO(response.data.nd_CU_NO);
        setSelectedemEMNOS(response.data.nd_SU_NO);
        setSelectedemEMNOP(response.data.address_NAMES);
        setndUkNo(response.data.ndUkNo);
        setsqQfNm(response.data.sqQfNm);
        setsq_LD_TH(response.data.sq_LD_TH);
        setsq_OP_TH(response.data.sq_OP_TH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBody = {};
  function values(){
    const table = document.getElementById("ARTable1");
    const totalRowCount = table.tBodies[0].rows.length;
    const arNettingList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const RQ_DT = document.getElementsByName("nd_GL_DT_" + i)[0];
      const CN_DT = document.getElementsByName("nd_IN_DT_" + i)[0];
      const PR_DT = document.getElementsByName("nd_DU_DT_" + i)[0];
      const Details = {
        ndUkNo : document.getElementById("ndUkNo_" + i).value,
        nd_DO_NO : document.getElementById("nd_DO_NO_" + i).value,
        nd_DO_TY : document.getElementById("nd_DO_TY_" + i).value,
        nd_DO_CO : document.getElementById("nd_DO_CO_" + i).value,
        nd_DO_PI : document.getElementById("nd_DO_PI_" + i).value, 
        nd_GL_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        nd_DU_DT : PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        nd_IN_DT : CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        nd_GR_AM : document.getElementById("nd_GR_AM_" + i).value,
        nd_OP_AM : document.getElementById("nd_OP_AM_" + i).value,
        nd_GR_FR : document.getElementById("nd_GR_FR_" + i).value, 
        nd_OP_FR : document.getElementById("nd_OP_FR_" + i).value,
        nd_CU_NO : document.getElementById("nd_CU_NO_" + i).value,
        nd_CU_PO : document.getElementById("nd_CU_PO_" + i).value, 
        nd_OR_NO : document.getElementById("nd_OR_NO_" + i).value,
        nd_OR_TY : document.getElementById("nd_OR_TY_" + i).value,
    };
    arNettingList.push(Details);
      }

      const table1= document.getElementById("APTable2");
      const totalRowCount1 = table1.tBodies[0].rows.length;
      const apNettingLists = [];
      for (let i = 0; i < totalRowCount1; i++) {
        const RQ_DT = document.getElementsByName("nd_GL_DT_" + i)[0];
        const CN_DT = document.getElementsByName("nd_IN_DT_" + i)[0];
        const PR_DT = document.getElementsByName("nd_DU_DT_" + i)[0];
        const Details = {
          ndUkNo : document.getElementById("ndUkNo_" + i).value,
          nd_DO_NO : document.getElementById("nd_DO_NO_" + i).value,
          nd_DO_TY : document.getElementById("nd_DO_TY_" + i).value,
          nd_DO_CO : document.getElementById("nd_DO_CO_" + i).value,
          nd_DO_PI : document.getElementById("nd_DO_PI_" + i).value, 
          nd_GL_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          nd_DU_DT : PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          nd_IN_DT : CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          nd_GR_AM : document.getElementById("nd_GR_AM_" + i).value,
          nd_OP_AM : document.getElementById("nd_OP_AM_" + i).value,
          nd_GR_FR : document.getElementById("nd_GR_FR_" + i).value, 
          nd_OP_FR : document.getElementById("nd_OP_FR_" + i).value,
          nd_CU_NO : document.getElementById("nd_CU_NO_" + i).value,
          nd_CU_PO : document.getElementById("nd_CU_PO_" + i).value, 
          nd_OR_NO : document.getElementById("nd_OR_NO_" + i).value,
          nd_OR_TY : document.getElementById("nd_OR_TY_" + i).value,
      };
      apNettingLists.push(Details);
        }
      requestBody ={
        arNettingList,
        apNettingLists,
          ndUkNo : FormData.ndUkNo,
        nd_NT_ID : document.getElementById("nd_NT_ID").value,
        nd_NT_TY : document.getElementById("nd_NT_TY").value,
        nd_NT_CO : document.getElementById("nd_NT_CO").value,
        nd_CU_NO : document.getElementById("nd_CU_NO").value,
        nd_SU_NO : document.getElementById("nd_SU_NO").value,
        nd_GL_DT : FormData.nd_GL_DT,
        nd_TR_CR : FormData.nd_TR_CR,
      }
  }

  const handleFormSubmit = () => {
    values();
    const orNo = document.getElementById("nd_NT_ID").value;
    const ukNo = FormData.ndUkNo;
    if (orNo) {
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ARAPNetting/Update`;
    
        if (confirm) {
          axios.put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/ARAPNetting/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
        
        const OR_DT = FormData.nd_GL_DT;
        if (OR_DT) {
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${nd_NT_TY}/${secondHalf}/1000/${firstHalf}`;
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
          fetch(finalUrl1, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error(`Request failed with status: ${resp.status}`);
              }
              return resp.json();
            })
            .then((resp) => {
              setnd_NT_ID(resp.dn_DO_NO);
              document.getElementById("nd_NT_ID").value = resp.dn_DO_NO;

              values();
              axios.post(`${domain}/api/ARAPNetting/Add`, requestBody, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                      "Access-Control-Allow-Origin": "http://localhost:3000",
                      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                    },
                  })
                  .then((resp) => {
                    handleResponse(resp.data);
                  })
                  .catch((error) => {
                    console.error("Error saving data:", error);
                  });

            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      }
    };

  const handleResponse = (data) => {
    const { warnings = [], errors = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errors.length > 0) {
      const errorMessage = errors.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
      });
    }

    if (errors.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };

  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "red !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
      <div
        style={{
          backgroundColor: "transparent !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Search
      </div>
    </Tooltip>
    <Button
      style={searchButtonStyles}
      icon="pi pi-search"
      outlined
      className="mr-2 search-button"
    ></Button> */}
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );
  const tableCellStyleW = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
  };
  const tableCellStyleLO = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_NT_ID"] ? filters["nd_NT_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_NT_ID",
                        e.target.value,
                        filters["nd_NT_ID"]
                          ? filters["nd_NT_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_NT_TY"] ? filters["nd_NT_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_NT_TY",
                        e.target.value,
                        filters["nd_NT_TY"]
                          ? filters["nd_NT_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_NT_CO"] ? filters["nd_NT_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_NT_CO",
                        e.target.value,
                        filters["nd_NT_CO"]
                          ? filters["nd_NT_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_GL_DT"] ? filters["nd_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_GL_DT",
                        e.target.value,
                        filters["nd_GL_DT"]
                          ? filters["nd_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_CU_NO"] ? filters["nd_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_CU_NO",
                        e.target.value,
                        filters["nd_CU_NO"]
                          ? filters["nd_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["address_NAME"] ? filters["address_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "address_NAME",
                        e.target.value,
                        filters["address_NAME"]
                          ? filters["address_NAME"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_SU_NO"] ? filters["nd_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_SU_NO",
                        e.target.value,
                        filters["nd_SU_NO"]
                          ? filters["nd_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["address_NAMES"] ? filters["address_NAMES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "address_NAMES",
                        e.target.value,
                        filters["address_NAMES"]
                          ? filters["address_NAMES"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_TR_CR"] ? filters["nd_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_TR_CR",
                        e.target.value,
                        filters["nd_TR_CR"]
                          ? filters["nd_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_DO_NO"] ? filters["nd_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_DO_NO",
                        e.target.value,
                        filters["nd_DO_NO"]
                          ? filters["nd_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_DO_TY"] ? filters["nd_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_DO_TY",
                        e.target.value,
                        filters["nd_DO_TY"]
                          ? filters["nd_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_DO_PI"] ? filters["nd_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_DO_PI",
                        e.target.value,
                        filters["nd_DO_PI"]
                          ? filters["nd_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["nd_GR_AM"] ? filters["nd_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_GR_AM",
                        e.target.value,
                        filters["nd_GR_AM"]
                          ? filters["nd_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_OP_AM"] ? filters["nd_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_OP_AM",
                        e.target.value,
                        filters["nd_OP_AM"]
                          ? filters["nd_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_GR_FR"] ? filters["nd_GR_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_GR_FR",
                        e.target.value,
                        filters["nd_GR_FR"]
                          ? filters["nd_GR_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_OP_FR"] ? filters["nd_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_OP_FR",
                        e.target.value,
                        filters["nd_OP_FR"]
                          ? filters["nd_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["nd_FL_AG"] ? filters["nd_FL_AG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "nd_FL_AG",
                        e.target.value,
                        filters["nd_FL_AG"]
                          ? filters["nd_FL_AG"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Netting ID</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name </th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Trans Currency</th>
                <th style={tableHeaderStyle}>Document No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Pay Item</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Foreign Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign Open Amount</th>
                <th style={tableHeaderStyle}>Processed Flag</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ndUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.ndUkNo}
                        checked={selectedRows.includes(rowData.ndUkNo)}
                        onChange={() => handleRowSelect(rowData.ndUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.nd_NT_ID}</td>
                    <td style={tableCellStyleLO}>{rowData.nd_NT_TY}</td>
                    <td style={tableCellStyleLO}>{rowData.nd_NT_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.nd_GL_DT)}</td> 
                    <td style={tableCellStyleLO}>{rowData.nd_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.address_NAME}</td>
                    <td style={tableCellStyle}>{rowData.nd_SU_NO}</td>
                    <td style={tableCellStyleW}>{rowData.address_NAMES}</td>
                    <td style={tableCellStyleLO}>{rowData.nd_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.nd_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.nd_DO_TY}</td>
                    <td style={tableCellStyleW}>{rowData.nd_DO_PI}</td>
                    <td style={tableCellStyle}>{rowData.nd_GR_AM}</td>
                    <td style={tableCellStyle}>{rowData.nd_OP_AM}</td>
                    <td style={tableCellStyleW}>{rowData.nd_GR_FR}</td>
                    <td style={tableCellStyle}>{rowData.nd_OP_FR}</td>
                    <td style={tableCellStyleW}>{rowData.nd_FL_AG}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
    {/* pagination */}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

      <ARAPNettingDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        ARTableData1={ARTableData1}
        APTableData2={APTableData2}
        setAPTableData2={setAPTableData2}
        setARTableData1={setARTableData1}
        addAPTable2Row={addAPTable2Row}
        deleteAPTable2Row={deleteAPTable2Row}
        addARTable1Row={addARTable1Row}
        deleteARTable1Row={deleteARTable1Row}
        SelectedemEMNOA={SelectedemEMNOA}
        setSelectedemEMNOA={setSelectedemEMNOA}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOS={SelectedemEMNOS}
        setSelectedemEMNOS={setSelectedemEMNOS}
        SelectedemEMNOP={SelectedemEMNOP}
        setSelectedemEMNOP={setSelectedemEMNOP}
        ndUkNo={ndUkNo}
        sqQfNm={sqQfNm}
        nd_NT_CO={nd_NT_CO}
        nd_NT_TY={nd_NT_TY}
        sq_LD_TH={sq_LD_TH}
        sq_OP_TH={sq_OP_TH}
        nd_NT_ID={nd_NT_ID}
      />
    </div>
  );
};

export default ARAPNettingMain;
