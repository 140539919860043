import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import WarrantyClaimDialog from "./WarrantyClaimDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  wcUkNo: "",
  wc_CL_CD1: "",
  wc_CL_CD2: "",
  wc_CL_CD3: "",
  wc_CL_CD4: "",
  wc_CL_CD5: "",
  wc_CL_CD6: "",
  wc_CL_CD7: "",
  wc_CL_CD8: "",
  wc_CL_CD9: "",
  wc_CL_CD10: "",
  wc_SR_DL: "",
  wc_DL_RF: "",
  wc_ST_CD: "",
  wc_AS_OR: "",
  wc_EQ_NO: "",
  wc_IT_NM: "",
  wc_LT_NO: "",
  wc_PR_MD: "",
  wc_PR_FM: "",
  wc_CU_NO: "",
  wc_BL_M1: "",
  wc_BL_M2: "",
  wc_BL_M3: "",
  wc_CL_PT: "",
  wc_BU_UN: "",
  wc_DF_CD: "",
  wc_CL_SU: "",
  wc_FL_DT: "",
  wc_RP_DT: "",
  wc_SU_LT: "",
  wc_CC_P1: "",
  wc_FL_DS: "",
  SelectedCustomer:"",
  SelectedItem:"",
};

const initialFilters = {
  wc_SR_DL: { value: "", operation: "" },
  wc_DL_RF: { value: "", operation: "" },
  wc_ST_CD: { value: "", operation: "" },
  wc_AS_OR: { value: "", operation: "" },
  wc_EQ_NO: { value: "", operation: "" },
  wc_IT_NM: { value: "", operation: "" },
  wc_LT_NO: { value: "", operation: "" },
  wc_PR_MD: { value: "", operation: "" },
  wc_PR_FM: { value: "", operation: "" },
  wc_CU_NO: { value: "", operation: "" },
};

const WarrantyClaimMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const [SelectedServiceDlr, setSelectedServiceDlr] = useState("");
  const [SelectedAccesor, setSelectedAccesor] = useState("");
  const [SelectedCustomer, setSelectedCustomer] = useState("");
  const [SelectedEquipment, setSelectedEquipment] = useState("");
  const [SelectedItem, setSelectedItem] = useState("");
  const [wc_ST_CD, setwc_ST_CD] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedServiceDlr(null);
    setSelectedAccesor(null);
    setSelectedCustomer(null);
    setSelectedEquipment(null);
    setSelectedItem(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/WarrantyClaim/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const WarrantyClaimData = response.data;
          delete WarrantyClaimData.wcUkNo;
          setFormData(WarrantyClaimData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_ST_CD(value);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "WC_ST_CD"){
              onchangeDropDownFormData1("wc_ST_CD",dataDefault.versionIds[i].default_VALUE);
              setwc_ST_CD(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/WarrantyClaim/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    // setFormData({ ...FormData, [name]: value });
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/WarrantyClaim/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WarrantyClaimData = response.data;
        setFormData(WarrantyClaimData);
        setSelectedServiceDlr(WarrantyClaimData.serviceDealerDES);
        setSelectedAccesor(WarrantyClaimData.accessorDES);
        setSelectedCustomer(WarrantyClaimData.customer);
        setSelectedEquipment(WarrantyClaimData.equipmentDES);
        setSelectedItem(WarrantyClaimData.itemDES);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      wcUkNo : document.getElementById("wcUkNo").value,
      wc_SR_DL : document.getElementById("wc_SR_DL").value,
      wc_DL_RF : document.getElementById("wc_DL_RF").value,
      wc_AS_OR : document.getElementById("wc_AS_OR").value,
      wc_ST_CD : FormData.wc_ST_CD,
      wc_EQ_NO : document.getElementById("wc_EQ_NO").value,
      wc_IT_NM : document.getElementById("wc_IT_NM").value,
      wc_LT_NO : document.getElementById("wc_LT_NO").value,
      wc_PR_MD : document.getElementById("wc_PR_MD").value,
      wc_PR_FM : document.getElementById("wc_PR_FM").value,
      wc_CU_NO : document.getElementById("wc_CU_NO").value,
      wc_BL_M1 : document.getElementById("wc_BL_M1").value,
      wc_BL_M2 : document.getElementById("wc_BL_M2").value,
      wc_BL_M3 : document.getElementById("wc_BL_M3").value,
      wc_CL_PT : document.getElementById("wc_CL_PT").value,
      wc_BU_UN : document.getElementById("wc_BU_UN").value,
      wc_CL_SU : document.getElementById("wc_CL_SU").value,
      wc_DF_CD : document.getElementById("wc_DF_CD").value,
      wc_SU_LT : document.getElementById("wc_SU_LT").value,
      wc_FL_DT : FormData.wc_FL_DT,
      wc_RP_DT : FormData.wc_RP_DT,
      wc_CC_P1 : document.getElementById("wc_CC_P1").value,
      wc_CL_PT : document.getElementById("wc_CL_PT").value,
      wc_FL_DS : document.getElementById("wc_FL_DS").value,
      wc_CL_CD1 : FormData.wc_CL_CD1,
      wc_CL_CD6 : FormData.wc_CL_CD6,
      wc_CL_CD2 : FormData.wc_CL_CD2,
      wc_CL_CD3 : FormData.wc_CL_CD3,
      wc_CL_CD4 : FormData.wc_CL_CD4,
      wc_CL_CD5 : FormData.wc_CL_CD5,
      wc_CL_CD7 : FormData.wc_CL_CD7,
      wc_CL_CD10: FormData.wc_CL_CD10,
      wc_CL_CD8 : FormData.wc_CL_CD8,
      wc_CL_CD9 : FormData.wc_CL_CD9,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.wcUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/WarrantyClaim/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      values();
      axios.post(`${domain}/api/WarrantyClaim/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}> 
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_SR_DL"] ? filters["wc_SR_DL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_SR_DL",
                        e.target.value,
                        filters["wc_SR_DL"]
                          ? filters["wc_SR_DL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_DL_RF"] ? filters["wc_DL_RF"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_DL_RF",
                        e.target.value,
                        filters["wc_DL_RF"] ? filters["wc_DL_RF"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_ST_CD"] ? filters["wc_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_ST_CD",
                        e.target.value,
                        filters["wc_ST_CD"]
                          ? filters["wc_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_AS_OR"] ? filters["wc_AS_OR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_AS_OR",
                        e.target.value,
                        filters["wc_AS_OR"]
                          ? filters["wc_AS_OR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_EQ_NO"] ? filters["wc_EQ_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_EQ_NO",
                        e.target.value,
                        filters["wc_EQ_NO"]
                          ? filters["wc_EQ_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_IT_NM"] ? filters["wc_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_IT_NM",
                        e.target.value,
                        filters["wc_IT_NM"]
                          ? filters["wc_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_LT_NO"] ? filters["wc_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_LT_NO",
                        e.target.value,
                        filters["wc_LT_NO"]
                          ? filters["wc_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_PR_MD"] ? filters["wc_PR_MD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_PR_MD",
                        e.target.value,
                        filters["wc_PR_MD"]
                          ? filters["wc_PR_MD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["wc_PR_FM"] ? filters["wc_PR_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_PR_FM",
                        e.target.value,
                        filters["wc_PR_FM"]
                          ? filters["wc_PR_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["wc_CU_NO"] ? filters["wc_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "wc_CU_NO",
                        e.target.value,
                        filters["wc_CU_NO"]
                          ? filters["wc_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>        
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={{display:"none"}}>Service DealerNo </th>
                <th style={tableHeaderStyle}>Service Dealer </th>
                <th style={tableHeaderStyle}>Dealer Reference</th>
                <th style={tableHeaderStyle}>Current Status</th>
                <th style={{display:"none"}}>Accessor no</th>
                <th style={tableHeaderStyle}>Accessor</th>
                <th style={{display:"none"}}>Equipemnt No</th>
                <th style={tableHeaderStyle}>Equipemnt</th>
                <th style={{display:"none"}}>Item Number</th>
                <th style={tableHeaderStyle}>Item</th>
                <th style={tableHeaderStyle}>Lot / Serial No</th>
                <th style={tableHeaderStyle}>Product Model</th>
                <th style={tableHeaderStyle}>Product Family</th>
                <th style={{display:"none"}}>Customer Number</th> 
                <th style={tableHeaderStyle}>Customer</th>              
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.wcUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.wcUkNo}
                        checked={selectedRows.includes(rowData.wcUkNo)}
                        onChange={() => handleRowSelect(rowData.wcUkNo)}
                      />
                    </td>
                    <td style={{display:"none"}}>{rowData.wc_SR_DL}</td>
                    <td style={tableCellStyle}>{rowData.serviceDealerDES}</td>
                    <td style={tableCellStyle}>{rowData.wc_DL_RF}</td>
                    <td style={tableCellStyle}>{rowData.wc_ST_CD}</td>
                    <td style={{display:"none"}}>{rowData.wc_AS_OR}</td>
                    <td style={tableCellStyle}>{rowData.accessorDES}</td>
                    <td style={{display:"none"}}>{rowData.wc_EQ_NO}</td>
                    <td style={tableCellStyle}>{rowData.equipmentDES}</td>
                    <td style={{display:"none"}}>{rowData.wc_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>
                    <td style={tableCellStyle}>{rowData.wc_LT_NO}</td>
                    <td style={tableCellStyle}>{rowData.wc_PR_MD}</td> 
                    <td style={tableCellStyle}>{rowData.wc_PR_FM}</td>
                    <td style={{display:"none"}}>{rowData.wc_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.customer}</td>
                                      
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <WarrantyClaimDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        SelectedServiceDlr={SelectedServiceDlr}
        setSelectedServiceDlr={setSelectedServiceDlr}
        SelectedAccesor={SelectedAccesor}
        setSelectedAccesor={setSelectedAccesor}
        SelectedCustomer={SelectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        SelectedEquipment={SelectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        wc_ST_CD={wc_ST_CD}
        setwc_ST_CD={setwc_ST_CD}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
      />
    </div>
  );
};

export default WarrantyClaimMain;
