import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ItemPopup from "../Popups/ItemPopup";
export default function InspectionMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  InspectionMasterTableData,
  setInspectionMasterTableData,
  im_CO_CT,
  setim_CO_CT,
  im_TR_UM,
  setim_TR_UM,
  Selectedinspection,
  setSelectedinspection,
  SelectedPartsno,
  setSelectedPartsno,
  SelectedEquipment,
  setSelectedEquipment,
  onchangeDropDownFormData1,
}) {
  const {
    imUkNo,im_EQ_NO,
  } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const addRow = () => {
    setInspectionMasterTableData([...InspectionMasterTableData, {}]);
  };

  const deleteRow = () => {
    if (InspectionMasterTableData.length > 0) {
      const updatedData = [...InspectionMasterTableData];
      updatedData.pop();
      setInspectionMasterTableData(updatedData);
    }
  };
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  // dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [im_CO_CTOptions, setim_CO_CTOptions] = useState([]);
  const [im_TR_UMOptions, setim_TR_UMOptions] = useState([]);
  // status
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setim_TR_UM(value);
  };
  useEffect(() => {
    fetchDropdownCostCentre();
    fetchDropdownST();
  }, []);
  const fetchDropdownCostCentre = async () => {
    const Options = await fetchCostCenter();
    setim_CO_CTOptions(Options);
  };
  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setim_TR_UMOptions(Options);
  };
  //advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [EquipmentPopupVisible, setEquipmentPopupVisible] = useState(false);
  const [InspectionPopupVisible, setInspectionPopupVisible] = useState(false);
  const [PartsnoPopupVisible, setPartsnoPopupVisible] = useState(false);

  const openEquipmentPopup = () => {
    setEquipmentPopupVisible(true);
    setShowSearchIcon(false);
  };
  const openInspectionPopup = (e) => {
    setInspectionPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const openPartsnoPopup = (e) => {
    setPartsnoPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectEquipment = (data) => {
    setSelectedEquipment(data.em_EQ_DS);
    onchangeFormData("im_EQ_NO", data.em_EQ_NM);
    setEquipmentPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectInspection = async (data) => {
    document.getElementById(`im_IN_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`inspectionDES_${SelectedRowIndex}`).value = data.im_IT_DS;
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":data.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
    onchangeDropDownFormData2(`im_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setInspectionPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectPartsno = (data) => {
    document.getElementById(`im_PA_NO_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`partsDES_${SelectedRowIndex}`).value = data.im_IT_DS;
    setPartsnoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(imUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{imUkNo ? "Revise Inspection Master" : "Add Inspection Master"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="imUkNo" className="font-bold">
              uk_no
                </label>
                <InputText 
                  id="imUkNo"
                  name="imUkNo"
                  value={imUkNo}
                />
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="im_EQ_NO" className="font-bold">
                Equipment Name <span style={{color:'red'}}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText 
                  id="SelectedEquipment"
                  name="SelectedEquipment"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedEquipment}
                />
              {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>openEquipmentPopup()}
                ></i>
              )}
                </div>
                <InputText style={{display:'none'}}
                  id="im_EQ_NO"
                  name="im_EQ_NO"
                  value={im_EQ_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="im_CO_CT" className="font-bold">
                  Cost Centre
                </label>
                <Dropdown
                  id="im_CO_CT"
                  name="im_CO_CT"
                  value={im_CO_CT}
                  options={im_CO_CTOptions}
                  onChange={(e) => onchangeDropDownFormData1("im_CO_CT", e.value)}
                  placeholder="Select Cost Centre"
                />
              </div>             
            </div>
          </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="InspectionMasterTable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th>Inspection Name <span style={{color:"red"}}>*</span> </th>    
            <th>Description</th>
            <th>Parts Number <span style={{color:"red"}}>*</span> </th>
            <th>Description</th>    
            <th>Quantity</th>
            <th>UM</th>
            <th>Labour Hrs</th>
          </tr>
        </thead>
        <tbody>
        {InspectionMasterTableData.map((row, index) => (
          <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`imUkNo_${index}`}
                name={`imUkNo_${index}`}
                value={row.imUkNo}
              />
            </td>
            <td>
              <div style={{display:"flex"}}>
            <InputText 
              id={`im_IN_NM_${index}`}
              name={`im_IN_NM_${index}`}
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={row.im_IN_NM ? row.im_IN_NM : row[`${'im_IN_NM'}_${index}`]}
            />
            {showSearchIcon && (
            <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "7%",
                  cursor: "pointer",
                }}
                onClick={(e)=>openInspectionPopup(index)}
              ></i>
            )}
              </div>
            </td>
            <td>
            <InputText disabled
              id={`inspectionDES_${index}`}
              name={`inspectionDES_${index}`}
              value={row.inspectionDES}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <div style={{display:"flex"}}>
            <InputText 
              id={`im_PA_NO_${index}`}
              name={`im_PA_NO_${index}`}
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={row.im_PA_NO ? row.im_PA_NO : row[`${'im_PA_NO'}_${index}`]}
            />
            {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "7%",
                  cursor: "pointer",
                }}
                onClick={(e)=>openPartsnoPopup(index)}
              ></i>
            )}
            </div>
            </td>
            <td>
            <InputText disabled
              id={`partsDES_${index}`}
              name={`partsDES_${index}`}
              value={row.partsDES}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText 
              id={`im_QN_TY_${index}`}
              name={`im_QN_TY_${index}`}
              value={row.im_QN_TY}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <Dropdown
                id={`im_TR_UM_${index}`}
                name={`im_TR_UM_${index}`}
                value={data[`im_TR_UM_${index}`] ? data[`im_TR_UM_${index}`] : row.im_TR_UM}
                options={im_TR_UMOptions}
                onChange={(e) => onchangeDropDownFormData2(`im_TR_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
            </td>
            <td>
            <InputText 
              id={`im_LB_HR_${index}`}
              name={`im_LB_HR_${index}`}
              value={row.im_LB_HR}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            </tr>
            ))}
        </tbody>
      </table> 
    </div>
        </div>
      </div>
      <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentPopupVisible(false)}
        onDataSelect={handleSelectEquipment}
        formData={data}
      /> 
      <ItemPopup
        visible={InspectionPopupVisible}
        onClose={() => setInspectionPopupVisible(false)}
        onDataSelect={handleSelectInspection}
        formData={data}
      /> 
      <ItemPopup
        visible={PartsnoPopupVisible}
        onClose={() => setPartsnoPopupVisible(false)}
        onDataSelect={handleSelectPartsno}
        formData={data}
      />
    </Dialog>
  );
}
