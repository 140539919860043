import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function EmployeeBenefitsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  employeeBenefitstable,
  addRow,
  deleteRow,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  eb_CO_CT,
  eb_BE_GP,
  eb_PL_DS,
  eb_PL_OP,
  eb_PL_ST,
  er_AD_NO,
  eb_DE_PT,
  eb_YE_AR,
  SelectedemEMNO,
  setSelectedemEMNO,
}) {
  const { 
    eb_EM_NO,
    eb_EF_TO,
    eb_PY_DE,
    eb_EM_PA,
    ebUkNo,
    eb_HR_DT,
    eb_EF_FR,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);  
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };

  const [eb_CO_CTOptions, seteb_CO_CTOptions] = useState([]);
  const [eb_YE_AROptions, seteb_YE_AROptions] = useState([]);
  const [eb_BE_GPOptions, seteb_BE_GPOptions] = useState([]);
  const [eb_PL_DSOptions, seteb_PL_DSOptions] = useState([]);
  const [eb_PL_OPOptions, seteb_PL_OPOptions] = useState([]);
  const [eb_PL_STOptions, seteb_PL_STOptions] = useState([]);
  const [eb_EM_PAOptions, seteb_EM_PAOptions] = useState([]);
  const [eb_DE_PTOptions, seteb_DE_PTOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // dropdowns
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  // cost center
  // const parameter1 = "CC/TY";
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
// Benefit group
  const parameter2 = "BE/GP";
  const finalUrl2 = `${url}/${parameter2}`;
  // plan description
  const parameter3 = "PL/ID";
  const finalUrl3 = `${url}/${parameter3}`;
  // plan option
  const parameter4 = "PL/OP";
  const finalUrl4 = `${url}/${parameter4}`;
  // status
  const parameter5 = "PL/ST";
  const finalUrl5 = `${url}/${parameter5}`;
  // year
  const parameter6 = "YE/AR";
  const finalUrl6 = `${url}/${parameter6}`;
  // DEPARTMENT
  const parameter7 = "EM/DP";
  const finalUrl7 = `${url}/${parameter7}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
  }, []);

// 1st dropdowns
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      seteb_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      seteb_BE_GPOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    seteb_PL_DSOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    seteb_PL_OPOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    seteb_PL_STOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    seteb_YE_AROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 7th dropdown
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    seteb_DE_PTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    document.getElementById("er_AD_NO").value = data.abAdNo;
    const  Url = `${domain}/api/EmployeeMaster/GetByEmployee`
    const requestData = {
      "emEmNo": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.employeeDetails;
      if(data){
        onchangeDropDownFormData1("eb_CO_CT", data.em_CC_NM);
        onchangeDropDownFormData7("eb_DE_PT", data.em_DE_PT); 
        onchangeFormData("eb_HR_DT", data.em_HR_DT);
        // document.getElementById("eb_HR_DT").value = response.data.employeeDetails.em_HR_DT;
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // onchangeDropDownFormData1("eb_CO_CT", data.em_CC_NM);
    // onchangeDropDownFormData2("eb_DE_PT", data.em_DE_PT);
    // onchangeFormData("eb_HR_DT", data.em_HR_DT);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // endsearchpopup
  const isUpdateMode = Boolean(ebUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ebUkNo ? "Revise Employee Benefits" : "Add Employee Benefit"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
            <div style={{display:'none'}}>
              <label htmlFor="eb_EM_NO" className="font-bold">
              uk_no 
            </label>
            <InputText
              id="ebUkNo"
              name="ebUkNo"
              value={ebUkNo}
              onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_EM_NO" className="font-bold">
              Employee <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                  <InputText
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openEmployeePopup}
                  ></i>
                  )}
                </div>
                  <div style={{display:"none"}}>
                    <InputText
                      id="eb_EM_NO"
                      name="eb_EM_NO"
                      defaultValue={eb_EM_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_CO_CT" className="font-bold">
            Cost Center
              </label>
              <Dropdown disabled
                id="eb_CO_CT"
                name="eb_CO_CT"
                value={eb_CO_CT}
                options={eb_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("eb_CO_CT", e.value)}
                placeholder="Select Cost Center"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_DE_PT" className="font-bold">
                  Department
              </label> 
              <Dropdown disabled
                id="eb_DE_PT"
                name="eb_DE_PT"
                value={eb_DE_PT}
                options={eb_DE_PTOptions}
                onChange={(e) => onchangeDropDownFormData7("eb_DE_PT", e.value)}
                placeholder="Select Department"
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_HR_DT" className="font-bold">
              Hire Date
              </label>
              <Calendar disabled
                id="eb_HR_DT"
                name="eb_HR_DT"
                value={moment(data.eb_HR_DT, "YYYYMMDD").isValid() ? moment(data.eb_HR_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "eb_HR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_YE_AR" className="font-bold">
                Year
              </label>
              {/* <InputText
                id="eb_YE_AR"
                name="eb_YE_AR"
                value={eb_YE_AR}
                onChange={(e) => onchangedata(e)}
              /> */}
              <Dropdown
                id="eb_YE_AR"
                name="eb_YE_AR"
                value={eb_YE_AR}
                options={eb_YE_AROptions}
                onChange={(e) => onchangeDropDownFormData6("eb_YE_AR", e.value)}
                placeholder="Select Year"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="eb_BE_GP" className="font-bold">
              Benefit Group
              </label>
              <Dropdown
                name="eb_BE_GP"
                value={eb_BE_GP}
                options={eb_BE_GPOptions}
                onChange={(e) => onchangeDropDownFormData2("eb_BE_GP", e.value)}
                placeholder="Select Benefit Group"
              />
            </div>
          </div>
        <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
          {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="employeeBenefitstable">
            <thead>
              <tr>
                  <th style={{display:"none"}}>UK_NO</th>
                  <th>Plan Description</th>
                  <th>Plan Option</th>
                  <th>Status </th>
                  <th>Effective From</th>
                  <th>Effective to</th>
                  <th>Pay Deductions</th>
                  <th>Employer Paid</th>
              </tr>
            </thead>
            <tbody>
            {employeeBenefitstable.map((row, index) => (
                <tr key={index}>
              <td style={{display:"none"}}>
                <InputText
                  id={`ebUkNo_${index}`}
                  name={`ebUkNo_${index}`}
                  defaultValue={row.ebUkNo}
                />
              </td>
              <td>
                <Dropdown
                  id={`eb_PL_DS_${index}`}
                  name={`eb_PL_DS_${index}`}
                  value={data[`eb_PL_DS_${index}`] ? data[`eb_PL_DS_${index}`]:row.eb_PL_DS}
                  options={eb_PL_DSOptions}
                  onChange={(e) => onchangeDropDownFormData3(`eb_PL_DS_${index}`, e.value)}
                  placeholder="Select Plan Des."
                />
              </td>
              <td>
              <Dropdown
                  id={`eb_PL_OP_${index}`}
                  name={`eb_PL_OP_${index}`}
                  value={data[`eb_PL_OP_${index}`] ? data[`eb_PL_OP_${index}`]:row.eb_PL_OP}
                  options={eb_PL_OPOptions}
                  onChange={(e) => onchangeDropDownFormData4(`eb_PL_OP_${index}`, e.value)}
                  placeholder="Select Provider"
                />
              </td>
              <td>
              <Dropdown
                  id={`eb_PL_ST_${index}`}
                  name={`eb_PL_ST_${index}`}
                  value={data[`eb_PL_ST_${index}`] ? data[`eb_PL_ST_${index}`]:row.eb_PL_ST}
                  options={eb_PL_STOptions}
                  onChange={(e) => onchangeDropDownFormData5(`eb_PL_ST_${index}`, e.value)}
                  placeholder="Select Status"
                />
              </td>

              <td>
              <Calendar 
                id={`eb_EF_FR_${index}`}
                name={`eb_EF_FR_${index}`}
                value={ row.eb_EF_FR ? moment(row.eb_EF_FR, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "eb_EF_FR", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar
                id={`eb_EF_TO_${index}`}
                name={`eb_EF_TO_${index}`}
                value={ row.eb_EF_TO ? moment(row.eb_EF_TO, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "eb_EF_TO", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>

              <td>
              <InputText 
                id={`eb_PY_DE_${index}`}
                name={`eb_PY_DE_${index}`}
                defaultValue={row.eb_PY_DE}
                onChange={(e) => onchangedata(e)}
              /> 
              </td>
              <td>
              <InputText  disabled
                id={`eb_EM_PA_${index}`}
                name={`eb_EM_PA_${index}`}
                value={"Y"}
                onChange={(e) => onchangedata(e)}
              /> 
              </td>

                </tr>
            ))}
            </tbody>
          </table>  
        </div>  
        </div>   
    </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        formData={data}
      />
    </Dialog>
  );
}
