import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AddressDialog from "./AddressDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import "../../../components/ListStyle/ListStyle.css";
import EmailPopup from "./EmailPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  abAdNo: "",
  ab_CL_12: "",
  ab_AD_CT: "",
  ab_AD_CY: "",
  ab_AD_P1: "",
  ab_AD_NM: "",
  ab_AD_EM: "",
  ab_CL_11: "",
  ab_AC_IN: "",
  ab_AL_NO: "",
  ab_TX_ID: "",
  ab_AD_L3: "",
  ab_AD_L4: "",
  ab_AD_P2: "",
  ab_CL_01: "",
  ab_CL_02: "",
  ab_CL_03: "",
  ab_CL_04: "",
  ab_CL_05: "",
  ab_CL_06: "",
  ab_CL_07: "",
  ab_CL_08: "",
  ab_CL_09: "",
  ab_CL_10: "",
  ab_CL_14: "",
  ab_CL_15: "",
  ab_CL_16: "",
  ab_CL_17: "",
  ab_CL_18: "",
  ab_CL_19: "",
  ab_CL_20: "",
  ab_PT_NO: "",
  ab_AP_AR: "",
  ab_AD_ST:"",
  City:"",
  Country:"",
  Industry:"",
  Vertical:"",
  Status:""
};
const AddressMain = () => {
  const initialFilters = {
    abAdNo: { value: "", operation: "" },
    ab_CL_12: { value: "", operation: "" },
    ab_AD_CT: { value: "", operation: "" },
    ab_AD_CY: { value: "", operation: "" },
    ab_AD_P1: { value: "", operation: "" },
    ab_AD_NM: { value: "", operation: "" },
    ab_AD_EM: { value: "", operation: "" },
    ab_CL_11: { value: "", operation: "" },
    ab_AC_IN: { value: "", operation: "" },
  };
  // buttonstyles
  const { buttonStyles, addButtonStyles,ListDesc,copyButtonStyles,checkboxStyle,tableHeaderStyle,inputStyle,tableCellStyle } = ButtonStyles();
  const [nextNumType, setnextNumType] = useState("");
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [abAdNo, setAbAdNo] = useState("");
  const [ab_AD_CT, setab_AD_CT] = useState("");
  const [ab_AD_CY, setab_AD_CY] = useState("");
  const [ab_CL_12, setab_CL_12] = useState("");
  const [ab_CL_11, setab_CL_11] = useState("");
  const [ab_CL_13, setab_CL_13] = useState("");
  const [ab_CL_07, setab_CL_07] = useState("");
  const [ab_AD_TY, setab_AD_TY] = useState("");
  const [ab_AC_IN, setab_AC_IN] = useState("");
  const [ab_AD_NM, setab_AD_NM] = useState("");
  const [ab_CO_CT, setab_CO_CT] = useState("");
  const [ab_AD_ST, setab_AD_ST] = useState("");
  const [ab_CL_01, setab_CL_01] = useState("");
  const [ab_CL_02, setab_CL_02] = useState("");
  const [ab_CL_03, setab_CL_03] = useState("");
  const [ab_CL_04, setab_CL_04] = useState("");
  const [ab_CL_05, setab_CL_05] = useState("");
  const [ab_CL_06, setab_CL_06] = useState("");
  const [ab_CL_08, setab_CL_08] = useState("");
  const [ab_CL_09, setab_CL_09] = useState("");
  const [ab_CL_10, setab_CL_10] = useState("");
  const [ab_CL_14, setab_CL_14] = useState("");
  const [ab_CL_15, setab_CL_15] = useState("");
  const [ab_CL_16, setab_CL_16] = useState("");
  const [ab_CL_17, setab_CL_17] = useState("");
  const [ab_CL_18, setab_CL_18] = useState("");
  const [ab_CL_19, setab_CL_19] = useState("");
  const [ab_CL_20, setab_CL_20] = useState("");
  const [ab_AP_AR, setab_AP_AR] = useState("");
  const [SelectedParent, setSelectedParent] = useState("");
  const [ab_AD_CTOptions, setab_AD_CTOptions] = useState([]);
  const [ab_AD_CYOptions, setab_AD_CYOptions] = useState([]);
  const [ab_CL_12Options, setab_CL_12Options] = useState([]);
  const [ab_CL_11Options, setab_CL_11Options] = useState([]);
  const [ab_AC_INOptions, setab_AC_INOptions] = useState([]);

  const [City, setCity] = useState("");
  const [Country, setCountry] = useState("");
  const [Status, setStatus] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Vertical, setVertical] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [ShowEmailPopup, setShowEmailPopup] = useState(false);
  const [ei_HO_ST, setei_HO_ST]= useState("");
  const [ei_PO_RT, setei_PO_RT]= useState("");
  const [ei_PS_WD, setei_PS_WD]= useState("");
  const [ei_AD_NO, setei_AD_NO]= useState("");
  const [av_FR_ML, setav_FR_ML]= useState("");
  const [ei_UK_NO, setei_UK_NO] = useState("");
  const [name, setname]= useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url11 = `${domain}/api/ClassCode/All`;

  const parameter = "FO/CI";
  const finalUrl = `${url11}/${parameter}`;

  const parameter1 = "FO/CY";
  const finalUrl1 = `${url11}/${parameter1}`;

  const parameter2 = "AB/12";
  const finalUrl2 = `${url11}/${parameter2}`;

  const parameter3 = "AB/11";
  const finalUrl3 = `${url11}/${parameter3}`;

  const parameter7 = "AB/ST";
  const finalUrl7 = `${url11}/${parameter7}`;

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_AD_CTOptions([{ label: 'N/A', value: 'N/A' }, ...options]);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_AD_CYOptions([{ label: 'N/A', value: 'N/A' }, ...options]);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_CL_12Options([{ label: 'N/A', value: 'N/A' }, ...options]);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_CL_11Options([{ label: 'N/A', value: 'N/A' }, ...options]);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_AC_INOptions([{ label: 'N/A', value: 'N/A' }, ...options]);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const updateFilters = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ab_AD_CT: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ab_AD_CY: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ab_CL_11: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const updateFilters3 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ab_CL_12: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const updateFilters4 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ab_AC_IN: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'abAdNo') {
          constructedColumn = 'abAdNo';
        } else {
          constructedColumn = column.toUpperCase();
        }
        return {
        column: constructedColumn,
        value: filters[column].value,
        operation: filters[column].operation,
      };
    });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setab_AD_CT(null);
    setab_AD_CY(null);
    setab_CL_12(null);
    setab_CL_11(null);
    setab_CL_13(null);
    setab_CL_07(null);
    setab_CL_01(null);
    setab_CL_02(null);
    setab_CL_03(null);
    setab_CL_04(null);
    setab_CL_05(null);
    setab_AD_ST(null);
    setab_CL_06(null);
    setab_CL_08(null);
    setab_CL_09(null);
    setab_CL_10(null);
    setab_CL_14(null);
    setab_CL_15(null);
    setab_CL_16(null);
    setab_CL_17(null);
    setab_CL_18(null);
    setab_CL_19(null);
    setab_CL_20(null);
    setab_AP_AR(null);
    setab_CO_CT(null);
    setSelectedParent(null);
    setOpen(true);
  };
  const onchangeDropDown = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData((prevData) => {
      const newFormData = { ...prevData, [name]: value };
      if (value === 'N/A') {
        newFormData[name] = ''; 
      }
      return newFormData;
    });
    setCity(value);
    updateFilters(value);
  };
  const onchangeDropDown1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if (value === 'N/A') {
      setCountry('');
    } else {
      setCountry(value);
    }
    setCountry(value);
    updateFilters1(value);
  };
  const onchangeDropDown2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if (value === 'N/A') {
      setIndustry('');
    } else {
      setIndustry(value);
    }
    // setIndustry(value);
    updateFilters2(value);
  };
  const onchangeDropDown3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if (value === 'N/A') {
      setVertical('');
    } else {
      setVertical(value);
    }
    // setVertical(value);
    updateFilters3(value);
  };
  const onchangeDropDown4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if (value === 'N/A') {
      setStatus('');
    } else {
      setStatus(value);
    }
    // setStatus(value);
    updateFilters4(value);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AddressBook/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const AdressBookData = response.data;
          delete AdressBookData.ab_UK_NO;
          delete AdressBookData.abAdNo;
          setFormData(AdressBookData);
          setab_AD_CT(response.data.ab_AD_CT);
          setab_AD_CY(response.data.ab_AD_CY);
          setab_CL_12(response.data.ab_CL_12);
          setab_CL_11(response.data.ab_CL_11);
          setab_CL_13(response.data.ab_CL_13);
          setab_CL_07(response.data.ab_CL_07);
          setab_AD_NM(response.data.ab_AD_NM);
          setab_AD_TY(response.data.ab_AD_TY);
          setab_AC_IN(response.data.ab_AC_IN);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions7();
  }, [currentPage, pageSize, filters]);

  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AD_TY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AC_IN(value);
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "AB_AC_IN"){
              onchangeDropDownFormData7("ab_AC_IN",dataDefault.versionIds[i].default_VALUE);
              setab_AC_IN(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "AB_AD_TY") {
              onchangeDropDownFormData6("ab_AD_TY",dataDefault.versionIds[i].default_VALUE);
              setab_AD_TY(dataDefault.versionIds[i].default_VALUE);
              setnextNumType(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/AddressBook/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const onchangedata = (Event) => {
  //   const { value, name } = Event.target;
  //   setFormData({ ...FormData, [name]: value });
  // };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    //setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AddressBook/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const AdressBookData = response.data;
        setFormData(AdressBookData);
        setab_AD_CT(response.data.ab_AD_CT);
        setab_AD_CY(response.data.ab_AD_CY);
        setab_CL_12(response.data.ab_CL_12);
        setab_CL_11(response.data.ab_CL_11);
        setab_CL_13(response.data.ab_CL_13);
        setab_CL_07(response.data.ab_CL_07);
        setab_AD_NM(response.data.ab_AD_NM);
        setab_AD_TY(response.data.ab_AD_TY);
        setab_AC_IN(response.data.ab_AC_IN);
        setab_CL_01(response.data.ab_CL_01);
        setab_CL_02(response.data.ab_CL_02);
        setab_CL_03(response.data.ab_CL_03);
        setab_CL_04(response.data.ab_CL_04);
        setab_CL_05(response.data.ab_CL_05);
        setab_CL_06(response.data.ab_CL_06);
        setab_CL_08(response.data.ab_CL_08);
        setab_CL_09(response.data.ab_CL_09);
        setab_CL_10(response.data.ab_CL_10);
        setab_CL_14(response.data.ab_CL_14);
        setab_CL_15(response.data.ab_CL_15);
        setab_CL_16(response.data.ab_CL_16);
        setab_CL_17(response.data.ab_CL_17);
        setab_CL_18(response.data.ab_CL_18);
        setab_CL_19(response.data.ab_CL_19);
        setab_CL_20(response.data.ab_CL_20);
        setab_AD_ST(response.data.ab_AD_ST);
        setSelectedParent(response.data.name);
        setab_CO_CT(response.data.ab_CO_CT);
        setAbAdNo(response.data.abAdNo);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const openEmailPopup = () => {
    var flag = 'E';
    handleOpen(flag);
  };
  const handleOpen = async (flag) => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    try {
      const response = await axios.get(
        `${domain}/api/AddressBook/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        setei_AD_NO(response.data.abAdNo);
        setname(response.data.ab_AD_NM);
        setav_FR_ML(response.data.ab_AD_EM);
        if(response.data.ab_AD_EM){
          if(response.data.ab_AD_EM.includes("gmail")){
            setei_HO_ST("smtp.gmail.com");
          } else if (response.data.ab_AD_EM.includes("yahoo")){
            setei_HO_ST("smtp.mail.yahoo.com");
          } else if (response.data.ab_AD_EM.includes("outlook")){
            setei_HO_ST("smtp.mail.outlook.com");
          }
        }

      if( flag === 'E') {
        const emailInput = {
          "ei_AD_NO" : response.data.abAdNo
        }
        try {
          const responseEmail = await axios.post(
            `${domain}/api/Email/GetByAddressNo`, emailInput,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );
      
          if (responseEmail.status === 200) {
            if(responseEmail.data.ei_UK_NO){
              setei_HO_ST(responseEmail.data.ei_HO_ST);
              setei_PO_RT(responseEmail.data.ei_PO_RT);
              setei_PS_WD(responseEmail.data.ei_PS_WD);
              setei_UK_NO(responseEmail.data.ei_UK_NO);
            } else {
              setei_PO_RT("587");
              setei_PS_WD("");
              setei_UK_NO("");
            }
          
            setShowEmailPopup(true);
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const searchButtonStyles = {
    backgroundColor: "#3d33d9a6",
    color: "white",
    lineHeight: "10%",
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div style={{ background: "red !important", color: "white", fontSize: "10px",}} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div  style={{ background: "white !important",  color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }} >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
        <Tooltip target=".search-button" position="bottom" mouseTrack>
        <div style={{ backgroundColor: "transparent !important", color: "white", fontSize: "10px" }}>
          Email
        </div>
      </Tooltip>
      <Button
        style={searchButtonStyles}
        icon="pi pi-envelope"
        outlined
        className="mr-2 search-button"
        onClick={openEmailPopup}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width: "0%",
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width: "100%",
    backgroundColor: "#f5f4f4",
  };
  const inputStyleCntry = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width: "120px",
    backgroundColor: "#f5f4f4",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"  }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const handleClearButton = () => {
    setCity(null);
    setCountry(null);
    setStatus(null);
    setIndustry(null);
    setVertical(null);
    setFilters(initialFilters);
  };
  const rightClear = (
    // <React.Fragment>
    //   <Button
    //     style={{ height: "35px" }}
    //     icon="pi pi-times"
    //     className="p-button-help"
    //     link
    //     onClick={handleClearButton}
    //   />
    // </React.Fragment>
    <React.Fragment>
    <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
</Tooltip>

  <Button
  style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
    icon="pi pi-times"
    className="mr-2 clear-button"
    link
    onClick={handleClearButton}
  />
  </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2"   >
        <div className="dropdown-container"> 
          <label htmlFor="Status" className="font-bold">
            City
          </label>
          <Dropdown style={{ width: "10rem",height:'2rem' }} className="custom-dropdown"
            id="City"
            name="City"
            value={City}
            options={ab_AD_CTOptions}
            onChange={(e) => onchangeDropDown("City", e.value)}
            placeholder="Select City"
          />
        </div>
      </div>
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
        <label htmlFor="ab_AD_CY" className="font-bold">
          Country
        </label>
        <Dropdown style={{ width: "10rem",height:'2rem' }} className="custom-dropdown"
          id="Country"
          name="Country"
          value={Country}
          options={ab_AD_CYOptions}
          onChange={(e) => onchangeDropDown1("Country", e.value)}
          placeholder="Select Country"
        />
      </div>
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
        <label htmlFor="ab_CL_11" className="font-bold">
          Industry
        </label>
        <Dropdown style={{ width: "10rem",height:'2rem' }} className="custom-dropdown"
          id="Industry"
          name="Industry"
          value={Industry}
          options={ab_CL_11Options}
          onChange={(e) => onchangeDropDown2("Industry", e.value)}
          placeholder="Select Industry"
        />
      </div>
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
        <label htmlFor="Vertical" className="font-bold">
          Vertical
        </label>
        <Dropdown style={{ width: "10rem" , height:'2rem' }} className="custom-dropdown"
          id="Vertical"
          name="Vertical"
          value={Vertical}
          options={ab_CL_12Options}
          onChange={(e) => onchangeDropDown3("Vertical", e.value)}
          placeholder="Select Vertical"
        />
      </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
          <label htmlFor="ab_CL_12" className="font-bold">
            Status
          </label>
          <Dropdown style={{ width: "10rem", height:'2rem' }} className="custom-dropdown"
            id="Status"
            name="Status"
            value={Status}
            options={ab_AC_INOptions}
            onChange={(e) => onchangeDropDown4("Status", e.value)}
            placeholder="Select Status"
          />
        </div>

      {/* Clear button aligned in the last column */}
    <div className="field col-lg-2 col-md-6 col-12 pe-md-2 d-flex justify-content-end align-items-center">
      <Tooltip target=".clear-button" position="bottom" mouseTrack>
        <div>Clear Filter</div>
      </Tooltip>

      <Button
        style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
        icon="pi pi-times"
        className="mr-2 clear-button"
        link
        onClick={handleClearButton}
      />
    </div>

    </div>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%" }}>
          <Toolbar className="p-mb-4" left={AdvanceSearch}/>
          {/* right={rightClear}  */}
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse",  width: "100%", marginTop: "20px" }}>
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["abAdNo"] ? filters["abAdNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "abAdNo",
                        e.target.value,
                        filters["abAdNo"]
                          ? filters["abAdNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"]
                          ? filters["ab_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_P1"] ? filters["ab_AD_P1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_P1",
                        e.target.value,
                        filters["ab_AD_P1"]
                          ? filters["ab_AD_P1"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_EM"] ? filters["ab_AD_EM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_EM",
                        e.target.value,
                        filters["ab_AD_EM"]
                          ? filters["ab_AD_EM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    readOnly
                    type="text"
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    readOnly
                    type="text"
                    style={inputStyleCntry}
                  />
                </td>
                <td>
                  <input
                    readOnly
                    width="100%"
                    type="text"
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    readOnly
                    type="text"
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    readOnly
                    type="text"
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle1}></th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>City</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Industry</th>
                <th style={tableHeaderStyle}>Vertical</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ab_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.ab_UK_NO}
                        checked={selectedRows.includes(rowData.ab_UK_NO)}
                        onChange={() => handleRowSelect(rowData.ab_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.abAdNo}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_P1}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_EM}</td>
                    <td style={tableCellStyle}>{rowData.cl_AD_CT}</td>
                    <td style={tableCellStyle}>{rowData.cl_AD_CY}</td>
                    <td style={tableCellStyle}>{rowData.cl_CL_11}</td>
                    <td style={tableCellStyle}>{rowData.cl_CL_12}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <EmailPopup
        ei_UK_NO={ei_UK_NO}
        name = {name}
        ei_AD_NO = {ei_AD_NO}
        ei_PS_WD = {ei_PS_WD}
        ei_PO_RT = {ei_PO_RT}
        ei_HO_ST ={ei_HO_ST}
        av_FR_ML ={av_FR_ML}
        visible = {ShowEmailPopup}
        onClose = {() => setShowEmailPopup(false)}
      />
      <AddressDialog
        open={open}
        //handleClose={handleClose}
        data={FormData}
        onchangedata={onchangedata}
        handleSearch={handleSearch}
        ab_AD_CT={ab_AD_CT}
        ab_AD_CY={ab_AD_CY}
        ab_CL_12={ab_CL_12}
        ab_CL_11={ab_CL_11}
        ab_CL_13={ab_CL_13}
        ab_CL_07={ab_CL_07}
        ab_AD_TY={ab_AD_TY}
        ab_AC_IN={ab_AC_IN}
        abAdNo={abAdNo}
        ab_AD_NM = {ab_AD_NM}
        setOpen={setOpen}
        initialValue={initialValue}
        setab_AD_CT={setab_AD_CT}
        setab_AD_CY={setab_AD_CY}
        setab_CL_12={setab_CL_12}
        setab_CL_11={setab_CL_11}
        setab_CL_13={setab_CL_13}
        setab_CL_07={setab_CL_07}
        setab_AD_TY={setab_AD_TY}
        setab_AC_IN={setab_AC_IN}
        setAbAdNo={setAbAdNo}
        setab_AD_NM= {setab_AD_NM}
        FormData={FormData}
        setFormData={setFormData}
        handleDefault={handleDefault}
        nextNumType={nextNumType}
        setnextNumType={setnextNumType}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        ab_CO_CT={ab_CO_CT}
        setab_CO_CT={setab_CO_CT}
        ab_CL_01={ab_CL_01}
        setab_CL_01={setab_CL_01}
        ab_CL_02={ab_CL_02}
        setab_CL_02={setab_CL_02}
        ab_CL_03={ab_CL_03}
        setab_CL_03={setab_CL_03}
        ab_CL_04={ab_CL_04}
        setab_CL_04={setab_CL_04}
        ab_CL_05={ab_CL_05}
        setab_CL_05={setab_CL_05}
        ab_AD_ST={ab_AD_ST}
        setab_AD_ST={setab_AD_ST}
        ab_CL_06={ab_CL_06}
        setab_CL_06={setab_CL_06}
        ab_CL_08={ab_CL_08}
        setab_CL_08={setab_CL_08}
        ab_CL_09={ab_CL_09}
        setab_CL_09={setab_CL_09}
        ab_CL_10={ab_CL_10}
        setab_CL_10={setab_CL_10}
        ab_CL_14={ab_CL_14}
        setab_CL_14={setab_CL_14}
        ab_CL_15={ab_CL_15}
        setab_CL_15={setab_CL_15}
        ab_CL_16={ab_CL_16}
        setab_CL_16={setab_CL_16}
        ab_CL_17={ab_CL_17}
        setab_CL_17={setab_CL_17}
        ab_CL_18={ab_CL_18}
        setab_CL_18={setab_CL_18}
        ab_CL_19={ab_CL_19}
        setab_CL_19={setab_CL_19}
        ab_CL_20={ab_CL_20}
        setab_CL_20={setab_CL_20}
        ab_AP_AR={ab_AP_AR}
        setab_AP_AR={setab_AP_AR}
        SelectedParent={SelectedParent}
        setSelectedParent={setSelectedParent}
      />
    </div>
  );
}
export default AddressMain;
