import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
export default function RentalItemDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  ri_LO_CN,
  setri_LO_CN,
  ri_CO_CT,
  setri_CO_CT,
  ri_BL_CY,
  setri_BL_CY,
  ri_IT_ST,
  setri_IT_ST,
  ri_DP_TY,
  setri_DP_TY,
  ri_CO_NO,
  CostCentreDropDown,
  ItemStatusDropdown
}) {
  const {
    riUkNo,
    ri_IT_NO,
    ri_SR_NO,
    ri_ST_DT,
    ri_EN_DT,
    ri_DP_DT,
    ri_DP_RE,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  
  const [ri_LO_CNOptions, setri_LO_CNOptions] = useState([]);
  const [ri_CO_CTOptions, setri_CO_CTOptions] = useState([]);
  const [ri_BL_CYOptions, setri_BL_CYOptions] = useState([]);
  const [ri_IT_STOptions, setri_IT_STOptions] = useState([]);
  const [ri_DP_TYOptions, setri_DP_TYOptions] = useState([]);
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setri_LO_CN(value);
  };
  const BillingCycleDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setri_BL_CY(value);
  };
  const DisposableTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setri_DP_TY(value);
  };
  useEffect(() => {
    Location();
    CostCentre();
    BillingCycle();
    ItemStatus();
    DisposableType();
  }, []);
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setri_LO_CNOptions(Options);
  };
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setri_CO_CTOptions(Options);
  };
  const BillingCycle = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setri_BL_CYOptions(Options);
  };
  const ItemStatus = async () => {
    const Options = await fetchClassCodeDS("IT/ST");
    setri_IT_STOptions(Options);
  };
  const DisposableType = async () => {
    const Options = await fetchClassCodeDS("DS/TY");
    setri_DP_TYOptions(Options);
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("ri_IT_NO", data.im_IT_NM);
    onchangeFormData("item_DES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openEquipmentPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(riUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{riUkNo ? "Revise Rental Item" : "Add Rental Item"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="riUkNo" className="font-bold">
                      UK_NO
                    </label>
                    <InputText disabled
                      id="riUkNo"
                      name="riUkNo"
                      value={riUkNo}
                    />
                  </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ri_IT_NO" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="ri_IT_NO"
                      name="ri_IT_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEquipmentPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="item_DES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="item_DES"
                    name="item_DES"
                    value={SelectedDesc}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="ri_CO_NO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="ri_CO_NO"
                  name="ri_CO_NO"
                  value={ri_CO_NO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ri_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="ri_CO_CT"
                    value={ri_CO_CT}
                    options={ri_CO_CTOptions}
                    onChange={(e) => CostCentreDropDown("ri_CO_CT", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ri_LO_CN" className="font-bold">
                     Location
                    </label>
                    <Dropdown
                    name="ri_LO_CN"
                    value={ri_LO_CN}
                    options={ri_LO_CNOptions}
                    onChange={(e) => LocationDropdown("ri_LO_CN", e.value)}
                    placeholder="Select Location"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ri_SR_NO" className="font-bold">
                    Serial No
                    </label>
                    <InputText 
                      id="ri_SR_NO"
                      name="ri_SR_NO"
                      value={ri_SR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ri_BL_CY" className="font-bold">
                     Billing Cycle
                    </label>
                    <Dropdown
                    name="ri_BL_CY"
                    value={ri_BL_CY}
                    options={ri_BL_CYOptions}
                    onChange={(e) => BillingCycleDropdown("ri_BL_CY", e.value)}
                    placeholder="Select Billing Cycle"
                  />
                  </div>

                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ri_IT_ST" className="font-bold">
                    Item Status
                    </label>
                    <Dropdown
                    name="ri_IT_ST"
                    value={ri_IT_ST}
                    options={ri_IT_STOptions}
                    onChange={(e) => ItemStatusDropdown("ri_IT_ST", e.value)}
                    placeholder="Select  Item Status"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ri_ST_DT" className="font-bold">
                    Rental Start Date
                  </label>
                    <Calendar
                    id="ri_ST_DT"
                    name="ri_ST_DT"
                    value={moment(data.ri_ST_DT, "YYYYMMDD").isValid() ? moment(data.ri_ST_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ri_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ri_EN_DT" className="font-bold">
                  Rental End Date
                  </label>
                  <Calendar
                    id="ri_EN_DT"
                    name="ri_EN_DT"
                    value={moment(data.ri_EN_DT, "YYYYMMDD").isValid() ? moment(data.ri_EN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ri_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ri_DP_TY" className="font-bold">
                    Disposition Type
                    </label>
                    <Dropdown
                    name="ri_DP_TY"
                    value={ri_DP_TY}
                    options={ri_DP_TYOptions}
                    onChange={(e) => DisposableTypeDropdown("ri_DP_TY", e.value)}
                    placeholder="Select   Disposition Type"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ri_DP_DT" className="font-bold">
                  Disposition Date
                  </label>     
                  <Calendar
                    id="ri_DP_DT"
                    name="ri_DP_DT"
                    value={moment(data.ri_DP_DT, "YYYYMMDD").isValid() ? moment(data.ri_DP_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "ri_DP_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ri_DP_RE" className="font-bold">
                Disposition Reason
                </label>
                <InputText 
                id="ri_DP_RE"
                name="ri_DP_RE"
                value={ri_DP_RE}
                onChange={(e) => onchangedata(e)}
                />
              </div>

                 
                </div>
            </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
