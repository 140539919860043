import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ShipmentStatusDialog from "./ShipmentStatusDialog";
import "jspdf-autotable";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  ssUkNo:"",
  //ch_ST_DT: moment(moment().toDate()).format("YYYYMMDD"),
  ssUkNo:"",
  ss_SH_DE:"",
  ss_SH_NO:"",
  ss_OG_NO:"",
  ss_SH_TO:"",
  shipto_NAME:"",
  ss_SL_TO:"",
  sold_NAME:"",
  ss_RT_CD:"",
  ss_CA_NO:"",
  ss_MO_TR:"",
  ss_AC_SD:"",
  ss_PR_DD:"",
  ss_ST_CD:"",
  ss_PR_SD:moment(moment().toDate()).format("YYYYMMDD"),
  ss_PR_DD:moment(moment().toDate()).format("YYYYMMDD"),
  ss_AC_SD:moment(moment().toDate()).format("YYYYMMDD"),
  ss_AC_DD:moment(moment().toDate()).format("YYYYMMDD"),
  ss_PK_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ss_AC_DT:"",
  SS_PK_TM:"",
  ss_AC_ST:"",
  ss_PR_DT:"",
  ss_PR_ST:"",
};

const initialFilters = {
  ss_SH_DE: { value: "", operation: "" },
  ss_SH_NO: { value: "", operation: "" },
  ss_OG_NO: { value: "", operation: "" },
  ss_SH_TO: { value: "", operation: "" },
  shipto_NAME: { value: "", operation: "" },
  ss_SL_TO: { value: "", operation: "" },
  sold_NAME: { value: "", operation: "" },
  ss_RT_CD: { value: "", operation: "" },
  ss_CA_NO: { value: "", operation: "" },
  ss_MO_TR: { value: "", operation: "" },
  ss_AC_SD: { value: "", operation: "" },
  ss_PR_DD: { value: "", operation: "" },
  ss_ST_CD: { value: "", operation: "" },
};

const ShipmentStatusMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,GenerateStyles,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [ch_CO_NO, setch_CO_NO] = useState("");
  const [ch_CO_CO, setch_CO_CO] = useState("");
  const [ch_SC_TY, setch_SC_TY] = useState("");
  const [ch_BS_CR, setch_BS_CR] = useState("");

  const [ss_SH_DE, setss_SH_DE] = useState("");
  const [ss_OG_NO, setss_OG_NO] = useState("");
  const [ss_SH_TY, setss_SH_TY] = useState("");
  const [ss_ST_CD, setss_ST_CD] = useState("");
  const [ss_CI_TY, setss_CI_TY] = useState("");
  const [ss_ST_AT, setss_ST_AT] = useState("");
  const [ss_ZO_NO, setss_ZO_NO] = useState("");
  const [ss_RT_CD, setss_RT_CD] = useState("");
  const [ss_CT_RY, setss_CT_RY] = useState("");
  const [ss_MO_TR, setss_MO_TR] = useState("");
  const [ss_FR_HN, setss_FR_HN] = useState("");
  const [ss_WT_UM, setss_WT_UM] = useState("");
  const [ss_VL_UM, setss_VL_UM] = useState("");


  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [customer, setCustomer] = useState("");
  const [Status, setStatus] = useState("");
  const [FDate, setFDate] = useState("");
  const [EDate, setEDate] = useState("");
  const [so_OP_STOptions, setso_OP_STOptions] = useState([]);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [ShipmentStatusData, setShipmentStatusData] = useState([{}]);

  const [SelectedSold, setSelectedSold] = useState("");
  const [SelectedShip, setSelectedShip] = useState("");

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
    if (cus !== null) {
      updatedFilters = {
        ...updatedFilters,
        la_AC_OW: {
          value: cus,
          operation: "EQUAL",
        },
      };
    }
    setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        la_ST_CD: {
          value: sts,
          operation: "LIKE",
        },
      };
    }
    setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setShipmentStatusData([{}]);
    setSelectedSold(null);
    setSelectedShip(null);
    setss_SH_DE(null);
    setss_OG_NO(null);
    setss_CI_TY(null);
    setss_ST_AT(null);
    setss_RT_CD(null);
    setss_ZO_NO(null);
    setss_CT_RY(null);
    setss_MO_TR(null);
    setss_FR_HN(null);
    setss_WT_UM(null);
    setss_VL_UM(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ShipmentStatus/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const ShipmentStatusData = response.data;
          delete ShipmentStatusData.ssUkNo;
         // setShipmentStatusData(ShipmentStatusData.serviceContracts);
          setFormData(ShipmentStatusData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters1(value);
    console.log(value);
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {

    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setch_CO_CO(`${company}`);
            if(fName === "CH_SC_TY"){
              setch_SC_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CH_BS_CR"){
              setch_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
           
           
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ShipmentStatus/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value, index) => {
    setFormData({ ...FormData, [name]: value });
    const fieldName=(FormData && FormData.ssUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    ShipmentStatusData[index][fieldName]=value;
  };
  const OnChangeShipmentStatusData = (key, value, index) => {
    const updatedData = [...ShipmentStatusData];
    updatedData[index][key] = value;
    setShipmentStatusData(updatedData);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ShipmentStatus/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ShipmentStatusData = response.data;
        //setShipmentStatusData(ShipmentStatusData.serviceContracts);
        setFormData(ShipmentStatusData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      ssUkNo : document.getElementById("ssUkNo").value,
      ss_SH_DE : ss_SH_DE,
      ss_OG_NO : ss_OG_NO,
      ss_SH_NO : document.getElementById("ss_SH_NO").value,
      ss_SH_TY : ss_SH_TY,
      ss_SH_CO : document.getElementById("ss_SH_CO").value,
      ss_ST_CD : ss_ST_CD,
      ss_SH_TO : document.getElementById("ss_SH_TO").value,
      ss_CI_TY : ss_CI_TY,
      ss_SL_TO : document.getElementById("ss_SL_TO").value,
      ss_ST_AT : ss_ST_AT,
      ss_RT_CD : ss_RT_CD,
      SS_PO_CD : document.getElementById("SS_PO_CD").value,
      ss_ZO_NO : ss_ZO_NO,
      ss_CT_RY : ss_CT_RY,
      ss_MO_TR : ss_MO_TR,
      ss_CA_NO : document.getElementById("ss_CA_NO").value,
      ss_FR_HN : ss_FR_HN,
      ss_NO_PS : document.getElementById("ss_NO_PS").value,
      ss_SC_WT : document.getElementById("ss_SC_WT").value,
      ss_WT_UM : ss_WT_UM,
      ss_NO_CN : document.getElementById("ss_NO_CN").value,
      ss_SC_VL : document.getElementById("ss_SC_VL").value,
      ss_VL_UM : ss_VL_UM,
      ss_PR_SD : FormData.ss_PR_SD,
      ss_PR_ST : FormData.ss_PR_ST,//document.getElementById("ss_PR_ST").value,
      ss_PR_DD : FormData.ss_PR_DD,
      ss_PR_DT : FormData.ss_PR_DT,//document.getElementById("ss_PR_DT").value,
      ss_AC_SD : FormData.ss_AC_SD,
      ss_AC_ST : FormData.ss_AC_ST,//document.getElementById("ss_AC_ST").value,
      ss_AC_DD : FormData.ss_AC_DD,
      ss_AC_DT : FormData.ss_AC_DT,//document.getElementById("ss_AC_DT").value,
      ss_PK_DT : FormData.ss_PK_DT,
      SS_PK_TM : FormData.SS_PK_TM,//document.getElementById("SS_PK_TM").value,
    };
  };
  const handleFormSubmit = () => {
    values();
 
    if (document.getElementById("ssUkNo").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ShipmentStatus/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ShipmentStatus/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div style={{ background: "red !important", color: "white", fontSize: "10px",  }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div style={{  background: "white !important",  color: "white", fontSize: "10px", }}  >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div style={{ background: "white !important",  color: "white", fontSize: "10px",  }} >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>

    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid" id="servicecol">
      <div className="field col">
        <label htmlFor="customer" className="font-bold">
          Customer
        </label>
        <div style={{ display: "flex" }}>
          <InputText
          style={{display:"none"}}
            id="customer"
            name="customer"
            value={customer}
          />
          <InputText
              id="ab_AD_NM"
              name="ab_AD_NM"
              readOnly
              value={selectedcustmer}
              style={{backgroundColor: "#ffffff" }}
              onChange={(Event) => onchangedata(Event)}
            />
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openCustomerPopup}
          ></i>
        </div>
      </div>
      <div className="field col">
        <label htmlFor="sl_TR_DT" className="font-bold">
          From Date
        </label>
        <Calendar
          id="FDate"
          name="FDate"
          value={moment(FDate, "YYYYMMDD").toDate()}
          onChange={(e) =>
            onchangedata({
              target: {
                name: "FDate",
                value: moment(e.value).format("YYYYMMDD"),
              },
            })
          }
          showIcon
        />
      </div>
      <div className="field col">
        <label htmlFor="sl_TR_DT" className="font-bold">
          To Date
        </label>
        <Calendar
          id="EDate"
          name="EDate"
          value={moment(EDate, "YYYYMMDD").toDate()}
          onChange={(e) =>
            onchangedata({
              target: {
                name: "EDate",
                value: moment(e.value).format("YYYYMMDD"),
              },
            })
          }
          showIcon
        />
      </div>
      <div className="field col" style={{display:"grid"}} >
        <label htmlFor="Status" className="font-bold">
          Status
        </label>
        <Dropdown
          style={{ width: "140px" }}
          id="Status"
          name="Status"
          value={Status}
          options={so_OP_STOptions}
          onChange={(e) => onchangeDropDown("Status", e.value)}
          placeholder="Select Status"
        />
      </div>
    </div>
  );
  const handleClearButton = () => {
    setStatus(null);
    setSelectedcustmer("");
    setCustomer("");
    setFilters(initialFilters);
  };
  const rightClear = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-times"
        className="p-button-help"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        {/* <div style={{ marginTop: "2%" }}>
          <Toolbar className="p-mb-4" left={AdvanceSearch} right={rightClear} />
        </div> */}
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table  style={{ borderCollapse: "collapse", width: "100%",  marginTop: "20px", }} >
            <thead>
            <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_SH_DE"] ? filters["ss_SH_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_SH_DE",
                        e.target.value,
                        filters["ss_SH_DE"]
                          ? filters["ss_SH_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_SH_NO"] ? filters["ss_SH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_SH_NO",
                        e.target.value,
                        filters["ss_SH_NO"] ? filters["ss_SH_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_OG_NO"] ? filters["ss_OG_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_OG_NO",
                        e.target.value,
                        filters["ss_OG_NO"]
                          ? filters["ss_OG_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_SH_TO"] ? filters["ss_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_SH_TO",
                        e.target.value,
                        filters["ss_SH_TO"]
                          ? filters["ss_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["shipto_NAME"] ? filters["shipto_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "shipto_NAME",
                        e.target.value,
                        filters["shipto_NAME"]
                          ? filters["shipto_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_SL_TO"] ? filters["ss_SL_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_SL_TO",
                        e.target.value,
                        filters["ss_SL_TO"]
                          ? filters["ss_SL_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sold_NAME"] ? filters["sold_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sold_NAME",
                        e.target.value,
                        filters["sold_NAME"] ? filters["sold_NAME"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_RT_CD"] ? filters["ss_RT_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_RT_CD",
                        e.target.value,
                        filters["ss_RT_CD"]
                          ? filters["ss_RT_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_CA_NO"] ? filters["ss_CA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_CA_NO",
                        e.target.value,
                        filters["ss_CA_NO"]
                          ? filters["ss_CA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_MO_TR"] ? filters["ss_MO_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_MO_TR",
                        e.target.value,
                        filters["ss_MO_TR"]
                          ? filters["ss_MO_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_AC_SD"] ? filters["ss_AC_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_AC_SD",
                        e.target.value,
                        filters["ss_AC_SD"]
                          ? filters["ss_AC_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ss_PR_DD"] ? filters["ss_PR_DD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_PR_DD",
                        e.target.value,
                        filters["ss_PR_DD"]
                          ? filters["ss_PR_DD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
      
                <td>
                  <input
                    type="text"
                    value={filters["ss_ST_CD"] ? filters["ss_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ss_ST_CD",
                        e.target.value,
                        filters["ss_ST_CD"]
                          ? filters["ss_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Shipment Depot</th>
                <th style={tableHeaderStyle}>Shipment No</th>
                <th style={tableHeaderStyle}>Origin</th>
                <th style={tableHeaderStyle}>Ship To</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Sold To</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Route Code</th>
                <th style={tableHeaderStyle}>Carrier Number</th>
                <th style={tableHeaderStyle}>Mode of Transport</th>
                <th style={tableHeaderStyle}>Shipment Date</th>
                <th style={tableHeaderStyle}>Delivery Date</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.ssUkNo}
                    style={{  backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",  }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.ssUkNo}
                        checked={selectedRows.includes(rowData.ssUkNo)}
                        onChange={() => handleRowSelect(rowData.ssUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ss_SH_DE}</td>
                    <td style={tableCellStyle}>{rowData.ss_SH_NO}</td>
                    <td style={tableCellStyle}>{rowData.ss_OG_NO}</td>
                    <td style={tableCellStyle}>{rowData.ss_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.shipto_NAME}</td> 
                    <td style={tableCellStyle}>{rowData.ss_SL_TO}</td>
                    <td style={tableCellStyle}>{rowData.sold_NAME}</td>
                    <td style={tableCellStyle}>{rowData.ss_RT_CD}</td> 
                    <td style={tableCellStyle}>{rowData.ss_CA_NO}</td>
                    <td style={tableCellStyle}>{rowData.ss_MO_TR}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ss_AC_SD)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.ss_PR_DD)}</td> 
                    <td style={tableCellStyle}>{rowData.ss_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <ShipmentStatusDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        ShipmentStatusData={ShipmentStatusData}
        setShipmentStatusData={setShipmentStatusData}
        onChangeShipmentStatusData={OnChangeShipmentStatusData}
        SelectedSold={SelectedSold}
        setSelectedSold={setSelectedSold}
        SelectedShip={SelectedShip}
        setSelectedShip={setSelectedShip}
        ss_SH_DE={ss_SH_DE}
        setss_SH_DE={setss_SH_DE}
        ss_OG_NO={ss_OG_NO}
        setss_OG_NO={setss_OG_NO}
        ss_SH_TY={ss_SH_TY}
        setss_SH_TY={setss_SH_TY}
        ss_ST_CD={ss_ST_CD}
        setss_ST_CD={setss_ST_CD}
        ss_CI_TY={ss_CI_TY}
        setss_CI_TY={setss_CI_TY}
        ss_ST_AT={ss_ST_AT}
        setss_ST_AT={setss_ST_AT}
        ss_RT_CD={ss_RT_CD}
        setss_RT_CD={setss_RT_CD}
        ss_ZO_NO={ss_ZO_NO}
        setss_ZO_NO={setss_ZO_NO}
        ss_CT_RY={ss_CT_RY}
        setss_CT_RY={setss_CT_RY}
        ss_MO_TR={ss_MO_TR}
        setss_MO_TR={setss_MO_TR}
        ss_FR_HN={ss_FR_HN}
        setss_FR_HN={setss_FR_HN}
        ss_WT_UM={ss_WT_UM}
        setss_WT_UM={setss_WT_UM}
        ss_VL_UM={ss_VL_UM}
        setss_VL_UM={setss_VL_UM}
        
      />
    </div>
  );
};

export default ShipmentStatusMain;