import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeaveStatusDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  ls_YE_AR,
  ls_EM_NO,
  ls_PL_EL,
  ls_PL_TN,
  ls_CL_EL,
  ls_CL_TN,
  ls_ML_EL,
  ls_ML_TN,
  ls_PA_EL,
  ls_PA_TN,
  ls_UL_EL,
  ls_UL_TN,
  ls_CO_EL,
  ls_CO_TN,
  ls_OH_EL,
  ls_OH_TN,
  ls_TO_EL,
  ls_TO_TN,
  setLs_PL_EL,
  setLs_PL_TN,
  setLs_CL_EL,
  setLs_CL_TN,
  setLs_ML_EL,
  setLs_ML_TN,
  setLs_PA_EL,
  setLs_PA_TN,
  setLs_UL_EL,
  setLs_UL_TN,
  setLs_CO_EL,
  setLs_CO_TN,
  setLs_OH_EL,
  setLs_OH_TN,
  setLs_TO_EL,
  setLs_TO_TN
}) {
  // search
  const { 
    ls_BG_BL,
    lsUkNo,
    ls_CO_NO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
// search
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");
// dropdown
const [ls_YE_AROptions, setls_YE_AROptions] = useState([]);
const openEmployeePopup = () => {
  setEmployeePopupVisible(true);
};
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const url = `${domain}/api/ClassCode/All`;
  // const parameter1 = "2000/2025";
  const parameter1 = "YE/AR";
  const finalUrl1 = `${url}/${parameter1}`;
    useEffect(() => {
    fetchDropdownOptions1();
  }, []);
  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setls_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    // onchangeFormData("ls_EM_NO", data.abAdNo);
    document.getElementById("ls_EM_NO").value = data.abAdNo;
    onchangeFormData("name", data.ab_AD_NM);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // endsearchpopup
  const isUpdateMode = Boolean(lsUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{lsUkNo ? "Revise Leave Status" : "Add Leave Status"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  // calculating the things
  const onchangedata1 = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value) || 0;

    switch (name) {
      case 'ls_PL_EL':
        setLs_PL_EL(parsedValue);
        break;
      case 'ls_PL_TN':
        setLs_PL_TN(parsedValue);
        break;
      case 'ls_CL_EL':
        setLs_CL_EL(parsedValue);
        break;
      case 'ls_CL_TN':
        setLs_CL_TN(parsedValue);
        break;
      case 'ls_ML_EL':
        setLs_ML_EL(parsedValue);
        break;
      case 'ls_ML_TN':
        setLs_ML_TN(parsedValue);
        break;
      case 'ls_PA_EL':
        setLs_PA_EL(parsedValue);
        break;
      case 'ls_PA_TN':
        setLs_PA_TN(parsedValue);
        break;
      case 'ls_UL_EL':
        setLs_UL_EL(parsedValue);
        break;
      case 'ls_UL_TN':
        setLs_UL_TN(parsedValue);
        break;
      case 'ls_CO_EL':
        setLs_CO_EL(parsedValue);
        break;
      case 'ls_CO_TN':
        setLs_CO_TN(parsedValue);
        break;
      case 'ls_OH_EL':
        setLs_OH_EL(parsedValue);
        break;
      case 'ls_OH_TN':
        setLs_OH_TN(parsedValue);
        break;
      default:
        break;
    }
    calculateTotalLeaves();
  };
  const calculateTotalLeaves = () => {
    const total_EL = ls_PL_EL + ls_CL_EL + ls_ML_EL + ls_PA_EL + ls_UL_EL + ls_CO_EL + ls_OH_EL;
    const total_TN = ls_PL_TN + ls_CL_TN + ls_ML_TN + ls_PA_TN + ls_UL_TN + ls_CO_TN + ls_OH_TN;

    setLs_TO_EL(total_EL);
    setLs_TO_TN(total_TN);
  };
  useEffect(() => {
    calculateTotalLeaves();
  }, [ls_PL_EL, ls_CL_EL, ls_ML_EL, ls_PA_EL, ls_UL_EL, ls_CO_EL, ls_OH_EL, ls_PL_TN, ls_CL_TN, ls_ML_TN, ls_PA_TN, ls_UL_TN, ls_CO_TN, ls_OH_TN]);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
   <div style={borderbox} >
      <div style={inboxstyle} >
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ls_EM_NO" className="font-bold">
              Employee <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={data.name}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openEmployeePopup}
              ></i>
              )}
            </div>   
            <div  style={{ display: "none" }} >
              <InputText
                id="ls_EM_NO"
                name="ls_EM_NO"
                defaultValue={ls_EM_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ls_YE_AR" className="font-bold">
              Year
            </label>
            <Dropdown
              id="ls_YE_AR"
              name="ls_YE_AR"
              value={ls_YE_AR}
              options={ls_YE_AROptions}
              onChange={(e) => onchangeDropDownFormData1("ls_YE_AR", e.value)}
              placeholder="Select Year"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ls_BG_BL" className="font-bold">
                Beginning Balance
            </label>
            <InputText
              id="ls_BG_BL"
              name="ls_BG_BL"
              value={ls_BG_BL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>       
      </div>
      <div style={inboxstyle}>
      <Container>
          <Row>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  
            </label>
            </Col>
            <Col md={3}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  Eligible
                </label>
            </Col>
            <Col md={3}>
                  <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                    Taken
                  </label>
            </Col>
            <Col md={3}>
                  <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                    Balance
                  </label>
            </Col>
          </Row>
          {/* 2nd */}
          <Row style={{marginTop:"2%"}} >
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  Annual Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText
                  id="ls_PL_EL"
                  name="ls_PL_EL"
                  value={ls_PL_EL}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
            <Col md={3}>
            <InputText disabled
                  id="ls_PL_TN"
                  name="ls_PL_TN"
                  value={ls_PL_TN}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
            <Col md={3}>
            <InputText disabled
                  id="ls_PL_EL - ls_PL_TN"
                  name="ls_PL_EL - ls_PL_TN"
                  value={isNaN(ls_PL_EL - ls_PL_TN) || (ls_PL_EL ==0 && ls_PL_TN==0)?'':ls_PL_EL - ls_PL_TN}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
          </Row>
          {/* 3rd */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Emergency Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText 
                  id="ls_CL_EL"
                  name="ls_CL_EL"
                  value={ls_CL_EL}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
            <Col md={3}>
            <InputText disabled
                  id="ls_CL_TN"
                  name="ls_CL_TN"
                  value={ls_CL_TN}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
            <Col md={3}>
            <InputText disabled
                  id="ls_CL_EL - ls_CL_TN"
                  name="ls_CL_EL - ls_CL_TN"
                  value={isNaN(ls_CL_EL - ls_CL_TN) || (ls_CL_EL ==0 && ls_CL_TN==0)?'':ls_CL_EL - ls_CL_TN}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
          </Row>
          {/* 4th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Maternity Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText
                id="ls_ML_EL"
                name="ls_ML_EL"
                value={ls_ML_EL}
                onChange={(e) => onchangedata1(e)}
              />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_ML_TN"
              name="ls_ML_TN"
              value={ls_ML_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_ML_EL - ls_ML_TN"
              name="ls_ML_EL - ls_ML_TN"
              value={isNaN(ls_ML_EL - ls_ML_TN) || (ls_ML_EL == 0 && ls_ML_TN == 0)?'':ls_ML_EL - ls_ML_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
          </Row>
          {/* 5th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Paternity Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText
              id="ls_PA_EL"
              name="ls_PA_EL"
              value={ls_PA_EL}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
                id="ls_PA_TN"
                name="ls_PA_TN"
                value={ls_PA_TN}
                onChange={(e) => onchangedata1(e)}
              />
          </Col>
            <Col md={3}>
            <InputText disabled
                  id="ls_PA_EL - ls_PA_TN"
                  name="ls_PA_EL - ls_PA_TN"
                  value={isNaN(ls_PA_EL - ls_PA_TN) || (ls_PA_EL == 0 && ls_PA_TN == 0)?'':ls_PA_EL - ls_PA_TN}
                  onChange={(e) => onchangedata1(e)}
                />
            </Col>
          </Row>
          {/* 6th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Unpaid Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText
              id="ls_UL_EL"
              name="ls_UL_EL"
              value={ls_UL_EL}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_UL_TN"
              name="ls_UL_TN"
              value={ls_UL_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_UL_EL - ls_UL_TN"
              name="ls_UL_EL - ls_UL_TN"
              value={isNaN(ls_UL_EL - ls_UL_TN) || (ls_UL_EL == 0 && ls_UL_TN == 0)?'':ls_UL_EL - ls_UL_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
          </Row>
          {/* 7th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Compensatory Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText
              id="ls_CO_EL"
              name="ls_CO_EL"
              value={ls_CO_EL}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}> 
            <InputText disabled
              id="ls_CO_TN"
              name="ls_CO_TN"
              value={ls_CO_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_CO_EL - ls_CO_TN"
              name="ls_CO_EL - ls_CO_TN"
              value={isNaN(ls_CO_EL - ls_CO_TN) || (ls_CO_EL == 0 && ls_CO_TN == 0)?'':ls_CO_EL - ls_CO_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
          </Row>  
          {/* 8th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Optional Holidays
            </label>
            </Col>
            <Col md={3}>
            <InputText
              id="ls_OH_EL"
              name="ls_OH_EL"
              value={ls_OH_EL}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_OH_TN"
              name="ls_OH_TN"
              value={ls_OH_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_OH_EL - ls_OH_TN"
              name="ls_OH_EL - ls_OH_TN"
              value={isNaN(ls_OH_EL - ls_OH_TN) || (ls_OH_EL == 0 && ls_OH_TN == 0)?'':ls_OH_EL - ls_OH_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
          </Row>
          {/* 9th */}
          <Row style={{marginTop:"2%"}}>
            <Col md={3}>
            <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
            Total Leave
            </label>
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_TO_EL"
              name="ls_TO_EL"
              value={ls_TO_EL}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_TO_TN"
              name="ls_TO_TN"
              value={ls_TO_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
            <Col md={3}>
            <InputText disabled
              id="ls_TO_EL - ls_TO_TN"
              name="ls_TO_EL - ls_TO_TN"
              value={isNaN(ls_TO_EL - ls_TO_TN) || (ls_TO_EL == 0 && ls_TO_TN == 0)?'': ls_TO_EL - ls_TO_TN}
              onChange={(e) => onchangedata1(e)}
            />
            </Col>
          </Row>     
        </Container>
      </div>  
    </div> 
    {/* end */}
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
