import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ParentItemPopup from "../Popups/ParentItemPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function BillingInvoiceGenerationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BillingInvoiceGenerationTableData,
  setBillingInvoiceGenerationTableData,
  bd_ST_AG,
  setbd_ST_AG,
  bd_CR_CD,
  setbd_CR_CD,
  bd_BL_TY,
  setbd_BL_TY,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  setbd_IT_NM,
  setitemDES1,
  setbd_BL_NM,
  setitemDES,
  setselectedEquipment,
  setselectedEquipmentDesc,
  total_DISCOUNT,
  total_TAX,
}) {
  const {
    bdUkNo,
    bd_CU_NO,
    bd_CN_NO,
    bd_CN_TY,
    bd_CN_CO,
    bd_EX_RT,
    bd_BS_CR,
    bd_BL_AM,
    bd_IN_DT,
  } = data || {};
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [bd_CR_CDOptions, setbd_CR_CDOptions] = useState([]);
  const [bd_BL_TYOptions, setbd_BL_TYOptions] = useState([]);
  const [bd_ST_AGOptions, setbd_ST_AGOptions] = useState([]);
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_CR_CD(value);
  };
  const BillTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_BL_TY(value);
  };
  const StageDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_ST_AG(value);
  };
  useEffect(() => {
    ProjectType();
    Currency();
    BillType();
  }, []);

  const ProjectType = async () => {
    const CostOptions = await fetchClassCodeDS("BL/NM");
    setbd_ST_AGOptions(CostOptions);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbd_CR_CDOptions(Options);
  };
  const BillType = async () => {
    const Options = await fetchClassCodeDS("BG/TY");
    setbd_BL_TYOptions(Options);
  };
  // Equipment Master
  const [EquipmentVisible, setEquipmentVisible] = useState(false);
  const openEquipmentPopup = (e) => {
    setEquipmentVisible(true);
    setSelectedRowIndex(e);
  };
  const handleEquipment =async (data) => {
    setselectedEquipment(data.em_EQ_NM)
    setselectedEquipmentDesc(data.em_EQ_DS)
    onchangeFormData("bd_EQ_NM", data.em_EQ_NM, SelectedRowIndex);
    onchangeFormData("equipmentDES", data.em_EQ_DS, SelectedRowIndex);
    setEquipmentVisible(false);
    setShowSearchIcon(false);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("bd_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
  };
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const handleItem = async (Result) => {
    setbd_BL_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("bd_BL_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const [Item1Visible, setItem1Visible] = useState(false);
  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };
  const handleItem1 = async (Result) => {
    setbd_IT_NM(Result.im_IT_NM);
    setitemDES1(Result.im_IT_DS);
    onchangeFormData("bd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES1", Result.im_IT_DS, SelectedRowIndex);
    setItem1Visible(false);
    setShowSearchIcon(false);
  };
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const openAddressPopup = (e) => {
    setAddressPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData(`bd_AD_NO_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`addr_NAME_${SelectedRowIndex}`, data.ab_AD_NM);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setBillingInvoiceGenerationTableData([...BillingInvoiceGenerationTableData, {}]);
  };
  const deleteRow = () => {
    if (BillingInvoiceGenerationTableData.length > 0) {
      const updatedData = [...BillingInvoiceGenerationTableData];
      updatedData.pop();
      setBillingInvoiceGenerationTableData(updatedData);
    }
  };  
const isUpdateMode = Boolean(bdUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{ bdUkNo ? "Revise Billing Invoice Generation" : "Add Billing Invoice Generation"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
); 
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div style={{display:'block'}}>
            <label htmlFor="bdUkNo" className="font-bold">
              uk_no
            </label>
            <InputText disabled
              id="bdUkNo"
              name="bdUkNo"
              defaultValue={bdUkNo}
              onChange={(e) => onchangedata(e)}
            />
        </div>
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bd_CN_NO" className="font-bold">
           Contract No
          </label>
          <InputText disabled
            id="bd_CN_NO"
            name="bd_CN_NO"
            defaultValue={bd_CN_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bd_CN_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="bd_CN_TY"
            name="bd_CN_TY"
            value={bd_CN_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bd_CN_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="bd_CN_CO"
            name="bd_CN_CO"
            value={bd_CN_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        
                
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_CR_CD" className="font-bold">
                 Currency
                  </label>
                  <Dropdown disabled
                    name="bd_CR_CD"
                    value={bd_CR_CD}
                    options={bd_CR_CDOptions}
                    onChange={(e) =>CurrencyDropDown("bd_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="bd_EX_RT"
                    name="bd_EX_RT"
                    value={bd_EX_RT}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="bd_BS_CR"
                    name="bd_BS_CR"
                    value={bd_BS_CR}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="name"
                name="name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="bd_CU_NO"
                name="bd_CU_NO"
                defaultValue={bd_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
            </div>
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BL_AM" className="font-bold">
                    Bill Amount
                  </label>
                  <InputText disabled
                    id="bd_BL_AM"
                    name="bd_BL_AM"
                    value={bd_BL_AM}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="total_DISCOUNT" className="font-bold">
                    Total Discount
                  </label>
                  <InputText disabled
                    id="total_DISCOUNT"
                    name="total_DISCOUNT"
                    value={total_DISCOUNT}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="total_TAX" className="font-bold">
                    Total Tax
                  </label>
                  <InputText disabled
                    id="total_TAX"
                    name="total_TAX"
                    value={total_TAX}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_IN_DT" className="font-bold">
              Invoice Date
            </label>
            <Calendar
              id="bd_IN_DT"
              name="bd_IN_DT"
              value={moment(bd_IN_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "bd_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
            />
          </div>
         
          </div>
        </div>  
        <div  style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>  
          <table className="custom-table" id="Billingtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Billing Stages</th>
                <th>Billing Item </th>
                <th>Description</th>
                <th>Bill Type</th>
                <th>Amount</th>
                <th>Discount %</th>
                <th>Discount Amount</th>
                <th>Tax %</th>
                <th>Tax Amount</th>
                <th>Amount-Foreign</th>
                <th>Discount Foreign</th>
                <th>Tax Foreign</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Completion Date</th>
                <th>Item Name</th>
                <th>Description</th>
                <th style={{display:"none"}}>Address No</th>
                <th>Address No <span style={{ color: 'red', }}>*</span></th>
                <th>Equipment name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {BillingInvoiceGenerationTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "block" }}>
                    <InputText
                      id={`bdUkNo_${index}`}
                      name={`bdUkNo_${index}`}
                      value={row.bdUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown disabled
                      id={`bd_ST_AG_${index}`}
                      name={`bd_ST_AG_${index}`}
                      value={ data[`bd_ST_AG_${index}`] ? data[`bd_ST_AG_${index}`]: row.bd_ST_AG}
                      options={bd_ST_AGOptions}
                      onChange={(e) =>StageDropdown(`bd_ST_AG_${index}`, e.value)}
                      placeholder="Select Stage"
                    />
                    </td>
                  <td style={{ display: "flex" }}>
                        <InputText disabled
                        style={{ width: "110px" }}
                        id={`bd_BL_NM_${index}`}
                        name={`bd_BL_NM_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.bd_BL_NM ? row.bd_BL_NM: row[`${"bd_BL_NM"}_${index}`]}
                        />
                        {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "15%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                    </td>
                      <td>
                      <InputText disabled
                      style={{ width: "200px" }}
                      id={`itemDES_${index}`}
                      name={`itemDES_${index}`}
                      defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                      readOnly
                      />
                      </td>
                      <td>
                    <Dropdown disabled
                      id={`bd_BL_TY_${index}`}
                      name={`bd_BL_TY_${index}`}
                      value={ data[`bd_BL_TY_${index}`] ? data[`bd_BL_TY_${index}`]: row.bd_BL_TY}
                      options={bd_BL_TYOptions}
                      onChange={(e) =>BillTypeDropdown(`bd_BL_TY_${index}`, e.value)}
                      placeholder="Select Bill Type"
                    />
                    </td>
                    <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_AM_DM_${index}`}
                      name={`bd_AM_DM_${index}`}
                      defaultValue={row.bd_AM_DM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled  style={{width:"90px"}}
                      id={`bd_DS_PE_${index}`}
                      name={`bd_DS_PE_${index}`}
                      defaultValue={row.bd_DS_PE}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_DS_AM_${index}`}
                      name={`bd_DS_AM_${index}`}
                      defaultValue={row.bd_DS_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`bd_TX_PE_${index}`}
                      name={`bd_TX_PE_${index}`}
                      defaultValue={row.bd_TX_PE}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`bd_TX_AM_${index}`}
                      name={`bd_TX_AM_${index}`}
                      defaultValue={row.bd_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_AM_FR_${index}`}
                      name={`bd_AM_FR_${index}`}
                      defaultValue={row.bd_AM_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_DS_FR_${index}`}
                      name={`bd_DS_FR_${index}`}
                      defaultValue={row.bd_DS_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_TX_FR_${index}`}
                      name={`bd_TX_FR_${index}`}
                      defaultValue={row.bd_TX_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
          
                  <td>
                  <Calendar disabled style={{width:"150px"}}
                  id={`bd_FR_DT_${index}`}
                  name={`bd_FR_DT_${index}`}
                  value={row.bd_FR_DT? moment(row.bd_FR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar disabled  style={{width:"150px"}}
                  id={`bd_EN_DT_${index}`}
                  name={`bd_EN_DT_${index}`}
                  value={row.bd_EN_DT? moment(row.bd_EN_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar disabled  style={{width:"150px"}}
                  id={`bd_AC_CP_${index}`}
                  name={`bd_AC_CP_${index}`}
                  value={row.bd_AC_CP? moment(row.bd_AC_CP, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_AC_CP", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td style={{ display: "flex" }}>
                    <InputText disabled
                    style={{ width: "110px" }}
                    id={`bd_IT_NM_${index}`}
                    name={`bd_IT_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.bd_IT_NM ? row.bd_IT_NM: row[`${"bd_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openItem1Popup(index)}
                  ></i>
                  )}
                </td>
                  <td>
                  <InputText disabled
                  style={{ width: "200px" }}
                  id={`itemDES1_${index}`}
                  name={`itemDES1_${index}`}
                  defaultValue={row.itemDES1 ? row.itemDES1 : row[`${"itemDES1"}_${index}`]}
                  readOnly
                  />
                  </td>
                  
                  
                  <td style={{display:"none"}}> 
                  <InputText disabled
                  id={`bd_AD_NO_${index}`}
                  name={`bd_AD_NO_${index}`}
                  onFocus={() => setShowSearchIcon(true)}
                  value={row.bd_AD_NO ? row.bd_AD_NO: data[`${"bd_AD_NO"}_${index}`]}
                  onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  
                  <td>
                  <div style={{ display: "flex", marginTop: "10px" ,width:"150px"}}>
                  <InputText disabled
                  id={`addr_NAME_${index}`}
                  name={`addr_NAME_${index}`}
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={row.addr_NAME ? row.addr_NAME : data[`${"addr_NAME"}_${index}`]}
                  />
                  {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "7%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openAddressPopup(index)}
                  ></i>
                  )}
                  </div>
                  </td>
                  <td style={{ display: "flex" }}>
                  <InputText disabled style={{ width: "12rem" }}
                  id={`bd_EQ_NM_${index}`}
                  name={`bd_EQ_NM_${index}`}
                  onChange={(e) => onchangeFormData(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={row.bd_EQ_NM ? row.bd_EQ_NM: row[`${"bd_EQ_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                  <i  className="pi pi-search"
                      style={{ color: "var(--primary-color)",  fontSize: "119%", marginTop: "15%", cursor: "pointer",  }}
                      onClick={(e) => openEquipmentPopup(index)}
                  ></i>
                )}
                </td>
                <td>
                <InputText disabled style={{ width: "15rem" }}
                  id={`equipmentDES_${index}`}
                  name={`equipmentDES_${index}`}
                  defaultValue={row.equipmentDES ? row.equipmentDES : row[`${"equipmentDES"}_${index}`]}
                />
              </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={AddresstypeE}
      />
      <ParentItemPopup
        visible={Item1Visible}
        onClose={() => setItem1Visible(false)}
        onDataSelect={handleItem1}
        formData={data}
      />
      <EquipmentPopup
        visible={EquipmentVisible}
        onClose={() => setEquipmentVisible(false)}
        onDataSelect={handleEquipment}
        formData={data}
      />
    </Dialog>
  );
}
