import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import TimeEntryDialog from "./TimeEntryDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  name: "",
  et_EM_NO: "",
  et_IN_DT: "",
  et_SH_FT: null,
  et_IN_TM: "",
  et_CO_NO: "",
  et_EM_NO: "",
  et_OU_TM: "",
  et_OU_DT: "",
  et_TO_HR: "",
};
const initialFilters = {
  et_EM_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  et_SH_FT: { value: "", operation: "" },
  et_IN_DT: { value: "", operation: "" },
  et_IN_TM: { value: "", operation: "" },
  et_OU_DT: { value: "", operation: "" },
  et_OU_TM: { value: "", operation: "" },
  et_TO_HR: { value: "", operation: "" },
};
const TimeEntryMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [et_SH_FT, setet_SH_FT] = useState("");
  const [timeentryTableData, settimeentryTableData] = useState([{}]);

  // time fromat
  const formatSavedTime = (inputTime) => {
    if (!inputTime) {
      return inputTime;
    }
    return moment(inputTime, "HH:mm:ss").format("HH:mm:ss");
  };
  //time format end
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const onchangeDropDownFormData1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setet_SH_FT(value);
  // };

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAddButtonClick = () => {
    settimeentryTableData([{}]);
    setFormData({});
    setSelectedemEMNO(null);
    setet_SH_FT(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setet_SH_FT(null);
    setOpen(false);
  };
  const addRow = () => {
    settimeentryTableData([...timeentryTableData, {}]);
  };
  const deleteRow = () => {
    if (timeentryTableData.length > 0) {
      const updatedData = [...timeentryTableData];
      updatedData.pop();
      settimeentryTableData(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/TimeEntry/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const TimeEntryData = response.data;
          // delete TimeEntryData.etUkNo;
          const updatedTimeentries = TimeEntryData.timeEntry.map((target) => {
            const UpdatedTimeentryData = { ...target };
            delete UpdatedTimeentryData.etUkNo;
            return UpdatedTimeentryData;
          });
          // setFormData(TimeEntryData);
          // setSelectedemEMNO(TimeEntryData.ad_EM_NM);
          // setet_SH_FT(TimeEntryData.et_SH_FT);
          settimeentryTableData(updatedTimeentries);
          setFormData(updatedTimeentries[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TimeEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    // if (FormData && FormData.etUkNo){
    //   timeentryTableData[0][name.slice(0,-2)]=value;
    // }
    //const fieldName=(FormData && FormData.etUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      timeentryTableData[index][name]=value;
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TimeEntry/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const TimeEntryData = response.data;
        settimeentryTableData(TimeEntryData.timeEntry);
        setFormData(TimeEntryData.timeEntry[0]);
        setet_SH_FT(TimeEntryData.timeEntry[0].et_SH_FT);
        handleClickOpen();
        // setFormData(TimeEntryData);
        // setSelectedemEMNO(response.data.ad_EM_NM);
        // setet_SH_FT(response.data.et_SH_FT);
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit = () => {
    if (FormData && FormData.etUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/TimeEntry/Update`;
      if (confirm) {
        const table = document.getElementById("timeentryTable");
        const totalRowCount = table.tBodies[0].rows.length;
        const modifiedTimeentries = [];
        for (let i = 0; i < totalRowCount; i++) {
          const UK_NO = document.getElementById("etUkNo_" + i);
          const EM_NO = document.getElementById("et_EM_NO_" + i);
          const name = document.getElementById("name_" + i);
          const SH_FT = FormData["et_SH_FT_" + i]?FormData["et_SH_FT_" + i]:FormData["et_SH_FT"];
          const IN_DT = document.getElementsByName("et_IN_DT_" + i)[0];
          const IN_TM = document.getElementById("et_IN_TM_" + i);
          const OU_DT = document.getElementsByName("et_OU_DT_" + i)[0];
          const OU_TM = document.getElementById("et_OU_TM_" + i);
          const TO_HR = document.getElementById("et_TO_HR_" + i);
          const timentry = {
            etUkNo: UK_NO.value,
            et_EM_NO: EM_NO.value,
            name: name.value,
            et_SH_FT: SH_FT,
            et_IN_DT: moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
            et_OU_DT: moment(OU_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
            et_IN_TM: IN_TM.value.replaceAll(":", ""),
            et_OU_TM: OU_TM.value.replaceAll(":", ""),
            et_TO_HR: TO_HR.value,
          };
          modifiedTimeentries.push(timentry);
        }
        const requestBody = {
          timeEntry: modifiedTimeentries,
        };
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      const table = document.getElementById("timeentryTable");
      const totalRowCount = table.tBodies[0].rows.length;
      const timeEntry = [];
      for (let i = 0; i < totalRowCount; i++) {
        const EM_NO = document.getElementById("et_EM_NO_" + i);
        const name = document.getElementById("name_" + i);
        const SH_FT = FormData["et_SH_FT_" + i];
        const IN_DT = document.getElementsByName("et_IN_DT_" + i)[0];
        const IN_TM = document.getElementById("et_IN_TM_" + i);
        const OU_DT = document.getElementsByName("et_OU_DT_" + i)[0];
        const OU_TM = document.getElementById("et_OU_TM_" + i);
        const TO_HR = document.getElementById("et_TO_HR_" + i);
        const timeEntries = {
          et_EM_NO: EM_NO.value,
          name: name.value,
          et_SH_FT: SH_FT,
          et_IN_DT: moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
          et_OU_DT: moment(OU_DT.value, "DD/MM/YYYY").format("YYYYMMDD"),
          et_IN_TM: IN_TM.value.replaceAll(":", ""),
          et_OU_TM: OU_TM.value.replaceAll(":", ""),
          et_TO_HR: TO_HR.value,
          et_CO_NO: company,
        };
        timeEntry.push(timeEntries);
      }
      const requestBody = {
        timeEntry,
      };
      axios
        .post(`${domain}/api/TimeEntry/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "red !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack>
        <div
          style={{
            background: "white !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };

  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["et_EM_NO"] ? filters["et_EM_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_EM_NO",
                        e.target.value,
                        filters["et_EM_NO"]
                          ? filters["et_EM_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"] ? filters["name"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_SH_FT"] ? filters["et_SH_FT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_SH_FT",
                        e.target.value,
                        filters["et_SH_FT"]
                          ? filters["et_SH_FT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_IN_DT"] ? filters["et_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_IN_DT",
                        e.target.value,
                        filters["et_IN_DT"]
                          ? filters["et_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_IN_TM"] ? filters["et_IN_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_IN_TM",
                        e.target.value,
                        filters["et_IN_TM"]
                          ? filters["et_IN_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_OU_DT"] ? filters["et_OU_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_OU_DT",
                        e.target.value,
                        filters["et_OU_DT"]
                          ? filters["et_OU_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_OU_TM"] ? filters["et_OU_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_OU_TM",
                        e.target.value,
                        filters["et_OU_TM"]
                          ? filters["et_OU_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["et_TO_HR"] ? filters["et_TO_HR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "et_TO_HR",
                        e.target.value,
                        filters["et_TO_HR"]
                          ? filters["et_TO_HR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyleH}>Employee ID</th>
                <th style={tableHeaderStyle}>Employee </th>
                <th style={tableHeaderStyle}>Shift</th>
                <th style={tableHeaderStyle}>In Date</th>
                <th style={tableHeaderStyle}>In Time</th>
                <th style={tableHeaderStyle}>Out Date</th>
                <th style={tableHeaderStyle}>Out Time</th>
                <th style={tableHeaderStyle}>Total Hrs</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.etUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.etUkNo}
                        checked={selectedRows.includes(rowData.etUkNo)}
                        onChange={() => handleRowSelect(rowData.etUkNo)}
                      />
                    </td>
                    <td style={tableCellStyleH}>{rowData.et_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.shift}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.et_IN_DT)} </td>
                    {/* <td style={tableCellStyle}>{rowData.et_IN_TM}</td> */}
                    <td style={tableCellStyle}>{formatSavedTime(rowData.et_IN_TM)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.et_OU_DT)}</td>
                    {/* <td style={tableCellStyle}>{rowData.et_OU_TM}</td> */}
                    <td style={tableCellStyle}>{formatSavedTime(rowData.et_OU_TM)}</td>
                    <td style={tableCellStyle}>{rowData.et_TO_HR}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
  {/* pagination */}
  <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      
      </div>

      <TimeEntryDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        et_SH_FT={et_SH_FT}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        addRow={addRow}
        deleteRow={deleteRow}
        timeentryTableData={timeentryTableData}
        settimeentryTableData={settimeentryTableData}
      />
    </div>
  );
};
export default TimeEntryMain;
