import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import RentalItemPopup from "../Popups/RentalItemPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import RentalContractPopup from "./RentalContractPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function RentalInvoiceDialog({
  open,
  handleClose,
  data,
  setFormData,
  handleResponse,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  handleConfirm,
  RentalInvoiceTableData,
  setRentalInvoiceTableData,
  rm_TR_CR,
  CurrencyDropDown,
  onchangegldate,
  rm_DO_CO,
  setrm_EX_RT,
  rm_PY_TM,
  setrm_PY_TM,
  rm_TX_AP,
  setrm_TX_AP,
  setrc_LO_CN,
  setrm_TR_UM,
  rm_IT_NM,
  setrm_IT_NM,
  setselecteditemDES,
  SelectedemEMNO,
  setSelectedemEMNO, net_AMOUNT,
  price_AMOUNT,
  discount_AMOUNT,
  tax_AMOUNT,
  total,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  fr_TOTAL,
  rm_DO_TY,
  rm_ST_AT,
  setrm_ST_AT,

}) {
  const {
    rmUkNo,
    rm_CU_NO,
    rm_DO_NO,
    rm_CN_NO,
    rm_IN_DT,
    rm_GL_DT,
    rm_FR_DT,
    rm_TO_DT,
    rm_RF_CE,
    rc_SL_PR,
    rc_OR_QT,
    rc_UN_PR,
    rc_DS_PE,
    rc_TX_PE,
    rm_BS_CR,
    rm_EX_RT,
    rm_CN_TY,
    rm_CO_NO,
  } = data;
  const { borderbox,inboxstyle,footergridstyle,footerfieldstyle,savestyle,cancelstyle,Dialogstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle, } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchPayTerm } = PayTerm();
  const [rm_PY_TMOptions, setrm_PY_TMOptions] = useState([]);
  const [rm_TR_CROptions, setrm_TR_CROptions] = useState([]);
  const [rm_ST_ATOptions, setrm_ST_ATOptions] = useState([]);
  const [rc_LO_CNOptions, setrc_LO_CNOptions] = useState([]);
  const [rm_TR_UMOptions, setrm_TR_UMOptions] = useState([]);
  const [rm_TX_APOptions, setrm_TX_APOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_PY_TM(value);
  };
  const ApplicableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_TX_AP(value);
  };
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_TR_UM(value);
  };
  const StatusDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_ST_AT(value);
  };
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_LO_CN(value);
  };
  const TaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_TX_AP(value);
  };
  useEffect(() => {
    Currency();
    PaymentTerm();
    Applicable();
    UM();
    Status();
    Type();
    Tax();

  }, []);
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setrm_TR_CROptions(Options);
  };
  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    setrm_PY_TMOptions(Options);
  };
  const Applicable = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setrm_TX_APOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrm_TR_UMOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setrm_ST_ATOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeCD("EX/LN");
    setrc_LO_CNOptions(Options);
  };
  const Tax = async () => {
    const Options = await fetchClassCodeCD("ME/PL");
    setrm_TX_APOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("rm_CU_NO", data.abAdNo);

    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`rm_TR_CR`, dataCuMt.cu_CR_CD);
      PaymentTermDropDown(`rm_PY_TM`, dataCuMt.cu_PT_TM);
      ApplicableDropdown(`rm_TX_AP`, dataCuMt.cu_TX_AP);
      const exData={
        "cr_TO_CR": document.getElementById("rm_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.rm_IN_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rm_EX_RT").value = dataExRt.cr_CO_MR;
          setrm_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setrm_IT_NM(data.ri_IT_NO)
    setselecteditemDES(data.item_DES)
    onchangeFormData(`rm_IT_NM_${SelectedRowIndex}`, data.ri_IT_NO);
    onchangeFormData(`itemDES_${SelectedRowIndex}`, data.item_DES);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":data.ri_IT_NO
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`rm_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      // TaxDropdown(`rm_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      document.getElementById (`rm_TX_AP_${SelectedRowIndex}`).value =  dataItMt.im_TX_AP;
      document.getElementById (`rm_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
    } else {
      console.error("Invalid API response:", data);
    }
    // await UnitRate(data.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const addRow = () => {
    setRentalInvoiceTableData([...RentalInvoiceTableData, {}]);
  };
  const deleteRow = () => {
    if (RentalInvoiceTableData.length > 0) {
      const updatedData = [...RentalInvoiceTableData];
      updatedData.pop();
      setRentalInvoiceTableData(updatedData);
    }
  };  
  const [RentalContractPopupVisible, setRentalContractPopupVisible] = useState(false);
  const openRentalContractPopup = () => {
    setRentalContractPopupVisible(true);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const handleRentalContract = async (SelectedRowIndex) => {
    setRentalInvoiceTableData([{}]);
    const contractDtls = [];
    if (selectedRows) {
      const ukNos = selectedRows.toString().split(",");
      const size = ukNos.length;
      for (let i = 0; i < size; i++) {
        try {
          const response = await axios.get(
            `${domain}/api/RentalContract/Select/${ukNos[i]}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );
  
          if (response.status === 200) {
            const data = response.data;
            const listSize = data.rentalContracts.length;

              onchangeFormData("rm_CN_NO", data.rc_TR_NO);
              onchangeFormData("rm_CN_TY", data.rc_TR_TY);
              onchangeFormData("rm_CO_NO", data.rc_TR_CO);
              CurrencyDropDown("rm_TR_CR", data.rc_TR_CR);
              document.getElementById("rm_EX_RT").value = data.rc_EX_RT;
              document.getElementById("rm_BS_CR").value = data.rc_BS_CR;
              onchangeFormData("rm_FR_DT", data.rc_ST_DT);
              onchangeFormData("rm_TO_DT", data.rc_EN_DT);
              PaymentTermDropDown("rm_PY_TM", data.rc_PY_TR);
              ApplicableDropdown("rm_TX_AP", data.rc_TX_AP);
              StatusDropdown("rm_ST_AT", data.rc_HD_ST);
              setSelectedemEMNO(data.cus_NAME);
              onchangeFormData("rm_CU_NO", data.rc_CU_NO);
              document.getElementById("rm_RF_CE").value = data.rc_RF_NC;

              for (let i = 0; i < listSize; i++) {
                const dtls = {
                  rm_IT_NM :data.rentalContracts[i].rc_IT_NM,
                   itemDES :data.rentalContracts[i].itemDES,
                  rm_TR_QT :data.rentalContracts[i].rc_OR_QT,
                  rm_TR_UM :data.rentalContracts[i].rc_TR_UM,
                  rm_RA_TE :data.rentalContracts[i].rc_UN_PR,
                  rm_EX_AM :data.rentalContracts[i].rc_UP_AM,
                  rm_DS_PE :data.rentalContracts[i].rc_DS_PE,
                  rm_DS_AM :data.rentalContracts[i].rc_DS_AM,
                  rm_TX_AP :data.rentalContracts[i].rc_TX_AP,
                  rm_TX_PE :data.rentalContracts[i].rc_TX_PE,
                  rm_TX_AM :data.rentalContracts[i].rc_TX_AM,
                  rm_FR_AM :data.rentalContracts[i].rc_FR_AM,
                  rm_FR_DS :data.rentalContracts[i].rc_FR_DS,
                  rm_FR_TX :data.rentalContracts[i].rc_OR_QT,
                  
                };
                contractDtls.push(dtls);
                const priceInput = {
                  "exchangeRate":data.rc_EX_RT,
                  "rentalInvoices":[ {
                      "quantity":  data.rentalContracts[i].rc_OR_QT,
                      "unitRate": data.rentalContracts[i].rc_UN_PR,
                      "discountPercentage": data.rentalContracts[i].rc_DS_PE,
                      "taxPercentage": data.rentalContracts[i].rc_TX_PE,
                    }]
                  }
                  onChangeOfPrice(priceInput);
              }
              
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      setRentalInvoiceTableData(contractDtls);
      setRentalContractPopupVisible(false);
    } else {
      setRentalInvoiceTableData([{}]);
    }
  };
  const  onChangeOfPrice= async(priceInput) => {
    const UrlCalulate = `${domain}/api/RentalInvoice/GetAmountDetails`;
    try {
      const response =await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCalulate = response.data;
      
      if (dataCalulate ) {
        for(var i=0;i<RentalInvoiceTableData.length;i++){
          document.getElementById(`rm_EX_AM_${i}`).value = dataCalulate.matUnit;
          document.getElementById(`rm_TX_AM_${i}`).value = dataCalulate.matTaxamt;
          document.getElementById(`rm_DS_AM_${i}`).value = dataCalulate.matDisamt;
          document.getElementById(`rm_FR_AM_${i}`).value = dataCalulate.matForeAmt;
          document.getElementById(`rm_FR_DS_${i}`).value = dataCalulate.forematDisamt;
          document.getElementById(`rm_FR_TX_${i}`).value = dataCalulate.foreTax;
        }
       
        document.getElementById ("price_AMOUNT").value =  dataCalulate.matUnit;
        document.getElementById ("discount_AMOUNT").value =  dataCalulate.matDisamt;
        document.getElementById ("net_AMOUNT").value =  dataCalulate.netAmt;
        document.getElementById ("tax_AMOUNT").value =  dataCalulate.matTaxamt;
        document.getElementById ("total").value =  dataCalulate.total;
        //  // Foreign
         document.getElementById ("fr_PRICE_AMOUNT").value =  dataCalulate.matForeAmt;
         document.getElementById ("fr_DISCOUNT_AMOUNT").value =  dataCalulate.forematDisamt;
         document.getElementById ("fr_NET_AMOUNT").value =  dataCalulate.forenetAmt;
         document.getElementById ("fr_TAX_AMOUNT").value =  dataCalulate.foreTax;
         document.getElementById ("fr_TOTAL").value =  dataCalulate.foretotal;
      } else {
        console.error("Invalid API response:", dataCalulate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleInputChange = (e, index) => {
    const quantity = document.getElementById(`rm_TR_QT_${index}`).value;
    const unitRate = document.getElementById(`rm_RA_TE_${index}`).value;
    const discountPercentage = document.getElementById(`rm_DS_PE_${index}`).value;
    const taxPercentage = document.getElementById(`rm_TX_PE_${index}`).value;
    const exchangeRate = document.getElementById("rm_EX_RT").value;

    const priceInput = {
      "exchangeRate":exchangeRate,
        "rentalInvoices": [{
            "quantity": quantity,
            "unitRate": unitRate,
            "discountPercentage": discountPercentage,
            "taxPercentage": taxPercentage,
        }]
    };

    onchangedata(e);
    onChangeOfPrice(priceInput);
  };
  const onchangeOfTaxAplMul = async(e,index) => {
    const txAP = e.currentTarget.value;
    const item = document.getElementById (`rm_IT_NM_${index}`).value;
    if(txAP.toUpperCase() === 'Y'){
      const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
      const requestData={
          "im_IT_NM": item
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data.Result;
        if (dataItMt ) {
          document.getElementById (`rm_TX_PE_${index}`).value =  dataItMt.im_VA_TX;
          onChangeOfPrice(index);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      document.getElementById (`rm_TX_PE_${index}`).value = '0';
      onChangeOfPrice(index);
    }
  };
  const onchangeOfHeaderTaxApl = async(name,value) => {
    const txAP = value;
    const table = document.getElementById("operationtable");
    const totalRowCount = table.tBodies[0].rows.length;

    if(txAP.toUpperCase() === 'Y'){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("rm_TX_AP_" + i).value = 'y';
        const item = document.getElementById ("rm_IT_NM_" + i).value;
        const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
        const requestData={
            "im_IT_NM": item
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.Result;
          if (dataItMt ) {
            document.getElementById ("rm_TX_PE_" + i).value =  dataItMt.im_VA_TX;
            onChangeOfPrice(i);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {    
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("rm_TX_AP_" + i).value = 'n';
        document.getElementById ("rm_TX_PE_" + i).value = '0';
        onChangeOfPrice(i);
      }
    }
    ApplicableDropdown("rm_TX_AP", value);
  };
  const isUpdateMode = Boolean(rmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{rmUkNo ? "Revise Rental Invoice" : "Add Rental Invoice"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
      {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          <Button 
            style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
        </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
            <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="rmUkNo" className="font-bold">
            UK NO
            </label>
            <InputText disabled
              id="rmUkNo"
              name="rmUkNo"
              defaultValue={rmUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rm_DO_NO" className="font-bold">
            Invoice No
            </label>
            <InputText disabled
              id="rm_DO_NO"
              name="rm_DO_NO"
              defaultValue={rm_DO_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rm_DO_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="rm_DO_TY"
              name="rm_DO_TY"
              value={rm_DO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rm_DO_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="rm_DO_CO"
              name="rm_DO_CO"
              value={rm_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rm_CN_NO" className="font-bold">
                Contract No <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="rm_CN_NO"
                      name="rm_CN_NO"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={rm_CN_NO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openRentalContractPopup}
                      ></i>
                    )}
                </div>
                  </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rm_CN_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="rm_CN_TY"
              name="rm_CN_TY"
              value={rm_CN_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rm_CO_NO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="rm_CO_NO"
              name="rm_CO_NO"
              value={rm_CO_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rm_IN_DT" className="font-bold">
                Invoice Date
              </label>
              <Calendar
              id="rm_IN_DT"
              name="rm_IN_DT"
              value={moment(data.rm_IN_DT, "YYYYMMDD").isValid() ? moment(data.rm_IN_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => {
                onchangedata({ target: { name: "rm_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "" } });
                onchangegldate({ target: { name: "rm_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "" } });
              }}
              dateFormat="dd/mm/yy"
            />

            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rm_GL_DT" className="font-bold">
            GL Date
                </label>
                <Calendar
                  id="rm_GL_DT"
                  name="rm_GL_DT"
                  value={moment(data.rm_GL_DT, "YYYYMMDD").isValid() ? moment(data.rm_GL_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "rm_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                /> 
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_ST_AT" className="font-bold">
                    Status
                    </label>
                    <Dropdown disabled
                      name="rm_ST_AT"  
                      value={rm_ST_AT}
                      options={rm_ST_ATOptions}
                      onChange={(e) => StatusDropdown("rm_ST_AT", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rm_CU_NO" className="font-bold">
                Customer  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="cus_NAME"
                  name="cus_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {/* {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )} */}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="rm_CU_NO"
                  name="rm_CU_NO"
                  defaultValue={rm_CU_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_PY_TM" className="font-bold">
                    Payment Term
                    </label>
                    <Dropdown disabled
                      name="rm_PY_TM"
                      value={rm_PY_TM}
                      options={rm_PY_TMOptions}
                      onChange={(e) => PaymentTermDropDown("rm_PY_TM", e.value)}
                      placeholder="Select Payment Term"
                    />
                  </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="rm_TX_AP" className="font-bold">
                      Tax Applicable
                      </label>
                      <Dropdown disabled
                      name="rm_TX_AP"
                      value={rm_TX_AP}
                      options={[
                        { label: "Yes", value: "y" },
                        { label: "No", value: "n" },
                        ]}
                      onChange={(e) => onchangeOfHeaderTaxApl("rm_TX_AP", e.value)}
                      placeholder="Select Applicable"
                    />
                    </div>
            
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_TR_CR" className="font-bold">
                  Currency
                    </label>
                    <Dropdown disabled
                      name="rm_TR_CR"
                      value={rm_TR_CR}
                      options={rm_TR_CROptions}
                      onChange={(e) =>CurrencyDropDown("rm_TR_CR", e.value)}
                      placeholder="Select Currency"
                    />
                  </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_EX_RT" className="font-bold">
                    Exchange Rate
                    </label>
                    <InputText disabled
                      id="rm_EX_RT"
                      name="rm_EX_RT"
                      value={rm_EX_RT}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_BS_CR" className="font-bold">
                      Base Currency
                    </label>
                    <InputText disabled
                      id="rm_BS_CR"
                      name="rm_BS_CR"
                      value={rm_BS_CR}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
              
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_FR_DT" className="font-bold">
                      From Date
                    </label>
                    <Calendar disabled
                      id="rm_FR_DT"
                      name="rm_FR_DT"
                      value={moment(data.rm_FR_DT, "YYYYMMDD").isValid() ? moment(data.rm_FR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "rm_FR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_TO_DT" className="font-bold">
                    To Date
                    </label>
                    <Calendar disabled
                      id="rm_TO_DT"
                      name="rm_TO_DT"
                      value={moment(data.rm_TO_DT, "YYYYMMDD").isValid() ? moment(data.rm_TO_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "rm_TO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_RF_CE" className="font-bold">
                    Reference
                    </label>
                    <InputText disabled
                      id="rm_RF_CE"
                      name="rm_RF_CE"
                      value={rm_RF_CE}
                      onChange={(e) => onchangedata(e)}
                    />
              </div>
            
                  
            </div>
          </div>  
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="operationtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th style={{ display: "none" }}>Item Name</th>
                <th>Item Name </th>
                <th>Quantity</th>
                <th>UM</th>
                <th>Rate</th>
                <th>Amount</th>
                <th>Discount %</th>
                <th>Discount </th>
                <th>Tax Y/N</th>
                <th>Tax %</th>
                <th>Tax </th>
                <th>Fore.Amount</th>
                <th>Fore. Discount</th>
                <th>Fore. Tax</th>
              </tr>
            </thead>
            <tbody>
              {RentalInvoiceTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`rmUkNo_${index}`}
                      name={`rmUkNo_${index}`}
                      value={row.rmUkNo}
                    />
                  </td>
                  
                
            <td style={{ display: "none" }}> 
            <InputText disabled
            id={`rm_IT_NM_${index}`}
            name={`rm_IT_NM_${index}`}
            value={row.rm_IT_NM ? row.rm_IT_NM: data[`${"rm_IT_NM"}_${index}`]}
            onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <div style={{ display: "flex", marginTop: "10px" ,width:"200px"}}>
            <InputText disabled
            id={`itemDES_${index}`}
            name={`itemDES_${index}`}
            value={row.itemDES ? row.itemDES : data[`${"itemDES"}_${index}`]}
            onChange={(e) => onchangedata(e)}
            />
            {/* {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "7%",
            cursor: "pointer",
            }}
            onClick={(e) => openItemPopup(index)}
            ></i>
            )} */}
            </div>
            </td>
                    
                  <td > 
                    <InputText  style={{width:"100px"}} disabled
                      id={`rm_TR_QT_${index}`}
                      name={`rm_TR_QT_${index}`}
                      defaultValue={row.rm_TR_QT}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  
                  <td>
                    <Dropdown disabled
                      id={`rm_TR_UM_${index}`}
                      name={`rm_TR_UM_${index}`}
                      value={ data[`rm_TR_UM_${index}`] ? data[`rm_TR_UM_${index}`]: row.rm_TR_UM}
                      options={rm_TR_UMOptions}
                      onChange={(e) =>UMDropdown(`rm_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                    </td>
                    <td >
                    <InputText  style={{width:"100px"}} disabled
                      id={`rm_RA_TE_${index}`}
                      name={`rm_RA_TE_${index}`}
                      defaultValue={row.rm_RA_TE}
                      onChange={(e) => handleInputChange(e, index)}
                      
                    />
                  </td>
                  <td >
                    <InputText   style={{width:"90px"}}
                    disabled
                    // disabled={data.rm_TR_CR==rm_BS_CR?false:true}
                      id={`rm_EX_AM_${index}`}
                      name={`rm_EX_AM_${index}`}
                      value={row.rm_EX_AM}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"100px"}}
                      id={`rm_DS_PE_${index}`}
                      name={`rm_DS_PE_${index}`}
                      defaultValue={row.rm_DS_PE}
                      onChange={(e) => handleInputChange(e, index)}
                      
                    />
                  </td>
                  <td >
                    <InputText   disabled style={{width:"90px"}}
                      id={`rm_DS_AM_${index}`}
                      name={`rm_DS_AM_${index}`}
                      value={row.rm_DS_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                  <InputText disabled
                    style={{ width: "60px" }}
                    id={`rm_TX_AP_${index}`}
                    name={`rm_TX_AP_${index}`}
                    defaultValue={row.rm_TX_AP}
                    onChange={(e) => onchangeOfTaxAplMul(e,index)}
                  />
                      {/* <Dropdown
                    id={`rm_TX_AP_${index}`}
                    name={`rm_TX_AP_${index}`} 
                    value={data[`rm_TX_AP_${index}`] ? data[`rm_TX_AP_${index}`] : row.rm_TX_AP} 
                    options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                    ]}
                    onChange={(e) => TaxDropdown(`rm_TX_AP_${index}`, e.value)} 
                    placeholder="Select Tax Applicable "
                    /> */}
                  </td>
            
                  <td >
                    <InputText disabled style={{width:"100px"}}
                      id={`rm_TX_PE_${index}`}
                      name={`rm_TX_PE_${index}`}
                      defaultValue={row.rm_TX_PE}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>

                  <td >
                    <InputText disabled style={{width:"100px"}}
                      id={`rm_TX_AM_${index}`}
                      name={`rm_TX_AM_${index}`}
                      value={row.rm_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>

                  <td >
                    <InputText  style={{width:"100px"}}
                    //  disabled={data.rm_TR_CR!=rm_BS_CR?false:true}
                    disabled
                      id={`rm_FR_AM_${index}`}
                      name={`rm_FR_AM_${index}`}
                      value={row.rm_FR_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>

                  <td >
                    <InputText  disabled style={{width:"100px"}}
                      id={`rm_FR_DS_${index}`}
                      name={`rm_FR_DS_${index}`}
                      value={row.rm_FR_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  
                  
                  <td >
                    <InputText  disabled style={{width:"100px"}}
                      id={`rm_FR_TX_${index}`}
                      name={`rm_FR_TX_${index}`}
                      value={row.rm_FR_TX}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
            
          
            
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>
          <div style={inboxstyle} >
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                defaultValue={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="discount_AMOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="discount_AMOUNT"
                name="discount_AMOUNT"
                defaultValue={discount_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="net_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="net_AMOUNT"
                name="net_AMOUNT"
                defaultValue={net_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                defaultValue={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="TOTAL" className="font-bold">
                Total
              </label>
              <InputText disabled
                id="total"
                name="total"
                defaultValue={total}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Fore.Amount
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                defaultValue={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_DISCOUNT_AMOUNT" className="font-bold">
                Fore.Discount
              </label>
              <InputText disabled
                id="fr_DISCOUNT_AMOUNT"
                name="fr_DISCOUNT_AMOUNT"
                defaultValue={fr_DISCOUNT_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_NET_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="fr_NET_AMOUNT"
                name="fr_NET_AMOUNT"
                defaultValue={fr_NET_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
                Fore.Tax
              </label>
              <InputText disabled
                id="fr_TAX_AMOUNT"
                name="fr_TAX_AMOUNT"
                defaultValue={fr_TAX_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TOTAL" className="font-bold">
                Fore.Total
              </label>
              <InputText disabled
                id="fr_TOTAL"
                name="fr_TOTAL"
                defaultValue={fr_TOTAL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <RentalContractPopup
        visible={RentalContractPopupVisible}
        onClose={() => setRentalContractPopupVisible(false)}
        opensubmit={handleRentalContract}
        formData={data}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />


      <RentalItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />


    </Dialog>
  );
}
