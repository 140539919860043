import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
const initialFilters = {
    liLeNo: { value: "", operation: "" },
    li_LE_TY: { value: "", operation: "" },
    li_LE_CO: { value: "", operation: "" },  
    li_TE_NT: { value: "", operation: "" },
    teanat_NAME: { value: "", operation: "" },
    li_TR_CR: { value: "", operation: "" },
    li_EX_RT: { value: "", operation: "" },  
    li_BS_CR: { value: "", operation: "" },
    li_PR_NO: { value: "", operation: "" },
    li_BL_NO: { value: "", operation: "" },  
    li_FL_NO: { value: "", operation: "" },
    li_UN_TS: { value: "", operation: "" },
    se_EX_PR: { value: "", operation: "" },
    se_EX_PR: { value: "", operation: "" },
};

const LeasePopup = ({ visible, onClose, onDataSelect, leaseType}) => {
  const {formatDate,PopupStyle } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'abAdNo') {
          constructedColumn = 'abAdNo';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    if(leaseType != null && leaseType != ""){	
			const def = {
        "column" : "LI_LE_TY" ,
        "value" : leaseType,
        "operation" : "EQUAL"
      }
      searchRequestDto.push(def);
		}
    const requestBody = {
      searchRequestDto,
    };
    const Url = `${domain}/api/LeaseInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(Url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const inputStyle1 = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  return (
    <Dialog id="dialog-popup-style"
      visible={open}
      onHide={handleClose}
      style={PopupStyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Lease Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
            <tr>
                <td>
                  <input
                    type="text"
                    value={filters["liLeNo"] ? filters["liLeNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "liLeNo",
                        e.target.value,
                        filters["liLeNo"]
                          ? filters["liLeNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_TY"] ? filters["li_LE_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_TY",
                        e.target.value,
                        filters["li_LE_TY"]
                          ? filters["li_LE_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_CO"] ? filters["li_LE_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_CO",
                        e.target.value,
                        filters["li_LE_CO"]
                          ? filters["li_LE_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["li_TE_NT"] ? filters["li_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TE_NT",
                        e.target.value,
                        filters["li_TE_NT"]
                          ? filters["li_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["teanat_NAME"] ? filters["teanat_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "teanat_NAME",
                        e.target.value,
                        filters["teanat_NAME"]
                          ? filters["teanat_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TR_CR"] ? filters["li_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TR_CR",
                        e.target.value,
                        filters["li_TR_CR"]
                          ? filters["li_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EX_RT"] ? filters["li_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EX_RT",
                        e.target.value,
                        filters["li_EX_RT"]
                          ? filters["li_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["li_BS_CR"] ? filters["li_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_BS_CR",
                        e.target.value,
                        filters["li_BS_CR"]
                          ? filters["li_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_PR_NO"] ? filters["li_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_PR_NO",
                        e.target.value,
                        filters["li_PR_NO"]
                          ? filters["li_PR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_BL_NO"] ? filters["li_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_BL_NO",
                        e.target.value,
                        filters["li_BL_NO"]
                          ? filters["li_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["li_FL_NO"] ? filters["li_FL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_FL_NO",
                        e.target.value,
                        filters["li_FL_NO"]
                          ? filters["li_FL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_UN_TS"] ? filters["li_UN_TS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_UN_TS",
                        e.target.value,
                        filters["li_UN_TS"]
                          ? filters["li_UN_TS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_EX_PR"] ? filters["se_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_EX_PR",
                        e.target.value,
                        filters["se_EX_PR"]
                          ? filters["se_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_FR_AM"] ? filters["se_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_FR_AM",
                        e.target.value,
                        filters["se_FR_AM"]
                          ? filters["se_FR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_ST"] ? filters["li_LE_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_ST",
                        e.target.value,
                        filters["li_LE_ST"]
                          ? filters["li_LE_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EN_DT",
                        e.target.value,
                        filters["li_EN_DT"]
                          ? filters["li_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
               <th style={tableHeaderStyle}>Lease Number</th>
                <th style={tableHeaderStyle}>Lease Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Tenent</th>
                <th style={tableHeaderStyle}>Tenent Name</th>
                <th style={tableHeaderStyle}>Trans Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Base Currency</th>
                <th style={tableHeaderStyle}>Property</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Floor</th> 
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign Amount</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.uiUkNo}
                      onClick={() => onDataSelect(rowData)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                     <td style={tableCellStyle}>{rowData.liLeNo}</td>
                      <td style={tableCellStyle}>{rowData.li_LE_TY}</td>
                      <td style={tableCellStyle}>{rowData.li_LE_CO}</td>
                      <td style={tableCellStyle}>{rowData.li_TE_NT}</td>
                      <td style={tableCellStyle}>{rowData.teanat_NAME}</td>
                      <td style={tableCellStyle}>{rowData.li_TR_CR}</td>
                      <td style={tableCellStyle}>{rowData.li_EX_RT}</td>
                      <td style={tableCellStyle}>{rowData.li_BS_CR}</td>
                      <td style={tableCellStyle}>{rowData.li_PR_NO}</td>
                      <td style={tableCellStyle}>{rowData.li_BL_NO}</td>
                      <td style={tableCellStyle}>{rowData.li_FL_NO}</td>
                      <td style={tableCellStyle}>{rowData.li_UN_TS}</td>
                      <td style={tableCellStyle}>{rowData.se_EX_PR}</td>
                      <td style={tableCellStyle}>{rowData.se_FR_AM}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.li_ST_DT)}</td> 
                      <td style={tableCellStyle}>{formatDate(rowData.li_EN_DT)}</td> 
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
  {/* pagination */}
  <Pagination
    currentPage={currentPage}
    totalPages={totalPages}
    setCurrentPage={setCurrentPage}
    />    
      </div>
    </Dialog>
  );
};

LeasePopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default LeasePopup;
