import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
import Pagination from "views/common/Pagination";

const initialFilters = {
  ag_GP_ID: { value: "", operation: "" },
  ag_NA_ME: { value: "", operation: "" },
};

const AccessGroupPopupFMS = ({ visible, onClose, onDataSelect }) => {
  const {tableHeaderStyle,tableCellStyle,PopupStyle,} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
    const updatedFilters = {
      ...filters,
      [column]: {
        value: updatedValue,
        operation: updatedOperation,
      },
    };
    setFilters(updatedFilters);
  };
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  useEffect(() => {
    setOpen(visible);
    handleSearch();
    setCurrentPage(0);
  }, [currentPage, pageSize, filters, visible]);


  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'abAdNo') {
          constructedColumn = 'abAdNo';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();
    const requestBody = {
      searchRequestDto,
    };
    const Url = `${domain}/api/AccessGroup/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(Url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  return (
    <Dialog id="dialog-popup-style" 
      visible={open} 
      onHide={handleClose}
      style={PopupStyle} >
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={
                      filters["ag_GP_ID"] ? filters["ag_GP_ID"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "ag_GP_ID",
                        e.target.value,
                        filters["ag_GP_ID"]
                          ? filters["ag_GP_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={
                      filters["ag_NA_ME"] ? filters["ag_NA_ME"].value : ""
                    }
                    onChange={(e) =>
                      handleFilterChange(
                        "ag_NA_ME",
                        e.target.value,
                        filters["ag_NA_ME"]
                          ? filters["ag_NA_ME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Group Id</th>
                <th style={tableHeaderStyle}>Name </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.imItNo}
                      onClick={() => onDataSelect(rowData)}
                      style={{
                              backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",
                            }}
                      className="table-row"
                    >
                      <td style={tableCellStyle}>{rowData.ag_GP_ID}</td>
                      <td style={tableCellStyle}>{rowData.ag_NA_ME}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
       {/* pagination */}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
        />  
      </div>
    </Dialog>
  );
};

export default AccessGroupPopupFMS;

