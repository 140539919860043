import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faFolder, faFile } from '@fortawesome/free-solid-svg-icons';
import Pagination from "views/common/Pagination";

const SharedWithMe = () => {

  const { formatDate } = ButtonStyles();
  const [items, setItems] = useState([]);
  const toast = useRef(null);

  const [tableData, setTableData] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const isProduction = process.env.NODE_ENV === 'production';
  const feDomain = isProduction ? "https://" + window.location.host : "http://localhost:3000";

  const handleFolderClick = (ukNo) => {
    window.location.replace(`${feDomain}/admin/filemanagementapplication&id=100576?path=${ukNo}`);
  };

  // Sample function to simulate an API call
  const fetchItems = async () => {
    const requestBody = {
      searchRequestDto: []
    };
    const url = `${domain}/api/AccessTable/List/${currentPage}/${pageSize}`;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      const listItemsData = response.data.content || [];
      setItems(listItemsData);
      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [currentPage]);
  // download api
  // download api
  const handleFileClick = async (UK_NO, fileName) => {
    const Request = {
      "fdUkNo": UK_NO
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/DownloadFile`, Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      saveAs(new Blob([response.data]), fileName);
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File downloaded successfully.",
        });
      } else {
        throw new Error("Failed to download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };
  // dotspopup
  const [popupVisible, setPopupVisible] = useState(false);

  const handleDotsClick = (item) => {
    setCurrentItem(item);
    setPopupVisible(true);
  };
  return (
    <div style={{ border: '1px solid green', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
      <Toast ref={toast} />
      <div className="row">
        <div className="col-12">
          <table className="table table-borderless transparent-table" style={{ width: '100%', marginBottom: '0' }}>
            <thead>
              <tr style={{ backgroundColor: '#e0f4e0', color: '#333', fontWeight: 'bold' }}>
                <th style={{ padding: '12px', textAlign: 'left' }}>File Name</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Shared By</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Shared Date</th>
                {/* <th style={{ padding: '12px', textAlign: 'left' }}>Location</th> */}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#f8f8f8' : '#ffffff' }}>
                  {/* <td onClick={() => handleFileClick(item.fdUkNo, item.fd_NA_ME)} style={{ padding: '12px', cursor: 'pointer' }}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={item.fd_TY_PE === 'FO' ? faFolder : item.fd_TY_PE === 'FI' ? faFile : null}
                        style={{
                          width: '24px', height: '24px', marginRight: '8px',
                          color: item.fd_TY_PE === 'FO' ? '#F8D775' : item.fd_TY_PE === 'FI' ? '#666666' : 'inherit'
                        }}
                      />
                      {item.fd_NA_ME}
                    </div>
                  </td> */}

                  <td key={item.fdUkNo} >
                <div className="d-flex align-items-center" _hover={{ cursor: "pointer" }}
                 style={{ cursor: item.fd_TY_PE === 'FI' ? 'pointer' : 'default' }}
                  onClick={() => {
                    if (item.fd_TY_PE === 'FI') {
                      handleFileClick(item.fdUkNo, item.fd_NA_ME);
                    }else if(item.fd_TY_PE === 'FO') {
                      handleFolderClick(item.fdUkNo);
                    }
                  }}>
                    <FontAwesomeIcon 
                      icon={item.fd_TY_PE === 'FO' ? faFolder : item.fd_TY_PE === 'FI' ? faFile : null} 
                      style={{ width: '24px', height: '24px', marginRight: '8px',
                          color: item.fd_TY_PE === 'FO' ? 'F8D775' : item.fd_TY_PE === 'FI' ? 'teal' : 'inherit' 
                        }} 
                    />
                    {item.fd_NA_ME}
                    
                </div>
              </td>

                  <td style={{ padding: '12px' }}>{item.owner}</td>

                  <td style={{ padding: '12px' }}>{formatDate(item.last_UPDATED_DATE)}</td>

                  {/* <td onClick={() => handleFileClick(item.fsUkNo)} style={{ padding: '12px', cursor: 'pointer' }}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={(item.fileType === 'FO' || item.folderType === 'FO') ? faFolder : item.fileType === 'FI' ? faFile : null}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                          color: (item.fileType === 'FO' || item.folderType === 'FO') ? '#F8D775' : item.fileType === 'FI' ? 'white' : 'inherit'
                        }}
                      />
                      {item.location}
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>


  );
};
export default SharedWithMe;
