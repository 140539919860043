import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFile } from "@fortawesome/free-solid-svg-icons";
import { Progress } from "@chakra-ui/react";
import { Pie } from 'react-chartjs-2';
import FileManagement from "views/admin/FileManagement/FileManagementMain";
import "./FileManagementSystem.css";

import ButtonStyles from "views/common/ButtonStyles";
const isProduction = process.env.NODE_ENV === 'production';
const feDomain = isProduction ? "https://" + window.location.host : "http://localhost:3000";

const FileManagementSystem = () => {
  // buttonstyles
  const { tableHeaderStyle, tableCellStyle, inputStyle, checkboxStyle } =
    ButtonStyles();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentPath, setCurrentPath] = useState([]); // Track the folder path
  const [isCreateFolderPopupOpen, setCreateFolderPopupOpen] = useState(false);
  const [isUploadFilePopupOpen, setUploadFilePopupOpen] = useState(false);
  const [isCreateFilePopupOpen, setCreateFilePopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [dashboardData, setDashboardData] = useState({
    typesList:[],
    countsList:[]
  });

  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  useEffect(() => {
    fetchStorageData();
    fetchRecentFiles();
    fetchRecentFolders();
  }, [currentPage, pageSize]);

  const [data1, setData1] = useState({
    labels: [],
    datasets: [
      {
        label: 'Storage in KB',
        data: [],
        backgroundColor: [ 'rgba(75,192,192,1)', '#FF6384', '#36A2EB', '#FFCE56', '#B833FF', '#E7E9ED'],
      },
    ],
  });
  useEffect(() => {
    if (!dashboardData.typesList || !dashboardData.countsList) return;

      setData1({
        labels: dashboardData.typesList,
        datasets: [
          {
            label: 'Storage in KB',
            data: dashboardData.countsList,
            backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
          },
        ],
      });
  }, [dashboardData.typesList]);

  
  const fetchStorageData = async () => {
    const url = `${domain}/api/FileManagementSystem/FileManagementSystemDashboard`;
    try {
      const response = await axios.post(url,{},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      const data = response.data;
      setDashboardData(data);

      if (!dashboardData.typesList || !dashboardData.countsList) return;

      setData1({
        labels: dashboardData.typesList,
        datasets: [
          {
            label: 'Storage by File Type',
            data: dashboardData.countsList,
            backgroundColor: ['#99FFFF', '#d5fff2', '#66CCFF', 'cyan', '#EBEDEF', '#AED6F1'],
          },
        ],
      });


    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
      toast.error("Failed to fetch file data.");
    }
  };

  const fetchRecentFiles = async () => {
    const url = `${domain}/api/FileManagementSystem/FetchRecentFiles`;
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const recentFiles = response.data;
        setFiles(recentFiles);
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  const fetchRecentFolders = async () => {
    const url = `${domain}/api/FileManagementSystem/FetchRecentFolders`;
    try {
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const recentFiles = response.data;
        setFolders(recentFiles);
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  // Navigate into folder
  const handleFolderClick = (ukNo) => {
    window.location.replace(`${feDomain}/admin/filemanagementapplication&id=100576?path=${ukNo}`);
  };

  const Progressstyle = {
    marginLeft: "3%",
    width: "94%",
    backgroundColor: "#f4f4f4",
  };

  const Progretextstyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "3% 3% 3% 3%",
    height: "60px"
  };

  const handleFileClick = async (fileName) => {
    try {
      const response = await axios.get(
        `${domain}/api/AwsS3File/download/${fileName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      
      saveAs(new Blob([response.data]), fileName);



      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File downloaded successfully.",
        });
      } else {
        throw new Error("Failed to download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };

  const onChangeOfSearch = async (value) => {
    setSearchQuery(value);

    const searchInput = {
      "fd_NA_ME" : value
    };

    const url = `${domain}/api/FileManagementSystem/SearchRecentFiles`;
    try {
      const response = await axios.post(
        url,searchInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const recentFiles = response.data;
        setFiles(recentFiles);
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  return (
    <div style={{ marginTop: "10rem" }}>
      <Toast ref={toast} />
      <div className="card" style={{ backgroundColor: "#cbe2fffa" }}>
        {/* Search input */}
        <input className="searchbar"
          style={{ width: "100%", maxWidth: "20rem", borderRadius: "2rem", padding: "1rem" }}
          type="text"
          placeholder="Search files..."
          value={searchQuery}
          onChange={(e) => onChangeOfSearch(e.target.value)}
        />
        
        <div className="container mt-4">
          <div className="row g-3">
            <div className="col-12 col-md-4">
              <div className="bg-white rounded p-3">
                <div style={Progretextstyle} id="progrs">
                  <h1 className="fw-bold">Progress</h1>
                  <span>78%</span>
                </div>
                <Progress colorScheme="teal" style={Progressstyle} value={40} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="bg-white rounded p-3">
                <div style={Progretextstyle} id="progrs">
                  <h1 className="fw-bold">Approved</h1>
                  <span>65%</span>
                </div>
                <Progress colorScheme="green" style={Progressstyle} value={40} />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="bg-white rounded p-3">
                <div style={Progretextstyle} id="progrs">
                  <h1 className="fw-bold">Rejected</h1>
                  <span>21%</span>
                </div>
                <Progress colorScheme="red" style={Progressstyle} value={90} />
              </div>
            </div>
          </div>
        </div>



        <div className="container mt-4">
          <div className="row g-3">
            <div className="col-12 col-md-4" style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}>
              <div style={Progretextstyle} id="progrs">
                <h1 className="fw-bold">Recent Files</h1>
                <span><a href="">View All</a></span>
              </div>
              <div className="row p-3">
                {files.map((file) => (
                  <div className="col-4" key={file.fdUkNo} style={{ cursor: "pointer" }} onClick={() => handleFileClick(file.fd_NA_ME)}>
                    <span>
                      <FontAwesomeIcon icon={faFile} style={{ marginRight: "8px", color: "teal", fontSize: "5rem" }} />
                      {file.fd_NA_ME}<br />
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-12 col-md-4" style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}>
              <div style={Progretextstyle} id="progrs">
                <h1 className="fw-bold">Folders</h1>
                <span><a href="">View All</a></span>
              </div>
              <div className="row p-3">
                {folders.map((folder) => (
                  <div className="col-4" key={folder.fdUkNo} style={{ cursor: "pointer" }} onClick={() => handleFolderClick(folder.fdUkNo)}>
                    <span>
                      <FontAwesomeIcon icon={faFolder} style={{ marginRight: "8px", color: "#ffd54f", fontSize: "5rem" }} />
                      {folder.fd_NA_ME}<br />
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-12 col-md-4" style={{ height: "40vh" }}>
              <div style={Progretextstyle} id="progrs">
                <h1 className="fw-bold">Storage Info</h1>
              </div>
              <div style={{ flex: 1, marginLeft: "1%", marginBottom: "10px", borderRadius: "10px", backgroundColor: "white" }}>
                <div style={{ height: "30vh", marginTop: "1%" }}>
                  <Pie data={data1} options={options1} style={{ borderRadius: "10px", border: "1px solid", backgroundColor: "white", height: "100%", padding: "10px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
};

export default FileManagementSystem;
