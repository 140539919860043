import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { InputMask } from "primereact/inputmask";

export default function RouteMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  onchangeFormData,

  SelectedemEMNO,
  setSelectedemEMNO,
 
  rm_RT_CD,
  setrm_RT_CD,
  rm_CO_CT,
  setrm_CO_CT,
  rm_OR_GN,
  setrm_OR_GN,
  rm_DE_ON,
  setrm_DE_ON,
  rm_CI_TY,
  setrm_CI_TY,
  rm_ST_AT,
  setrm_ST_AT,
  rm_MO_TR,
  setrm_MO_TR,
  rm_CT_RY,
  setrm_CT_RY,
  rm_DI_UM,
  setrm_DI_UM,
}) {
  const {
    vmUkNo,
    rm_OR_PC,
    rm_DE_PC,
    rm_CA_NO,
    rm_DE_AD,
    rm_DI_CE,
    rm_LD_TM,


    

  } = data;
  const {borderbox,inboxstyle, Dialogstyle, cancelstyle, savestyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [rm_CO_CTOptions, setrm_CO_CTOptions] = useState([]);
  const [rm_RT_CDOptions, setrm_RT_CDOptions] = useState([]);
  const [rm_OR_GNOptions, setrm_OR_GNOptions] = useState([]);
  const [rm_DE_ONOptions, setrm_DE_ONOptions] = useState([]);
  const [rm_CI_TYOptions, setrm_CI_TYOptions] = useState([]);

  const [rm_ST_ATOptions, setrm_ST_ATOptions] = useState([]);
  const [rm_MO_TROptions, setrm_MO_TROptions] = useState([]);
  const [rm_CT_RYOptions, setrm_CT_RYOptions] = useState([]);
  const [rm_DI_UMOptions, setrm_DI_UMOptions] = useState([]);
  //advance search
  const [Addresstype, setAddresstype] = useState("E");  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  //RouteCode
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_RT_CD(value);
  };
// Cost Centre
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_CO_CT(value);
  };
// Origin
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_OR_GN(value);
  };
// Destination
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrm_DE_ON(value);
  };
// City
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrm_CI_TY(value);
};
// State
const onchangeDropDownFormData6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrm_ST_AT(value);
};
// ModeOfTransport
const onchangeDropDownFormData7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrm_MO_TR(value);
};
// Country
const onchangeDropDownFormData8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrm_CT_RY(value);
};
// UM
const onchangeDropDownFormData9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrm_DI_UM(value);
};


  useEffect(() => {
    fetchRouteCode();
    fetchDropdownCost();
    fetchOrigin();
    fetchDestination();
    fetchCity();
    fetchState();
    fetchModeOfTransport();
    fetchCountry();
    fetchUM();


  }, []);
  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setrm_CO_CTOptions(Options);
  };

  const fetchRouteCode = async () => {
    const Options = await fetchClassCodeDS("RT/CD");
    setrm_RT_CDOptions(Options);
  };

  const fetchOrigin = async () => {
    const Options = await fetchClassCodeDS("OR/GN");
    setrm_OR_GNOptions(Options);
  };
  const fetchDestination = async () => {
    const Options = await fetchClassCodeDS("DE/NO");
    setrm_DE_ONOptions(Options);
  };
  const fetchCity = async () => {
    const Options = await fetchClassCodeDS("FO/CI");
    setrm_CI_TYOptions(Options);
  };
  const fetchState = async () => {
    const Options = await fetchClassCodeDS("FO/ST");
    setrm_ST_ATOptions(Options);
  };
  const fetchModeOfTransport = async () => {
    const Options = await fetchClassCodeDS("FO/ST");
    setrm_MO_TROptions(Options);
  };
  const fetchCountry = async () => {
    const Options = await fetchClassCodeDS("FO/ST");
    setrm_CT_RYOptions(Options);
  };
  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrm_DI_UMOptions(Options);
  };
  
      // Handle form data change
      const onChangeTime=(name,value,index)=>{
        value= moment(value, "HH:mm:ss").format("HHmmss");
        onchangeFormData(name, value,index);
      };
  
  const [FacilityAddressPopupVisible, setFacilityAddressPopupVisible] = useState(false);
  const openCustomerPopup = () => {
    setFacilityAddressPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("rm_CA_NO", data.abAdNo);
    setFacilityAddressPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(vmUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{vmUkNo ? "Revise Route Master" : "Add Route Master"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );


  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >


      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_RT_CD" className="font-bold">
                    Route Code
                    </label>
                    <Dropdown
                    id="rm_RT_CD"
                    name="rm_RT_CD"
                    value={rm_RT_CD}
                    options={rm_RT_CDOptions}
                    onChange={(e) => onchangeDropDownFormData1("rm_RT_CD", e.value)}
                    placeholder="Select Cost Center"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    id="rm_CO_CT"
                    name="rm_CO_CT"
                    value={rm_CO_CT}
                    options={rm_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData2("rm_CO_CT", e.value)}
                    placeholder="Select Cost Center"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_OR_GN" className="font-bold">
                    Origin
                    </label>
                    <Dropdown
                    id="rm_OR_GN"
                    name="rm_OR_GN"
                    value={rm_OR_GN}
                    options={rm_OR_GNOptions}
                    onChange={(e) => onchangeDropDownFormData3("rm_OR_GN", e.value)}
                    placeholder="Select Origin"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rm_OR_PC" className="font-bold">
                  Post Code
                  </label>
                  <InputText
                    id="rm_OR_PC"
                    name="rm_OR_PC"
                    value={rm_OR_PC}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_DE_ON" className="font-bold">
                    Destination
                    </label>
                    <Dropdown
                    id="rm_DE_ON"
                    name="rm_DE_ON"
                    value={rm_DE_ON}
                    options={rm_DE_ONOptions}
                    onChange={(e) => onchangeDropDownFormData4("rm_DE_ON", e.value)}
                    placeholder="Select Destination"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rm_DE_PC" className="font-bold">
                  Post Code
                  </label>
                  <InputText
                    id="rm_DE_PC"
                    name="rm_DE_PC"
                    value={rm_DE_PC}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rm_DE_AD" className="font-bold">
                  Destination Address
                  </label>
                  <InputText
                    id="rm_DE_AD"
                    name="rm_DE_AD"
                    value={rm_DE_AD}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_CI_TY" className="font-bold">
                    City
                    </label>
                    <Dropdown
                    id="rm_CI_TY"
                    name="rm_CI_TY"
                    value={rm_CI_TY}
                    options={rm_CI_TYOptions}
                    onChange={(e) => onchangeDropDownFormData5("rm_CI_TY", e.value)}
                    placeholder="Select Destination"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2"> 
                  <label htmlFor="rm_CA_NO" className="font-bold">
                  Carrier Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedemEMNO}
                    />
                     {showSearchIcon && (
                    <i  className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                     )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="rm_CA_NO"
                      name="rm_CA_NO"
                      value={rm_CA_NO}
                      onChange={(Event) => onchangedata(Event)}  
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_ST_AT" className="font-bold">
                    State
                    </label>
                    <Dropdown
                    id="rm_ST_AT"
                    name="rm_ST_AT"
                    value={rm_ST_AT}
                    options={rm_ST_ATOptions}
                    onChange={(e) => onchangeDropDownFormData6("rm_ST_AT", e.value)}  
                    placeholder="Select State"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_MO_TR" className="font-bold">
                    Mode Of Transport
                    </label>
                    <Dropdown
                    id="rm_MO_TR"
                    name="rm_MO_TR"
                    value={rm_MO_TR}
                    options={rm_MO_TROptions}
                    onChange={(e) => onchangeDropDownFormData7("rm_MO_TR", e.value)}  
                    placeholder="Select  Mode Of Transport"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rm_CT_RY" className="font-bold">
                    Country
                    </label>
                    <Dropdown
                    id="rm_CT_RY"
                    name="rm_CT_RY"
                    value={rm_CT_RY}
                    options={rm_CT_RYOptions}
                    onChange={(e) => onchangeDropDownFormData8("rm_CT_RY", e.value)}  
                    placeholder="Select Country"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">  
                    <label htmlFor="rm_DI_CE" className="font-bold">
                    Distance
                    </label>
                    <InputText 
                      id="rm_DI_CE"
                      name="rm_DI_CE"
                      value={rm_DI_CE}
                      onChange={(e) => onchangedata(e)}
                    />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">  
                    <label htmlFor="rm_DI_CE" className="font-bold">
                    Distance  UM
                    </label>
                      <Dropdown
                        id="rm_DI_UM"
                        name="rm_DI_UM"
                        value={rm_DI_UM}
                        options={rm_DI_UMOptions}
                        onChange={(e) => onchangeDropDownFormData9("rm_DI_UM", e.value)}
                        placeholder="Select  UM"
                      />
                    </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rm_LD_TM" className="font-bold">
                  Lead Time
                  </label>
                  {/* <InputText
                    id="rm_LD_TM"
                    name="rm_LD_TM"
                    value={rm_LD_TM}
                    onChange={(Event) => onchangedata(Event)}

                  /> */
                  <InputMask
                  id={rm_LD_TM}
                  name={rm_LD_TM}
                  mask="99:99:99"
                  onBlur={(e) => { onChangeTime("rm_LD_TM",e.target.value,);}}
                  value={moment(rm_LD_TM, "HHmmss").format("HH:mm:ss")}
                />
                  }
                </div>
                </div>
            </div>
      </div>
      <AddressBookPopup
        visible={FacilityAddressPopupVisible}
        onClose={() => setFacilityAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
