import { Box, Center, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import './NavbarAdmin.css';
import axios from "axios";
export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  
  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  let mainText = useColorModeValue('navy.700', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '10px';
  let gap = '0px';
// tap navbar styles
  let navbarShadowT = 'lg';
  let navbarBgT = 'linear-gradient(90deg, #85c1e9, #5dade2 )';
  let navbarbgimage = 'linear-gradient(to right, #cce6ff, #afccff7d, #ccebff, #c8daf9b8)';
  // 'linear-gradient(90deg, #cceeff, #b3d9ff)'; 
//   'linear-gradient(90deg, #e8f8f5, #eafaf1)';

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

const token = localStorage.getItem("token");
const domain = localStorage.getItem("domain");
const company = localStorage.getItem("company");

// fetch nav bar name dynamically
const [productName, setproductName] = useState("");
useEffect(() => {
  const GetProductName = async () => {
    try {
      const response = await axios.get(
        // `${domain}/api/ClientMaster/Get`,
        `${domain}/api/ClientMaster/Get`,
        {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      if (data.cm_NA_ME) {
        setproductName(data.cm_NA_ME);
      } else {
        console.error("Invalid API response:", data);
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  GetProductName();
}, []); 

  return (
    <>
      <Flex
          w='100%'
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems={{ xl: 'center' }}
          mb={gap}
        >
        {/* Full-width Navbar */}
          <Box
            position={navbarPosition}
            w='100%'
            bg={navbarbgimage}
            boxShadow={navbarShadowT}
            borderColor={navbarBorder}
            borderRadius='0'
            borderBottom='1px solid #ccc'
            px={paddingX}
            py='5px'
            top='0'
            zIndex='999'
            display='flex'
            flexDirection={{ sm: 'row', md: 'row' }}
            justifyContent='space-between'
            alignItems='center'
            minHeight={{ sm: 'auto', md: '3rem',  lg:'3rem'}} 
            height={{ sm: 'auto', md: '3rem', lg:'3rem' }} 
            paddingY={{ sm: '5px', md: '10px' }} 
            marginLeft={{ sm: '-1rem', md: '-1rem' }} 
          >
            <Text 
              fontSize={{ sm: '15px', md: '22px', lg:'25px'}} 
              color={mainText} style={{fontFamily:'emoji'}} 
              mb={{ sm: '10px', md: '0' }} 
            >
              {/* log navbar name */}
              <span style={{fontWeight:'bold',color:'#241679',fontFamily:'sans-serif',fontSize:'20px'}}> {productName} </span> 
            </Text>

            <Box ms='auto' w={{ md: 'unset' }} style={{marginRight:'1rem'}}>
              <AdminNavbarLinks
                onOpen={props.onOpen}
                logoText={props.logoText}
                secondary={props.secondary}
                fixed={props.fixed}
                scrolled={scrolled}
              />
            </Box>
          </Box>
      </Flex>

      {/* Original Admin Navbar (sidebar top name )*/}
      <Box
        style={{ height:'3rem' }}
        className='SearchNavBar'
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition='center'
        backgroundSize='cover'
        marginTop={{ sm: '35px', md: '30px' , lg:'30px' }}
        // borderRadius='16px' 
        borderWidth='1.5px'
        borderStyle='solid'
        backgroundColor='#dfedff'
        // height='0%'
        transition='box-shadow 0.25s linear, background-color 0.25s linear'
        alignItems={{ xl: 'center' }}
        display={secondary ? 'block' : 'flex'}
        // minH='75px'
        justifyContent={{ xl: 'center' }}
        lineHeight='25.6px'
        mx='auto'
        mt={secondaryMargin}
        pb='8px'
        right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
        px={{
          sm: paddingX,
          md: '10px'
        }}
        ps={{
          xl: '12px'
        }}
        pt='8px'
        top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
        w={{
          base: 'calc(100vw - 6%)',
          md: 'calc(100vw - 8%)',
          lg: 'calc(100vw - 6%)',
          xl: 'calc(100vw - 350px)',
          '2xl': 'calc(100vw - 365px)'
        }}
      >
        <Flex style={{height:'3rem'}}
          w='100%'
          flexDirection={{
            sm: 'column',
            md: 'row'
          }}
          alignItems={{ xl: 'center' }}
          mb={gap}
        >
          <Box mb={{ sm: '8px', md: '0px' }} >
            <Link
              color={mainText}
              href='#'
              bg='inherit'
              borderRadius='inherit'
              fontWeight='bold'
              fontSize='25px'
              _hover={{ color: mainText }}
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}
            >
              {brandText}
            </Link>
          </Box>
          {/* <Box ms='auto' w={{ sm: '100%', md: 'unset' }}>
            <AdminNavbarLinks
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
            />
          </Box> */}
        </Flex>
        {secondary ? <Text color='white'>{message}</Text> : null}
      </Box>
    </>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func
};
