import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function SalesHierarchyDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  handleFormSubmit,
  sh_PD_GP,
  setsh_PD_GP,
  onchangeFormData,
  SalesHierarchyData,
  setSalesHierarchyData,
}) {
  const {
    shUkNo,
    sh_SQ_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const addRow = () => {
    setSalesHierarchyData([...SalesHierarchyData, {}]);
  };

  const deleteRow = () => {
    if (setSalesHierarchyData.length > 0) {
      const updatedData = [...SalesHierarchyData];
      updatedData.pop();
      setSalesHierarchyData(updatedData);
    }
  };
// dropdown
  const { fetchClassCodeDS } = ClassCode();
  const [sh_PD_GPOptions, setsh_PD_GPOptions] = useState([]);
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsh_PD_GP(value);
  };
  useEffect(() => {
    fetchDropdowntype();
  }, []);

  const fetchDropdowntype = async () => {
    const Options = await fetchClassCodeDS("IN/PD");
    setsh_PD_GPOptions(Options);
  };
  const isUpdateMode = Boolean(shUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={shUkNo ? "Revise Sales Hierarchy" : "Add Sales Hierarchy"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >

<div  style={tablestyle} >
    <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
    </div>
    <div  style={multitablestyle}>
    <table className="custom-table" id="SalesHierarchyTable" style={{overflow:"auto"}}>
  <thead>
    <tr>
      <th style={{ display: "none" }} >UK_NO</th>
      <th> Seq No </th>    
      <th> Product Group </th>
      
    </tr>
  </thead>
  <tbody>
  {SalesHierarchyData.map((row, index) => (
          <tr key={index}>
        <td style={{ display: "none" }} >
        <InputText
          id={`shUkNo_${index}`}
          name={`shUkNo_${index}`}
          value={row.shUkNo}
        />
      </td>
      <td>
      <InputText disabled
        id={`sh_SQ_NO_${index}`}
        name={`sh_SQ_NO_${index}`}
        value={index + 1}
      />
      </td>
      <td>
        <Dropdown
          id={`sh_PD_GP_${index}`}
          name={`sh_PD_GP_${index}`}
          value={data[`sh_PD_GP_${index}`] ? data[`sh_PD_GP_${index}`]:row.sh_PD_GP }
          options={sh_PD_GPOptions}
          onChange={(e) => onchangeDropDownFormData(`sh_PD_GP_${index}`, e.value)}
          placeholder="Select Product Grp."
        />
      </td>
      </tr>
        ))}
  </tbody>
</table> 
    </div>
</div>


    </Dialog>
  );
}
