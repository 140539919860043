import React, { useState, useRef, useEffect } from 'react';
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";


const CreateMainFolderPopup = ({ isOpen, onClose, onCreateMainFolder  }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [error, setError] = useState('');
  const [folderName, setFolderName] = useState('');
  // const [ setNewMainFolderName] = useState('');
  const [FormData, setFormData] = useState("");
  const toast = useRef(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [newMainFolderName, setnewMainFolderName] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(typeof onCreateMainFolder );
    if (newFolderName.trim() !== '') {
      onCreateMainFolder (newFolderName.trim());
      setNewFolderName('');
    } else {
      toast.current.show({ severity: "warn", summary: "Warning", detail: "Folder name cannot be empty." });
    }
  };




  useEffect(() => {
   
  }, []);

  return (
    isOpen && (

      <div className="popup-overlay">
        <Toast ref={toast} />
        <div className="popup-content">
          <h2>Create New Main Folder</h2>
        
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="newMainFolderName" className="font-bold">
              Creation Main Folder
            </label>
            <InputText style={{ width: "10rem" }}
              id="newMainFolderName"
              name="newMainFolderName"
              defaultValue={newMainFolderName}
              placeholder="Enter Folder Name"
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          </div>

          <form onSubmit={handleSubmit}>
            {error && <div className="error-message">{error}</div>}
            <button type="submit">Create</button>
            <button type="button" onClick={onClose} aria-label="Cancel creation of folder">Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default CreateMainFolderPopup;
