import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CustomerEnquiryDialog from "./CustomerEnquiryDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import { Form } from "react-bootstrap";
const initialValue = {
  sd_UK_NO:"",
  sh_OR_NO:"",
  sh_CO_CT:"",
  sh_OR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sh_RQ_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sdOrNo:"",
  sdOrTy:"",
  sd_OR_CO:"",
  sdLnNo:"",
  sd_CU_NO:"",
  Cust_Name:"",
  sd_SH_TO:"",
  sd_CO_CT:"",
  sd_ST_TY:"", 
  sd_IT_NM:"",
  sd_IT_NM2:"",
  sd_OR_QT:"",
  sd_TR_UM:"",
  sd_ST_CD:"",
  sd_SA_PE:"",
  Sales_name:"",
  sd_AS_TO:"",
  sd_CN_QT:"",
  sd_CN_DT:"",
  sd_PR_RF:"", 
  sd_RE_MK:"",sh_TR_CR:"",sh_AS_TO:"",sh_PR_RF:"",
  sh_RF_NO:"",sh_CO_PE:"",
};
const initialFilters = {
  ep_PO_NM: { value: "", operation: "" },
  po_NAME: { value: "", operation: "" },
  ep_EX_CT: { value: "", operation: "" },
  expanseCategoryDES: { value: "", operation: "" },
  ep_EX_TY: { value: "", operation: "" },
  expanseTypeDES: { value: "", operation: "" },
  ep_EF_DT: { value: "", operation: "" },
  ep_LO_CN: { value: "", operation: "" },
  ep_DA_AL: { value: "", operation: "" },
  ep_TL_PE: { value: "", operation: "" },
  ep_AU_AM: { value: "", operation: "" },
};
const CustomerEnquiryMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyle1
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [shUkNo, setshUkNo ] = useState("");
  const [sh_OR_NO, setsh_OR_NO ] = useState("");
  const [sh_TR_CR, setsh_TR_CR ] = useState("");
  const [sh_OR_TY, setsh_OR_TY ] = useState("");
  const [sh_OR_CO, setsh_OR_CO ] = useState("");
  const [sh_CO_CT, setsh_CO_CT ] = useState("");
  const [sh_AS_TO, setsh_AS_TO ] = useState("");
  const [sh_BS_CR, setsh_BS_CR ] = useState("");
  const [sd_TR_UM, setsd_TR_UM ] = useState("");
  const [sd_ST_TY, setsd_ST_TY ] = useState("");
  const [sh_CU_NO, setsh_CU_NO ] = useState("");
  const [sh_SH_TO, setsh_SH_TO ] = useState("");
  const [sh_EX_RT, setsh_EX_RT ] = useState("");
  const [is_LO_CN, setis_LO_CN ] = useState("");
  const [sd_CO_CT, setsd_CO_CT ] = useState("");
  const [sh_SA_PE, setsh_SA_PE ] = useState("");
  const [sd_ST_CD, setsd_ST_CD ] = useState("");
// AdvanceSearch
  const [selectedCustomer, setselectedCustomer ] = useState("");
  const [selectedShipto, setselectedShipto ] = useState("");
  const [selectedSales, setselectedSales ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [CustomerEnquiryTableData, setCustomerEnquiryTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;  
        if (column === 'sdOrNo') {
          constructedColumn = 'sdOrNo';
        } 
        else if (column === 'sdOrTy') {
          constructedColumn = 'sdOrTy';
        }
        else if (column === 'sdLnNo') {
          constructedColumn = 'sdLnNo';
        }
        else {
          constructedColumn = column.toUpperCase();
        }
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setCustomerEnquiryTableData([{}]);
    setshUkNo(null);
    setsh_OR_NO(null);
    setsh_CU_NO(null);
    setsh_SH_TO(null);
    setsh_TR_CR(null);
    setsh_EX_RT(null);
    setsh_AS_TO(null);
    setsh_SA_PE(null);
    setselectedCustomer(null);
    setselectedShipto(null);
    setselectedSales(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setshUkNo(null);
    setsh_OR_NO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CustomerEnquiry/Select/${selectedRows[0].sd_UK_NO}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CustomerData = response.data;
          delete CustomerData.salesHeader.shUkNo;
          delete CustomerData.salesHeader.sh_OR_NO;
          for (let i = 0; i < CustomerData.salesDetail.length; i++) {
            delete CustomerData.salesDetail[i].sd_UK_NO;
            // CustomerData.salesDetail[i].sd_ST_CD = sd_ST_CD;
          }
          setCustomerEnquiryTableData(CustomerData.salesDetail);
          setFormData(CustomerData.salesHeader);
          setsh_TR_CR(CustomerData.salesHeader.sh_TR_CR);
          setsh_CU_NO(CustomerData.salesHeader.sh_CU_NO);
          setsh_SH_TO(CustomerData.salesHeader.sh_SH_TO);
          setsh_EX_RT(CustomerData.salesHeader.sh_EX_RT);
          if(CustomerData.salesHeader.sh_AS_TO){
            setsh_AS_TO(CustomerData.salesHeader.sh_AS_TO.toString());
          }
          setsh_SA_PE(CustomerData.salesHeader.sh_SA_PE);
          setselectedCustomer(CustomerData.salesHeader.cus_NAME);
          setselectedShipto(CustomerData.salesHeader.shipto_NAME);
          setselectedSales(CustomerData.salesHeader.salesPerson_DES);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsh_CO_CT(value);
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setsh_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "SH_BS_CR"){
              setsh_BS_CR(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "SH_OR_TY"){
              setsh_OR_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SH_CO_CT"){
              onchangeDropDownFormData1("sh_CO_CT",dataDefault.versionIds[i].default_VALUE);
              setsh_CO_CT(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SD_CO_CT"){
              setsd_CO_CT(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "IS_LO_CN"){
              setis_LO_CN(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SD_ST_CD"){
              setsd_ST_CD(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CustomerEnquiry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const OnChangeCustomerTableData = (key, value, index) => {
    const updatedData = [...CustomerEnquiryTableData];
    updatedData[index][key] = value;
    setCustomerEnquiryTableData(updatedData);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  // const handleRowSelect = (rowId) => {
  //   const isRowSelected = selectedRows.includes(rowId);
  //   if (!isRowSelected) {
  //     setSelectedRows([rowId]);
  //   } else {
  //     setSelectedRows([]);
  //   }
  //   setIsRecordSelected(!isRowSelected);
  // };
const handleUpdate = async () => {
  if (!isRecordSelected || selectedRows.length > 1) {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: isRecordSelected
        ? "Select a single record to edit."
        : "Select only one record to edit.",
      life: 3000,
    });
    return;
  }
  setFormData(initialValue);
  try {
    const response = await axios.get(
      `${domain}/api/CustomerEnquiry/Select/${selectedRows[0].sd_UK_NO}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      }
    );
    if (response.status === 200) {
      const CustomerData = response.data;
      setCustomerEnquiryTableData(CustomerData.salesDetail);
      setFormData(CustomerData.salesHeader);
      setshUkNo(CustomerData.salesHeader.shUkNo);
      setsh_TR_CR(CustomerData.salesHeader.sh_TR_CR);
      setsh_OR_NO(CustomerData.salesHeader.sh_OR_NO);
      setsh_CU_NO(CustomerData.salesHeader.sh_CU_NO);
      setsh_SH_TO(CustomerData.salesHeader.sh_SH_TO);
      setsh_EX_RT(CustomerData.salesHeader.sh_EX_RT);
      if(CustomerData.salesHeader.sh_AS_TO){
        setsh_AS_TO(CustomerData.salesHeader.sh_AS_TO.toString());
      }
      setsh_SA_PE(CustomerData.salesHeader.sh_SA_PE);
      setselectedCustomer(CustomerData.salesHeader.cus_NAME);
      setselectedShipto(CustomerData.salesHeader.shipto_NAME);
      setselectedSales(CustomerData.salesHeader.salesPerson_DES);
      handleClickOpen();
    } else {
      console.error(`API request failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
var requestBody = {};
function values () {
  const salesHeader = {
    shUkNo:  document.getElementById("shUkNo").value,
    sh_OR_NO: document.getElementById("sh_OR_NO").value,
    sh_OR_TY: document.getElementById("sh_OR_TY").value,
    sh_OR_CO: document.getElementById("sh_OR_CO").value,
    sh_CO_CT: sh_CO_CT,
    sh_OR_DT: FormData.sh_OR_DT,
    sh_RQ_DT: FormData.sh_RQ_DT,
    sh_CU_NO: document.getElementById("sh_CU_NO").value,
    sh_SH_TO: document.getElementById("sh_SH_TO").value,
    sh_SA_PE: document.getElementById("sh_SA_PE").value,
    sd_SA_PE: document.getElementById("sh_SA_PE").value,
    sh_TR_CR: FormData.sh_TR_CR,
    sh_EX_RT: document.getElementById("sh_EX_RT").value,
    sh_BS_CR: document.getElementById("sh_BS_CR").value,
    sh_AS_TO: FormData.sh_AS_TO,
    sh_RF_NO: FormData.sh_RF_NO,
    sh_PR_RF: FormData.sh_PR_RF,
    sh_CO_PE: FormData.sh_CO_PE,
  };
  const table = document.getElementById("CustomerEnquirytable");
  const totalRowCount = table.tBodies[0].rows.length;
  const salesDetail = [];
  for (let i = 0; i < totalRowCount; i++) {
    const Details = {
        sd_UK_NO : document.getElementById("sd_UK_NO_" + i).value,
        sdLnNo : document.getElementById("sdLnNo_" + i).value,
        sd_OR_CO : company,
        sd_IT_NM : document.getElementById("sd_IT_NM_" + i).value,
        sd_CO_CT : document.getElementById("sd_CO_CT_" + i).value,
        sd_OR_QT : document.getElementById("sd_OR_QT_" + i).value,
        sd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        sd_TR_ST : document.getElementById("sd_TR_ST_" + i).value,
        sd_ST_TY : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        sd_ST_CD : document.getElementById("sd_ST_CD_" + i).value,
        sd_CN_QT : document.getElementById("sd_CN_QT_" + i).value,
        sd_RE_MK : document.getElementById("sd_RE_MK_" + i).value,
    };
    salesDetail.push(Details);
  }
 requestBody = {
  salesDetail,
  salesHeader,   
  };
};
const handleFormSubmit = () => {
  values ();
  if(document.getElementById("sh_OR_NO").value){
  if (FormData.sd_UK_NO) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/CustomerEnquiry/Update`;
    if (confirm) {
      axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    axios.post(`${domain}/api/CustomerEnquiry/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }}else {
        
    const OR_DT = FormData.sh_OR_DT;
    if (OR_DT) {
      const currentYear = OR_DT.toString();
      const firstHalf = currentYear.substring(0, 2);
      const secondHalf = currentYear.substring(2, 4);
      const urlNextNumber = `${domain}/api/DocumentNumber/All`;
      const parameters = `${sh_OR_TY}/${secondHalf}/1000/${firstHalf}`;
      const finalUrl1 = `${urlNextNumber}/${parameters}`;
      axios.get(finalUrl1, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        setsh_OR_NO(resp.dn_DO_NO);
          document.getElementById("sh_OR_NO").value = resp.data.dn_DO_NO;
          if(resp.data.dn_DO_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/CustomerEnquiry/Add`, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }
};
const handleRowSelect = (rowData) => {
  const isRowSelected = selectedRows.some(row => row.sd_UK_NO === rowData.sd_UK_NO);
  if (!isRowSelected) {
    setSelectedRows([rowData]);
  } else {
    setSelectedRows([]);
  }
  setIsRecordSelected(!isRowSelected);
};
// generation 
const GenerateQuote = async () => {
  if (selectedRows.length === 0) {
    console.error("No row selected");
    return;
  }
  const selectedRow = selectedRows[0];

  const  GenUrl = `${domain}/api/CustomerEnquiry/GenerateQuoteForCustomerEnquiry`
  const Request = {
    "salesHeader":{
      "sh_OR_TY": selectedRow.sdOrTy,
      "sh_OR_NO": selectedRow.sdOrNo,
    }}
  try {
  const response = await axios.post(GenUrl, Request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });
  const dataQT = response.data;
  if(dataQT) {
    handleResponse(response.data);
  } else {
    console.error(`API request failed with status: ${response.status}`);
  }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Update
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Add
      </div>
  </Tooltip>
    <Button 
      style={addButtonStyles}
      icon="pi pi-plus"     
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Copy
      </div>
  </Tooltip>
    <Button 
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
        <Tooltip target=".invoice-button" position="bottom" mouseTrack>
        <div style={{ backgroundColor: "transparent !important", color: "white",  fontSize: "10px", }} >
          Generate
        </div>
      </Tooltip>
      <Button 
        // style={GenerateStyles}
        style={{backgroundColor:"#0099FF",color:'white',border:"none",lineHeight: "10%",}}
        icon="pi pi-file"
        outlined
        className="mr-2 invoice-button"
        onClick={GenerateQuote}
        disabled={!isRecordSelected}
      ></Button>
  </React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
return (
  <div className="tableData" style={{ marginTop: "5%" }}>
    <Toast ref={toast} />
    <div className="card">
      <Toolbar
        className="p-mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      />
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <td style={{width:'40px'}}>
                <input
                  type="text"
                  style={checkboxStyle}
                  readOnly
                  onMouseDown={(e) => e.preventDefault()}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sdOrNo",
                      e.target.value,
                      filters["sdOrNo"]
                        ? filters["sdOrNo"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sdOrTy",
                      e.target.value,
                      filters["sdOrTy"] ? filters["sdOrTy"].operation : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_OR_CO",
                      e.target.value,
                      filters["sd_OR_CO"]
                        ? filters["sd_OR_CO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sdLnNo"] ? filters["sdLnNo"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sdLnNo",
                      e.target.value,
                      filters["sdLnNo"]
                        ? filters["sdLnNo"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_CU_NO"] ? filters["sd_CU_NO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_CU_NO",
                      e.target.value,
                      filters["sd_CU_NO"]
                        ? filters["sd_CU_NO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input disabled
                  type="text"
                  value={filters["Cust_Name"] ? filters["Cust_Name"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "Cust_Name",
                      e.target.value,
                      filters["Cust_Name"] ? filters["Cust_Name"].operation : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SH_TO",
                      e.target.value,
                      filters["sd_SH_TO"]
                        ? filters["sd_SH_TO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td> 
              <td>
                <input
                  type="text"
                  value={filters["sd_CO_CT"] ? filters["sd_CO_CT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_CO_CT",
                      e.target.value,
                      filters["sd_CO_CT"]
                        ? filters["sd_CO_CT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["sd_OR_DT"] ? filters["sd_OR_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_OR_DT",
                      e.target.value,
                      filters["sd_OR_DT"]
                        ? filters["sd_OR_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["sd_RQ_DT"] ? filters["sd_RQ_DT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_RQ_DT",
                      e.target.value,
                      filters["sd_RQ_DT"]
                        ? filters["sd_RQ_DT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["sd_ST_TY"] ? filters["sd_ST_TY"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_ST_TY",
                      e.target.value,
                      filters["sd_ST_TY"]
                        ? filters["sd_ST_TY"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_IT_NM",
                      e.target.value,
                      filters["sd_IT_NM"]
                        ? filters["sd_IT_NM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input disabled
                  type="text"
                  value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "im_IT_DS",
                      e.target.value,
                      filters["im_IT_DS"]
                        ? filters["im_IT_DS"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_OR_QT"] ? filters["sd_OR_QT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_OR_QT",
                      e.target.value,
                      filters["sd_OR_QT"] ? filters["sd_OR_QT"].operation : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_TR_UM",
                      e.target.value,
                      filters["sd_TR_UM"]
                        ? filters["sd_TR_UM"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              
              <td>
                <input
                  type="text"
                  value={filters["sd_SA_PE"] ? filters["sd_SA_PE"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_SA_PE",
                      e.target.value,
                      filters["sd_SA_PE"] ? filters["sd_SA_PE"].operation : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>
              <td>
                <input disabled
                  type="text"
                  value={filters["Sales_name"] ? filters["Sales_name"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "Sales_name",
                      e.target.value,
                      filters["Sales_name"]
                        ? filters["Sales_name"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle1}
                />
              </td> 
              <td>
                <input
                  type="text"
                  value={filters["sd_AS_TO"] ? filters["sd_AS_TO"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_AS_TO",
                      e.target.value,
                      filters["sd_AS_TO"]
                        ? filters["sd_AS_TO"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>   
              <td>
                <input
                  type="text"
                  value={filters["sd_CN_QT"] ? filters["sd_CN_QT"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_CN_QT",
                      e.target.value,
                      filters["sd_CN_QT"]
                        ? filters["sd_CN_QT"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td>  
              <td>
                <input
                  type="text"
                  value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "sd_ST_CD",
                      e.target.value,
                      filters["sd_ST_CD"]
                        ? filters["sd_ST_CD"].operation
                        : "equals"
                    )
                  }
                  style={inputStyle}
                />
              </td> 
            </tr>
            <tr>
              <th style={tableHeaderStyle}></th>
              <th style={tableHeaderStyle}>Order No </th>
              <th style={tableHeaderStyle}>Type</th>
              <th style={tableHeaderStyle}>Company</th>  
              <th style={tableHeaderStyle}>Line No</th>   
              <th style={tableHeaderStyle}>Customer No</th>  
              <th style={tableHeaderStyle}>Name</th> 
              <th style={tableHeaderStyle}>Ship To</th>  
              <th style={tableHeaderStyle}>Cost Centre</th>  
              <th style={tableHeaderStyle}>Order Date</th> 
              <th style={tableHeaderStyle}>Requested Date</th>  
              <th style={tableHeaderStyle}>Type</th>    
              <th style={tableHeaderStyle}>Item Number</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Order Quantity</th>
              <th style={tableHeaderStyle}>UM</th>     
              <th style={tableHeaderStyle}>Sales Person</th>  
              <th style={tableHeaderStyle}>Name</th> 
              <th style={tableHeaderStyle}>Assigned To</th>  
              <th style={tableHeaderStyle}>Cancelled Quantity</th>  
              <th style={tableHeaderStyle}>Status</th>  

            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((rowData, index) => (
                <tr
                  key={rowData.sd_UK_NO}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "aliceblue" : "#ffffff",
                  }}
                  className="table-row"
                >
                  <td style={tableCellStyle}>
                    <input
                      style={{ width: "10% !important" }}
                      type="checkbox"
                      value={rowData.sd_UK_NO}
                      // checked={selectedRows.includes(rowData.sd_UK_NO) }
                      // onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      checked={selectedRows.some(row => row.sd_UK_NO === rowData.sd_UK_NO)}
                      onChange={() => handleRowSelect(rowData)}
                    />
                  </td>
                  <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                  <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                  <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>   
                  <td style={tableCellStyle}>{rowData.sdLnNo}</td>
                  <td style={tableCellStyle}>{rowData.sd_CU_NO}</td>
                  <td style={tableCellStyle}>{rowData.name}</td>   
                  <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                  <td style={tableCellStyle}>{rowData.sd_CO_CT}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.sd_OR_DT)}</td>
                  <td style={tableCellStyle}>{formatDate(rowData.sd_RQ_DT)}</td>
                  <td style={tableCellStyle}>{rowData.sd_ST_TY}</td> 
                  <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                  <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                  <td style={tableCellStyle}>{rowData.sd_OR_QT}</td>   
                  <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                  <td style={tableCellStyle}>{rowData.sd_SA_PE}</td>   
                  <td style={tableCellStyle}>{rowData.salesPerson_DES}</td>   
                  <td style={tableCellStyle}>{rowData.sd_AS_TO}</td>
                  <td style={tableCellStyle}>{rowData.sd_CN_QT}</td>  
                  <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>                 
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    {/* pagination */}
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
    </div>
    <CustomerEnquiryDialog
      open={open}
      handleClose={handleClose}
      data={FormData}
      handleFormSubmit={handleFormSubmit}
      onchangedata={onchangedata}
      onchangeFormData={onchangeFormData}
      setFormData={setFormData}
      CustomerEnquiryTableData={CustomerEnquiryTableData}
      setCustomerEnquiryTableData={setCustomerEnquiryTableData}
      sh_OR_CO={sh_OR_CO}
      sh_OR_NO={sh_OR_NO}
      setsh_OR_NO={setsh_OR_NO}
      sh_OR_TY={sh_OR_TY}
      setsh_OR_TY={setsh_OR_TY}
      sh_CO_CT={sh_CO_CT}
      setsh_CO_CT={setsh_CO_CT}
      sh_AS_TO={sh_AS_TO}
      setsh_AS_TO={setsh_AS_TO}
      sh_BS_CR={sh_BS_CR}
      sd_TR_UM={sd_TR_UM}
      setsd_TR_UM={setsd_TR_UM}
      sd_ST_TY={sd_ST_TY}
      setsd_ST_TY={setsd_ST_TY}
      sh_TR_CR={sh_TR_CR}
      setsh_TR_CR={setsh_TR_CR}
      sh_CU_NO={sh_CU_NO}
      setsh_CU_NO={setsh_CU_NO}
      sh_SH_TO={sh_SH_TO}
      setsh_SH_TO={setsh_SH_TO}
      sh_EX_RT={sh_EX_RT}
      setsh_EX_RT={setsh_EX_RT}
      shUkNo={shUkNo}
      setshUkNo={setshUkNo}
      is_LO_CN={is_LO_CN}
      setis_LO_CN={setis_LO_CN}
      sd_CO_CT={sd_CO_CT}
      setsd_CO_CT={setsd_CO_CT}
      sh_SA_PE={sh_SA_PE}
      setsh_SA_PE={setsh_SA_PE}
      sd_ST_CD={sd_ST_CD}
      selectedCustomer={selectedCustomer}
      setselectedCustomer={setselectedCustomer}
      selectedShipto={selectedShipto}
      setselectedShipto={setselectedShipto}
      selectedSales={selectedSales}
      setselectedSales={setselectedSales}
      onchangeDropDownFormData1={onchangeDropDownFormData1}
      OnChangeCustomerTableData={OnChangeCustomerTableData}
    />
  </div>
);
};

export default CustomerEnquiryMain;
