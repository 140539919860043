import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Calendar } from "primereact/calendar";


const TerminatePopup = ({ visibleTerminate,onCloseTerminate, data, handleResponse,Terminate, onchangeFormData,handleUpdate}) => {
  const {li_UK_NO,li_TE_NT,li_ST_DT,li_EN_DT,li_EX_DT,li_TR_BY,li_TR_RS,li_TR_PN,li_TR_TX,li_TR_AM,li_MN_RN } = data;
  const [formData, setFormData] = useState(data);
  const toast = useRef(null);
  const { borderbox, inboxstyle } = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleCloseTerminate = () => {
    onCloseTerminate();
  };
  useEffect(() => {
    setFormData(data);
    handleUpdate() // Initialize form data with passed data prop
  }, [data]);
  const handleTerminateClick = () => {
    Terminate(formData, handleCloseTerminate); 
  };
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "10%" }}
        icon="pi pi-check"
        variant="outlined"
        onClick={handleTerminateClick}
        tooltip="Terminate"
      />
    </React.Fragment>
  );
  const TotalAmount = async () => {
    const Url = `${domain}/api/LeaseInformation/GetTerminateLeaseTotalAmount`;
    const requestData = {
      "li_MN_RN" : formData.li_MN_RN,
      "li_TR_TX" : formData.li_TR_TX, 
    
    };
  
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dateTotalAmount = response.data.Result;
      if (dateTotalAmount) {
        onchangeFormData("li_TR_PN", dateTotalAmount.PenaltyAmount);
        onchangeFormData("li_TR_AM", dateTotalAmount.TotalAmount);
        
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Dialog id="dialog-style"
      visible={visibleTerminate}
      onHide={handleCloseTerminate}
      style={{ width: "40rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Terminate Details"
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >      
      <Toast ref={toast} />
      <div style={borderbox}>
        <div style={inboxstyle}>
        <div className="formgrid grid">
        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TE_NT" className="font-bold">
                Tenant
              </label>
              <InputText
                id="li_TE_NT"
                name="li_TE_NT"
                value={formData.li_TE_NT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_ST_DT" className="font-bold">
              StartDate
              </label>
              <InputText
                id="li_ST_DT"
                name="li_ST_DT"
                value={formData.li_ST_DT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_EN_DT" className="font-bold">
              EndDate
              </label>
              <InputText
                id="li_EN_DT"
                name="li_EN_DT"
                value={formData.li_EN_DT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
         </div>
          <div className="formgrid grid">
            <div style={{display:"none"}}>
              <label htmlFor="li_UK_NO" className="font-bold">
                UK_NO
              </label>
              <InputText
                id="li_UK_NO"
                name="li_UK_NO"
                value={formData.li_UK_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div style={{display:"none"}}>
              <label htmlFor="li_MN_RN" className="font-bold">
                MonthRent
              </label>
              <InputText
                id="li_MN_RN"
                name="li_MN_RN"
                value={formData.li_MN_RN}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            {/* <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TR_DT" className="font-bold">
              ExitDate
              </label>
              <Calendar
                id="li_EX_DT"
                name="li_EX_DT"
                value={moment(formData.li_EX_DT, "YYYYMMDD").isValid() ? moment(formData.li_TR_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "li_EX_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "" } })}
                dateFormat="dd/mm/yy"
              />
            </div> */}
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="li_EX_DT" className="font-bold">
             Exit Date
            </label>
          <Calendar
               id="li_EX_DT"
               name="li_EX_DT"
               value={moment(formData.li_EX_DT, "YYYYMMDD").isValid() ? new Date(moment(formData.li_EX_DT, "YYYYMMDD")) : null}
               onChange={(e) => onchangedata({ target: { name: "li_EX_DT", value: moment(e.value).format("YYYYMMDD") } })}
               dateFormat="dd/mm/yy"
               onBlur={TotalAmount} // Call TotalAmount function onBlur of Calendar component
           />
           </div>

            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TR_BY" className="font-bold">
              TerminationBy
              </label>
              <InputText
                id="li_TR_BY"
                name="li_TR_BY"
                value={formData.li_TR_BY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TR_RS" className="font-bold">
                Reason
              </label>
              <InputText
                id="li_TR_RS"
                name="li_TR_RS"
                value={formData.li_TR_RS}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
          <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TR_PN" className="font-bold">
              PenaltyAmount
              </label>
              <InputText
                id="li_TR_PN"
                name="li_TR_PN"
                value={formData.li_TR_PN}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="li_TR_TX" className="font-bold">
                Tax
              </label>
              <InputText
                id="li_TR_TX"
                name="li_TR_TX"
                value={formData.li_TR_TX}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor=" li_TR_AM" className="font-bold">
                Total
              </label>
              <InputText
                id="li_TR_AM"
                name="li_TR_AM"
                value={formData.li_TR_AM}
                onChange={(e) => onchangedata(e)}
              />
            </div>


          </div>
        </div>
      </div>
    </Dialog>
  );
};

TerminatePopup.propTypes = {
  visibleTerminate: PropTypes.bool.isRequired,
  onCloseTerminate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleResponse: PropTypes.func.isRequired,
};

export default TerminatePopup;
