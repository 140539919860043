import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import EquipmentPopup from "../Popups/EquipmentPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function MaintainanceWorkOrderDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  setwm_CC_NM,
  setwm_FA_CD,
  setwm_OR_UM,
  setwm_PR_TY,
  wm_OR_NO,
  wm_OR_TY,
  wm_OR_CO,
  wm_PR_TY,
  wm_OR_UM,
  wm_FA_CD,
  wm_CC_NM,
  SelectedEquipment,
  setSelectedEquipment,
  SelectedSuprPrd,
  setSelectedSuprPrd,
  SelectedCustomer,
  setSelectedCustomer,
  SelectedSupervisor,
  setSelectedSupervisor,
  onchangeDropDownFormData1
}) {
  const {
    wmUkNo,
    wm_EQ_NM,
    wm_OR_QT,
    wm_IS_DS,
    wm_SR_PD,
    wm_CO_NO,
    wm_SU_NO,
    wm_CU_NO,
    wm_SR_DT,
    wm_MT_RD,
    wm_NX_SD,
    wm_AC_TM,
    wm_ET_TM,
    wm_ST_DT,
    wm_CO_DT,
    wm_ST_TM,
    wm_CO_TM,
    wm_ST_CD,
    wm_PD_MD,
    wm_PD_FM,

  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "wm_ST_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [wm_CC_NMOptions, setwm_CC_NMOptions] = useState([]);
  const [wm_FA_CDOptions, setwm_FA_CDOptions] = useState([]);

  const [wm_OR_UMOptions, setwm_OR_UMOptions] = useState([]);
  const [wm_PR_TYOptions, setwm_PR_TYOptions] = useState([]);

// Failure code
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwm_FA_CD(value);
  };
// um
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwm_OR_UM(value);
  };
  // Priority
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwm_PR_TY(value);
  };
  useEffect(() => {
    fetchDropdownCOst();
    fetchDropdownFL();
    fetchDropdownUM();
    fetchDropdownPR();
  }, []);

  const fetchDropdownCOst = async () => {
    const Options = await fetchCostCenter();
    setwm_CC_NMOptions(Options);
  };
  const fetchDropdownFL = async () => {
    const Options = await fetchClassCodeDS("EM/FA");
    setwm_FA_CDOptions(Options);
  };
  const fetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setwm_OR_UMOptions(Options);
  };
  const fetchDropdownPR = async () => {
    const Options = await fetchClassCodeDS("WO/PR");
    setwm_PR_TYOptions(Options);
  };
  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [EquipmentPopupVisible, setEquipmentPopupVisible] = useState(false);
  const [ServicePrdPopupVisible, setServicePrdPopupVisible] =useState(false);
  const [SupervisorPopupVisible, setSupervisorPopupVisible] = useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] =useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [addressTypesuprprd, setaddressTypesuprprd] = useState("S");
  const [addressTypesupervsr, setaddressTypesupervsr] = useState("E");
  const openEquipmentPopup = () => {
    setEquipmentPopupVisible(true);
    setShowSearchIcon(false);
  };
  const handleSelectEquipment = (data) => {
    setSelectedEquipment(data.em_EQ_DS);
    onchangeFormData("wm_EQ_NM", data.em_EQ_NM);
    // document.getElementById("wm_EQ_NM").value = data.em_EQ_NM;
    document.getElementById("wm_PD_MD").value = data.em_PD_MD;
    document.getElementById("wm_PD_FM").value = data.em_PD_FM;
    setEquipmentPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openServicePrdPopup = () => {
    setServicePrdPopupVisible(true);
    setShowSearchIcon(false);
  };
  const handleDataSelectServiceprd = (data) => {
    setSelectedSuprPrd(data.ab_AD_NM);
    onchangeFormData("wm_SR_PD", data.abAdNo);
    setServicePrdPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openSupervisorPopup = () => {
    setSupervisorPopupVisible(true);
    setShowSearchIcon(false);
  };
  const handleDataSelectSupervisor = (data) => {
    setSelectedSupervisor(data.ab_AD_NM);
    onchangeFormData("wm_SU_NO", data.abAdNo);
    setSupervisorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
    setShowSearchIcon(false);
  };
  const handleDataSelectCustomer = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    onchangeFormData("wm_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(wmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{wmUkNo ? "Revise Maintainance Work Order" : "Add Maintainance Work Order"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div style={{display:"none"}}>
                  <label htmlFor="wmUkNo" className="font-bold">
                   uk_no
                  </label>
                    <InputText 
                      id="wmUkNo"
                      name="wmUkNo"
                      value={wmUkNo}
                    />
                  </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_OR_NO" className="font-bold">
                    Order No
                    </label>
                    <InputText disabled
                      id="wm_OR_NO"
                      name="wm_OR_NO"
                      value={wm_OR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_OR_TY" className="font-bold">
                      Type
                    </label>
                    <InputText disabled
                      id="wm_OR_TY"
                      name="wm_OR_TY"
                      value={wm_OR_TY}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_OR_CO" className="font-bold">
                      Company
                    </label>
                    <InputText disabled
                      id="wm_OR_CO"
                      name="wm_OR_CO"
                      value={wm_OR_CO}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_CC_NM" className="font-bold">
                      Cost Centre
                    </label>
                    <Dropdown
                    id="wm_CC_NM"
                    name="wm_CC_NM"
                    value={wm_CC_NM}
                    options={wm_CC_NMOptions}
                    onChange={(e) => onchangeDropDownFormData1("wm_CC_NM", e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_FA_CD" className="font-bold">
                      Failure Code
                    </label>
                    <Dropdown
                    name="wm_FA_CD"
                    value={wm_FA_CD}
                    options={wm_FA_CDOptions}
                    onChange={(e) => onchangeDropDownFormData2("wm_FA_CD", e.value)}
                    placeholder="Select Failure Code"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_CO_NO" className="font-bold">
                     Contact Number
                    </label>
                    <InputText
                      id="wm_CO_NO"
                      name="wm_CO_NO"
                      value={wm_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_EQ_NM" className="font-bold">
                   Equipment Name <span style={{color:"red"}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText 
                      id="em_EQ_DS"
                      name="em_EQ_DS"
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={SelectedEquipment}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEquipmentPopup}
                    ></i>
                    )}
                    </div>
                    <InputText style={{display:"none"}}
                      id="wm_EQ_NM"
                      name="wm_EQ_NM"
                      value={wm_EQ_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_PD_MD" className="font-bold">
                      Product Model
                    </label>
                    <InputText disabled
                      id="wm_PD_MD"
                      name="wm_PD_MD"
                      value={wm_PD_MD}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_PD_FM" className="font-bold">
                      Product Family
                    </label>
                    <InputText disabled
                      id="wm_PD_FM"
                      name="wm_PD_FM"
                      value={wm_PD_FM}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_OR_QT" className="font-bold">
                      Quantity
                    </label>
                    <InputText
                      id="wm_OR_QT"
                      name="wm_OR_QT"
                      value={wm_OR_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_OR_UM" className="font-bold">
                      UM
                    </label>
                    <Dropdown
                      name="wm_OR_UM"
                      value={wm_OR_UM}
                      options={wm_OR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData5("wm_OR_UM", e.value)}
                      placeholder="Select UM"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_IS_DS" className="font-bold">
                   Issue
                    </label>
                    <InputText
                      id="wm_IS_DS"
                      name="wm_IS_DS"
                      value={wm_IS_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_SR_PD" className="font-bold">
                   Service Provider <span style={{color:"red"}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={SelectedSuprPrd}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openServicePrdPopup}
                    ></i>
                    )}
                    </div>
                    <InputText style={{display:"none"}}
                      id="wm_SR_PD"
                      name="wm_SR_PD"
                      value={wm_SR_PD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                 
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_SU_NO" className="font-bold">
                      Supervisor <span style={{color:"red"}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={SelectedSupervisor}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSupervisorPopup}
                    ></i>
                    )}
                    </div>
                    <InputText style={{display:"none"}}
                      id="wm_SU_NO"
                      name="wm_SU_NO"
                      value={wm_SU_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_CU_NO" className="font-bold">
                      Customer <span style={{color:"red"}}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedCustomer}
                    />
                      {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                    )}
                    </div>
                    <InputText style={{display:"none"}}
                      id="wm_CU_NO"
                      name="wm_CU_NO"
                      value={wm_CU_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_SR_DT" className="font-bold">
                      Service Date
                    </label>
                    <Calendar
                      id="wm_SR_DT"
                      name="wm_SR_DT"
                      value={moment(data.wm_SR_DT, "YYYYMMDD").isValid() ? moment(data.wm_SR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "wm_SR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_AC_TM" className="font-bold">
                      Down Time
                    </label>
                    {/* <InputText
                      id="wm_AC_TM"
                      name="wm_AC_TM"
                      value={wm_AC_TM}
                      onChange={(e) => onchangedata(e)}
                    /> */}
                    <InputMask
                      id={wm_AC_TM}
                      name={wm_AC_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("wm_AC_TM",e.target.value,);}}
                      value={moment(wm_AC_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_ST_DT" className="font-bold">
                      Start Date
                    </label>
                    {/* <Calendar
                      id="wm_ST_DT"
                      name="wm_ST_DT"
                      value={moment(data.wm_ST_DT, "YYYYMMDD").isValid() ? moment(data.wm_ST_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "wm_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    /> */}
                    <Calendar
                        id="wm_ST_DT"
                        name="wm_ST_DT"
                        value={moment(wm_ST_DT, "YYYYMMDD").toDate()}
                        onChange={handleDateChange}
                        dateFormat="dd/mm/yy"
                      />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_NX_SD" className="font-bold">
                      Next Service Date
                    </label>
                    <Calendar
                      id="wm_NX_SD"
                      name="wm_NX_SD"
                      value={moment(data.wm_NX_SD, "YYYYMMDD").isValid() ? moment(data.wm_NX_SD, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "wm_NX_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_ET_TM" className="font-bold">
                      Est. Down Time
                    </label>
                    <InputMask
                      id={wm_ET_TM}
                      name={wm_ET_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("wm_ET_TM",e.target.value,);}}
                      value={moment(wm_ET_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_CO_DT" className="font-bold">
                      Completion Date
                    </label>
                    <Calendar
                      id="wm_CO_DT"
                      name="wm_CO_DT"
                      value={moment(data.wm_CO_DT, "YYYYMMDD").isValid() ? moment(data.wm_CO_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "wm_CO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_MT_RD" className="font-bold">
                      Meter Reading
                    </label>
                    <InputText
                      id="wm_MT_RD"
                      name="wm_MT_RD"
                      value={wm_MT_RD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_ST_TM" className="font-bold">
                      Start Time
                    </label>
                    <InputMask
                      id={wm_ST_TM}
                      name={wm_ST_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("wm_ST_TM",e.target.value,);}}
                      value={moment(wm_ST_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_CO_TM" className="font-bold">
                      Completion Time
                    </label>
                    <InputMask
                      id={wm_CO_TM}
                      name={wm_CO_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("wm_CO_TM",e.target.value,);}}
                      value={moment(wm_CO_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_ST_CD" className="font-bold">
                      WO Status
                    </label>
                    <InputText disabled
                      id="wm_ST_CD"
                      name="wm_ST_CD"
                      value={wm_ST_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wm_PR_TY" className="font-bold">
                      Priority
                    </label>
                    <Dropdown
                      name="wm_PR_TY"
                      value={wm_PR_TY}
                      options={wm_PR_TYOptions}
                      onChange={(e) => onchangeDropDownFormData6("wm_PR_TY", e.value)}
                      placeholder="Select Priority"
                    />
                  </div>
                </div>
            </div>
      </div>
      <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentPopupVisible(false)}
        onDataSelect={handleSelectEquipment}
        formData={data}
      />
       {/* SERVICE PRD */}
       <AddressBookPopup
        visible={ServicePrdPopupVisible}
        onClose={() => setServicePrdPopupVisible(false)}
        onDataSelect={handleDataSelectServiceprd}
        formData={data}
        addressType={addressTypesuprprd}
        setFormData={setFormData}
      />
      {/* custoemr */}
        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelectCustomer}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* SUPERVISOR */}
        <AddressBookPopup
        visible={SupervisorPopupVisible}
        onClose={() => setSupervisorPopupVisible(false)}
        onDataSelect={handleDataSelectSupervisor}
        formData={data}
        addressType={addressTypesupervsr}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
