import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ProductRegistrationDialog from "./ProductRegistrationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  prUkNo:"",
  pr_EQ_NM:"",
  pr_EQ_DS:"",
  pr_ST_NO:"",
  pr_CU_NO:"",
  pr_DE_AL:"",
  pr_SR_NO:"",
  pr_IT_NM:"",
  pr_PD_MD:"",
  pr_PD_FM:"",
  pr_LT_NO:"",
  pr_IN_DT:"",
  pr_SE_DT:"",
  pr_AQ_DT:"",
  pr_RG_ST:"",
  pr_AS_NO:"",
};

const initialFilters = {
	pr_EQ_NM: { value: "", operation: "" },
  pr_EQ_DS: { value: "", operation: "" },
  pr_SR_NO: { value: "", operation: "" },
  pr_PD_MD: { value: "", operation: "" },
  pr_PD_FM: { value: "", operation: "" }, 
  pr_AQ_DT: { value: "", operation: "" },
  pr_IN_DT: { value: "", operation: "" },
  pr_ST_NO: { value: "", operation: "" },
  site_NAME: { value: "", operation: "" },
  pr_CU_NO: { value: "", operation: "" }, 
  customer_NAME: { value: "", operation: "" },
  pr_RG_ST: { value: "", operation: "" }, 
  pr_SE_DT: { value: "", operation: "" },    
  pr_IT_NM: { value: "", operation: "" },
  item_NAME: { value: "", operation: "" }, 
  pr_LT_NO: { value: "", operation: "" },
  pr_DE_AL: { value: "", operation: "" },  
  delaer_NAME: { value: "", operation: "" },
  pr_AS_NO: { value: "", operation: "" }, 
  accessor_NAME: { value: "", operation: "" },      
};

const ProductRegistrationMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pr_RG_ST, setpr_RG_ST ] = useState("");
  const [SelectedEQNO, setSelectedEQNO ] = useState("");
  const [SelectedSTNO, setSelectedSTNO ] = useState("");
  const [SelectedCUSTNO, setSelectedCUSTNO ] = useState("");
  const [SelectedDLNO, setSelectedDLNO ] = useState("");
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [SelectedASNM, setSelectedASNM ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setpr_RG_ST(null);
    setSelectedEQNO(null);
    setSelectedSTNO(null);
    setSelectedCUSTNO(null);
    setSelectedDLNO(null);
    setSelectedITNM(null);
    setSelectedASNM(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ProductRegistration/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ProductRegistrationData = response.data;
          delete ProductRegistrationData.prUkNo;
          setFormData(ProductRegistrationData);
          setpr_RG_ST(ProductRegistrationData.pr_RG_ST);
          setSelectedEQNO(ProductRegistrationData.equipmentDES);
          setSelectedSTNO(ProductRegistrationData.site_NAME);
          setSelectedCUSTNO(ProductRegistrationData.customer_NAME);
          setSelectedDLNO(ProductRegistrationData.delaer_NAME);
          setSelectedITNM(ProductRegistrationData.item_NAME);
          setSelectedASNM(ProductRegistrationData.accessor_NAME);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [pr_CO_NO , setpr_CO_NO] = useState("");
 
  const handleDefault = async () => {
    setpr_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ProductRegistration/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ProductRegistration/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ProductRegistrationData = response.data;
        setFormData(ProductRegistrationData);
        setpr_RG_ST(ProductRegistrationData.pr_RG_ST);
        setSelectedEQNO(ProductRegistrationData.equipmentDES);
        setSelectedSTNO(ProductRegistrationData.site_NAME);
        setSelectedCUSTNO(ProductRegistrationData.customer_NAME);
        setSelectedDLNO(ProductRegistrationData.delaer_NAME);
        setSelectedITNM(ProductRegistrationData.item_NAME);
        setSelectedASNM(ProductRegistrationData.accessor_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      prUkNo : FormData.prUkNo,
      pr_EQ_NM : document.getElementById("pr_EQ_NM").value,
      pr_ST_NO : document.getElementById("pr_ST_NO").value,
      pr_CU_NO : document.getElementById("pr_CU_NO").value,
      pr_DE_AL : document.getElementById("pr_DE_AL").value,
      pr_SR_NO : document.getElementById("pr_SR_NO").value,
      pr_IT_NM : document.getElementById("pr_IT_NM").value,
      pr_PD_MD : document.getElementById("pr_PD_MD").value,
      pr_PD_FM : document.getElementById("pr_PD_FM").value,
      pr_LT_NO : document.getElementById("pr_LT_NO").value,
      pr_IN_DT : FormData.pr_IN_DT,
      pr_SE_DT : FormData.pr_SE_DT,
      pr_AQ_DT : FormData.pr_AQ_DT,
      pr_RG_ST : FormData.pr_RG_ST,
      pr_AS_NO : document.getElementById("pr_AS_NO").value,
      pr_CO_NO: company,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.prUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ProductRegistration/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      values();
      axios.post(`${domain}/api/ProductRegistration/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_EQ_NM"] ? filters["pr_EQ_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_EQ_NM",
                        e.target.value,
                        filters["pr_EQ_NM"]
                          ? filters["pr_EQ_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_EQ_DS"] ? filters["pr_EQ_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_EQ_DS",
                        e.target.value,
                        filters["pr_EQ_DS"] ? filters["pr_EQ_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_SR_NO"] ? filters["pr_SR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_SR_NO",
                        e.target.value,
                        filters["pr_SR_NO"]
                          ? filters["pr_SR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_PD_MD"] ? filters["pr_PD_MD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_PD_MD",
                        e.target.value,
                        filters["pr_PD_MD"]
                          ? filters["pr_PD_MD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_PD_FM"] ? filters["pr_PD_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_PD_FM",
                        e.target.value,
                        filters["pr_PD_FM"]
                          ? filters["pr_PD_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_AQ_DT"] ? filters["pr_AQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_AQ_DT",
                        e.target.value,
                        filters["pr_AQ_DT"]
                          ? filters["pr_AQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_IN_DT"] ? filters["pr_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_IN_DT",
                        e.target.value,
                        filters["pr_IN_DT"] ? filters["pr_IN_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_ST_NO"] ? filters["pr_ST_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_ST_NO",
                        e.target.value,
                        filters["pr_ST_NO"]
                          ? filters["pr_ST_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["site_NAME"] ? filters["site_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "site_NAME",
                        e.target.value,
                        filters["site_NAME"]
                          ? filters["site_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_CU_NO"] ? filters["pr_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_CU_NO",
                        e.target.value,
                        filters["pr_CU_NO"]
                          ? filters["pr_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["customer_NAME"] ? filters["customer_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "customer_NAME",
                        e.target.value,
                        filters["customer_NAME"]
                          ? filters["customer_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RG_ST"] ? filters["pr_RG_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RG_ST",
                        e.target.value,
                        filters["pr_RG_ST"]
                          ? filters["pr_RG_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_SE_DT"] ? filters["pr_SE_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_SE_DT",
                        e.target.value,
                        filters["pr_SE_DT"]
                          ? filters["pr_SE_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_IT_NM"] ? filters["pr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_IT_NM",
                        e.target.value,
                        filters["pr_IT_NM"]
                          ? filters["pr_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_NAME"] ? filters["item_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_NAME",
                        e.target.value,
                        filters["item_NAME"]
                          ? filters["item_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_LT_NO"] ? filters["pr_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_LT_NO",
                        e.target.value,
                        filters["pr_LT_NO"]
                          ? filters["pr_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_DE_AL"] ? filters["pr_DE_AL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DE_AL",
                        e.target.value,
                        filters["pr_DE_AL"]
                          ? filters["pr_DE_AL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["delaer_NAME"] ? filters["delaer_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "delaer_NAME",
                        e.target.value,
                        filters["delaer_NAME"]
                          ? filters["delaer_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_AS_NO"] ? filters["pr_AS_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_AS_NO",
                        e.target.value,
                        filters["pr_AS_NO"]
                          ? filters["pr_AS_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["accessor_NAME"] ? filters["accessor_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "accessor_NAME",
                        e.target.value,
                        filters["accessor_NAME"]
                          ? filters["accessor_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Equipment No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Serial Number</th>
                <th style={tableHeaderStyle}>Product Model</th>
                <th style={tableHeaderStyle}>Product Family</th>
                <th style={tableHeaderStyle}>Acquired Date</th>
                <th style={tableHeaderStyle}>Installation Date</th>
                <th style={tableHeaderStyle}>Site Number</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Registration Status</th>
                <th style={tableHeaderStyle}>In Service Date</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Lot/SN</th>
                <th style={tableHeaderStyle}>Dealer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Accessor</th>
                <th style={tableHeaderStyle}>Name</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.prUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.prUkNo}
                        checked={selectedRows.includes(rowData.prUkNo)}
                        onChange={() => handleRowSelect(rowData.prUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pr_EQ_NM}</td>
                    <td style={tableCellStyle}>{rowData.equipmentDES}</td>
                    <td style={tableCellStyle}>{rowData.pr_SR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_PD_MD}</td>
                    <td style={tableCellStyle}>{rowData.pr_PD_FM}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pr_AQ_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pr_IN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.pr_ST_NO}</td>
                    <td style={tableCellStyle}>{rowData.site_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pr_CU_NO}</td> 
                    <td style={tableCellStyle}>{rowData.customer_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pr_RG_ST}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pr_SE_DT)}</td>    
                    <td style={tableCellStyle}>{rowData.pr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_NAME}</td> 
                    <td style={tableCellStyle}>{rowData.pr_LT_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_DE_AL}</td>  
                    <td style={tableCellStyle}>{rowData.delaer_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pr_AS_NO}</td> 
                    <td style={tableCellStyle}>{rowData.accessor_NAME}</td>             
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ProductRegistrationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        pr_RG_ST={pr_RG_ST}
        setpr_RG_ST={setpr_RG_ST}
        SelectedEQNO={SelectedEQNO}
        SelectedSTNO={SelectedSTNO}
        SelectedCUSTNO={SelectedCUSTNO}
        SelectedDLNO={SelectedDLNO}
        SelectedITNM={SelectedITNM}
        SelectedASNM={SelectedASNM}
        setSelectedEQNO={setSelectedEQNO}
        setSelectedSTNO={setSelectedSTNO}
        setSelectedCUSTNO={setSelectedCUSTNO}
        setSelectedDLNO={setSelectedDLNO}
        setSelectedITNM={setSelectedITNM}
        setSelectedASNM={setSelectedASNM}
      />
    </div>
  );
};

export default ProductRegistrationMain;
