import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import RentalSecurityDepositDialog from "./RentalSecurityDepositDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rsUkNo:"",
  rs_CN_NO:"",
  rs_CN_TY:"",
  rs_CO_NO:"",
  rs_CU_NO:"",
  cus_NAME:"",
  rs_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rs_GL_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rs_TR_CR:"",
  rs_DP_TY:"",
  rs_DP_AM:"",
  rs_RF_DT:"",
  rs_RF_AM:"",
  rs_ST_AT:"",
  rs_DE_AM:"",
  rs_RF_DT:moment(moment().toDate()).format("YYYYMMDD"),
  rs_FD_AM:"",
  rs_FR_AM:"",
  rs_FR_DM:"",
  rs_DO_NO:"",
  rs_DO_TY:"",
  rs_DO_CO:"",
};

const initialFilters = {
  rs_CN_NO:{ value: "", operation: "" },
  rs_CN_TY:{ value: "", operation: "" },
  rs_CO_NO:{ value: "", operation: "" },
  rs_CU_NO:"",
  cus_NAME:"",
  rs_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rs_GL_DT: moment(moment().toDate()).format("YYYYMMDD"),
  rs_TR_CR:"",
  rs_DP_TY:"",
  rs_DP_AM:"",
  rs_RF_DT:"",
  rs_RF_AM:"",
};

const RentalSecurityDepositMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,poststyles
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const [refundFlag, setrefundFlag] = useState(false);

  const [RefundDialogVisible, setRefundDialogVisible] = useState(false);

  const openRefundPopup = () => {
    setRefundDialogVisible(true);
  };

  const [cm_PR_TY, setcm_PR_TY] = useState([]);
  const [rs_ST_AT, setrs_ST_AT] = useState([]);
  const [rs_DP_TY, setrs_DP_TY] = useState([]);
  const [cm_PA_PR, setcm_PA_PR] = useState([]);
  const [rs_TR_CR, setrs_TR_CR ] = useState("");
  const [cm_PY_TM, setcm_PY_TM ] = useState("");
  const [cm_HL_CD, setcm_HL_CD ] = useState("");
  const [rs_DO_NO, setrs_DO_NO ] = useState("");
  // AdvanceSearch
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");



  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
     setFormData({ ...FormData, [name]: value });
     onChnageExchangeRate(rs_TR_CR,value);
     onChnageExchangeRate(value, FormData.rs_GL_DT);
  };

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrs_TR_CR(value);
    onChnageExchangeRate(value, FormData.rs_GL_DT);
  };
  const ContractStatusDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrs_ST_AT(value);
  };
  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("rs_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rs_EX_RT").value = dataExRt.cr_CO_MR;
          setrs_EX_RT(dataExRt.cr_CO_MR);
          Amount();
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  };
  const Amount = async () => {
    const Url = `${domain}/api/RentalSecurityDeposit/GetAmountDetails`;
      const requestData={
              "rs_DP_AM":rs_DP_AM,
              "rs_FD_AM":document.getElementById("rs_FD_AM").value,
              "rs_EX_RT":document.getElementById("rs_EX_RT").value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        document.getElementById("rs_DP_AM").value = dataCuMt.rs_DP_AM;
       RefAmount(dataCuMt.rs_DP_AM);
      } else {
        console.error("Invalid API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const RefAmount = async (depositamt) => {
    const Url = `${domain}/api/RentalSecurityDeposit/GetCalculateValue`;
      const requestData={
              "rs_DP_AM": depositamt,
              "rs_RF_AM": document.getElementById("rs_RF_AM").value,
              "rs_FD_AM": rs_FD_AM,
              "rs_FR_AM": document.getElementById("rs_FR_AM").value,
              "rs_EX_RT": rs_EX_RT,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        document.getElementById("rs_DE_AM").value = dataCuMt.rs_DE_AM;
        document.getElementById("rs_FR_DM").value = dataCuMt.rs_FR_DM;
        document.getElementById("rs_DP_AM").value = dataCuMt.rs_DP_AM;
        document.getElementById("rs_RF_AM").value = dataCuMt.rs_RF_AM;
      } else {
        console.error("Invalid API response:", dataCuMt);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setcm_PR_TY(null);
    setrs_DP_TY(null);
    setrs_TR_CR(null);
    setrs_RE_CD(null);
    setrs_FD_AM(null);
    setrs_EX_RT(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setcm_PR_TY(null);
    setrs_DP_TY(null);
    setrs_TR_CR(null);
    setrs_RE_CD(null);
    setrs_FD_AM(null);
    setrs_EX_RT(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    setrefundFlag(false)
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/RentalSecurityDeposit/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const RentalSecurityDepositData = response.data;
          delete RentalSecurityDepositData.rsUkNo;
          setFormData(RentalSecurityDepositData);
          setrs_ST_AT(response.data.rs_ST_AT);
          setrs_DP_TY(response.data.rs_DP_TY);
          setrs_TR_CR(response.data.rs_TR_CR);
          setrs_EX_RT(response.data.rs_EX_RT);
          setrs_RE_CD(response.data.rs_RE_CD);
          setSelectedemEMNO(response.data.cus_NAME);
          setSelectedemEMNO1(response.data.sitedDES);
          setrs_ST_AT(response.data.rs_ST_AT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

const [rs_CO_NO, setrs_CO_NO] = useState("");
const [rs_CN_TY, setrs_CN_TY] = useState("");
const [rs_BS_CR, setrs_BS_CR] = useState("");
const [rs_EX_RT, setrs_EX_RT] = useState("");
const [rs_RE_CD, setrs_RE_CD] = useState("");
const [rs_DO_TY, setrs_DO_TY] = useState("");
const [rs_DO_CO, setrs_DO_CO] = useState("");
const [rs_DP_AM, setrs_DP_AM] = useState("");
const [rs_FD_AM, setrs_FD_AM] = useState("");
const [pm_TR_NO, setpm_TR_NO] = useState("");

  const handleDefault = async () => {
    setrs_CO_NO(`${company}`);
    setrs_DO_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "RS_CN_TY"){
              setrs_CN_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RS_DO_TY"){
              setrs_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RS_BS_CR"){
              setrs_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "RS_ST_AT"){
              ContractStatusDropDown("rs_ST_AT",dataDefault.versionIds[i].default_VALUE);
              setrs_ST_AT(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/RentalSecurityDeposit/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData,  [name]: value, }));
    if (name === 'rs_DP_AM') {
      setrs_DP_AM(value);
    } else if (name === 'rs_FD_AM') {
      setrs_FD_AM(value);
    }
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handlePost = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }

    const requestBodyAPIInput = {
      "rsUkNo" : `${selectedRows[0]}`,
    }
    axios
    .post(`${domain}/api/RentalSecurityDeposit/PostRentalSecurityDeposit`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const Refund =  async () => {
    const  Url = `${domain}/api/RentalSecurityDeposit/PostRentalSecurityDepositRefund`
    const request = {
      "rsUkNo" : FormData.rsUkNo,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RentalSecurityDeposit/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RentalSecurityDepositData = response.data;
        setFormData(RentalSecurityDepositData);
        setrs_ST_AT(response.data.rs_ST_AT);
        setrs_DP_TY(response.data.rs_DP_TY);
        setrs_TR_CR(response.data.rs_TR_CR);
        setrs_EX_RT(response.data.rs_EX_RT);
        setrs_RE_CD(response.data.rs_RE_CD);
        setrs_ST_AT(response.data.rs_ST_AT);
        setSelectedemEMNO(response.data.cus_NAME);
        setSelectedemEMNO1(response.data.sitedDES);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleRefund = async () => {
    setrefundFlag(true)
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/RentalSecurityDeposit/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const RentalSecurityDepositData = response.data;
        setFormData(RentalSecurityDepositData);
        setrs_ST_AT(response.data.rs_ST_AT);
        setrs_DP_TY(response.data.rs_DP_TY);
        setrs_TR_CR(response.data.rs_TR_CR);
        setrs_EX_RT(response.data.rs_EX_RT);
        setrs_RE_CD(response.data.rs_RE_CD);
        setSelectedemEMNO(response.data.cus_NAME);
        setSelectedemEMNO1(response.data.sitedDES);
        // openRefundPopup();
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    const TR_DT = FormData.rs_TR_DT;
    const GL_DT = FormData.rs_GL_DT;
    const TR_CR = FormData.rs_TR_CR;
    // const ST_DT = FormData.rc_ST_DT;
    // const EN_DT = FormData.rc_EN_DT;
    const ST_AT = FormData.rs_ST_AT;
    const DP_TY = FormData.rs_DP_TY;
    requestBodyInput = {
      rsUkNo : FormData.rsUkNo,
      rs_CN_NO: FormData.rs_CN_NO,
      rs_CN_TY: FormData.rs_CN_TY,
      rs_CO_NO: rs_CO_NO,
      rs_CU_NO: FormData.rs_CU_NO,
      rs_TR_DT: TR_DT,
      rs_GL_DT: GL_DT,
      rs_TR_CR: TR_CR,
      rs_DP_AM: FormData.rs_DP_AM,
      rs_EX_RT: rs_EX_RT,
      rs_BS_CR: rs_BS_CR,
      // rc_ST_DT: ST_DT,
      // rs_EN_DT: EN_DT,
      rs_ST_AT: ST_AT,
      rs_DP_TY: DP_TY,
      rs_RF_DT: FormData.rs_RF_DT,
      rs_RF_AM: FormData.rs_RF_AM,
      rs_DE_AM: FormData.rs_DE_AM,
      rs_RE_CD: FormData.rs_RE_CD,
      rs_FR_AM: FormData.rs_FR_AM,
      rs_FR_DM: FormData.rs_FR_DM,
      rs_FD_AM: FormData.rs_FD_AM,
      rs_DO_NO: FormData.rs_DO_NO,
      rs_DO_TY: rs_DO_TY,
      rs_DO_CO: rs_DO_CO,
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.rs_DO_NO) {
    if (FormData.rsUkNo) {
      if(refundFlag){
        const  Url = `${domain}/api/RentalSecurityDeposit/PostRentalSecurityDepositRefund`
          const request = {
            "rsUkNo" : FormData.rsUkNo,
          }
          axios.post(Url, request, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      } else {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/RentalSecurityDeposit/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    }
    } else {
      axios.post(`${domain}/api/RentalSecurityDeposit/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const OR_DT = FormData.rs_GL_DT;
        if(OR_DT){
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${rs_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
              setrs_DO_NO(resp.dn_DO_NO);
              FormData.rs_DO_NO = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/RentalSecurityDeposit/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const handleConfirm = () => {
      values();
      const orNo = FormData.rs_CN_NO;
      const ukNo = FormData.rsUkNo;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Confirm ?");
          const update = `${domain}/api/RentalSecurityDeposit/ConfirmRentalSecurityDepo`;
          if (confirm) {
            axios
              .post(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".post-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Post
      </div>
    </Tooltip>
      <Button 
       style={{backgroundColor:"#0099FF",color:'white',border:"none",lineHeight: "10%",}}
        icon="pi pi-send"
        outlined
        className="mr-2 post-button"
        onClick={handlePost}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".refund-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Refund
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-undo"  
        outlined
        className="mr-2 refund-button"
        onClick={handleRefund}
        disabled={!isRecordSelected}
      ></Button>
      
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_CN_NO"] ? filters["rs_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_CN_NO",
                        e.target.value,
                        filters["rs_CN_NO"]
                          ? filters["rs_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_CN_TY"] ? filters["rs_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_CN_TY",
                        e.target.value,
                        filters["rs_CN_TY"] ? filters["rs_CN_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_CO_NO"] ? filters["rs_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_CO_NO",
                        e.target.value,
                        filters["rs_CO_NO"]
                          ? filters["rs_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_CU_NO"] ? filters["rs_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_CU_NO",
                        e.target.value,
                        filters["rs_CU_NO"]
                          ? filters["rs_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cus_NAME"] ? filters["cus_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_NAME",
                        e.target.value,
                        filters["cus_NAME"]
                          ? filters["cus_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_TR_DT"] ? filters["rs_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_TR_DT",
                        e.target.value,
                        filters["rs_TR_DT"]
                          ? filters["rs_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_GL_DT"] ? filters["rs_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_GL_DT",
                        e.target.value,
                        filters["rs_GL_DT"]
                          ? filters["rs_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_TR_CR"] ? filters["rs_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_TR_CR",
                        e.target.value,
                        filters["rs_TR_CR"]
                          ? filters["rs_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_DP_TY"] ? filters["rs_DP_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_DP_TY",
                        e.target.value,
                        filters["rs_DP_TY"]
                          ? filters["rs_DP_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_DP_AM"] ? filters["rs_DP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_DP_AM",
                        e.target.value,
                        filters["rs_DP_AM"]
                          ? filters["rs_DP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_RF_DT"] ? filters["rs_RF_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_RF_DT",
                        e.target.value,
                        filters["rs_RF_DT"]
                          ? filters["rs_RF_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["rs_RF_AM"] ? filters["rs_RF_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_RF_AM",
                        e.target.value,
                        filters["rs_RF_AM"]
                          ? filters["rs_RF_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rs_RF_DT"] ? filters["rs_RF_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rs_RF_DT",
                        e.target.value,
                        filters["rs_RF_DT"]
                          ? filters["rs_RF_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contract No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Deposit Type</th>
                <th style={tableHeaderStyle}>Deposit Amount</th>
                <th style={tableHeaderStyle}>Deposit Date</th>
                <th style={tableHeaderStyle}>Refund Amount</th>
                <th style={tableHeaderStyle}>Refund Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rsUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rsUkNo}
                        checked={selectedRows.includes(rowData.rsUkNo)}
                        onChange={() => handleRowSelect(rowData.rsUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rs_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.rs_CN_TY}</td>
                    <td style={tableCellStyle}>{rowData.rs_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.rs_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.cus_NAME}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.rs_TR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rs_GL_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.rs_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.rs_DP_TY}</td>
                    <td style={rightalined}>{rowData.rs_DP_AM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rs_RF_DT)}</td> 
                    <td style={rightalined}>{rowData.rs_RF_AM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rs_RF_DT)}</td>  
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
 {/* pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <RentalSecurityDepositDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        rs_CN_TY={rs_CN_TY}
        rs_CO_NO={rs_CO_NO}
        cm_PR_TY={cm_PR_TY}
        pm_TR_NO={pm_TR_NO}
        setcm_PR_TY={setcm_PR_TY}
        rs_ST_AT={rs_ST_AT}
        setrs_ST_AT={setrs_ST_AT}
        rs_DP_TY={rs_DP_TY}
        setrs_DP_TY={setrs_DP_TY}
        cm_PA_PR={cm_PA_PR}
        setcm_PA_PR={setcm_PA_PR}
        rs_TR_CR={rs_TR_CR}
        setrs_TR_CR={setrs_TR_CR}
        cm_PY_TM={cm_PY_TM}
        setcm_PY_TM={setcm_PY_TM}
        cm_HL_CD={cm_HL_CD}
        setcm_HL_CD={setcm_HL_CD}
        rs_BS_CR={rs_BS_CR}
        rs_EX_RT={rs_EX_RT}
        setrs_EX_RT={setrs_EX_RT}
        rs_RE_CD={rs_RE_CD}
        setrs_RE_CD={setrs_RE_CD}
        rs_DO_CO={rs_DO_CO}
        rs_DO_TY={rs_DO_TY}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        CurrencyDropDown={CurrencyDropDown}
        onchangegldate={onchangegldate}
        handleConfirm={handleConfirm}
        setrs_DP_AM={setrs_DP_AM}
        setrs_FD_AM={setrs_FD_AM}
        rs_FD_AM={rs_FD_AM}
        rs_DP_AM={rs_DP_AM}
		    refundFlag={refundFlag}
        Amount={Amount}
        ContractStatusDropDown={ContractStatusDropDown}
        RefAmount={RefAmount}
      />
      
    </div>
  );
};

export default RentalSecurityDepositMain;
