import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function CarrierMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  cm_SH_TR,
  setcm_SH_TR,
  cm_PR_RT,
  setcm_PR_RT,
  cm_RG_TY,
  setcm_RG_TY,
  cm_CT_RY,
  setcm_CT_RY,
  eg_CO_NO,
  onchangeFormData,
  SelectedShip,
  setSelectedShip,
  cm_CO_NO,
}) {
  const {
    cmUkNo,
    cm_CA_CD,
    cm_RG_NO,
    cm_RF_NO,
    cm_EF_DT,
    cm_EX_DT,
    cm_IS_AG,
    cm_CA_NO,

  } = data;


  const { borderbox, inboxstyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();


  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "cm_EF_DT", value: moment(e.value).format("YYYYMMDD") } });

  };


  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

  const [cm_SH_TROptions, setcm_SH_TROptions] = useState([]);
  const [cm_PR_RTOptions, setcm_PR_RTOptions] = useState([]);
  const [cm_RG_TYOptions, setcm_RG_TYOptions] = useState([]);
  const [cm_CT_RYOptions, setcm_CT_RYOptions] = useState([]);
  // BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_SH_TR(value);
  };
  // Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_PR_RT(value);
  };
  // loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_RG_TY(value);
  };
  // lang
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcm_CT_RY(value);
  };


  useEffect(() => {
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
    fetchDropdown4();

  }, []);

  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeDS("TR/TY");
    setcm_SH_TROptions(Options);
  };

  const fetchDropdown2 = async () => {
    const Options = await fetchClassCodeDS("PR/RT");
    setcm_PR_RTOptions(Options);
  };

  const fetchDropdown3 = async () => {
    const Options = await fetchClassCodeDS("RG/TY");
    setcm_RG_TYOptions(Options);
  };

  const fetchDropdown4 = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setcm_CT_RYOptions(Options);
  };
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [ShipToType, setShipToType] = useState("E");
  const [showSearchIcon, setShowSearchIcon] = useState("");


  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const handleDataSelectShip = (data) => {
    setSelectedShip(data.ab_AD_NM);
    onchangeFormData("cm_CA_NO", data.abAdNo);
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };


  const isUpdateMode = Boolean(cmUkNo);

    // DIALOG HEADER
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{cmUkNo ? "Revise Carrier Master" : "Add Carrier Master"}</h5>
        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
      </div>
    );
  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
      <div style={borderbox} >
      <div style={inboxstyle}>
          <div className="formgrid grid">
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
          <label htmlFor="cm_CO_NO" className="font-bold">
            Company
              </label>
              <InputText disabled
                id="cm_CO_NO"
                name="cm_CO_NO"
                value={cm_CO_NO}
                 onChange={(e) => onchangedata(e)}
                readOnly
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_CA_NO" className="font-bold">
              Carrier Number <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM1"
                  name="ab_AD_NM1"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedShip}
                />
                {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openShipToPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="cm_CA_NO"
                  name="cm_CA_NO"
                  value={cm_CA_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_CA_CD" className="font-bold">
                Carrier Code
              </label>
              <InputText 
                id="cm_CA_CD"
                name="cm_CA_CD"
                value={cm_CA_CD}
                 onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_SH_TR" className="font-bold">
                Tracking Type
              </label>
              <Dropdown
                name="cm_SH_TR"
                value={cm_SH_TR}
                options={cm_SH_TROptions}
                onChange={(e) => onchangeDropDownFormData1("cm_SH_TR", e.value)}
                placeholder=" Tracking Type"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_PR_RT" className="font-bold">
                Performance Rating
              </label>
              <Dropdown
                name="cm_PR_RT"
                value={cm_PR_RT}
                options={cm_PR_RTOptions}
                onChange={(e) => onchangeDropDownFormData2("cm_PR_RT", e.value)}
                placeholder="  Performance Rating"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_RG_NO" className="font-bold">
                Registration No
              </label>
              <InputText
                id="cm_RG_NO"
                name="cm_RG_NO"
                value={cm_RG_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_EF_DT" className="font-bold">
                Effective Date
              </label>
              <Calendar
                id="cm_EF_DT"
                name="cm_EF_DT"
                value={moment(cm_EF_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_RG_TY" className="font-bold">
                Licence Type
              </label>
              <Dropdown
                name="cm_RG_TY"
                value={cm_RG_TY}
                options={cm_RG_TYOptions}
                onChange={(e) => onchangeDropDownFormData3("cm_RG_TY", e.value)}
                placeholder="Select Performance Rating"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_EX_DT" className="font-bold">
                Expired date
              </label>
              <Calendar
                id="cm_EX_DT"
                name="cm_EX_DT"
                value={moment(cm_EX_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "cm_EX_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_RF_NO" className="font-bold">
                Reference No
              </label>
              <InputText
                id="cm_RF_NO"
                name="cm_RF_NO"
                value={cm_RF_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_CT_RY" className="font-bold">
                Country
              </label>
              <Dropdown
                name="cm_CT_RY"
                value={cm_CT_RY}
                options={cm_CT_RYOptions}
                onChange={(e) => onchangeDropDownFormData4("cm_CT_RY", e.value)}
                placeholder="Select Country"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="cm_IS_AG" className="font-bold">
                Issuing Agency
              </label>
              <InputText
                id="cm_IS_AG"
                name="cm_IS_AG"
                value={cm_IS_AG}
                onChange={(e) => onchangedata(e)}
              />
            </div>

          </div>
        </div>
      </div>
      {/* SHIP TO POPUP */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelectShip}
        formData={data}
        addressType={ShipToType}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
