import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function ExchangeRateDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  setFormData,
  cr_FR_CR,
  setcr_FR_CR,
  cr_TO_CR,
  setcr_TO_CR,
  AutoPostDropdown,
  cr_DI_DE,
}) {
  const {
    crUkNo,
    cr_EF_DT,
    cr_CO_MR,
    cr_CO_DR,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [cr_FR_CROptions, setcr_FR_CROptions] = useState([]);
  const [cr_TO_CROptions, setcr_TO_CROptions] = useState([]);
// From Currency
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcr_FR_CR(value);
  };
// Currency
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcr_TO_CR(value);
  };
  useEffect(() => {
    fetchDropdownFRCR();
    fetchDropdownTOCR();
  }, []);
  const fetchDropdownFRCR = async () => {
    const Options = await fetchClassCodeDS("FO/CR");
    setcr_FR_CROptions(Options);
  };
  const fetchDropdownTOCR = async () => {
    const Options = await fetchClassCodeDS("FO/CR");
    setcr_TO_CROptions(Options);
  };
  const isUpdateMode = Boolean(crUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{crUkNo ? "Revise Exchange Rate" : "Add Exchange Rate"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cr_FR_CR" className="font-bold">
                    From Currency
                    </label>
                    <Dropdown
                    name="cr_FR_CR"
                    value={cr_FR_CR}
                    options={cr_FR_CROptions}
                    onChange={(e) => onchangeDropDownFormData1("cr_FR_CR", e.value)}
                    placeholder="From Currency"
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cr_TO_CR" className="font-bold">
                    To Currency
                    </label>
                    <Dropdown
                    name="cr_TO_CR"
                    value={cr_TO_CR}
                    options={cr_TO_CROptions}
                    onChange={(e) => onchangeDropDownFormData2("cr_TO_CR", e.value)}
                    placeholder=" To Currency"
                    disabled
                  />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cr_EF_DT" className="font-bold">
                    Effective Date
                    </label>
                    <Calendar
                      id="cr_EF_DT"
                      name="cr_EF_DT"
                      value={moment(data.cr_EF_DT, "YYYYMMDD").isValid() ? moment(data.cr_EF_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "cr_EF_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  </div>
                  <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cr_DI_DE" className="font-bold">
                  Display Decimals
                  </label>
                  <Dropdown
                    id="cr_DI_DE"
                    name="cr_DI_DE"
                    value={cr_DI_DE}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => AutoPostDropdown("cr_DI_DE", e.value)}
                    placeholder=" Display Decimals"
                  />
                </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cr_CO_MR" className="font-bold"> 
                    Multiplier Rate
                    </label>
                    <InputText
                      id="cr_CO_MR"
                      name="cr_CO_MR"
                      value={cr_CO_MR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cr_CO_DR" className="font-bold">
                    Divisor Rate
                    </label>
                    <InputText
                      id="cr_CO_DR"
                      name="cr_CO_DR"
                      value={cr_CO_DR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
