import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function UpdateOverTimeDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  onchangeFormData,
  UpdateOTtable,
  addRow,
  deleteRow,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  em_DE_PT,
  uo_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  uo_EM_NO,
  emEMNOList
}) {
  const { 
  uoUkNo,
  uo_CN_FM,
  uo_HL_OT,
  uo_NO_OT,
  uo_FY_YR,
  uo_CT_RY,
  uo_TO_OT,
  uo_SL_PR,
  uo_PE_NO,
  UO_SL_PR,
  uo_FR_DT,
  uo_TO_DT,
  name,
  em_PY_FR,
  em_AL_NO,
  em_CC_NM,
  toDate,
  fromdate,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [addressType, setaddressType] = useState("E");
  const [em_CC_NMOptions, setem_CC_NMOptions] = useState([]);
  const [uo_FY_YROptions, setuo_FY_YROptions] = useState([]);
  const [uo_PE_NOOptions, setuo_PE_NOOptions] = useState([]);
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };

  const onchangedata = (e) => {
    const { name, value } = e.target;
      onchangeFormData(name, value);
  };
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };

  useEffect(() => {
  }, [emEMNOList]);

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  // Cost
  // const parameter1 = "CO/CT";
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
  // year
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
  // month
  const parameter3 = "MN/TH";
  const finalUrl3 = `${url}/${parameter3}`;
  // DEpartment
  const parameter4 = "EM/DP";
  const finalUrl4 = `${url}/${parameter4}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
  }, []);

  // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setem_CC_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 2nd fetchdropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setuo_FY_YROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 3rd fetchdropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setuo_PE_NOOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }

  };
  // 4th fetchdropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_DE_PTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("uo_EM_NO", data.abAdNo, SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    const  Url = `${domain}/api/EmployeeMaster/GetByEmployee`
    const requestData = {
      "emEmNo": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data.employeeDetails;
      if(data){
        document.getElementById (`em_CC_NM_${SelectedRowIndex}`).value =  data.em_CC_NM;
        // onchangeDropDownFormData1(`em_CC_NM_${SelectedRowIndex}`, data.em_CC_NM);
        // onchangeFormData(`em_DE_PT_${SelectedRowIndex}`, data.em_DE_PT);
      }
      else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // endsearchpopup
  const days = `${domain}/api/UpdateOverTime/GetCalculateDateDetails`
  const getytdmnth = async (fromDate) => {
    if(fromDate!=""){
      const req = {
        "uo_FR_DT": fromDate,
      };
      try {
        const response = await axios.post(days, req, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        const dataday = response.data.Result;
        if (dataday) {
          onchangeFormData("uo_FY_YR", dataday.uo_FY_YR);
          onchangeFormData("uo_PE_NO", dataday.uo_PE_NO);
        }
      } catch (error) {
        console.error("Error in Formatted Dates", error);
      }
    }
  };
  const OT = `${domain}/api/UpdateOverTime/GetUpdateOverTime`
  const avdays = async (fromDate,toDate) => {
    if(fromDate!="" && toDate!=""){
      const req = {
        "uo_EM_NO": document.getElementById("uo_EM_NO").value,
        "uo_FR_DT":fromDate ,
        "uo_TO_DT":toDate
      };
      try {
        const response = await axios.post(OT, req, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        const dataOT = response.data.Result;
        if (dataOT) {
          onchangeFormData("uo_TO_OT", dataOT.uo_TO_OT);
          onchangeFormData("uo_HL_OT", dataOT.uo_HL_OT);
          onchangeFormData("uo_NO_OT", dataOT.uo_NO_OT);
        }
      } catch (error) {
        console.error("Error in Formatted Dates", error);
      }
    }
  };
// enable and disable fileds on header and table
useEffect(() => {
  fetchfields();
}, []);
const [showyear, setshowyear] = useState("");
const [showdepartment, setshowdepartment] = useState("");
const fetchfields = async () => {
  try {
    const request ={
        name: "DISPLAY_YEAR_FIELD",
        value: "FALSE",
    }
    const response = await axios.post( `${domain}/api/ErpParameters/GetByRole`,request,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      }
    );
      const responseValue = response.data.value;
      setshowyear(responseValue === "true");
      setshowdepartment(responseValue === "true");
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
// TOtal OT
const onchangeOT = async(uo_NO_OT,  uo_HL_OT,SelectedRowIndex) => {
    const UrlOT = `${domain}/api/UpdateOverTime/GetCalculateOverTime`;
      const OTInput = {
        "uo_NO_OT": parseFloat(uo_NO_OT),
        "uo_HL_OT": parseFloat(uo_HL_OT),
      }
        try {
          const response =await axios.post(UrlOT, OTInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataOT = response.data.Result;
          if (dataOT ) {
            document.getElementById (`uo_TO_OT_${SelectedRowIndex}`).value =  dataOT.uo_TO_OT;          
           
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
};
const onchangeHT = async(uo_NO_OT,  uo_HL_OT,SelectedRowIndex) => {
  // uo_NO_OT ='';
  const UrlOT = `${domain}/api/UpdateOverTime/GetCalculateOverTime`;
    const OTInput = {
      "uo_NO_OT": parseFloat(uo_NO_OT),
      "uo_HL_OT": parseFloat(uo_HL_OT),
    }
      try {
        const response =await axios.post(UrlOT, OTInput, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataOT = response.data.Result;
        if (dataOT ) {
          document.getElementById (`uo_TO_OT_${SelectedRowIndex}`).value =  dataOT.uo_TO_OT;          
         
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
};
// OT eligible employees list
const [leaveDaysList, setLeaveDaysList] = useState(UpdateOTtable.map(() => ''));
const isUpdateMode = Boolean(uoUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{uoUkNo ? "Revise Update Overtime" : "Add Update Overtime"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {isUpdateMode && (
        <div style={{ display: 'flex',width:"5%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
const productDialogFooter = (
  <React.Fragment>
  <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
  {isUpdateMode && (
   <div style={{ display: 'flex',width:"5%" }}>
   <Button 
     style={{width:"100%"}}
     icon="pi pi-check-circle" 
     onClick={handleConfirm} 
     tooltip={"Confirm"}>
   </Button>
   </div>
  )}
   <div style={{ display: 'flex',width:"10%" }}>
   <Button 
     style={{width:"100%"}}
     icon="pi pi-times" 
     variant="outlined" 
     onClick={handleClose}
     tooltip="Cancel"
   >
   </Button>
   <Button 
     style={{width:"100%"}}
     icon="pi pi-check" 
     onClick={handleFormSubmit} 
      tooltip="Save">
   </Button>
   </div>
</div>
</React.Fragment>

);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
       <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div style={{display:"none"}}>
              <label htmlFor="uoUkNo" className="font-bold">
              uk_no
              </label>
              <InputText
                id="uoUkNo"
                name="uoUkNo"
                value={uoUkNo}
                onChange={(e) => onchangedata(e)}
              />
            </div>
              <div style={{display:"none"}}>
                <label htmlFor="uo_CO_NO" className="font-bold">
                co_no
                </label>
                <InputText disabled
                  id="uo_CO_NO"
                  name="uo_CO_NO"
                  value={uo_CO_NO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="uo_FR_DT" className="font-bold">
                From Date
                </label>
                {/* <Calendar
                id="uo_FR_DT"
                name="uo_FR_DT"
                value={moment(uo_FR_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "uo_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
              /> */}
              <Calendar
                  id="uo_FR_DT"
                  name="uo_FR_DT"
                  value={moment(uo_FR_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => {
                    const selectedDateF = moment(e.value).format("YYYYMMDD");
                    onchangeFormData("uo_FR_DT", selectedDateF);
                    getytdmnth(selectedDateF);
                  }}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="uo_TO_DT" className="font-bold">
                To Date
                </label>
                <Calendar
                  id="uo_TO_DT"
                  name="uo_TO_DT"
                  value={moment(data.uo_TO_DT, "YYYYMMDD").isValid() ? moment(data.uo_TO_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "uo_TO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="uo_FY_YR" className="font-bold">
                  Year
                  </label>
                  <InputText disabled
                    id="uo_FY_YR"
                    name="uo_FY_YR"
                    value={uo_FY_YR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="uo_PE_NO" className="font-bold">
                Month
                </label>
                <InputText disabled
                  id="uo_PE_NO"
                  name="uo_PE_NO"
                  value={uo_PE_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              
          </div>
        </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
      <table className="custom-table" id="UpdateOTtable">
      <thead>
        <tr>
        <th style={{ display: "none" }}>UK NO</th>
          <th style={{ display: "none" }}>Employee NO  </th>
          <th>Employee <span style={{ color: "red" }}>*</span> </th>
          <th>Cost Centre</th>
          <th>Department</th>
          <th>Normal OT</th>
          <th>Holiday OT</th>
          <th>Total OT</th>
          <th>Confirm</th>
          {/* <th>Process</th> */}
        </tr>
      </thead>
      <tbody>
      {UpdateOTtable.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`uoUkNo_${index}`}
              name={`uoUkNo_${index}`}
              defaultValue={row.uoUkNo}
            />
            </td>
            <td style={{display:"none"}}>    
            <div>
            <InputText
              id={`uo_EM_NO_${index}`}
              name={`uo_EM_NO_${index}`}
              defaultValue={row.uo_EM_NO ? row.uo_EM_NO : row[`${'uo_EM_NO'}_${index}`]}
              onChange={(e) => onchangedata(e)}
            /> 
            </div>
          </td>
          <td>
            <div style={{display:"flex", width:"120px"}}>
              <InputText
                id={`name_${index}`}
                name={`name_${index}`}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.name ? row.name : row[`${'name'}_${index}`]}
              />
              {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>openEmployeePopup(index)}
                ></i> 
              )} 
           </div>     
          </td>
          <td>
          <Dropdown
            id={`em_CC_NM_${index}`}
            name={`em_CC_NM_${index}`}
            value={data[`em_CC_NM_${index}`] ? data[`em_CC_NM_${index}`]:row.em_CC_NM }
            options={em_CC_NMOptions}
            onChange={(e) => handleFormDataChange(`em_CC_NM_${index}`, e.value)}
            placeholder="Select Cost Centre"
          />
          </td>
          <td>
          <Dropdown
            id={`em_DE_PT_${index}`}
            name={`em_DE_PT_${index}`}
            value={data[`em_DE_PT_${index}`] ? data[`em_DE_PT_${index}`]:row.em_DE_PT }
            options={em_DE_PTOptions}
            onChange={(e) => handleFormDataChange1(`em_DE_PT_${index}`, e.value)}
            placeholder="Select Dept."
          />
          {/* <InputText
            id={`em_DE_PT_${index}`}
            name={`em_DE_PT_${index}`}
            defaultValue={row.em_DE_PT}
            onChange={(e) => onchangedata(e)}
          /> */}
          </td>
          <td>
          <InputText
            id={`uo_NO_OT_${index}`}
            name={`uo_NO_OT_${index}`}
            defaultValue={row.uo_NO_OT}
            // onChange={(e) => onchangedata(e)}
            onChange={(e) => {
              onchangedata(e); 
              onchangeOT(document.getElementById(`uo_NO_OT_${index}`).value, document.getElementById(`uo_HL_OT_${index}`).value,index);                      
            }}
          />
          </td>
          <td>
          <InputText
            id={`uo_HL_OT_${index}`}
            name={`uo_HL_OT_${index}`}
            defaultValue={row.uo_HL_OT}
            // onChange={(e) => onchangedata(e)}
            onChange={(e) => {
              onchangedata(e); 
              onchangeHT(document.getElementById(`uo_NO_OT_${index}`).value, document.getElementById(`uo_HL_OT_${index}`).value,index);                      
            }}
          />
          </td>
          <td>
          {/* <InputText
            id={`uo_TO_OT_${index}`}
            name={`uo_TO_OT_${index}`}
            defaultValue={row.uo_TO_OT}
            onChange={(e) => onchangedata(e)}
          /> */}
           <InputText disabled
              id={`uo_TO_OT_${index}`}
              name={`uo_TO_OT_${index}`}
              value={row.uo_TO_OT || ''}
            />
          </td>
          <td>
          <InputText
            id={`uo_CN_FM_${index}`}
            name={`uo_CN_FM_${index}`}
            defaultValue={row.uo_CN_FM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          {/* <td>
          <InputText
            id={`UO_SL_PR_${index}`}
            name={`UO_SL_PR_${index}`}
            defaultValue={row.UO_SL_PR}
            onChange={(e) => onchangedata(e)}
          />
          </td> */}
          </tr>
           ))}
      </tbody>
    </table>
    </div>      
      </div> 
    </div>
    <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
    />    
    </Dialog>
  );
}
