import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ParentItemPopup from "../Popups/ParentItemPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "../Popups/ProjectMasterPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ServiceInvoiceGenerationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ServiceInvoiceGenerationTable,
  addRow,
  deleteRow,
  sr_OR_TY,
  sr_OR_CO,
  sr_BS_CR,
  setsr_BL_TY,
  ic_CO_CT,
  ic_CO_TY,
  sr_ST_CD,
  price_AMOUNT,
  discount_AMOUNT,
  tax_AMOUNT,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_TAX_AMOUNT,
  setselectedEQ,
  setselectedNM,
  setselectedItem,
  setselectedDesc,
  setselectedItem1,
  setselectedDesc1,
  SelectedemEMNO,
  setSelectedemEMNO,
  setSelectedemEMNO1,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  sr_CR_CD,
  setsr_CR_CD

}) {
  const {
    srUkNo,
    sr_CU_NO,
    sr_OR_NO,
    sr_OR_DT,
    sr_PR_NO,
    sr_IN_DT,
  } = data;

  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // DROPDOWN
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [sr_CR_CDOptions, setsr_CR_CDOptions] = useState([]);
  const [sr_BL_TYOptions, setsr_BL_TYOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_CR_CD(value);
  };
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_BL_TY(value);
  };
  useEffect(() => {
    Currency();
    Type();
  }, []);
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsr_CR_CDOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setsr_BL_TYOptions(Options);
  };
 // --------------------------------------------------------------------------------------
  
// Project Master
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.pm_PR_DS);
    onchangeFormData("sr_PR_NO", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };
  // -------------------------------------------------------------------------------------------
// EMPLOYEE
const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
const openAddressPopup = (e) => {
  setAddressPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelectE = (data) => {
  setSelectedemEMNO1(data.ab_AD_NM);
  onchangeFormData(`sr_EM_NO_${SelectedRowIndex}`, data.abAdNo);
  onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
  setAddressPopupVisible(false);
  setShowSearchIcon(false);
};
// ------------------------------------------------------------------------------------------------
// Equipment
const [EquipmentPopupVisible, setEquipmentVisible] = useState(false);
const openEquipmentPopup = () => {
  setEquipmentVisible(true);
};
const handleDataSelect2 = (data) => {
  setselectedEQ(data.em_EQ_NM)
  setselectedNM(data.em_EQ_DS)
  onchangeFormData("sr_EQ_NM", data.em_EQ_NM, SelectedRowIndex);
  onchangeFormData("equiDES", data.em_EQ_DS, SelectedRowIndex);
  setEquipmentVisible(false);
  setShowSearchIcon(false);
};
// -----------------------------------------------------------------------------------------------
  // Customer
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("sr_CU_NO", data.abAdNo);
    
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`sr_CR_CD`, dataCuMt.cu_CR_CD);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
  };
  // --------------------------------------------------------------------------------
  // Item Name
  const [ItemVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("sr_IT_NM", data.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", data.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`sr_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  // ----------------------------------------------------------------------------------------
  // Billing Item
  const [Item1Visible, setItem1Visible] = useState(false);
  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };
  const handleItem1 =async (data) => {
    setselectedItem1(data.im_IT_NM)
    setselectedDesc1(data.im_IT_DS)
    onchangeFormData("sr_BL_NM", data.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES1", data.im_IT_DS, SelectedRowIndex);
    setItem1Visible(false);
    setShowSearchIcon(false);
  };
const isUpdateMode = Boolean(srUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{srUkNo ? "Revise Service Invoice Generation" : "Add Service Invoice Generation"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="srUkNo" className="font-bold">
            UK NO
            </label>
            <InputText disabled
              id="srUkNo"
              name="srUkNo"
              defaultValue={srUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_NO" className="font-bold">
            Service No
            </label>
            <InputText disabled
              id="sr_OR_NO"
              name="sr_OR_NO"
              value={sr_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="sr_OR_TY"
              name="sr_OR_TY"
              value={sr_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_OR_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="sr_OR_CO"
              name="sr_OR_CO"
              value={sr_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_CU_NO" className="font-bold">
                Customer  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="cus_NAME"
                  name="cus_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
              </div>
              <InputText disabled style={{ display: "none" }}
                id="sr_CU_NO"
                name="sr_CU_NO"
                defaultValue={sr_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_CR_CD" className="font-bold">
              Currency
              </label>
              <Dropdown disabled
                name="sr_CR_CD"
                value={sr_CR_CD}
                options={sr_CR_CDOptions}
                onChange={(e) =>CurrencyDropDown("sr_CR_CD", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_PR_NO" className="font-bold">
                Project No  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="proj_NAME"
                  name="proj_NAME"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedProjectMaster}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openProjectMasterPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText disabled
                  id="sr_PR_NO"
                  name="sr_PR_NO"
                  value={sr_PR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openProjectMasterPopup}
                ></i>
              </div>
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="sr_IN_DT" className="font-bold">
              Invoice Date
            </label>
            <Calendar
            id="sr_IN_DT"
            name="sr_IN_DT"
            value={moment(sr_IN_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "sr_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </div>  
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="ServiceInvoiceGenerationTable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Bill Item</th>
              <th>Description</th>
              <th>Bill Type</th>
              <th>Item Name</th>
              <th>Description</th>
              <th>Cost</th>
              <th>MarkUp %</th>
              <th>MarkUp Amount</th>
              <th>Amount</th>
              <th>Dicount %</th>
              <th>Discount Amount</th>
              <th>Fore.Amount</th>
              <th>Fore. Discount</th>
              <th>Fore.Tax</th>
              <th>Tax %</th>
              <th>Tax Amount</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Equipment Name</th>
              <th>Description</th>
              <th style={{display:"none"}}>Employee No</th>
              <th>Employee No<span style={{ color: 'red', }}>*</span></th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ServiceInvoiceGenerationTable.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`srUkNo_${index}`}
                    name={`srUkNo_${index}`}
                    value={row.srUkNo}
                  />
                </td>
                <td >
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      style={{ width: "10rem" }}
                      id={`sr_BL_NM_${index}`}
                      name={`sr_BL_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sr_BL_NM ? row.sr_BL_NM: row[`${"sr_BL_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "15%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openItem1Popup(index)}
                    ></i>
                    )}
                    </div>
                  </td>
                    <td>
                    <InputText disabled
                    style={{ width: "15rem" }}
                    id={`itemDES1_${index}`}
                    name={`itemDES1_${index}`}
                    defaultValue={row.itemDES1 ? row.itemDES1 : row[`${"itemDES1"}_${index}`]}
                    readOnly
                    />
                  </td>
                  <td>
                    <Dropdown disabled style={{ width: "10rem" }}
                      id={`sr_BL_TY_${index}`}
                      name={`sr_BL_TY_${index}`}
                      value={ data[`sr_BL_TY_${index}`] ? data[`sr_BL_TY_${index}`]: row.sr_BL_TY}
                      options={sr_BL_TYOptions}
                      onChange={(e) =>LocationDropdown(`sr_BL_TY_${index}`, e.value)}
                      placeholder="Select Location"
                    />
                  </td>
                  <td >
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      style={{ width: "10rem" }}
                      id={`sr_IT_NM_${index}`}
                      name={`sr_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.sr_IT_NM ? row.sr_IT_NM: row[`${"sr_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "15%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                    </div>
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "15rem" }}
                      id={`itemDES_${index}`}
                      name={`itemDES_${index}`}
                      defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                    />
                  </td>
                  <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_CO_ST_${index}`}
                    name={`sr_CO_ST_${index}`}
                    defaultValue={row.sr_CO_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_MK_PE_${index}`}
                    name={`sr_MK_PE_${index}`}
                    defaultValue={row.sr_MK_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_MK_AM_${index}`}
                    name={`sr_MK_AM_${index}`}
                    defaultValue={row.sr_MK_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`sr_AM_DM_${index}`}
                    name={`sr_AM_DM_${index}`}
                    // defaultValue={data.sr_CR_CD==sr_BS_CR?row.sr_AM_DM:0}
                    defaultValue={row.sr_AM_DM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_DS_PE_${index}`}
                    name={`sr_DS_PE_${index}`}
                    defaultValue={row.sr_DS_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_DS_AM_${index}`}
                    name={`sr_DS_AM_${index}`}
                    defaultValue={row.sr_DS_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_AM_FR_${index}`}
                    name={`sr_AM_FR_${index}`}
                    // defaultValue={data.sr_CR_CD==sr_BS_CR?row.sr_AM_FR:0}
                    defaultValue={row.sr_AM_FR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_DS_FR_${index}`}
                    name={`sr_DS_FR_${index}`}
                    defaultValue={row.sr_DS_FR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`sr_TX_FR_${index}`}
                    name={`sr_TX_FR_${index}`}
                    defaultValue={row.sr_TX_FR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
                <td >
                  <InputText  disabled style={{width:"10rem"}}
                    id={`sr_TX_PE_${index}`}
                    name={`sr_TX_PE_${index}`}
                    defaultValue={row.sr_TX_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`sr_TX_AM_${index}`}
                    name={`sr_TX_AM_${index}`}
                    defaultValue={row.sr_TX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <Calendar style={{width:"10rem"}} disabled
                id={`sr_FR_DT_${index}`}
                name={`sr_FR_DT_${index}`}
                value={row.sr_FR_DT? moment(row.sr_FR_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "sr_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar disabled style={{width:"10rem"}}
                id={`sr_TO_DT_${index}`}
                name={`sr_TO_DT_${index}`}
                value={row.sr_TO_DT? moment(row.sr_TO_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "sr_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}              
                dateFormat="dd/mm/yy"
                />
                </td> 
                <td >
                <div style={{ display: "flex" }}> 
                  <InputText disabled
                    style={{ width: "10rem" }}
                    id={`sr_EQ_NM_${index}`}
                    name={`sr_EQ_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.sr_EQ_NM ? row.sr_EQ_NM: row[`${"sr_EQ_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openEquipmentPopup(index)}
                  ></i>
                  )}
                </div>
              </td>
                <td>
                <InputText disabled
                style={{ width: "15rem" }}
                id={`equiDES_${index}`}
                name={`equiDES_${index}`}
                defaultValue={row.equiDES ? row.equiDES : row[`${"equiDES"}_${index}`]}
                />
                </td>
                <td style={{display:"none"}}> 
                <InputText disabled
                id={`sr_EM_NO_${index}`}
                name={`sr_EM_NO_${index}`}
                onFocus={() => setShowSearchIcon(true)}
                value={row.sr_EM_NO ? row.sr_EM_NO: data[`${"sr_EM_NO"}_${index}`]}
                onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <div style={{ display: "flex", width:"15rem"}}>
                <InputText disabled
                id={`name_${index}`}
                name={`name_${index}`}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.name ? row.name : data[`${"name"}_${index}`]}
                />
                {showSearchIcon && (
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
                }}
                onClick={(e) => openAddressPopup(index)}
                ></i>
                )}
                </div>
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`sr_ST_CD_${index}`}
                    name={`sr_ST_CD_${index}`}
                    defaultValue={sr_ST_CD}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
      </div>
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelectE}
        formData={data}
        addressType={AddresstypeE}
      />
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      <ParentItemPopup
        visible={Item1Visible}
        onClose={() => setItem1Visible(false)}
        onDataSelect={handleItem1}
        formData={data}
      />
      <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />
    </Dialog>
  );
}
