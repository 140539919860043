import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
const initialValue = {
  phUkNo:"",
  pd_DO_CO:"",
  pd_DD_DT: "",
  pd_CR_CD:"",
  pd_BA_AC:"",
};

const initialFilters = {
};

const PaymentGenerationMain = () => {
    // buttonstyles
    const { buttonStyles, 
     } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pd_DO_CO, setph_DO_CO ] = useState("");
  const [pd_DD_DT, setpd_DD_DT ] = useState("");
  const { fetchClassCodeCD } = ClassCode();

  const onchangeFormData = (name, value) => {
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  // Advance Search 
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [Selectedaccount,setSelectedaccount]= useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedaccount(data.account); 
    setSelectedemEMNOA(data.am_AC_DS);
    onchangeFormData("pd_BA_AC", data.account);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleClearButton=()=>{
    setpd_DD_DT(null);
    setph_CR_CDOptions(null);
    setSelectedaccount("");
    setSelectedemEMNOA("");
    setFilters("");
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setph_DO_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PaymentGeneration/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };
  const handlePaymentGeneration = async () => {
    const  Url = `${domain}/api/PaymentGeneration/GenerateAutomaticPaymentEntries`
    const request = {
      "automaticPayment":{
        "pd_DD_DT": FormData.pd_DD_DT,
        "pd_CR_CD": FormData.pd_CR_CD,
        "pd_DO_CO": document.getElementById("pd_DO_CO").value,
        "pd_BA_AC": FormData.pd_BA_AC,
      }
        
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const [pd_CR_CD, setph_CR_CD] = useState(null);
  const [ph_CR_CDOptions, setph_CR_CDOptions] = useState("");
  const onchangeDropDown = (e) => {
    setph_CR_CD(e.value); 
    setFormData({ ...FormData, pd_CR_CD: e.value }); 
  };
  useEffect(() => {Currency();}, []);
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setph_CR_CDOptions(Options);
  };
  const allFieldsSelected = pd_DO_CO && pd_DD_DT !== null && pd_CR_CD && Selectedaccount;
  // const leftToolbarTemplate = ( 
  //   <React.Fragment>
  //      <Tooltip target=".update-button" position="bottom" mouseTrack >
  //      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
  //       Payment Generation
  //     </div>  
  //     </Tooltip>
  //     <Button   
  //       style={buttonStyles}
  //       icon="pi pi-check"
  //       outlined
  //       className="mr-2 update-button"
  //       onClick={handlePaymentGeneration}
  //       disabled={!allFieldsSelected}
  //     ></Button>
      
  //   </React.Fragment>
  // );

  // const exportExcel = () => {
  //   import("xlsx").then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(tableData);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });

  //     const data = new Blob([excelBuffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //     });
  //     saveAs(data, "products_export.xlsx");
  //   });
  // };

  // const rightToolbarTemplate = (
  //   <React.Fragment>
  //     <Button
  //       style={{ height: "35px" }}
  //       icon="pi pi-upload"
  //       className="p-button-help"
  //       rounded
  //       onClick={exportExcel}
  //       data-pr-tooltip="XLS"
  //     />
  //   </React.Fragment>
  // );

  const AdvanceSearch = (
    <div className="formgrid grid"  id="headerfilters">
      <div className="field col">
        <label htmlFor="pd_DO_CO" className="font-bold">
        Company
        </label>
        <InputText disabled
          id="pd_DO_CO"
          name="pd_DO_CO"
          value={pd_DO_CO}
          onChange={(e) => onchangedata(e)}
        />
      </div>
      <div className="field col">
        <label htmlFor="pd_DD_DT" className="font-bold">
            GL Date
        </label>
        <Calendar
          id="pd_DD_DT"
          name="pd_DD_DT"
          defaultValue={moment(pd_DD_DT, "YYYYMMDD").toDate()}
          onChange={(e) => onchangedata({ target: { name: "pd_DD_DT", value: moment(e.value).format("YYYYMMDD") } })}
          dateFormat="dd/mm/yy"
        />
      </div>
      <div className="field col" style={{marginLeft:"3%"}}>
        <label htmlFor="pd_CR_CD" className="font-bold">
          Currency Code
        </label>
        <Dropdown
          style={{width:"90%"}}
          id="pd_CR_CD"
          name="pd_CR_CD"
          value={pd_CR_CD}
          options={ph_CR_CDOptions}
          onChange={onchangeDropDown}
          placeholder="Select Currency"
        />
      </div>
      <div className="field col">
          <label htmlFor="pd_BA_AC" className="font-bold">
            Bank Account <span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: "flex" }}>
            <InputText
              id="accountDes"
              name="accountDes"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNOA}
            />
            {showSearchIcon && (
            <i
              className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
              }}
              onClick={openAccountPopup}
            ></i>
            )}
          </div>
          <div style={{ display: "none" }}>
            <InputText
              id="account"
              name="account"
              value={Selectedaccount}
            />
          </div>
      </div> 
      <div className="field col">
        <Button
          style={{
            display: "flex",
            justifyContent: "flex-end",
            backgroundColor: "#03a9f485",
            border: "none", 
            marginTop:"13%"
          }}
            type="button"
            label="Generate"
            icon="pi pi-check"
            onClick={handlePaymentGeneration}
        >
        </Button> 
      </div>  
    </div>
  );
  const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  </Tooltip>
  <Button
    style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
    icon="pi pi-times"
    className="mr-2 clear-button"
    link
    onClick={handleClearButton}
  />
  </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        {/* <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
        /> */}
        <div style={{ marginTop: "2%"}}>
       
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Toolbar
              className="p-mb-4"
              left={AdvanceSearch}
              right={rightClear}
            />
        
          </table>
        </div>
        </div>
      </div>
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
      />
    </div>
  );
};

export default PaymentGenerationMain;
