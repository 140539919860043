import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import ProjectMasterPopup from "../Popups/ProjectMasterPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function ProjectBudgetDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ProjectBudgetTableData,
  setProjectBudgetTableData,
  pbPrNo,
  setpbPrNo,
  SelectedProject,
  setSelectedProject,
  SelectedDesc,
  setselectedDesc,
  pb_CO_NO,
}) {
  const {
    pb_UK_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
 
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [ProjectVisible, setProjectVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleJob =async (data) => {
    setSelectedProject(data.pm_PR_NO)
    setselectedDesc(data.pm_PR_DS)
    onchangeFormData("pbPrNo", data.pm_PR_NO);
    onchangeFormData("projectDES", data.pm_PR_DS);
    const Url = `${domain}/api/AccountMaster/GetByAccountMasterByCostCenter`;
    const requestData={
      "am_CO_NM":data.pm_PR_NO,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
  
      if (dataLead && dataLead.length > 0) {
        setProjectBudgetTableData(dataLead.map(item => ({
          pb_AC_ID: item.am_AC_ID,
          pbCoCt: item.am_CO_NM,
          pbObAc: item.am_OB_AC,
          pbObSb: item.am_OB_SB,
          pbLvDt: item.am_AC_LD,
          // pb_CA_MD: item.pa_CA_MD,
          pb_AC_DS: item.am_AC_DS,
          pb_PE_CD: item.am_PE_CD

        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setProjectVisible(false);
    setShowSearchIcon(false);
  };
  const openJobPopup = (e) => {
    setProjectVisible(true);
    setSelectedRowIndex(e);
  };
  const onchangeFormUnitPrice = async(index) => {
    handleUnit(index);
  };
  const onchangeFormUnit = async(index) => {
    handleAmount(index);
  };
  const handleUnit =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`pb_BU_UT_${SelectedRowIndex}`).value,
      "add_2":document.getElementById (`pb_RV_UT_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`pb_TO_UT_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
   
  };
  const handleAmount =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`pb_BU_AM_${SelectedRowIndex}`).value,
      "add_2":document.getElementById (`pb_RV_AM_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`pb_TO_AM_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  };
  const addRow = () => {
    setProjectBudgetTableData([...ProjectBudgetTableData, {}]);
  };
  const deleteRow = () => {
    if (ProjectBudgetTableData.length > 0) {
      const updatedData = [...ProjectBudgetTableData];
      updatedData.pop();
      setProjectBudgetTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(pb_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{pb_UK_NO ? "Revise Project Budget" : "Add Project Budget"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pbPrNo" className="font-bold">
                Project No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText 
                disabled={!!isUpdateMode}
                  id="pbPrNo"
                  name="pbPrNo"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedProject}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openJobPopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="projectDES" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="projectDES"
                name="projectDES"
                value={SelectedDesc}
              readOnly
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pb_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="pb_CO_NO"
                name="pb_CO_NO"
                value={pb_CO_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div> 
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="ProjectBudgettable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th>Account Id</th>
                    <th>Cost Centre</th>
                    <th>Account Object</th>
                    <th>Subsidiary</th>
                    <th>Account Description</th>
                    <th>Level of Detail</th>
                    <th>Post Code</th>
                    <th>Calc-Method</th>
                    <th>Budgeted Units</th>
                    <th>Budgeted Amount</th>
                    <th>Revision Units</th>
                    <th>Revision Amount</th>
                    <th>Total Units</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {ProjectBudgetTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`pb_UK_NO_${index}`}
                          name={`pb_UK_NO_${index}`}
                          value={row.pb_UK_NO}
                        />
                      </td>
                      <td >
                        <InputText  disabled style={{width:'10rem'}}
                          id={`pb_AC_ID_${index}`}
                          name={`pb_AC_ID_${index}`}
                          defaultValue={row.pb_AC_ID}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pbCoCt_${index}`}
                          name={`pbCoCt_${index}`}
                          defaultValue={row.pbCoCt}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pbObAc_${index}`}
                          name={`pbObAc_${index}`}
                          defaultValue={row.pbObAc}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText  disabled style={{width:'10rem'}}
                          id={`pbObSb_${index}`}
                          name={`pbObSb_${index}`}
                          defaultValue={row.pbObSb}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pb_AC_DS_${index}`}
                          name={`pb_AC_DS_${index}`}
                          defaultValue={row.pb_AC_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pbLvDt_${index}`}
                          name={`pbLvDt_${index}`}
                          defaultValue={row.pbLvDt}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText  disabled style={{width:'10rem'}}
                          id={`pb_PE_CD_${index}`}
                          name={`pb_PE_CD_${index}`}
                          defaultValue={row.pb_PE_CD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText  disabled style={{width:'10rem'}}
                          id={`pb_CA_MD_${index}`}
                          name={`pb_CA_MD_${index}`}
                          defaultValue={row.pb_CA_MD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText  style={{width:'10rem'}}
                          id={`pb_BU_UT_${index}`}
                          name={`pb_BU_UT_${index}`}
                          defaultValue={row.pb_BU_UT}
                          onChange={(e) => {
                            onchangeFormUnitPrice(e, index);
                          }}
                        />
                      </td>
                      <td >
                        <InputText  style={{width:'10rem'}}
                          id={`pb_BU_AM_${index}`}
                          name={`pb_BU_AM_${index}`}
                          defaultValue={row.pb_BU_AM}
                          onChange={(e) => {
                            onchangeFormUnit(e, index);
                          }}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`pb_RV_UT_${index}`}
                          name={`pb_RV_UT_${index}`}
                          defaultValue={row.pb_RV_UT}
                          onChange={(e) => {
                            onchangeFormUnitPrice(e, index);
                          }}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`pb_RV_AM_${index}`}
                          name={`pb_RV_AM_${index}`}
                          defaultValue={row.pb_RV_AM}
                          onChange={(e) => {
                            onchangeFormUnit(e, index);
                          }}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pb_TO_UT_${index}`}
                          name={`pb_TO_UT_${index}`}
                          defaultValue={row.pb_TO_UT}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:'10rem'}}
                          id={`pb_TO_AM_${index}`}
                          name={`pb_TO_AM_${index}`}
                          defaultValue={row.pb_TO_AM}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
      </div>  
      <ProjectMasterPopup
        visible={ProjectVisible}
        onClose={() => setProjectVisible(false)}
        onDataSelect={handleJob}
        formData={data}
      />
    </Dialog>
  );
}
