import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
export default function ProjectAccountStructureDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  handleFormSubmit,
  ProjectAccountStructureTableData,
  setProjectAccountStructureTableData,
  pa_CO_NO,
}) {
  const {
    pa_UK_NO,
    pa_CA_MD,
    pa_CD_DS,
    paAcCd,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const addRow = () => {
    setProjectAccountStructureTableData([...ProjectAccountStructureTableData, {}]);
  };
  const deleteRow = () => {
    if (ProjectAccountStructureTableData.length > 0) {
      const updatedData = [...ProjectAccountStructureTableData];
      updatedData.pop();
      setProjectAccountStructureTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(pa_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{pa_UK_NO ? "Revise Project Account Structure" : "Add Project Account Structure"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle}>
         <div className="formgrid grid">
         <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="paAcCd" className="font-bold">
              Account Code Structure
            </label>
            <InputText 
              id="paAcCd"
              name="paAcCd"
              value={paAcCd}
              onChange={(Event) => onchangedata(Event)}
            />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pa_CD_DS" className="font-bold">
                Description
              </label>
              <InputText 
                id="pa_CD_DS"
                name="pa_CD_DS"
                value={pa_CD_DS}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pa_CA_MD" className="font-bold">
                Calc-Method
              </label>
              <InputText 
                id="pa_CA_MD"
                name="pa_CA_MD"
                value={pa_CA_MD}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>                  
          </div>
          </div>  
            <div  style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
                {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
              </div>
              <div  style={multitablestyle}>
              <table className="custom-table" id="ProjectAccountStructuretable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th style={{ display: "none" }}>Company</th>
                    <th>Account Object</th>
                    <th>Subsidiary</th>
                    <th>Account Description</th>
                    <th>Level of Detail</th>
                    <th>Post Code</th>
                  </tr>
                </thead>
                <tbody>
                  {ProjectAccountStructureTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`pa_UK_NO_${index}`}
                          name={`pa_UK_NO_${index}`}
                          value={row.pa_UK_NO}
                        />
                      </td>
                      <td style={{ display: "none" }}>
                        <InputText  disabled
                          id={`pa_CO_NO_${index}`}
                          name={`pa_CO_NO_${index}`}
                          value={pa_CO_NO}
                          readOnly
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`paObAc_${index}`}
                          name={`paObAc_${index}`}
                          defaultValue={row.paObAc}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`paObSb_${index}`}
                          name={`paObSb_${index}`}
                          defaultValue={row.paObSb}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`pa_AC_DS_${index}`}
                          name={`pa_AC_DS_${index}`}
                          defaultValue={row.pa_AC_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`paLvDt_${index}`}
                          name={`paLvDt_${index}`}
                          defaultValue={row.paLvDt}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`pa_PE_CD_${index}`}
                          name={`pa_PE_CD_${index}`}
                          defaultValue={row.pa_PE_CD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
          </div>
      </div>
    </Dialog>
  );
}
