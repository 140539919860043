import React, { useState }  from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
// import AddressDialog from "views/admin/Common/AddressDialog";
import CrmContactDialog from "../CrmContact/CrmContactDialog";
import ButtonStyles from "views/common/ButtonStyles";

const ContactOwnerPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  open,
  handleClose,
  onDataSelect,
}) => {
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const {Dialogstyle} = ButtonStyles();

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  const [showcontactdialog, setshowcontactdialog] = useState(false);

  const opendialog = () => {
    // handleClose();
    setshowcontactdialog(true);  
  };

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Contact Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <Button
            style={{
              width: "6.5%",
              border: "none",
              backgroundColor: "#a0c1cdcf",
            }}
            icon="pi pi-plus"
            className="p-button-secondary"
            onClick={opendialog}
          />
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["ccCnNo"] ? filters["ccCnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ccCnNo",
                        e.target.value,
                        filters["ccCnNo"]
                          ? filters["ccCnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_NM"] ? filters["cc_CN_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_NM",
                        e.target.value,
                        filters["cc_CN_NM"]
                          ? filters["cc_CN_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Name</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.cc_UK_NO}
                onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                      <td style={tableCellStyle}>{rowData.ccCnNo}</td>
                      <td style={tableCellStyle}>{rowData.cc_CN_NM}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
      <CrmContactDialog
        open={showcontactdialog}
        handleClose={() => setshowcontactdialog(false)}
        data={FormData}
        // handleFormSubmit={handleFormSubmit}
        // onchangedata={onchangedata}
        // onchangeFormData={onchangeFormData}
        // onchangeDropDownFormData={onchangeDropDownFormData}
        // onchangeDropDownFormData1={onchangeDropDownFormData1}
        // onchangeDropDownFormData2={onchangeDropDownFormData2}
        // cc_TY_PE={cc_TY_PE}
        // cc_LD_ST={cc_LD_ST}
        // ccCnNo={ccCnNo}
        // cc_DE_GN={cc_DE_GN}
        // selectedAbAdNM={selectedAbAdNM}
        // selectedAbAdNN={selectedAbAdNN}
        // setSelectedAbAdNM={setSelectedAbAdNM}
        // setSelectedAbAdNN={setSelectedAbAdNN}
        // onchangeUKFormData={onchangeUKFormData}
      />
    </Dialog>
  );
};

ContactOwnerPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default ContactOwnerPopupData;
