import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
export default function PurchaseProjectionDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeOrderdate,
  setFormData,
  ReleasePO,
  SelectedemEMNO,
  setSelectedemEMNO,
  setph_SH_NO,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNM,
  ph_OR_TY,
  ph_OR_CO,
  ph_BS_CR,
  ph_SU_NO,
  ph_SH_NO,
  ph_CO_CT,
  ph_EX_RT,
  ph_PY_TR,
  pd_ST_CD,
  setpd_IT_NM,
  setph_PY_TR,
  onchangedata,
  setph_SU_NO,
  setph_EX_RT,
  addRow,
  deleteRow,
  addRow1,
  deleteRow1,
  PurchaseProjectiontable,
  PurchaseProjectiontable1,
  setpay_to_SUPPLIER,
  setSelectedsupplier,
  setSelectedName,
  ph_HL_CD,
  setph_HL_CD,
  ph_LN_CT,
  setph_LN_CT,
  setpd_LO_CN,
  setpd_LT_NO,
}) {
  const { 
    pdUkNo,
    phUkNO,
    ph_OR_NO,
    ph_CN_DT,
    ph_OR_DT,
    ph_RQ_DT,
    ph_PR_DT,
    ph_TX_AP,
    net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,
    total,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
    fr_TOTAL,
  } = data;

  const {footergridstyle,footerfieldstyle,plusstyle,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,
    minusstyle,tablebutton,tablestyle,multitablestyle,GenerateStyles } = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const [activeTab, setActiveTab] = useState(0); 
  const [Addresstype, setAddresstype] = useState("S");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_TR_CROptions, setph_TR_CROptions] = useState([]);
  const [ph_TX_APOptions, setph_TX_APOptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [pd_SC_UMOptions, setpd_SC_UMOptions] = useState([]);
  const [pd_TX_APOptions, setpd_TX_APOptions] = useState([]);
  const [ph_PY_TROptions, setph_PY_TROptions] = useState([]);
  const [pay_to_SUPPLIEROptions, setpay_to_SUPPLIEROptions] = useState([]);
  const [ph_LN_CTOptions, setph_LN_CTOptions] = useState([]);
  const [ph_HL_CDOptions, setph_HL_CDOptions] = useState([]);
  const [pd_LT_NOOptions, setpd_LT_NOOptions] = useState([]);
  const [pd_LO_CNOptions, setpd_LO_CNOptions] = useState([]);

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const LandedCostDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_LN_CT(value);
  };
  const LOTNoDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LT_NO(value);
  };
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LO_CN(value);
  };
  const HoldCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_HL_CD(value);
  };
  const SupplierDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpay_to_SUPPLIER(value);
  };
  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData7(name, value, index);
  };

  const url = `${domain}/api/ClassCode/All`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;
//Currency
  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;
//Tax Applicable
  const parameter3 = "TX/AP";
  const finalUrl3 = `${url}/${parameter3}`;
//UM
  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;
//UM
  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl6 = `${urlpayterm}`;
// traiaining reason
  const parameter7 = "ME/PL";
  const finalUrl7 = `${url}/${parameter7}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    Supplier();
    LandedCost();
    HoldCode();
    LOTNo();
    LocatioN();
  }, []);
  const LandedCost = async () => {
    const Options = await fetchClassCodeDS("AP/LC");
    setph_LN_CTOptions(Options);
  };
  const LOTNo = async () => {
    const Options = await fetchClassCodeDS("AP/LC");
    setpd_LT_NOOptions(Options);
  };
  const LocatioN = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setpd_LO_CNOptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setph_HL_CDOptions(Options);
  };
  const Supplier = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setpay_to_SUPPLIEROptions(Options);
  };
// 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setph_TX_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_TR_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_SC_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.pt_PY_DS,
      value: item.pt_PY_CO,
    }));
    setph_PY_TROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 7th dropdown
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setpd_TX_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [addressTypeship, setaddressTypeship] = useState("F");
const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
const openSupplierhPopup = (e) => {
  setSupplierhPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
const openShipToPopup = (e) => {
  setShipToPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};
const handlesupplier =async (data) => {
  setSelectedName(data.ab_AD_NM);
  setSelectedsupplier( data.abAdNo);
  document.getElementById (`supplier_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`name_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplierPopupVisible(false);
  setShowSearchIcon(false);
};
// Supplier
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  setph_SU_NO(data.abAdNo);
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);
  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData6("ph_PY_TR", dataCuMt.su_PT_TM);
         setph_PY_TR(dataCuMt.su_PT_TM);
        onchangeDropDownFormData2("ph_TR_CR", dataCuMt.su_CR_CD);
        onchangeDropDownFormData3("ph_TX_AP", dataCuMt.su_TX_AP);
        HoldCodeDropdown("ph_HL_CD", dataCuMt.su_HL_CD);
        LandedCostDropdown("ph_LN_CT", response.data.Result.su_LD_CR);
        onchangeFormData(`ph_TR_CR`, dataCuMt.su_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("ph_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.ph_TR_CR, //currency
          "cr_EF_DT": data.ph_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setSupplierhPopupVisible(false);
};
exchangeRate(null);
function exchangeRate (exData){
  if(exData==null && ph_BS_CR!="" && data.ph_TR_CR!="" && data.ph_OR_DT!=""){
    exData={
      "cr_TO_CR": ph_BS_CR,
      "cr_FR_CR": data.ph_TR_CR,
      "cr_EF_DT": data.ph_OR_DT
    }
  }
  if(exData!=null){

    axios
    .post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
        setph_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  }
};
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);
  const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
  const requestData={
    "abAdNo":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = response.data;
    if (data && data.data) {
      setSelectedAbAdNM(data.data.ab_AD_NM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setShipToPopupVisible(false);
};
const onchangeFormUnitPrice = async(e,index) => {
  onchangedata(e);
  onChangeOfPrice(index);
};
const  onChangeOfPrice= async(SelectedRowIndex) => {
  const UrlCalulate = `${domain}/api/RentalContract/GetAmountDetails`;
  const priceInput = {
    "exChangeRate": document.getElementById("ph_EX_RT").value,
    "rentalContracts":[{
            "unitPrice":document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value,
            "quantity":document.getElementById (`pd_OR_QT_${SelectedRowIndex}`).value,
            "taxPercentage":document.getElementById (`pd_TX_PE_${SelectedRowIndex}`).value,
            "mulDisPercentage":document.getElementById (`pd_DS_PR_${SelectedRowIndex}`).value,
            "foreUnitPrice":document.getElementById (`pd_FR_UP_${SelectedRowIndex}`).value,
       } ]
  }
  try {
    const response =await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCalulate = response.data.Result;
    if (dataCalulate ) {
      document.getElementById (`pd_DS_AM_${SelectedRowIndex}`).value =  dataCalulate.matDisamt;
      document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value =  dataCalulate.unitPrice;
      document.getElementById (`net_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.matnetAmtMul;
      document.getElementById (`pd_FR_DS_${SelectedRowIndex}`).value =  dataCalulate.forematDisamt;
      document.getElementById (`pd_FR_AT_${SelectedRowIndex}`).value =  dataCalulate.foreAmount;
      document.getElementById (`fr_NET_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.forenetAmtMul;
      document.getElementById (`pd_OR_AT_${SelectedRowIndex}`).value =  dataCalulate.matUnit;
      document.getElementById (`pd_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.matTaxamt;
      // COPY
      const purchaseQuotes = {
        ph_TR_CR: data.ph_TR_CR,
        ph_EX_RT: document.getElementById("ph_EX_RT").value,
        ph_BS_CR: document.getElementById("ph_BS_CR").value,
        ph_CO_CT: ph_CO_CT
      };
     
      const table = document.getElementById("PurchaseProjectiontable");
      const totalRowCount = table.tBodies[0].rows.length;
      const PurchaseProjections = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const PR_DT = document.getElementsByName("pd_PR_DT_" + i)[0];
        const Details = {
          pdUkNo:  document.getElementById("pdUkNo_" + i).value,
          pd_LN_NO: document.getElementById("pd_LN_NO_" + i).value,
          pd_IT_NM: document.getElementById("pd_IT_NM_" + i).value,
          im_IT_DS: document.getElementById("im_IT_DS_" + i).value,
          pd_OR_QT: document.getElementById("pd_OR_QT_" + i).value,
          pd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          pd_SC_QT: document.getElementById("pd_SC_QT_" + i).value,
          pd_SC_UM: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
          availability_AMOUNT :document.getElementById("availability_AMOUNT_" + i).value,
          pd_LS_PR: document.getElementById("pd_LS_PR_" + i).value,
          pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
          pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
          pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
          pd_DS_AM :document.getElementById("pd_DS_AM_" + i).value,
          net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
          pd_TX_AP : table.tBodies[0].rows[i].cells[15].children[0].children[1].children[0].value,
          pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
          pd_TX_AM: document.getElementById("pd_TX_AM_" + i).value,
          pd_FR_UP :document.getElementById("pd_FR_UP_" + i).value,
          pd_FR_AT :document.getElementById("pd_FR_AT_" + i).value,
          pd_FR_DS :document.getElementById("pd_FR_DS_" + i).value,
          fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
          pd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
          pd_CN_DT: CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_ST_CD: document.getElementById("pd_ST_CD_" + i).value,
        };
        PurchaseProjections.push(Details);
      }
      const requestBodyNet = {
        PurchaseProjections,
        purchaseQuotes
      };
      axios.post(`${domain}/api/PurchaseQuote/GetPopulateNetAmount`, requestBodyNet, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((respNet) => {
        document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.fr_PRICE_AMOUNT;
        document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.fr_DISCOUNT_AMOUNT;
        document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.fr_NET_AMOUNT;
        document.getElementById("tax_AMOUNT").value = respNet.data.header.tax_AMOUNT;
        document.getElementById("discount_AMOUNT").value = respNet.data.header.discount_AMOUNT;
        document.getElementById("price_AMOUNT").value = respNet.data.header.price_AMOUNT;
        document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
        document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.fr_TAX_AMOUNT;
        document.getElementById("total").value = respNet.data.header.total;
        document.getElementById("fr_TOTAL").value = respNet.data.header.fr_TOTAL;
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });


    } else {
      console.error("Invalid API response:", dataCalulate);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("PurchaseProjectiontable");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : ph_CO_CT,
        "bp_IT_NM" : document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : ph_OR_DT,
        "bp_TO_DT" : ph_OR_DT,
        "bp_CU_NO" : document.getElementById("ph_SU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : data.ph_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            // onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
};
const handleDataSelect2 = async(Result) => {
  setSelectedAbAdNN(Result.im_IT_DS);
  setpd_IT_NM(Result.im_IT_NM);
  document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
  document.getElementById (`im_IT_DS_${SelectedRowIndex}`).value =  Result.im_IT_DS;
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeDropDownFormData7(`pd_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      document.getElementById (`pd_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(phUkNO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{phUkNO ? "Revise Purchase Projection" : "Add Purchase Projection"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
    {activeTab === 0 && (
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
    )}
    {activeTab === 0 && (
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    )}
     {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          {activeTab === 0 && (
          <Button
            style={GenerateStyles}
            icon="pi pi-file"
            variant="outlined"
            onClick={ReleasePO}
            tooltip="Release PO"
          />
        )}
        </div>
       )}
    </div>
  </div>
);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
    <Button 
    style={{width:"5%",display:isUpdateMode ? 'block' : 'none',
      marginLeft:"0%",marginTop:"-3%",backgroundColor:"transparent",color:"blue",
    }}
      icon="pi pi-check-circle" 
      onClick={ReleasePO} 
      tooltip={"Release PO"}>
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Enter Purchase Projection">
              {/* tab1 general */}

              <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
              <label htmlFor="phUkNO" className="font-bold">
              UKNO
              </label>
              <InputText
              id="phUkNO"
              name="phUkNO"
              value={phUkNO}
              onChange={(e) => onchangedata(e)}
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_NO" className="font-bold">
            Order No
            </label>
            <InputText disabled
            id="ph_OR_NO"
            name="ph_OR_NO"
            value={ph_OR_NO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_TY" className="font-bold">
            Type
            </label>
            <InputText disabled
            id="ph_OR_TY"
            name="ph_OR_TY"
            value={ph_OR_TY}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
            id="ph_OR_CO"
            name="ph_OR_CO"
            value={ph_OR_CO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_DT" className="font-bold">
            Order Date
            </label>
            <Calendar
            id="ph_OR_DT"
            name="ph_OR_DT"
            value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
            onChange={(e) =>
            onchangeOrderdate({
            target: {
            name: "ph_OR_DT",
            value: moment(e.value).format("YYYYMMDD"),
            },
            })
            }
            dateFormat="dd/mm/yy"
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_RQ_DT" className="font-bold">
            Requested Date
            </label>
            <Calendar
            id="ph_RQ_DT"
            name="ph_RQ_DT"
            value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "ph_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
            
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled
            id="ph_CO_CT"
            name="ph_CO_CT"
            value={ph_CO_CT}
            options={ph_CO_CTOptions}
            onChange={(e) => onchangeDropDownFormData1("ph_CO_CT", e.value)}
            placeholder="Select Cost Centre"
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_SU_NO" className="font-bold">
            Supplier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              id="sup_NAME"
              name="sup_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNO}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              )}
              </div>
              <div style={{ display: "none" }}>
              <InputText
              id="ph_SU_NO"
              name="ph_SU_NO"
              value={ph_SU_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PY_TR" className="font-bold">
                Pay Term
              </label>
              <Dropdown
                id="ph_PY_TR"
                name="ph_PY_TR"
                value={ph_PY_TR}
                options={ph_PY_TROptions}
                onChange={(e) => onchangeDropDownFormData6("ph_PY_TR", e.value)}
                placeholder="Select Pay Term"
              />
            </div>
            

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TX_AP" className="font-bold">
              Tax Applicable
              </label>
              <Dropdown
              id="ph_TX_AP"
              name="ph_TX_AP"
              value={ph_TX_AP}
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData3("ph_TX_AP", e.value)}
              placeholder="Select Tax Applicable"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_SH_NO" className="font-bold">
              Ship To <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText
              id="shipto_NAME"
              name="shipto_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedAbAdNM}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openShipToPopup}
              ></i>
              )}
              </div>
              <div style={{display:"none"}}>
              <InputText
              id="ph_SH_NO"
              name="ph_SH_NO"
              value={ph_SH_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              </div>
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_LN_CT" className="font-bold">
              Landed Cost
              </label>
              <Dropdown
              id="ph_LN_CT"
              name="ph_LN_CT"
              value={ph_LN_CT}
              options={ph_LN_CTOptions}
              onChange={(e) => LandedCostDropdown("ph_LN_CT", e.value)}
              placeholder="Select LandedCost"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_HL_CD" className="font-bold">
              Hold Code
              </label>
              <Dropdown
              id="ph_HL_CD"
              name="ph_HL_CD"
              value={ph_HL_CD}
              options={ph_HL_CDOptions}
              onChange={(e) => HoldCodeDropdown("ph_HL_CD", e.value)}
              placeholder="Select Hold Code"
              />
              </div>
            
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TR_CR" className="font-bold">
              Currency
              </label>
              <Dropdown
              id="ph_TR_CR"
              name="ph_TR_CR"
              value={data.ph_TR_CR}
              options={ph_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData2("ph_TR_CR", e.value)}
              placeholder="Select Currency"
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_EX_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
              id="ph_EX_RT"
              name="ph_EX_RT"
              value={ph_EX_RT}
              onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_BS_CR" className="font-bold">
            Base Currency
            </label>
            <InputText disabled
            id="ph_BS_CR"
            name="ph_BS_CR"
            value={ph_BS_CR}
            onChange={(e) => onchangedata(e)}
            />
            </div>
            
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_PR_DT" className="font-bold">
            Promised Date
            </label>
            <Calendar
            id="ph_PR_DT"
            name="ph_PR_DT"
            value={moment(ph_PR_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "ph_PR_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
            />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_CN_DT" className="font-bold">
            Cancelled Date
            </label>
            <Calendar
            id="ph_CN_DT"
            name="ph_CN_DT"
            value={moment(ph_CN_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "ph_CN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
            />
            </div>
          
          </div>
              </div>

              <div  style={tablestyle} >
                <div className="table-buttons" style={tablebutton} >
                  {(
                  <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                  )}
                  {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                  )}
                </div>
                <div  style={multitablestyle}>
                <table className="custom-table" id="PurchaseProjectiontable" style={{overflow:"auto"}}>
              <thead>
                <tr>
                  <th style={{ display: "none" }} >UK_NO</th>
                  <th>Line No</th>
                  <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
                  <th>Description</th>
                  <th> Order   Quantity</th>
                  <th>UM</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Fore.Price</th>
                  <th>Fore.Amount</th>
                  <th>Released Quantity</th>
                  <th>Balance Quantity</th>
                  <th>Confirm Quantity</th>
                  <th>Tax Applicable</th>
                  <th>Tax %</th>
                  <th>Tax Amount</th>
                  <th>Landed Cost</th>
                  <th>LOT No</th>
                  <th>Location</th>
                  <th>Can. Quantity</th>
                  <th>Can. Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {PurchaseProjectiontable.map((row, index) => (
                      <tr key={index}>
                    <td style={{ display: "none" }} >
                    <InputText
                      id={`pdUkNo_${index}`}
                      name={`pdUkNo_${index}`}
                      defaultValue={row.pdUkNo}
                    />
                  </td>
                  <td>
                  <InputText disabled
                  style={{ width: "90px" }}
                  id={`pd_LN_NO_${index}`}
                  name={`pd_LN_NO_${index}`}
                  value={index + 1}
                  readOnly
                />
                  </td>
                  <td >
                    <div style={{ display: "flex" }}> 
                    <InputText
                      style={{ width: "130px" }}
                      id={`pd_IT_NM_${index}`}
                      name={`pd_IT_NM_${index}`}
                      // defaultValue={pd_IT_NM || row.pd_IT_NM}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={row.pd_IT_NM ? row.pd_IT_NM: row[`${"pd_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "15%",
                        cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                    ></i>
                    )}
                    </div>
                  </td>
                  <td>
                  <InputText disabled
                      style={{ width: "200px" }}
                      id={`im_IT_DS_${index}`}
                      name={`im_IT_DS_${index}`}
                      defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                    />
                  </td>
                  <td>
                  <InputText style={{width:"120px"}}
                    id={`pd_OR_QT_${index}`}
                    name={`pd_OR_QT_${index}`}
                    defaultValue={row.pd_OR_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`pd_TR_UM_${index}`}
                    name={`pd_TR_UM_${index}`}
                    value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
                    options={pd_TR_UMOptions}
                    onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                  </td>
                  <td>
                  <InputText style={{width:"100px"}}
                  disabled={data.ph_TR_CR==ph_BS_CR?false:true}
                    id={`pd_BS_UP_${index}`}
                    name={`pd_BS_UP_${index}`}
                    defaultValue={row.pd_BS_UP}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:"100px"}}
                    id={`pd_OR_AT_${index}`}
                    name={`pd_OR_AT_${index}`}
                    defaultValue={row.pd_OR_AT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText style={{width:"90px"}}
                  disabled={data.ph_TR_CR!=ph_BS_CR?false:true}
                    id={`pd_FR_UP_${index}`}
                    name={`pd_FR_UP_${index}`}
                    defaultValue={data.ph_TR_CR!=ph_BS_CR ? row.pd_FR_UP:0}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`pd_FR_AT_${index}`}
                    name={`pd_FR_AT_${index}`}
                    defaultValue={data.ph_TR_CR!=ph_BS_CR ? row.pd_FR_AT:0}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"130px"}}
                    id={`pd_RL_QT_${index}`}
                    name={`pd_RL_QT_${index}`}
                    defaultValue={row.pd_RL_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"130px"}}
                    id={`balance_QTY_${index}`}
                    name={`balance_QTY_${index}`}
                    defaultValue={row.balance_QTY}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"130px"}}
                    id={`confirm_QTY_${index}`}
                    name={`confirm_QTY_${index}`}
                    defaultValue={row.confirm_QTY}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Dropdown
                  id={`pd_TX_AP_${index}`}
                  name={`pd_TX_AP_${index}`} 
                  value={data[`pd_TX_AP_${index}`] ? data[`pd_TX_AP_${index}`] : row.pd_TX_AP} 
                  options={[
                  { label: "Yes", value: "y" },
                  { label: "No", value: "n" },
                  ]}
                  onChange={(e) => handleFormDataChange3(`pd_TX_AP_${index}`, e.value)} 
                  placeholder="Select Tax Applicable "
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`pd_TX_PE_${index}`}
                    name={`pd_TX_PE_${index}`}
                    defaultValue={row.pd_TX_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:"90px"}}
                    id={`pd_TX_AM_${index}`}
                    name={`pd_TX_AM_${index}`}
                    defaultValue={row.pd_TX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>   
                  <td>
                  <InputText  style={{width:"90px"}}
                    id={`pd_LD_CT_${index}`}
                    name={`pd_LD_CT_${index}`}
                    defaultValue={row.pd_LD_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>         
                  <td>
                  <Dropdown
                    id={`pd_LT_NO_${index}`}
                    name={`pd_LT_NO_${index}`}
                    value={ data[`pd_LT_NO_${index}`] ? data[`pd_LT_NO_${index}`]: row.pd_LT_NO}
                    options={pd_LT_NOOptions}
                    onChange={(e) =>LOTNoDropdown(`pd_LT_NO_${index}`, e.value)}
                    placeholder="Select LOT No"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`pd_LO_CN_${index}`}
                    name={`pd_LO_CN_${index}`}
                    value={ data[`pd_LO_CN_${index}`] ? data[`pd_LO_CN_${index}`]: row.pd_LO_CN}
                    options={pd_LO_CNOptions}
                    onChange={(e) =>LocationDropdown(`pd_LO_CN_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  
                  <td>
                  <InputText style={{width:"120px"}}
                    id={`pd_CN_QT_${index}`}
                    name={`pd_CN_QT_${index}`}
                    defaultValue={row.pd_CN_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Calendar 
                  style={{width:"150px"}}
                  id={`pd_CN_DT_${index}`}
                  name={`pd_CN_DT_${index}`}
                  value={currentDate}
                  onChange={(e) => {setCurrentDate(e.value);
                  onDateChange("pd_CN_DT", index, e.value);
                  }}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <InputText disabled
                  style={{ width: "100px" }}
                  id={`pd_ST_CD_${index}`}
                  name={`pd_ST_CD_${index}`}
                  value={ row.pd_ST_CD ? row.pd_ST_CD : pd_ST_CD}
                  readOnly
                  />
                </td>
                  </tr>
                  ))}
              </tbody>
            </table>   
            
            </div>
              </div>  

              <div style={inboxstyle} >
                <div className="formgrid grid" style={footergridstyle}>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="price_AMOUNT" className="font-bold">
                      Amount
                    </label>
                    <InputText disabled
                      id="price_AMOUNT"
                      name="price_AMOUNT"
                      value={price_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="discount_AMOUNT" className="font-bold">
                      Discount
                    </label>
                    <InputText disabled
                      id="discount_AMOUNT"
                      name="discount_AMOUNT"
                      value={discount_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="tax_AMOUNT" className="font-bold">
                      Tax
                    </label>
                    <InputText disabled
                      id="tax_AMOUNT"
                      name="tax_AMOUNT"
                      value={tax_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="TOTAL" className="font-bold">
                      Total
                    </label>
                    <InputText disabled
                      id="total"
                      name="total"
                      value={total}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  </div>
                  <div className="formgrid grid" style={footergridstyle}>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                      Fore.Amount
                    </label>
                    <InputText disabled
                      id="fr_PRICE_AMOUNT"
                      name="fr_PRICE_AMOUNT"
                      value={fr_PRICE_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="FR_DISCOUNT_AMOUNT" className="font-bold">
                      Fore.Discount
                    </label>
                    <InputText disabled
                      id="fr_DISCOUNT_AMOUNT"
                      name="fr_DISCOUNT_AMOUNT"
                      value={fr_DISCOUNT_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="FR_TAX_AMOUNT" className="font-bold">
                      Fore.Tax
                    </label>
                    <InputText disabled
                      id="fr_TAX_AMOUNT"
                      name="fr_TAX_AMOUNT"
                      value={fr_TAX_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field" style={footerfieldstyle} >
                    <label htmlFor="fr_TOTAL" className="font-bold">
                      Fore.Total
                    </label>
                    <InputText disabled
                      id="fr_TOTAL"
                      name="fr_TOTAL"
                      value={fr_TOTAL}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
              </div>
          </TabPanel>
          <TabPanel header="Landed Cost">
        <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow1} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow1} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
          <table className="custom-table" id="PurchaseProjectiontable1" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th>Landed Item</th>
            <th>Description </th>
            <th>Landed Cost</th>
            <th>Pay to Supplier</th>
            <th style={{ display: "none" }}>Supplier</th>
            <th>Supplier <span style={{ color: 'red', }}>*</span></th>
          </tr>
        </thead>
        <tbody>
        {PurchaseProjectiontable1.map((row, index) => (
            <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`pdUkNo_${index}`}
                name={`pdUkNo_${index}`}
                defaultValue={row.pdUkNo}
              />
            </td>
            <td>
            <InputText disabled
              id={`landed_ITEM_${index}`}
              name={`landed_ITEM_${index}`}
              defaultValue={row.landed_ITEM}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <InputText disabled 
              id={`description_${index}`}
              name={`description_${index}`}
              defaultValue={row.description}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <InputText  
              id={`cost_AMOUNT_${index}`}
              name={`cost_AMOUNT_${index}`}
              defaultValue={row.cost_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <Dropdown 
            id={`pay_to_SUPPLIER_${index}`}
            name={`pay_to_SUPPLIER_${index}`} 
            value={data[`pay_to_SUPPLIER_${index}`] ? data[`pay_to_SUPPLIER_${index}`] : row.pay_to_SUPPLIER} 
            options={[
            { label: "Yes", value: "y" },
            { label: "No", value: "n" },
            ]}
            onChange={(e) => SupplierDropDown(`pay_to_SUPPLIER_${index}`, e.value)} 
            placeholder="Select Pay to Supplier "
            />
            </td>
            <td style={{ display: "none" }}> 
            <InputText disabled
            id={`supplier_${index}`}
            name={`supplier_${index}`}
            value={row.supplier ? row.supplier: data[`${"supplier"}_${index}`]}
            onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <div style={{ display: "flex" }}>
            <InputText disabled 
            id={`name_${index}`}
            name={`name_${index}`}
            onChange={(e) => onchangedata(e)}
            onFocus={() => setShowSearchIcon(true)}
            value={row.name ? row.name : data[`${"name"}_${index}`]}
            />
            {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "7%",
            cursor: "pointer",
            }}
            onClick={(e) => openSupplierPopup(index)}
            ></i>
            )}
            </div>
            </td>
            
            </tr>
            ))}
        </tbody>
      </table>   
      
      </div>
    </div>  
          </TabPanel>
        </TabView>
      </div> 

      {/* supplier h */}
      <AddressBookPopup
        visible={SupplierhPopupVisible}
        onClose={() => setSupplierhPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData={setFormData}
        />
      {/*shipto  */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeship}
        setFormData={setFormData}
      />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      {/* supplier */}
      <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handlesupplier}
        formData={data}
        addressType={Addresstype}
        setFormData={setFormData}
      />
      
    </Dialog>
  );
}
