import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SecurityDepositDialog from "./SecurityDepositDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  seLeNo: "",
  se_BL_CD: "",
  se_UN_TS: "",
  se_TE_NT:"",
  tenant_NAME:"",
  de_TYPE_DESC:"",
  se_AS_NO:"",
  dsd:"",
  se_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  se_EX_PR:"",
  se_FR_AM: "",
  seUkNo:"",
  se_LE_TY:"",
  se_LE_CO:"",
  se_GL_DT: moment(moment().toDate()).format("YYYYMMDD"),
  se_BS_CR:"",
  se_PR_NO:"",
  se_BL_NO:"",
  se_FL_NO:"",
  se_FR_DT:"",
  se_EN_DT:"",
  se_TR_CR:"",
  se_PO_ST:"",
};

const initialFilters = {
  seLeNo: { value: "", operation: "" },
  de_TYPE_DESC: { value: "", operation: "" },
  se_BL_CD: { value: "", operation: "" },
  se_UN_TS: { value: "", operation: "" },
  se_TE_NT: { value: "", operation: "" },
  tenant_NAME: { value: "", operation: "" },
  se_AS_NO: { value: "", operation: "" },
  dsd: { value: "", operation: "" },
  se_TR_DT: { value: "", operation: "" },
  se_EX_PR: { value: "", operation: "" },
  se_FR_AM: { value: "", operation: "" },
  se_PO_ST: { value: "", operation: "" },
 
};

const SecurityDepositeMain = () => {
    // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,
  checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,poststyles
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [se_PR_NO, setse_PR_NO] = useState("");
  const [se_BL_NO, setse_BL_NO] = useState("");
  const [se_FL_NO, setse_FL_NO] = useState("");
  const [se_UN_TS, setse_UN_TS] = useState("");
  const [se_BL_CD, setse_BL_CD] = useState("");
  const [se_AS_NO, setse_AS_NO] = useState("");
  const [bi_ST_DT, setbi_ST_DT] = useState("");
  const [se_DO_NO, setse_DO_NO] = useState("");


  const [SecurityDepositTable, setSecurityDepositTable] = useState([{}]);

  const [SelectedTenent,setSelectedTenent] =useState("");
  
  const [SelectedTransCurrency,setSelectedTransCurrency] =useState("");

  const [SelectedLeaseNo,setSelectedLeaseNo] =useState("");

  const [SelectedLeaseType,setSelectedLeaseType] =useState("");

  const [SelectedLeaseCompany,setSelectedLeaseCompany] =useState("");

  const [SelectedExchangeRate,setSelectedExchangeRate] =useState("");

  const [SelectedBaseCurrency,setSelectedBaseCurrency] =useState("");

  const [SelectedProperty,setSelectedProperty] =useState("");

  const [SelectedBuidling,setSelectedBuidling] =useState("");

  const [SelectedFloor,setSelectedFloor] =useState("");

  const [SelectedUnit,setSelectedUnit] =useState("");

  const [SelectedTenentname,setSelectedTenentname] =useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };



  const handleClickOpen = () => {
    
    
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setSecurityDepositTable([{}]);
    setFormData(initialValue);
    setse_BL_CD(null);
    setse_AS_NO(null);
    setse_TR_CR(null);
    setse_BS_CR(null);
    setSelectedTenentname(null);
    setSelectedLeaseNo(null);
    setSelectedLeaseType(null);
    setSelectedLeaseCompany(null);
    setSelectedTenent(null);
    setSelectedTransCurrency(null);
    setSelectedExchangeRate(null);
    setSelectedBaseCurrency(null);
    setOpen(true);
  };

  const handleClose = () => {
    setSecurityDepositTable([{}]);
    setFormData(initialValue);
    setse_BL_CD(null);
    setse_AS_NO(null);
    setSelectedTenentname(null);
    setSelectedLeaseNo(null);
    setSelectedLeaseType(null);
    setSelectedLeaseCompany(null);
    setSelectedTenent(null);
    setSelectedTransCurrency(null);
    setSelectedExchangeRate(null);
    setSelectedBaseCurrency(null);
    setOpen(false);
  };

  const addRow = () => {
    var startdate=null;
    var enddate=null;
    if(SecurityDepositTable[0]!=undefined){
       startdate=SecurityDepositTable[0]['se_FR_DT']==undefined?null:SecurityDepositTable[0]['se_FR_DT'];
       enddate=SecurityDepositTable[0]['se_EN_DT']==undefined?null:SecurityDepositTable[0]['se_EN_DT'];
    }
    setSecurityDepositTable([...SecurityDepositTable, {'se_FR_DT':startdate,'se_EN_DT':enddate}]);
  };
  
  const deleteRow = () => {
    if (SecurityDepositTable.length > 0) {
      const updatedData = [...SecurityDepositTable];
      updatedData.pop();
      setSecurityDepositTable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SecurityDeposit/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

        if (response.status === 200) {
        const SecurityDepositData = response.data;
        delete SecurityDepositData.seUkNo;
        for (let i = 0; i < SecurityDepositData.securityDeposits.length; i++) {
          delete SecurityDepositData.securityDeposits[i].seUkNo;
        }
        setSecurityDepositTable(SecurityDepositData.securityDeposits);
        setFormData(SecurityDepositData.securityDeposits[0]);

        setSelectedLeaseNo(response.data.seLeNo);
        setSelectedLeaseType(response.data.se_LE_TY);
        setSelectedLeaseCompany(response.data.se_LE_CO);

        setSelectedTenent(response.data.se_TE_NT);
        setSelectedTransCurrency(response.data.se_TR_CR);
        setSelectedExchangeRate(response.data.se_EX_RT);

        setSelectedBaseCurrency(response.data.se_BS_CR);
        setse_BL_CD(response.data.se_BL_CD);
        setse_AS_NO(response.data.se_AS_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const url = `${domain}/api/Contact/AllFields`;

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

const [se_LE_CO,setse_LE_CO] = useState("");
const [se_LE_TY,setse_LE_TY] = useState("");

const [se_DO_CO,setse_DO_CO] = useState("");
const [se_DO_TY,setse_DO_TY] = useState("");
const [se_BS_CR,setse_BS_CR] = useState("");
const [se_TR_CR,setse_TR_CR] = useState("");

  const handleDefault = async () => {
    setse_DO_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setse_LE_CO(`${company}`)
            if(fName === "SE_LE_TY"){
              setse_LE_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SE_DO_TY"){
              setse_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SecurityDeposit/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_PR_NO(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_BL_NO(value);
  };
  const onchangeDropDownFormData3= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_FL_NO(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_UN_TS(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_BL_CD(value);
  };
  const onchangeDropDownFormData6= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setse_AS_NO(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SecurityDeposit/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SecurityDepositData = response.data;
        setSecurityDepositTable(SecurityDepositData.securityDeposits);
        setFormData(SecurityDepositData.securityDeposits[0]);
        setSelectedLeaseNo(response.data.seLeNo);
        setSelectedLeaseType(response.data.se_LE_TY);
        setSelectedLeaseCompany(response.data.se_LE_CO);
        setSelectedTenent(response.data.se_TE_NT);
        setse_TR_CR(response.data.se_TR_CR);
        setSelectedExchangeRate(response.data.se_EX_RT);
        setse_BS_CR(response.data.se_BS_CR);
        setse_BL_CD(response.data.se_BL_CD);
        setse_AS_NO(response.data.se_AS_NO);
        setSelectedTenentname(response.data.tenant_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
      const table = document.getElementById("SecurityDepositTable");
      const totalRowCount = table.tBodies[0].rows.length;
      const securityDeposits = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const FR_DT = document.getElementsByName("se_FR_DT_" + i)[0]; 
        const EN_DT = document.getElementsByName("se_EN_DT_" + i)[0];
        const Details = {
          seUkNo: document.getElementById("seUkNo_" + i).value,
          se_FL_NO: document.getElementById("se_FL_NO_" + i).value,
          se_PR_NO: document.getElementById("se_PR_NO_" + i).value,
          se_BL_NO: document.getElementById("se_BL_NO_" + i).value,
          se_UN_TS: document.getElementById("se_UN_TS_" + i).value,
          se_BL_CD: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          se_AS_NO :table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value, 
          se_EX_PR: document.getElementById("se_EX_PR_" + i).value,
          se_FR_AM: document.getElementById("se_FR_AM_" + i).value,
          se_FR_DT: FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          se_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        };
        securityDeposits.push(Details);
      }
      requestBodyInput = {
        securityDeposits,
        seUkNo: FormData.seUkNo,
        seLeNo: document.getElementById("seLeNo").value,
        se_LE_TY: document.getElementById("se_LE_TY").value,
        se_LE_CO: document.getElementById("se_LE_CO").value,
        se_DO_NO: document.getElementById("se_DO_NO").value,
        se_DO_TY: document.getElementById("se_DO_TY").value,
        se_DO_CO: document.getElementById("se_DO_CO").value,
        se_TE_NT: document.getElementById("se_TE_NT").value,
        se_TR_DT: FormData.se_TR_DT,
        se_GL_DT: FormData.se_GL_DT,
        se_TR_CR: document.getElementById("se_TR_CR").value,
        se_EX_RT: document.getElementById("se_EX_RT").value,
        se_BS_CR: document.getElementById("se_BS_CR").value,
      };
    }
    const handleFormSubmit = () => {
      values();
      const orNo = document.getElementById("se_DO_NO").value;
      const ukNo = FormData.seUkNo;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Update ?");
          const update = `${domain}/api/SecurityDeposit/Update`;
      
          if (confirm) {
            axios.put(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } else {
          axios.post(`${domain}/api/SecurityDeposit/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {    
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } else {     
          
          const OR_DT = FormData.se_GL_DT;
          if (OR_DT) {
            const currentYear = OR_DT.toString();
            const firstHalf = currentYear.substring(0, 2);
            const secondHalf = currentYear.substring(2, 4);
            const urlNextNumber = `${domain}/api/DocumentNumber/All`;
            const parameters = `${se_DO_TY}/${secondHalf}/1000/${firstHalf}`;
            const finalUrl1 = `${urlNextNumber}/${parameters}`;
            fetch(finalUrl1, {
              method: "GET",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            })
              .then((resp) => {
                if (!resp.ok) {
                  throw new Error(`Request failed with status: ${resp.status}`);
                }
                return resp.json();
              })
              .then((resp) => {
                setse_DO_NO(resp.dn_DO_NO);
                document.getElementById("se_DO_NO").value = resp.dn_DO_NO;
  
                values();
                axios.post(`${domain}/api/SecurityDeposit/Add`, requestBodyInput, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "http://localhost:3000",
                        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                      },
                    })
                    .then((resp) => {
                      handleResponse(resp.data);
                    })
                    .catch((error) => {
                      console.error("Error saving data:", error);
                    });
  
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });
          }
        }
      };


    const handleConfirm = () => {    
      values();
      const orNo = document.getElementById("se_DO_NO").value;
      const ukNo = FormData.seUkNo;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Confirm ?");
          const update = `${domain}/api/SecurityDeposit/ConfirmSecurityDeposit`;
          if (confirm) {
            axios
              .post(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } 
      }
  } ;

    const handlePost = async () => {
      if (!isRecordSelected || selectedRows.length > 1) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: isRecordSelected
            ? "Select a single record to edit."
            : "Select only one record to edit.",
          life: 3000,
        });
        return;
      }
  
      const requestBodyAPIInput = {
        "seUkNo" : `${selectedRows[0]}`,
      }
      axios
      .post(`${domain}/api/SecurityDeposit/PostSecurityDeposit`, requestBodyAPIInput, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
      handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
    };

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
       <Tooltip target=".post-button" position="bottom" mouseTrack >
  <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
  Post
  </div>
    </Tooltip>
      <Button 
        style={{backgroundColor:"#0099FF",color:'white',border:"none",lineHeight: "10%",}}
        icon="pi pi-send"
        outlined
        className="mr-2 post-button"
        onClick={handlePost}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };

  const tableCellStyleA = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "right",
    width:"0%",
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
              <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["seLeNo"] ? filters["seLeNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "seLeNo",
                        e.target.value,
                        filters["seLeNo"]
                          ? filters["seLeNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_BL_NO"] ? filters["se_BL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_BL_NO",
                        e.target.value,
                        filters["se_BL_NO"]
                          ? filters["se_BL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_UN_TS"] ? filters["se_UN_TS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_UN_TS",
                        e.target.value,
                        filters["se_UN_TS"]
                          ? filters["se_UN_TS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_TE_NT"] ? filters["se_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_TE_NT",
                        e.target.value,
                        filters["se_TE_NT"]
                          ? filters["se_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tenant_NAME"] ? filters["tenant_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tenant_NAME",
                        e.target.value,
                        filters["tenant_NAME"]
                          ? filters["tenant_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["se_AS_NO"] ? filters["se_AS_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_AS_NO",
                        e.target.value,
                        filters["se_AS_NO"]
                          ? filters["se_AS_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["de_TYPE_DESC"] ? filters["de_TYPE_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "de_TYPE_DESC",
                        e.target.value,
                        filters["de_TYPE_DESC"]
                          ? filters["de_TYPE_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_TR_DT"] ? filters["se_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_TR_DT",
                        e.target.value,
                        filters["se_TR_DT"]
                          ? filters["se_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_EX_PR"] ? filters["se_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_EX_PR",
                        e.target.value,
                        filters["se_EX_PR"]
                          ? filters["se_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_FR_AM"] ? filters["se_FR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_FR_AM",
                        e.target.value,
                        filters["se_FR_AM"]
                          ? filters["se_FR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_CN_FM"] ? filters["se_CN_FM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_CN_FM",
                        e.target.value,
                        filters["se_CN_FM"]
                          ? filters["se_CN_FM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["se_PO_ST"] ? filters["se_PO_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "se_PO_ST",
                        e.target.value,
                        filters["se_PO_ST"]
                          ? filters["se_PO_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Lease No</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyleH}>Deposit Type</th>  
                <th style={tableHeaderStyleH}>Deposit Type</th> 
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Fore.Amount</th>
                <th style={tableHeaderStyle}>Confirm</th>
                <th style={tableHeaderStyle}>Post</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.seUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.seUkNo}
                        checked={selectedRows.includes(rowData.seUkNo)}
                        onChange={() => handleRowSelect(rowData.seUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.seLeNo}</td>
                    <td style={tableCellStyle}>{rowData.se_BL_NO}</td>
                    <td style={tableCellStyle}>{rowData.se_UN_TS}</td>
                    <td style={tableCellStyle}>{rowData.se_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.tenant_NAME}</td>
                    <td style={tableCellStyleH}>{rowData.se_AS_NO}</td>
                    <td style={tableCellStyleH}>{rowData.de_TYPE_DESC}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.se_TR_DT)}</td>
                    <td style={tableCellStyleA}>{rowData.se_EX_PR}</td>
                    <td style={tableCellStyleA}>{rowData.se_FR_AM}</td>
                    <td style={tableCellStyleA}>{rowData.se_CN_FM}</td>
                    <td style={tableCellStyleA}>{rowData.se_PO_ST}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
  {/* pagination */}
  <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
    />
      </div>
      <SecurityDepositDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        se_PR_NO={se_PR_NO}
        se_BL_NO={se_BL_NO}
        se_FL_NO={se_FL_NO}
        se_UN_TS={se_UN_TS}
        se_BL_CD={se_BL_CD}
        se_AS_NO={se_AS_NO}
        setSelectedLeaseNo={setSelectedLeaseNo}
        SelectedLeaseNo={SelectedLeaseNo}
        setSelectedLeaseType={setSelectedLeaseType}
        SelectedLeaseType={SelectedLeaseType}
        setSelectedLeaseCompany={setSelectedLeaseCompany}
        SelectedLeaseCompany={SelectedLeaseCompany}
        SelectedTenent={SelectedTenent}
        SelectedTenentname={SelectedTenentname}
        setSelectedTenentname={setSelectedTenentname}
        setSelectedTenent={setSelectedTenent}
        SelectedTransCurrency={SelectedTransCurrency}
        setSelectedTransCurrency={setSelectedTransCurrency}
        setSelectedExchangeRate={setSelectedExchangeRate}
        SelectedExchangeRate={SelectedExchangeRate}
        setSelectedBaseCurrency={setSelectedBaseCurrency}
        SelectedBaseCurrency={SelectedBaseCurrency}
        setSelectedProperty={setSelectedProperty}
        SelectedProperty={SelectedProperty}
        setSelectedBuidling={setSelectedBuidling}
        SelectedBuidling={SelectedBuidling}
        setSelectedFloor={setSelectedFloor}
        SelectedFloor={SelectedFloor}
        setSelectedUnit={setSelectedUnit}
        SelectedUnit={SelectedUnit}
        SecurityDepositTable={SecurityDepositTable} 
        setSecurityDepositTable={setSecurityDepositTable}
        addRow={addRow} 
        deleteRow={deleteRow} 
        se_LE_TY={se_LE_TY}
        se_LE_CO={se_LE_CO}
        se_DO_CO={se_DO_CO}
        se_DO_TY={se_DO_TY}
        se_DO_NO={se_DO_NO}
        setbi_ST_DT={setbi_ST_DT}
        bi_ST_DT={bi_ST_DT}
        se_BS_CR={se_BS_CR}
        se_TR_CR={se_TR_CR}
        setse_BS_CR={setse_BS_CR}
        setse_TR_CR={setse_TR_CR}
        handleConfirm={handleConfirm}
  
      />
    </div>
  );
};

export default SecurityDepositeMain;
