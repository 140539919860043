import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function LabTestMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  lm_TS_TY,
  setlm_TS_TY,
  lm_RT_ID,
  setlm_RT_ID,
  lm_CO_NO,
}) {
  const {
    lmUkNo,
    lm_TS_CD,
    lm_TS_NM,
    lm_LB_DS,
    lm_CH_RG,

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [lm_TS_TYOptions, setlm_TS_TYOptions] = useState([]);
  const [lm_RT_IDOptions, setlm_RT_IDOptions] = useState([]);


  const TestTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_TS_TY(value);
  };
  
  const ReportDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlm_RT_ID(value);
  };


  useEffect(() => {
    TestType();
    Report();

  }, []);

  const TestType = async () => {
    const Options = await fetchClassCodeDS("LB/TY");
    setlm_TS_TYOptions(Options);
  };

  const Report = async () => {
    const Options = await fetchClassCodeDS("HO/RP");
    setlm_RT_IDOptions(Options);
  };



const isUpdateMode = Boolean(lmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={lmUkNo ? "Revise Lab Test Master" : "Add Lab Test Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_TS_CD" className="font-bold">
                    Test Code
                    </label>
                    <InputText 
                      id="lm_TS_CD"
                      name="lm_TS_CD"
                      value={lm_TS_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_TS_TY" className="font-bold">
                    Test Type
                    </label>
                    <Dropdown
                    name="lm_TS_TY"
                    value={lm_TS_TY}
                    options={lm_TS_TYOptions}
                    onChange={(e) => TestTypeDropdown("lm_TS_TY", e.value)}
                    placeholder="Select Test Type"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="lm_CO_NO"
                      name="lm_CO_NO"
                      value={lm_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_TS_NM" className="font-bold">
                      Test Name
                    </label>
                    <InputText
                      id="lm_TS_NM"
                      name="lm_TS_NM"
                      value={lm_TS_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_LB_DS" className="font-bold">
                   Description
                    </label>
                    <InputText
                      id="lm_LB_DS"
                      name="lm_LB_DS"
                      value={lm_LB_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_CH_RG" className="font-bold">
                   Charges
                    </label>
                    <InputText
                      id="lm_CH_RG"
                      name="lm_CH_RG"
                      value={lm_CH_RG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="lm_RT_ID" className="font-bold">
                    Report
                    </label>
                    <Dropdown
                    name="lm_RT_ID"
                    value={lm_RT_ID}
                    options={lm_RT_IDOptions}
                    onChange={(e) => ReportDropdown("lm_RT_ID", e.value)}
                    placeholder="Select Report"
                  />
                  </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
