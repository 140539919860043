import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import { Toast } from "primereact/toast";
import ButtonStyles from "views/common/ButtonStyles";
export default function TestMasterDialog({
  // useraccess,
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  handleFormSubmit,
  tm_CO_CT,
  settm_CO_CT,
  tm_TS_ST,
  settm_TS_ST,
  tm_TS_TY,
  settm_TS_TY,
  tm_EV_TS,
  settm_EV_TS,
  tm_TS_MT,
  settm_TS_MT,
  tm_SM_UM,
  settm_SM_UM,
  tm_TS_UM,
  settm_TS_UM,
  tm_CL_01,
  settm_CL_01,
  tm_CL_02,
  settm_CL_02,
  tm_CL_03,
  settm_CL_03,
  tm_CL_04,
  settm_CL_04,
  tm_CL_05,
  settm_CL_05,
}) {


  const {
    tm_TS_ID,
    tm_TS_DS,
    tm_TS_DR,
    tm_SM_NO,
    tm_SM_PR,
    tm_SM_SZ,
    tm_AL_MI,
    tm_PR_MI,
    tm_TR_VL,
    tm_PR_MX,
    tm_AL_MX,
    onchangeCheckbox,
    tmUkNo,
    numeric,
  } = data;

  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, savestyle, TabBoxStyle, cancelstyle, Dialogstyle } = ButtonStyles();

  const [activeTab, setActiveTab] = useState(0);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");



  // Dropdown
  const { fetchClassCodeDS, } = ClassCode();
  const { fetchCostCenter, } = Costcenter();
  const [tm_CO_CTOptions, settm_CO_CTOptions] = useState([]);
  const [tm_TS_STOptions, settm_TS_STOptions] = useState([]);
  const [tm_TS_TYOptions, settm_TS_TYOptions] = useState([]);
  const [tm_EV_TSOptions, settm_EV_TSOptions] = useState([]);
  const [tm_TS_MTOptions, settm_TS_MTOptions] = useState([]);
  const [tm_SM_UMOptions, settm_SM_UMOptions] = useState([]);
  const [tm_TS_UMOptions, settm_TS_UMOptions] = useState([]);
  const [tm_CL_01Options, settm_CL_01Options] = useState([]);
  const [tm_CL_02Options, settm_CL_02Options] = useState([]);
  const [tm_CL_03Options, settm_CL_03Options] = useState([]);
  const [tm_CL_04Options, settm_CL_04Options] = useState([]);
  const [tm_CL_05Options, settm_CL_05Options] = useState([]);



  // Cost Centre
  const onchangeDropDownFormDataCostCenter = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CO_CT(value);
  };
  // Status
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_TS_ST(value);
  };

  // Test Type
  const onchangeDropDownFormDataTestType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_TS_TY(value);
  };
  //Evaluate Test
  const onchangeDropDownFormDataEvaluateTest = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_EV_TS(value);
  };

  //Test Method 
  const onchangeDropDownFormDataTestMethod = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_TS_MT(value);
  };
  //Sample UM
  const onchangeDropDownFormDataSampleUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_SM_UM(value);
  };
  //Result UM
  const onchangeDropDownFormDataResultUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_TS_UM(value);
  };
  //Class Code  1
  const onchangeDropDownFormDataClassCode1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CL_01(value);
  };
  //Class Code 2
  const onchangeDropDownFormDataClassCode2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CL_02(value);
  };
  //Class Code 3
  const onchangeDropDownFormDataClassCode3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CL_03(value);
  };
  //Class Code 4
  const onchangeDropDownFormDataClassCode4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CL_04(value);
  };
  //Class Code 5
  const onchangeDropDownFormDataClassCode5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settm_CL_05(value);
  };
  useEffect(() => {
    CostCentre();
    Status();
    TestType();
    EvaluateTest();
    TestMethod();
    SampleUM();
    ResultUM();
    ClassCode1();
    ClassCode2();
    ClassCode3();
    ClassCode4();
    ClassCode5();
  }, []);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    settm_CO_CTOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("TE/ST");
    settm_TS_STOptions(Options);
  };
  const TestType = async () => {
    const Options = await fetchClassCodeDS("TE/TY");
    settm_TS_TYOptions(Options);
  };
  const EvaluateTest = async () => {
    const Options = await fetchClassCodeDS("TE/EV");
    settm_EV_TSOptions(Options);
  };
  const TestMethod = async () => {
    const Options = await fetchClassCodeDS("TS/ME");
    settm_TS_MTOptions(Options);
  };
  const SampleUM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    settm_SM_UMOptions(Options);
  };
  const ResultUM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    settm_TS_UMOptions(Options);
  };
  const ClassCode1 = async () => {
    const Options = await fetchClassCodeDS("QC/01");
    settm_CL_01Options(Options);
  };
  const ClassCode2 = async () => {
    const Options = await fetchClassCodeDS("QC/02");
    settm_CL_02Options(Options);
  };
  const ClassCode3 = async () => {
    const Options = await fetchClassCodeDS("QC/03");
    settm_CL_03Options(Options);
  };
  const ClassCode4 = async () => {
    const Options = await fetchClassCodeDS("QC/04");
    settm_CL_04Options(Options);
  };
  const ClassCode5 = async () => {
    const Options = await fetchClassCodeDS("QC/05");
    settm_CL_05Options(Options);
  };

  const isUpdateMode = Boolean(tmUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
    </React.Fragment>
  );




  // tab enable and disable
  const [showWPSTab, setShowWPSTab] = useState("");
  useEffect(() => {
    fetchTab();
    fetchfields();
  }, []);
  const fetchTab = async () => {
    try {
      const request = {
        name: "DISPLAY_WPS_TAB",
        value: "FALSE",
      }
      const response = await axios.post(`${domain}/api/ErpParameters/GetByRole`, request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      const responseValue = response.data.value;
      setShowWPSTab(responseValue === "true");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // fields disable enable
  const [showfoodallow, setshowfoodallow] = useState("");
  // const [showfuelallow, setshowfuelallow] = useState("");
  const fetchfields = async () => {
    try {
      const request = {
        name: "DISPLAY_FOOD_ALLOW_FIELD",
        value: "FALSE",
      }
      const response = await axios.post(`${domain}/api/ErpParameters/GetByRole`, request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      const responseValue = response.data.value;
      setshowfoodallow(responseValue === "true");
      // setshowfuelallow(responseValue === "true");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{tmUkNo ? "Revise  Test Maste" : "Add  Test Maste"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        {activeTab === 0 && (
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
        )}
        {activeTab === 0 && (
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div style={TabBoxStyle}>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_ID" className="font-bold">
                    Test ID
                  </label>
                  <InputText
                    id="tm_TS_ID"
                    name="tm_TS_ID"
                    value={tm_TS_ID}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                    id="tm_TS_DS"
                    name="tm_TS_DS"
                    value={tm_TS_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="tm_CO_CT"
                    value={tm_CO_CT}
                    options={tm_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCenter("tm_CO_CT", e.value)}
                    placeholder=" Cost Center"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_FR_DT" className="font-bold">
                    Effective From
                  </label>
                  <Calendar
                    id="tm_FR_DT"
                    name="tm_FR_DT"
                    value={moment(data.tm_FR_DT, "YYYYMMDD").isValid() ? moment(data.tm_FR_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "tm_FR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TO_DT" className="font-bold">
                    Effective To
                  </label>
                  <Calendar
                    id="tm_TO_DT"
                    name="tm_TO_DT"
                    value={moment(data.tm_TO_DT, "YYYYMMDD").isValid() ? moment(data.tm_TO_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "tm_TO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_ST" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    id="tm_TS_ST"
                    name="tm_TS_ST"
                    value={tm_TS_ST}
                    options={tm_TS_STOptions}
                    onChange={(e) => onchangeDropDownFormDataStatus("tm_TS_ST", e.value)}
                    placeholder=" Status"
                  />
                </div>

              </div>
            </div>
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-12">
                  <h3 className="font-bold" style={{ color: "black", fontSize: "large", }}> Definition  </h3>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_TY" className="font-bold">
                    Test Type
                  </label>
                  <Dropdown
                    name="tm_TS_TY"
                    value={tm_TS_TY}
                    options={tm_TS_TYOptions}
                    onChange={(e) => onchangeDropDownFormDataTestType("tm_TS_TY", e.value)}
                    placeholder=" Test Type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_EV_TS" className="font-bold">
                    Evaluate Test
                  </label>
                  <Dropdown
                    name="tm_EV_TS"
                    value={tm_EV_TS}
                    options={tm_EV_TSOptions}
                    onChange={(e) => onchangeDropDownFormDataEvaluateTest("tm_EV_TS", e.value)}
                    placeholder="   Evaluate Test"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_MT" className="font-bold">
                    Test Method
                  </label>
                  <Dropdown
                    name="tm_TS_MT"
                    value={tm_TS_MT}
                    options={tm_TS_MTOptions}
                    onChange={(e) => onchangeDropDownFormDataTestMethod("tm_TS_MT", e.value)}
                    placeholder="  Test Method"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_DR" className="font-bold">
                    Test Duration
                  </label>
                  <InputText
                    id="tm_TS_DR"
                    name="tm_TS_DR"
                    value={tm_TS_DR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
            <div style={inboxstyle} >

              <div className="formgrid grid">
                <div className="field col-12">
                  <h3 className="font-bold" style={{ color: "black", fontSize: "large", textDecoration: "underline" }} >Sample Information </h3>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_SM_NO" className="font-bold">
                    No Of Samples
                  </label>
                  <InputText
                    id="tm_SM_NO"
                    name="tm_SM_NO"
                    value={tm_SM_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_SM_PR" className="font-bold">
                    Sample Percentage
                  </label>
                  <InputText
                    id="tm_SM_PR"
                    name="tm_SM_PR"
                    value={tm_SM_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_SM_SZ" className="font-bold">
                    Sample Size
                  </label>
                  <InputText
                    id="tm_SM_SZ"
                    name="tm_SM_SZ"
                    value={tm_SM_SZ}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_SM_UM" className="font-bold">
                    Sample UM
                  </label>
                  <Dropdown
                    name="tm_SM_UM"
                    value={tm_SM_UM}
                    options={tm_SM_UMOptions}
                    onChange={(e) => onchangeDropDownFormDataSampleUM("tm_SM_UM", e.value)}
                    placeholder=" Sample UM"
                  />
                </div>
              </div>
            </div>
            <div style={inboxstyle} >

              <div className="formgrid grid">
                <div className="field col-12">
                  <h3 className="font-bold" style={{
                    color: "black", fontSize: "large", textDecoration: "underline"
                  }} > Alpha/Numeric</h3>
                </div>

                {/* Checkbox for Numeric */}

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <Checkbox
                    inputId="numeric"
                    name="numeric"
                    checked={numeric}
                    onChange={(e) => onchangeCheckbox(e.target.checked)}
                    style={{ marginRight: "20px" }} // Add space between the checkbox and label
                  />
                  <label htmlFor="numeric" className="font-bold">
                    Numeric
                  </label>
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_CD" className="font-bold">
                    UDC
                  </label>
                  <InputText
                    id="tm_CL_CD"
                    name="tm_CL_CD"
                    value={'TE/PR'}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_AL_MI" className="font-bold">
                    Allowed Minimum
                  </label>
                  <InputText
                    id="tm_AL_MI"
                    name="tm_AL_MI"
                    value={tm_AL_MI}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_PR_MI" className="font-bold">
                    Preferred Minimum
                  </label>
                  <InputText
                    id="tm_PR_MI"
                    name="tm_PR_MI"
                    value={tm_PR_MI}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TR_VL" className="font-bold">
                    Target
                  </label>
                  <InputText
                    id="tm_TR_VL"
                    name="tm_TR_VL"
                    value={tm_TR_VL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_PR_MX" className="font-bold">
                    Preferred Maximum
                  </label>
                  <InputText
                    id="tm_PR_MX"
                    name="tm_PR_MX"
                    value={tm_PR_MX}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_AL_MX" className="font-bold">
                    Allowed Maximum
                  </label>
                  <InputText
                    id="tm_AL_MX"
                    name="tm_AL_MX"
                    value={tm_AL_MX}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_TS_UM" className="font-bold">
                    Result UM
                  </label>
                  <Dropdown
                    name="tm_TS_UM"
                    value={tm_TS_UM}
                    options={tm_TS_UMOptions}
                    onChange={(e) => onchangeDropDownFormDataResultUM("tm_TS_UM", e.value)}
                    placeholder=" Result UM"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab2 */}

          {/* {showWPSTab && ( */}
          <TabPanel header="Category">
            <div style={inboxstyle} >
              <div className="formgrid grid">

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_01" className="font-bold">
                    Code 1
                  </label>
                  <Dropdown
                    name="tm_CL_01"
                    value={tm_CL_01}
                    options={tm_CL_01Options}
                    onChange={(e) => onchangeDropDownFormDataClassCode1("tm_CL_01", e.value)}
                    placeholder=" Code 1"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_02" className="font-bold">
                    Code 2
                  </label>
                  <Dropdown
                    name="tm_CL_02"
                    value={tm_CL_02}
                    options={tm_CL_02Options}
                    onChange={(e) => onchangeDropDownFormDataClassCode2("tm_CL_02", e.value)}
                    placeholder=" Code 2"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_03" className="font-bold">
                    Code 3
                  </label>
                  <Dropdown
                    name="tm_CL_03"
                    value={tm_CL_03}
                    options={tm_CL_03Options}
                    onChange={(e) => onchangeDropDownFormDataClassCode3("tm_CL_03", e.value)}
                    placeholder=" Code 3"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_04" className="font-bold">
                    Code 4
                  </label>
                  <Dropdown
                    name="tm_CL_04"
                    value={tm_CL_04}
                    options={tm_CL_04Options}
                    onChange={(e) => onchangeDropDownFormDataClassCode4("tm_CL_04", e.value)}
                    placeholder=" Code 4"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tm_CL_05" className="font-bold">
                    Code 5
                  </label>
                  <Dropdown
                    name="tm_CL_05"
                    value={tm_CL_05}
                    options={tm_CL_05Options}
                    onChange={(e) => onchangeDropDownFormDataClassCode5("tm_CL_05", e.value)}
                    placeholder=" Code 5"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* )} */}
        </TabView>
      </div>

    </Dialog>
  );
}
