import React, { useState, useEffect,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import CashPopup from "./CashPopup";
import CardPopup from "./CardPopup";
import CouponPopup from "./CouponPopup";
import CreditPopup from "./CreditPopup";
import "./hospitalitystyles.css";
export default function HospitalityDirectSalesDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  setFormData,
  onchangeFormData,
  // form main page
  sh_CN_ID,
  setsh_CN_ID,
  sd_TR_UM,
  setsd_TR_UM,
  sp_TR_CR,
  setsp_TR_CR,
  SelectedCustomer,
  setSelectedCustomer,
  ItemTableData,
  setItemTableData,
  PaymentTableData,
  setPaymentTableData,
  onchangeDropDownFormData3,
  sh_CU_NO,
  setsh_CU_NO,
  sh_DS_PR,
  sh_DS_AT,
  is_CO_CT,
  sh_BS_CR,
  sh_EX_RT,
  sd_LO_CN,
  tax,
  price,
}) {
  const {
    shUkNo, sd_UK_NO,is_LT_NO,sp_EX_RT,sp_PY_AM,sp_PY_MT,sd_IT_NM,im_IT_DS,sd_UT_PR,sd_EX_PR,sd_OR_QT,sd_DS_PR,sd_DS_AT,net_AMOUNT,sd_TX_PE,sd_TX_AM,
    is_LO_CN,sh_OR_DT,
    PRICE_AMOUNT,DISCOUNT_AMOUNT,
    NET_AMOUNT,TAX_AMOUNT,TOTAL,im_IT_NM,PAID_BALANCE,PAID_TOTAL,
  } = data;
  const {borderbox,inboxstylesales,offerbtnstyles,pricestyles,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,inputboxstyles} = ButtonStyles();
 
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);
// for cashpopup
const [selecttotal, setselecttotal] = useState("");
const [selectPaidtotal, setselectPaidtotal] = useState("");

  const addRow = () => {
    setItemTableData([...ItemTableData, {}]);
  };
  const deleteRow = () => {
    if (ItemTableData.length > 0) {
      const updatedData = [...ItemTableData];
      updatedData.pop();
      setItemTableData(updatedData);
    }
  };
  // 1st row related
  const {fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);
  const [sp_TR_CROptions, setsp_TR_CROptions] = useState([]);
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_TR_UM(value);
  };
  useEffect(() => {
    fetchDropdownUM();
    fetchDropdownCR();
  }, []);
  const fetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsd_TR_UMOptions(Options);
  };
  const fetchDropdownCR = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsp_TR_CROptions(Options);
  };

useEffect(() => {
  GetPrdtGrp();
}, []);
const handleResponse = (data) => {
  const { warnings = [], errorMessage = [], infos = [] } = data;

  if (warnings.length > 0) {
    const warningMessage = warnings.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: warningMessage,
    });
  }

  if (infos.length > 0) {
    const infoMessage = infos.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: infoMessage,
    });
  }

  if (errorMessage.length > 0) {
    const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: errorMessages,
    });
  }
};
// from buttons
const [Items, setItems] = useState([]);
const [pdgrps, setpdgrp] = useState([]);
const GetPrdtGrp = async () =>{
  const UrlPrdp = `${domain}/api/SalesHierarchy/GetSalesHeirarchy`;
  const requestData = { }
    try {
      const response = await axios.post(UrlPrdp, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataPDgrp = response.data.Result;
      if (dataPDgrp && Array.isArray(dataPDgrp)) {
        setpdgrp(dataPDgrp.map(item => item.sh_PD_GP)); 
      } else {
        console.error("Invalid API response: No result data", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
};
const GetItems = async (pdgrps) =>{
  const UrlITEM = `${domain}/api/ItemMaster/GetAllWithProductGrp`;
  const requestData = {
    "im_PD_GP": pdgrps 
  };
    try {
      const response = await axios.post(UrlITEM, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItem = response.data.Result2;
      if (dataItem && Array.isArray(dataItem)) {
        setItems(dataItem); 
      } else {
        console.error("Invalid API response: No result data", response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
};
const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function(...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};
const onChangeItem = throttle(async (ItemName, index) => {
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData = { "im_IT_NM": ItemName };

  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": company,
        "x-flag": localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const dataItMt = response.data.Result;
    if (dataItMt) {
      setItemTableData(prevData => {
        const updatedData = [...prevData];
        if (updatedData.length > index) {
          updatedData[index] = {
            ...updatedData[index],
            sd_IT_NM: dataItMt.im_IT_NM,
            im_IT_DS: dataItMt.im_IT_DS,
            sd_TR_UM: dataItMt.im_PR_UM,
            sd_TX_PE: dataItMt.im_TO_TX,
            sd_OR_QT: 1,
            sd_DS_PR: 0,
          };
        } else {
          updatedData.push({
            sd_IT_NM: dataItMt.im_IT_NM,
            im_IT_DS: dataItMt.im_IT_DS,
            sd_TR_UM: dataItMt.im_PR_UM,
            sd_TX_PE: dataItMt.im_TO_TX,
            sd_OR_QT: 1,
            sd_DS_PR: 0,
          });
        }
        return updatedData;
      });
      // setNextIndex(index);
      await onchangebspr(dataItMt.im_IT_NM,index);
    } else {
      console.error("Invalid API response: No result data", response.dataItMt);
    }
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
}, 2000);

// Function to handle base price calculation and update
const onchangebspr = async(SelectedRowIndex) => {
  // price 
  if(price == 1){
    const table = document.getElementById("Itemtable");
  const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
    const priceInput1 = {
      "bp_CO_CT" : document.getElementById("is_CO_CT").value,
      "bp_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "bp_FR_DT" : sh_OR_DT,
      "bp_TO_DT" : sh_OR_DT,
      "bp_CU_NO" : sh_CU_NO,
      "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[4].children[0].children[1].children[0].value,        
      "bp_CR_CD" : document.getElementById ("sh_BS_CR").value,
    }
    try {
      const response =await axios.post(UrlPrice, priceInput1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });      
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data;
      if (dataItMt ) {
        handleResponse (response.data);
        if(dataItMt.price){
          document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.bp_UN_PR;
          // TNCLUSIVE TAX
          if(tax == "I"){
            const UrlPrice = `${domain}/api/ItemSellingPrice/GetTaxInclusivePrice`;
            const SellingTaxInput = {
              "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
              "is_SL_PR" : dataItMt.price.bp_UN_PR      
            }
            console.log("SellingTaxInput " + SellingTaxInput)
            try {
              const response =await axios.post(UrlPrice, SellingTaxInput, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              });      
              if (response.status !== 200) {
                throw new Error(`API request failed with status: ${response.status}`);
              }
              const dataItMt = response.data;
              if (dataItMt ) {
                handleResponse (response.data);
                if(dataItMt.price){
                  document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
                  onChangeOfPrice(SelectedRowIndex);
                }
              } else {
                console.error("Invalid API response:", data);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }else{
            onChangeOfPrice(SelectedRowIndex);
          }
        }
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }else{
    const UrlPrice = `${domain}/api/ItemSellingPrice/GetSellingPrice`;
    const SellingpriceInput = {
      "is_CO_CT" : document.getElementById("is_CO_CT").value,
      "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "is_LO_CN" : sd_LO_CN,
      "is_LT_NO" : document.getElementById (`sd_LO_NO_${SelectedRowIndex}`).value,
    }
    console.log("SellingpriceInput " + SellingpriceInput)
    try {
      const response =await axios.post(UrlPrice, SellingpriceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });      
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data;
      if (dataItMt ) {
        handleResponse (response.data);
        if(dataItMt.price){
          document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
      // inclusive tax
          if(tax == "I"){
            const UrlPrice = `${domain}/api/ItemSellingPrice/GetTaxInclusivePrice`;
            const SellingTaxInput = {
              "is_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
              "is_SL_PR" : dataItMt.price.is_SL_PR      
            }
            try {
              const response =await axios.post(UrlPrice, SellingTaxInput, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              });      
              if (response.status !== 200) {
                throw new Error(`API request failed with status: ${response.status}`);
              }
              const dataItMt = response.data;
              if (dataItMt ) {
                handleResponse (response.data);
                if(dataItMt.price){
                  document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.price.is_SL_PR;
                  onChangeOfPrice(SelectedRowIndex);
                }
  
              } else {
                console.error("Invalid API response:", data);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }else{
            onChangeOfPrice(SelectedRowIndex);
          }
        }

        
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
	
};
// Function to handle price calculation
const onChangeOfPrice = async (index) => {
  const UrlCalulate = `${domain}/api/SalesQuote/GetCalculatedPrice`;
  const priceInput = {
    "exchange_RATE": sp_EX_RT,
    "fr_UNIT_PRICE": document.getElementById(`sd_UT_PR_${index}`).value,
    "tax_PERCENTAGE": document.getElementById(`sd_TX_PE_${index}`).value,
    "cost_CENTER": document.getElementById("is_CO_CT").value,
    "item_NAME": document.getElementById(`sd_IT_NM_${index}`).value,
    "row_NUM": index,
    "discount_PERCENTAGE": document.getElementById(`sd_DS_PR_${index}`).value,
    "unit_PRICE": document.getElementById(`sd_UT_PR_${index}`).value,
    "order_QUANTITY": document.getElementById(`sd_OR_QT_${index}`).value,
  };

  try {
    const response = await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": company,
        "x-flag": localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const dataCalulate = response.data.out1;
    if (dataCalulate) {
      document.getElementById(`sd_DS_AT_${index}`).value = dataCalulate.discount_AMOUNT;
      document.getElementById(`sd_EX_PR_${index}`).value = dataCalulate.price_AMOUNT;
      document.getElementById(`sd_TX_AM_${index}`).value = dataCalulate.tax_AMOUNT;
      document.getElementById(`net_AMOUNT_${index}`).value = dataCalulate.net_AMOUNT;

      // Update totals
      const salesHeader = {
        sh_TR_CR: sp_TR_CR,
        sh_EX_RT: document.getElementById("sh_EX_RT").value,
        sh_DS_AT: document.getElementById("sh_DS_AT").value,
        sh_BS_CR: document.getElementById("sh_BS_CR").value,
      };

      const table = document.getElementById("Itemtable");
      const totalRowCount = table.tBodies[0].rows.length;
      const salesDetail = [];

      for (let i = 0; i < totalRowCount; i++) {
        const Details = {
          sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
          sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
          sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
          sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        };
        salesDetail.push(Details);
      }

      const requestBodyNet = { salesHeader, salesDetail };
      try {
        const respNet = await axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmountRetail`, requestBodyNet, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": company,
            "x-flag": localStorage.getItem("userType"),
          },
        });

        document.getElementById("TAX_AMOUNT").value = respNet.data.header.tax_AMOUNT;
        document.getElementById("PRICE_AMOUNT").value = respNet.data.header.price_AMOUNT;
        document.getElementById("DISCOUNT_AMOUNT").value = respNet.data.header.discount_AMOUNT;
        document.getElementById("TOTAL").value = respNet.data.header.total;
        setselecttotal(respNet.data.header.total);
       } catch (error) {
        console.error("Error updating data:", error);
      }
    } else {
      console.error("Invalid API response:", dataCalulate);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
// Function to handle unit price change
const onchangeFormUnitPrice = async (e, index) => {
  onchangedata(e);
  await onChangeOfPrice(index);
};
// payment popups
const [CashPopupVisible, setCashPopupVisible] = useState(false);
const [CardPopupVisible, setCardPopupVisible] = useState(false);
const [CouponPopupVisible, setCouponPopupVisible] = useState(false);
const [CreditPopupVisible, setCreditPopupVisible] = useState(false);
const openCashPopup = () => {
  setCashPopupVisible(true);
};
const openCardPopup = () => {
  setCardPopupVisible(true);
};
const openCouponPopup = () => {
  setCouponPopupVisible(true);
};
const openCreditPopup = () => {
  setCreditPopupVisible(true);
};
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleORDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(shUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
// payment table
const [Cashsp_TR_CR, setCashsp_TR_CR ] = useState(sh_BS_CR);
function handlebal (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CashPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CashExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// Card
const [Cardsp_TR_CR, setCardsp_TR_CR ] = useState(sh_BS_CR);
function handlebalCard (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;
      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CardPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CardExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// credit
const [Creditsp_TR_CR, setCreditsp_TR_CR ] = useState(sh_BS_CR);
function handlebalCredit (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CreditPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CreditExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "125rem", marginLeft:"10rem",height:"100rem"}}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={shUkNo ? "Revise Hospitality Sales" : "Add Hospitality Sales"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
    <div style={{display:"none"}} >
    <div className="field col ">
      {/* cost centre */}
      <InputText    id="is_CO_CT"  name="is_CO_CT"  value={is_CO_CT}    />
      {/* base currnecy */}
      <InputText  id="sh_BS_CR"  name="sh_BS_CR"    value={sh_BS_CR} />
      {/* customer */}
      <InputText  id="sh_CU_NO"  name="sh_CU_NO" value={sh_CU_NO}    />
      {/* Exchange Rate */}
      <InputText    id="sh_EX_RT"  name="sh_EX_RT" value={sh_EX_RT}  />
      {/* order date */}
      <Calendar  id="sh_OR_DT" name="sh_OR_DT" value={moment(sh_OR_DT, "YYYYMMDD").toDate()}   onChange={handleORDateChange}    dateFormat="dd/mm/yy"   />
      <label htmlFor="sh_DS_PR" className="font-bold">
        Dis. % /Amount
      </label>
      <div style={{display:"flex"}}>
      <InputText  id="sh_DS_PR"    name="sh_DS_PR"  value={sh_DS_PR} />
      <InputText  id="sh_DS_AT"  name="sh_DS_AT"  value={sh_DS_AT} />
      </div>
    </div>
    </div>
      <div style={borderbox} >
        <div style={inboxstylesales} >
          <div style={{ display: "flex", gap: "1rem", position: "relative"}} className="container-fluid">
        {/* 1st column */}
          <div>
            <div style={{ flex: "5", position: "relative" }}>
            <div className="table-responsive" style={{height:"28vh",width:"50rem"}}>
              <div className="table-buttons" style={tablebutton} >
                {(
                <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                )}
                {(
                <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <table className="table table-bordered" id="Itemtable" style={{display:"block",height:'13rem',overflow:"scroll"}} >
                <thead>
                  <tr>
                    <th>Line No</th>
                    <th style={{display:"none"}}>Item Number</th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th style={{ display: "none" }}>UM</th>
                    <th>Unit Price</th>
                    <th style={{ display: "none" }}>Amount</th>
                    <th style={{ display: "none" }}>Discount %</th>
                    <th style={{ display: "none" }}>Discount</th>
                    <th style={{ display: "none" }}>Net Amount</th>
                    <th style={{ display: "none" }}>Tax %</th>
                    <th style={{ display: "none" }}>Tax</th>
                  </tr>
                </thead>
                <tbody>
                {ItemTableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <InputText disabled
                        style={{ width: "60px" }}
                        id={`sd_LN_NO_${index}`}
                        name={`sd_LN_NO_${index}`}
                        value={index + 1}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                    <InputText
                      id={`sd_IT_NM_${index}`}
                      name={`sd_IT_NM_${index}`}
                      onChange={(e) => onchangedata(e)}
                      value={row.sd_IT_NM ? row.sd_IT_NM: row[`${"sd_IT_NM"}_${index}`]}
                    />
                    </td>
                    <td>
                      <InputText disabled
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        defaultValue={row.im_IT_DS ? row.im_IT_DS: row[`${"im_IT_DS"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText
                        id={`sd_OR_QT_${index}`}
                        name={`sd_OR_QT_${index}`}
                        defaultValue={row.sd_OR_QT}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <Dropdown disabled
                        id={`sd_TR_UM_${index}`}
                        name={`sd_TR_UM_${index}`}
                        value={ data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`]: row.sd_TR_UM}
                        options={sd_TR_UMOptions}
                        onChange={(e) =>onchangeDropDownFormData2(`sd_TR_UM_${index}`, e.value)}
                        placeholder="Select UM"
                      />
                    </td>
                    <td>
                      <InputText disabled
                        id={`sd_UT_PR_${index}`}
                        name={`sd_UT_PR_${index}`}
                        value={row.sd_UT_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`sd_EX_PR_${index}`}
                        name={`sd_EX_PR_${index}`}
                        value={row.sd_EX_PR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`sd_DS_PR_${index}`}
                        name={`sd_DS_PR_${index}`}
                        defaultValue={row.sd_DS_PR}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`sd_DS_AT_${index}`}
                        name={`sd_DS_AT_${index}`}
                        value={row.sd_DS_AT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        value={row.net_AMOUNT}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`sd_TX_PE_${index}`}
                        name={`sd_TX_PE_${index}`}
                        value={row.sd_TX_PE}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`sd_TX_AM_${index}`}
                        name={`sd_TX_AM_${index}`}
                        value={row.sd_TX_AM}
                      />
                       <InputText disabled
                        style={{ width: "70px" }}
                        id={`sd_LO_CN_${index}`}
                        name={`sd_LO_CN_${index}`}
                        value={sd_LO_CN}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
              {/* Price Details */}
              <div className="price-details">
                <div className="price">
                  <strong style={{width:'10rem'}}>Price Amount</strong> 
                  <InputText disabled style={inputboxstyles}
                    id="PRICE_AMOUNT" name="PRICE_AMOUNT" value={PRICE_AMOUNT}   onChange={(e) => onchangedata(e)} />
                </div>
                <div className="price">
                  <strong style={{width:'10rem'}} >Discount</strong> 
                  <InputText disabled style={inputboxstyles}
                    id="DISCOUNT_AMOUNT"  name="DISCOUNT_AMOUNT" value={DISCOUNT_AMOUNT}  onChange={(e) => onchangedata(e)}  />
                </div>
                <div className="price">
                  <strong style={{width:'10rem'}} >Tax</strong>
                  <InputText disabled style={inputboxstyles}
                    id="TAX_AMOUNT"   name="TAX_AMOUNT"  value={TAX_AMOUNT} onChange={(e) => onchangedata(e)} />
                </div>
                <div className="price">
                  <strong style={{width:'10rem'}}>Total</strong>
                  <InputText disabled style={inputboxstyles}
                    id="TOTAL"   name="TOTAL"   value={TOTAL}  onChange={(e) => onchangedata(e)} />
                </div>
              </div>
            </div>   
            <div className="PaymentTable">
              <div style={{maxHeight:"200px",overflow:"auto" }}>
              <table className="table table-bordered flex-grow-1" id="PaymentTable">
              <thead>
                <tr>
                  <th>Payment Method</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Exchange Rate</th>
                </tr>
              </thead>
              <tbody>
              {PaymentTableData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <InputText disabled
                      style={{ width: "70px" }}
                      id={`sp_PY_MT_${index}`}
                      name={`sp_PY_MT_${index}`}
                      defaultValue={row.sp_PY_MT}
                    />
                    </td>
                  <td>
                    <InputText disabled
                      style={{ width: "70px" }}
                      id={`sp_PY_AM_${index}`}
                      name={`sp_PY_AM_${index}`}
                      defaultValue={row.sp_PY_AM}
                    />
                  </td>
                  <td>
                    <Dropdown disabled
                        id={`sp_TR_CR_${index}`}
                        name={`sp_TR_CR_${index}`}
                        value={data[`sp_TR_CR_${index}`] ? data[`sp_TR_CR_${index}`] : row.sp_TR_CR} 
                        options={sp_TR_CROptions}
                        onChange={(e) =>onchangeDropDownFormData3(`sp_TR_CR_${index}`, e.value)}
                        placeholder="Select Currency"
                      />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "70px" }}
                      id={`sp_EX_RT_${index}`}
                      name={`sp_EX_RT_${index}`}
                      defaultValue={row.sp_EX_RT}
                    />
                  </td>
                </tr>
              ))}
              </tbody>
              </table>
              </div>
              <div className="table table-bordered mt-3 d-flex justify-content-between align-items-center p-1 w-75">
              <label htmlFor="PAID_TOTAL" className="font-bold">
                  Total
                </label>
                <InputText disabled
                  style={{width:"10rem"}}
                  id="PAID_TOTAL"
                  name="PAID_TOTAL"
                  defaultValue={PAID_TOTAL}
                  onChange={(e) => onchangedata(e)}
                />
                <label htmlFor="PAID_BALANCE" className="font-bold">
                  Balance
                </label>
                <InputText disabled
                  style={{width:"10rem"}}
                  id="PAID_BALANCE"
                  name="PAID_BALANCE"
                  defaultValue={PAID_BALANCE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>  
            </div> 
          </div>
{/* 2nd column */}
            <div style={{ flex: "2", display: "flex", flexDirection: "column", gap: "1rem" }}>
            {/* 5 Buttons in a Row */}
              <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-times" label="Cancel Item" className="p-button-danger" style={offerbtnstyles}/>
                  <Button icon="pi pi-plus" label="Add SP" className="p-button-success" style={offerbtnstyles}/>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-percent" label="Discount" className="p-button-warning" style={offerbtnstyles}/>
                  <Button icon="pi pi-user" label="Customer" className="p-button-info" style={offerbtnstyles}/>
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-split" label="Split" className="p-button-secondary" style={offerbtnstyles} />
                  <Button icon="pi pi-table" label="Table" className="p-button-secondary" style={offerbtnstyles} />
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-check" label="OK" className="p-button-success" style={offerbtnstyles} />
                  <Button icon="pi pi-times" label="Cancel" className="p-button-danger" style={offerbtnstyles} />
              </div>
        {/* 6 Payment Buttons */}
              <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "1rem" }}>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-wallet" label="Cash" className="p-button-secondary" style={offerbtnstyles} onClick={openCashPopup} />
                  <Button icon="pi pi-credit-card" label="Card" className="p-button-secondary" style={offerbtnstyles} onClick={openCardPopup} />
                </div>
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <Button icon="pi pi-coupon" label="Coupon" className="p-button-secondary" style={offerbtnstyles} onClick={openCouponPopup} />
                  <Button icon="pi pi-credit-card" label="Credit" className="p-button-secondary" style={offerbtnstyles} onClick={openCreditPopup} />
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "11.5rem", flex: "1" }}>
                  <Button icon="pi pi-check" label="Pay" className="p-button-success" style={offerbtnstyles} />
                  <div style={{ flex: "1" }}></div>
                </div>
              </div>
            </div>
{/* 3rd column */}
            <div style={{ flex: "2", display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1rem" }}>
                {/* 3x3 Grid of Fields */}
                {Items.map((item, index) => (
                  <InputText 
                    key={index} 
                    placeholder={item.im_IT_DS} 
                    onClick={() => onChangeItem(item.im_IT_NM,index)}
                  />
                ))}
              </div>
            </div>
{/* 4th column */}
            <div style={{ flex: "1", display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              {Array.isArray(pdgrps) && pdgrps.map((pdgp, index) => (
                <InputText 
                  key={index} 
                  placeholder={pdgp} 
                  onClick={() => GetItems(pdgp)}
                  readOnly
                />
              ))}
              </div>
            </div>
          </div>
        </div>
      </div>
       {/* payment popups */}
       <CashPopup
        visible={CashPopupVisible}
        onClose={() => setCashPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={selectPaidtotal}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebal={handlebal}
        Cashsp_TR_CR={Cashsp_TR_CR}
        setCashsp_TR_CR={setCashsp_TR_CR}
      />
      <CardPopup
        visible={CardPopupVisible}
        onClose={() => setCardPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={selectPaidtotal}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebalCard={handlebalCard}
        Cardsp_TR_CR={Cardsp_TR_CR}
        setCardsp_TR_CR={setCardsp_TR_CR}
      />
       <CouponPopup
        visible={CouponPopupVisible}
        onClose={() => setCouponPopupVisible(false)}
      />
     <CreditPopup
        visible={CreditPopupVisible}
        onClose={() => setCreditPopupVisible(false)}
        formData={data}
        grossAmount={selecttotal}
        paidTotal={selectPaidtotal}
        sh_BS_CR={sh_BS_CR}
        sh_EX_RT={sh_EX_RT}
        sh_OR_DT={sh_OR_DT}
        handlebalCredit={handlebalCredit}
        Creditsp_TR_CR={Creditsp_TR_CR}
        setCreditsp_TR_CR={setCreditsp_TR_CR}
      />
    </Dialog>
  );
}
