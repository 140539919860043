import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import axios from "axios";
import "jspdf-autotable";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ManageCaseDialog from "./ManageCaseDialog";
import '../../../components/ListStyle/ListStyle.css';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  cs_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  cs_CS_TY: "",
  cs_ST_AT: "",
  cs_PR_TY: "",
  cs_CU_NO: "",
  cs_CA_NM: "",
  cs_AD_PH: "",
  cs_CS_CO: "",
  cs_CS_ID: "",
  cs_AS_GN: "",
  cs_QA_SC: "",
  cs_QA_FN: "",
  cs_CB_NO: "",
  cs_ST_TM: "",
  cs_EN_TM: "",
  cs_ST_DT: "",
  cs_EN_DT: "",
};
const initialFilters = {
  cs_TR_DT: { value: "", operation: "" },
  cs_CS_ID: { value: "", operation: "" },
  cs_CS_TY: { value: "", operation: "" },
  cs_CS_CO: { value: "", operation: "" },
  cs_CU_NO: { value: "", operation: "" },
  cs_CB_NO: { value: "", operation: "" },
  cs_AS_GN: { value: "", operation: "" },
  cs_CA_NM: { value: "", operation: "" },
  cs_AD_PH: { value: "", operation: "" },
  cs_AD_EM: { value: "", operation: "" },
  cs_QA_FN: { value: "", operation: "" },
};
const ManageCaseMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,copyButtonStyles,checkboxStyle,tableHeaderStyle,inputStyle,tableCellStyle,inputStyle1 } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);

  const [cus_NAME, setcus_NAME] = useState("");
  const [as_NAME, setas_NAME] = useState("");
  const [eq_NAME, seteq_NAME] = useState("");
  const [item_NAME, setitem_NAME] = useState("");

  const [cs_CU_NO, setcs_CU_NO] = useState("");
  const [cs_AS_GN, setcs_AS_GN] = useState("");
  const [cs_CS_CO, setcs_CS_CO] = useState("");
  const [cs_CS_TY, setcs_CS_TY] = useState("");
  const [cs_QA_SC, setcs_QA_SC] = useState("");
  const [cs_PR_TY, setcs_PR_TY] = useState("");
  const [cs_ST_AT, setcs_ST_AT] = useState("");
  const [cs_PR_GP, setcs_PR_GP] = useState("");
  const [cs_PR_MD, setcs_PR_MD] = useState("");
  const [cs_CS_ID, setcs_CS_ID] = useState("");
  const [cs_ST_TM, setcs_ST_TM] = useState("");
  const [cs_EN_TM, setcs_EN_TM] = useState("");
  const [csUkNo, setcsUkNo] = useState("");
  const [cs_AD_PH, setcs_AD_PH] = useState("");
  const [cs_AD_EM, setcs_AD_EM] = useState("");
  
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [addressTypeassigne, setaddressTypeassigne] = useState("E");
  const [addressTypeCUST, setaddressTypeCUST] = useState("C");
  const [CompanyPopupVisible, setCompanyPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] = useState(false);
  const [Customer, setCustomer] = useState("");
  const [Assignee, setAssignee] = useState("");
  const [FDate, setFDate] = useState("");
  const [EDate, setEDate] = useState("");
  const [Status, setStatus] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [cs_ST_ATOptions, setcs_ST_ATOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const handleDataSelect = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    setCustomer( data.abAdNo);
    setCompanyPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };

  const handleDataSelect1 = (data) => {
    setSelectedAssignee(data.ab_AD_NM);
    setAssignee( data.abAdNo);
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters1 (data.abAdNo);
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters2 (value);
    console.log(value);
  };
  const url11 =`${domain}/api/ClassCode/All`;
  const parameter2 = "EM/ST";
  const finalUrl2 = `${url11}/${parameter2}`;

  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
       setcs_ST_ATOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  };

  const openCompanyPopup = () => {
    setCompanyPopupVisible(true);
  };

  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const updateFilters = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cs_CU_NO: {
        value: sts,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cs_AS_GN: {
        value: sts,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cs_ST_AT: {
        value: sts,
        operation: "LIKE",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setcus_NAME(null);
    setas_NAME(null);
    seteq_NAME(null);
    setitem_NAME(null);
    setcs_QA_SC(null);
    setcs_PR_TY(null);
    // setcs_ST_AT(null);
    setcs_PR_GP(null);
    setcs_PR_MD(null);
    setcs_CS_ID(null);
    setcsUkNo(null);
    setcs_AS_GN(null);
    setcs_CU_NO(null);
    setcs_AD_EM(null);
    setcs_AD_PH(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setcus_NAME(null);
    setas_NAME(null);
    seteq_NAME(null);
    setitem_NAME(null);
    setcs_QA_SC(null);
    setcs_PR_TY(null);
    // setcs_ST_AT(null);
    setcs_PR_GP(null);
    setcs_PR_MD(null);
    setcs_CS_ID(null);
    setcsUkNo(null);
    setcs_AS_GN(null);
    setcs_CU_NO(null);
    setcs_AD_EM(null);
    setcs_AD_PH(null);
    setOpen(false);
  };

  const handleCopyData = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ManageCase/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ManageCaseData = response.data;
        delete ManageCaseData.csUkNo;
        delete ManageCaseData.cs_CS_ID;
        setFormData(ManageCaseData);
        setcus_NAME(ManageCaseData.cus_NAME);
        setas_NAME(ManageCaseData.as_NAME);
        seteq_NAME(ManageCaseData.eq_NAME);
        setitem_NAME(ManageCaseData.item_NAME);
        setcs_QA_SC(ManageCaseData.cs_QA_SC);
        setcs_PR_TY(ManageCaseData.cs_PR_TY);
        // setcs_ST_AT(ManageCaseData.cs_ST_AT);
        setcs_PR_GP(ManageCaseData.cs_PR_GP);
        setcs_PR_MD(ManageCaseData.cs_PR_MD);
        setcs_CS_TY(ManageCaseData.cs_CS_TY);
        setcs_AS_GN(ManageCaseData.cs_AS_GN);
        setcs_CU_NO(ManageCaseData.cs_CU_NO);
        setcs_ST_TM(ManageCaseData.cs_ST_TM);
        setcs_EN_TM(ManageCaseData.cs_EN_TM);
        setcs_AD_EM(ManageCaseData.cs_AD_EM);
        setcs_AD_PH(ManageCaseData.cs_AD_PH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleDefault();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);


  const handleDefault = async () => {
    setcs_CS_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "CS_CS_TY"){
              setcs_CS_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "CS_ST_AT"){
              onchangeDropDownFormData2("cs_ST_AT",dataDefault.versionIds[i].default_VALUE);
              setcs_ST_AT(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ManageCase/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    if(name=='cs_CU_NO')
      FormData[name]=value;
    else
      setFormData({ ...FormData, [name]: value });
  };

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcs_QA_SC(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcs_PR_TY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcs_ST_AT(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcs_PR_GP(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcs_PR_MD(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ManageCase/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ManageCaseData = response.data;
        setFormData(ManageCaseData);
        setcus_NAME(ManageCaseData.cus_NAME);
        setas_NAME(ManageCaseData.as_NAME);
        seteq_NAME(ManageCaseData.eq_NAME);
        setitem_NAME(ManageCaseData.item_NAME);
        setcs_QA_SC(ManageCaseData.cs_QA_SC);
        setcs_PR_TY(ManageCaseData.cs_PR_TY);
        // setcs_ST_AT(ManageCaseData.cs_ST_AT);
        setcs_PR_GP(ManageCaseData.cs_PR_GP);
        setcs_PR_MD(ManageCaseData.cs_PR_MD);
        setcs_CS_ID(ManageCaseData.cs_CS_ID);
        setcs_CS_TY(ManageCaseData.cs_CS_TY);
        setcsUkNo(ManageCaseData.csUkNo);
        setcs_AS_GN(ManageCaseData.cs_AS_GN);
        setcs_CU_NO(ManageCaseData.cs_CU_NO);
        setcs_ST_TM(ManageCaseData.cs_ST_TM);
        setcs_EN_TM(ManageCaseData.cs_EN_TM);
        setcs_AD_EM(ManageCaseData.cs_AD_EM);
        setcs_AD_PH(ManageCaseData.cs_AD_PH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
    const ST_TM = FormData.cs_ST_TM;
    var stTm = '0';
    if(ST_TM){
      stTm = ST_TM;
    } else if(cs_ST_TM){
      stTm = cs_ST_TM;
    }
    if(stTm.toString().includes(":")){
      stTm = stTm.replaceAll(":", "");
    }
    
    const EN_TM = FormData.cs_EN_TM;
    var enTm = '0';
    if(EN_TM){
      enTm = EN_TM;
    } else if(cs_EN_TM){
      enTm = cs_EN_TM;
    }
    if(enTm.toString().includes(":")){
      enTm = enTm.replaceAll(":", "");
    }

    requestBodyInput = {
      csUkNo:document.getElementById("csUkNo").value,
      cs_CS_ID: document.getElementById("cs_CS_ID").value,
      cs_CS_TY: document.getElementById("cs_CS_TY").value,
      cs_CS_CO: document.getElementById("cs_CS_CO").value,
      cs_TR_DT: FormData.cs_TR_DT,
      cs_CB_NO: document.getElementById("cs_CB_NO").value,
      cs_AD_PH: document.getElementById("cs_AD_PH").value,
      cs_CU_NO: cs_CU_NO,
      cs_AD_EM: document.getElementById("cs_AD_EM").value,
      cs_CA_NM: document.getElementById("cs_CA_NM").value,
      cs_CA_MB: document.getElementById("cs_CA_MB").value,
      cs_QA_FN: document.getElementById("cs_QA_FN").value,
      cs_QA_SC: cs_QA_SC,
      cs_ST_DT: FormData.cs_ST_DT,
      cs_ST_TM: stTm,
      cs_EN_DT: FormData.cs_EN_DT,
      cs_EN_TM: enTm,
      cs_PR_TY: cs_PR_TY,
      cs_ST_AT: cs_ST_AT,
      cs_AS_GN: cs_AS_GN, 
      cs_PR_GP: cs_PR_GP,
      cs_EQ_NM: document.getElementById("cs_EQ_NM").value,
      cs_PR_MD: cs_PR_MD,
      cs_IT_NM: document.getElementById("cs_IT_NM").value,
      cs_LO_TN: document.getElementById("cs_LO_TN").value,
      issue_DESC: document.getElementById("issue_DESC").value,
      cs_CS_SU: document.getElementById("cs_CS_SU").value,
      sol_DESC: document.getElementById("sol_DESC").value,
    };
  }
  const handleFormSubmit = () => {
    const csId = document.getElementById("cs_CS_ID").value;
    const ukNo = document.getElementById("csUkNo").value;
    values();
    if(csId){
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ManageCase/Update`;
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/ManageCase/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    }else {
      const OR_DT = FormData.cs_TR_DT;
        if (OR_DT) {
          const currentYear = OR_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${cs_CS_TY}/${secondHalf}/1000/${firstHalf}`;
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setcs_CS_ID(resp.dn_DO_NO);
            document.getElementById("cs_CS_ID").value = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/ManageCase/Add`, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
    };

    const handleResponse = (data) => {
      const { warnings = [], errorMessage = [], infos = [] } = data;
    
      if (warnings.length > 0) {
        const warningMessage = warnings.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: warningMessage,
        });
      }
    
      if (infos.length > 0) {
        const infoMessage = infos.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: infoMessage,
        });
      }
    
      if (errorMessage.length > 0) {
        const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessages,
        });
      }
    
      if (errorMessage.length === 0) {
        handleClose();
        handleDefault();
      }
    };
  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "red !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
      <div
        style={{
          backgroundColor: "transparent !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Search
      </div>
    </Tooltip>
    <Button
      style={searchButtonStyles}
      icon="pi pi-search"
      outlined
      className="mr-2 search-button"
    ></Button> */}
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );

  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%"
  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"15%",
  };
  const tableCellStyleA = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    width:"8%",
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  const handleClearButton=()=>{
    setSelectedAssignee("");
    setSelectedCustomer("");
    setCustomer("");
    setAssignee("");
    setStatus(null);
    setFilters(initialFilters);
  }
  
  const AdvanceSearch = (
          <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="cs_CU_NO" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{display:"none"}}
                id="Customer"
                name="Customer"
                value={Customer}
                onChange={(Event) => onchangedata(Event)}
              />
               <InputText
                id="cs_CU_NO"
                name="cs_CU_NO"
				        readOnly
				        style={{backgroundColor:"#ffffff"}}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedCustomer}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCompanyPopup}
              ></i>
               )}
            </div>
          </div>
          <div className="field col">
            <label htmlFor="cs_AS_GN" className="font-bold">
              Assignee <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{display:"none"}}
                id="Assignee"
                name="Assignee"
                value={Assignee}
                onChange={(Event) => onchangedata(Event)}
              />
              <InputText
                id="cs_AS_GN"
                name="cs_AS_GN"
				        readOnly
				        style={{backgroundColor:"#ffffff"}}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAssignee}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openContactOwnerPopup}
              ></i>
               )}
               <div>
            </div>
            </div>
          </div>
          <div className="field col" style={{display:"grid"}}>
            <label htmlFor="Status" className="font-bold">
              Status
            </label>
            <Dropdown
            style={{width:"140px"}}
              id="Status"
              name="Status"
              value={Status}
              options={cs_ST_ATOptions}
              onChange={(e) => onchangeDropDown("Status", e.value)}
              placeholder="Select Status"
            />
      </div>
    
  </div>
);
const rightClear = (
  <React.Fragment>
    <Button
      style={{ height: "35px" }}
      icon="pi pi-times"
      className="p-button-help"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar  className="p-mb-4" left={AdvanceSearch} right={rightClear} />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_CS_ID"] ? filters["cs_CS_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CS_ID",
                        e.target.value,
                        filters["cs_CS_ID"]
                          ? filters["cs_CS_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_CS_TY"] ? filters["cs_CS_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CS_TY",
                        e.target.value,
                        filters["cs_CS_TY"]
                          ? filters["cs_CS_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_CS_CO"] ? filters["cs_CS_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CS_CO",
                        e.target.value,
                        filters["cs_CS_CO"]
                          ? filters["cs_CS_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_TR_DT"] ? filters["cs_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_TR_DT",
                        e.target.value,
                        filters["cs_TR_DT"]
                          ? filters["cs_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              
                <td>
                  <input
                    type="text"
                    value={filters["cs_CU_NO"] ? filters["cs_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CU_NO",
                        e.target.value,
                        filters["cs_CU_NO"]
                          ? filters["cs_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_CB_NO"] ? filters["cs_CB_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CB_NO",
                        e.target.value,
                        filters["cs_CB_NO"]
                          ? filters["cs_CB_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_AS_GN"] ? filters["cs_AS_GN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_AS_GN",
                        e.target.value,
                        filters["cs_AS_GN"]
                          ? filters["cs_AS_GN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_CA_NM"] ? filters["cs_CA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_CA_NM",
                        e.target.value,
                        filters["cs_CA_NM"]
                          ? filters["cs_CA_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_AD_PH"] ? filters["cs_AD_PH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_AD_PH",
                        e.target.value,
                        filters["cs_AD_PH"]
                          ? filters["cs_AD_PH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cs_AD_EM"] ? filters["cs_AD_EM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cs_AD_EM",
                        e.target.value,
                        filters["cs_AD_EM"]
                          ? filters["cs_AD_EM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td >
                  <input
                    width="100%"
                    type="text"
                    readOnly
                    style={inputStyleH}
                  />
                </td>
                <td >
                  <input
                    width="100%"
                    type="text"
                    readOnly
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle1}></th>
                <th style={tableHeaderStyle}>Case ID</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Assignee</th>
                <th style={tableHeaderStyle}>Caller</th>
                <th style={tableHeaderStyle}>Phone No</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Priority</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Contract ID</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.csUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.csUkNo}
                        checked={selectedRows.includes(rowData.csUkNo)}
                        onChange={() => handleRowSelect(rowData.csUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cs_CS_ID}</td>
                    <td style={tableCellStyle}>{rowData.cs_CS_TY}</td>
                    <td style={tableCellStyle}>{rowData.cs_CS_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cs_TR_DT)}</td>
                    <td style={tableCellStyleC}>{rowData.cus_NAME}</td>
                    <td style={tableCellStyleA}>{rowData.as_NAME}</td>
                    <td style={tableCellStyle}>{rowData.cs_CA_NM}</td>
                    <td style={tableCellStyle}>{rowData.cs_AD_PH}</td>
                    <td style={tableCellStyle}>{rowData.cs_AD_EM}</td>
                    <td style={tableCellStyle}>{rowData.priority}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>
                    <td style={tableCellStyle}>{rowData.cs_CB_NO}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <AddressBookPopup
        visible={CompanyPopupVisible}
        onClose={() => setCompanyPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeCUST}
        setFormData={setFormData}
      />
      {/* contact owner */}
      <AddressBookPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={FormData}
        addressType={addressTypeassigne}
        setFormData={setFormData}
      />
      <ManageCaseDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        cs_PR_TY={cs_PR_TY}
        cs_ST_AT={cs_ST_AT}
        cs_QA_SC={cs_QA_SC}
        cs_PR_GP={cs_PR_GP}
        cs_CS_ID={cs_CS_ID}
        cs_PR_MD={cs_PR_MD}
        cs_CS_TY={cs_CS_TY}
        cs_CS_CO={cs_CS_CO}
        as_NAME={as_NAME}
        cus_NAME={cus_NAME}
        setas_NAME={setas_NAME}
        setcus_NAME={setcus_NAME}
        eq_NAME={eq_NAME}
        item_NAME={item_NAME}
        seteq_NAME={seteq_NAME}
        setitem_NAME={setitem_NAME}
        csUkNo={csUkNo}
        cs_CU_NO={cs_CU_NO}
        cs_AS_GN={cs_AS_GN}
        setcs_CU_NO={setcs_CU_NO}
        setcs_AS_GN={setcs_AS_GN}
        cs_AD_PH={cs_AD_PH}
        cs_AD_EM={cs_AD_EM}
        setcs_AD_EM={setcs_AD_EM}
        setcs_AD_PH={setcs_AD_PH}
      />
    </div>
  );
};

export default ManageCaseMain;
