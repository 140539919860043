import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
export default function InPatientDepartmentDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,

  // checkboxes in treatment
  id_AL_FL,
  setid_AL_FL,
  id_TB_FL,
  setid_TB_FL,
  id_SM_FL,
  setid_SM_FL,
  Alcoholchecked,
  setAlcoholchecked,
  Tabaccochecked,
  setTabaccochecked,
  Smokingchecked,
  setSmokingchecked,

}) {
  const {
    idUkNo,Id_VI_NO,Id_VI_DT,Id_PA_NM,Id_PA_ID,Id_DO_NO,
    pd_GN_DR,pd_PA_AG,SPECIALIZATION,pd_BL_GP,
    pa_AL_GY,pc_CH_NC,

    id_DE_PT,id_RM_TY,id_BD_NO,

    ph_QA_DS,ph_AN_DS,
    pm_MD_NM,pm_DS_AG,pm_DS_NT,pm_MD_QT,pm_MD_UM,

    lm_TS_TY,lm_TS_NM,
    pl_LB_TY,pl_LB_NM,pl_CH_RG,id_LB_TL,
    pm_PR_DS,pm_CH_RG,pm_PR_NM,
    pp_PR_NM,pp_SU_PR,pp_PR_CH,pp_PR_QT,pp_PR_AM,id_PR_TL,
    // consumables
    pc_ST_TY,pc_IT_NM,pc_IT_DS,pc_IT_PR,pc_IT_QT,pc_IT_UM,pc_IT_AM,
    id_CN_TL,id_OT_CH,id_MS_CH,

    im_IT_NM,im_IT_DS,im_UN_PR,im_PR_UM,

    id_HT_CM,id_WT_KG,id_BP_LV,id_BP_HI,
    im_ST_TY,
    Id_RE_MK,id_DS_TY,id_PV_DA,id_FL_UP,

  } = data;
  const {borderboxdir,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // checkboxes
  // level  break type checkboxes
  const handleCheckboxBreakType = (event) => {
    const { name, checked } = event.target;
    if (name === 'Alcohol') {
      setAlcoholchecked(checked);
      if (checked) {
        setid_AL_FL('A');
        setFormData(prevFormData => ({ ...prevFormData, id_AL_FL: 'A' }));
      } else {
        setid_AL_FL('');
        setFormData(prevFormData => ({ ...prevFormData, id_AL_FL: '' }));
      }
    } else if (name === 'Tabacco') {
      setTabaccochecked(checked);
      if (checked) {
        setid_TB_FL('T');
        setFormData(prevFormData => ({ ...prevFormData, id_TB_FL: 'T' }));
      } else {
        setid_TB_FL('');
        setFormData(prevFormData => ({ ...prevFormData, id_TB_FL: '' }));
      }
    }else if (name === 'Smoking') {
      setSmokingchecked(checked);
      if (checked) {
        setid_SM_FL('S');
        setFormData(prevFormData => ({ ...prevFormData, id_SM_FL: 'S' }));
      } else {
        setid_SM_FL('');
        setFormData(prevFormData => ({ ...prevFormData, id_SM_FL: '' }));
      }
    }
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{idUkNo ? "Revise IP Department" : "Add IP Department"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div className="p-grid" style={borderboxdir} >
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="Id_VI_NO" className="font-bold">
                      Visit No
                    </label>
                    <InputText 
                      id="Id_VI_NO"
                      name="Id_VI_NO"
                      value={Id_VI_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="Id_VI_DT" className="font-bold">
                      Visit Date
                    </label>
                    <Calendar
                      id="Id_VI_DT"
                      name="Id_VI_DT"
                      value={moment(data.Id_VI_DT, "YYYYMMDD").isValid() ? moment(data.Id_VI_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "Id_VI_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_RG_DT" className="font-bold">
                      Reg. Date
                    </label>
                    <Calendar
                      id="pd_RG_DT"
                      name="pd_RG_DT"
                      value={moment(data.pd_RG_DT, "YYYYMMDD").isValid() ? moment(data.pd_RG_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "pd_RG_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="Id_PA_ID" className="font-bold">
                      Patient ID
                    </label>
                    <InputText 
                      id="Id_PA_ID"
                      name="Id_PA_ID"
                      value={Id_PA_ID}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="Id_PA_NM" className="font-bold">
                      Name
                    </label>
                    <InputText 
                      id="Id_PA_NM"
                      name="Id_PA_NM"
                      value={Id_PA_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_GN_DR" className="font-bold">
                      Gender
                    </label>
                    <InputText 
                      id="pd_GN_DR"
                      name="pd_GN_DR"
                      value={pd_GN_DR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_PA_AG" className="font-bold">
                      Age
                    </label>
                    <InputText 
                      id="pd_PA_AG"
                      name="pd_PA_AG"
                      value={pd_PA_AG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="Id_DO_NO" className="font-bold">
                      Doctor
                    </label>
                    <InputText 
                      id="Id_DO_NO"
                      name="Id_DO_NO"
                      value={Id_DO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="SPECIALIZATION" className="font-bold">
                      Specialization
                    </label>
                    <InputText 
                      id="SPECIALIZATION"
                      name="SPECIALIZATION"
                      value={SPECIALIZATION}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pd_BL_GP" className="font-bold">
                      Blood Group
                    </label>
                    <InputText 
                      id="pd_BL_GP"
                      name="pd_BL_GP"
                      value={pd_BL_GP}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div style={inboxstyle} >
              <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Allery</h1>
                <div className="formgrid grid">
                  <div className="field col-lg-12 col-md-6 col-sm-12 mb-3">
                    <InputText 
                      id="pa_AL_GY"
                      name="pa_AL_GY"
                      value={pa_AL_GY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
            <div style={inboxstyle} >
              <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Chronic</h1>
                <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-sm-12 mb-3">
                      <InputText 
                        id="pc_CH_NC"
                        name="pc_CH_NC"
                        value={pc_CH_NC}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                </div>
            </div>
          </div>
        </div>


    {/* Second Container: TabView with 6 Tabs */}
    <div  style={TabBoxStyle} >
      <TabView>
        <TabPanel header="Allocate Bed">
          <div class="row" style={inboxstyle}>
            <div class="col-lg-5 col-md-12 col-sm-12 mb-3">
                <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="id_DE_PT" className="font-bold">
                      Department
                    </label>
                    <InputText 
                      id="id_DE_PT"
                      name="id_DE_PT"
                      value={id_DE_PT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="id_RM_TY" className="font-bold">
                      Room Type
                    </label>
                    <InputText 
                      id="id_RM_TY"
                      name="id_RM_TY"
                      value={id_RM_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                    <label htmlFor="id_BD_NO" className="font-bold">
                      Bed No.
                    </label>
                      <InputText 
                        id="id_BD_NO"
                        name="id_BD_NO"
                        value={id_BD_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                      </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                    <label htmlFor="id_EX_DT" className="font-bold">
                      Expected Dis. Date
                    </label>
                    <Calendar
                      id="id_EX_DT"
                      name="id_EX_DT"
                      value={moment(data.id_EX_DT, "YYYYMMDD").isValid() ? moment(data.id_EX_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "id_EX_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Health Details">
        <div class="row" style={inboxstyle}>
          <div class="col-lg-5 col-md-12 col-sm-12 mb-3">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_HT_CM" className="font-bold">
                  Height (CM)
                  </label>
                  <InputText 
                    id="id_HT_CM"
                    name="id_HT_CM"
                    value={id_HT_CM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_WT_KG" className="font-bold">
                    Weight (KG)
                  </label>
                  <InputText 
                    id="id_WT_KG"
                    name="id_WT_KG"
                    value={id_WT_KG}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                  <label htmlFor="id_BP_LV" className="font-bold">
                    BP
                  </label>
                  <div style={{display:"flex"}}> 
                    <InputText 
                      id="id_BP_LV"
                      name="id_BP_LV"
                      value={id_BP_LV}
                      onChange={(e) => onchangedata(e)}
                    />
                    <InputText 
                      id="id_BP_HI"
                      name="id_BP_HI"
                      value={id_BP_HI}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th>Specialized Health Details</th>
                      <th>Answers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> 
                        <InputText  id="ph_QA_DS"   name="ph_QA_DS"  value={ph_QA_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="ph_AN_DS"   name="ph_AN_DS"  value={ph_AN_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>
        </TabPanel>
        <TabPanel header="Prescription">
          <div class="row" style={inboxstyle}>
            {/*  */}
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Symptoms</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={"idUkNo"}
                          />
                        </td>
                        <td> 
                          <InputText  id="Id_VI_NO"   name="Id_VI_NO"  value={Id_VI_NO}  onChange={(e) => onchangedata(e)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Get Daignosis"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />

                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Diagnosis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={"idUkNo"}
                          />
                        </td>
                        <td> 
                          <InputText  id="Id_VI_NO"   name="Id_VI_NO"  value={Id_VI_NO}  onChange={(e) => onchangedata(e)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Get Medicines"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Medicines</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={"idUkNo"}
                          />
                        </td>
                        <td> 
                          <InputText  id="Id_VI_NO"   name="Id_VI_NO"  value={Id_VI_NO}  onChange={(e) => onchangedata(e)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Medicines"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}> Doctor Note </h1>
                  <textarea
                    id="fa_FL_DS"
                    name="fa_FL_DS"
                    value={"fa_FL_DS"}
                    onChange={(e) => onchangedata(e)}
                    style={{ width: "100%",height:'9rem' }}
                  /> 
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            {/* table 2nd tab */}
            <div style={inboxstyle} >
              <table className="custom-table">
                <thead>
                  <tr> 
                    <th>Medicine</th>
                    <th>Dosage</th>
                    <th>Dosage Note</th>
                    <th>Quantity</th>
                    <th>UM</th>
                    <th>Available Qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 
                      <InputText  id="pm_MD_NM"   name="pm_MD_NM"  value={pm_MD_NM}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="pm_DS_AG"   name="pm_DS_AG"  value={pm_DS_AG}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="pm_DS_NT"   name="pm_DS_NT"  value={pm_DS_NT}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="pm_MD_QT"   name="pm_MD_QT"  value={pm_MD_QT}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="pm_MD_UM"   name="pm_MD_UM"  value={pm_MD_UM}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="Id_VI_NO"   name="Id_VI_NO"  value={Id_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </TabPanel>
          {/* 3rd tab */}
        <TabPanel header="Lab Test">
          <div class="row" style={inboxstyle}>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="lm_TS_TY" className="font-bold">
                  Select Lab Test Type
                </label>
                <InputText  style={{width:'20rem'}}
                  id="lm_TS_TY"
                  name="lm_TS_TY"
                  value={lm_TS_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Test Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"idUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="lm_TS_NM"   name="lm_TS_NM"  value={lm_TS_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Tests"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th> </th>
                      <th>Test Type</th>
                      <th>Test Name</th>
                      <th>Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input type="checkbox" value={"idUkNo"}/>
                      </td>
                      <td>
                        <InputText  id="pl_LB_TY"   name="pl_LB_TY"  value={pl_LB_TY}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pl_LB_NM"   name="pl_LB_NM"  value={pl_LB_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="pl_CH_RG"   name="pl_CH_RG"  value={pl_CH_RG}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_LB_TL" className="font-bold">
                  Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="id_LB_TL"
                  name="id_LB_TL"
                  value={id_LB_TL}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <Button
                style={{backgroundColor:'darkcyan',border:'none',width:'12rem'}}
                label="Open Reports"
                icon="pi pi-times"
                tooltip="Cancel"
              />
            </div>
          </div>
        </TabPanel>
          {/* 4th tab */}
        <TabPanel header="Procedure">
          <div class="row" style={inboxstyle}>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="pm_PR_NM" className="font-bold">
                  Select Procedure
                </label>
                <InputText  style={{width:'20rem'}}
                  id="pm_PR_NM"
                  name="pm_PR_NM"
                  value={pm_PR_NM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Sub Procedure Name</th>
                      <th>Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"idUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="pm_PR_DS"   name="pm_PR_DS"  value={pm_PR_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pm_CH_RG"   name="pm_CH_RG"  value={pm_CH_RG}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Procedure"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
              <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Procedure Name</th>
                      <th>Sub Procedure Name</th>
                      <th>Charge</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"idUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="pp_PR_NM"   name="pp_PR_NM"  value={pp_PR_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pp_SU_PR"   name="pp_SU_PR"  value={pp_SU_PR}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pp_PR_CH"   name="pp_PR_CH"  value={pp_PR_CH}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pp_PR_QT"   name="pp_PR_QT"  value={pp_PR_QT}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pp_PR_AM"   name="pp_PR_AM"  value={pp_PR_AM}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_PR_TL" className="font-bold">
                  Grand Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="id_PR_TL"
                  name="id_PR_TL"
                  value={id_PR_TL}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        {/* 5th */}
        <TabPanel header="Consumables" >
        <div class="row" style={inboxstyle}>
            <div class="col-lg-5 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="im_ST_TY" className="font-bold">
                  Stock Type
                </label>
                <InputText  style={{width:'20rem'}}
                  id="im_ST_TY"
                  name="im_ST_TY"
                  value={im_ST_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Price </th>
                      <th>UM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"idUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="im_IT_NM"   name="im_IT_NM"  value={im_IT_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_PR_UM"   name="im_PR_UM"  value={im_PR_UM}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Consumables"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th>Stock Type</th>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>UM</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <InputText  id="pc_ST_TY"   name="pc_ST_TY"  value={pc_ST_TY}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pc_IT_NM"   name="pc_IT_NM"  value={pc_IT_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="pc_IT_DS"   name="pc_IT_DS"  value={pc_IT_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pc_IT_PR"   name="pc_IT_PR"  value={pc_IT_PR}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="pc_IT_QT"   name="pc_IT_QT"  value={pc_IT_QT}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="pc_IT_UM"   name="pc_IT_UM"  value={pc_IT_UM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="pc_IT_AM"   name="pc_IT_AM"  value={pc_IT_AM}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_CN_TL" className="font-bold">
                  Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="id_CN_TL"
                  name="id_CN_TL"
                  value={id_CN_TL}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        {/* 6th tab */}
        <TabPanel header="Charges">
          <div class="row" style={inboxstyle}>            
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="id_OT_CH" className="font-bold">
                Other Charges
              </label>
              <InputText 
                id="id_OT_CH"
                name="id_OT_CH"
                value={id_OT_CH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="id_MS_CH" className="font-bold">
                Mis. Charges
              </label>
              <InputText 
                id="id_MS_CH"
                name="id_MS_CH"
                value={id_MS_CH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="Id_RE_MK" className="font-bold">
                Remarks
              </label>
              <InputText 
                id="Id_RE_MK"
                name="Id_RE_MK"
                value={Id_RE_MK}
                onChange={(e) => onchangedata(e)}
              />
            </div>

          </div>
        </div>
        </TabPanel>
        {/* 7TH TAB */}
        <TabPanel header="Billing">
          <div class="row" style={inboxstyle}> 

            <div class="col">
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}> Bill Details </h1>
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Lab Test Charges
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Procedure Charges
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Consumable Charges
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Consultation Fee
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Other Charges
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Misc. Charges
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div> 

            <div class="col"> 
            <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Discount</h1>
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Discount %
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Discount Amount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Deposit</h1>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Amount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Given By
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <Button
                    style={{backgroundColor:'#80d3d3',border:'none',marginTop:'1rem',width:'6rem'}}
                    label="Deposit"
                  />
              </div>
            </div> 

            <div class="col">
              <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Total Bill Details</h1>
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Bill Amount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Discount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Grand Total
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Paid Amount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Remaining Amount
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Extra Amount  
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div> 

          </div>

          <div class="row" style={inboxstyle}>
            {/* bed charges table */}
            <div class="col-lg-4 col-md-6 col-12 pe-md-2">
              <table className="custom-table">
                <thead>
                  <tr> 
                    <th>Date</th>
                    <th>Room Type</th>
                    <th>Charge </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 
                      <InputText  id="im_IT_NM"   name="im_IT_NM"  value={im_IT_NM}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* deposi details */}
            <div class="col-lg-4 col-md-6 col-12 pe-md-2">
              
              <table className="custom-table">
                <thead>
                  <tr> 
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Given By </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 
                      <InputText  id="im_IT_NM"   name="im_IT_NM"  value={im_IT_NM}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* payment method */}
            <div class="col-lg-4 col-md-6 col-12 pe-md-2">

            <div className="formgrid grid" style={{marginTop:'1rem'}} >
                <div className="field col-lg-3 col-md-6 col-12 ">
                  <Button
                    style={{backgroundColor:'#80d3d3',border:'none',marginTop:'1rem',width:'6rem'}}
                    label="Cash"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 ">
                  <Button
                    style={{backgroundColor:'#80d3d3',border:'none',marginTop:'1rem',width:'6rem'}}
                    label="Card"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 ">
                  <Button
                    style={{backgroundColor:'#80d3d3',border:'none',marginTop:'1rem',width:'6rem'}}
                    label="Coupon"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 ">
                  <Button
                    style={{backgroundColor:'#80d3d3',border:'none',marginTop:'1rem',width:'6rem'}}
                    label="Credit"
                  />
                </div>
              </div>


              <table className="custom-table">
                <thead>
                  <tr> 
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Currency </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 
                      <InputText  id="im_IT_NM"   name="im_IT_NM"  value={im_IT_NM}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="formgrid grid" style={{marginTop:'1rem'}} >
                <div className="field col-lg-6 col-md-6 col-12 d-flex align-items-center">
                  <label htmlFor="id_CN_TL" className="font-bold" style={{ whiteSpace: 'nowrap', width: '5rem', marginRight: '1rem' }}>
                    Total
                  </label>
                  <InputText
                    style={{ width: '10rem' }}
                    id="id_CN_TL"
                    name="id_CN_TL"
                    value={id_CN_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-6 col-md-6 col-12 d-flex align-items-center">
                  <label htmlFor="id_CN_TL" className="font-bold" style={{ whiteSpace: 'nowrap', width: '5rem', marginRight: '1rem' }}>
                    Balance
                  </label>
                  <InputText
                    style={{ width: '10rem' }}
                    id="id_CN_TL"
                    name="id_CN_TL"
                    value={id_CN_TL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>


            </div>
          </div>
        </TabPanel>
        {/* treatment */}
        <TabPanel header="Treatment">
          <div class="row" style={inboxstyle}> 
          <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}> Notes </h1>
            <div class="col">
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    S/B
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    C/O
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    H/O
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    RS
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                
                
              </div>
            </div>

            <div class="col">
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    CVS
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    CNS
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    ENT
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    BP
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>

            <div class="col">
              <div className="formgrid grid">
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_OT_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    P/A
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_OT_CH"
                    name="id_OT_CH"
                    value={id_OT_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="id_MS_CH" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    O/E
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="id_MS_CH"
                    name="id_MS_CH"
                    value={id_MS_CH}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-12 col-md-12 col-12 d-flex align-items-center mb-1">
                  <label htmlFor="Id_RE_MK" className="font-bold" style={{ whiteSpace: 'nowrap', width: '10rem' }}>
                    Pulse
                  </label>
                  <InputText style={{width:'10rem'}}
                    id="Id_RE_MK"
                    name="Id_RE_MK"
                    value={Id_RE_MK}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

               
              </div>
            </div>

            <div class="col">
              <div style={{width:"20rem"}}>
                <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                  <label htmlFor="quantity_level" className="font-bold d-block mb-1">
                    Alcohol
                  </label>
                  <input
                    type="checkbox"
                    id="Alcohol"
                    name="Alcohol"
                    checked={Alcoholchecked}
                    onChange={handleCheckboxBreakType}
                  />
                </div>
                <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                  <label htmlFor="amount_level" className="font-bold d-block mb-1">
                    Tabacco
                  </label>
                  <input
                    type="checkbox"
                    id="Tabacco"
                    name="Tabacco"
                    checked={Tabaccochecked}
                    onChange={handleCheckboxBreakType}
                  />
                </div>
                <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                  <label htmlFor="amount_level" className="font-bold d-block mb-1">
                    Smoking
                  </label>
                  <input
                    type="checkbox"
                    id="Smoking"
                    name="Smoking"
                    checked={Smokingchecked}
                    onChange={handleCheckboxBreakType}
                  />
                </div>
              </div>
            </div>

            <div class="row">
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th>Medicine </th>
                      <th>Dosage </th>
                      <th>Quantity </th>
                      <th>Total Days </th>
                      <th>Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> 
                        <InputText  id="im_IT_NM"   name="im_IT_NM"  value={im_IT_NM}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_IT_DS"   name="im_IT_DS"  value={im_IT_DS}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="im_UN_PR"   name="im_UN_PR"  value={im_UN_PR}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>

          </div>

        </TabPanel>
        {/* Discharge */}
        <TabPanel header="Discharge">
          <div class="row" style={inboxstyle}>  
            <div class="col">
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_DS_DT" className="font-bold">
                    Discharge Date
                  </label>
                    <Calendar
                      id="id_DS_DT"
                      name="id_DS_DT"
                      value={moment(data.id_DS_DT, "YYYYMMDD").isValid() ? moment(data.id_DS_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "id_DS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_DS_TY" className="font-bold">
                    Discharge Type
                  </label>
                  <InputText 
                    id="id_DS_TY"
                    name="id_DS_TY"
                    value={id_DS_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_PV_DA" className="font-bold">
                    Daignosis
                  </label>
                  <textarea rows={5} cols={30} 
                    id="id_PV_DA"
                    name="id_PV_DA"
                    value={id_PV_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_FL_UP" className="font-bold">
                    Follow UP
                  </label>
                  <textarea rows={5} cols={30} 
                    id="id_FL_UP"
                    name="id_FL_UP"
                    value={id_FL_UP}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>


              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
  </Dialog>
  );
}
