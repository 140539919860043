import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function CustomerMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  onchangeDropDownFormData14,
  onchangeDropDownFormData15,
  onchangeDropDownFormData16,
  onchangeDropDownFormData17,
  onchangeDropDownFormData18,
  onchangeDropDownFormData19,
  onchangeDropDownFormData20,
  onchangeDropDownFormData21,
  onchangeDropDownFormData22,
  onchangeDropDownFormData23,
  cu_CO_NO,
  cu_TX_CD,
  SelectedemEMNO,
  setSelectedemEMNO,
  selectedAbAdNM,
  setSelectedAbAdNM,
  selectedAbAdNM1,
  setSelectedAbAdNM1,
  selectedAbAdNM2,
  setSelectedAbAdNM2,
  selectedAbAdNM3,
  setSelectedAbAdNM3,
  selectedAbAdNM4,
  setSelectedAbAdNM4,
 
}) {
  const {
    cuUkNo,
    cu_CU_NO,
    cu_PT_TM,
    cu_CR_CD,
    cu_PY_IN,
    cu_GL_CD,
    cu_IN_HD,
    cu_ST_AD,
    cu_PA_NO,
    cu_OD_AP,
    cu_NT_FL,
    cu_ST_PT,
    cu_OD_DY,
    cu_IN_AD,
    cu_CR_MR,
    cu_CO_MR,
    cu_CR_LT,
    cu_DT_OP,
    cu_PE_OP,
    cu_ST_CY,
    cu_CR_NO,
    cu_PR_AG,
    cu_DS_PR,
    cu_TR_DY,
    cu_FR_HC,
    cu_RT_CD,
    cu_TX_AP,
    cu_TX_TY,
    cu_TX_RT,
    cu_TX_AR,
    cu_HL_CD,
    cu_BL_TY,
    cu_AP_FR,
    cu_PR_GP,
    cu_IT_RT,    
  } = data;
  const {TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [cu_PT_TMOptions, setcu_PT_TMOptions] = useState([]);
  const [cu_CR_CDOptions, setcu_CR_CDOptions] = useState([]);
  const [cu_PY_INOptions, setcu_PY_INOptions] = useState([]);
  const [cu_GL_CDOptions, setcu_GL_CDOptions] = useState([]);
  const [cu_IN_HDOptions, setcu_IN_HDOptions] = useState([]);
  const [cu_ST_ADOptions, setcu_ST_ADOptions] = useState([]);
  const [cu_IN_ADOptions, setcu_IN_ADOptions] = useState([]);
  const [cu_OD_APOptions, setcu_OD_APOptions] = useState([]);
  const [cu_NT_FLOptions, setcu_NT_FLOptions] = useState([]);
  const [cu_PR_AGOptions, setcu_PR_AGOptions] = useState([]);
  const [cu_FR_HCOptions, setcu_FR_HCOptions] = useState([]);
  const [cu_RT_CDOptions, setcu_RT_CDOptions] = useState([]);
  const [cu_TX_AROptions, setcu_TX_AROptions] = useState([]);
  const [cu_TX_CDOptions, setcu_TX_CDOptions] = useState([]);
  const [cu_HL_CDOptions, setcu_HL_CDOptions] = useState([]);
  const [cu_TX_TYOptions, setcu_TX_TYOptions] = useState([]);
  const [cu_TX_APOptions, setcu_TX_APOptions] = useState([]);
  const [cu_ST_PTOptions, setcu_ST_PTOptions] = useState([]);
  const [cu_ST_CYOptions, setcu_ST_CYOptions] = useState([]);
  const [cu_AP_FROptions, setcu_AP_FROptions] = useState([]);
  const [cu_IT_RTOptions, setcu_IT_RTOptions] = useState([]);
  const [cu_PR_GPOptions, setcu_PR_GPOptions] = useState([]);
  const [cu_BL_TYOptions, setcu_BL_TYOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const urlcost = `${domain}/api/CostCenter/DropDown`;

  // cost center
  // const parameter1 = "CC/TY";
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl1 = `${urlpayterm}`;

  const url = `${domain}/api/ClassCode/All`;
  // designation
  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;
  // departmemt
  const parameter3 = "FO/PI";
  const finalUrl3 = `${url}/${parameter3}`;
  // pay class
  const parameter4 = "GL/GC";
  const finalUrl4 = `${url}/${parameter4}`;
  // grade
  const parameter5 = "ME/PL";
  const finalUrl5 = `${url}/${parameter5}`;
  // Pay Frequency
  const parameter6 = "ME/PL";
  const finalUrl6 = `${url}/${parameter6}`;
 // Pay Frequency
 const parameter7 = "ME/PL";
 const finalUrl7 = `${url}/${parameter7}`;
 // Pay Frequency
 const parameter8 = "ME/PL";
 const finalUrl8 = `${url}/${parameter8}`;
  // Pay Frequency
  const parameter9 = "ME/PL";
  const finalUrl9 = `${url}/${parameter9}`;
 // Pay Frequency
const parameter10 = "AD/SH";
const finalUrl10 = `${url}/${parameter10}`;
// Pay Frequency
const parameter11 = "FO/FH";
const finalUrl11 = `${url}/${parameter11}`;
// Pay Frequency
const parameter12 = "FO/RC";
const finalUrl12 = `${url}/${parameter12}`;
// Pay Frequency
const parameter13 = "FO/TA";
const finalUrl13 = `${url}/${parameter13}`;
// Pay Frequency
const parameter14 = "TX/RT";
const finalUrl14 = `${url}/${parameter14}`;
 // Pay Frequency
const parameter15 = "FO/HD";
const finalUrl15 = `${url}/${parameter15}`;
// Pay Frequency
const parameter16 = "TA/TY";
const finalUrl16 = `${url}/${parameter16}`;
// Pay Frequency
const parameter17 = "ME/PL";
const finalUrl17 = `${url}/${parameter17}`;
// Pay Frequency
const parameter18 = "ME/PL";
const finalUrl18 = `${url}/${parameter18}`;
// Pay Frequency
const parameter19 = "ST/CY";
const finalUrl19 = `${url}/${parameter19}`;
// Pay Frequency
const parameter20 = "ME/PL";
const finalUrl20 = `${url}/${parameter20}`;
// Pay Frequency
const parameter21 = "ME/PL";
const finalUrl21 = `${url}/${parameter21}`;
// Pay Frequency
const parameter22 = "SO/PR";
const finalUrl22 = `${url}/${parameter22}`;
// Pay Frequency
const parameter23 = "BL/TO";
const finalUrl23 = `${url}/${parameter23}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions11();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
    fetchDropdownOptions14();
    fetchDropdownOptions15();
    fetchDropdownOptions16();
    fetchDropdownOptions17();
    fetchDropdownOptions18();
    fetchDropdownOptions19();
    fetchDropdownOptions20();
    fetchDropdownOptions21();
    fetchDropdownOptions22();
    fetchDropdownOptions23();
  }, []);

// 1st dropdowns
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setcu_PT_TMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdowns
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setcu_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_PY_INOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setcu_GL_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_IN_HDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 6th dropdown
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_ST_ADOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 7th dropdown
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_IN_ADOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 8th dropdown
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_OD_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 9th dropdown
const fetchDropdownOptions9 = async () => {
  try {
    const response = await axios.get(finalUrl9, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_NT_FLOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 10th dropdown
const fetchDropdownOptions10 = async () => {
  try {
    const response = await axios.get(finalUrl10, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_PR_AGOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 11th dropdown
const fetchDropdownOptions11 = async () => {
  try {
    const response = await axios.get(finalUrl11, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_FR_HCOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 12th dropdown
const fetchDropdownOptions12= async () => {
  try {
    const response = await axios.get(finalUrl12, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_RT_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 13th dropdown
const fetchDropdownOptions13= async () => {
  try {
    const response = await axios.get(finalUrl13, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_TX_AROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 14th dropdown
const fetchDropdownOptions14= async () => {
  try {
    const response = await axios.get(finalUrl14, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setcu_TX_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 15th dropdown
const fetchDropdownOptions15= async () => {
  try {
    const response = await axios.get(finalUrl15, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_HL_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 16th dropdown
const fetchDropdownOptions16= async () => {
  try {
    const response = await axios.get(finalUrl16, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_TX_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 17th dropdown
const fetchDropdownOptions17= async () => {
  try {
    const response = await axios.get(finalUrl17, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_TX_APOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 18th dropdown
const fetchDropdownOptions18= async () => {
  try {
    const response = await axios.get(finalUrl18, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_ST_PTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 19th dropdown
const fetchDropdownOptions19= async () => {
  try {
    const response = await axios.get(finalUrl19, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_ST_CYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 20th dropdown
const fetchDropdownOptions20= async () => {
  try {
    const response = await axios.get(finalUrl20, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_AP_FROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 21th dropdown
const fetchDropdownOptions21= async () => {
  try {
    const response = await axios.get(finalUrl21, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_IT_RTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 22th dropdown
const fetchDropdownOptions22= async () => {
  try {
    const response = await axios.get(finalUrl22, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_PR_GPOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 23th dropdown
const fetchDropdownOptions23= async () => {
  try {
    const response = await axios.get(finalUrl23, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setcu_BL_TYOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const GetTaxCode =  async (cu_TX_CD) => {
    const  Url = `${domain}/api/TaxRate/GetByTaxCode`
    const requestData={
      "tx_TX_CD": cu_TX_CD,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeFormData("cu_TX_RT", dataItMt.tx_TX_RT);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const openCustomerPopup = (e) => {
  setCustomerPopupVisible(true);
  setSelectedRowIndex(e);
};
//  startsearchpopup
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData("cu_CU_NO", data.abAdNo);
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
};
const [ParentPopupVisible, setParentPopupVisible] = useState(false);
const openParentPopup = (e) => {
  setParentPopupVisible(true);
  setSelectedRowIndex(e);
};
//  parentpopup
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  onchangeFormData("cu_PA_NO", data.abAdNo);
  setParentPopupVisible(false);
  setShowSearchIcon(false);
};
const [CollectionManagerPopupVisible, setCollectionManagerPopupVisible] = useState(false);
const openCollectionManagerPopup = (e) => {
  setCollectionManagerPopupVisible(true);
  setSelectedRowIndex(e);
};
// collection managerpopup
const handleDataSelect2 = async (data) => {
  setSelectedAbAdNM1(data.ab_AD_NM);
  onchangeFormData("cu_CO_MR", data.abAdNo);
  setCollectionManagerPopupVisible(false);
  setShowSearchIcon(false);
};

const [CreditManagerPopupVisible, setCreditManagerPopupVisible] = useState(false);
const [addressTypeMng, setaddressTypeMng ] = useState("E");
const openCreditManagerPopup = (e) => {
  setCreditManagerPopupVisible(true);
  setSelectedRowIndex(e);
};
//credit managerpopup
const handleDataSelect3 = async (data) => {
  setSelectedAbAdNM2(data.ab_AD_NM);
  onchangeFormData("cu_CR_MR", data.abAdNo);
  setCreditManagerPopupVisible(false);
  setShowSearchIcon(false);
};

const [PersonOpeningPopupVisible, setPersonOpeningPopupVisible] = useState(false);
const openPersonOpeningPopup = (e) => {
  setPersonOpeningPopupVisible(true);
  setSelectedRowIndex(e);
};
//person openingpopup
const handleDataSelect4 = async (data) => {
  setSelectedAbAdNM3(data.ab_AD_NM);
  onchangeFormData("cu_PE_OP", data.abAdNo);
  setPersonOpeningPopupVisible(false);
  setShowSearchIcon(false);
};

const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
const [addressTypecust, setaddressTypecust] = useState("C");
const openCarrierPopup = (e) => {
  setCarrierPopupVisible(true);
  setSelectedRowIndex(e);
};
//  carrier popup
const handleDataSelect5 = async (data) => {
  setSelectedAbAdNM4(data.ab_AD_NM);
  onchangeFormData("cu_CR_NO", data.abAdNo);
  setCarrierPopupVisible(false);
  setShowSearchIcon(false);
};
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cuUkNo ? "Revise Customer Master " : "Add Customer Master "}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />)}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />)}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="cu_CU_NO" className="font-bold">
                  Customer  <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedemEMNO}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
                  <InputText style={{ display: "none" }}
                    id="cu_CU_NO"
                    name="cu_CU_NO"
                    value={cu_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_CR_CD" className="font-bold">
                    Currency
                  </label>
                  <Dropdown
                    name="cu_CR_CD"
                    value={cu_CR_CD}
                    options={cu_CR_CDOptions}
                    onChange={(e) => onchangeDropDownFormData1("cu_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="cu_CO_NO"
                    name="cu_CO_NO"
                    value={cu_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_PT_TM" className="font-bold">
                    Payment Term
                  </label>
                  <Dropdown
                    name="cu_PT_TM"
                    value={cu_PT_TM}
                    options={cu_PT_TMOptions}
                    onChange={(e) => onchangeDropDownFormData2("cu_PT_TM", e.value)}
                    placeholder="Select Payterm"
                  />
                </div>
            
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_PY_IN" className="font-bold">
                    Payment Instrument
                  </label>
                  <Dropdown
                    name="cu_PY_IN"
                    value={cu_PY_IN}
                    options={cu_PY_INOptions}
                    onChange={(e) => onchangeDropDownFormData3("cu_PY_IN", e.value)}
                    placeholder="Select Payment Instrument"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_GL_CD" className="font-bold">
                    GL Code
                  </label>
                  <Dropdown
                    name="cu_GL_CD"
                    value={cu_GL_CD}
                    options={cu_GL_CDOptions}
                    onChange={(e) => onchangeDropDownFormData4("cu_GL_CD", e.value)}
                    placeholder="Select GL Code"

                  />
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_IN_HD" className="font-bold">
                   Hold Voice
                  </label>
                  <Dropdown
              id="cu_IN_HD"
              name="cu_IN_HD"
              value={cu_IN_HD}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData5("cu_IN_HD", e.value)}
              placeholder="Select Hold Voice"
            />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_ST_AD" className="font-bold">
                    Send Statement to C/P
                  </label>
                  <Dropdown
              id="cu_ST_AD"
              name="cu_ST_AD"
              value={cu_ST_AD}
              options={[
                { label: "C", value: "C" },
                { label: "P", value: "P" },
              ]}
              onChange={(e) => onchangeDropDownFormData6("cu_ST_AD", e.value)}
              placeholder="Select Send Invoice to C/P"
            />
                </div>
         
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_IN_AD" className="font-bold">
                    Send Invoice to C/P
                  </label>
                  <Dropdown
              id="cu_IN_AD"
              name="cu_IN_AD"
              value={cu_IN_AD}
              options={[
                { label: "C", value: "C" },
                { label: "P", value: "P" },
              ]}
              onChange={(e) => onchangeDropDownFormData7("cu_IN_AD", e.value)}
              placeholder="Select Send statement to C/P"
            />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="cu_PA_NO" className="font-bold">
              Parent  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="parent_Name"
                name="parent_Name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM}
               
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openParentPopup}
              ></i>
               )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cu_PA_NO"
                name="cu_PA_NO"
                value={cu_PA_NO}
                onChange={(Event) => onchangedata(Event)}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openParentPopup}
              ></i>
              )}
            </div>
          </div>
          {/* <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="cu_PA_NO" className="font-bold">
              Alternate Payor
            </label>
            <InputText
                id="cu_PA_NO"
                name="cu_PA_NO"
                value={cu_PA_NO}
                onChange={(Event) => onchangedata(Event)}
              />
          </div> */}
         
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_ST_PT" className="font-bold">
                    Statement Print
                  </label>
                  <Dropdown
                  id="cu_ST_PT"
                  name="cu_ST_PT"
                  value={cu_ST_PT}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData18("cu_ST_PT", e.value)}
                  placeholder="Select Statement Print"
            />
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_OD_AP" className="font-bold">
                   Overdue Hold
                  </label>
                  <Dropdown
              id="cu_OD_AP"
              name="cu_OD_AP"
              value={cu_OD_AP}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData8("cu_OD_AP", e.value)}
              placeholder="Select Overdue Hold"
            />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_OD_DY" className="font-bold">
                    Overdue Days
                  </label>
                  <InputText
                    id="cu_OD_DY"
                    name="cu_OD_DY"
                    value={cu_OD_DY}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
         
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_NT_FL" className="font-bold">
                    AP/AR Netting
                  </label>
                  <Dropdown
              id="cu_NT_FL"
              name="cu_NT_FL"
              value={cu_NT_FL}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData9("cu_NT_FL", e.value)}
              placeholder="Select AR/AP Netting"
            />
                </div>
                </div>
   
            </div>
          </TabPanel>
          {/* tab2 */}

          <TabPanel header="Credit & Collection">
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="cu_CR_MR" className="font-bold">
                  Credit Manager <span style={{ color: 'red' }}>*</span>
                </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="creditManager_Name"
                      name="creditManager_Name"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM2}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCreditManagerPopup}
                    ></i>
                    )}
                  </div>
                  <InputText style={{ display: "none" }}
                    id="cu_CR_MR"
                    name="cu_CR_MR"
                    value={cu_CR_MR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_CR_LT" className="font-bold">
                  Credit Limit
                  </label>
                  <InputText
                    id="cu_CR_LT"
                    name="cu_CR_LT"
                    value={cu_CR_LT}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="cu_CO_MR" className="font-bold">
                  Collection Manager  <span style={{ color: 'red' }}>*</span>
                </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="collectionManager_Name"
                      name="collectionManager_Name"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM1}
                    />
                      {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCollectionManagerPopup}
                    ></i>
                    )}
                  </div>
                  <InputText style={{ display: "none" }}
                    id="cu_CO_MR"
                    name="cu_CO_MR"
                    value={cu_CO_MR}
                    onChange={(Event) => onchangedata(Event)}
                  />
              </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_ST_CY" className="font-bold">
                  Statement Cycle
                  </label>
                  <Dropdown
                    name="cu_ST_CY"
                    value={cu_ST_CY}
                    options={cu_ST_CYOptions}
                    onChange={(e) => onchangeDropDownFormData19("cu_ST_CY", e.value)}
                    placeholder="Select Statement Cycle"

                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_DT_OP" className="font-bold">
                  Date of Opening
                  </label>
                  <Calendar
                    id="cu_DT_OP"
                    name="cu_DT_OP"
                    value={moment(data.cu_DT_OP, "YYYYMMDD").isValid() ? moment(data.cu_DT_OP, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "cu_DT_OP", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_PE_OP" className="font-bold">
                    Person Opening  <span style={{ color: 'red' }}>*</span>
                  </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM3}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openPersonOpeningPopup}
              ></i>
               )}
            </div>
              <InputText style={{ display: "none" }}
                id="cu_PE_OP"
                name="cu_PE_OP"
                value={cu_PE_OP}
                onChange={(Event) => onchangedata(Event)}
              />
          </div>
        </div>
        </div>
          </TabPanel>
          <TabPanel header="Billing">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_BL_TY" className="font-bold">
                  Billing Type
                  </label>
                  <Dropdown
                    name="cu_BL_TY"
                    value={cu_BL_TY}
                    options={cu_BL_TYOptions}
                    onChange={(e) => onchangeDropDownFormData23("cu_BL_TY", e.value)}
                    placeholder="Select Billing Type"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_CR_NO" className="font-bold">
                    Carrier  <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="carrier_Name"
                      name="carrier_Name"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM4}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCarrierPopup}
                    ></i>
                    )}
                  </div>
                    <InputText style={{ display: "none" }}
                      id="cu_CR_NO"
                      name="cu_CR_NO"
                      value={cu_CR_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_PR_AG" className="font-bold">
                  Adjustment Schedule
                  </label>
                  <Dropdown
                    name="cu_PR_AG"
                    value={cu_PR_AG}
                    options={cu_PR_AGOptions}
                    onChange={(e) => onchangeDropDownFormData10("cu_PR_AG", e.value)}
                    placeholder="Select Adjustment Schedule"

                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TR_DY" className="font-bold">
                  Transit Days
                  </label>
                  <InputText
                    id="cu_TR_DY"
                    name="cu_TR_DY"
                    value={cu_TR_DY}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_AP_FR" className="font-bold">
                  Apply Freight
                  </label>
                  <Dropdown
                  id="cu_AP_FR"
                  name="cu_AP_FR"
                  value={cu_AP_FR}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData20("cu_AP_FR", e.value)}
                  placeholder="Select Apply Freight"
            />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_FR_HC" className="font-bold">
                  Freight Handlling
                  </label>
                  <Dropdown
                    name="cu_FR_HC"
                    value={cu_FR_HC}
                    options={cu_FR_HCOptions}
                    onChange={(e) => onchangeDropDownFormData11("cu_FR_HC", e.value)}
                    placeholder="Select Freight Handling"

                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_PR_GP" className="font-bold">
                  Price group
                  </label>
                  <Dropdown
                    name="cu_PR_GP"
                    value={cu_PR_GP}
                    options={cu_PR_GPOptions}
                    onChange={(e) => onchangeDropDownFormData22("cu_PR_GP", e.value)}
                    placeholder="Select Price Group"

                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_DS_PR" className="font-bold">
                  Discount %
                  </label>
                  <InputText
                    id="cu_DS_PR"
                    name="cu_DS_PR"
                    value={cu_DS_PR}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_IT_RT" className="font-bold">
                  Item Restriction
                  </label>
                  <Dropdown
                  id="cu_IT_RT"
                  name="cu_IT_RT"
                  value={cu_IT_RT}
                  options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                  ]}
                  onChange={(e) => onchangeDropDownFormData21("cu_IT_RT", e.value)}
                  placeholder="Select Item Restriction"
            />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_RT_CD" className="font-bold">
                  Route Code
                  </label>
                  <Dropdown
                    name="cu_RT_CD"
                    value={cu_RT_CD}
                    options={cu_RT_CDOptions}
                    onChange={(e) => onchangeDropDownFormData12("cu_RT_CD", e.value)}
                    placeholder="Select Route Code"

                  />
                </div>
              </div>
              </div>
          </TabPanel>
          <TabPanel header="Tax">
            <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TX_AP" className="font-bold">
                   Tax Applicable
                  </label>
                  <Dropdown
                    id="cu_TX_AP"
                    name="cu_TX_AP"
                    value={cu_TX_AP}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                    ]}
                    onChange={(e) => onchangeDropDownFormData17("cu_TX_AP", e.value)}
                    placeholder="Select Tax Applicable"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TX_TY" className="font-bold">
                    Tax Type
                  </label>
                  <Dropdown
                    name="cu_TX_TY"
                    value={cu_TX_TY}
                    options={cu_TX_TYOptions}
                    onChange={(e) => onchangeDropDownFormData16("cu_TX_TY", e.value)}
                    placeholder="Select Tax Type"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TX_CD" className="font-bold">
                   Tax Code
                  </label>
                  <Dropdown
                    name="cu_TX_CD"
                    value={cu_TX_CD}
                    options={cu_TX_CDOptions}
                    onChange={(e) => {
                      const selectedValue = e.value;
                      onchangeDropDownFormData14("cu_TX_CD", selectedValue);
                      GetTaxCode(selectedValue);
                    }}
                    placeholder="Select Tax Code"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TX_RT" className="font-bold">
                   Tax Rate
                  </label>
                  <InputText disabled
                    id="cu_TX_RT"
                    name="cu_TX_RT"
                    defaultValue={cu_TX_RT}
                    onChange={(e) => onchangedata(e)}
                  /> 
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_TX_AR" className="font-bold">
                   Tax Area
                  </label>
                  <Dropdown
                    name="cu_TX_AR"
                    value={cu_TX_AR}
                    options={ cu_TX_AROptions}
                    onChange={(e) => onchangeDropDownFormData13("cu_TX_AR", e.value)}
                    placeholder="Select Tax Area"

                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cu_HL_CD" className="font-bold">
                    Hold Code
                  </label>
                  <Dropdown
                    name="cu_HL_CD"
                    value={cu_HL_CD}
                    options={ cu_HL_CDOptions}
                    onChange={(e) => onchangeDropDownFormData15("cu_HL_CD", e.value)}
                    placeholder="Select Hold Code"
                  />
                </div>
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div> 
      {/* customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* parent  */}
      <AddressBookPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* collection manager */}
      <AddressBookPopup
        visible={CollectionManagerPopupVisible}
        onClose={() => setCollectionManagerPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeMng}
        setFormData={setFormData}
      />
      {/* CREDIT MANAGER */}
      <AddressBookPopup
        visible={CreditManagerPopupVisible}
        onClose={() => setCreditManagerPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
        addressType={addressTypeMng}
        setFormData={setFormData}
      />
      {/* personopening */}
      <AddressBookPopup
        visible={PersonOpeningPopupVisible}
        onClose={() => setPersonOpeningPopupVisible(false)}
        onDataSelect={handleDataSelect4}
        formData={data}
        addressType={addressTypeMng}
        setFormData={setFormData}
      />
      {/* carrier */}
      <AddressBookPopup
        visible={CarrierPopupVisible}
        onClose={() => setCarrierPopupVisible(false)}
        onDataSelect={handleDataSelect5}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
