import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function OperationMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  om_CO_NO,
}) {
  const {
    omUkNo,
    om_OP_NM,
    om_OP_TY,
    om_OP_CG,
    

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  

  useEffect(() => {
    

  }, []);

  

const isUpdateMode = Boolean(omUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={omUkNo ? "Revise Operation Master" : "Add Operation Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="om_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="om_CO_NO"
                      name="om_CO_NO"
                      value={om_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="om_OP_NM" className="font-bold">
                    Operation Name
                    </label>
                    <InputText 
                      id="om_OP_NM"
                      name="om_OP_NM"
                      value={om_OP_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="om_OP_TY" className="font-bold">
                     Operation Type
                    </label>
                    <InputText 
                      id="om_OP_TY"
                      name="om_OP_TY"
                      value={om_OP_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="om_OP_CG" className="font-bold">
                     Operation Charge
                    </label>
                    <InputText 
                      id="om_OP_CG"
                      name="om_OP_CG"
                      value={om_OP_CG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                </div>
            </div>
      </div>
    </Dialog>
  );
}
