import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import JobMasterPopup from "../Popups/JobMasterPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function JobBudgetDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  JobBudgetTableData,
  setJobBudgetTableData,
  setad_AD_TY,
  Selectedjob,
  setSelectedjob,
  SelectedDesc,
  setselectedDesc,
  jb_CO_NO,
}) {
  const {
    jb_UK_NO,
    jbPrNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [JobVisible, setJobVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleJob =async (data) => {
    setSelectedjob(data.jm_PR_NO)
    setselectedDesc(data.jm_PR_DS)
    onchangeFormData("jbPrNo", data.jm_PR_NO);
    onchangeFormData("jobDES", data.jm_PR_DS);
    const Url = `${domain}/api/AccountMaster/GetByAccountMasterByCostCenter`;
    const requestData={
      "am_CO_NM":data.jm_PR_NO,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
  
      if (dataLead && dataLead.length > 0) {
        setJobBudgetTableData(dataLead.map(item => ({
          jb_AC_ID: item.am_AC_ID,
          jbObAc: item.am_OB_AC,
          jbObSb: item.am_OB_SB,
          jbLvDt: item.am_AC_LD,
          // jb_CA_MD: item.ja_CA_MD,
          jb_AC_DS: item.am_AC_DS,
          jb_PE_CD: item.am_PE_CD,
          jbCoCt: item.am_CO_NM,
        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setJobVisible(false);
    setShowSearchIcon(false);
  };
  const openJobPopup = (e) => {
    setJobVisible(true);
    setSelectedRowIndex(e);
  };
  const onchangeFormUnitPrice = async(e,index) => {
    handleUnit(index);
  };
  const onchangeFormUnit = async(e,index) => {
    handleAmount(index);
  };
  const handleUnit =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`jb_BU_UT_${SelectedRowIndex}`).value,
      "add_2":document.getElementById (`jb_RV_UT_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`jb_TO_UT_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
   
  };
  const handleAmount =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`jb_BU_AM_${SelectedRowIndex}`).value,
      "add_2":document.getElementById (`jb_RV_AM_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`jb_TO_AM_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
   
  };
  const addRow = () => {
    setJobBudgetTableData([...JobBudgetTableData, {}]);
  };
  const deleteRow = () => {
    if (JobBudgetTableData.length > 0) {
      const updatedData = [...JobBudgetTableData];
      updatedData.pop();
      setJobBudgetTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(jb_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jb_UK_NO ? "Revise Job Budget" : "Add Job Budget"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle}>
            <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="jbPrNo" className="font-bold">
                  Job No <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <InputText 
                      disabled={!!isUpdateMode}
                        id="jbPrNo"
                        name="jbPrNo"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={Selectedjob}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openJobPopup}
                      ></i>
                      )}
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="jobDES" className="font-bold">
                    Description
                    </label>
                    <InputText disabled
                      id="jobDES"
                      name="jobDES"
                      value={SelectedDesc}
                    readOnly
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="jb_CO_NO" className="font-bold">
                Company
                    </label>
                    <InputText disabled
                      id="jb_CO_NO"
                      name="jb_CO_NO"
                      value={jb_CO_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
              </div>
                    
            </div>
          </div>  
          <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="JobBudgettable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Account Id</th>
              <th>Cost Centre</th>
              <th>Account Object</th>
              <th>Subsidiary</th>
              <th>Account Description</th>
              <th>Level of Detail</th>
              <th>Post Code</th>
              <th>Calc-Method</th>
              <th>Budgeted Units</th>
              <th>Budgeted Amount</th>
              <th>Revision Units</th>
              <th>Revision Amount</th>
              <th>Total Units</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {JobBudgetTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`jb_UK_NO_${index}`}
                    name={`jb_UK_NO_${index}`}
                    value={row.jb_UK_NO}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`jb_AC_ID_${index}`}
                    name={`jb_AC_ID_${index}`}
                    defaultValue={row.jb_AC_ID}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jbCoCt_${index}`}
                    name={`jbCoCt_${index}`}
                    defaultValue={row.jbCoCt}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jbObAc_${index}`}
                    name={`jbObAc_${index}`}
                    defaultValue={row.jbObAc}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`jbObSb_${index}`}
                    name={`jbObSb_${index}`}
                    defaultValue={row.jbObSb}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jb_AC_DS_${index}`}
                    name={`jb_AC_DS_${index}`}
                    defaultValue={row.jb_AC_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jbLvDt_${index}`}
                    name={`jbLvDt_${index}`}
                    defaultValue={row.jbLvDt}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`jb_PE_CD_${index}`}
                    name={`jb_PE_CD_${index}`}
                    defaultValue={row.jb_PE_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`jb_CA_MD_${index}`}
                    name={`jb_CA_MD_${index}`}
                    defaultValue={row.jb_CA_MD}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`jb_BU_UT_${index}`}
                    name={`jb_BU_UT_${index}`}
                    defaultValue={row.jb_BU_UT}
                    onChange={(e) => {
                      onchangeFormUnitPrice(e, index);
                    }}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`jb_BU_AM_${index}`}
                    name={`jb_BU_AM_${index}`}
                    defaultValue={row.jb_BU_AM}
                    onChange={(e) => {
                      onchangeFormUnit(e, index);
                    }}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`jb_RV_UT_${index}`}
                    name={`jb_RV_UT_${index}`}
                    defaultValue={row.jb_RV_UT}
                    onChange={(e) => {
                      onchangeFormUnitPrice(e, index);
                    }}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`jb_RV_AM_${index}`}
                    name={`jb_RV_AM_${index}`}
                    defaultValue={row.jb_RV_AM}
                    onChange={(e) => {
                      onchangeFormUnit(e, index);
                    }}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jb_TO_UT_${index}`}
                    name={`jb_TO_UT_${index}`}
                    defaultValue={row.jb_TO_UT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`jb_TO_AM_${index}`}
                    name={`jb_TO_AM_${index}`}
                    defaultValue={row.jb_TO_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
              </tr>
            ))}
          </tbody>
        </table>
        </div>
          </div>
        </div>
      <JobMasterPopup
        visible={JobVisible}
        onClose={() => setJobVisible(false)}
        onDataSelect={handleJob}
        formData={data}
      />
    </Dialog>
  );
}
