import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function IpdQueueDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  IpdQueueTable,
  setIpdQueueTable,
  IpdQueueTable1,
  setIpdQueueTable1,
  handleConfirm,


  onchangeDropDownFormDataStatus,

}) {
  const {
    idUkNo,
    id_VI_NO,
    id_PA_ID,
    id_PA_NM,
    pt_UK_NO,
    pt_MD_NM,
    pt_TR_DT,
    pt_TR_TM,

  } = data;

  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, footergridstyle, footerfieldstyle,Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sd_DA_TE", value: moment(e.value).format("YYYYMMDD") } });

  };




  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");




  useEffect(() => {

  }, []);




  const addRow = () => {
    setIpdQueueTable([...IpdQueueTable, {}]);
  };
  const deleteRow = () => {
    if (IpdQueueTable.length > 0) {
      const updatedData = [...IpdQueueTable];
      updatedData.pop();
      setIpdQueueTable(updatedData);
    }
  };
  const addRow1 = () => {
    setIpdQueueTable1([...IpdQueueTable1, {}]);
  };
  const deleteRow1 = () => {
    if (IpdQueueTable1.length > 0) {
      const updatedData1 = [...IpdQueueTable1];
      updatedData1.pop();
      setIpdQueueTable1(updatedData1);
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(idUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );

  const onChangeOfPrice = async (e) => {
    //const table = document.getElementById("RentalContractTable");
    //const totalRowCount = table.tBodies[0].rows.length;
    // rentalContracts = [];
    const table = document.getElementById("IpdQueueTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const IpdQueueList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        idUkNo: document.getElementById("idUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      IpdQueueList.push(details);
    }
    const requestBodyNet = {
      IpdQueueList,
    };

    const response = await axios.post(`${domain}/api/IpdQueue/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("F_EXT_PRICE").value = respNet.f_EXT_PRICE;
      document.getElementById("F_EXT_COST").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
    // DIALOG HEADER
   
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{idUkNo ? "Revise Time Card Interface" : "Add Time Card Interface"}</h5>
        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
      </div>
    );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
      <div style={borderbox} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="idUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="idUkNo"
                    name="idUkNo"
                    defaultValue={idUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_VI_NO" className="font-bold">
                    Visit No
                  </label>
                  <InputText disabled
                    id="id_VI_NO"
                    name="id_VI_NO"
                    value={id_VI_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_PA_ID" className="font-bold">
                    Patient ID
                  </label>
                  <InputText disabled
                    id="id_PA_ID"
                    name="id_PA_ID"
                    value={id_PA_ID}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="id_PA_NM" className="font-bold">
                    Name
                  </label>
                  <InputText disabled
                    id="id_PA_NM"
                    name="id_PA_NM"
                    value={id_PA_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>

          <TabPanel header=" Treatment">

            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >

                {(
                  <Button icon="pi pi-plus" onClick={addRow1} style={plusstyle} tooltip="Add" />
                )}

                {(
                  <Button icon="pi pi-minus" onClick={deleteRow1} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="IpdQueueTable1">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Treatment</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {IpdQueueTable1.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`pt_UK_NO_${index}`}
                            name={`pt_UK_NO_${index}`}
                            defaultValue={row.pt_UK_NO}
                          />
                        </td>
                        <td >
                          <InputText
                            id={`pt_MD_NM_${index}`}
                            name={`pt_MD_NM_${index}`}
                            defaultValue={row.pt_MD_NM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Calendar
                            id={`pt_TR_DT_${index}`}
                            name={`pt_TR_DT_${index}`}
                            value={row.pt_TR_DT ? moment(row.pt_TR_DT, "YYYYMMDD").toDate() : null}
                            onChange={(e) => {
                              onDateChange("pt_TR_DT", index, e.value);
                              //updateTotalHours(index);
                            }}
                            dateFormat="dd/mm/yy"
                          />
                        </td>

                        <td>
                          <InputMask
                            id={`pt_TR_TM_${index}`}
                            name={`pt_TR_TM_${index}`}
                            mask="99:99:99"
                            value={moment(row.pt_TR_TM, "HHmmss").format("HH:mm:ss")}
                            onBlur={(e) => {
                              onChangeTime("pt_TR_TM", e.target.value, index);
                              // handleTimeChange(index);
                            }}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pt_ST_CD_${index}`}
                            name={`pt_ST_CD_${index}`}
                            value={data[`pt_ST_CD_${index}`] ? data[`pt_ST_CD_${index}`] : row.pt_ST_CD}
                            options={[
                              { label: "Yes", value: "y" },
                              { label: "No", value: "n" },
                            ]}
                            onChange={(e) => onchangeDropDownFormDataStatus(`pt_ST_CD_${index}`, e.value)}
                            placeholder=" Status"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </TabPanel>
          <TabPanel header=" TPR BP">

            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >

                {(
                  <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
                )}

                {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="TpBpList">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Pulse</th>
                      <th>BP</th>
                      <th>Intake</th>
                      <th>Output</th>
                    </tr>
                  </thead>
                  <tbody>
                    {IpdQueueTable.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`tb_UK_NO_${index}`}
                            name={`tb_UK_NO_${index}`}
                            defaultValue={row.tb_UK_NO}
                          />
                        </td>
                        <td>
                          <Calendar
                            id={`tb_TR_DT_${index}`}
                            name={`tb_TR_DT_${index}`}
                            value={row.tb_TR_DT ? moment(row.tb_TR_DT, "YYYYMMDD").toDate() : null}
                            onChange={(e) => {
                              onDateChange("tb_TR_DT", index, e.value);
                              //updateTotalHours(index);
                            }}
                            dateFormat="dd/mm/yy"
                          />
                        </td>

                        <td>
                          <InputMask
                            id={`tb_TR_TM_${index}`}
                            name={`tb_TR_TM_${index}`}
                            mask="99:99:99"
                            value={moment(row.tb_TR_TM, "HHmmss").format("HH:mm:ss")}
                            onBlur={(e) => {
                              onChangeTime("tb_TR_TM", e.target.value, index);
                              // handleTimeChange(index);
                            }}
                          />
                        </td>
                        <td >
                          <InputText
                            id={`tb_PL_SE_${index}`}
                            name={`tb_PL_SE_${index}`}
                            defaultValue={row.tb_PL_SE}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText
                            id={`tb_BL_PR_${index}`}
                            name={`tb_BL_PR_${index}`}
                            defaultValue={row.tb_BL_PR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText
                            id={`tb_IN_TK_${index}`}
                            name={`tb_IN_TK_${index}`}
                            defaultValue={row.tb_IN_TK}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText
                            id={`tb_OU_PT_${index}`}
                            name={`tb_OU_PT_${index}`}
                            defaultValue={row.tb_OU_PT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

          </TabPanel>
        </TabView>
      </div>
    </Dialog>

  );
}

