import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CampaignDialog from "./CampaignDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  cpUkNo:"",
  cp_CP_NM:"",
  cp_BI_NG:"",
  cp_AC_ON:"",
  cp_LO_CN:"",
  cp_LN_GE:"",
  cp_DM_PH:"",
  cp_ST_DT:"",
  cp_EN_DT:"",
  cp_BU_GT:"",
  cp_IM_ON:"",
  cp_CL_CK:"",
  cp_CN_CT:"",
  cp_RT_IV:"",
  cp_CV_RT:"",
  cp_CT_PL:"",
  cp_FB_OB:"",
  cp_BU_TY:"",
  cp_BD_AM:"",
  cp_DA_BU:"",
  cp_CA_CY:"",
  cp_FB_PO:"",
  cp_CA_ME:"",
  cp_WE_LI:"",
  cp_OP_GO:"",
  cp_BI_EV:"",
  cp_ST_AT:"",
  cp_CA_AC:"",
  cp_PR_PO:"",
  cp_FO_WU:"",

  cp_ST_TM:"",
  cp_EN_TM:"",
  cp_MX_AG:"",
  cp_MI_AG:"",
  cp_GE_DR:"",
  cp_DS_UN:"",
  cp_RA_DI:"",
  cp_PL_FB:"",
  cp_PL_IN:"",
  cp_PL_AN:"",
  cp_PL_MS:"",
  cp_CI_TY:"",
  cp_RE_GN:"",
  cp_BD_TY:"",
};

const initialFilters = {
  cp_CP_NM: { value: "", operation: "" },
  cp_BI_NG: { value: "", operation: "" },
  cp_AC_ON: { value: "", operation: "" },
  cp_LO_CN: { value: "", operation: "" },
  cp_LN_GE: { value: "", operation: "" },
  cp_DM_PH: { value: "", operation: "" },
  cp_ST_DT: { value: "", operation: "" },
  cp_EN_DT: { value: "", operation: "" },
  cp_BU_GT: { value: "", operation: "" },
  cp_IM_ON: { value: "", operation: "" },
  cp_CL_CK: { value: "", operation: "" },
  cp_CN_CT: { value: "", operation: "" },
};

const CampaignMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,refreshButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [QuenstionTableData, setQuenstionTableData] = useState([{}]);
  const [AnswerTableData, setAnswerTableData] = useState([{}]);
// drodpdowns
  const [cp_CO_NO , setcp_CO_NO] = useState("");
  const [cp_BI_NG, setcp_BI_NG ] = useState("");
  const [cp_AC_ON, setcp_AC_ON ] = useState("");
  const [cp_LO_CN, setcp_LO_CN ] = useState("");
  const [cp_LN_GE, setcp_LN_GE ] = useState("");
  const [cp_DM_PH, setcp_DM_PH ] = useState("");
  const [cp_FB_OB, setcp_FB_OB ] = useState("");
  const [cp_BU_TY, setcp_BU_TY ] = useState("");
  const [cp_CA_CY, setcp_CA_CY ] = useState("");
  const [cp_FB_PO, setcp_FB_PO ] = useState("");
  const [cp_BU_GT, setcp_BU_GT ] = useState("");
  const [cp_OP_GO, setcp_OP_GO ] = useState("");
  const [cp_BI_EV, setcp_BI_EV ] = useState("");
  const [cp_ST_AT, setcp_ST_AT ] = useState("");
  const [cp_CA_AC, setcp_CA_AC ] = useState("");
  const [cp_CP_NO, setcp_CP_NO ] = useState("");
  const [cp_CP_TY, setcp_CP_TY ] = useState("");
  const [cq_TY_PE, setcq_TY_PE ] = useState("");
  const [cq_QU_TN, setcq_QU_TN ] = useState("");
  const [cp_GE_DR, setcp_GE_DR ] = useState("");
  const [cp_CI_TY, setcp_CI_TY ] = useState("");
  const [cp_RE_GN, setcp_RE_GN ] = useState("");
  const [cp_DS_UN, setcp_DS_UN ] = useState("");
  const [cp_PL_FB, setcp_PL_FB ] = useState("");
  const [cp_PL_IN, setcp_PL_IN ] = useState("");
  const [cp_PL_AN, setcp_PL_AN ] = useState("");
  const [cp_PL_MS, setcp_PL_MS ] = useState("");
  const [cp_BD_TY, setcp_BD_TY ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setQuenstionTableData([{}]);
    setAnswerTableData([{}]);
    setcp_BI_NG(null);
    setcp_AC_ON(null);
    setcp_LO_CN(null);
    setcp_LN_GE(null);
    setcp_DM_PH(null);
    setcp_FB_OB(null);
    setcp_BU_TY(null);
    setcp_CA_CY(null);
    setcp_FB_PO(null);
    setcp_OP_GO(null);
    setcp_BI_EV(null);
    setcp_ST_AT(null);
    setcp_CA_AC(null);
    setcp_BU_GT(null);
    setcp_GE_DR(null);
    setcp_CI_TY(null);
    setcp_RE_GN(null);
    setcp_DS_UN(null);
    setcp_PL_FB(null);
    setcp_PL_IN(null);
    setcp_PL_AN(null);
    setcp_PL_MS(null);
    setcp_BD_TY(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/Campaign/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CampaignData = response.data;
          delete CampaignData.cpUkNo;
          setFormData(CampaignData);
          setcp_BI_NG(CampaignData.cp_BI_NG);
          setcp_AC_ON(CampaignData.cp_AC_ON);
          setcp_LO_CN(CampaignData.cp_LO_CN);
          setcp_LN_GE(CampaignData.cp_LN_GE);
          setcp_DM_PH(CampaignData.cp_DM_PH);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
    ErpParameterAC();
    ErpParameterPG();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    setcp_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "CP_CP_TY"){
              setcp_CP_TY(dataDefault.versionIds[i].default_VALUE)
            }
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/Campaign/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/Campaign/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CampaignData = response.data;
        setFormData(CampaignData);
        setcp_BI_NG(CampaignData.cp_BI_NG);
        setcp_AC_ON(CampaignData.cp_AC_ON);
        setcp_LO_CN(CampaignData.cp_LO_CN);
        setcp_LN_GE(CampaignData.cp_LN_GE);
        setcp_DM_PH(CampaignData.cp_DM_PH);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [ac_ID, setac_ID] = useState("");
  const [page_ID, setpage_ID] = useState("");
  const ErpParameterAC = async ()=> {
      try {
        const inputParam = {
          "name" : 'FACEBOOK_ADS_ACCOUNT_ID'
        }
        const response = await axios.post(
          `${domain}/api/ErpParameters/GetByRole`, inputParam,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
    
        if (response.status === 200) {
          const data = response.data;
          if(data){
            setac_ID(response.data.value); 
          }
           
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const ErpParameterPG = async ()=> {
    try {
      const inputParam1 = {
        "name" : 'FACEBOOK_PAGEID'
      }
      const response = await axios.post(
        `${domain}/api/ErpParameters/GetByRole`, inputParam1,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        if(data){
          setpage_ID(response.data.value); 
        }
         
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onChangeQuestionData = (index, field, value) => {
    const updatedData = [...QuenstionTableData];
    updatedData[index][field] = value;
    setQuenstionTableData(updatedData);
  };


  var requestBody = {};

  function values() {
    var facebookinput = {};

    // Start date 
    const startDate = FormData.cp_ST_DT; 
    const startTime = FormData.cp_ST_TM;

    const currentYear = startDate.toString();
    const Year = currentYear.substring(0, 4);
    const months = currentYear.substring(4, 6);
    const  Month = months - 1
    const Day = currentYear.substring(6, 8);
    // Start time
    const currentTime = startTime.toString();
    const Hour = currentTime.substring(0, 2);
    const Minutes = currentTime.substring(2, 4);
    const Seconds = currentTime.substring(4, 6);
    const MilliSeconds = 0;
    const dateTimeString = `${Year}T${Month}T${Day}T${Hour}T${Minutes}T${Seconds}T${MilliSeconds}`;
    const date = new Date(dateTimeString);
    const timestamp = date.getTime();

    // nd date 
    const EndDate = FormData.cp_EN_DT; 
    const EndTime = FormData.cp_EN_TM;

    const EndYear = EndDate.toString();
    const YearE = EndYear.substring(0, 4);
    const monthsE = EndYear.substring(4, 6);
    const  MonthE = monthsE - 1
    const DayE = EndYear.substring(6, 8);
    // time
    const currentTimeE = EndTime.toString();
    const HourE = currentTimeE.substring(0, 2);
    const MinutesE = currentTimeE.substring(2, 4);
    const SecondsE = currentTimeE.substring(4, 6);
    const MilliSecondsE = 0;


    // new Date(year,month,day,hours,minutes,seconds,ms)

    const dateTimeStringE = `${YearE}T${MonthE}T${DayE}T${HourE}T${MinutesE}T${SecondsE}T${MilliSecondsE}`;
    const Enddate = new Date(dateTimeStringE);
    const timestampE = Enddate.getTime();
    
    const table = document.getElementById("CampaignQuenstionTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const campaignQuestionnaireList = [];
    const questions = [];
    var count = 0;
    for (let i = 0; i < totalRowCount; i++) {  
      count++;
      const key = "question" + count;
      const Details = {
        cq_TY_PE: table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        cq_QU_TN: document.getElementById("cq_QU_TN_" + i).value,
      }
      campaignQuestionnaireList.push(Details);
      const question = {
        "type": table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        "key": key,
        // "label": "Do you like rainbows?"
      }
      questions.push(question);
    }
    const objective = FormData.cp_FB_OB;
    if(objective.includes("LEAD")){
       facebookinput = {
        "accountId" : ac_ID,
        "campaignName" : document.getElementById("cp_CP_NM").value,
        "objective": FormData.cp_FB_OB,
        "buyingType": FormData.cp_BU_TY,
        "special_ad_categories" : ["NONE"],
        "pageId": page_ID,
        "bidAmount" : document.getElementById("cp_BD_AM").value,
        "dailyBudget" : document.getElementById("cp_DA_BU").value,
        "countries" : [FormData.cp_CA_CY],
        "facebookPositions" : [FormData.cp_FB_PO],
        // "facebookPositions" : [ "feed",
        //   "right_hand_column", "marketplace", "video_feeds", "story", "search", "instream_video", "facebook_reels", "profile_feed"
        // ],

        "message" : FormData.cp_CA_ME, 
        "link" : document.getElementById("cp_WE_LI").value,
        "optimizationGoal" : FormData.cp_OP_GO, 
        "billingEvent" : FormData.cp_BI_EV, 
        "status" : FormData.cp_ST_AT,
        "creativeId" : "120214815839120174", 
        "questions": questions,
        "privacyPolicy" : document.getElementById("cp_PR_PO").value,
        "followUpActionURL" : document.querySelector("#cp_FO_WU").value,
        "callToAction" : FormData.cp_CA_AC,

        "maxAge":document.getElementById("cp_MX_AG").value,
        "minAge": document.getElementById("cp_MI_AG").value,
        "genders": [FormData.cp_GE_DR],
        "endTime": timestampE,
        "startTime": timestamp,
        "regions": [
          {
            "key": FormData.cp_RE_GN
          }
        ] ,
        "cities": [
          {
            "key": FormData.cp_CI_TY,
            // "radius": document.getElementById("cp_RA_DI").value,
            // "distance_unit": FormData.cp_DS_UN,
          }
        ],
        "publisher_platforms": [
          FormData.cp_PL_FB,
          FormData.cp_PL_IN,
          FormData.cp_PL_AN,
          FormData.cp_PL_MS
        ],
        "budgetType" :FormData.cp_BD_TY,
        "budgetAmount":document.getElementById("cp_BU_GT").value,
       }
    } else if (objective.includes("AWARENESS")){
      const countryList = [];
      if(FormData.cp_CA_CY){
        countryList.push(FormData.cp_CA_CY);
      }
      const regionList = [];
      if(FormData.cp_RE_GN){
        const region = {
          "key": FormData.cp_RE_GN
        }
        regionList.push(region);
      }
      const cityList = [];
      if(FormData.cp_CI_TY){
        if(document.getElementById("cp_RA_DI").value){
          const city = {
            "key": FormData.cp_CI_TY,
            "radius": document.getElementById("cp_RA_DI").value,
            "distance_unit": FormData.cp_DS_UN,
          }
          cityList.push(city);
        } else {
          const city = {
            "key": FormData.cp_CI_TY
          }
          cityList.push(city);
        }
      }
      facebookinput = {
        "accountId" : ac_ID,
        "campaignName" : document.getElementById("cp_CP_NM").value,
        "objective": FormData.cp_FB_OB,
        "buyingType": FormData.cp_BU_TY,
        "special_ad_categories":[],
        "pageId": page_ID,
        "bidAmount" : document.getElementById("cp_BD_AM").value,
        "dailyBudget" : document.getElementById("cp_DA_BU").value,
        "countries" : countryList,
        "facebookPositions" : [FormData.cp_FB_PO],
        "message" : FormData.cp_CA_ME, 
        "link" : document.getElementById("cp_WE_LI").value,
        "optimizationGoal" : FormData.cp_OP_GO, 
        "billingEvent" : FormData.cp_BI_EV, 
        "status" : FormData.cp_ST_AT,
        "creativeId" : "120213823088030174",

        "maxAge":document.getElementById("cp_MX_AG").value,
        "minAge": document.getElementById("cp_MI_AG").value,
        "genders": [FormData.cp_GE_DR],
        "endTime": timestampE,
        "startTime": timestamp,
        "regions": regionList,
        "cities": cityList,
        "publisher_platforms": [
          FormData.cp_PL_FB,
          FormData.cp_PL_IN,
          FormData.cp_PL_AN,
          FormData.cp_PL_MS
        ],
        "budgetType" :FormData.cp_BD_TY,
        "budgetAmount":document.getElementById("cp_BU_GT").value,
       }
    }

    requestBody = {
      cpUkNo  : FormData.cpUkNo,
      cp_CP_NM: document.getElementById("cp_CP_NM").value,
      cp_CP_NO: document.getElementById("cp_CP_NO").value,
      cp_BI_NG: FormData.cp_BI_NG,
      cp_AC_ON: FormData.cp_AC_ON,
      // cp_LO_CN: FormData.cp_LO_CN,
      cp_LN_GE: FormData.cp_LN_GE,
      cp_DM_PH: FormData.cp_DM_PH,
      cp_ST_DT: FormData.cp_ST_DT,
      cp_EN_DT: FormData.cp_EN_DT,
      cp_ST_TM: FormData.cp_ST_TM,
      cp_EN_TM: FormData.cp_EN_TM,
      cp_BU_GT: document.getElementById("cp_BU_GT").value,
      cp_CT_PL: document.getElementById("cp_CT_PL").value,
      cp_CV_RT: document.getElementById("cp_CV_RT").value,
      cp_RT_IV: document.getElementById("cp_RT_IV").value,
      cp_IM_ON: document.getElementById("cp_IM_ON").value,
      cp_CL_CK: document.getElementById("cp_CL_CK").value,
      cp_CN_CT: document.getElementById("cp_CN_CT").value,

      cp_MX_AG: document.getElementById("cp_MX_AG").value,
      cp_MI_AG: document.getElementById("cp_MI_AG").value,
      cp_GE_DR: FormData.cp_GE_DR,
      cp_DS_UN: FormData.cp_DS_UN,
      cp_RA_DI: document.getElementById("cp_RA_DI").value,
      cp_PL_FB: FormData.cp_PL_FB,
      cp_PL_IN: FormData.cp_PL_IN,
      cp_PL_AN: FormData.cp_PL_AN,
      cp_PL_MS: FormData.cp_PL_MS,
      cp_CI_TY: FormData.cp_CI_TY,
      cp_RE_GN: FormData.cp_RE_GN,
      cp_CO_NO: company,

        // Ads fields
      "faceBookAdsDto" : facebookinput,
      "campaignQuestionnaireList":campaignQuestionnaireList,
    };
  };
  const handleFormSubmit = () => {
    values();
    console.log(requestBody);
    if (document.getElementById("cp_CP_NO").value) {
    if (FormData.cpUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/Campaign/Update`;
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      } 
    }else {
      axios.post(`${domain}/api/Campaign/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    } 
    }else {
      const ST_DT = FormData.cp_ST_DT;
      if(ST_DT){
        const currentYear = ST_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${cp_CP_TY}/${secondHalf}/${company}/${firstHalf}`;  
        const finalUrl1 = `${urlNextNumber}/${parameters}`;

        axios.get(finalUrl1, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
            setcp_CP_NO(resp.dn_DO_NO);
            document.getElementById("cp_CP_NO").value = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/Campaign/Add`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`,
                  "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      }
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".refresh-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Refresh
        </div>
      </Tooltip>
      <Button 
        style={refreshButtonStyles}
        icon="pi pi-refresh"
        outlined
        className="mr-2 refresh-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CP_NM"] ? filters["cp_CP_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CP_NM",
                        e.target.value,
                        filters["cp_CP_NM"]
                          ? filters["cp_CP_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BI_NG"] ? filters["cp_BI_NG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BI_NG",
                        e.target.value,
                        filters["cp_BI_NG"] ? filters["cp_BI_NG"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_AC_ON"] ? filters["cp_AC_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_AC_ON",
                        e.target.value,
                        filters["cp_AC_ON"]
                          ? filters["cp_AC_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_LN_GE"] ? filters["cp_LN_GE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_LN_GE",
                        e.target.value,
                        filters["cp_LN_GE"]
                          ? filters["cp_LN_GE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_DM_PH"] ? filters["cp_DM_PH"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_DM_PH",
                        e.target.value,
                        filters["cp_DM_PH"]
                          ? filters["cp_DM_PH"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_ST_DT"] ? filters["cp_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_ST_DT",
                        e.target.value,
                        filters["cp_ST_DT"] ? filters["cp_ST_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_EN_DT"] ? filters["cp_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_EN_DT",
                        e.target.value,
                        filters["cp_EN_DT"]
                          ? filters["cp_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_BU_GT"] ? filters["cp_BU_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_BU_GT",
                        e.target.value,
                        filters["cp_BU_GT"]
                          ? filters["cp_BU_GT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_IM_ON"] ? filters["cp_IM_ON"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_IM_ON",
                        e.target.value,
                        filters["cp_IM_ON"]
                          ? filters["cp_IM_ON"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CL_CK"] ? filters["cp_CL_CK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CL_CK",
                        e.target.value,
                        filters["cp_CL_CK"]
                          ? filters["cp_CL_CK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cp_CN_CT"] ? filters["cp_CN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cp_CN_CT",
                        e.target.value,
                        filters["cp_CN_CT"]
                          ? filters["cp_CN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Campaign Name</th>
                <th style={tableHeaderStyle}>Bidding Type</th>
                <th style={tableHeaderStyle}>Acquisition</th>
                <th style={tableHeaderStyle}>Language</th>
                <th style={tableHeaderStyle}>Demographic</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Budget</th>
                <th style={tableHeaderStyle}>Impression</th>
                <th style={tableHeaderStyle}>Clicks</th>
                <th style={tableHeaderStyle}>Contacts</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.cpUkNo}
                    style={{  backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.cpUkNo}
                        checked={selectedRows.includes(rowData.cpUkNo)}
                        onChange={() => handleRowSelect(rowData.cpUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cp_CP_NM}</td>
                    <td style={tableCellStyle}>{rowData.cp_BI_NG}</td>
                    <td style={tableCellStyle}>{rowData.cp_AC_ON}</td>
                    <td style={tableCellStyle}>{rowData.cp_LN_GE}</td> 
                    <td style={tableCellStyle}>{rowData.cp_DM_PH}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cp_ST_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cp_EN_DT)}</td>
                    <td style={tableCellStyle}>{rowData.cp_BU_GT}</td>
                    <td style={tableCellStyle}>{rowData.cp_IM_ON}</td> 
                    <td style={tableCellStyle}>{rowData.cp_CL_CK}</td>
                    <td style={tableCellStyle}>{rowData.cp_CN_CT}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <CampaignDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        cp_CO_NO={cp_CO_NO}
        cp_BI_NG={cp_BI_NG}
        setcp_BI_NG={setcp_BI_NG}
        cp_AC_ON={cp_AC_ON}
        setcp_AC_ON={setcp_AC_ON}
        cp_LO_CN={cp_LO_CN}
        setcp_LO_CN={setcp_LO_CN}
        cp_LN_GE={cp_LN_GE}
        setcp_LN_GE={setcp_LN_GE}
        cp_DM_PH={cp_DM_PH}
        setcp_DM_PH={setcp_DM_PH}
        cp_FB_OB={cp_FB_OB}
        setcp_FB_OB={setcp_FB_OB}
        cp_BU_TY={cp_BU_TY}
        setcp_BU_TY={setcp_BU_TY}
        cp_CA_CY={cp_CA_CY}
        setcp_CA_CY={setcp_CA_CY}
        cp_FB_PO={cp_FB_PO}
        setcp_FB_PO={setcp_FB_PO}
        cp_BU_GT={cp_BU_GT}
        setcp_BU_GT={setcp_BU_GT}
        cp_GE_DR={cp_GE_DR}
        setcp_GE_DR={setcp_GE_DR}
        cp_OP_GO={cp_OP_GO}
        setcp_OP_GO={setcp_OP_GO}
        cp_BI_EV={cp_BI_EV}
        setcp_BI_EV={setcp_BI_EV}
        cp_ST_AT={cp_ST_AT}
        setcp_ST_AT={setcp_ST_AT}
        cp_CA_AC={cp_CA_AC}
        setcp_CA_AC={setcp_CA_AC}
        cp_RE_GN={cp_RE_GN}
        setcp_RE_GN={setcp_RE_GN}
        cp_PL_FB={cp_PL_FB}
        setcp_PL_FB={setcp_PL_FB}
        cp_PL_IN={cp_PL_IN}
        setcp_PL_IN={setcp_PL_IN}
        cp_PL_MS={cp_PL_MS}
        setcp_PL_MS={setcp_PL_MS}
        cp_PL_AN={cp_PL_AN}
        setcp_PL_AN={setcp_PL_AN}
        cp_CI_TY={cp_CI_TY}
        setcp_CI_TY={setcp_CI_TY}
        cp_CP_NO={cp_CP_NO}
        cp_CP_TY={cp_CP_TY}
        cq_QU_TN={cq_QU_TN}
        setcq_QU_TN={setcq_QU_TN}
        cq_TY_PE={cq_TY_PE}
        setcq_TY_PE={setcq_TY_PE}
        cp_DS_UN={cp_DS_UN}
        setcp_DS_UN={setcp_DS_UN}
        cp_BD_TY={cp_BD_TY}
        setcp_BD_TY={setcp_BD_TY}
        QuenstionTableData={QuenstionTableData}
        setQuenstionTableData={setQuenstionTableData}
        AnswerTableData={AnswerTableData}
        setAnswerTableData={setAnswerTableData}
        onChangeQuestionData={onChangeQuestionData}
      />
    </div>
  );
};

export default CampaignMain;
