import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import SupplierhPopup from "./SupplierhPopup";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function VoucherMatchDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  FormData,
  SelectedemEMNO,
  selectedAbAdNN,
  setSelectedAbAdNN,
  setSelectedemEMNO,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  addRow,
  deleteRow,
  VoucherMatchtable,
  price_AMOUNT,
  net_AMOUNT,
  tax_AMOUNT,
  total,
  fr_PRICE_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  fr_TOTAL,
}) {
  const { 
  prUkNo,
  pr_OR_NO,
  pr_OR_TY,
  pr_SU_NO,
  pr_OR_CO,
  pr_IN_NO,
  pr_TR_CR,
  pr_EX_RT,
  pr_BS_CR,
  pr_RC_AT,
  pr_TX_AM,
  pr_RC_DT,
  // pr_TR_DT,
  pr_GL_DT,
  pr_IN_DT,
  pr_DO_NO,
  pr_DO_TY,
  pr_TR_UM,
  pr_RC_QT,
  pr_ST_CD,
  pr_FR_AT,
  pr_CO_CT,
  pr_TR_DT,
  inv_AMOUNT,
  } = data;
  const [pr_TR_CROptions, setpr_TR_CROptions] = useState([]);
  const [pr_TR_UMOptions, setpr_TR_UMOptions] = useState([]);
  const [pr_CO_CTOptions, setpr_CO_CTOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const { footergridstyle,footerfieldstyle,borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,
    plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle, } = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;
  const parameter2 = "IN/UM";
  const finalUrl2 = `${url}/${parameter2}`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl3 = `${urlcost}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpr_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpr_TR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  //3nd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
      value: item.cc_CC_NM,
      }));
      setpr_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
  const openSupplierhPopup = (e) => {
    setSupplierhPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("pr_SU_NO", data.abAdNo);
    setSupplierhPopupVisible(false);
  };
  const handleDataSelect2 = async(data) => {
    setSelectedAbAdNN(data.im_IT_DS);
    onchangeFormData(`itemDES_${SelectedRowIndex}`, data.im_IT_DS);
    onchangeFormData(`pr_IT_NM_${SelectedRowIndex}`, data.im_IT_NM);
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
const isUpdateMode = Boolean(prUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{prUkNo ? "Revise  Match Voucher" : "Add  Match Voucher"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_OR_NO" className="font-bold">
                Order No
                </label>
                <InputText disabled
                  id="pr_OR_NO"
                  name="pr_OR_NO"
                  value={pr_OR_NO}
                  onChange={(e) => onchangedata(e)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_OR_TY" className="font-bold">
                Type
                </label>
                <InputText disabled
                  id="pr_OR_TY"
                  name="pr_OR_TY"
                  value={pr_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
                    
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_OR_CO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="pr_OR_CO"
                  name="pr_OR_CO"
                  value={pr_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RC_DT" className="font-bold">
              Receipt Date
              </label>
              <Calendar disabled
                id="pr_RC_DT"
                name="pr_RC_DT"
                value={moment(pr_RC_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "pr_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
                />
                </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pr_GL_DT" className="font-bold">
            GL Date
            </label>
            <Calendar 
              id="pr_GL_DT"
              name="pr_GL_DT"
              value={moment(pr_GL_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "pr_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_IN_DT" className="font-bold">
              Invoice Date
              </label>
              <Calendar 
                id="pr_IN_DT"
                name="pr_IN_DT"
                value={moment(pr_IN_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "pr_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
                />
                </div>
            
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pr_SU_NO" className="font-bold">
            Supplier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
              id="name"
              name="name"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNO}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              )}
              </div>
              <div style={{ display: "none" }}>
              <InputText
              id="pr_SU_NO"
              name="pr_SU_NO"
              value={pr_SU_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="auto_POST" className="font-bold">
              Auto Post
              </label>
              <InputText disabled
                      id="auto_POST"
                      name="auto_POST"
                      value={"Yes"}
              />
            </div>
              
            
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_TR_CR" className="font-bold">
              Currency
              </label>
              <Dropdown disabled
              id="pr_TR_CR"
              name="pr_TR_CR"
              value={pr_TR_CR}
              options={pr_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData1("pr_TR_CR", e.value)}
              placeholder="Select Currency"
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="pr_EX_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
              id="pr_EX_RT"
              name="pr_EX_RT"
              value={pr_EX_RT}
              onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="pr_BS_CR" className="font-bold">
            Base Currency
            </label>
            <InputText disabled
            id="pr_BS_CR"
            name="pr_BS_CR"
            value={pr_BS_CR}
            onChange={(e) => onchangedata(e)}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="inv_AMOUNT" className="font-bold">
            Invoice Amount
              </label>
              <InputText disabled
                id="inv_AMOUNT"
                name="inv_AMOUNT"
                value={inv_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_TX_AM" className="font-bold">
            Tax Amount
              </label>
              <InputText disabled
                id="pr_TX_AM"
                name="pr_TX_AM"
                value={pr_TX_AM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_IN_NO" className="font-bold">
            Supplier Invoice
              </label>
              <InputText
                id="pr_IN_NO"
                name="pr_IN_NO"
                value={pr_IN_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            
            
            
          
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="VoucherMatchtable" style={{overflow:"auto"}}>
        <thead>
          <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Line No</th>
          <th>Document No</th>
          <th>Type</th>
          <th style={{ display: "none" }}>Item Name</th>
          <th>Item Name <span style={{ color: 'red', }}>*</span></th>
          <th>Receipt Quantity</th>
          <th>UM</th>
          <th>Location</th>
          <th>LOT No</th>
          <th>Receipt Amount</th>
          <th>Open Quantity</th>
          <th>Open Amount</th>
          <th>Tax %</th>
          <th>Tax Amount</th>
          <th>Fore.Tax Amount</th>
          <th>Cost Centre</th>
          <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {VoucherMatchtable.map((row, index) => (
                <tr key={index}>
                <td style={{ display: "none" }} >
                <InputText
                  id={`prUkNo_${index}`}
                  name={`prUkNo_${index}`}
                  defaultValue={row.prUkNo}
                />
              </td>
              <td>
                <InputText disabled style={{width:"10rem"}}
                id={`pr_LN_NO_${index}`}
                name={`pr_LN_NO_${index}`}
                defaultValue={row.pr_LN_NO}
              />
              </td>
                <td>
                <InputText disabled style={{width:"10rem"}}
                id={`pr_DO_NO_${index}`}
                name={`pr_DO_NO_${index}`}
                defaultValue={row.pr_DO_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_DO_TY_${index}`}
                name={`pr_DO_TY_${index}`}
                defaultValue={row.pr_DO_TY}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td style={{ display: "none" }}> 
              <InputText disabled
              id={`pr_IT_NM_${index}`}
              name={`pr_IT_NM_${index}`}
              value={row.pr_IT_NM ? row.pr_IT_NM: data[`${"pr_IT_NM"}_${index}`]}
              onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <div style={{ display: "flex",width:"15rem"}}>
              <InputText disabled
              id={`itemDES_${index}`}
              name={`itemDES_${index}`}
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={row.itemDES ? row.itemDES : data[`${"itemDES"}_${index}`]}
              />
              {showSearchIcon && (
               <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "7%",
              cursor: "pointer",
              }}
              onClick={(e) => openItemPopup(index)}
              ></i>
              )}
              </div>
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_RC_QT_${index}`}
                name={`pr_RC_QT_${index}`}
                defaultValue={row.pr_RC_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown disabled style={{width:"7rem"}}
                id={`pr_TR_UM_${index}`}
                name={`pr_TR_UM_${index}`}
                value={ data[`pr_TR_UM_${index}`] ? data[`pr_TR_UM_${index}`]: row.pr_TR_UM}
                options={pr_TR_UMOptions}
                onChange={(e) =>handleFormDataChange(`pr_TR_UM_${index}`, e.value)}
                placeholder="Select UM"
               />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_LO_CN_${index}`}
                name={`pr_LO_CN_${index}`}
                defaultValue={row.pr_LO_CN}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_LT_NO_${index}`}
                name={`pr_LT_NO_${index}`}
                defaultValue={row.pr_LT_NO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_RC_AT_${index}`}
                name={`pr_RC_AT_${index}`}
                defaultValue={row.pr_RC_AT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_OP_QT_${index}`}
                name={`pr_OP_QT_${index}`}
                defaultValue={row.pr_OP_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_OP_AT_${index}`}
                name={`pr_OP_AT_${index}`}
                defaultValue={row.pr_OP_AT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"7rem"}}
                id={`pr_TX_PE_${index}`}
                name={`pr_TX_PE_${index}`}
                defaultValue={row.pr_TX_PE}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_TX_AM_${index}`}
                name={`pr_TX_AM_${index}`}
                defaultValue={row.pr_TX_AM}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_FR_AT_${index}`}
                name={`pr_FR_AT_${index}`}
                defaultValue={row.pr_FR_AT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown disabled style={{width:"7rem"}}
                id={`pr_CO_CT_${index}`}
                name={`pr_CO_CT_${index}`}
                value={data[`pr_CO_CT_${index}`] ? data[`pr_CO_CT_${index}`]:row.pr_CO_CT }
                options={pr_CO_CTOptions}
                onChange={(e) => handleFormDataChange1(`pr_CO_CT_${index}`, e.value)}
                placeholder="Select Cost Centre"
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pr_ST_CD_${index}`}
                name={`pr_ST_CD_${index}`}
                defaultValue={row.pr_ST_CD}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              </tr>
              ))}
            </tbody>
    </table>   
    
    </div>
        </div>  
        <div style={inboxstyle}>
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Gross Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              defaultValue={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Taxable Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              defaultValue={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              defaultValue={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore. Gross Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              defaultValue={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_NET_AMOUNT" className="font-bold">
              Fore. Taxable Amount
            </label>
            <InputText disabled
              id="fr_NET_AMOUNT"
              name="fr_NET_AMOUNT"
              defaultValue={fr_NET_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              defaultValue={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
        </div>
      </div>
      <SupplierhPopup
        visible={SupplierhPopupVisible}
        onClose={() => setSupplierhPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
    </Dialog>
  );
}
