import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function TimeCardInterfaceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  TimeCardInterfaceTableData,
  setTimeCardInterfaceTableData,
  handleFormDataChange,
  tc_PR_FL,
  tc_TR_TY,
  tc_TR_NO,
  tc_TR_CO,
}) {
  const {
    tcUkNo,
    // tc_TR_NO,
    // tc_TR_TY,
    //tc_TR_CO,
    tc_TR_DT

  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "tc_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
    // onDateChange({ target: { name: "pi_RQ_DT", value: moment(e.value).format("YYYYMMDD") } });
    //  onDateChange({ target: { name: "pi_PO_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  const addRow = () => {
    setTimeCardInterfaceTableData([...TimeCardInterfaceTableData, {}]);
  };
  const deleteRow = () => {
    if (TimeCardInterfaceTableData.length > 0) {
      const updatedData = [...TimeCardInterfaceTableData];
      updatedData.pop();
      setTimeCardInterfaceTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(tcUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{tcUkNo ? "Revise Time Card Interface" : "Add Time Card Interface"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
         <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={(e)=>handleFormSubmit("process")} 
          tooltip={"Process"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tc_TR_NO" className="font-bold">
                Transaction No
              </label>
              <InputText disabled
                id="tc_TR_NO"
                name="tc_TR_NO"
                defaultValue={tc_TR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tc_TR_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="tc_TR_TY"
                name="tc_TR_TY"
                defaultValue={tc_TR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tc_TR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="tc_TR_CO"
                name="tc_TR_CO"
                value={tc_TR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tc_TR_DT" className="font-bold">
                Date
              </label>
              <Calendar
                id="tc_TR_DT"
                name="tc_TR_DT"
                value={moment(tc_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>

          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="TimeCardInterfaceTableData">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Employee Code</th>
                  <th>Name</th>
                  <th>Shift</th>
                  <th>In Time</th>
                  <th>Out Time</th>
                  <th>Break</th>
                  <th>Work Duration</th>
                  <th>OverTime</th>
                  <th>Total Duration</th>
                  <th>Status</th>
                  <th>Remarks</th>
                  <th>Processed </th>

                </tr>
              </thead>
              <tbody>
                {TimeCardInterfaceTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`tcUkNo_${index}`}
                        name={`tcUkNo_${index}`}
                        value={row.tcUkNo}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_EM_CD_${index}`}
                        name={`tc_EM_CD_${index}`}
                        defaultValue={row.tc_EM_CD}
                        onChange={(e) => onchangedata(e)}
                        disabled
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_EM_NM_${index}`}
                        name={`tc_EM_NM_${index}`}
                        defaultValue={row.tc_EM_NM}
                        onChange={(e) => onchangedata(e)}
                        disabled
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_SH_FT_${index}`}
                        name={`tc_SH_FT_${index}`}
                        defaultValue={row.tc_SH_FT}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td>
                      <InputMask
                        id={`tc_IN_TM_${index}`}
                        name={`tc_IN_TM_${index}`}
                        mask="99:99:99"
                        value={moment(row.tc_IN_TM, "HHmmss").format("HH:mm:ss")}
                        onBlur={(e) => {
                          onChangeTime("tc_IN_TM", e.target.value, index);
                          //handleTimeChange(index);
                        }}
                      />
                    </td>
                    <td>
                      <InputMask
                        id={`tc_OT_TM_${index}`}
                        name={`tc_OT_TM_${index}`}
                        mask="99:99:99"
                        value={moment(row.tc_OT_TM, "HHmmss").format("HH:mm:ss")}
                        onBlur={(e) => {
                          onChangeTime("tc_OT_TM", e.target.value, index);
                          // handleTimeChange(index);
                        }}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_BR_AK_${index}`}
                        name={`tc_BR_AK_${index}`}
                        defaultValue={row.tc_BR_AK}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_NR_DU_${index}`}
                        name={`tc_NR_DU_${index}`}
                        defaultValue={row.tc_NR_DU}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* <td>
                      <InputMask
                        id={`tc_OT_DU_${index}`}
                        name={`tc_OT_DU_${index}`}
                        mask="99:99:99"
                        value={moment(row.tc_OT_DU, "HHmmss").format("HH:mm:ss")}
                        onBlur={(e) => {
                          onChangeTime("tc_OT_DU", e.target.value, index);
                          //handleTimeChange(index);
                        }}
                      />
                    </td> */}
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_OT_DU_${index}`}
                        name={`tc_OT_DU_${index}`}
                        defaultValue={row.tc_OT_DU}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_TO_DU_${index}`}
                        name={`tc_TO_DU_${index}`}
                        defaultValue={row.tc_TO_DU}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_ST_CD_${index}`}
                        name={`tc_ST_CD_${index}`}
                        defaultValue={row.tc_ST_CD}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`tc_RM_RK_${index}`}
                        name={`tc_RM_RK_${index}`}
                        defaultValue={row.tc_RM_RK}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                  <td>
                      <Dropdown
                        id={`tc_PR_FL_${index}`}
                        name={`tc_PR_FL_${index}`}
                        value={data[`tc_PR_FL_${index}`] ? data[`tc_PR_FL_${index}`] : row.tc_PR_FL}
                        options={[
                          { label: "Yes", value: "y" },
                          { label: "No", value: "n" },
                          { label: "N/A", value: "" },
                          ]}
                        onChange={(e) => handleFormDataChange(`tc_PR_FL_${index}`, e.value)}
                        placeholder="Processed"
                        disabled
                      />
                    </td> 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
