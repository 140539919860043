import React from 'react';

const DotsPopup = ({ handleClosePopup, currentItem, handleOptionClick }) => {
	
	  const handleClick = () => {
    handleOptionClick(currentItem); // Pass currentItem to handleOptionClick
    handleClosePopup(); // Close the popup after handling the option
  };
  return (
    <div
      style={{
        position: 'absolute',
        top: '120px', // Adjusted to add space at the top
        right: '0',
        background: 'white',
        border: '1px solid #ccc',
        padding: '8px 12px',
        borderRadius: '4px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
        width: '150px',
        zIndex: 100,
      }}
    >
      <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
        <li
          onClick={() => handleOptionClick(currentItem)} // Pass currentItem to handleOptionClick
          style={{
            cursor: 'pointer',
            padding: '5px',
            color: '#007bff',
            textAlign: 'center',
          }}
        >
          RemoveStarMark
        </li>
      </ul>
      <button onClick={handleClosePopup} style={{ marginTop: '8px', fontSize: '12px' }}>Close</button>
    </div>
  );
};

export default DotsPopup;

