import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomerPopup from "./CustomerPopup";
import ParentPopup from "./ParentPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function  PDCReceiptsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  rh_CR_CO,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  setFormData,
  rh_RD_TY,
  rh_DO_CO,
  rh_CR_CD,
  addRow,
  deleteRow,
  onchangeReceivedate,
  PDCReceiptstable,
  setPDCReceiptstable,
  setrh_CR_CO,
  handleResponse,
  auto_POST,
  AutoPostDropdown
}) {
  const { 
    rd_UK_NO,
    rh_CQ_NO,
    rh_RC_FR,
    rd_PY_ID,
    rh_RC_NO,
    rd_PA_AM,
    rd_DI_AM,
    rd_WO_AM,
    rd_DO_NO,
    rd_DO_TY,
    rh_CU_NO,
    rd_DO_CO,
    rd_Di_FR,
    rd_DO_PI,
    rd_WO_FR,
    rd_PA_FR,
    rh_OP_AM,
    rd_GR_AM,
    rh_PA_NO,
    rh_MT_DT,
    rh_DT_DT,
    name,
    rh_RC_DT,
    rh_RC_AM,
    rh_FR_AM,
    rh_GL_DT,
    rh_CR_RT,
    rh_RM_EX,
    rh_GL_BA,
    rh_AC_BL,
    rh_UK_NO,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,} = ButtonStyles();
  const [bankFilter, setbankFilter] = useState("BA");
  const [auto_POSTOptions, setauto_POSTOptions] = useState([]);
  const [rh_CR_COOptions, setrh_CR_COOptions] = useState([]);
  const [rd_WO_COOptions, setrd_WO_COOptions] = useState([]);
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const handleFormDataChange1 = (name, value) => {
    const index = name.split("_")[3];
    onchangeDropDownFormData2(name, value, index);
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setSelectedRowsInvoices([]);
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);
  const openParentPopup = (e) => {
    setParentPopupVisible(true);
    setSelectedRowIndex(e);
  }; 
  const handleDataSelect2 = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData("rh_PA_NO", data.abAdNo);
    setParentPopupVisible(false);  
    setShowSearchIcon(false);
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [selectedRowsInvoices, setSelectedRowsInvoices] = useState([]);
  const handleDataSelect = async() => {
    setPDCReceiptstable([{}]);
    const ledgerDtls = [];
    if(selectedRowsInvoices){
      const ukNos = selectedRowsInvoices.toString().split(",");
      const size = ukNos.length;
      const leng = size - 1;
      var cus = '';
      var cur = '';
      for (let i = 0; i < size; i++){
        try {
          const response = await axios.get(`${domain}/api/CustomerLedger/Selects/${ukNos[i]}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );
  
          if (response.status === 200) {
            const dataCuMt = response.data;
            if(i === 0){
              cus = dataCuMt.cl_CU_NO;
              cur = dataCuMt.cl_CR_CD;
            }
            if(cus !== dataCuMt.cl_CU_NO){
              const errors = {
                "errorMessage" : ["Invoices of Multiple Customers included."]
              }
              handleResponse(errors);
            } else if(cur !== dataCuMt.cl_CR_CD){
              const errors = {
                "errorMessage" : ["Invoices of Multiple Currencies included."]
              }
              handleResponse(errors);
            } else {
              onchangeFormData("rh_CU_NO", dataCuMt.cl_CU_NO);
              document.getElementById("rh_CU_NO").value =  dataCuMt.cl_CU_NO;
              document.getElementById("name").value =  dataCuMt.name;
              document.getElementById("rh_CR_RT").value =  dataCuMt.cl_CR_RT;
              setrh_CR_CO(dataCuMt.cl_CR_CD);
              const dtls = {
                "rd_DO_NO" : dataCuMt.cl_DO_NO,
                    "rd_DO_TY" : dataCuMt.cl_DO_TY,
                    "rd_DO_CO" : dataCuMt.cl_DO_CO,
                    "rd_GR_AM" : dataCuMt.cl_GR_AM,
                    "rd_OP_AM" : dataCuMt.cl_OP_AM,
                    "rd_DO_PI" : dataCuMt.cl_DO_PI
              } 
              ledgerDtls.push(dtls);
              if(i === leng){
                setPDCReceiptstable(ledgerDtls);
                setCustomerPopupVisible(false);
              }
            }
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      
    } else {
      setPDCReceiptstable([{}]);
    }
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO(data.am_AC_DS);
    onchangeFormData("rh_GL_BA", data.account);
    setAccountPopupVisible(false);    
    setShowSearchIcon(false);
  };
  const  urlcalc = `${domain}/api/ReceiptsEntry/GetCalculateHeaderAmt`
  const domescal = async (SelectedRowIndex) => {
  const requestbody ={
    "receiptsEntry": {
      "rh_CR_RT": document.getElementById("rh_CR_RT").value,
    },
    "receiptDetailsList": PDCReceiptstable.map((row, index) => ({
        "rd_PA_FR": document.getElementById(`rd_PA_FR_${index}`).value,
        "rd_PA_AM": document.getElementById(`rd_PA_AM_${index}`).value,
    })),
  };
  try {
      const response = await axios.post(urlcalc, requestbody, {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
      });
      const datacal = response.data.Result;
      if (datacal) {
        document.getElementById("rh_RC_AM").value = datacal.rh_RC_AM;
        document.getElementById("rh_RC_FR").value = datacal.rh_RC_FR;
        document.getElementById (`rd_PA_AM_${SelectedRowIndex}`).value =  datacal.rh_RC_AM; 
    
        let sum = 0;
        for (let i = 0; i < PDCReceiptstable.length; i++) {
            const rd_PA_AM = parseFloat(document.getElementById(`rd_PA_AM_${i}`).value) || 0;
            sum += rd_PA_AM;
        }
        document.getElementById("rh_RC_AM").value = sum;
    }
    
  } catch (error) {
      console.error("Error fetching dropdown options:", error);
  }
  };
  const url = `${domain}/api/ClassCode/All`;
// comp type
const parameter1 =  "FO/CR";
const finalUrl1 = `${url}/${parameter1}`;
 // comp type
 const parameter2 =  "AR/WO";
 const finalUrl2 = `${url}/${parameter2}`;

 const parameter3 =  "ME/PL";
 const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setauto_POSTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setrh_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrd_WO_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// sum row fields
const [sumAmount, setSumAmount] = useState(0);
const calculateSumAmount = () => {
  let sum = 0;
  PDCReceiptstable.forEach((row) => {
    sum += parseFloat(row.rd_PA_AM) || 0;
  });
  return sum;
};

useEffect(() => {
  calculateSumAmount();
}, [PDCReceiptstable]);

const handleRowValueChange = (index, value) => {
  const updatedPDCReceiptstable = [...PDCReceiptstable];
  updatedPDCReceiptstable[index].rd_PA_AM = value;
  setPDCReceiptstable(updatedPDCReceiptstable);
  calculateSumAmount();
};
const isUpdateMode = Boolean(rh_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{rh_UK_NO ? "Revise PDC Receipts" : "Add PDC Receipts"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
              <label htmlFor="rh_UK_NO" className="font-bold">
                UKNO
              </label>
              <InputText
                id="rh_UK_NO"
                name="rh_UK_NO"
                value={rh_UK_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rh_RC_NO" className="font-bold">
                Receipt Number
                </label>
                <InputText disabled
                  id="rh_RC_NO"
                  name="rh_RC_NO"
                  value={rh_RC_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rh_RD_TY" className="font-bold">
                Type
                </label>
                <InputText disabled
                  id="rh_RD_TY"
                  name="rh_RD_TY"
                  value={rh_RD_TY}
                />                  
            </div>    
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_DO_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="rh_DO_CO"
                name="rh_DO_CO"
                value={rh_DO_CO}
              />
            </div>    
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_RC_DT" className="font-bold">
              Receipt Date
              </label>
              <Calendar
                id="rh_RC_DT"
                name="rh_RC_DT"
                value={moment(data.rh_RC_DT, "YYYYMMDD").isValid() ? moment(data.rh_RC_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangeReceivedate({ target: { name: "rh_RC_DT", value: moment(e.value).format("YYYYMMDD"), }, }) }
                dateFormat="dd/mm/yy"
              />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="rh_CU_NO" className="font-bold">
                    Customer
                    </label>
                    <InputText
                      id="rh_CU_NO"
                      name="rh_CU_NO"
                      value={rh_CU_NO}
                      readOnly
                    />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="name" className="font-bold">
                    Customer <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="name"
                      name="name"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={name}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openCustomerPopup}
                      ></i>
                    )}
              </div>
              </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_GL_DT" className="font-bold">
                    GL Date
                    </label>
                    <Calendar
                      id="rh_GL_DT"
                      name="rh_GL_DT"
                      value={moment(data.rh_GL_DT, "YYYYMMDD").isValid() ? moment(data.rh_GL_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "rh_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />    
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_RC_AM" className="font-bold">
                    Amount
                    </label>
                    <InputText disabled
                      id="rh_RC_AM"
                      name="rh_RC_AM"
                      defaultValue={sumAmount}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_RC_FR" className="font-bold">
                    Foreign Amount
                    </label>
                    <InputText disabled
                      id="rh_RC_FR"
                      name="rh_RC_FR"
                      defaultValue={rh_RC_FR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_DT_DT" className="font-bold">
                    Due Date
                    </label>
                    <Calendar
                      id="rh_DT_DT"
                      name="rh_DT_DT"
                      value={moment(data.rh_DT_DT, "YYYYMMDD").isValid() ? moment(data.rh_DT_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "rh_DT_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />   
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_CR_CO" className="font-bold">
                    Currency
                    </label>
                    <Dropdown
                      id="rh_CR_CO"
                      name="rh_CR_CO"
                      value={rh_CR_CO}
                      options={rh_CR_COOptions}
                      onChange={(e) => onchangeDropDownFormData1("rh_CR_CO", e.value)}
                      placeholder="Select Currency"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_CR_RT" className="font-bold">
                    Exchange Rate
                    </label>
                    <InputText
                      id="rh_CR_RT"
                      name="rh_CR_RT"
                      value={rh_CR_RT}
                    readOnly
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_CR_CD" className="font-bold">
                    Base Currency
                    </label>
                    <InputText disabled
                      id="rh_CR_CD"
                      name="rh_CR_CD"
                      value={rh_CR_CD}
                      // readOnly
                    />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_PA_NO" className="font-bold">
                Parent  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="parent_Name"
                  name="parent_Name"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO1}
                
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openParentPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="rh_PA_NO"
                  name="rh_PA_NO"
                  value={rh_PA_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openParentPopup}
                ></i>
                )}
              </div>
            </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_MT_DT" className="font-bold">
                  Maturity Date
                    </label>
                    <Calendar
                        id="rh_MT_DT"
                        name="rh_MT_DT"
                        value={moment(data.rh_MT_DT, "YYYYMMDD").isValid() ? moment(data.rh_MT_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onchangedata({ target: {name: "rh_MT_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                        dateFormat="dd/mm/yy"
                      />   
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_CQ_NO" className="font-bold">
                    NFT/Cheque
                    </label>
                    <InputText
                      id="rh_CQ_NO"
                      name="rh_CQ_NO"
                      defaultValue={rh_CQ_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_GL_BA" className="font-bold">
                    Bank Account <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                <InputText
                  id="am_AC_DS"
                  name="am_AC_DS"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="rh_GL_BA"
                  name="rh_GL_BA"
                  value={rh_GL_BA}
                  onChange={(Event) => onchangedata(Event)}
                />
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
              </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_AC_BL" className="font-bold">
                    Account Balance
                    </label>
                    <InputText
                      id="rh_AC_BL"
                      name="rh_AC_BL"
                      value={rh_AC_BL}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="auto_POST" className="font-bold">
                      Auto Post
                    </label>
                    <Dropdown
                      id="auto_POST"
                      name="auto_POST"
                      value={auto_POST}
                      options={[
                        { label: "Yes", value: "y" },
                        { label: "No", value: "n" },
                        ]}
                      onChange={(e) => AutoPostDropdown("auto_POST", e.value)}
                      placeholder="Select Auto Post"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rh_RM_EX" className="font-bold">
                  Reference
                    </label>
                    <InputText
                      id="rh_RM_EX"
                      name="rh_RM_EX"
                      value={rh_RM_EX}
                      onChange={(e) => onchangedata(e)}
                    />
                  
                  </div>
          </div>
        </div>
        <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="PDCReceiptstable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th style={{ display: "none" }} >Receipt Id</th>
            <th>Receipt Amount</th>
            <th>Discount Amount</th>
            <th>Write Off Amount</th>
            <th>Write Off Code</th>
            <th>Gross Amount</th>
            <th>Open Amount</th>
            <th>Document Number</th>
            <th>Document Type</th>
            <th>Company</th>
            <th>Pay Item</th>
            <th>Foreign Receipt</th>
            <th>Foreign Disccount</th>
            <th>Foriegn Write Off</th>
          </tr>
        </thead>
        <tbody>
        {PDCReceiptstable.map((row, index) => (
                <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`rd_UK_NO_${index}`}
                name={`rd_UK_NO_${index}`}
                defaultValue={row.rd_UK_NO}
              />
            </td>
            <td style={{ display: "none" }}>
            <InputText style={{width:"90px"}}
              id={`rd_PY_ID_${index}`}
              name={`rd_PY_ID_${index}`}
              defaultValue={row.rd_PY_ID}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText  style={{width:"90px"}}
                id={`rd_PA_AM_${index}`}
                name={`rd_PA_AM_${index}`}
                value={row.rd_PA_AM}
                disabled={rh_CR_CO!=rh_CR_CD}
                onChange={(Event) => {
                  onchangedata(Event);
                  domescal(index); 
                  }}
              />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_DI_AM_${index}`}
              name={`rd_DI_AM_${index}`}
              defaultValue={row.rd_DI_AM}
              disabled={rh_CR_CO!=rh_CR_CD}
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_WO_AM_${index}`}
              name={`rd_WO_AM_${index}`}
              defaultValue={row.rd_WO_AM}
              disabled={rh_CR_CO!=rh_CR_CD}
            />
            </td>
            <td>
            <Dropdown
            id={`rd_WO_CO_${index}`}
            name={`rd_WO_CO_${index}`}
            value={ data[`rd_WO_CO_${index}`] ? data[`rd_WO_CO_${index}`]: row.rd_WO_CO}
            options={rd_WO_COOptions}
            onChange={(e) =>handleFormDataChange1(`rd_WO_CO_${index}`, e.value)}
            placeholder="Select WriteOff Code"
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_GR_AM_${index}`}
              name={`rd_GR_AM_${index}`}
              defaultValue={row.rd_GR_AM}
            readOnly
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_OP_AM_${index}`}
              name={`rd_OP_AM_${index}`}
              defaultValue={row.rd_OP_AM}
              readOnly
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_DO_NO_${index}`}
              name={`rd_DO_NO_${index}`}
              value={row.rd_DO_NO}
              readOnly
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_DO_TY_${index}`}
              name={`rd_DO_TY_${index}`}
              defaultValue={row.rd_DO_TY}
              readOnly
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_DO_CO_${index}`}
              name={`rd_DO_CO_${index}`}
              defaultValue={row.rd_DO_CO}
            readOnly
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_DO_PI_${index}`}
              name={`rd_DO_PI_${index}`}
              defaultValue={row.rd_DO_PI}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText
            style={{ width: "90px" }}
            id={`rd_PA_FR_${index}`}
            name={`rd_PA_FR_${index}`}
            value={row.rd_PA_FR}
            disabled={rh_CR_CO == rh_CR_CD}
            onChange={(event) => {
            onchangedata(event);
            domescal(index); 
            }}
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_Di_FR_${index}`}
              name={`rd_Di_FR_${index}`}
              defaultValue={row.rd_Di_FR}
              disabled={rh_CR_CO==rh_CR_CD}
            />
            </td>
            <td>
            <InputText style={{width:"90px"}}
              id={`rd_WO_FR_${index}`}
              name={`rd_WO_FR_${index}`}
              defaultValue={row.rd_WO_FR}
              disabled={rh_CR_CO==rh_CR_CD}
            />
            </td>
            </tr>
            ))}
        </tbody>
      </table>   
      
      </div>
        </div>   
      </div>
  <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        formData={data}
        opensubmit={handleDataSelect}
        selectedRowsInvoices={selectedRowsInvoices}
        setSelectedRowsInvoices={setSelectedRowsInvoices}
      />
   <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        bankFilter={bankFilter}
      />
         <ParentPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
    </Dialog>
  );
}
