import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import HospitalPaymentDetailsDialog from "./HospitalPaymentDetailsDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  hbUkNo:"",
  hb_PA_ID:"",
  hb_PA_NM:"",
  hb_CO_NO:"",
  hb_VI_NO:"",
  hb_VI_DT:"",
  hb_ST_CD:"", 
  hb_TO_AM:"",
  hb_BL_AM:"", 
  hb_DS_AM:"",
  hb_CO_AM:"",  
  hb_PR_AM:"",
  hb_CN_AM:"", 
  hb_OT_AM:"",
  hb_MS_AM:"",
  hb_LB_AM:"", 
};

const initialFilters = {
  hb_PA_ID: { value: "", operation: "" },
  hb_PA_NM: { value: "", operation: "" },
  hb_CO_NO: { value: "", operation: "" },
  hb_VI_NO: { value: "", operation: "" },
  hb_VI_DT: { value: "", operation: "" },
  hb_ST_CD: { value: "", operation: "" }, 
  hb_TO_AM: { value: "", operation: "" },
  hb_BL_AM: { value: "", operation: "" }, 
  hb_DS_AM: { value: "", operation: "" },
  hb_CO_AM: { value: "", operation: "" },  
  hb_PR_AM: { value: "", operation: "" },
  hb_CN_AM: { value: "", operation: "" }, 
  hb_OT_AM: { value: "", operation: "" },
  hb_MS_AM: { value: "", operation: "" },
  hb_LB_AM: { value: "", operation: "" }, 

};

const HospitalPaymentDetailsMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pr_RG_ST, setpr_RG_ST ] = useState("");
  const [SelectedEQNO, setSelectedEQNO ] = useState("");
  const [SelectedSTNO, setSelectedSTNO ] = useState("");
  const [SelectedCUSTNO, setSelectedCUSTNO ] = useState("");
  const [SelectedDLNO, setSelectedDLNO ] = useState("");
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [SelectedASNM, setSelectedASNM ] = useState("");
  const [PaymentTableData, setPaymentTableData] =useState([{}]);
  const [sp_TR_CR, setsp_TR_CR ] = useState("");
  const [finalDesc, setFinalDesc] = useState([]);
  const [sp_BS_CR, setsp_BS_CR] = useState([]);
  
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsp_TR_CR(value);
   // currencychnage(value,FormData.sh_OR_DT);
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setpr_RG_ST(null);
    setSelectedEQNO(null);
    setSelectedSTNO(null);
    setSelectedCUSTNO(null);
    setSelectedDLNO(null);
    setSelectedITNM(null);
    setSelectedASNM(null);
    setPaymentTableData([{}]);
    setFinalDesc("");
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/HospitalPaymentDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const HospitalPaymentDetailsData = response.data;
          delete HospitalPaymentDetailsData.hbUkNo;
          setFormData(HospitalPaymentDetailsData);
          setpr_RG_ST(HospitalPaymentDetailsData.pr_RG_ST);
          setSelectedEQNO(HospitalPaymentDetailsData.equipmentDES);
          setSelectedSTNO(HospitalPaymentDetailsData.site_NAME);
          setSelectedCUSTNO(HospitalPaymentDetailsData.customer_NAME);
          setSelectedDLNO(HospitalPaymentDetailsData.delaer_NAME);
          setSelectedITNM(HospitalPaymentDetailsData.item_NAME);
          setSelectedASNM(HospitalPaymentDetailsData.accessor_NAME);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [hb_CO_NO , sethb_CO_NO] = useState("");
 
  const handleDefault = async () => {
    sethb_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "SP_TR_CR"){
              onchangeDropDownFormData3("sp_TR_CR",dataDefault.versionIds[i].default_VALUE);
              setsp_TR_CR(response.data.versionIds[i].default_VALUE);
            }
            if(fName === "SP_BS_CR"){
              setsp_BS_CR(response.data.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BillingDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/BillingDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const HospitalPaymentDetailsData = response.data;
        setFormData(HospitalPaymentDetailsData);
        // setpr_RG_ST(HospitalPaymentDetailsData.pr_RG_ST);
        // setSelectedEQNO(HospitalPaymentDetailsData.equipmentDES);
        // setSelectedSTNO(HospitalPaymentDetailsData.site_NAME);
        // setSelectedCUSTNO(HospitalPaymentDetailsData.customer_NAME);
        // setSelectedDLNO(HospitalPaymentDetailsData.delaer_NAME);
        // setSelectedITNM(HospitalPaymentDetailsData.item_NAME);
        // setSelectedASNM(HospitalPaymentDetailsData.accessor_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      hbUkNo : FormData.hbUkNo,
      hb_PA_ID : document.getElementById("hb_PA_ID").value,
      hb_CO_NO : company,
      hb_VI_NO : document.getElementById("hb_VI_NO").value,
      hb_VI_DT : document.getElementById("hb_VI_DT").value,
      hb_LB_AM : document.getElementById("hb_LB_AM").value,
      hb_PR_AM : document.getElementById("hb_PR_AM").value,
      hb_CN_AM : document.getElementById("hb_CN_AM").value,
      hb_OT_AM : document.getElementById("hb_OT_AM").value,
      hb_MS_AM : document.getElementById("hb_MS_AM").value,
      hb_CO_AM : document.getElementById("hb_CO_AM").value,
      hb_BL_AM : document.getElementById("hb_BL_AM").value,
      hb_DS_AM : document.getElementById("hb_DS_AM").value,
      hb_TO_AM : document.getElementById("hb_TO_AM").value,
  
    };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.hbUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/HospitalPaymentDetails/Update`;
      if (confirm) {
        axios
          .put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      values();
      axios.post(`${domain}/api/HospitalPaymentDetails/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>

    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["hb_PA_ID"] ? filters["hb_PA_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_PA_ID",
                        e.target.value,
                        filters["hb_PA_ID"]
                          ? filters["hb_PA_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_PA_NM"] ? filters["hb_PA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_PA_NM",
                        e.target.value,
                        filters["hb_PA_NM"] ? filters["hb_PA_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["hb_CO_NO"] ? filters["hb_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_CO_NO",
                        e.target.value,
                        filters["hb_CO_NO"]
                          ? filters["hb_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["hb_VI_NO"] ? filters["hb_VI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_VI_NO",
                        e.target.value,
                        filters["hb_VI_NO"]
                          ? filters["hb_VI_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["hb_VI_DT"] ? filters["hb_VI_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_VI_DT",
                        e.target.value,
                        filters["hb_VI_DT"]
                          ? filters["hb_VI_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["hb_ST_CD"] ? filters["hb_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_ST_CD",
                        e.target.value,
                        filters["hb_ST_CD"]
                          ? filters["hb_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_TO_AM"] ? filters["hb_TO_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_TO_AM",
                        e.target.value,
                        filters["hb_TO_AM"] ? filters["hb_TO_AM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_BL_AM"] ? filters["hb_BL_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_BL_AM",
                        e.target.value,
                        filters["hb_BL_AM"]
                          ? filters["hb_BL_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_DS_AM"] ? filters["hb_DS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_DS_AM",
                        e.target.value,
                        filters["hb_DS_AM"]
                          ? filters["hb_DS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_CO_AM"] ? filters["hb_CO_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_CO_AM",
                        e.target.value,
                        filters["hb_CO_AM"]
                          ? filters["hb_CO_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_PR_AM"] ? filters["hb_PR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_PR_AM",
                        e.target.value,
                        filters["hb_PR_AM"]
                          ? filters["hb_PR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_CN_AM"] ? filters["hb_CN_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_CN_AM",
                        e.target.value,
                        filters["hb_CN_AM"]
                          ? filters["hb_CN_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_OT_AM"] ? filters["hb_OT_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_OT_AM",
                        e.target.value,
                        filters["hb_OT_AM"]
                          ? filters["hb_OT_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_MS_AM"] ? filters["hb_MS_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_MS_AM",
                        e.target.value,
                        filters["hb_MS_AM"]
                          ? filters["hb_MS_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["hb_LB_AM"] ? filters["hb_LB_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "hb_LB_AM",
                        e.target.value,
                        filters["hb_LB_AM"]
                          ? filters["hb_LB_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Patient ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Visit No</th>
                <th style={tableHeaderStyle}>Visit Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Total Amount	</th>
                <th style={tableHeaderStyle}>Bill Amount</th>
                <th style={tableHeaderStyle}>Discount</th>
                <th style={tableHeaderStyle}>Consultation Fee</th>
                <th style={tableHeaderStyle}>Procedure Amount</th>
                <th style={tableHeaderStyle}>Consumables Amount	</th>
                <th style={tableHeaderStyle}>Other Charges</th>
                <th style={tableHeaderStyle}>Mis. Charges</th>
                <th style={tableHeaderStyle}>Lab Amount</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.hbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.hbUkNo}
                        checked={selectedRows.includes(rowData.hbUkNo)}
                        onChange={() => handleRowSelect(rowData.hbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.hb_PA_ID}</td>
                    <td style={tableCellStyle}>{rowData.hb_PA_NM}</td>
                    <td style={tableCellStyle}>{rowData.hb_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.hb_VI_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.hb_VI_DT)}</td>
                    <td style={tableCellStyle}>{rowData.hb_ST_CD}</td> 
                    <td style={tableCellStyle}>{rowData.hb_TO_AM}</td>
                    <td style={tableCellStyle}>{rowData.hb_BL_AM}</td> 
                    <td style={tableCellStyle}>{rowData.hb_DS_AM}</td>
                    <td style={tableCellStyle}>{rowData.hb_CO_AM}</td>  
                    <td style={tableCellStyle}>{rowData.hb_PR_AM}</td>
                    <td style={tableCellStyle}>{rowData.hb_CN_AM}</td> 
                    <td style={tableCellStyle}>{rowData.hb_OT_AM}</td>
                    <td style={tableCellStyle}>{rowData.hb_MS_AM}</td>
                    <td style={tableCellStyle}>{rowData.hb_LB_AM}</td>          
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <HospitalPaymentDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        pr_RG_ST={pr_RG_ST}
        setpr_RG_ST={setpr_RG_ST}
        SelectedEQNO={SelectedEQNO}
        SelectedSTNO={SelectedSTNO}
        SelectedCUSTNO={SelectedCUSTNO}
        SelectedDLNO={SelectedDLNO}
        SelectedITNM={SelectedITNM}
        SelectedASNM={SelectedASNM}
        setSelectedEQNO={setSelectedEQNO}
        setSelectedSTNO={setSelectedSTNO}
        setSelectedCUSTNO={setSelectedCUSTNO}
        setSelectedDLNO={setSelectedDLNO}
        setSelectedITNM={setSelectedITNM}
        setSelectedASNM={setSelectedASNM}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        sp_TR_CR={sp_TR_CR}
        setsp_TR_CR={setsp_TR_CR}
        PaymentTableData={PaymentTableData}
        setPaymentTableData={setPaymentTableData}
        finalDesc={finalDesc}
        setFinalDesc={setFinalDesc}
        sp_BS_CR={sp_BS_CR}
        setsp_BS_CR={setsp_BS_CR}
        hb_CO_NO={hb_CO_NO}
      />
    </div>
  );
};

export default HospitalPaymentDetailsMain;
