import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import ContractBillingDetailsPopup from "../Popups/ContractBillingDetailsPopup";
import ParentItemPopup from "../Popups/ParentItemPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function TimeMaterialBillingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  TimeMaterialBillingTableData,
  setTimeMaterialBillingTableData,
  bd_CR_CD,
  setbd_CR_CD,
  // CurrencyDropDown,
  // onchangegldate,
  setbd_BL_ST,
  setbd_EX_RT,
  bd_EX_RT,
  bd_BL_TY,
  setbd_BL_TY,
  bd_DO_TY,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  ic_CO_CT,
  ic_CO_TY,
  setbd_IT_NM,
  setitemDES,
  bd_CN_NO,
  setbd_CN_NO,
  bd_DS_PE,
  setbd_DS_PE,
  
}) {
  const {
    bdUkNo,
    bd_CU_NO,
    bd_BL_AM,
    bd_TX_PE,
    bd_IN_DT,
    bd_CN_TY,
    bd_BS_CR,
    bd_CN_CO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [bd_CR_CDOptions, setbd_CR_CDOptions] = useState([]);
  const [bd_BL_STOptions, setbd_BL_STOptions] = useState([]);
  const [bd_BL_TYOptions, setbd_BL_TYOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const StatusDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_BL_ST(value);
  };
  const BillTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_BL_TY(value);
  };
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_CR_CD(value);
    // onChnageExchangeRate(value, FormData.bd_IN_DT);
  };
  useEffect(() => {
    Currency();
    BillType();
    Status();
  }, []);
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbd_CR_CDOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("BL/ST");
    setbd_BL_STOptions(Options);
  };
  const BillType = async () => {
    const Options = await fetchClassCodeCD("BG/TY");
    setbd_BL_TYOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("bd_CU_NO", data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`bd_CR_CD`, dataCuMt.cu_CR_CD);
      const exData={
        "cr_TO_CR": document.getElementById("bd_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.bd_IN_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("bd_EX_RT").value = dataExRt.cr_CO_MR;
          setbd_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [Item1Visible, setItem1Visible] = useState(false);
  const openItem1Popup = (e) => {
    setItem1Visible(true);
    setSelectedRowIndex(e);
  };
  const handleItem1 = async (Result) => {
    setbd_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("bd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES1", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemCost/GetUnitCost`;
    const requestData={
      "ic_IT_NM": Result.im_IT_NM,
      "ic_CO_CT": document.getElementById (`ic_CO_CT_${SelectedRowIndex}`).value,
      "ic_CO_TY": document.getElementById (`ic_CO_TY_${SelectedRowIndex}`).value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data;
    if (dataItMt ) {
      document.getElementById (`bd_UT_CT_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItem1Visible(false);
    setShowSearchIcon(false);
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("bd_BL_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const openAddressPopup = (e) => {
    setAddressPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    onchangeFormData(`bd_AD_NO_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`addr_NAME_${SelectedRowIndex}`, data.ab_AD_NM);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [ContractBillingDetailsPopupVisible, setContractBillingDetailsPopupVisible] = useState(false);
  const openContractBillingDetailsPopup = () => {
    setContractBillingDetailsPopupVisible(true);
  };
  const handleDataSelect2 = (data) => {
    document.getElementById("bd_CN_NO").value =  data.cb_CN_NO;
    onchangeFormData("bd_CN_TY", data.cb_CN_TY);
    onchangeFormData("bd_CN_CO", data.cb_CN_CO);
    setSelectedemEMNO(data.name);
    onchangeFormData("bd_CU_NO", data.cb_CU_NO);
    setselectedItem(data.cb_BL_NM);
    setselectedDesc(data.itemDES);
    CurrencyDropDown(`bd_CR_CD`, data.cb_CR_CD);
    document.getElementById("bd_EX_RT").value =  data.cb_EX_RT;
    onchangeFormData("bd_BS_CR", data.cb_BS_CR);
    onchangeFormData("bd_BL_AM", data.cb_BL_AM);
    BillTypeDropdown(`bd_BL_TY`, data.cb_BL_TY);
    setbd_DS_PE(data.cb_DS_PE);
    document.getElementById("bd_TX_PE").value =  data.cb_TX_PE;
    setContractBillingDetailsPopupVisible(false);
    setShowSearchIcon(false)
  };
  const onchangeFormAmount = async(e,index) => {
    onchangedata(e);
    GetCalculate(index);
  };
  const GetCalculate = async(index) => {
    const table = document.getElementById("operationtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const billingInvoiceGenerationList = [];
    
    for (let i = 0; i < totalRowCount; i++) {
      const Details = {
        bd_PE_CT: document.getElementById(`bd_PE_CT_${i}`).value,
        bd_AM_DM: document.getElementById(`bd_AM_DM_${i}`).value,
        bd_UT_CT: document.getElementById(`bd_UT_CT_${i}`).value,
        bd_MK_AM: document.getElementById(`bd_MK_AM_${i}`).value,
        bd_AM_FR: document.getElementById(`bd_AM_FR_${i}`).value,
      };
      billingInvoiceGenerationList.push(Details);
    }

    const requestBodyNet = {
      billingInvoiceGenerationList,
      bd_TX_PE: document.getElementById("bd_TX_PE").value,
      bd_BL_AM: document.getElementById("bd_BL_AM").value,
      bd_DS_PE: document.getElementById("bd_DS_PE").value,
      bd_EX_RT: document.getElementById("bd_EX_RT").value,
    };

    const Url = `${domain}/api/TimeAndMaterialBilling/GetcalculateAndUpdateTaxDetailsWithMarkup`;

    try {
      const response = await axios.post(Url, requestBodyNet, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const datatax = response.data.Result;
      
      if (datatax) {
        for(let i = 0; i < TimeMaterialBillingTableData.length; i++) {
          if (index === i) {
            document.getElementById(`bd_DS_AM_${i}`).value = datatax[i].BD_DS_AM;
            document.getElementById(`bd_AM_DM_${i}`).value = datatax[i].BD_AM_DM;
            document.getElementById(`bd_TX_AM_${i}`).value = datatax[i].BD_TX_AM;
            document.getElementById(`bd_DS_FR_${i}`).value = datatax[i].BD_DS_FR;
            document.getElementById(`bd_TX_FR_${i}`).value = datatax[i].BD_TX_FR;
            document.getElementById(`bd_AM_FR_${i}`).value = datatax[i].BD_AM_FR;
          }
        }
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const addRow = () => {
    setTimeMaterialBillingTableData([...TimeMaterialBillingTableData, {}]);
  };
  const deleteRow = () => {
    if (TimeMaterialBillingTableData.length > 0) {
      const updatedData = [...TimeMaterialBillingTableData];
      updatedData.pop();
      setTimeMaterialBillingTableData(updatedData);
    }
  };  
  const isUpdateMode = Boolean(bdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{bdUkNo ? "Revise Time & Material Billing" : "Add Time & Material Billing"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_CN_NO" className="font-bold">
              Contract No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="bd_CN_NO"
                    name="bd_CN_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={bd_CN_NO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openContractBillingDetailsPopup}
                    ></i>
                   )}
              </div>
                </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bd_CN_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="bd_CN_TY"
            name="bd_CN_TY"
            value={bd_CN_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="bd_CN_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="bd_CN_CO"
            name="bd_CN_CO"
            value={bd_CN_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_IN_DT" className="font-bold">
              Invoice Date
            </label>
            <Calendar
              id="bd_IN_DT"
              name="bd_IN_DT"
              value={moment(bd_IN_DT, "YYYYMMDD").toDate()}
              onChange={(e) => {
                handleDateChange(e);
                onchangegldate({ target: { name: "bd_IN_DT", value: moment(e.value).format("YYYYMMDD") } });
              }}
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div> */}
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="name"
                name="name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="bd_CU_NO"
                name="bd_CU_NO"
                defaultValue={bd_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BL_NM" className="font-bold">
                 Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="bd_BL_NM"
                      name="bd_BL_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>     
          
            
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_CR_CD" className="font-bold">
                 Currency
                  </label>
                  <Dropdown disabled
                    name="bd_CR_CD"
                    value={bd_CR_CD}
                    options={bd_CR_CDOptions}
                    onChange={(e) =>CurrencyDropDown("bd_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="bd_EX_RT"
                    name="bd_EX_RT"
                    defaultValue={bd_EX_RT}
                  />
            </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="bd_BS_CR"
                    name="bd_BS_CR"
                    defaultValue={bd_BS_CR}
                  />
            </div>
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BL_AM" className="font-bold">
                    Bill Amount
                  </label>
                  <InputText disabled
                    id="bd_BL_AM"
                    name="bd_BL_AM"
                    defaultValue={bd_BL_AM}
                    onChange={GetCalculate}
                  />
            </div>
           
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_TX_PE" className="font-bold">
                    Tax %
                  </label>
                  <InputText disabled
                    id="bd_TX_PE"
                    name="bd_TX_PE"
                    value={bd_TX_PE}
                    onChange={GetCalculate}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_DS_PE" className="font-bold">
                  Discount %
                  </label>
                  <InputText  disabled
                    id="bd_DS_PE"
                    name="bd_DS_PE"
                    value={bd_DS_PE}
                    onChange={GetCalculate}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_BL_TY" className="font-bold">
                   Bill Type
                  </label>
                  <Dropdown disabled
                    name="bd_BL_TY"
                    value={bd_BL_TY}
                    options={bd_BL_TYOptions}
                    onChange={(e) => BillTypeDropdown("bd_BL_TY", e.value)}
                    placeholder="Select Bill Type"
                  />
                </div>
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item Name</th>
              <th>Description</th>
              <th style={{display:"none"}}>Address No</th>
              <th>Address No <span style={{ color: 'red', }}>*</span></th>
              <th>Cost</th>
              <th>MarkUp %</th>
              <th>MarkUp Amount</th>
              <th>Amount</th>
              <th>Discount Amount</th>
              <th>Tax Amount</th>
              <th>Amount-Foreign</th>
              <th>Discount Foreign</th>
              <th>Tax Foreign</th>
              <th>Status</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Invoice Date</th>
              <th>Invoice No</th>
              <th>Invoice Type</th>
              <th style={{ display: "none" }}>Cost Centre</th>
              <th style={{ display: "none" }}>Type</th>
              
            </tr>
          </thead>
          <tbody>
            {TimeMaterialBillingTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`bdUkNo_${index}`}
                    name={`bdUkNo_${index}`}
                    value={row.bdUkNo}
                  />
                </td>
               
                <td style={{ display: "flex" }}>
                  <InputText 
                  style={{ width: "110px" }}
                  id={`bd_IT_NM_${index}`}
                  name={`bd_IT_NM_${index}`}
                  onChange={(e) => onchangeFormData(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={row.bd_IT_NM ? row.bd_IT_NM: row[`${"bd_IT_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "15%",
                cursor: "pointer",
                }}
                onClick={(e) => openItem1Popup(index)}
                ></i>
                )}
              </td>
              <td>
              <InputText disabled
              style={{ width: "200px" }}
              id={`itemDES1_${index}`}
              name={`itemDES1_${index}`}
              defaultValue={row.itemDES1 ? row.itemDES1 : row[`${"itemDES1"}_${index}`]}
              />
              </td>
              <td style={{display:"none"}}> 
                <InputText
                id={`bd_AD_NO_${index}`}
                name={`bd_AD_NO_${index}`}
                onFocus={() => setShowSearchIcon(true)}
                value={row.bd_AD_NO ? row.bd_AD_NO: data[`${"bd_AD_NO"}_${index}`]}
                onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <div style={{ display: "flex", marginTop: "10px" ,width:"150px"}}>
                <InputText
                id={`addr_NAME_${index}`}
                name={`addr_NAME_${index}`}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.addr_NAME ? row.addr_NAME : data[`${"addr_NAME"}_${index}`]}
                />
                {showSearchIcon && (
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
                }}
                onClick={(e) => openAddressPopup(index)}
                ></i>
                )}
                </div>
                </td>
                  <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`bd_UT_CT_${index}`}
                    name={`bd_UT_CT_${index}`}
                    defaultValue={row.bd_UT_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`bd_PE_CT_${index}`}
                    name={`bd_PE_CT_${index}`}
                    defaultValue={row.bd_PE_CT}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`bd_MK_AM_${index}`}
                    name={`bd_MK_AM_${index}`}
                    defaultValue={row.bd_MK_AM}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                   disabled={data.bd_CR_CD==bd_BS_CR?false:true}
                    id={`bd_AM_DM_${index}`}
                    name={`bd_AM_DM_${index}`}
                    defaultValue={row.bd_AM_DM}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`bd_DS_AM_${index}`}
                    name={`bd_DS_AM_${index}`}
                    defaultValue={row.bd_DS_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`bd_TX_AM_${index}`}
                    name={`bd_TX_AM_${index}`}
                    defaultValue={row.bd_TX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                 disabled={data.bd_CR_CD!=bd_BS_CR?false:true}
                    id={`bd_AM_FR_${index}`}
                    name={`bd_AM_FR_${index}`}
                    defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_AM_FR:0}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`bd_DS_FR_${index}`}
                    name={`bd_DS_FR_${index}`}
                    defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_DS_FR:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`bd_TX_FR_${index}`}
                    name={`bd_TX_FR_${index}`}
                    defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_TX_FR:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`bd_BL_ST_${index}`}
                    name={`bd_BL_ST_${index}`}
                    value={ data[`bd_BL_ST_${index}`] ? data[`bd_BL_ST_${index}`]: row.bd_BL_ST}
                    options={bd_BL_STOptions}
                    onChange={(e) =>StatusDropdown(`bd_BL_ST_${index}`, e.value)}
                    placeholder="Select Status"
                  />
                  </td>
                <td>
                  <Calendar  style={{width:"150px"}}
                  id={`bd_FR_DT_${index}`}
                  name={`bd_FR_DT_${index}`}
                  value={row.bd_FR_DT? moment(row.bd_FR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar   style={{width:"150px"}}
                  id={`bd_EN_DT_${index}`}
                  name={`bd_EN_DT_${index}`}
                  value={row.bd_EN_DT? moment(row.bd_EN_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                <Calendar disabled
              style={{width:"150px"}}
              id={`bd_IN_DT_${index}`}
              name={`bd_IN_DT_${index}`}
              value={row.bd_IN_DT}
              onChange={(e) => {//setCurrentDate(e.value);
              onDateChange("bd_IN_DT", index, e.value);
              }}
              dateFormat="dd/mm/yy"
            />
            </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`bd_DO_NO_${index}`}
                    name={`bd_DO_NO_${index}`}
                    defaultValue={row.bd_DO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`bd_DO_TY_${index}`}
                    name={`bd_DO_TY_${index}`}
                    defaultValue={bd_DO_TY}
                  />
                </td>
                      <td style={{display:"none"}} >
                      <InputText   style={{width:"90px" }}
                        id={`ic_CO_TY_${index}`}
                        name={`ic_CO_TY_${index}`}
                        defaultValue={ic_CO_TY}
                      />
                    </td>
                    <td style={{display:"none"}} >
                      <InputText  style={{width:"90px" }}
                        id={`ic_CO_CT_${index}`}
                        name={`ic_CO_CT_${index}`}
                        defaultValue={ic_CO_CT}
                      />
                    </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      </div> 
        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      <ParentItemPopup
      visible={Item1Visible}
      onClose={() => setItem1Visible(false)}
      onDataSelect={handleItem1}
      formData={data}
    />
        <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={AddresstypeE}
      />

      <ContractBillingDetailsPopup
        visible={ContractBillingDetailsPopupVisible}
        onClose={() => setContractBillingDetailsPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        billType="T"
      />
    </Dialog>
  );
}
