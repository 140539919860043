import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LoadBuildingDetailsDialog from "./LoadBuildingDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  lbUkNo:"",
  lb_LD_NO:"",
  lb_LD_TY:"",
  lb_LD_CO:"",
  lb_PL_DE:"",
  lb_VE_TY:"",
  lb_ST_CD:"",
  lb_VE_NO:"",
  lb_RT_CD:"",   
  lb_CA_NO:"",  
  lb_ZN_CD:"",   
   lb_MO_TR:"",   
  lb_CT_RY:"",   
   lb_SH_DT:"",
   lb_SH_TM:"",    
   lb_SH_NO:"", 
   lb_SH_TO:"",   
  ld_CI_TY:"", 
   lb_ST_AT:"",    
  lb_PO_CD:"",   
  lb_SC_WT:"",   
  lb_WT_UM:"",
  lb_SC_VL:"",
  lb_VL_UM:"",
  lb_CO_CT:"",
  lb_SH_DE:"",
  lb_DE_DE:"",
  lb_DL_DT:"",
  lb_DL_TM:"",
  lb_DS_GP:"",
  lb_MD_TR :"",
  lb_CN_RY:"",
  la_SH_DT:moment(moment().toDate()).format("YYYYMMDD"), 
  lb_VH_ID:"",
  la_SH_TM:"",
  lb_CR_NO:"",



 
};

const initialFilters = {
  lb_LD_NO:{ value: "", operation: "" },
  lb_LD_TY:{ value: "", operation: "" },
  lb_LD_CO:{ value: "", operation: "" },
  lb_PL_DE:{ value: "", operation: "" },
  lb_VE_TY:{ value: "", operation: "" },
  lb_ST_CD:{ value: "", operation: "" },
  lb_VE_NO:{ value: "", operation: "" },
  lb_RT_CD:{ value: "", operation: "" },   
  lb_CA_NO:{ value: "", operation: "" },  
  lb_ZN_CD:{ value: "", operation: "" },   
   lb_MO_TR:{ value: "", operation: "" },   
  lb_CT_RY:{ value: "", operation: "" },   
   lb_SH_DT:{ value: "", operation: "" },
   lb_SH_TM:{ value: "", operation: "" },    
   lb_SH_NO:{ value: "", operation: "" }, 
   lb_SH_TO:{ value: "", operation: "" },   
  ld_CI_TY:{ value: "", operation: "" }, 
   lb_ST_AT:{ value: "", operation: "" },    
  lb_PO_CD:{ value: "", operation: "" },   
  lb_SC_WT:{ value: "", operation: "" },   
  lb_WT_UM:{ value: "", operation: "" },
  lb_SC_VL:{ value: "", operation: "" },
  lb_VL_UM:{ value: "", operation: "" },
  lb_CO_CT:{ value: "", operation: "" },
  lb_SH_DE:{ value: "", operation: "" },
  lb_DE_DE:{ value: "", operation: "" },
  lb_DL_DT:{ value: "", operation: "" },
  lb_DL_TM:{ value: "", operation: "" },
  lb_DS_GP:{ value: "", operation: "" },


};

const LoadBuildingDetailsMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH,inputStyle1,formatSavedTime
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null); 
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns

  const [lb_PL_DE, setlb_PL_DE ] = useState("");
  const [lb_VE_TY, setlb_VE_TY ] = useState("");
  const [lb_ST_AT, setlb_ST_AT ] = useState("");
  const [lb_RT_CD, setlb_RT_CD ] = useState("");
  const [lb_ZN_CD, setlb_ZN_CD ] = useState("");
  const [lb_MD_TR, setlb_MD_TR ] = useState("");
  const [lb_CN_RY, setlb_CN_RY ] = useState("");
  const [lb_CI_TY, setlb_CI_TY ] = useState("");
  const [lb_ST_CD, setlb_ST_CD ] = useState("");
  const [lb_WT_UM, setlb_WT_UM ] = useState("");
  const [lb_VL_UM, setlb_VL_UM ] = useState("");
  const [lb_OR_DE, setlb_OR_DE ] = useState("");
  const [lb_SH_DE, setlb_SH_DE ] = useState("");
  const [lb_DE_DE, setlb_DE_DE ] = useState("");
  const [lb_LD_TY, setlb_LD_TY ] = useState("");
  const [lb_LD_NO, setlb_LD_NO ] = useState(""); 
  const [f_WEIGHT, setf_WEIGHT ] = useState("");
  const [f_VOLUMET, setf_VOLUMET ] = useState("");
  const [F_FORE_PRICE, setF_FORE_PRICE ] = useState(""); 
  const [lb_DS_GP, setlb_DS_GP ] = useState("");  
  const [sd_SH_NO, setsd_SH_NO ] = useState("");
  const [sd_OR_CO , setsd_OR_CO ] = useState("");
  const [sd_OR_TY, setsd_OR_TY] = useState("");
  const [sd_FR_CD, setsd_FR_CD] = useState("");

  
 // Advance Search
  const [SelectedShip, setSelectedShip ] = useState("");
  const [SelectedSold, setSelectedSold ] = useState("");
  const [SelectedItem, setSelectedItem ] = useState("");
  const [SelectedCarrier, setSelectedCarrier ] = useState("");
  const [SelectedVehicleID, setSelectedVehicleID ] = useState("");
  const [shipto_NAME, setshipto_NAME ] = useState("");
  const [LoadBuildingDetailsTable, setLoadBuildingDetailsTable] = useState([{}]);
  
  
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setLoadBuildingDetailsTable([{}]);
    setFormData(initialValue);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLoadBuildingDetailsTable([{}]);
    setFormData(initialValue);

    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [lb_CO_NO, setlb_CO_NO] = useState("")


  const handleDefault = async () => {
    setlb_CO_NO(`${company}`)
    
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
           
            // if(fName === "SD_BS_CR"){
            //   setsd_BS_CR(dataDefault.versionIds[i].default_VALUE)
            // }
            if(fName === "LB_LD_TY"){
              setlb_LD_TY(dataDefault.versionIds[i].default_VALUE)
            }
        
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/LoadBuildingDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.lbUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      LoadBuildingDetailsTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/LoadBuildingDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const LoadBuildingDetailsData = response.data;
        setLoadBuildingDetailsTable(LoadBuildingDetailsData.loadBuildingDetailsList);
        setFormData(LoadBuildingDetailsData.loadBuildingDetailsList[0]);
        setSelectedItem(LoadBuildingDetailsData.loadBuildingDetailsList.item_DES);
        setshipto_NAME(LoadBuildingDetailsData.shipto_NAME); 
        setf_WEIGHT(LoadBuildingDetailsData.f_WEIGHT); 
        setf_VOLUMET(LoadBuildingDetailsData.f_VOLUME); 
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/LoadBuildingDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const LoadBuildingDetailsData = response.data;
          delete LoadBuildingDetailsData.loadBuildingDetailsList[0].lb_LD_NO;
          delete LoadBuildingDetailsData.loadBuildingDetailsList[0].lbUkNo;
          for (let i = 0; i < LoadBuildingDetailsData.loadBuildingDetailsList.length; i++) {
            delete LoadBuildingDetailsData.loadBuildingDetailsList[i].lbUkNo;
          }
          setLoadBuildingDetailsTable(LoadBuildingDetailsData.loadBuildingDetailsList);
          setFormData(LoadBuildingDetailsData.loadBuildingDetailsList[0]);
     
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("LoadBuildingDetailsTable");
  const totalRowCount = table.tBodies[0].rows.length;
  const loadBuildingDetailsList = [];
  for (let i = 0; i < totalRowCount; i++) {
    const details = {
      lbUkNo : document.getElementById("lbUkNo_" + i).value,
        lb_SH_NO : document.getElementById("lb_SH_NO_" + i).value,
        shipto_NAME : document.getElementById("shipto_NAME_" + i).value,
        lb_SH_TO : document.getElementById("lb_SH_TO_" + i).value,
        lb_CI_TY : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        lb_ST_CD : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        lb_PO_CD : document.getElementById("lb_PO_CD_" + i).value,
        lb_WE_GT : document.getElementById("lb_WE_GT_" + i).value,
        lb_WT_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        lb_VL_ME : document.getElementById("lb_VL_ME_" + i).value,
        lb_VL_UM : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
        lb_OR_DE : table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
        lb_SH_DE : table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
        lb_DE_DE : table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value,
        lb_DL_DT : document.getElementById("lb_DL_DT_" + i).value,
        lb_DL_TM : document.getElementById("lb_DL_TM_" + i).value,
        lb_DS_GP : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
      
    };
    loadBuildingDetailsList.push(details);
  }
  requestBody = {
    loadBuildingDetailsList,
    lbUkNo   : FormData.lbUkNo,
    lb_LD_NO : document.getElementById("lb_LD_NO").value,
    lb_LD_TY : lb_LD_TY,
    lb_CO_NO : document.getElementById("lb_CO_NO").value,
    lb_PL_DE : FormData.lb_PL_DE,
    lb_VE_TY : FormData.lb_VE_TY,
    lb_ST_AT : FormData.lb_ST_AT,
    lb_RT_CD : FormData.lb_RT_CD,
    lb_ZN_CD : FormData.lb_ZN_CD,
    lb_MD_TR : FormData.lb_MD_TR,
    lb_CN_RY : FormData.lb_CN_RY,
    la_SH_DT : FormData.la_SH_DT,
    la_SH_TM : FormData.la_SH_TM,
    lb_VH_ID : FormData.lb_VH_ID,
    lb_CR_NO : FormData.lb_CR_NO,
    
    
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = FormData.lb_LD_NO;
  const ukNo = FormData.lbUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/LoadBuildingDetails/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/LoadBuildingDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
    const FR_DT = FormData.la_SH_DT;
    if(FR_DT){
      const currentYear = FR_DT.toString();
      const firstHalf = currentYear.substring(0, 2);
      const secondHalf = currentYear.substring(2, 4);
      const urlNextNumber = `${domain}/api/DocumentNumber/All`;
      const parameters = `${lb_LD_TY}/${secondHalf}/${company}/${firstHalf}`;  
      const finalUrl1 = `${urlNextNumber}/${parameters}`;
      axios.get(finalUrl1, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        setlb_LD_NO(resp.dn_DO_NO);
          document.getElementById("lb_LD_NO").value = resp.data.dn_DO_NO;
          if(resp.data.dn_DO_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/LoadBuildingDetails/Add`, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }
  };




   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["lb_LD_NO"] ? filters["lb_LD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_LD_NO",
                        e.target.value,
                        filters["lb_LD_NO"]
                          ? filters["lb_LD_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["lb_LD_TY"] ? filters["lb_LD_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_LD_TY",
                        e.target.value,
                        filters["lb_LD_TY"] ? filters["lb_LD_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["lb_LD_CO"] ? filters["lb_LD_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_LD_CO",
                        e.target.value,
                        filters["lb_LD_CO"]
                          ? filters["lb_LD_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["lb_PL_DE"] ? filters["lb_PL_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_PL_DE",
                        e.target.value,
                        filters["lb_PL_DE"] ? filters["lb_PL_DE"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              
                <td>
                  <input
                    type="text"
                    value={filters["lb_VE_TY"] ? filters["lb_VE_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_VE_TY",
                        e.target.value,
                        filters["lb_VE_TY"]
                          ? filters["lb_VE_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_ST_CD"] ? filters["lb_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_ST_CD",
                        e.target.value,
                        filters["lb_ST_CD"]
                          ? filters["lb_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_VE_NO"] ? filters["lb_VE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_VE_NO",
                        e.target.value,
                        filters["lb_VE_NO"]
                          ? filters["lb_VE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["lb_RT_CD"] ? filters["lb_RT_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_RT_CD",
                        e.target.value,
                        filters["lb_RT_CD"]
                          ? filters["lb_RT_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_CA_NO"] ? filters["lb_CA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_CA_NO",
                        e.target.value,
                        filters["lb_CA_NO"]
                          ? filters["lb_CA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_ZN_CD"] ? filters["lb_ZN_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_ZN_CD",
                        e.target.value,
                        filters["lb_ZN_CD"]
                          ? filters["lb_ZN_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_MO_TR"] ? filters["lb_MO_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_MO_TR",
                        e.target.value,
                        filters["lb_MO_TR"]
                          ? filters["lb_MO_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_CT_RY"] ? filters["lb_CT_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_CT_RY",
                        e.target.value,
                        filters["lb_CT_RY"]
                          ? filters["lb_CT_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_SH_DT"] ? filters["lb_SH_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SH_DT",
                        e.target.value,
                        filters["lb_SH_DT"]
                          ? filters["lb_SH_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_SH_TM"] ? filters["lb_SH_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SH_TM",
                        e.target.value,
                        filters["lb_SH_TM"]
                          ? filters["lb_SH_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_SH_NO"] ? filters["lb_SH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SH_NO",
                        e.target.value,
                        filters["lb_SH_NO"]
                          ? filters["lb_SH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_SH_TO"] ? filters["lb_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SH_TO",
                        e.target.value,
                        filters["lb_SH_TO"]
                          ? filters["lb_SH_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ld_CI_TY"] ? filters["ld_CI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ld_CI_TY",
                        e.target.value,
                        filters["ld_CI_TY"]
                          ? filters["ld_CI_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["lb_ST_AT"] ? filters["lb_ST_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_ST_AT",
                        e.target.value,
                        filters["lb_ST_AT"]
                          ? filters["lb_ST_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_PO_CD"] ? filters["lb_PO_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_PO_CD",
                        e.target.value,
                        filters["lb_PO_CD"]
                          ? filters["lb_PO_CD"].operation
                          : "lb_PO_CD"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["lb_SC_WT"] ? filters["lb_SC_WT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SC_WT",
                        e.target.value,
                        filters["lb_SC_WT"]
                          ? filters["lb_SC_WT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_WT_UM"] ? filters["lb_WT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_WT_UM",
                        e.target.value,
                        filters["lb_WT_UM"]
                          ? filters["lb_WT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["lb_SC_VL"] ? filters["lb_SC_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SC_VL",
                        e.target.value,
                        filters["lb_SC_VL"]
                          ? filters["lb_SC_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_VL_UM"] ? filters["lb_VL_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_VL_UM",
                        e.target.value,
                        filters["lb_VL_UM"]
                          ? filters["lb_VL_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_CO_CT"] ? filters["lb_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_CO_CT",
                        e.target.value,
                        filters["lb_CO_CT"]
                          ? filters["lb_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_SH_DE"] ? filters["lb_SH_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_SH_DE",
                        e.target.value,
                        filters["lb_SH_DE"]
                          ? filters["lb_SH_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_DE_DE"] ? filters["lb_DE_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_DE_DE",
                        e.target.value,
                        filters["lb_DE_DE"]
                          ? filters["lb_DE_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_DL_DT"] ? filters["lb_DL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_DL_DT",
                        e.target.value,
                        filters["lb_DL_DT"]
                          ? filters["lb_DL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_DL_TM"] ? filters["lb_DL_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_DL_TM",
                        e.target.value,
                        filters["lb_DL_TM"]
                          ? filters["lb_DL_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["lb_DS_GP"] ? filters["lb_DS_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "lb_DS_GP",
                        e.target.value,
                        filters["lb_DS_GP"]
                          ? filters["lb_DS_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td>  
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Load NO</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Planning Depot</th> 
                <th style={tableHeaderStyle}>Vehicle Type</th> 
                <th style={tableHeaderStyle}>Status</th> 
                <th style={tableHeaderStyle}>Vehicle ID</th> 
                <th style={tableHeaderStyle}>Route Code</th>  
                <th style={tableHeaderStyleH}>Carrier No</th> 
                <th style={tableHeaderStyle}>Zone</th> 
                <th style={tableHeaderStyle}>Mod of Transport</th>  
                <th style={tableHeaderStyle}>Country</th> 
                <th style={tableHeaderStyle}>Ship Date</th> 
                <th style={tableHeaderStyle}>Ship Time</th> 
                <th style={tableHeaderStyle}>Shipment No</th> 
                <th style={tableHeaderStyle}>Ship To</th> 
                <th style={tableHeaderStyle}>City</th> 
                <th style={tableHeaderStyle}>State</th>  
                <th style={tableHeaderStyleH}>Postal Code</th>
                <th style={tableHeaderStyle}>Weight</th>
                <th style={tableHeaderStyleH}>UM</th>
                <th style={tableHeaderStyle}>Volume</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Origin Depot</th> 
                <th style={tableHeaderStyle}>Shipment Depot</th> 
                <th style={tableHeaderStyle}>Destination Depot</th> 
                <th style={tableHeaderStyle}>Delivery Date</th> 
                <th style={tableHeaderStyle}>Delivery Time</th> 
                <th style={tableHeaderStyle}>Dispatch Group</th>         
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.lbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.lbUkNo}
                        checked={selectedRows.includes(rowData.lbUkNo)}
                        onChange={() => handleRowSelect(rowData.lbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.lb_LD_NO}</td>
                    <td style={tableCellStyle}>{rowData.lb_LD_TY}</td>
                    <td style={tableCellStyle}>{rowData.lb_LD_CO}</td>
                    <td style={tableCellStyle}>{rowData.lb_PL_DE}</td>
                    <td style={tableCellStyle}>{rowData.lb_VE_TY}</td>
                    <td style={tableCellStyle}>{rowData.lb_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.lb_VE_NO}</td>
                    <td style={tableCellStyle}>{rowData.lb_RT_CD}</td>   
                    <td style={tableCellStyleH}>{rowData.lb_CA_NO}</td>  
                    <td style={tableCellStyle}>{rowData.lb_ZN_CD}</td>   
                    <td style={rightalined}>{rowData.lb_MO_TR}</td>   
                    <td style={tableCellStyle}>{rowData.lb_CT_RY}</td>   
                    <td style={rightalined}>{rowData.lb_SH_DT}</td>
                    <td style={rightalined}>{rowData.lb_SH_TM}</td>    
                    <td style={rightalined}>{rowData.lb_SH_NO}</td> 
                    <td style={rightalined}>{rowData.lb_SH_TO}</td>   
                    <td style={tableCellStyle}>{rowData.ld_CI_TY}</td> 
                    <td style={rightalined}>{rowData.lb_ST_AT}</td>    
                    <td style={tableCellStyleH}>{rowData.lb_PO_CD}</td>   
                    <td style={tableCellStyle}>{rowData.lb_SC_WT}</td>   
                    <td style={tableCellStyleH}>{rowData.lb_WT_UM}</td>
                    <td style={tableCellStyle}>{rowData.lb_SC_VL}</td>
                    <td style={tableCellStyle}>{rowData.lb_VL_UM}</td>
                    <td style={tableCellStyle}>{rowData.lb_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.lb_SH_DE}</td>
                    <td style={tableCellStyle}>{rowData.lb_DE_DE}</td>
                    <td style={tableCellStyle}>{rowData.lb_DL_DT}</td>
                    <td style={tableCellStyle}>{rowData.lb_DL_TM}</td>
                    <td style={tableCellStyle}>{rowData.lb_DS_GP}</td>
         



                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <LoadBuildingDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        LoadBuildingDetailsTable={LoadBuildingDetailsTable}
        setLoadBuildingDetailsTable={setLoadBuildingDetailsTable}
       // CurrencyDropDown={CurrencyDropDown}
        //onchangegldate={onchangegldate}
       // CostCentreDropDown={CostCentreDropDown}
      //  StatusDropdown={StatusDropdown}
   
    
        SelectedShip={SelectedShip}
        setSelectedShip={setSelectedShip}
        SelectedSold={SelectedSold}
        setSelectedSold={setSelectedSold}
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        SelectedVehicleID={SelectedVehicleID}
        setSelectedVehicleID={setSelectedVehicleID}
        SelectedCarrier={SelectedCarrier}
        setSelectedCarrier={setSelectedCarrier}
        shipto_NAME={shipto_NAME}
        setshipto_NAME={setshipto_NAME}
       
        lb_PL_DE={lb_PL_DE}
        setlb_PL_DE={setlb_PL_DE}
        lb_VE_TY={lb_VE_TY}
        setlb_VE_TY={setlb_VE_TY}
        lb_ST_AT={lb_ST_AT}
        setlb_ST_AT={setlb_ST_AT}
        lb_RT_CD={lb_RT_CD}
        setlb_RT_CD={setlb_RT_CD}
        lb_ZN_CD={lb_ZN_CD}
        setlb_ZN_CD={setlb_ZN_CD}
        lb_MD_TR={lb_MD_TR}
        setlb_MD_TR={setlb_MD_TR}
        lb_CN_RY={lb_CN_RY}
        setlb_CN_RY={setlb_CN_RY}
        lb_CI_TY={lb_CI_TY}
        setlb_CI_TY={setlb_CI_TY}
        lb_ST_CD={lb_ST_CD}
        setlb_ST_CD={setlb_ST_CD}
        lb_WT_UM={lb_WT_UM}
        setlb_WT_UM={setlb_WT_UM}
        lb_VL_UM={lb_VL_UM}
        setlb_VL_UM={setlb_VL_UM}
        lb_OR_DE={lb_OR_DE}
        setlb_OR_DE={setlb_OR_DE}
        lb_SH_DE={lb_SH_DE}
        setlb_SH_DE={setlb_SH_DE}
        lb_DE_DE={lb_DE_DE}
        setlb_DE_DE={setlb_DE_DE}
        lb_LD_TY={lb_LD_TY}
        setlb_LD_TY={setlb_LD_TY}
        lb_LD_NO={lb_LD_NO}
        setlb_LD_NO={setlb_LD_NO}
        f_VOLUMET={f_VOLUMET}
        setf_VOLUMET={setf_VOLUMET}
        f_WEIGHT={f_WEIGHT}
        setf_WEIGHT={setf_WEIGHT}
        lb_DS_GP={lb_DS_GP}
        setlb_DS_GP={setlb_DS_GP}
        lb_CO_NO={lb_CO_NO}
        setlb_CO_NO={setlb_CO_NO}
     
      />
    </div>
  );
};

export default LoadBuildingDetailsMain;
