import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
const initialFilters = {
  pm_PR_NO: { value: "", operation: "" },
  pm_PR_DS: { value: "", operation: "" },
  pm_PR_TY: { value: "", operation: "" },
  pm_PR_ST: { value: "", operation: "" },
  pm_PA_PR:{ value: "", operation: "" },
  proj_NAME:{ value: "", operation: "" },
};

const StockAvailabilityPopup = ({ visible, onClose, onDataSelect, ItemlmId}) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const {PopupStyle} = ButtonStyles();

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  // useEffect(() => {
  //   setOpen(visible);
  //   handleSearch();
  //   setCurrentPage(0);
  // }, [currentPage, pageSize, filters, visible]);
  useEffect(() => {
    if (visible) {
      setOpen(true);
      handleSearch();
    } else {
      setOpen(false);
    }
  }, [visible]);

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/StockAvailability/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };

  return (
    <Dialog id="dialog-popup-style"
      visible={open}
      onHide={handleClose}
      style={PopupStyle}
      header="Stock Details"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
              <td>
                  <input
                    type="text"
                    value={filters["is_IT_NM"] ? filters["is_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_IT_NM",
                        e.target.value,
                        filters["is_IT_NM"]
                          ? filters["is_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["itemDES"] ? filters["itemDES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "itemDES",
                        e.target.value,
                        filters["itemDES"] ? filters["itemDES"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_CO_CT"] ? filters["is_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_CO_CT",
                        e.target.value,
                        filters["is_CO_CT"]
                          ? filters["is_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_LO_CN"] ? filters["is_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_LO_CN",
                        e.target.value,
                        filters["is_LO_CN"]
                          ? filters["is_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_LT_NO"] ? filters["is_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_LT_NO",
                        e.target.value,
                        filters["is_LT_NO"]
                          ? filters["is_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_PR_UM"] ? filters["is_PR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_PR_UM",
                        e.target.value,
                        filters["is_PR_UM"]
                          ? filters["is_PR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_SC_QT"] ? filters["is_SC_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_SC_QT",
                        e.target.value,
                        filters["is_SC_QT"] ? filters["is_SC_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_SC_CM"] ? filters["is_SC_CM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_SC_CM",
                        e.target.value,
                        filters["is_SC_CM"] ? filters["is_SC_CM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["is_AV_QT"] ? filters["is_AV_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "is_AV_QT",
                        e.target.value,
                        filters["is_AV_QT"] ? filters["is_AV_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
               <th style={tableHeaderStyle}>Item No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot No</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Sec Qty</th>
                <th style={tableHeaderStyle}>Sec UM</th>
                <th style={tableHeaderStyle}>Available Qty</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.isUkNo}
                      onClick={() => onDataSelect(rowData,ItemlmId)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                    <td style={tableCellStyle}>{rowData.is_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.itemDES}</td>
                    <td style={tableCellStyle}>{rowData.is_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.is_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.is_LT_NO}</td> 
                    <td style={tableCellStyle}>{rowData.is_PR_UM}</td>
                    <td style={tableCellStyle}>{rowData.is_SC_QT}</td>
                    <td style={tableCellStyle}>{rowData.is_SC_CM}</td> 
                    <td style={tableCellStyle}>{rowData.is_AV_QT}</td> 
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />      
      </div>
    </Dialog>
  );
};

StockAvailabilityPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default StockAvailabilityPopup;
