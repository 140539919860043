import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ReleaseQCHoldDialog from "./ReleaseQCHoldDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  hoUkNo:"",
  ho_OR_NO:"",
  ho_OR_TY:"",
  ho_OR_CO:"",
  ho_CO_CT:"",
  ho_HL_CD:"",
  ho_AP_VR:"", 
  ho_ST_CD:"",
  ho_JB_NO:"",
  ho_JB_TY:"",
  ho_JB_CO:"",
  ho_IT_NM:"",
  item_DESC:"",
  ho_LO_CN:"",
  ho_LT_NO:"",
  ho_CO_QT:"",
  ho_RL_QT:"",
  ho_BL_QT:"",
};

const initialFilters = {
  ho_OR_NO: { value: "", operation: "" },
  ho_OR_TY: { value: "", operation: "" },
  ho_OR_CO: { value: "", operation: "" },
  ho_CO_CT: { value: "", operation: "" },
  ho_HL_CD: { value: "", operation: "" },
  ho_AP_VR: { value: "", operation: "" }, 
  ho_ST_CD: { value: "", operation: "" },
  ho_JB_NO: { value: "", operation: "" },
  ho_JB_TY: { value: "", operation: "" },
  ho_JB_CO: { value: "", operation: "" },
  ho_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },
  ho_LO_CN: { value: "", operation: "" },
  ho_LT_NO: { value: "", operation: "" },
  ho_CO_QT: { value: "", operation: "" },
  ho_RL_QT: { value: "", operation: "" },
  ho_BL_QT: { value: "", operation: "" },
};

const ReleaseQCHoldMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [ReleaseQCHoldtable, setReleaseQCHoldtable] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setReleaseQCHoldtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const addRow = () => {
    setReleaseQCHoldtable([...ReleaseQCHoldtable, {}]);
  };

  const deleteRow = () => {
    if (ReleaseQCHoldtable.length > 0) {
      const updatedData = [...ReleaseQCHoldtable];
      updatedData.pop();
      setReleaseQCHoldtable(updatedData);
    }
  };
  

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ReleaseQCHold/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ReleaseQCHold/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ReleaseQCHoldData = response.data;
        setReleaseQCHoldtable(ReleaseQCHoldData);
        setFormData(ReleaseQCHoldData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFormSubmit =  async () => {
    const  Url = `${domain}/api/ReleaseQCHold/PostErrorBatch`
    const request = {
        "hoUkNo": FormData.hoUkNo,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_OR_NO"] ? filters["ho_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_OR_NO",
                        e.target.value,
                        filters["ho_OR_NO"]
                          ? filters["ho_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_OR_TY"] ? filters["ho_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_OR_TY",
                        e.target.value,
                        filters["ho_OR_TY"]
                          ? filters["ho_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_OR_CO"] ? filters["ho_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_OR_CO",
                        e.target.value,
                        filters["ho_OR_CO"]
                          ? filters["ho_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_CO_CT"] ? filters["ho_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_CO_CT",
                        e.target.value,
                        filters["ho_CO_CT"]
                          ? filters["ho_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["ho_HL_CD"] ? filters["ho_HL_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_HL_CD",
                        e.target.value,
                        filters["ho_HL_CD"]
                          ? filters["ho_HL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_AP_VR"] ? filters["ho_AP_VR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_AP_VR",
                        e.target.value,
                        filters["ho_AP_VR"]
                          ? filters["ho_AP_VR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_ST_CD"] ? filters["ho_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_ST_CD",
                        e.target.value,
                        filters["ho_ST_CD"]
                          ? filters["ho_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_JB_NO"] ? filters["ho_JB_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_JB_NO",
                        e.target.value,
                        filters["ho_JB_NO"]
                          ? filters["ho_JB_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_JB_TY"] ? filters["ho_JB_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_JB_TY",
                        e.target.value,
                        filters["ho_JB_TY"]
                          ? filters["ho_JB_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_JB_CO"] ? filters["ho_JB_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_JB_CO",
                        e.target.value,
                        filters["ho_JB_CO"]
                          ? filters["ho_JB_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_IT_NM"] ? filters["ho_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_IT_NM",
                        e.target.value,
                        filters["ho_IT_NM"]
                          ? filters["ho_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_LO_CN"] ? filters["ho_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_LO_CN",
                        e.target.value,
                        filters["ho_LO_CN"]
                          ? filters["ho_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_LT_NO"] ? filters["ho_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_LT_NO",
                        e.target.value,
                        filters["ho_LT_NO"]
                          ? filters["ho_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_CO_QT"] ? filters["ho_CO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_CO_QT",
                        e.target.value,
                        filters["ho_CO_QT"]
                          ? filters["ho_CO_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_RL_QT"] ? filters["ho_RL_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_RL_QT",
                        e.target.value,
                        filters["ho_RL_QT"]
                          ? filters["ho_RL_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ho_BL_QT"] ? filters["ho_BL_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ho_BL_QT",
                        e.target.value,
                        filters["ho_BL_QT"]
                          ? filters["ho_BL_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Hold Code</th>
                <th style={tableHeaderStyle}>Approver</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Job No</th>
                <th style={tableHeaderStyle}>Type </th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot No</th>
                <th style={tableHeaderStyle}>Completed Quantity </th>
                <th style={tableHeaderStyle}>Realesed Quantity </th>
                <th style={tableHeaderStyle}>Balanced Quantity </th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.hoUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.hoUkNo}
                        checked={selectedRows.includes(rowData.hoUkNo)}
                        onChange={() => handleRowSelect(rowData.hoUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ho_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.ho_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.ho_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.ho_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.ho_HL_CD}</td>
                    <td style={tableCellStyle}>{rowData.ho_AP_VR}</td> 
                    <td style={tableCellStyle}>{rowData.ho_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.ho_JB_NO}</td>
                    <td style={tableCellStyle}>{rowData.ho_JB_TY}</td>
                    <td style={tableCellStyle}>{rowData.ho_JB_CO}</td>
                    <td style={tableCellStyle}>{rowData.ho_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ho_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.ho_LT_NO}</td>
                    <td style={tableCellStyle}>{rowData.ho_CO_QT}</td>
                    <td style={tableCellStyle}>{rowData.ho_RL_QT}</td>
                    <td style={tableCellStyle}>{rowData.ho_BL_QT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ReleaseQCHoldDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}  
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        ReleaseQCHoldtable={ReleaseQCHoldtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default ReleaseQCHoldMain;
