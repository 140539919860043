import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ReNamePopup from "./ReNamePopup";
import SharedWithMePopup from "./SharedWithMePopup";
import SharedWithGroupPopup from "./SharedWithGroup";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function ({
  visible,onClose,optionsSelected,handleResponse,documentType,setoptionsSelected,addressType
}) {
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [renamePopup, setrenamePopup] = useState(false);
  const [sharePopup, setsharePopup] = useState(false);
  const [shareGroupPopup, setshareGroupPopup] = useState(false);

  const handleRenameClick = () => {
    setrenamePopup(true);
  };
      const handleMoveClick = async () => { 
        alert(1);
              };
  const handleDeleteClick = async () => { 
    const fileDetails = {
      "fdUkNo" : optionsSelected
    };
    const url = `${domain}/api/FileManagementSystem/DeleteFileAndFolder`;
    try {
      const response = await axios.post(url, fileDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data;
      handleResponse(listItemsData);
      onClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
   
  const handleShareToGroupClick = async () => { 
    setshareGroupPopup(true);
  };

  const handleShareToEmployeeClick = async () => { 
    setsharePopup(true);
  };

  const handleStarMarkClick = async () => { 
    const fileDetails = {
      "fdUkNo" : optionsSelected
    };
    const requestBody = {
      "documentType" : documentType,
      "fileDetails" : fileDetails
    };
    const url = `${domain}/api/StarMark/StarredMark`;
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data;
      handleResponse(listItemsData);
      onClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
   };
  return (
    <Dialog id="dialog-styleDots"
      visible={visible}
      onHide={onClose}
      style={{height : "40vh", width : "35vh"}}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      //header={dialogHeader}
      modal
      className="p-fluid">
        <div style={{width: "100%", zIndex: 100 }}>
          <div className="row">
            <div className="col-12">
              <table style={{width: "100%"}} >
                <tbody style={{width: "100%"}} >
                  
                <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary" 
                     onClick={() => handleRenameClick()}>Rename</button>
                    </td>
                  </tr>
                  
                  <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary" 
                     onClick={() => handleStarMarkClick()}>Star Mark </button>
                    </td>
                  </tr>
                  
                  <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary"  
                    onClick={() => handleDeleteClick()}>Delete </button>
                    </td>
                  </tr>
                  {/* <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary"  
                    onClick={() => handleMoveClick()}>Move</button>
                    </td>
                  </tr> */}
                  
                  <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary"  
                    onClick={() => handleShareToEmployeeClick()}>Share to Team Member</button>
                    </td>
                  </tr>
                  <tr style={{width: "100%"}}>
                    <td style={{width: "100%"}} >
                    <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary"  
                    onClick={() => handleShareToGroupClick()}>Share to Group</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ReNamePopup
            isOpen={renamePopup}
            onClose={() => setrenamePopup(false)}
            optionsSelected={optionsSelected}
            handleResponse={handleResponse}
            documentType={documentType}
          />
          <SharedWithMePopup
            visible={sharePopup}
            onClose={() => setsharePopup(false)}
            optionsSelected={optionsSelected}
            handleResponse={handleResponse}
            documentType={documentType}
            addressType={addressType}
          />
          <SharedWithGroupPopup
            visible={shareGroupPopup}
            onClose={() => setshareGroupPopup(false)}
            optionsSelected={optionsSelected}
            handleResponse={handleResponse}
            documentType={documentType}
            addressType={addressType}
          />
        </div>
    </Dialog>
  );
}