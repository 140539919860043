import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import LeasePopup from "../Popups/LeasePopup";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function TenantWorkOrderDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  Selectedsupervisor,
  setSelectedsupervisor,
  tw_OR_TY,
  tw_OR_CO,
  tw_TE_NT,
  tw_LE_NO,
  tw_PR_NO,
  tw_BL_NO,
  tw_FL_NO,
  tw_UN_TS,
  tw_CO_CT,
  tw_ST_AT,
  tw_SR_TY,
  tw_PR_TY, 
  tw_TX_AR,
  tw_TX_RT,
  tw_LE_CO,
  tw_LE_TY,
  tw_OR_NO,
}) {
  const {
    twUkNo,
    tenant_NAME,
    tw_TR_DT,
    tw_SU_VR,
    tw_ES_HR,
    tw_ES_AM,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [tw_SR_TYOptions, settw_SR_TYOptions] = useState([]);
  const [tw_ST_ATOptions, settw_ST_ATOptions] = useState([]);
  const [tw_CO_CTOptions, settw_CO_CTOptions] = useState([]);
  const [tw_OR_TYOptions, settw_OR_TYOptions] = useState([]);
  const [tw_PR_NOOptions, settw_PR_NOOptions] = useState([]);
  const [tw_BL_NOOptions, settw_BL_NOOptions] = useState([]);
  const [tw_FL_NOOptions, settw_FL_NOOptions] = useState([]);
  const [tw_PR_TYOptions, settw_PR_TYOptions] = useState([]);
  const [tw_TX_AROptions, settw_TX_AROptions] = useState([]);
  const [tw_TX_RTOptions, settw_TX_RTOptions] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const [addressType, setaddressType] = useState("E");
  const [addressTypeC, setaddressTypeC] = useState("C");
  useEffect(() => {
    fetchOrdertype();
    fetchcost();
    fetchprpty();
    fetchblding();
    fetchflor();
    fetchsevicetype();
    fetchprority();
    fetchtaxarea();
    fetchtaxrate();
    fetchum();
  }, []);
// costcentre
  const fetchcost = async () => {
    const Options = await fetchCostCenter();
    settw_CO_CTOptions(Options);
  };
// property
  const fetchprpty = async () => {
    const Options = await fetchCostCenterP("PT");
    settw_PR_NOOptions(Options);
  };
// building
  const fetchblding = async () => {
    const Options = await fetchCostCenterP("BL");
    settw_BL_NOOptions(Options);
  };
  // Order type
  const fetchOrdertype = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    settw_OR_TYOptions(Options);
  };
// floor
  const fetchflor = async () => {
    const Options = await fetchClassCodeDS("FL/IN");
    settw_FL_NOOptions(Options);
  };
// service type
  const fetchsevicetype = async () => {
    const Options = await fetchClassCodeDS("SR/TY");
    settw_SR_TYOptions(Options);
  };
// priority
  const fetchprority = async () => {
    const Options = await fetchClassCodeDS("WO/PR");
    settw_PR_TYOptions(Options);
  };
  // tax area
  const fetchtaxarea = async () => {
    const Options = await fetchClassCodeDS("FO/TA");
    settw_TX_AROptions(Options);
  };
// tax rate
  const fetchtaxrate = async () => {
    const Options = await fetchClassCodeDS("TX/RT");
    settw_TX_RTOptions(Options);
  };
// status
  const fetchum = async () => {
    const Options = await fetchClassCodeDS("TN/WO");
    settw_ST_ATOptions(Options);
  };
  const [LeasePopupVisible, setLeasePopupVisible] = useState(false);
  const [SupervisorPopupVisible,setSupervisorPopupVisible] = useState(false);
  const [TenantPopupVisible,setTenantPopupVisible] = useState(false);

  const openLeasePopup = () => {
    setLeasePopupVisible(true);
  };
  const handleDataSelect = async (data) => {
    document.getElementById("tw_LE_NO").value =  data.liLeNo;
    onchangeFormData("tw_LE_TY", data.li_LE_TY);
    onchangeFormData("tw_LE_CO", data.li_LE_CO);
    onchangeFormData("tw_TE_NT", data.li_TE_NT);
    document.getElementById("tenant_NAME").value =  data.teanat_NAME;
    onchangeFormData("tw_UN_TS", data.li_UN_TS);
    onchangeDropDownFormData3("tw_PR_NO", data.li_PR_NO);
    onchangeDropDownFormData4("tw_BL_NO", data.li_BL_NO);
    onchangeDropDownFormData7("tw_FL_NO", data.li_FL_NO);
    setLeasePopupVisible(false);
    setShowSearchIcon(false);
  };
  const openSupervisorPopup = () => {
    setSupervisorPopupVisible(true);
  };
  const handleDataSelect1 =async (data) => {
    setSelectedsupervisor(data.ab_AD_NM);
    onchangeFormData("tw_SU_VR", data.abAdNo);
    setSupervisorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 =async (data) => {
    onchangeFormData("tw_TE_NT", data.abAdNo);
    setTenantPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "tw_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(twUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{twUkNo ? "Revise Tenant Work Order" : "Add Tenant Work Order"}</h5>
        <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
          <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
            <Button
              style={savestyle}
              icon="pi pi-check"
              onClick={handleFormSubmit} 
               tooltip="Save"
            />
            <Button
              style={cancelstyle}
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Cancel"
            />
        </div>
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_OR_NO" className="font-bold">
                Order Number
              </label>
              <InputText disabled
                id="tw_OR_NO"
                name="tw_OR_NO"
                value={tw_OR_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_OR_TY" className="font-bold">
                Type
              </label>
                <Dropdown disabled
                id="tw_OR_TY"
                name="tw_OR_TY"
                value={tw_OR_TY}
                options={tw_OR_TYOptions}
                onChange={(e) => onchangeDropDownFormData1("tw_OR_TY", e.value)}
                placeholder="Select  Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="tw_OR_CO"
                name="tw_OR_CO"
                value={tw_OR_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_LE_NO" className="font-bold">
                Lease Number <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="tw_LE_NO"
                  name="tw_LE_NO"
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={tw_LE_NO}
                />
              {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeasePopup}
                ></i>
              )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_LE_TY" className="font-bold">
                Lease Type
              </label>
              <InputText disabled
                id="tw_LE_TY"
                name="tw_LE_TY"
                defaultValue={tw_LE_TY}
              />
            </div>
            <div style={{display:"none"}}>
              <label htmlFor="tw_LE_CO" className="font-bold">
                Lease Company
              </label>
              <InputText disabled
                id="tw_LE_CO"
                name="tw_LE_CO"
                value={tw_LE_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_TE_NT" className="font-bold">
                Tenant
              </label>
              <InputText  style={{ display: "none" }} 
                id="tw_TE_NT"
                name="tw_TE_NT"
                value={tw_TE_NT}
              />
              <InputText disabled
                id="tenant_NAME"
                name="tenant_NAME"
                defaultValue={tenant_NAME}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                id="tw_CO_CT"
                name="tw_CO_CT"
                value={tw_CO_CT}
                options={tw_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData2("tw_CO_CT", e.value)}
                placeholder="Select  Cost Centre"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_SU_VR" className="font-bold">
                Supervisor <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText
                id="sup_NAME"
                name="sup_NAME"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={Selectedsupervisor}
              />
                {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openSupervisorPopup}
                ></i>
                )}
                </div>
              <InputText style={{display:"none"}}
                id="tw_SU_VR"
                name="tw_SU_VR"
                value={tw_SU_VR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_UN_TS" className="font-bold">
                Unit
              </label>
              <InputText disabled
                id="tw_UN_TS"
                name="tw_UN_TS"
                value={tw_UN_TS}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_FL_NO" className="font-bold">
                Floor
              </label>
              <Dropdown disabled
                name="tw_FL_NO"
                value={tw_FL_NO}
                options={tw_FL_NOOptions}
                onChange={(e) =>  onchangeDropDownFormData7("tw_FL_NO", e.value) }
                placeholder="Select floor"
              />
            </div>                
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_BL_NO" className="font-bold">
                Building
              </label>
              <Dropdown disabled
                name="tw_BL_NO"
                value={tw_BL_NO}
                options={tw_BL_NOOptions}
                onChange={(e) => onchangeDropDownFormData4("tw_BL_NO", e.value)}
                placeholder="Select Building"
              />
            </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_PR_NO" className="font-bold">
                Property
              </label>
              <Dropdown disabled
                name="tw_PR_NO"
                value={tw_PR_NO}
                options={tw_PR_NOOptions}
                onChange={(e) =>  onchangeDropDownFormData3("tw_PR_NO", e.value) }
                placeholder="Select Property"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_SR_TY" className="font-bold">
                Service Type
              </label>
              <Dropdown
                id="tw_SR_TY"
                name="tw_SR_TY"
                value={tw_SR_TY}
                options={tw_SR_TYOptions}
                onChange={(e) => onchangeDropDownFormData5("tw_SR_TY", e.value) }
                placeholder="Select Service Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_PR_TY" className="font-bold">
                Priority
              </label>
              <Dropdown
                id="tw_PR_TY"
                name="tw_PR_TY"
                value={tw_PR_TY}
                options={tw_PR_TYOptions}
                onChange={(e) => onchangeDropDownFormData8("tw_PR_TY", e.value)  }
                placeholder="Select Priority"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_TR_DT" className="font-bold">
                Transaction Date
              </label>
              <Calendar
                id="tw_TR_DT"
                name="tw_TR_DT"
                value={moment(tw_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_ST_DT" className="font-bold">
                Start Date
              </label>
              <Calendar
                id="tw_ST_DT"
                name="tw_ST_DT"
                value={moment(data.tw_ST_DT, "YYYYMMDD").isValid() ? moment(data.tw_ST_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "tw_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_CO_DT" className="font-bold">
                Completed Date
              </label>
              <Calendar
                id="tw_CO_DT"
                name="tw_CO_DT"
                value={moment(data.tw_CO_DT, "YYYYMMDD").isValid() ? moment(data.tw_CO_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "tw_CO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_ES_HR" className="font-bold">
                Estimated Hours
              </label>
              <InputText
                id="tw_ES_HR"
                name="tw_ES_HR"
                value={tw_ES_HR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_ES_AM" className="font-bold">
                Estimated Amount
              </label>
              <InputText
                id="tw_ES_AM"
                name="tw_ES_AM"
                value={tw_ES_AM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_TX_AR" className="font-bold">
                Tax Area
              </label>
              <Dropdown
                id="tw_TX_AR"
                name="tw_TX_AR"
                value={tw_TX_AR}
                options={tw_TX_AROptions}
                onChange={(e) => onchangeDropDownFormData9("tw_TX_AR", e.value) }
                placeholder="Select Tax Area"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_TX_RT" className="font-bold">
                Tax Rate
              </label>
              <Dropdown
                id="tw_TX_RT"
                name="tw_TX_RT"
                value={tw_TX_RT}
                options={tw_TX_RTOptions}
                onChange={(e) => onchangeDropDownFormData10("tw_TX_RT", e.value) }
                placeholder="Select Tax Rate"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="tw_ST_AT" className="font-bold">
                Status
              </label>
              <Dropdown
                id="tw_ST_AT"
                name="tw_ST_AT"
                value={tw_ST_AT}
                options={tw_ST_ATOptions}
                onChange={(e) => onchangeDropDownFormData6("tw_ST_AT", e.value)}
                placeholder="Select Status"
              />
            </div>
          </div>
        </div>
      </div>
    <LeasePopup
      visible={LeasePopupVisible}
      onClose={() => setLeasePopupVisible(false)}
      onDataSelect={handleDataSelect}
      formData={data}
      leaseType={tw_LE_TY}
    />
      <AddressBookPopup
      visible={SupervisorPopupVisible}
      onClose={() => setSupervisorPopupVisible(false)}
      onDataSelect={handleDataSelect1}
      formData={data}
      addressType={addressTypeC}
    />
      <AddressBookPopup
      visible={TenantPopupVisible}
      onClose={() => setTenantPopupVisible(false)}
      onDataSelect={handleDataSelect2}
      formData={data}
      addressType={addressType}
    />
    </Dialog>
  );
}
