import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ReceiptReversalDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ReceiptReversalTableData,
  setReceiptReversalTableData,
  rh_VO_RE,
  setrh_VO_RE,
  rh_DO_CO,
}) {
  const {
    rh_UK_NO,
    rh_VO_DT,
  } = data;
  const { borderbox, inboxstyle,savestyle,cancelstyle, Dialogstyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [rh_VO_REOptions, setrh_VO_REOptions] = useState([]);
  //  Reason
  const  ReasonDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrh_VO_RE(value);
  };
  useEffect(() => {
    Reason();
  }, []);
  const Reason = async () => {
    const Options = await fetchClassCodeDS("RV/RE");
    setrh_VO_REOptions(Options);
  };
  const isUpdateMode = Boolean(rh_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{rh_UK_NO ? "Revise Receipt Reversal" : "Add Receipt Reversal"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_VO_DT" className="font-bold">
                Reversal G/L Date
              </label>
              <Calendar
                id="rh_VO_DT"
                name="rh_VO_DT"
                value={moment(data.rh_VO_DT, "YYYYMMDD").isValid() ? moment(data.rh_VO_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "rh_VO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_VO_RE" className="font-bold">
              Reason
              </label>
              <Dropdown
                name="rh_VO_RE"
                value={rh_VO_RE}
                options={rh_VO_REOptions}
                onChange={(e) =>  ReasonDropdown("rh_VO_RE", e.value)}
                placeholder=" Reason "
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
