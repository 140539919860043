import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import PropertyInfoPopup from "../Popups/PropertyInformationPopup";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import UnitPopup from "../Popups/UnitPopup";

import '../../common/TabStyles.css';
export default function CampaignDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  pl_LS_TY,
  setpl_LS_TY,
  pl_LS_CT,
  setpl_LS_CT,
  pl_PR_CT,
  setpl_PR_CT,
  pl_PR_TY,
  setpl_PR_TY,
  pl_LO_CN,
  setpl_LO_CN,
  onchangeDropDownFormData7,
  pl_LO_SB,
  setpl_LO_SB,
  pl_WB_PU,
  onchangeFormData,
  pl_PR_NM,
  onChangeOfUnitSelect,
  pl_UN_NO,
  pl_FL_NO,
  pl_PR_AR,
  pl_BA_RM,
  pl_BE_RM,
  pl_PA_SP,
  pl_ST_CD,
  setpl_ST_CD,
  pl_CO_NO,
  propertyOwner_DESC,
}) {
  const {
    plUkNo,
    pl_BL_NO,
    pl_WB_SI,
    pl_UN_PR,
    pl_AD_L1,
    pl_AD_ST,
    pl_ZI_CD,
    pl_AD_CT,
    pl_AD_CY,
    image_SRC1,
    image_SRC2,
    image_SRC3,
    image_SRC4,
    image_SRC5,
    pt_DESC,

  } = data;
  const { borderbox, inboxstyle, savestyle, cancelstyle, Dialogstyle } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [pl_LS_TYOptions, setpl_LS_TYOptions] = useState([]);
  const [pl_LS_CTOptions, setpl_LS_CTOptions] = useState([]);
  const [pl_PR_CTOptions, setpl_PR_CTOptions] = useState([]);
  const [pl_PR_TYOptions, setpl_PR_TYOptions] = useState([]);
  const [pl_LO_CNOptions, setpl_LO_CNOptions] = useState([]);
  const [pl_LO_SBOptions, setpl_LO_SBOptions] = useState([]);
  const [pl_ST_CDOptions, setpl_ST_CDOptions] = useState([]);
  const [images, setImages] = useState({
    image_SRC1: null,
    image_SRC2: null,
    image_SRC3: null,
    image_SRC4: null,
    image_SRC5: null,
  });

  const [activeTab, setActiveTab] = useState(0);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_LS_TY(value);
  };
  // Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_LS_CT(value);
  };
  // loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_PR_CT(value);
  };
  // lang
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_PR_TY(value);
  };
  // demogr
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_LO_CN(value);
  };
  //
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_LO_SB(value);
  };

  //
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_ST_CD(value);
  };

  useEffect(() => {
    fetchDropdownLestingType();
    fetchDropdownListingCategory();
    fetchDropdownPropertyCatagory();
    fetchDropdownPropertyType();
    fetchDropdownLocation();
    fetchDropdownSubLocation();
    fetchDropdownstatus();

  }, []);

  const fetchDropdownLestingType = async () => {
    const Options = await fetchClassCodeDS("LS/TY");
    setpl_LS_TYOptions(Options);
  };

  const fetchDropdownListingCategory = async () => {
    const Options = await fetchClassCodeDS("LS/CT");
    setpl_LS_CTOptions(Options);
  };

  const fetchDropdownPropertyCatagory = async () => {
    const Options = await fetchClassCodeDS("PR/TY");
    setpl_PR_CTOptions(Options);
  };

  const fetchDropdownPropertyType = async () => {
    const Options = await fetchClassCodeDS("PR/TP");
    setpl_PR_TYOptions(Options);
  };

  const fetchDropdownLocation = async () => {
    const Options = await await fetchClassCodeDS("LO/CN");
    setpl_LO_CNOptions(Options);
  };
  const fetchDropdownSubLocation = async () => {
    const Options = await await fetchClassCodeDS("LO/SB");
    setpl_LO_SBOptions(Options);
  };
  const fetchDropdownstatus = async () => {
    const Options = await fetchClassCodeDS("LS/ST");
    setpl_ST_CDOptions(Options);
  };
  // const onFileChange = (e) => {
  //   const file = e.target.files[0];  // Get the first selected file
  //   if (file) {
  //     console.log('Selected file:', file);
  //     // You can then process the file or upload it
  //   }
  // };
  // Function to handle image selection and preview
  // const onFileChange = (e) => {
  //   const { name, files } = e.target;
  //   const file = files[0];
  //   if (file) {
  //     // Create a URL to display the image
  //     const imageURL = URL.createObjectURL(file);
  //     // Update the state with the new image URL
  //     setImages((prevImages) => ({
  //       ...prevImages,
  //       [name]: imageURL,
  //     }));
  //   }
  // };
  const onFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read the file as base64 data URL
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 string from result
  
        // Pass the base64 string to onchangedata function
        onchangedata({
          target: {
            name: name,
            value: base64String, // This will be sent as part of the DTO
          },
        });
  
        // Optionally: Set a preview for the image
        setImages((prevImages) => ({
          ...prevImages,
          [name]: reader.result, // Set the full data URL for image preview
        }));
      };
    }
  };
  
  
  const handleDataSelect = async (pi_FA_AD) => {
    //setSelectedAbAdNN(data.ab_AD_NM);
    // onchangeFormData("sl_CO_NM", data.abAdNo);
    //  document.getElementById("sl_CO_NM").value = data.abAdNo;
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData = {
      "abAdNo": pi_FA_AD//10008//
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.data;
      if (dataLead) {
        onchangeFormData("pl_AD_L1", dataLead.ab_AD_L1);
        onchangeFormData("pl_AD_CT", dataLead.ab_AD_CT);
        onchangeFormData("pl_AD_ST", dataLead.ab_AD_L2);
        onchangeFormData("pl_ZI_CD", dataLead.ab_AD_PC);
        onchangeFormData("pl_AD_CY", dataLead.ab_AD_CY);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setCompanyPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleDataSelectUnit = async (ua_UN_NO, ua_PR_NO) => {
    const Url = `${domain}/api/UnitAttributes/GetBYProperty`;
    const requestData = {
      "ua_UN_NO": ua_UN_NO,
      "ua_PR_NO": ua_PR_NO,
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result[0];
      if (dataLead) {
        console.log("dataLead:", dataLead);
        document.getElementById("pl_BA_RM").value = dataLead.ua_AB_01;
        document.getElementById("pl_BE_RM").value = dataLead.ua_AB_02;
        document.getElementById("pl_PA_SP").value = dataLead.ua_AB_08;

      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setCompanyPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [UnitPopupVisible, setUnitPopupVisible] = useState(false);
  const openUnitPopup = () => {
    setUnitPopupVisible(true);
  };
  const handleDataSelect1 = (data) => {
    console.log("Data received in handleDataSelect1:", data);
    document.getElementById("pl_UN_NO").value = data.ui_UN_NO;
    document.getElementById("pl_FL_NO").value = data.ui_UN_NO;
    document.getElementById("pl_BL_NO").value = data.bu_DESC;
   
    onChangeOfUnitSelect(data);
    setUnitPopupVisible(false);
    setShowSearchIcon(false);
    handleDataSelectUnit(data.ui_UN_NO, data.ui_PR_NO);
  };



  const [propertyPopupVisible, setpropertyPopupVisible] = useState(false);
  const openpropertyPopup = () => {
    setpropertyPopupVisible(true);
  };
  const handlePropertySelect = (content) => { 
    document.getElementById("pl_PR_NM").value = content.pi_PR_NO;
    document.getElementById("pt_DESC").value = content.pt_DESC;
    document.getElementById("pl_PR_AR").value = content.pi_TR_QT;
    document.getElementById("propertyOwner_DESC").value = content.fac_NAME;

   // document.getElementById("SelectedemEMNO").value = content.fac_NAME;
    // document.getElementById("bi_TX_RT").value =  content.pi_TX_RT;
    // document.getElementById("bi_TX_AR").value =  content.pi_TX_AR;
    //onchangeDropDownFormData5("bi_TX_RT", content.pi_TX_RT);
   // onchangeDropDownFormData6("bi_TX_AR", content.pi_TX_AR);

    // BuildingInformationtable.forEach(s=>{
    //   s['bi_ST_DT']=content.pi_ST_DT;
    //   s['bi_EN_DT']=content.pi_EN_DT;
    // });
    //setbi_ST_DT(content.pi_ST_DT)
    setpropertyPopupVisible(false);
    setShowSearchIcon(false);
    handleDataSelect(content.pi_FA_AD)
  };
  const isUpdateMode = Boolean(plUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
    </React.Fragment>
  );
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{plUkNo ? "Revise Property Listing" : "Add Property Listing"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >

        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Property Listing">
            <div style={inboxstyle} >

              <div className="formgrid grid">
              <div className="field col-sm" style={{display : "none"}}>
                <label htmlFor="plUkNo" className="font-bold">
                  UK_NO
                </label>
                <InputText disabled
                  id="plUkNo"
                  name="plUkNo"
                  value={plUkNo}
                />
              </div>
              <div className="field col-sm" style={{display : "none"}}>
                <label htmlFor="pl_CO_NO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="pl_CO_NO"
                  name="pl_CO_NO"
                  value={pl_CO_NO}
                />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_PR_NM" className="font-bold">
                    Property Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      style={{ display: "none" }}
                      id="pl_PR_NM"
                      name="pl_PR_NM"
                      value={pl_PR_NM}
                    />
                    <InputText
                      id="pt_DESC"
                      name="pt_DESC"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={pt_DESC}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openpropertyPopup}
                      ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor=" propertyOwner_DESC" className="font-bold">
                    Property Owner
                  </label>
                  <InputText
                    id="propertyOwner_DESC"
                    name="propertyOwner_DESC"
                    value={propertyOwner_DESC}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_LS_TY" className="font-bold">
                    Listing Type
                  </label>
                  <Dropdown
                    name="pl_LS_TY"
                    value={pl_LS_TY}
                    options={pl_LS_TYOptions}
                    onChange={(e) => onchangeDropDownFormData1("pl_LS_TY", e.value)}
                    placeholder="Listing Type"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_LS_CT" className="font-bold">
                    Listing Category
                  </label>
                  <Dropdown
                    name="pl_LS_CT"
                    value={pl_LS_CT}
                    options={pl_LS_CTOptions}
                    onChange={(e) => onchangeDropDownFormData2("pl_LS_CT", e.value)}
                    placeholder="Listing Category"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_PR_CT" className="font-bold">
                    Property Catagory
                  </label>
                  <Dropdown
                    name="pl_PR_CT"
                    value={pl_PR_CT}
                    options={pl_PR_CTOptions}
                    onChange={(e) => onchangeDropDownFormData3("pl_PR_CT", e.value)}
                    placeholder="Property Catagory"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_PR_TY" className="font-bold">
                    Property Type
                  </label>
                  <Dropdown
                    name="pl_PR_TY"
                    value={pl_PR_TY}
                    options={pl_PR_TYOptions}
                  // options={[
                  //   { label: "Yes", value: "y" },
                  //   { label: "No", value: "n" },
                  // ]}
                    onChange={(e) => onchangeDropDownFormData4("pl_PR_TY", e.value)}
                    placeholder="Property Type"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_LO_CN" className="font-bold">
                    Location
                  </label>
                  <Dropdown
                    name="pl_LO_CN"
                    value={pl_LO_CN}
                    options={pl_LO_CNOptions}
                    onChange={(e) => onchangeDropDownFormData5("pl_LO_CN", e.value)}
                    placeholder="Location"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_LO_SB" className="font-bold">
                    Sub Location
                  </label>
                  <Dropdown
                    name="pl_LO_SB"
                    value={pl_LO_SB}
                    options={pl_LO_SBOptions}
                    onChange={(e) => onchangeDropDownFormData6("pl_LO_SB", e.value)}
                    placeholder=" Sub Location"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_UN_NO" className="font-bold">
                    Unit <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="pl_UN_NO"
                      name="pl_UN_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={pl_UN_NO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openUnitPopup}
                      ></i>
                    )}
                  </div>
                </div>
                {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_UN_NO" className="font-bold">
                    Unit
                  </label>
                  <InputText
                    id="pl_UN_NO"
                    name="pl_UN_NO"
                    value={pl_UN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div> */}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_FL_NO" className="font-bold">
                    Floor
                  </label>
                  <InputText disabled
                    id="pl_FL_NO"
                    name="pl_FL_NO"
                    defaultValue={pl_FL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_BL_NO" className="font-bold">
                    Building
                  </label>
                  <InputText disabled
                    id="pl_BL_NO"
                    name="pl_BL_NO"
                    value={pl_BL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_PR_AR" className="font-bold">
                    Area
                  </label>
                  <InputText disabled
                    id="pl_PR_AR"
                    name="pl_PR_AR"
                    value={pl_PR_AR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_BA_RM" className="font-bold">
                    Bed Room
                  </label>
                  <InputText disabled
                    id="pl_BA_RM"
                    name="pl_BA_RM"
                    value={pl_BA_RM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_BE_RM" className="font-bold">
                    Bath Room
                  </label>
                  <InputText disabled
                    id="pl_BE_RM"
                    name="pl_BE_RM"
                    value={pl_BE_RM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_PA_SP" className="font-bold">
                    Parking Space
                  </label>
                  <InputText disabled
                    id="pl_PA_SP"
                    name="pl_PA_SP"
                    value={pl_PA_SP}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_ST_CD" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    name="pl_ST_CD"
                    value={pl_ST_CD}
                    options={pl_ST_CDOptions}
                    onChange={(e) => onchangeDropDownFormData8("pl_ADpl_ST_CD_ST", e.value)}
                    placeholder="Status"
                  />
                </div>
                {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_ST_CD" className="font-bold">
                    Status
                  </label>
                  <InputText
                    id="pl_ST_CD"
                    name="pl_ST_CD"
                    value={pl_ST_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div> */}

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_WB_PU" className="font-bold">
                    Publish
                  </label>
                  <Dropdown
                    name="pl_WB_PU"
                    value={pl_WB_PU}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                    ]}
                    onChange={(e) => onchangeDropDownFormData7("pl_WB_PU", e.value)}
                    placeholder="Publish"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_WB_SI" className="font-bold">
                    Website
                  </label>
                  <InputText
                    id="pl_WB_SI"
                    name="pl_WB_SI"
                    value={pl_WB_SI}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_UN_PR" className="font-bold">
                    Price
                  </label>
                  <InputText
                    id="pl_UN_PR"
                    name="pl_UN_PR"
                    value={pl_UN_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>
            </div>

            <div style={inboxstyle} >
              <h2 style={{ fontWeight: 'bold', color: 'black', marginBottom: '20px' }}>Address Information</h2>


              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_AD_L1" className="font-bold">
                    Street
                  </label>
                  <InputText disabled
                    id="pl_AD_L1"
                    name="pl_AD_L1"
                    value={pl_AD_L1}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_AD_CT" className="font-bold">
                    City
                  </label>
                  <InputText disabled
                    id="pl_AD_CT"
                    name="pl_AD_CT"
                    value={pl_AD_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_AD_ST" className="font-bold">
                    State
                  </label>
                  <InputText disabled
                    id="pl_AD_ST"
                    name="pl_AD_ST"
                    value={pl_AD_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_ZI_CD" className="font-bold">
                    Zip Code
                  </label>
                  <InputText disabled
                    id="pl_ZI_CD"
                    name="pl_ZI_CD"
                    value={pl_ZI_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pl_AD_CY" className="font-bold">
                    Country
                  </label>
                  <InputText disabled
                    id="pl_AD_CY"
                    name="pl_AD_CY"
                    value={pl_AD_CY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>
            </div>
          </TabPanel>
          <TabPanel header="Property Image">
            <div style={{ inboxstyle }}>
              <div className="formgrid grid">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div key={i} className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor={`image_SRC${i}`} className="font-bold">
                      Image0{i}
                    </label>
                    <input
                      type="file"
                      id={`image_SRC${i}`}
                      name={`image_SRC${i}`}
                      onChange={onFileChange}
                      accept="image/*"
                    />
                    {images[`image_SRC${i}`] && (
                      <img
                        src={images[`image_SRC${i}`]}
                        alt={`Preview of Image0${i}`}
                        style={{ width: '100%', marginTop: '10px' }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </TabPanel>
        </TabView>

      </div>
      <PropertyInfoPopup
        visible={propertyPopupVisible}
        onClose={() => setpropertyPopupVisible(false)}
        onDataSelect={handlePropertySelect}
      />

      <UnitPopup
        visible={UnitPopupVisible}
        onClose={() => setUnitPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
