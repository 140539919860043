import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function WorkOrderIssueDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkOrderIssueTableData,
  setWorkOrderIssueTableData,
  jb_TR_UM,
  setjb_TR_UM,
  setjb_OR_NO,
  jb_JB_UM,
  setjb_JB_UM,

}) {
  const {
    jbUkNo,
    jb_JB_NO,
    jb_JB_TY,
    jb_JB_CO,
    jb_OR_NO,
    jb_OR_TY,
    jb_OR_CO,
    jb_JB_QT,
    jb_IS_DT,
    // jh_IS_QT,
    jb_PR_NM,
    jb_CO_NM,
  } = data;
  const { plusstyle, minusstyle, tablebutton, tablestyle, borderbox, inboxstyle, savestyle, cancelstyle, Dialogstyle } = ButtonStyles();


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [isDisabled, setIsDisabled] = useState(true);

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();

  const [jb_TR_UMOptions, setjb_TR_UMOptions] = useState([]);
  const [jh_IS_QT, setjh_IS_QT] = useState([]);
  const [jb_JB_UMOptions, setjb_JB_UMOptions] = useState([]);


  // UM
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjb_TR_UM(value);
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjb_JB_UM(value);
  };

  useEffect(() => {
    FetchUM();
    UM();
  }, []);

  const FetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_TR_UMOptions(Options);
  };


  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_JB_UMOptions(Options);
  };

  const addRow = () => {
    setWorkOrderIssueTableData([...WorkOrderIssueTableData, {}]);
  };
  const deleteRow = () => {
    if (WorkOrderIssueTableData.length > 0) {
      const updatedData = [...WorkOrderIssueTableData];
      updatedData.pop();
      setWorkOrderIssueTableData(updatedData);
    }
  };


  const onChangeOfIssueQuantity = async (jh_IS_QT) => {
    const table = document.getElementById("WorkOrderIssuetable");
    const totalRowCount = table.tBodies[0].rows.length;
    const jobOrderDetailsList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const txAP = document.getElementById("jb_OR_QT_" + i).value;
      const txAP1 = document.getElementById("jb_IS_TY_" + i).value;
      const Details = {
        requiredQuantity: txAP,
        issueType: txAP1
      }
      jobOrderDetailsList.push(Details);

      const Url = `${domain}/api/WorkOrderIssue/onChangeOfIssueQuantity`;
      const requestData = {
        jobOrderDetailsList,
        "jobQuantity": document.getElementById("jb_JB_QT").value,
        "issueQuantity": jh_IS_QT,//document.getElementById("jh_IS_QT").value,

      }

      try {
        const response = await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        });

        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data;
        if (dataItMt) {
          document.getElementById(`jb_IS_QT_${SelectedRowIndex}`).value = dataItMt.issuedQuantity;

        } else {
          console.error("Invalid API response:", data);
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const onSuccessOfItemHeader = async () => {
    const Url = `${domain}/api/WorkOrderEntry/GetByWoNumber`;
    const requestData = {
      "wd_WO_NO": document.getElementById("jb_OR_NO").value,
      "wd_WO_TY": document.getElementById("jb_OR_TY").value,
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.Result) {
        document.getElementById("jb_PR_NM").value = data.Result.wd_IT_NM;
      } else {
        console.error("Invalid API response:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const handleSelectItem = (Result) => {
    document.getElementById(`jb_CO_NM_${SelectedRowIndex}`).value = Result.im_IT_NM;
    document.getElementById(`item_DESC_${SelectedRowIndex}`).value = Result.im_IT_DS;
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jbUkNo ? "Revise Material Issue" : "Add Material Issue"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_NO" className="font-bold">
                Related Order
              </label>
              <InputText
                id="jb_OR_NO"
                name="jb_OR_NO"
                value={jb_OR_NO}
                disabled={isDisabled}
                onChange={(e) => {
                  setjb_OR_NO(e.target.value);
                  onSuccessOfItemHeader();
                }}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_TY" className="font-bold">
                Related Type
              </label>
              <InputText disabled
                id="jb_OR_TY"
                name="jb_OR_TY"
                value={jb_OR_TY}
                onChange={(e) => onchangedata(e)}

              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="jb_OR_CO"
                name="jb_OR_CO"
                value={jb_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_NO" className="font-bold">
                Order Number
              </label>
              <InputText disabled
                id="jb_JB_NO"
                name="jb_JB_NO"
                value={jb_JB_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_TY" className="font-bold">
                Order Type
              </label>
              <InputText disabled
                id="jb_JB_TY"
                name="jb_JB_TY"
                value={jb_JB_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_CO" className="font-bold">
                Order Company
              </label>
              <InputText disabled
                id="jb_JB_CO"
                name="jb_JB_CO"
                value={jb_JB_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_PR_NM" className="font-bold">
                Item Number
              </label>
              <InputText disabled
                id="jb_PR_NM"
                name="jb_PR_NM"
                value={jb_PR_NM}
                onChange={(e) => onchangedata(e)}

              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_QT" className="font-bold">
                Order Quantity
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="jb_JB_QT"
                  name="jb_JB_QT"
                  value={jb_JB_QT}
                  onChange={(e) => onchangedata(e)}
                />
                <Dropdown
                  id="jb_JB_UM"
                  name="jb_JB_UM"
                  value={jb_JB_UM}
                  options={jb_JB_UMOptions}
                  onChange={(e) => onchangeDropDownFormData("jb_JB_UM", e.value)}
                  placeholder="UM"
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jh_IS_QT" className="font-bold">
               Issued Quantity
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="jh_IS_QT"
                  name="jh_IS_QT"
                  value={jh_IS_QT}
                  onChange={(e) => onchangedata(e)}
                />
                <Dropdown
                  id="jb_JB_UM"
                  name="jb_JB_UM"
                  value={jb_JB_UM}
                  options={jb_JB_UMOptions}
                  onChange={(e) => onchangeDropDownFormData("jb_JB_UM", e.value)}
                  placeholder="UM"
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jh_IS_QT" className="font-bold">
              Issue Quantity
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="jh_IS_QT"
                  name="jh_IS_QT"
                  value={jh_IS_QT}
                  //onChange={(e) => onchangedata(e)}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setjh_IS_QT(newValue);
                    onChangeOfIssueQuantity(newValue);
                  }}
                />
                <Dropdown
                  id="jb_JB_UM"
                  name="jb_JB_UM"
                  value={jb_JB_UM}
                  options={jb_JB_UMOptions}
                  onChange={(e) => onchangeDropDownFormData("jb_JB_UM", e.value)}
                  placeholder="UM"
                />
              </div>
            </div>
            {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jh_IS_QT" className="font-bold">
                Issue Quantity
              </label>
              <InputText
                id="jh_IS_QT"
                name="jh_IS_QT"
                value={jh_IS_QT}
                // onChange={(e) => onchangedata(e)}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setjh_IS_QT(newValue);
                  onChangeOfIssueQuantity(newValue);
                }}
              />
            </div> */}
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_IS_DT" className="font-bold">
                Issued Date
              </label>
              <Calendar
                id="jb_IS_DT"
                name="jb_IS_DT"
                value={moment(data.jb_IS_DT, "YYYYMMDD").isValid() ? moment(data.jb_IS_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "jb_IS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
        </div>
        <div style={tablestyle}  >
          <div className="table-buttons" style={tablebutton} >
            <Button
              icon="pi pi-plus"
              onClick={addRow}
              style={plusstyle}
              tooltip="Add"
            />
            <Button
              icon="pi pi-minus"
              onClick={deleteRow}
              style={minusstyle}
              tooltip="Delete"
            />
          </div>
          <div style={{ display: "flex", overflow: "auto" }}>
            <table className="custom-table" id="WorkOrderIssuetable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th> Line No </th>
                  <th> Component Item <span style={{ color: 'red' }}>*</span></th>
                  <th> Description </th>
                  <th> Required Quantity </th>
                  <th> UM </th>
                  <th> Issued Quantity </th>
                  <th> Cost Centre </th>
                  <th> Location </th>
                  <th> Stock </th>
                  <th> Issue Type </th>
                </tr>
              </thead>
              <tbody>
                {WorkOrderIssueTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`jbUkNo_${index}`}
                        name={`jbUkNo_${index}`}
                        value={row.jbUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "7rem" }}
                        id={`jb_LN_NO_${index}`}
                        name={`jb_LN_NO_${index}`}
                        value={index + 1}
                      />
                    </td>
                    {/* <td >
                      <InputText style={{ width: "10rem" }}
                        id={`jb_CO_NM_${index}`}
                        name={`jb_CO_NM_${index}`}
                        value={row.jb_CO_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "15rem" }}
                        id={`jb_CO_NM_${index}`}
                        name={`jb_CO_NM_${index}`}
                        value={row.jb_CO_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td> */}
                    <td>
                      <div style={{ display: 'flex' }}>
                        <InputText style={{ width: "10rem" }}
                          id={`jb_CO_NM_${index}`}
                          name={`jb_CO_NM_${index}`}
                          onChange={(e) => onchangedata(e)}
                          onFocus={() => setShowSearchIcon(true)}
                          value={row.jb_CO_NM}
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "15%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openItemPopup(index)}
                          ></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "15rem" }}
                        id={`item_DESC_${index}`}
                        name={`item_DESC_${index}`}
                        value={row.item_DESC}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`jb_OR_QT_${index}`}
                        name={`jb_OR_QT_${index}`}
                        value={row.jb_OR_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`jb_TR_UM_${index}`}
                        name={`jb_TR_UM_${index}`}
                        value={data[`jb_TR_UM_${index}`] ? data[`jb_TR_UM_${index}`] : row.jb_TR_UM}
                        options={jb_TR_UMOptions}
                        onChange={(e) => onchangeDropDownFormData1(`jb_TR_UM_${index}`, e.value)}
                        placeholder="Select Exp Type"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }}
                        id={`jb_IS_QT_${index}`}
                        name={`jb_IS_QT_${index}`}
                        defaultValue={row.jb_IS_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`jb_CO_CT_${index}`}
                        name={`jb_CO_CT_${index}`}
                        value={row.jb_CO_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`jb_LO_CN_${index}`}
                        name={`jb_LO_CN_${index}`}
                        value={row.jb_LO_CN}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`jb_LT_NO_${index}`}
                        name={`jb_LT_NO_${index}`}
                        value={row.jb_LT_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`jb_IS_TY_${index}`}
                        name={`jb_IS_TY_${index}`}
                        value={row.jb_IS_TY}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
        <ItemPopup
          visible={ItemVisible}
          onClose={() => setItemVisible(false)}
          onDataSelect={handleSelectItem}
          formData={data}
        />
      </div>
    </Dialog>
  );
}
