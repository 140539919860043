import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import CrmContactDialog from "./CrmContactDialog";
import axios from "axios";
import '../../../components/ListStyle/ListStyle.css';
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import SecondPopup from "views/admin/Popups/AddressBookPopup";
import ContactOwnerPopup from "views/admin/Popups/AddressBookPopup";
import EmailPopup from "./EmailPopup";
import CallPopup from "./CallPopup";
import  WhatsappPopup  from "./WhatsappPopup";
import  SMSPopup  from "./SMSPopup";
import NotePopup from "./NotePopup";
import TaskPopup from "./TaskPopup";
import ActivityPopup from "./ActivityPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialValue = {
  ab_AD_NM: "",
  cc_AD_NO: "",
  ccCnNo: "",
  cc_CN_NM: "",
  cc_TL_DS: "",
  cc_PH_NO: "",
  cc_EM_IL: "",
  cc_CN_TY: "",
  cc_EM_NO: "",
  cc_EM_NM: "",
  cc_DE_GN: "",
  cc_TY_DS: "",
  ab_AC_IN: "",
  cc_LD_ST: ""
};
const CrmContactMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [cc_CN_TY, setcc_CN_TY] = useState("");
  const [cc_DE_GN, setcc_DE_GN] = useState("");
  const [cc_LD_ST, setcc_LD_ST] = useState("");
  const [ccCnNo, setcc_CN_NO] = useState("");
  const [customer, setCustomer] = useState("");
  const [Owner, setOwner] = useState("");
  const [Status, setStatus] = useState("");
  const [cc_LD_STOptions, setcc_LD_STOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [addressType, setaddressType] = useState("E");
  const [addressTypeC, setaddressTypeC] = useState("C");
  
  const [addStatus, setaddStatus] = useState("");

  const initialFilters = {
    ccCnNo: { value: "", operation: "" },
    //cc_AD_NO: { value:"", operation: "" },
    cc_CN_NM: { value: "", operation: "" },
    cc_TL_DS: { value: "", operation: "" },
    cc_PH_NO: { value: "", operation: "" },
    cc_EM_IL: { value: "", operation: "" },
    ab_AD_NM: { value: "", operation: "" },
    cc_EM_NM: { value: "", operation: "" },
    ab_AC_IN: { value: "", operation: "" },
  };

  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");

  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [selectedowner, setSelectedowner] = useState("");

  const [secondPopupVisible, setSecondPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] =useState(false);
  const [ShowEmailPopup, setShowEmailPopup] = useState(false);
  const [ ShowCallPopup, setShowCallPopup] = useState(false);
  const [ ShowWhatsappPopup, setShowWhatsappPopup] = useState(false);
  const [ ShowSMSPopup, setShowSMSPopup] = useState(false);
  const [ ShowNotePopup, setShowNotePopup] = useState(false);
  const [ ShowTaskPopup, setShowTaskPopup] = useState(false);
  const [ ShowActivityPopup, setShowActivityPopup] = useState(false);
  const [av_CC_NO, setav_CC_NO] = useState("");
  const [av_TO_CL, setav_TO_CL] = useState("");
  const [av_TO_ML, setav_TO_ML] = useState("");
  const [av_FR_CL, setav_FR_CL] = useState("");
  const [ei_HO_ST, setei_HO_ST] = useState("");
  const [ei_PO_RT, setei_PO_RT] = useState("");
  const [ei_PS_WD, setei_PS_WD] = useState("");
  const [ab_AD_EM, setab_AD_EM] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const url11 = `${domain}/api/ClassCode/All`;
  const parameter2 = "EM/ST";
  const finalUrl2 = `${url11}/${parameter2}`;
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcc_LD_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const openSecondPopup = () => {
    setSecondPopupVisible(true);
  };
  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const openEmailPopup = () => {
    var flag = 'E';
    handleOpen(flag);
    setShowEmailPopup(true);
  };
  const handleOpen = async (flag) => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    try {
      const response = await axios.get(
        `${domain}/api/Contact/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
  
      if (response.status === 200) {
        const CrmContactData = response.data;
        setav_CC_NO(response.data.ccCnNo);
        setav_TO_CL(response.data.cc_PH_NO); 
        setav_TO_ML(response.data.cc_EM_IL); 

        if(flag === 'C' || flag === 'S') {
          try {
            const inputParam = {
              "name" : 'CALL_DEFAULT_NO'
            }
            const response = await axios.post(
              `${domain}/api/ErpParameters/GetByRole`, inputParam,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              }
            );
        
            if (response.status === 200) {
              if(response.data.value != "false"){
                setav_FR_CL(response.data.value); 
              }
               
            } else {
              console.error(`API request failed with status: ${response.status}`);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      if( flag === 'W') {
        try {
          const inputParam = {
            "name" : 'WHATSAPP_DEFAULT_NO'
          }
          const response = await axios.post(
            `${domain}/api/ErpParameters/GetByRole`, inputParam,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );
      
          if (response.status === 200) {
            if(response.data.value != "false"){
              setav_FR_CL(response.data.value); 
            }
             
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
       }
       if( flag === 'E') {
        try {
          const response = await axios.get(
            `${domain}/api/Users/Get`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );
      
          if (response.status === 200) {
            setab_AD_EM(response.data.addressBook.ab_AD_EM);
              setei_HO_ST(response.data.email.ei_HO_ST);
              setei_PO_RT(response.data.email.ei_PO_RT);
              setei_PS_WD(response.data.email.ei_PS_WD);
             
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
       }

      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const openCallPopup = () => {
    var flag = 'C';
    handleOpen(flag);
    setShowCallPopup(true);
  };
  const openWhatsappPopup = () => {
    var flag = 'W';
    handleOpen(flag);
    document.getElementById("av_TE_XT_WHATSAPP").value = '';
    document.getElementById("medias").value = '';
    setShowWhatsappPopup(true);
  };
  const openSMSPopup = () => {
    var flag = 'S';
    handleOpen(flag);
    document.getElementById("av_TE_XT_SMS").value = '';
    setShowSMSPopup(true);
  };
  const openNotePopup = () => {
    var flag = 'N';
    handleOpen(flag);
    setShowNotePopup(true);
  }; 
  const openTaskPopup = () => {
    var flag = 'T';
    handleOpen(flag);
    setShowTaskPopup(true);
  };
  const openActivityPopup = () => {
    var flag = 'A';
    handleOpen(flag);
    setShowActivityPopup(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_AD_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters1 = (own) => {
    let updatedFilters = { ...filters };
  if (own !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_EM_NO: {
        value: own,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      cc_LD_ST: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'ccCnNo') {
          constructedColumn = 'ccCnNo';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;
  
    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
  
    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleDataSelect = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setSecondPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const handleDataSelect1 = (data) => {
    setSelectedowner(data.ab_AD_NM);
    setOwner( data.abAdNo);
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters1 (data.abAdNo);
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters2 (value);
    console.log(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setcc_DE_GN(null);
    setcc_CN_TY(null);
    setOpen(true);
    setShowSearchIcon(false);
  };
  const handleClearButton=()=>{
    setCustomer("");
    setOwner("");
    setSelectedcustmer("");
    setSelectedowner("");
    setStatus(null);
    setFilters("");
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/Contact/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CrmContactData = response.data;
          delete CrmContactData.cc_UK_NO;
          delete CrmContactData.ccCnNo;
          setFormData(CrmContactData);
          setSelectedAbAdNM(response.data.cc_EM_NM);
          setSelectedAbAdNN(response.data.ab_AD_NM);
          setcc_DE_GN(response.data.cc_DE_GN);
          setcc_CN_TY(response.data.cc_CN_TY);
          setcc_LD_ST(response.data.cc_LD_ST);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);

  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_LD_ST(value);
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "CC_LD_ST"){
              onchangeDropDownFormData2("cc_LD_ST",dataDefault.versionIds[i].default_VALUE);
              setcc_LD_ST(dataDefault.versionIds[i].default_VALUE);
            } 
			if(fName === "AB_AC_IN"){
              setaddStatus(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/Contact/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeUKFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcc_CN_NO(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    // setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/Contact/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CrmContactData = response.data;
        setFormData(CrmContactData);
        setSelectedAbAdNM(response.data.cc_EM_NM);
        setSelectedAbAdNN(response.data.ab_AD_NM);
        setcc_DE_GN(response.data.cc_DE_GN);
        setcc_CN_TY(response.data.cc_CN_TY);
        setcc_LD_ST(response.data.cc_LD_ST);
        setcc_CN_NO(response.data.ccCnNo);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const searchButtonStyles = {
    backgroundColor: "#3d33d9a6",
    color: "white",
    lineHeight: "10%",
  };

  const CallButtonStyles = {
    backgroundColor: "#258c00d1",
    color: "white",
    lineHeight: "10%",
  };
  const WatssappButtonStyles = {
    backgroundColor: "#21db35d1",
    color: "white",
    lineHeight: "10%",
  };
  const SMSButtonStyles = {
    backgroundColor: "#6ec8c0d1",
    color: "white",
    lineHeight: "10%",
  };
  const NoteButtonStyles = {
    backgroundColor: "#99ab5fdb",
    color: "white",
    lineHeight: "10%",
  };
  const TaskButtonStyles = {
    backgroundColor: "#5f7d90d1",
    color: "white",
    lineHeight: "10%",
  };
  const ActivityButtonStyles = {
    backgroundColor: "#6ec8c0d1",
    color: "white",
    lineHeight: "10%",
  };

  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div style={{ background: "red !important", color: "white", fontSize: "10px", }}>
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important",color: "white", fontSize: "10px", }}>
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important", color: "white", fontSize: "10px",  }} >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
      
    </React.Fragment>
  );
  const tableHeaderStyleH = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f4f4",
    display:"none"
  };
  const emailStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const customerStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    // width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
       <Tooltip target=".envelope-button" position="bottom" mouseTrack>
        <div
          style={{
            backgroundColor: "transparent !important",
            color: "white",
            fontSize: "10px",
          }}
        >
          Email
        </div>
      </Tooltip>
     
      <Button
        style={searchButtonStyles}
        icon="pi pi-envelope"
        outlined
        className="mr-2 envelope-button"
        onClick={openEmailPopup}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".phone-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
          Phone
        </div>
      </Tooltip>
     
      <Button
        style={CallButtonStyles}
        icon="pi pi-phone"
        outlined
        className="mr-2 phone-button"
        onClick={openCallPopup}
        disabled={!isRecordSelected}
      ></Button>
    <Tooltip target=".whatsapp-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
          Whatsapp
        </div>
      </Tooltip>
     
      <Button
        style={WatssappButtonStyles}
        icon="pi pi-whatsapp"
        outlined
        className="mr-2 whatsapp-button"
        onClick={openWhatsappPopup}
        disabled={!isRecordSelected}
      ></Button>

      <Tooltip target=".sms-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
          SMS
        </div>
      </Tooltip>
      <Button
        style={SMSButtonStyles}
        icon="pi pi-envelope"
        outlined
        className="mr-2 sms-button"
        onClick={openSMSPopup}
        disabled={!isRecordSelected}
      ></Button>


    <Tooltip target=".note-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
          Note
        </div>
      </Tooltip> 
      <Button
        style={NoteButtonStyles}
        icon="pi pi-list"
        outlined
        className="mr-2 note-button"
        onClick={openNotePopup}
        disabled={!isRecordSelected}
      ></Button>
       <Tooltip target=".task-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
         Task
        </div>
      </Tooltip>
      <Button
        style={TaskButtonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 task-button"
        onClick={openTaskPopup}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".activity-button" position="bottom" mouseTrack>
        <div className="tooltip-content">
         Activity
        </div>
      </Tooltip>
      <Button
        style={ActivityButtonStyles}
        icon="pi pi-list"
        outlined
        className="mr-2 activity-button"
        onClick={openActivityPopup}
        disabled={!isRecordSelected}
      ></Button>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
      {/* <Button
        style={{ height: "35px" }}
        type="button"
        icon="pi pi-download"
        className="p-button-rounded p-button-success"
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Import"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={handleImport}
        style={{ display: "none" }}
      /> */}
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid" id="headerfilters">
      <div className="field col" >
        <label htmlFor="customer" className="font-bold">
          Customer<span style={{ color: 'red' }}>*</span>
        </label>
        <div>
        </div>
        <div style={{ display: "flex", width:"50%" }}>
          <InputText 
          style={{display:"none"}}
            id="customer"
            name="customer"
            value={customer}
          />
          <InputText 
            id="ab_AD_NM"
            name="ab_AD_NM"
            readOnly
            onChange={(event) => onchangedata(event)}
            onFocus={() => setShowSearchIcon(true)}
            value={selectedcustmer}
            style={{backgroundColor:"#ffffff"}}
            
          />
            {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openSecondPopup}
          ></i>
            )}
        </div>
      </div>
      <div className="field col">
        <label htmlFor="Owner" className="font-bold">
          Owner<span style={{ color: 'red' }}>*</span>
        </label>
        <div style={{ display: "flex", width:"50%"}}>
          <InputText
          style={{display:"none"}}
            id="Owner"
            name="Owner"
            onChange={(event) => onchangedata(event)}
            value={Owner}
          />
            <InputText
            id="cc_EM_NM"
            name="cc_EM_NM"
            readOnly
            style={{backgroundColor:"#ffffff"}}
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={selectedowner}
          />
          {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openContactOwnerPopup}
          ></i>
          )}
          <div>
        </div>
        </div>
      </div>
      <div className="field col" style={{marginLeft:"3%"}}>
        <label htmlFor="status" className="font-bold">
          Status
        </label>
        <Dropdown
        style={{width:"110%"}}
          id="Status"
          name="Status"
          value={Status}
          options={cc_LD_STOptions}
          onChange={(e) => onchangeDropDown("Status", e.value)}
          placeholder="Select Status"
        />
      </div>
    </div>
  );
  const rightClear = (
    <React.Fragment>
      <Tooltip  target=".clear-button" position="bottom" mouseTrack>
        <div>Clear Filter</div>
      </Tooltip>
    <Button
      style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
      icon="pi pi-times"
      className="mr-2 clear-button"
      link
      onClick={handleClearButton}
    />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
       <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4" 
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ccCnNo"] ? filters["ccCnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ccCnNo",
                        e.target.value,
                        filters["ccCnNo"]
                          ? filters["ccCnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_CN_NM"] ? filters["cc_CN_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_CN_NM",
                        e.target.value,
                        filters["cc_CN_NM"]
                          ? filters["cc_CN_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    readOnly
                    style={inputStyleH}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_PH_NO"] ? filters["cc_PH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_PH_NO",
                        e.target.value,
                        filters["cc_PH_NO"]
                          ? filters["cc_PH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_EM_IL"] ? filters["cc_EM_IL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_EM_IL",
                        e.target.value,
                        filters["cc_EM_IL"]
                          ? filters["cc_EM_IL"].operation
                          : "equals"
                      )
                    }
                    style={emailStyle}
                  />
                </td>
                <td>
                  <input
                    readOnly
                    style={inputStyleH}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={customer}
                    style={inputStyleH}
                    
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                    style={inputStyleH}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={Owner}
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["cc_EM_NM"] ? filters["cc_EM_NM"].value : ""}
                    onMouseDown={(e) => e.preventDefault()}
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    type="text"
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Title</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Email</th>
                {/* <th style={tableHeaderStyle}>Type</th> */}
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyleH}>Customer No</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyleH}>Owner ID</th>
                <th style={tableHeaderStyle}>Owner</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.cc_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.cc_UK_NO}
                        checked={selectedRows.includes(rowData.cc_UK_NO)}
                        onChange={() => handleRowSelect(rowData.cc_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ccCnNo}</td>
                    <td style={tableCellStyle}>{rowData.cc_CN_NM}</td>
                    <td style={tableCellStyle}>{rowData.cc_TL_DS}</td>
                    <td style={tableCellStyle}>{rowData.cc_PH_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_EM_IL}</td>
                    {/* <td style={tableCellStyle}>{rowData.cc_CN_TY}</td> */}
                    <td style={tableCellStyle}>{rowData.cc_TY_DS}</td>
                    <td style={tableCellStyleH}>{rowData.cc_AD_NO}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={tableCellStyleH}>{rowData.cc_EM_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_EM_NM}</td>
                    <td style={tableCellStyle}>{rowData.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <SecondPopup
        visible={secondPopupVisible}
        onClose={() => setSecondPopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressTypeC}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        addressType={addressType}
      />
      <EmailPopup
        av_FR_ML={ab_AD_EM}
        password={ei_PS_WD}
        smtpPort={ei_PO_RT}
        smtpServer={ei_HO_ST}
        av_TO_ML={av_TO_ML}
        av_CC_NO={av_CC_NO}
        visible={ShowEmailPopup}
        onClose={() => setShowEmailPopup(false)}
      />
      <CallPopup
        av_FR_CL = {av_FR_CL}
        av_CC_NO={av_CC_NO}
        av_TO_CL={av_TO_CL}
        visible={ShowCallPopup}
        onClose={() => setShowCallPopup(false)}
      />
       <WhatsappPopup
       av_TO_CL={av_TO_CL}
        av_FR_CL={av_FR_CL}
        av_CC_NO={av_CC_NO}
        visible={ShowWhatsappPopup}
        onClose={() => setShowWhatsappPopup(false)}
      />
      <SMSPopup
        av_TO_CL={av_TO_CL}
        av_FR_CL={av_FR_CL}
        av_CC_NO={av_CC_NO}
        visible={ShowSMSPopup}
        onClose={() => setShowSMSPopup(false)}
      />
        <NotePopup
        av_CC_NO={av_CC_NO}
        visible={ShowNotePopup}
        onClose={() => setShowNotePopup(false)}
      />
       <TaskPopup
        av_CC_NO={av_CC_NO}
        visible={ShowTaskPopup}
        onClose={() => setShowTaskPopup(false)}
      />
      <ActivityPopup
        av_CC_NO={av_CC_NO}
        visible={ShowActivityPopup}
        onClose={() => setShowActivityPopup(false)}
      />
      <CrmContactDialog
        open={open}
        setOpen={setOpen}
        data={FormData}
        onchangedata={onchangedata}
        handleDefault={handleDefault}
        cc_CN_TY={cc_CN_TY}
        cc_LD_ST={cc_LD_ST}
        ccCnNo={ccCnNo}
        cc_DE_GN={cc_DE_GN}
        setcc_CN_TY={setcc_CN_TY}
        setcc_DE_GN={setcc_DE_GN}
        setcc_LD_ST={setcc_LD_ST}
        setcc_CN_NO={setcc_CN_NO}
        initialValue={initialValue}
        selectedAbAdNM={selectedAbAdNM}
        selectedAbAdNN={selectedAbAdNN}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
		    addStatus={addStatus}
        FormData={FormData}
        setFormData={setFormData}
        onchangeUKFormData={onchangeUKFormData}
        handleDataSelect={handleDataSelect}
        handleDataSelect1={handleDataSelect1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
      />
    </div>
  );
};

export default CrmContactMain;
