import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
import { DATA_GRID_DEFAULT_SLOTS_COMPONENTS } from "@mui/x-data-grid/internals";
export default function ShippingDetailsDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ShippingDetailsTable,
  setShippingDetailsTable,
  CurrencyDropDown,
  onchangegldate,
  setonchangegldate,

  setselectedItem,
  setselectedDesc,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemSOTO,
  setSelectedemSOTO,

  SelectedemEMNOC,
  setSelectedemEMNOC,
  SelectedemEMNOD,
  setSelectedemEMNOD,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  selectedAbAdNM,
  setSelectedAbAdNM,

  handleConfirm,
  price_AMOUNT,
  discount_AMOUNT,
  net_AMOUNT,
  F_EXT_PRICE,
  F_EXT_COST,
  F_FORE_PRICE,
  tax_AMOUNT,
  total,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  fr_TOTAL,
  setselectedSeNO,
  setselectedLoc,
  CostCentreDropDown,
  StatusDropdown,

  sd_SH_DE,
  setsd_SH_DE,
  sd_ST_AT,
  setsd_ST_AT,
  sd_SH_SO,
  setsd_SH_SO,
  sd_CI_TY,
  setsd_CI_TY,
  sd_OR_GN,
  setsd_OR_GN,
  sd_PO_CD,
  setsd_PO_CD,
  sd_ST_CD,
  setsd_ST_CD,
  sd_RT_CD,
  setsd_RT_CD,
  sd_TR_CR,
  setsd_TR_CR,
  sd_ZN_CD,
  setsd_ZN_CD,
  sd_BS_CR,
  setsd_BS_CR,

  setsd_EX_RT,
  sd_TR_UM,
  setsd_TR_UM,
  sd_WT_UM,
  setsd_WT_UM,
  sd_VL_UM,
  setsd_VL_UM,
  sd_DS_GP,
  setsd_DS_GP,
  sd_OR_TY,
  setsd_OR_TY,
  sd_MD_TR,
  setsd_MD_TR,
  sd_FR_CD,
  setsd_FR_CD,
  // ADSVANCSE SEACRH
  SelectedSold,
  setSelectedSold,
  SelectedShip,
  setSelectedShip,
  SelectedItem,
  setSelectedItem,
  SelectedCarrier,
  setSelectedCarrier,

  sd_OR_CO,
}) {
  const {
    sdUkNo,
    sd_SH_NO,

    sd_DA_TE,
    sd_SH_TO,
    sd_SO_TO,
    sd_EX_RT,
    sd_IT_NO,
    sd_SH_QT,
    sd_WE_GT,
    sd_VL_ME,
    sd_EX_PR,
    sd_EX_CT,
    sd_FR_PR,
    sd_PS_DT,
    sd_PS_TM,
    sd_PD_DT,
    sd_PD_TM,
    sd_AS_DT,
    sd_AS_TM,
    sd_AD_DT,
    sd_AD_TM,
    sd_NO_PE,
    sd_CR_NO,
    sd_NO_CO,

  } = data;

  const { footergridstyle, footerfieldstyle, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, savestyle, TabBoxStyle, cancelstyle, Dialogstyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sd_DA_TE", value: moment(e.value).format("YYYYMMDD") } });

  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [sd_SH_DEOptions, setsd_SH_DEOptions] = useState([]);
  const [sd_ST_ATOptions, setsd_ST_ATOptions] = useState([]);
  const [sd_SH_SOOptions, setsd_SH_SOOptions] = useState([]);
  const [sd_CI_TYOptions, setsd_CI_TYOptions] = useState([]);
  const [sd_OR_GNOptions, setsd_OR_GNOptions] = useState([]);
  const [sd_PO_CDOptions, setsd_PO_CDOptions] = useState([]);
  const [sd_ST_CDOptions, setsd_ST_CDOptions] = useState([]);
  const [sd_RT_CDOptions, setsd_RT_CDOptions] = useState([]);
  const [sd_TR_CROptions, setsd_TR_CROptions] = useState([]);

  const [sd_ZN_CDOptions, setsd_ZN_CDOptions] = useState([]);
  const [sd_BS_CROptions, setsd_BS_CROptions] = useState([]);
  const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);
  const [sd_WT_UMOptions, setsd_WT_UMOptions] = useState([]);
  const [sd_VL_UMOptions, setsd_VL_UMOptions] = useState([]);
  const [sd_DS_GPOptions, setsd_DS_GPOptions] = useState([]);
  const [sd_MD_TROptions, setsd_MD_TROptions] = useState([]);
  const [sd_FR_CDOptions, setsd_FR_CDOptions] = useState([]);
  const [sd_OR_TYOptions, setsd_OR_TYOptions] = useState([]);



  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  //Type
  const onchangeDropDownFormDataType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_OR_TY(value);
  };
  //ShipmentDepot
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_SH_DE(value);
  };
  //Status
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_ST_AT(value);
  };
  // ShipmentSource
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_SH_SO(value);
  };
  // City
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_CI_TY(value);
  };
  // Origin
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_OR_GN(value);
  };
  // Postal Code
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_PO_CD(value);
  };
  //State
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_ST_CD(value);
  };
  //Route
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_RT_CD(value);
  };
  //Currency
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_TR_CR(value);
  };
  //Zone       
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_ZN_CD(value);
  };
  //BaseCurrency
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_BS_CR(value);
  };
  //QUantityUM
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_TR_UM(value);
  };
  //WeightUM
  const WeightUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_WT_UM(value);
  };
  //VolumeUM
  const VolumeUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_VL_UM(value);
  };
  //DispatchGroup
  const DispatchGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_DS_GP(value);
  };
  //ModeOfTransport
  const onchangeDropDownModeOfTransport = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_MD_TR(value);
  };
  //Freight
  const onchangeDropDownModeOfFreight = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsd_FR_CD(value);
  };

  useEffect(() => {
    ShipmentDepot();
    Status();
    ShipmentSource();
    City();
    Origin();
    PostalCode();
    State();
    Route();
    Currency();
    Zone();
    BaseCurrency();
    QUantityUM();
    WeightUM();
    VolumeUM();
    DispatchGroup();
    ModeOfTransport();
    Freight();
    Type();

  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setsd_OR_TYOptions(Options);
  };
  const ShipmentDepot = async () => {
    const Options = await fetchCostCenter();
    setsd_SH_DEOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setsd_ST_ATOptions(Options);
  };
  const ShipmentSource = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setsd_SH_SOOptions(Options);
  };
  const City = async () => {
    const Options = await fetchClassCodeDS("FO/CI");
    setsd_CI_TYOptions(Options);
  };
  const Origin = async () => {
    const Options = await fetchCostCenter();
    setsd_OR_GNOptions(Options);
  };
  const PostalCode = async () => {
    const Options = await fetchClassCodeDS("PS/DT");
    setsd_PO_CDOptions(Options);
  };



  const State = async () => {
    const Options = await fetchClassCodeDS("FO/ST");
    setsd_ST_CDOptions(Options);
  };

  const Route = async () => {
    const Options = await fetchClassCodeDS("RT/CD");
    setsd_RT_CDOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsd_TR_CROptions(Options);
  };
  const Zone = async () => {
    const Options = await fetchClassCodeDS("ZO/NO");
    setsd_ZN_CDOptions(Options);
  };

  const BaseCurrency = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setsd_BS_CROptions(Options);
  };

  const QUantityUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsd_TR_UMOptions(Options);
  };
  const WeightUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsd_WT_UMOptions(Options);
  };
  const VolumeUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsd_VL_UMOptions(Options);
  };
  const DispatchGroup = async () => {
    const Options = await fetchClassCodeDS("DS/GP");
    setsd_DS_GPOptions(Options);
  };
  const ModeOfTransport = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setsd_MD_TROptions(Options);
  };
  const Freight = async () => {
    const Options = await fetchClassCodeDS("FO/FH");
    setsd_FR_CDOptions(Options);
  };



  // Advance Search
  const [SoldToPopupVisible, setSoldToPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [CarrierType, setCarrierType] = useState("E");
  const [ShipToType, setShipToType] = useState("E");
  const [SoldTotype, setSoldTotype] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const openSoldToPopup = () => {
    setSoldToPopupVisible(true);
  };

  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const openCarrierPopup = () => {
    setShipToPopupVisible(true);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedSold(data.ab_AD_NM);
    onchangeFormData("sd_SO_TO", data.abAdNo);
    // document.getElementById("sd_SH_TO").value = data.abAdNo;
    setSoldToPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleDataSelectShip = (data) => {
    setSelectedShip(data.ab_AD_NM);
    onchangeFormData("sd_SH_TO", data.abAdNo);
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectCarrier = (data) => {
    setSelectedCarrier(data.ab_AD_NM);
    onchangeFormData("sd_CR_NO", data.abAdNo);
    setCarrierPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleItem = async (data) => {
    onchangeFormData("item_DES", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("sd_IT_NO", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setShippingDetailsTable([...ShippingDetailsTable, {}]);
  };
  const deleteRow = () => {
    if (ShippingDetailsTable.length > 0) {
      const updatedData = [...ShippingDetailsTable];
      updatedData.pop();
      setShippingDetailsTable(updatedData);
    }
  };
  const [activeTab, setActiveTab] = useState(0);



  const onChangeOfPrice = async (e) => {
    const table = document.getElementById("ShippingDetailsTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const ShippingDetailsList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        sdUkNo: document.getElementById("sdUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      ShippingDetailsList.push(details);
    }
    const requestBodyNet = {
      ShippingDetailsList,
    };

    const response = await axios.post(`${domain}/api/ShippingDetails/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("F_EXT_PRICE").value = respNet.f_EXT_PRICE;
      document.getElementById("F_EXT_COST").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  const apicurr = `${domain}/api/ExChangeRate/Rate`
  const currencychnage = async () => {
    const requestData = {
      "cr_TO_CR": document.getElementById("sd_BS_CR").value, //base curr
      "cr_FR_CR": data.sd_TR_CR,//currency
      "cr_EF_DT": data.sd_DA_TE,
    }

    try {
      const response = await axios.post(apicurr, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      const datacur = response.data.Result;
      if (datacur) {
        document.getElementById("sd_EX_RT").value = datacur.cr_CO_MR;
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };



  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  const isUpdateMode = Boolean(sdUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sdUkNo ? "Revise Shipping Details" : "Add Shipping Details"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        {activeTab === 0 && (
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
        )}
        {activeTab === 0 && (
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="sdUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="sdUkNo"
                    name="sdUkNo"
                    defaultValue={sdUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_SH_NO" className="font-bold">
                    Shipment No
                  </label>
                  <InputText disabled
                    id="sd_SH_NO"
                    name="sd_SH_NO"
                    value={sd_SH_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_OR_TY" className="font-bold">
                    Shipment Depot
                  </label>
                  <Dropdown
                    name="sd_OR_TY"
                    value={sd_OR_TY}
                    options={sd_OR_TYOptions}
                    onChange={(e) => onchangeDropDownFormDataType("sd_OR_TY", e.value)}
                    placeholder="Select  Type"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="sd_OR_CO"
                    name="sd_OR_CO"
                    value={sd_OR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_DA_TE" className="font-bold">
                    Date
                  </label>
                  <Calendar
                    id="sd_DA_TE"
                    name="sd_DA_TE"
                    value={moment(sd_DA_TE, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_SH_DE" className="font-bold">
                    Shipment Depot
                  </label>
                  <Dropdown
                    name="sd_SH_DE"
                    value={sd_SH_DE}
                    options={sd_SH_DEOptions}
                    onChange={(e) => onchangeDropDownFormData1("sd_SH_DE", e.value)}
                    placeholder="Select  Shipment Depot"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_ST_AT" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    name="sd_ST_AT"
                    value={sd_ST_AT}
                    options={sd_ST_ATOptions}
                    onChange={(e) => onchangeDropDownFormData2("sd_ST_AT", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_SH_SO" className="font-bold">
                    Shipment Source
                  </label>
                  <Dropdown
                    name="sd_SH_SO"
                    value={sd_SH_SO}
                    options={sd_SH_SOOptions}
                    onChange={(e) => onchangeDropDownFormData3("sd_SH_SO", e.value)}
                    placeholder="Select   Shipment Source"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_CI_TY" className="font-bold">
                    City
                  </label>
                  <Dropdown
                    name="sd_CI_TY"
                    value={sd_CI_TY}
                    options={sd_CI_TYOptions}
                    onChange={(e) => onchangeDropDownFormData4("sd_CI_TY", e.value)}
                    placeholder="Select City"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_OR_GN" className="font-bold">
                    Origin
                  </label>
                  <Dropdown
                    name="sd_OR_GN"
                    value={sd_OR_GN}
                    options={sd_OR_GNOptions}
                    onChange={(e) => onchangeDropDownFormData5("sd_OR_GN", e.value)}
                    placeholder="Select Origin"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PO_CD" className="font-bold">
                    Postal Code
                  </label>
                  <Dropdown
                    name="sd_PO_CD"
                    value={sd_PO_CD}
                    options={sd_PO_CDOptions}
                    onChange={(e) => onchangeDropDownFormData6("sd_PO_CD", e.value)}
                    placeholder="Select Postal Code"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_SH_TO" className="font-bold">
                    Ship To <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM1"
                      name="ab_AD_NM1"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedShip}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openShipToPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="sd_SH_TO"
                      name="sd_SH_TO"
                      value={sd_SH_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_ST_CD" className="font-bold">
                    State
                  </label>
                  <Dropdown
                    name="sd_ST_CD"
                    value={sd_ST_CD}
                    options={sd_ST_CDOptions}
                    onChange={(e) => onchangeDropDownFormData7("sd_ST_CD", e.value)}
                    placeholder="Select State"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_SO_TO" className="font-bold">
                    Sold To <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedSold}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openSoldToPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="sd_SO_TO"
                      name="sd_SO_TO"
                      value={sd_SO_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_RT_CD" className="font-bold">
                    Route
                  </label>
                  <Dropdown
                    name="sd_RT_CD"
                    value={sd_RT_CD}
                    options={sd_RT_CDOptions}
                    onChange={(e) => onchangeDropDownFormData8("sd_RT_CD", e.value)}
                    placeholder="Select Route"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_TR_CR" className="font-bold">
                    Currency
                  </label>
                  <Dropdown
                    name="sd_TR_CR"
                    value={sd_TR_CR}
                    options={sd_TR_CROptions}
                    // onChange={(e) => onchangeDropDownFormData9("sd_TR_CR", e.value)}
                    onChange={(e) => {
                      onchangeDropDownFormData9("sd_TR_CR", e.value);
                      currencychnage(); // Call the function after successful change
                    }}
                    placeholder="Select Currency"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_EX_RT" className="font-bold">
                    Exchange Rate
                  </label>
                  <InputText disabled
                    id="sd_EX_RT"
                    name="sd_EX_RT"
                    value={sd_EX_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_ZN_CD" className="font-bold">
                    Zone
                  </label>
                  <Dropdown
                    name="sd_ZN_CD"
                    value={sd_ZN_CD}
                    options={sd_ZN_CDOptions}
                    onChange={(e) => onchangeDropDownFormData10("sd_ZN_CD", e.value)}
                    placeholder="Select Zone"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="sd_BS_CR"
                    name="sd_BS_CR"
                    value={sd_BS_CR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>
            </div>
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
                {(
                  <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
                )}
                {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="ShippingDetailsTable">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th> Line No</th>
                      <th>Item Number <span style={{ color: 'red' }}>*</span></th>
                      <th>ItemDes </th>
                      <th>Quantity</th>
                      <th>UM</th>
                      <th>Weight</th>
                      <th>UM</th>
                      <th>Volume</th>
                      <th>UM</th>
                      <th>Extended Price</th>
                      <th>Extended Cost</th>
                      <th>Foreign Price</th>
                      <th>Dispatch Group</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ShippingDetailsTable.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`sdUkNo_${index}`}
                            name={`sdUkNo_${index}`}
                            defaultValue={row.sdUkNo}
                          />
                        </td>
                        <td >
                          <InputText disabled style={{ width: "90px" }}
                            id={`sd_LN_NO_${index}`}
                            name={`sd_LN_NO_${index}`}
                            value={index + 1}
                            readOnly
                          />
                        </td>

                        <td style={{ display: "flex" }}>
                          <InputText
                            style={{ width: "110px" }}
                            id={`sd_IT_NO_${index}`}
                            name={`sd_IT_NO_${index}`}
                            onChange={(e) => onchangeFormData(e)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={row.sd_IT_NO ? row.sd_IT_NO : row[`${"sd_IT_NO"}_${index}`]}
                          />
                          {showSearchIcon && (
                            <i
                              className="pi pi-search"
                              style={{
                                color: "var(--primary-color)",
                                fontSize: "119%",
                                marginTop: "15%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => openItemPopup(index)}
                            ></i>
                          )}
                        </td>
                        <td>
                          <InputText disabled
                            style={{ width: "200px" }}
                            id={`item_DES_${index}`}
                            name={`item_DES_${index}`}
                            value={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                            readOnly
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_SH_QT_${index}`}
                            name={`sd_SH_QT_${index}`}
                            defaultValue={row.sd_SH_QT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`sd_TR_UM_${index}`}
                            name={`sd_TR_UM_${index}`}
                            value={data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`] : row.sd_TR_UM}
                            options={sd_TR_UMOptions}
                            onChange={(e) => UMDropdown(`sd_TR_UM_${index}`, e.value)}
                            placeholder="Select UM"
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_WE_GT_${index}`}
                            name={`sd_WE_GT_${index}`}
                            defaultValue={row.sd_WE_GT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`sd_WT_UM_${index}`}
                            name={`sd_WT_UM_${index}`}
                            value={data[`sd_WT_UM_${index}`] ? data[`sd_WT_UM_${index}`] : row.sd_WT_UM}
                            options={sd_WT_UMOptions}
                            onChange={(e) => WeightUMDropdown(`sd_WT_UM_${index}`, e.value)}
                            placeholder="Select UM"
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_VL_ME_${index}`}
                            name={`sd_VL_ME_${index}`}
                            defaultValue={row.sd_VL_ME}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`sd_VL_UM_${index}`}
                            name={`sd_VL_UM_${index}`}
                            value={data[`sd_VL_UM_${index}`] ? data[`sd_VL_UM_${index}`] : row.sd_VL_UM}
                            options={sd_VL_UMOptions}
                            onChange={(e) => VolumeUMDropdown(`sd_VL_UM_${index}`, e.value)}
                            placeholder="Select UM"
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_EX_PR_${index}`}
                            name={`sd_EX_PR_${index}`}
                            defaultValue={row.sd_EX_PR}
                            onChange={(e) => onchangedata(e)}
                          // onChange={(e) => {onchangedata(e);onChangeOfPrice(e);}}

                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_EX_CT_${index}`}
                            name={`sd_EX_CT_${index}`}
                            defaultValue={row.sd_EX_CT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`sd_FR_PR_${index}`}
                            name={`sd_FR_PR_${index}`}
                            defaultValue={row.sd_FR_PR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`sd_DS_GP_${index}`}
                            name={`sd_DS_GP_${index}`}
                            value={data[`sd_DS_GP_${index}`] ? data[`sd_DS_GP_${index}`] : row.sd_DS_GP}
                            options={sd_DS_GPOptions}
                            onChange={(e) => VolumeUMDropdown(`sd_DS_GP_${index}`, e.value)}
                            placeholder="Select Dispatch Group"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={tablestyle} >
              <div className="formgrid grid" style={footergridstyle}>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="F_EXT_PRICE" className="font-bold">
                    Extended Price
                  </label>
                  <InputText disabled
                    id="F_EXT_PRICE"
                    name="F_EXT_PRICE"
                    defaultValue={F_EXT_PRICE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="F_EXT_COST" className="font-bold">
                    Extended Cost
                  </label>
                  <InputText disabled
                    id="F_EXT_COST"
                    name="F_EXT_COST"
                    defaultValue={F_EXT_COST}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="F_FORE_PRICE" className="font-bold">
                    Foreign Price
                  </label>
                  <InputText disabled
                    id="F_FORE_PRICE"
                    name="F_FORE_PRICE"
                    defaultValue={F_FORE_PRICE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>
            </div>
          </TabPanel>
          <TabPanel header="Time & Date ">
            <div style={tablestyle} >

              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PS_DT" className="font-bold">
                    Promised Ship Date
                  </label>
                  <Calendar
                    id="sd_PS_DT"
                    name="sd_PS_DT"
                    value={moment(data.sd_PS_DT, "YYYYMMDD").isValid() ? moment(data.sd_PS_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "sd_PS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PS_TM" className="font-bold">
                    Promised Ship Time
                  </label>
                  <InputMask
                    id={sd_PS_TM}
                    name={sd_PS_TM}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("sd_PS_TM", e.target.value,); }}
                    value={moment(sd_PS_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PD_DT" className="font-bold">
                    Promised Delivery Date
                  </label>
                  <Calendar
                    id="sd_PD_DT"
                    name="sd_PD_DT"
                    value={moment(data.sd_PD_DT, "YYYYMMDD").isValid() ? moment(data.sd_PD_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "sd_PD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PD_TM" className="font-bold">
                    Promised Delivery Time
                  </label>
                  <InputMask
                    id={sd_PD_TM}
                    name={sd_PD_TM}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("sd_PD_TM", e.target.value,); }}
                    value={moment(sd_PD_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_AS_DT" className="font-bold">
                    Actual Ship Date
                  </label>
                  <Calendar
                    id="sd_AS_DT"
                    name="sd_AS_DT"
                    value={moment(data.sd_AS_DT, "YYYYMMDD").isValid() ? moment(data.sd_AS_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "sd_AS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_AS_TM" className="font-bold">
                    Actual Ship Time
                  </label>
                  <InputMask
                    id={sd_AS_TM}
                    name={sd_AS_TM}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("sd_PS_TM", e.target.value,); }}
                    value={moment(sd_AS_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_AD_DT" className="font-bold">
                    Actual Delivery Date
                  </label>
                  <Calendar
                    id="sd_AD_DT"
                    name="sd_AD_DT"
                    value={moment(data.sd_AD_DT, "YYYYMMDD").isValid() ? moment(data.sd_AD_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: { name: "sd_AD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_AD_TM" className="font-bold">
                    Actual Delivery Time
                  </label>
                  <InputMask
                    id={sd_AD_TM}
                    name={sd_AD_TM}
                    mask="99:99:99"
                    onBlur={(e) => { onChangeTime("sd_AD_TM", e.target.value,); }}
                    value={moment(sd_AD_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_MD_TR" className="font-bold">
                    Mode of Transport
                  </label>
                  <Dropdown
                    name="sd_MD_TR"
                    value={sd_MD_TR}
                    options={sd_MD_TROptions}
                    onChange={(e) => onchangeDropDownModeOfTransport("sd_MD_TR", e.value)}
                    placeholder="Select Mode of Transport"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_NO_PE" className="font-bold">
                    No of Pieces
                  </label>
                  <InputText
                    id="sd_NO_PE"
                    name="sd_NO_PE"
                    value={sd_NO_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_CR_NO" className="font-bold">
                    Carrier No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM2"
                      name="ab_AD_NM2"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedCarrier}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openCarrierPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="sd_CR_NO"
                      name="sd_CR_NO"
                      value={sd_CR_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_NO_CO" className="font-bold">
                    No of Containers
                  </label>
                  <InputText
                    id="sd_NO_CO"
                    name="sd_NO_CO"
                    value={sd_NO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_FR_CD" className="font-bold">
                    Freight
                  </label>
                  <Dropdown
                    name="sd_FR_CD"
                    value={sd_FR_CD}
                    options={sd_FR_CDOptions}
                    onChange={(e) => onchangeDropDownModeOfFreight("sd_FR_CD", e.value)}
                    placeholder="Select Freight"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      {/* SOLD TO POPUP */}
      <AddressBookPopup
        visible={SoldToPopupVisible}
        onClose={() => setSoldToPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={SoldTotype}
        setFormData={setFormData}
      />
      {/* SHIP TO POPUP */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelectShip}
        formData={data}
        addressType={ShipToType}
        setFormData={setFormData}
      />
      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      {/* Carrier  No POPUP */}
      <AddressBookPopup
        visible={CarrierPopupVisible}
        onClose={() => setCarrierPopupVisible(false)}
        onDataSelect={handleDataSelectCarrier}
        formData={data}
        addressType={CarrierType}
        setFormData={setFormData}
      />

    </Dialog>

  );
}
