import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BedDetailsDialog from "./BedDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  bdUkNo: "",
  bd_PA_ID: "",
  bd_PA_NM: "",
  bd_VI_NO: "",
  bd_ST_CD: "",
  bd_DE_PT: "",
  bd_RM_TY: "",
  bd_BD_NO: "",
  bd_IN_DT: moment(moment().toDate()).format("YYYYMMDD"),
  bd_IN_TM: "",
  bd_OT_DT: moment(moment().toDate()).format("YYYYMMDD"),
  bd_OT_TM: "",

};

const initialFilters = {
  bd_PA_ID: { value: "", operation: "" },
  bd_PA_NM: { value: "", operation: "" },
  bd_VI_NO: { value: "", operation: "" },
  bd_ST_CD: { value: "", operation: "" },
  bd_DE_PT: { value: "", operation: "" },
  bd_RM_TY: { value: "", operation: "" },
  bd_BD_NO: { value: "", operation: "" },
  bd_IN_DT: { value: "", operation: "" },
  bd_IN_TM: { value: "", operation: "" },
  bd_OT_DT: { value: "", operation: "" },
  bd_OT_TM: { value: "", operation: "" },
};

const BedDetailsMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  //const [bdUkNo,  setbdUkNo] = useState([]);
  const [bd_DE_PT, setbd_DE_PT] = useState([]);
  const [bd_RM_TY, setbd_RM_TY] = useState([]);
  const [bd_ST_CD, setbd_ST_CD] = useState([]);


  // time fromat
  const formatSavedTime = (inputTime) => {
    if (!inputTime) {
      return inputTime;
    }
    return moment(inputTime, "HH:mm:ss").format("HH:mm:ss");
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setbd_DE_PT(null);
    setbd_RM_TY(null);
    setbd_ST_CD(null);

    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/BedAllocation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const BedDetailsData = response.data;
          delete BedDetailsData.bdUkNo;
          setFormData(BedDetailsData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [bd_CO_NO, setbd_CO_NO] = useState("");

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setbd_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;

          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BedAllocation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };



  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/BedAllocation/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const BedDetailsData = response.data;
        setbd_DE_PT(BedDetailsData.bd_DE_PT);
        setbd_RM_TY(BedDetailsData.bd_RM_TY);
        setbd_ST_CD(BedDetailsData.bd_ST_CD);
        setFormData(BedDetailsData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // user acess for add,update,view
  const [useraccess, setuseraccess] = useState("");
  useEffect(() => {
    AccessCheck();
  }, []);
  const AccessCheck = async () => {
    try {
      const request = {
        "us_FN_NM": "Add",
        "us_AP_NM": "compensationdetails"
      }
      const response = await axios.post(`${domain}/api/UserSecurity/CheckSecurity`, request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      const responseValue = response.data.result.us_FL_AG;
      setuseraccess(responseValue === "YES");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDischarge = (type) => {
    console.log("handleFormSubmit called with type:", type);
    console.log("FormData:", FormData);
  
    if (type === "discharge") {
      const confirmtransfer = window.confirm("Do you want to Discharge ?");
      if (confirmtransfer) {
        console.log("Making discharge request...");
        // Replace with actual API call
        axios.post(`${domain}/api/BedAllocation/DischargePatientBedDetails`, FormData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          console.log("Discharge response:", resp.data);
        })
        .catch((error) => {
          console.error("Error dischargeing data:", error);
        });
      }
    } 
  };
  


const handleFormSubmit = (type) => {
  console.log("handleFormSubmit called with type:", type);
  console.log("FormData:", FormData);

  if (type === "transfer") {
    const confirmtransfer = window.confirm("Do you want to Transfer ?");
    if (confirmtransfer) {
      console.log("Making transfer request...");
      // Replace with actual API call
      axios.post(`${domain}/api/BedAllocation/TransferPatientBedDetails`, FormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`,
          "x-flag": localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        console.log("Transfer response:", resp.data);
      })
      .catch((error) => {
        console.error("Error transferring data:", error);
      });
    }
  } else {
    values();
    if (FormData.bdUkNo) {
      const confirm = window.confirm("Do you want to Admit ?");
      const update = `${domain}/api/BedAllocation/AdmitPatientBedDetails`;
      if (confirm) {
        axios
          .post(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    }
  }
};

  
  

  var requestBody = {};
  function values() {
    requestBody = {
      bdUkNo: FormData.bdUkNo,
      bd_DE_PT: bd_DE_PT,
      bd_RM_TY: bd_RM_TY,
      bd_ST_CD: bd_ST_CD,
      bd_PA_ID: document.getElementById("bd_PA_ID").value,
      bd_PA_NM: document.getElementById("bd_PA_NM").value,
      bd_VI_NO: document.getElementById("bd_VI_NO").value,
      bd_BD_NO: document.getElementById("bd_BD_NO").value,
      bd_BD_ST: document.getElementById("bd_BD_ST").value,
      bd_CH_RG: document.getElementById("bd_CH_RG").value,
      bd_CO_NO: company,
    };
  }
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Select
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>

    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", border: "none", backgroundColor: "#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{ width: '40px' }}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_PA_ID"] ? filters["bd_PA_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_PA_ID",
                        e.target.value,
                        filters["bd_PA_ID"]
                          ? filters["bd_PA_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["bd_PA_NM"] ? filters["bd_PA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_PA_NM",
                        e.target.value,
                        filters["bd_PA_NM"]
                          ? filters["bd_PA_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_VI_NO"] ? filters["bd_VI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_VI_NO",
                        e.target.value,
                        filters["bd_VI_NO"]
                          ? filters["bd_VI_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_ST_CD"] ? filters["bd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_ST_CD",
                        e.target.value,
                        filters["bd_ST_CD"]
                          ? filters["bd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_DE_PT"] ? filters["bd_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_DE_PT",
                        e.target.value,
                        filters["bd_DE_PT"]
                          ? filters["bd_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_RM_TY"] ? filters["bd_RM_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_RM_TY",
                        e.target.value,
                        filters["bd_RM_TY"]
                          ? filters["bd_RM_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_BD_NO"] ? filters["bd_BD_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BD_NO",
                        e.target.value,
                        filters["bd_BD_NO"]
                          ? filters["bd_BD_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_IN_DT"] ? filters["bd_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_IN_DT",
                        e.target.value,
                        filters["bd_IN_DT"]
                          ? filters["bd_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_IN_TM"] ? filters["bd_IN_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_IN_TM",
                        e.target.value,
                        filters["bd_IN_TM"]
                          ? filters["bd_IN_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_OT_DT"] ? filters["bd_OT_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OT_DT",
                        e.target.value,
                        filters["bd_OT_DT"]
                          ? filters["bd_OT_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_OT_TM"] ? filters["bd_OT_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OT_TM",
                        e.target.value,
                        filters["bd_OT_TM"]
                          ? filters["bd_OT_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Patient ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Visit No</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Department</th>
                <th style={tableHeaderStyle}>Room Type</th>
                <th style={tableHeaderStyle}>Bed No</th>
                <th style={tableHeaderStyle}>In Date</th>
                <th style={tableHeaderStyle}>In Time</th>
                <th style={tableHeaderStyle}>Out Date</th>
                <th style={tableHeaderStyle}>Out Time</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.bdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.bdUkNo}
                        checked={selectedRows.includes(rowData.bdUkNo)}
                        onChange={() => handleRowSelect(rowData.bdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.bd_PA_ID}</td>
                    <td style={tableCellStyle}>{rowData.bd_PA_NM}</td>
                    <td style={tableCellStyle}>{rowData.bd_VI_NO}</td>
                    <td style={tableCellStyle}>{rowData.bd_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.bd_DE_PT}</td>
                    <td style={tableCellStyle}>{rowData.bd_RM_TY}</td>
                    <td style={tableCellStyle}>{rowData.bd_BD_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.bd_IN_DT)}</td>
                    <td style={tableCellStyle}>{formatSavedTime(rowData.bd_IN_TM)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.bd_OT_DT)}</td>
                    <td style={tableCellStyle}>{formatSavedTime(rowData.bd_OT_TM)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />

      </div>

      <BedDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        // bdUkNo={bdUkNo}
        bd_CO_NO={bd_CO_NO}
        bd_DE_PT={bd_DE_PT}
        setbd_DE_PT={setbd_DE_PT}
        bd_RM_TY={bd_RM_TY}
        setbd_RM_TY={setbd_RM_TY}
        bd_ST_CD={bd_ST_CD}
        setbd_ST_CD={setbd_ST_CD}
        setFormData={setFormData}
        handleDischarge={handleDischarge}

      />
    </div>
  );
};

export default BedDetailsMain;
