import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "../../common/Pagination"; // Adjust the path based on your file structure
import ButtonStyles from "../../common/ButtonStyles"; // Adjust the path based on your file structure
import '../../common/TabStyles.css';
const initialFilters = {
  ui_UN_NO: { value: "", operation: "" },
  ui_PR_NO: { value: "", operation: "" },
  pr_DESC:  { value: "", operation: "" },
  bu_DESC:  { value: "", operation: "" },
  ui_FL_NO: { value: "", operation: "" },
  ui_UN_TY: { value: "", operation: "" },
  ui_UN_US: { value: "", operation: "" },
  ui_AR_TY: { value: "", operation: "" },
  ui_TR_QT: { value: "", operation: "" }, 
};

const UnitPopup = ({ visible, onClose, onDataSelect }) => {
  const {
    formatDate,
    checkboxStyle,
    tableHeaderStyle,
    inputStyle,
    tableCellStyle,
    PopupStyle,
  } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      updatedOperation = "LIKE";
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleSearch = async () => {
    const searchRequestDto = buildSearchRequestDto();

    const requestBody = {
      searchRequestDto,
    };

    const paginationUrl = `${domain}/api/UnitInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (visible) {
      setOpen(true);
      setCurrentPage(0); // Reset page to 0 when dialog opens
    } else {
      setOpen(false); // Ensure closed state is handled when not visible
    }
  }, [visible]);

  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open, currentPage, pageSize, filters]);

  return (
    <Dialog id="dialog-popup-style"
      visible={open}
      onHide={handleClose}
      style={PopupStyle}
      header={"Unit Details"}
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Unit</th>
                <th style={tableHeaderStyle}>Property No</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Building</th>
                <th style={tableHeaderStyle}>Floor</th>
                <th style={tableHeaderStyle}>Unit Type</th>
                <th style={tableHeaderStyle}>Unit Usage</th>
                <th style={tableHeaderStyle}>Area Type</th>
                <th style={tableHeaderStyle}>Area</th>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["ui_UN_NO"] ? filters["ui_UN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_UN_NO",
                        e.target.value,
                        filters["ui_UN_NO"]
                          ? filters["ui_UN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_PR_NO"] ? filters["ui_PR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DESC",
                        e.target.value,
                        filters["ui_PR_NO"] ? filters["ui_PR_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_DESC"] ? filters["pr_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_DESC",
                        e.target.value,
                        filters["pr_DESC"] ? filters["pr_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bu_DESC"] ? filters["bu_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bu_DESC",
                        e.target.value,
                        filters["bu_DESC"] ? filters["bu_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_FL_NO"] ? filters["ui_FL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_FL_NO",
                        e.target.value,
                        filters["ui_FL_NO"]
                          ? filters["ui_FL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_UN_TY"] ? filters["ui_UN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_UN_TY",
                        e.target.value,
                        filters["ui_UN_TY"]
                          ? filters["ui_UN_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    disabled
                    type="text"
                    value={filters["ui_UN_US"] ? filters["ui_UN_US"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_UN_US",
                        e.target.value,
                        filters["ui_UN_US"]
                          ? filters["ui_UN_US"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_AR_TY"] ? filters["ui_AR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_AR_TY",
                        e.target.value,
                        filters["ui_AR_TY"]
                          ? filters["ui_AR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ui_TR_QT"] ? filters["ui_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ui_TR_QT",
                        e.target.value,
                        filters["ui_TR_QT"]
                          ? filters["ui_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.uiUkNo}
                    onClick={() => onDataSelect(rowData)}
                    style={{
                      backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>{rowData.ui_UN_NO}</td>
                    <td style={tableCellStyle}>{rowData.ui_PR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pr_DESC}</td>
                    <td style={tableCellStyle}>{rowData.bu_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ui_FL_NO}</td>
                    <td style={tableCellStyle}>{rowData.ui_UN_TY}</td>
                    <td style={tableCellStyle}>{rowData.ui_UN_US}</td>
                    <td style={tableCellStyle}>{rowData.ui_AR_TY}</td>
                    <td style={tableCellStyle}>{rowData.ui_TR_QT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Dialog>
  );
};

UnitPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default UnitPopup;
