import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function PurchaseRequisitionDialog({
  open,
  handleClose,
  data,
  setFormData,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  SelectedemEMNO,
  setSelectedemEMNO,
  setSelectedemEMNO1,
  ph_OR_TY,
  ph_OR_CO,
  ph_CO_CT,
  pd_ST_CD,
  cancelFlag,
  addRow,
  deleteRow,
  PurchaseRequisitiontable,
  setpd_IT_NM,
  pd_IT_NM,
  pd_CO_CT,
  ph_HL_CD,
  GenerateQuote,
  OnchangeTableData,
  ph_RL_TY,
  setph_RL_TY,
  ph_RL_CO,
  setph_RL_CO
}) {
  const { 
    pdUkNo,
    phUkNO,
    ph_OR_NO,
    ph_DE_PT,
    ph_OR_DT,
    ph_RQ_DT,
    ph_PE_RQ,
    ph_RL_NO,
  } = data;
  const {TabBoxStyle,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle,GenerateStyles} = ButtonStyles();
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  
   const isProtected = pd_ST_CD > 200;
  //  const cancelFlag = pd_ST_CD >= 200 ? cancelFlag : false;

  const [ph_DE_PTOptions, setph_DE_PTOptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [pd_SC_UMOptions, setpd_SC_UMOptions] = useState([]);
  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_HL_CDOptions, setph_HL_CDOptions] = useState([]);
  const [ph_RL_TYOptions, setph_RL_TYOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ph_RL_COOptions, setph_RL_COOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(0); 
  const [currentDate, setCurrentDate] = useState(moment().toDate());
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "PO/DP";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
   const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl2 = `${urlcost}`;
// provider
  const parameter3 = "FO/HD";
  const finalUrl3 = `${url}/${parameter3}`;
// grade
  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;
// delivery type
  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;

  const urlcom = `${domain}/api/CompanyMaster/GetActiveCompaniesByClient`;
  const finalUrl6 = `${urlcom}`;

  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_RL_TY(value);
  };

  const CompanyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_RL_CO(value);
  };
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    Type();
   
  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setph_RL_TYOptions(Options);
  };
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.coCoNo,
        value: item.coCoNo,
      }));
      setph_RL_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setph_DE_PTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setph_HL_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4th dropdown
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_TR_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 5th dropdown
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_SC_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
const [RequestedPopupVisible, setRequestedPopupVisible] = useState(false);
const [addressTypeSUP, setaddressTypeSUP] = useState("E");
const openRequestedPopup = (e) => {
  setRequestedPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelect = async(Result) => {
  setSelectedemEMNO1(Result.im_IT_DS);
  setpd_IT_NM(Result.im_IT_NM);
  OnchangeTableData("pd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
  OnchangeTableData("im_IT_DS", Result.im_IT_DS, SelectedRowIndex);
  // document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
  // document.getElementById (`im_IT_DS_${SelectedRowIndex}`).value =  Result.im_IT_DS;
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeDropDownFormData5(`pd_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      // onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
const handleDataSelect1 = (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  onchangeFormData("ph_PE_RQ", data.abAdNo);
  setRequestedPopupVisible(false);
};
const isUpdateMode = Boolean(phUkNO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{phUkNO ? "Revise Purchase Requisition" : "Add Purchase Requisition" }</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
    {activeTab === 0 && (
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
    )}
    {activeTab === 0 && (
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    )}
     {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          {activeTab === 0 && (
          <Button
            style={GenerateStyles}
            icon="pi pi-file"
            variant="outlined"
            onClick={GenerateQuote}
            tooltip="Generate Quote"
          />
        )}
        </div>
       )}
    </div>
  </div>
);
const productDialogFooter = (
  <React.Fragment>
    <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
      {isUpdateMode && (
      <div style={{ display: 'flex',width:"10%" }}>
        {activeTab === 0 && (
        <Button
          style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue", }}
          icon="pi pi-check-circle"
          variant="outlined"
          onClick={GenerateQuote}
          tooltip="Generate Quote"
        />
      )}
      </div>
      )}
        <div style={{ display: 'flex', width: "10%" }}>
      {activeTab === 0 && (
        <Button
          style={{ width: "100%", marginRight: "8px", }}
          icon="pi pi-times"
          variant="outlined"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      {activeTab === 0 && (
        <Button
          style={{ width: "100%" }}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip={activeTab === 0 ? "Submit" : "Update"} // Conditional tooltip
        />
      )}
    </div>
  </div>
</React.Fragment>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Requisition Details">
              {/* tab1 general */}
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="phUkNO" className="font-bold">
                      UKNO
                    </label>
                    <InputText
                      id="phUkNO"
                      name="phUkNO"
                      value={phUkNO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="ph_OR_NO" className="font-bold">
                    Order No
                      </label>
                      <InputText disabled
                      id="ph_OR_NO"
                      name="ph_OR_NO"
                      value={ph_OR_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_OR_TY" className="font-bold">
                  Type
                  </label>
                  <InputText disabled
                  id="ph_OR_TY"
                  name="ph_OR_TY"
                  value={ph_OR_TY}
                  onChange={(e) => onchangedata(e)}
                    />
                  </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_OR_CO" className="font-bold">
                  Company
                    </label>
                    <InputText disabled
                      id="ph_OR_CO"
                      name="ph_OR_CO"
                      value={ph_OR_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_OR_DT" className="font-bold">
                    Order Date
                    </label>
                    <Calendar
                    id="ph_OR_DT"
                    name="ph_OR_DT"
                    value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "ph_OR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    disabled={cancelFlag || isProtected}
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_RQ_DT" className="font-bold">
                    Requested Date
                    </label>
                    <Calendar
                    id="ph_RQ_DT"
                    name="ph_RQ_DT"
                    value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "ph_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    disabled={cancelFlag || isProtected}
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown disabled
                      id="ph_CO_CT"
                      name="ph_CO_CT"
                      value={ph_CO_CT}
                      options={ph_CO_CTOptions}
                      onChange={(e) => onchangeDropDownFormData2("ph_CO_CT", e.value)}
                      placeholder="Select Cost Center"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_PE_RQ" className="font-bold">
                      Requested By <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <InputText
                        id="emp_NAME"
                        name="emp_NAME"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={SelectedemEMNO}
                        disabled={cancelFlag || isProtected}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openRequestedPopup}
                      ></i>
                      )}
                    </div>
                    <div style={{display:"none"}}>
                      <InputText 
                        id="ph_PE_RQ"
                        name="ph_PE_RQ"
                        value={ph_PE_RQ}
                        onChange={(Event) => onchangedata(Event)}
                      />
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openRequestedPopup}
                      ></i>
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_DE_PT" className="font-bold">
                  Department
                    </label>
                    <Dropdown
                      id="ph_DE_PT"
                      name="ph_DE_PT"
                      value={ph_DE_PT}
                      options={ph_DE_PTOptions}
                      onChange={(e) => onchangeDropDownFormData1("ph_DE_PT", e.value)}
                      placeholder="Select Department"
                      disabled={cancelFlag || isProtected}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ph_HL_CD" className="font-bold">
                    Hold Code
                    </label>
                    <Dropdown
                      id="ph_HL_CD"
                      name="ph_HL_CD"
                      value={ph_HL_CD}
                      options={ph_HL_CDOptions}
                      onChange={(e) => onchangeDropDownFormData3("ph_HL_CD", e.value)}
                      placeholder="Select  Hold Code"
                      disabled={cancelFlag || isProtected}
                    />
                  </div>
                  
                  
                </div>
              </div>
              <div  style={tablestyle} >
                <div className="table-buttons" style={tablebutton} >
                {(
                <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                )}
                  {(
                <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div  style={multitablestyle}>
              <table className="custom-table" id="PurchaseRequisitiontable" style={{overflow:"auto"}}>
                <thead>
                  <tr>
                    <th style={{ display: "none" }} >UK_NO</th>
                    <th>Line No</th>
                    <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
                    <th>Description</th>
                    <th>Order Quantity</th>
                    <th>UM</th>
                    <th>Secondary Quantity</th>
                    <th>UM </th>
                    <th>Cancelled Quantity</th>
                    <th>Cancelled Date</th>
                    <th>Status</th>
                    <th>Cost Centre</th>
                    <th>Remarks</th>
                  
                  </tr>
                </thead>
                <tbody>
                {PurchaseRequisitiontable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }} >
                      <InputText
                        id={`pdUkNo_${index}`}
                        name={`pdUkNo_${index}`}
                        defaultValue={row.pdUkNo}
                      />
                    </td>
                      <td>
                    <InputText disabled
                    style={{ width: "90px" }}
                    id={`pd_LN_NO_${index}`}
                    name={`pd_LN_NO_${index}`}
                    value={index + 1}
                  />
                    </td>
                    <td >
                      <div style={{ display: "flex" }}> 
                      <InputText
                        style={{ width: "110px" }}
                        id={`pd_IT_NM_${index}`}
                        name={`pd_IT_NM_${index}`}
                        onChange={(e) =>  OnchangeTableData('pd_IT_NM',e.value,index)}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.pd_IT_NM ? row.pd_IT_NM: row[`${"pd_IT_NM"}_${index}`]}
                        disabled={cancelFlag || isProtected}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                      </div>
                    </td>
                    <td>
                    <InputText disabled
                        style={{ width: "15rem" }}
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        value={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                        onChange={(e) =>  OnchangeTableData('im_IT_DS',e.value,index)}
                      />
                    </td>
                    <td>
                    <InputText style={{width:"12rem"}}
                      id={`pd_OR_QT_${index}`}
                      name={`pd_OR_QT_${index}`}
                      value={data[`pd_OR_QT_${index}`] ? data[`pd_OR_QT_${index}`] : row.pd_OR_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <Dropdown
                      id={`pd_TR_UM_${index}`}
                      name={`pd_TR_UM_${index}`}
                      value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
                      options={pd_TR_UMOptions}
                      onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"12rem"}}
                      id={`pd_SC_QT_${index}`}
                      name={`pd_SC_QT_${index}`}
                      value={data[`pd_SC_QT_${index}`] ? data[`pd_SC_QT_${index}`] : row.pd_SC_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <Dropdown
                      id={`pd_SC_UM_${index}`}
                      name={`pd_SC_UM_${index}`}
                      value={ data[`pd_SC_UM_${index}`] ? data[`pd_SC_UM_${index}`]: row.pd_SC_UM}
                      options={pd_SC_UMOptions}
                      onChange={(e) =>handleFormDataChange1(`pd_SC_UM_${index}`, e.value)}
                      placeholder="Select UM"
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"12rem"}}
                      id={`pd_CN_QT_${index}`}
                      name={`pd_CN_QT_${index}`}
                      value={data[`pd_CN_QT_${index}`] ? data[`pd_CN_QT_${index}`] : row.pd_CN_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled={!cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <Calendar 
                        style={{width:"10rem"}}
                        id={`pd_CN_DT_${index}`}
                        name={`pd_CN_DT_${index}`}
                        value={currentDate}
                        onChange={(e) => {setCurrentDate(e.value);
                        onDateChange("pd_CN_DT", index, e.value);
                        }}
                        dateFormat="dd/mm/yy"
                        disabled={!cancelFlag || isProtected}
                      />
                    </td>
                    
                    <td>
                    <InputText disabled style={{width:"10rem"}}
                      id={`pd_ST_CD_${index}`}
                      name={`pd_ST_CD_${index}`}
                      defaultValue={pd_ST_CD}
                    />
                    </td>
                    <td>
                    <InputText disabled style={{width:"10rem"}}
                      id={`pd_CO_CT_${index}`}
                      name={`pd_CO_CT_${index}`}
                      defaultValue={pd_CO_CT}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"18rem"}}
                      id={`pd_RE_MK_${index}`}
                      name={`pd_RE_MK_${index}`}
                      value={data[`pd_RE_MK_${index}`] ? data[`pd_RE_MK_${index}`] : row.pd_RE_MK}
                      onChange={(e) => onchangedata(e)}
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    
                    </tr>
                    ))}
                </tbody>
              </table>   
          
          </div>
              </div>
          </TabPanel>
          <TabPanel header="Additional Details">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RL_NO" className="font-bold">
                Ref. Order No
                  </label>
                  <InputText 
                  id="ph_RL_NO"
                  name="ph_RL_NO"
                  value={ph_RL_NO}
                  onChange={(e) => onchangedata(e)}
                  disabled={cancelFlag || isProtected}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_RL_TY" className="font-bold">
                Type
                </label>
                <Dropdown
                    id="ph_RL_TY"
                    name="ph_RL_TY"
                    value={ph_RL_TY}
                    options={ph_RL_TYOptions}
                    onChange={(e) => TypeDropdown("ph_RL_TY", e.value)}
                    placeholder="Select Type"
                    disabled={cancelFlag || isProtected}
                  />
                </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RL_CO" className="font-bold">
                Company
                  </label>
                  <Dropdown
                    id="ph_RL_CO"
                    name="ph_RL_CO"
                    value={ph_RL_CO}
                    options={ph_RL_COOptions}
                    onChange={(e) => CompanyDropdown("ph_RL_CO", e.value)}
                    placeholder="Select Company"
                    disabled={cancelFlag || isProtected}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
      </TabView>
      </div>    
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
      {/* REQUESTED */}
       <AddressBookPopup
          visible={RequestedPopupVisible}
          onClose={() => setRequestedPopupVisible(false)}
          onDataSelect={handleDataSelect1}
          formData={data}
          addressType={addressTypeSUP}
          setFormData={setFormData}
        />
    </Dialog>
  );
}
