import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV,faFolder, faFile,
  faFilePdf, faFileExcel, faFileWord, faFilePowerpoint, faFileAlt ,faFileCode, faImage} from '@fortawesome/free-solid-svg-icons';
import './FileManagementDashboard.css';
import { split } from "lodash";

const FileManagementDashboardMain = () => {

  const {formatDate ,formatSavedTime} = ButtonStyles();
  const [items, setItems] = useState([]);
  const toast = useRef(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [currentFolder, setcurrentFolder] = useState("");
  
  const [files, setFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(9999950);
  
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      fetchItems(currentFolder);
    }
  };
  // fetch list
  const fetchItems = async (ukNo) => {
    setcurrentFolder(ukNo);
    const requestBody = {
      searchRequestDto: []
    };
    const url = `${domain}/api/FileManagementSystem/List/${ukNo}/${currentPage}/${pageSize}`;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data.content || []; 
      setItems(listItemsData);
        const pathurl = `${domain}/api/FileManagementSystem/FetchPath`
        const requestpath = {
          "fdUkNo": ukNo
        };
        try {
          const response = await axios.post(pathurl, requestpath, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": company,
              "x-flag": localStorage.getItem("userType"),
            },
          });      
          const Data = response.data; 
          setCurrentPath(Data)
        } catch (error) {
          console.error("Error fetching data:", error);
        }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // file path access
  const handleAccesspath = async (ukNo) =>{
    setcurrentFolder(ukNo);
    if (ukNo === 0 ){     
      fetchItems(ukNo);
      setCurrentPath([{ fd_NA_ME: "", fdUkNo: 0 }]);
    }else {
    const pathurl = `${domain}/api/FileManagementSystem/CheckAccessOfPath`
    const requestpath = {
      "fdUkNo": ukNo
    };
    try {
      const response = await axios.post(pathurl, requestpath, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });      
      const Data = response.data; 
      if (Data.flag === true) {

        const requestBody = {
          searchRequestDto: []
        };

        const url = `${domain}/api/FileManagementSystem/List/${ukNo}/${currentPage}/${pageSize}`;
        setLoading(true);
        setError(null);
        try {
          const response = await axios.post(url, requestBody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": company,
              "x-flag": localStorage.getItem("userType"),
            },
          });
          
          const listItemsData = response.data.content || []; 
          setItems(listItemsData);
          // alert(ukNo);
            const pathurl = `${domain}/api/FileManagementSystem/FetchPath`
            const requestpath = {
              "fdUkNo": ukNo
            };
            try {
              const response = await axios.post(pathurl, requestpath, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": company,
                  "x-flag": localStorage.getItem("userType"),
                },
              });      
              const Data = response.data; 
              setCurrentPath(Data);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
    
        } catch (error) {
          console.error("Error fetching data:", error);
        }

      } else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Access is Not there for this folder",
        });
      }    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    }
  };

  useEffect(() => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("path=");
     const LinkId = l_array[1];
     if (LinkId) {
      fetchItems(LinkId);
    } else {
      fetchItems(0);
    }
  }, [currentPage]); 

  const [documentType, setdocumentType] = useState ("");
  const [addressType, setaddressType] = useState ("");
  
  useEffect(() => {
    handleDefault();
  }, []);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, 
          "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "DocumentType"){
              setdocumentType(dataDefault.versionIds[i].default_VALUE);
          }
          if(fName === "AB_AD_TY"){
            setaddressType(dataDefault.versionIds[i].default_VALUE);
          }
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        // handleSearch(listDefault,count);
      } else {
        // handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // download api
  const handleFileClick = async (UK_NO,fileName) => {
    const Request = {
      "fdUkNo": UK_NO
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/DownloadFile`,Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      saveAs(new Blob([response.data]), fileName);
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File downloaded successfully.",
        });
      } else {
        throw new Error("Failed to download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };
  // search function
  const onChangeOfSearch = async (value) => {
    setSearchQuery(value);
    const searchInput = {
      "fd_NA_ME" : value
    };
    const url = `${domain}/api/FileManagementSystem/SearchRecentFiles`;
    try {
      const response = await axios.post(
        url,searchInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const recentFiles = response.data;
        setItems(recentFiles);
        // handleAccesspath(recentFiles.fdUkNo);
      } else {
        console.error("Error fetching data:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    }
  };

  // file icons based on extension
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase(); // Get the file extension
  
    switch (extension) {
      case 'pdf':
        return faFilePdf;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
      case 'doc':
      case 'docx':
        return faFileWord;
      case 'ppt':
      case 'pptx':
        return faFilePowerpoint;
      case 'txt':
        return faFileAlt;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return faImage; // Image files
      case 'jsp':
        return faFileCode; // .jsp files (web development)
      default:
        return faFile; // Default to a general file icon
    }
  };
  // Function to determine color based on file type
  const getFileColor = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
  
    switch (extension) {
      case 'pdf':
        return '#D32F2F'; // Red for PDF
      case 'xls':
      case 'xlsx':
        return '#388E3C'; // Green for Excel
      case 'doc':
      case 'docx':
        return '#1976D2'; // Blue for Word
      case 'ppt':
      case 'pptx':
        return '#F57C00'; // Orange for PowerPoint
      case 'txt':
        return '#757575'; // Gray for Text file
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return '#0288D1'; // Light Blue for Images
      case 'jsp':
        return '#8E24AA'; // Purple for .jsp (web development)
      default:
        return '#000000'; // Default black color for other file types
    }
  };
  // back function 
  const handleBack = async () => {
    if (currentPath.length > 1) {
      const parentFolder = currentPath[currentPath.length - 2].fdUkNo; 
      fetchItems(parentFolder);
    } else {
      toast.current.show({
        severity: "info",
        summary: "You are at the root folder.",
        detail: "There are no more folders to go back to.",
      });
    }
  };  
  //home function 
  const handleHome = async () => {
    fetchItems(0);
  };

  return (
    <div >
      <Toast ref={toast} />

      <div style={{padding:'10px',boxShadow:'2px 2px 5px #8dc0c0',backgroundColor:'#e8fef8a1',height:'2rem'}}>
        <table style={{marginTop:'-10px'}}>
          <tr >
            <td className="left-side" >
              {currentPath.map((file) => (
                <button key={file.fdUkNo} onClick={() => handleAccesspath(file.fdUkNo)}>
                  {file.fd_NA_ME} &gt;
                </button>
              ))}
            </td> 
            <td className="right-side">              
              <div className="button-container" style={{height:'25px'}}>
                {/* Search input */}
                  <input className="searchbar"
                    style={{ width: "20%" }}
                    type="text"
                    placeholder="Search files..."
                    value={searchQuery}
                    onChange={(e) => onChangeOfSearch(e.target.value)}
                  />

                {/* home button */}
                <Button onClick={handleHome} icon="pi pi-home"
                  style={{
                    color: "#0000ffa8",
                    backgroundColor: "#e0f7fa",  // Light cyan shade
                    border: "1px solid #ddd",  // Light border
                    padding: "8px 16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontWeight: "bold",
                    transition: "background-color 0.3s",
                  }}
                >
                  <span>Home</span>
                </Button>
                {/* backbutton */}
                <Button  onClick={handleBack} icon="pi pi-arrow-left"
                style={{
                  color: "#0000ffa8",
                  backgroundColor: "#f0f8ff",  // Light blue shade
                  border: "1px solid #ddd",  // Light border
                  padding: "8px 16px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontWeight: "bold",
                  marginRight: "10px",
                  transition: "background-color 0.3s",
                }}>
                 <span> Back </span> 
                </Button>
                
              </div>
            </td>
          </tr>
        </table>
      </div>

      
      <div className="container mt-4" style={{ height: '45rem', overflowY: 'auto' }}>
        <div className="row">
          {items.map((item, index) => (
            <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-2" key={item.id}>
              <div
                className="d-flex flex-column align-items-center"
                style={{
                  cursor: item.fd_TY_PE === 'FI' ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (item.fd_TY_PE === 'FI') {
                    handleFileClick(item.fdUkNo, item.fd_NA_ME);
                  } else if (item.fd_TY_PE === 'FO') {
                    fetchItems(item.fdUkNo);
                  }
                }}
              >
                {/* Folder Icon */}
                <FontAwesomeIcon
                  icon={item.fd_TY_PE === 'FO' ? faFolder : getFileIcon(item.fd_NA_ME)} 
                  // icon={item.fd_TY_PE === 'FO' ? faFolder : item.fd_TY_PE === 'FI' ? faFile : null} 
                  className="mb-2"
                  style={{ 
                    fontSize:'4rem',
                    color: item.fd_TY_PE === 'FO' ? '#F8D775' : getFileColor(item.fd_NA_ME) 
                    }} 
                  />
                
                {/* Filename */}
                  <div
                    className="text-center"
                    style={{
                      fontSize: '1rem',
                      width: '50%',  
                      maxWidth: '50%',
                      textAlign: 'center',
                      display: '-webkit-box',  // Enable multi-line ellipsis
                      WebkitLineClamp: 3,      // Clamp the text to 3 lines
                      WebkitBoxOrient: 'vertical', // Align the text vertically
                      overflow: 'hidden',      // Hide anything outside the box
                      textOverflow: 'ellipsis', // Apply ellipsis to truncated text
                    }}
                  >
                    {item.fd_NA_ME}
                  </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      
    </div>
  );
};
export default FileManagementDashboardMain;
