import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CustomerMasterDialog from "./CustomerMasterDialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
    cuUkNo:"",
    cu_CU_NO:"",
    name:"",
    cu_PT_TM:"",
    cu_CR_CD:"",
    cu_PY_IN:"",
    cu_GL_CD:"",
    cu_IN_HD:"",
    cu_ST_AD:"",
    cu_PA_NO:"",
    cu_OD_AP:"",
    cu_NT_FL:"",
    cu_ST_PT:"",
    cu_OD_DY:"",
    cu_IN_AD:"",
    cu_CR_MR:"",
    cu_CO_MR:"",
    cu_CR_LT:"",
    cu_DT_OP:"",
    cu_PE_OP:"",
    cu_ST_CY:"",
    cu_CR_NO:"",
    cu_PR_AG:"",
    cu_DS_PR:"",
    cu_TR_DY:"",
    cu_FR_HC:"",
    cu_RT_CD:"",
    cu_TX_AP:"",
    cu_TX_TY:"",
    cu_TX_CD:"",
    cu_TX_RT:"",
    cu_TX_AR:"",
    cu_HL_CD:"",
    ec_WP_BS:"",
    ec_WP_TA:"",
    cu_BL_TY:"",
    cu_AP_FR:"",
    cu_PR_GP:"",
    cu_IT_RT:"",
    parent_Name:"",
};

const initialFilters = {
  cu_CU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  cu_CO_NO: { value: "", operation: "" },
  cu_CR_CD: { value: "", operation: "" },
  cu_CR_LT: { value: "", operation: "" },
  cu_PT_TM: { value: "", operation: "" },
  cu_PA_NO: { value: "", operation: "" },
  parent_Name: { value: "", operation: "" },
 
};

const CustomerMasterMain = () => {
  // buttonstyles
  const { buttonStyles, addButtonStyles, copyButtonStyles,checkboxStyle,tableHeaderStyle, tableCellStyle,ListDesc } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [customer, setCustomer] = useState("");
  const [Parent, setParent] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNM1, setSelectedAbAdNM1] = useState("");
  const [selectedAbAdNM2, setSelectedAbAdNM2] = useState("");
  const [selectedAbAdNM3, setSelectedAbAdNM3] = useState("");
  const [selectedAbAdNM4, setSelectedAbAdNM4] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [selectedparent, setSelectedparent] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [cu_PT_TM, setcu_PT_TM] = useState([]);
  const [cu_CR_CD, setcu_CR_CD] = useState([]);
  const [cu_PY_IN, setcu_PY_IN] = useState([]);
  const [cu_GL_CD, setcu_GL_CD] = useState([]);
  const [cu_IN_HD, setcu_IN_HD] = useState([]);
  const [cu_ST_AD, setcu_ST_AD] = useState([]);
  const [cu_IN_AD, setcu_IN_AD] = useState([]);
  const [cu_OD_AP, setcu_OD_AP] = useState([]);
  const [cu_NT_FL, setcu_NT_FL] = useState([]);
  const [cu_PR_AG, setcu_PR_AG] = useState([]);
  const [cu_FR_HC, setcu_FR_HC] = useState([]);
  const [cu_RT_CD, setcu_RT_CD] = useState([]);
  const [cu_TX_AR, setcu_TX_AR] = useState([]);
  const [cu_TX_CD, setcu_TX_CD] = useState([]);
  const [cu_HL_CD, setcu_HL_CD] = useState([]);
  const [cu_TX_TY, setcu_TX_TY] = useState([]);
  const [cu_TX_AP, setcu_TX_AP] = useState([]);
  const [cu_ST_PT, setcu_ST_PT] = useState([]);
  const [cu_ST_CY, setcu_ST_CY] = useState([]);
  const [cu_AP_FR, setcu_AP_FR] = useState([]);
  const [cu_IT_RT, setcu_IT_RT] = useState([]);
  const [cu_PR_GP, setcu_PR_GP] = useState([]);
  const [cu_BL_TY, setcu_BL_TY] = useState([]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
  };
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);
  const openParentPopup = (e) => {
    setParentPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const handleDataSelect1 = (data) => {
    setSelectedparent(data.ab_AD_NM);
    setParent( data.abAdNo);
    setParentPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters1 (data.abAdNo);
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM1(null);
    setSelectedAbAdNM2(null);
    setSelectedAbAdNM3(null);
    setSelectedAbAdNM4(null);
    setcu_PT_TM(null);
    setcu_CR_CD(null);
    setcu_PY_IN(null);
    setcu_GL_CD(null);
    setcu_IN_HD(null);
    setcu_ST_AD(null);
    setcu_IN_AD(null);
    setcu_OD_AP(null);
    setcu_NT_FL(null);
    setcu_PR_AG(null);
    setcu_FR_HC(null);
    setcu_RT_CD(null);
    setcu_TX_AR(null);
    setcu_TX_CD(null);
    setcu_HL_CD(null);
    setcu_TX_TY(null);
    setcu_TX_AP(null);
    setcu_ST_PT(null);
    setcu_ST_CY(null);
    setcu_AP_FR(null);
    setcu_IT_RT(null);
    setcu_PR_GP(null);
    setcu_BL_TY(null);
    setOpen(true);
  };


  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM1(null);
    setSelectedAbAdNM2(null);
    setSelectedAbAdNM3(null);
    setSelectedAbAdNM4(null);
    setcu_PT_TM(null);
    setcu_CR_CD(null);
    setcu_PY_IN(null);
    setcu_GL_CD(null);
    setcu_IN_HD(null);
    setcu_ST_AD(null);
    setcu_IN_AD(null);
    setcu_OD_AP(null);
    setcu_NT_FL(null);
    setcu_PR_AG(null);
    setcu_FR_HC(null);
    setcu_RT_CD(null);
    setcu_TX_AR(null);
    setcu_TX_CD(null);
    setcu_HL_CD(null);
    setcu_TX_TY(null);
    setcu_TX_AP(null);
    setcu_ST_PT(null);
    setcu_ST_CY(null);
    setcu_AP_FR(null);
    setcu_IT_RT(null);
    setcu_PR_GP(null);
    setcu_BL_TY(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CustomerMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CustomerMasterData = response.data;
          delete CustomerMasterData.cuUkNo;
          setcu_PT_TM(CustomerMasterData.cu_PT_TM);
          setcu_CR_CD(CustomerMasterData.cu_CR_CD);
          setcu_PY_IN(CustomerMasterData.cu_PY_IN);
          setcu_GL_CD(CustomerMasterData.cu_GL_CD);
          setcu_IN_HD(CustomerMasterData.cu_IN_HD);
          setcu_ST_AD(CustomerMasterData.cu_ST_AD);
          setcu_IN_AD(CustomerMasterData.cu_IN_AD);
          setcu_OD_AP(CustomerMasterData.cu_OD_AP);
          setcu_NT_FL(CustomerMasterData.cu_NT_FL);
          setcu_PR_AG(CustomerMasterData.cu_PR_AG);
          setcu_FR_HC(CustomerMasterData.cu_FR_HC);
          setcu_RT_CD(CustomerMasterData.cu_RT_CD);
          setcu_TX_AR(CustomerMasterData.cu_TX_AR);
          setcu_TX_CD(CustomerMasterData.cu_TX_CD);
          setcu_HL_CD(CustomerMasterData.cu_HL_CD);
          setcu_TX_TY(CustomerMasterData.cu_TX_TY);
          setcu_TX_AP(CustomerMasterData.cu_TX_AP);
          setcu_ST_PT(CustomerMasterData.cu_ST_PT);
          setcu_ST_CY(CustomerMasterData.cu_ST_CY);
          setcu_AP_FR(CustomerMasterData.cu_AP_FR);
          setcu_IT_RT(CustomerMasterData.cu_IT_RT);
          setcu_PR_GP(CustomerMasterData.cu_PR_GP);
          setcu_BL_TY(CustomerMasterData.cu_BL_TY);
          setFormData(CustomerMasterData);
          setSelectedemEMNO(CustomerMasterData.name);
          setSelectedAbAdNM(CustomerMasterData.parent_Name);
          setSelectedAbAdNM1(CustomerMasterData.collectionManager_Name);
          setSelectedAbAdNM2(CustomerMasterData.creditManager_Name);
          setSelectedAbAdNM3(CustomerMasterData.personOpening_Name);
          setSelectedAbAdNM4(CustomerMasterData.carrier_Name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      cu_CU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };
  const updateFilters1 = (own) => {
    let updatedFilters = { ...filters };
  if (own !== null) {
    updatedFilters = {
      ...updatedFilters,
      cu_PA_NO: {
        value: own,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };

  useEffect(() => {
    handleDefault();
    // handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CustomerMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [cu_CO_NO , setcu_CO_NO ] = useState("");
  const handleDefault = async () => {
    setcu_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            // setfi_CO_NO(`${company}`);
            // if(fName === "SD_OR_TY"){
            //   setsdOrTy(dataDefault.versionIds[i].default_VALUE)
            // }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_CR_CD(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_PT_TM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_PY_IN(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_GL_CD(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_IN_HD(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_ST_AD(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_IN_AD(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_OD_AP(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_NT_FL(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_PR_AG(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_FR_HC(value);
  };

  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_RT_CD(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_TX_AR(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_TX_CD(value);
  };
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_HL_CD(value);
  };
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_TX_TY(value);
  };
  const onchangeDropDownFormData17 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_TX_AP(value);
  };
  const onchangeDropDownFormData18 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_ST_PT(value);
  };
  const onchangeDropDownFormData19 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_ST_CY(value);
  };
  const onchangeDropDownFormData20 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_AP_FR(value);
  };
  const onchangeDropDownFormData21 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_IT_RT(value);
  };
  const onchangeDropDownFormData22 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_PR_GP(value);
  };
  const onchangeDropDownFormData23 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setcu_BL_TY(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CustomerMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CustomerMasterData = response.data;
        setFormData(CustomerMasterData);
        setSelectedemEMNO(response.data.name);
        setSelectedAbAdNM(response.data.parent_Name);
        setSelectedAbAdNM1(response.data.collectionManager_Name);
        setSelectedAbAdNM2(response.data.creditManager_Name);
        setSelectedAbAdNM3(response.data.personOpening_Name);
        setSelectedAbAdNM4(response.data.carrier_Name);
        setcu_PT_TM(response.data.cu_PT_TM);
        setcu_CR_CD(response.data.cu_CR_CD);
        setcu_PY_IN(response.data.cu_PY_IN);
        setcu_GL_CD(response.data.cu_GL_CD);
        setcu_IN_HD(response.data.cu_IN_HD);
        setcu_ST_AD(response.data.cu_ST_AD);
        setcu_IN_AD(response.data.cu_IN_AD);
        setcu_OD_AP(response.data.cu_OD_AP);
        setcu_NT_FL(response.data.cu_NT_FL);
        setcu_PR_AG(response.data.cu_PR_AG);
        setcu_FR_HC(response.data.cu_FR_HC);
        setcu_RT_CD(response.data.cu_RT_CD);
        setcu_TX_AR(response.data.cu_TX_AR);
        setcu_TX_CD(response.data.cu_TX_CD);
        setcu_HL_CD(response.data.cu_HL_CD);
        setcu_TX_TY(response.data.cu_TX_TY);
        setcu_TX_AP(response.data.cu_TX_AP);
        setcu_ST_PT(response.data.cu_ST_PT);
        setcu_ST_CY(response.data.cu_ST_CY);
        setcu_AP_FR(response.data.cu_AP_FR);
        setcu_AP_FR(response.data.cu_AP_FR);
        setcu_PR_GP(response.data.cu_PR_GP);
        setcu_BL_TY(response.data.cu_BL_TY);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

var requestBody = {};
function values () {
  requestBody = {
    cuUkNo   : FormData.cuUkNo,
    cu_CU_NO : FormData.cu_CU_NO,
    cu_CR_CD : FormData.cu_CR_CD,
    cu_CO_NO : FormData.cu_CO_NO,
    cu_PT_TM : FormData.cu_PT_TM,
    cu_PY_IN : FormData.cu_PY_IN,
    cu_GL_CD : FormData.cu_GL_CD,
    cu_IN_HD : FormData.cu_IN_HD,
    cu_ST_AD : FormData.cu_ST_AD,
    cu_IN_AD : FormData.cu_IN_AD,
    cu_PA_NO : FormData.cu_PA_NO,
    cu_ST_PT : FormData.cu_ST_PT,
    cu_OD_AP : FormData.cu_OD_AP,
    cu_OD_DY : FormData.cu_OD_DY,
    cu_NT_FL : FormData.cu_NT_FL,
    cu_CR_MR : FormData.cu_CR_MR,
    cu_CR_LT : FormData.cu_CR_LT,
    cu_CO_MR : FormData.cu_CO_MR,
    cu_ST_CY : FormData.cu_ST_CY,
    cu_DT_OP : FormData.cu_DT_OP,
    cu_PE_OP : FormData.cu_PE_OP,
    cu_BL_TY : FormData.cu_BL_TY,
    cu_CR_NO : FormData.cu_CR_NO,
    cu_PR_AG : FormData.cu_PR_AG,
    cu_TR_DY : FormData.cu_TR_DY,
    cu_AP_FR : FormData.cu_AP_FR,
    cu_FR_HC : FormData.cu_FR_HC,
    cu_PR_GP : FormData.cu_PR_GP,
    cu_DS_PR : FormData.cu_DS_PR,
    cu_IT_RT : FormData.cu_IT_RT,
    cu_RT_CD : FormData.cu_RT_CD,
    cu_TX_AP : FormData.cu_TX_AP,
    cu_TX_TY : FormData.cu_TX_TY,
    cu_TX_CD : cu_TX_CD,
    cu_TX_RT : FormData.cu_TX_RT,
    // document.getElementById("cu_TX_RT").value,
    cu_TX_AR : FormData.cu_TX_AR,
    cu_HL_CD : FormData.cu_HL_CD,
  };
}

const handleFormSubmit = () => {
  values ();
  if (FormData.cuUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/CustomerMaster/Update`;
    if (confirm) {
      axios
        .put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    FormData.cu_CO_NO = company;
    axios
      .post(`${domain}/api/CustomerMaster/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
};

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const contactStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };


  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
    <div className="formgrid grid">
 <div className="field col" >
            <label htmlFor="customer" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{width:"125px"}}
                id="customer"
                name="customer"
                onFocus={() => setShowSearchIcon(true)}
                value={customer}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
               )}
              <div>
              <InputText disabled
                id="ab_AD_NM"
                name="ab_AD_NM"
                readOnly
                value={selectedcustmer}
                style={{width:"250px"}}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            </div>
          </div>
          <div className="field col" >
            <label htmlFor="Parent" className="font-bold">
              Parent <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{width:"125px"}}
                id="Parent"
                name="Parent"
                onFocus={() => setShowSearchIcon(true)}
                value={Parent}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openParentPopup}
              ></i>
               )}
              <div>
              <InputText disabled
                id="ab_AD_NM"
                name="ab_AD_NM"
                readOnly
                value={selectedparent}
                style={{width:"250px"}}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            </div>
          </div>
</div>
);
const handleClearButton=()=>{
  setCustomer("");
  setSelectedcustmer("");
  setParent("");
  setSelectedparent("");
  setFilters("");
}

const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>

    <Button
      style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
      // style={{ height: "35px" }}
      icon="pi pi-times"
      className="mr-2 clear-button"
      link
      onClick={handleClearButton}
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
         <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
              <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
              <td >
                  <input disabled
                    type="text"
                    value={customer}
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input disabled
                    width="100%"
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_CO_NO"] ? filters["cu_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_CO_NO",
                        e.target.value,
                        filters["cu_CO_NO"]
                          ? filters["cu_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_CR_CD"] ? filters["cu_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_CR_CD",
                        e.target.value,
                        filters["cu_CR_CD"]
                          ? filters["cu_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_CR_LT"] ? filters["cu_CR_LT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_CR_LT",
                        e.target.value,
                        filters["cu_CR_LT"]
                          ? filters["cu_CR_LT"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cu_PT_TM"] ? filters["cu_PT_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cu_PT_TM",
                        e.target.value,
                        filters["cu_PT_TM"]
                          ? filters["cu_PT_TM"].operation
                          : "equals"
                      )
                    }
                    style={contactStyle}
                  />
                </td>
                <td >
                  <input
                    width="100%"
                    type="text"
                    value={Parent}
                    style={inputStyleH}
                    
                  />
                </td>
                <td>
                  <input
                    width="100%"
                    type="text"
                    value={filters["parent_Name"] ? filters["parent_Name"].value : ""}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                    style={inputStyleH}
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Credit Limit</th>
                <th style={tableHeaderStyle}>Payment Term</th>
                <th style={tableHeaderStyle}>Parent</th>
                <th style={tableHeaderStyle}>Name</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.cuUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.cuUkNo}
                        checked={selectedRows.includes(rowData.cuUkNo)}
                        onChange={() => handleRowSelect(rowData.cuUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cu_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.cu_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cu_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.cu_CR_LT}</td>
                    <td style={tableCellStyle}>{rowData.cu_PT_TM}</td>
                    <td style={tableCellStyle}>{rowData.cu_PA_NO}</td>
                    <td style={tableCellStyle}>{rowData.parent_Name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
  {/* pagination */}
  <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>

        {/* custoemr */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
        {/* parent */}
      <AddressBookPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={FormData}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      <CustomerMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        onchangeDropDownFormData15={onchangeDropDownFormData15}
        onchangeDropDownFormData16={onchangeDropDownFormData16}
        onchangeDropDownFormData17={onchangeDropDownFormData17}
        onchangeDropDownFormData18={onchangeDropDownFormData18}
        onchangeDropDownFormData19={onchangeDropDownFormData19}
        onchangeDropDownFormData20={onchangeDropDownFormData20}
        onchangeDropDownFormData21={onchangeDropDownFormData21}
        onchangeDropDownFormData22={onchangeDropDownFormData22}
        onchangeDropDownFormData23={onchangeDropDownFormData23}
        cu_CO_NO={cu_CO_NO}
        cu_PT_TM={cu_PT_TM}
        cu_CR_CD={cu_CR_CD}
        cu_PY_IN={cu_PY_IN}
        cu_GL_CD={cu_GL_CD}
        cu_IN_HD={cu_IN_HD}
        cu_ST_AD={cu_ST_AD}
        cu_IN_AD={cu_IN_AD}
        cu_OD_AP={cu_OD_AP}
        cu_NT_FL={cu_NT_FL}
        cu_PR_AG={cu_PR_AG}
        cu_FR_HC={cu_FR_HC}
        cu_RT_CD={cu_RT_CD}
        cu_TX_AR={cu_TX_AR}
        cu_TX_CD={cu_TX_CD}
        cu_HL_CD={cu_HL_CD}
        cu_TX_TY={cu_TX_TY}
        cu_TX_AP={cu_TX_AP}
        cu_ST_PT={cu_ST_PT}
        cu_ST_CY={cu_ST_CY}
        cu_IT_RT={cu_IT_RT}
        cu_AP_FR={cu_AP_FR}
        cu_PR_GP={cu_PR_GP}
        cu_BL_TY={cu_BL_TY}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        selectedAbAdNM1={selectedAbAdNM1}
        selectedAbAdNM2={selectedAbAdNM2}
        selectedAbAdNM3={selectedAbAdNM3}
        selectedAbAdNM4={selectedAbAdNM4}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedAbAdNM1={setSelectedAbAdNM1}
        setSelectedAbAdNM2={setSelectedAbAdNM2}
        setSelectedAbAdNM3={setSelectedAbAdNM3}
        setSelectedAbAdNM4={setSelectedAbAdNM4}
      />
    </div>
    </div>
  );
};

export default CustomerMasterMain;
