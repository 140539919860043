import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function OperationSequenceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  operationtabledata,
  setoperationtabledata,
}) {
  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { osUkNo,os_WK_CT, os_SQ_DS } = data;
  const addRow = () => {
    setoperationtabledata([...operationtabledata, {}]);
  };
  
  const deleteRow = () => {
    if (operationtabledata.length > 0) {
      const updatedData = [...operationtabledata];
      updatedData.pop();
      setoperationtabledata(updatedData);
    }
  };
  const [workcentreVisible, setworkcentreVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect = (content) => {
    document.getElementById (`os_WK_CT_${SelectedRowIndex}`).value =  content.wc_WK_CT;
    // onchangeFormData("os_WK_CT", content.wc_WK_CT,SelectedRowIndex);
    setworkcentreVisible(false);
    // setShowSearchIcon(false);
  };
  const openWorkcentrePopup = (e) => {
    setworkcentreVisible(true);
    setSelectedRowIndex(e);
  };

const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{osUkNo ? "Revise Operation Sequence" : "Add Operation Sequence"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th> Operation Sequence</th>
              <th> Description </th>
              <th> Work Centre </th>
            </tr>
          </thead>
          <tbody>
            {operationtabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`osUkNo_${index}`}
                    name={`osUkNo_${index}`}
                    value={row.osUkNo}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_OP_SQ_${index}`}
                    name={`os_OP_SQ_${index}`}
                    defaultValue={row.os_OP_SQ}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_SQ_DS_${index}`}
                    name={`os_SQ_DS_${index}`}
                    defaultValue={row.os_SQ_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <div style={{display:"flex"}}>
                  <InputText
                    id={`os_WK_CT_${index}`}
                    name={`os_WK_CT_${index}`}
                    defaultValue={row.os_WK_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                   <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                    }}
                    onClick={(e)=>openWorkcentrePopup(index)}
                  ></i>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 
      <WorkCentrePopup
        visible={workcentreVisible}
        onClose={() => setworkcentreVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
