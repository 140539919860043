import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AttachTestToItemDialog from "./AttachTestToItemDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  itUkNo:"",
  ti_IT_NM:"",
  item_DESC:"",
  ti_TS_ID:"",   
  ti_AL_MI:"",
  ti_PR_MI:"",
  ti_TR_VL:"",   
  ti_PR_MX:"",
  ti_AL_MX:"",
  ti_TS_UM:"",    
  ti_FR_DT:"",   
  ti_TO_DT:"", 
};

const initialFilters = {
  ti_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },
  ti_TS_ID: { value: "", operation: "" },   
  ti_AL_MI: { value: "", operation: "" },
  ti_PR_MI: { value: "", operation: "" },
  ti_TR_VL: { value: "", operation: "" },   
  ti_PR_MX: { value: "", operation: "" },
  ti_AL_MX: { value: "", operation: "" },
  ti_TS_UM: { value: "", operation: "" },    
  ti_FR_DT: { value: "", operation: "" },   
  ti_TO_DT: { value: "", operation: "" }, 
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [ti_CO_CT, setti_CO_CT ] = useState("");
  const [ep_TR_CR, setep_TR_CR ] = useState("");
  const [ti_TS_UM, setti_TS_UM ] = useState("");
  const [ep_EX_TY, setep_EX_TY ] = useState("");
  const [ep_LO_CN, setep_LO_CN ] = useState("");
  
// AdvanceSearch
const [SelectedITNM, setSelectedITNM ] = useState("");

  const [selectedSupplier, setselectedSupplier ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [AttachTestToItemTableData, setAttachTestToItemTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setAttachTestToItemTableData([{}]);
    setti_CO_CT(null);
    setep_TR_CR(null);
    setti_TS_UM(null);
    setep_EX_TY(null);
    setep_LO_CN(null);
    setSelectedITNM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AttachTestToItem/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.attachTestToItemList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.itUkNo;
            return UpdatedworkData;
          });
          setAttachTestToItemTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          setSelectedITNM(CashFlowData.item_DESC);
          setti_CO_CT(CashFlowData.ti_CO_CT);
          setti_TS_UM(CashFlowData.attachTestToItemList.ti_TS_UM);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ti_CO_NO , setti_CO_NO] = useState("");

  const handleDefault = async () => {
    setti_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/AttachTestToItem/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AttachTestToItem/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setAttachTestToItemTableData(CashFlowData.attachTestToItemList);
        setFormData(CashFlowData.attachTestToItemList[0]);
        setSelectedITNM(CashFlowData.item_DESC);
        setti_CO_CT(CashFlowData.ti_CO_CT);
        setti_TS_UM(CashFlowData.attachTestToItemList.ti_TS_UM);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("AttachTestToItemtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const attachTestToItemList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const FR_DT = document.getElementsByName("ti_FR_DT_" + i)[0];
    const TO_DT = document.getElementsByName("ti_TO_DT_" + i)[0];
    const details = {
        itUkNo : document.getElementById("itUkNo_" + i).value,
        ti_TS_ID : document.getElementById("ti_TS_ID_" + i).value,
        ti_TS_DS : document.getElementById("ti_TS_DS_" + i).value,
        ti_AL_MI : document.getElementById("ti_AL_MI_" + i).value,
        ti_PR_MI : document.getElementById("ti_PR_MI_" + i).value,
        ti_TR_VL : document.getElementById("ti_TR_VL_" + i).value,
        ti_PR_MX : document.getElementById("ti_PR_MX_" + i).value,
        ti_AL_MX : document.getElementById("ti_AL_MX_" + i).value,
        ti_TS_UM : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        ti_FR_DT : FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ti_TO_DT : TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        ti_CO_NO :company,
    };
    attachTestToItemList.push(details);
  }
 requestBody = {
  attachTestToItemList,
  itUkNo:FormData.itUkNo,
  ti_IT_NM: document.getElementById("ti_IT_NM").value,
  item_DESC: document.getElementById("im_IT_DS").value,
  ti_CO_CT: ti_CO_CT, 
  ti_TS_ST: document.getElementById("ti_TS_ST").value,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.itUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/AttachTestToItem/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ti_CO_NO = company;
      axios
        .post(`${domain}/api/AttachTestToItem/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}>
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input 
                    type="text"
                    value={filters["ti_IT_NM"] ? filters["ti_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_IT_NM",
                        e.target.value,
                        filters["ti_IT_NM"]
                          ? filters["ti_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"] ? filters["item_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ti_TS_ID"] ? filters["ti_TS_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_TS_ID",
                        e.target.value,
                        filters["ti_TS_ID"]
                          ? filters["ti_TS_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["ti_AL_MI"] ? filters["ti_AL_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_AL_MI",
                        e.target.value,
                        filters["ti_AL_MI"]
                          ? filters["ti_AL_MI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ti_PR_MI"] ? filters["ti_PR_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_PR_MI",
                        e.target.value,
                        filters["ti_PR_MI"] ? filters["ti_PR_MI"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ti_TR_VL"] ? filters["ti_TR_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_TR_VL",
                        e.target.value,
                        filters["ti_TR_VL"]
                          ? filters["ti_TR_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ti_PR_MX"] ? filters["ti_PR_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_PR_MX",
                        e.target.value,
                        filters["ti_PR_MX"]
                          ? filters["ti_PR_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ti_AL_MX"] ? filters["ti_AL_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_AL_MX",
                        e.target.value,
                        filters["ti_AL_MX"]
                          ? filters["ti_AL_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ti_TS_UM"] ? filters["ti_TS_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_TS_UM",
                        e.target.value,
                        filters["ti_TS_UM"]
                          ? filters["ti_TS_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ti_FR_DT"] ? filters["ti_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_FR_DT",
                        e.target.value,
                        filters["ti_FR_DT"]
                          ? filters["ti_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["ti_TO_DT"] ? filters["ti_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ti_TO_DT",
                        e.target.value,
                        filters["ti_TO_DT"]
                          ? filters["ti_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Test  Id</th>  
                <th style={tableHeaderStyle}>Allowed Minimum</th>   
                <th style={tableHeaderStyle}>Preferred Minimum</th>  
                <th style={tableHeaderStyle}>Target</th> 
                <th style={tableHeaderStyle}>Preferred Maximum</th>  
                <th style={tableHeaderStyle}>Allowed Maximum</th>  
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>From Date</th>  
                <th style={tableHeaderStyle}>To Date</th>               
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.itUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.itUkNo}
                        checked={selectedRows.includes(rowData.itUkNo)}
                        onChange={() => handleRowSelect(rowData.itUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ti_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.ti_TS_ID}</td>   
                    <td style={tableCellStyle}>{rowData.ti_AL_MI}</td>
                    <td style={tableCellStyle}>{rowData.ti_PR_MI}</td>
                    <td style={tableCellStyle}>{rowData.ti_TR_VL}</td>   
                    <td style={tableCellStyle}>{rowData.ti_PR_MX}</td>
                    <td style={tableCellStyle}>{rowData.ti_AL_MX}</td>
                    <td style={tableCellStyle}>{rowData.ti_TS_UM}</td>    
                    <td style={tableCellStyle}>{formatDate(rowData.ti_FR_DT)}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.ti_TO_DT)}</td>              
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <AttachTestToItemDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        AttachTestToItemTableData={AttachTestToItemTableData}
        setAttachTestToItemTableData={setAttachTestToItemTableData}
        ti_CO_NO={ti_CO_NO}
        ti_CO_CT={ti_CO_CT}
        setti_CO_CT={setti_CO_CT}
        ti_TS_UM={ti_TS_UM}
        setti_TS_UM={setti_TS_UM}
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
      />
    </div>
  );
};

export default WorkCentreMain;
