import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemPopup from "../Popups/ItemPopup";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ItemUMConversionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ItemUMConversionTableData,
  setItemUMConversionTableData,
  ep_TR_CR,
  seter_EX_TY,
  seter_AD_CR,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  ic_FR_UM,
  setic_FR_UM,
  ic_TO_UM,
  setic_TO_UM,
  ep_CO_NO,
}) {
  const {
    icUkNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeCD } = ClassCode();
  const [ic_FR_UMOptions, setic_FR_UMOptions] = useState([]);
  const [ic_TO_UMOptions, setic_TO_UMOptions] = useState([]);
  //From UM
  const FromUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_FR_UM(value);
  };
  // To UM
  const ToUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_TO_UM(value);
  };
  useEffect(() => {
    FromUM();
    ToUM();
  }, []);
  const FromUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_FR_UMOptions(Options);
  };
  const ToUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_TO_UMOptions(Options);
  };
  const addRow = () => {
    setItemUMConversionTableData([...ItemUMConversionTableData, {}]);
  };
  const deleteRow = () => {
    if (ItemUMConversionTableData.length > 0) {
      const updatedData = [...ItemUMConversionTableData];
      updatedData.pop();
      setItemUMConversionTableData(updatedData);
    }
  };
  // const handleConversion =async () => {
  //   const Url = `${domain}/api/ItemConversion/convert`;
  //   const requestData={
  //     "factor":document.getElementById (`ic_CN_FT_${SelectedRowIndex}`).value,
  //   }
  //   try {
  //     const response =await axios.post(Url, requestData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //         "Access-Control-Allow-Origin": "http://localhost:3000",
  //         "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //       },
  //     });
  
  //     if (response.status !== 200) {
  //       throw new Error(`API request failed with status: ${response.status}`);
  //     }
  //     const dataLead = response.data.Result;
  
  //     if (dataLead ) {
  //       document.getElementById (`ic_IN_CN_${SelectedRowIndex}`).value =  dataLead.cl_CR_AM;             
  
  //     } else {
  //       console.error("Invalid API response:" );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (Result) => {
    setselectedItem(Result.im_IT_NM)
    setselectedDesc(Result.im_IT_DS)
    onchangeFormData("ic_IT_NM", Result.im_IT_NM);
    onchangeFormData("name", Result.im_IT_DS);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      for(var i=0;i<ItemUMConversionTableData.length;i++){
        FromUMDropdown(`ic_TO_UM_${i}`, dataItMt.im_PR_UM);
      }
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(icUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{icUkNo ? "Revise Item UM Conversion" : "Add Item UM Conversion"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                        <label htmlFor="ic_IT_NM" className="font-bold">
                      Item No <span style={{ color: 'red' }}>*</span>
                        </label>
                        <div style={{ display: "flex" }}>
                          <InputText 
                            id="ic_IT_NM"
                            name="ic_IT_NM"
                            onChange={(Event) => onchangedata(Event)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={SelectedItem}
                          />
                          {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "4%",
                              cursor: "pointer",
                            }}
                            onClick={openItemPopup}
                          ></i>
                          )}
                        </div>
                      </div>
                      <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                        <label htmlFor="name" className="font-bold">
                        Description
                        </label>
                        <InputText disabled
                          id="name"
                          name="name"
                          value={SelectedDesc}
                        readOnly
                        />
                  </div>
                        
              </div>
          </div>  
            <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="ItemUMConversiontable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>1</th>
                <th>From UM</th>
                <th>=</th>
                <th>Conversion Factor</th>
                <th>To UM</th>
                <th>Inverse Conversion</th>
              </tr>
            </thead>
            <tbody>
              {ItemUMConversionTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`icUkNo_${index}`}
                      name={`icUkNo_${index}`}
                      value={row.icUkNo}
                    />
                  </td>
                    <td >
                    <InputText  disabled style={{display:"block"}}
                      value={1}
                    />
                  </td>
                  <td>
                    <Dropdown 
                      id={`ic_FR_UM_${index}`}
                      name={`ic_FR_UM_${index}`}
                      value={ data[`ic_FR_UM_${index}`] ? data[`ic_FR_UM_${index}`]: row.ic_FR_UM}
                      options={ic_FR_UMOptions}
                      onChange={(e) =>FromUMDropdown(`ic_FR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                    </td>
                  <td >
                    <InputText  disabled
                      value={"="}
                    />
                  </td>
                  <td >
                    <InputText 
                      id={`ic_CN_FT_${index}`}
                      name={`ic_CN_FT_${index}`}
                      defaultValue={row.ic_CN_FT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown disabled 
                      id={`ic_TO_UM_${index}`}
                      name={`ic_TO_UM_${index}`}
                      value={ data[`ic_TO_UM_${index}`] ? data[`ic_TO_UM_${index}`]: row.ic_TO_UM}
                      options={ic_TO_UMOptions}
                      onChange={(e) =>ToUMDropdown(`ic_TO_UM_${index}`, e.value)}
                      placeholder="Select Cost Centre"
                    />
                    </td>
                  <td >
                    <InputText disabled
                      id={`ic_IN_CN_${index}`}
                      name={`ic_IN_CN_${index}`}
                      defaultValue={row.ic_IN_CN}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
            </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
