import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import LeasePopup from "../Popups/LeasePopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function DepositRefundDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  addRow,
  deleteRow,
  handleConfirm,
  DepositRefundtable,
  sr_TR_DT,
  onchangedata,
  setSelectedLeaseNo,
  SelectedLeaseNo,
  setSelectedLeaseType,
  SelectedLeaseType,
  setSelectedLeaseCompany,
  SelectedLeaseCompany,
  SelectedTenent,
  setSelectedTenent,
  SelectedTenentName,
  setSelectedTenentName,
  SelectedTransCurrency,
  setSelectedTransCurrency,
  setSelectedExchangeRate,
  SelectedExchangeRate,
  setSelectedBaseCurrency,
  SelectedBaseCurrency,
  sr_DO_TY,
  sr_DO_CO,
  setSrEXPR,
  setSrFRAM,
  sr_RS_CD,
  setsr_RS_CD,
  setFormData,
  setDepositRefundtable
}) {
  const { 
  srUkNo,
  srLeNo,
  sr_LE_TY,
  sr_LE_CO,
  sr_TE_NT,
  sr_RF_DT,
  sr_GL_DT,
  sr_TR_CR,
  sr_EX_RT,
  sr_BS_CR,
  sr_PR_NO,
  sr_BL_NO,
  sr_FL_NO,
  sr_UN_TS,
  sr_BL_CD,
  sr_AS_NO,
  // sr_EX_PR,
  sr_OP_AM,
  sr_RF_AM,
  sr_FR_AM,
  sr_FR_OP,
  sr_RF_FR,
  sr_DO_NO,
  sr_CN_FM,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,
    savestyle,cancelstyle,confirmstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS,fetchClassCodeCD} = ClassCode();
  const [sr_BL_CDOptions, setsr_BL_CDOptions] = useState([]);
  const [sr_AS_NOOptions, setsr_AS_NOOptions] = useState([]);

  const [LeaseNoPopupVisible, setLeaseNoPopupVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };
  const openLeaseNoPopup = (e) => {
    setLeaseNoPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const handleDataSelect2 = async (data) => {
    setSelectedLeaseNo(data.liLeNo);  
    //setSelectedLeaseType(data.li_LE_TY);
    setSelectedLeaseCompany(data.li_LE_CO);  
    setSelectedTenent(data.li_TE_NT); 
    setSelectedTenentName(data.teanat_NAME);
    setSelectedTransCurrency(data.li_TR_CR); 
    setSelectedExchangeRate(data.li_EX_RT); 
    setSelectedBaseCurrency(data.li_BS_CR);
    onchangeFormData("srLeNo", data.liLeNo);   
    onchangeFormData("sr_LE_TY", data.li_LE_TY);
    onchangeFormData("sr_LE_CO", data.li_LE_CO);
    onchangeFormData("sr_TE_NT", data.li_TE_NT);   
    onchangeFormData("sr_TR_CR", data.li_TR_CR);
    onchangeFormData("sr_EX_RT", data.li_EX_RT);
    onchangeFormData("sr_BS_CR", data.li_BS_CR);
    DepositRefundtable.forEach(s=>{
      s['sr_UN_TS']=data.li_UN_TS;
      s['sr_FL_NO']=data.li_FL_NO;
      s['sr_BL_NO']=data.li_BL_NO;
      s['sr_PR_NO']=data.li_PR_NO;
      s['sr_EX_PR']=data.se_EX_PR;
      s['sr_OP_AM']=data.se_EX_PR;
      s['sr_FR_AM']=data.se_FR_AM;
      s['sr_FR_OP']=data.se_FR_AM;
    });
	const ukNo = data.li_UK_NO;
	try {
        const response = await axios.get(
          `${domain}/api/LeaseInformation/Select/${ukNo}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const LeaseInformationData = response.data;
		  const led = [];
		  const  size = LeaseInformationData.leaseInformations.length;
		  for(let i = 0 ;  i < size ; i++){
		  const s = {
			  "sr_UN_TS" : data.li_UN_TS,
			  "sr_FL_NO" : data.li_FL_NO,
			  "sr_BL_NO" : data.li_BL_NO,
			  "sr_PR_NO" : data.li_PR_NO,
			  "sr_EX_PR" : data.se_EX_PR,
			  "sr_OP_AM" : data.se_EX_PR,
			  "sr_FR_AM" : data.se_FR_AM,
			  "sr_FR_OP" : data.se_FR_AM,
		   }
		   led.push(s);
	
		  }
		  setDepositRefundtable(led);
		  
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    setLeaseNoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [sr_RS_CDOptions, setsr_RS_CDOptions] = useState([]);
  const ReasonCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsr_RS_CD(value);
  };
  useEffect(() => {
    fetchBillcode();
    fetchDepositetype();
    ReasonCode();
  }, []);

  const fetchBillcode = async () => {
    const Options = await fetchClassCodeDS("BL/CD");
    setsr_BL_CDOptions(Options);
  };
  const fetchDepositetype = async () => {
    const Options = await fetchClassCodeDS("DE/TY");
    setsr_AS_NOOptions(Options);
  };
  const ReasonCode = async () => {
    const Options = await fetchClassCodeDS("RN/RC");
    setsr_RS_CDOptions(Options);
  };
  // DATE DEFAULT
  const [selectedRFDate, setSelectedRFDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedRFDate(e.value);
    onchangedata({ target: { name: "sr_RF_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "sr_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // DEFAULT AFTER CONFIRM
  const isDisabled = sr_CN_FM === "C";

  const isUpdateMode = Boolean(srUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{srUkNo ? "Revise Deposit Refund" : "Add Deposit Refund"}</h5>
        <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
          <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
            <Button
              style={savestyle}
              icon="pi pi-check"
              onClick={handleFormSubmit} 
               tooltip="Save"
            />
            <Button
              style={cancelstyle}
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Cancel"
            />
          {isUpdateMode && (
            <Button 
              style={confirmstyle}
              icon="pi pi-check-circle" 
              onClick={handleConfirm} 
              tooltip={"Confirm"}>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
  return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="srLeNo" className="font-bold">
                  Lease Number <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText  disabled={isDisabled}
                    id="srLeNo"
                    name="srLeNo"
                    value={SelectedLeaseNo}
                    onFocus={() => setShowSearchIcon(true)}
                    readOnly
                  />
                  {showSearchIcon && (
                    <i  className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                    }}
                    onClick={(e) => openLeaseNoPopup(e)}
                  ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_LE_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="sr_LE_TY"
                  name="sr_LE_TY"
                  defaultValue={SelectedLeaseType}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_LE_CO" className="font-bold">
                    Company
                </label>
                <InputText disabled
                  id="sr_LE_CO"
                  name="sr_LE_CO"
                  value={SelectedLeaseCompany}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sr_DO_NO" className="font-bold">
                Document No
              </label>
              <InputText disabled
                id="sr_DO_NO"
                name="sr_DO_NO"
                value={sr_DO_NO}
                onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_DO_TY" className="font-bold">
                  Document Type
                </label>
                <InputText disabled
                  id="sr_DO_TY"
                  name="sr_DO_TY"
                  value={sr_DO_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_DO_CO" className="font-bold">
                  Document Company
                </label>
                <InputText  disabled
                  id="sr_DO_CO"
                  name="sr_DO_CO"
                  defaultValue={sr_DO_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                  <label htmlFor="sr_TE_NT" className="font-bold" >
                  Tenant
                  </label>
                  <div style={{display:"none"}}>
                  <InputText disabled
                    id="sr_TE_NT"
                    name="sr_TE_NT"
                    value={SelectedTenent}
                  />
                  </div>
                    <InputText disabled
                    id="sr_TE_NT"
                    name="sr_TE_NT"
                    value={SelectedTenentName}
                  />
              </div> 
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_RF_DT" className="font-bold">
                  Refund  Date
                </label>
                <Calendar disabled={isDisabled}
                id="sr_RF_DT"
                name="sr_RF_DT"
                value={moment(sr_RF_DT, "YYYYMMDD").toDate()}
                onChange={handleDateTRChange}
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sr_GL_DT" className="font-bold">
                    GL Date
                  </label>
                  <Calendar disabled={isDisabled}
                  id="sr_GL_DT"
                  name="sr_GL_DT"
                  value={moment(sr_GL_DT, "YYYYMMDD").toDate()}
                  onChange={handleDateGLChange}
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_TR_CR" className="font-bold">
                Trans. Currency
                </label>
                <InputText disabled
                  id="sr_TR_CR"
                  name="sr_TR_CR"
                  value={SelectedTransCurrency}
                  readOnly
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_EX_RT" className="font-bold">
                Exchange Rate
                </label>
                <InputText disabled
                  id="sr_EX_RT"
                  name="sr_EX_RT"
                  value={SelectedExchangeRate}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_BS_CR" className="font-bold">
                Base Currency
                </label>
                <InputText disabled
                  id="sr_BS_CR"
                  name="sr_BS_CR"
                  value={SelectedBaseCurrency}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sr_RS_CD" className="font-bold">
                Reason 
                </label>
                <Dropdown disabled={isDisabled}
                  id="sr_RS_CD"
                  name="sr_RS_CD"
                  value={sr_RS_CD}
                  options={sr_RS_CDOptions}
                  onChange={(e) => ReasonCodeDropdown("sr_RS_CD", e.value)}
                  placeholder="Select Reason "
                />
              </div>
          </div>
        </div>
        <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="DepositRefundtable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Unit </th>
          <th>Floor </th>
          <th>Building </th>
          <th>Property </th>
          <th>Billing Code</th>
          <th style={{ display: "none" }}>Deposit Type</th>
          <th>Gross Amount</th>
          <th style={{ display: "none" }}>Open Amount</th>
          <th>Refund Amount</th>
          <th>Fore. Amount</th>          
          <th>Fore.Open</th>
          <th>Fore. Refund</th>
        </tr>
      </thead>
      <tbody>
      {DepositRefundtable.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`srUkNo_${index}`}
              name={`srUkNo_${index}`}
              value={row.srUkNo}
            />
          </td>
          <td>
            <InputText disabled 
            id={`sr_UN_TS_${index}`}
            name={`sr_UN_TS_${index}`}
            defaultValue={row.sr_UN_TS}
            readOnly
          />
          </td>
          <td>
            <InputText disabled 
            id={`sr_FL_NO_${index}`}
            name={`sr_FL_NO_${index}`}
            defaultValue={row.sr_FL_NO}
            readOnly
          />
          </td>
          <td>
            <InputText disabled 
            id={`sr_BL_NO_${index}`}
            name={`sr_BL_NO_${index}`}
            defaultValue={row.sr_BL_NO}
            readOnly
          />
          </td>
          <td>
            <InputText disabled 
            id={`sr_PR_NO_${index}`}
            name={`sr_PR_NO_${index}`}
            defaultValue={row.sr_PR_NO}
            readOnly
          />
          </td>
            <td>
            <Dropdown disabled={isDisabled}
              id={`sr_BL_CD_${index}`}
              name={`sr_BL_CD_${index}`}
              value={data[`sr_BL_CD_${index}`] ? data[`sr_BL_CD_${index}`]:row.sr_BL_CD }
              options={sr_BL_CDOptions}
              onChange={(e) => handleFormDataChange(`sr_BL_CD_${index}`, e.value)}
              placeholder="Select Billing Code"
            />
          </td>
          <td style={{ display: "none" }}>
          <Dropdown disabled={isDisabled}
              id={`sr_AS_NO_${index}`}
              name={`sr_AS_NO_${index}`}
              value={data[`sr_AS_NO_${index}`] ? data[`sr_AS_NO_${index}`]:row.sr_AS_NO }
              options={sr_AS_NOOptions}
              onChange={(e) => handleFormDataChange1(`sr_AS_NO_${index}`, e.value)}
              placeholder="Select Deposit Type"
            />
          </td>
          <td>
          <InputText  disabled
            id={`sr_EX_PR_${index}`}
            name={`sr_EX_PR_${index}`}
            defaultValue={row.sr_EX_PR}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td style={{ display: "none" }}>
          <InputText  disabled={isDisabled}
            id={`sr_OP_AM_${index}`}
            name={`sr_OP_AM_${index}`}
            defaultValue={row.sr_OP_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText 
            disabled={sr_TR_CR == sr_BS_CR ? false : true}
            id={`sr_RF_AM_${index}`}
            name={`sr_RF_AM_${index}`}
            defaultValue={row.sr_RF_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled 
            id={`sr_FR_AM_${index}`}
            name={`sr_FR_AM_${index}`}
            defaultValue={row.sr_FR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText 
            disabled={sr_TR_CR !== sr_BS_CR ? false : true} 
            id={`sr_FR_OP_${index}`}
            name={`sr_FR_OP_${index}`}
            value={row.sr_FR_OP}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText 
            disabled={sr_TR_CR !== sr_BS_CR ? false : true} 
            id={`sr_RF_FR_${index}`}
            name={`sr_RF_FR_${index}`}
            defaultValue={row.sr_RF_FR}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
        </div>   
      </div>
  <LeasePopup
        visible={LeaseNoPopupVisible}
        onClose={() => setLeaseNoPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        leaseType={SelectedLeaseType}
      /> 
  
    </Dialog>
  );

      }
