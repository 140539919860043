import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import DiagnosisPopup from "views/admin/Popups/DiagnosisPopup";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
export default function MedicineMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MedicineMasterTableData,
  setMedicineMasterTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  setselectedAddress,
  setselectedName,
  mm_CO_NO,
}) {
  const {
    mmUkNo,
  } = data;
  const [AddresstypeC, setAddresstype] = useState("C");
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [cr_CR_TYOptions, setcr_CR_TYOptions] = useState([]);



  useEffect(() => {
  }, []);




  const addRow = () => {
    setMedicineMasterTableData([...MedicineMasterTableData, {}]);
  };
  
  const deleteRow = () => {
    if (MedicineMasterTableData.length > 0) {
      const updatedData = [...MedicineMasterTableData];
      updatedData.pop();
      setMedicineMasterTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(mmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("mm_MD_NM", data.im_IT_NM);
    onchangeFormData("med_NAME", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  // Address
  const [AddressVisible, setAddressVisible] = useState(false);
  const handleAddress =async (data) => {
    setselectedAddress(data.dm_DA_CD)
    setselectedName(data.dm_DA_DS)
    onchangeFormData("md_DA_CD", data.dm_DA_CD, SelectedRowIndex);
    onchangeFormData("diagnosis", data.dm_DA_DS, SelectedRowIndex);
    setAddressVisible(false);
    setShowSearchIcon(false);
  };
  const openAddressPopup = (e) => {
    setAddressVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={mmUkNo ? "Revise Medicine Master" : "Add Medicine Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="mm_MD_NM" className="font-bold">
                 Medicine Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="mm_MD_NM"
                      name="mm_MD_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="med_NAME" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="med_NAME"
                    name="med_NAME"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="mm_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="mm_CO_NO"
                    name="mm_CO_NO"
                    value={mm_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>
                  
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="MedicineMastertable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Seq No</th>
              <th>Diagnosis <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {MedicineMasterTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`md_UK_NO_${index}`}
                    name={`md_UK_NO_${index}`}
                    value={row.md_UK_NO}
                  />
                </td>
                  <td >
                  <InputText disabled
                    id={`md_SQ_NO_${index}`}
                    name={`md_SQ_NO_${index}`}
                    defaultValue={index+1}
                  />
                </td>
                
                
                    <td style={{ display: "flex" }}>
                    <InputText
                    id={`md_DA_CD_${index}`}
                    name={`md_DA_CD_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.md_DA_CD ? row.md_DA_CD: row[`${"md_DA_CD"}_${index}`]}
                    />
                    {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                  }}
                  onClick={(e) => openAddressPopup(index)}
                  ></i>
                  )}
                </td>
                  <td>
                  <InputText disabled
                  id={`diagnosis_${index}`}
                  name={`diagnosis_${index}`}
                  defaultValue={row.diagnosis ? row.diagnosis : row[`${"diagnosis"}_${index}`]}
                  readOnly
                  />
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
        <DiagnosisPopup
        visible={AddressVisible}
        onClose={() => setAddressVisible(false)}
        onDataSelect={handleAddress}
        formData={data}
      />
    </Dialog>
  );
}
