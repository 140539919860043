import React, { useState, useEffect,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function WarrantyClaimDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SelectedServiceDlr,
  setSelectedServiceDlr,
  SelectedAccesor,
  setSelectedAccesor,
  SelectedCustomer,
  setSelectedCustomer,
  SelectedEquipment,
  setSelectedEquipment,
  SelectedItem,
  setSelectedItem,
  wc_ST_CD,
  setwc_ST_CD,
  onchangeDropDownFormData1,
}) {
  const {
    wcUkNo,
    wc_CL_CD1,
    wc_CL_CD2,
    wc_CL_CD3,
    wc_CL_CD4,
    wc_CL_CD5,
    wc_CL_CD6,
    wc_CL_CD7,
    wc_CL_CD8,
    wc_CL_CD9,
    wc_CL_CD10,
    wc_SR_DL,
    wc_DL_RF,
    wc_AS_OR,
    wc_EQ_NO,
    wc_IT_NM,
    wc_LT_NO,
    wc_PR_MD,
    wc_PR_FM,
    wc_CU_NO,
    wc_BL_M1,
    wc_BL_M2,
    wc_BL_M3,
    wc_CL_PT,
    wc_BU_UN,
    wc_DF_CD,
    wc_CL_SU,
    wc_FL_DT,
    wc_RP_DT,
    wc_SU_LT,
    wc_CC_P1,
    wc_FL_DS,
    // function values
    im_IT_DS,
  } = data;
  const {borderbox,TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [wc_ST_CDOptions, setwc_ST_CDOptions] = useState([]);

  useEffect(() => {
    fetchDropdownST();
  }, []);

  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setwc_ST_CDOptions(Options);
  };

// advance search
const [ServiceDLRPopupVisible, setServiceDLRPopupVisible] = useState(false);
const [AccessorPopupVisible, setAccessorPopupVisible] = useState(false);
const [EquipemntPopupVisible, setEquipemntPopupVisible] = useState(false);
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);

const [showSearchIcon, setShowSearchIcon] = useState("");
const [addressService, setaddressService] = useState("S");
const [addressAccessor, setaddressAccessor] = useState("S");
const [addressCustomer, setaddressCustomer] = useState("C");

const openServiceDealrPopup = () => {
  setServiceDLRPopupVisible(true);
};
const openAccessorPopup = () => {
  setAccessorPopupVisible(true);
};
const openEquipmentPopup = () => {
  setEquipemntPopupVisible(true);
};
const openItemPopup = () => {
  setItemPopupVisible(true);
};
const openCustomerPopup = () => {
  setCustomerPopupVisible(true);
};

const handleSelectService = (data) => {
  setSelectedServiceDlr(data.ab_AD_NM);
  onchangeFormData("wc_SR_DL", data.abAdNo);
  setServiceDLRPopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectAccessor = (data) => {
  setSelectedAccesor(data.ab_AD_NM);
  onchangeFormData("wc_AS_OR", data.abAdNo);
  setAccessorPopupVisible(false);
  setShowSearchIcon(false);
};
const selectedCustomerRef = useRef(null);
const selectedItemRef = useRef(null);
const handleSelectEquipemnt = async (data) => {
  setSelectedEquipment(data.em_EQ_DS);
  // onchangeFormData("wc_EQ_NO", data.em_EQ_NM);
  document.getElementById("wc_EQ_NO").value = data.em_EQ_NM;
  const Url = `${domain}/api/EquipmentMaster/GetByEquipmentName`;
  const requestData={
    "em_EQ_NM":data.em_EQ_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    // if (response.status !== 200) {
    //   throw new Error(`API request failed with status: ${response.status}`);
    // }
    // const dataEQ = response.data.result;
    // if (dataEQ ) {
    //   document.getElementById("im_IT_DS").value = dataEQ.item_NAME;
    //   document.getElementById("wc_IT_NM").value = dataEQ.em_IT_NM;
    //   document.getElementById("wc_LT_NO").value = dataEQ.em_SR_NO;
    //   document.getElementById("wc_PR_MD").value = dataEQ.em_PD_MD;
    //   document.getElementById("wc_PR_FM").value = dataEQ.em_PD_FM;
    //   document.getElementById("SelectedCustomer").value = dataEQ.customer_NAME;
    //   document.getElementById("wc_CU_NO").value = dataEQ.em_CU_NO;
    // } else {
    //   console.error("Invalid API response:", data);
    // }
    if (response.status === 200 && response.data.result) {
      const dataEQ = response.data.result;
      console.log(dataEQ); 
      // Use React ref to directly access DOM elements if necessary
      if (selectedCustomerRef.current) {
        selectedCustomerRef.current.value = dataEQ.customer_NAME;
      }
      if (selectedItemRef.current) {
        selectedItemRef.current.value = dataEQ.item_NAME;
      }
      setFormData((prevState) => ({
        ...prevState,
        em_EQ_DS: dataEQ.em_EQ_DS,
        wc_EQ_NO: dataEQ.em_EQ_NM,
        SelectedItem: dataEQ.item_NAME,
        wc_IT_NM: dataEQ.em_IT_NM,
        wc_LT_NO: dataEQ.em_SR_NO,
        wc_PR_MD: dataEQ.em_PD_MD,
        wc_PR_FM: dataEQ.em_PD_FM,
        cust : dataEQ.customer_NAME,
        SelectedCustomer: dataEQ.customer_NAME,
        wc_CU_NO: dataEQ.em_CU_NO,
      }));
    } else {
      console.error("Invalid API response or status not 200:", response);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setEquipemntPopupVisible(false);
  setShowSearchIcon(false);
};
  const handleSelectItem = (data) => {
    setSelectedItem(data.im_IT_DS);
    onchangeFormData("wc_IT_NM", data.im_IT_NM);
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectCustomer = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    document.getElementById("SelectedCustomer").value = data.ab_AD_NM;
    onchangeFormData("wc_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(wcUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{wcUkNo ? "Revise Warranty Claim" : "Add Warranty Claim" }</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
      )}
        {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
       )}
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
          {/* tab1 general */}
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="wcUkNo" className="font-bold">
                  UK_NO
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="wcUkNo"
                    name="wcUkNo"
                    value={wcUkNo}
                  />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AD_NM" className="font-bold">
                  Service Dealer <span style={{color:"red"}}> * </span>
                </label>
                <div style={{display:"flex"}}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedServiceDlr}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openServiceDealrPopup}
                  ></i>
                    )}
                    </div>

                <InputText
                  style={{display:"none"}}
                  id="wc_SR_DL"
                  name="wc_SR_DL"
                  value={wc_SR_DL}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_DL_RF" className="font-bold">
                  Dealer Reference
                </label>
                <InputText
                  id="wc_DL_RF"
                  name="wc_DL_RF"
                  value={wc_DL_RF}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AD_NM" className="font-bold">
                  Accessor <span style={{color:"red"}}> * </span>
                </label>
                <div style ={{display:"flex"}}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"                    
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedAccesor}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openAccessorPopup}
                  ></i>
                    )}
                    </div>
                <InputText style={{display:"none"}}
                  id="wc_AS_OR"
                  name="wc_AS_OR"
                  value={wc_AS_OR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_ST_CD" className="font-bold">
                  Status
                </label>
                <Dropdown
                  id="wc_ST_CD"
                  name="wc_ST_CD"
                  value={wc_ST_CD}
                  options={wc_ST_CDOptions}
                  onChange={(e) => onchangeDropDownFormData1("wc_ST_CD", e.value)}
                  placeholder="Select Status"
                />
              </div>            
            </div>
          </div>
          {/* 2ND DIV */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_EQ_NO" className="font-bold">
                    Equipment No <span style={{color:"red"}}> * </span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText
                    id="em_EQ_DS"
                    name="em_EQ_DS"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedEquipment}
                  />
                  {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEquipmentPopup}
                    ></i>
                      )}
                  </div>
                  <InputText style={{display:"BLOCK"}}
                    id="wc_EQ_NO"
                    name="wc_EQ_NO"
                    value={wc_EQ_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_IT_NM" className="font-bold">
                    Item <span style={{color:"red"}}> * </span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText disabled
                    id="SelectedItem"
                    name="SelectedItem"                    
                    // onChange={(e) => onchangedata(e)}
                    // onFocus={() => setShowSearchIcon(true)}
                    // defaultValue={SelectedItem}
                    ref={selectedItemRef} // Attach ref
                    defaultValue={SelectedItem}
                    onChange={(e) => setFormData({ ...FormData, SelectedItem: e.target.value })}
                    onFocus={() => setShowSearchIcon(true)}
                  />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                      )}
                  </div>
                  <InputText style={{display:"block"}}
                    id="wc_IT_NM"
                    name="wc_IT_NM"
                    defaultValue={wc_IT_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_LT_NO" className="font-bold">
                    Lot / Serial No
                  </label>
                  <InputText disabled
                    id="wc_LT_NO"
                    name="wc_LT_NO"
                    defaultValue={wc_LT_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_PR_MD" className="font-bold">
                    Product Model
                  </label>
                  <InputText disabled
                    id="wc_PR_MD"
                    name="wc_PR_MD"
                    defaultValue={wc_PR_MD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_PR_FM" className="font-bold">
                  Product Family
                  </label>
                  <InputText disabled
                    id="wc_PR_FM"
                    name="wc_PR_FM"
                    defaultValue={wc_PR_FM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_CU_NO" className="font-bold">
                  Customer <span style={{color:"red"}}> * </span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText disabled
                    id="SelectedCustomer"
                    name="SelectedCustomer"
                    // onChange={(e) => onchangedata(e)}
                    // onFocus={() => setShowSearchIcon(true)}
                    // defaultValue={SelectedCustomer}
                    ref={selectedCustomerRef} // Attach ref
                    defaultValue={SelectedCustomer}
                    onChange={(e) => setFormData({ ...FormData, SelectedCustomer: e.target.value })}
                    onFocus={() => setShowSearchIcon(true)}
                  />
                  {showSearchIcon && (
                    <i className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                      )}
                  </div>
                  <InputText style={{display:"block"}}
                    id="wc_CU_NO"
                    name="wc_CU_NO"
                    defaultValue={wc_CU_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                {/* 2 BOX */}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_BL_M1" className="font-bold">
                    Current Balance Meter 1
                  </label>
                  <InputText
                    id="wc_BL_M1"
                    name="wc_BL_M1"
                    value={wc_BL_M1}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_BL_M2" className="font-bold">
                  Current Balance Meter 2
                  </label>
                  <InputText
                    id="wc_BL_M2"
                    name="wc_BL_M2"
                    value={wc_BL_M2}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wc_BL_M3" className="font-bold">
                  Current Balance Meter 3
                  </label>
                  <InputText
                    id="wc_BL_M3"
                    name="wc_BL_M3"
                    value={wc_BL_M3}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
          {/* 3 DIV */}
          <div style={inboxstyle} >
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_PT" className="font-bold">
                  Casual Part
                </label>
                <InputText
                  id="wc_CL_PT"
                  name="wc_CL_PT"
                  value={wc_CL_PT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_BU_UN" className="font-bold">
                  Business Unit
                </label>
                <InputText
                  id="wc_BU_UN"
                  name="wc_BU_UN"
                  value={wc_BU_UN}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_SU" className="font-bold">
                  Casual Supplier
                </label>
                <InputText
                  id="wc_CL_SU"
                  name="wc_CL_SU"
                  value={wc_CL_SU}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_DF_CD" className="font-bold">
                  Defect Code
                </label>
                <InputText
                  id="wc_DF_CD"
                  name="wc_DF_CD"
                  value={wc_DF_CD}
                  onChange={(e) => onchangedata(e)}
                />
              </div>  
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_SU_LT" className="font-bold">
                  Supplier Lot
                </label>
                <InputText
                  id="wc_SU_LT"
                  name="wc_SU_LT"
                  value={wc_SU_LT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_FL_DT" className="font-bold">
                  Failure Date
                </label>
                <Calendar
                  id="wc_FL_DT"
                  name="wc_FL_DT"
                  value={moment(data.wc_FL_DT, "YYYYMMDD").isValid() ? moment(data.wc_FL_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "wc_FL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_RP_DT" className="font-bold">
                  Repair Date
                </label>
                <Calendar
                  id="wc_RP_DT"
                  name="wc_RP_DT"
                  value={moment(data.wc_RP_DT, "YYYYMMDD").isValid() ? moment(data.wc_RP_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "wc_RP_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>  
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CC_P1" className="font-bold">
                  Component Code - Part
                </label>
                <InputText
                  id="wc_CC_P1"
                  name="wc_CC_P1"
                  value={wc_CC_P1}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_FL_DS" className="font-bold">
                  Failure Description
                </label>
                <InputText
                  id="wc_FL_DS"
                  name="wc_FL_DS"
                  value={wc_FL_DS}
                  onChange={(e) => onchangedata(e)}
                />
              </div>  

            </div>
          </div>            
        </TabPanel>
          {/* tab2 */}
        <TabPanel header="Classification">
            <div style={inboxstyle}>
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wc_CL_CD1" className="font-bold">
              Phase 
              </label>
                <InputText
                  id="wc_CL_CD1"
                  name="wc_CL_CD1"
                  defaultValue={wc_CL_CD1}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD6" className="font-bold">
                Status
                </label>
                <InputText
                  id="wc_CL_CD6"
                  name="wc_CL_CD6"
                  defaultValue={wc_CL_CD6}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD2" className="font-bold">
                  Category 02
                </label>
                <InputText
                  id="wc_CL_CD2"
                  name="wc_CL_CD2"
                  defaultValue={wc_CL_CD2}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD3" className="font-bold">
                Category 03
                </label>
                <InputText
                  id="wc_CL_CD3"
                  name="wc_CL_CD3"
                  defaultValue={wc_CL_CD3}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD4" className="font-bold">
                Category 04
                </label>
                <InputText
                  id="wc_CL_CD4"
                  name="wc_CL_CD4"
                  defaultValue={wc_CL_CD4}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD5" className="font-bold">
                Category 05
                </label>
                <InputText
                  id="wc_CL_CD5"
                  name="wc_CL_CD5"
                  defaultValue={wc_CL_CD5}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD10" className="font-bold">
                Category 10
                </label>
                <InputText
                  id="wc_CL_CD10"
                  name="wc_CL_CD10"
                  defaultValue={wc_CL_CD10}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD7" className="font-bold">
                Service Type
                </label>
                  <InputText
                    id="wc_CL_CD7"
                    name="wc_CL_CD7"
                    defaultValue={wc_CL_CD7}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD8" className="font-bold">
                Skill Type
                </label>
                  <InputText
                    id="wc_CL_CD8"
                    name="wc_CL_CD8"
                    defaultValue={wc_CL_CD8}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="wc_CL_CD9" className="font-bold">
              Experience Level
                </label>
                  <InputText
                    id="wc_CL_CD9"
                    name="wc_CL_CD9"
                    defaultValue={wc_CL_CD9}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              </div>
            </div>
        </TabPanel>
      </TabView>
      </div> 
      {/* service */}
      <AddressBookPopup
        visible={ServiceDLRPopupVisible}
        onClose={() => setServiceDLRPopupVisible(false)}
        onDataSelect={handleSelectService}
        formData={data}
        addressType={addressService}
      />
      {/* accessor */}
      <AddressBookPopup
        visible={AccessorPopupVisible}
        onClose={() => setAccessorPopupVisible(false)}
        onDataSelect={handleSelectAccessor}
        formData={data}
        addressType={addressAccessor}
      />
      {/* customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressCustomer}
      />
      {/* equipemrnt */}
      <EquipmentPopup
        visible={EquipemntPopupVisible}
        onClose={() => setEquipemntPopupVisible(false)}
        onDataSelect={handleSelectEquipemnt}
        formData={data}
      />
      {/* item */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
