import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SupplierMasterDialog from "./SupplierMasterDialog";
import { InputText } from "primereact/inputtext";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  suUkNo:"",
    su_SU_NO:"",
    name: "",
    su_CO_NO:"",
    su_CR_CD:"",
    su_PT_TM:"",
    su_HO_PY:"",
    su_TX_AP:"",
    su_TX_TY:"",
    su_PT_TM:"",
    su_PY_IN:"",
    su_GL_CD:"",
    su_HO_PY:"",
    su_WH_PE:"",
    su_NT_FL:"",
    su_CR_NO:"",
    su_AP_NO:"",
    su_HL_CD:"",
    su_RT_CD:"",
    su_IT_RT:"",
    su_TR_DY:"",
    su_LD_CR:"",
    su_FR_HC:"",
    su_TX_CD:"",
    su_TX_RT:"",
    su_TX_AR:"",
};
const initialFilters = {
  su_SU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  su_CO_NO: { value: "", operation: "" },
  su_CR_CD: { value: "", operation: "" },
  su_PT_TM: { value: "", operation: "" },
  su_HO_PY: { value: "", operation: "" },
  su_TX_AP: { value: "", operation: "" },
  su_TX_TY: { value: "", operation: "" },
};
const SupplierMasterMain = () => {
// buttonstyles
const { buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [supplier, setSupplier] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [selectedAbAdNM2, setSelectedAbAdNM2] = useState("");
  const [selectedAbAdNM1, setSelectedAbAdNM1] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [su_CR_CD, setsu_CR_CD] = useState([]);
  const [su_PT_TM, setsu_PT_TM] = useState([]);
  const [su_PY_IN, setsu_PY_IN] = useState([]);
  const [su_GL_CD, setsu_GL_CD] = useState([]);
  const [su_HO_PY, setsu_HO_PY] = useState([]);
  const [su_WH_PE, setsu_WH_PE] = useState([]);
  const [su_NT_FL, setsu_NT_FL] = useState([]);
  const [su_HL_CD, setsu_HL_CD] = useState([]);
  const [su_RT_CD, setsu_RT_CD] = useState([]);
  const [su_IT_RT, setsu_IT_RT] = useState([]);
  const [su_LD_CR, setsu_LD_CR] = useState([]);
  const [su_FR_HC, setsu_FR_HC] = useState([]);
  const [su_TX_AP, setsu_TX_AP] = useState([]);
  const [su_TX_TY, setsu_TX_TY] = useState([]);
  const [su_TX_CD, setsu_TX_CD] = useState([]);
  const [su_TX_AR, setsu_TX_AR] = useState([]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
   
  };
  const handleDataSelect = (data) => {
    setSelectedSupplier(data.ab_AD_NM);
    setSupplier(data.abAdNo);
    setSupplierPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedAbAdNM1(null);
    setSelectedAbAdNM2(null);
    setSelectedemEMNO(null);
    setsu_CR_CD(null);
    setsu_PT_TM(null);
    setsu_PY_IN(null);
    setsu_GL_CD(null);
    setsu_HO_PY(null);
    setsu_WH_PE(null);
    setsu_NT_FL(null);
    setsu_HL_CD(null);
    setsu_RT_CD(null);
    setsu_IT_RT(null);
    setsu_LD_CR(null);
    setsu_FR_HC(null);
    setsu_TX_AP(null);
    setsu_TX_TY(null);
    setsu_TX_CD(null);
    setsu_TX_AR(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM1(null);
    setSelectedAbAdNM2(null);
    setSelectedemEMNO(null);
    setsu_CR_CD(null);
    setsu_PT_TM(null);
    setsu_PY_IN(null);
    setsu_GL_CD(null);
    setsu_HO_PY(null);
    setsu_WH_PE(null);
    setsu_NT_FL(null);
    setsu_HL_CD(null);
    setsu_RT_CD(null);
    setsu_IT_RT(null);
    setsu_LD_CR(null);
    setsu_FR_HC(null);
    setsu_TX_AP(null);
    setsu_TX_TY(null);
    setsu_TX_CD(null);
    setsu_TX_AR(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SupplierMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SupplierMasterData = response.data;
          delete SupplierMasterData.suUkNo;
          setsu_CR_CD(SupplierMasterData.su_CR_CD);
          setsu_PT_TM(SupplierMasterData.su_PT_TM);
          setsu_PY_IN(SupplierMasterData.su_PY_IN);
          setsu_GL_CD(SupplierMasterData.su_GL_CD);
          setsu_HO_PY(SupplierMasterData.su_HO_PY);
          setsu_WH_PE(SupplierMasterData.su_WH_PE);
          setsu_NT_FL(SupplierMasterData.su_NT_FL);
          setsu_HL_CD(SupplierMasterData.su_HL_CD);
          setsu_RT_CD(SupplierMasterData.su_RT_CD);
          setsu_IT_RT(SupplierMasterData.su_IT_RT);
          setsu_LD_CR(SupplierMasterData.su_LD_CR);
          setsu_FR_HC(SupplierMasterData.su_FR_HC);
          setsu_TX_AP(SupplierMasterData.su_TX_AP);
          setsu_TX_TY(SupplierMasterData.su_TX_TY);
          setsu_TX_CD(SupplierMasterData.su_TX_CD);
          setsu_TX_AR(SupplierMasterData.su_TX_AR);
          setFormData(SupplierMasterData);
          setSelectedemEMNO(SupplierMasterData.name);
          setSelectedAbAdNM1(SupplierMasterData.approvalName);
          setSelectedAbAdNM2(SupplierMasterData.carrierName);  
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault()
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SupplierMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [su_CO_NO , setsu_CO_NO ] = useState("");
  const handleDefault = async () => {
    setsu_CO_NO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            // setfi_CO_NO(`${company}`);
            // if(fName === "SD_OR_TY"){
            //   setsdOrTy(dataDefault.versionIds[i].default_VALUE)
            // }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
 
 
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_CR_CD(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_PT_TM(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_PY_IN(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_GL_CD(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_HO_PY(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_WH_PE(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_NT_FL(value);
  };
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_HL_CD(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_RT_CD(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_IT_RT(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_LD_CR(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_FR_HC(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_TX_AP(value);
  };
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_TX_TY(value);
  };
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_TX_CD(value);
  };
  const onchangeDropDownFormData17 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsu_TX_AR(value);
  };
 
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SupplierMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SupplierMasterData = response.data;
        setFormData(SupplierMasterData);
        setSelectedemEMNO(response.data.name);
        setSelectedAbAdNM1(response.data.approvalName);
        setSelectedAbAdNM2(response.data.carrierName);  
        setsu_CR_CD(response.data.su_CR_CD);
        setsu_PT_TM(SupplierMasterData.su_PT_TM);
        setsu_PY_IN(response.data.su_PY_IN);
        setsu_GL_CD(response.data.su_GL_CD);
        setsu_HO_PY(response.data.su_HO_PY);
        setsu_WH_PE(response.data.su_WH_PE);
        setsu_NT_FL(response.data.su_NT_FL);
        setsu_RT_CD(response.data.su_RT_CD);
        setsu_IT_RT(SupplierMasterData.su_IT_RT);
        setsu_LD_CR(response.data.su_LD_CR);
        setsu_FR_HC(response.data.su_FR_HC);
        setsu_TX_AP(response.data.su_TX_AP);
        setsu_TX_TY(response.data.su_TX_TY);
        setsu_TX_CD(response.data.su_TX_CD);
        setsu_TX_AR(response.data.su_TX_AR);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      su_SU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }

  setFilters(updatedFilters);
  };

  var requestBody = {};
function values () {
  requestBody = {
    suUkNo   : FormData.suUkNo,
    su_SU_NO : FormData.su_SU_NO,
    su_CR_CD : FormData.su_CR_CD,
    su_CO_NO : FormData.su_CO_NO,
    su_PT_TM : FormData.su_PT_TM,
    su_PY_IN : FormData.su_PY_IN,
    su_GL_CD : FormData.su_GL_CD,
    su_HO_PY : FormData.su_HO_PY,
    su_WH_PE : FormData.su_WH_PE,
    su_NT_FL : FormData.su_NT_FL,
    su_AP_NO : FormData.su_AP_NO,
    su_HL_CD : FormData.su_HL_CD,
    su_CR_NO : FormData.su_CR_NO,
    su_RT_CD : FormData.su_RT_CD,
    su_IT_RT : FormData.su_IT_RT,
    su_TR_DY : FormData.su_TR_DY,
    su_LD_CR : FormData.su_LD_CR,
    su_FR_HC : FormData.su_FR_HC,
    su_TX_AP : FormData.su_TX_AP,
    su_TX_TY : FormData.su_TX_TY,
    su_TX_CD : su_TX_CD,
    su_TX_RT : FormData.su_TX_RT,
    su_TX_AR : FormData.su_TX_AR,
  };
}
const handleFormSubmit = () => {
  values ();
  if (FormData.suUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/SupplierMaster/Update`;
    if (confirm) {
      axios
        .put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    FormData.su_CO_NO = company;
    axios
      .post(`${domain}/api/SupplierMaster/Add`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
};

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"  }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );

  const handleClearButton=()=>{
    setSelectedSupplier("");
    setSupplier("");
    setFilters("");
  }

  const AdvanceSearch = (
    <div className="formgrid grid">
 <div className="field col-lg-4 col-md-6 col-12 pe-md-2" >
      <label htmlFor="supplier" className="font-bold">
        Supplier <span style={{ color: 'red' }}>*</span>
      </label>
      <div style={{ display: "flex" }}>
        <InputText
        style={{width:"125px"}}
          id="supplier"
          name="supplier"
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={supplier}
        />
         {showSearchIcon && (
        <i
          className="pi pi-search"
          style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "4%",
            cursor: "pointer",
          }}
          onClick={openSupplierPopup}
        ></i>
         )}
        <div>
        <InputText
          id="ab_AD_NM"
          name="ab_AD_NM"          
          onChange={(Event) => onchangedata(Event)}
          onFocus={() => setShowSearchIcon(true)}
          value={selectedSupplier}
          style={{backgroundColor:"#ffffff"}}
        />
      </div>
      </div>
    </div>
        
</div>
);
const rightClear = (
  <React.Fragment>
  <Tooltip  target=".clear-button" position="bottom" mouseTrack>
  <div>Clear Filter</div>
  </Tooltip>
  
  <Button
    style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
    // style={{ height: "35px" }}
    icon="pi pi-times"
    className="mr-2 clear-button"
    link
    onClick={handleClearButton}
    />
  </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        </div>
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td >
                  <input disabled
                    width="100%"
                    type="text"
                    value={supplier}
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    width="100%"
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_CO_NO"] ? filters["su_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_CO_NO",
                        e.target.value,
                        filters["su_CO_NO"]
                          ? filters["su_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_CR_CD"] ? filters["su_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_CR_CD",
                        e.target.value,
                        filters["su_CR_CD"]
                          ? filters["su_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_PT_TM"] ? filters["su_PT_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_PT_TM",
                        e.target.value,
                        filters["su_PT_TM"]
                          ? filters["su_PT_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_HO_PY"] ? filters["su_HO_PY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_HO_PY",
                        e.target.value,
                        filters["su_HO_PY"]
                          ? filters["su_HO_PY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_TX_AP"] ? filters["su_TX_AP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_TX_AP",
                        e.target.value,
                        filters["su_TX_AP"]
                          ? filters["su_TX_AP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["su_TX_TY"] ? filters["su_TX_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "su_TX_TY",
                        e.target.value,
                        filters["su_TX_TY"]
                          ? filters["su_TX_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Supplier</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Payment Term</th>
                <th style={tableHeaderStyle}>Payment Hold</th>
                <th style={tableHeaderStyle}>Tax Applicable</th>
                <th style={tableHeaderStyle}>Tax Type</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.suUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.suUkNo}
                        checked={selectedRows.includes(rowData.suUkNo)}
                        onChange={() => handleRowSelect(rowData.suUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.su_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{rowData.su_CO_NO}</td>
                    <td style={tableCellStyle}>{rowData.su_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.su_PT_TM}</td>
                    <td style={tableCellStyle}>{rowData.su_HO_PY}</td>
                    <td style={tableCellStyle}>{rowData.su_TX_AP}</td>
                    <td style={tableCellStyle}>{rowData.su_TX_TY}</td>
                                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* SUPPLIER */}
      <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeSUP}
        setFormData={setFormData}
      />
      <SupplierMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        onchangeDropDownFormData15={onchangeDropDownFormData15}
        onchangeDropDownFormData16={onchangeDropDownFormData16}
        onchangeDropDownFormData17={onchangeDropDownFormData17}
        su_CO_NO={su_CO_NO}
        su_CR_CD={su_CR_CD}
        su_PT_TM={su_PT_TM}
        su_PY_IN={su_PY_IN}
        su_GL_CD={su_GL_CD}
        su_HO_PY={su_HO_PY}
        su_WH_PE={su_WH_PE}
        su_NT_FL={su_NT_FL}
        su_HL_CD={su_HL_CD}
        su_RT_CD={su_RT_CD}
        su_IT_RT={su_IT_RT}
        su_LD_CR={su_LD_CR}
        su_FR_HC={su_FR_HC}
        su_TX_AP={su_TX_AP}
        su_TX_TY={su_TX_TY}
        su_TX_CD={su_TX_CD}
        su_TX_AR={su_TX_AR}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        selectedAbAdNM2={selectedAbAdNM2}
        selectedAbAdNM1={selectedAbAdNM1}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedAbAdNM2={setSelectedAbAdNM2}
        setSelectedAbAdNM1={setSelectedAbAdNM1}
     
      />
    </div>
    </div>
  );
};

export default SupplierMasterMain;
