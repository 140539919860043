import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import OperationSequencePopup from "../Popups/OperationSequencePopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import WorkOrderEntryPopup from "../Popups/WorkOrderEntryPopup";

export default function MaterialRequisitionDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  onchangedata,
  setMaterialRequisitiontable,
  MaterialRequisitiontable,
  routingItemName,
  setroutingItemName,
  jb_JB_UM, 
  setjb_JB_UM,
  jb_CO_CT,
  setjb_CO_CT,
  jb_TR_UM,
  setjb_TR_UM,
  rtWK_dESC,
  setrtWK_dESC,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  SelectedemOperator,
  setSelectedemOperator,
  SelectedOrder,
  setSelectedOrder,
  jb_OR_CO,
  jb_JB_CO,
  jb_OR_TY,
  jb_JB_TY,
  handleResponse,

}) {
  const {
    jbUkNo,
    jb_JB_NO,
   // jb_JB_TY,
  //  jb_JB_CO,
    jb_OR_NO,
    //jb_OR_TY,
   // jb_OR_CO, 
    jb_JB_QT,
     jb_IS_DT,
    jh_IS_QT,
    jb_SU_NO,
    ep_PO_NM,
    jb_PR_NM,
    JB_IS_DT,
    jb_OP_NO,
    CancelQty,
    item_DESC,
    jb_TR_DT,
    
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton,
    tablestyle, multitablestyle, savestyle, cancelstyle, Dialogstyle }
    = ButtonStyles();

    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");


  // from main page
  const addRow = () => {
    setMaterialRequisitiontable([...MaterialRequisitiontable, {}]);
  };
  const deleteRow = () => {
    if (MaterialRequisitiontable.length > 0) {
      const updatedData = [...MaterialRequisitiontable];
      updatedData.pop();
      setMaterialRequisitiontable(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };


  const onChangeOfOrderNo = async (wd_WO_NO, wd_WO_TY, SelectedRowIndex) => {
    const Url = `${domain}/api/PartList/GetByConfirmFLag`;
    const requestData = {
      "plWoNo": wd_WO_NO,
      "pl_WO_TY": wd_WO_TY,
    };
  
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`,
          "x-flag": localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const Resultdata = response.data.Result;
  
      if (Resultdata && Array.isArray(Resultdata)) {
        // Log the lengths of arrays to make sure they match
        console.log("Resultdata length:", Resultdata.length);
        console.log("MaterialRequisitiontable length:", MaterialRequisitiontable.length);
  
        // Update the MaterialRequisitiontable state to match the Resultdata
        const updatedTable = Resultdata.map((result, index) => ({
          jb_LN_NO: result.pl_LN_NO || "",  
          jb_CO_NM: result.pl_CO_NM || "",  
          comp_desc: result.item_NAME || "", 
          jb_OR_QT: result.pl_OR_QT || "", 
          jb_TR_UM: result.pl_TR_UM || "",  
          jb_CO_CT: result.pl_CO_CT || "", 
        }));
  
        // Set the state of MaterialRequisitiontable
        setMaterialRequisitiontable(updatedTable);
  
        // After updating the state, access the DOM elements
        setTimeout(() => {
          Resultdata.forEach((result, index) => {
            console.log(`Updating row ${index}`);
  
            const coNmElement = document.getElementById(`jb_CO_NM_${index}`);
            const descElement = document.getElementById(`comp_desc_${index}`);
            const orQtElement = document.getElementById(`jb_OR_QT_${index}`);
            const lnNoElement = document.getElementById(`jb_LN_NO_${index}`);
  
            // Set values if the elements exist
            if (coNmElement) coNmElement.value = result.pl_CO_NM;
            else console.error(`Element jb_CO_NM_${index} not found`);
  
            if (descElement) descElement.value = result.item_NAME;
            else console.error(`Element comp_desc_${index} not found`);
  
            if (orQtElement) orQtElement.value = result.pl_OR_QT;
            else console.error(`Element jb_OR_QT_${index} not found`);
  
            onchangeDropDownFormData4(`jb_TR_UM_${index}`, result.pl_TR_UM);
            onchangeDropDownFormData5(`jb_CO_CT_${index}`, result.pl_CO_CT);
  
            if (lnNoElement) lnNoElement.value = result.pl_LN_NO;
            else console.error(`Element jb_LN_NO_${index} not found`);
          });
        }, 500); 
      } else {
        console.error("Invalid API response or empty array:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
    setShowSearchIcon(false);
  };
  
  
  
  
  
  
  const [AddresstypeE, setAddresstypeE] = useState("E");

  const [SalesPersonPopupVisible, setSalesPersonPopupVisible] = useState(false);
  const openSalesPersonPopup = () => {
    setSalesPersonPopupVisible(true);
  };
  const handleDataSelectE = async (data) => {
    setSelectedemEMNOE(data.ab_AD_NM);
    onchangeFormData("jb_SU_NO", data.abAdNo);
    setSalesPersonPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [OperatorPopupVisible, setOperatorPopupVisible] = useState(false);
  const openOperatorPopup = () => {
    setOperatorPopupVisible(true);
  };
  const handleDataSelectOperator = async (data) => {
    setSelectedemOperator(data.ab_AD_NM);
    onchangeFormData("jb_OP_NO", data.abAdNo);
    setOperatorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [OrderPopupVisible, setOrderPopupVisible] = useState(false);

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [RoutingItemPopupVisible, setRoutingItemPopupVisible] = useState(false);
  const openRoutingItemPopup = () => {
    setRoutingItemPopupVisible(true);
  };
  const handleDataSelectItem = (data) => {
    setroutingItemName(data.im_IT_DS);
    onchangeFormData("rt_IT_NM", data.im_IT_NM);
    setRoutingItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openOrderPopup = () => {
    setOrderPopupVisible(true);
  };
  const handleDataSelectItem1 = (Result) => { 
    document.getElementById("jb_OR_NO").value = Result.wd_WO_NO;
    document.getElementById("jb_PR_NM").value = Result.wd_IT_NM;
    document.getElementById("item_DESC").value = Result.it_DES;
    document.getElementById("jb_JB_QT").value = Result.wd_WO_QT;
   // document.getElementById("jb_JB_UM").value = Result.wd_TR_UM;
    onchangeDropDownFormData3(Result.wd_TR_UM);
    setSelectedOrder(Result.wd_WO_NO);
    document.getElementById("jb_JB_NO").value = Result.wd_WO_NO;
    setOrderPopupVisible(false);
    setShowSearchIcon(false);
    onChangeOfOrderNo(Result.wd_WO_NO,Result.wd_WO_TY ,SelectedRowIndex)
  };

  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);
  const [OperSequPopupVisible, setOperSequPopupVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const openWorkcentrePopup = (e) => {
    setWorkCentrePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const openOperationPopup = (e) => {
    setOperSequPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectWKC = (content) => {
    setrtWK_dESC(content.wc_WC_DS);
    document.getElementById(`rtWK_dESC_${SelectedRowIndex}`).value = content.wc_WC_DS;
    document.getElementById(`rt_WK_CT_${SelectedRowIndex}`).value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectOpSq = (content) => {
    document.getElementById(`rt_OP_DS_${SelectedRowIndex}`).value = content.os_SQ_DS;
    document.getElementById(`rt_OP_SQ_${SelectedRowIndex}`).value = content.os_OP_SQ;
    setOperSequPopupVisible(false);
    setShowSearchIcon(false);
  };

  // class code Dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [jb_JB_UMOptions, setjb_JB_UMOptions] = useState([]);
  const [jb_CO_CTOptions, setjb_CO_CTOptions] = useState([]);
  const [jb_TR_UMOptions, setjb_TR_UMOptions] = useState([]);

  // UM
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjb_JB_UM(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjb_CO_CT(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjb_TR_UM(value);
  };


  useEffect(() => {
    fetchDropdownDataUM();
    fetchDropdownData();
    fetchCostCentre();
  }, []);
  // COST center
  const fetchCostCentre = async () => {
    const UMOptions = await fetchCostCenter();
    setjb_CO_CTOptions(UMOptions);
  };
  // UM
  const fetchDropdownDataUM = async () => {
    const UMOptions = await fetchClassCodeCD("IN/UM");
    setjb_JB_UMOptions(UMOptions);
  };
  // UM
  const fetchDropdownData = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjb_TR_UMOptions(Options);
  };


  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jbUkNo ? "Revise Material Requisition" : "Add Material Requisition"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
              <label htmlFor="jbUkNo" className="font-bold">
                uK
              </label>
              <InputText disabled
                id="jbUkNo"
                name="jbUkNo"
                value={jbUkNo}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_NO" className="font-bold">
                Related Order
              </label>
              <InputText disabled
                id="jb_OR_NO"
                name="jb_OR_NO"
                defaultValue={jb_OR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_TY" className="font-bold">
                Related Type
              </label>
              <InputText disabled
                id="jb_OR_TY"
                name="jb_OR_TY"
                value={jb_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="jb_OR_CO"
                name="jb_OR_CO"
                value={jb_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_JB_NO" className="font-bold">
            Order No<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedOrder}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openOrderPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="jb_JB_NO"
                name="jb_JB_NO"
                defaultValue={jb_JB_NO}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_TY" className="font-bold">
                Order Type
              </label>
              <InputText disabled
                id="jb_JB_TY"
                name="jb_JB_TY"
                value={jb_JB_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_CO" className="font-bold">
                Order Company
              </label>
              <InputText disabled
                id="jb_JB_CO"
                name="jb_JB_CO"
                value={jb_JB_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_PR_NM" className="font-bold">
                Item Number
              </label>
              <InputText disabled
                id="jb_PR_NM"
                name="jb_PR_NM"
                defaultValue={jb_PR_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="item_DESC" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="item_DESC"
                name="item_DESC"
                defaultValue={item_DESC}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_JB_QT" className="font-bold">
              Order Quantity
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="jb_JB_QT"
                  name="jb_JB_QT"
                  defaultValue={jb_JB_QT}
                  onChange={(e) => onchangedata(e)}
                />
                  <Dropdown  
                 id="jb_JB_UM"
                name="jb_JB_UM"
                defaultValue={jb_JB_UM}
                options={jb_JB_UMOptions}
                onChange={(e) => onchangeDropDownFormData3("jb_JB_UM", e.value)}
                placeholder="UM"
              />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_SU_NO" className="font-bold">
                Supervisor <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="sales_PERSON"
                  name="sales_PERSON"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNOE}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openSalesPersonPopup}
                  ></i>
                )}
              </div>
              <InputText style={{ display: "none" }}
                id="jb_SU_NO"
                name="jb_SU_NO"
                value={jb_SU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jb_TR_DT" className="font-bold">
            Requested Date
            </label>
            <Calendar
              id="jb_TR_DT"
              name="jb_TR_DT"
              value={moment(data.jb_TR_DT, "YYYYMMDD").isValid() ? moment(data.jb_TR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jb_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="JB_IS_DT" className="font-bold">
                Shift
              </label>
              <InputText 
                id="JB_IS_DT"
                name="JB_IS_DT"
                value={JB_IS_DT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="jb_OP_NO" className="font-bold">
                Operator <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="sales_PERSON"
                  name="sales_PERSON"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemOperator}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openOperatorPopup}
                  ></i>
                )}
              </div>
              <InputText style={{ display: "none" }}
                id="jb_OP_NO"
                name="jb_OP_NO"
                value={jb_OP_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="CancelQty" className="font-bold">
                Cancel Qty
              </label>
              <InputText disabled
                id="CancelQty"
                name="CancelQty"
                value={CancelQty}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="MaterialRequisitiontable" style={{ overflow: "auto" }}>
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK_NO</th>
                  <th>Line No</th>
                  <th>Item Number</th>
                  <th>Description</th>
                  <th>Required Quantity</th>
                  <th>UM</th>
                  <th>Cost Centre</th>
                </tr>
              </thead>
              <tbody>
                {MaterialRequisitiontable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`jbUkNo_${index}`}
                        name={`jbUkNo_${index}`}
                        defaultValue={row.jbUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "5rem" }}
                        id={`jb_LN_NO_${index}`}
                        name={`jb_LN_NO_${index}`}
                        defaultValue={row.jb_LN_NO}
                      />
                    </td>


                    <td>
                      <InputText disabled style={{ width: "20rem" }}
                        id={`jb_CO_NM_${index}`}
                        name={`jb_CO_NM_${index}`}
                        defaultValue={row.jb_CO_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "20rem" }}
                        id={`comp_desc_${index}`}
                        name={`comp_desc_${index}`}
                        value={row.comp_desc}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled style={{ width: "8rem" }}
                        id={`jb_OR_QT_${index}`}
                        name={`jb_OR_QT_${index}`}
                        defaultValue={row.jb_OR_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>

                    <td>
                      <Dropdown
                        id={`jb_TR_UM_${index}`}
                        name={`jb_TR_UM_${index}`}
                        defaultValue={data[`jb_TR_UM_${index}`] ? data[`jb_TR_UM_${index}`] : jb_TR_UM}
                        options={jb_TR_UMOptions}
                        onChange={(e) => onchangeDropDownFormData4(`jb_TR_UM_${index}`, e.value)}
                        placeholder="UM"
                       // disabled
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`jb_CO_CT_${index}`}
                        defaultValue={`jb_CO_CT_${index}`}
                        value={data[`jb_CO_CT_${index}`] ? data[`jb_CO_CT_${index}`] : jb_CO_CT}
                        options={jb_CO_CTOptions}
                        onChange={(e) => onchangeDropDownFormData5(`jb_CO_CT_${index}`, e.value)}
                        placeholder="Cost Centre"
                        disabled
                      />
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ItemPopup
        visible={RoutingItemPopupVisible}
        onClose={() => setRoutingItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
      <WorkCentrePopup
        visible={WorkCentrePopupVisible}
        onClose={() => setWorkCentrePopupVisible(false)}
        onDataSelect={handleDataSelectWKC}
        formData={data}
      />
      <OperationSequencePopup
        visible={OperSequPopupVisible}
        onClose={() => setOperSequPopupVisible(false)}
        onDataSelect={handleDataSelectOpSq}
        formData={data}
      />
      <AddressBookPopup
        visible={SalesPersonPopupVisible}
        onClose={() => setSalesPersonPopupVisible(false)}
        onDataSelect={handleDataSelectE}
        formData={data}
        addressType={AddresstypeE}
      />
      <AddressBookPopup
        visible={OperatorPopupVisible}
        onClose={() => setOperatorPopupVisible(false)}
        onDataSelect={handleDataSelectOperator}
        formData={data}
        addressType={AddresstypeE}
      />
        <WorkOrderEntryPopup
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleDataSelectItem1}
        formData={data}
      />
    </Dialog>
  );
}
