import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";


const UploadPopup = ({
    handleUpload,
    visible,
    onClose,
    selectedFiles,
    setSelectedFiles,
}) => {

    // const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
      setSelectedFiles(event.target.files);
    };

    const onUploadClick = () => {
        handleUpload(selectedFiles); 
        onClose(); 
    };

  return (
    <Dialog style={{width:'20rem',height:'20rem'}}
        visible={visible}
        onHide={onClose}
    > 
        <div >

            <form>
                <input type="file" onChange={handleFileChange} multiple />
            </form>

            <div className="flex justify-content-between mt-8">
                <Button type="submit"
                    label="Upload" 
                    onClick={onUploadClick} 
                    className="p-button-primary"
                />
                <Button type="button"
                    label="Cancel" 
                    onClick={onClose} 
                    className="p-button-secondary"
                />
            </div>
      </div>
        

    </Dialog>
  )
}

export default UploadPopup