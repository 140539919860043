import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function CycleCountGenerationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  CycleCountGenerationTableData,
  setCycleCountGenerationTableData,
  ep_TR_CR,
  setep_TR_CR,
  ep_PO_NM,
  setep_PO_NM,
  ep_EX_CT,
  setep_EX_CT,
  ep_EX_TY,
  setep_EX_TY,
  ep_LO_CN,
  setep_LO_CN,
  selectedSupplier,
  setselectedSupplier,
  ep_CO_NO,
}) {
  const { epUkNo, ep_EF_DT } = data;
  const {savestyle,cancelstyle,Dialogstyle,borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [ep_PO_NMOptions, setep_PO_NMOptions] = useState([]);
  const [ep_TR_CROptions, setep_TR_CROptions] = useState([]);
  const [ep_EX_CTOptions, setep_EX_CTOptions] = useState([]);
  const [ep_EX_TYOptions, setep_EX_TYOptions] = useState([]);
  const [ep_LO_CNOptions, setep_LO_CNOptions] = useState([]);
// Policy Name
const GroupProfileDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setep_PO_NM(value);
};
// Curr
const CurrencyDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setep_TR_CR(value);
};
 // Expense Category
const ExpenseCategoryDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_CT(value);
};
  // Expense Type
const ExpenseTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_EX_TY(value);
};
  // Location
const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setep_LO_CN(value);
};
  useEffect(() => {
    GroupProfile();
    PolicyName();
    ExpenseCategory();
    ExpenseType();
    Location();
  }, []);
  const GroupProfile = async () => {
    const Options = await fetchClassCodeDS("EX/PO");
    setep_PO_NMOptions(Options);
  };
  const PolicyName = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setep_TR_CROptions(Options);
  };
  const ExpenseCategory = async () => {
    const Options = await fetchClassCodeDS("EX/CT");
    setep_EX_CTOptions(Options);
  };
  const ExpenseType = async () => {
    const Options = await fetchClassCodeDS("EX/TY");
    setep_EX_TYOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setep_LO_CNOptions(Options);
  };
  const addRow = () => {
    setCycleCountGenerationTableData([...CycleCountGenerationTableData, {}]);
  };
  const deleteRow = () => {
    if (CycleCountGenerationTableData.length > 0) {
      const updatedData = [...CycleCountGenerationTableData];
      updatedData.pop();
      setCycleCountGenerationTableData(updatedData);
    }
  };
  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");
  const handleDataSelect =async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById (`ep_PR_SU_${SelectedRowIndex}`).value =  data.abAdNo;
    document.getElementById (`sup_NAME_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };
const isUpdateMode = Boolean(epUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{epUkNo ? "Revise Cycle Count Generation" : "Add Cycle Count Generation"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
          <div  style={inboxstyle}>
          <div className="formgrid grid">
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ep_PO_NM" className="font-bold">
                  Policy Name
                </label>
                <Dropdown
                name="ep_PO_NM"
                value={ep_PO_NM}
                options={ep_PO_NMOptions}
                onChange={(e) => GroupProfileDropdown("ep_PO_NM", e.value)}
                placeholder="Select Policy Name"
              />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="ep_TR_CR" className="font-bold">
                  Reim Currency
                </label>
                <Dropdown
                name="ep_TR_CR"
                value={ep_TR_CR}
                options={ep_TR_CROptions}
                onChange={(e) => CurrencyDropdown("ep_TR_CR", e.value)}
                placeholder="Select Currency"
              />
              </div>
                    
            </div>
          </div>  
          <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="CycleCountGenerationtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Expense Category</th>
                <th>Expense Type</th>
                <th>Effective Date</th>
                <th>Location</th>
                <th>Daily Allowance</th>
                <th>Tolerance%</th>
                <th>Audit Amount</th>
                <th style={{ display: "none" }}>Supplier</th>
                <th>Supplier <span style={{ color: 'red', }}>*</span></th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {CycleCountGenerationTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`epUkNo_${index}`}
                      name={`epUkNo_${index}`}
                      value={row.epUkNo}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ep_EX_CT_${index}`}
                      name={`ep_EX_CT_${index}`}
                      value={ data[`ep_EX_CT_${index}`] ? data[`ep_EX_CT_${index}`]: row.ep_EX_CT}
                      options={ep_EX_CTOptions}
                      onChange={(e) =>ExpenseCategoryDropdown(`ep_EX_CT_${index}`, e.value)}
                      placeholder="Select Exp Category"
                    />
                    </td>
                    <td>
                    <Dropdown
                      id={`ep_EX_TY_${index}`}
                      name={`ep_EX_TY_${index}`}
                      value={ data[`ep_EX_TY_${index}`] ? data[`ep_EX_TY_${index}`]: row.ep_EX_TY}
                      options={ep_EX_TYOptions}
                      onChange={(e) =>ExpenseTypeDropdown(`ep_EX_TY_${index}`, e.value)}
                      placeholder="Select Exp Type"
                    />
                    </td>
                    <td>
                  <Calendar style={{width:"150px"}}
                  id={`ep_EF_DT_${index}`}
                  name={`ep_EF_DT_${index}`}
                  value={row.ep_EF_DT? moment(row.ep_EF_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "ep_EF_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                    <Dropdown
                      id={`ep_LO_CN_${index}`}
                      name={`ep_LO_CN_${index}`}
                      value={ data[`ep_LO_CN_${index}`] ? data[`ep_LO_CN_${index}`]: row.ep_LO_CN}
                      options={ep_LO_CNOptions}
                      onChange={(e) =>LocationDropdown(`ep_LO_CN_${index}`, e.value)}
                      placeholder="Select Location"
                    />
                    </td>
                    <td >
                    <InputText style={{width:"100px"}}
                      id={`ep_DA_AL_${index}`}
                      name={`ep_DA_AL_${index}`}
                      defaultValue={row.ep_DA_AL}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{width:"90px"}}
                      id={`ep_TL_PE_${index}`}
                      name={`ep_TL_PE_${index}`}
                      defaultValue={row.ep_TL_PE}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{width:"90px"}}
                      id={`ep_AU_AM_${index}`}
                      name={`ep_AU_AM_${index}`}
                      defaultValue={row.ep_AU_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td style={{ display: "none" }}> 
                    <InputText
                      style={{width:"90px"}}
                      id={`ep_PR_SU_${index}`}
                      name={`ep_PR_SU_${index}`}
                      defaultValue={row.ep_PR_SU ? row.ep_PR_SU: data[`${"ep_PR_SU"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <div style={{ display: "flex", width:"150px"}}>
                    <InputText
                      id={`sup_NAME_${index}`}
                      name={`sup_NAME_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={row.sup_NAME ? row.sup_NAME : data[`${"sup_NAME"}_${index}`]}
                    />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "7%",
                        cursor: "pointer",
                        }}
                        onClick={(e) => openSupplierPopup(index)}
                      ></i>
                      )}
                      </div>
                      </td>
                      <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`ep_CO_NO_${index}`}
                        name={`ep_CO_NO_${index}`}
                        defaultValue={ep_CO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
              </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          </div>
      </div>
      <AddressBookPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeSUP}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
