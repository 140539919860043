import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import Costcenter  from "views/common/CostCentreDrpDwn";
import { InputMask } from "primereact/inputmask";
export default function WorkOrderDownTimeDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkOrderDownTimeTableData,
  setWorkOrderDownTimeTableData,
  onchangeDropDownFormData1,
  dt_CO_CT,
  setdt_CO_CT,
  workCT_NAME,
  setworkCT_NAME,
  wo_TY_HR,
  setwo_TY_HR
}) {
  const {
    dtUkNo,
    dt_WC_CT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };

  const [dt_CO_CTOptions, setdt_CO_CTOptions] = useState([]);
  const [dt_PL_UNOptions, setdt_PL_UNOptions] = useState([]);
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  useEffect(() => {
    fetchDropdownCostCentre();
    fetchDropdownDataHrs();
  }, []);
  const fetchDropdownCostCentre = async () => {
    const Options = await fetchCostCenter();
    setdt_CO_CTOptions(Options);
  };
  const fetchDropdownDataHrs = async () => {
    const Options = await fetchClassCodeDS("HR/TY");
    setdt_PL_UNOptions(Options);
  };
  const addRow = () => {
    setWorkOrderDownTimeTableData([...WorkOrderDownTimeTableData, {}]);
  };
  const deleteRow = () => {
    if (WorkOrderDownTimeTableData.length > 0) {
      const updatedData = [...WorkOrderDownTimeTableData];
      updatedData.pop();
      setWorkOrderDownTimeTableData(updatedData);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [addressType, setaddressType] = useState("E");
  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);
  const [SupervisorPopupVisible, setSupervisorPopupVisible] = useState(false);

  const openSupervisorPopup = (e) => {
    setSupervisorPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectSupervisor = (data) => {
    // document.getElementById(`EmployeeName_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`dt_EM_NO_${SelectedRowIndex}`).value = data.abAdNo;
    setSupervisorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openWorkcentrePopup = () => {
    setWorkCentrePopupVisible(true);
  };
  const handleDataSelectWKCT =async (content) => {
    document.getElementById("workCT_NAME").value = content.wc_WC_DS;
    document.getElementById("dt_WC_CT").value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{dtUkNo ? " Revise WO Down Time " : " Add WO Down Time "}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={borderbox}>
        <div  style={inboxstyle}>
         <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="dt_CO_CT" className="font-bold">
              Cost Centre
            </label>
              <Dropdown
                id="dt_CO_CT"
                name="dt_CO_CT"
                value={dt_CO_CT}
                options={dt_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("dt_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>  
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="dt_TR_DT" className="font-bold">
              Date
              </label>
              <Calendar
                id="dt_TR_DT"
                name="dt_TR_DT"
                value={moment(data.dt_TR_DT, "YYYYMMDD").isValid() ? moment(data.dt_TR_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "dt_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="dt_WC_CT" className="font-bold">
                Work Centre <span style={{color:'red'}}>*</span>
              </label>
                <div style={{ display: 'flex' }}>
                  <InputText
                    id="workCT_NAME"
                    name="workCT_NAME"
                    onChange={(event) => onchangedata(event)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={workCT_NAME}
                  />
                  {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: 'var(--primary-color)',
                        fontSize: '119%',
                        marginTop: '4%',
                        cursor: 'pointer',
                      }}
                      onClick={openWorkcentrePopup}
                    ></i>
                  )}
                </div>
                <InputText style={{display:'none'}}
                  id="dt_WC_CT"
                  name="dt_WC_CT"
                  value={dt_WC_CT}
                  onChange={(event) => onchangedata(event)}
                />
            </div>
          </div>
        </div>  
        <div  style={tablestyle}>
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="WorkOrderDownTimetable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Shift</th>
                <th>Area</th>
                <th>Disruption Code</th>
                <th>Description</th>
                <th>Planned / UnPlanned</th>
                <th>From Date</th>
                <th>To Date </th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Hours</th>
                <th>Order No</th>
                <th>Type</th>
                <th>Company</th>
                <th>Equipment No</th>
                <th>Supervisor</th>
              </tr>
            </thead>
            <tbody>
              {WorkOrderDownTimeTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`dtUkNo_${index}`}
                      name={`dtUkNo_${index}`}
                      value={row.dtUkNo}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_SF_CD_${index}`}
                      name={`dt_SF_CD_${index}`}
                      value={row.dt_SF_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_AR_EA_${index}`}
                      name={`dt_AR_EA_${index}`}
                      value={row.dt_AR_EA}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                    <InputText style={{width:"10rem"}}   
                      id={`dt_DI_NM_${index}`}
                      name={`dt_DI_NM_${index}`}
                      value={row.dt_DI_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"10rem"}}
                      id={`dt_DI_CD_${index}`}
                      name={`dt_DI_CD_${index}`}
                      value={row.dt_DI_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_PL_UN_${index}`}
                      name={`dt_PL_UN_${index}`}
                      value={row.dt_PL_UN}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <Calendar  style={{width:"7rem"}}
                      id={`dt_FR_DT_${index}`}
                      name={`dt_FR_DT_${index}`}
                      value={ row.dt_FR_DT ? moment(row.dt_FR_DT, "YYYYMMDD").toDate() : null }
                      onChange={(e) => onDateChange({ target: { name: "dt_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td >
                    <Calendar  style={{width:"7rem"}}
                      id={`dt_TO_DT_${index}`}
                      name={`dt_TO_DT_${index}`}
                      value={ row.dt_TO_DT ? moment(row.dt_TO_DT, "YYYYMMDD").toDate() : null }
                      onChange={(e) => onDateChange({ target: { name: "dt_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                  </td>
                  <td>
                    <InputMask style={{width:"7rem"}}
                      id={`dt_ST_TM_${index}`}
                      name={`dt_ST_TM_${index}`}
                      mask="99:99:99"
                      value={moment(row.dt_ST_TM, "HHmmss").format("HH:mm:ss")}
                      onBlur={(e) => {onChangeTime("dt_ST_TM",e.target.value,index); }}
                    />
                  </td>
                  <td > 
                    <InputMask style={{width:"7rem"}}
                      id={`dt_EN_TM_${index}`}
                      name={`dt_EN_TM_${index}`}
                      mask="99:99:99"
                      value={moment(row.dt_EN_TM, "HHmmss").format("HH:mm:ss")}
                      onBlur={(e) => {onChangeTime("dt_EN_TM",e.target.value,index);}}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}} 
                      id={`dt_TI_ME_${index}`}
                      name={`dt_TI_ME_${index}`}
                      defaultValue={row.dt_TI_ME}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText style={{width:"7rem"}}
                      id={`dt_WO_NO_${index}`}
                      name={`dt_WO_NO_${index}`}
                      value={row.dt_WO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_WO_TY_${index}`}
                      name={`dt_WO_TY_${index}`}
                      value={row.dt_WO_TY}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_WO_CO_${index}`}
                      name={`dt_WO_CO_${index}`}
                      value={row.dt_WO_CO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText style={{width:"7rem"}}
                      id={`dt_EQ_NO_${index}`}
                      name={`dt_EQ_NO_${index}`}
                      value={row.dt_EQ_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <div style={{display:'flex'}}> 
                      <InputText style={{width:"7rem"}}
                        id={`dt_EM_NO_${index}`}
                        name={`dt_EM_NO_${index}`}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)} 
                        value={row.dt_EM_NO}
                      />
                      {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={() => openSupervisorPopup(index)}
                      ></i>)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
      <WorkCentrePopup
        visible={WorkCentrePopupVisible}
        onClose={() => setWorkCentrePopupVisible(false)}
        onDataSelect={handleDataSelectWKCT}
        formData={data}
      />
       <AddressBookPopup
        visible={SupervisorPopupVisible}
        onClose={() => setSupervisorPopupVisible(false)}
        onDataSelect={handleSelectSupervisor}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
