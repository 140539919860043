import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import LocationMasterPopup from "../Popups/LocationMasterPopup";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";

export default function InventoryTransferDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  InventoryTransferTableData,
  setInventoryTransferTableData,
  ic_CO_CT2,
  setic_LO_CN2,
  setic_TR_UM,
  setic_CO_CT2,
  ic_CO_CT,
  setic_CO_CT,
  setic_LT_NO,
  setic_SC_UM,
  ic_DO_CO,
  ic_DO_TY,
  setSelectedLocationMaster,
  SelectedLocationMaster,
  FromCostCentreDropdown,
  ic_CO_TY,
  LocationDropdown,
  handleLocationDropdownChange,
  ic_LO_CN,
  setic_LO_CN,
}) {
  const {
    icUkNo,
    ic_DO_NO,
    ic_TR_DT,
    ic_GL_DT,
    ic_RF_CE,
  } = data;
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ic_CO_CTOptions, setic_CO_CTOptions] = useState([]);
  const [ic_CO_CT2Options, setic_CO_CT2Options] = useState([]);
  const [ic_TR_UMOptions, setic_TR_UMOptions] = useState([]);
  const [ic_LO_CN2Options, setic_LO_CN2Options] = useState([]);
   const [ic_LO_CNOptions, setic_LO_CNOptions] = useState([]);
  const [ic_LT_NOOptions, setic_LT_NOOptions] = useState([]);
  const [ic_SC_UMOptions, setic_SC_UMOptions] = useState([]);


  const ToCostCentreDropdown = async(name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_CO_CT2(value);
    const Url = `${domain}/api/LocationMaster/GetByCostCenter`;
    const requestData={
      "lm_CO_CT": value
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
      if (dataLead && dataLead.length > 0) {
        const locationNames = dataLead.map(item => ({
          label: item.lm_LO_CN,
          value: item.lm_LO_CN
        }));
  
        TolocationDropdown(locationNames);
        
      } else {
        console.error("Invalid API response:", dataLead);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const TolocationDropdown = (name, locationNames) => {
    setic_LO_CN2(locationNames); 
     setFormData(prevFormData => ({ ...prevFormData, [name]: locationNames }));
   };
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_TR_UM(value);
  };
  const LotNoDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_LT_NO(value);
  };
  const SecUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setic_SC_UM(value);
  };
  // const LocationDropdown = (name, value) => {
  //   setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  //   setic_LO_CN(value);
  // };
  
  useEffect(() => {
    FromCostCentre();
    ToCostCentre();
    UM();
    Location();
    LotNo();
    Tolocation();
    SecUM();
  }, []);
  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setic_CO_CTOptions(Options);
  };
  const ToCostCentre = async () => {
    const Options =  await fetchCostCenter();
    setic_CO_CT2Options(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_TR_UMOptions(Options);
  };


   const urlloc = `${domain}/api/LocationMaster/DropDown`;
   const finalUrl1 = `${urlloc}`;
   const finalUrl2 = `${urlloc}`;
  const Location = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.lm_LO_CN,
        value: item.lm_LO_CN,
      }));
      setic_LO_CNOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const Tolocation = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.lm_LO_CN,
        value: item.lm_LO_CN,
      }));
      setic_LO_CN2Options(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const SecUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setic_SC_UMOptions(Options);
  };
  const LotNo = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setic_LT_NOOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [ic_IT_NM, setic_IT_NM] = useState("");
  const [itemDES, setitemDES] = useState("");
  const handleItem = async (Result) => {
    setic_IT_NM(Result.im_IT_NM);
    setitemDES(Result.im_IT_DS);
    onchangeFormData("ic_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      SecUMDropdown(`ic_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      UMDropdown(`ic_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
    await UnitCost(Result.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const UnitCost =async (im_IT_NM) => {
    const Url = `${domain}/api/ItemCost/GetUniqueCost`;
    const requestData={
      "ic_IT_NM": im_IT_NM,
      "ic_CO_CT": ic_CO_CT,
      "ic_CO_TY": ic_CO_TY,//document.getElementById (`ic_CO_TY_${SelectedRowIndex}`).value,
      "ic_LO_CN": ic_LO_CN,//document.getElementById (`ic_LO_CN_${SelectedRowIndex}`).value,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`ic_UN_CT_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };
  const Stock =async (im_IT_NM,ic_CO_CT,ic_LO_CN) => {
    const Url = `${domain}/api/ItemCost/GetUniqueCost`;
    const requestData={
      "is_IT_NM": im_IT_NM,
      "is_CO_CT": ic_CO_CT,
      "is_LO_CN": ic_LO_CN,
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`ic_UN_CT_${SelectedRowIndex}`).value =  dataItMt.ic_CO_ST;
 
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
   
  };

  const handleCalculation = (index) => {
    const cost = parseFloat(document.getElementById(`ic_UN_CT_${SelectedRowIndex}`).value);
    const quantity1 = parseFloat(document.getElementById(`ic_TR_QT_${SelectedRowIndex}`).value);
    if (!isNaN(cost) && !isNaN(quantity1)) {
      const recAmount = (cost * quantity1).toFixed(2);
      console.log(recAmount);
      document.getElementById(`ic_EX_CT_${SelectedRowIndex}`).value = recAmount;
    } else {
      console.log("Invalid input values for cost or quantity");
    }
  };
  
  // Advance Search
  const [LocationMasterPopupVisible, setLocationMasterPopupVisible] = useState(false);
  const openLocationMasterPopup = (e) => {
    setLocationMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };

  const [locType, setlocType] = useState();
  const handleLocationMaster = async (data) => {
    setSelectedLocationMaster(data.lm_LO_CN);
    // setlocType("ic_CO_CT", data.lm_CO_CT);
    onchangeFormData("ic_LO_CN", data.lm_LO_CN, SelectedRowIndex);
    onchangeFormData("name", data.lm_LO_CN, SelectedRowIndex);
    setLocationMasterPopupVisible(false);
  };
  const addRow = () => {
    setInventoryTransferTableData([...InventoryTransferTableData, {}]);
  };
  const deleteRow = () => {
    if (InventoryTransferTableData.length > 0) {
      const updatedData = [...InventoryTransferTableData];
      updatedData.pop();
      setInventoryTransferTableData(updatedData);
    }
  };  
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "ic_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "ic_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const isUpdateMode = Boolean(icUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{icUkNo ? "Revise Inventory Transfer" : "Add Inventory Transfer"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ic_DO_NO" className="font-bold">
                Transaction No
                </label>
                <InputText disabled
                  id="ic_DO_NO"
                  name="ic_DO_NO"
                  defaultValue={ic_DO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ic_DO_TY" className="font-bold">
                Type 
                </label>
                <InputText disabled
                  id="ic_DO_TY"
                  name="ic_DO_TY"
                  value={ic_DO_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ic_DO_CO" className="font-bold">
                Company
                </label>
                <InputText disabled
                  id="ic_DO_CO"
                  name="ic_DO_CO"
                  value={ic_DO_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ic_TR_DT" className="font-bold">
                Transfer Date
                </label>
                    <Calendar
                    id="ic_TR_DT"
                    name="ic_TR_DT"
                    value={moment(ic_TR_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateTRChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ic_GL_DT" className="font-bold">
                GL Date
                  </label>
                  <Calendar
                    id="ic_GL_DT"
                    name="ic_GL_DT"
                    value={moment(ic_GL_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateGLChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="auto_POST" className="font-bold">
                  Auto Post
                  </label>
                    <InputText disabled
                      id="auto"
                      name="auto"
                      value={"Yes"}
                    />
                </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ic_CO_CT" className="font-bold">
                    From Cost Centre
                    </label>
                    <Dropdown
                      id="ic_CO_CT"
                      name="ic_CO_CT"
                      value={ic_CO_CT}
                      options={ic_CO_CTOptions}
                      onChange={(e) => FromCostCentreDropdown("ic_CO_CT", e.value)}
                      placeholder="Select Cost Centre"
                    />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="ic_CO_CT2" className="font-bold">
                      To Cost Centre
                      </label>
                      <Dropdown
                        id="ic_CO_CT2"
                        name="ic_CO_CT2"
                        value={ic_CO_CT2}
                        options={ic_CO_CT2Options}
                        onChange={(e) => ToCostCentreDropdown("ic_CO_CT2", e.value)}
                        placeholder="Select Cost Centre"
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="ic_RF_CE" className="font-bold">
                      Reference
                      </label>
                      <InputText
                        id="ic_RF_CE"
                        name="ic_RF_CE"
                        value={ic_RF_CE}
                        onChange={(e) => onchangedata(e)}
                      />
                </div> 
              </div>
          </div>
      </div>  
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item <span style={{ color: 'red' }}>*</span> </th>
              <th>Description</th>
              <th>UM</th>
              <th>From Location</th>
              <th>Lot No</th>
              <th>Stock</th>
              <th>Quantity</th>
              <th>Unit Cost</th>
              <th>Amount</th>
              <th>To Location</th>
              <th>Sec Quanity</th>
              <th>Sec UM </th>
              <th style={{ display: "none" }}>Type</th>
            </tr>
          </thead>
          <tbody>
            {InventoryTransferTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`icUkNo_${index}`}
                    name={`icUkNo_${index}`}
                    value={row.icUkNo}
                  />
                </td>
                <td >
                  <div style={{ display: "flex" }}> 
                  <InputText
                    style={{ width: "10rem" }}
                    id={`ic_IT_NM_${index}`}
                    name={`ic_IT_NM_${index}`}
                    onChange={(e) => onchangeFormData(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={row.ic_IT_NM ? row.ic_IT_NM: row[`${"ic_IT_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "15%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openItemPopup(index)}
                  ></i>
                  )}
                </div>
              </td>
                <td>
                <InputText disabled
                    style={{ width: "15rem" }}
                    id={`itemDES_${index}`}
                    name={`itemDES_${index}`}
                    defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                />
                </td>
                <td>    
                <Dropdown style={{width:'7rem'}}
                  id={`ic_TR_UM_${index}`}
                  name={`ic_TR_UM_${index}`}
                  value={ data[`ic_TR_UM_${index}`] ? data[`ic_TR_UM_${index}`]: row.ic_TR_UM}
                  options={ic_TR_UMOptions}
                  onChange={(e) =>UMDropdown(`ic_TR_UM_${index}`, e.value)}
                  placeholder="Select UM"
                />
                </td>
                <td>
                <Dropdown style={{width:"8rem"}}
                  id={`ic_LO_CN_${index}`}
                  name={`ic_LO_CN_${index}`}
                  value={data[`ic_LO_CN_${index}`] ? data[`ic_LO_CN_${index}`]:row.ic_LO_CN }
                  options={ic_LO_CNOptions}
                  onChange={(e) => LocationDropdown(`ic_LO_CN_${index}`, e.value)}
                  placeholder="Select Location"
                />
                </td>
                <td>
                  <Dropdown style={{width:"10rem"}}
                    id={`ic_LT_NO_${index}`}
                    name={`ic_LT_NO_${index}`}
                    value={ data[`ic_LT_NO_${index}`] ? data[`ic_LT_NO_${index}`]: row.ic_LT_NO}
                    options={ic_LT_NOOptions}
                    onChange={(e) =>LotNoDropdown(`ic_LT_NO_${index}`, e.value)}
                    placeholder="Select Lot No"
                  />
                  </td>
                
                  <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`ic_AV_QT_${index}`}
                    name={`ic_AV_QT_${index}`}
                    defaultValue={row.ic_AV_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"10rem"}}
                    id={`ic_TR_QT_${index}`}
                    name={`ic_TR_QT_${index}`}
                    defaultValue={row.ic_TR_QT}
                    //onChange={(e) => onchangedata(e)}
                    onChange={(e) => {
                      onchangedata(e);
                      handleCalculation(index); 
                    }}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`ic_UN_CT_${index}`}
                    name={`ic_UN_CT_${index}`}
                    defaultValue={row.ic_UN_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"10rem"}}
                    id={`ic_EX_CT_${index}`}
                    name={`ic_EX_CT_${index}`}
                    defaultValue={row.ic_EX_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown style={{width:"10rem"}}
                    id={`ic_LO_CN2_${index}`}
                    name={`ic_LO_CN2_${index}`}
                    value={ data[`ic_LO_CN2_${index}`] ? data[`ic_LO_CN2_${index}`]: row.ic_LO_CN2}
                    options={ic_LO_CN2Options}
                    onChange={(e) =>TolocationDropdown(`ic_LO_CN2_${index}`, e.value)}
                    placeholder="Select To location"
                  />
                  </td>
                  <td >
                  <InputText style={{width:"10rem"}}
                    id={`ic_SC_QT_${index}`}
                    name={`ic_SC_QT_${index}`}
                    defaultValue={row.ic_SC_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                  <td>
                  <Dropdown style={{width:"7rem"}}
                    id={`ic_SC_UM_${index}`}
                    name={`ic_SC_UM_${index}`}
                    value={ data[`ic_SC_UM_${index}`] ? data[`ic_SC_UM_${index}`]: row.ic_SC_UM}
                    options={ic_SC_UMOptions}
                    onChange={(e) =>SecUMDropdown(`ic_SC_UM_${index}`, e.value)}
                    placeholder="Select SecUM"
                  />
                  </td>
                  <td style={{display:"none"}} >
                  <InputText  disabled style={{width:"7rem" }}
                    id={`ic_CO_TY_${index}`}
                    name={`ic_CO_TY_${index}`}
                    defaultValue={ic_CO_TY}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
        <LocationMasterPopup
        visible={LocationMasterPopupVisible}
        onClose={() => setLocationMasterPopupVisible(false)}
        onDataSelect={handleLocationMaster}
        formData={data}
        locType="S100"
      />
    </Dialog>
  );
}
