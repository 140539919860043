import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import '../../../components/ListStyle/ListStyle.css';
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function AddressDialog({
  open,
  setOpen,
  data,
  initialValue,
  setab_AD_CT,
  setab_CL_11,
  setab_CL_12,
  setab_CL_07,
  setab_CL_13,
  setab_AD_CY,
  setab_AD_TY,
  setab_AC_IN,
  setAbAdNo,
  setab_AD_NM,
  FormData,
  setFormData,
  ab_CL_11,
  ab_CL_12,
  ab_CL_07,
  ab_CL_13,
  ab_AD_CT,
  ab_AD_CY,
  ab_AD_TY,
  ab_AC_IN,
  abAdNo,
  ab_AD_NM,
  handleSearch,
  handleDefault,
  nextNumType,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  ab_CO_CT,
  setab_CO_CT,
  ab_AP_AR,
  setab_AP_AR,
  ab_AD_ST,
  setab_AD_ST,
  ab_CL_01,
  setab_CL_01,
  ab_CL_02,
  setab_CL_02,
  ab_CL_03,
  setab_CL_03,
  ab_CL_04,
  setab_CL_04,
  ab_CL_05,
  setab_CL_05,
  ab_CL_06,
  setab_CL_06,
  ab_CL_08,
  setab_CL_08,
  ab_CL_09,
  setab_CL_09,
  ab_CL_10,
  setab_CL_10,
  ab_CL_14,
  setab_CL_14,
  ab_CL_15,
  setab_CL_15,
  ab_CL_16,
  setab_CL_16,
  ab_CL_17,
  setab_CL_17,
  ab_CL_18,
  setab_CL_18,
  ab_CL_19,
  setab_CL_19,
  ab_CL_20,
  setab_CL_20,
  SelectedParent,
  setSelectedParent,
}) {
  const {borderbox,inboxstyle,Dialogstyle,savestyle,cancelstyle} = ButtonStyles();
  // advance searchs
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);
  const [addressTypePrnt, setaddressTypePrnt] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const openParentPopup = () => {
    setParentPopupVisible(true);
  };
  const handleDataSelectParent = (data) => {
    setSelectedParent(data.ab_AD_NM);
    document.getElementById("ab_PT_NO").value = data.abAdNo;
    setParentPopupVisible(false);
    setShowSearchIcon(false);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);
  const handleClose = () => {
    setFormData(initialValue);
    setAbAdNo(null);
    setab_AD_NM(null);
    setOpen(false);
  };
  const dataObject = data || null;
  const {
    ab_UK_NO,
    ab_AL_NO,
    ab_TX_ID,
    ab_AD_L1,
    ab_AD_L2,
    ab_AD_L3,
    ab_AD_L4,
    ab_WB_SI,
    ab_AD_PC,
    ab_AD_EM,
    ab_AD_P1,
    ab_AD_P2,
    ab_AD_FX,
    ab_PT_NO,
  } = dataObject || {};
  var requestBodyInput = {};
  function values() {
    requestBodyInput = {
      ab_UK_NO: document.getElementById("ab_UK_NO").value,
      abAdNo: FormData.abAdNo,
      ab_AD_NM: document.getElementById("ab_AD_NM").value,
      ab_AD_L1: document.getElementById("ab_AD_L1").value,
      ab_AD_L2: document.getElementById("ab_AD_L2").value,
      ab_AD_P1: document.getElementById("ab_AD_P1").value,
      ab_AD_EM: document.getElementById("ab_AD_EM").value,
      ab_WB_SI: document.getElementById("ab_WB_SI").value,
      ab_AD_PC: document.getElementById("ab_AD_PC").value,
      ab_AD_CT: ab_AD_CT,
      ab_AD_CY: ab_AD_CY,
      ab_AD_TY: ab_AD_TY,
      ab_AC_IN: ab_AC_IN,
      ab_CL_11: ab_CL_11,
      ab_CL_12: ab_CL_12,
      ab_CL_13: ab_CL_13,
      ab_CL_07: ab_CL_07,
      ab_CO_NO: company,
      ab_CL_01: ab_CL_01,
      ab_CL_02: ab_CL_02,
      ab_CL_03: ab_CL_03,
      ab_CL_04: ab_CL_04,
      ab_CL_05: ab_CL_05,
      ab_CL_06: ab_CL_06,
      ab_CL_08: ab_CL_08,
      ab_CL_09: ab_CL_09,
      ab_CL_10: ab_CL_10,
      ab_CL_14: ab_CL_14,
      ab_CL_15: ab_CL_15,
      ab_CL_16: ab_CL_16,
      ab_CL_17: ab_CL_17,
      ab_CL_18: ab_CL_18,
      ab_CL_19: ab_CL_19,
      ab_CL_20: ab_CL_20,
      ab_CO_CT: ab_CO_CT,
      ab_AP_AR: ab_AP_AR,
      ab_AD_ST: ab_AD_ST,
      ab_AD_FX: document.getElementById("ab_AD_FX").value,
      ab_AL_NO: document.getElementById("ab_AL_NO").value,
      ab_TX_ID: document.getElementById("ab_TX_ID").value,
      ab_AD_L3: document.getElementById("ab_AD_L3").value,
      ab_AD_L4: document.getElementById("ab_AD_L4").value,
      ab_AD_P2: document.getElementById("ab_AD_P2").value,
      ab_PT_NO: document.getElementById("ab_PT_NO").value,
    };
  };
  const handleFormSubmitAdress = () => {
    values();
    if (document.getElementById("abAdNo").value) {
      if (document.getElementById("ab_UK_NO").value) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/AddressBook/Update`;
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/AddressBook/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    } else {
      const urlNextNumber = `${domain}/api/BaseNumber/All`;
      const parameters = `${nextNumType}/${company}`;
      const finallink = `${urlNextNumber}/${parameters}`;
      axios.get(finallink, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          onchangeFormData("abAdNo", resp.data.bn_BA_NO);
          setAbAdNo(resp.data.bn_BA_NO);
          setFormData({ ...FormData, ["abAdNo"]: resp.data.bn_BA_NO });
          document.getElementById("abAdNo").value = resp.data.bn_BA_NO;
          if(resp.data.bn_BA_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/AddressBook/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };
  // const urlNextNumber = `${domain}/api/BaseNumber/All`;
  // const parameters = `${nextNumType}/${company}`;
  // const finalLink = `${urlNextNumber}/${parameters}`;
  // const handleFormSubmitAdress = () => {
  //   const adNO = document.getElementById("abAdNo");
  //   if (adNO.value) {
  //     const ukNO = document.getElementById("ab_UK_NO");
  //     if (ukNO.value) {
  //       setFormData({ ...FormData, ["ab_UK_NO"]: ukNO.value});
  //       setFormData({ ...FormData, ["abAdNo"]: adNO.value});
  //       const confirm = window.confirm("Do you want to Update ?");
  //       const update = `${domain}/api/AddressBook/Update`;
  //       if (confirm) {
  //         axios.put(update, FormData, {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               "Content-Type": "application/json",
  //               "Access-Control-Allow-Origin": "http://localhost:3000",
  //               "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //             },
  //           })
  //           .then((resp) => {
  //             handleResponse(resp.data);
  //           })
  //           .catch((error) => {
  //             console.error("Error updating data:", error);
  //           });
  //       }
  //     } else {
  //       FormData.ab_CO_NO = company;
  //       axios.post(`${domain}/api/AddressBook/Add`, FormData, {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //             "Access-Control-Allow-Origin": "http://localhost:3000",
  //             "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //           },
  //         })
  //         .then((resp) => {
  //           handleResponse(resp.data);
  //         })
  //         .catch((error) => {
  //           console.error("Error adding data:", error);
  //         });
  //     }
  //   } else {
  //     axios
  //       .get(finalLink, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //         },
  //       })
  //       .then((resp) => {
  //         onchangeFormData("abAdNo", resp.bn_BA_NO);
  //         setAbAdNo(resp.bn_BA_NO);
  //         setFormData({ ...FormData, ["abAdNo"]: resp.bn_BA_NO });
  //         const ab_NO = document.getElementById("abAdNo");
  //         ab_NO.value = resp.data.bn_BA_NO;
  //         if (resp.data.bn_BA_NO === undefined) {
  //           handleResponse(resp.data);
  //         } else {
  //           saveApi();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   }
  // };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    if (name === "ab_AD_NM") {
      setab_AD_NM(value);
    }    
  setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [ab_AD_CTOptions, setab_AD_CTOptions] = useState([]);
  const [ab_AD_CYOptions, setab_AD_CYOptions] = useState([]);
  const [ab_CL_12Options, setab_CL_12Options] = useState([]);
  const [ab_CL_11Options, setab_CL_11Options] = useState([]);
  const [ab_CL_13Options, setab_CL_13Options] = useState([]);
  const [ab_CL_07Options, setab_CL_07Options] = useState([]);
  const [ab_AD_TYOptions, setab_AD_TYOptions] = useState([]);
  const [ab_AC_INOptions, setab_AC_INOptions] = useState([]);
  const [ab_CO_CTOptions, setab_CO_CTOptions] = useState([]);
  const [ab_AP_AROptions, setab_AP_AROptions] = useState([]);
  const [ab_AD_STOptions, setab_AD_STOptions] = useState([]);
  const [ab_CL_01Options, setab_CL_01Options] = useState([]);
  const [ab_CL_02Options, setab_CL_02Options] = useState([]);
  const [ab_CL_03Options, setab_CL_03Options] = useState([]);
  const [ab_CL_04Options, setab_CL_04Options] = useState([]);
  const [ab_CL_05Options, setab_CL_05Options] = useState([]);
  const [ab_CL_06Options, setab_CL_06Options] = useState([]);
  const [ab_CL_08Options, setab_CL_08Options] = useState([]);
  const [ab_CL_09Options, setab_CL_09Options] = useState([]);
  const [ab_CL_10Options, setab_CL_10Options] = useState([]);
  const [ab_CL_14Options, setab_CL_14Options] = useState([]);
  const [ab_CL_15Options, setab_CL_15Options] = useState([]);
  const [ab_CL_16Options, setab_CL_16Options] = useState([]);
  const [ab_CL_17Options, setab_CL_17Options] = useState([]);
  const [ab_CL_18Options, setab_CL_18Options] = useState([]);
  const [ab_CL_19Options, setab_CL_19Options] = useState([]);
  const [ab_CL_20Options, setab_CL_20Options] = useState([]);

  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AD_CT(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AD_CY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_12(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_11(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_13(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_07(value);
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CO_CT(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AP_AR(value);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_AD_ST(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_01(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_02(value);
  };
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_03(value);
  };
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_04(value);
  };
  const onchangeDropDownFormData17 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_05(value);
  };
  // sales group
  const onchangeDropDownFormData18 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_06(value);
  };
  // manager
  const onchangeDropDownFormData19 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_08(value);
  };
  const onchangeDropDownFormData20 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_09(value);
  };
  const onchangeDropDownFormData21 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_10(value);
  };
  const onchangeDropDownFormData22 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_14(value);
  };
  const onchangeDropDownFormData23 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_15(value);
  };
  const onchangeDropDownFormData24 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_16(value);
  };
  const onchangeDropDownFormData25 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_17(value);
  };
  const onchangeDropDownFormData26 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_18(value);
  };
  const onchangeDropDownFormData27 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_19(value);
  };
  const onchangeDropDownFormData28 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setab_CL_20(value);
  };
  useEffect(() => {
    fetchDropdownCostCenter();
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownPAYRE();
    fetchDropdownState();
    fetchDropdownClassCode1();
    fetchDropdownClassCode2();
    fetchDropdownClassCode3();
    fetchDropdownClassCode4();
    fetchDropdownClassCode5();
    fetchDropdownClassCode6();
    fetchDropdownManager();
    fetchDropdownSalesTeam();
    fetchDropdownClassCode9();
    fetchDropdownClassCode14();
    fetchDropdownClassCode15();
    fetchDropdownClassCode16();
    fetchDropdownClassCode17();
    fetchDropdownClassCode18();
    fetchDropdownClassCode19();
    fetchDropdownClassCode20();
  }, []);
  const fetchDropdownCostCenter = async () => {
    const Options = await fetchCostCenter();
    setab_CO_CTOptions(Options);
  };
  const fetchDropdownOptions = async () => {
    const Options = await fetchClassCodeDS("FO/CI");
    setab_AD_CTOptions(Options);
  };
  const fetchDropdownOptions1 = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setab_AD_CYOptions(Options);
  };
  const fetchDropdownOptions2 = async () => {
    const Options = await fetchClassCodeDS("AB/12");
    setab_CL_12Options(Options);
  };
  const fetchDropdownOptions3 = async () => {
    const Options = await fetchClassCodeDS("AB/11");
    setab_CL_11Options(Options);
  };
  const fetchDropdownOptions4 = async () => {
    const Options = await fetchClassCodeDS("AB/13");
    setab_CL_13Options(Options);
  };
  const fetchDropdownOptions5 = async () => {
    const Options = await fetchClassCodeDS("AB/7");
    setab_CL_07Options(Options);
  };
  const fetchDropdownOptions6 = async () => {
    const Options = await fetchClassCodeDS("FO/AT");
    setab_AD_TYOptions(Options);
  };
  const fetchDropdownOptions7 = async () => {
    const Options = await fetchClassCodeDS("AB/ST");
    setab_AC_INOptions(Options);
  };
  const fetchDropdownPAYRE= async () => {
    const Options = await fetchClassCodeDS("AB/ST"); //PARAMETER NOT THERE
    setab_AP_AROptions(Options);
  };
  const fetchDropdownState= async () => {
    const Options = await fetchClassCodeDS("AD/ST");
    setab_AD_STOptions(Options);
  };
  const fetchDropdownClassCode1 = async () => {
    const Options = await fetchClassCodeDS("AB/1");
    setab_CL_01Options(Options);
  };
  const fetchDropdownClassCode2 = async () => {
    const Options = await fetchClassCodeDS("AB/2");
    setab_CL_02Options(Options);
  };
  const fetchDropdownClassCode3 = async () => {
    const Options = await fetchClassCodeDS("AB/3");
    setab_CL_03Options(Options);
  };
  const fetchDropdownClassCode4 = async () => {
    const Options = await fetchClassCodeDS("AB/4");
    setab_CL_04Options(Options);
  };
  const fetchDropdownClassCode5 = async () => {
    const Options = await fetchClassCodeDS("AB/5");
    setab_CL_05Options(Options);
  };
  const fetchDropdownClassCode6 = async () => {
    const Options = await fetchClassCodeDS("AB/6");
    setab_CL_06Options(Options);
  };
  const fetchDropdownManager = async () => {
    const Options = await fetchClassCodeDS("AB/8");
    setab_CL_08Options(Options);
  };
  const fetchDropdownSalesTeam = async () => {
    const Options = await fetchClassCodeDS("AB/10");
    setab_CL_10Options(Options);
  };
  const fetchDropdownClassCode9 = async () => {
    const Options = await fetchClassCodeDS("AB/9");
    setab_CL_09Options(Options);
  };
  const fetchDropdownClassCode14 = async () => {
    const Options = await fetchClassCodeDS("AB/14");
    setab_CL_14Options(Options);
  };
  const fetchDropdownClassCode15 = async () => {
    const Options = await fetchClassCodeDS("AB/15");
    setab_CL_15Options(Options);
  };
  const fetchDropdownClassCode16 = async () => {
    const Options = await fetchClassCodeDS("AB/16");
    setab_CL_16Options(Options);
  };
  const fetchDropdownClassCode17 = async () => {
    const Options = await fetchClassCodeDS("AB/17");
    setab_CL_17Options(Options);
  };
  const fetchDropdownClassCode18 = async () => {
    const Options = await fetchClassCodeDS("AB/18");
    setab_CL_18Options(Options);
  };
  const fetchDropdownClassCode19 = async () => {
    const Options = await fetchClassCodeDS("AB/19");
    setab_CL_19Options(Options);
  };
  const fetchDropdownClassCode20 = async () => {
    const Options = await fetchClassCodeDS("AB/20");
    setab_CL_20Options(Options);
  };

  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(ab_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ab_UK_NO ? "Revise Adress Book" : "Add Adress Book"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmitAdress} 
           tooltip="Save"
        />
      )}
         {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
         )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div style={inboxstyle} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)} unmountOnExit={false}>
          <TabPanel header="Address">
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="ab_UK_NO" className="font-bold">
                  UK_NO
                </label>
                <InputText disabled
                  id="ab_UK_NO"
                  name="ab_UK_NO"
                  defaultValue={ab_UK_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                <label htmlFor="abAdNo" className="font-bold">
                  Address No
                </label>
                <InputText disabled
                  id="abAdNo"
                  name="abAdNo"
                  defaultValue={abAdNo}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                <label htmlFor="ab_AD_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="ab_AD_TY"
                  name="ab_AD_TY"
                  defaultValue={ab_AD_TY}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AD_NM" className="font-bold">
                  Name
                </label>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  defaultValue={ab_AD_NM}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AL_NO" className="font-bold">
                  Alternate No
                </label>
                <InputText
                  id="ab_AL_NO"
                  name="ab_AL_NO"
                  defaultValue={ab_AL_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CO_CT" className="font-bold">
                  Cost Centre
                </label>
                <Dropdown
                  id="ab_CO_CT"
                  name="ab_CO_CT"
                  value={ab_CO_CT}
                  options={ab_CO_CTOptions}
                  onChange={(e) => onchangeDropDownFormData10("ab_CO_CT", e.value)}
                  placeholder="Select Cost Centre"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_TX_ID" className="font-bold">
                 Tax ID
                </label>
                <InputText
                  id="ab_TX_ID"
                  name="ab_TX_ID"
                  value={ab_TX_ID}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AC_IN" className="font-bold">
                  Status
                </label>
                <Dropdown
                  id="ab_AC_IN"
                  name="ab_AC_IN"
                  value={ab_AC_IN}
                  options={ab_AC_INOptions}
                  onChange={(e) => onchangeDropDownFormData7("ab_AC_IN", e.value)}
                  placeholder="Select Status"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AP_AR" className="font-bold">
                  Payable/Receivable
                </label>
                <Dropdown
                  id="ab_AP_AR"
                  name="ab_AP_AR"
                  value={ab_AP_AR}
                  options={ab_AP_AROptions}
                  onChange={(e) => onchangeDropDownFormData11("ab_AP_AR", e.value)}
                  placeholder="Payable/Receivable"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_PT_NO" className="font-bold">
                    Parent Add No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="SelectedParent"
                      name="SelectedParent"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedParent}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openParentPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ab_PT_NO"
                      name="ab_PT_NO"
                      defaultValue={ab_PT_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                {/* <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AD_PA" className="font-bold">
                  Payor <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                      id="SelectedPayor"
                      name="SelectedPayor"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedPayor}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openCustomerPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ab_AD_PA"
                      name="ab_AD_PA"
                      value={ab_AD_PA}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div> */}
            </div>
            </div>
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_L1" className="font-bold">
                    Address Line 1
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_L1"
                      name="ab_AD_L1"
                      value={ab_AD_L1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="title" className="font-bold">
                    Address Line 2
                  </label>
                  <InputText
                    id="ab_AD_L2"
                    name="ab_AD_L2"
                    value={ab_AD_L2}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_L3" className="font-bold">
                    Address Line 3
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_L3"
                      name="ab_AD_L3"
                      value={ab_AD_L3}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_L4" className="font-bold">
                    Address Line 4
                  </label>
                  <InputText
                    id="ab_AD_L4"
                    name="ab_AD_L4"
                    value={ab_AD_L4}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_CT" className="font-bold">
                    City
                  </label>
                  <Dropdown
                    id="ab_AD_CT"
                    name="ab_AD_CT"
                    value={ab_AD_CT || null}
                    options={ab_AD_CTOptions}
                    onChange={(e) => onchangeDropDownFormData("ab_AD_CT", e.value)}
                    placeholder="Select City"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_ST" className="font-bold">
                    State
                  </label>
                  <Dropdown
                    id="ab_AD_ST"
                    name="ab_AD_ST"
                    value={ab_AD_ST || null}
                    options={ab_AD_STOptions}
                    onChange={(e) => onchangeDropDownFormData12("ab_AD_ST", e.value)}
                    placeholder="Select State"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_CY" className="font-bold">
                    Country
                  </label>
                  <Dropdown
                    id="ab_AD_CY"
                    name="ab_AD_CY"
                    value={ab_AD_CY || null}
                    options={ab_AD_CYOptions}
                    onChange={(e) => onchangeDropDownFormData1("ab_AD_CY", e.value)}
                    placeholder="Select Country"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_PC" className="font-bold">
                    Postal Code
                  </label>
                  <InputText
                    id="ab_AD_PC"
                    name="ab_AD_PC"
                    value={ab_AD_PC}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_P1" className="font-bold">
                    Phone 1
                  </label>
                    <InputText
                      id="ab_AD_P1"
                      name="ab_AD_P1"
                      defaultValue={ab_AD_P1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_P2" className="font-bold">
                    Phone 2
                  </label>
                    <InputText
                      id="ab_AD_P2"
                      name="ab_AD_P2"
                      defaultValue={ab_AD_P2}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_EM" className="font-bold">
                    Email
                  </label>
                  <InputText
                    id="ab_AD_EM"
                    name="ab_AD_EM"
                    defaultValue={ab_AD_EM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_WB_SI" className="font-bold">
                    Website
                  </label>
                  <InputText
                    id="ab_WB_SI"
                    name="ab_WB_SI"
                    value={ab_WB_SI}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_AD_TY" className="font-bold">
                    Type
                  </label>
                  <Dropdown disabled
                    id="ab_AD_TY"
                    name="ab_AD_TY"
                    value={ab_AD_TY || null}
                    options={ab_AD_TYOptions}
                    onChange={(e) => onchangeDropDownFormData6("ab_AD_TY", e.value)}
                    placeholder="Select Type"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_AD_FX" className="font-bold">
                  Fax
                </label>
                <InputText
                  id="ab_AD_FX"
                  name="ab_AD_FX"
                  value={ab_AD_FX}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Classification">
          <div style={inboxstyle} >
            <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_01" className="font-bold">
                  Class Code 1
                </label>
                <Dropdown 
                  id="ab_CL_01"
                  name="ab_CL_01"
                  value={ab_CL_01}
                  options={ab_CL_01Options}
                  onChange={(e) => onchangeDropDownFormData13("ab_CL_01", e.value)}
                  placeholder="Select ClassCode1"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_02" className="font-bold">
                  Class Code 2
                </label>
                <Dropdown 
                  id="ab_CL_02"
                  name="ab_CL_02"
                  value={ab_CL_02}
                  options={ab_CL_02Options}
                  onChange={(e) => onchangeDropDownFormData14("ab_CL_02", e.value)}
                  placeholder="Select ClassCode2"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_03" className="font-bold">
                  Class Code 3
                </label>
                <Dropdown 
                  id="ab_CL_03"
                  name="ab_CL_03"
                  value={ab_CL_03}
                  options={ab_CL_03Options}
                  onChange={(e) => onchangeDropDownFormData15("ab_CL_03", e.value)}
                  placeholder="Select ClassCode3"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_04" className="font-bold">
                  Class Code 4
                </label>
                <Dropdown 
                  id="ab_CL_04"
                  name="ab_CL_04"
                  value={ab_CL_04}
                  options={ab_CL_04Options}
                  onChange={(e) => onchangeDropDownFormData16("ab_CL_04", e.value)}
                  placeholder="Select ClassCode4"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_05" className="font-bold">
                  Class Code 5
                </label>
                <Dropdown 
                  id="ab_CL_05"
                  name="ab_CL_05"
                  value={ab_CL_05}
                  options={ab_CL_05Options}
                  onChange={(e) => onchangeDropDownFormData17("ab_CL_01", e.value)}
                  placeholder="Select ClassCode1"
                />
              </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_06" className="font-bold">
                    Sales Group
                  </label>
                  <Dropdown
                    id="ab_CL_06"
                    name="ab_CL_06"
                    value={ab_CL_06}
                    options={ab_CL_06Options}
                    onChange={(e) => onchangeDropDownFormData18("ab_CL_06", e.value)}
                    placeholder="Select Sales Group"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_07" className="font-bold">
                    Sales Person
                  </label>
                  <Dropdown
                    id="ab_CL_07"
                    name="ab_CL_07"
                    value={ab_CL_07 || null}
                    options={ab_CL_07Options}
                    onChange={(e) => onchangeDropDownFormData5("ab_CL_07", e.value)}
                    placeholder="Select Sales Person"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_08" className="font-bold">
                    Manager
                  </label>
                  <Dropdown
                    id="ab_CL_08"
                    name="ab_CL_08"
                    value={ab_CL_08}
                    options={ab_CL_08Options}
                    onChange={(e) => onchangeDropDownFormData19("ab_CL_08", e.value)}
                    placeholder="Select Manager"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_09" className="font-bold">
                    Class Code 9
                  </label>
                  <Dropdown
                    id="ab_CL_09"
                    name="ab_CL_09"
                    value={ab_CL_09}
                    options={ab_CL_09Options}
                    onChange={(e) => onchangeDropDownFormData20("ab_CL_09", e.value)}
                    placeholder="Select Classcode9"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_10" className="font-bold">
                    Sales Team
                  </label>
                  <Dropdown
                    id="ab_CL_10"
                    name="ab_CL_10"
                    value={ab_CL_10}
                    options={ab_CL_10Options}
                    onChange={(e) => onchangeDropDownFormData21("ab_CL_10", e.value)}
                    placeholder="Select Sales Team"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_11" className="font-bold">
                    Industry
                  </label>
                  <Dropdown
                    id="ab_CL_11"
                    name="ab_CL_11"
                    value={ab_CL_11 || null}
                    options={ab_CL_11Options}
                    onChange={(e) => onchangeDropDownFormData3("ab_CL_11", e.value)}
                    placeholder="Select Industry"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_12" className="font-bold">
                    Vertical
                  </label>
                  <Dropdown
                    id="ab_CL_12"
                    name="ab_CL_12"
                    value={ab_CL_12 || null}
                    options={ab_CL_12Options}
                    onChange={(e) => onchangeDropDownFormData2("ab_CL_12", e.value)}
                    placeholder="Select Vertical"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ab_CL_13" className="font-bold">
                    Area
                  </label>
                  <Dropdown
                    id="ab_CL_13"
                    name="ab_CL_13"
                    value={ab_CL_13 || null}
                    options={ab_CL_13Options}
                    onChange={(e) => onchangeDropDownFormData4("ab_CL_13", e.value)}
                    placeholder="Select Area"
                  />
                </div>
             
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_14" className="font-bold">
                  Class Code 14
                </label>
                <Dropdown 
                  id="ab_CL_14"
                  name="ab_CL_14"
                  value={ab_CL_14}
                  options={ab_CL_14Options}
                  onChange={(e) => onchangeDropDownFormData22("ab_CL_14", e.value)}
                  placeholder="Select ClassCode14"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_15" className="font-bold">
                  Class Code 15
                </label>
                <Dropdown 
                  id="ab_CL_15"
                  name="ab_CL_15"
                  value={ab_CL_15}
                  options={ab_CL_15Options}
                  onChange={(e) => onchangeDropDownFormData23("ab_CL_15", e.value)}
                  placeholder="Select ClassCode15"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_16" className="font-bold">
                  Class Code 16
                </label>
                <Dropdown 
                  id="ab_CL_16"
                  name="ab_CL_16"
                  value={ab_CL_16}
                  options={ab_CL_16Options}
                  onChange={(e) => onchangeDropDownFormData24("ab_CL_16", e.value)}
                  placeholder="Select ClassCode16"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_17" className="font-bold">
                  Class Code 17
                </label>
                <Dropdown 
                  id="ab_CL_17"
                  name="ab_CL_17"
                  value={ab_CL_17}
                  options={ab_CL_17Options}
                  onChange={(e) => onchangeDropDownFormData25("ab_CL_17", e.value)}
                  placeholder="Select ClassCode17"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_18" className="font-bold">
                  Class Code 18
                </label>
                <Dropdown 
                  id="ab_CL_18"
                  name="ab_CL_18"
                  value={ab_CL_18}
                  options={ab_CL_18Options}
                  onChange={(e) => onchangeDropDownFormData26("ab_CL_18", e.value)}
                  placeholder="Select ClassCode18"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_19" className="font-bold">
                  Class Code 18
                </label>
                <Dropdown 
                  id="ab_CL_19"
                  name="ab_CL_19"
                  value={ab_CL_19}
                  options={ab_CL_19Options}
                  onChange={(e) => onchangeDropDownFormData27("ab_CL_19", e.value)}
                  placeholder="Select ClassCode19"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_20" className="font-bold">
                  Class Code 20
                </label>
                <Dropdown 
                  id="ab_CL_20"
                  name="ab_CL_20"
                  value={ab_CL_20}
                  options={ab_CL_20Options}
                  onChange={(e) => onchangeDropDownFormData28("ab_CL_20", e.value)}
                  placeholder="Select ClassCode20"
                />
              </div>



          </div>
          </div>

          </TabPanel>


        </TabView>
      </div>
      {/* accessor */}
      <AddressBookPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleDataSelectParent}
        formData={data}
        addressType={addressTypePrnt}
        setFormData={setFormData}
      />

    </Dialog>
  );
}



