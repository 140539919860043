import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function PayMethodDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  handleFormSubmit,
  pm_PY_TY,
  setpm_PY_TY,
  onchangeFormData,
  PaymethodData,
  setPaymethodData,
}) {
  const {
    pmUkNo,
    pm_PY_ME,
    pm_CO_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle} = ButtonStyles();
  const addRow = () => {
    setPaymethodData([...PaymethodData, {}]);
  };

  const deleteRow = () => {
    if (setPaymethodData.length > 0) {
      const updatedData = [...PaymethodData];
      updatedData.pop();
      setPaymethodData(updatedData);
    }
  };
// dropdown
  const { fetchClassCodeDS } = ClassCode();
  const [pm_PY_TYOptions, setpm_PY_TYOptions] = useState([]);
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_PY_TY(value);
  };
  useEffect(() => {
    fetchDropdowntype();
  }, []);

  const fetchDropdowntype = async () => {
    const Options = await fetchClassCodeDS("DI/TY");
    setpm_PY_TYOptions(Options);
  };
  const isUpdateMode = Boolean(pmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{pmUkNo ? "Revise Pay Method" : "Add Pay Method"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
    );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >

<div  style={tablestyle} >
    <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
    </div>
    <div  style={multitablestyle}>
    <table className="custom-table" id="PaymethodTable" style={{overflow:"auto"}}>
  <thead>
    <tr>
      <th style={{ display: "none" }} >UK_NO</th>
      <th> Pay Method </th>    
      <th> Type </th>
      
    </tr>
  </thead>
  <tbody>
  {PaymethodData.map((row, index) => (
          <tr key={index}>
        <td style={{ display: "none" }} >
        <InputText
          id={`pmUkNo_${index}`}
          name={`pmUkNo_${index}`}
          value={row.pmUkNo}
        />
      </td>
      <td>
        <InputText 
          id={`pm_PY_ME_${index}`}
          name={`pm_PY_ME_${index}`}
          defaultValue={row.pm_PY_ME ? row.pm_PY_ME : row[`${'pm_PY_ME'}_${index}`]}
          onChange={(Event) => onchangedata(Event)}
        />
      </td>
      <td>
        <Dropdown
          id={`pm_PY_TY_${index}`}
          name={`pm_PY_TY_${index}`}
          value={data[`pm_PY_TY_${index}`] ? data[`pm_PY_TY_${index}`]:row.pm_PY_TY }
          options={pm_PY_TYOptions}
          onChange={(e) => onchangeDropDownFormData(`pm_PY_TY_${index}`, e.value)}
          placeholder="Select Type"
        />
      </td>
      </tr>
        ))}
  </tbody>
</table> 
    </div>
</div>


    </Dialog>
  );
}
