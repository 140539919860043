import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import Costcenter from "views/common/CostCentreDrpDwn";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import OperationSequencePopup from "../Popups/OperationSequencePopup";
export default function DefineProcessDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  DefineProcesstable,
  setDefineProcesstable,
  SelectedItem,
  setselectedItem,
  item_DESC,
  setitem_DESC,
  rt_RT_TY,
  setrt_RT_TY,
  rt_CO_CT,
  setrt_CO_CT,
  rt_BT_UM,
  setrt_BT_UM,
  rt_TB_CD,
  setrt_TB_CD,
  rtWK_dESC,
  setrtWK_dESC,
  rt_CO_NO,
}) {
  const {
    rtUkNo,
    rt_IT_NM,
    rt_BT_QT,
    rt_FR_DT,
    rt_TO_DT
   } = data;
   const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
   const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [rt_RT_TYOptions, setrt_RT_TYOptions] = useState([]);
  const [rt_CO_CTOptions, setrt_CO_CTOptions] = useState([]);
  const [rt_BT_UMOptions, setrt_BT_UMOptions] = useState([]);
  const [rt_TB_CDOptions, setrt_TB_CDOptions] = useState([]);

  //Type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrt_RT_TY(value);
  };
  // cost center
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrt_CO_CT(value);
  };
  // um
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrt_BT_UM(value);
  };
  // TIME BASIS
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrt_TB_CD(value);
  };

  useEffect(() => {
    FetchType();
    FetchCostCentre();
    FetchUM();
    FetchTimeBasis();
  }, []);

  const FetchCostCentre = async () => {
    const Options = await fetchCostCenter();
    setrt_CO_CTOptions(Options);
  };
  const FetchType = async () => {
    const Options = await fetchClassCodeDS("MF/TY");
    setrt_RT_TYOptions(Options);
  };
  const FetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrt_BT_UMOptions(Options);
  };
  const FetchTimeBasis = async () => {
    const Options = await fetchClassCodeCD("MF/TB");
    setrt_TB_CDOptions(Options);
  };
  const addRow = () => {
    setDefineProcesstable([...DefineProcesstable, {}]);
  };
  const deleteRow = () => {
    if (DefineProcesstable.length > 0) {
      const updatedData = [...DefineProcesstable];
      updatedData.pop();
      setDefineProcesstable(updatedData);
    }
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    document.getElementById("rt_IT_NM").value = data.im_IT_NM;
    document.getElementById("item_DESC").value = data.im_IT_DS;
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);
  const [OperSequPopupVisible, setOperSequPopupVisible] = useState(false);
  const openWorkcentrePopup = (e) => {
    setWorkCentrePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const openOperationPopup = (e) => {
    setOperSequPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectWKC = (content) => {
    setrtWK_dESC(content.wc_WC_DS);
    document.getElementById(`rtWK_dESC_${SelectedRowIndex}`).value = content.wc_WC_DS;
    document.getElementById(`rt_WK_CT_${SelectedRowIndex}`).value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectOpSq = (content) => {
    document.getElementById(`rt_OP_DS_${SelectedRowIndex}`).value = content.os_SQ_DS;
    document.getElementById(`rt_OP_SQ_${SelectedRowIndex}`).value = content.os_OP_SQ;
    setOperSequPopupVisible(false);
    setShowSearchIcon(false);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{rtUkNo ? "Revise Define Process" : "Add Define Process"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle}> 
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="rtUkNo" className="font-bold">
                  uK
                </label>
                <InputText disabled
                  id="rtUkNo"
                  name="rtUkNo"
                  value={rtUkNo}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rt_IT_NM" className="font-bold">
                    Process <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText 
                    id="item_DESC"
                    name="item_DESC"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={item_DESC}
                  />
                  {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                  )}
                  <InputText disabled style={{display:'none'}}
                  id="rt_IT_NM"
                  name="rt_IT_NM"
                  defaultValue={rt_IT_NM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rt_RT_TY" className="font-bold">
                  Type
                </label>
                <Dropdown
                  id="rt_RT_TY"
                  name="rt_RT_TY"
                  value={rt_RT_TY}
                  options={rt_RT_TYOptions}
                  onChange={(e) => onchangeDropDownFormData1("rt_RT_TY", e.value)}
                  placeholder="Select Type"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rt_BT_QT" className="font-bold">
                Batch Quantity
                </label>
                <InputText 
                  id="rt_BT_QT"
                  name="rt_BT_QT"
                  value={rt_BT_QT}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rt_CO_CT" className="font-bold">
                Cost Centre
                </label>
                <Dropdown
                  id="rt_CO_CT"
                  name="rt_CO_CT"
                  value={rt_CO_CT}
                  options={rt_CO_CTOptions}
                  onChange={(e) => onchangeDropDownFormData2("rt_CO_CT", e.value)}
                  placeholder="Select Cost Centre"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="rt_BT_UM" className="font-bold">
                UM
                </label>
                <Dropdown
                  id="rt_BT_UM"
                  name="rt_BT_UM"
                  value={rt_BT_UM}
                  options={rt_BT_UMOptions}
                  onChange={(e) => onchangeDropDownFormData3("rt_BT_UM", e.value)}
                  placeholder="Select UM"
                />
              </div>
                    
            </div>
          </div>
        <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
        </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="DefineProcesstable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Work Centre <span style={{color:'red'}}>*</span> </th>
                <th>Description</th>
                <th>Oper. Seq. <span style={{color:'red'}}>*</span> </th>
                <th>Description</th>
                <th>Labour Hrs</th>
                <th>Machine Hrs</th>
                <th>Setup Hrs</th>
                <th>Time Basis</th>
                <th>From Date</th>
                <th>To Date</th>
              </tr>
            </thead>
            <tbody>
              {DefineProcesstable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`rtUkNo_${index}`}
                      name={`rtUkNo_${index}`}
                      value={row.rtUkNo}
                    />
                  </td>
                  <td>
                  <div style={{display:"flex"}}>
                    <InputText style={{width:"10rem"}}
                      id={`rt_WK_CT_${index}`}
                      name={`rt_WK_CT_${index}`}               
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}  
                      defaultValue={row.rt_WK_CT}
                    />
                    {showSearchIcon && (
                    <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={() => openWorkcentrePopup(index)}
                      ></i>)}
                  </div>
                </td>
                <td>
                  <InputText style={{width:"15rem"}} disabled
                    id={`rtWK_dESC_${index}`}
                    name={`rtWK_dESC_${index}`}
                  Value={row.rtWK_dESC ? row.rtWK_dESC : row[`${"rtWK_dESC"}_${index}`]}
                  />
                </td>
                <td>
                <div style={{display:'flex'}}>
                  <InputText style={{width:"10rem"}}
                    id={`rt_OP_SQ_${index}`}
                    name={`rt_OP_SQ_${index}`}
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={row.rt_OP_SQ}
                  />
                  {showSearchIcon && (
                    <i className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={() => openOperationPopup(index)}
                    ></i>)}
                  </div>
                </td>
                <td>
                  <InputText style={{width:"15rem"}}
                    id={`rt_OP_DS_${index}`}
                    name={`rt_OP_DS_${index}`}
                    defaultValue={row.rt_OP_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_LB_HR_${index}`}
                    name={`rt_LB_HR_${index}`}
                    value={row.rt_LB_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_MC_HR_${index}`}
                    name={`rt_MC_HR_${index}`}
                    value={row.rt_MC_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText style={{width:"8rem"}}
                    id={`rt_ST_HR_${index}`}
                    name={`rt_ST_HR_${index}`}
                    value={row.rt_ST_HR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`rt_TB_CD_${index}`}
                    name={`rt_TB_CD_${index}`}
                    value={data[`rt_TB_CD_${index}`] ? data[`rt_TB_CD_${index}`] : rt_TB_CD}
                    options={rt_TB_CDOptions}
                    onChange={(e) => onchangeDropDownFormData4(`rt_TB_CD_${index}`, e.value)}
                    placeholder="Select Time Basis"
                  />
                </td>
                <td>
                  <Calendar style={{width:"7rem"}}
                    id={`rt_FR_DT_${index}`}
                    name={`rt_FR_DT_${index}`}
                    value={row.rt_FR_DT? moment(row.rt_FR_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "rt_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                  <Calendar style={{width:"7rem"}}
                    id={`rt_TO_DT_${index}`}
                    name={`rt_TO_DT_${index}`}
                    value={row.rt_TO_DT? moment(row.rt_TO_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "rt_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>  
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      <WorkCentrePopup
        visible={WorkCentrePopupVisible}
        onClose={() => setWorkCentrePopupVisible(false)}
        onDataSelect={handleDataSelectWKC}
        formData={data}
      />
       <OperationSequencePopup
        visible={OperSequPopupVisible}
        onClose={() => setOperSequPopupVisible(false)}
        onDataSelect={handleDataSelectOpSq}
        formData={data}
      />
    </Dialog>
  );
}
