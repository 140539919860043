import React, { useState, useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Toast } from "primereact/toast";
import ButtonStyles from "views/common/ButtonStyles";
import GeneratePopup from "./GeneratePopup";
import '../../common/TabStyles.css';
export default function InterCompanySalesOrderDialog({
  handleFormSubmit,
  sh_OR_TY,
  sh_OR_NO,
  sh_OR_CO,
  setsh_OR_CO,
  handleClose,
  sh_CU_NO,
  sh_SH_TO,
  setsh_CU_NO,
  setsh_SH_TO,
  sh_TX_AP,
  sh_PY_TR,
  sh_TR_CR,
  sh_EX_RT,
  setsh_EX_RT,
  onchangeFormData,
  values,
  requestBodyInput,
  onchangeDropDownFormData4,
  onchangeOrderdate,
  onchangeDropDownFormData5,
  onchangeDropDownFormData,
  onchangeDropDownFormData3,
  onchangeDropDownFormData7,
  onchangeDropDownFormData2,
  InterCompanySalesOrderData,
  deleteRow,
  addRow,
  sd_CO_CT,
  sd_ST_CD,
  sh_CO_CT,
  sh_BS_CR,
  open,
  setOpen,
  data,
  onchangedata,
  sh_UK_NO,
  setsh_UK_NO,
  FormData,
  setFormData,
  selectedAbAdNN,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNM,
  initialValue,
  isRecordSelected,
  handleClickOpen,
  handleResponse,
  po_MAIN_COSTCENTRE,
  so_INV_COSTCENTRE,
  po_INV_COSTCENTRE,
  po_SUPPLIER,
  // for generate popup
  so_ORDERTYPE,
  po_ORDERTYPE,
  ph_OR_CO,
}) {
     // footerstyles
  const { footergridstyle,footerfieldstyle,borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle,Dialogstyle  } = ButtonStyles();
  const toast = useRef(null);
  const [GeneratePopupVisible, setGeneratePopupVisible] = useState(false);
  const openGenratePopup = () => {
    setGeneratePopupVisible(true);
  };


  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemVisible] = useState(false);

  const [sh_CO_CTOptions, setsh_CO_CTOptions] = useState([]);
  const [sh_PY_TROptions, setsh_PY_TROptions] = useState([]);
  const [sh_TR_CROptions, setsh_TR_CROptions] = useState([]);

  const [sh_TX_APOptions, setsh_TX_APOptions] = useState([]);
  const [sd_TX_APOptions, setsd_TX_APOptions] = useState([]);
  const [sd_ST_CDOptions, setsd_ST_CDOptions] = useState([]);
  const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);

  const [sd_IT_NM, setSd_IT_NM] = useState("");
  const [im_IT_DS, setIm_IT_DS] = useState("");
  const [sd_TX_AP, setsd_TX_AP] = useState("");
  const [sd_TR_UM, setsd_TR_UM] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [addressTypeshipto, setaddressTypeshipto] = useState("E");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const {
    sd_UK_NO,
    sh_OR_DT,
    sh_RQ_DT,
    sh_PO_DT,
    sh_CU_PO,
    net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,
    total,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
    fr_TOTAL,
    sh_DS_PR,
    sh_DS_AT,
    sh_PR_DT,
  } = data;

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl1 = `${urlpayterm}`;

  const url = `${domain}/api/ClassCode/All`;

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl = `${urlcost}`;

  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "ME/PL";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "ME/PL";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "SO/ST";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "IN/UM";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setsh_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setsh_PY_TROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsh_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsh_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsd_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsd_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsd_TR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const onchangeDropDownFormData6 = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    setsd_TR_UM(value);
    const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    InterCompanySalesOrderData[index][fieldName]=value;
  };
  const handleDataSelect = async (dataAb) => {
    setSelectedAbAdNN(dataAb.ab_AD_NM);
    setsh_CU_NO(dataAb.abAdNo);
    setSelectedAbAdNM(dataAb.ab_AD_NM);
    setsh_SH_TO(dataAb.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
      "cu_CU_NO": dataAb.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData("sh_PY_TR", dataCuMt.cu_PT_TM);
        //setsh_PY_TR(dataCuMt.cu_PT_TM);
        onchangeDropDownFormData4("sh_TR_CR", dataCuMt.cu_CR_CD);
        onchangeDropDownFormData5("sh_TX_AP", dataCuMt.cu_TX_AP);

        const exData={
          "cr_TO_CR": document.getElementById("sh_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
          "cr_EF_DT": data.sh_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  function exchangeRate (exData){
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("sh_EX_RT").value = dataExRt.cr_CO_MR;
            setsh_EX_RT(dataExRt.cr_CO_MR);
          }

        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  };
  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    setsh_SH_TO(data.abAdNo);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.data) {
        setSelectedAbAdNM(data.data.ab_AD_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const onChangeOfPrice= async(SelectedRowIndex) => {
    const UrlCalulate = `${domain}/api/SalesQuote/GetCalculatedPrice`;
    const priceInput = {
      "exchange_RATE": document.getElementById("sh_EX_RT").value,
      "fr_UNIT_PRICE": document.getElementById (`sd_FR_PR_${SelectedRowIndex}`).value,
      "tax_PERCENTAGE": document.getElementById (`sd_TX_PE_${SelectedRowIndex}`).value,
      "cost_CENTER":   document.getElementById (`sd_CO_CT_${SelectedRowIndex}`).value,
      "item_NAME":document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "row_NUM": SelectedRowIndex,
      "discount_PERCENTAGE": document.getElementById (`sd_DS_PR_${SelectedRowIndex}`).value,
      "unit_PRICE": document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value,
      "order_QUANTITY": document.getElementById (`sd_OR_QT_${SelectedRowIndex}`).value
    }
    try {
      const response =await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCalulate = response.data.out1;
      if (dataCalulate ) {
        document.getElementById (`sd_DS_AT_${SelectedRowIndex}`).value =  dataCalulate.discount_AMOUNT;
        document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataCalulate.unit_PRICE;//UNIT COST
        document.getElementById (`sd_DS_PR_${SelectedRowIndex}`).value =  dataCalulate.discount_PERCENTAGE;
        document.getElementById (`net_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.net_AMOUNT;
        document.getElementById (`sd_FR_DS_${SelectedRowIndex}`).value =  dataCalulate.fr_DISCOUNT_AMOUNT;
        document.getElementById (`sd_FR_AT_${SelectedRowIndex}`).value =  dataCalulate.fr_PRICE_AMOUNT;
        document.getElementById (`fr_NET_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.fr_NET_AMOUNT;
        document.getElementById (`sd_EX_PR_${SelectedRowIndex}`).value =  dataCalulate.price_AMOUNT;
        document.getElementById (`sd_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.tax_AMOUNT;

        const salesHeader = {
          sh_TR_CR: sh_TR_CR,
          sh_EX_RT: document.getElementById("sh_EX_RT").value,
          sh_DS_AT: document.getElementById("sh_DS_AT").value,
          sh_BS_CR: document.getElementById("sh_BS_CR").value,
        };
        const table = document.getElementById("InterCompanySalesOrder");
        const totalRowCount = table.tBodies[0].rows.length;
        const salesDetail = [];
        for (let i = 0; i < totalRowCount; i++) {  
          const RQ_DT = document.getElementsByName("sd_RQ_DT_" + i)[0]; 
          const PR_DT = document.getElementsByName("sd_PR_DT_" + i)[0];    
          const txAP = document.getElementById ("sd_TX_AP_" + i).value;
          var TX_AP = 'n';
          if(txAP.toUpperCase() === 'Y'){
            TX_AP = 'y';
          }
          const Details = {
            sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
            sdLnNo: document.getElementById("sdLnNo_" + i).value,
            sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
            sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
            sd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
            sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
            sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
            sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
            sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
            sd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            sd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
            sd_FR_AT: document.getElementById("sd_FR_AT_" + i).value,
            sd_TX_AP: TX_AP,
            sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
            sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
            sd_ST_CD: document.getElementById("sd_ST_CD_" + i).value,
            sd_FR_DS: document.getElementById("sd_FR_DS_" + i).value,
            sd_CO_CT: document.getElementById("sd_CO_CT_" + i).value
          };
          salesDetail.push(Details);
        }
        const requestBodyNet = {
          salesHeader,
          salesDetail,
        };
        axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmount`, requestBodyNet, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((respNet) => {
          document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.fr_PRICE_AMOUNT;
          document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.fr_DISCOUNT_AMOUNT;
          document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.fr_NET_AMOUNT;
          document.getElementById("tax_AMOUNT").value = respNet.data.header.tax_AMOUNT;
          document.getElementById("discount_AMOUNT").value = respNet.data.header.discount_AMOUNT;
          document.getElementById("price_AMOUNT").value = respNet.data.header.price_AMOUNT;
          document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
          document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.fr_TAX_AMOUNT;
          document.getElementById("total").value = respNet.data.header.total;
          document.getElementById("fr_TOTAL").value = respNet.data.header.fr_TOTAL;
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });


      } else {
        console.error("Invalid API response:", dataCalulate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeHeaderDisAmt = async(e) => {
    const salesHeader = {
      sh_TR_CR: sh_TR_CR,
      sh_EX_RT: document.getElementById("sh_EX_RT").value,
      sh_DS_AT: e.currentTarget.value,
      sh_BS_CR: document.getElementById("sh_BS_CR").value,
    };
    const table = document.getElementById("InterCompanySalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const RQ_DT = document.getElementsByName("sd_RQ_DT_" + i)[0]; 
      const PR_DT = document.getElementsByName("sd_PR_DT_" + i)[0];    
      const txAP = document.getElementById ("sd_TX_AP_" + i).value;
      var TX_AP = 'n';
      if(txAP.toUpperCase() === 'Y'){
        TX_AP = 'y';
      }
      const Details = {
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sdLnNo: document.getElementById("sdLnNo_" + i).value,
        sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
        sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
        sd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
        sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
        sd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
        sd_FR_AT: document.getElementById("sd_FR_AT_" + i).value,
        sd_TX_AP: TX_AP,
        sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
        sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
        sd_ST_CD: document.getElementById("sd_ST_CD_" + i).value,
        sd_FR_DS: document.getElementById("sd_FR_DS_" + i).value,
        sd_CO_CT: document.getElementById("sd_CO_CT_" + i).value
      };
      salesDetail.push(Details);
    }
    const requestBodyNet = {
      salesHeader,
      salesDetail,
    };
    axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmount`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((respNet) => {
      document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.fr_PRICE_AMOUNT;
      document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.fr_DISCOUNT_AMOUNT;
      document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.fr_NET_AMOUNT;
      document.getElementById("tax_AMOUNT").value = respNet.data.header.tax_AMOUNT;
      document.getElementById("discount_AMOUNT").value = respNet.data.header.discount_AMOUNT;
      document.getElementById("price_AMOUNT").value = respNet.data.header.price_AMOUNT;
      document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
      document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.fr_TAX_AMOUNT;
      document.getElementById("total").value = respNet.data.header.total;
      document.getElementById("fr_TOTAL").value = respNet.data.header.fr_TOTAL;
    })
    .catch((error) => {
      console.error("Error updating data:", error);
    });

    onchangedata(e);
  }; 
  const onchangeHeaderDisPer = async(e) => {
    const disPer = e.currentTarget.value;
    const table = document.getElementById("InterCompanySalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;

    if(disPer){
      for (let i = 0; i < totalRowCount; i++) { 
        const disPerMul = document.getElementById ("sd_DS_PR_" + i).value;
        if(!disPerMul || disPerMul === '0'){
          document.getElementById ("sd_DS_PR_" + i).value = disPer;
          onChangeOfPrice(i);
        }
      }
    }
    onchangedata(e);
  }; 
  const onchangeOfHeaderTaxApl = async(name,value) => {
    const txAP = value;
    const table = document.getElementById("InterCompanySalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;

    if(txAP.toUpperCase() === 'Y'){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("sd_TX_AP_" + i).value = 'y';
        const item = document.getElementById ("sd_IT_NM_" + i).value;
        const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
        const requestData={
            "im_IT_NM": item
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.Result;
          if (dataItMt ) {
            document.getElementById ("sd_TX_PE_" + i).value =  dataItMt.im_VA_TX;
            onChangeOfPrice(i);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {    
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("sd_TX_AP_" + i).value = 'n';
        document.getElementById ("sd_TX_PE_" + i).value = '0';
        onChangeOfPrice(i);
      }
    }
    onchangeDropDownFormData5("sh_TX_AP", value);
  }; 
  const onchangeOfTaxAplMul = async(e,index) => {
    const txAP = e.currentTarget.value;
    const item = document.getElementById (`sd_IT_NM_${index}`).value;
    if(txAP.toUpperCase() === 'Y'){
      const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
      const requestData={
          "im_IT_NM": item
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data.Result;
        if (dataItMt ) {
          document.getElementById (`sd_TX_PE_${index}`).value =  dataItMt.im_VA_TX;
          onChangeOfPrice(index);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      document.getElementById (`sd_TX_PE_${index}`).value = '0';
      onChangeOfPrice(index);
    }
  };  
  const onchangeFormUnitPrice = async(e,index) => {
    onchangedata(e);
    onChangeOfPrice(index);
  };
  const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("InterCompanySalesOrder");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : sh_CO_CT,
        "bp_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : FormData.sh_OR_DT,
        "bp_TO_DT" : FormData.sh_OR_DT,
        "bp_CU_NO" : document.getElementById("sh_CU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : sh_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
  };
  const handleDataSelect2 = async (Result) => {
    const bsCr = document.getElementById("sh_BS_CR").value;
    const table = document.getElementById("InterCompanySalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === sh_TR_CR){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_UT_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_FR_PR_" + i).setAttribute('disabled','disabled');
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_FR_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_UT_PR_" + i).setAttribute('disabled','disabled');
      }
    }

    // setSd_IT_NM(Result.im_IT_NM);
    // setIm_IT_DS(Result.im_IT_DS);
    // onchangeFormData("sd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    // onchangeFormData("im_IT_DS", Result.im_IT_DS, SelectedRowIndex);

    document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value = Result.im_IT_NM;
    document.getElementById (`im_IT_DS_${SelectedRowIndex}`).value = Result.im_IT_DS;

    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":Result.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        document.getElementById (`sd_TX_AP_${SelectedRowIndex}`).value =  dataItMt.im_TX_AP;
        document.getElementById (`sd_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
        onchangeDropDownFormData6(`sd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
        onchangebspr(SelectedRowIndex);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const [Gsh_OR_NO, setGsh_OR_NO] = useState("");
  const [ph_OR_NO, setph_OR_NO] = useState("");
  const [Gsh_OR_CO, setGsh_OR_CO] = useState("");

  // generate so/po function
  const handleGeneratePOSO = () => {
    const OR_DT = FormData.sh_OR_DT;
    if (OR_DT) {
    const currentYear = OR_DT.toString();
    const firstHalf = currentYear.substring(0, 2);
    const secondHalf = currentYear.substring(2, 4);
    const urlNextNumber = `${domain}/api/DocumentNumber/All`;
    const parameters = `${so_ORDERTYPE}/${secondHalf}/${Gsh_OR_CO}/${firstHalf}`;
    if (document.getElementById("Gsh_OR_NO").value) {
      if (document.getElementById("ph_OR_NO").value) {
   // generate
   const table = document.getElementById("InterCompanySalesOrder");
   const totalRowCount = table.tBodies[0].rows.length;
   const salesDetail = [];
   for (let i = 0; i < totalRowCount; i++) { 
     const Details = { 
     sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
   }
   salesDetail.push(Details);
 }
   const RequestSO = {
     "salesHeader":{      
       "shUkNo" : document.getElementById("sh_UK_NO").value,
       "sh_OR_NO" : document.getElementById("Gsh_OR_NO").value,
       "sh_OR_CO" : document.getElementById("sh_OR_CO").value,
       "sh_IN_NO" : document.getElementById("ph_OR_NO").value,
       "sh_CO_CT" : sh_CO_CT,
       "po_MAIN_COSTCENTRE": document.getElementById("po_MAIN_COSTCENTRE").value, 
       "so_INV_COSTCENTRE": document.getElementById("so_INV_COSTCENTRE").value,
       "po_INV_COSTCENTRE": document.getElementById("po_INV_COSTCENTRE").value,
       "po_SUPPLIER" :document.getElementById("po_SUPPLIER").value,
       "orderType" : document.getElementById("Gsh_OR_TY").value,
       "purchaseType" : document.getElementById("ph_OR_TY").value,
       },
       "salesDetail":salesDetail
       }
       console.log(RequestSO);
   const GenerateSourl = `${domain}/api/InterCompanySalesOrder/GenerateSalesAndPurchaseOrderForInterCompanyOrders`;
   try{     
     axios.post(GenerateSourl, RequestSO, {
       headers: {
         Authorization: `Bearer ${token}`,
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin": "http://localhost:3000",
         "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
       },
     })
     .then((resp) => {
       handleResponse(resp.data);
     })
     }
     catch(error){
       console.error("Error updating data:", error);
     };


      }else{
        const parametersPH = `${po_ORDERTYPE}/${secondHalf}/${ph_OR_CO}/${firstHalf}`;
        const finalUrl2 = `${urlNextNumber}/${parametersPH}`;
        axios.get(finalUrl2, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            setph_OR_NO(resp.data.dn_DO_NO);
            document.getElementById("ph_OR_NO").value = resp.data.dn_DO_NO;

          // generate
          const table = document.getElementById("InterCompanySalesOrder");
          const totalRowCount = table.tBodies[0].rows.length;
          const salesDetail = [];
          for (let i = 0; i < totalRowCount; i++) { 
            const Details = { 
            sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
          }
          salesDetail.push(Details);
        }
          const RequestSO = {
            "salesHeader":{      
              "shUkNo" : document.getElementById("sh_UK_NO").value,
              "sh_OR_NO" : document.getElementById("Gsh_OR_NO").value,
              "sh_OR_CO" : document.getElementById("sh_OR_CO").value,
              "sh_IN_NO" : document.getElementById("ph_OR_NO").value,
              "sh_CO_CT" : sh_CO_CT,
              "po_MAIN_COSTCENTRE": document.getElementById("po_MAIN_COSTCENTRE").value, 
              "so_INV_COSTCENTRE": document.getElementById("so_INV_COSTCENTRE").value,
              "po_INV_COSTCENTRE": document.getElementById("po_INV_COSTCENTRE").value,
              "po_SUPPLIER" :document.getElementById("po_SUPPLIER").value,
              "orderType" : document.getElementById("Gsh_OR_TY").value,
              "purchaseType" : document.getElementById("ph_OR_TY").value,
              },
              "salesDetail":salesDetail
              }
              console.log(RequestSO);
          const GenerateSourl = `${domain}/api/InterCompanySalesOrder/GenerateSalesAndPurchaseOrderForInterCompanyOrders`;
          try{     
            axios.post(GenerateSourl, RequestSO, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            }
            catch(error){
              console.error("Error updating data:", error);
            };
        })
      }
    }else{
      const OR_DT = FormData.sh_OR_DT;
      if (OR_DT) {
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        axios.get(finalUrl1, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            setGsh_OR_NO(resp.data.dn_DO_NO);
            document.getElementById("Gsh_OR_NO").value = resp.data.dn_DO_NO;
            // ph next number
            const parametersPH = `${po_ORDERTYPE}/${secondHalf}/${ph_OR_CO}/${firstHalf}`;
            const finalUrl2 = `${urlNextNumber}/${parametersPH}`;
            axios.get(finalUrl2, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
              .then((resp) => {
                setph_OR_NO(resp.data.dn_DO_NO);
                document.getElementById("ph_OR_NO").value = resp.data.dn_DO_NO;

              // generate
              const table = document.getElementById("InterCompanySalesOrder");
              const totalRowCount = table.tBodies[0].rows.length;
              const salesDetail = [];
              for (let i = 0; i < totalRowCount; i++) { 
                const Details = { 
                sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
              }
              salesDetail.push(Details);
            }
              const RequestSO = {
                "salesHeader":{      
                  "shUkNo" : document.getElementById("sh_UK_NO").value,
                  "sh_OR_NO" : document.getElementById("Gsh_OR_NO").value,
                  "sh_OR_CO" : document.getElementById("sh_OR_CO").value,
                  "sh_IN_NO" : document.getElementById("ph_OR_NO").value,
                  "sh_CO_CT" : sh_CO_CT,
                  "po_MAIN_COSTCENTRE": document.getElementById("po_MAIN_COSTCENTRE").value, 
                  "so_INV_COSTCENTRE": document.getElementById("so_INV_COSTCENTRE").value,
                  "po_INV_COSTCENTRE": document.getElementById("po_INV_COSTCENTRE").value,
                  "po_SUPPLIER" :document.getElementById("po_SUPPLIER").value,
                  "orderType" : document.getElementById("Gsh_OR_TY").value,
                  "purchaseType" : document.getElementById("ph_OR_TY").value,
                  },
                  "salesDetail":salesDetail
                  }
                  console.log(RequestSO);
              const GenerateSourl = `${domain}/api/InterCompanySalesOrder/GenerateSalesAndPurchaseOrderForInterCompanyOrders`;
              try{     
                axios.post(GenerateSourl, RequestSO, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                }
                catch(error){
                  console.error("Error updating data:", error);
                };
            })
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      } 
    }
  }
  else{
    console.error("Order Date is Not Entered ");
  }
};






 // DATE DEFAULT
 const [selectedDate, setSelectedDate] = useState(moment().toDate());
 const handleORDateChange = (e) => {
   setSelectedDate(e.value);
   onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
 };
 const [selectedRqDate, setSelectedRqDate] = useState(moment().toDate());
 const handleRQDateChange = (e) => {
   const newDate = e.value;
 setSelectedRqDate(newDate);
 setCurrentDate(newDate); // Sync sd_RQ_DT with sh_RQ_DT
 onchangedata({ target: { name: "sh_RQ_DT", value: moment(newDate).format("YYYYMMDD") } });
 };

 const [selectedPRDate, setSelectedPRDate] = useState(moment().toDate());
 const handlePRDateChange = (e) => {
  const newDate = e.value;
  setSelectedPRDate(newDate);
 setCurrentDate1(newDate); // Sync sd_RQ_DT with sh_RQ_DT
 onchangedata({ target: { name: "sh_PR_DT", value: moment(newDate).format("YYYYMMDD") } });
 };
 const [currentDate, setCurrentDate] = useState(moment().toDate());
 const [currentDate1, setCurrentDate1] = useState(moment().toDate());
 const isUpdateMode = Boolean(sh_UK_NO);
   // DIALOG HEADER
   const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sh_UK_NO ? "Revise Inter Company SO" : "Add Inter Company SO"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
        <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
             tooltip="Save"
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
          <Button 
            style={{border:"none",backgroundColor:"transparent",color:"blue"}}
            icon="pi pi-file" 
            onClick={openGenratePopup} 
            tooltip={"Generate"}>
          </Button>

        </div>
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
     <div style={borderbox} >
            <div style={inboxstyle} >
         <Toast ref={toast} />
        <div className="formgrid grid">
          <div style ={{display:"none"}}>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="sh_UK_NO" className="font-bold">
                UKNO
              </label>
              <InputText   id="sh_UK_NO"   name="sh_UK_NO"  value={sh_UK_NO} style={{width:'10rem'}} />
           
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="po_MAIN_COSTCENTRE" className="font-bold">
              PO_MAIN_COSTCENTRE
              </label>
              <InputText  id="po_MAIN_COSTCENTRE" name="po_MAIN_COSTCENTRE"  defaultValue={po_MAIN_COSTCENTRE} disabled  style={{width:'10rem'}}/>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="so_INV_COSTCENTRE" className="font-bold">
              so_INV_COSTCENTRE
              </label>
              <InputText  id="so_INV_COSTCENTRE" name="so_INV_COSTCENTRE"  value={so_INV_COSTCENTRE} disabled style={{width:'10rem'}} />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="po_INV_COSTCENTRE" className="font-bold">
              po_INV_COSTCENTRE
              </label>
              <InputText  id="po_INV_COSTCENTRE" name="po_INV_COSTCENTRE"  value={po_INV_COSTCENTRE}  disabled  style={{width:'10rem'}}/>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="po_SUPPLIER" className="font-bold">
              po_SUPPLIER
              </label>
              <InputText  id="po_SUPPLIER" name="po_SUPPLIER"  value={po_SUPPLIER} disabled style={{width:'10rem'}} />
            </div>
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_OR_NO" className="font-bold">
              Order No
            </label>
            <InputText disabled
              id="sh_OR_NO"
              name="sh_OR_NO"
              value={sh_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_OR_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="sh_OR_TY"
              name="sh_OR_TY"
              value={sh_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_OR_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="sh_OR_CO"
              name="sh_OR_CO"
              value={sh_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_OR_DT" className="font-bold">
              Order Date
            </label>
             <Calendar disabled={!!sh_UK_NO}
              id="sh_OR_DT"
              name="sh_OR_DT"
              value={moment(sh_OR_DT, "YYYYMMDD").toDate()}
              onChange={(e) => {
                handleORDateChange(e);
                onchangeOrderdate({target: { name: "sh_OR_DT",value: moment(e.value).format("YYYYMMDD")}})}}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_RQ_DT" className="font-bold">
              Req. Date
            </label>
             <Calendar disabled={!!sh_UK_NO}
              id="sh_RQ_DT"
              name="sh_RQ_DT"
              value={moment(sh_RQ_DT, "YYYYMMDD").toDate()}
              onChange={handleRQDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_CO_CT" className="font-bold">
              Cost Center
            </label>
            <Dropdown disabled
              id="sh_CO_CT"
              name="sh_CO_CT"
              value={sh_CO_CT}
              options={sh_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData3("sh_CO_CT", e.value)}
              placeholder="Select Cost Center"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_CU_NO" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled={!!sh_UK_NO}
                id="cus_NAME"
                name="cus_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNN}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{display:"none"}}>
              <InputText 
                id="sh_CU_NO"
                name="sh_CU_NO"
                defaultValue={sh_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_PY_TR" className="font-bold">
              Pay Term
            </label>
            <Dropdown disabled={!!sh_UK_NO}
              id="sh_PY_TR"
              name="sh_PY_TR"
              value={sh_PY_TR}
              options={sh_PY_TROptions}
              onChange={(e) => onchangeDropDownFormData("sh_PY_TR", e.value)}
              placeholder="Select Pay Term"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_TX_AP" className="font-bold">
              Tax Y/N
            </label>
            <Dropdown disabled={!!sh_UK_NO}
              id="sh_TX_AP"
              name="sh_TX_AP"
              value={sh_TX_AP}
              options={[
                { label: "Yes", value: "y" },
                { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeOfHeaderTaxApl("sh_TX_AP", e.value)}
              placeholder="Select Tax"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_SH_TO" className="font-bold">
              Ship To <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled={!!sh_UK_NO}
                id="sh_SH_NM"
                name="sh_SH_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openShipToPopup}
              ></i>
               )}
            </div>
            <div style={{display:"none"}}>
              <InputText
                id="sh_SH_TO"
                name="sh_SH_TO"
                value={sh_SH_TO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_DS_PR" className="font-bold">
              Discount %
            </label>
            <InputText disabled={!!sh_UK_NO}
              id="sh_DS_PR"
              name="sh_DS_PR"
              value={sh_DS_PR}
              onChange={(e) => onchangeHeaderDisPer(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_DS_AT" className="font-bold">
              Dis.Amount
            </label>
            <InputText disabled={!!sh_UK_NO}
              id="sh_DS_AT"
              name="sh_DS_AT"
              value={sh_DS_AT}
              onChange={(e) => onchangeHeaderDisAmt(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_TR_CR" className="font-bold">
              Currency
            </label>
            <Dropdown disabled={!!sh_UK_NO}
              id="sh_TR_CR"
              name="sh_TR_CR"
              value={sh_TR_CR}
              options={sh_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData4("sh_TR_CR", e.value)}
              placeholder="Select Currency"

            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_EX_RT" className="font-bold">
              Exchange Rate
            </label>
            <InputText disabled
              id="sh_EX_RT"
              name="sh_EX_RT"
              value={sh_EX_RT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_BS_CR" className="font-bold">
              Base Currency
            </label>
            <InputText disabled id="sh_BS_CR" name="sh_BS_CR" value={sh_BS_CR} />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_CU_PO" className="font-bold">
              Customer PO
            </label>
            <InputText id="sh_CU_PO" name="sh_CU_PO" value={sh_CU_PO}  disabled={!!sh_UK_NO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="sh_PO_DT" className="font-bold">
              PO Date
            </label>
            <Calendar disabled={!!sh_UK_NO}
              id="sh_PO_DT"
              name="sh_PO_DT"
              value={moment(sh_PO_DT, "YYYYMMDD").toDate()}
              onChange={(e) =>  onchangeOrderdate({  target: {  name: "sh_PO_DT",  value: moment(e.value).format("YYYYMMDD"),   },   }) }
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="sh_PR_DT" className="font-bold">
              Promised Date
            </label>
            <Calendar disabled={!!sh_UK_NO}
              id="sh_PR_DT"
              name="sh_PR_DT"
              value={moment(sh_PR_DT, "YYYYMMDD").toDate()}
              onChange={handlePRDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          </div>
      </div>
      <div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus" onClick={addRow}  style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus"  onClick={deleteRow}  style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
            <table className="custom-table" id="InterCompanySalesOrder">
              <thead>
                <tr>
                  <th style={{display:"none"}}>UKNO</th>
                  <th>Line No</th>
                  <th>Item Number <span style={{ color: 'red' }}>*</span></th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>UM</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Discount %</th>
                  <th>Discount Amount</th>
                  <th>Net Amount</th>
                  <th>Fore. Price</th>
                  <th>Fore. Amount</th>
                  <th>Fore. Discount Amt</th>
                  <th>Fore. Net Amount</th>
                  <th>Tax Y/N</th>
                  <th>Tax Rate</th>
                  <th>Tax</th>
                  <th>Status</th>
                  <th>Cost Center</th>
                  <th style={{display:'none'}} >Requested Date</th>
                  <th style={{display:'none'}} >Promised Date</th>
                </tr>
              </thead>
              <tbody>
                {InterCompanySalesOrderData.map((row, index) => (
                  <tr key={index}>
                     <td style={{display:"none"}}>
                      <InputText
                        style={{ width: "90px" }}
                        id={`sd_UK_NO_${index}`}
                        name={`sd_UK_NO_${index}`}
                        defaultValue={row.sd_UK_NO}
                        readOnly
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`sdLnNo_${index}`}
                        name={`sdLnNo_${index}`}
                        value={index + 1}
                      />
                    </td>
                    <td style={{ display: "flex" }}>
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "10rem" }}
                        id={`sd_IT_NM_${index}`}
                        name={`sd_IT_NM_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.sd_IT_NM ? row.sd_IT_NM: row[`${"sd_IT_NM"}_${index}`]}
                      />
                       {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                       )}
                    </td>
                    <td>
                    <InputText disabled
                        style={{ width: "15rem" }}
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "7rem" }}
                        id={`sd_OR_QT_${index}`}
                        name={`sd_OR_QT_${index}`}
                        defaultValue={row.sd_OR_QT}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                  <Dropdown disabled={!!sh_UK_NO}
                    id={`sd_TR_UM_${index}`}
                    name={`sd_TR_UM_${index}`}
                    value={ data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`]: row.sd_TR_UM}
                    options={sd_TR_UMOptions}
                    onChange={(e) =>handleFormDataChange(`sd_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                  </td>
                    <td>
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "10rem" }}
                        id={`sd_UT_PR_${index}`}
                        name={`sd_UT_PR_${index}`}
                        defaultValue={row.sd_UT_PR}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`sd_EX_PR_${index}`}
                        name={`sd_EX_PR_${index}`}
                        defaultValue={row.sd_EX_PR}
                      />
                    </td>
                    <td>
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "7rem" }}
                        id={`sd_DS_PR_${index}`}
                        name={`sd_DS_PR_${index}`}
                        defaultValue={row.sd_DS_PR}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_DS_AT_${index}`}
                        name={`sd_DS_AT_${index}`}
                        defaultValue={row.sd_DS_AT}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        defaultValue={row.net_AMOUNT}
                      />
                    </td>
                    <td>
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "10rem" }}
                        id={`sd_FR_PR_${index}`}
                        name={`sd_FR_PR_${index}`}
                        defaultValue={row.sd_FR_PR}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`sd_FR_AT_${index}`}
                        name={`sd_FR_AT_${index}`}
                        defaultValue={row.sd_FR_AT}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`sd_FR_DS_${index}`}
                        name={`sd_FR_DS_${index}`}
                        defaultValue={row.sd_FR_DS}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`fr_NET_AMOUNT_${index}`}
                        name={`fr_NET_AMOUNT_${index}`}
                        defaultValue={row.fr_NET_AMOUNT}
                        readOnly
                      />
                    </td>
                    <td>
                     
                      <InputText disabled={!!sh_UK_NO}
                        style={{ width: "7rem" }}
                        id={`sd_TX_AP_${index}`}
                        name={`sd_TX_AP_${index}`}
                        defaultValue={row.sd_TX_AP}
                        onChange={(e) => onchangeOfTaxAplMul(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "5rem" }}
                        id={`sd_TX_PE_${index}`}
                        name={`sd_TX_PE_${index}`}
                        defaultValue={row.sd_TX_PE}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_TX_AM_${index}`}
                        name={`sd_TX_AM_${index}`}
                        defaultValue={row.sd_TX_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                    <InputText disabled
                        style={{ width: "6rem" }}
                        id={`sd_ST_CD_${index}`}
                        name={`sd_ST_CD_${index}`}
                        value={ row.sd_ST_CD ? row.sd_ST_CD : sd_ST_CD}
                        readOnly
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "6rem" }}
                        id={`sd_CO_CT_${index}`}
                        name={`sd_CO_CT_${index}`}
                        value={sd_CO_CT}
                      />
                    </td>
                    <td style={{display:'none'}}>
                      <Calendar 
                        style={{width:"150px"}}
                        id={`sd_RQ_DT_${index}`}
                        name={`sd_RQ_DT_${index}`}
                        value={currentDate}
                        onChange={(e) => {setCurrentDate(e.value);
                        onDateChange("sd_RQ_DT", index, e.value);
                        }}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                      <td style={{display:'none'}}>
                      <Calendar 
                        style={{width:"150px"}}
                        id={`sd_PR_DT_${index}`}
                        name={`sd_PR_DT_${index}`}
                        value={currentDate1}
                        onChange={(e) => {setCurrentDate1(e.value);
                        onDateChange("sd_PR_DT", index, e.value);
                        }}
                        dateFormat="dd/mm/yy"
                      />
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div className="formgrid grid" style={footergridstyle} >
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              value={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Discount
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              value={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              value={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              value={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="TOTAL" className="font-bold">
              Total
            </label>
            <InputText disabled
              id="total"
              name="total"
              value={total}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          <div className="formgrid grid" style={footergridstyle} >
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore.Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              value={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_DISCOUNT_AMOUNT" className="font-bold">
              Fore.Discount
            </label>
            <InputText disabled
              id="fr_DISCOUNT_AMOUNT"
              name="fr_DISCOUNT_AMOUNT"
              value={fr_DISCOUNT_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_NET_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="fr_NET_AMOUNT"
              name="fr_NET_AMOUNT"
              value={fr_NET_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              value={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_TOTAL" className="font-bold">
              Fore.Total
            </label>
            <InputText disabled
              id="fr_TOTAL"
              name="fr_TOTAL"
              value={fr_TOTAL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
      </div>
      </div>
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeshipto}
        setFormData={setFormData}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
        <GeneratePopup
          visible={GeneratePopupVisible}
          onClose={() => setGeneratePopupVisible(false)}
          data={data}
          ph_OR_TY={po_ORDERTYPE}
          ph_OR_CO={ph_OR_CO}
          setFormData={setFormData}
          ph_OR_NO={ph_OR_NO}
          Gsh_OR_NO={Gsh_OR_NO}
          setGsh_OR_NO={setGsh_OR_NO}
          setph_OR_NO={setph_OR_NO}
          Gsh_OR_TY={so_ORDERTYPE}
          Gsh_OR_CO={Gsh_OR_CO}
          setGsh_OR_CO={setGsh_OR_CO}
          handleGeneratePOSO={handleGeneratePOSO}
        />
    </Dialog>
  );
}
