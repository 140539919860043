import React, { useState, useEffect } from "react";
import "components/ListStyle/ListStyle.css";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const LeaseHistory = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {
    // buttonstyles
    const {formatDate, tableHeaderStyle, inputStyle,tableCellStyle,Dialogstyle } = ButtonStyles();
    const initialFilters = {
        li_UN_TS: { value: "", operation: "" },
        li_FL_NO: { value: "", operation: "" },
        li_BL_NO: { value: "", operation: "" },
        li_PR_NO: { value: "", operation: "" },
        liLeNo: { value: "", operation: "" },
        li_LE_TY: { value: "", operation: "" },
        li_ST_DT: { value: "", operation: "" },
        li_EN_DT: { value: "", operation: "" },
        li_TE_NT: { value: "", operation: "" },
        teanat_NAME: { value: "", operation: "" },
        li_MN_RN: { value: "", operation: "" },
        li_AN_RN: { value: "", operation: "" },
      };
    const [filters, setFilters] = useState(initialFilters);
    const handleFilterChange = (column, value) => {
        let updatedOperation = "LIKE";  
        let updatedValue = value;
        if (value.startsWith(">")) {
            updatedOperation = "GREATER_THAN";
            updatedValue = value.substring(1);
        } else if (value.startsWith("<")) {
            updatedOperation = "LESS_THAN";
            updatedValue = value.substring(1);
        } else if (value.includes(",")) {
            updatedOperation = "BETWEEN";
        } else if (!isNaN(value)) {
            updatedOperation = "EQUAL";
        }
        const updatedFilters = { ...filters };
        updatedFilters[column] = {
            value: updatedValue,
            operation: updatedOperation,
        };
        setFilters(updatedFilters);
    };
    // Function to filter data based on filters
    const filterData = (data) => {
        if (!Array.isArray(data)) return [];

        return data.filter((row) => {
            return Object.keys(filters).every((key) => {
                const filter = filters[key];
                if (!filter.value) return true;
                const value = row[key];
                switch (filter.operation) {
                    case "GREATER_THAN":
                        return parseFloat(value) > parseFloat(filter.value);
                    case "LESS_THAN":
                        return parseFloat(value) < parseFloat(filter.value);
                    case "EQUAL":
                        return value.toString() === filter.value.toString();
                    case "LIKE":
                        return value.toString().includes(filter.value);
                    case "BETWEEN":
                        const [min, max] = filter.value.split(",").map((v) => parseFloat(v));
                        return value >= min && value <= max;
                    default:
                        return true;
                }
            });
        });
    };
    // const filteredData = filterData(dashboardData.OCCUPIED_UNITS);
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const [selecteddays, setSelecteddays] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [initialData, setInitialData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const ListLease = `${domain}/api/LeaseInformation/Lists/${currentPage}/${pageSize}`;
    const ListLeaseExpiry= async (days) => {
        const requestBody = {
            "searchRequestDto":[],
            "input":days,
        };
        try {
            const response = await axios.post(ListLease, requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });
            // const data = response.data.leaseInformationPage.content;
            // setDashboardData(data);
            const data = response.data;
            if (days) {
              setFilteredData(data.leasesNearingExpiry);
            } else {
              setInitialData(data.leaseInformationPage.content);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleClose = () => {
        onClose();
        setOpen(false);
        setFilters(initialFilters);
    };
    useEffect(() => {
        const filtered = filterData(initialData);
        setFilteredData(filtered);
    }, [initialData, filters]);

    useEffect(() => {
        ListLeaseExpiry();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear,]);

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            // style={{ width: "80rem", height: "53rem",}}
            style={Dialogstyle}
            header={"Lease History"}
        >
            <div style={{ display: "flex",overflowY:'auto' }}>
                {/* <div style={{ flex: "1 0 48%",  borderRadius: "10px", flexDirection: "column" }}> */}
                    {/* <div style={{ textAlign: "center", fontSize: "150%", fontWeight: "bold", marginBottom: "1%" }}>Lease Near Expiry</div> */}
                    {/* <div style={{ overflow: "auto" }}> */}
                    <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                        <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                        <tr>
                            {Object.keys(initialFilters).map((key) => (
                                <td key={key}>
                                    <input
                                        type="text"
                                        value={filters[key]?.value || ""}
                                        onChange={(e) =>
                                            handleFilterChange(key, e.target.value)
                                        }
                                        style={inputStyle}
                                        disabled={key === 'teanat_NAME'}
                                    />
                                </td>
                            ))}
                        </tr>
                            <tr>
                                <th style={tableHeaderStyle} >Unit</th>
                                <th style={tableHeaderStyle} >Floor</th>
                                <th style={tableHeaderStyle} >Building</th>
                                <th style={tableHeaderStyle} >Property</th>
                                <th style={tableHeaderStyle} >Lease No</th>
                                <th style={tableHeaderStyle} >Type</th>
                                <th style={tableHeaderStyle} >From Date</th>
                                <th style={tableHeaderStyle} >To Date</th>
                                <th style={tableHeaderStyle} >Tenant</th>
                                <th style={tableHeaderStyle} >Name</th>
                                <th style={tableHeaderStyle} >Monthly Rent</th>
                                <th style={tableHeaderStyle} >Annual Rent</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                        {filteredData.map((rowData, index) => (
                                <tr key={index} className="table-row" style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                    <td style={tableCellStyle} >{rowData.li_UN_TS}</td>
                                    <td style={tableCellStyle} >{rowData.li_FL_NO}</td>
                                    <td style={tableCellStyle} >{rowData.li_BL_NO}</td>
                                    <td style={tableCellStyle} >{rowData.li_PR_NO}</td>
                                    <td style={tableCellStyle} >{rowData.liLeNo}</td>
                                    <td style={tableCellStyle} >{rowData.li_LE_TY}</td>
                                    <td style={tableCellStyle} >{formatDate(rowData.li_ST_DT)}</td>
                                    <td style={tableCellStyle} >{formatDate(rowData.li_EN_DT)}</td>
                                    <td style={tableCellStyle} >{rowData.li_TE_NT}</td>
                                    <td style={tableCellStyle} >{rowData.teanat_NAME}</td>
                                    <td style={tableCellStyle} >{rowData.li_MN_RN}</td>
                                    <td style={tableCellStyle} >{rowData.li_AN_RN}</td>
                                   
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* </div> */}
                {/* </div> */}
               
            </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />
        </Dialog>
    );
};

export default LeaseHistory;

