import React, { useState, useEffect } from "react";
import "components/ListStyle/ListStyle.css";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
const UnoccupiedUnitDetails = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {
    // buttonstyles
    const { tableHeaderStyle,inputStyle,tableCellStyle,Dialogstyle } = ButtonStyles();
    const [open, setOpen] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        UNOCCUPIED_UNITS: [],
    });
    // filters
    const initialFilters = {
        ui_AL_DS: { value: "", operation: "" },
        ui_FL_NO: { value: "", operation: "" },
        ui_BL_NO: { value: "", operation: "" },
        ui_PR_NO: { value: "", operation: "" },
        ui_TR_QT: { value: "", operation: "" },
        ui_TR_UM: { value: "", operation: "" },
    };
    const [filters, setFilters] = useState(initialFilters);
    const handleFilterChange = (column, value) => {
    let updatedOperation = "";
    let updatedValue = value;
    // Determine operation based on value format
    if (value.startsWith(">")) {
        updatedOperation = "GREATER_THAN";
        updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
        updatedOperation = "LESS_THAN";
        updatedValue = value.substring(1);
    } else if (value.includes(",")) {
        updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
        updatedOperation = "EQUAL";
    } else {
        updatedOperation = "LIKE";
    }

    // Update filters state
    const updatedFilters = { ...filters };
    updatedFilters[column] = {
        value: updatedValue,
        operation: updatedOperation,
    };
    setFilters(updatedFilters);
    };
    // Function to filter data based on filters
    const filterData = (data) => {
        if (!Array.isArray(data)) return [];
        
        return data.filter((row) => {
          return Object.keys(filters).every((key) => {
            const filter = filters[key];
            if (!filter.value) return true; // If filter value is empty, don't apply the filter
            
            const value = row[key];
            // Check if value is null or undefined before calling toString
            const valueStr = value != null ? value.toString() : "";
      
            switch (filter.operation) {
              case "GREATER_THAN":
                return parseFloat(valueStr) > parseFloat(filter.value);
              case "LESS_THAN":
                return parseFloat(valueStr) < parseFloat(filter.value);
              case "EQUAL":
                return valueStr === filter.value.toString();
              case "LIKE":
                return valueStr.includes(filter.value);
              case "BETWEEN":
                const [min, max] = filter.value.split(",").map((v) => parseFloat(v));
                return value >= min && value <= max;
              default:
                return true;
            }
          });
        });
      };
      
    const filteredData = filterData(dashboardData.UNOCCUPIED_UNITS);

    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    const domain = localStorage.getItem("domain");

    const dashboard = `${domain}/api/Dashboard/RealEstateDashboard`;
    const REdashboard = async () => {
        const requestinput = {
            "year":selectedYear ,
            "month": selectedMonth,
        };
        try {
            const response = await axios.post(dashboard, requestinput, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
            });
            const data = response.data;
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
        setFilters(initialFilters);
    };
    useEffect(() => {
        REdashboard();
        setOpen(visible);
    }, [visible, selectedMonth, selectedYear]);

    return (
        <Dialog id="dialog-style"
            visible={open}
            onHide={handleClose}
            // style={{ width: "75rem", height: "53rem" }}
            style={Dialogstyle}
            header={"UnOccupied Units"}
            headerStyle={{textAlign:"center"}}
        >
            <div style={{ display: "flex",overflowY:'auto'  }}>
                <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                    <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                    <tr>
                        {Object.keys(initialFilters).map((key) => (
                            <td key={key}>
                            <input
                                type="text"
                                value={filters[key]?.value || ""}
                                onChange={(e) => handleFilterChange(key, e.target.value)}
                                style={inputStyle}
                                // Disable the input for Area (ui_TR_QT) and UM (ui_TR_UM)
                                disabled={key === 'ui_TR_QT' || key === 'ui_TR_UM'}
                            />
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <th style={tableHeaderStyle} >Unit</th>
                        <th style={tableHeaderStyle} >Floor</th>
                        <th style={tableHeaderStyle} >Building</th>
                        <th style={tableHeaderStyle} >Property</th>
                        <th style={tableHeaderStyle} >Area</th>
                        <th style={tableHeaderStyle} >UM</th>
                    </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                    {/* {dashboardData.UNOCCUPIED_UNITS && dashboardData.UNOCCUPIED_UNITS.map((rowData, index) => ( */}
                        {filteredData.map((rowData, index) => (
                            <tr key={index} className="table-row" 
                            style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                <td style={tableCellStyle} >{rowData.ui_UN_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_FL_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_BL_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_PR_NO}</td>
                                <td style={tableCellStyle} >{rowData.ui_TR_QT}</td>
                                <td style={tableCellStyle} >{rowData.ui_TR_UM}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};

export default UnoccupiedUnitDetails;

