import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
import StockAvailabilityPopup from "../Popups/StockAvailabilityPopup";
export default function PurchaseOrderDialog({
  open,
  handleClose,
  data,
  FormData,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeOrderdate,
  OnchangeTableData,
  OnchangeTableData1,
  setFormData,
  handleConfirm,
  SelectedemEMNO,
  setSelectedemEMNO,
  setph_SH_NO,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNM,
  LocationDropdown,
  LOTNoDropdown,
  ph_OR_TY,
  ph_OR_CO,
  ph_BS_CR,
  ph_SU_NO,
  ph_SH_NO,
  ph_CO_CT,
  ph_EX_RT,
  ph_PY_TR,
  pd_ST_CD,
  setpd_IT_NM,
  setph_PY_TR,
  setph_SU_NO,
  setph_EX_RT,
  addRow,
  deleteRow,
  addRow1,
  deleteRow1,
  onchangeFieldSetFormData,
  PurchaseOrdertable,
  PurchaseOrdertable1,
  cancelFlag,
  setapplied,
  settax_APPLICABLE,
  setcurrency,
  setSelectedsupplier,
  setSelectedName,
  ph_LN_CT,
  pd_CO_CT,
  HoldCodeDropdown,
  setreturnable,
  setpd_RL_TY,
  setpd_RL_CO,
  LandedCostDropdown,
  ph_AL_TY,
  setSelectedstock,
  table1Ref,
  handleInputChange,
}) {
  const { 
    pdUkNo,
    phUkNO,
    ph_OR_NO,
    ph_HL_CD,
    ph_OR_DT,
    ph_RQ_DT,
    ph_TX_AP,
    ph_DS_AM,
    ph_DS_PR,
    net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,
    total,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_tax_AMOUNT,
    fr_TOTAL,
    ph_PR_DT,
  } = data;

  const {footergridstyle,footerfieldstyle,plusstyle,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,confirmstyle,
    minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const isProtected = pd_ST_CD > 200;
  const [activeTab, setActiveTab] = useState(0); 
  const [Addresstype, setAddresstype] = useState("S");
  const [addressTypeship, setaddressTypeship] = useState("S");
  const [showSearchIcon, setShowSearchIcon] = useState("F");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [currentDate, setCurrentDate] = useState(moment().toDate());

  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_TR_CROptions, setph_TR_CROptions] = useState([]);
  const [ph_TX_APOptions, setph_TX_APOptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [pd_SC_UMOptions, setpd_SC_UMOptions] = useState([]);
  const [pd_TX_APOptions, setpd_TX_APOptions] = useState([]);
  const [ph_PY_TROptions, setph_PY_TROptions] = useState([]);
  const [appliedOptions, setappliedOptions] = useState([]);
  const [tax_APPLICABLEOptions, settax_APPLICABLEOptions] = useState([]);
  const [currencyOptions, setcurrencyOptions] = useState([]);
  const [pay_TO_SUPPLIEROptions, setpay_TO_SUPPLIEROptions] = useState([]);
  const [ph_LN_CTOptions, setph_LN_CTOptions] = useState([]);
  const [ph_HL_CDOptions, setph_HL_CDOptions] = useState([]);
  const [pd_LO_CNOptions, setpd_LO_CNOptions] = useState([]);
  const [pd_LT_NOOptions, setpd_LT_NOOptions] = useState([]);
  const [returnableOptions, setreturnableOptions] = useState([]);
  const [pd_RL_TYOptions, setpd_RL_TYOptions] = useState([]);
  const [pd_RL_COOptions, setpd_RL_COOptions] = useState([]);
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange3 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData7(name, value, index);
  };
  const AppliedDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setapplied(value);
  };
  const TaxDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settax_APPLICABLE(value);
  };
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcurrency(value);
  };
  const SupplierDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settax_APPLICABLE(value);
  };
  const ReturnableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setreturnable(value);
  };
  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_RL_TY(value);
  };
  const CompanyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_RL_CO(value);
  }; 
  const url = `${domain}/api/ClassCode/All`;

  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "TX/AP";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter7 = "ME/PL";
  const finalUrl7 = `${url}/${parameter7}`;

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;

  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl6 = `${urlpayterm}`;

  const urlloc = `${domain}/api/LocationMaster/DropDown`;
  const finalUrl8 = `${urlloc}`;

  const urlcom = `${domain}/api/CompanyMaster/GetActiveCompaniesByClient`;
  const finalUrl9 = `${urlcom}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    Applied();
    Tax();
    Currency();
    Supplier();
    LandedCost();
    HoldCode();
    Returnable();
    fetchDropdownOptions9();
    Type();
  }, []);
// Company
  const fetchDropdownOptions9 = async () => {
    try {
      const response = await axios.get(finalUrl9, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.coCoNo,
        value: item.coCoNo,
      }));
      setpd_RL_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// Location 
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.lm_LO_CN,
      value: item.lm_LO_CN,
    }));
    setpd_LO_CNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const Type = async () => {
  const Options = await fetchClassCodeCD("FO/DT");
  setpd_RL_TYOptions(Options);
};
const Applied = async () => {
  const Options = await fetchClassCodeDS("ME/PL");
  setappliedOptions(Options);
};
const Tax = async () => {
  const Options = await fetchClassCodeDS("ME/PL");
  settax_APPLICABLEOptions(Options);
};
const Returnable = async () => {
  const Options = await fetchClassCodeDS("ME/PL");
  setreturnableOptions(Options);
};
const Currency = async () => {
  const Options = await fetchClassCodeCD("FO/CR");
  setcurrencyOptions(Options);
};
const Supplier = async () => {
  const Options = await fetchClassCodeDS("ME/PL");
  setpay_TO_SUPPLIEROptions(Options);
};
const LandedCost = async () => {
  const Options = await fetchClassCodeDS("AP/LC");
  setph_LN_CTOptions(Options);
};
const HoldCode = async () => {
  const Options = await fetchClassCodeDS("FO/HD");
  setph_HL_CDOptions(Options);
};
// 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setph_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpd_TR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpd_SC_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 6th dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setph_PY_TROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 7th dropdown
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setpd_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
const openSupplierhPopup = (e) => {
  setSupplierhPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
const openShipToPopup = (e) => {
  setShipToPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};
const handlesupplier =async (data) => {
  setSelectedName(data.ab_AD_NM);
  setSelectedsupplier( data.abAdNo);
  OnchangeTableData1("supplier", data.abAdNo, SelectedRowIndex);
  OnchangeTableData1("name", data.ab_AD_NM, SelectedRowIndex);
  setSupplierPopupVisible(false);
  setShowSearchIcon(false);
};
// LOT NO
const [StockAvailabilityPopupVisible, setStockAvailabilityPopupVisible] = useState(false);
const openStockAvailabilityPopup = (e) => {
  setStockAvailabilityPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleStockAvailability =async (data) => {
  setSelectedstock( data.abAdNo);
  OnchangeTableData("pd_LT_NO", data.is_LT_NO, SelectedRowIndex);
  setStockAvailabilityPopupVisible(false);
  setShowSearchIcon(false);
};
// Supplier
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  setph_SU_NO(data.abAdNo);
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);

  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData6("ph_PY_TR", dataCuMt.su_PT_TM);
         setph_PY_TR(dataCuMt.su_PT_TM);
        onchangeDropDownFormData2("ph_TR_CR", dataCuMt.su_CR_CD);
        onchangeDropDownFormData3("ph_TX_AP", dataCuMt.su_TX_AP);
       
        onchangeFormData(`ph_TR_CR`, dataCuMt.su_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("ph_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.ph_TR_CR, //currency
          "cr_EF_DT": data.ph_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setSupplierhPopupVisible(false);
  setShowSearchIcon(false);
};
exchangeRate(null);
function exchangeRate (exData){
  if(exData==null && ph_BS_CR!="" && data.ph_TR_CR!="" && data.ph_OR_DT!=""){
    exData={
      "cr_TO_CR": ph_BS_CR,
      "cr_FR_CR": data.ph_TR_CR,
      "cr_EF_DT": data.ph_OR_DT
    }
  }
  if(exData!=null){

    axios
    .post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
        setph_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  }
};
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);
  const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
  const requestData={
    "abAdNo":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = response.data;
    if (data && data.data) {
      setSelectedAbAdNM(data.data.ab_AD_NM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setShipToPopupVisible(false);
  setShowSearchIcon(false);
};
const onchangeFormUnitPrice = async(e,index) => {
  onchangedata(e);
  onChangeOfPrice(index);
};
const  onChangeOfPrice= async(SelectedRowIndex) => {
  const UrlCalulate = `${domain}/api/RentalContract/GetAmountDetails`;
  const priceInput = {
    "exChangeRate": document.getElementById("ph_EX_RT").value,
    "rentalContracts":[{
            "unitPrice":document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value,
            "quantity":document.getElementById (`pd_OR_QT_${SelectedRowIndex}`).value,
            "taxPercentage":document.getElementById (`pd_TX_PE_${SelectedRowIndex}`).value,
            "mulDisPercentage":document.getElementById (`pd_DS_PR_${SelectedRowIndex}`).value,
            "foreUnitPrice":document.getElementById (`pd_FR_UP_${SelectedRowIndex}`).value,
       } ]
  }
  try {
    const response =await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCalulate = response.data.Result;
    if (dataCalulate ) {
      onchangeFieldSetFormData(`pd_DS_AM_${SelectedRowIndex}`,dataCalulate.matDisamt);
      onchangeFieldSetFormData(`pd_BS_UP_${SelectedRowIndex}`,dataCalulate.unitPrice);
      onchangeFieldSetFormData(`net_AMOUNT_${SelectedRowIndex}`,dataCalulate.matnetAmtMul);
      onchangeFieldSetFormData(`pd_FR_DS_${SelectedRowIndex}`,dataCalulate.forematDisamt);
      onchangeFieldSetFormData(`pd_FR_AT_${SelectedRowIndex}`,dataCalulate.foreAmount);
      onchangeFieldSetFormData(`fr_NET_AMOUNT_${SelectedRowIndex}`,dataCalulate.forenetAmtMul);
      onchangeFieldSetFormData(`pd_OR_AT_${SelectedRowIndex}`,dataCalulate.matUnit);
      onchangeFieldSetFormData(`pd_TX_AM_${SelectedRowIndex}`,dataCalulate.matTaxamt);
      // COPY
      const purchaseQuotes = {
        ph_TR_CR: data.ph_TR_CR,
        ph_EX_RT: document.getElementById("ph_EX_RT").value,
        ph_BS_CR: document.getElementById("ph_BS_CR").value,
        ph_CO_CT: ph_CO_CT
      };
     
      const table = document.getElementById("PurchaseOrdertable");
      const totalRowCount = table.tBodies[0].rows.length;
      const purchaseOrders = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const PR_DT = document.getElementsByName("pd_PR_DT_" + i)[0];
        const Details = {
          pdUkNo : document.getElementById("pdUkNo_" + i).value,
        pd_LN_NO : document.getElementById("pd_LN_NO_" + i).value,
        pd_IT_NM : document.getElementById("pd_IT_NM_" + i).value,
        im_IT_DS : document.getElementById("im_IT_DS_" + i).value,
        pd_OR_QT : document.getElementById("pd_OR_QT_" + i).value,
        pd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        pd_LS_PR : document.getElementById("pd_LS_PR_" + i).value,
        std_COST : document.getElementById("std_COST_" + i).value,
        pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
        pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
        pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
        pd_DS_AM : document.getElementById("pd_DS_AM_" + i).value,
        net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
        pd_TX_AP : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
        pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
        pd_TX_AM : document.getElementById("pd_TX_AM_" + i).value,
        pd_FR_UP : document.getElementById("pd_FR_UP_" + i).value,
        pd_FR_AT : document.getElementById("pd_FR_AT_" + i).value,
        pd_FR_DS : document.getElementById("pd_FR_DS_" + i).value,
        fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
        pd_LD_CT : document.getElementById("pd_LD_CT_" + i).value,
        pd_LO_CN : table.tBodies[0].rows[i].cells[21].children[0].children[1].children[0].value,
        pd_LT_NO : document.getElementById("pd_LT_NO_" + i).value,
        returnable : table.tBodies[0].rows[i].cells[23].children[0].children[1].children[0].value,
        pd_RQ_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        pd_PR_DT : PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        pd_SC_QT : document.getElementById("pd_SC_QT_" + i).value,
        pd_SC_UM : table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
        pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
        pd_CN_DT : CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        pd_RL_NO : document.getElementById("pd_RL_NO_" + i).value,
        pd_RL_TY : table.tBodies[0].rows[i].cells[31].children[0].children[1].children[0].value,
        pd_RL_CO : table.tBodies[0].rows[i].cells[32].children[0].children[1].children[0].value,
        pd_ST_CD : document.getElementById("pd_ST_CD_" + i).value,
        pd_CO_CT : document.getElementById("pd_CO_CT_" + i).value,
      };
        purchaseOrders.push(Details);
      }
      const requestBodyNet = {
        purchaseOrders,
        purchaseQuotes
      };
      axios.post(`${domain}/api/PurchaseQuote/GetPopulateNetAmount`, requestBodyNet, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((respNet) => {
        onchangeFieldSetFormData("price_AMOUNT", respNet.data.header.price_AMOUNT);
        onchangeFieldSetFormData("fr_PRICE_AMOUNT", respNet.data.header.fr_PRICE_AMOUNT);
        onchangeFieldSetFormData("fr_DISCOUNT_AMOUNT", respNet.data.header.fr_DISCOUNT_AMOUNT);
        onchangeFieldSetFormData("fr_NET_AMOUNT", respNet.data.header.fr_NET_AMOUNT);
        onchangeFieldSetFormData("tax_AMOUNT", respNet.data.header.tax_AMOUNT);
        onchangeFieldSetFormData("discount_AMOUNT", respNet.data.header.discount_AMOUNT);
        onchangeFieldSetFormData("net_AMOUNT", respNet.data.header.net_AMOUNT);
        onchangeFieldSetFormData("fr_tax_AMOUNT", respNet.data.header.fr_tax_AMOUNT);
        onchangeFieldSetFormData("total", respNet.data.header.total);
        onchangeFieldSetFormData("fr_TOTAL", respNet.data.header.fr_TOTAL);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });


    } else {
      console.error("Invalid API response:", dataCalulate);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const onchangeHeaderDisAmt = async(e) => {
  const purchaseQuotes = {
    ph_TR_CR: data.ph_TR_CR,
    ph_EX_RT: document.getElementById("ph_EX_RT").value,
    ph_BS_CR: document.getElementById("ph_BS_CR").value,
    ph_DS_AM: e.currentTarget.value,
  };
  const table = document.getElementById("PurchaseOrdertable");
  const totalRowCount = table.tBodies[0].rows.length;
  const purchaseOrders = [];
  for (let i = 0; i < totalRowCount; i++) {  
    const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
    const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
    const PR_DT = document.getElementsByName("pd_PR_DT_" + i)[0];
    const Details = {
      pdUkNo : document.getElementById("pdUkNo_" + i).value,
    pd_LN_NO : document.getElementById("pd_LN_NO_" + i).value,
    pd_IT_NM : document.getElementById("pd_IT_NM_" + i).value,
    im_IT_DS : document.getElementById("im_IT_DS_" + i).value,
    pd_OR_QT : document.getElementById("pd_OR_QT_" + i).value,
    pd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
    pd_LS_PR : document.getElementById("pd_LS_PR_" + i).value,
    std_COST : document.getElementById("std_COST_" + i).value,
    pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
    pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
    pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
    pd_DS_AM : document.getElementById("pd_DS_AM_" + i).value,
    net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
    pd_TX_AP : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
    pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
    pd_TX_AM : document.getElementById("pd_TX_AM_" + i).value,
    pd_FR_UP : document.getElementById("pd_FR_UP_" + i).value,
    pd_FR_AT : document.getElementById("pd_FR_AT_" + i).value,
    pd_FR_DS : document.getElementById("pd_FR_DS_" + i).value,
    fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
    pd_LD_CT : document.getElementById("pd_LD_CT_" + i).value,
    pd_LO_CN : table.tBodies[0].rows[i].cells[21].children[0].children[1].children[0].value,
    pd_LT_NO : document.getElementById("pd_LT_NO_" + i).value,
    returnable : table.tBodies[0].rows[i].cells[23].children[0].children[1].children[0].value,
    pd_RQ_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
    pd_PR_DT : PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
    pd_SC_QT : document.getElementById("pd_SC_QT_" + i).value,
    pd_SC_UM : table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
    pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
    pd_CN_DT : CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
    pd_RL_NO : document.getElementById("pd_RL_NO_" + i).value,
    pd_RL_TY : table.tBodies[0].rows[i].cells[31].children[0].children[1].children[0].value,
    pd_RL_CO : table.tBodies[0].rows[i].cells[32].children[0].children[1].children[0].value,
    pd_ST_CD : document.getElementById("pd_ST_CD_" + i).value,
    pd_CO_CT : document.getElementById("pd_CO_CT_" + i).value,
  };
    purchaseOrders.push(Details);
  }
  const requestBodyNet = {
    purchaseQuotes,
        purchaseOrders,
  };
  axios.post(`${domain}/api/PurchaseQuote/GetPopulateNetAmount`, requestBodyNet, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((respNet) => {
    onchangeFieldSetFormData("price_AMOUNT", respNet.data.header.price_AMOUNT);
    onchangeFieldSetFormData("fr_PRICE_AMOUNT", respNet.data.header.fr_PRICE_AMOUNT);
    onchangeFieldSetFormData("fr_DISCOUNT_AMOUNT", respNet.data.header.fr_DISCOUNT_AMOUNT);
    onchangeFieldSetFormData("fr_NET_AMOUNT", respNet.data.header.fr_NET_AMOUNT);
    onchangeFieldSetFormData("tax_AMOUNT", respNet.data.header.tax_AMOUNT);
    onchangeFieldSetFormData("discount_AMOUNT", respNet.data.header.discount_AMOUNT);
    onchangeFieldSetFormData("net_AMOUNT", respNet.data.header.net_AMOUNT);
    onchangeFieldSetFormData("fr_tax_AMOUNT", respNet.data.header.fr_tax_AMOUNT);
    onchangeFieldSetFormData("total", respNet.data.header.total);
    onchangeFieldSetFormData("fr_TOTAL", respNet.data.header.fr_TOTAL);
  })
  .catch((error) => {
    console.error("Error updating data:", error);
  });

  onchangedata(e);
};
const onchangeHeaderDisPer = async(e) => {
  const disPer = e.currentTarget.value;
  const table = document.getElementById("PurchaseOrdertable");
  const totalRowCount = table.tBodies[0].rows.length;

  if(disPer){
    for (let i = 0; i < totalRowCount; i++) { 
      const disPerMul = document.getElementById ("pd_DS_PR_" + i).value;
      if(!disPerMul || disPerMul === '0'){
        document.getElementById ("pd_DS_PR_" + i).value = disPer;
        onChangeOfPrice(i);
      }
    }
  }
  onchangedata(e);
}; 
const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("PurchaseOrdertable");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : ph_CO_CT,
        "bp_IT_NM" : document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : ph_OR_DT,
        "bp_TO_DT" : ph_OR_DT,
        "bp_CU_NO" : document.getElementById("ph_SU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : data.ph_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
};
const handleDataSelect2 = async(Result) => {
  setSelectedAbAdNN(Result.im_IT_DS);
  setpd_IT_NM(Result.im_IT_NM);
  OnchangeTableData("pd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
  OnchangeTableData("im_IT_DS", Result.im_IT_DS, SelectedRowIndex);
  // document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
  // document.getElementById (`im_IT_DS_${SelectedRowIndex}`).value =  Result.im_IT_DS;
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeFieldSetFormData(`pd_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      onchangeFieldSetFormData(`pd_TX_PE_${SelectedRowIndex}`, dataItMt.im_VA_TX);
      onchangeDropDownFormData5(`pd_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
    await LastPrice(Result.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
const LastPrice =async (im_IT_NM) => {
  const requestBodyNet = {
    "is_IT_NM": im_IT_NM,
    "is_CO_CT": pd_CO_CT,
  };
  axios.post(`${domain}/api/StockAvailability/GetAvailableStock`, requestBodyNet, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((respNet) => {
    onchangeFieldSetFormData(`pd_LS_PR_${SelectedRowIndex}`,respNet.data.Result);
  })
  .catch((error) => {
    console.error("Error updating data:", error);
  });
};
const Price =async (im_IT_NM) => {
  const Url = `${domain}/api/PurchaseReceipt/GetLastPrice`;
  const requestData={
    "pr_IT_NM": im_IT_NM,
    "pr_CO_CT": pd_CO_CT,
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataItMt = response.data.result;
  if (dataItMt ) {
    onchangeFieldSetFormData(`availability_AMOUNT_${SelectedRowIndex}`,dataItMt.pr_UT_CT);
  } else {
    console.error("Invalid API response:", data);
  }
} catch (error) {
  console.error("Error fetching data:", error);
}
 
};
const isUpdateMode = Boolean(phUkNO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{phUkNO ? "Revise Purchase Order" : "Add Purchase Order"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
    {activeTab === 0 && (
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
    )}
    {activeTab === 0 && (
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    )}
     {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          {activeTab === 0 && (
          <Button
            style={confirmstyle}
            icon="pi pi-check-circle"
            variant="outlined"
            onClick={handleConfirm}
            tooltip="Confirm"
          />
        )}
        </div>
       )}
    </div>
  </div>
);
  const productDialogFooter = (
    <React.Fragment>
    <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
     {isUpdateMode && (
      <div style={{ display: 'flex',width:"10%" }}>
        {activeTab === 0 && (
        <Button
          style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue", }}
          icon="pi pi-check-circle"
          variant="outlined"
          onClick={handleConfirm}
          tooltip="Confirm"
        />
      )}
      </div>
     )}
       <div style={{ display: 'flex', width: "10%" }}>
      {activeTab === 0 && (
        <Button
          style={{ width: "100%", marginRight: "8px", }}
          icon="pi pi-times"
          variant="outlined"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      {activeTab === 0 && (
        <Button
          style={{ width: "100%" }}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip={activeTab === 0 ? "Submit" : "Update"} 
        />
      )}
    </div>
  </div>
</React.Fragment>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
              {/* tab1 general */}
          <div style={inboxstyle} >
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
          <label htmlFor="phUkNO" className="font-bold">
          UKNO
          </label>
          <InputText
          id="phUkNO"
          name="phUkNO"
          value={phUkNO}
          onChange={(e) => onchangedata(e)}
          />
          </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_NO" className="font-bold">
            Order No
            </label>
            <InputText disabled
            id="ph_OR_NO"
            name="ph_OR_NO"
            value={ph_OR_NO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_TY" className="font-bold">
            Type
            </label>
            <InputText disabled
            id="ph_OR_TY"
            name="ph_OR_TY"
            value={ph_OR_TY}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
            id="ph_OR_CO"
            name="ph_OR_CO"
            value={ph_OR_CO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_DT" className="font-bold">
            Order Date
            </label>
            <Calendar
            id="ph_OR_DT"
            name="ph_OR_DT"
            value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
            onChange={(e) =>
            onchangeOrderdate({
            target: {
            name: "ph_OR_DT",
            value: moment(e.value).format("YYYYMMDD"),
            },
            })
            }
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_RQ_DT" className="font-bold">
            Requested Date
            </label>
            <Calendar
            id="ph_RQ_DT"
            name="ph_RQ_DT"
            value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "ph_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled
            id="ph_CO_CT"
            name="ph_CO_CT"
            value={ph_CO_CT}
            options={ph_CO_CTOptions}
            onChange={(e) => onchangeDropDownFormData1("ph_CO_CT", e.value)}
            placeholder="Select Cost Centre"
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_SU_NO" className="font-bold">
            Supplier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              id="sup_NAME"
              name="sup_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNO}
              disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              )}
              </div>
              <InputText style={{ display: "none" }}
              id="ph_SU_NO"
              name="ph_SU_NO"
              value={ph_SU_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PY_TR" className="font-bold">
                Pay Term
              </label>
              <Dropdown
                id="ph_PY_TR"
                name="ph_PY_TR"
                value={ph_PY_TR}
                options={ph_PY_TROptions}
                onChange={(e) => onchangeDropDownFormData6("ph_PY_TR", e.value)}
                placeholder="Select Pay Term"
                disabled={cancelFlag || isProtected}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TX_AP" className="font-bold">
              Tax Applicable
              </label>
              <Dropdown
              id="ph_TX_AP"
              name="ph_TX_AP"
              value={ph_TX_AP}
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeDropDownFormData3("ph_TX_AP", e.value)}
              placeholder="Select Tax Applicable"
              disabled={cancelFlag || isProtected}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_SH_NO" className="font-bold">
              Ship To <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText
              id="shipto_NAME"
              name="shipto_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedAbAdNM}
              disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openShipToPopup}
              ></i>
              )}
              </div>
              <div style={{display:"none"}}>
              <InputText
              id="ph_SH_NO"
              name="ph_SH_NO"
              value={ph_SH_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_DS_PR" className="font-bold">
                Discount %
              </label>
              <InputText
                id="ph_DS_PR"
                name="ph_DS_PR"
                value={ph_DS_PR}
                onChange={(e) => onchangeHeaderDisPer(e)}
                disabled={cancelFlag || isProtected}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_DS_AM" className="font-bold">
                Dis.Amount
              </label>
              <InputText
                id="ph_DS_AM"
                name="ph_DS_AM"
                value={ph_DS_AM}
                onChange={(e) => onchangeHeaderDisAmt(e)}
                disabled={cancelFlag || isProtected}
              />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TR_CR" className="font-bold">
              Currency
              </label>
              <Dropdown
              id="ph_TR_CR"
              name="ph_TR_CR"
              value={data.ph_TR_CR}
              options={ph_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData2("ph_TR_CR", e.value)}
              placeholder="Select Currency"
              disabled={cancelFlag || isProtected}
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_EX_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
              id="ph_EX_RT"
              name="ph_EX_RT"
              value={ph_EX_RT}
              onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_BS_CR" className="font-bold">
            Base Currency
            </label>
            <InputText disabled
            id="ph_BS_CR"
            name="ph_BS_CR"
            value={ph_BS_CR}
            onChange={(e) => onchangedata(e)}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_LN_CT" className="font-bold">
              Landed Cost
              </label>
              <Dropdown
              id="ph_LN_CT"
              name="ph_LN_CT"
              value={ph_LN_CT}
              options={ph_LN_CTOptions}
              onChange={(e) => LandedCostDropdown("ph_LN_CT", e.value)}
              placeholder="Select Landed Cost"
              disabled={cancelFlag || isProtected}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_HL_CD" className="font-bold">
              Hold Code
              </label>
              <Dropdown
                id="ph_HL_CD"
                name="ph_HL_CD"
                value={ph_HL_CD}
                options={ph_HL_CDOptions}
                onChange={(e) => HoldCodeDropdown("ph_HL_CD", e.value)}
                placeholder="Select  Hold Code"
                disabled={cancelFlag || isProtected}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_PR_DT" className="font-bold">
            Promised Date
            </label>
            <Calendar
            id="ph_PR_DT"
            name="ph_PR_DT"
            value={moment(ph_PR_DT, "YYYYMMDD").toDate()}
            onChange={(e) => onchangedata({ target: { name: "ph_PR_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </div>
          </div>
        </div>

        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="PurchaseOrdertable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th>Line No</th>
            <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
            <th>Description</th>
            <th>Order Quantity</th>
            <th>UM</th>
            <th>Last in Price</th>
            <th>Std Cost</th>
            <th>Unit Price</th>
            <th>Amount</th>
            <th>Discount %</th>
            <th>Discount Amount</th>
            <th>Net Amount</th>
            <th>Tax Applicable</th>
            <th>Tax%</th>
            <th>Tax</th>
            <th>Fore.Price</th>
            <th>Fore.Amount</th>
            <th>Fore. Discount Amt</th>
            <th>Fore. Net Amount</th>
            <th>Landed Cost</th>
            <th>Location</th>
            <th>LOT No</th>
            <th>Returnable</th>
            <th>Req.Date</th>
            <th>Promised Date</th>
            <th>Secondary Quantity</th>
            <th>UM</th>
            <th>Can. Quantity</th>
            <th>Can. Date</th>
            <th>Rel. Order No</th>
            <th>Rel. Order Type</th>
            <th>Rel. Order Co</th>
            <th>Status</th>
            <th>Cost Centre</th>
          </tr>
        </thead>
        <tbody>
        {PurchaseOrdertable.map((row, index) => (
                <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`pdUkNo_${index}`}
                name={`pdUkNo_${index}`}
                defaultValue={row.pdUkNo}
              />
            </td>
            <td>
            <InputText disabled
            style={{ width: "7rem" }}
            id={`pd_LN_NO_${index}`}
            name={`pd_LN_NO_${index}`}
            value={index + 1}
          />
            </td>
            <td >
              <div style={{ display: "flex" }}> 
              <InputText
                style={{ width: "12rem" }}
                id={`pd_IT_NM_${index}`}
                name={`pd_IT_NM_${index}`}
                // defaultValue={pd_IT_NM || row.pd_IT_NM}
                onChange={(e) =>  OnchangeTableData('pd_IT_NM',e.value,index)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.pd_IT_NM ? row.pd_IT_NM: row[`${"pd_IT_NM"}_${index}`]}
                disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                }}
                onClick={(e) => openItemPopup(index)}
              ></i>
              )}
              </div>
            </td>
            <td>
            <InputText disabled
                style={{ width: "15rem" }}
                id={`im_IT_DS_${index}`}
                name={`im_IT_DS_${index}`}
                value={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                onChange={(e) =>  OnchangeTableData('im_IT_DS',e.value,index)}
              />
            </td>
            <td>
            <InputText style={{width:"10rem"}}
              id={`pd_OR_QT_${index}`}
              name={`pd_OR_QT_${index}`}
              value={data[`pd_OR_QT_${index}`] ? data[`pd_OR_QT_${index}`] : row.pd_OR_QT}
              onChange={(e) => onchangeFormUnitPrice(e,index)}
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <Dropdown style={{ width: "7rem" }}
              id={`pd_TR_UM_${index}`}
              name={`pd_TR_UM_${index}`}
              value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
              options={pd_TR_UMOptions}
              onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
              placeholder="Select UM"
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td> 
            <InputText disabled style={{width:"10rem"}}
              id={`pd_LS_PR_${index}`}
              name={`pd_LS_PR_${index}`}
              value={data[`pd_LS_PR_${index}`] ? data[`pd_LS_PR_${index}`] : row.pd_LS_PR}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td> 
            <InputText disabled style={{width:"10rem"}}
              id={`std_COST_${index}`}
              name={`std_COST_${index}`}
              value={data[`std_COST_${index}`] ? data[`std_COST_${index}`] : row.std_COST}
              onChange={(e) => onchangedata(e)}
            />
            </td>
          
            <td>
            <InputText style={{width:"10rem"}}
              id={`pd_BS_UP_${index}`}
              name={`pd_BS_UP_${index}`}
              defaultValue={data[`pd_BS_UP_${index}`] ? data[`pd_BS_UP_${index}`] : row.pd_BS_UP}
              onChange={(e) => onchangeFormUnitPrice(e,index)}
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <InputText  disabled style={{width:"10rem"}}
              id={`pd_OR_AT_${index}`}
              name={`pd_OR_AT_${index}`}
              value={data[`pd_OR_AT_${index}`] ? data[`pd_OR_AT_${index}`] : row.pd_OR_AT}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
              <InputText
              style={{ width: "10rem" }}
              id={`pd_DS_PR_${index}`}
              name={`pd_DS_PR_${index}`}
              value={data[`pd_DS_PR_${index}`] ? data[`pd_DS_PR_${index}`] : row.pd_DS_PR}
              onChange={(e) => onchangeFormUnitPrice(e,index)}
              disabled={cancelFlag || isProtected}
              />
              </td>
              <td>
              <InputText disabled
                style={{ width: "10rem" }}
                id={`pd_DS_AM_${index}`}
                name={`pd_DS_AM_${index}`}
                value={data[`pd_DS_AM_${index}`] ? data[`pd_DS_AM_${index}`] : row.pd_DS_AM}
              />
              </td>
              <td>
              <InputText disabled
                style={{ width: "10rem" }}
                id={`net_AMOUNT_${index}`}
                name={`net_AMOUNT_${index}`}
                value={data[`net_AMOUNT_${index}`] ? data[`net_AMOUNT_${index}`] : row.net_AMOUNT}
              />
              </td>    
              <td>
            <Dropdown style={{ width: "7rem" }}
                    id={`pd_TX_AP_${index}`}
                    name={`pd_TX_AP_${index}`} 
                    value={data[`pd_TX_AP_${index}`] ? data[`pd_TX_AP_${index}`] : row.pd_TX_AP} 
                    options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                    ]}
                    onChange={(e) => handleFormDataChange3(`pd_TX_AP_${index}`, e.value)} 
                    placeholder="Select Tax Applicable "
                    disabled={cancelFlag || isProtected}
                    />
            </td>
            <td>
            <InputText disabled style={{width:"10rem"}}
              id={`pd_TX_PE_${index}`}
              name={`pd_TX_PE_${index}`}
              value={data[`pd_TX_PE_${index}`] ? data[`pd_TX_PE_${index}`] : row.pd_TX_PE}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"10rem"}}
              id={`pd_TX_AM_${index}`}
              name={`pd_TX_AM_${index}`}
              value={data[`pd_TX_AM_${index}`] ? data[`pd_TX_AM_${index}`] : row.pd_TX_AM}
              onChange={(e) => onchangedata(e)}
            />
            </td>         
            <td>
            <InputText style={{width:"10rem"}}
              id={`pd_FR_UP_${index}`}
              name={`pd_FR_UP_${index}`}
              value={data[`pd_FR_UP_${index}`] ? data[`pd_FR_UP_${index}`] : row.pd_FR_UP}
              onChange={(e) => onchangeFormUnitPrice(e,index)}
              disabled={cancelFlag || isProtected}
            />
            </td>

            <td>
            <InputText   disabled style={{width:"10rem"}}
              id={`pd_FR_AT_${index}`}
              name={`pd_FR_AT_${index}`}
              value={data[`pd_FR_AT_${index}`] ? data[`pd_FR_AT_${index}`] : row.pd_FR_AT}
              onChange={(e) => onchangedata(e)}
            />
            </td>
            <td>
          <InputText disabled
            style={{ width: "8rem" }}
            id={`pd_FR_DS_${index}`}
            name={`pd_FR_DS_${index}`}
            value={data[`pd_FR_DS_${index}`] ? data[`pd_FR_DS_${index}`] : row.pd_FR_DS}
            // onChange={(e) => onchangedata(e)}
            readOnly
          />
        </td>
        <td>
          <InputText disabled
            style={{ width: "7rem" }}
            id={`fr_NET_AMOUNT_${index}`}
            name={`fr_NET_AMOUNT_${index}`}
            value={data[`fr_NET_AMOUNT_${index}`] ? data[`fr_NET_AMOUNT_${index}`] : row.fr_NET_AMOUNT}
            readOnly
          />
        </td>
        <td>
          <InputText 
            style={{ width: "10rem" }}
            id={`pd_LD_CT_${index}`}
            name={`pd_LD_CT_${index}`}
            value={data[`pd_LD_CT_${index}`] ? data[`pd_LD_CT_${index}`] : row.pd_LD_CT}
            onChange={(e) => onchangedata(e)}
          />
        </td>
        <td>
            <Dropdown style={{ width: "8rem" }}
              id={`pd_LO_CN_${index}`}
              name={`pd_LO_CN_${index}`}
              value={ data[`pd_LO_CN_${index}`] ? data[`pd_LO_CN_${index}`]: row.pd_LO_CN}
              options={pd_LO_CNOptions}
              onChange={(e) =>LocationDropdown(`pd_LO_CN_${index}`, e.value)}
              placeholder="Select Location"
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <td style={{ display: "flex" }}>
              <InputText
                style={{ width: "12rem" }}
                id={`pd_LT_NO_${index}`}
                name={`pd_LT_NO_${index}`}
                // defaultValue={pd_IT_NM || row.pd_IT_NM}
                onChange={(e) =>  OnchangeTableData('pd_LT_NO',e.value,index)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.pd_LT_NO ? row.pd_LT_NO: row[`${"pd_LT_NO"}_${index}`]}
                disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "15%",
                  cursor: "pointer",
                }}
                onClick={(e) => openStockAvailabilityPopup(index)}
              ></i>
              )}
            </td>
            {/* <Dropdown
              id={`pd_LT_NO_${index}`}
              name={`pd_LT_NO_${index}`}
              value={ data[`pd_LT_NO_${index}`] ? data[`pd_LT_NO_${index}`]: row.pd_LT_NO}
              options={pd_LT_NOOptions}
              onChange={(e) =>LOTNoDropdown(`pd_LT_NO_${index}`, e.value)}
              placeholder="Select LOT No"
              disabled={cancelFlag || isProtected}
            /> */}
            </td>
            <td>
            <Dropdown style={{ width: "8rem" }}
            id={`returnable_${index}`}
            name={`returnable_${index}`} 
            value={data[`returnable_${index}`] ? data[`returnable_${index}`] : row.returnable} 
            options={[
            { label: "Yes", value: "y" },
            { label: "No", value: "n" },
            ]}
            onChange={(e) => ReturnableDropdown(`returnable_${index}`, e.value)} 
            placeholder="Select Returnable "
            disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <Calendar style={{width:"10rem"}}
            id={`pd_RQ_DT_${index}`}
            name={`pd_RQ_DT_${index}`}
            value={row.pd_RQ_DT? moment(row.pd_RQ_DT, "YYYYMMDD").toDate(): null}
            onChange={(e) => OnchangeTableData('pd_RQ_DT',moment(e.value).format("YYYYMMDD"),index)}
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <Calendar style={{width:"10rem"}}
            id={`pd_PR_DT_${index}`}
            name={`pd_PR_DT_${index}`}
            value={row.pd_PR_DT? moment(row.pd_PR_DT, "YYYYMMDD").toDate(): null}
            onChange={(e) => OnchangeTableData('pd_PR_DT',moment(e.value).format("YYYYMMDD"),index)}
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <InputText style={{width:"10rem"}}
              id={`pd_SC_QT_${index}`}
              name={`pd_SC_QT_${index}`}
              value={data[`pd_SC_QT_${index}`] ? data[`pd_SC_QT_${index}`] : row.pd_SC_QT}
              onChange={(e) => onchangedata(e)}
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <Dropdown style={{ width: "7rem" }}
              id={`pd_SC_UM_${index}`}
              name={`pd_SC_UM_${index}`}
              value={ data[`pd_SC_UM_${index}`] ? data[`pd_SC_UM_${index}`]: row.pd_SC_UM}
              options={pd_SC_UMOptions}
              onChange={(e) =>handleFormDataChange1(`pd_SC_UM_${index}`, e.value)}
              placeholder="Select UM"
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <InputText style={{width:"10rem"}}
              id={`pd_CN_QT_${index}`}
              name={`pd_CN_QT_${index}`}
              value={data[`pd_CN_QT_${index}`] ? data[`pd_CN_QT_${index}`] : row.pd_CN_QT}
              onChange={(e) => onchangedata(e)}
              disabled={!cancelFlag || isProtected}
            />
            </td>
            <td>
            <Calendar 
            style={{width:"10rem"}}
            id={`pd_CN_DT_${index}`}
            name={`pd_CN_DT_${index}`}
            value={currentDate}
            onChange={(e) => {setCurrentDate(e.value);
            onDateChange("pd_CN_DT", index, e.value);
            }}
            dateFormat="dd/mm/yy"
            disabled={!cancelFlag || isProtected}
            />
            </td>
            <td>
              <InputText 
                style={{ width: "7rem" }}
                id={`pd_RL_NO_${index}`}
                name={`pd_RL_NO_${index}`}
                value={data[`pd_RL_NO_${index}`] ? data[`pd_RL_NO_${index}`] : row.pd_RL_NO}
                onChange={(e) => onchangedata(e)}
              />
            </td>
            <td>
            <Dropdown style={{ width: "8rem" }}
              id={`pd_RL_TY_${index}`}
              name={`pd_RL_TY_${index}`}
              value={ data[`pd_RL_TY_${index}`] ? data[`pd_RL_TY_${index}`]: row.pd_RL_TY}
              options={pd_RL_TYOptions}
              onChange={(e) =>TypeDropdown(`pd_RL_TY_${index}`, e.value)}
              placeholder="Select Rel. Order Type"
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <Dropdown style={{ width: "7rem" }}
              id={`pd_RL_CO_${index}`}
              name={`pd_RL_CO_${index}`}
              value={ data[`pd_RL_CO_${index}`] ? data[`pd_RL_CO_${index}`]: row.pd_RL_CO}
              options={pd_RL_COOptions}
              onChange={(e) =>CompanyDropdown(`pd_RL_CO_${index}`, e.value)}
              placeholder="Select Company"
              disabled={cancelFlag || isProtected}
            />
            </td>
            <td>
            <InputText disabled
            style={{ width: "7rem" }}
            id={`pd_ST_CD_${index}`}
            name={`pd_ST_CD_${index}`}
            value={ row.pd_ST_CD ? row.pd_ST_CD : pd_ST_CD}
            readOnly
            />
          </td>
          <td>
            <InputText disabled
            style={{ width: "7rem" }}
            id={`pd_CO_CT_${index}`}
            name={`pd_CO_CT_${index}`}
            value={pd_CO_CT}
            />
          </td>
            </tr>
            ))}
        </tbody>
      </table>   
      </div>
        </div>  
        <div style={inboxstyle} >
          <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                value={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="discount_AMOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="discount_AMOUNT"
                name="discount_AMOUNT"
                value={discount_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="net_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="net_AMOUNT"
                name="net_AMOUNT"
                value={net_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                value={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="TOTAL" className="font-bold">
                Total
              </label>
              <InputText disabled
                id="total"
                name="total"
                value={total}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Fore.Amount
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                value={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_DISCOUNT_AMOUNT" className="font-bold">
                Fore.Discount
              </label>
              <InputText disabled
                id="fr_DISCOUNT_AMOUNT"
                name="fr_DISCOUNT_AMOUNT"
                value={fr_DISCOUNT_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_NET_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="fr_NET_AMOUNT"
                name="fr_NET_AMOUNT"
                value={fr_NET_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="FR_tax_AMOUNT" className="font-bold">
                Fore.Tax
              </label>
              <InputText disabled
                id="fr_tax_AMOUNT"
                name="fr_tax_AMOUNT"
                value={fr_tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TOTAL" className="font-bold">
                Fore.Total
              </label>
              <InputText disabled
                id="fr_TOTAL"
                name="fr_TOTAL"
                value={fr_TOTAL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
          </TabPanel>
          <TabPanel header="Landed Cost">
          <div style={inboxstyle} >
            <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_AL_TY" className="font-bold">
                    Allocation Type
                  </label>
                  <InputText disabled
                    id="ph_AL_TY"
                    name="ph_AL_TY"
                    value={ph_AL_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                
              </div>
              <div  style={tablestyle} >
                <div className="table-buttons" style={tablebutton} >
                  {(
                  <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                  )}
                  {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                  )}
                </div>
                <div  style={multitablestyle}>
            <table  className="custom-table" id="PurchaseOrdertable1" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th>Landed Item</th>
              <th>Description </th>
              <th>Applied</th>
              <th>Landed Cost</th>
              <th>Tax Applicable</th>
              <th>Tax %</th>
              <th>Tax Amount</th>
              <th>Currency</th>
              <th>Pay to Supplier</th>
              <th style={{ display: "none" }}>Supplier</th>
              <th>Supplier <span style={{ color: 'red', }}>*</span></th>
            </tr>
          </thead>
          <tbody>
          {PurchaseOrdertable1.map((row, index) => (
                  <tr key={index}>
                <td style={{ display: "none" }} >
                <InputText
                  id={`pdUkNo_${index}`}
                  name={`pdUkNo_${index}`}
                  defaultValue={row.pdUkNo}
                />
              </td>
              <td>
              <InputText disabled style={{width:"15rem"}}
                id={`item_NAME_${index}`}
                name={`item_NAME_${index}`}
                defaultValue={row.item_NAME}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"15rem"}}
                id={`description_${index}`}
                name={`description_${index}`}
                value={data[`description_${index}`] ? data[`description_${index}`] : row.description}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              <td>
              <Dropdown style={{width:"10rem"}}
              id={`applied_${index}`}
              name={`applied_${index}`} 
              value={data[`applied_${index}`] ? data[`applied_${index}`] : row.applied} 
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => AppliedDropDown(`applied_${index}`, e.value)} 
              placeholder="Select Applied Y/N "
              disabled={cancelFlag || isProtected}
              />
              </td>
              <td>
              <InputText  style={{width:"10rem"}}
                id={`cost_AMOUNT_${index}`}
                name={`cost_AMOUNT_${index}`}
                value={data[`cost_AMOUNT_${index}`] ? data[`cost_AMOUNT_${index}`] : row.cost_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown style={{width:"12rem"}}
              id={`tax_APPLICABLE_${index}`}
              name={`tax_APPLICABLE_${index}`} 
              value={data[`tax_APPLICABLE_${index}`] ? data[`tax_APPLICABLE_${index}`] : row.tax_APPLICABLE} 
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => TaxDropDown(`tax_APPLICABLE_${index}`, e.value)} 
              placeholder="Select Tax Y/N "
              disabled={cancelFlag || isProtected}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`tax_PERCENTAGE_${index}`}
                name={`tax_PERCENTAGE_${index}`}
                value={data[`tax_PERCENTAGE_${index}`] ? data[`tax_PERCENTAGE_${index}`] : row.tax_PERCENTAGE}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`tax_AMOUNT_${index}`}
                name={`tax_AMOUNT_${index}`}
                value={data[`tax_AMOUNT_${index}`] ? data[`tax_AMOUNT_${index}`] : row.tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown disabled style={{width:"10rem"}}
                id={`currency_${index}`}
                name={`currency_${index}`}
                value={ data[`currency_${index}`] ? data[`currency_${index}`]: row.currency}
                options={currencyOptions}
                onChange={(e) =>CurrencyDropDown(`currency_${index}`, e.value)}
                placeholder="Select Currency"
              />
              </td>
              <td>
              <Dropdown style={{width:"10rem"}}
              id={`pay_TO_SUPPLIER_${index}`}
              name={`pay_TO_SUPPLIER_${index}`} 
              value={data[`pay_TO_SUPPLIER_${index}`] ? data[`pay_TO_SUPPLIER_${index}`] : row.pay_TO_SUPPLIER} 
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => SupplierDropDown(`pay_TO_SUPPLIER_${index}`, e.value)} 
              placeholder="Select Pay to Supplier "
              disabled={cancelFlag || isProtected}
              />
              </td>
              <td style={{ display: "none" }}> 
              <InputText 
              id={`supplier_${index}`}
              name={`supplier_${index}`}
              value={row.supplier ? row.supplier: data[`${"supplier"}_${index}`]}
              onChange={(e) =>  OnchangeTableData1('supplier',e.value,index)}
              disabled={cancelFlag || isProtected}
              />
              </td>
              <td>
              <div style={{ display: "flex",width:"18rem"}}>
              <InputText 
              id={`name_${index}`}
              name={`name_${index}`}
              onFocus={() => setShowSearchIcon(true)}
              onChange={(e) =>  OnchangeTableData1('name',e.value,index)}
              disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "7%",
              cursor: "pointer",
              }}
              onClick={(e) => openSupplierPopup(index)}
              ></i>
              )}
              </div>
              </td>
              
              </tr>
              ))}
          </tbody>
            </table>  
            </div>
          </div> 
          </div> 
          </TabPanel>
        </TabView>
      </div> 
      {/* supplier h */}
      <AddressBookPopup
        visible={SupplierhPopupVisible}
        onClose={() => setSupplierhPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData={setFormData}
      />
      {/* shipto */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeship}
        setFormData={setFormData}
      />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      {/* supplier */}
      <AddressBookPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        onDataSelect={handlesupplier}
        formData={data}
        addressType={Addresstype}
        setFormData={setFormData}
      />
      <StockAvailabilityPopup
        visible={StockAvailabilityPopupVisible}
        onClose={() => setStockAvailabilityPopupVisible(false)}
        onDataSelect={handleStockAvailability}
        formData={data}
      />
    </Dialog>
  );
}
