import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
 
export default function PatientQuestionnaireDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  operationtabledata,
  setoperationtabledata,
  pm_CO_NO,
}) {
  const {
    pmUkNo,
  } = data;
  const addRow = () => {
    setoperationtabledata([...operationtabledata, {}]);
  };
  
  const deleteRow = () => {
    if (operationtabledata.length > 0) {
      const updatedData = [...operationtabledata];
      updatedData.pop();
      setoperationtabledata(updatedData);
    }
  };
const isUpdateMode = Boolean(pmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={pmUkNo ? "Revise Patient Questionnaire" : "Add Patient Questionnaire"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th style={{ display: "none" }}>Company</th>
              <th>Seq No</th>
              <th>Questions</th>
            </tr>
          </thead>
          <tbody>
            {operationtabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`pmUkNo_${index}`}
                    name={`pmUkNo_${index}`}
                    value={row.pmUkNo}
                  />
                </td>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`pm_CO_NO_${index}`}
                    name={`pm_CO_NO_${index}`}
                    value={pm_CO_NO}
                  />
                </td>
                <td>
                  <InputText 
                    id={`pm_SQ_NO_${index}`}
                    name={`pm_SQ_NO_${index}`}
                    defaultValue={row.pm_SQ_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText
                    id={`pm_PA_QA_${index}`}
                    name={`pm_PA_QA_${index}`}
                    defaultValue={row.pm_PA_QA}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 

    </Dialog>
  );
}
