import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import Costcenter from "views/common/CostCentreDrpDwn";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
import '../../common/TabStyles.css';
export default function LabTestResultDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  LabTestResultTableData,
  setLabTestResultTableData,
  lr_PA_FA,
  setlr_PA_FA,
  pl_CO_NO,
  pl_CO_CT,
  setpl_CO_CT,
}) {
  const {
    plUkNo,
    pl_VI_NO,
    pl_VI_DT,
    pl_PA_ID,
   
    pl_LB_NM,
   } = data;

  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [pl_CO_CTOptions, setpl_CO_CTOptions] = useState([]);
  const [lr_PA_FAOptions, setlr_PA_FAOptions] = useState([]);

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlr_PA_FA(value);
  };

  useEffect(() => {
    FetchDropdownCostCenter();
    FetchDropdownPassFail();
  }, []);

  const FetchDropdownCostCenter = async () => {
    const Options = await fetchCostCenter();
    setpl_CO_CTOptions(Options);
  };
    
  const FetchDropdownPassFail = async () => {
    const Options = await fetchClassCodeDS("LB/PF");
    setlr_PA_FAOptions(Options);
  };

  const addRow = () => {
    setLabTestResultTableData([...LabTestResultTableData, {}]);
  };
  const deleteRow = () => {
    if (LabTestResultTableData.length > 0) {
      const updatedData = [...LabTestResultTableData];
      updatedData.pop();
      setLabTestResultTableData(updatedData);
    }
  };
// advance searchs

 
  // DIALOG HEADER
  const isUpdateMode = Boolean(plUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{plUkNo ? "Revise Detail Price Breakup" : "Add Detail Price Breakup"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div  style={inboxstyle}>
          <div className="formgrid grid">
            
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pl_VI_NO" className="font-bold">
            Visit No
            </label>
            <InputText disabled
              id="pl_VI_NO"
              name="pl_VI_NO"
              defaultValue={pl_VI_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pl_VI_DT" className="font-bold">
            Visit Date
            </label>
            <Calendar
              id="pl_VI_DT"
              name="pl_VI_DT"
              value={moment(pl_VI_DT, "YYYYMMDD").toDate()}
              onChange={onDateChange}
              dateFormat="dd/mm/yy"
              disabled
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pl_PA_ID" className="font-bold">
            Patient ID
            </label>
            <InputText disabled
              id="pl_PA_ID"
              name="pl_PA_ID"
              defaultValue={pl_PA_ID}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pl_CO_NO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="pl_CO_NO"
              name="pl_CO_NO"
              defaultValue={pl_CO_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pl_LB_NM" className="font-bold">
            Lab Test Name
            </label>
            <InputText disabled
              id="pl_LB_NM"
              name="pl_LB_NM"
              defaultValue={pl_LB_NM}
              onChange={(e) => onchangedata(e)}
            />
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="pl_CO_CT" className="font-bold">
                Cost Centre
                </label>
                  <Dropdown
                    id="pl_CO_CT"
                    name="pl_CO_CT"
                    value={pl_CO_CT}
                    options={pl_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData1("pl_CO_CT", e.value)}
                    placeholder="	Cost Centre"
                    disabled
                  />
              </div>
          </div>
        </div>  
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="LabTestResulttable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Test ID</th>
                <th>Description</th>
                <th>Minimum</th>
                <th>Maximum</th>
                <th>Target</th>
                <th>Result</th>
                <th>Pass/Fail</th>
                <th>Sample Size</th>
                <th>Defect</th>
                <th>Cause</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody>
              {LabTestResultTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`lrUkNo_${index}`}
                      name={`lrUkNo_${index}`}
                      value={row.plUkNo}
                    />
                  </td>
                  <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_TS_ID_${index}`}
                          name={`lr_TS_ID_${index}`}
                          value={row.lr_TS_ID}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_TS_DS_${index}`}
                          name={`lr_TS_DS_${index}`}
                          value={row.lr_TS_DS}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_AL_MI_${index}`}
                          name={`lr_AL_MI_${index}`}
                          value={row.lr_AL_MI}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_AL_MX_${index}`}
                          name={`lr_AL_MX_${index}`}
                          value={row.lr_AL_MX}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_TR_VL_${index}`}
                          name={`lr_TR_VL_${index}`}
                          value={row.lr_TR_VL}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_RS_VL_${index}`}
                          name={`lr_RS_VL_${index}`}
                          value={row.lr_RS_VL}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                      <td >
                      <Dropdown style={{width:'10rem'}} 
                        id={`lr_PA_FA_${index}`}
                        name={`lr_PA_FA_${index}`}
                        value={ data[`lr_PA_FA_${index}`] ? data[`lr_PA_FA_${index}`]: row.lr_PA_FA}
                        options={lr_PA_FAOptions}
                        onChange={(e) =>onchangeDropDownFormData2(`lr_PA_FA_${index}`, e.value)}
                        placeholder="Pass/Fail"
                        disabled
                      />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_SA_SZ_${index}`}
                          name={`lr_SA_SZ_${index}`}
                          value={row.lr_SA_SZ}
                          onChange={(e) => onchangedata(e)}
                          
                        />
                      </td>
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_DE_CT_${index}`}
                          name={`lr_DE_CT_${index}`}
                          value={row.lr_DE_CT}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>  
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_CU_SE_${index}`}
                          name={`lr_CU_SE_${index}`}
                          value={row.lr_CU_SE}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>  
                      <td >
                        <InputText style={{width:'10rem'}}
                          id={`lr_RM_RK_${index}`}
                          name={`lr_RM_RK_${index}`}
                          value={row.lr_RM_RK}
                          onChange={(e) => onchangedata(e)}
                          disabled
                        />
                      </td>                    
                     
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
