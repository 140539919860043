import React, { useState,useEffect } from   "react";
import axios from "axios";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";
import "./Sidebar.css";


  function Sidebar(props) {
    const { routes } = props;
    let variantChange = "0.2s linear";
    let shadow = useColorModeValue(
      "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
      "unset"
    );
    let sidebarBg = useColorModeValue("white", "navy.800");
    let sidebarMargins = "0px";

    const token = localStorage.getItem("token");
    const domain = localStorage.getItem("domain");
    const company = localStorage.getItem("company");

    const [brandName, setbrandName] = useState("");
    useEffect(() => {
      const GetBrandName = async () => {
        try {
          const response = await axios.get(
            // `${domain}/api/ClientMaster/Get`,
            `${domain}/api/CompanyMaster/Get`,
            {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
          const data = response.data;
          if (data.co_PR_NM) {
            setbrandName(data.co_PR_NM);
          } else {
            console.error("Invalid API response:", data);
          }

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      GetBrandName();
    }, []); 
    return (
      
      <Box className="SideBar" display={{ sm: "none", xl: "block" }} w="100%" position='fixed' minH='100%'>
        <Box className="Bar" style={{
          backgroundImage: "linear-gradient(to left top, #dfe5f780, #afccff7d, #bbccf300, #c8daf9b8)", 
          color: "white !important",width:"15%",overflow:"hidden",marginTop:'2rem'}}
          bg={sidebarBg}
          transition={variantChange}
          h='100vh'
          m={sidebarMargins}
          minH='100%'
          overflowX='hidden'
          boxShadow={shadow}>
            
            <div className="BrandName" style={{
              fontFamily:"sans-serif",
              fontSize:"25px",
              color:"white",
              padding:"5px",
              // backgroundImage:"linear-gradient(to left, #8dcdff, #787cfd75)",
              backgroundColor: "#5edbf55e",
              // height:"6%",
              // marginTop:"7px",
            }}
            >
              <h1 
              className="Brand"
              style={{ marginLeft:"1%",color:'#1b254b',fontWeight:'bold'}}>
                {brandName}
              </h1>
            </div>

          <Scrollbars
            renderView={renderView}>
            <Content routes={routes} />
          </Scrollbars>
        </Box>
      </Box>
    );
  }
  export function SidebarResponsive(props) {
    let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
    let menuColor = useColorModeValue("gray.400", "white");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleItemClick = () => {
      if (isOpen) {
        onClose(); // Close the sidebar when an item is clicked
      }
    };
    const btnRef = React.useRef();
    const { routes } = props;
    return (
      <div className="SideBarResponsive">
      <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
        <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
          <Icon
            as={IoMenuOutline}
            color={menuColor}
            my='auto'
            w='20px'
            h='20px'
            me='10px'
            _hover={{ cursor: "pointer" }}
          />
        </Flex>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          placement={document.documentElement.dir === "rtl" ? "right" : "left"}
          finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent w='285px' maxW='285px' bg={sidebarBackgroundColor}>
            <DrawerCloseButton
              zIndex='3'
              onClose={onClose}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
            />
            <DrawerBody maxW='285px' px='0rem' pb='0'>
              <Scrollbars
                renderView={renderView}>
                <Content routes={routes} onItemClick={handleItemClick} />
              </Scrollbars>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
      </div>
    );
  }
  Sidebar.propTypes = {
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.string,
  };

  export default Sidebar;
