import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import axios from "axios";
export default function PutawayRequestDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  PutawayRequestTableData,
  setPutawayRequestTableData,
  pr_LO_CN,
  setpr_LO_CN,
  pr_RQ_QT,
  setpr_RQ_QT,
  setpr_OR_TY,
  pr_CO_CT,
  setpr_CO_CT,
  setpr_TR_UM,
  setpr_AC_LO,
  setpr_LT_NO,
  setpr_TO_LO,
  pr_OP_QT,
  setpr_OP_QT,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  handleConfirm,
}) {
  const {
    prUkNo,
    pr_DO_NO,
    pr_DO_TY,
    pr_DO_CO,
    pr_OR_NO,
    pr_OR_TY,
    pr_OR_CO,
    pr_LT_NO,
    pr_LN_NO,
  } = data;

  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [pr_CO_CTOptions, setpr_CO_CTOptions] = useState([]);
  const [pr_LO_CNOptions, setpr_LO_CNOptions] = useState([]);
  const [pr_RQ_QTOptions, setpr_RQ_QTOptions] = useState([]);
  const [pr_OP_QTOptions, setpr_OP_QTOptions] = useState([]);
  const [pr_TO_LOOptions, setpr_TO_LOOptions] = useState([]);
  const [pr_AC_LOOptions, setpr_AC_LOOptions] = useState([]);
  const [pr_LT_NOOptions, setpr_LT_NOOptions] = useState([]);
  const [pr_TR_UMOptions, setpr_TR_UMOptions] = useState([]);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Expense Type
  const CostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_CO_CT(value);
  };

  // Advance Curr
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_LO_CN(value);
  };

  const ToLocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_TO_LO(value);
  };
  // SuggestedLocation
  const SuggestedLocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_AC_LO(value);
  };

  // Reim Curr
  const ReqQuantityDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_RQ_QT(value);
  };

  // Status
  const OpenQuantityDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_OP_QT(value);
  };

  // Expense Category
  const OrdertypeDropdown = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_OR_TY(value);
  };



  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_TR_UM(value);
  };

  const SuggestedQtyUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_TR_UM(value);
  };
  const LotDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_LT_NO(value);
  };

  useEffect(() => {
    ReimCurrency();
    Status();

    CostCenter();
    UM();
    Location();
    ToLocation();
    SuggestedQtyUM();
    Lot();
    SuggestedLocation();

  }, []);



  const ReimCurrency = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_RQ_QTOptions(Options);
  };



  const Status = async () => {
    const Options = await fetchClassCodeDS("EX/ST");
    setpr_OP_QTOptions(Options);
  };


  const Location = async () => {
    const Options = await fetchClassCodeCD("EX/LN");
    setpr_LO_CNOptions(Options);
  };
  const SuggestedLocation = async () => {
    const Options = await fetchClassCodeCD("EX/LN");
    setpr_AC_LOOptions(Options);
  };

  const ToLocation = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setpr_TO_LOOptions(Options);
  };

  const CostCenter = async () => {
    const Options = await fetchCostCenter();
    setpr_CO_CTOptions(Options);
  };



  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_TR_UMOptions(Options);
  };
  const Lot = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_LT_NOOptions(Options);
  };
  const SuggestedQtyUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setpr_TR_UMOptions(Options);
  };

  // Advance Search
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem = async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("pr_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };


  const addRow = () => {
    setPutawayRequestTableData([...PutawayRequestTableData, {}]);
  };

  const deleteRow = () => {
    if (PutawayRequestTableData.length > 0) {
      const updatedData = [...PutawayRequestTableData];
      updatedData.pop();
      setPutawayRequestTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(prUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );
  
  const onchangeFormSuggestedQty = async(e,index) => {
    onchangedata(e);
    onchangeSuggestedQty(index);
  };

  const onchangeSuggestedQty = async (SelectedRowIndex) => {
    const UrlPrice = `${domain}/api/PutawayRequest/CalculateOpenQuantity`;
    const RequestBody = {

      "putawayRequestList": [
        {
          "pr_OR_QT": document.getElementById(`pr_OR_QT_${SelectedRowIndex}`).value,
          "pr_SU_QT": document.getElementById(`pr_SU_QT_${SelectedRowIndex}`).value,

        }
      ]
    }
    try {
      const response = await axios.post(UrlPrice, RequestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt) {
        document.getElementById("pr_OP_QT").value = dataItMt.pr_OP_QT;
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }





  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{prUkNo ? "Revise Putaway Request" : "Add Putaway Request"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />

        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
        <Button
          style={{ border: "none", backgroundColor: 'transparent', color: 'blue' }}
          icon="pi pi-check-circle"
          onClick={handleConfirm}
          tooltip="Confrim"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_DO_NO" className="font-bold">
                Doc No
              </label>
              <InputText disabled
                id="pr_DO_NO"
                name="pr_DO_NO"
                defaultValue={pr_DO_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_DO_TY" className="font-bold">
                Doc Type
              </label>
              <InputText disabled
                id="pr_DO_TY"
                name="pr_DO_TY"
                defaultValue={pr_DO_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_DO_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="pr_DO_CO"
                name="pr_DO_CO"
                defaultValue={pr_DO_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_OR_NO" className="font-bold">
                Order No
              </label>
              <InputText disabled
                id="pr_OR_NO"
                name="pr_OR_NO"
                defaultValue={pr_OR_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_OR_TY" className="font-bold">
                Order Type
              </label>
              <InputText disabled
                id="pr_OR_TY"
                name="pr_OR_TY"
                defaultValue={pr_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="pr_OR_CO"
                name="pr_OR_CO"
                defaultValue={pr_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown disabled
                id="pr_CO_CT"
                name="pr_CO_CT"
                value={pr_CO_CT}
                options={pr_CO_CTOptions}
                onChange={(e) => CostCentreDropdown("pr_CO_CT", e.value)}
                placeholder=" Cost Center"
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_IT_NM" className="font-bold">
                Item No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="pr_IT_NM"
                  name="pr_IT_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedItem}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="itemDES" className="font-bold">
                Description
              </label>
              <InputText disabled
                id="itemDES"
                name="itemDES"
                value={SelectedDesc}
                readOnly
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_LO_CN" className="font-bold">
                Location
              </label>
              <Dropdown disabled
                name="pr_LO_CN"
                value={pr_LO_CN}
                options={pr_LO_CNOptions}
                onChange={(e) => LocationDropdown("pr_LO_CN", e.value)}
                placeholder="Select Location"
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RQ_QT" className="font-bold">
                Req Quantity
              </label>
              <Dropdown disabled
                name="pr_RQ_QT"
                value={pr_RQ_QT}
                options={pr_RQ_QTOptions}
                onChange={(e) => ReqQuantityDropdown("pr_RQ_QT", e.value)}
                placeholder="Select Req Quantity"
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_OP_QT" className="font-bold">
                Open Quantity
              </label>
              <Dropdown disabled
                name="pr_OP_QT"
                value={pr_OP_QT}
                options={pr_OP_QTOptions}
                onChange={(e) => OpenQuantityDropdown("pr_OP_QT", e.value)}
                placeholder="Select Open Quantity"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_LT_NO" className="font-bold">
                Lot Number
              </label>
              <InputText disabled
                id="pr_LT_NO"
                name="pr_LT_NO"
                defaultValue={pr_LT_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="operationtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Line No</th>
                  <th>Quantity</th>
                  <th>UM</th>
                  <th>To Location</th>
                  <th>Suggested Location</th>
                  <th>Suggested Qty</th>
                  <th>UM</th>

                </tr>
              </thead>
              <tbody>
                {PutawayRequestTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`prUkNo_${index}`}
                        name={`prUkNo_${index}`}
                        value={row.prUkNo}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "120px" }}
                        id={`pr_LN_NO_${index}`}
                        name={`pr_LN_NO_${index}`}
                        defaultValue={row.pr_LN_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{ width: "120px" }}
                        id={`pr_OR_QT_${index}`}
                        name={`pr_OR_QT_${index}`}
                        defaultValue={row.pr_OR_QT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>

                    <td>
                      <Dropdown disabled
                        id={`pr_TR_UM_${index}`}
                        name={`pr_TR_UM_${index}`}
                        value={data[`pr_TR_UM_${index}`] ? data[`pr_TR_UM_${index}`] : row.pr_TR_UM}
                        options={pr_TR_UMOptions}
                        onChange={(e) => UMDropdown(`pr_TR_UM_${index}`, e.value)}
                        placeholder="UM"
                      />
                    </td>


                    <td>
                      <Dropdown disabled
                        id={`pr_TO_LO_${index}`}
                        name={`pr_TO_LO_${index}`}
                        value={data[`pr_TO_LO_${index}`] ? data[`pr_TO_LO_${index}`] : row.pr_TO_LO}
                        options={pr_TO_LOOptions}
                        onChange={(e) => ToLocationDropdown(`pr_TO_LO_${index}`, e.value)}
                        placeholder="Location"
                      />
                    </td>
                    <td>
                      <Dropdown 
                        id={`pr_AC_LO_${index}`}
                        name={`pr_AC_LO_${index}`}
                        value={data[`pr_AC_LO_${index}`] ? data[`pr_AC_LO_${index}`] : row.pr_AC_LO}
                        options={pr_AC_LOOptions}
                        onChange={(e) => SuggestedLocationDropdown(`pr_AC_LO_${index}`, e.value)}
                        placeholder="Suggested Location"
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "120px" }}
                        id={`pr_SU_QT_${index}`}
                        name={`pr_SU_QT_${index}`}
                        defaultValue={row.pr_SU_QT}
                        onChange={(e) => onchangeFormSuggestedQty(e,index)}
                  
                      />
                    </td>

                    <td>

                      <Dropdown disabled style={{ width: "150px" }}
                        id={`pr_TR_UM_${index}`}
                        name={`pr_TR_UM_${index}`}
                        value={data[`pr_TR_UM_${index}`] ? data[`pr_TR_UM_${index}`] : row.pr_TR_UM}
                        options={pr_TR_UMOptions}
                        onChange={(e) => SuggestedQtyUMDropdown(`pr_TR_UM_${index}`, e.value)}
                        placeholder="UM"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
