import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function VatReceivablesDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  addRow,
  deleteRow,
  VatReceivablestable,
}) {
  const { 
  tdUkNo,
  gl_BA_TY,
  gl_BA_NO,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const isUpdateMode = Boolean(tdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{tdUkNo ? "Revise Error Batch" : "Add Error Batch"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
          <div style={inboxstyle}>
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="gl_BA_NO" className="font-bold">
                      Batch No
                      </label>
                      <InputText disabled
                        id="gl_BA_NO"
                        name="gl_BA_NO"
                        value={gl_BA_NO}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="gl_BA_TY" className="font-bold">
                      Batch Type
                      </label>
                      <InputText disabled
                        id="gl_BA_TY"
                        name="gl_BA_TY"
                        value={gl_BA_TY}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
            </div>
          </div>
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
                {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
              </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="VatReceivablestable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th>Document No</th>
              <th>Document Type</th>
              <th>Company</th>
              <th>GL Date</th>
              <th>Account No</th>
              <th>Description</th>
              <th>Debit Amount</th>
              <th>Credit Amount</th>
              <th>Ledger type</th>
              <th>Post Code</th>
            </tr>
          </thead>
          <tbody>
          {VatReceivablestable.map((row, index) => (
                  <tr key={index}>
                <td style={{ display: "none" }} >
                <InputText
                  id={`tdUkNo_${index}`}
                  name={`tdUkNo_${index}`}
                  defaultValue={row.tdUkNo}
                />
              </td>
                
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`gl_DO_NO_${index}`}
                name={`gl_DO_NO_${index}`}
                defaultValue={row.gl_DO_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`gl_DO_TY_${index}`}
                name={`gl_DO_TY_${index}`}
                defaultValue={row.gl_DO_TY}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`gl_DO_CO_${index}`}
                name={`gl_DO_CO_${index}`}
                defaultValue={row.gl_DO_CO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Calendar disabled style={{width:"150px"}}
                id={`gl_GL_DT_${index}`}
                name={`gl_GL_DT_${index}`}
                value={ row.gl_GL_DT ? moment(row.gl_GL_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "gl_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                showIcon
                dateFormat="dd/mm/yy"
              />
              </td>
              
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`gl_AC_NO_${index}`}
                name={`gl_AC_NO_${index}`}
                defaultValue={row.gl_AC_NO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`acc_DESC_${index}`}
                name={`acc_DESC_${index}`}
                defaultValue={row.acc_DESC}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`debit_AMOUNT_${index}`}
                name={`debit_AMOUNT_${index}`}
                defaultValue={row.debit_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`credit_AMOUNT_${index}`}
                name={`credit_AMOUNT_${index}`}
                defaultValue={row.credit_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`gl_LE_TY_${index}`}
                name={`gl_LE_TY_${index}`}
                defaultValue={row.gl_LE_TY}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`gl_GL_PO_${index}`}
                name={`gl_GL_PO_${index}`}
                defaultValue={row.gl_GL_PO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              </tr>
              ))}
          </tbody>
        </table>   
        
        </div>
          </div>   
      </div>
    </Dialog>
  );
}
