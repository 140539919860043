import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function BudgetVsActualComparisionDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  fb_DO_CO,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOE,
  setSelectedemEMNOE,
}) {
  const {
    fbUkNo,
    fb_CT_RY,
    fb_FI_YR,
    fb_AC_ID,
    fb_OB_AC,
    fb_OB_SB,
    fb_LV_DT,
    fb_BU_AM,
    cu_BU_AM,
    cu_AC_AM,
    cu_VR_AM,
    cu_PR_AM,
    fb_PE_01,
    gb_NP_01,
    fb_PR_01,
    fb_VR_01,
    fb_PE_02,
    gb_NP_02,
    fb_VR_02,
    fb_PR_02,
    fb_PE_03,
    gb_NP_03,
    fb_PR_03,
    fb_VR_03,
    fb_PE_04,
    gb_NP_04,
    fb_PR_04,
    fb_VR_04,
    fb_PE_05,
    gb_NP_05,
    fb_PR_05,
    fb_VR_05,
    fb_PE_06,
    gb_NP_06,
    fb_PR_06,
    fb_VR_06,
    fb_PE_07,
    gb_NP_07,
    fb_PR_07,
    fb_VR_07,
    fb_PE_08,
    gb_NP_08,
    fb_PR_08,
    fb_VR_08,
    fb_PE_09,
    gb_NP_09,
    fb_PR_09,
    fb_VR_09,
    fb_PE_10,
    gb_NP_10,
    fb_PR_10,
    fb_VR_10,
    fb_PE_11,
    gb_NP_11,
    fb_PR_11,
    fb_VR_11,
    fb_PE_12,
    gb_NP_12,
    fb_PR_12,
    fb_VR_12,
    account,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [fb_CT_RYOptions, setfb_CT_RYOptions] = useState([]);
  const [fb_FI_YROptions, setfb_FI_YROptions] = useState([]);
  const [fb_ST_CDOptions, setfb_ST_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
// provider
  const parameter3 = "FA/ST";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);
  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_CT_RYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_FI_YROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const isUpdateMode = Boolean(fbUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{fbUkNo ? "Revise Budget Vs Actual Comparision" : "Add Budget Vs Actual Comparision"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="fb_CT_RY" className="font-bold">
            Century
            </label>
            <Dropdown
              id="fb_CT_RY"
              name="fb_CT_RY"
              value={fb_CT_RY}
              options={fb_CT_RYOptions}
              onChange={(e) => onchangeDropDownFormData1("fb_CT_RY", e.value)}
              placeholder="Select Century"
            />
        </div> 
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="fb_FI_YR" className="font-bold">
            Financial Year
          </label>
          <Dropdown
            id="fb_FI_YR"
            name="fb_FI_YR"
            value={fb_FI_YR}
            options={fb_FI_YROptions}
            onChange={(e) => onchangeDropDownFormData2("fb_FI_YR", e.value)}
            placeholder="Select financial Year"
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="fb_DO_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="fb_DO_CO"
            name="fb_DO_CO"
            value={fb_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="account" className="font-bold">
          Account
          </label>
          <InputText
            id="account"
            name="account"
            value={account}
            onChange={(e) => onchangedata(e)}
          />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="fb_OB_SB" className="font-bold">
          Subscidiary
          </label>
          <InputText
            id="fb_OB_SB"
            name="fb_OB_SB"
            value={fb_OB_SB}
            onChange={(e) => onchangedata(e)}
          />
        </div>
          </div>
        </div>
        <div style={inboxstyle} >
          <Container style={{width:"120%"}}>
        <Row>
          <Col md={2}>
              <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                Period
              </label>
          </Col>
          <Col md={2}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  Budget Amount
                </label>
          </Col>
          <Col md={2}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  Actual Amount
                </label>
          </Col>
          <Col md={2}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                 Variance
                </label>
          </Col>
          <Col md={2}>
                <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                  %
                </label>
          </Col>
        </Row>
        {/* 2nd */}
        <Row style={{marginTop:"2%"}} >
        <Col md={2}>
          <InputText disabled
                id="fy_PN_01"
                name="fy_PN_01"
                value={1}
              readOnly
              />
          </Col>
          <Col md={2}>
          <InputText 
                id="fb_PE_01"
                name="fb_PE_01"
                value={fb_PE_01}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText 
                id="gb_NP_01"
                name="gb_NP_01"
                value={gb_NP_01}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_01"
                name="fb_VR_01"
                value={fb_VR_01}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_01"
                name="fb_PR_01"
                value={fb_PR_01}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 3rd */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_02"
                name="fy_PN_02"
                value={2}
               readOnly
              />
          </Col>
          <Col md={2}>
          <InputText 
                id="fb_PE_02"
                name="fb_PE_02"
                value={fb_PE_02}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_02"
                name="gb_NP_02"
                value={gb_NP_02}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_02"
                name="fb_VR_02"
                value={fb_VR_02}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_02"
                name="fb_PR_02"
                value={fb_PR_02}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 4th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_03"
                name="fy_PN_03"
                value={3}
               readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_03"
                name="fb_PE_03"
                value={fb_PE_03}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_03"
                name="gb_NP_03"
                value={gb_NP_03}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_03"
                name="fb_VR_03"
                value={fb_VR_03}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_03"
                name="fb_PR_03"
                value={fb_PR_03}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 5th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_04"
                name="fy_PN_04"
                value={4}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_04"
                name="fb_PE_04"
                value={fb_PE_04}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_04"
                name="gb_NP_04"
                value={gb_NP_04}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_04"
                name="fb_VR_04"
                value={fb_VR_04}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_04"
                name="fb_PR_04"
                value={fb_PR_04}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 6th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_05"
                name="fy_PN_05"
                value={5}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_05"
                name="fb_PE_05"
                value={fb_PE_05}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_05"
                name="gb_NP_05"
                value={gb_NP_05}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_05"
                name="fb_VR_05"
                value={fb_VR_05}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_05"
                name="fb_PR_05"
                value={fb_PR_05}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 7th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_06"
                name="fy_PN_06"
                value={6}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_06"
                name="fb_PE_06"
                value={fb_PE_06}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_06"
                name="gb_NP_06"
                value={gb_NP_06}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_06"
                name="fb_VR_06"
                value={fb_VR_06}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_06"
                name="fb_PR_06"
                value={fb_PR_06}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>  
         {/*8th  */}
         <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_07"
                name="fy_PN_07"
                value={7}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_07"
                name="fb_PE_07"
                value={fb_PE_07}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_07"
                name="gb_NP_07"
                value={gb_NP_07}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_07"
                name="fb_VR_07"
                value={fb_VR_07}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_07"
                name="fb_PR_07"
                value={fb_PR_07}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 9th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_08"
                name="fy_PN_08"
                value={8}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_08"
                name="fb_PE_08"
                value={fb_PE_08}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_08"
                name="gb_NP_08"
                value={gb_NP_08}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_08"
                name="fb_VR_08"
                value={fb_VR_08}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_08"
                name="fb_PR_08"
                value={fb_PR_08}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 10th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_09"
                name="fy_PN_09"
                value={9}
               readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_09"
                name="fb_PE_09"
                value={fb_PE_09}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_09"
                name="gb_NP_09"
                value={gb_NP_09}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_09"
                name="fb_VR_09"
                value={fb_VR_09}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_09"
                name="fb_PR_09"
                value={fb_PR_09}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 11th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_10"
                name="fy_PN_10"
                value={10}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_10"
                name="fb_PE_10"
                value={fb_PE_10}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_10"
                name="gb_NP_10"
                value={gb_NP_10}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_10"
                name="fb_VR_10"
                value={fb_VR_10}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_10"
                name="fb_PR_10"
                value={fb_PR_10}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 12th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_11"
                name="fy_PN_11"
                value={11}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_11"
                name="fb_PE_11"
                value={fb_PE_11}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_11"
                name="gb_NP_11"
                value={gb_NP_11}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_11"
                name="fb_VR_11"
                value={fb_VR_11}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_11"
                name="fb_PR_11"
                value={fb_PR_11}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
        {/* 13th */}
        <Row style={{marginTop:"2%"}} >
          <Col md={2}>
          <InputText disabled
                id="fy_PN_12"
                name="fy_PN_12"
                value={12}
                readOnly
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PE_12"
                name="fb_PE_12"
                value={fb_PE_12}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="gb_NP_12"
                name="gb_NP_12"
                value={gb_NP_12}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_VR_12"
                name="fb_VR_12"
                value={fb_VR_12}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
          <Col md={2}>
          <InputText
                id="fb_PR_12"
                name="fb_PR_12"
                value={fb_PR_12}
                onChange={(e) => onchangedata(e)}
              />
          </Col>
        </Row>
          </Container>  
        </div>
      </div>   
    </Dialog>
  );
}
