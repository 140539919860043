import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import WorkOrderEntryPopup from "../Popups/WorkOrderEntryPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function WaxSettingProcessDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WaxSettingProcessTableData,
  setWaxSettingProcessTableData,
  SelectedITNM,
  setSelectedITNM,
  SelectedWONO,
  setSelectedWONO,
  bd_TO_LO,
  setbd_TO_LO,
  jd_TR_UM,
  setjd_TR_UM,
  jd_SC_UM,
  setjd_SC_UM,
  jd_LO_CN,
  setjd_LO_CN,
  jd_CO_CT,
  setjd_CO_CT,
  jd_DM_RS,
  setjd_DM_RS,
  bd_DM_LO,
  setbd_DM_LO,
  LocationDropdown,
  jd_OR_NO,
  onchangeDropDownFormDataBagNo,
  jd_BG_NO,
  jd_OR_CO,
}) {
  const {
    jdUkNo,
    jd_OR_TY,
    jd_TR_DT,
    jd_RL_NO,
    jd_RL_TY,
    jd_RL_CO,
    jd_OR_DT,
    jd_DE_NO,
    jd_RQ_DT,
    bd_WO_QT,
    compl_QTY,
    jd_CO_QT,
    jd_BG_QT,
    jd_SC_QT,
    jd_LS_QT,
    jd_LS_PR,
    jd_WT_QT,
    ji_DE_NO,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [bd_TO_LOOptions, setbd_TO_LOOptions] = useState([]);
  const [jd_TR_UMOptions, setjd_TR_UMOptions] = useState([]);
  const [jd_SC_UMOptions, setjd_SC_UMOptions] = useState([]);
  const [jd_LO_CNOptions, setjd_LO_CNOptions] = useState([]);
  const [jd_CO_CTOptions, setjd_CO_CTOptions] = useState([]);
  const [jd_DM_RSOptions, setjd_DM_RSOptions] = useState([]);
  const [bd_DM_LOOptions, setbd_DM_LOOptions] = useState([]);
  //const [selectedITNM, setSelectedITNM] = useState('');
  

  // UM
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_TR_UM(value);
  };

  // UM
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_SC_UM(value);
  };

  // FrLocation
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_LO_CN(value);
  };
  //Cost Center
  const onchangeDropDownFormData4 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_CO_CT(value);
  };

  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_DM_RS(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_DM_LO(value);
  };
  // Location
  // const LocationDropdown = (name, value) => {
  //   const index = name.split("_")[3];
  //   setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  //   setbd_TO_LO(value);
  // };

  useEffect(() => {
    Location();
    DropDownFormData1();
    DropDownFormData2();
    DropDownFormData3();
    DropDownFormData4();
    DropDownFormData5();
    DropDownFormData6();
    // LocationDropdown();


  }, []);

  const DropDownFormData1 = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setjd_TR_UMOptions(Options);
  };

  const DropDownFormData2 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjd_SC_UMOptions(Options);
  };
  const DropDownFormData3 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setjd_LO_CNOptions(Options);
  };
  const DropDownFormData4 = async () => {
    const Options = await fetchCostCenter();
    setjd_CO_CTOptions(Options);
  };
  const DropDownFormData5 = async () => {
    const Options = await fetchClassCodeDS("JW/DM");
    setjd_DM_RSOptions(Options);
  };
  const DropDownFormData6 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setbd_DM_LOOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setbd_TO_LOOptions(Options);
  };



  const addRow = () => {
    setWaxSettingProcessTableData([...WaxSettingProcessTableData, {}]);
  };

  const deleteRow = () => {
    if (WaxSettingProcessTableData.length > 0) {
      const updatedData = [...WaxSettingProcessTableData];
      updatedData.pop();
      setWaxSettingProcessTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(jdUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
    </React.Fragment>
  );

  const onChangeOfOrderNo = async (wd_WO_NO, wd_WO_TY) => {
    const Url = `${domain}/api/JewelTransferDetails/GetDetailsByWoNumber`;
    const requestData = {
      "jd_OR_NO": wd_WO_NO,
      "jd_OR_TY": wd_WO_TY,
      "jd_LO_CN": jd_LO_CN
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const Resultdata = response.data;
      if (Resultdata) {
      
        onchangeFormData("jd_OR_DT", Resultdata.WorkOrderEntryResult.wd_OR_DT);
        setSelectedITNM(Resultdata.WorkOrderEntryResult.wd_IT_NM);
        document.getElementById("jd_DE_NO").value = Resultdata.WorkOrderEntryResult.wd_IT_NM;//it_DES IT_DES
        onchangeFormData("jd_RQ_DT", Resultdata.WorkOrderEntryResult.wd_RQ_DT);
        document.getElementById("jd_TR_DT").value = Resultdata.WorkOrderEntryResult.wd_TR_DT;
        document.getElementById("jd_RL_NO").value = Resultdata.WorkOrderEntryResult.wd_RL_NO;
        document.getElementById("jd_RL_TY").value = Resultdata.WorkOrderEntryResult.wd_RL_TY;
        document.getElementById("jd_RL_CO").value = Resultdata.WorkOrderEntryResult.wd_RL_CO;
        // LocationDropdown("bd_TO_LO", Resultdata.WorkOrderEntryResult.wd_LO_CN);
       // document.getElementById("bd_BG_NO").value = Resultdata.Result.bd_BG_NO;
      } else {
        console.error("Invalid API response:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setShipToPopupVisible(false);
    setShowSearchIcon(false);
    await onChangeOfOrderNo1(wd_WO_NO ,wd_WO_TY)
  };
  const onChangeOfOrderNo1 = async (wd_WO_NO, wd_WO_TY) => {
    //  setSelectedAbAdNM(data.ab_AD_NM);
    //  setsh_SH_TO(data.abAdNo);

    const Url = `${domain}/api/JewelTransferDetails/GetPartListDetailsByWoNumber`;
    const requestData = {
      "jd_OR_NO": wd_WO_NO,
      "jd_OR_TY": wd_WO_TY,
      "jd_LO_CN": jd_LO_CN,
      "jd_BG_NO" : jd_BG_NO ,
      "jd_CO_CT" : jd_CO_CT

    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const Resultdata = response.data.Result;

      if (Resultdata && Array.isArray(Resultdata)) {
        const result = Resultdata[0];

        document.getElementById(`jd_IT_NM_${SelectedRowIndex}`).value = result.bd_IT_NM;
        document.getElementById(`item_DESC_${SelectedRowIndex}`).value = result.bd_IT_NM;
        //onchangeDropDownFormData1(`jd_TR_UM_${SelectedRowIndex}`, result.bd_TR_UM);
        document.getElementById(`jd_TR_UM_${SelectedRowIndex}`).value = result.bd_TR_UM;
        document.getElementById(`jd_SC_QT_${SelectedRowIndex}`).value = result.bd_SC_QT;
        // onchangeDropDownFormData2(`jd_SC_UM_${SelectedRowIndex}`, result.bd_SC_UM);
        document.getElementById(`jd_SC_UM_${SelectedRowIndex}`).value = result.bd_SC_UM;
        onchangeDropDownFormData3(`jd_LO_CN_${SelectedRowIndex}`, result.bd_FR_LO);
        // document.getElementById(`jd_LO_CN${SelectedRowIndex}`).value = result.bd_FR_LO;
        document.getElementById(`bd_AV_QT_${SelectedRowIndex}`).value = result.bd_AV_QT;
        onchangeDropDownFormData4(`jd_CO_CT_${SelectedRowIndex}`, result.bd_CO_CT);
        //document.getElementById(`jd_CO_CT_${SelectedRowIndex}`).value = result.bd_CO_CT;
        onchangeDropDownFormData5(`jd_DM_RS_${SelectedRowIndex}`, result.bd_DM_RS);
        onchangeDropDownFormData6(`jd_LN_NO_${SelectedRowIndex}`, result.bd_LN_NO);
        // document.getElementById(`bd_LN_NO_${SelectedRowIndex}`).value = result.bd_LN_NO;



      } else {
        console.error("Invalid API response:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [OrderPopupVisible, setOrderPopupVisible] = useState(false);

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DESC", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("jd_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ji_DE_NO").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const openOrderPopup = () => {
    setOrderPopupVisible(true);
  };
  const handleDataSelectOrder = (Result) => {
    setSelectedWONO(Result.wd_WO_NO);
    document.getElementById("jd_OR_NO").value = Result.wd_WO_NO;
    document.getElementById("jd_OR_TY").value = Result.wd_WO_TY;
    document.getElementById("jd_OR_CO").value = Result.wd_WO_CO;
    setOrderPopupVisible(false);
    setShowSearchIcon(false);
    onChangeOfOrderNo(Result.wd_WO_NO, Result.wd_WO_TY)
  };
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jdUkNo ? "Revise Jewellery Process" : "Add Jewellery Process"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip={isUpdateMode ? "Transfer" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_NO" className="font-bold">
              Order No<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedWONO}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openOrderPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="jd_OR_NO"
                name="jd_OR_NO"
                defaultValue={jd_OR_NO}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="jd_OR_TY"
              name="jd_OR_TY"
              defaultValue={jd_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="jd_OR_CO"
              name="jd_OR_CO"
              value={jd_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_DT" className="font-bold">
              Transaction Date
            </label>
            <Calendar
              id="jd_TR_DT"
              name="jd_TR_DT"
              value={moment(data.jd_TR_DT, "YYYYMMDD").isValid() ? moment(data.jd_TR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jd_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_NO" className="font-bold">
              Sales Order
            </label>
            <InputText disabled
              id="jd_RL_NO"
              name="jd_RL_NO"
              value={jd_RL_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_TY" className="font-bold">
              Sales Type
            </label>
            <InputText disabled
              id="jd_RL_TY"
              name="jd_RL_TY"
              value={jd_RL_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_CO" className="font-bold">
              Sales Company
            </label>
            <InputText disabled
              id="jd_RL_CO"
              name="jd_RL_CO"
              value={jd_RL_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_DT" className="font-bold">
              Order Date
            </label>
            <Calendar
              id="jd_OR_DT"
              name="jd_OR_DT"
              value={moment(data.jd_OR_DT, "YYYYMMDD").isValid() ? moment(data.jd_OR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jd_OR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_DE_NO" className="font-bold">
              Design No<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedITNM}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openItemPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="jd_DE_NO"
                name="jd_DE_NO"
                defaultValue={jd_DE_NO}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
          {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_DE_NO" className="font-bold">
              Design No
            </label>
            <InputText disabled
              id="jd_DE_NO"
              name="jd_DE_NO"
              value={jd_DE_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div> */}
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RQ_DT" className="font-bold">
              Requested  Date
            </label>
            <Calendar disabled
              id="jd_RQ_DT"
              name="jd_RQ_DT"
              value={moment(data.jd_RQ_DT, "YYYYMMDD").isValid() ? moment(data.jd_RQ_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jd_RQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_BG_NO" className="font-bold">
              Bag No
            </label>
            <Dropdown
              name="jd_BG_NO"
              value={jd_BG_NO}
              options={[
                { label: "230002_1", value: "230002_1" },
                { label: "230002_2", value: "230002_2" },
                { label: "230006_1", value: "230006_1" },
                { label: "N/A", value: "" },
              ]}
              onChange={(e) => onchangeDropDownFormDataBagNo("jd_BG_NO", e.value)}
              placeholder="	Location"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="compl_QTY" className="font-bold">
              Completed Quantity
            </label>
            <InputText disabled
              id="compl_QTY"
              name="compl_QTY"
              value={compl_QTY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_BG_QT" className="font-bold">
              Bag Quantity
            </label>
            <InputText disabled
              id="jd_BG_QT"
              name="jd_BG_QT"
              value={jd_BG_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_SC_QT" className="font-bold">
              Stones
            </label>
            <InputText disabled
              id="jd_SC_QT"
              name="jd_SC_QT"
              value={jd_SC_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_CO_QT" className="font-bold">
              Completion Quantity
            </label>
            <InputText
              id="jd_CO_QT"
              name="jd_CO_QT"
              value={jd_CO_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_LS_QT" className="font-bold">
              Loss
            </label>
            <InputText disabled
              id="jd_LS_QT"
              name="jd_LS_QT"
              value={jd_LS_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_WT_QT" className="font-bold">
              Weight
            </label>
            <InputText
              id="jd_WT_QT"
              name="jd_WT_QT"
              value={jd_WT_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_LS_PR" className="font-bold">
              Loss%
            </label>
            <InputText disabled
              id="jd_LS_PR"
              name="jd_LS_PR"
              value={jd_LS_PR}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_TO_LO" className="font-bold">
              Location
            </label>
            <Dropdown disabled
              name="bd_TO_LO"
              value={bd_TO_LO}
              options={bd_TO_LOOptions}
              onChange={(e) => LocationDropdown("bd_TO_LO", e.value)}
              placeholder="	Location"
            />
          </div> */}

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="WaxSettingProcesstable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line No</th>
                <th>Item Name<span style={{ color: 'red' }}>*</span></th>
                <th>Description</th>
                <th>Quantity</th>
                <th>UM</th>
                <th>Sec. Quantity</th>
                <th>UM</th>
                <th>Location</th>
                <th>CostCentre</th>
                <th>Carat</th>
                <th>Stones</th>
                <th>Reason</th>
                <th>Unit Cost</th>
                <th>Amount</th>
                <th>Fore. Cost</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {WaxSettingProcessTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`jdUkNo_${index}`}
                      name={`jdUkNo_${index}`}
                      value={row.jdUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "90px" }}
                      id={`jd_LN_NO_${index}`}
                      name={`jd_LN_NO_${index}`}
                      //value={index + 1}
                      defaultValue={row.jd_LN_NO}
                      readOnly
                    />
                  </td>
                  <td style={{ display: "flex" }}>
                    <InputText disabled
                      style={{ width: "110px" }}
                      id={`jd_IT_NM_${index}`}
                      name={`jd_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.bd_IT_NM ? row.jd_IT_NM : row[`${"bd_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup1(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DESC_${index}`}
                      name={`item_DESC_${index}`}
                      value={row.item_DESC ? row.item_DESC : row[`${"item_DESC"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`jd_OR_QT_${index}`}
                      name={`jd_OR_QT_${index}`}
                      defaultValue={row.jd_OR_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_TR_UM_${index}`}
                      name={`jd_TR_UM_${index}`}
                      value={data[`jd_TR_UM_${index}`] ? data[`jd_TR_UM_${index}`] : row.jd_TR_UM}
                      options={jd_TR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData1(`jd_TR_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`jd_SC_QT_${index}`}
                      name={`jd_SC_QT_${index}`}
                      defaultValue={row.jd_SC_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_SC_UM_${index}`}
                      name={`jd_SC_UM_${index}`}
                      value={data[`jd_SC_UM_${index}`] ? data[`jd_SC_UM_${index}`] : row.jd_SC_UM}
                      options={jd_SC_UMOptions}
                      onChange={(e) => onchangeDropDownFormData2(`jd_SC_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_LO_CN${index}`}
                      name={`jd_LO_CN${index}`}
                      value={data[`jd_LO_CN${index}`] ? data[`jd_LO_CN${index}`] : row.jd_LO_CN}
                      options={jd_LO_CNOptions}
                      onChange={(e) => onchangeDropDownFormData3(`jd_LO_CN${index}`, e.value)}
                      placeholder="Location"

                    />
                  </td>
                  {/* <td >
                    <InputText style={{ width: "100px" }}
                      id={`bd_AV_QT_${index}`}
                      name={`bd_AV_QT_${index}`}
                      defaultValue={row.bd_AV_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td> */}
                  <td>
                    <Dropdown
                      id={`jd_CO_CT_${index}`}
                      name={`jd_CO_CT_${index}`}
                      value={data[`jd_CO_CT_${index}`] ? data[`jd_CO_CT_${index}`] : row.jd_CO_CT}
                      options={jd_CO_CTOptions}
                      onChange={(e) => onchangeDropDownFormData4(`jd_CO_CT_${index}`, e.value)}
                      placeholder="Cost Center"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_DM_CT_${index}`}
                      name={`jd_DM_CT_${index}`}
                      defaultValue={row.jd_DM_CT}
                      onChange={(e) => onchangedata(e)}

                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_DM_ST_${index}`}
                      name={`jd_DM_ST_${index}`}
                      defaultValue={row.jd_DM_ST}
                      onChange={(e) => onchangedata(e)}

                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_DM_RS_${index}`}
                      name={`jd_DM_RS_${index}`}
                      value={data[`jd_DM_RS_${index}`] ? data[`jd_DM_RS_${index}`] : row.jd_DM_RS}
                      options={jd_DM_RSOptions}
                      onChange={(e) => onchangeDropDownFormData5(`jd_DM_RS_${index}`, e.value)}
                      placeholder="Reason"

                    />
                  </td>
                  {/* <td>
                    <Dropdown
                      id={`bd_DM_LO_${index}`}
                      name={`bd_DM_LO_${index}`}
                      value={data[`bd_DM_LO_${index}`] ? data[`bd_DM_LO_${index}`] : row.bd_DM_LO}
                      options={bd_DM_LOOptions}
                      onChange={(e) => onchangeDropDownFormData6(`bd_DM_LO_${index}`, e.value)}
                      placeholder=" TO Location"
                    />
                  </td> */}
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_UN_CT_${index}`}
                      name={`jd_UN_CT_${index}`}
                      defaultValue={row.jd_UN_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_EX_CT_${index}`}
                      name={`jd_EX_CT_${index}`}
                      defaultValue={row.jd_EX_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_EX_CT_${index}`}
                      name={`jd_EX_CT_${index}`}
                      defaultValue={row.jd_FR_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`jd_FR_AM_${index}`}
                      name={`jd_FR_AM_${index}`}
                      defaultValue={row.jd_FR_AM}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <WorkOrderEntryPopup
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleDataSelectOrder}
        formData={data}
      />
      {/* Design No  */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
