import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import moment from "moment";
import axios from "axios";
import AddressBookPopup from "../Popups/AddressBookPopup";
import Buttonstyles from "views/common/ButtonStyles";
export default function JournalEntryDialog({
  open,
  handleClose,
  onchangedata,
  data,
  setFormData,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  JournalEntrytable,
  gl_DO_CO,
  gl_BA_TY,
  gl_DO_TY,
  gl_BS_CR,
  gl_CR_CD,
  gl_CR_RT,
  gl_SB_LE,
  onchangegldate,
  gl_AC_NO,
  acc_DESC,
  handleConfirm,
  handlePost,
  gl_CN_FM
}) {
  const {
    gl_EX_PL,
    gl_DO_NO,
    gl_BA_NO,
    glUkNo,
    gl_JE_LE,
    gl_AC_AM,
    gl_TA_AP,
    gl_TX_CD,
    gl_TA_AM,
    gl_TX_AM,
    gl_SB_TY,
    gl_RM_MK,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,postButtonStyles,confirmstyle,savestyle,cancelstyle,Dialogstyle} = Buttonstyles();
  const [bankFilter, setbankFilter] = useState("BA");
  
  const [gl_DO_TYOptions, setgl_DO_TYOptions] = useState([]);
  const [gl_BA_TYOptions, setgl_BA_TYOptions] = useState([]);
  const [gl_CR_CDOptions, setgl_CR_CDOptions] = useState([]);
  const [gl_TX_APOptions, setgl_TX_APOptions] = useState([]);
  const [gl_TX_CDOptions, setgl_TX_CDOptions] = useState([]);
  const [gl_SB_TYOptions, setgl_SB_TYOptions] = useState([]);
  const [gl_GL_DT, setgl_GL_DT] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [SelectedNAME, setSelectedNAME] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = (data) => {
    onchangeFormData("gl_AC_NO", data.account, SelectedRowIndex);
    onchangeFormData("acc_DESC", data.am_AC_DS, SelectedRowIndex);
    setAccountPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [AddressPopupVisible, setAddressPopupVisible] = useState(false);
  const [addressType, setaddressType] = useState(gl_SB_TY);
  const openAddressPopup = (index) => {
    const selectedType = data[`gl_SB_TY_${index}`] || JournalEntrytable[index].gl_SB_TY;
    setSelectedRowIndex(index);
    setaddressType(selectedType);
    setAddressPopupVisible(true);
  };
  const handleDataSelect1 = (content) => {
    onchangeFormData("gl_SB_LE", content.abAdNo, SelectedRowIndex);
    onchangeFormData("SelectedNAME", content.ab_AD_NM, SelectedRowIndex);
    //setSelectedNAME(data.ab_AD_NM);
    setAddressPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleFormDataChange6 = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData6(name, value, index);
  };
  // const onchangedata = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "fi_ST_DT" || name === "fi_EN_DT") {
  //     console.log(`Received date for ${name}:`, value);
  //     const formattedDate = moment(value, "DD/MM/YYYY", true).format(
  //       "YYYYMMDD"
  //     );
  //     console.log(`Formatted date for ${name}:`, formattedDate);

  //     if (moment(formattedDate, "YYYYMMDD").isValid()) {
  //       onchangeFormData(name, formattedDate);
  //     } else {
  //       console.error("Invalid date format:", value);
  //     }
  //   } else {
  //     onchangeFormData(name, value);
  //   }
  // };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "gl_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
	  setgl_GL_DT(e.value);
  };
  const url = `${domain}/api/ClassCode/All`;

  const parameter1 = "FO/DT";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "FO/DT";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "IN/TX";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "TX/RT";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "FO/AT";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    setgl_GL_DT(moment(moment().toDate()).format("YYYYMMDD"));
    onchangeDropDownFormData3("gl_CR_CD", gl_CR_CD);
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setgl_DO_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setgl_BA_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3nd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setgl_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 4nd dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setgl_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 5nd dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setgl_TX_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 6nd dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setgl_SB_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const onChangeOfDebitAmount = async (SelectedRowIndex) => {
	  const Amount = document.getElementById (`gl_DE_AM_${SelectedRowIndex}`).value;
    const table = document.getElementById("JournalEntrytable");
    const txCode = table.tBodies[0].rows[SelectedRowIndex].cells[7].children[0].children[1].children[0].value;
	  handletaxdtls(SelectedRowIndex,Amount,txCode);
  }; 
  const onChangeOfCreditAmount = async (SelectedRowIndex) => {
	  const Amount = document.getElementById (`gl_CR_AM_${SelectedRowIndex}`).value;
    const table = document.getElementById("JournalEntrytable");
    const txCode = table.tBodies[0].rows[SelectedRowIndex].cells[7].children[0].children[1].children[0].value;
	  handletaxdtls(SelectedRowIndex,Amount,txCode);
  };
  const onChangeOfTaxCode = (name,SelectedRowIndex, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData5(name, value, index); 
	var Amount = document.getElementById (`gl_DE_AM_${SelectedRowIndex}`).value;
	if(!Amount){
		Amount = document.getElementById (`gl_CR_AM_${SelectedRowIndex}`).value;
	}
	if(!value){
		document.getElementById (`gl_TX_AM_${SelectedRowIndex}`).value = '';
		document.getElementById (`gl_TA_AM_${SelectedRowIndex}`).value = '';
 	}
	handletaxdtls(SelectedRowIndex,Amount,value);	
  };
  const onChangeOfTaxApplicable = (name,SelectedRowIndex, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData4(name, value, index);
    if(value === 'N') {
      document.getElementById (`gl_TX_AM_${SelectedRowIndex}`).value = '';
      document.getElementById (`gl_TA_AM_${SelectedRowIndex}`).value = '';
    } else {
      var Amount = document.getElementById (`gl_DE_AM_${SelectedRowIndex}`).value;
      if(!Amount){
        Amount = document.getElementById (`gl_CR_AM_${SelectedRowIndex}`).value;
      }
      const table = document.getElementById("JournalEntrytable");
      const txCode = table.tBodies[0].rows[SelectedRowIndex].cells[7].children[0].children[1].children[0].value;
      handletaxdtls(SelectedRowIndex,Amount,txCode);
    }
	
	
  };
  const handletaxdtls = async (SelectedRowIndex,amount,txCode) => { 
    const PostUrl = `${domain}/api/JournalEntry/GetTaxDetails`; 
    const requestdtls ={
      "gl_AC_AM": amount,
      "gl_TX_CD": txCode 
      }
      try {
        const response =await axios.post(PostUrl, requestdtls, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataTX = response.data.header;
        if (dataTX ) {
          document.getElementById (`gl_TA_AM_${SelectedRowIndex}`).value =  dataTX.gl_TA_AM;
          document.getElementById (`gl_TX_AM_${SelectedRowIndex}`).value =  dataTX.gl_TX_AM;
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };  
  const isUpdateMode = Boolean(glUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{glUkNo ? "Revise Journal Entry" : "Add Journal Entry"}</h5>
      <div style={{ display:'flex', justifyContent: 'flex-start'}}>
        <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
            tooltip={isUpdateMode ? "Update" : "Submit"}
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        {isUpdateMode && (
          <div style={{ display: 'flex',width:"10%" }}>
          <Button 
            style={confirmstyle}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
          <Button 
            style={postButtonStyles}
            icon="pi pi-send" 
            onClick={handlePost} 
            tooltip={"Post"}>
          </Button>
          </div>
        )}
        </div>
      </div>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
       <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
       {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        <Button 
          icon="pi pi-send" 
          onClick={handlePost} 
          tooltip={"Post"}>
        </Button>
        </div>
       )}
        <div style={{ display: 'flex',width:"10%" }}>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-times" 
          variant="outlined" 
          onClick={handleClose}
          tooltip="Cancel"
        >
        </Button>
        <Button 
          style={{width:"100%"}}
          icon="pi pi-check" 
          onClick={handleFormSubmit} 
          tooltip={isUpdateMode ? "Update" : "Submit"}>
        </Button>
        </div>
    </div>
  </React.Fragment>
  );
  const isDisabled = gl_CN_FM === 20;
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}> 
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_DO_NO" className="font-bold">
                Document No
              </label>
              <InputText disabled
                id="gl_DO_NO"
                name="gl_DO_NO"
                value={gl_DO_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_DO_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="gl_DO_TY"
                name="gl_DO_TY"
                value={gl_DO_TY}
                // options={gl_DO_TYOptions}
                // onChange={(e) => onchangeDropDownFormData1("gl_DO_TY", e.value)}
                // placeholder="Select Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_DO_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="gl_DO_CO"
                name="gl_DO_CO"
                value={gl_DO_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_BA_NO" className="font-bold">
                Batch No
              </label>
              <InputText disabled
                id="gl_BA_NO"
                name="gl_BA_NO"
                value={gl_BA_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_BA_TY" className="font-bold">
                Batch Type
              </label>
              <InputText
                id="gl_BA_TY"
                name="gl_BA_TY"
                value={gl_BA_TY}
                disabled
                // options={gl_BA_TYOptions}
                // onChange={(e) => onchangeDropDownFormData2("gl_BA_TY", e.value)}
                // placeholder="Select Batch Type"
              />
            </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_GL_DT" className="font-bold">
                GL Date
              </label>
              <Calendar disabled={isDisabled}
                id="gl_GL_DT"
                name="gl_GL_DT"
                value={moment(gl_GL_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              /> 
            </div>
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_CR_CD" className="font-bold">
              Trans Currency
              </label>
              <Dropdown disabled={isDisabled}
                id="gl_CR_CD"
                name="gl_CR_CD"
                value={gl_CR_CD}
                options={gl_CR_CDOptions}
                onChange={(e) => onchangeDropDownFormData3("gl_CR_CD", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_CR_RT" className="font-bold">
                Exchange  Rate
              </label>
              <InputText disabled
                id="gl_CR_RT"
                name="gl_CR_RT"
                value={gl_CR_RT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_BS_CR" className="font-bold">
                Base Currency
              </label>
              <InputText disabled
                id="gl_BS_CR"
                name="gl_BS_CR"
                value={gl_BS_CR}
              />
            </div>
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="gl_EX_PL" className="font-bold">
                Explanation
              </label>
              <InputText disabled={isDisabled}
                id="gl_EX_PL"
                name="gl_EX_PL"
                value={gl_EX_PL}
                onChange={(e) => onchangedata(e)}
              />

          </div>
          </div>
        </div>

        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
            <table  className="custom-table" id="JournalEntrytable" style={{ overflow: "auto" }} >
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK_NO</th>
                  <th>Line No</th>
                  <th>Account <span style={{color:"red"}}>*</span></th>
                  <th>Description</th>
                  <th>Debit Amount</th>
                  <th>Credit Amount </th>
                  <th>Tax Applicable</th>
                  <th>Tax Code</th>
                  <th>Taxable Amount </th>
                  <th>Tax </th>
                  <th>Sub Ledger Type</th>
                  <th>Sub Ledger <span style={{color:"red"}}>*</span> </th>
                  <th>Name </th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {JournalEntrytable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`glUkNo_${index}`}
                        name={`glUkNo_${index}`}
                        defaultValue={row.glUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`gl_JE_LE_${index}`}
                        name={`gl_JE_LE_${index}`}
                        value={index + 1 }
                      />
                    </td>
                    <td >
                      {/* <InputText
                        style={{ width: "90px" }}
                        id={`gl_AC_NO_${index}`}
                        name={`gl_AC_NO_${index}`}
                        defaultValue={row.gl_AC_NO}
                        onChange={(Event) => onchangedata(Event)}
                      /> */}
                      <div style={{display:"flex"}}>
                      <InputText style={{width:"10rem"}} disabled={isDisabled}
                        id={`gl_AC_NO_${index}`}
                        name={`gl_AC_NO_${index}`}
                        value={row.gl_AC_NO ? row.gl_AC_NO: row[`${"gl_AC_NO"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)}
                      />
                        {showSearchIcon && (
                      <i 
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openAccountPopup(index)}
                      ></i>
                        )}
                        </div>
                    </td>
                    <td>
                      <InputText disabled
                      style={{width:"15rem"}}
                      id={`acc_DESC_${index}`}
                      name={`acc_DESC_${index}`}
                      value={row.acc_DESC ? row.acc_DESC: row[`${"acc_DESC"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td> 
                      <InputText disabled={isDisabled}
                        style={{ width: "10rem" }}
                        id={`gl_DE_AM_${index}`}
                        name={`gl_AC_AM_${index}`}
                        defaultValue={row.debit_AMOUNT}
                        onChange={(e) => { onchangedata(e); onChangeOfDebitAmount(index);}}
                      />
                    </td>
                    <td>
                      <InputText disabled={isDisabled}
                        style={{ width: "10rem" }}
                        id={`gl_CR_AM_${index}`}
                        name={`gl_AC_AM_${index}`}
                        defaultValue={row.credit_AMOUNT}
                        onChange={(e) => {onchangedata(e);onChangeOfCreditAmount(index);}}
                      />
                    </td>
                    <td>
                      <Dropdown disabled={isDisabled}
                        id={`gl_TA_AP_${index}`}
                        name={`gl_TA_AP_${index}`}
                        value={ data[`gl_TA_AP_${index}`] ? data[`gl_TA_AP_${index}`] : row.gl_TA_AP }
                        options={gl_TX_APOptions}
                        onChange={(e) => onChangeOfTaxApplicable(`gl_TA_AP_${index}`, index, e.value)}
                        placeholder="Tax Y/N"
                      />
                    </td>
                    <td>
                      <Dropdown  disabled={isDisabled}
                        id={`gl_TX_CD_${index}`}
                        name={`gl_TX_CD_${index}`}
                        value={ data[`gl_TX_CD_${index}`]  ? data[`gl_TX_CD_${index}`]  : row.gl_TX_CD }
                        options={gl_TX_CDOptions}
                        onChange={(e) => onChangeOfTaxCode(`gl_TX_CD_${index}`, index, e.value)}
                        placeholder="Tax Code"
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`gl_TA_AM_${index}`}
                        name={`gl_TA_AM_${index}`}
                        value={row.gl_TA_AM}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`gl_TX_AM_${index}`}
                        name={`gl_TX_AM_${index}`}
                        value={row.gl_TX_AM}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <Dropdown disabled={isDisabled}
                      style={{width:"8rem"}}
                        id={`gl_SB_TY_${index}`}
                        name={`gl_SB_TY_${index}`}
                        value={data[`gl_SB_TY_${index}`] ? data[`gl_SB_TY_${index}`]  : row.gl_SB_TY }
                        options={gl_SB_TYOptions}
                        onChange={(e) => handleFormDataChange6(`gl_SB_TY_${index}`, e.value)}
                        placeholder="Select Sub Ledger Type"
                      />
                    </td>
                    <td>
                    <div style={{display:"flex"}} >
                      <InputText disabled={isDisabled}
                        style={{ width: "10rem" }}
                        id={`gl_SB_LE_${index}`}
                        name={`gl_SB_LE_${index}`}
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.gl_SB_LE ? row.gl_SB_LE : row[`${'gl_SB_LE'}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i 
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                          
                        }}
                        onClick={(e) => openAddressPopup(index)}
                      ></i>
                        )}
                        </div>
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "15rem" }}
                        id={`SelectedNAME_${index}`}
                        name={`SelectedNAME_${index}`}
                        defaultValue={row.name ? row.name : row[`${'SelectedNAME'}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText disabled={isDisabled}
                        style={{ width: "18rem" }}
                        id={`gl_RM_MK_${index}`}
                        name={`gl_RM_MK_${index}`}
                        defaultValue={row.gl_RM_MK}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        bankFilter={bankFilter}
        setFormData={setFormData}
      />
      <AddressBookPopup
        visible={AddressPopupVisible}
        onClose={() => setAddressPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
