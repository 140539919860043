// ReNamePopup.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";


const ReNamePopup = ({ isOpen, onClose, optionsSelected,handleResponse,documentType,setoptionsSelected }) => {
  const [fileName, setFileName] = useState('');

  const toast = useRef(null);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


   // Rename
   const handleOptionClick = async (option) => {
    const fileDetails = {
      "fdUkNo" : optionsSelected,
      "fd_NA_ME" : fileName
    };
    const url = `${domain}/api/FileManagementSystem/ReNameFolderAndFile`;
    try {
      const response = await axios.post(url, fileDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data;
      handleResponse(listItemsData);
      onClose();
      setFileName(null);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    isOpen && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Rename</h2>
          <form>
            <input
              type="text"
              placeholder="Enter new name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              autoFocus
            />
            <button type="submit" onClick={handleOptionClick}>OK</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default ReNamePopup;
