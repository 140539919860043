import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ClassCode from "views/common/ClassCode";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import { Calendar } from "primereact/calendar";
import moment from "moment";
export default function PriceAdjustmentScheduleDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AdjustmentScheduledata,
  setAdjustmentScheduledata,
  as_AD_SH,
  setas_AD_SH, 
  as_AD_NM,
  setas_AD_NM,
  as_IT_GP,
  setas_IT_GP,
  as_CU_GP,
  setas_CU_GP,
  as_CO_NO
}) {
  const {
  asUkNo, 
  as_SQ_NO,   
  as_PR_EF,
  as_FR_DT,
  as_TO_DT,  
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const addRow = () => {
    setAdjustmentScheduledata([...AdjustmentScheduledata, {}]);
  };
  
  const deleteRow = () => {
    if (data.length > 0) {
      const updatedData = [...AdjustmentScheduledata];
      updatedData.pop();
      setAdjustmentScheduledata(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  }; 
  //dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [as_AD_SHOptions, setas_AD_SHOptions] = useState([]);
  const [as_AD_NMOptions, setas_AD_NMOptions] = useState([]);
  const [as_IT_GPOptions, setas_IT_GPOptions] = useState([]);
  const [as_CU_GPOptions, setas_CU_GPOptions] = useState([]);
  
//type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setas_AD_SH(value);   
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setas_AD_NM(value);
  };  
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setas_IT_GP(value);   
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setas_CU_GP(value);
  };  
  useEffect(() => {
    fetchDropdownAS();
    fetchDropdownAN();
    fetchDropdownIG();
    fetchDropdownCG();

  }, []);

  const fetchDropdownAS = async () => {
    const Options = await fetchClassCodeCD("AD/SH");
    setas_AD_SHOptions(Options);   
  };

  const fetchDropdownAN = async () => {    
    const Options = await fetchClassCodeCD("AD/NM");
    setas_AD_NMOptions(Options);
  };

  const fetchDropdownIG = async () => {    
    const Options = await fetchClassCodeCD("IN/PR");
    setas_IT_GPOptions(Options);
  };

  const fetchDropdownCG = async () => {    
    const Options = await fetchClassCodeCD("SO/PR");
    setas_CU_GPOptions(Options);
  };
  const isUpdateMode = Boolean(asUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{asUkNo ? "Revise Price Adjustment Schedule" : "Add Price Adjustment Schedule"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="as_AD_SH" className="font-bold">
                  Adjustment Schedule
                </label>
                  <Dropdown
                    id="as_AD_SH"
                    name="as_AD_SH"
                    value={as_AD_SH}
                    options={as_AD_SHOptions}
                    onChange={(e) => onchangeDropDownFormData("as_AD_SH", e.value)}
                    placeholder="Adjustment Schedule"
                  />
              </div>      
            </div> 
          </div> 
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="PriceAdjustmentSchedule">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                
                  <th>Sequence</th>
                  <th>Adjustment Name</th>
                  <th>Item Group</th>
                  <th>Customer Group</th>
                  <th>Preference</th>              
                  <th>From Date</th>
                  <th>To Date</th>             
                  <th>Company</th>              
                </tr>
              </thead>
              <tbody>
                {AdjustmentScheduledata.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`asUkNo_${index}`}
                        name={`asUkNo_${index}`}
                        value={row.asUkNo}
                        disabled={!!asUkNo}
                      />
                    </td> 
                    <td >
                      <InputText disabled style={{width:"10rem"}}
                        id={`as_SQ_NO_${index}`}
                        name={`as_SQ_NO_${index}`}
                        value={index+1}
                      />
                    </td>           
                    <td>                        
                      <Dropdown 
                        id={`as_AD_NM_${index}`}
                        name={`as_AD_NM_${index}`}
                        value={data[`as_AD_NM_${index}`] ? data[`as_AD_NM_${index}`] : row.as_AD_NM}
                        options={as_AD_NMOptions}
                        onChange={(e) => onchangeDropDownFormData1(`as_AD_NM_${index}`, e.value)}
                        placeholder="Adjustment Name"
                      />
                    </td>
                    <td>                        
                      <Dropdown 
                        id={`as_IT_GP_${index}`}
                        name={`as_IT_GP_${index}`}
                        value={data[`as_IT_GP_${index}`] ? data[`as_IT_GP_${index}`] : row.as_IT_GP}
                        options={as_IT_GPOptions}
                        onChange={(e) => onchangeDropDownFormData2(`as_IT_GP_${index}`, e.value)}
                        placeholder="Item Group"
                      />
                    </td>
                    <td>                        
                      <Dropdown 
                        id={`as_CU_GP_${index}`}
                        name={`as_CU_GP_${index}`}
                        value={data[`as_CU_GP_${index}`] ? data[`as_CU_GP_${index}`] : row.as_CU_GP}
                        options={as_CU_GPOptions}
                        onChange={(e) => onchangeDropDownFormData3(`as_CU_GP_${index}`, e.value)}
                        placeholder="Customer Group"
                      />
                    </td>
                    <td>
                    <InputText style={{width:"10rem"}}
                        id={`as_PR_EF_${index}`}
                        name={`as_PR_EF_${index}`}
                        defaultValue={row.as_PR_EF}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>                
                    <td>
                      <Calendar style={{width:"10rem"}}
                        id={`as_FR_DT_${index}`}
                        name={`as_FR_DT_${index}`}
                        value={ row.as_FR_DT ? moment(row.as_FR_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "as_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                    </td>
                    <td>
                      <Calendar style={{width:"10rem"}}
                        id={`as_TO_DT_${index}`}
                        name={`as_TO_DT_${index}`}
                        value={ row.as_TO_DT ? moment(row.as_TO_DT, "YYYYMMDD").toDate() : null }
                        onChange={(e) => onDateChange({ target: { name: "as_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                    </td>                                
                    <td>
                    <InputText style={{width:"10rem"}} disabled
                        id={`as_CO_NO${index}`}
                        name={`as_CO_NO_${index}`}
                        defaultValue={as_CO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div> 
      </div>      
    </Dialog>
  );
}
