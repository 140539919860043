import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ServiceTypeDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  handleFormSubmit,
  st_CL_CD1,
  setst_CL_CD1,
  st_CL_CD2,
  setst_CL_CD2,
  st_CL_CD3,
  setst_CL_CD3,
  st_CL_CD4,
  setst_CL_CD4,
  st_CL_CD5,
  setst_CL_CD5,
  st_SE_TY,
  setst_SE_TY,
  st_CV_GL,
  setst_CV_GL,
  st_NC_GL,
  setst_NC_GL,
  st_PR_MT,
  setst_PR_MT,
  flatRateChecked,
  setFlatRateChecked,
  timeAndMaterialsChecked,
  setTimeAndMaterialsChecked,
  PricingMethod,
  setPricingMethod,
}) {
  const { stUkNo,st_TH_HR, st_NC_RT, } = data;
  // buttonstyles
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle } = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [st_CL_CD5Options, setst_CL_CD5Options] = useState([]);
  const [st_CL_CD4Options, setst_CL_CD4Options] = useState([]);
  const [st_CL_CD3Options, setst_CL_CD3Options] = useState([]);
  const [st_CL_CD2Options, setst_CL_CD2Options] = useState([]);
  const [st_CL_CD1Options, setst_CL_CD1Options] = useState([]);
  const [st_SE_TYOptions, setst_SE_TYOptions] = useState([]);
  const [st_CV_GLOptions, setst_CV_GLOptions] = useState([]);
  const [st_NC_GLOptions, setst_NC_GLOptions] = useState([]);
  // classcode 1
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CL_CD1(value);
  };
  // classcode 2
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CL_CD2(value);
  };
  // classcode 3
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CL_CD3(value);
  };
  // classcode 4
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CL_CD4(value);
  };
  // classcode 5
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CL_CD5(value);
  };
  // Serivice type
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_SE_TY(value);
  };
  // GL Code
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_CV_GL(value);
  };
  // non- coverred gl code
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setst_NC_GL(value);
  };
  useEffect(() => {
    fetchDropdownCC1();
    fetchDropdownCC2();
    fetchDropdownCC3();
    fetchDropdownCC4();
    fetchDropdownCC5();
    fetchDropdownST();
    fetchDropdownGL();
    fetchDropdownNCGL();
  }, []);
  const fetchDropdownCC1 = async () => {
    const Options = await fetchClassCodeDS("SE/1");
    setst_CL_CD1Options(Options);
  };
  const fetchDropdownCC2 = async () => {
    const Options = await fetchClassCodeDS("SE/2");
    setst_CL_CD2Options(Options);
  };
  const fetchDropdownCC3 = async () => {
    const Options = await fetchClassCodeDS("SE/3");
    setst_CL_CD3Options(Options);
  };
  const fetchDropdownCC4 = async () => {
    const Options = await fetchClassCodeDS("SE/4");
    setst_CL_CD4Options(Options);
  };
  const fetchDropdownCC5 = async () => {
    const Options = await fetchClassCodeDS("SE/5");
    setst_CL_CD5Options(Options);
  };
  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("SE/TY");
    setst_SE_TYOptions(Options);
  };
  const fetchDropdownGL = async () => {
    const Options = await fetchClassCodeDS("IN/GC");
    setst_CV_GLOptions(Options);
  };
  const fetchDropdownNCGL = async () => {
    const Options = await fetchClassCodeDS("IN/GC");
    setst_NC_GLOptions(Options);
  };
  // const [flatRateChecked, setFlatRateChecked] = useState(false);
  // const [timeAndMaterialsChecked, setTimeAndMaterialsChecked] = useState(false);
  // const [PricingMethod, setPricingMethod ] = useState(false);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'flatRate') {
      setFlatRateChecked(checked);
      if (checked) {
        setTimeAndMaterialsChecked(false);
        setPricingMethod('FL');
        setst_PR_MT('FL');
        setFormData(prevFormData => ({ ...prevFormData, st_PR_MT: 'FL' }));
      } else {
        setPricingMethod('');
        setst_PR_MT('');
        setFormData(prevFormData => ({ ...prevFormData, st_PR_MT: '' }));
      }
    } else if (name === 'timeAndMaterials') {
      setTimeAndMaterialsChecked(checked);
      if (checked) {
        setFlatRateChecked(false);
        setPricingMethod('TAM');
        setst_PR_MT('TAM');
        setFormData(prevFormData => ({ ...prevFormData, st_PR_MT: 'TAM' }));
      } else {
        setPricingMethod('');
        setst_PR_MT('');
        setFormData(prevFormData => ({ ...prevFormData, st_PR_MT: '' }));
      }
    }
  };
  const isUpdateMode = Boolean(stUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{stUkNo ? "Revise Service Type" : "Add Service Type"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={TabBoxStyle}>
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
          {/* tab1 general */}
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="stUkNo" className="font-bold">
                  UK
                </label>
                <InputText
                  id="stUkNo"
                  name="stUkNo"
                  defaultValue={stUkNo}
                  onChange={(e) => onchangedata(e)}
                />
              </div>  
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_SE_TY" className="font-bold">
                  Service Type
                </label>
                <Dropdown
                  name="st_SE_TY"
                  value={st_SE_TY}
                  options={st_SE_TYOptions}
                  onChange={(e) => onchangeDropDownFormData6("st_SE_TY", e.value)}
                  placeholder="Select Service Type"
                />
              </div>  
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CV_GL" className="font-bold">
                  Covered GL code
                </label>
                <Dropdown
                  name="st_CV_GL"
                  value={st_CV_GL}
                  options={st_CV_GLOptions}
                  onChange={(e) => onchangeDropDownFormData7("st_CV_GL", e.value)}
                  placeholder="Select GL Code"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_NC_GL" className="font-bold">
                  Non Covered GL Code
                </label>
                <Dropdown
                  name="st_NC_GL"
                  value={st_NC_GL}
                  options={st_NC_GLOptions}
                  onChange={(e) => onchangeDropDownFormData8("st_NC_GL", e.value)}
                  placeholder="Select Non Cov. GL Code"
                />
              </div>              
            </div>
          </div>
          <div style={{ display:"flex"}}>
              <div  style={inboxstyle}  className="field col-lg-5 col-md-6 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-12">
                  <h3 className="font-bold" style={{color:"cadetblue",fontSize:"large"}}>Method of Pricing</h3>
                </div>
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="st_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                      Flat Rate
                    </label>
                    <input
                      type="checkbox"
                      id="flatRate"
                      name="flatRate"
                      checked={flatRateChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="st_PR_MT" className="font-bold" style={{ marginRight: '15px' }}>
                      Time & Materials
                    </label>
                    <input
                      type="checkbox"
                      name="timeAndMaterials"
                      id="timeAndMaterials"
                      checked={timeAndMaterialsChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>   
                </div>
              </div>
              <div  style={{
                    border: "1px solid #e8e8e8",
                    padding: "16px",
                    borderRadius: "15px",
                    backgroundColor: "aliceblue",
                    marginLeft:"1%",
                    marginTop:"2%"
                  }}
                className="field col-lg-7 col-md-7 col-12 pe-md-2" >
                <div className="formgrid grid"> 
                <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="st_TH_HR" className="font-bold">
                      Threshold Hours
                    </label>
                    <InputText
                      id="st_TH_HR"
                      name="st_TH_HR"
                      value={st_TH_HR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>  
                  <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
                    <label htmlFor="st_NC_RT" className="font-bold">
                      Non Covered Response Time
                    </label>
                    <InputText
                      id="st_NC_RT"
                      name="st_NC_RT"
                      value={st_NC_RT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                            
                </div>
              </div>
          </div>
        </TabPanel>
        <TabPanel header="Class Code">
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CL_CD1" className="font-bold">
                Class Code 1
                </label>
                <Dropdown
                  name="st_CL_CD1"
                  value={st_CL_CD1}
                  options={st_CL_CD1Options}
                  onChange={(e) => onchangeDropDownFormData1("st_CL_CD1", e.value)}
                  placeholder="Select Class Code 01"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CL_CD2" className="font-bold">
                Class Code 2
                </label>
                <Dropdown
                  name="st_CL_CD2"
                  value={st_CL_CD2}
                  options={st_CL_CD2Options}
                  onChange={(e) => onchangeDropDownFormData2("st_CL_CD2", e.value)}
                  placeholder="Select Class Code 02"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CL_CD3" className="font-bold">
                Class Code 3
                </label>
                <Dropdown
                  name="st_CL_CD3"
                  value={st_CL_CD3}
                  options={st_CL_CD3Options}
                  onChange={(e) => onchangeDropDownFormData3("st_CL_CD3", e.value)}
                  placeholder="Select Class Code 03"
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CL_CD4" className="font-bold">
                Class Code 4
                </label>
                <Dropdown
                  name="st_CL_CD4"
                  value={st_CL_CD4}
                  options={st_CL_CD4Options}
                  onChange={(e) => onchangeDropDownFormData4("st_CL_CD4", e.value)}
                  placeholder="Select Class Code 04"
                />
                
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="st_CL_CD5" className="font-bold">
                Class Code 5
                </label>
                <Dropdown
                  name="st_CL_CD5"
                  value={st_CL_CD5}
                  options={st_CL_CD5Options}
                  onChange={(e) => onchangeDropDownFormData5("st_CL_CD5", e.value)}
                  placeholder="Select Class Code 05"
                />
              </div>
            </div>
            </div>
        </TabPanel>
      </TabView>
    </div>    
    </Dialog>
  );
}
