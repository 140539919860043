import React, { useState, useEffect,useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ContactOwnerPopup from "./ContactOwnerPopup";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { groupBy } from 'lodash';
import ButtonStyles from "views/common/ButtonStyles";
export default function SalesLeadDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  sl_LD_ST,
  sl_IN_RY,
  sl_CI_TY,
  sl_LD_SO,
  sl_AD_CY,
  // sl_ST_AT,
  slLdId,
  sl_TI_TL,
  sl_LD_CO,
  sl_LD_TY,
  sl_UK_NO,
  sl_CO_NM,
  sl_CN_NO,
  sl_TH_SC,
  selectedAbAdNo,
  selectedAbAdNp,
  setSelectedAbAdNo,
  setSelectedAbAdNp,
  selectedAbAdNM,
  selectedAbAdNN,
  setSelectedAbAdNM,
  setSelectedAbAdNN,
  sl_PR_ST,
  sl_AD_L1,
  sl_AD_L2,
  sl_PO_CD,
  sl_WB_SI,
  sl_AD_EM,
  sl_AD_P1,
  cc_PH_NO,
  cc_EM_IL,
  sl_QA_FN,
  setsl_QA_FN,
  setFormData,
  QualificationTableData,
  setQualificationTableData,
  onchangeDropDownFormData9,
  sl_LD_OW,
  lq_QA_AN,
  lq_QA_CM,
  lq_QA_QU,
  lq_QU_NO,
  selectedValues,
  setSelectedValues,
}) {
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,TabBoxStyle,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle,confirmstyle} = ButtonStyles();
  const [activeTab, setActiveTab] = useState(0);
  const toast = useRef(null);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeLead, setaddressTypeLead] = useState("E");
  const [addressTypePatner, setaddressTypePatner] = useState("P");
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] = useState(false);
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [PartnerPopupVisible, setPartnerPopupVisible] = useState(false);
  const [sl_in_ryOptions, setsl_in_ryOptions] = useState([]);
  const [sl_ld_stOptions, setsl_ld_stOptions] = useState([]);
  const [sl_ci_tyOptions, setsl_ci_tyOptions] = useState([]);
  const [sl_ad_cyOptions, setsl_ad_cyOptions] = useState([]);
  const [sl_st_atOptions, setsl_st_atOptions] = useState([]);
  const [sl_ld_soOptions, setsl_ld_soOptions] = useState([]);
  const [sl_TI_TLOptions, setsl_TI_TLOptions] = useState([]);
  const [sl_PR_STOptions, setsl_PR_STOptions] = useState([]);
  const [sl_QA_FNOptions, setsl_QA_FNOptions] = useState([]);
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData9(name, value, index);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };
  const openPartnerPopup = () => {
    setPartnerPopupVisible(true);
  };
  const { 
    sl_TR_DT,
    sl_LD_PA,
    sl_ST_AT,
    sl_QA_SC,
  } = data || {};
  
  const addRow = () => {
    setQualificationTableData([...QualificationTableData, {}]);
  };
  const deleteRow = () => {
    if (QualificationTableData.length > 0) {
      const updatedData = [...QualificationTableData];
      updatedData.pop();
      setQualificationTableData(updatedData);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const url =`${domain}/api/ClassCode/All`;
  const parameter = "LD/ST";
  const finalUrl = `${url}/${parameter}`;

  // const parameter1 = "FO/IN";
  // const finalUrl1 = `${url}/${parameter1}`;

  const parameter1 = "AB/11";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "FO/CI";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "FO/CY";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "AB/12";
  const finalUrl4 = `${url}/${parameter4}`;
  
  const parameter5 = "LD/SO";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "TI/TL";
  const finalUrl6 = `${url}/${parameter6}`;

  const parameter7 = "LE/ST";
  const finalUrl7 = `${url}/${parameter7}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    QualificatioN();
  }, []);

  const QualificatioN = async () => {
    const Qurl =`${domain}/api/LeadQualification/GetAllQualifications`;

    try {
      const response = await axios.get(Qurl, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.leadQualifications.map((item) => ({
        label: item.sqQfNm,
        value: item.sqQfNm,
      }));
      setsl_QA_FNOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_ld_stOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_in_ryOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'http://localhost:3000',
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_ci_tyOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_ad_cyOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_st_atOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };   
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_ld_soOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_TI_TLOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsl_PR_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // COVERT TO OPPOURTINY
  const  oppourtiny =  `${domain}/api/SalesLead/ConvertToOpportunity`
  const leadtooppourtunity = async () => {
    const request = {
        "sl_UK_NO" : document.getElementById("sl_UK_NO").value, 
        "oppo_TYPE" : document.getElementById("sl_LD_TY").value,
    }
    try {
      const response = await axios.post(oppourtiny, request, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const resdata = response.data.Result;
      if (resdata && response.data.infos) {
        const infoMessages = response.data.infos;
        infoMessages.forEach((infoMessage) => {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: infoMessage,
          });
        });
      }
      setTimeout(() => {
        handleClose();
      }, 500);
    } catch (error) {
      console.error("Error While Converting", error);
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: error.message,
      });
    }
  };

// QUALIFICATION ONCHANGE
const QualificatioNDropdown = async (name, value) => {
  setQualificationTableData([{}]);
  const ledgerDtls = [];
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setsl_QA_FN(value);
  const Url = `${domain}/api/SalesLead/onChangeOfQualification`;
  const requestData={
    "sl_QA_FN":value,
  }
  try {
    const response = await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataLead = response.data.data;

    if (dataLead && dataLead.length > 0) {
      const size = dataLead.length;
      for (let i = 0; i < size; i++){
        const ans = dataLead[i].qualificationAnswers;

        const options = ans.map((item) => ({
          label: item.sa_QA_AN,
          value: item.sa_QA_AN,
        }));
        // setanswerOptions(options);        
        const dtl = {
          "lq_QU_NO" : dataLead[i].sqSqNo,
          "lq_QA_QU" : dataLead[i].sq_QA_DS,
          "answerOptions" : options
        }
        ledgerDtls.push(dtl);
      }
      setQualificationTableData(ledgerDtls);

    } else {
      console.error("Invalid API response:" );
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const [SelectedRowIndex, setSelectedRowIndex] = useState("");

const onRowSelect = (index) => {
  setSelectedRowIndex(index); 
};

  const handleDataSelect =async (data) => {
    setSelectedAbAdNN(data.ab_AD_NM);
    // onchangeFormData("sl_CO_NM", data.abAdNo);
    document.getElementById("sl_CO_NM").value = data.abAdNo;
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.data;
      if (dataLead) {
        onchangeFormData("sl_AD_L1", dataLead.ab_AD_L1);
        onchangeFormData("sl_AD_L2", dataLead.ab_AD_L2);
        onchangeFormData("sl_AD_P1", dataLead.ab_AD_P1);
        onchangeFormData("sl_WB_SI", dataLead.ab_WB_SI);
        onchangeFormData("sl_PO_CD", dataLead.ab_AD_PC);
        onchangeFormData("sl_AD_EM", dataLead.ab_AD_EM);
        onchangeDropDownFormData2("sl_CI_TY", dataLead.ab_AD_CT);
        onchangeDropDownFormData3("sl_AD_CY", dataLead.ab_AD_CY);
        onchangeDropDownFormData1("sl_IN_RY", dataLead.ab_CL_11);
        onchangeDropDownFormData4("sl_ST_AT", dataLead.ab_CL_12);
        // onchangeFormData("sl_ST_AT", data.data.ab_CL_12);
        // AutoLeadAssignment(dataLead.ab_CL_11);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectContact =async (result) => {
    setSelectedAbAdNM(result.cc_CN_NM);
    // onchangeFormData("sl_CN_NO", result.ccCnNo);
    document.getElementById("sl_CN_NO").value = result.ccCnNo;
    const Url = `${domain}/api/Contact/onChangeOfContactPerson`;
    const requestData={
      "ccCnNo":result.ccCnNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const data = response.data;
  
      if (data && data.contact ) {
        onchangeDropDownFormData6("sl_TI_TL", data.contact.cc_DE_GN);
        onchangeFormData("cc_EM_IL", data.contact.cc_EM_IL);
        onchangeFormData("cc_PH_NO", data.contact.cc_PH_NO);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 =async (data) => {
    setSelectedAbAdNo(data.ab_AD_NM);
    onchangeFormData("sl_LD_OW", data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect3 = (data) => {
    setSelectedAbAdNp(data.ab_AD_NM);
    onchangeFormData("sl_LD_PA", data.abAdNo);
    setPartnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sl_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // product interrest
  const [totalPages, setTotalPages] = useState();
  const [tableData, setTableData] = useState(null);


  const handleCheckboxChange = (event, rowData,id) => {
    const { checked, value } = event.target;
    // setFormData(prevFormData => ({ ...prevFormData, [name]: value }));

    if (checked) {
      document.getElementById(id).setAttribute('checked','checked');

    } else {
      document.getElementById(id).removeAttribute('checked');
    }

    const oneRow = {
      "id" : id,
      "checkedValue" : checked
    }
    const existingData = selectedValues;
    const newData = [];
    newData.push(oneRow);

    setSelectedValues(newData);

    // setSelectedValues(prevFormData => {
    //   if (checked) {
    //     document.getElementById(id).setAttribute('checked','checked');
    //     document.getElementById(id+"-1").value = 5;
    //     //return [...prevFormData, { piUkNo: value, pi_PR_CT: rowData.pi_PR_CT, pi_PR_NO: rowData.pi_PR_NO }];

    //   } else {
    //     document.getElementById(id).removeAttribute('checked');
    //     document.getElementById(id+'-1').value = 4;
    //     //return prevFormData.filter(item => item.piUkNo !== value);
    //   }
    // });
  };

  useEffect(() => {
    ProductInterest();
  }, []);

  const ProductInterest = async () => {
    const requestBody=
      {
        "searchRequestDto":[]
    }
    try {
      const response = await axios.post(`${domain}/api/ProductInterest/List`, requestBody, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const listData = response.data;

      if (Array.isArray(listData) && listData.length > 0) {
        setTableData(listData);

      } else {
        console.error("Invalid API response:", listData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }  
  };
  const handletabchange = (e) => {
    setActiveTab(e.index);
    if(e.index === 2){
      const completeTableDate = tableData;
      const sizeComplete = completeTableDate.length;
      // alert(completeTableDate.length);

      const sizeSelected = selectedValues.length;
      // alert(sizeSelected);

      for (let i = 0; i < sizeComplete; i++){
        for (let j = 0; j < sizeSelected; j++){
        }
      }
    }
  };

  const isUpdateMode = Boolean(sl_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sl_UK_NO ? "Revise Sales Lead" : "Add Sales Lead"}</h5>
        <div style={{ display: 'flex', justifyContent:'space-between',width:'65%' }}>
          <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
            <Button
              style={savestyle}
              icon="pi pi-check"
              onClick={handleFormSubmit} 
               tooltip="Save"
            />
            <Button
              style={cancelstyle}
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Cancel"
            />
             {isUpdateMode && (
            <Button 
            style={confirmstyle}
            icon="pi pi-circle" 
            variant="outlined" 
            onClick={leadtooppourtunity}
            tooltip={"Convert"}
            >
          </Button>
          )}
          </div>
      </div>
    </div>
  );
  // const dialogHeader = (
  //   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  //     <h5>{sl_UK_NO ? "Revise Sales Lead" : "Add Sales Lead"}</h5>
  //     <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
     
  //       {/* {activeTab === 0 && ( */}
  //       <Button
  //         style={savestyle}
  //         icon="pi pi-check"
  //         onClick={handleFormSubmit} 
  //         tooltip="Save"
  //       />
  //       {/* )}
  //          {activeTab === 0 && ( */}
  //       <Button 
  //         style={cancelstyle}
  //         icon="pi pi-times" 
  //         variant="outlined" 
  //         onClick={handleClose}
  //         tooltip="Cancel"
  //       >
  //       </Button>
  //          {/* )} */}
  //       <Button 
  //       style={{ display: isUpdateMode ? 'block' : 'none' ,width:"5%",marginLeft:"82%",marginBottom:"-5%"}}
  //       icon="pi pi-circle-on" 
  //       variant="outlined" 
  //       onClick={leadtooppourtunity}
  //       tooltip={"Convert"}
  //       >
  //       </Button>
  //     </div>
  //   </div>
  // );
  const tableRef = useRef(null);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <Toast ref={toast} />
      <div style={TabBoxStyle}> 
          <TabView activeIndex={activeTab} onTabChange={(e) => handletabchange(e)}>
          <TabPanel header="Lead" >
            <div style={borderbox} >
              <div style={inboxstyle}>
                <div className="formgrid grid" >
                <div className="field col-sm" style={{display : "none"}}>
                    <label htmlFor="sl_UK_NO" className="font-bold">
                      UK_NO
                    </label>
                    <InputText disabled
                      id="sl_UK_NO"
                      name="sl_UK_NO"
                      value={sl_UK_NO}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="slLdId" className="font-bold">
                      Lead ID
                    </label>
                    <InputText disabled
                      id="slLdId"
                      name="slLdId"
                      value={slLdId}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_LD_TY" className="font-bold">
                      Type
                    </label>
                    <InputText disabled
                      id="sl_LD_TY"
                      name="sl_LD_TY"
                      defaultValue={sl_LD_TY}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_LD_CO" className="font-bold">
                      Company
                    </label>
                    <InputText disabled
                      id="sl_LD_CO"
                      name="sl_LD_CO"
                      defaultValue={sl_LD_CO}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_CO_NM" className="font-bold">
                      Customer <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <InputText
                        id="ab_AD_NM"
                        name="ab_AD_NM"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={selectedAbAdNN}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openCustomerPopup}
                      ></i>
                      )}
                    </div>
                    <div  style={{ display: "none" }}>
                      <InputText
                        id="sl_CO_NM"
                        name="sl_CO_NM"
                        defaultValue={sl_CO_NM}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_LD_SO" className="font-bold">
                      Source
                    </label>
                    <Dropdown
                    id="sl_LD_SO"
                      name="sl_LD_SO"
                      value={sl_LD_SO}
                      options={sl_ld_soOptions}
                      onChange={(e) => onchangeDropDownFormData5("sl_LD_SO", e.value)}
                      placeholder="Select Source"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_TR_DT" className="font-bold">
                      Date
                    </label>
                    <Calendar
                      id="sl_TR_DT"
                      name="sl_TR_DT"
                      //value={selectedDate}
                      value={moment(sl_TR_DT, "YYYYMMDD").toDate()}
                      onChange={handleDateChange}
                      // onChange={(e) => onchangedata({ target: { name: "sl_TR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_CN_NO" className="font-bold">
                      Contact <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <InputText
                        id="cc_EM_NO"
                        name="cc_EM_NO"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={selectedAbAdNM}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openContactOwnerPopup}
                      ></i>
                      )}
                    </div>
                    <div style={{ display: "none" }} >
                      <InputText
                        id="sl_CN_NO"
                        name="sl_CN_NO"
                        defaultValue={sl_CN_NO}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_TI_TL" className="font-bold">
                      Title
                    </label>
                    <Dropdown disabled
                    id="sl_TI_TL"
                      name="sl_TI_TL"
                      value={sl_TI_TL}
                      options={sl_TI_TLOptions}
                      onChange={(e) => onchangeDropDownFormData6("sl_TI_TL", e.value)}
                      placeholder="Select Title"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_PH_NO" className="font-bold">
                      Phone No
                    </label>
                    <InputText disabled
                      id="cc_PH_NO"
                      name="cc_PH_NO"
                      value={cc_PH_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_LD_OW" className="font-bold">
                      Lead Owner<span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                      <InputText 
                        id="ab_AD_NM"
                        name="ab_AD_NM"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}                
                        value={selectedAbAdNo}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openLeadOwnerPopup}
                      ></i>
                      )}
                    </div>
                    <div style={{ display: "none" }} >
                      <InputText disabled
                        id="sl_LD_OW"
                        name="sl_LD_OW"
                        defaultValue={sl_LD_OW}
                      />
                    </div>
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_LD_ST" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                    id="sl_LD_ST"
                      name="sl_LD_ST"
                      value={sl_LD_ST}
                      options={sl_ld_stOptions}
                      onChange={(e) => onchangeDropDownFormData("sl_LD_ST", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cc_EM_IL" className="font-bold">
                      Email
                    </label>
                    <InputText disabled
                      id="cc_EM_IL"
                      name="cc_EM_IL"
                      value={cc_EM_IL}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_TH_SC" className="font-bold">
                    Threshold Score
                    </label>
                    <InputText
                      id="sl_TH_SC"
                      name="sl_TH_SC"
                      defaultValue={sl_TH_SC}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sl_QA_SC" className="font-bold">
                    Qualification Score
                    </label>
                    <InputText disabled
                      id="sl_QA_SC"
                      name="sl_QA_SC"
                      value={sl_QA_SC}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>

                </div>
            {/* <div className="formgrid grid">  
              <div className="field col">
                <label htmlFor="sl_IN_RY" className="font-bold">
                  Industry
                </label>
                <Dropdown
                id="sl_IN_RY"
                  name="sl_IN_RY"
                  value={sl_IN_RY}
                  options={sl_in_ryOptions}
                  onChange={(e) => onchangeDropDownFormData1("sl_IN_RY", e.value)}
                  placeholder="Select Industry"
                />
              </div>
            </div> */}
            <div className="field col" style={{display:"none"}}>
              <label htmlFor="sl_CO_CT" className="font-bold">
                COST CENTER
              </label>
              <InputText
                id="sl_CO_CT"
                name="sl_CO_CT"
                value={"M100"}
              />
            </div>
          </div>
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_AD_L1" className="font-bold">
                  Address
                </label>
                <InputText disabled
                  id="sl_AD_L1"
                  name="sl_AD_L1"
                  value={sl_AD_L1}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_AD_L2" className="font-bold">
                  Street
                </label>
                <InputText disabled
                  id="sl_AD_L2"
                  name="sl_AD_L2"
                  value={sl_AD_L2}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_CI_TY" className="font-bold">
                  City
                </label>
              <Dropdown disabled
              id="sl_CI_TY"
                name="sl_CI_TY"
                value={sl_CI_TY}
                options={sl_ci_tyOptions}
                onChange={(e) => onchangeDropDownFormData2("sl_CI_TY", e.value)}
                placeholder="Select City"
              />
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_AD_P1" className="font-bold">
                  Phone No
                </label>
                <InputText disabled
                  id="sl_AD_P1"
                  name="sl_AD_P1"
                  value={sl_AD_P1}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_AD_EM" className="font-bold">
                  Email
                </label>
                <InputText disabled
                  id="sl_AD_EM"
                  name="sl_AD_EM"
                  value={sl_AD_EM}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_PO_CD" className="font-bold">
                  Postal Code
                </label>
                <InputText disabled
                  id="sl_PO_CD"
                  name="sl_PO_CD"
                  value={sl_PO_CD}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_WB_SI" className="font-bold">
                  Website
                </label>
                <InputText disabled
                  id="sl_WB_SI"
                  name="sl_WB_SI"
                  value={sl_WB_SI}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_AD_CY" className="font-bold">
                  Country
                </label>
                <Dropdown disabled
                id="sl_AD_CY"
                  name="sl_AD_CY"
                  value={sl_AD_CY}
                  options={sl_ad_cyOptions}
                  onChange={(e) => onchangeDropDownFormData3("sl_AD_CY", e.value)}
                  placeholder="Select Country"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_IN_RY" className="font-bold">
                  Industry
                </label>
                <Dropdown disabled
                id="sl_IN_RY"
                  name="sl_IN_RY"
                  value={sl_IN_RY}
                  options={sl_in_ryOptions}
                  onChange={(e) => onchangeDropDownFormData1("sl_IN_RY", e.value)}
                  placeholder="Select Industry"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_LD_PA" className="font-bold">
                  Partner<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ab_LD_PA"
                    name="ab_LD_PA"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)} 
                    value={selectedAbAdNp}  
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openPartnerPopup}
                  ></i>
                  )}
                </div>
                <div style={{ display: "none" }}>
                  <InputText
                    id="sl_LD_PA"
                    name="sl_LD_PA"
                    value={sl_LD_PA}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_PR_ST" className="font-bold">
                Stage
                </label>
                <Dropdown
                id="sl_PR_ST"
                  name="sl_PR_ST"
                  value={sl_PR_ST}
                  options={sl_PR_STOptions}
                  onChange={(e) => onchangeDropDownFormData7("sl_PR_ST", e.value)}
                  placeholder="Select Stage"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_ST_AT" className="font-bold">
                  Vertical
                </label>
                <Dropdown disabled
                id="sl_ST_AT"
                  name="sl_ST_AT"
                  value={sl_ST_AT}
                  options={sl_st_atOptions}
                  onChange={(e) => onchangeDropDownFormData4("sl_ST_AT", e.value)}
                  placeholder="Select Vertical"
                />
              </div>
            </div>
          </div>
          </div>
          </TabPanel>
          {/* QUALIFICATION TABLE */}
          <TabPanel header="Qualification">
          <div style={borderbox}  >
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sl_QA_FN" className="font-bold">
                  Qualification
                </label>
                <Dropdown
                  id="sl_QA_FN"
                  name="sl_QA_FN"
                  value={sl_QA_FN}
                  options={sl_QA_FNOptions}
                  onChange={(e) => QualificatioNDropdown("sl_QA_FN", e.value)}
                  placeholder="Select Qualification"
                />
              </div>
            </div>
            
          </div>
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="SalesQualificationtable" ref={tableRef} >
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Question No</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {QualificationTableData.map((row, index) => (
                  <tr key={index}  onClick={() => onRowSelect(index)}> 
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`lq_UK_NO_${index}`}
                        name={`lq_UK_NO_${index}`}
                        value={row.lq_UK_NO}
                      />
                    </td>
                    <td >
                      <InputText  disabled
                        id={`lq_QU_NO_${index}`}
                        name={`lq_QU_NO_${index}`}
                        defaultValue={row.lq_QU_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled 
                        id={`lq_QA_QU_${index}`}
                        name={`lq_QA_QU_${index}`}
                        defaultValue={row.lq_QA_QU}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <Dropdown
                        id={`lq_QA_AN_${index}`}
                        name={`lq_QA_AN_${index}`}
                        value={ data[`lq_QA_AN_${index}`] ? data[`lq_QA_AN_${index}`]: row.lq_QA_AN}
                        options={row.answerOptions}
                        onChange={(e) => handleFormDataChange(`lq_QA_AN_${index}`, e.value)}
                        placeholder="Select Answer"
                      />
                    </td>
                    <td >
                      <InputText  
                        id={`lq_QA_CM_${index}`}
                        name={`lq_QA_CM_${index}`}
                        value={data[`lq_QA_CM_${index}`] ? data[`lq_QA_CM_${index}`] : row.lq_QA_CM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
          </div>
          </TabPanel>
          {/* <TabPanel header="Product Interest">
            <div className="tableData" style={{ marginTop: "5%" }}>
              <Toast ref={toast} />
                <div className="card" style={{backgroundColor:"#F8F9F9"}}>
                  <div className="groupContainer" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ overflowX: "auto", maxWidth: "100%",display:"contents",backgroundColor:"#F2F4F4"}}>
                      {tableData && (
                        Object.entries(groupBy(tableData, "pi_PR_TY")).map(([pi_PR_TY, group], index) => (
                          <div key={index}>
                            <table>
                              <thead>
                                  <h3
                                  style={{fontWeight:"bold",color:"#0099FF"}}
                                  >{pi_PR_TY}</h3>
                              </thead>
                              <tbody>
                                {group.map((rowData) => (
                                  <tr key={rowData.piUkNo}>
                                    <td>
                                      <input
                                        style={{ width: "10% !important" }}
                                        type="checkbox"
                                        value={rowData.piUkNo}
                                      />
                                    </td>
                                    <td>{rowData.pi_PR_NM}</td>
                                    <td>{rowData.pi_PR_CT}</td>
                                    <td>{rowData.pi_PR_NO}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </TabPanel> */}
            <TabPanel header="Product Interest">
          <div className="tableData" style={{ marginTop: "5%" }}>
            <Toast ref={toast} />
            <div className="card" style={{ backgroundColor: "#F8F9F9" }}>
              <div className="groupContainer" style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ overflowX: "auto", maxWidth: "100%", display: "contents", backgroundColor: "#F2F4F4" }}>
                  {tableData && (
                    Object.entries(groupBy(tableData, "pi_PR_TY")).map(([pi_PR_TY, group], index) => (
                      <div key={index}>
                        <table>
                          <thead>
                            <tr>
                              <th colSpan="4" style={{ fontWeight: "bold", color: "#0099FF" }}>{pi_PR_TY}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {group.map((rowData) => (
                              <tr key={rowData.piUkNo}>
                                <td>
                                  <input
                                    id={rowData.pi_PR_CT.toString()+rowData.pi_PR_NO.toString()}
                                    type="checkbox"
                                    value={rowData.piUkNo}
                                    onChange={(event) => handleCheckboxChange(event, rowData, rowData.pi_PR_CT.toString()+rowData.pi_PR_NO.toString())}
                                  />
                                </td>
                                <td>{rowData.pi_PR_NM}</td>
                                {/* Hidden input fields to store the data without displaying */}
                                <input type="hidden" value={rowData.pi_PR_CT} />
                                <input type="hidden" value={rowData.pi_PR_NO} />
                                <input value={rowData.pi_PR_NO} id={rowData.pi_PR_CT.toString()+rowData.pi_PR_NO.toString()+"-1"} style={{display:'none'}}/>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
            </TabPanel>
          </TabView>
      </div>
      {/* CUSTOMER */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelectContact}
        formData={data}
      />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeLead}
        setFormData={setFormData}
      />
      {/* patner */}
      <AddressBookPopup
        visible={PartnerPopupVisible}
        onClose={() => setPartnerPopupVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
        addressType={addressTypePatner}
        setFormData={setFormData}
      />
    </Dialog>
  );
}

