import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BaggingProcessDialog from "./BaggingProcessDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  bdUkNo:"",
  bd_OR_NO:"",
  bd_OR_TY:"",
  bd_OR_CO:"",   
  bd_BG_NO:"",
  bd_WO_QT:"",
  bd_WO_UM:"", 
  bd_DE_NO:"",
  design_DESC:"",  
  bd_TR_DT:"",   
  bd_IT_NM:"",
  item_NAME:"",
  bd_TO_LO:"",   
  bd_OR_QT:"",   
  bd_TR_UM:"",   
  bd_BG_QT:"",    
  bd_TR_QT:"",   
  bd_RT_QT:"",   
  bd_BL_QT:"",   
  bd_RL_NO:"",   
  bd_RL_TY:"",  
};

const initialFilters = {
  bd_OR_NO: { value: "", operation: "" },
  bd_OR_TY: { value: "", operation: "" },
  bd_OR_CO: { value: "", operation: "" },   
  bd_BG_NO: { value: "", operation: "" },
  bd_WO_QT: { value: "", operation: "" },
  bd_WO_UM: { value: "", operation: "" }, 
  bd_DE_NO: { value: "", operation: "" },
  design_DESC: { value: "", operation: "" },  
  bd_TR_DT: { value: "", operation: "" },   
  bd_IT_NM: { value: "", operation: "" },
  item_NAME: { value: "", operation: "" },
  bd_TO_LO: { value: "", operation: "" },   
  bd_OR_QT: { value: "", operation: "" },   
  bd_TR_UM: { value: "", operation: "" },   
  bd_BG_QT: { value: "", operation: "" },    
  bd_TR_QT: { value: "", operation: "" },   
  bd_RT_QT: { value: "", operation: "" },   
  bd_BL_QT: { value: "", operation: "" },   
  bd_RL_NO: { value: "", operation: "" },   
  bd_RL_TY: { value: "", operation: "" },   
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [ep_PO_NM, setep_PO_NM ] = useState("");
  const [ep_TR_CR, setep_TR_CR ] = useState("");
  const [ep_EX_CT, setep_EX_CT ] = useState("");
  const [ep_EX_TY, setep_EX_TY ] = useState("");
  const [ep_LO_CN, setep_LO_CN ] = useState("");


  const [bd_TO_LO, setbd_TO_LO ] = useState("");
  const [bd_TR_UM, setbd_TR_UM ] = useState("");
  const [bd_SC_UM, setbd_SC_UM ] = useState("");
  const [bd_FR_LO, setbd_FR_LO ] = useState("");
  const [bd_CO_CT, setbd_CO_CT ] = useState("");
  const [bd_DM_RS, setbd_DM_RS ] = useState("");
  const [bd_DM_LO, setbd_DM_LO ] = useState("");

  
// AdvanceSearch
  const [selectedSupplier, setselectedSupplier ] = useState("");
  const [SelectedOrder, setSelectedOrder ] = useState("");
  const [ep_PR_SU, setep_PR_SU ] = useState("");
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [BaggingProcessTableData, setBaggingProcessTableData] = useState([{}]);


  const LocationDropdown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbd_TO_LO(value);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };



  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setBaggingProcessTableData([{}]);
    setbd_TO_LO(null);
    setbd_TR_UM(null);
    setbd_SC_UM(null);
    setbd_FR_LO(null);
    setbd_CO_CT(null);
    setbd_DM_LO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/BagDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.bagDetailsList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.bdUkNo;
            return UpdatedworkData;
          });
          setBaggingProcessTableData(updatedCashFlowData); 
          setbd_TO_LO(response.data.bd_TO_LO);
          setbd_TR_UM(CashFlowData.bagDetailsList.bd_TR_UM);
          setbd_SC_UM(CashFlowData.bagDetailsList.bd_SC_UM);
          setbd_FR_LO(CashFlowData.bagDetailsList.bd_FR_LO);
          setbd_CO_CT(CashFlowData.bagDetailsList.bd_CO_CT);
          setbd_DM_LO(CashFlowData.bagDetailsList.bd_DM_LO);
          setSelectedOrder(CashFlowData.bagDetailsList.bd_OR_NO);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ep_CO_NO , setep_CO_NO] = useState("");

  const handleDefault = async () => {
    setep_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BagDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/BagDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setBaggingProcessTableData(CashFlowData.bagDetailsList);
        setFormData(CashFlowData.bagDetailsList[0]);
        setbd_TO_LO(response.data.bd_TO_LO);
        setbd_TR_UM(CashFlowData.bagDetailsList.bd_TR_UM);
        setbd_SC_UM(CashFlowData.bagDetailsList.bd_SC_UM);
        setbd_FR_LO(CashFlowData.bagDetailsList.bd_FR_LO);
        setbd_CO_CT(CashFlowData.bagDetailsList.bd_CO_CT);
        setbd_DM_LO(CashFlowData.bagDetailsList.bd_DM_LO);
        setSelectedOrder(CashFlowData.bd_OR_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("BaggingProcesstable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const bagDetailsList = [];
  for (let i = 0; i < totalRowCount1; i++) {
   
    const details = {
        bdUkNo : document.getElementById("bdUkNo_" + i).value,
        bd_LN_NO : document.getElementById("bd_LN_NO_" + i).value,
        bd_IT_NM : document.getElementById("bd_IT_NM_" + i).value,
        item_DESC : document.getElementById("item_DESC_" + i).value,
        bd_OR_QT : document.getElementById("bd_OR_QT_" + i).value,
        bd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        bd_SC_QT : document.getElementById("bd_SC_QT_" + i).value,
        bd_SC_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        bd_FR_LO : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        bd_AV_QT : document.getElementById("bd_AV_QT_" + i).value,
        bd_CO_CT : table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
        bd_CT_TR : document.getElementById("bd_CT_TR_" + i).value,
        bd_ST_TR : document.getElementById("bd_ST_TR_" + i).value,
        bd_DM_RS : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
        bd_DM_LO : table.tBodies[0].rows[i].cells[14].children[0].children[1].children[0].value,
        bd_UN_CT : document.getElementById("bd_UN_CT_" + i).value,
        bd_EX_CT : document.getElementById("bd_EX_CT_" + i).value,
        bd_FR_CT : document.getElementById("bd_FR_CT_" + i).value,
        bd_FR_AM : document.getElementById("bd_FR_AM_" + i).value,
    };
    bagDetailsList.push(details);
  }
 requestBody = {
  bagDetailsList,
    bdUkNo:FormData.bdUkNo,
    bd_OR_NO: document.getElementById("bd_OR_NO").value,
    bd_OR_TY: document.getElementById("bd_OR_TY").value, 
    bd_OR_CO: document.getElementById("bd_OR_CO").value,
    bd_TR_DT: document.getElementById("bd_TR_DT").value,
    bd_RL_NO: document.getElementById("bd_RL_NO").value,
    bd_RL_TY: document.getElementById("bd_RL_TY").value,
    bd_RL_CO: document.getElementById("bd_RL_CO").value,
    bd_OR_DT: document.getElementById("bd_OR_DT").value,
    bd_DE_NO: document.getElementById("bd_DE_NO").value,
    bd_RQ_DT: document.getElementById("bd_RQ_DT").value,
    bd_WO_QT: document.getElementById("bd_WO_QT").value,
    bd_CO_QT: document.getElementById("bd_CO_QT").value,
    bd_BG_QT: document.getElementById("bd_BG_QT").value,
    bd_BL_QT: document.getElementById("bd_BL_QT").value,
    bd_SC_QT: document.getElementById("bd_SC_QT").value,
    bd_WT_QT: document.getElementById("bd_WT_QT").value,
    bd_BG_NO: document.getElementById("bd_BG_NO").value,
    bd_TO_LO: bd_TO_LO
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.bdUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/BagDetails/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.ep_CO_NO = company;
      axios
        .post(`${domain}/api/BagDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_OR_NO"] ? filters["bd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OR_NO",
                        e.target.value,
                        filters["bd_OR_NO"]
                          ? filters["bd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_OR_TY"] ? filters["bd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OR_TY",
                        e.target.value,
                        filters["bd_OR_TY"] ? filters["bd_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_OR_CO"] ? filters["bd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OR_CO",
                        e.target.value,
                        filters["bd_OR_CO"]
                          ? filters["bd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["bd_BG_NO"] ? filters["bd_BG_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BG_NO",
                        e.target.value,
                        filters["bd_BG_NO"]
                          ? filters["bd_BG_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_WO_QT"] ? filters["bd_WO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_WO_QT",
                        e.target.value,
                        filters["bd_WO_QT"] ? filters["bd_WO_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_WO_UM"] ? filters["bd_WO_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_WO_UM",
                        e.target.value,
                        filters["bd_WO_UM"]
                          ? filters["bd_WO_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["bd_DE_NO"] ? filters["bd_DE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_DE_NO",
                        e.target.value,
                        filters["bd_DE_NO"]
                          ? filters["bd_DE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["design_DESC"] ? filters["design_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "design_DESC",
                        e.target.value,
                        filters["design_DESC"]
                          ? filters["design_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_TR_DT"] ? filters["bd_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_TR_DT",
                        e.target.value,
                        filters["bd_TR_DT"]
                          ? filters["bd_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_IT_NM"] ? filters["bd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_IT_NM",
                        e.target.value,
                        filters["bd_IT_NM"]
                          ? filters["bd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["item_NAME"] ? filters["item_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_NAME",
                        e.target.value,
                        filters["item_NAME"]
                          ? filters["item_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_TO_LO"] ? filters["bd_TO_LO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_TO_LO",
                        e.target.value,
                        filters["bd_TO_LO"]
                          ? filters["bd_TO_LO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_OR_QT"] ? filters["bd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_OR_QT",
                        e.target.value,
                        filters["bd_OR_QT"]
                          ? filters["bd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_TR_UM"] ? filters["bd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_TR_UM",
                        e.target.value,
                        filters["bd_TR_UM"]
                          ? filters["bd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_BG_QT"] ? filters["bd_BG_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BG_QT",
                        e.target.value,
                        filters["bd_BG_QT"]
                          ? filters["bd_BG_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_TR_QT"] ? filters["bd_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_TR_QT",
                        e.target.value,
                        filters["bd_TR_QT"]
                          ? filters["bd_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_RT_QT"] ? filters["bd_RT_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_RT_QT",
                        e.target.value,
                        filters["bd_RT_QT"]
                          ? filters["bd_RT_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_BL_QT"] ? filters["bd_BL_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_QT",
                        e.target.value,
                        filters["bd_BL_QT"]
                          ? filters["bd_BL_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_RL_NO"] ? filters["bd_RL_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_RL_NO",
                        e.target.value,
                        filters["bd_RL_NO"]
                          ? filters["bd_RL_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_RL_TY"] ? filters["bd_RL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_RL_TY",
                        e.target.value,
                        filters["bd_RL_TY"]
                          ? filters["bd_RL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>  
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Bag No</th>
                <th style={tableHeaderStyle}>Order Quantity</th>  
                <th style={tableHeaderStyle}>Um</th>   
                <th style={tableHeaderStyle}>Design No</th>  
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Date</th>  
                <th style={tableHeaderStyle}>Item Name</th>  
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Location</th>   
                <th style={tableHeaderStyle}>Quantity</th>  
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>Bag Quantity</th> 
                <th style={tableHeaderStyle}>Transferred Quantity</th>        
                <th style={tableHeaderStyle}>Return Quantity</th>   
                <th style={tableHeaderStyle}>Balance Quantity</th>   
                <th style={tableHeaderStyle}>Sales Order</th>   
                <th style={tableHeaderStyle}>Type</th>     

              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.bdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.bdUkNo}
                        checked={selectedRows.includes(rowData.bdUkNo)}
                        onChange={() => handleRowSelect(rowData.bdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.bd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.bd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.bd_OR_CO}</td>   
                    <td style={tableCellStyle}>{rowData.bd_BG_NO}</td>
                    <td style={tableCellStyle}>{rowData.bd_WO_QT}</td>
                    <td style={tableCellStyle}>{rowData.bd_WO_UM}</td> 
                    <td style={tableCellStyle}>{rowData.bd_DE_NO}</td>
                    <td style={tableCellStyle}>{rowData.design_DESC}</td>  
                    <td style={tableCellStyle}>{formatDate(rowData.bd_TR_DT)}</td>   
                    <td style={tableCellStyle}>{rowData.bd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_NAME}</td>
                    <td style={tableCellStyle}>{rowData.bd_TO_LO}</td>   
                    <td style={tableCellStyle}>{rowData.bd_OR_QT}</td>   
                    <td style={tableCellStyle}>{rowData.bd_TR_UM}</td>   
                    <td style={tableCellStyle}>{rowData.bd_BG_QT}</td>    
                    <td style={tableCellStyle}>{rowData.bd_TR_QT}</td>   
                    <td style={tableCellStyle}>{rowData.bd_RT_QT}</td>   
                    <td style={tableCellStyle}>{rowData.bd_BL_QT}</td>   
                    <td style={tableCellStyle}>{rowData.bd_RL_NO}</td>   
                    <td style={tableCellStyle}>{rowData.bd_RL_TY}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <BaggingProcessDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        BaggingProcessTableData={BaggingProcessTableData}
        setBaggingProcessTableData={setBaggingProcessTableData}
        ep_CO_NO={ep_CO_NO}
        ep_PR_SU={ep_PR_SU}
        ep_TR_CR={ep_TR_CR}
        setep_TR_CR={setep_TR_CR}
        ep_PO_NM={ep_PO_NM}
        setep_PO_NM={setep_PO_NM}
        ep_EX_CT={ep_EX_CT}
        setep_EX_CT={setep_EX_CT}
        ep_EX_TY={ep_EX_TY}
        setep_EX_TY={setep_EX_TY}
        ep_LO_CN={ep_LO_CN}
        setep_LO_CN={setep_LO_CN}
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        SelectedOrder={SelectedOrder}
        setSelectedOrder={setSelectedOrder}
        bd_TO_LO={bd_TO_LO}
        setbd_TO_LO={setbd_TO_LO}
        bd_TR_UM={bd_TR_UM}
        setbd_TR_UM={setbd_TR_UM}
        bd_SC_UM={bd_SC_UM}
        setbd_SC_UM={setbd_SC_UM}
        bd_FR_LO={bd_FR_LO}
        setbd_FR_LO={setbd_FR_LO}
        bd_CO_CT={bd_CO_CT}
        setbd_CO_CT={setbd_CO_CT}
        bd_DM_RS={bd_DM_RS}
        setbd_DM_RS={setbd_DM_RS}
        bd_DM_LO={bd_DM_LO}
        setbd_DM_LO={setbd_DM_LO}
        LocationDropdown={LocationDropdown}
      />
    </div>
  );
};

export default WorkCentreMain;
