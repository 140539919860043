import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function FlexibleAccountingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  FlexibleAccountingTableData,
  setFlexibleAccountingTableData,
  onchangeDropDownFormDataCostCentre,
  fa_OR_TY,
  setfa_OR_TY,
  fa_FL_NM,
  setfa_FL_NM,
  fa_OR_CO,
}) {
  const { faUkNo, fa_PO_IT,} = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, savestyle,  cancelstyle, Dialogstyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS } = ClassCode();
  const [fa_OR_TYOptions, setfa_OR_TYOptions] = useState([]);
  const [fa_FL_NMOptions, setfa_FL_NMOptions] = useState([]);
  // Order Type
  const onchangeDropDownFormDataOrderType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfa_OR_TY(value);
  };
  // File Name
  const onchangeDropDownFormDataFileName = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setfa_FL_NM(value);
  };
  useEffect(() => {
    FileName();
    OrderType();
  }, []);
  const OrderType = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setfa_OR_TYOptions(Options);
  };
  const FileName = async () => {
    const Options = await fetchClassCodeDS("FA/FN");
    setfa_FL_NMOptions(Options);
  };
  const addRow = () => {
    setFlexibleAccountingTableData([...FlexibleAccountingTableData, {}]);
  };
  const deleteRow = () => {
    if (FlexibleAccountingTableData.length > 0) {
      const updatedData = [...FlexibleAccountingTableData];
      updatedData.pop();
      setFlexibleAccountingTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(faUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{faUkNo ? "Revise Flexible Accounting" : "Add Flexible Accounting"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div  className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fa_PO_IT" className="font-bold">
              Posting Code
              </label>
              <InputText 
                id="fa_PO_IT"
                name="fa_PO_IT"
                value={fa_PO_IT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div  className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fa_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="fa_OR_CO"
                name="fa_OR_CO"
                value={fa_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fa_OR_TY" className="font-bold"> 
              Order Type
              </label>
              <Dropdown
                name="fa_OR_TY"
                value={fa_OR_TY}
                options={fa_OR_TYOptions}
                onChange={(e) => onchangeDropDownFormDataOrderType("fa_OR_TY", e.value)}
                placeholder=" Order Type"
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="FlexibleAccountingtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Cost Centre	</th>
                  <th>File Name</th>
                  <th>Data Item</th>
                </tr>
              </thead>
              <tbody>
                {FlexibleAccountingTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`faUkNo_${index}`}
                        name={`faUkNo_${index}`}
                        value={row.faUkNo}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`fa_CO_NM_${index}`}
                        name={`fa_CO_NM_${index}`}
                        value={data[`fa_CO_NM_${index}`] ? data[`fa_CO_NM_${index}`] : row.fa_CO_NM}
                        options={[
                          { label: "Yes", value: "y" },
                          { label: "No", value: "n" },
                          ]}
                        onChange={(e) => onchangeDropDownFormDataCostCentre(`fa_CO_NM_${index}`, e.value)}
                        placeholder="Cost center"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`fa_FL_NM_${index}`}
                        name={`fa_FL_NM_${index}`}
                        value={data[`fa_FL_NM_${index}`] ? data[`fa_FL_NM_${index}`] : row.fa_FL_NM}
                        options={fa_FL_NMOptions}
                        onChange={(e) => onchangeDropDownFormDataFileName(`fa_FL_NM_${index}`, e.value)}
                        placeholder="File Name"
                      />
                    </td>
                    <td >
                      <InputText
                        id={`fa_DA_IT_${index}`}
                        name={`fa_DA_IT_${index}`}
                        defaultValue={row.fa_DA_IT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
