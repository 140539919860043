import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function ParentCostSimulationDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ParentCostSimulationTable,
  setParentCostSimulationTable,
  ParentCostSimulationTable1,
  setParentCostSimulationTable1,


  handleConfirm,
  material_TOT,



  // ADSVANCSE SEACRH
  SelectedItem,
  setSelectedItem,

  SelectedITNM,
  setSelectedITNM,
  ph_CO_CT,
  setph_CO_CT,
  pm_IT_UM,
  setpm_IT_UM,
  pm_ST_TY,
  setpm_ST_TY,
  pm_OP_SQ,
  setpm_OP_SQ,
  pm_ST_CD,
  setpm_ST_CD,
  pl_WK_CT,
  setpl_WK_CT,
  pl_OP_SQ,
  setpl_OP_SQ,

}) {
  const {
    phUkNo,
    ph_CO_IT,
    ph_CO_QT,
    ph_UN_CT,
    ph_CO_AM,
    material,
    machine,
    labour,
    overhead,
   
  } = data;

  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, footergridstyle, footerfieldstyle, Dialogstyle, cancelstyle, savestyle} = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sd_DA_TE", value: moment(e.value).format("YYYYMMDD") } });

  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();



  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [pm_IT_UMOptions, setpm_IT_UMOptions] = useState([]);
  const [pm_ST_TYOptions, setpm_ST_TYOptions] = useState([]);
  const [pm_ST_CDOptions, setpm_ST_CDOptions] = useState([]);
  const [pm_OP_SQOptions, setpm_OP_SQOptions] = useState([]);
  const [pl_WK_CTOptions, setpl_WK_CTOptions] = useState([]);
  const [pl_OP_SQOptions, setpl_OP_SQOptions] = useState([]);


  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  //Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_CO_CT(value);
  };
  //UM
  const onchangeDropDownFormDataUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_IT_UM(value);
  };
  //Type
  const onchangeDropDownFormDataType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_ST_TY(value);
  };
  //Status
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_ST_CD(value);
  };
  //OperSeq 
  const onchangeDropDownFormDataOperSeq = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_OP_SQ(value);
  };
  //WorkCenter
  const onchangeDropDownFormDataWorkCenter = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_WK_CT(value);
  };
  //OperSeq1 
  const onchangeDropDownFormDataOperSeq1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpl_OP_SQ(value);
  };


  useEffect(() => {


    CostCentre();
    UM();
    Type();
    OperSeq();
    Status();
    WorkCenter();
    OperSeq1();
    const table = document.getElementById("CostSimulationMaterial");
    if (table) {
      console.log(table.tBodies);
    } else {
      console.error("Table not found");
    }

  }, [ParentCostSimulationTable]);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setph_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setpm_IT_UMOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeCD("IN/TY");
    setpm_ST_TYOptions(Options);
  };
  const OperSeq = async () => {
    const Options = await fetchCostCenter();
    setpm_OP_SQOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("CS/ST");
    setpm_ST_CDOptions(Options);
  };
  const OperSeq1 = async () => {
    const Options = await fetchCostCenter();
    setpl_OP_SQOptions(Options);
  };

  const WorkCenter = async () => {
    const Options = await fetchCostCenter();
    setpl_WK_CTOptions(Options);
  };







  // Advance Search
  const [SoldToPopupVisible, setSoldToPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  const [showSearchIcon, setShowSearchIcon] = useState("");

  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DESC", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("pm_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };


  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ph_CO_IT").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setParentCostSimulationTable([...ParentCostSimulationTable, {}]);
  };
  const deleteRow = () => {
    if (ParentCostSimulationTable.length > 0) {
      const updatedData = [...ParentCostSimulationTable];
      updatedData.pop();
      setParentCostSimulationTable(updatedData);
    }
  };
  const addRow1 = () => {
    setParentCostSimulationTable1([...ParentCostSimulationTable1, {}]);
  };
  const deleteRow1 = () => {
    if (ParentCostSimulationTable1.length > 0) {
      const updatedData1 = [...ParentCostSimulationTable1];
      updatedData1.pop();
      setParentCostSimulationTable1(updatedData1);
    }
  };

  const [activeTab, setActiveTab] = useState(0);



  const onChangeOfPrice = async (e) => {
    //const table = document.getElementById("RentalContractTable");
    //const totalRowCount = table.tBodies[0].rows.length;
    // rentalContracts = [];
    const table = document.getElementById("ParentCostSimulationTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const ParentCostSimulationList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        phUkNo: document.getElementById("phUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      ParentCostSimulationList.push(details);
    }
    const requestBodyNet = {
      ParentCostSimulationList,
    };

    const response = await axios.post(`${domain}/api/ParentCostSimulation/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("F_EXT_PRICE").value = respNet.f_EXT_PRICE;
      document.getElementById("F_EXT_COST").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  // DIALOG HEADER
  const isUpdateMode = Boolean(phUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{phUkNo ? "Revise Cost Simulation - Parent" : "Add Cost Simulation - Parent"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
      <div style={borderbox} >

        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Material">
            <div
              style={{
                marginTop: "2%",
                border: "1px solid #e8e8e8",
                padding: "16px",
                borderRadius: "15px",
                backgroundColor: "aliceblue",
                overflow: "auto",
              }}
            >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="phUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="phUkNo"
                    name="phUkNo"
                    defaultValue={phUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_IT" className="font-bold">
                  Parent Item<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openItemPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ph_CO_IT"
                      name="ph_CO_IT"
                      defaultValue={ph_CO_IT}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="ph_CO_CT"
                    value={ph_CO_CT}
                    options={ph_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("ph_CO_CT", e.value)}
                    placeholder="	Cost Centre"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_QT" className="font-bold">
                    Quantity
                  </label>
                  <InputText
                    id="ph_CO_QT"
                    name="ph_CO_QT"
                    value={ph_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_UN_CT" className="font-bold">
                    Unit Cost
                  </label>
                  <InputText disabled
                    id="ph_UN_CT"
                    name="ph_UN_CT"
                    value={ph_UN_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_AM" className="font-bold">
                    Amount
                  </label>
                  <InputText disabled
                    id="ph_CO_AM"
                    name="ph_CO_AM"
                    value={ph_CO_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>


              </div>
            </div>
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
                {(
                  <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
                )}
                {(
                  <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="CostSimulationMaterial">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Item Number <span style={{ color: 'red' }}>*</span></th>
                      <th>ItemDes </th>
                      <th>UM</th>
                      <th>Type</th>
                      <th>Quantity</th>
                      <th>Unit Cost</th>
                      <th>Amount</th>
                      <th>Material</th>
                      <th>Machine</th>
                      <th>Labour</th>
                      <th>OverHead</th>
                      <th>Status</th>
                      <th>Oper. Seq.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ParentCostSimulationTable.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`pm_UK_NO_${index}`}
                            name={`pm_UK_NO_${index}`}
                            defaultValue={row.pm_UK_NO}
                          />
                        </td>
                        <td style={{ display: "flex" }}>
                          <InputText
                            style={{ width: "110px" }}
                            id={`pm_IT_NM_${index}`}
                            name={`pm_IT_NM_${index}`}
                            onChange={(e) => onchangeFormData(e)}
                            onFocus={() => setShowSearchIcon(true)}
                            value={row.pm_IT_NM ? row.pm_IT_NM : row[`${"pm_IT_NM"}_${index}`]}
                          />
                          {showSearchIcon && (
                            <i
                              className="pi pi-search"
                              style={{
                                color: "var(--primary-color)",
                                fontSize: "119%",
                                marginTop: "15%",
                                cursor: "pointer",
                              }}
                              onClick={(e) => openItemPopup1(index)}
                            ></i>
                          )}
                        </td>
                        <td>
                          <InputText disabled
                            style={{ width: "200px" }}
                            id={`item_DESC_${index}`}
                            name={`item_DESC_${index}`}
                            value={row.item_DESC ? row.item_DESC : row[`${"item_DESC"}_${index}`]}
                            readOnly
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pm_IT_UM_${index}`}
                            name={`pm_IT_UM_${index}`}
                            value={data[`pm_IT_UM_${index}`] ? data[`pm_IT_UM_${index}`] : row.pm_IT_UM}
                            options={pm_IT_UMOptions}
                            onChange={(e) => onchangeDropDownFormDataUM(`pm_IT_UM_${index}`, e.value)}
                            placeholder=" UM"
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pm_ST_TY_${index}`}
                            name={`pm_ST_TY_${index}`}
                            value={data[`pm_ST_TY_${index}`] ? data[`pm_ST_TY_${index}`] : row.pm_ST_TY}
                            options={pm_ST_TYOptions}
                            onChange={(e) => onchangeDropDownFormDataType(`pm_ST_TY_${index}`, e.value)}
                            placeholder=" Type"
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_IT_QT_${index}`}
                            name={`pm_IT_QT_${index}`}
                            defaultValue={row.pm_IT_QT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_IT_QT_${index}`}
                            name={`pm_IT_QT_${index}`}
                            defaultValue={row.pm_IT_QT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_IT_AM_${index}`}
                            name={`pm_IT_AM_${index}`}
                            defaultValue={row.pm_IT_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>


                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_MT_AM_${index}`}
                            name={`pm_MT_AM_${index}`}
                            defaultValue={row.pm_MT_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_MC_AM_${index}`}
                            name={`pm_MC_AM_${index}`}
                            defaultValue={row.pm_MC_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_LB_AM_${index}`}
                            name={`pm_LB_AM_${index}`}
                            defaultValue={row.pm_LB_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pm_OH_AM_${index}`}
                            name={`pm_OH_AM_${index}`}
                            defaultValue={row.pm_OH_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pm_ST_CD_${index}`}
                            name={`pm_ST_CD_${index}`}
                            value={data[`pm_ST_CD_${index}`] ? data[`pm_ST_CD_${index}`] : row.pm_ST_CD}
                            options={pm_ST_CDOptions}
                            onChange={(e) => onchangeDropDownFormDataStatus(`pm_ST_CD_${index}`, e.value)}
                            placeholder="Status"
                          />
                        </td>

                        <td>
                          <Dropdown
                            id={`pm_OP_SQ_${index}`}
                            name={`pm_OP_SQ_${index}`}
                            value={data[`pm_OP_SQ_${index}`] ? data[`pm_OP_SQ_${index}`] : row.pm_OP_SQ}
                            options={pm_OP_SQOptions}
                            onChange={(e) => onchangeDropDownFormDataOperSeq(`pm_OP_SQ_${index}`, e.value)}
                            placeholder="Oper. Seq."
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={inboxstyle}  >
              <div className="formgrid grid" style={footergridstyle} >
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="material" className="font-bold">
                  Material
                  </label>
                  <InputText disabled
                    id="material"
                    name="material"
                    value={material}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="machine" className="font-bold">
                  Machine
                  </label>
                  <InputText disabled
                    id="machine"
                    name="machine"
                    value={machine}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="labour" className="font-bold">
                  Labour
                  </label>
                  <InputText disabled
                    id="labour"
                    name="labour"
                    value={labour}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="overhead" className="font-bold">
                  OverHead
                  </label>
                  <InputText disabled
                    id="overhead"
                    name="overhead"
                    value={overhead}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="material_TOT" className="font-bold">
                    Total Amount
                  </label>
                  <InputText disabled
                    id="material_TOT"
                    name="material_TOT"
                    value={material_TOT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>

            </div>
          </TabPanel>
          <TabPanel header="Machine & Labour ">
            <div style={inboxstyle}  >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="phUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="phUkNo"
                    name="phUkNo"
                    defaultValue={phUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_IT" className="font-bold">
                  Parent Item<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openItemPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="ph_CO_IT"
                      name="ph_CO_IT"
                      defaultValue={ph_CO_IT}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_CT" className="font-bold">
                    Cost Centre
                  </label>
                  <Dropdown
                    name="sd_RT_CD"
                    value={ph_CO_CT}
                    options={ph_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("ph_CO_CT", e.value)}
                    placeholder="	Cost Centre"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CO_QT" className="font-bold">
                    Quantity
                  </label>
                  <InputText
                    id="ph_CO_QT"
                    name="ph_CO_QT"
                    value={ph_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
              
                {(
                    <Button icon="pi pi-plus" onClick={addRow1} style={plusstyle} tooltip="Add" />
                )}
               
                 {(
                  <Button icon="pi pi-minus" onClick={deleteRow1} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div style={multitablestyle}>
                <table className="custom-table" id="CostSimulationLabourTable">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Work Centre</th>
                      <th>Oper. Seq.</th>
                      <th>Description </th>
                      <th>Setup Hrs</th>
                      <th>Machine Hrs	</th>
                      <th>Labour Hrs</th>
                      <th>M/C Rate</th>
                      <th>Lab. Rate</th>
                      <th>O/H Rate</th>
                      <th>M/C Amt</th>
                      <th>Lab. Amt</th>
                      <th>O/H Amt</th>
                      <th>Total Amt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ParentCostSimulationTable1.map((row, index) => (
                      <tr key={index}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`pl_UK_NO_${index}`}
                            name={`pl_UK_NO_${index}`}
                            defaultValue={row.pl_UK_NO}
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pl_WK_CT_${index}`}
                            name={`pl_WK_CT_${index}`}
                            value={data[`pl_WK_CT_${index}`] ? data[`pl_WK_CT_${index}`] : row.pl_WK_CT}
                            options={pl_WK_CTOptions}
                            onChange={(e) => onchangeDropDownFormDataWorkCenter(`pl_WK_CT_${index}`, e.value)}
                            placeholder=" WorkCenter "
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`pl_OP_SQ_${index}`}
                            name={`pl_OP_SQ_${index}`}
                            value={data[`pl_OP_SQ_${index}`] ? data[`pl_OP_SQ_${index}`] : row.pl_OP_SQ}
                            options={pl_OP_SQOptions}
                            onChange={(e) => onchangeDropDownFormDataOperSeq1(`pl_OP_SQ_${index}`, e.value)}
                            placeholder=" Oper. Seq."
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_OP_DS_${index}`}
                            name={`pl_OP_DS_${index}`}
                            defaultValue={row.pl_OP_DS}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_ST_HR_${index}`}
                            name={`pl_ST_HR_${index}`}
                            defaultValue={row.pl_ST_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_MC_HR_${index}`}
                            name={`pl_MC_HR_${index}`}
                            defaultValue={row.pl_MC_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_LB_HR_${index}`}
                            name={`pl_LB_HR_${index}`}
                            defaultValue={row.pl_LB_HR}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_MC_CL_${index}`}
                            name={`pl_MC_CL_${index}`}
                            defaultValue={row.pl_MC_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_LB_CL_${index}`}
                            name={`pl_LB_CL_${index}`}
                            defaultValue={row.pl_LB_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_OH_CL_${index}`}
                            name={`pl_OH_CL_${index}`}
                            defaultValue={row.pl_OH_CL}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_MC_AM_${index}`}
                            name={`pl_MC_AM_${index}`}
                            defaultValue={row.pl_MC_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_LB_AM_${index}`}
                            name={`pl_LB_AM_${index}`}
                            defaultValue={row.pl_LB_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`pl_OH_AM_${index}`}
                            name={`pl_OH_AM_${index}`}
                            defaultValue={row.pl_OH_AM}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td >
                          <InputText style={{ width: "100px" }}
                            id={`tot_AMT_${index}`}
                            name={`tot_AMT_${index}`}
                            defaultValue={row.tot_AMT}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={inboxstyle}  >
              <div className="formgrid grid" style={footergridstyle} >
                <div className="field" style={footerfieldstyle} >
                  <label htmlFor="material_TOT" className="font-bold">
                    Total Amount
                  </label>
                  <InputText disabled
                    id="material_TOT"
                    name="material_TOT"
                    value={material_TOT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>

              </div>

            </div>
          </TabPanel>
        </TabView>
      </div>

      {/* item */}
      {<ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />

    </Dialog>

  );
}

