import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import FileManagementDialog from "./FileManagementDialog";
import axios from "axios";
import saveAs from "file-saver";
import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import CreateFolderPopup from "./CreateFolder";
import CreateMainFolderPopup from "./CreateMainFolder";
import UploadFilePopup from "./UpoloadFile";
import CreateFilePopup from "./CreateFile";
import ReNamePopup from "./ReNamePopup";
import MoveFromFolderPopup from "./MoveFromFolderPopup";

// sravani
import SharedWithMePopup from "./SharedWithMePopup";
//import CreateFolderPopup from "./CreateMainFolder";
const initialValue = {
  fdUkNo: "",
  fd_FI_NM: "",
  fd_FI_DT: "",
  fd_FI_TI: "",
  fd_FI_TY: "",
  Location: "",
  fd_FI_OW: "",
};

const initialFilters = {
  fd_FI_NM: { value: "", operation: "" },
  fd_FI_DT: { value: "", operation: "" },
  fd_FI_TI: { value: "", operation: "" },
  fd_FI_TY: { value: "", operation: "" },
  Location: { value: "", operation: "" },
  fd_FI_OW: { value: "", operation: "" },
};

const FileManagementMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, inputStyle1
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [isCreateFolderPopupOpen, setCreateFolderPopupOpen] = useState(false);
  const [isCreateMainFolderPopupOpen, setCreateMainFolderPopupOpen] = useState(false);
  const [isUploadFilePopupOpen, setUploadFilePopupOpen] = useState(false);
  const [isCreateFilePopupOpen, setCreateFilePopupOpen] = useState(false);
  const [newMainFolderName, setnewMainFolderName] = useState(false);
  const [SubFolderName, setSubFolderName] = useState(false);
  const [file, setFile] = useState(false);
  const [handleOptionClick, sethandleOptionClick] = useState(false);
  const [isReNamePopupOpen, setReNamePopupOpen] = useState(false);

  const [SharedPopupOpen, setSharedPopupOpen] = useState(false);
  const openshared = () => {
    setSharedPopupOpen(true);
  }

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  // time fromat
  const formatSavedTime = (inputTime) => {
    if (!inputTime) {
      return inputTime;
    }
    return moment(inputTime, "HH:mm:ss").format("HH:mm:ss");
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  // Helper function to find folder by path
  const findFolderByPath = (folderList, path) => {
    let currentFolder = { subfolders: folderList };
    for (let folderName of path) {
      currentFolder = currentFolder.subfolders.find((f) => f.name === folderName);
      if (!currentFolder) break;
    }
    return currentFolder;
  };
  const handleCreateFolder = async () => {
    const requestPayload = {
      mainFolder: newMainFolderName,
      subFolder: document.getElementById("SubFolderName").value,
      newFolder: document.getElementById("OtherFolderName").value
    };

    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/CreateFolderCombinations`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          }
        }
      );

      // Pass the response data directly to handleResponse
      handleResponse2(response.data);
    } catch (error) {
      console.error("Error creating folder:", error);
      // Extract error message from the response if available
      const errorMessage = error.response ? error.response.data : "An unknown error occurred";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage, // Show the extracted error message
      });
    }
  };

  const handleResponse2 = (message) => {
    if (message === "Folder created successfully") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Folder created successfully!",
      });
      setnewMainFolderName('');
      handleClose();
    } else if (message === "Folder already exists") {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: message,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Unexpected response from server.",
      });
    }
    setCreateFolderPopupOpen(false);
  };



  // Handle creating a new Main folder
  const handleCreateMainFolder = async () => {
    const requestPayload = {
      mainFolder: document.getElementById("newMainFolderName").value,
    };

    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/CreateMainFolder`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          }
        }
      );

      // Handle response message
      handleResponse(response.data); // Pass response data directly to handleResponse
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Folder already exists..",
      });
    }
  };


  // Handle the backend response to display messages
  const handleResponse1 = (message) => {
    if (message === "Main folder created successfully.") {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Folder created successfully!",
      });
      setnewMainFolderName('');
      handleClose();
    } else if (message === "Folder already exists.") {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: message,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Unexpected response from server.",
      });
    }
    setCreateMainFolderPopupOpen(false);
  };

  const handleUpload = async () => {

    const requestPayload = {
      file: file, // Append the file to FormData
      mainFolder: newMainFolderName,
      subFolder: SubFolderName
    } // Include other fields if necessary

    try {
      const response = await axios.post(`${domain}/api/FileManagementSystem/Upload`, requestPayload, {
        headers: {
          Authorization: `Bearer ${token}`, // Include your token if required
          "Content-Type": "multipart/form-data", // Set content type for file upload
          "x-company": `${company}`,
          "x-flag": localStorage.getItem("userType"),
        },
      });

      // Handle response message
      handleResponse3(response.data); // Pass response data to handleResponse function
    } catch (error) {
      console.error("Error uploading file:", error);
      const errorMessage = error.response?.data || "An unexpected error occurred"; // Get the error message from the response
      toast.error(`Error: ${errorMessage}`); // Show error toast notification
    }
  };

  const handleResponse3 = (message) => {
    if (message === "File uploaded successfully") {
      //toast.success("File uploaded successfully!");
      setFile(null); // Reset file input if needed
      setnewMainFolderName(null);
      setSubFolderName(null);
    } else if (message.startsWith("Failed to upload file")) {
      toast.warn(message); // Show the warning message
    } else {
      toast.error("Unexpected response from server.");
    }
    setUploadFilePopupOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
      setCreateMainFolderPopupOpen(false);
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
      // setCreateMainFolderPopupOpen(false);
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleSearch();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async () => {
    const requestBody = {
      "searchRequestDto": [],
    };
    const paginationUrl = `${domain}/api/FileManagementSystem/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/FileManagement/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const FileManagementData = response.data;
        setFormData(FileManagementData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      {/* <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Create Folder
        </div>
      </Tooltip> */}
      <button
        className="btn btn-outline-dark me-2"
        onClick={() => setCreateMainFolderPopupOpen(true)}
      > Create Main Folder
      </button>

      <button
        className="btn btn-outline-dark me-2"
        onClick={() => setCreateFolderPopupOpen(true)}
      > Create Sub Folder
      </button>
      {/* <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Create File
        </div>
      </Tooltip> */}
      <button
        className="btn btn-outline-dark me-2"
        onClick={() => setUploadFilePopupOpen(true)}
      >Upload File
      </button>
      {/* <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Upload files
        </div>
      </Tooltip> */}
      <button
        className="btn btn-outline-dark me-2"
        onClick={() => setReNamePopupOpen(true)}
      > ReName
      </button>


      <button
        className="btn btn-outline-dark me-2"
        onClick={() => setCreateFilePopupOpen(true)}
      > Download File
      </button>

      <button
        className="btn btn-outline-dark me-2"
        onClick={openshared}
      > Shared With Me
      </button>

    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{ width: "40px" }}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fd_FI_NM"] ? filters["fd_FI_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fd_FI_NM",
                        e.target.value,
                        filters["fd_FI_NM"]
                          ? filters["fd_FI_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fd_FI_DT"] ? filters["fd_FI_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fd_FI_DT",
                        e.target.value,
                        filters["fd_FI_DT"] ? filters["fd_FI_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fd_FI_TI"] ? filters["fd_FI_TI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fd_FI_TI",
                        e.target.value,
                        filters["fd_FI_TI"]
                          ? filters["fd_FI_TI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fd_FI_TY"] ? filters["fd_FI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fd_FI_TY",
                        e.target.value,
                        filters["fd_FI_TY"]
                          ? filters["fd_FI_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["Location"] ? filters["Location"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "Location",
                        e.target.value,
                        filters["Location"]
                          ? filters["Location"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["fd_FI_OW"] ? filters["fd_FI_OW"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fd_FI_OW",
                        e.target.value,
                        filters["fd_FI_OW"]
                          ? filters["fd_FI_OW"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>File Name</th>
                <th style={tableHeaderStyle}>Upload Date</th>
                <th style={tableHeaderStyle}>Upload Time</th>
                <th style={tableHeaderStyle}>File Type</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Owner</th>

              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.fdUkNo}
                        checked={selectedRows.includes(rowData.fdUkNo)}
                        onChange={() => handleRowSelect(rowData.fdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.fd_FI_NM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.fd_FI_DT)}</td>
                    <td style={tableCellStyle}>{formatSavedTime(rowData.fd_FI_TI)}</td>
                    <td style={tableCellStyle}>{rowData.fd_FI_TY}</td>
                    <td style={tableCellStyle}>{rowData.Location}</td>
                    <td style={tableCellStyle}>{rowData.fd_FI_OW}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {/* Popups */}
      <CreateFolderPopup
        isOpen={isCreateFolderPopupOpen}
        onClose={() => setCreateFolderPopupOpen(false)}
        onCreateFolder={handleCreateFolder}
        newMainFolderName={newMainFolderName}
        setnewMainFolderName={setnewMainFolderName}
      />
      <CreateMainFolderPopup
        isOpen={isCreateMainFolderPopupOpen}
        onClose={() => setCreateMainFolderPopupOpen(false)}
        onCreateMainFolder={handleCreateMainFolder}
        handleResponse={handleResponse}
      />
      <UploadFilePopup
        isOpen={isUploadFilePopupOpen}
        onClose={() => setUploadFilePopupOpen(false)}
        onUploadFile={handleUpload}
        newMainFolderName={newMainFolderName}
        setnewMainFolderName={setnewMainFolderName}
        SubFolderName={SubFolderName}
        setSubFolderName={setSubFolderName}
        file={file}
        setFile={setFile}
      // onUploadFile={handleFileUpload}
      />
      <CreateFilePopup
        isOpen={isCreateFilePopupOpen}
        onClose={() => setCreateFilePopupOpen(false)}
      />

      <FileManagementDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
      />
      <ReNamePopup
        isOpen={isReNamePopupOpen}
        onClose={() => setReNamePopupOpen(false)}
        onCreateMainFolder={handleOptionClick}
        handleResponse={handleResponse}
        //handleOptionClick={handleOptionClick}
        sethandleOptionClick={sethandleOptionClick}
      />
      <MoveFromFolderPopup
        isOpen={isReNamePopupOpen}
        onClose={() => setReNamePopupOpen(false)}
        onCreateMainFolder={handleOptionClick}
        handleResponse={handleResponse}
      />

      <SharedWithMePopup
        visible={SharedPopupOpen}
        onClose={() => setSharedPopupOpen(false)}
      />

    </div>
  );
};

export default FileManagementMain;
