import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { InputMask } from "primereact/inputmask";

export default function RateDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  onchangeFormData,
  SelectedemEMNO,
  setSelectedemEMNO,
  rd_RT_NM,
  setrd_RT_NM,
  rd_CO_CT,
  setrd_CO_CT,
  rd_RT_TY,
  setrd_RT_TY,
  rd_PA_BL,
  setrd_PA_BL,
  rd_RT_ST,
  setrd_RT_ST,
  rd_BI_BL,
  setrd_BI_BL,
  rd_RT_BS,
  setrd_RT_BS,
  rd_RT_LV,
  setrd_RT_LV,
  rd_RT_UM,
  setrd_RT_UM,
  rd_D1_TY,
  setrd_D1_TY,
  rd_DI_LV,
  setrd_DI_LV,
  rd_D2_TY,
  setrd_D2_TY,
  rd_D1_UM,
  setrd_D1_UM,
  rd_D2_UM,
  setrd_D2_UM,
}) {
  const {
    vmUkNo,
    rd_D1_EN,
    rd_D2_EN,
    rd_CO_NO,
 


    

  } = data;
  const {borderbox,inboxstyle, Dialogstyle, cancelstyle, savestyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter,fetchCostCenterP } = Costcenter();
  const [rd_CO_CTOptions, setrd_CO_CTOptions] = useState([]);
  const [rd_RT_NMOptions, setrd_RT_NMOptions] = useState([]);
  const [rd_RT_TYOptions, setrd_RT_TYOptions] = useState([]);
  const [rd_PA_BLOptions, setrd_PA_BLOptions] = useState([]);
  const [rd_RT_STOptions, setrd_RT_STOptions] = useState([]);
  const [rd_BI_BLOptions, setrd_BI_BLOptions] = useState([]);
  const [rd_RT_BSOptions, setrd_RT_BSOptions] = useState([]);
  const [rd_RT_LVOptions, setrd_RT_LVOptions] = useState([]);
  const [rd_RT_UMOptions, setrd_RT_UMOptions] = useState([]);

  const [rd_D1_TYOptions, setrd_D1_TYOptions] = useState([]);
  const [rd_DI_LVOptions, setrd_DI_LVOptions] = useState([]);
  const [rd_D2_TYOptions, setrd_D2_TYOptions] = useState([]);
  const [rd_D1_UMOptions, setrd_D1_UMOptions] = useState([]);
  const [rd_D2_UMOptions, setrd_D2_UMOptions] = useState([]);
  //advance search
  const [Addresstype, setAddresstype] = useState("E");  
  const [showSearchIcon, setShowSearchIcon] = useState("");
  //Cost Center
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrd_CO_CT(value);
  };
// Rate Name
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrd_RT_NM(value);
  };
// Rate Type
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrd_RT_TY(value);
  };
// Payable
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrd_PA_BL(value);
  };
// RateStructure
const onchangeDropDownFormData5 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_RT_ST(value);
};
// Billable
const onchangeDropDownFormData6 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_BI_BL(value);
};
// Rate Basis
const onchangeDropDownFormData7 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_RT_BS(value);
};
// RateLevel
const onchangeDropDownFormData8 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_RT_LV(value);
};
// RateUM
const onchangeDropDownFormData9 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_RT_UM(value);
};
//Definition1Type
const onchangeDropDownFormData11 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_D1_TY(value);
};
// DistanceLevel
const onchangeDropDownFormData10 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_DI_LV(value);
};
// Definition2Type
const onchangeDropDownFormData12 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_D2_TY(value);
};
// Definition1UM
const onchangeDropDownFormData13 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_D1_UM(value);
};
// Definition2UM
const onchangeDropDownFormData14 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setrd_D2_UM(value);
};

  useEffect(() => {
    fetchRateName();
    fetchDropdownCost();
    fetchRateType();
    fetchPayable();
    fetchRateStructure();
    fetchBillable();
    fetchRateBasis();
    fetchRateLevel();
    fetchRateUM();
    fetchDistanceLevel();
    fetchDefinition1Type();
    fetchDefinition2Type();
    fetchDefinition1UM();
    fetchDefinition2UM();


  }, []);
  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setrd_CO_CTOptions(Options);
  };

  const fetchRateName = async () => {
    const Options = await fetchClassCodeDS("RT/NM");
    setrd_RT_NMOptions(Options);
  };

  const fetchRateType = async () => {
    const Options = await fetchClassCodeDS("RT/TY");
    setrd_RT_TYOptions(Options);
  };
  const fetchPayable = async () => {
    const Options = await fetchClassCodeDS("RT/PA");
    setrd_PA_BLOptions(Options);
  };
  const fetchRateStructure = async () => {
    const Options = await fetchClassCodeDS("RT/ST");
    setrd_RT_STOptions(Options);
  };
  const fetchBillable = async () => {
    const Options = await fetchClassCodeDS("RT/BL");
    setrd_BI_BLOptions(Options);
  };
  const fetchRateBasis = async () => {
    const Options = await fetchClassCodeDS("RT/BS");
    setrd_RT_BSOptions(Options);
  };
  const fetchRateLevel = async () => {
    const Options = await fetchClassCodeDS("RT/LV");
    setrd_RT_LVOptions(Options);
  };
  const fetchRateUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrd_RT_UMOptions(Options);
  };
  const fetchDistanceLevel = async () => {
    const Options = await fetchClassCodeDS("RT/DT");
    setrd_DI_LVOptions(Options);
  };
  const fetchDefinition1Type = async () => {
    const Options = await fetchClassCodeDS("DF/TY");
    setrd_D1_TYOptions(Options);
  };
  const fetchDefinition2Type = async () => {
    const Options = await fetchClassCodeDS("DF/TY");
    setrd_D2_TYOptions(Options);
  };
  const fetchDefinition1UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrd_D1_UMOptions(Options);
  };
  const fetchDefinition2UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrd_D2_UMOptions(Options);
  };


      // Handle form data change
      const onChangeTime=(name,value,index)=>{
        value= moment(value, "HH:mm:ss").format("HHmmss");
        onchangeFormData(name, value,index);
      };
  
  const [FacilityAddressPopupVisible, setFacilityAddressPopupVisible] = useState(false);
  const openCustomerPopup = () => {
    setFacilityAddressPopupVisible(true);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("rd_CO_NO", data.abAdNo);
    setFacilityAddressPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(vmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{vmUkNo ? "Revise Rate Details" : "Add Rate Details"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
    visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={{
        border:'1px solid #e8e8e8',padding:'8px',borderRadius:'15px' }} >
            <div style={inboxstyle} >
                  <div className="formgrid grid">
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_NM" className="font-bold">
                    Rate Name
                    </label>
                    <Dropdown
                    id="rd_RT_NM"
                    name="rd_RT_NM"
                    value={rd_RT_NM}
                    options={rd_RT_NMOptions}
                    onChange={(e) => onchangeDropDownFormData1("rd_RT_NM", e.value)}
                    placeholder="Select Rate Name"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_CO_CT" className="font-bold">
                    Branch
                    </label>
                    <Dropdown
                    id="rd_CO_CT"
                    name="rd_CO_CT"
                    value={rd_CO_CT}
                    options={rd_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData2("rd_CO_CT", e.value)}
                    placeholder="Select Branch"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_TY" className="font-bold">
                    Rate Type
                    </label>
                    <Dropdown
                    id="rd_RT_TY"
                    name="rd_RT_TY"
                    value={rd_RT_TY}
                    options={rd_RT_TYOptions}
                    onChange={(e) => onchangeDropDownFormData3("rd_RT_TY", e.value)}
                    placeholder="Select Rate Type"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_PA_BL" className="font-bold">
                    Payable
                    </label>
                    <Dropdown
                    id="rd_PA_BL"
                    name="rd_PA_BL"
                    value={rd_PA_BL}
                    options={rd_PA_BLOptions}
                    onChange={(e) => onchangeDropDownFormData4("rd_PA_BL", e.value)}
                    placeholder="Select Payable"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_ST" className="font-bold">
                    Rate Structure
                    </label>
                    <Dropdown
                    id="rd_RT_ST"
                    name="rd_RT_ST"
                    value={rd_RT_ST}
                    options={rd_RT_STOptions}
                    onChange={(e) => onchangeDropDownFormData5("rd_RT_ST", e.value)}
                    placeholder="Select Rate Structure"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_BI_BL" className="font-bold">
                    Billable
                    </label>
                    <Dropdown
                    id="rd_BI_BL"
                    name="rd_BI_BL"
                    value={rd_BI_BL}
                    options={rd_BI_BLOptions}
                    onChange={(e) => onchangeDropDownFormData6("rd_BI_BL", e.value)}
                    placeholder="Select Billable"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_BS" className="font-bold">
                    Rate Basis
                    </label>
                    <Dropdown
                    id="rd_RT_BS"
                    name="rd_RT_BS"
                    value={rd_RT_BS}
                    options={rd_RT_BSOptions}
                    onChange={(e) => onchangeDropDownFormData7("rd_RT_BS", e.value)}
                    placeholder="Select Billable"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_LV" className="font-bold">
                    Rate Level
                    </label>
                    <Dropdown
                    id="rd_RT_LV"
                    name="rd_RT_LV"
                    value={rd_RT_LV}
                    options={rd_RT_LVOptions}
                    onChange={(e) => onchangeDropDownFormData8("rd_RT_LV", e.value)}
                    placeholder="Select Rate Level"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_RT_UM" className="font-bold">
                    Rate UM
                    </label>
                    <Dropdown
                    id="rd_RT_UM"
                    name="rd_RT_UM"
                    value={rd_RT_UM}
                    options={rd_RT_UMOptions}
                    onChange={(e) => onchangeDropDownFormData9("rd_RT_UM", e.value)}
                    placeholder="Select Rate UM"  
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="rd_DI_LV" className="font-bold">
                    Distance Level
                    </label>
                    <Dropdown
                    id="rd_DI_LV"
                    name="rd_DI_LV"
                    value={rd_DI_LV}
                    options={rd_DI_LVOptions}
                    onChange={(e) => onchangeDropDownFormData10("rd_DI_LV", e.value)}
                    placeholder="Select  Distance Level"  
                  />
                  </div>
                  </div>
                  </div>
              <div class="row">
                <div class="col">
                  <div style={inboxstyle} >
                      <div className="formgrid grid">
                        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                            <label htmlFor="rd_D1_TY" className="font-bold">
                              Definition1 Type
                            </label>
                            <Dropdown
                              id="rd_D1_TY"
                              name="rd_D1_TY"
                              value={rd_D1_TY}
                              options={rd_D1_TYOptions}
                              onChange={(e) => onchangeDropDownFormData11("rd_D1_TY", e.value)}
                              placeholder="Select  Definition1 Type"  
                            />
                        </div>
                        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                            <label htmlFor="rd_D1_UM" className="font-bold">
                              Definition1 UM
                            </label>
                            <Dropdown
                              id="rd_D1_UM"
                              name="rd_D1_UM"
                              value={rd_D1_UM}
                              options={rd_D1_UMOptions}
                              onChange={(e) => onchangeDropDownFormData13("rd_D1_UM", e.value)}
                              placeholder="Select Definition1 UM"  
                            />
                        </div>
                        <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                          <label htmlFor="rd_D1_EN" className="font-bold">
                              Definition1 Entries
                          </label>
                          <InputText
                            id="rd_D1_EN"
                            name="rd_D1_EN"
                            value={rd_D1_EN}
                            onChange={(Event) => onchangedata(Event)}
                          />
                        </div> 
                      </div>
                  </div>
                </div>
                <div class="col">
                  <div style={inboxstyle} >
                    <div className="formgrid grid">
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="rd_D2_TY" className="font-bold">
                          Definition2 Type
                        </label>
                        <Dropdown
                          id="rd_D2_TY"
                          name="rd_D2_TY"
                          value={rd_D2_TY}
                          options={rd_D2_TYOptions}
                          onChange={(e) => onchangeDropDownFormData12("rd_D2_TY", e.value)}
                          placeholder="Select  Definition2 Type"  
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="rd_D2_UM" className="font-bold">
                          Definition2 UM
                        </label>
                        <Dropdown
                          id="rd_D2_UM"
                          name="rd_D2_UM"
                          value={rd_D2_UM}
                          options={rd_D2_UMOptions}
                          onChange={(e) => onchangeDropDownFormData14("rd_D2_UM", e.value)}
                          placeholder="Select  Definition2 UM"  
                        />
                      </div>
                      <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                        <label htmlFor="rd_D2_EN" className="font-bold">
                            Definition2 Entries
                        </label>
                        <InputText
                          id="rd_D2_EN"
                          name="rd_D2_EN"
                          value={rd_D2_EN}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="rd_CO_NO"
                  name="rd_CO_NO"
                  value={rd_CO_NO}
                  onChange={(Event) => onchangedata(Event)}  
                />
              </div>
          </div>
      <AddressBookPopup
        visible={FacilityAddressPopupVisible}
        onClose={() => setFacilityAddressPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={Addresstype}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
