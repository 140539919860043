import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import VehicleMasterPopup from "../Popups/VehicleMasterPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function JewelleryAcknowledgementDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  JewelleryAcknowledgementTable,
  setJewelleryAcknowledgementTable,

  handleConfirm,

  SelectedAcknowledgingPerson,
  setSelectedAcknowledgingPerson,
  SelectedTransferPerson,
  setSelectedTransferPerson,
  SelectedITNM,
  setSelectedITNM,

  lb_LD_TY,

  la_SH_TM,
  lb_CR_NO,
  lb_VH_ID,
  lb_CO_NO,
  onchangeDropDownFormData1,
  jt_BG_NO,
  jt_OR_CO,
}) {
  const {
    jtUkNo,
    jt_OR_NO,
    jt_OR_TY,
    // jt_OR_CO,
    jt_TR_DT,
    jt_IT_NM,
    jt_WO_QT,
    jt_CO_QT,
    jt_LO_CN,
    jt_TO_LO,
    jt_RL_PS,
    jt_WT_QT,
    jt_RL_QT,
    jt_AK_DT,
    jt_AK_PS,
    jt_RL_QT1,
  } = data;

  const { inboxstyle, savestyle, cancelstyle, Dialogstyle, TabBoxStyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "jt_TR_DT", value: moment(e.value).format("YYYYMMDD") } });

  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "jt_AK_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  // Dropdown
  //const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  //const { fetchCostCenter } = Costcenter();




  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");




  useEffect(() => {

  }, []);









  // Advance Search

  const [TransferPersonPopupVisible, setTransferPersonPopupVisible] = useState(false);
  const [DesignPopupVisible, setDesignPopupVisible] = useState(false);
  const [AcknowledgingPersonPopupVisible, setAcknowledgingPersonPopupVisible] = useState(false);


  const [CarrierType, setCarrierType] = useState("E");
  const [showSearchIcon, setShowSearchIcon] = useState("");


  const openCarrierPopup = () => {
    setAcknowledgingPersonPopupVisible(true);
  };
  const openTransferPerson = () => {
    setTransferPersonPopupVisible(true);
  };


  const handleDataSelectAcknowledgingPerson = (data) => {
    setSelectedAcknowledgingPerson(data.ab_AD_NM);
    onchangeFormData("lb_CR_NO", data.abAdNo);
    setAcknowledgingPersonPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectTransferPerson = (data) => {
    setSelectedTransferPerson(data.ab_AD_NM);
    onchangeFormData("jt_RL_PS", data.abAdNo);
    setTransferPersonPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openDesignPopup = () => {
    setDesignPopupVisible(true);
  };
  const handleDataSelectDesign = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("jt_IT_NM").value = Result.im_IT_NM;
    setDesignPopupVisible(false);
    setShowSearchIcon(false);
  };

  const addRow = () => {
    setJewelleryAcknowledgementTable([...JewelleryAcknowledgementTable, {}]);
  };
  const deleteRow = () => {
    if (JewelleryAcknowledgementTable.length > 0) {
      const updatedData = [...JewelleryAcknowledgementTable];
      updatedData.pop();
      setJewelleryAcknowledgementTable(updatedData);
    }
  };
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(jtUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );

  const onChangeOfPrice = async (e) => {
    //const table = document.getElementById("RentalContractTable");
    //const totalRowCount = table.tBodies[0].rows.length;
    // rentalContracts = [];
    const table = document.getElementById("JewelleryAcknowledgementTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const JewelleryAcknowledgementList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        jtUkNo: document.getElementById("jtUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      JewelleryAcknowledgementList.push(details);
    }
    const requestBodyNet = {
      JewelleryAcknowledgementList,
    };

    const response = await axios.post(`${domain}/api/JewelleryAcknowledgement/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("f_WEIGHT").value = respNet.f_EXT_PRICE;
      document.getElementById("f_VOLUME").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jtUkNo ? "Revise Bag Acknowledgement" : "Add Bag Acknowledgement"}</h5>
      <div style={{ width: '30rem', display: 'flex', justifyContent: 'flex-start' }}>
        {activeTab === 0 && (
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
        )}
        {activeTab === 0 && (
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        )}
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
                  <label htmlFor="jtUkNo" className="font-bold">
                    UK NO
                  </label>
                  <InputText disabled
                    id="jtUkNo"
                    name="jtUkNo"
                    defaultValue={jtUkNo}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_OR_NO" className="font-bold">
                    Work Order
                  </label>
                  <InputText disabled
                    id="jt_OR_NO"
                    name="jt_OR_NO"
                    value={jt_OR_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_OR_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="jt_OR_TY"
                    name="jt_OR_TY"
                    value={jt_OR_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="jt_OR_CO"
                    name="jt_OR_CO"
                    value={jt_OR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_TR_DT" className="font-bold">
                    Transfer Date
                  </label>
                  <Calendar disabled
                    id="jt_TR_DT"
                    name="jt_TR_DT"
                    value={moment(jt_TR_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_IT_NM" className="font-bold">
                    Design No<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText disabled
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openDesignPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="jt_IT_NM"
                      name="jt_IT_NM"
                      defaultValue={jt_IT_NM}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_WO_QT" className="font-bold">
                    Order Quantity
                  </label>
                  <InputText disabled
                    id="jt_WO_QT"
                    name="jt_WO_QT"
                    value={jt_WO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_BG_NO" className="font-bold">
                  Bag No
                  </label>
                  <InputText disabled
                    id="jt_BG_NO"
                    name="jt_BG_NO"
                    value={jt_WO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_BG_NO" className="font-bold">
                    Bag No
                  </label>
                  <Dropdown
                    id="jt_BG_NO"
                    name="jt_BG_NO"
                    value={jt_BG_NO}
                    options={[
                      { label: "230002_1", value: "230002_1" },
                      { label: "230002_2", value: "230002_2" },
                      { label: "230006_1", value: "230006_1" },
                      { label: "240009_1", value: "240009_1" },
                      { label: "240010_1", value: "240010_1" },
                      { label: "240010_2", value: "240010_2" },
                      { label: "240011_1", value: "240011_1" },
                      { label: "N/A", value: "N/A" },
                    ]}
                    onChange={(e) => onchangeDropDownFormData1("jt_BG_NO", e.value)}
                    placeholder="Bag No"
                    disabled
                  />
                </div> */}

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_CO_QT" className="font-bold">
                    Bag Quantity
                  </label>
                  <InputText disabled
                    id="jt_CO_QT"
                    name="jt_CO_QT"
                    value={jt_CO_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_LO_CN" className="font-bold">
                    From Location
                  </label>
                  <InputText disabled
                    id="jt_LO_CN"
                    name="jt_LO_CN"
                    value={jt_LO_CN}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_TO_LO" className="font-bold">
                    To Location
                  </label>
                  <InputText disabled
                    id="jt_TO_LO"
                    name="jt_TO_LO"
                    value={jt_TO_LO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_RL_PS" className="font-bold">
                    Transfer Person<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText disabled
                      id="ab_AD_NM1"
                      name="ab_AD_NM1"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedTransferPerson}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openTransferPerson}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="jt_RL_PS"
                      name="jt_RL_PS"
                      value={jt_RL_PS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_WT_QT" className="font-bold">
                    Weight
                  </label>
                  <InputText disabled
                    id="jt_WT_QT"
                    name="jt_WT_QT"
                    value={jt_WT_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_RL_QT" className="font-bold">
                    Received Quantity
                  </label>
                  <InputText disabled
                    id="jt_RL_QT"
                    name="jt_RL_QT"
                    value={jt_RL_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
           

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_RL_QT" className="font-bold">
                    Receive Quantity
                  </label>
                  <InputText
                    id="jt_RL_QT1"
                    name="jt_RL_QT1"
                    value={jt_RL_QT1}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_AK_DT" className="font-bold">
                  Acknowledging Date
                  </label>
                  <Calendar
                    id="jt_AK_DT"
                    name="jt_AK_DT"
                    value={moment(jt_AK_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateGLChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>

                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jt_AK_PS" className="font-bold">
                    Acknowledging Person<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM1"
                      name="ab_AD_NM1"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedAcknowledgingPerson}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openCarrierPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: "none" }}>
                    <InputText
                      id="jt_AK_PS"
                      name="jt_AK_PS"
                      value={jt_AK_PS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
              </div>
            </div>


          </TabPanel>

        </TabView>
      </div>

      {/* Design  */}
      <ItemPopup
        visible={DesignPopupVisible}
        onClose={() => setDesignPopupVisible(false)}
        onDataSelect={handleDataSelectDesign}
        formData={data}
      />
      {/* AcknowledgingPerson*/}
      <AddressBookPopup
        visible={AcknowledgingPersonPopupVisible}
        onClose={() => setAcknowledgingPersonPopupVisible(false)}
        onDataSelect={handleDataSelectAcknowledgingPerson}
        formData={data}
        addressType={CarrierType}
        setFormData={setFormData}
      />
      {/* TransferPerson */}
      <AddressBookPopup
        visible={TransferPersonPopupVisible}
        onClose={() => setTransferPersonPopupVisible(false)}
        onDataSelect={handleDataSelectTransferPerson}
        formData={data}
        addressType={CarrierType}
        setFormData={setFormData}
      />

    </Dialog>

  );
}
