import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import QuoteComparisionDialog from "./QuoteComparisionDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  qtUkNo:"",
  qt_OR_NO:"",
  qt_OR_TY:"",
  qt_OR_CO:"",
  qt_LN_NO:"",
  qt_IT_NM:"",
  qt_OR_QT:"",
  qt_TR_UM:"",
  qt_ST_CT:"",
  qt_SU_NO:"",
  qt_LT_PR:"",
  qt_SU_01:"",
  qt_PR_01:"",
  qt_SU_02:"",
  qt_PR_02:"",
  qt_SU_03:"",
  qt_PR_03:"",
  qt_SU_04:"",
  qt_PR_04:"",
  qt_FS_OP:"",
  qt_SE_OP:"",
  qt_CO_CT:"",
  qt_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  qt_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
 
};

const initialFilters = {
  qt_OR_NO:{ value: "", operation: "" },
  qt_OR_TY:{ value: "", operation: "" },
  qt_OR_CO:{ value: "", operation: "" },
  qt_LN_NO:{ value: "", operation: "" },
  qt_IT_NM:{ value: "", operation: "" },
  qt_OR_QT:{ value: "", operation: "" },
  qt_TR_UM:{ value: "", operation: "" },
  qt_ST_CT:{ value: "", operation: "" },
  qt_SU_NO:{ value: "", operation: "" },
  qt_LT_PR:{ value: "", operation: "" },
  qt_SU_01:{ value: "", operation: "" },
  qt_PR_01:{ value: "", operation: "" },
  qt_SU_02:{ value: "", operation: "" },
  qt_PR_02:{ value: "", operation: "" },
  qt_SU_03:{ value: "", operation: "" },
  qt_PR_03:{ value: "", operation: "" },
  qt_SU_04:{ value: "", operation: "" },
  qt_PR_04:{ value: "", operation: "" },
  qt_FS_OP:{ value: "", operation: "" },
  qt_SE_OP:{ value: "", operation: "" },
  qt_CO_CT:{ value: "", operation: "" },
};

const QuoteComparisionMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,tableCellStyleH,tableHeaderStyleH,inputStyle1,ListDesc,ListOrder,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  
  const [qt_TR_UM, setqt_TR_UM] = useState("");
  const [qt_PT_01, setqt_PT_01] = useState("");
  const [qt_PT_02, setqt_PT_02] = useState("");
  const [qt_PT_03, setqt_PT_03] = useState("");
  const [qt_PT_04, setqt_PT_04] = useState("");

  const [Selectedsupplier, setSelectedsupplier] = useState("");
  const [SelectedName, setSelectedName] = useState("");
  const [Selectedsupplier1, setSelectedsupplier1] = useState("");
  const [SelectedName1, setSelectedName1] = useState("");
  const [Selectedsupplier2, setSelectedsupplier2] = useState("");
  const [SelectedName2, setSelectedName2] = useState("");
  const [Selectedsupplier3, setSelectedsupplier3] = useState("");
  const [SelectedName3, setSelectedName3] = useState("");
  const [Selectedsupplier4, setSelectedsupplier4] = useState("");
  const [SelectedName4, setSelectedName4] = useState("");

 
// Advancesearch
  const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
  const [SelectedemEMNOC, setSelectedemEMNOC ] = useState("");
  const [SelectedemEMNOD, setSelectedemEMNOD ] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE ] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1 ] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM ] = useState("");
  const [SelectedItem, setselectedItem ] = useState("");
  const [SelectedDesc, setselectedDesc ] = useState("");
  const [selectedSeNO, setselectedSeNO ] = useState("");
  const [selectedLoc, setselectedLoc ] = useState("");

// Next No
  const [qt_PR_BY, setqt_PR_BY ] = useState("");
  const [qt_FS_OP, setqt_FS_OP ] = useState("");

  const [QuoteComparisionTable, setQuoteComparisionTable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setQuoteComparisionTable([{}]);
    setFormData(initialValue);
    setqt_TR_UM(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setQuoteComparisionTable([{}]);
    setFormData(initialValue);
    setqt_TR_UM(null);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedemEMNOE(null);
    setSelectedemEMNOD(null);
    setSelectedemEMNOC(null);
    setselectedItem(null);
    setselectedDesc(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [qt_OR_CO , setqt_OR_CO] = useState("");
  const [qt_OR_TY , setqt_OR_TY] = useState("");
 


  const handleDefault = async () => {
    setqt_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "QT_OR_TY"){
              setqt_OR_TY(dataDefault.versionIds[i].default_VALUE)
            }
           
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/QuoteComparison/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.qtUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      QuoteComparisionTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/QuoteComparison/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const QuoteComparisionData = response.data;
        setQuoteComparisionTable(QuoteComparisionData.quoteComparisons);
        setFormData(QuoteComparisionData.quoteComparisons[0]);
        setqt_TR_UM(QuoteComparisionData.quoteComparisons.qt_TR_UM);
        setSelectedemEMNO(response.data.quoteComparisons[0].name1);
        setSelectedAbAdNM(response.data.quoteComparisons[0].name2);
        setSelectedemEMNOE(response.data.quoteComparisons[0].name3);
        setSelectedemEMNOD(response.data.quoteComparisons[0].name4);
        setSelectedemEMNOC(response.data.quoteComparisons[0].name5);

        setselectedItem(QuoteComparisionData.quoteComparisons[0].qt_IT_NM);
        setselectedDesc(QuoteComparisionData.quoteComparisons[0].itemDES);

        setSelectedsupplier(QuoteComparisionData.quoteComparisons[0].qt_SU_01);
        setSelectedName(QuoteComparisionData.quoteComparisons[0].sup_NAME1);

        setSelectedsupplier1(QuoteComparisionData.quoteComparisons[0].qt_SU_02);
        setSelectedName1(QuoteComparisionData.quoteComparisons[0].sup_NAME2);

        setSelectedsupplier2(QuoteComparisionData.quoteComparisons[0].qt_SU_03);
        setSelectedName2(QuoteComparisionData.quoteComparisons[0].sup_NAME3);

        setSelectedsupplier3(QuoteComparisionData.quoteComparisons[0].qt_SU_04);
        setSelectedName3(QuoteComparisionData.quoteComparisons[0].sup_NAME4);
        
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/QuoteComparison/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const QuoteComparisionData = response.data;
          delete QuoteComparisionData.quoteComparisons[0].qt_OR_NO;
          delete QuoteComparisionData.quoteComparisons[0].qtUkNo;
          for (let i = 0; i < QuoteComparisionData.quoteComparisons.length; i++) {
            delete QuoteComparisionData.quoteComparisons[i].qtUkNo;
          }
          setQuoteComparisionTable(QuoteComparisionData.quoteComparisons);
        setFormData(QuoteComparisionData.quoteComparisons[0]);
        setqt_TR_UM(QuoteComparisionData.quoteComparisons.qt_TR_UM);
        setSelectedemEMNO(response.data.quoteComparisons[0].name1);
        setSelectedAbAdNM(response.data.quoteComparisons[0].name2);
        setSelectedemEMNOE(response.data.quoteComparisons[0].name3);
        setSelectedemEMNOD(response.data.quoteComparisons[0].name4);
        setSelectedemEMNOC(response.data.quoteComparisons[0].name5);

        setselectedItem(QuoteComparisionData.quoteComparisons[0].qt_IT_NM);
        setselectedDesc(QuoteComparisionData.quoteComparisons[0].itemDES);

        setSelectedsupplier(QuoteComparisionData.quoteComparisons[0].qt_SU_01);
        setSelectedName(QuoteComparisionData.quoteComparisons[0].sup_NAME1);

        setSelectedsupplier1(QuoteComparisionData.quoteComparisons[0].qt_SU_02);
        setSelectedName1(QuoteComparisionData.quoteComparisons[0].sup_NAME2);

        setSelectedsupplier2(QuoteComparisionData.quoteComparisons[0].qt_SU_03);
        setSelectedName2(QuoteComparisionData.quoteComparisons[0].sup_NAME3);

        setSelectedsupplier3(QuoteComparisionData.quoteComparisons[0].qt_SU_04);
        setSelectedName3(QuoteComparisionData.quoteComparisons[0].sup_NAME4);
          
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("QuoteComparisionTable");
  const totalRowCount = table.tBodies[0].rows.length;
  const quoteComparisons = [];
  for (let i = 0; i < totalRowCount; i++) {
    const details = {
        qtUkNo : document.getElementById("qtUkNo_" + i).value,
        qt_LN_NO : document.getElementById("qt_LN_NO_" + i).value,
        qt_IT_NM : document.getElementById("qt_IT_NM_" + i).value,
        itemDES : document.getElementById("itemDES_" + i).value,
        qt_OR_QT : document.getElementById("qt_OR_QT_" + i).value,
        qt_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        qt_SU_NO : document.getElementById("qt_SU_NO_" + i).value,
        sup_NAME : document.getElementById("sup_NAME_" + i).value,
        qt_LT_PR : document.getElementById("qt_LT_PR_" + i).value,
        qt_ST_CT : document.getElementById("qt_ST_CT_" + i).value,

        qt_SU_01 : document.getElementById("qt_SU_01_" + i).value,
        sup_NAME1 : document.getElementById("sup_NAME1_" + i).value,
        qt_PR_01 : document.getElementById("qt_PR_01_" + i).value,
        qt_PT_01 : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
        qt_MK_01 : document.getElementById("qt_MK_01_" + i).value,
        qt_DL_01 : document.getElementById("qt_DL_01_" + i).value,
        qt_PL_01 : document.getElementById("qt_PL_01_" + i).value,

        qt_SU_02 : document.getElementById("qt_SU_02_" + i).value,
        sup_NAME2 : document.getElementById("sup_NAME2_" + i).value,
        qt_PR_02 : document.getElementById("qt_PR_02_" + i).value,
        qt_PT_02 : table.tBodies[0].rows[i].cells[20].children[0].children[1].children[0].value,
        qt_MK_02 : document.getElementById("qt_MK_02_" + i).value,
        qt_DL_02 : document.getElementById("qt_DL_02_" + i).value,
        qt_PL_02 : document.getElementById("qt_PL_02_" + i).value,

        qt_SU_03 : document.getElementById("qt_SU_03_" + i).value,
        sup_NAME3 : document.getElementById("sup_NAME3_" + i).value,
        qt_PR_03 : document.getElementById("qt_PR_03_" + i).value,
        qt_PT_03 : table.tBodies[0].rows[i].cells[27].children[0].children[1].children[0].value,
        qt_MK_03 : document.getElementById("qt_MK_03_" + i).value,
        qt_DL_03 : document.getElementById("qt_DL_03_" + i).value,
        qt_PL_03 : document.getElementById("qt_PL_03_" + i).value,

        qt_SU_04 : document.getElementById("qt_SU_04_" + i).value,
        sup_NAME4 : document.getElementById("sup_NAME4_" + i).value,
        qt_PR_04 : document.getElementById("qt_PR_04_" + i).value,
        qt_PT_04 : table.tBodies[0].rows[i].cells[34].children[0].children[1].children[0].value,
        qt_MK_04 : document.getElementById("qt_MK_04_" + i).value,
        qt_DL_04 : document.getElementById("qt_DL_04_" + i).value,
        qt_PL_04 : document.getElementById("qt_PL_04_" + i).value,
        qt_RM_RK : document.getElementById("qt_RM_RK_" + i).value,
    };
    quoteComparisons.push(details);
  }
  requestBody = {
    quoteComparisons,
    qtUkNo   : FormData.qtUkNo,
    qt_OR_NO : document.getElementById("qt_OR_NO").value,
    qt_OR_TY : document.getElementById("qt_OR_TY").value,
    qt_OR_CO : document.getElementById("qt_OR_CO").value,
    qt_OR_DT : FormData.qt_OR_DT,
    qt_RQ_DT : FormData.qt_RQ_DT,
    qt_RQ_BY : document.getElementById("qt_RQ_BY").value,
    qt_FS_OP : document.getElementById("qt_FS_OP").value,
    qt_SE_OP : document.getElementById("qt_SE_OP").value,
    qt_PR_BY : document.getElementById("qt_PR_BY").value,
    qt_CH_BY : document.getElementById("qt_CH_BY").value,
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = FormData.qt_OR_NO;
  const ukNo = FormData.qtUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/QuoteComparison/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/QuoteComparison/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.qt_OR_DT;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${qt_OR_TY}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            onchangeFormData("qt_OR_NO", resp.dn_DO_NO);
            setFormData({ ...FormData, ["qt_OR_NO"]: resp.dn_DO_NO });
            values();
            axios.post(`${domain}/api/QuoteComparison/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };




   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );  
  const ListPrice  = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"60px"
  };
  const ListSupplier  = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"90px"
  };
  const ListCost  = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"80px"
  };
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["qt_OR_NO"] ? filters["qt_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_OR_NO",
                        e.target.value,
                        filters["qt_OR_NO"]
                          ? filters["qt_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["qt_OR_TY"] ? filters["qt_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_OR_TY",
                        e.target.value,
                        filters["qt_OR_TY"] ? filters["qt_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["qt_OR_CO"] ? filters["qt_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_OR_CO",
                        e.target.value,
                        filters["qt_OR_CO"]
                          ? filters["qt_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["qt_LN_NO"] ? filters["qt_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_LN_NO",
                        e.target.value,
                        filters["qt_LN_NO"] ? filters["qt_LN_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["qt_IT_NM"] ? filters["qt_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_IT_NM",
                        e.target.value,
                        filters["qt_IT_NM"]
                          ? filters["qt_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["qt_OR_QT"] ? filters["qt_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_OR_QT",
                        e.target.value,
                        filters["qt_OR_QT"]
                          ? filters["qt_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_TR_UM"] ? filters["qt_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_TR_UM",
                        e.target.value,
                        filters["qt_TR_UM"]
                          ? filters["qt_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_ST_CT"] ? filters["qt_ST_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_ST_CT",
                        e.target.value,
                        filters["qt_ST_CT"]
                          ? filters["qt_ST_CT"].operation
                          : "equals"
                      )
                    }
                    style={ListCost}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["qt_SU_NO"] ? filters["qt_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SU_NO",
                        e.target.value,
                        filters["qt_SU_NO"]
                          ? filters["qt_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={ListSupplier}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_LT_PR"] ? filters["qt_LT_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_LT_PR",
                        e.target.value,
                        filters["qt_LT_PR"]
                          ? filters["qt_LT_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_SU_01"] ? filters["qt_SU_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SU_01",
                        e.target.value,
                        filters["qt_SU_01"]
                          ? filters["qt_SU_01"].operation
                          : "equals"
                      )
                    }
                    style={ListSupplier}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_PR_01"] ? filters["qt_PR_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_PR_01",
                        e.target.value,
                        filters["qt_PR_01"]
                          ? filters["qt_PR_01"].operation
                          : "equals"
                      )
                    }
                    style={ListPrice}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_SU_02"] ? filters["qt_SU_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SU_02",
                        e.target.value,
                        filters["qt_SU_02"]
                          ? filters["qt_SU_02"].operation
                          : "equals"
                      )
                    }
                    style={ListSupplier}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_PR_02"] ? filters["qt_PR_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_PR_02",
                        e.target.value,
                        filters["qt_PR_02"]
                          ? filters["qt_PR_02"].operation
                          : "equals"
                      )
                    }
                    style={ListPrice}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_SU_03"] ? filters["qt_SU_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SU_03",
                        e.target.value,
                        filters["qt_SU_03"]
                          ? filters["qt_SU_03"].operation
                          : "equals"
                      )
                    }
                    style={ListSupplier}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_PR_03"] ? filters["qt_PR_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_PR_03",
                        e.target.value,
                        filters["qt_PR_03"]
                          ? filters["qt_PR_03"].operation
                          : "equals"
                      )
                    }
                    style={ListPrice}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_SU_04"] ? filters["qt_SU_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SU_04",
                        e.target.value,
                        filters["qt_SU_04"]
                          ? filters["qt_SU_04"].operation
                          : "equals"
                      )
                    }
                    style={ListSupplier}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_PR_04"] ? filters["qt_PR_04"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_PR_04",
                        e.target.value,
                        filters["qt_PR_04"]
                          ? filters["qt_PR_04"].operation
                          : "equals"
                      )
                    }
                    style={ListPrice}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["qt_FS_OP"] ? filters["qt_FS_OP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_FS_OP",
                        e.target.value,
                        filters["qt_FS_OP"]
                          ? filters["qt_FS_OP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["qt_SE_OP"] ? filters["qt_SE_OP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_SE_OP",
                        e.target.value,
                        filters["qt_SE_OP"]
                          ? filters["qt_SE_OP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["qt_CO_CT"] ? filters["qt_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "qt_CO_CT",
                        e.target.value,
                        filters["qt_CO_CT"]
                          ? filters["qt_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Order Type</th>
                <th style={tableHeaderStyle}>Order Company</th>   
                <th style={tableHeaderStyle}>Line No</th> 
                <th style={tableHeaderStyle}>Item Name</th> 
                <th style={tableHeaderStyle}>Order Quantity</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Std Cost</th>  
                <th style={tableHeaderStyle}>Last Supplier</th> 
                <th style={tableHeaderStyle}>Last Purchase Price</th> 
                <th style={tableHeaderStyle}>Supplier 1</th>  
                <th style={tableHeaderStyle}>Price 1</th> 
                <th style={tableHeaderStyle}>Supplier 2</th>  
                <th style={tableHeaderStyle}>Price 2</th> 
                <th style={tableHeaderStyle}>Supplier 3</th>  
                <th style={tableHeaderStyle}>Price 3</th>
                <th style={tableHeaderStyle}>Supplier 4</th>  
                <th style={tableHeaderStyle}>Price 4</th>
                <th style={tableHeaderStyle}>First Option</th>
                <th style={tableHeaderStyle}>Second Option</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                          
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.qtUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.qtUkNo}
                        checked={selectedRows.includes(rowData.qtUkNo)}
                        onChange={() => handleRowSelect(rowData.qtUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.qt_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.qt_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.qt_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.qt_LN_NO}</td> 
                    <td style={tableCellStyle}>{rowData.qt_IT_NM}</td>  
                    <td style={tableCellStyle}>{rowData.qt_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.qt_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.qt_ST_CT}</td>   
                    <td style={tableCellStyle}>{rowData.qt_SU_NO}</td>  
                    <td style={tableCellStyle}>{rowData.qt_LT_PR}</td>   
                    <td style={tableCellStyle}>{rowData.qt_SU_01}</td>   
                    <td style={tableCellStyle}>{rowData.qt_PR_01}</td>   
                    <td style={tableCellStyle}>{rowData.qt_SU_02}</td>
                    <td style={tableCellStyle}>{rowData.qt_PR_02}</td>    
                    <td style={tableCellStyle}>{rowData.qt_SU_03}</td> 
                    <td style={tableCellStyle}>{rowData.qt_PR_03}</td>   
                    <td style={tableCellStyle}>{rowData.qt_SU_04}</td> 
                    <td style={tableCellStyle}>{rowData.qt_PR_04}</td>    
                    <td style={tableCellStyle}>{rowData.qt_FS_OP}</td>   
                    <td style={tableCellStyle}>{rowData.qt_SE_OP}</td>   
                    <td style={tableCellStyle}>{rowData.qt_CO_CT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <QuoteComparisionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        QuoteComparisionTable={QuoteComparisionTable}
        setQuoteComparisionTable={setQuoteComparisionTable}
        qt_OR_CO={qt_OR_CO}
        qt_OR_TY={qt_OR_TY}
        qt_TR_UM={qt_TR_UM}
        setqt_TR_UM={setqt_TR_UM}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOC={SelectedemEMNOC}
        setSelectedemEMNOC={setSelectedemEMNOC}
        SelectedemEMNOD={SelectedemEMNOD}
        setSelectedemEMNOD={setSelectedemEMNOD}
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        selectedAbAdNM={selectedAbAdNM}
        setSelectedAbAdNM={setSelectedAbAdNM}
        qt_FS_OP={qt_FS_OP}
        setqt_FS_OP={setqt_FS_OP}
        qt_PR_BY={qt_PR_BY}
        setqt_PR_BY={setqt_PR_BY}
        setselectedSeNO={setselectedSeNO}
        selectedSeNO={selectedSeNO}
        selectedLoc={selectedLoc}
        setselectedLoc={setselectedLoc}
        setSelectedsupplier={setSelectedsupplier}
        Selectedsupplier={Selectedsupplier}
        setSelectedName={setSelectedName}
        SelectedName={SelectedName}
        setSelectedsupplier1={setSelectedsupplier1}
        Selectedsupplier1={Selectedsupplier1}
        setSelectedName1={setSelectedName1}
        SelectedName1={SelectedName1}
        setSelectedsupplier2={setSelectedsupplier2}
        Selectedsupplier2={Selectedsupplier2}
        setSelectedName2={setSelectedName2}
        SelectedName2={SelectedName2}
        setSelectedsupplier3={setSelectedsupplier3}
        Selectedsupplier3={Selectedsupplier3}
        setSelectedName3={setSelectedName3}
        SelectedName3={SelectedName3}
        setSelectedsupplier4={setSelectedsupplier4}
        Selectedsupplier4={Selectedsupplier4}
        setSelectedName4={setSelectedName4}
        SelectedName4={SelectedName4}
        qt_PT_01={qt_PT_01}
        qt_PT_02={qt_PT_02}
        qt_PT_03={qt_PT_03}
        qt_PT_04={qt_PT_04}
        setqt_PT_01={setqt_PT_01}
        setqt_PT_02={setqt_PT_02}
        setqt_PT_03={setqt_PT_03}
        setqt_PT_04={setqt_PT_04}
        
      />
    </div>
  );
};

export default QuoteComparisionMain;
