import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import MaintainanceOrders from "../Popups/MaintainOrdersPopup";
export default function FailureAnalysisDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  handleFormSubmit,
}) {
  const {
    faUkNo,
    fa_OR_NO,
    fa_OR_TY,
    fa_OR_CO, 
    fa_EQ_NM,
    fa_FL_CD,
    fa_FL_DS,
    fa_FA_DS,
    fa_RS_ON,
  } = data;
// buttonstyles
const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
// ADVance search
const [OrderPopupVisible, setOrderPopupVisible] = useState(false);
const [showSearchIcon, setShowSearchIcon] = useState("");

const openOrderPopup = () => {
  setOrderPopupVisible(true);
  setShowSearchIcon(false);
};
const handleSelectOrder = (content) => {
  document.getElementById("fa_OR_NO").value = content.wm_OR_NO;
  document.getElementById("fa_OR_TY").value = content.wm_OR_TY;
  document.getElementById("fa_OR_CO").value = content.wm_OR_CO;
  document.getElementById("fa_EQ_NM").value = content.wm_EQ_NM;
  
  setOrderPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(faUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{faUkNo ? "Revise Failure Analysis" : "Add Failure Analysis"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
        <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="faUkNo" className="font-bold">
                  UK
                </label>
                <InputText
                  id="faUkNo"
                  name="faUkNo"
                  defaultValue={faUkNo}
                  onChange={(e) => onchangedata(e)}
                />
            </div>  
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fa_OR_NO" className="font-bold">
                  Order No <span style={{color:"red"}}> *</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText
                  id="fa_OR_NO"
                  name="fa_OR_NO"                     
                  onFocus={() => setShowSearchIcon(true)} 
                  onChange={(e) => onchangedata(e)} 
                  defaultValue={fa_OR_NO} 
                />
                {showSearchIcon && (
                  <i 
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                    
                  }}
                  onClick={(e) => openOrderPopup(e)}
                ></i>
                  )}
                </div>
              </div>  
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fa_OR_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="fa_OR_TY"
                  name="fa_OR_TY"
                  defaultValue={fa_OR_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fa_OR_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="fa_OR_CO"
                  name="fa_OR_CO"
                  defaultValue={fa_OR_CO}
                  onChange={(e) => onchangedata(e)}
                />
              </div> 
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fa_EQ_NM" className="font-bold">
                  Equipment Name
                </label>
                <InputText disabled
                  id="fa_EQ_NM"
                  name="fa_EQ_NM"
                  defaultValue={fa_EQ_NM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="fa_FL_CD" className="font-bold">
                  Failure Code
                </label>
                <InputText
                  id="fa_FL_CD"
                  name="fa_FL_CD"
                  defaultValue={fa_FL_CD}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
          </div>
          <div className="formgrid grid"> 
            <label htmlFor="fa_FL_DS" className="font-bold">
              Failure
            </label>
            <textarea
              id="fa_FL_DS"
              name="fa_FL_DS"
              value={fa_FL_DS}
              onChange={(e) => onchangedata(e)}
              style={{ width: "100%" }}
            />          
          </div>
          </div>
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <label htmlFor="fa_FA_DS" className="font-bold">
              Analysis
            </label>
            <textarea
                id="fa_FA_DS"
                name="fa_FA_DS"
                value={fa_FA_DS}
                onChange={(e) => onchangedata(e)}
                style={{ width: "100%" }}
              />
          </div>
          </div>
          <div style={inboxstyle} >
            <div className="formgrid grid">
              <label htmlFor="fa_RS_ON" className="font-bold">
                Resolution
              </label>
              <textarea
                id="fa_RS_ON"
                name="fa_RS_ON"
                value={fa_RS_ON}
                onChange={(e) => onchangedata(e)}
                style={{ width: "100%" }}
              />
            </div>
          </div>
      </div>    
      <MaintainanceOrders
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleSelectOrder}
        formData={data}
      />  
    </Dialog>
  );
}
