import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import WorkOrderEntryPopup from "../Popups/WorkOrderEntryPopup";
import ClassCode from "views/common/ClassCode";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import Costcenter  from "views/common/CostCentreDrpDwn";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function FindingTransferDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  handleRun,
  onchangedata,
  onchangeFormData,

  addRow,
  deleteRow,
  FindingTransfertable,
  SelectedOrder,
  setSelectedOrder,
  SelectedITNM,
  setSelectedITNM,

  setFormData,
  ji_OR_TY,
  ji_OR_CO,
  ji_DO_NO,
  ji_DO_TY,
  ji_DO_CO,
  ji_FR_CT,
  setji_FR_CT,
  ji_TO_CT,
  setji_TO_CT,
  ji_FR_LO,
  setji_FR_LO,
  ji_TR_UM,
  setji_TR_UM,
  ji_SC_UM,
  setji_SC_UM,
  ji_TO_LO,
  setji_TO_LO,
 // ji_TR_DT,
}) {
  const {
    jiUkNo,
    ji_OR_NO,
    ji_GL_DT,
    ji_TR_DT,
    ji_WO_QT,
    ji_BG_NO,
    ji_BG_QT,
    ji_WO_TR,
    ji_TR_QT,
    ji_DE_NO,
  } = data;
  

  const [ji_FR_CTOptions, setji_FR_CTOptions] = useState([]);
  const [ji_TO_CTOptions, setji_TO_CTOptions] = useState([]);
  const [ji_FR_LOOptions, setji_FR_LOOptions] = useState([]);
  const [ji_TR_UMOptions, setji_TR_UMOptions] = useState([]);
  const [ji_SC_UMOptions, setji_SC_UMOptions] = useState([]);
  const [ji_TO_LOOptions, setji_TO_LOOptions] = useState([]);
  

  const [pm_ST_CDOptions, setpm_ST_CDOptions] = useState([]);
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  // Dropdown
  const { fetchClassCodeDS } = ClassCode();
  const { fetchCostCenter } = Costcenter();
   

    const onchangeDropDownFormData1 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_FR_CT(value);
    };

    const onchangeDropDownFormData2 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_TO_CT(value);
    };
    const onchangeDropDownFormData3 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_TR_UM(value);
    };

    const onchangeDropDownFormData4 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_SC_UM(value);
    };

    const onchangeDropDownFormData5 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_FR_LO(value);
    };

    const onchangeDropDownFormData6 = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setji_TO_LO(value);
    };

  useEffect(() => {
    FromCostCentre();
    ToCostCenter();
    Status();
    TransferUM();
    SecUM();
    FromLocation();
    ToLocation();
  }, []);

  const Status = async () => {
    const Options = await fetchClassCodeDS("CS/ST");
    setpm_ST_CDOptions(Options);
  };
  const TransferUM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setji_TR_UMOptions(Options);
  };
  const SecUM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setji_SC_UMOptions(Options);
  };
  const FromLocation = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setji_FR_LOOptions(Options);
  };
  const ToLocation = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setji_TO_LOOptions(Options);
  };
  const FromCostCentre = async () => {
    const Options = await fetchCostCenter();
    setji_FR_CTOptions(Options);
  };
  const ToCostCenter = async () => {
    const Options = await fetchCostCenter();
    setji_TO_CTOptions(Options);
  };
 
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [OrderPopupVisible, setOrderPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);

  const openOrderPopup = () => {
    setOrderPopupVisible(true);
  };
  const handleDataSelectOrder = (Result) => {
    setSelectedOrder(Result.wd_WO_NO);
    document.getElementById("ji_OR_NO").value = Result.wd_WO_NO;
    //setbd_OR_TY(Result.wd_WO_TY);
    //document.getElementById("bd_OR_CO").value =Result.wd_WO_CO;
    setOrderPopupVisible(false);
    setShowSearchIcon(false);
     onChangeOfOrderNo(Result.wd_WO_NO,Result.wd_WO_TY)
  };

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ji_DE_NO").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DESC", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("ji_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const onChangeOfOrderNo = async (wd_WO_NO ,wd_WO_TY) => {
      const Url = `${domain}/api/BagDetails/GetDetailsByWoNumber`;
      const requestData={
        "bd_OR_NO":wd_WO_NO,
        "bd_OR_TY":wd_WO_TY
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });  
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const Resultdata = response.data.Result;
        if (Resultdata) { 
           document.getElementById("ji_BG_NO").value = Resultdata.bd_BG_NO; 
          await onChangeOfBagDetails(wd_WO_NO, Resultdata.bd_BG_NO);
          await onChangeOfPartListDetails(wd_WO_NO);
        } else {
          console.error("Invalid API response:", Resultdata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
       
      }
      //setShipToPopupVisible(false);
      setShowSearchIcon(false);
     // onChangeOfOrderNo1(wd_WO_NO ,wd_WO_TY)
     
   
    };
    const onChangeOfBagDetails = async (wd_WO_NO,bd_BG_NO) => {
    
        const Url = `${domain}/api/JewelInventoryTransfer/GetBagDetails`;
        const requestData={
          "ji_BG_NO":bd_BG_NO,
          "ji_OR_NO":wd_WO_NO,
          "ji_OR_TY":ji_OR_TY,
          "ji_OR_CO":ji_OR_CO,
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });  
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const Resultdata = response.data;
          if (Resultdata) { 
            document.getElementById("ji_WO_QT").value = Resultdata.Result.ji_WO_QT; 
            document.getElementById("ji_WO_TR").value = Resultdata.Result.ji_WO_TR; 
            document.getElementById("ji_BG_QT").value = Resultdata.Result.ji_BG_QT; 
            document.getElementById("ji_DE_NO").value = Resultdata.Result.ji_DE_NO; 
            document.getElementById("ji_DE_NO").value = Resultdata.Result.ji_DE_NO;
            setSelectedITNM=Resultdata.Result.item_Name;
            
          } else {
            console.error("Invalid API response:", Resultdata);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
        //setShipToPopupVisible(false);
        setShowSearchIcon(false);
       // onChangeOfOrderNo1(wd_WO_NO ,wd_WO_TY)
       
      };
      const onChangeOfPartListDetails = async (wd_WO_NO) => {
    
        const Url = `${domain}/api/JewelInventoryTransfer/GetPartListDetailsByWoNumber`;
        const requestData={
          "ji_OR_NO":wd_WO_NO,
          "ji_OR_TY":ji_OR_TY,
          "ji_OR_CO":ji_OR_CO,
          "ji_TO_LO":ji_TO_LO,
          "ji_FR_LO":ji_FR_LO,
          "ji_FR_CT":ji_FR_CT,
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });  
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const Resultdata = response.data.Result;
          if (Array.isArray(Resultdata) && Resultdata.length > 0) {
            Resultdata.forEach((Result, index) => {
              const rowIndex = SelectedRowIndex + index; 
          
              // Update the document fields with the array data
              document.getElementById(`ji_TR_QT_${rowIndex}`).value = Result.ji_TR_QT;
              document.getElementById(`ji_AV_QT_${rowIndex}`).value = Result.ji_AV_QT;
              document.getElementById(`ji_LN_NO_${rowIndex}`).value = Result.ji_LN_NO;
          
              // Call onchange functions with the array data
              onchangeDropDownFormData5(`ji_FR_LO_${rowIndex}`, Result.ji_FR_LO);
              onchangeDropDownFormData6(`ji_TO_LO_${rowIndex}`, Result.ji_TO_LO);
              onchangeDropDownFormData4(`ji_SC_UM_${rowIndex}`, Result.ji_SC_UM);
              onchangeDropDownFormData3(`ji_TR_UM_${rowIndex}`, Result.ji_TR_UM);
          
              // Call onchangeFormData for additional fields
              onchangeFormData("item_DESC", Result.item_Name, rowIndex);
              onchangeFormData("ji_IT_NM", Result.ji_IT_NM, rowIndex);
            });
          } else {
            console.error("Invalid API response:", Resultdata);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    
        //setShipToPopupVisible(false);
        setShowSearchIcon(false);
       // onChangeOfOrderNo1(wd_WO_NO ,wd_WO_TY)
      };

  const isUpdateMode = Boolean(jiUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
        {isUpdateMode && (
          <div style={{ display: 'flex', width: "10%" }}>
            <Button
              style={{ width: "100%" }}
              icon="pi pi-check-circle"
              onClick={handleConfirm}
              tooltip={"Confirm"}>
            </Button>
            <Button
              style={{ width: "100%" }}
              icon="pi pi-play"
              onClick={handleRun}
              tooltip={"Run"}>
            </Button>
          </div>
        )}
        <div style={{ display: 'flex', width: "10%" }}>
          <Button
            style={{ width: "100%" }}
            icon="pi pi-times"
            variant="outlined"
            onClick={handleClose}
            tooltip="Cancel"
          >
          </Button>
          <Button
            style={{ width: "100%" }}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save">
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "ji_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  const handleDateGLChange = (e) => {
    setSelectedGLDate(e.value);
    onchangedata({ target: { name: "ji_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // const [selectedGLDate, setSelectedGLDate] = useState(moment().toDate());
  // const handleDateGLChange = (e) => {
  //   setSelectedGLDate(e.value);
  //   onchangedata({ target: { name: "ji_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  // };
  // disabled after confirm
 // const isDisabled = ac_ST_US === "C";


  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jiUkNo ? "Revise Finding Transfer" : "Add Finding Transfer"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
      
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_DO_NO" className="font-bold">
                Transaction No
              </label>
              <InputText disabled
                id="ji_DO_NO"
                name="ji_DO_NO"
                value={ji_DO_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_DO_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="ji_DO_TY"
                name="ji_DO_TY"
                value={ji_DO_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_DO_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ji_DO_CO"
                name="ji_DO_CO"
                value={ji_DO_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_TR_DT" className="font-bold">
                Transfer Date
              </label>
              <Calendar disabled
                id="ji_TR_DT"
                name="ji_TR_DT"
                value={moment(ji_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_OR_NO" className="font-bold">
                Work Order<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: 'flex' }}>
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedOrder}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openOrderPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <InputText
                  id="ji_OR_NO"
                  name="ji_OR_NO"
                  defaultValue={ji_OR_NO}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_OR_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="ji_OR_TY"
                name="ji_OR_TY"
                value={ji_OR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_OR_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ji_OR_CO"
                name="ji_OR_CO"
                value={ji_OR_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_GL_DT" className="font-bold">
              GL Date
              </label>
              <Calendar disabled
                id="ji_GL_DT"
                name="ji_GL_DT"
                value={moment(ji_GL_DT, "YYYYMMDD").toDate()}
                onChange={handleDateGLChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_DE_NO" className="font-bold">
                Item Number<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: 'flex' }}>
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedITNM}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openItemPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <InputText
                  id="ji_DE_NO"
                  name="ji_DE_NO"
                  defaultValue={ji_DE_NO}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_WO_QT" className="font-bold">
              Order Quantity
              </label>
              <InputText disabled
                id="ji_WO_QT"
                name="ji_WO_QT"
                value={ji_WO_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_BG_NO" className="font-bold">
                Bag No
              </label>
              <InputText 
                id="ji_BG_NO"
                name="ji_BG_NO"
                value={ji_BG_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_BG_QT" className="font-bold">
                Bag Quantity
              </label>
              <InputText
                id="ji_BG_QT"
                name="ji_BG_QT"
                value={ji_BG_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_FR_CT" className="font-bold">
                From Cost Centre
              </label>
              <Dropdown 
                id="ji_FR_CT"
                name="ji_FR_CT"
                value={ji_FR_CT}
                options={ji_FR_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("ji_FR_CT", e.value)}
                placeholder="From Cost Centre"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_TO_CT" className="font-bold">
                To Cost Centre
              </label>
              <Dropdown 
                id="ji_TO_CT"
                name="ji_TO_CT"
                value={ji_TO_CT}
                options={ji_TO_CTOptions}
                onChange={(e) => onchangeDropDownFormData2("ji_TO_CT", e.value)}
                placeholder="To Cost Centre"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_WO_TR" className="font-bold">
                Transferred Qty.
              </label>
              <InputText 
                id="ji_WO_TR"
                name="ji_WO_TR"
                value={ji_WO_TR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ji_TR_QT" className="font-bold">
                Transfer Quantity
              </label>
              <InputText 
                id="ji_TR_QT"
                name="ji_TR_QT"
                value={ji_TR_QT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="FindingTransfertable" style={{ overflow: "auto" }}>
            <thead>
              <tr>
                <th style={{ display: "none" }} >UK_NO</th>
                <th>Line No	</th>
                <th>Item No <span style={{ color: "red" }}>*</span> </th>
                <th>Description</th>
                <th>Transfer Quantity	</th>
                <th>UM</th>
                <th>Sec. Qty</th>
                <th>UM</th>
                <th>From Location</th>
                <th>Stock</th>
                <th>To Location</th>
              </tr>
            </thead>
            <tbody>
              {FindingTransfertable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }} >
                    <InputText
                      id={`jiUkNo_${index}`}
                      name={`jiUkNo_${index}`}
                      defaultValue={row.jiUkNo}
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <InputText disabled
                      id={`ji_LN_NO_${index}`}
                      name={`ji_LN_NO_${index}`}
                      value={index + 1}
                      //defaultValue={row.ji_LN_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td style={{ display: "flex" }}>
                    <InputText
                      // style={{ width: "110px" }}
                      id={`ji_IT_NM_${index}`}
                      name={`ji_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.ji_IT_NM ? row.ji_IT_NM : row[`${"ji_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup1(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      //style={{ width: "20px" }}
                      id={`item_DESC_${index}`}
                      name={`item_DESC_${index}`}
                      value={row.item_DESC ? row.item_DESC : row[`${"item_DESC"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <InputText disabled
                      id={`ji_TR_QT_${index}`}
                      name={`ji_TR_QT_${index}`}
                      defaultValue={row.ji_TR_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ji_TR_UM_${index}`}
                      name={`ji_TR_UM_${index}`}
                      value={data[`ji_TR_UM_${index}`] ? data[`ji_TR_UM_${index}`] : row.ji_TR_UM}
                      options={ji_TR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData3(`ji_TR_UM_${index}`, e.value)}
                      placeholder="UM"
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <InputText disabled
                      id={`ji_SC_QT_${index}`}
                      name={`ji_SC_QT_${index}`}
                      defaultValue={row.ji_SC_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ji_SC_UM_${index}`}
                      name={`ji_SC_UM_${index}`}
                      value={data[`ji_SC_UM_${index}`] ? data[`ji_SC_UM_${index}`] : row.ji_SC_UM}
                      options={ji_SC_UMOptions}
                      onChange={(e) => onchangeDropDownFormData4(`ji_SC_UM_${index}`, e.value)}
                      placeholder="UM"
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ji_FR_LO_${index}`}
                      name={`ji_FR_LO_${index}`}
                      value={data[`ji_FR_LO_${index}`] ? data[`ji_FR_LO_${index}`] : row.ji_FR_LO}
                      options={ji_FR_LOOptions}
                      onChange={(e) => onchangeDropDownFormData5(`ji_FR_LO_${index}`, e.value)}
                      placeholder="From Location"
                    />
                  </td>
                  <td style={{ width: "100px" }}>
                    <InputText 
                      id={`ji_AV_QT_${index}`}
                      name={`ji_AV_QT_${index}`}
                      defaultValue={row.ji_AV_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`ji_TO_LO_${index}`}
                      name={`ji_TO_LO_${index}`}
                      value={data[`ji_TO_LO_${index}`] ? data[`ji_TO_LO_${index}`] : row.ji_TO_LO}
                      options={ji_TO_LOOptions}
                      onChange={(e) => onchangeDropDownFormData6(`ji_TO_LO_${index}`, e.value)}
                      placeholder="To Location"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>
      </div>
   
      {/* WorkOrder */}
      <WorkOrderEntryPopup
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleDataSelectOrder}
        formData={data}
      />
      {/* Item  */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
      {/* item */}
      {<ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />}
    </Dialog>
  );
}
