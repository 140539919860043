import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import MaterialRequisitionDialog from "./MaterialRequisitionDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialValue = {
  jbUkNo:"",
  jb_JB_NO:"",
  jb_JB_TY:"",
  jb_JB_CO:"",
  jb_JB_QT:"",
  jb_JB_UM:"",
  jb_CO_CT:"",
  jb_PR_NM:"", 
  item_DESC:"", 
  jb_CO_NM:"",
  comp_desc:"",
  jb_OR_QT:"",
  jb_TR_QT:"",
  jb_IS_QT:"",
  jb_TR_UM:"",
  jb_LO_CN:"",
  jb_LT_NO:"",
  jb_TR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  jb_IS_DT:moment(moment().toDate()).format("YYYYMMDD"),
  jb_OR_NO:"",
  jb_OR_TY:"",
  jb_OR_CO:"",
 
};
const initialFilters = {
  jb_JB_NO:  { value: "", operation: "" },
  jb_JB_TY:  { value: "", operation: "" },
  jb_JB_CO:  { value: "", operation: "" },
  jb_JB_QT:  { value: "", operation: "" },
  jb_JB_UM:  { value: "", operation: "" },
  jb_CO_CT:  { value: "", operation: "" },
  jb_PR_NM:  { value: "", operation: "" }, 
  item_DESC:  { value: "", operation: "" }, 
  jb_CO_NM:  { value: "", operation: "" },
  comp_desc:  { value: "", operation: "" },
  jb_OR_QT:  { value: "", operation: "" },
  jb_TR_QT:  { value: "", operation: "" },
  jb_IS_QT:  { value: "", operation: "" },
  jb_TR_UM:  { value: "", operation: "" },
  jb_LO_CN:  { value: "", operation: "" },
  jb_LT_NO:  { value: "", operation: "" },
  jb_TR_DT:  { value: "", operation: "" },
  jb_IS_DT:  { value: "", operation: "" },
  jb_OR_NO:  { value: "", operation: "" },
  jb_OR_TY:  { value: "", operation: "" },
  jb_OR_CO:  { value: "", operation: "" },
};
const MaterialRequisitionMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,ListDesc,
     copyButtonStyles,exportButtonStyles, checkboxStyle,tableHeaderStyle,
     inputStyle,tableCellStyle,inputStyle1  } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState(initialFilters);
  const [SelectedemOperator, setSelectedemOperator] = useState(initialFilters);
  const [SelectedOrder, setSelectedOrder] = useState(initialFilters);

  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  const [MaterialRequisitiontable, setMaterialRequisitiontable] = useState([{}]);
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const [routingItemName, setroutingItemName] = useState("");
  const [rtWK_dESC, setrtWK_dESC] = useState("");

  const [rt_CO_CT, setrt_CO_CT] = useState("");
  const [jb_JB_UM, setjb_JB_UM] = useState("");
  const [rt_RT_TY, setrt_RT_TY] = useState("");
  const [jb_TR_UM, setjb_TR_UM] = useState("");
  const [jb_CO_CT, setjb_CO_CT] = useState("");
  const [item_DESC, setitem_DESC] = useState("");
  const [jb_OR_CO, setjb_OR_CO] = useState("");
  const [jb_JB_CO, setjb_JB_CO] = useState("");
  const [jb_OR_TY, setjb_OR_TY] = useState("");
  const [jb_JB_TY, setjb_JB_TY] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setMaterialRequisitiontable([{}]);
    setroutingItemName(null);
    setrtWK_dESC(null);
    setjb_JB_UM(null);
    setFormData(initialValue);
    setSelectedemEMNOE(null);
    setSelectedemOperator(null);
    setSelectedOrder(null);
    setjb_TR_UM(null);
    setjb_CO_CT(null);
    setitem_DESC(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleDefault = async () => {
    setjb_JB_CO(`${company}`)
    setjb_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "JB_JB_TY"){
              setjb_JB_TY(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "JB_OR_TY"){
              setjb_OR_TY(dataDefault.versionIds[i].default_VALUE);
            }
           
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/MaterialRequisition/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  // COST CENTER
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_CO_CT(value);
  };
  // Type
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setrt_RT_TY(value);
  };
  // Time Basis
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjb_TR_UM(value);
  };

  
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    setFormData(initialValue);
    const jobOrderDetails={
      jbUkNo : selectedRows[0],
    }
      const requestBodyInput = {
        jobOrderDetails
        };
    try {
      const response = await axios.post(
        `${domain}/api/MaterialRequisition/Select`,requestBodyInput,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const MaterialRequisition = response.data.Result;
        setFormData(MaterialRequisition.jobOrderDetails);
        setMaterialRequisitiontable(MaterialRequisition.jobOrderDetailsList);
        setitem_DESC(MaterialRequisition.jobOrderDetailsList.it_DES);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
 // }
  };


  // const handleUpdate = async () => {
  //   if (!isRecordSelected || selectedRows.length > 1) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: isRecordSelected
  //         ? "Select a single record to edit."
  //         : "Select only one record to edit.",
  //       life: 3000,
  //     });
  //     return;
  //   }
  //   setFormData(initialValue);
  //   try {
  //     const response = await axios.get(
  //       `${domain}/api/MaterialRequisition/Select/${selectedRows[0]}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const MaterialRequisitionData = response.data;
  //       setMaterialRequisitiontable(MaterialRequisitionData.MaterialRequisitionList);
  //       setFormData(MaterialRequisitionData);
  //       setroutingItemName(MaterialRequisitionData.parent_DES);
  //       handleClickOpen();
  //     } else {
  //       console.error(`API request failed with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      const jobOrderDetails={
        jbUkNo : selectedRows[0],
      }
        const requestBodyInput = {
          jobOrderDetails
          };

      try {
        const response = await axios.post(
          `${domain}/api/MaterialRequisition/Select`,requestBodyInput,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const MaterialRequisition = response.data.Result;
          const MaterialRequisitionData = MaterialRequisition.jobOrderDetailsList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.jbUkNo;
            return UpdatedworkData;
          });
         // const MaterialRequisitionHeaderData =MaterialRequisition.jobOrderDetails;
         const MaterialRequisitionHeaderData = { ...MaterialRequisition.jobOrderDetails };

          delete  MaterialRequisitionHeaderData.jbUkNo;
         // return MaterialRequisitionHeaderData;
          setFormData(MaterialRequisitionHeaderData);
          setMaterialRequisitiontable(MaterialRequisitionData);
          setitem_DESC(MaterialRequisition.jobOrderDetailsList.it_DES);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  var requestBodyInput = {};
  const values = () => {
    const jobOrderDetails={
      jbUkNo: document.getElementById("jbUkNo").value,
      jb_OR_NO: document.getElementById("jb_OR_NO").value,
      jb_OR_TY: document.getElementById("jb_OR_TY").value,
      jb_OR_CO: document.getElementById("jb_OR_CO").value,
      jb_JB_NO: document.getElementById("jb_JB_NO").value,
      jb_JB_TY: document.getElementById("jb_JB_TY").value,
      jb_JB_CO: document.getElementById("jb_JB_CO").value,
      jb_PR_NM: document.getElementById("jb_PR_NM").value,
      jb_JB_QT: document.getElementById("jb_JB_QT").value,
      jb_JB_UM: jb_JB_UM,
      jb_SU_NO: document.getElementById("jb_SU_NO").value,
      JB_IS_DT: document.getElementById("JB_IS_DT").value,
      jb_OP_NO: document.getElementById("jb_OP_NO").value,
      CancelQty: document.getElementById("CancelQty").value,
    }
      const table = document.getElementById("MaterialRequisitiontable");
      const totalRowCount = table.tBodies[0].rows.length;
      const jobOrderDetailsList = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const Details = {
          jbUkNo: document.getElementById("jbUkNo_" + i).value,
          jb_LN_NO: document.getElementById("jb_LN_NO_" + i).value,
          jb_CO_NM: document.getElementById("jb_CO_NM_" + i).value,
          comp_desc: document.getElementById("comp_desc_" + i).value,
          jb_OR_QT: document.getElementById("jb_OR_QT_" + i).value,
          jb_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          jb_CO_CT: table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
      
        };
        jobOrderDetailsList.push(Details);
      }
      requestBodyInput = {
        jobOrderDetails,
        jobOrderDetailsList,
      };
  };
  const handleFormSubmit = () => {
    values();
    const ukNo = FormData.jbUkNo;
      if (ukNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/MaterialRequisition/Update`;
    
        if (confirm) {
          axios.put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios.post(`${domain}/api/MaterialRequisition/Add`, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
    
  };
  // header buttons starts
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
        <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
        <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={exportButtonStyles}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  // header button ends
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar  className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}  />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_JB_NO"] ? filters["jb_JB_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_JB_NO",
                        e.target.value,
                        filters["jb_JB_NO"]
                          ? filters["jb_JB_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_JB_TY"] ? filters["jb_JB_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_JB_TY",
                        e.target.value,
                        filters["jb_JB_TY"]
                          ? filters["jb_JB_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_JB_CO"] ? filters["jb_JB_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_JB_CO",
                        e.target.value,
                        filters["jb_JB_CO"]
                          ? filters["jb_JB_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_JB_QT"] ? filters["jb_JB_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_JB_QT",
                        e.target.value,
                        filters["jb_JB_QT"]
                          ? filters["jb_JB_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_JB_UM"] ? filters["jb_JB_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_JB_UM",
                        e.target.value,
                        filters["jb_JB_UM"]
                          ? filters["jb_JB_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_CO_CT"] ? filters["jb_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_CO_CT",
                        e.target.value,
                        filters["jb_CO_CT"]
                          ? filters["jb_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_PR_NM"] ? filters["jb_PR_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_PR_NM",
                        e.target.value,
                        filters["jb_PR_NM"]
                          ? filters["jb_PR_NM"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_DESC",
                        e.target.value,
                        filters["item_DESC"]
                          ? filters["item_DESC"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_CO_NM"] ? filters["jb_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_CO_NM",
                        e.target.value,
                        filters["jb_CO_NM"]
                          ? filters["jb_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["comp_desc"] ? filters["comp_desc"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "comp_desc",
                        e.target.value,
                        filters["comp_desc"]
                          ? filters["comp_desc"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_OR_QT"] ? filters["jb_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_OR_QT",
                        e.target.value,
                        filters["jb_OR_QT"]
                          ? filters["jb_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_TR_QT"] ? filters["jb_TR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_TR_QT",
                        e.target.value,
                        filters["jb_TR_QT"]
                          ? filters["jb_TR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_IS_QT"] ? filters["jb_IS_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_IS_QT",
                        e.target.value,
                        filters["jb_IS_QT"]
                          ? filters["jb_IS_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_TR_UM"] ? filters["jb_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_TR_UM",
                        e.target.value,
                        filters["jb_TR_UM"]
                          ? filters["jb_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_LO_CN"] ? filters["jb_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_LO_CN",
                        e.target.value,
                        filters["jb_LO_CN"]
                          ? filters["jb_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_LT_NO"] ? filters["jb_LT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_LT_NO",
                        e.target.value,
                        filters["jb_LT_NO"]
                          ? filters["jb_LT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_TR_DT"] ? filters["jb_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_TR_DT",
                        e.target.value,
                        filters["jb_TR_DT"]
                          ? filters["jb_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_IS_DT"] ? filters["jb_IS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_IS_DT",
                        e.target.value,
                        filters["jb_IS_DT"]
                          ? filters["jb_IS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_OR_NO"] ? filters["jb_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_OR_NO",
                        e.target.value,
                        filters["jb_OR_NO"]
                          ? filters["jb_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_OR_TY"] ? filters["jb_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_OR_TY",
                        e.target.value,
                        filters["jb_OR_TY"]
                          ? filters["jb_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jb_OR_CO"] ? filters["jb_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jb_OR_CO",
                        e.target.value,
                        filters["jb_OR_CO"]
                          ? filters["jb_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Order Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Component Item</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Order Quantity</th>
                <th style={tableHeaderStyle}>Transfer Quantity</th>
                <th style={tableHeaderStyle}>Issued Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot Number</th>
                <th style={tableHeaderStyle}>Transaction Date</th>
                <th style={tableHeaderStyle}>Issued Date</th>
                <th style={tableHeaderStyle}>Rel. Order No</th>
                <th style={tableHeaderStyle}>Rel. Type</th>
                <th style={tableHeaderStyle}>Rel. Company</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.jbUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.jbUkNo}
                        checked={selectedRows.includes(rowData.jbUkNo)}
                        onChange={() => handleRowSelect(rowData.jbUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.jb_JB_NO}</td>
                    <td style={tableCellStyle}>{rowData.jb_JB_TY}</td>
                    <td style={tableCellStyle}>{rowData.jb_JB_CO}</td>
                    <td style={tableCellStyle}>{rowData.jb_JB_QT}</td>
                    <td style={tableCellStyle}>{rowData.jb_JB_UM}</td>
                    <td style={tableCellStyle}>{rowData.jb_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.jb_PR_NM}</td> 
                    <td style={tableCellStyle}>{rowData.item_DESC}</td> 
                    <td style={tableCellStyle}>{rowData.jb_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.comp_desc}</td>
                    <td style={tableCellStyle}>{rowData.jb_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.jb_TR_QT}</td>
                    <td style={tableCellStyle}>{rowData.jb_IS_QT}</td>
                    <td style={tableCellStyle}>{rowData.jb_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.jb_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.jb_LT_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.jb_TR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.jb_IS_DT)}</td>
                    <td style={tableCellStyle}>{rowData.jb_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.jb_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.jb_OR_CO}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <MaterialRequisitionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        FormData={FormData}
        setFormData={setFormData}
        onchangedata={onchangedata}
        handleFormSubmit={handleFormSubmit}
        onchangeFormData={onchangeFormData}
        MaterialRequisitiontable={MaterialRequisitiontable}
        setMaterialRequisitiontable={setMaterialRequisitiontable}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        routingItemName={routingItemName}
        setroutingItemName={setroutingItemName}
        rtWK_dESC={rtWK_dESC}
        setrtWK_dESC={setrtWK_dESC}
        rt_CO_CT={rt_CO_CT}
        setrt_CO_CT={setrt_CO_CT}
        jb_JB_UM={jb_JB_UM}
        setjb_JB_UM={setjb_JB_UM}
        rt_RT_TY={rt_RT_TY}
        setrt_RT_TY={setrt_RT_TY}
        jb_TR_UM={jb_TR_UM}
        setjb_TR_UM={setjb_TR_UM}
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        SelectedemOperator={SelectedemOperator}
        setSelectedemOperator={setSelectedemOperator}
        SelectedOrder={SelectedOrder}
        setSelectedOrder={setSelectedOrder}
        jb_CO_CT={jb_CO_CT}
        setjb_CO_CT={setjb_CO_CT}
        item_DESC={item_DESC}
        setitem_DESC={setitem_DESC}
        jb_OR_CO={jb_OR_CO}
        jb_JB_CO={jb_JB_CO}
        jb_OR_TY={jb_OR_TY}
        jb_JB_TY={jb_JB_TY}
        handleResponse={handleResponse}
      />
    </div>
  );
};

export default MaterialRequisitionMain;
