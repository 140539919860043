import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ShippingDetailsDialog from "./ShippingDetailsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sdUkNo:"",
  sd_SH_DE:"",
  sd_SH_SO:"",
  sd_OR_GN:"",
  sd_SH_TO:"",
  sd_SO_TO:"",
  sd_TR_CR:"",
  sd_EX_RT:"",
  sd_BS_CR:"",
  sd_SH_NO:"",
  sd_ST_AT:"",
  sd_CI_TY:"",
  sd_PO_CD:"",
  sd_ST_CD:"",
  sd_RT_CD:"",
  sd_ZN_CD:"",
  sd_PS_DT:"",
  sd_PS_TM:"",
  sd_AS_DT:"",
  sd_AS_TM:"",
  sd_PD_DT: "",//moment(moment().toDate()).format("YYYYMMDD"),
  sd_PD_TM:"", //moment(moment().toDate()).format("YYYYMMDD"),
  sd_AD_DT:"",
  sd_AD_TM:"",
  sd_MD_TR:"",
  sd_NO_PE:"",
  sd_CR_NO:"",
  sd_NO_CO:"",
  sd_FR_CD:"",
  sd_IT_NO:"",
  sd_SH_QT:"",
  sd_TR_UM:"",
  sd_WE_GT:"",
  sd_WT_UM:"",
  sd_EX_PR:"",
  sd_EX_CT:"",
  sd_FR_PR:"",
  sd_OR_NO:"",
  sd_OR_TY:"",
  sd_OR_CO:"",
  sd_LN_NO:"",
  sd_DS_GP:"",
  sd_DA_TE:moment(moment().toDate()).format("YYYYMMDD"),
 
 

 



 
};

const initialFilters = {
  sd_SH_DE:{ value: "", operation: "" },
  sd_SH_SO:{ value: "", operation: "" },
  sd_OR_GN:{ value: "", operation: "" },
  sd_SH_TO:{ value: "", operation: "" },
  sd_SO_TO:{ value: "", operation: "" },
  sd_TR_CR:{ value: "", operation: "" },
  sd_BS_CR:{ value: "", operation: "" },
  sd_EX_RT:{ value: "", operation: "" },
  sd_SH_NO:{ value: "", operation: "" },
  sd_ST_AT:{ value: "", operation: "" },
  sd_CI_TY:{ value: "", operation: "" },
  sd_PO_CD:{ value: "", operation: "" },
  sd_ST_CD:{ value: "", operation: "" },
  sd_RT_CD:{ value: "", operation: "" },
  sd_ZN_CD:{ value: "", operation: "" },
  sd_PS_DT:{ value: "", operation: "" },
  sd_PS_TM:{ value: "", operation: "" },
  sd_AS_DT:{ value: "", operation: "" },
  sd_AS_TM:{ value: "", operation: "" },
  sd_PD_DT:{ value: "", operation: "" },
  sd_PD_TM:{ value: "", operation: "" },
  sd_AD_DT:{ value: "", operation: "" },
  sd_AD_TM:{ value: "", operation: "" },
  sd_MD_TR:{ value: "", operation: "" },
  sd_NO_PE:{ value: "", operation: "" },
  sd_CR_NO:{ value: "", operation: "" },
  sd_NO_CO:{ value: "", operation: "" },
  sd_FR_CD:{ value: "", operation: "" },
  sd_IT_NO:{ value: "", operation: "" },
  sd_SH_QT:{ value: "", operation: "" },
  sd_TR_UM:{ value: "", operation: "" },
  sd_WE_GT:{ value: "", operation: "" },
  sd_WT_UM:{ value: "", operation: "" },
  sd_EX_PR:{ value: "", operation: "" },
  sd_EX_CT:{ value: "", operation: "" },
  sd_FR_PR:{ value: "", operation: "" },
  sd_OR_NO:{ value: "", operation: "" },
  sd_OR_TY:{ value: "", operation: "" },
  sd_OR_CO:{ value: "", operation: "" },
  sd_LN_NO:{ value: "", operation: "" },
  sd_DS_GP:{ value: "", operation: "" },


};

const ShippingDetailsMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,rightalined,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,formatSavedTime,
    ListDesc,ListOwnerDesc,ListOrder,ListForeignStyle,ListTitleDesc
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [sd_SH_DE, setsd_SH_DE ] = useState("");
  const [sd_ST_AT, setsd_ST_AT ] = useState("");
  const [sd_SH_SO, setsd_SH_SO ] = useState("");
  const [sd_CI_TY, setsd_CI_TY ] = useState("");
  const [sd_OR_GN, setsd_OR_GN ] = useState("");
  const [sd_PO_CD, setsd_PO_CD ] = useState("");
  const [sd_ST_CD, setsd_ST_CD ] = useState("");
  const [sd_RT_CD, setsd_RT_CD ] = useState("");
  const [sd_TR_CR, setsd_TR_CR ] = useState("");
  const [sd_ZN_CD, setsd_ZN_CD ] = useState("");
  const [sd_BS_CR, setsd_BS_CR ] = useState("");
  const [sd_EX_RT, setsd_EX_RT ] = useState("");
  const [sd_TR_UM, setsd_TR_UM ] = useState("");
  const [sd_WT_UM, setsd_WT_UM ] = useState("");
  const [sd_VL_UM, setsd_VL_UM ] = useState("");
  const [sd_DS_GP, setsd_DS_GP ] = useState(""); 
  const [F_EXT_PRICE, setF_EXT_PRICE ] = useState("");
  const [F_EXT_COST, setF_EXT_COST ] = useState("");
  const [F_FORE_PRICE, setF_FORE_PRICE ] = useState(""); 
  const [sd_MD_TR, setsd_MD_TR ] = useState("");  
  const [sd_SH_NO, setsd_SH_NO ] = useState("");
  const [sd_OR_CO , setsd_OR_CO ] = useState("");
  const [sd_OR_TY, setsd_OR_TY] = useState("");
  const [sd_FR_CD, setsd_FR_CD] = useState("");

  
 // Advance Search
  const [SelectedShip, setSelectedShip ] = useState("");
  const [SelectedSold, setSelectedSold ] = useState("");
  const [SelectedItem, setSelectedItem ] = useState("");
  const [SelectedCarrier, setSelectedCarrier ] = useState("");
  const [ShippingDetailsTable, setShippingDetailsTable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleAddButtonClick = () => {
    setShippingDetailsTable([{}]);
    setFormData(initialValue);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setShippingDetailsTable([{}]);
    setFormData(initialValue);
    setsd_SH_DE(null); 
    setsd_ST_AT(null); 
    setsd_SH_SO(null); 
    setsd_CI_TY(null); 
    setsd_OR_GN(null); 
    setsd_PO_CD(null); 
    setsd_ST_CD(null); 
    setsd_RT_CD(null); 
    setsd_TR_CR(null); 
    setsd_ZN_CD(null); 
    setsd_BS_CR(null); 
    setsd_EX_RT(null); 
    setsd_TR_UM(null); 
    setsd_WT_UM(null); 
    setsd_VL_UM(null); 
    setsd_DS_GP(null); 
    setsd_RT_CD(null);
    setSelectedSold(null); 
    setSelectedShip(null); 
    setSelectedItem(null); 
    setF_FORE_PRICE(null);
    setF_EXT_COST(null);
    setF_EXT_PRICE(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    setsd_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
           
            if(fName === "SD_BS_CR"){
              setsd_BS_CR(dataDefault.versionIds[i].default_VALUE)
            }
            // if(fName === "SD_OR_TY"){
            //   setsd_OR_TY(dataDefault.versionIds[i].default_VALUE)
            // }
        
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ShippingDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.sdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      ShippingDetailsTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ShippingDetails/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ShippingDetailsData = response.data;
        setShippingDetailsTable(ShippingDetailsData.shippingDetailsList);
        setFormData(ShippingDetailsData.shippingDetailsList[0]);
        setSelectedItem(ShippingDetailsData.shippingDetailsList.item_DES);
        setsd_SH_DE(ShippingDetailsData.sd_SH_DE); 
        setsd_ST_AT(ShippingDetailsData.sd_ST_AT); 
        setsd_SH_SO(ShippingDetailsData.sd_SH_SO); 
        setsd_CI_TY(ShippingDetailsData.sd_CI_TY); 
        setsd_OR_GN(ShippingDetailsData.sd_OR_GN); 
        setsd_PO_CD(ShippingDetailsData.sd_PO_CD); 
        setsd_ST_CD(ShippingDetailsData.sd_ST_CD); 
        setsd_RT_CD(ShippingDetailsData.sd_RT_CD); 
        setsd_TR_CR(ShippingDetailsData.sd_TR_CR); 
        setsd_ZN_CD(ShippingDetailsData.sd_ZN_CD); 
        setsd_BS_CR(ShippingDetailsData.sd_BS_CR); 
        setsd_EX_RT(ShippingDetailsData.sd_EX_RT); 
        setsd_TR_UM(ShippingDetailsData.sd_TR_UM); 
        setsd_WT_UM(ShippingDetailsData.sd_WT_UM); 
        setsd_VL_UM(ShippingDetailsData.sd_VL_UM); 
        setsd_DS_GP(ShippingDetailsData.sd_DS_GP); 
        setSelectedSold(ShippingDetailsData.sold_DES); 
        setSelectedShip(ShippingDetailsData.ship_DES); 
        setSelectedItem(ShippingDetailsData.item_DES); 
        setF_EXT_PRICE(ShippingDetailsData.f_EXT_PRICE)
        setF_EXT_COST(ShippingDetailsData.f_EXT_COST)
        setF_FORE_PRICE(ShippingDetailsData.f_FORE_PRICE)
       // setsd_SH_QT(CashFlowData.purchaseOrderInterfaceList[0].sd_SH_QT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ShippingDetails/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ShippingDetailsData = response.data;
          delete ShippingDetailsData.ShippingDetailss[0].rc_TR_NO;
          delete ShippingDetailsData.ShippingDetailss[0].sdUkNo;
          for (let i = 0; i < ShippingDetailsData.ShippingDetailss.length; i++) {
            delete ShippingDetailsData.ShippingDetailss[i].sdUkNo;
           // ShippingDetailsData.ShippingDetailss[i].rc_ST_CD = rc_ST_CD;
          }
          setShippingDetailsTable(ShippingDetailsData.ShippingDetailss);
          setFormData(ShippingDetailsData.ShippingDetailss[0]);
          setsd_SH_DE(ShippingDetailsData.sd_SH_DE); 
          setsd_ST_AT(ShippingDetailsData.sd_ST_AT); 
          setsd_SH_SO(ShippingDetailsData.sd_SH_SO); 
          setsd_CI_TY(ShippingDetailsData.sd_CI_TY); 
          setsd_OR_GN(ShippingDetailsData.sd_OR_GN); 
          setsd_PO_CD(ShippingDetailsData.sd_PO_CD); 
          setsd_ST_CD(ShippingDetailsData.sd_ST_CD); 
          setsd_RT_CD(ShippingDetailsData.sd_RT_CD); 
          setsd_TR_CR(ShippingDetailsData.sd_TR_CR); 
          setsd_ZN_CD(ShippingDetailsData.sd_ZN_CD); 
          setsd_BS_CR(ShippingDetailsData.sd_BS_CR); 
          setsd_EX_RT(ShippingDetailsData.sd_EX_RT); 
          setsd_TR_UM(ShippingDetailsData.sd_TR_UM); 
          setsd_WT_UM(ShippingDetailsData.sd_WT_UM); 
          setsd_VL_UM(ShippingDetailsData.sd_VL_UM); 
          setsd_DS_GP(ShippingDetailsData.sd_DS_GP); 
          setSelectedSold(ShippingDetailsData.sold_DES); 
          setSelectedShip(ShippingDetailsData.ship_DES); 
          setSelectedItem(ShippingDetailsData.item_DES); 
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  var requestBody = {};
  function values () {
    const table = document.getElementById("ShippingDetailsTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const shippingDetailsList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        sdUkNo : document.getElementById("sdUkNo_" + i).value,
          sd_IT_NO : document.getElementById("sd_IT_NO_" + i).value,
          sd_LN_NO : document.getElementById("sd_LN_NO_" + i).value,
          item_DES : document.getElementById("item_DES_" + i).value,
          sd_SH_QT : document.getElementById("sd_SH_QT_" + i).value,
          sd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          sd_WE_GT : document.getElementById("sd_WE_GT_" + i).value,
          sd_WT_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
          sd_VL_ME : document.getElementById("sd_VL_ME_" + i).value,
          sd_VL_UM : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
          sd_EX_PR : document.getElementById("sd_EX_PR_" + i).value,
          sd_EX_CT : document.getElementById("sd_EX_CT_" + i).value,
          sd_FR_PR : document.getElementById("sd_FR_PR_" + i).value,
          sd_DS_GP : table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
      };
      shippingDetailsList.push(details);
    }
    requestBody = {
      shippingDetailsList,
      sdUkNo   : FormData.sdUkNo,
      sd_SH_NO : document.getElementById("sd_SH_NO").value,
      sd_OR_TY : sd_OR_TY,
      sd_OR_CO : document.getElementById("sd_OR_CO").value,
      sd_DA_TE : FormData.sd_DA_TE,
      sd_SH_DE : sd_SH_DE,
      sd_ST_AT : sd_ST_AT,
      sd_SH_SO : sd_SH_SO,
      sd_CI_TY : sd_CI_TY,
      sd_OR_GN : sd_OR_GN,
      sd_PO_CD : sd_PO_CD,
      sd_SH_TO : document.getElementById("sd_SH_TO").value,
      sd_ST_CD :sd_ST_CD,
      sd_SO_TO : document.getElementById("sd_SO_TO").value,
      sd_RT_CD : sd_RT_CD,
      sd_TR_CR : sd_TR_CR,
      sd_EX_RT : document.getElementById("sd_EX_RT").value,
      sd_ZN_CD : sd_ZN_CD,
      sd_BS_CR : document.getElementById("sd_BS_CR").value,
      sd_PS_DT : FormData.sd_PS_DT,
      sd_PS_TM : FormData.sd_PS_TM,
      sd_PD_DT : FormData.sd_PD_DT,
      sd_PD_TM : FormData.sd_PD_TM,
      sd_AS_DT : FormData.sd_AS_DT,
      sd_AS_TM : FormData.sd_AS_TM,
      sd_AD_DT : FormData.sd_AD_DT,
      sd_AD_TM : FormData.sd_AD_TM,
      sd_MD_TR : sd_MD_TR,
      sd_NO_PE : FormData.sd_NO_PE,//document.getElementById("sd_NO_PE").value,
      sd_CR_NO : FormData.sd_CR_NO,//document.getElementById("sd_CR_NO").value,
      sd_NO_CO : FormData.sd_NO_CO,//document.getElementById("sd_NO_CO").value,
      sd_FR_CD : sd_FR_CD,
      
    };
  };
  const handleFormSubmit = () => {
  values();
  const orNo = FormData.sd_SH_NO;
  const ukNo = FormData.sdUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ShippingDetails/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/ShippingDetails/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
    const FR_DT = FormData.sd_DA_TE;
    if(FR_DT){
      const currentYear = FR_DT.toString();
      const firstHalf = currentYear.substring(0, 2);
      const secondHalf = currentYear.substring(2, 4);
      const urlNextNumber = `${domain}/api/DocumentNumber/All`;
      const parameters = `${sd_OR_TY}/${secondHalf}/${company}/${firstHalf}`;  
      const finalUrl1 = `${urlNextNumber}/${parameters}`;
      axios.get(finalUrl1, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        setsd_SH_NO(resp.dn_DO_NO);
          document.getElementById("sd_SH_NO").value = resp.data.dn_DO_NO;
          if(resp.data.dn_DO_NO ===undefined)
          {
            handleResponse(resp.data);
          }else{
            values();
            axios.post(`${domain}/api/ShippingDetails/Add`, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
          }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_DE"] ? filters["sd_SH_DE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_DE",
                        e.target.value,
                        filters["sd_SH_DE"]
                          ? filters["sd_SH_DE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_SO"] ? filters["sd_SH_SO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_SO",
                        e.target.value,
                        filters["sd_SH_SO"] ? filters["sd_SH_SO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_GN"] ? filters["sd_OR_GN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_GN",
                        e.target.value,
                        filters["sd_OR_GN"]
                          ? filters["sd_OR_GN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_TO"] ? filters["sd_SH_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_TO",
                        e.target.value,
                        filters["sd_SH_TO"] ? filters["sd_SH_TO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_SO_TO"] ? filters["sd_SO_TO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SO_TO",
                        e.target.value,
                        filters["sd_SO_TO"]
                          ? filters["sd_SO_TO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_CR"] ? filters["sd_TR_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_CR",
                        e.target.value,
                        filters["sd_TR_CR"]
                          ? filters["sd_TR_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_EX_RT"] ? filters["sd_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_EX_RT",
                        e.target.value,
                        filters["sd_EX_RT"]
                          ? filters["sd_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_BS_CR"] ? filters["sd_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_BS_CR",
                        e.target.value,
                        filters["sd_BS_CR"]
                          ? filters["sd_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["sd_SH_NO"] ? filters["sd_SH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_NO",
                        e.target.value,
                        filters["sd_SH_NO"]
                          ? filters["sd_SH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_AT"] ? filters["sd_ST_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_AT",
                        e.target.value,
                        filters["sd_ST_AT"]
                          ? filters["sd_ST_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_CI_TY"] ? filters["sd_CI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CI_TY",
                        e.target.value,
                        filters["sd_CI_TY"]
                          ? filters["sd_CI_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_PO_CD"] ? filters["sd_PO_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PO_CD",
                        e.target.value,
                        filters["sd_PO_CD"]
                          ? filters["sd_PO_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_CD",
                        e.target.value,
                        filters["sd_ST_CD"]
                          ? filters["sd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_RT_CD"] ? filters["sd_RT_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_RT_CD",
                        e.target.value,
                        filters["sd_RT_CD"]
                          ? filters["sd_RT_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_ZN_CD"] ? filters["sd_ZN_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ZN_CD",
                        e.target.value,
                        filters["sd_ZN_CD"]
                          ? filters["sd_ZN_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_PS_DT"] ? filters["sd_PS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PS_DT",
                        e.target.value,
                        filters["sd_PS_DT"]
                          ? filters["sd_PS_DT"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_PS_TM"] ? filters["sd_PS_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PS_TM",
                        e.target.value,
                        filters["sd_PS_TM"]
                          ? filters["sd_PS_TM"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_AS_DT"] ? filters["sd_AS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_AS_DT",
                        e.target.value,
                        filters["sd_AS_DT"]
                          ? filters["sd_AS_DT"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["sd_AS_TM"] ? filters["sd_AS_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_AS_TM",
                        e.target.value,
                        filters["sd_AS_TM"]
                          ? filters["sd_AS_TM"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_PD_DT"] ? filters["sd_PD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PD_DT",
                        e.target.value,
                        filters["sd_PD_DT"]
                          ? filters["sd_PD_DT"].operation
                          : "sd_PD_DT"
                      )
                    }
                    style={ListTitleDesc}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["sd_PD_TM"] ? filters["sd_PD_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_PD_TM",
                        e.target.value,
                        filters["sd_PD_TM"]
                          ? filters["sd_PD_TM"].operation
                          : "equals"
                      )
                    }
                    style={ListTitleDesc}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_AD_DT"] ? filters["sd_AD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_AD_DT",
                        e.target.value,
                        filters["sd_AD_DT"]
                          ? filters["sd_AD_DT"].operation
                          : "equals"
                      )
                    }
                    style={ListTitleDesc}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["sd_AD_TM"] ? filters["sd_AD_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_AD_TM",
                        e.target.value,
                        filters["sd_AD_TM"]
                          ? filters["sd_AD_TM"].operation
                          : "equals"
                      )
                    }
                    style={ListTitleDesc}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_MD_TR"] ? filters["sd_MD_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_MD_TR",
                        e.target.value,
                        filters["sd_MD_TR"]
                          ? filters["sd_MD_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_NO_PE"] ? filters["sd_NO_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_NO_PE",
                        e.target.value,
                        filters["sd_NO_PE"]
                          ? filters["sd_NO_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_CR_NO"] ? filters["sd_CR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_CR_NO",
                        e.target.value,
                        filters["sd_CR_NO"]
                          ? filters["sd_CR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_NO_CO"] ? filters["sd_NO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_NO_CO",
                        e.target.value,
                        filters["sd_NO_CO"]
                          ? filters["sd_NO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_FR_CD"] ? filters["sd_FR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_FR_CD",
                        e.target.value,
                        filters["sd_FR_CD"]
                          ? filters["sd_FR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_IT_NO"] ? filters["sd_IT_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IT_NO",
                        e.target.value,
                        filters["sd_IT_NO"]
                          ? filters["sd_IT_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_SH_QT"] ? filters["sd_SH_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_SH_QT",
                        e.target.value,
                        filters["sd_SH_QT"]
                          ? filters["sd_SH_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_TR_UM"] ? filters["sd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_TR_UM",
                        e.target.value,
                        filters["sd_TR_UM"]
                          ? filters["sd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_WE_GT"] ? filters["sd_WE_GT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_WE_GT",
                        e.target.value,
                        filters["sd_WE_GT"]
                          ? filters["sd_WE_GT"].operation  
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_WT_UM"] ? filters["sd_WT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_WT_UM",
                        e.target.value,
                        filters["sd_WT_UM"]
                          ? filters["sd_WT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["sd_EX_PR"] ? filters["sd_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_EX_PR",
                        e.target.value,
                        filters["sd_EX_PR"]
                          ? filters["sd_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_EX_CT"] ? filters["sd_EX_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_EX_CT",
                        e.target.value,
                        filters["sd_EX_CT"]
                          ? filters["sd_EX_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_FR_PR"] ? filters["sd_FR_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_FR_PR",
                        e.target.value,
                        filters["sd_FR_PR"]
                          ? filters["sd_FR_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
               
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_NO"] ? filters["sd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_NO",
                        e.target.value,
                        filters["sd_OR_NO"]
                          ? filters["sd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_TY"] ? filters["sd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_TY",
                        e.target.value,
                        filters["sd_OR_TY"]
                          ? filters["sd_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_CO",
                        e.target.value,
                        filters["sd_OR_CO"]
                          ? filters["sd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_LN_NO"] ? filters["sd_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LN_NO",
                        e.target.value,
                        filters["sd_LN_NO"]
                          ? filters["sd_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
                <td>
                  <input
                    type="text"
                    value={filters["sd_DS_GP"] ? filters["sd_DS_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_DS_GP",
                        e.target.value,
                        filters["sd_DS_GP"]
                          ? filters["sd_DS_GP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                    
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Shipment Depot</th>
                <th style={tableHeaderStyle}>Shipment Source</th>
                <th style={tableHeaderStyle}>Origin</th>   
                <th style={tableHeaderStyle}>Ship To</th> 
                <th style={tableHeaderStyle}>Sold To</th> 
                <th style={tableHeaderStyle}>Currency</th> 
                <th style={tableHeaderStyle}>Exchange Rate</th> 
                <th style={tableHeaderStyle}>Base Currency</th>  
                <th style={tableHeaderStyle}>Shipment No</th> 
                <th style={tableHeaderStyle}>Status</th> 
                <th style={tableHeaderStyle}>City</th>  
                <th style={tableHeaderStyle}>Postal Code</th> 
                <th style={tableHeaderStyle}>State</th> 
                <th style={tableHeaderStyle}>Route</th> 
                <th style={tableHeaderStyle}>Zone</th> 
                <th style={tableHeaderStyle}>Promised Ship Date</th> 
                <th style={tableHeaderStyle}>Promised Ship Time</th> 
                <th style={tableHeaderStyle}>Actual Ship Date	</th>  
                <th style={tableHeaderStyle}>Actual Ship Time</th>
                <th style={tableHeaderStyle}>Promised Delivery Date</th>
                <th style={tableHeaderStyle}>Promised Delivery Time</th>
                <th style={tableHeaderStyle}>Actual Delivery Date</th> 
                <th style={tableHeaderStyle}>Actual Delivery Time</th> 
                <th style={tableHeaderStyle}>Mode of Transport</th> 
                <th style={tableHeaderStyle}>No of Pieces</th> 
                <th style={tableHeaderStyle}>Carrier No</th> 
                <th style={tableHeaderStyle}>No of Containers</th> 
                <th style={tableHeaderStyle}>Freight</th> 
                <th style={tableHeaderStyle}>Item Number</th> 
                <th style={tableHeaderStyle}>Quantity</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Weight</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Extended Price</th> 
                <th style={tableHeaderStyle}>Extended Cost</th> 
                <th style={tableHeaderStyle}>Foreign Price</th> 
                <th style={tableHeaderStyle}>Order No	</th> 
                <th style={tableHeaderStyle}>Type</th> 
                <th style={tableHeaderStyle}>Company</th> 
                <th style={tableHeaderStyle}>Line No</th> 
                <th style={tableHeaderStyle}>Dispatch Group</th>                           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sdUkNo}
                        checked={selectedRows.includes(rowData.sdUkNo)}
                        onChange={() => handleRowSelect(rowData.sdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sd_SH_DE}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_SO}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_GN}</td>

                    <td style={tableCellStyle}>{rowData.sd_SH_TO}</td>
                    <td style={tableCellStyle}>{rowData.sd_SO_TO}</td>
                    <td style={tableCellStyle}>{rowData.sd_TR_CR}</td>
                    <td style={tableCellStyle}>{rowData.sd_EX_RT}</td>
                    <td style={tableCellStyle}>{rowData.sd_BS_CR}</td>   
                    <td style={tableCellStyle}>{rowData.sd_SH_NO}</td>  
                    <td style={tableCellStyle}>{rowData.sd_ST_AT}</td>   
                    <td style={tableCellStyle}>{rowData.sd_CI_TY}</td>   
                    <td style={tableCellStyle}>{rowData.sd_PO_CD}</td>   
                    <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.sd_RT_CD}</td>    
                    <td style={tableCellStyle}>{rowData.sd_ZN_CD}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sd_PS_DT)}</td> 
                    <td style={tableCellStyle}>{formatSavedTime(rowData.sd_PS_TM)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_AS_DT)}</td> 
                    <td style={tableCellStyle}>{formatSavedTime(rowData.sd_AS_TM)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_PD_DT)}</td> 
                    <td style={tableCellStyle}>{formatSavedTime(rowData.sd_PD_TM)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_AD_DT)}</td> 
                    <td style={tableCellStyle}>{formatSavedTime(rowData.sd_AD_TM)}</td>
                    <td style={tableCellStyle}>{rowData.sd_MD_TR}</td>   
                    <td style={tableCellStyle}>{rowData.sd_NO_PE}</td> 
                    <td style={tableCellStyle}>{rowData.sd_CR_NO}</td>    
                    <td style={tableCellStyle}>{rowData.sd_NO_CO}</td>   
                    <td style={tableCellStyle}>{rowData.sd_FR_CD}</td>   
                    <td style={tableCellStyle}>{rowData.sd_IT_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_SH_QT}</td>
                    <td style={tableCellStyle}>{rowData.sd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.sd_WE_GT}</td>
                    <td style={tableCellStyle}>{rowData.sd_WT_UM}</td>
                    <td style={tableCellStyle}>{rowData.sd_EX_PR}</td> 
                    <td style={tableCellStyle}>{rowData.sd_EX_CT}</td>
                    <td style={tableCellStyle}>{rowData.sd_FR_PR}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sd_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_DS_GP}</td>



                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ShippingDetailsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ShippingDetailsTable={ShippingDetailsTable}
        setShippingDetailsTable={setShippingDetailsTable}
        sd_SH_DE={sd_SH_DE}
        setsd_SH_DE={setsd_SH_DE}
        sd_ST_AT={sd_ST_AT}
        setsd_ST_AT={setsd_ST_AT}
        sd_SH_SO={sd_SH_SO}
        setsd_SH_SO={setsd_SH_SO}
        sd_CI_TY={sd_CI_TY}
        setsd_CI_TY={setsd_CI_TY}
        sd_OR_GN={sd_OR_GN}
        setsd_OR_GN={setsd_OR_GN}
        sd_PO_CD={sd_PO_CD}
        setsd_PO_CD={setsd_PO_CD}
        sd_ST_CD={sd_ST_CD}
        setsd_ST_CD={setsd_ST_CD}
        sd_RT_CD={sd_RT_CD}
        setsd_RT_CD={setsd_RT_CD}
        sd_TR_CR={sd_TR_CR}
        setsd_TR_CR={setsd_TR_CR}
        sd_ZN_CD={sd_ZN_CD}
        setsd_ZN_CD={setsd_ZN_CD}
        sd_BS_CR={sd_BS_CR}
        setsd_BS_CR={setsd_BS_CR}
        sd_EX_RT={sd_EX_RT}
        setsd_EX_RT={setsd_EX_RT}
        sd_TR_UM={sd_TR_UM}
        setsd_TR_UM={setsd_TR_UM}
        SelectedShip={SelectedShip}
        setSelectedShip={setSelectedShip}
        SelectedSold={SelectedSold}
        setSelectedSold={setSelectedSold}
        SelectedItem={SelectedItem}
        setSelectedItem={setSelectedItem}
        sd_WT_UM={sd_WT_UM}
        setsd_WT_UM={setsd_WT_UM}
        sd_VL_UM={sd_VL_UM}
        setsd_VL_UM={setsd_VL_UM}
        sd_DS_GP={sd_DS_GP}
        setsd_DS_GP={setsd_DS_GP}
        sd_SH_NO={sd_SH_NO}
        setsd_SH_NO={setsd_SH_NO}
        sd_OR_TY={sd_OR_TY}
        setsd_OR_TY={setsd_OR_TY}
        sd_OR_CO={sd_OR_CO}
        setsd_OR_CO={setsd_OR_CO}
        F_FORE_PRICE={F_FORE_PRICE}
        F_EXT_COST={F_EXT_COST}
        setF_EXT_COST={setF_EXT_COST}
        F_EXT_PRICE={F_EXT_PRICE}
        setF_EXT_PRICE={setF_EXT_PRICE}
        setF_FORE_PRICE={setF_FORE_PRICE}
        sd_MD_TR={sd_MD_TR}
        setsd_MD_TR={setsd_MD_TR}
        SelectedCarrier={SelectedCarrier}
        setSelectedCarrier={setSelectedCarrier}
        sd_FR_CD={sd_FR_CD}
        setsd_FR_CD={setsd_FR_CD}
      />
    </div>
  );
};

export default ShippingDetailsMain;
