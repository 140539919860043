import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faFolder, faFile } from '@fortawesome/free-solid-svg-icons';
import DotsPopup from "./DotsPopup";
import Pagination from "views/common/Pagination";

const StarMarkMain = () => {

  const { formatDate } = ButtonStyles();
  const [items, setItems] = useState([]);
  const toast = useRef(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [isDotsPopupOpen, setDotsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [tableData, setTableData] = useState(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  // Sample function to simulate an API call
  const fetchItems = async () => {
    const requestBody = {
      searchRequestDto: []
    };
    const url = `${domain}/api/StarMark/StarMarkList/${currentPage}/${pageSize}`;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });

      const data = response.data;
      const listItemsData = response.data.content || [];
      setItems(listItemsData);
      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [currentPage]);
  // download api
  const handleFileClick = async (fileName) => {
    const Request = {
      "fdUkNo": fileName
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/DownloadFile`, Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      saveAs(new Blob([response.data]), fileName);
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File downloaded successfully.",
        });
      } else {
        throw new Error("Failed to download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };
  // RemoveStarMark
  const handleOptionClick = async (option) => {
    // if (option === "RemoveStarMark") {
    const Request = {
      "fsUkNo": option.fsUkNo,
    }
    try {
      const response = await axios.post(
        `${domain}/api/StarMark/RemoveStarMark`, Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "StarMark Remove successfully.",
        });
      } else {
        throw new Error("Failed to RemoveStarMark.");
      }
    } catch (error) {
      console.error("Error RemoveStarMark:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
    console.log("RemoveStarMark clicked for", currentItem);
    //}
    setPopupVisible(false); // Close popup after action
  };



  const [popupVisible, setPopupVisible] = useState(false);

  const handleDotsClick = (item) => {
    setCurrentItem(item);
    setPopupVisible(true);
    setDotsPopupOpen(true);
    // handleOptionClick(item);
  };

  const closePopup = () => {
    setDotsPopupOpen(false);
  };

  return (
    <div style={{ border: '1px solid green', padding: '20px', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
      <Toast ref={toast} />
      <div className="row">
        <div className="col-12">
          <table className="table table-borderless transparent-table" style={{ width: '100%', marginBottom: '0' }}>
            <thead>
              <tr style={{ backgroundColor: '#e0f4e0', color: '#333', fontWeight: 'bold' }}>
                <th style={{ padding: '12px', textAlign: 'left' }}> File Name</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Owner</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Last Modified</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Location</th>
                <th style={{ padding: '12px', textAlign: 'left' }}>Options</th>

              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? '#f8f8f8' : '#ffffff' }}>
                  <td onClick={() => handleFileClick(item.fsUkNo)} style={{ padding: '12px', cursor: 'pointer' }}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={item.type === 'FO' ? faFolder : item.type === 'FI' ? faFile : null}
                        style={{
                          width: '24px', height: '24px', marginRight: '8px',
                          color: item.type === 'FO' ? '#F8D775' : item.type === 'FI' ? 'green' : 'inherit'
                        }}
                      />
                      {item.fileName}
                    </div>
                  </td>

                  <td style={{ padding: '12px' }}>{item.owner}</td>

                  <td style={{ padding: '12px' }}>{formatDate(item.last_UPDATED_DATE)}</td>
                  <td onClick={() => handleFileClick(item.fsUkNo)} style={{ padding: '12px', cursor: 'pointer' }}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        icon={faFolder}
                        style={{
                          width: '24px',
                          height: '24px',
                          marginRight: '8px',
                          color: '#F8D775'
                        }}
                      />
                      {item.location}
                    </div>
                  </td>
                  <td className="text-end">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDotsClick(item)}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
        {isDotsPopupOpen && (
          <DotsPopup
            currentItem={currentItem}
            handleClosePopup={closePopup}
            handleOptionClick={handleOptionClick}
          />
        )}
      </div>
    </div>


  );
};
export default StarMarkMain;
