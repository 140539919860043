import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCodePopup from "views/admin/Popups/ClassCodePopup";
export default function OrderGroupsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  OrderGroupsTableData,
  setOrderGroupsTableData,
  ogOrGp,
  setogOrGp,
  og_CO_NO,
  SelectedType,
  setselectedType,
  SelectedDesc,
  setselectedDesc,
}) {
  const { ogUkNo, ogOrTy, } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ogOrGpOptions, setogOrGpOptions] = useState([]);
  // BIDDING type
  const OrderGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setogOrGp(value);
  };
  useEffect(() => {
    fetchDropdownBT();
  }, []);
  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("OR/GP");
    setogOrGpOptions(Options);
  };
  const addRow = () => {
    setOrderGroupsTableData([...OrderGroupsTableData, {}]);
  };
  const deleteRow = () => {
    if (OrderGroupsTableData.length > 0) {
      const updatedData = [...OrderGroupsTableData];
      updatedData.pop();
      setOrderGroupsTableData(updatedData);
    }
  };
  const [TypeVisible, setTypeVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleType = async (data) => {
    setselectedType(data.cl_CL_CD)
    setselectedDesc(data.cl_CL_DS)
    onchangeFormData("ogOrTy", data.cl_CL_CD, SelectedRowIndex);
    onchangeFormData("og_OR_DS", data.cl_CL_DS, SelectedRowIndex);
    setTypeVisible(false);
    setShowSearchIcon(false);
  };
  const openTypePopup = (e) => {
    setTypeVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(ogUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ogUkNo ? "Revise Order Groups" : "Add Order Groups"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ogOrGp" className="font-bold">
                Order Group
              </label>
              <Dropdown
                name="ogOrGp"
                value={ogOrGp}
                options={ogOrGpOptions}
                onChange={(e) => OrderGroupDropdown("ogOrGp", e.value)}
                placeholder="Select Order Group"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
              <label htmlFor="og_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="og_CO_NO"
                name="og_CO_NO"
                value={og_CO_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="OrderGroupstable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Order Type</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {OrderGroupsTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`ogUkNo_${index}`}
                        name={`ogUkNo_${index}`}
                        value={row.ogUkNo}
                      />
                    </td>
                    <td >
                      <div style={{ display: "flex" }}>
                        <InputText 
                          id={`ogOrTy_${index}`}
                          name={`ogOrTy_${index}`}
                          onChange={(e) => onchangeFormData(e)}
                          onFocus={() => setShowSearchIcon(true)}
                          value={row.ogOrTy ? row.ogOrTy : row[`${"ogOrTy"}_${index}`]}
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "15%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openTypePopup(index)}
                          ></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <InputText disabled 
                        id={`og_OR_DS_${index}`}
                        name={`og_OR_DS_${index}`}
                        value={row.og_OR_DS ? row.og_OR_DS : row[`${"og_OR_DS"}_${index}`]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ClassCodePopup
        visible={TypeVisible}
        onClose={() => setTypeVisible(false)}
        onDataSelect={handleType}
        formData={data}
      />
    </Dialog>
  );
}
