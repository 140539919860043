import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function EstimateRateDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  er_CR_CD,
  seter_CR_CD,
  er_PR_UM,
  seter_PR_UM,
  er_CO_NO,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
}) {
  const {
    erUkNo,
    er_UN_RT,
    er_UN_CT,
    er_MK_UP,
    er_MK_AM,
    er_EX_MR,
    er_AL_DS,
    er_FR_DT,
    er_TO_DT,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeCD } = ClassCode();
  const [er_CR_CDOptions, seter_CR_CDOptions] = useState([]);
  const [er_PR_UMOptions, seter_PR_UMOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const CurrencyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_CR_CD(value);
  };
  const UmDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_PR_UM(value);
  };
  useEffect(() => {
    Currency();
    UM();
  }, []);
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    seter_CR_CDOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seter_PR_UMOptions(Options);
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("er_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":data.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UmDropdown(`er_PR_UM`, dataItMt.im_PR_UM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

const isUpdateMode = Boolean(erUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{erUkNo ? "Revise Estimate Rate" : "Add Estimate Rate"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_IT_NM" className="font-bold">
                    Item No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="er_IT_NM"
                      name="er_IT_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="itemDES" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="itemDES"
                    name="itemDES"
                    value={SelectedDesc}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="er_CO_NO"
                    name="er_CO_NO"
                    value={er_CO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_CR_CD" className="font-bold">
                  Currency
                  </label>
                  <Dropdown
                    name="er_CR_CD"
                    value={er_CR_CD}
                    options={er_CR_CDOptions}
                    onChange={(e) => CurrencyDropdown("er_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_PR_UM" className="font-bold">
                    UM
                  </label>
                  <Dropdown
                    name="er_PR_UM"
                    value={er_PR_UM}
                    options={er_PR_UMOptions}
                    onChange={(e) => UmDropdown("er_PR_UM", e.value)}
                    placeholder="Select UM"
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_UN_RT" className="font-bold">
                Rate
                </label>
                <InputText 
                  id="er_UN_RT"
                  name="er_UN_RT"
                  value={er_UN_RT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_UN_CT" className="font-bold">
                Unit Cost
                </label>
                <InputText 
                  id="er_UN_CT"
                  name="er_UN_CT"
                  value={er_UN_CT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_FR_DT" className="font-bold">
                    From Date
                  </label>
                  <Calendar
                    id="er_FR_DT"
                    name="jm_PS_DT"
                    value={moment(er_FR_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "er_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="er_TO_DT" className="font-bold">
                 To Date
                  </label>
                  <Calendar
                    id="er_TO_DT"
                    name="er_TO_DT"
                    value={moment(er_TO_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => onchangedata({ target: { name: "er_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_MK_UP" className="font-bold">
                Mark Up %
                </label>
                <InputText 
                  id="er_MK_UP"
                  name="er_MK_UP"
                  value={er_MK_UP}
                  onChange={(e) => onchangedata(e)}
                />
               </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_MK_AM" className="font-bold">
                  Mark Up Amount
                </label>
                <InputText 
                  id="er_MK_AM"
                  name="er_MK_AM"
                  value={er_MK_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_EX_MR" className="font-bold">
                Expected Margin %
                </label>
                <InputText 
                  id="er_EX_MR"
                  name="er_EX_MR"
                  value={er_EX_MR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
             <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="er_AL_DS" className="font-bold">
                Allowed Discount %
                </label>
                <InputText 
                  id="er_AL_DS"
                  name="er_AL_DS"
                  value={er_AL_DS}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
