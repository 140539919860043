import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LabTestResultDialog from "./LabTestResultDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  plUkNo:"",
  pl_PA_ID:"",
  pl_PA_NM:"",
  pl_CO_NO:"",   
  pl_VI_NO:"",
  pl_LB_TY:"",
  pl_LB_NM:"",   
  pl_CH_RG:"", 
  pl_ST_CD:"", 
};

const initialFilters = {
  pl_PA_ID: { value: "", operation: "" },
  pl_PA_NM: { value: "", operation: "" },
  pl_CO_NO: { value: "", operation: "" },   
  pl_VI_NO: { value: "", operation: "" },
  pl_LB_TY: { value: "", operation: "" },
  pl_LB_NM: { value: "", operation: "" },   
  pl_CH_RG: { value: "", operation: "" }, 
  pl_ST_CD: { value: "", operation: "" },  	
};

const LabTestResultMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [lr_PA_FA, setlr_PA_FA ] = useState("");
  const [pl_CO_NO, setpl_CO_NO ] = useState("");
  const [pl_CO_CT, setpl_CO_CT ] = useState("");
 // const [pl_CO_CT, setpl_CO_CT ] = useState("");


  
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [LabTestResultTableData, setLabTestResultTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setLabTestResultTableData([{}]);
    setpl_CO_CT(null);
    setlr_PA_FA(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/LabTestResult/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          const updatedCashFlowData = CashFlowData.LabTestResultList.map((target) => {
            const UpdatedworkData = { ...target };
            delete UpdatedworkData.plUkNo;
            return UpdatedworkData;
          });
          setLabTestResultTableData(updatedCashFlowData);
          setFormData(updatedCashFlowData[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    setpl_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),  
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;

            if (fName === "PL_CO_CT") {
              setpl_CO_CT(dataDefault.versionIds[i].default_VALUE) 
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PatientLabTest/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  //   if (!isRecordSelected || selectedRows.length > 1) {
  //     toast.current.show({
  //       severity: "error",
  //       summary: "Error",
  //       detail: isRecordSelected
  //         ? "Select a single record to edit."
  //         : "Select only one record to edit.",
  //       life: 3000,
  //     });
  //     return;
  //   }
  //   setFormData(initialValue);
  //   try {
  //     const response = await axios.get(
  //       `${domain}/api/LabTestResult/Select/${selectedRows[0]}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //       const DetailPriceData = response.data;
  //       setLabTestResultTableData(DetailPriceData.LabTestResultList);
  //       setFormData(DetailPriceData.LabTestResultList[0]);
  //       //setlr_CO_CT(null);
  //       //setlr_PA_FA(null);
  //       handleClickOpen();
  //     } else {
  //       console.error(`API request failed with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleUpdate = async () => {
    handleDefault();
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);

    const table = document.getElementById("LabTestResulttable");
    //const totalRowCount = table.tBodies[0].rows.length;
    if(selectedRows){
    const patientLabTest = {
      plUkNo: selectedRows[0],
      pl_CO_CT:pl_CO_CT,
    };
    const ukNos = selectedRows.toString().split(",");
    const size = ukNos.length;

    for (let i = 0; i < size; i++) {  
      const Details = {
      }
      //patientLabTest.push(Details);
    };
    const RequestInv = {
      "patientLabTest":patientLabTest
    }
    try {
      const response = await axios.post(
        `${domain}/api/LabTestResult/Select`, RequestInv,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
             "Access-Control-Allow-Origin": "http://localhost:3000",
             "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {

        const LabTestData = response.data;
        setLabTestResultTableData(LabTestData.labTestResultList);
        // setsd_SH_NO(salesinvoiceData.salesDetail[0].sd_SH_NO);
        // setSelectedCustomer(salesinvoiceData.salesHeader.cus_NAME);
        // setselectedShipto(salesinvoiceData.salesHeader.shipto_NAME);
        setFormData(LabTestData.patientLabTest);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  };


var requestBody = {};
function values () {
  const patientLabTest={
    lr_VI_NO: document.getElementById("pl_VI_NO").value,
    lr_VI_DT: document.getElementById("pl_VI_DT").value,
    lr_PA_ID: document.getElementById("pl_PA_ID").value,
    lr_CO_NO: document.getElementById("pl_CO_NO").value,
    lr_LB_NM: document.getElementById("pl_LB_NM").value,
    lr_CO_CT:pl_CO_CT,
  }
  const table = document.getElementById("LabTestResulttable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const labTestResultList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const FR_DT = document.getElementsByName("pd_FR_DT_" + i)[0];
    const TO_DT = document.getElementsByName("pd_TO_DT_" + i)[0];
    const details = {
        lrUkNo : document.getElementById("lrUkNo_" + i).value,
        lr_TS_ID : document.getElementById("lr_TS_ID_" + i).value,
        lr_TS_DS : document.getElementById("lr_TS_DS_" + i).value,
        lr_AL_MI : document.getElementById("lr_AL_MI_" + i).value,
        lr_AL_MX : document.getElementById("lr_AL_MX_" + i).value,
        lr_TR_VL : document.getElementById("lr_TR_VL_" + i).value,
        lr_RS_VL : document.getElementById("lr_RS_VL_" + i).value,
        lr_PA_FA : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        lr_SA_SZ : document.getElementById("lr_SA_SZ_" + i).value,
        lr_DE_CT : document.getElementById("lr_DE_CT_" + i).value,
        lr_CU_SE : document.getElementById("lr_CU_SE_" + i).value,
        lr_RM_RK : document.getElementById("lr_RM_RK_" + i).value,
    };
    labTestResultList.push(details);
  }
  requestBody = {
    labTestResultList,
    patientLabTest,
    lr_VI_NO: document.getElementById("pl_VI_NO").value,
    lr_VI_DT: document.getElementById("pl_VI_DT").value,
    lr_PA_ID: document.getElementById("pl_PA_ID").value,
    lr_CO_NO: document.getElementById("pl_CO_NO").value,
    lr_LB_NM: document.getElementById("pl_LB_NM").value,
    lr_CO_CT:pl_CO_CT,
  };
}
const handleFormSubmit = () => {
  values ();
  if (FormData.plUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/LabTestResult/Update`;
    if (confirm) {
      axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
   
  }
};
const leftToolbarTemplate = ( 
  <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
      <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
      Select
    </div>  
    </Tooltip>
      <Button   
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
  </React.Fragment>
);
const exportExcel = () => {
  import("xlsx").then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(tableData);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "products_export.xlsx");
  });
};
const rightToolbarTemplate = (
  <React.Fragment>
    <Button
      style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
      icon="pi pi-upload"
      className="p-button-help"
      rounded
      onClick={exportExcel}
      data-pr-tooltip="XLS"
    />
  </React.Fragment>
);
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PA_ID"] ? filters["pl_PA_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_PA_ID",
                        e.target.value,
                        filters["pl_PA_ID"]
                          ? filters["pl_PA_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_PA_NM"] ? filters["pl_PA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "fr_AD_IT",
                        e.target.value,
                        filters["pl_PA_NM"] ? filters["pl_PA_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_CO_NO"] ? filters["pl_CO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_CO_NO",
                        e.target.value,
                        filters["pl_CO_NO"]
                          ? filters["pl_CO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["pl_VI_NO"] ? filters["pl_VI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_VI_NO",
                        e.target.value,
                        filters["pl_VI_NO"]
                          ? filters["pl_VI_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_LB_TY"] ? filters["pl_LB_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_LB_TY",
                        e.target.value,
                        filters["pl_LB_TY"] ? filters["pl_LB_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pl_LB_NM"] ? filters["pl_LB_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_LB_NM",
                        e.target.value,
                        filters["pl_LB_NM"]
                          ? filters["pl_LB_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["pl_CH_RG"] ? filters["pl_CH_RG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_CH_RG",
                        e.target.value,
                        filters["pl_CH_RG"]
                          ? filters["pl_CH_RG"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["pl_ST_CD"] ? filters["pl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pl_ST_CD",
                        e.target.value,
                        filters["pl_ST_CD"]
                          ? filters["pl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
               

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Patient ID </th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Visit No</th>   
                <th style={tableHeaderStyle}>Lab Type</th>  
                <th style={tableHeaderStyle}>Test Name</th> 
                <th style={tableHeaderStyle}>Charge</th>  
                <th style={tableHeaderStyle}>Status</th>                
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.plUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.plUkNo}
                        checked={selectedRows.includes(rowData.plUkNo)}
                        onChange={() => handleRowSelect(rowData.plUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pl_PA_ID}</td>
                    <td style={tableCellStyle}>{rowData.pl_PA_NM}</td>
                    <td style={tableCellStyle}>{rowData.pl_CO_NO}</td>   
                    <td style={tableCellStyle}>{rowData.pl_VI_NO}</td>
                    <td style={tableCellStyle}>{rowData.pl_LB_TY}</td>
                    <td style={tableCellStyle}>{rowData.pl_LB_NM}</td>   
                    <td style={tableCellStyle}>{rowData.pl_CH_RG}</td> 
                    <td style={tableCellStyle}>{rowData.pl_ST_CD}</td>              
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <LabTestResultDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        LabTestResultTableData={LabTestResultTableData}
        setLabTestResultTableData={setLabTestResultTableData}
        pl_CO_NO={pl_CO_NO}
        lr_PA_FA={lr_PA_FA}
        setlr_PA_FA={setlr_PA_FA}
        pl_CO_CT={pl_CO_CT}
        setpl_CO_CT={setpl_CO_CT}


      />
    </div>
  );
};

export default LabTestResultMain;
