import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import moment from "moment";
import JobMasterPopup from "../Popups/JobMasterPopup";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ScheduleActivityDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ScheduleActivityTableData,
  setScheduleActivityTableData,
  setad_AD_TY,
  Selectedjob,
  setSelectedjob,
  SelectedDesc,
  setselectedDesc,
  sa_CO_NO,
}) {
  const {
    sa_UK_NO,
    saPrNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  const [JobVisible, setJobVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleJob =async (data) => {
    setSelectedjob(data.jm_PR_NO)
    setselectedDesc(data.jm_PR_DS)
    onchangeFormData("saPrNo", data.jm_PR_NO);
    onchangeFormData("jobDES", data.jm_PR_DS);
    const Url = `${domain}/api/AccountMaster/GetByAccountMasterByCostCenter`;
    const requestData={
      "am_CO_NM":data.jm_PR_NO,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;
  
      if (dataLead && dataLead.length > 0) {
        setScheduleActivityTableData(dataLead.map(item => ({
          sa_AC_ID: item.am_AC_ID,
          saObAc: item.am_OB_AC,
          saObSb: item.am_OB_SB,
          saLvDt: item.am_AC_LD,
          sa_AC_DS: item.am_AC_DS,
          sa_PE_CD: item.am_PE_CD,
          sa_CO_CT: item.am_CO_NM,
        })));
  
      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setJobVisible(false);
    setShowSearchIcon(false);
  };
  const openJobPopup = (e) => {
    setJobVisible(true);
    setSelectedRowIndex(e);
  };
  const addRow = () => {
    setScheduleActivityTableData([...ScheduleActivityTableData, {}]);
  };
  const deleteRow = () => {
    if (ScheduleActivityTableData.length > 0) {
      const updatedData = [...ScheduleActivityTableData];
      updatedData.pop();
      setScheduleActivityTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(sa_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{sa_UK_NO ? "Revise Schedule Activity" : "Add Schedule Activity"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="saPrNo" className="font-bold">
                  Job No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      disabled={!!isUpdateMode}
                      id="saPrNo"
                      name="saPrNo"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={Selectedjob}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openJobPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="jobDES" className="font-bold">
                    Description
                  </label>
                  <InputText disabled
                    id="jobDES"
                    name="jobDES"
                    value={SelectedDesc}
                  />
                </div>
              </div>
            </div>  
            <div style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
                {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
              </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="ScheduleActivitytable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th style={{ display: "none" }}>Company</th>
                  <th>Account Id</th>
                  <th>Cost Centre</th>
                  <th>Account Object</th>
                  <th>Subsidiary</th>
                  <th>Account Description</th>
                  <th>Level of Detail</th>
                  <th>Post Code</th>
                  <th>Schd Start Date</th>
                  <th>Schd Start Time</th>  
                  <th>Actual Start Date</th>
                  <th>Actual Start Time</th> 
                  <th>Schd End Date</th>
                  <th>Schd End Time</th> 
                  <th>Actual End Date</th>
                  <th>Actual End Time</th>       
                </tr>
              </thead>
              <tbody>
                {ScheduleActivityTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`sa_UK_NO_${index}`}
                        name={`sa_UK_NO_${index}`}
                        value={row.sa_UK_NO}
                      />
                    </td>
                    <td style={{ display: "none" }}>
                      <InputText disabled
                        id={`sa_CO_NO_${index}`}
                        name={`sa_CO_NO_${index}`}
                        value={sa_CO_NO}
                        readOnly
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`sa_AC_ID_${index}`}
                        name={`sa_AC_ID_${index}`}
                        defaultValue={row.sa_AC_ID}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`sa_CO_CT_${index}`}
                        name={`sa_CO_CT_${index}`}
                        defaultValue={row.sa_CO_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`saObAc_${index}`}
                        name={`saObAc_${index}`}
                        defaultValue={row.saObAc}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`saObSb_${index}`}
                        name={`saObSb_${index}`}
                        defaultValue={row.saObSb}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`sa_AC_DS_${index}`}
                        name={`sa_AC_DS_${index}`}
                        defaultValue={row.sa_AC_DS}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText disabled style={{width:"90px"}}
                        id={`saLvDt_${index}`}
                        name={`saLvDt_${index}`}
                        defaultValue={row.saLvDt}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText  disabled style={{width:"90px"}}
                        id={`sa_PE_CD_${index}`}
                        name={`sa_PE_CD_${index}`}
                        defaultValue={row.sa_PE_CD}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sa_SS_DT_${index}`}
                    name={`sa_SS_DT_${index}`}
                    value={row.sa_SS_DT? moment(row.sa_SS_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sa_SS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                      <InputMask style={{width:"100px"}}
                        id={`sa_SS_TM_${index}`}
                        name={`sa_SS_TM_${index}`}
                        mask="99:99:99"
                        onBlur={(e) => {
                          onChangeTime("sa_SS_TM",e.target.value,index);
                        }}
                        value={moment(row.sa_SS_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sa_AS_DT_${index}`}
                    name={`sa_AS_DT_${index}`}
                    value={row.sa_AS_DT? moment(row.sa_AS_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sa_AS_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                      <InputMask style={{width:"110px"}}
                        id={`sa_AS_TM_${index}`}
                        name={`sa_AS_TM_${index}`}
                        mask="99:99:99"
                        onBlur={(e) => {
                          onChangeTime("sa_AS_TM",e.target.value,index);
                        }}
                        value={moment(row.sa_AS_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sa_SE_DT_${index}`}
                    name={`sa_SE_DT_${index}`}
                    value={row.sa_SE_DT? moment(row.sa_SE_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sa_SE_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                      <InputMask style={{width:"100px"}}
                        id={`sa_SE_TM_${index}`}
                        name={`sa_SE_TM_${index}`}
                        mask="99:99:99"
                        onBlur={(e) => {
                          onChangeTime("sa_SE_TM",e.target.value,index);
                        }}
                        value={moment(row.sa_SE_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                    id={`sa_AE_DT_${index}`}
                    name={`sa_AE_DT_${index}`}
                    value={row.sa_AE_DT? moment(row.sa_AE_DT, "YYYYMMDD").toDate(): null}
                    onChange={(e) => onDateChange({ target: { name: "sa_AE_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                      <InputMask style={{width:"100px"}}
                        id={`sa_AE_TM_${index}`}
                        name={`sa_AE_TM_${index}`}
                        mask="99:99:99"
                        onBlur={(e) => {
                          onChangeTime("sa_AE_TM",e.target.value,index);
                        }}
                        value={moment(row.sa_AE_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
      </div>
      <JobMasterPopup
        visible={JobVisible}
        onClose={() => setJobVisible(false)}
        onDataSelect={handleJob}
        formData={data}
      />
    </Dialog>
  );
}
