import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function TeritotyGroupingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  handleFormSubmit,
  tg_CO_NO,
  settg_CO_NO,
  tg_TR_TY,
  settg_TR_TY,
  tg_CN_TY,
  settg_CN_TY,
}) {
  const { tgUkNo } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [tg_TR_TYOptions, settg_TR_TYOptions] = useState([]);
  const [tg_CN_TYOptions, settg_CN_TYOptions] = useState([]);
  // Teritory
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...data, [name]: value });
    settg_TR_TY(value);
  };
  // country
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...data, [name]: value });
    settg_CN_TY(value);
  };
  useEffect(() => {
    fetchDropdownTR();
    fetchDropdownDataCN();
  }, []);
  // TERITORY
  const fetchDropdownTR = async () => {
    const Options = await fetchClassCodeDS("FO/TE");
    settg_TR_TYOptions(Options);
  };
  // COUNTRY
  const fetchDropdownDataCN = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    settg_CN_TYOptions(Options);
  };
  const isUpdateMode = Boolean(tgUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{tgUkNo ? "Revise Teritory Grouping" : "Add Teritory Grouping"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
            <div className="formgrid grid">
            <div style={{display:"none"}}>
                <label htmlFor="tgUkNo" className="font-bold">
                  UK_NO
                </label>
                <InputText disabled
                  id="tgUkNo"
                  name="tgUkNo"
                  value={tgUkNo}
                />
              </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="tg_TR_TY" className="font-bold">
                  Teritory
                </label>
                <Dropdown
                  id="tg_TR_TY"
                  name="tg_TR_TY"
                  value={tg_TR_TY}
                  options={tg_TR_TYOptions}
                  onChange={(e) => onchangeDropDownFormData1("tg_TR_TY", e.value)}
                  placeholder="Select Teritory"
                />
              </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="tg_CN_TY" className="font-bold">
                  Country
                </label>
                <Dropdown
                  id="tg_CN_TY"
                  name="tg_CN_TY"
                  value={tg_CN_TY}
                  options={tg_CN_TYOptions}
                  onChange={(e) => onchangeDropDownFormData2("tg_CN_TY", e.value)}
                  placeholder="Select Country"
                />
              </div>
              <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                <label htmlFor="tg_CO_NO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="tg_CO_NO"
                  name="tg_CO_NO"
                  value={tg_CO_NO}
                />
              </div>
            </div>
         </div>
      </div> 
    </Dialog>
  );
}
