import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
export default function FileManagementDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,onchangeFormData,
  FormData,
  setFormData,
  pr_RG_ST,
  setpr_RG_ST,
  SelectedEQNO,
  setSelectedEQNO,
  SelectedSTNO,
  setSelectedSTNO,
  SelectedCUSTNO,
  setSelectedCUSTNO,
  SelectedDLNO,
  setSelectedDLNO,
  SelectedITNM,
  setSelectedITNM,
  SelectedASNM,
  setSelectedASNM,

}) {
  const {
    fdUkNo,
    pr_EQ_NM,
    pr_ST_NO,
    pr_CU_NO,
    pr_DE_AL,
    pr_SR_NO,
    pr_IT_NM,
    pr_PD_MD,
    pr_PD_FM,
    pr_LT_NO,
    pr_IN_DT,
    pr_SE_DT,
    pr_AQ_DT,
    pr_AS_NO,
    pr_EQ_DS,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [pr_RG_STOptions, setpr_RG_STOptions] = useState([]);
  // BIDDING type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_RG_ST(value);
  };
  useEffect(() => {
    fetchDropdownRGST();
  }, []);
  const fetchDropdownRGST = async () => {
    const Options = await fetchClassCodeDS("EQ/RS");
    setpr_RG_STOptions(Options);
  };
  // advance searchs
  const [EQnoPopupVisible, setEQnoPopupVisible] = useState(false);
  const [SitemNoPopupVisible, setSitemNoPopupVisible] =useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [DealerPopupVisible, setDealerPopupVisible] =useState(false);
  const [ItemPopupVisible, setItemPopupVisible] =useState(false);
  const [AccessorPopupVisible, setAccessorPopupVisible] =useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const [addressTypesite, setaddressTypesite] = useState("F");
  const [addressTypedelaer, setaddressTypedelaer] = useState("S");
  const [addressTypeaccessor, setaddressTypeaccessor] = useState("E");
  const [showSearchIcon, setShowSearchIcon] = useState(false);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const openEqNoPopup = () => {
    setEQnoPopupVisible(true);
  };
  const handleDataSelectEqNO = async (data) => {
    setSelectedEQNO(data.em_EQ_DS);
    onchangeFormData("pr_EQ_DS", data.em_EQ_DS);
    // onchangeFormData("pr_EQ_NM", data.em_EQ_NM);
    document.getElementById("pr_EQ_NM").value = data.em_EQ_NM;
    const Url = `${domain}/api/EquipmentMaster/GetByEquipmentName`;
  const requestData={
    "em_EQ_NM":data.em_EQ_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const dataEQ = response.data.result;

    if (dataEQ ) {
      document.getElementById("pr_ST_NO").value = dataEQ.em_ST_NO;
      document.getElementById("ab_AD_NM").value = dataEQ.site_NAME;
      document.getElementById("pr_CU_NO").value = dataEQ.em_CU_NO;
      document.getElementById("SelectedCUSTNO").value = dataEQ.customer_NAME;
      document.getElementById("pr_DE_AL").value = dataEQ.em_DE_AL;
      document.getElementById("SelectedDLNO").value = dataEQ.delaer_NAME;
      document.getElementById("pr_SR_NO").value = dataEQ.em_SR_NO;
      document.getElementById("pr_IT_NM").value = dataEQ.em_IT_NM;
      document.getElementById("im_IT_DS").value = dataEQ.item_NAME;
      document.getElementById("pr_PD_MD").value = dataEQ.em_PD_MD;
      document.getElementById("pr_PD_FM").value = dataEQ.em_PD_FM;
      document.getElementById("pr_IN_DT").value = dataEQ.em_IN_DT; 
      document.getElementById("pr_SE_DT").value = dataEQ.em_SE_DT;
      onchangeFormData("pr_AQ_DT", dataEQ.em_AQ_DT);
      document.getElementById("pr_AS_NO").value = dataEQ.em_AS_NO;
      document.getElementById("SelectedASNM").value = dataEQ.accessor_NAME;
      onchangeDropDownFormData("pr_RG_ST", dataEQ.em_RG_ST);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setEQnoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openSitePopup = () => {
    setSitemNoPopupVisible(true);
  };
  const handleDataSelectSite = (data) => {
    setSelectedSTNO(data.ab_AD_NM);
    document.getElementById("pr_ST_NO").value = data.abAdNo;
    setSitemNoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const handleDataSelectCust = (data) => {
    setSelectedCUSTNO(data.ab_AD_NM);
    document.getElementById("pr_CU_NO").value = data.abAdNo;
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openDealerPopup = () => {
    setDealerPopupVisible(true);
  };
  const handleDataSelectDealer = (data) => {
    setSelectedDLNO(data.ab_AD_NM);
    document.getElementById("pr_DE_AL").value = data.abAdNo;
    setDealerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("pr_IT_NM").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openAccessorPopup = () => {
    setAccessorPopupVisible(true);
  };
  const handleDataSelectAccessor = (data) => {
    setSelectedASNM(data.ab_AD_NM);
    document.getElementById("pr_AS_NO").value = data.abAdNo;
    setAccessorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{fdUkNo ? "Revise Product Registration" : "Add Product Registration"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
            <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_EQ_DS" className="font-bold">
                  Equipment No<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                      id="em_EQ_DS"
                      name="em_EQ_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedEQNO}
                    />
                    {showSearchIcon && (
                      <i className="pi pi-search"
                        style={{ color: 'var(--primary-color)', fontSize: '119%',marginTop: '4%',  cursor: 'pointer' }}
                        onClick={openEqNoPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_EQ_NM"
                      name="pr_EQ_NM"
                      defaultValue={pr_EQ_NM}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_ST_NO" className="font-bold">
                    Site Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: 'flex' }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedSTNO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openSitePopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_ST_NO"
                      name="pr_ST_NO"
                      defaultValue={pr_ST_NO}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_CU_NO" className="font-bold">
                  Customer<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                      id="SelectedCUSTNO"
                      name="SelectedCUSTNO"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedCUSTNO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openCustomerPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_CU_NO"
                      name="pr_CU_NO"
                      defaultValue={pr_CU_NO}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_DE_AL" className="font-bold">
                  Dealer<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                      id="SelectedDLNO"
                      name="SelectedDLNO"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedDLNO}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openDealerPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_DE_AL"
                      name="pr_DE_AL"
                      defaultValue={pr_DE_AL}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_SR_NO" className="font-bold">
                  Serial Number
                  </label>
                  <InputText
                  id="pr_SR_NO"
                  name="pr_SR_NO"
                  defaultValue={pr_SR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_IT_NM" className="font-bold">
                  Item Number<span style={{ color: 'red' }}>*</span>
                </label>
                <div  style={{ display: 'flex' }}>
                    <InputText
                      id="im_IT_DS"
                      name="im_IT_DS"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedITNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openItemPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_IT_NM"
                      name="pr_IT_NM"
                      defaultValue={pr_IT_NM}
                      onChange={(event) => onchangedata(event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_PD_MD" className="font-bold">
                  Product Model
                  </label>
                  <InputText
                  id="pr_PD_MD"
                  name="pr_PD_MD"
                  defaultValue={pr_PD_MD}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div> 
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_PD_FM" className="font-bold">
                  Product Family
                  </label>
                  <InputText
                  id="pr_PD_FM"
                  name="pr_PD_FM"
                  defaultValue={pr_PD_FM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>     
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_LT_NO" className="font-bold">
                  Lot/SN
                  </label>
                  <InputText
                  id="pr_LT_NO"
                  name="pr_LT_NO"
                  defaultValue={pr_LT_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_IN_DT" className="font-bold">
                    Installation Date
                  </label>
                  <Calendar
                    id="pr_IN_DT"
                    name="pr_IN_DT"
                    value={moment(data.pr_IN_DT, "YYYYMMDD").isValid() ? moment(data.pr_IN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "pr_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_SE_DT" className="font-bold">
                  In Service Date
                  </label>
                  <Calendar
                    id="pr_SE_DT"
                    name="pr_SE_DT"
                    value={moment(data.pr_SE_DT, "YYYYMMDD").isValid() ? moment(data.pr_SE_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "pr_SE_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_AQ_DT" className="font-bold">
                  Acquired Date
                  </label>
                  <Calendar
                    id="pr_AQ_DT"
                    name="pr_AQ_DT"
                    value={moment(data.pr_AQ_DT, "YYYYMMDD").isValid() ? moment(data.pr_AQ_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "pr_AQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pr_RG_ST" className="font-bold">
                    Registration Status
                  </label>
                  <Dropdown
                    id="pr_RG_ST"
                    name="pr_RG_ST"
                    value={pr_RG_ST}
                    options={pr_RG_STOptions}
                    onChange={(e) => onchangeDropDownFormData("pr_RG_ST", e.value)}
                    placeholder="Select Type"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="pr_AS_NO" className="font-bold">
                  Accessor <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: 'flex' }}>
                    <InputText
                      id="SelectedASNM"
                      name="SelectedASNM"
                      onChange={(event) => onchangedata(event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedASNM}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: 'var(--primary-color)',
                          fontSize: '119%',
                          marginTop: '4%',
                          cursor: 'pointer',
                        }}
                        onClick={openAccessorPopup}
                      ></i>
                    )}
                  </div>
                  <div style={{ display: 'none' }}>
                    <InputText
                      id="pr_AS_NO"
                      name="pr_AS_NO"
                      defaultValue={pr_AS_NO}
                    />
                  </div>
                </div>
            </div>
        </div>
      </div>
      {/* equipment */}
      <EquipmentPopup
        visible={EQnoPopupVisible}
        onClose={() => setEQnoPopupVisible(false)}
        onDataSelect={handleDataSelectEqNO}
        formData={data}
      />
      {/* site */}
      <AddressBookPopup
        visible={SitemNoPopupVisible}
        onClose={() => setSitemNoPopupVisible(false)}
        onDataSelect={handleDataSelectSite}
        formData={data}
        addressType={addressTypesite}
        setFormData={setFormData}
      />
      {/* custoemr */}
        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelectCust}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* dealer */}
        <AddressBookPopup
        visible={DealerPopupVisible}
        onClose={() => setDealerPopupVisible(false)}
        onDataSelect={handleDataSelectDealer}
        formData={data}
        addressType={addressTypedelaer}
        setFormData={setFormData}
      />
      {/* accessor */}
        <AddressBookPopup
        visible={AccessorPopupVisible}
        onClose={() => setAccessorPopupVisible(false)}
        onDataSelect={handleDataSelectAccessor}
        formData={data}
        addressType={addressTypeaccessor}
        setFormData={setFormData}
      />
      {/* item */}
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
