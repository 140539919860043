import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function EquipmentMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SelectedSiteno,
  setSelectedSiteno,
  SelectedCustomer,
  setSelectedCustomer,
  SelectedParent,
  setSelectedParent,
  SelectedItem,
  setSelectedItem,
  SelectedServiceprd,
  setSelectedServiceprd,
  SelectedTechnician,
  setSelectedTechnician,
  SelectedSalesprsn,
  setSelectedSalesprsn,
  SelectedDealer,
  setSelectedDealer,
  SelectedAccessor,
  setSelectedAccessor,
  SelectedDistributer,
  setSelectedDistributer,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  em_CC_NM,
  em_RG_ST,
  em_EQ_ST,
  em_CL_01,
  em_CL_02,
  em_CL_03,
  em_CL_04,
  em_CL_05,
  em_CL_06,
  em_CL_07,
  em_CL_08,
  em_CL_09,
  em_CL_10,
  setem_CL_01,
  setem_CL_02,
  setem_CL_03,
  setem_CL_04,
  setem_CL_05,
  setem_CL_06,
  setem_CL_07,
  setem_CL_08,
  setem_CL_09,
  setem_CL_10,
  setem_CC_NM,
  setem_RG_ST,
  setem_EQ_ST,
  em_CN_RY,
  setem_CN_RY,
}) {
  const {
    emUkNo,
    em_EQ_NM,
    em_EQ_DS,
    em_ST_NO,
    em_CU_NO,
    em_AQ_DT,
    em_IN_DT,
    em_DS_DT,
    em_SE_DT,
    em_UN_NO,
    em_SR_NO,
    em_PA_NM,
    em_IT_NM,
    em_PD_MD,
    em_PD_FM,
    em_PL_NO,
    em_AD_DS,
    em_SE_PR,
    em_TE_CN,
    em_SA_NO,
    em_SR_DT,
    em_WE_DT,
    em_DE_AL,
    em_AS_NO,
    em_DS_NO,
  } = data;
  const {borderbox,TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // DROPDOWNS
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [em_CC_NMOptions, setem_CC_NMOptions] = useState([]);
  const [em_RG_STOptions, setem_RG_STOptions] = useState([]);
  const [em_EQ_STOptions, setem_EQ_STOptions] = useState([]);
  const [em_CL_01Options, setem_CL_01Options] = useState([]);
  const [em_CL_02Options, setem_CL_02Options] = useState([]);
  const [em_CL_03Options, setem_CL_03Options] = useState([]);
  const [em_CL_04Options, setem_CL_04Options] = useState([]);
  const [em_CL_05Options, setem_CL_05Options] = useState([]);
  const [em_CL_06Options, setem_CL_06Options] = useState([]);
  const [em_CL_07Options, setem_CL_07Options] = useState([]);
  const [em_CL_08Options, setem_CL_08Options] = useState([]);
  const [em_CL_09Options, setem_CL_09Options] = useState([]);
  const [em_CL_10Options, setem_CL_10Options] = useState([]);
  const [em_CN_RYOptions, setem_CN_RYOptions] = useState([]);
  // Registration status
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_RG_ST(value);
  };
  // CLASS CODE1
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_01(value);
  };
  // CLASS CODE2
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_02(value);
  };
  // CLASS CODE3
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_03(value);
  };
  // CLASS CODE4
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_04(value);
  };
  // CLASS CODE5
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_05(value);
  };
  // CLASS CODE6
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_06(value);
  };
  //CLASS CODE7
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_07(value);
  };
  // CLASS CODE8
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_08(value);
  };
  // CLASS CODE9
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_09(value);
  };
  // CLASS CODE10
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CL_10(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setem_CN_RY(value);
  };
  useEffect(() => {
    fetchDropdownCOST();
    fetchDropdownRST();
    fetchDropdownST();
    fetchDropdownCC1();
    fetchDropdownCC2();
    fetchDropdownCC3();
    fetchDropdownCC4();
    fetchDropdownCC5();
    fetchDropdownCC6();
    fetchDropdownCC7();
    fetchDropdownCC8();
    fetchDropdownCC9();
    fetchDropdownCC10();
    country();
  }, []);
  const fetchDropdownCOST = async () => {
    const Options = await fetchCostCenter();
    setem_CC_NMOptions(Options);
  };
  const fetchDropdownRST = async () => {
    const Options = await fetchClassCodeDS("EQ/RS");
    setem_RG_STOptions(Options);
  };
  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setem_EQ_STOptions(Options);
  };
  const fetchDropdownCC1 = async () => {
    const Options = await fetchClassCodeDS("EM/1");
    setem_CL_01Options(Options);
  };
  const fetchDropdownCC2 = async () => {
    const Options = await fetchClassCodeDS("EM/2");
    setem_CL_02Options(Options);
  };
  const fetchDropdownCC3 = async () => {
    const Options = await fetchClassCodeDS("EM/3");
    setem_CL_03Options(Options);
  };
  const fetchDropdownCC4 = async () => {
    const Options = await fetchClassCodeDS("EM/4");
    setem_CL_04Options(Options);
  };
  const fetchDropdownCC5 = async () => {
    const Options = await fetchClassCodeDS("EM/5");
    setem_CL_05Options(Options);
  };
  const fetchDropdownCC6 = async () => {
    const Options = await fetchClassCodeDS("EM/6");
    setem_CL_06Options(Options);
  };
  const fetchDropdownCC7 = async () => {
    const Options = await fetchClassCodeDS("EM/7");
    setem_CL_07Options(Options);
  };
  const fetchDropdownCC8 = async () => {
    const Options = await fetchClassCodeDS("EM/8");
    setem_CL_08Options(Options);
  };
  const fetchDropdownCC9 = async () => {
    const Options = await fetchClassCodeDS("EM/9");
    setem_CL_09Options(Options);
  };
  const fetchDropdownCC10 = async () => {
    const Options = await fetchClassCodeDS("EM/10");
    setem_CL_10Options(Options);
  };
  const country = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setem_CN_RYOptions(Options);
  }; 
  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SitenoPopupVisible, setSitenoPopupVisible] = useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [ServiceprdPopupVisible, setServiceprdPopupVisible] = useState(false);
  const [TechnicianPopupVisible, setTechnicianPopupVisible] = useState(false);
  const [SalesPrsnPopupVisible, setSalesPrsnPopupVisible] = useState(false);
  const [DealerPopupVisible, setDealerPopupVisible] = useState(false);
  const [AccessorPopupVisible, setAccessorPopupVisible] = useState(false);
  const [DistributerPopupVisible, setDistributerPopupVisible] = useState(false);
  const [addresSite, setaddresSite] = useState("F");
  const [addressCustomer, setaddressCustomer] = useState("C");
  const [addressServiceprd, setaddressServiceprd] = useState("S");
  const [addressTechnician, setaddressTechnician] = useState("E");
  const [addressSalesPRSN, setaddressSalesPRSN] = useState("E");
  const [addressDealer, setaddressDealer] = useState("S");
  const [addressAccessor, setaddressAccessor] = useState("E");
  const [addressDistributer, setaddressDistributer] = useState("S"); 
  const openSiteNoPopup = () => {
    setSitenoPopupVisible(true);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openParentPopup = () => {
    setParentPopupVisible(true);
  };
  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const openServiceprdPopup = () => {
    setServiceprdPopupVisible(true);
  };
  const openTechnicianPopup = () => {
    setTechnicianPopupVisible(true);
  };
  const openSalesprsnPopup = () => {
    setSalesPrsnPopupVisible(true);
  };
  const openDealerPopup = () => {
    setDealerPopupVisible(true);
  };
  const openAccessorPopup = () => {
    setAccessorPopupVisible(true);
  };
  const openDistributerPopup = () => {
    setDistributerPopupVisible(true);
  };
  const handleSelectSiteNO = (data) => {
    setSelectedSiteno(data.ab_AD_NM);
    onchangeFormData("em_ST_NO", data.abAdNo);
    setSitenoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectCustomer = (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    onchangeFormData("em_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectParent = (data) => {
    setSelectedParent(data.em_EQ_DS);
    onchangeFormData("em_PA_NM", data.em_EQ_NM);
    setParentPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectItem = (data) => {
    setSelectedItem(data.im_IT_DS);
    onchangeFormData("em_IT_NM", data.im_IT_NM);
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectServiceprd = (data) => {
    setSelectedServiceprd(data.ab_AD_NM);
    onchangeFormData("em_SE_PR", data.abAdNo);
    setServiceprdPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectTechnician = (data) => {
    setSelectedTechnician(data.ab_AD_NM);
    onchangeFormData("em_TE_CN", data.abAdNo);
    setTechnicianPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectSalesprsn = (data) => {
    setSelectedSalesprsn(data.ab_AD_NM);
    onchangeFormData("em_SA_NO", data.abAdNo);
    setSalesPrsnPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectDealer = (data) => {
    setSelectedDealer(data.ab_AD_NM);
    onchangeFormData("em_DE_AL", data.abAdNo);
    setDealerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectAccessor = (data) => {
    setSelectedAccessor(data.ab_AD_NM);
    onchangeFormData("em_AS_NO", data.abAdNo);
    setAccessorPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectDistributer = (data) => {
    setSelectedDistributer(data.ab_AD_NM);
    onchangeFormData("em_DS_NO", data.abAdNo);
    setDistributerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(emUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ emUkNo ? "Revise Equipment Master" : "Add Equipment Master" }</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
      )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Equipment Details">
            {/* tab1 general */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div style={{display:"none"}}>
                  <label htmlFor="emUkNo" className="font-bold">
                    UK_NO
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="emUkNo"
                      name="emUkNo"
                      value={emUkNo}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EQ_NM" className="font-bold">
                   Equipment No
                  </label>
                  <InputText
                    id="em_EQ_NM"
                    name="em_EQ_NM"
                    value={em_EQ_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EQ_DS" className="font-bold">
                   Name
                  </label>
                  <InputText
                    id="em_EQ_DS"
                    name="em_EQ_DS"
                    value={em_EQ_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div> 
              </div>
            </div>
          {/* 2ND DIV */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_ST_NO" className="font-bold">
                    Site Number <span style={{color:"red"}}> * </span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"                    
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedSiteno}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSiteNoPopup}
                    ></i>
                     )}
                     </div>
                  <InputText style={{display:"none"}}
                    id="em_ST_NO"
                    name="em_ST_NO"
                    value={em_ST_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CU_NO" className="font-bold">
                   Customer No <span style={{color:"red"}}> * </span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}                    
                    value={SelectedCustomer}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                     )}
                     </div>
                  <InputText style={{display:"none"}}
                    id="em_CU_NO"
                    name="em_CU_NO"
                    value={em_CU_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>  
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CN_RY" className="font-bold">
                    Country
                  </label>
                  <Dropdown
                    name="em_CN_RY"
                    value={em_CN_RY}
                    options={em_CN_RYOptions}
                    onChange={(e) => onchangeDropDownFormData14("em_CN_RY", e.value)}
                    placeholder="Select Country"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_RG_ST" className="font-bold">
                   Registration Status
                  </label>
                  <Dropdown
                    name="em_RG_ST"
                    value={em_RG_ST}
                    options={em_RG_STOptions}
                    onChange={(e) => onchangeDropDownFormData1("em_RG_ST", e.value)}
                    placeholder="Select Reg. Status"
                  />
                </div>
              </div>
            </div>
          {/* 3rd div */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AQ_DT" className="font-bold">
                   Acquired Date
                  </label>
                  <Calendar
                    id="em_AQ_DT"
                    name="em_AQ_DT"
                    value={moment(data.em_AQ_DT, "YYYYMMDD").isValid() ? moment(data.em_AQ_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_AQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_IN_DT" className="font-bold">
                   Installation Date
                  </label>
                  <Calendar
                    id="em_IN_DT"
                    name="em_IN_DT"
                    value={moment(data.em_IN_DT, "YYYYMMDD").isValid() ? moment(data.em_IN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DS_DT" className="font-bold">
                   Disposed Date
                  </label>
                  <Calendar
                    id="em_DS_DT"
                    name="em_DS_DT"
                    value={moment(data.em_DS_DT, "YYYYMMDD").isValid() ? moment(data.em_DS_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_DS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SE_DT" className="font-bold">
                   In Service Date
                  </label>
                  <Calendar
                    id="em_SE_DT"
                    name="em_SE_DT"
                    value={moment(data.em_SE_DT, "YYYYMMDD").isValid() ? moment(data.em_SE_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_SE_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
              </div>
            </div>
          {/* 4 DIV */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_UN_NO" className="font-bold">
                    Unit No
                  </label>
                  <InputText
                    id="em_UN_NO"
                    name="em_UN_NO"
                    value={em_UN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SR_NO" className="font-bold">
                    Serial Number
                  </label>
                  <InputText
                    id="em_SR_NO"
                    name="em_SR_NO"
                    value={em_SR_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PA_NM" className="font-bold">
                    Parent Number <span style={{color:"red"}}> *</span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText
                    id="em_PA_NM"
                    name="em_PA_NM"                    
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedParent}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openParentPopup}
                    ></i>
                     )}
                     </div>
                  <InputText style={{display:"none"}}
                    id="em_PA_NM"
                    name="em_PA_NM"
                    value={em_PA_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_IT_NM" className="font-bold">
                   Item Number <span style={{color:"red"}}> *</span>
                  </label>
                  <div style ={{display:"flex"}}>
                  <InputText
                    id="em_IT_NM"
                    name="em_IT_NM"                    
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedItem}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                     )}
                     </div>
                  <InputText style={{display:"none"}}
                    id="em_IT_NM"
                    name="em_IT_NM"
                    value={em_IT_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>  
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PD_MD" className="font-bold">
                   Product Model
                  </label>
                  <InputText
                    id="em_PD_MD"
                    name="em_PD_MD"
                    value={em_PD_MD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PD_FM" className="font-bold">
                   Product Family
                  </label>
                  <InputText
                    id="em_PD_FM"
                    name="em_PD_FM"
                    value={em_PD_FM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CC_NM" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown
                    id="em_CC_NM"
                    name="em_CC_NM"
                    value={em_CC_NM}
                    options={em_CC_NMOptions}
                    onChange={(e) => onchangeDropDownFormData2("em_CC_NM", e.value)}
                    placeholder="Select Cost Centre"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_EQ_ST" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    id="em_EQ_ST"
                    name="em_EQ_ST"
                    value={em_EQ_ST}
                    options={em_EQ_STOptions}
                    onChange={(e) => onchangeDropDownFormData3("em_EQ_ST", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_PL_NO" className="font-bold">
                    Part List No
                  </label>
                  <InputText
                    id="em_PL_NO"
                    name="em_PL_NO"
                    value={em_PL_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AD_DS" className="font-bold">
                   Additional Description
                  </label>
                  <InputText
                    id="em_AD_DS"
                    name="em_AD_DS"
                    value={em_AD_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>  

              </div>
            </div>            
          </TabPanel>
          {/* tab2 */}
          <TabPanel header="Service">
            <div style={inboxstyle}>
                <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SE_PR" className="font-bold">
                  Service Provider <span style={{color:"red"}}> *</span>
                  </label>
                  <div style={{display:"flex"}}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onFocus={() => setShowSearchIcon(true)}
                      onChange={(e) => onchangedata(e)}
                      value={SelectedServiceprd}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openServiceprdPopup}
                      ></i>
                      )}
                      </div>
                    <InputText style={{display:"none"}}
                      id="em_SE_PR"
                      name="em_SE_PR"
                      value={em_SE_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="em_TE_CN" className="font-bold">
                  Technician  <span style={{color:"red"}}> * </span>
                    </label>
                    <div style={{display:"flex"}}>
                      <InputText
                        id="ab_AD_NM"
                        name="ab_AD_NM"
                        onFocus={() => setShowSearchIcon(true)}
                        onChange={(e) => onchangedata(e)}
                        value={SelectedTechnician}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openTechnicianPopup}
                      ></i>
                      )}
                      </div>
                    <InputText style={{display:"none"}}
                      id="em_TE_CN"
                      name="em_TE_CN"
                      value={em_TE_CN}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="em_SR_DT" className="font-bold">
                    Start Date
                    </label>
                    <Calendar
                      id="em_SR_DT"
                      name="em_SR_DT"
                      value={moment(data.em_SR_DT, "YYYYMMDD").isValid() ? moment(data.em_SR_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_SR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="em_RT_DT" className="font-bold">
                    Return Date
                    </label>
                    <Calendar
                      id="em_RT_DT"
                      name="em_RT_DT"
                      value={moment(data.em_RT_DT, "YYYYMMDD").isValid() ? moment(data.em_RT_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "em_RT_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="em_SA_NO" className="font-bold">
                    Sales Person  <span style={{color:"red"}}> * </span>
                    </label>
                    <div style={{display:"flex"}}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedSalesprsn}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openSalesprsnPopup}
                      ></i>
                      )}
                      </div>
                    <InputText style={{display:"none"}}
                      id="em_SA_NO"
                      name="em_SA_NO"
                      value={em_SA_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
        {/* 2nd div */}
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_SR_DT" className="font-bold">
                    Start Date
                  </label>
                  <Calendar
                    id="em_SR_DT"
                    name="em_SR_DT"
                    value={moment(data.em_SR_DT, "YYYYMMDD").isValid() ? moment(data.em_SR_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_SR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_WE_DT" className="font-bold">
                    End Date
                  </label>
                  <Calendar
                    id="em_WE_DT"
                    name="em_WE_DT"
                    value={moment(data.em_WE_DT, "YYYYMMDD").isValid() ? moment(data.em_WE_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "em_WE_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DE_AL" className="font-bold">
                  Dealer <span style={{color:"red"}}> * </span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedDealer}
                  />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openDealerPopup}
                    ></i>
                      )}
                      </div>
                  <InputText style={{display:"none"}}
                    id="em_DE_AL"
                    name="em_DE_AL"
                    value={em_DE_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_AS_NO" className="font-bold">
                  Accessor <span style={{color:"red"}}> * </span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedAccessor}
                  />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openAccessorPopup}
                    ></i>
                      )}
                      </div>
                  <InputText style={{display:"none"}}
                    id="em_AS_NO"
                    name="em_AS_NO"
                    value={em_AS_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_DS_NO" className="font-bold">
                  Distributer <span style={{color:"red"}}> * </span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="ab_AD_NM"
                    name="ab_AD_NM"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedDistributer}
                  />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openDistributerPopup}
                    ></i>
                      )}
                      </div>
                  <InputText style={{display:"none"}}
                    id="em_DS_NO"
                    name="em_DS_NO"
                    value={em_DS_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {/* tab3 */}
          <TabPanel header="Classification">
            <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_01" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="em_CL_01"
                    value={em_CL_01}
                    options={em_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData4("em_CL_01", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="em_CL_02"
                    value={em_CL_02}
                    options={em_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData5("em_CL_02", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="em_CL_03"
                    value={em_CL_03}
                    options={em_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData6("em_CL_03", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="em_CL_04"
                    value={em_CL_04}
                    options={em_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData7("em_CL_04", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="em_CL_05"
                    value={em_CL_05}
                    options={em_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData8("em_CL_05", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_06" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="em_CL_06"
                    value={em_CL_06}
                    options={em_CL_06Options}
                    onChange={(e) => onchangeDropDownFormData9("em_CL_06", e.value)}
                    placeholder="Select Class Code 06"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="em_CL_07"
                    value={em_CL_07}
                    options={em_CL_07Options}
                    onChange={(e) => onchangeDropDownFormData10("em_CL_07", e.value)}
                    placeholder="Select Class Code 07"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="em_CL_08"
                    value={em_CL_08}
                    options={em_CL_08Options}
                    onChange={(e) => onchangeDropDownFormData11("em_CL_08", e.value)}
                    placeholder="Select Class Code 08"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="em_CL_09"
                    value={em_CL_09}
                    options={em_CL_09Options}
                    onChange={(e) => onchangeDropDownFormData12("em_CL_09", e.value)}
                    placeholder="Select Class Code 09"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="em_CL_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="em_CL_10"
                    value={em_CL_10}
                    options={em_CL_10Options}
                    onChange={(e) => onchangeDropDownFormData13("em_CL_10", e.value)}
                    placeholder="Select Class Code 10"
                  />
                </div>
              </div>
            </div>
          </TabPanel>
      </TabView>
      </div> 
      {/* site no */}
      <AddressBookPopup
        visible={SitenoPopupVisible}
        onClose={() => setSitenoPopupVisible(false)}
        onDataSelect={handleSelectSiteNO}
        formData={data}
        addressType={addresSite}
      />
      {/* customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        formData={data}
        addressType={addressCustomer}
      />
      {/* parent */}
      <EquipmentPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleSelectParent}
        formData={data}
      />
      {/* item */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleSelectItem}
        formData={data}
      />
    {/* service prd */}
      <AddressBookPopup
        visible={ServiceprdPopupVisible}
        onClose={() => setServiceprdPopupVisible(false)}
        onDataSelect={handleSelectServiceprd}
        formData={data}
        addressType={addressServiceprd}
      />
    {/* technician */}  
      <AddressBookPopup
        visible={TechnicianPopupVisible}
        onClose={() => setTechnicianPopupVisible(false)}
        onDataSelect={handleSelectTechnician}
        formData={data}
        addressType={addressTechnician}
      />
        {/* Sales person */}
        <AddressBookPopup
        visible={SalesPrsnPopupVisible}
        onClose={() => setSalesPrsnPopupVisible(false)}
        onDataSelect={handleSelectSalesprsn}
        formData={data}
        addressType={addressSalesPRSN}
      />
        {/* Dealer */}
        <AddressBookPopup
        visible={DealerPopupVisible}
        onClose={() => setDealerPopupVisible(false)}
        onDataSelect={handleSelectDealer}
        formData={data}
        addressType={addressDealer}
      />
        {/* Accessor */}
        <AddressBookPopup
        visible={AccessorPopupVisible}
        onClose={() => setAccessorPopupVisible(false)}
        onDataSelect={handleSelectAccessor}
        formData={data}
        addressType={addressAccessor}
      />
        {/* Distributer */}
        <AddressBookPopup
        visible={DistributerPopupVisible}
        onClose={() => setDistributerPopupVisible(false)}
        onDataSelect={handleSelectDistributer}
        formData={data}
        addressType={addressDistributer}
      />
    </Dialog>
  );
}
