import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function MenusCreationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MenusCreationTableData,
  setMenusCreationTableData,
  menu_LEVEL,
  setmenu_LEVEL,
  role,
  setrole,
  enabled,
  setenabled,
  url,
  seturl,
  screen_ID,
  setscreen_ID,
  report_FLAG,
  setreport_FLAG,
  report_ID,
  setreport_ID,
}) {
  const {
    id,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeCD ,fetchClassCodeDS} = ClassCode();

  const [menu_LEVELOptions, setmenu_LEVELOptions] = useState([]);
  const [roleOptions, setroleOptions] = useState([]);
  const [enabledOptions, setenabledOptions] = useState([]);
  const [urlOptions, seturlOptions] = useState([]);
  const [screen_IDOptions, setscreen_IDOptions] = useState([]);
  const [report_FLAGOptions, setreport_FLAGOptions] = useState([]);
  const [report_IDOptions, setreport_IDOptions] = useState([]);


  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setmenu_LEVEL(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrole(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setenabled(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seturl(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setscreen_ID(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setreport_FLAG(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setreport_ID(value);
  };



  useEffect(() => {
    DropDownFormData1();
    DropDownFormData2();
    DropDownFormData3();
    DropDownFormData4();
    DropDownFormData5();
    DropDownFormData6();
    DropDownFormData7();


  }, []);


  const DropDownFormData1 = async () => {
    const Options = await fetchClassCodeDS("MU/LV");
    setmenu_LEVELOptions(Options);
  };
  const DropDownFormData2 = async () => {
    const Options = await fetchClassCodeCD("FO/EN");
    setroleOptions(Options);
  };
  const DropDownFormData3 = async () => {
    const Options = await fetchClassCodeDS("FO/EN");
    setenabledOptions(Options);
  };
  const DropDownFormData4 = async () => {
    const Options = await fetchClassCodeDS("FO/EN");
    seturlOptions(Options);
  };
  const DropDownFormData5 = async () => {
    const Options = await fetchClassCodeDS("FO/SR");
    setscreen_IDOptions(Options);
  };
  const DropDownFormData6 = async () => {
    const Options = await fetchClassCodeDS("FO/EN");
    setreport_FLAGOptions(Options);
  };
  const DropDownFormData7 = async () => {
    const Options = await fetchClassCodeDS("FO/RE");
    setreport_ID(Options);
  };




  const addRow = () => {
    setMenusCreationTableData([...MenusCreationTableData, {}]);
  };

  const deleteRow = () => {
    if (MenusCreationTableData.length > 0) {
      const updatedData = [...MenusCreationTableData];
      updatedData.pop();
      setMenusCreationTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(id);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{id ? "Revise Menus Creationn" : "Add Menus Creation"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="MenusCreationtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Menu</th>
                  <th>Description</th>
                  <th>Menu Level</th>
                  <th>Parent</th>
                  <th>Role</th>
                  <th>Order Id</th>
                  <th>Enabled</th>
                  <th>URL</th>
                  <th>Screen Id</th>
                  <th>Version Id</th>
                  <th>Report Flag</th>
                  <th>Report Id</th>
                </tr>
              </thead>
              <tbody>
                {MenusCreationTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`id_${index}`}
                        name={`id_${index}`}
                        value={row.id}
                      />
                    </td>
                    <td >
                      <InputText
                        id={`menu_${index}`}
                        name={`menu_${index}`}
                        defaultValue={row.menu}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td >
                      <InputText
                        id={`description_${index}`}
                        name={`description_${index}`}
                        defaultValue={row.description}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`menu_LEVEL_${index}`}
                        name={`menu_LEVEL_${index}`}
                        value={data[`menu_LEVEL_${index}`] ? data[`menu_LEVEL_${index}`] : row.menu_LEVEL}
                        options={menu_LEVELOptions}
                        onChange={(e) => onchangeDropDownFormData1(`menu_LEVEL_${index}`, e.value)}
                        placeholder="Menu Level"
                      />
                    </td>
                    <td >
                      <InputText
                        id={`parent_${index}`}
                        name={`parent_${index}`}
                        defaultValue={row.parent}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td >
                      <InputText
                        id={`role_${index}`}
                        name={`role_${index}`}
                        defaultValue={row.role}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    {/* <td>
                      <Dropdown
                        id={`role5_${index}`}
                        name={`role_${index}`}
                        value={data[`role_${index}`] ? data[`role_${index}`] : row.role}
                        options={roleOptions}
                        onChange={(e) => onchangeDropDownFormData2(`role_${index}`, e.value)}
                        placeholder="Role"
                      />
                    </td> */}
                    <td >
                      <InputText
                        id={`order_ID_${index}`}
                        name={`order_ID_${index}`}
                        defaultValue={row.order_ID}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`enabled_${index}`}
                        name={`enabled_${index}`}
                        value={data[`enabled_${index}`] ? data[`enabled_${index}`] : row.enabled}
                        options={enabledOptions}
                        onChange={(e) => onchangeDropDownFormData3(`enabled_${index}`, e.value)}
                        placeholder="Enabled"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`url_${index}`}
                        name={`url_${index}`}
                        value={data[`url_${index}`] ? data[`url_${index}`] : row.url}
                        options={urlOptions}
                        onChange={(e) => onchangeDropDownFormData4(`url_${index}`, e.value)}
                        placeholder="URL"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`screen_ID_${index}`}
                        name={`screen_ID_${index}`}
                        value={data[`screen_ID_${index}`] ? data[`screen_ID_${index}`] : row.screen_ID}
                        options={screen_IDOptions}
                        onChange={(e) => onchangeDropDownFormData5(`screen_ID_${index}`, e.value)}
                        placeholder="Screen Id"
                      />
                    </td>
                    <td >
                      <InputText
                        id={`version_ID_${index}`}
                        name={`version_ID_${index}`}
                        defaultValue={row.version_ID}
                        onChange={(e) => onchangedata(e)}

                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`report_FLAG_${index}`}
                        name={`report_FLAG_${index}`}
                        value={data[`report_FLAG_${index}`] ? data[`report_FLAG_${index}`] : row.report_FLAG}
                        options={report_FLAGOptions}
                        onChange={(e) => onchangeDropDownFormData6(`report_FLAG_${index}`, e.value)}
                        placeholder="Report Flag"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`report_ID_${index}`}
                        name={`report_ID_${index}`}
                        value={data[`report_ID_${index}`] ? data[`report_ID_${index}`] : row.report_ID}
                        options={report_IDOptions}
                        onChange={(e) => onchangeDropDownFormData7(`report_ID_${index}`, e.value)}
                        placeholder="Report Id"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
