import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import CustomerAdvanceDialog from "./CustomerAdvanceDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  rh_UK_NO:"",
  rh_RC_NO: "",
  rh_RD_TY: "",
  rh_DO_CO: "",
  rh_CU_NO:"",
  rh_RC_DT:moment(moment().toDate()).format("YYYYMMDD"),
  rh_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
  // rh_GL_PO:"",
  rh_RC_AM: "",
  rh_CR_CO: "",
  rh_CR_RT:"",
  rh_RC_FR:"",
  rh_BA_NO:"",
  rh_BA_TY:"",
};

const initialFilters = {
  rh_RC_NO: { value: "", operation: "" },
  rh_RD_TY: { value: "", operation: "" },
  rh_DO_CO: { value: "", operation: "" },
  rh_CU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  rh_RC_DT: { value: "", operation: "" },
  rh_GL_DT: { value: "", operation: "" },
  // rh_GL_PO: { value: "", operation: "" },
  rh_RC_AM: { value: "", operation: "" },
  rh_CR_CO: { value: "", operation: "" },
  rh_CR_RT: { value: "", operation: "" },
  rh_RC_FR: { value: "", operation: "" },
  rh_BA_NO: { value: "", operation: "" },
  rh_BA_TY: { value: "", operation: "" },
};

const CustomerAdvanceMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles, copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle, } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");

  const [rh_CR_CO, setrh_CR_CO] = useState("");
  const [rh_GL_BA, setrh_GL_BA] = useState("");
  const [rh_RC_NO, setrh_RC_NO] = useState("");
  const [rh_CR_RT, setrh_CR_RT] = useState("");
  const [rh_CU_NO, setrh_CU_NO] = useState("");
  const [rh_UK_NO, setrh_UK_NO] = useState("");
  const [rh_RC_AM, setrh_RC_AM] = useState("");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setrh_CR_CO(null);
    setrh_GL_BA(null);
    setrh_CU_NO(null);
    setrh_UK_NO(null);
    setrh_RC_NO(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedemEMNO1(null);
    setrh_CR_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CustomerAdvance/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const CustomerAdvanceData = response.data;
          delete CustomerAdvanceData.rh_UK_NO;
          setrh_CR_CO(CustomerAdvanceData.rh_CR_CO);
          setFormData(CustomerAdvanceData);
          setSelectedemEMNO1(CustomerAdvanceData.name);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [rh_DO_CO , setrh_DO_CO ] = useState("");
  const [rh_RD_TY, setrh_RD_TY] = useState("");
  const [rh_CR_CD, setrh_CR_CD] = useState("");
  // const [rh_RC_FR, setrh_RC_FR] = useState("");
  const handleDefault = async () => {
    setrh_DO_CO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
             
              if(fName === "RH_RD_TY"){
                setrh_RD_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "RH_CR_CD"){
                setrh_CR_CD(dataDefault.versionIds[i].default_VALUE)
              }
             
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/CustomerAdvance/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({  ...prevData, [name]: value,  }));
  };
  const onchangeReceivedate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    onChnageExchangeRate (rh_CR_CO,value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({  ...prevData, [name]: value,  }));
    setrh_CR_CO(value);
    // setFormData((prevData) => ({  ...prevData, rh_RC_FR: '',}));
    onChnageExchangeRate (value, FormData.rh_RC_DT);
  };
  const Getamt = async () => {
    const Url = `${domain}/api/CustomerAdvance/GetAmountDetails`;
    const requestData = {
      "rh_CR_RT": document.getElementById("rh_CR_RT").value,
      "rh_RC_FR": document.getElementById("rh_RC_FR").value
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataAmt = response.data.Result;
        if (dataAmt) {
          document.getElementById("rh_RC_AM").value = dataAmt.rh_RC_AM;
        } else {
          console.error("Invalid API response:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
  function onChnageExchangeRate(frCr, ReceiveDate) {
    const exData = {
      "cr_TO_CR":  document.getElementById("rh_CR_CD").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": ReceiveDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rh_CR_RT").value = dataExRt.cr_CO_MR;
          setrh_CR_RT(dataExRt.cr_CO_MR);
           Getamt();
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  }
  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/CustomerAdvance/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CustomerAdvanceData = response.data;
        setFormData(CustomerAdvanceData.receiptsEntry);
        setSelectedemEMNO1(CustomerAdvanceData.receiptsEntry.name);
        setSelectedemEMNO(CustomerAdvanceData.receiptsEntry.accountDes);
        setrh_CR_CO(CustomerAdvanceData.rh_CR_CO);
        setrh_UK_NO(CustomerAdvanceData.receiptsEntry.rh_UK_NO);
        setrh_CU_NO(CustomerAdvanceData.receiptsEntry.rh_CU_NO);
        setrh_RC_NO(CustomerAdvanceData.receiptsEntry.rh_RC_NO);
        setrh_CR_CD(CustomerAdvanceData.receiptsEntry.rh_CR_CD);
        setrh_RC_AM(CustomerAdvanceData.receiptsEntry.rh_RC_AM);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyInput = {};
  function values() {
    const receiptsEntry = {
      rh_UK_NO: document.getElementById("rh_UK_NO").value,
      rh_RC_NO: document.getElementById("rh_RC_NO").value,
      rh_RD_TY: document.getElementById("rh_RD_TY").value,
      rh_DO_CO: document.getElementById("rh_DO_CO").value,
      rh_RC_DT: FormData.rh_RC_DT,
      rh_GL_DT: FormData.rh_GL_DT,
      rh_CU_NO: document.getElementById("rh_CU_NO").value,
      rh_CR_CO: rh_CR_CO,
      rh_CR_RT: document.getElementById("rh_CR_RT").value,
      rh_CR_CD: document.getElementById("rh_CR_CD").value,
      rh_GL_BA: document.getElementById("rh_GL_BA").value,
      rh_RC_AM: document.getElementById("rh_RC_AM").value,
      rh_RC_FR: document.getElementById("rh_RC_FR").value,
      rh_CQ_NO: document.getElementById("rh_CQ_NO").value,
      rh_RM_EX: document.getElementById("rh_RM_EX").value,
  };
    requestBodyInput = {
      receiptsEntry,
    };
  }

  const handleFormSubmit = () => {
    values();
    if (document.getElementById("rh_RC_NO").value) {
    if (document.getElementById("rh_UK_NO").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/CustomerAdvance/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/CustomerAdvance/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const RC_DT = FormData.rh_RC_DT;
        if(RC_DT){
          const currentYear = RC_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${rh_RD_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setrh_RC_NO(resp.dn_DO_NO);
              document.getElementById("rh_RC_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/CustomerAdvance/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
    };
  const generate = async (rh_UK_NO) => {
    const Url = `${domain}/api/CustomerAdvance/PostCustomerAdvance`;
    const request = {
      "receiptsEntry": {
        "rh_UK_NO": rh_UK_NO
      },
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const data = response.data;
      console.log("Generated successfully:", data);
    } catch (error) {
      console.error("Error generating data:", error);
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const inputStyleN = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  //  width:"100%",
    backgroundColor:"#f5f4f4"
  };
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
            <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_NO"] ? filters["rh_RC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_NO",
                        e.target.value,
                        filters["rh_RC_NO"]
                          ? filters["rh_RC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RD_TY"] ? filters["rh_RD_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RD_TY",
                        e.target.value,
                        filters["rh_RD_TY"]
                          ? filters["rh_RD_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_DO_CO"] ? filters["rh_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_DO_CO",
                        e.target.value,
                        filters["rh_DO_CO"]
                          ? filters["rh_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CU_NO"] ? filters["rh_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CU_NO",
                        e.target.value,
                        filters["rh_CU_NO"]
                          ? filters["rh_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleN}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_DT"] ? filters["rh_RC_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_DT",
                        e.target.value,
                        filters["rh_RC_DT"]
                          ? filters["rh_RC_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_GL_DT"] ? filters["rh_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_DT",
                        e.target.value,
                        filters["rh_GL_DT"]
                          ? filters["rh_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                {/* <td>
                  <input
                    type="text"
                    value={filters["rh_GL_PO"] ? filters["rh_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_GL_PO",
                        e.target.value,
                        filters["rh_GL_PO"]
                          ? filters["rh_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> */}
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_AM"] ? filters["rh_RC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_AM",
                        e.target.value,
                        filters["rh_RC_AM"]
                          ? filters["rh_RC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_CO"] ? filters["rh_CR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_CO",
                        e.target.value,
                        filters["rh_CR_CO"]
                          ? filters["rh_CR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_CR_RT"] ? filters["rh_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_CR_RT",
                        e.target.value,
                        filters["rh_CR_RT"]
                          ? filters["rh_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_RC_FR"] ? filters["rh_RC_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_RC_FR",
                        e.target.value,
                        filters["rh_RC_FR"]
                          ? filters["rh_RC_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_NO"] ? filters["rh_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_NO",
                        e.target.value,
                        filters["rh_BA_NO"]
                          ? filters["rh_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["rh_BA_TY"] ? filters["rh_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "rh_BA_TY",
                        e.target.value,
                        filters["rh_BA_TY"]
                          ? filters["rh_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Receipt No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Receipt Date</th>
                <th style={tableHeaderStyle}>GL Date</th>
                {/* <th style={tableHeaderStyle}>Post Code</th> */}
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Fore.Amount</th>
                <th style={tableHeaderStyle}>Batch Number</th>
                <th style={tableHeaderStyle}>Batch Type</th>
            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.rh_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.rh_UK_NO}
                        checked={selectedRows.includes(rowData.rh_UK_NO)}
                        onChange={() => handleRowSelect(rowData.rh_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.rh_RC_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_RD_TY}</td>
                    <td style={tableCellStyle}>{rowData.rh_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.rh_RC_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.rh_GL_DT)}</td> 
                    {/* <td style={tableCellStyle}>{rowData.rh_GL_PO}</td> */}
                    <td style={tableCellStyle}>{rowData.rh_RC_AM}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_CO}</td>
                    <td style={tableCellStyle}>{rowData.rh_CR_RT}</td>
                    <td style={tableCellStyle}>{rowData.rh_RC_FR}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.rh_BA_TY}</td>                 
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
       {/* pagination */}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <CustomerAdvanceDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        generate={generate}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        rh_CR_CO={rh_CR_CO}
        rh_RD_TY={rh_RD_TY}
        rh_DO_CO={rh_DO_CO}
        rh_CR_CD={rh_CR_CD}
        rh_CU_NO={rh_CU_NO}
        rh_UK_NO={rh_UK_NO}
        rh_GL_BA={rh_GL_BA}
        rh_RC_NO={rh_RC_NO}
        setrh_CU_NO={setrh_CU_NO}
        SelectedemEMNO={SelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedemEMNO1={setSelectedemEMNO1}
        onchangeReceivedate={onchangeReceivedate}
        Getamt={Getamt}
        setrh_RC_AM={setrh_RC_AM}
        rh_RC_AM={rh_RC_AM}
      />
    </div>
  );
};

export default CustomerAdvanceMain;
