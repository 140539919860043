import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ProjectMasterPopup from "views/admin/Popups/ProjectMasterPopup"; 
import ConrtactPopup from "../Popups/ContractPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function ContractBillingDetailsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ContractBillingDetailsTableData,
  setContractBillingDetailsTableData, 
  cb_PR_TY,
  setcb_PR_TY,
  cb_ST_CD,
  setcb_ST_CD,
  cb_CR_CD,
  setcb_CR_CD,
  cb_BL_TY,
  setcb_BL_TY,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  cb_EX_RT,
  setcb_BL_NM,
  setitemDES,
  cb_CN_NO,
  setcb_CN_NO,
}) {
  const {
    cbUkNo,
    cb_PR_NO,
    cb_CU_NO,
    cb_DS_PE,
    total_AMOUNT,
    total_DISCOUNT,
    total_TAXABLE_AMOUNT,
    total_TAX,
    total_BILLED,
    total_BALANCE,
    total_FOREIGN_AMOUNT,
    total_FOREIGN_DISCOUNT,
    total_FOREIGN_TAXABLE_AMOUNT,
    total_FOREIGN_TAX,
    total_FOREIGN_BILLED,
    total_FOREIGN_BALANCE,
    cb_CN_TY,
    cb_CN_CO,
    cb_BS_CR,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,
    plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [cb_PR_TYOptions, setcb_PR_TYOptions] = useState([]);
  const [cb_ST_CDOptions, setcb_ST_CDOptions] = useState([]);
  const [cb_CR_CDOptions, setcb_CR_CDOptions] = useState([]);
  const [cb_BL_TYOptions, setcb_BL_TYOptions] = useState([]);

  const ProjectTypeDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcb_PR_TY(value);
  };
  const ContractStatusDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcb_ST_CD(value);
  };
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcb_CR_CD(value);
  };
  const BillTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcb_BL_TY(value);
  };
  useEffect(() => {
    ProjectType();
    ContractStatus();
    Currency();
    BillType();
  }, []);

  const ProjectType = async () => {
    const CostOptions = await fetchClassCodeDS("PR/TY");
    setcb_PR_TYOptions(CostOptions);
  };
  const ContractStatus = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setcb_ST_CDOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setcb_CR_CDOptions(Options);
  };
  const BillType = async () => {
    const Options = await fetchClassCodeDS("BG/TY");
    setcb_BL_TYOptions(Options);
  };

  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  // Advance Search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("cb_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
  };
  // Advance Search
  const [ProjectMasterPopupVisible, setProjectMasterPopupVisible] = useState(false);
  const openProjectMasterPopup = (e) => {
    setProjectMasterPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleProjectMaster = async (data) => {
    setSelectedProjectMaster(data.proj_NAME);
    onchangeFormData("cb_PR_NO", data.pm_PR_NO);
    setProjectMasterPopupVisible(false);
  };
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const handleItem = async (Result) => {
          setcb_BL_NM(Result.im_IT_NM);
          setitemDES(Result.im_IT_DS);
          // onchangeFormData("cb_BL_NM", Result.im_IT_NM, SelectedRowIndex);
          // onchangeFormData("itemDES", Result.im_IT_DS, SelectedRowIndex);
          document.getElementById (`cb_BL_NM_${SelectedRowIndex}`).value =  Result.im_IT_NM;
          document.getElementById (`itemDES_${SelectedRowIndex}`).value =  Result.im_IT_DS;
          const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
          const requestData={
            "im_IT_NM":Result.im_IT_NM
          }
         try {
        const response =await axios.post(Url, requestData, {
          headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
      });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      document.getElementById (`cb_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const [ContractPopupVisible, setContractPopupVisible] = useState(false);
  const openContractPopup = () => {
    setContractPopupVisible(true);
  };
  const handleDataSelect1 = (data) => {
    // onchangeFormData("cb_CN_NO", data.cm_CN_NO);
    // setcb_CN_NO(data.cm_CN_NO);
    document.getElementById("cb_CN_NO").value =  data.cm_CN_NO;
    onchangeFormData("cb_CN_TY", data.cm_CN_TY);
    onchangeFormData("cb_CN_CO", data.cm_CN_CO);
    onchangeFormData("cb_BS_CR", data.cm_BS_CR);
    document.getElementById("cb_EX_RT").value =  data.cm_EX_RT;
    document.getElementById("cb_CU_NO").value =  data.cm_CU_NO;
    onchangeFormData("cb_PR_NO", data.cm_PR_NO);
    setSelectedProjectMaster(data.proj_NAME);
    setSelectedemEMNO(data.name);
    CurrencyDropDown(`cb_CR_CD`, data.cm_CR_CD);
    ContractStatusDropDown(`cb_ST_CD`, data.cm_CN_ST);
    ProjectTypeDropDown(`cb_PR_TY`, data.cm_PR_TY);
    setContractPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setContractBillingDetailsTableData([...ContractBillingDetailsTableData, {}]);
  };
  const deleteRow = () => {
    if (ContractBillingDetailsTableData.length > 0) {
      const updatedData = [...ContractBillingDetailsTableData];
      updatedData.pop();
      setContractBillingDetailsTableData(updatedData);
    }
  };
  const handleAmount =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`cb_BL_ED_${SelectedRowIndex}`).value,
      "sub_1":document.getElementById (`cb_BL_AM_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`cb_BL_CE_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
      await handleFooter();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  };
  const handleForeign =async (SelectedRowIndex) => {
    const Url = `${domain}/api/ErpOperation/GetAddAndSubtract`;
    const requestData={
      "add_1":document.getElementById (`cb_FR_AM_${SelectedRowIndex}`).value,
      "sub_1":document.getElementById (`cb_FR_BL_${SelectedRowIndex}`).value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.result;
  
      if (dataLead) {
        document.getElementById (`cb_FR_BA_${SelectedRowIndex}`).value =  dataLead.result;
  
      } else {
        console.error("Invalid API response:" );
      }
      await handleFooter();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  };
  const onchangeFormAmount = async(e,index) => {
    onchangedata(e);
    handleper(index);
  }; 
  const onchangeForeAmount = async(e,index) => {
    onchangedata(e);
    document.getElementById(`cb_BL_AM_${index}`).value="";
    handleper(index);
  };
  const onchangeDomeFormAmount = async(e,index) => {
    onchangedata(e);
    document.getElementById(`cb_FR_AM_${index}`).value="";
    handleper(index);
  };
  const handleper = async(index) => {
    const Url = `${domain}/api/ContractBillingDetails/GetcalculateAndUpdateTaxDetails`;
    const requestData={
      contractBillingDetailsList: []
    };
    const totalNumberOfRows = ContractBillingDetailsTableData.length;
    for (let i = 0; i < totalNumberOfRows; i++) { 
      
    }

    const rowData = {
      cb_BL_AM: document.getElementById(`cb_BL_AM_${index}`).value,
      cb_DS_PE: document.getElementById(`cb_DS_PE_${index}`).value,
      // cb_TX_AM: document.getElementById(`cb_TX_AM_${i}`).value,
      cb_TX_PE: document.getElementById(`cb_TX_PE_${index}`).value,
      cb_FR_AM: document.getElementById(`cb_FR_AM_${index}`).value,
      cb_EX_RT: document.getElementById("cb_EX_RT").value,
    };
    requestData.contractBillingDetailsList.push(rowData);
  
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const datatax = response.data.Result;
      
      if (datatax) {
        for(let i = 0; i < setContractBillingDetailsTableData.length; i++) {
            document.getElementById(`cb_DS_AM_${index}`).value = datatax[i].CB_DS_AM;
            document.getElementById(`cb_TX_AM_${index}`).value = datatax[i].CB_TX_AM;
            // document.getElementById(`cb_BL_ED_${index}`).value = datatax[i].CB_BL_ED;

            document.getElementById(`cb_FR_BL_${index}`).value = datatax[i].CB_FR_BL;
            document.getElementById(`cb_DS_FR_${index}`).value = datatax[i].CB_DS_FR;
            document.getElementById(`cb_TX_FR_${index}`).value = datatax[i].CB_TX_FR;
            document.getElementById(`cb_BL_AM_${index}`).value = datatax[i].CB_BL_AM;
        }
        await handleFooter();
      } else {
        console.error("Invalid API response:", data);
      }
      // await handleAmount(SelectedRowIndex);
      // await handleForeign(SelectedRowIndex);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFooter = async (SelectedRowIndex) => {
    const Url = `${domain}/api/ContractBillingDetails/GetUpdateSummaryDetails`;
    const requestData={
      contractBillingDetailsList: []
    };
    const totalNumberOfRows = ContractBillingDetailsTableData.length;
    for (let i = 0; i < totalNumberOfRows; i++) { 
      const rowData = {
        cb_BL_AM: document.getElementById(`cb_BL_AM_${i}`).value,
        cb_DS_AM: document.getElementById(`cb_DS_AM_${i}`).value,
        cb_TX_AM: document.getElementById(`cb_TX_AM_${i}`).value,
       // cb_BL_ED: document.getElementById(`cb_BL_ED_${i}`).value,
        cb_FR_AM: document.getElementById(`cb_FR_AM_${i}`).value,
        cb_DS_FR: document.getElementById(`cb_DS_FR_${i}`).value,
        cb_TX_FR: document.getElementById(`cb_TX_FR_${i}`).value,
        // cb_FR_BL: document.getElementById(`cb_FR_BL_${i}`).value,
        // cb_FR_BA: document.getElementById(`cb_FR_BA_${i}`).value,
        // cb_BL_CE: document.getElementById(`cb_BL_CE_${i}`).value,
      };
      requestData.contractBillingDetailsList.push(rowData);
    }
  
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const datatax = response.data.Result;
    if (datatax ) {
      document.getElementById ("total_AMOUNT").value =  datatax.total_AMOUNT;
      document.getElementById ("total_DISCOUNT").value =  datatax.total_DISCOUNT; 
      document.getElementById ("total_TAXABLE_AMOUNT").value =  datatax.total_TAXABLE_AMOUNT;
      document.getElementById ("total_TAX").value =  datatax.total_TAX;
      document.getElementById ("total_BILLED").value =  datatax.total_BILLED;  
      document.getElementById ("total_BALANCE").value =  datatax.total_BALANCE;      
      
      document.getElementById ("total_FOREIGN_AMOUNT").value =  datatax.total_FOREIGN_AMOUNT;
      document.getElementById ("total_FOREIGN_DISCOUNT").value =  datatax.total_FOREIGN_DISCOUNT; 
      document.getElementById ("total_FOREIGN_TAXABLE_AMOUNT").value =  datatax.total_FOREIGN_TAXABLE_AMOUNT;
      document.getElementById ("total_FOREIGN_TAX").value =  datatax.total_FOREIGN_TAX;
      document.getElementById ("total_FOREIGN_BILLED").value =  datatax.total_FOREIGN_BILLED;  
      document.getElementById ("total_FOREIGN_BALANCE").value =  datatax.total_FOREIGN_BALANCE;             
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    
  };
  const isUpdateMode = Boolean(cbUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cbUkNo ? "Revise Contract Billing Details" : "Add Contract Billing Details"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="cb_CN_NO" className="font-bold">
              Contract No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="cb_CN_NO"
                    name="cb_CN_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={cb_CN_NO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openContractPopup}
                    ></i>
                   )}
              </div>
                </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_CN_TY" className="font-bold">
                Type
                  </label>
                  <InputText disabled
                    id="cb_CN_TY"
                    name="cb_CN_TY"
                    value={cb_CN_TY}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_CN_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="cb_CN_CO"
                    name="cb_CN_CO"
                    value={cb_CN_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cb_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="name"
                name="name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText disabled
                id="cb_CU_NO"
                name="cb_CU_NO"
                defaultValue={cb_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
            </div>
          </div>  
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cb_PR_NO" className="font-bold">
              Project No  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText  disabled
                id="proj_NAME"
                name="proj_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedProjectMaster}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openProjectMasterPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="cb_PR_NO"
                name="cb_PR_NO"
                value={cb_PR_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openProjectMasterPopup}
              ></i>
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_PR_TY" className="font-bold">
                   Project Type
                  </label>
                  <Dropdown disabled
                    name="cb_PR_TY"
                    value={cb_PR_TY}
                    options={cb_PR_TYOptions}
                    onChange={(e) => ProjectTypeDropDown("cb_PR_TY", e.value)}
                    placeholder="Select  Project Type"
                  />
                </div>
                
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_CR_CD" className="font-bold">
                 Currency
                  </label>
                  <Dropdown disabled
                    name="cb_CR_CD"
                    value={cb_CR_CD}
                    options={cb_CR_CDOptions}
                    onChange={(e) =>CurrencyDropDown("cb_CR_CD", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="cb_EX_RT"
                    name="cb_EX_RT"
                    defaultValue={cb_EX_RT}
                  />
            </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled
                    id="cb_BS_CR"
                    name="cb_BS_CR"
                    value={cb_BS_CR}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cb_ST_CD" className="font-bold">
                   Status
                  </label>
                  <Dropdown disabled
                    name="cb_ST_CD"
                    value={cb_ST_CD}
                    options={cb_ST_CDOptions}
                    onChange={(e) => ContractStatusDropDown("cb_ST_CD", e.value)}
                    placeholder="Select Contract Status"
                  />
                </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                  <label htmlFor="cb_DS_PE" className="font-bold">
                  Discount %
                  </label>
                  <InputText 
                    id="cb_DS_PE"
                    name="cb_DS_PE"
                    value={cb_DS_PE}
                    onChange={(e) => onchangedata(e)}
                  />
            </div>
          </div>
        </div>
        <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
        </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="ContractBillingDetailstable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Billing Item </th>
                <th>Description</th>
                <th>Bill Type</th>
                <th>Amount</th>
                <th>Discount %</th>
                <th>Discount Amount</th>
                <th>Tax %</th>
                <th>Tax Amount</th>
                <th>Billed</th>
                <th>Balance</th>
                <th>Amount-Foreign</th>
                <th>Discount Foreign</th>
                <th>Tax Foreign</th>
                <th>Billed Foreign</th>
                <th>Balance-Foreign</th>
              </tr>
            </thead>
            <tbody>
              {ContractBillingDetailsTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`cbUkNo_${index}`}
                      name={`cbUkNo_${index}`}
                      value={row.cbUkNo}
                    />
                  </td>
                  <td >
                    <div style={{ display: "flex" }}>
                  <InputText
                  style={{ width: "110px" }}
                  id={`cb_BL_NM_${index}`}
                  name={`cb_BL_NM_${index}`}
                  onChange={(e) => onchangeFormData(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={row.cb_BL_NM ? row.cb_BL_NM: row[`${"cb_BL_NM"}_${index}`]}
                  />
                  {showSearchIcon && (
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "15%",
                cursor: "pointer",
                }}
                onClick={(e) => openItemPopup(index)}
                ></i>
                )}
                </div>
              </td>
                <td>
                <InputText disabled
                style={{ width: "200px" }}
                id={`itemDES_${index}`}
                name={`itemDES_${index}`}
                defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                readOnly
                />
                </td>
                <td>
                  <Dropdown
                    id={`cb_BL_TY_${index}`}
                    name={`cb_BL_TY_${index}`}
                    value={ data[`cb_BL_TY_${index}`] ? data[`cb_BL_TY_${index}`]: row.cb_BL_TY}
                    options={cb_BL_TYOptions}
                    onChange={(e) =>BillTypeDropdown(`cb_BL_TY_${index}`, e.value)}
                    placeholder="Select Bill Type"
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                  disabled={data.cb_CR_CD==cb_BS_CR?false:true} 
                    id={`cb_BL_AM_${index}`}
                    name={`cb_BL_AM_${index}`}
                    defaultValue={row.cb_BL_AM}
                    onChange={(e) => {
                      onchangeDomeFormAmount(e, index);
                    }}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                    id={`cb_DS_PE_${index}`}
                    name={`cb_DS_PE_${index}`}
                    defaultValue={row.cb_DS_PE}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`cb_DS_AM_${index}`}
                    name={`cb_DS_AM_${index}`}
                    defaultValue={row.cb_DS_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_TX_PE_${index}`}
                    name={`cb_TX_PE_${index}`}
                    defaultValue={row.cb_TX_PE}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_TX_AM_${index}`}
                    name={`cb_TX_AM_${index}`}
                    defaultValue={row.cb_TX_AM}
                    onChange={(e) => onchangeFormAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_BL_ED_${index}`}
                    name={`cb_BL_ED_${index}`}
                    defaultValue={row.cb_BL_ED}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled  style={{width:"90px"}}
                    id={`cb_BL_CE_${index}`}
                    name={`cb_BL_CE_${index}`}
                    defaultValue={row.cb_BL_CE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    disabled={data.cb_CR_CD!=cb_BS_CR?false:true} 
                    id={`cb_FR_AM_${index}`}
                    name={`cb_FR_AM_${index}`}
                    defaultValue={data.cb_CR_CD!=cb_BS_CR ? row.cb_FR_AM:0}
                    onChange={(e) => onchangeForeAmount(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_DS_FR_${index}`}
                    name={`cb_DS_FR_${index}`}
                    defaultValue={data.cb_CR_CD!=cb_BS_CR ? row.cb_DS_FR:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_TX_FR_${index}`}
                    name={`cb_TX_FR_${index}`}
                    defaultValue={data.cb_CR_CD!=cb_BS_CR ? row.cb_TX_FR:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled  style={{width:"90px"}}
                    id={`cb_FR_BL_${index}`}
                    name={`cb_FR_BL_${index}`}
                    defaultValue={data.cb_CR_CD!=cb_BS_CR ? row.cb_FR_BL:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`cb_FR_BA_${index}`}
                    name={`cb_FR_BA_${index}`}
                    defaultValue={row.cb_FR_BA}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_AMOUNT" className="font-bold">
                Total Amount
              </label>
              <InputText disabled
                id="total_AMOUNT"
                name="total_AMOUNT"
                value={total_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_DISCOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="total_DISCOUNT"
                name="total_DISCOUNT"
                value={total_DISCOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_TAXABLE_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="total_TAXABLE_AMOUNT"
                name="total_TAXABLE_AMOUNT"
                value={total_TAXABLE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_TAX" className="font-bold">
              Tax
              </label>
              <InputText disabled
                id="total_TAX"
                name="total_TAX"
                value={total_TAX}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_BILLED" className="font-bold">
                Billed
              </label>
              <InputText disabled
                id="total_BILLED"
                name="total_BILLED"
                value={total_BILLED}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_BALANCE" className="font-bold">
                Balance
              </label>
              <InputText disabled
                id="total_BALANCE"
                name="total_BALANCE"
                value={total_BALANCE}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_AMOUNT" className="font-bold">
              Fore Amount
              </label>
              <InputText disabled
                id="total_FOREIGN_AMOUNT"
                name="total_FOREIGN_AMOUNT"
                value={total_FOREIGN_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_DISCOUNT" className="font-bold">
                Fore Discount
              </label>
              <InputText disabled
                id="total_FOREIGN_DISCOUNT"
                name="total_FOREIGN_DISCOUNT"
                value={total_FOREIGN_DISCOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_TAXABLE_AMOUNT" className="font-bold">
              Fore Net Amount
              </label>
              <InputText disabled
                id="total_FOREIGN_TAXABLE_AMOUNT"
                name="total_FOREIGN_TAXABLE_AMOUNT"
                value={total_FOREIGN_TAXABLE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_TAX" className="font-bold">
              Fore Tax
              </label>
              <InputText disabled
                id="total_FOREIGN_TAX"
                name="total_FOREIGN_TAX"
                value={total_FOREIGN_TAX}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_BILLED" className="font-bold">
              Fore Billed
              </label>
              <InputText disabled
                id="total_FOREIGN_BILLED"
                name="total_FOREIGN_BILLED"
                value={total_FOREIGN_BILLED}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="total_FOREIGN_BALANCE" className="font-bold">
              Fore Balance
              </label>
              <InputText disabled
                id="total_FOREIGN_BALANCE"
                name="total_FOREIGN_BALANCE"
                value={total_FOREIGN_BALANCE}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
        </div>
      </div>  
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      
      <ProjectMasterPopup
        visible={ProjectMasterPopupVisible}
        onClose={() => setProjectMasterPopupVisible(false)}
        onDataSelect={handleProjectMaster}
        formData={data}
      />

        <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <ConrtactPopup
        visible={ContractPopupVisible}
        onClose={() => setContractPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
    </Dialog>
  );
}
