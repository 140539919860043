import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function WorkDayCalendarDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkDayCalendarTableData,
  setWorkDayCalendarTableData,
  setselectedSupplier,
  wc_YE_AR,
  wc_MN_TH,
  setwc_YE_AR,
  setwc_MN_TH,
  wc_CO_CT,
  setwc_CO_CT,
  wc_WK_CT,
  setwc_WK_CT,
  setwc_DA_01,
  setwc_DA_02,
  setwc_DA_03,
  setwc_DA_04,
  setwc_DA_05,
  setwc_DA_06,
  setwc_DA_07,
  setwc_DA_08,
  setwc_DA_09,
  setwc_DA_10,
  setwc_DA_11,
  setwc_DA_12,
  setwc_DA_13,
  setwc_DA_14,
  setwc_DA_15,
  setwc_DA_16,
  setwc_DA_17,
  setwc_DA_18,
  setwc_DA_19,
  setwc_DA_20,
  setwc_DA_21,
  setwc_DA_22,
  setwc_DA_23,
  setwc_DA_24,
  setwc_DA_25,
  setwc_DA_26,
  setwc_DA_27,
  setwc_DA_28,
  setwc_DA_29,
  setwc_DA_30,
  setwc_DA_31,
}) {
  const {
    wcUkNo,
  
  } = data;
  const {inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [wc_YE_AROptions, setwc_YE_AROptions] = useState([]);
  const [wc_MN_THOptions, setwc_MN_THOptions] = useState([]);
  const [wc_CO_CTOptions, setwc_CO_CTOptions] = useState([]);
  const [wc_WK_CTOptions, setwc_WK_CTOptions] = useState([]);
  const [wc_DA_01Options, setwc_DA_01Options] = useState([]);
  const [wc_DA_02Options, setwc_DA_02Options] = useState([]);
  const [wc_DA_03Options, setwc_DA_03Options] = useState([]);
  const [wc_DA_04Options, setwc_DA_04Options] = useState([]);
  const [wc_DA_05Options, setwc_DA_05Options] = useState([]);
  const [wc_DA_06Options, setwc_DA_06Options] = useState([]);
  const [wc_DA_07Options, setwc_DA_07Options] = useState([]);
  const [wc_DA_08Options, setwc_DA_08Options] = useState([]);
  const [wc_DA_09Options, setwc_DA_09Options] = useState([]);
  const [wc_DA_10Options, setwc_DA_10Options] = useState([]);
  const [wc_DA_11Options, setwc_DA_11Options] = useState([]);
  const [wc_DA_12Options, setwc_DA_12Options] = useState([]);
  const [wc_DA_13Options, setwc_DA_13Options] = useState([]);
  const [wc_DA_14Options, setwc_DA_14Options] = useState([]);
  const [wc_DA_15Options, setwc_DA_15Options] = useState([]);
  const [wc_DA_16Options, setwc_DA_16Options] = useState([]);
  const [wc_DA_17Options, setwc_DA_17Options] = useState([]);
  const [wc_DA_18Options, setwc_DA_18Options] = useState([]);
  const [wc_DA_19Options, setwc_DA_19Options] = useState([]);
  const [wc_DA_20Options, setwc_DA_20Options] = useState([]);
  const [wc_DA_21Options, setwc_DA_21Options] = useState([]);
  const [wc_DA_22Options, setwc_DA_22Options] = useState([]);
  const [wc_DA_23Options, setwc_DA_23Options] = useState([]);
  const [wc_DA_24Options, setwc_DA_24Options] = useState([]);
  const [wc_DA_25Options, setwc_DA_25Options] = useState([]);
  const [wc_DA_26Options, setwc_DA_26Options] = useState([]);
  const [wc_DA_27Options, setwc_DA_27Options] = useState([]);
  const [wc_DA_28Options, setwc_DA_28Options] = useState([]);
  const [wc_DA_29Options, setwc_DA_29Options] = useState([]);
  const [wc_DA_30Options, setwc_DA_30Options] = useState([]);
  const [wc_DA_31Options, setwc_DA_31Options] = useState([]);
  // Year
  const onchangeDropDownFormDataFormDataYear = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_YE_AR(value);
  };
  // Month
  const onchangeDropDownFormDataMonth = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_MN_TH(value);
  };
 // CostCentre
const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_CO_CT(value);
};
  // WorlCentre
const onchangeDropDownFormDataWorlCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_WK_CT(value);
};
const onchangeDropDownFormDataDay01 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_01(value);
};
const onchangeDropDownFormDataDay02 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_02(value);
};
const onchangeDropDownFormDataDay03 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_03(value);
};
const onchangeDropDownFormDataDay04 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_04(value);
};
const onchangeDropDownFormDataDay05 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_05(value);
};
const onchangeDropDownFormDataDay06 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_06(value);
};
const onchangeDropDownFormDataDay07 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_07(value);
};

const onchangeDropDownFormDataDay08 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_08(value);
};
const onchangeDropDownFormDataDay09 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_09(value);
};
const onchangeDropDownFormDataDay10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_10(value);
};
const onchangeDropDownFormDataDay11 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_11(value);
};
const onchangeDropDownFormDataDay12 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_12(value);
};
const onchangeDropDownFormDataDay13 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_13(value);
};
const onchangeDropDownFormDataDay14 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_14(value);
};

const onchangeDropDownFormDataDay15 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_15(value);
};
const onchangeDropDownFormDataDay16 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_16(value);
};
const onchangeDropDownFormDataDay17 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_17(value);
};
const onchangeDropDownFormDataDay18 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_18(value);
};
const onchangeDropDownFormDataDay19 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_19(value);
};
const onchangeDropDownFormDataDay20 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_20(value);
};
const onchangeDropDownFormDataDay21 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_21(value);
};
const onchangeDropDownFormDataDay22 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_22(value);
};
const onchangeDropDownFormDataDay23 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_23(value);
};
const onchangeDropDownFormDataDay24 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_24(value);
};
const onchangeDropDownFormDataDay25 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_25(value);
};
const onchangeDropDownFormDataDay26 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_26(value);
};
const onchangeDropDownFormDataDay27 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_27(value);
};
const onchangeDropDownFormDataDay28 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_28(value);
};
const onchangeDropDownFormDataDay29 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_29(value);
};
const onchangeDropDownFormDataDay30 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_30(value);
};
const onchangeDropDownFormDataDay31 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwc_DA_31(value);
};
  useEffect(() => {
    Year();
    Month();
    CostCentre();
    WorkCentre();
    Day01();
    Day02();
    Day03();
    Day04();
    Day05();
    Day06();
    Day07();
    Day08();
    Day09();
    Day10();
    Day11();
    Day12();
    Day13();
    Day14();
    Day15();
    Day16();
    Day17();
    Day18();
    Day19();
    Day20();
    Day21();
    Day22();
    Day23();
    Day24();
    Day25();
    Day26();
    Day27();
    Day28();
    Day29();
    Day30();
    Day31();
  }, []);
  const Year = async () => {
    const Options = await fetchClassCodeDS("YE/AR");
    setwc_YE_AROptions(Options);
  };

  const Month = async () => {
    const Options = await fetchClassCodeDS("FO/MO");
    setwc_MN_THOptions(Options);
  };

  const WorkCentre = async () => {
    const Options = await fetchCostCenter();
    setwc_WK_CTOptions(Options);
  };
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setwc_CO_CTOptions(Options);
  };
  const Day01 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_01Options(Options);
  };
  const Day02 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_02Options(Options);
  };
  const Day03 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_03Options(Options);
  };
  const Day04 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_04Options(Options);
  };
  const Day05 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_05Options(Options);
  };
  const Day06 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_06Options(Options);
  };
  const Day07 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_07Options(Options);
  };
  const Day08 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_08Options(Options);
  };
  const Day09 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_09Options(Options);
  };
  const Day10 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_10Options(Options);
  };
  const Day11 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_11Options(Options);
  };
  const Day12 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_12Options(Options);
  };
  const Day13 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_13Options(Options);
  };
  const Day14 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_14Options(Options);
  };
  const Day15 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_15Options(Options);
  };
  const Day16 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_16Options(Options);
  };
  const Day17 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_17Options(Options);
  };
  const Day18 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_18Options(Options);
  };
  const Day19 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_19Options(Options);
  };
  const Day20 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_20Options(Options);
  };
  const Day21 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_21Options(Options);
  };
  const Day22 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_22Options(Options);
  };
  const Day23 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_23Options(Options);
  };
  const Day24 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_24Options(Options);
  };
  const Day25 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_25Options(Options);
  };
  const Day26 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_26Options(Options);
  };
  const Day27 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_27Options(Options);
  };
  const Day28 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_28Options(Options);
  };
  const Day29 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_29Options(Options);
  };
  const Day30 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_30Options(Options);
  };
  const Day31 = async () => {
    const Options = await fetchClassCodeCD("WK/DA");
    setwc_DA_31Options(Options);
  };

  // const ExpenseCategory = async () => {
  //   const Options = await fetchClassCodeDS("EX/CT");
  //   setep_EX_CTOptions(Options);
  // };
  // const onchangeD = async () => {
  //   const Options = await fetchClassCodeDS("EX/TY");
  //   setep_EX_TYOptions(Options);
  // };
  // const Location = async () => {
  //   const Options = await fetchClassCodeDS("EX/LN");
  //   setep_LO_CNOptions(Options);
  // };


  const addRow = () => {
    setWorkDayCalendarTableData([...WorkDayCalendarTableData, {}]);
  };
  
  const deleteRow = () => {
    if (WorkDayCalendarTableData.length > 0) {
      const updatedData = [...WorkDayCalendarTableData];
      updatedData.pop();
      setWorkDayCalendarTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(wcUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");
  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleDataSelect =async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById (`ep_PR_SU_${SelectedRowIndex}`).value =  data.abAdNo;
    document.getElementById (`sup_NAME_${SelectedRowIndex}`).value =  data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{wcUkNo ? "Revise Work Day Calendar" : "Add Work Day Calendar"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
       
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );


  return (
    <Dialog  id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
      <div  style={inboxstyle}>
         <div className="formgrid grid">
                 <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_YE_AR" className="font-bold">
                    Year
                    </label>
                    <Dropdown
                    name="wc_YE_AR"
                    value={wc_YE_AR}
                    options={wc_YE_AROptions}
                    onChange={(e) => onchangeDropDownFormDataFormDataYear("wc_YE_AR", e.value)}
                    placeholder="Year"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_MN_TH" className="font-bold">
                    Month
                    </label>
                    <Dropdown
                    name="wc_MN_TH"
                    value={wc_MN_TH}
                    options={wc_MN_THOptions}
                    onChange={(e) => onchangeDropDownFormDataMonth("wc_MN_TH", e.value)}
                    placeholder="Month"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_CO_CT" className="font-bold">
                    Cost Centre
                    </label>
                    <Dropdown
                    name="wc_CO_CT"
                    value={wc_CO_CT}
                    options={wc_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataCostCentre("wc_CO_CT", e.value)}
                    placeholder=" Cost Centre"
                  />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="wc_WK_CT" className="font-bold">
                    Work Centre
                    </label>
                    <Dropdown
                    name="wc_WK_CT"
                    value={wc_WK_CT}
                    options={wc_WK_CTOptions}
                    onChange={(e) => onchangeDropDownFormDataWorlCentre("wc_WK_CT", e.value)}
                    placeholder="Work Centre"
                  />
                  </div>
                  
          </div> 
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="WorkDayCalendartable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Day 01</th>
              <th>Day 02</th>
              <th>Day 03</th>
              <th>Day 04</th>
              <th>Day 05</th>
              <th>Day 06</th>
              <th>Day 07</th>
              <th>Day 08</th>
              <th>Day 09</th>
              <th>Day 10</th>
              <th>Day 11</th>
              <th>Day 12</th>
              <th>Day 13</th>
              <th>Day 14</th>
              <th>Day 15</th>
              <th>Day 16</th>
              <th>Day 17</th>
              <th>Day 18</th>
              <th>Day 19</th>
              <th>Day 20</th>
              <th>Day 21</th>
              <th>Day 22</th>
              <th>Day 23</th>
              <th>Day 24</th>
              <th>Day 25</th>
              <th>Day 26</th>
              <th>Day 27</th>
              <th>Day 28</th>
              <th>Day 29</th>
              <th>Day 30</th>
              <th>Day 31</th>
              <th style={{ display: "none" }}>Company</th>
            </tr>
          </thead>
          <tbody>
            {WorkDayCalendarTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`wcUkNo_${index}`}
                    name={`wcUkNo_${index}`}
                    value={row.wcUkNo}
                  />
                </td>
                 <td>
                  <Dropdown
                    id={`wc_DA_01_${index}`}
                    name={`wc_DA_01_${index}`}
                    value={ data[`wc_DA_01_${index}`] ? data[`wc_DA_01_${index}`]: row.wc_DA_01}
                    options={wc_DA_01Options}
                    onChange={(e) =>onchangeDropDownFormDataDay01(`wc_DA_01_${index}`, e.value)}
                    placeholder="Day 01"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_02_${index}`}
                    name={`wc_DA_02_${index}`}
                    value={ data[`wc_DA_02_${index}`] ? data[`wc_DA_02_${index}`]: row.wc_DA_02}
                    options={wc_DA_02Options}
                    onChange={(e) =>onchangeDropDownFormDataDay02(`wc_DA_02_${index}`, e.value)}
                    placeholder="Day 02"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`wc_DA_03_${index}`}
                    name={`wc_DA_03_${index}`}
                    value={ data[`wc_DA_03_${index}`] ? data[`wc_DA_03_${index}`]: row.wc_DA_03}
                    options={wc_DA_03Options}
                    onChange={(e) =>onchangeDropDownFormDataDay03(`wc_DA_03_${index}`, e.value)}
                    placeholder="Day 03"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_04_${index}`}
                    name={`wc_DA_04_${index}`}
                    value={ data[`wc_DA_04_${index}`] ? data[`wc_DA_04_${index}`]: row.wc_DA_04}
                    options={wc_DA_04Options}
                    onChange={(e) =>onchangeDropDownFormDataDay04(`wc_DA_04_${index}`, e.value)}
                    placeholder="Day 04"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_05_${index}`}
                    name={`wc_DA_05_${index}`}
                    value={ data[`wc_DA_05_${index}`] ? data[`wc_DA_05_${index}`]: row.wc_DA_05}
                    options={wc_DA_05Options}
                    onChange={(e) =>onchangeDropDownFormDataDay05(`wc_DA_05_${index}`, e.value)}
                    placeholder="Day 05"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_06_${index}`}
                    name={`wc_DA_06_${index}`}
                    value={ data[`wc_DA_06_${index}`] ? data[`wc_DA_06_${index}`]: row.wc_DA_06}
                    options={wc_DA_06Options}
                    onChange={(e) =>onchangeDropDownFormDataDay06(`wc_DA_06_${index}`, e.value)}
                    placeholder="Day 06"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_07_${index}`}
                    name={`wc_DA_07_${index}`}
                    value={ data[`wc_DA_07_${index}`] ? data[`wc_DA_07_${index}`]: row.wc_DA_07}
                    options={wc_DA_07Options}
                    onChange={(e) =>onchangeDropDownFormDataDay07(`wc_DA_07_${index}`, e.value)}
                    placeholder="Day 07"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_08_${index}`}
                    name={`wc_DA_08_${index}`}
                    value={ data[`wc_DA_08_${index}`] ? data[`wc_DA_08_${index}`]: row.wc_DA_08}
                    options={wc_DA_08Options}
                    onChange={(e) =>onchangeDropDownFormDataDay08(`wc_DA_08_${index}`, e.value)}
                    placeholder="Day 08"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_09_${index}`}
                    name={`wc_DA_09_${index}`}
                    value={ data[`wc_DA_09_${index}`] ? data[`wc_DA_09_${index}`]: row.wc_DA_09}
                    options={wc_DA_09Options}
                    onChange={(e) =>onchangeDropDownFormDataDay09(`wc_DA_09_${index}`, e.value)}
                    placeholder="Day 09"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_10_${index}`}
                    name={`wc_DA_10_${index}`}
                    value={ data[`wc_DA_10_${index}`] ? data[`wc_DA_10_${index}`]: row.wc_DA_10}
                    options={wc_DA_10Options}
                    onChange={(e) =>onchangeDropDownFormDataDay10(`wc_DA_10_${index}`, e.value)}
                    placeholder="Day 10"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_11_${index}`}
                    name={`wc_DA_11_${index}`}
                    value={ data[`wc_DA_11_${index}`] ? data[`wc_DA_11_${index}`]: row.wc_DA_11}
                    options={wc_DA_11Options}
                    onChange={(e) =>onchangeDropDownFormDataDay11(`wc_DA_11_${index}`, e.value)}
                    placeholder="Day 11"
                  />
                  </td> 
                   <td>
                  <Dropdown
                    id={`wc_DA_12_${index}`}
                    name={`wc_DA_12_${index}`}
                    value={ data[`wc_DA_12_${index}`] ? data[`wc_DA_12_${index}`]: row.wc_DA_12}
                    options={wc_DA_12Options}
                    onChange={(e) =>onchangeDropDownFormDataDay12(`wc_DA_12_${index}`, e.value)}
                    placeholder="Day 12"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_13_${index}`}
                    name={`wc_DA_13_${index}`}
                    value={ data[`wc_DA_13_${index}`] ? data[`wc_DA_13_${index}`]: row.wc_DA_13}
                    options={wc_DA_13Options}
                    onChange={(e) =>onchangeDropDownFormDataDay13(`wc_DA_13_${index}`, e.value)}
                    placeholder="Day 13"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_14_${index}`}
                    name={`wc_DA_14_${index}`}
                    value={ data[`wc_DA_14_${index}`] ? data[`wc_DA_14_${index}`]: row.wc_DA_14}
                    options={wc_DA_14Options}
                    onChange={(e) =>onchangeDropDownFormDataDay14(`wc_DA_14_${index}`, e.value)}
                    placeholder="Day 14"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_15_${index}`}
                    name={`wc_DA_15_${index}`}
                    value={ data[`wc_DA_15_${index}`] ? data[`wc_DA_15_${index}`]: row.wc_DA_15}
                    options={wc_DA_15Options}
                    onChange={(e) =>onchangeDropDownFormDataDay15(`wc_DA_15_${index}`, e.value)}
                    placeholder="Day 15"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_16_${index}`}
                    name={`wc_DA_16_${index}`}
                    value={ data[`wc_DA_16_${index}`] ? data[`wc_DA_16_${index}`]: row.wc_DA_16}
                    options={wc_DA_16Options}
                    onChange={(e) =>onchangeDropDownFormDataDay16(`wc_DA_16_${index}`, e.value)}
                    placeholder="Day 16"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_17_${index}`}
                    name={`wc_DA_17_${index}`}
                    value={ data[`wc_DA_17_${index}`] ? data[`wc_DA_17_${index}`]: row.wc_DA_17}
                    options={wc_DA_17Options}
                    onChange={(e) =>onchangeDropDownFormDataDay17(`wc_DA_17_${index}`, e.value)}
                    placeholder="Day 17"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_18_${index}`}
                    name={`wc_DA_18_${index}`}
                    value={ data[`wc_DA_18_${index}`] ? data[`wc_DA_18_${index}`]: row.wc_DA_18}
                    options={wc_DA_18Options}
                    onChange={(e) =>onchangeDropDownFormDataDay18(`wc_DA_18_${index}`, e.value)}
                    placeholder="Day 18"
                  />
                  </td>  
                  <td>
                  <Dropdown
                    id={`wc_DA_19_${index}`}
                    name={`wc_DA_19_${index}`}
                    value={ data[`wc_DA_19_${index}`] ? data[`wc_DA_19_${index}`]: row.wc_DA_19}
                    options={wc_DA_19Options}
                    onChange={(e) =>onchangeDropDownFormDataDay19(`wc_DA_19_${index}`, e.value)}
                    placeholder="Day 19"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_20_${index}`}
                    name={`wc_DA_20_${index}`}
                    value={ data[`wc_DA_20_${index}`] ? data[`wc_DA_20_${index}`]: row.wc_DA_20}
                    options={wc_DA_20Options}
                    onChange={(e) =>onchangeDropDownFormDataDay20(`wc_DA_20_${index}`, e.value)}
                    placeholder="Day 20"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_21_${index}`}
                    name={`wc_DA_21_${index}`}
                    value={ data[`wc_DA_21_${index}`] ? data[`wc_DA_21_${index}`]: row.wc_DA_21}
                    options={wc_DA_21Options}
                    onChange={(e) =>onchangeDropDownFormDataDay21(`wc_DA_21_${index}`, e.value)}
                    placeholder="Day 21"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_22_${index}`}
                    name={`wc_DA_22_${index}`}
                    value={ data[`wc_DA_22_${index}`] ? data[`wc_DA_22_${index}`]: row.wc_DA_22}
                    options={wc_DA_22Options}
                    onChange={(e) =>onchangeDropDownFormDataDay22(`wc_DA_22_${index}`, e.value)}
                    placeholder="Day 22"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_23_${index}`}
                    name={`wc_DA_23_${index}`}
                    value={ data[`wc_DA_23_${index}`] ? data[`wc_DA_23_${index}`]: row.wc_DA_23}
                    options={wc_DA_23Options}
                    onChange={(e) =>onchangeDropDownFormDataDay23(`wc_DA_23_${index}`, e.value)}
                    placeholder="Day 23"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_24_${index}`}
                    name={`wc_DA_24_${index}`}
                    value={ data[`wc_DA_24_${index}`] ? data[`wc_DA_24_${index}`]: row.wc_DA_24}
                    options={wc_DA_24Options}
                    onChange={(e) =>onchangeDropDownFormDataDay24(`wc_DA_24_${index}`, e.value)}
                    placeholder="Day 24"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_25_${index}`}
                    name={`wc_DA_25_${index}`}
                    value={ data[`wc_DA_25_${index}`] ? data[`wc_DA_25_${index}`]: row.wc_DA_25}
                    options={wc_DA_25Options}
                    onChange={(e) =>onchangeDropDownFormDataDay25(`wc_DA_25_${index}`, e.value)}
                    placeholder="Day 25"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_26_${index}`}
                    name={`wc_DA_26_${index}`}
                    value={ data[`wc_DA_26_${index}`] ? data[`wc_DA_26_${index}`]: row.wc_DA_26}
                    options={wc_DA_26Options}
                    onChange={(e) =>onchangeDropDownFormDataDay26(`wc_DA_26_${index}`, e.value)}
                    placeholder="Day 26"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_27_${index}`}
                    name={`wc_DA_27_${index}`}
                    value={ data[`wc_DA_27_${index}`] ? data[`wc_DA_27_${index}`]: row.wc_DA_27}
                    options={wc_DA_27Options}
                    onChange={(e) =>onchangeDropDownFormDataDay27(`wc_DA_27_${index}`, e.value)}
                    placeholder="Day 27"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_28_${index}`}
                    name={`wc_DA_28_${index}`}
                    value={ data[`wc_DA_28_${index}`] ? data[`wc_DA_28_${index}`]: row.wc_DA_28}
                    options={wc_DA_28Options}
                    onChange={(e) =>onchangeDropDownFormDataDay28(`wc_DA_28_${index}`, e.value)}
                    placeholder="Day 28"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_29_${index}`}
                    name={`wc_DA_29_${index}`}
                    value={ data[`wc_DA_29_${index}`] ? data[`wc_DA_29_${index}`]: row.wc_DA_29}
                    options={wc_DA_29Options}
                    onChange={(e) =>onchangeDropDownFormDataDay29(`wc_DA_29_${index}`, e.value)}
                    placeholder="Day 29"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_30_${index}`}
                    name={`wc_DA_30_${index}`}
                    value={ data[`wc_DA_30_${index}`] ? data[`wc_DA_30_${index}`]: row.wc_DA_30}
                    options={wc_DA_30Options}
                    onChange={(e) =>onchangeDropDownFormDataDay30(`wc_DA_30_${index}`, e.value)}
                    placeholder="Day 30"
                  />
                  </td> 
                  <td>
                  <Dropdown
                    id={`wc_DA_31_${index}`}
                    name={`wc_DA_31_${index}`}
                    value={ data[`wc_DA_31_${index}`] ? data[`wc_DA_31_${index}`]: row.wc_DA_31}
                    options={wc_DA_31Options}
                    onChange={(e) =>onchangeDropDownFormDataDay31(`wc_DA_31_${index}`, e.value)}
                    placeholder="Day 31"
                  />
                  </td>   
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <AddressBookPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeSUP}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
