import React, { useState, useEffect ,useRef} from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import '../../../components/ListStyle/ListStyle.css';
import moment from "moment";
import { Calendar } from "primereact/calendar";
import ItemPopup from "../Popups/ItemPopup";
import { Toast } from "primereact/toast";
import ButtonStyles from "views/common/ButtonStyles";
import { TabView, TabPanel } from "primereact/tabview";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import BankAccountPopup from "../Popups/BankAccountPopup";
import '../../common/TabStyles.css';
export default function SalesQuoteDialog({
  handleFormSubmit,
  sh_OR_TY,
  sh_OR_NO,
  sh_OR_CO,
  handleClose,
  sh_CU_NO,
  sh_SH_TO,
  setsh_CU_NO,
  setsh_SH_TO,
  sh_TX_AP,
  sh_PY_TR,
  sh_TR_CR,
  sh_EX_RT,
  setsh_EX_RT,
  sh_UK_NO,
  setsh_UK_NO,
  onchangeFormData,
  values,
  requestBodyInput,
  onchangeDropDownFormData4,
  onchangeOrderdate,
  onchangeDropDownFormData5,
  onchangeDropDownFormData,
  onchangeDropDownFormData3,
  onchangeDropDownFormData7,
  onchangeDropDownFormData2,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  SalesOrderData,
  setSalesOrderData,
  deleteTableRow,
  addTableRow,
  sd_CO_CT,
  sd_ST_CD,
  sh_CO_CT,
  sh_BS_CR,
  open,
  setOpen,
  data,
  onchangedata,
  shUkNo,
  setshUkNo,
  FormData,
  setFormData,
  selectedAbAdNN,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNM,
  initialValue,
  isRecordSelected,
  handleClickOpen,
  sh_TX_TY,
  sh_TX_AR,
  sh_AS_TO,
  SALES_PERSON,
  setSALES_PERSON,
  bank_NAME,
  setbank_NAME,
}) {
  // footerstyles
  const { footergridstyle,footerfieldstyle,multirowstyle,TabBoxStyle,
    borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  const toast = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [ItemPopupVisible, setItemVisible] = useState(false);
  const [SalespersonPopupVisible, setSalespersonPopupVisible] = useState(false);
  const [BankPopupVisible, setBankPopupVisible] = useState(false);
  const [addressTypeCust, setaddressTypeCust] = useState("C");
  const [addressTypeSalesPerson, setaddressTypeSalesPerson] = useState("E");
  const openSalesPersonPopup = () => {
    setSalespersonPopupVisible(true);
  };
  const openBankPopup = () => {
    setBankPopupVisible(true);
  };
  const handleSelectBank = (content) => {
    setbank_NAME(content.ba_BA_NM)
    document.getElementById("sh_BA_AC").value = content.ba_AC_NO;
    setBankPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleSelectSalesPerson = (data) => {
    setSALES_PERSON(data.ab_AD_NM);
    document.getElementById("sh_SA_PE").value = data.abAdNo;
    setSalespersonPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [sh_CO_CTOptions, setsh_CO_CTOptions] = useState([]);
  const [sh_PY_TROptions, setsh_PY_TROptions] = useState([]);
  const [sh_TR_CROptions, setsh_TR_CROptions] = useState([]);

  const [sh_TX_APOptions, setsh_TX_APOptions] = useState([]);
  const [sd_TX_APOptions, setsd_TX_APOptions] = useState([]);
  const [sd_ST_CDOptions, setsd_ST_CDOptions] = useState([]);
  const [sd_TR_UMOptions, setsd_TR_UMOptions] = useState([]);

  const [sh_TX_TYOptions, setsh_TX_TYOptions] = useState([]);
  const [sh_TX_AROptions, setsh_TX_AROptions] = useState([]);
  const [sh_AS_TOOptions, setsh_AS_TOOptions] = useState([]);

  const [sd_IT_NM, setSd_IT_NM] = useState("");
  const [im_IT_DS, setIm_IT_DS] = useState("");
  const [sd_TX_AP, setsd_TX_AP] = useState("");
  const [sd_TR_UM, setsd_TR_UM] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };


  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const {
    sd_UK_NO,
    sh_OR_DT,
    sh_RQ_DT,
    net_AMOUNT,
    price_AMOUNT,
    discount_AMOUNT,
    tax_AMOUNT,
    total,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
    fr_TOTAL,
    sh_DS_AT,
    sh_PR_DT,
    sh_DS_PR,
    sh_RE_MK,sh_RF_NO,
    sh_BA_AC,sh_PR_RF,
    sh_CO_PE,sh_SA_PE,sh_DL_DT,sh_VL_TY,sh_HD_CD,sh_LD_TM
  } = data;

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl1 = `${urlpayterm}`;

  const url = `${domain}/api/ClassCode/All`;

  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl = `${urlcost}`;

  // const parameter1 = "FO/CR";
  // const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "ME/PL";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "ME/PL";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "SO/ST";
  const finalUrl5 = `${url}/${parameter5}`;

  const parameter6 = "IN/UM";
  const finalUrl6 = `${url}/${parameter6}`;
// tax type
  const parameter7 = "TA/TY";
  const finalUrl7 = `${url}/${parameter7}`;
// tax area
  const parameter8 = "FO/TA";
  const finalUrl8 = `${url}/${parameter8}`;
// assigned to
  const parameter9 = "AB/6";
  const finalUrl9 = `${url}/${parameter9}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setsh_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setsh_PY_TROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsh_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsh_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsd_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsd_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setsd_TR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions7 = async () => {
    try {
      const response = await axios.get(finalUrl7, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsh_TX_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions8 = async () => {
    try {
      const response = await axios.get(finalUrl8, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsh_TX_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions9 = async () => {
    try {
      const response = await axios.get(finalUrl9, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsh_AS_TOOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const onchangeDropDownFormData6 = (name, value,index=null) => {
    setFormData({ ...FormData, [name]: value });
    setsd_TR_UM(value);
    const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    SalesOrderData[index][fieldName]=value;
  };
  const isUpdateMode = Boolean(shUkNo);
  const handleDataSelect = async (dataAb) => {
    setSelectedAbAdNN(dataAb.ab_AD_NM);
    setsh_CU_NO(dataAb.abAdNo);

    setSelectedAbAdNM(dataAb.ab_AD_NM);
    setsh_SH_TO(dataAb.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
      "cu_CU_NO": dataAb.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData("sh_PY_TR", dataCuMt.cu_PT_TM);
        //setsh_PY_TR(dataCuMt.cu_PT_TM);
        onchangeDropDownFormData4("sh_TR_CR", dataCuMt.cu_CR_CD);
        onchangeDropDownFormData5("sh_TX_AP", dataCuMt.cu_TX_AP);

        const exData={
          "cr_TO_CR": document.getElementById("sh_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
          "cr_EF_DT": data.sh_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  function exchangeRate (exData){
    axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("sh_EX_RT").value = dataExRt.cr_CO_MR;
        setsh_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  };
  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    setsh_SH_TO(data.abAdNo);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.data) {
        setSelectedAbAdNM(data.data.ab_AD_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const  onChangeOfPrice= async(SelectedRowIndex) => {
    const UrlCalulate = `${domain}/api/SalesQuote/GetCalculatedPrice`;
    const priceInput = {
      "exchange_RATE": document.getElementById("sh_EX_RT").value,
      "fr_UNIT_PRICE": document.getElementById (`sd_FR_PR_${SelectedRowIndex}`).value,
      "tax_PERCENTAGE": document.getElementById (`sd_TX_PE_${SelectedRowIndex}`).value,
      "cost_CENTER":   document.getElementById (`sd_CO_CT_${SelectedRowIndex}`).value,
      "item_NAME":document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
      "row_NUM": SelectedRowIndex,
      "discount_PERCENTAGE": document.getElementById (`sd_DS_PR_${SelectedRowIndex}`).value,
      "unit_PRICE": document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value,
      "order_QUANTITY": document.getElementById (`sd_OR_QT_${SelectedRowIndex}`).value
    }
    try {
      const response =await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCalulate = response.data.out1;
      if (dataCalulate ) {
        document.getElementById (`sd_DS_AT_${SelectedRowIndex}`).value =  dataCalulate.discount_AMOUNT;
        document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataCalulate.unit_PRICE;//UNIT COST
        document.getElementById (`sd_DS_PR_${SelectedRowIndex}`).value =  dataCalulate.discount_PERCENTAGE;
        document.getElementById (`net_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.net_AMOUNT;
        document.getElementById (`sd_FR_DS_${SelectedRowIndex}`).value =  dataCalulate.fr_DISCOUNT_AMOUNT;
        document.getElementById (`sd_FR_AT_${SelectedRowIndex}`).value =  dataCalulate.fr_PRICE_AMOUNT;
        document.getElementById (`fr_NET_AMOUNT_${SelectedRowIndex}`).value =  dataCalulate.fr_NET_AMOUNT;
        document.getElementById (`sd_EX_PR_${SelectedRowIndex}`).value =  dataCalulate.price_AMOUNT;
        document.getElementById (`sd_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.tax_AMOUNT;

        const salesHeader = {
          sh_TR_CR: sh_TR_CR,
          sh_EX_RT: document.getElementById("sh_EX_RT").value,
          sh_DS_AT: document.getElementById("sh_DS_AT").value,
          sh_BS_CR: document.getElementById("sh_BS_CR").value,
        };
        const table = document.getElementById("SalesOrder");
        const totalRowCount = table.tBodies[0].rows.length;
        const salesDetail = [];
        for (let i = 0; i < totalRowCount; i++) {  
          const RQ_DT = document.getElementsByName("sd_RQ_DT_" + i)[0]; 
          const PR_DT = document.getElementsByName("sd_PR_DT_" + i)[0];    
          const txAP = document.getElementById ("sd_TX_AP_" + i).value;
          var TX_AP = 'n';
          if(txAP.toUpperCase() === 'Y'){
            TX_AP = 'y';
          }
          const Details = {
            sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
            sdLnNo: document.getElementById("sdLnNo_" + i).value,
            sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
            sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
            sd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
            sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
            sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
            sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
            sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
            sd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            sd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
            sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
            sd_FR_AT: document.getElementById("sd_FR_AT_" + i).value,
            sd_TX_AP: TX_AP,
            sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
            sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
            sd_ST_CD: document.getElementById("sd_ST_CD_" + i).value,
            sd_FR_DS: document.getElementById("sd_FR_DS_" + i).value,
            sd_CO_CT: document.getElementById("sd_CO_CT_" + i).value
          };
          salesDetail.push(Details);
        }
        const requestBodyNet = {
          salesHeader,
          salesDetail,
        };
        axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmount`, requestBodyNet, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((respNet) => {
          document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.fr_PRICE_AMOUNT;
          document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.fr_DISCOUNT_AMOUNT;
          document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.fr_NET_AMOUNT;
          document.getElementById("tax_AMOUNT").value = respNet.data.header.tax_AMOUNT;
          document.getElementById("discount_AMOUNT").value = respNet.data.header.discount_AMOUNT;
          document.getElementById("price_AMOUNT").value = respNet.data.header.price_AMOUNT;
          document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
          document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.fr_TAX_AMOUNT;
          document.getElementById("total").value = respNet.data.header.total;
          document.getElementById("fr_TOTAL").value = respNet.data.header.fr_TOTAL;
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });


      } else {
        console.error("Invalid API response:", dataCalulate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeHeaderDisAmt = async(e) => {
    const salesHeader = {
      sh_TR_CR: sh_TR_CR,
      sh_EX_RT: document.getElementById("sh_EX_RT").value,
      sh_DS_AT: e.currentTarget.value,
      sh_BS_CR: document.getElementById("sh_BS_CR").value,
    };
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    const salesDetail = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const RQ_DT = document.getElementsByName("sd_RQ_DT_" + i)[0]; 
      const PR_DT = document.getElementsByName("sd_PR_DT_" + i)[0];    
      const txAP = document.getElementById ("sd_TX_AP_" + i).value;
      var TX_AP = 'n';
      if(txAP.toUpperCase() === 'Y'){
        TX_AP = 'y';
      }
      const Details = {
        sd_UK_NO: document.getElementById("sd_UK_NO_" + i).value,
        sdLnNo: document.getElementById("sdLnNo_" + i).value,
        sd_IT_NM: document.getElementById("sd_IT_NM_" + i).value,
        sd_OR_QT: document.getElementById("sd_OR_QT_" + i).value,
        sd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        sd_UT_PR: document.getElementById("sd_UT_PR_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_DS_PR: document.getElementById("sd_DS_PR_" + i).value,
        sd_DS_AT: document.getElementById("sd_DS_AT_" + i).value,
        sd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_PR_DT: PR_DT.value?moment(PR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
        sd_FR_AT: document.getElementById("sd_FR_AT_" + i).value,
        sd_TX_AP: TX_AP,
        sd_TX_PE: document.getElementById("sd_TX_PE_" + i).value,
        sd_TX_AM: document.getElementById("sd_TX_AM_" + i).value,
        sd_ST_CD: document.getElementById("sd_ST_CD_" + i).value,
        sd_FR_DS: document.getElementById("sd_FR_DS_" + i).value,
        sd_CO_CT: document.getElementById("sd_CO_CT_" + i).value
      };
      salesDetail.push(Details);
    }
    const requestBodyNet = {
      salesHeader,
      salesDetail,
    };
    axios.post(`${domain}/api/SalesQuote/GetPopulateNetAmount`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((respNet) => {
      document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.fr_PRICE_AMOUNT;
      document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.fr_DISCOUNT_AMOUNT;
      document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.fr_NET_AMOUNT;
      document.getElementById("tax_AMOUNT").value = respNet.data.header.tax_AMOUNT;
      document.getElementById("discount_AMOUNT").value = respNet.data.header.discount_AMOUNT;
      document.getElementById("price_AMOUNT").value = respNet.data.header.price_AMOUNT;
      document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
      document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.fr_TAX_AMOUNT;
      document.getElementById("total").value = respNet.data.header.total;
      document.getElementById("fr_TOTAL").value = respNet.data.header.fr_TOTAL;
    })
    .catch((error) => {
      console.error("Error updating data:", error);
    });

    onchangedata(e);
  };
  const onchangeHeaderDisPer = async(e) => {
    const disPer = e.currentTarget.value;
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;

    if(disPer){
      for (let i = 0; i < totalRowCount; i++) { 
        const disPerMul = document.getElementById ("sd_DS_PR_" + i).value;
        if(!disPerMul || disPerMul === '0'){
          document.getElementById ("sd_DS_PR_" + i).value = disPer;
          onChangeOfPrice(i);
        }
      }
    }
    onchangedata(e);
  }; 
  
  const onchangeOfHeaderTaxApl = async(name,value) => {
    const txAP = value;
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;

    if(txAP.toUpperCase() === 'Y'){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("sd_TX_AP_" + i).value = 'y';
        const item = document.getElementById ("sd_IT_NM_" + i).value;
        const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
        const requestData={
            "im_IT_NM": item
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.Result;
          if (dataItMt ) {
            document.getElementById ("sd_TX_PE_" + i).value =  dataItMt.im_VA_TX;
            onChangeOfPrice(i);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {    
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("sd_TX_AP_" + i).value = 'n';
        document.getElementById ("sd_TX_PE_" + i).value = '0';
        onChangeOfPrice(i);
      }
    }
    onchangeDropDownFormData5("sh_TX_AP", value);
  };
  const onchangeOfTaxAplMul = async(e,index) => {
    const txAP = e.currentTarget.value;
    const item = document.getElementById (`sd_IT_NM_${index}`).value;
    if(txAP.toUpperCase() === 'Y'){
      const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
      const requestData={
          "im_IT_NM": item
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data.Result;
        if (dataItMt ) {
          document.getElementById (`sd_TX_PE_${index}`).value =  dataItMt.im_VA_TX;
          onChangeOfPrice(index);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      document.getElementById (`sd_TX_PE_${index}`).value = '0';
      onChangeOfPrice(index);
    }
  };
  const onchangeFormUnitPrice = async(e,index) => {
    onchangedata(e);
    onChangeOfPrice(index);
  };
  const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("SalesOrder");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : sh_CO_CT,
        "bp_IT_NM" : document.getElementById (`sd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : FormData.sh_OR_DT,
        "bp_TO_DT" : FormData.sh_OR_DT,
        "bp_CU_NO" : document.getElementById("sh_CU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : sh_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`sd_UT_PR_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
  }
  const handleDataSelect2 = async (Result) => {
    const bsCr = document.getElementById("sh_BS_CR").value;
    const table = document.getElementById("SalesOrder");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === sh_TR_CR){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_UT_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_FR_PR_" + i).setAttribute('disabled','disabled');
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("sd_FR_PR_" + i).removeAttribute('disabled');
        document.getElementById("sd_UT_PR_" + i).setAttribute('disabled','disabled');
      }
    }

    setSd_IT_NM(Result.im_IT_NM);
    setIm_IT_DS(Result.im_IT_DS);
    onchangeFormData("sd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
    onchangeFormData("im_IT_DS", Result.im_IT_DS, SelectedRowIndex);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":Result.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        document.getElementById (`sd_TX_AP_${SelectedRowIndex}`).value =  dataItMt.im_TX_AP;
        document.getElementById (`sd_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
        onchangeDropDownFormData6(`sd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
        onchangebspr(SelectedRowIndex);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  // DATE DEFAULT
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleORDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "sh_OR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedPRDate, setSelectedPRDate] = useState(moment().toDate());
  const handlePRDateChange = (e) => {
    setSelectedPRDate(e.value);
    onchangedata({ target: { name: "sh_PR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [selectedRqDate, setSelectedRqDate] = useState(moment().toDate());
  const handleRQDateChange = (e) => {
    const newDate = e.value;
  setSelectedRqDate(newDate);
  setCurrentDate(newDate); // Sync sd_RQ_DT with sh_RQ_DT
  onchangedata({ target: { name: "sh_RQ_DT", value: moment(newDate).format("YYYYMMDD") } });
  };

  const [currentDate, setCurrentDate] = useState(moment().toDate());

  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sh_UK_NO ? "Revise Sales Quote" : "Add Sales Quote"}</h5>
          <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
          {activeTab === 0 && (
            <Button
              style={savestyle}
              icon="pi pi-check"
              onClick={handleFormSubmit} 
               tooltip="Save"
            />
          )}
          {activeTab === 0 && (
            <Button
              style={cancelstyle}
              icon="pi pi-times"
              onClick={handleClose}
              tooltip="Cancel"
            />
          )}
          </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
        <Toast ref={toast} />
         <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
            <TabPanel header="General" className="tab-header">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                  <label htmlFor="sh_UK_NO" className="font-bold">
                    UKNO
                  </label>
                  <InputText
                    id="shUkNo"
                    name="shUkNo"
                    value={sh_UK_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_OR_NO" className="font-bold">
                    Order No
                  </label>
                  <InputText disabled
                    id="sh_OR_NO"
                    name="sh_OR_NO"
                    value={sh_OR_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_OR_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="sh_OR_TY"
                    name="sh_OR_TY"
                    value={sh_OR_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="sh_OR_CO"
                    name="sh_OR_CO"
                    value={sh_OR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_OR_DT" className="font-bold">
                    Order Date
                  </label>
                  <Calendar
                    id="sh_OR_DT"
                    name="sh_OR_DT"
                    value={moment(sh_OR_DT, "YYYYMMDD").toDate()}
                    // onChange={(e) =>
                    //   onchangeOrderdate({
                    //     target: {
                    //       name: "sh_OR_DT",
                    //       value: moment(e.value).format("YYYYMMDD"),
                    //     },
                    //   })
                    // }
                    onChange={(e) => {
                      handleORDateChange(e);
                      onchangeOrderdate({target: { name: "sh_OR_DT",value: moment(e.value).format("YYYYMMDD")}})}}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_RQ_DT" className="font-bold">
                    Requested Date
                  </label>
                  <Calendar
                    id="sh_RQ_DT"
                    name="sh_RQ_DT"
                    value={moment(sh_RQ_DT, "YYYYMMDD").toDate()}
                    onChange={handleRQDateChange}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_CO_CT" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown disabled
                    id="sh_CO_CT"
                    name="sh_CO_CT"
                    value={sh_CO_CT}
                    options={sh_CO_CTOptions}
                    onChange={(e) => onchangeDropDownFormData3("sh_CO_CT", e.value)}
                    placeholder="Select Cost Center"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_CU_NO" className="font-bold">
                    Customer <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="cus_NAME"
                      name="cus_NAME"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNN}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{display:"none"}}>
                    <InputText 
                      id="sh_CU_NO"
                      name="sh_CU_NO"
                      defaultValue={sh_CU_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
              
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_TR_CR" className="font-bold">
                    Currency
                  </label>
                  <Dropdown
                    id="sh_TR_CR"
                    name="sh_TR_CR"
                    value={sh_TR_CR}
                    options={sh_TR_CROptions}
                    onChange={(e) => onchangeDropDownFormData4("sh_TR_CR", e.value)}
                    // onChange={(e) => {
                    //   onchangeDropDownFormData4("sh_TR_CR", e.value);
                    //   onchangebspr(SelectedRowIndex);
                    // }}
                    placeholder="Currency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_EX_RT" className="font-bold">
                    Exchange Rate
                  </label>
                  <InputText disabled
                    id="sh_EX_RT"
                    name="sh_EX_RT"
                    value={sh_EX_RT}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_BS_CR" className="font-bold">
                    Base Currency
                  </label>
                  <InputText disabled id="sh_BS_CR" name="sh_BS_CR" value={sh_BS_CR} />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_PY_TR" className="font-bold">
                    Pay Term
                  </label>
                  <Dropdown
                    id="sh_PY_TR"
                    name="sh_PY_TR"
                    value={sh_PY_TR}
                    options={sh_PY_TROptions}
                    onChange={(e) => onchangeDropDownFormData("sh_PY_TR", e.value)}
                    placeholder="Pay Term"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_TX_AP" className="font-bold">
                    Tax Y/N
                  </label>
                  <Dropdown
                    id="sh_TX_AP"
                    name="sh_TX_AP"
                    value={sh_TX_AP}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                    ]}
                    onChange={(e) => onchangeOfHeaderTaxApl("sh_TX_AP", e.value)}
                    placeholder="Tax"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SH_TO" className="font-bold">
                    Ship To <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="sh_SH_NM"
                      name="sh_SH_NM"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openShipToPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{display:"none"}}>
                    <InputText
                      id="sh_SH_TO"
                      name="sh_SH_TO"
                      value={sh_SH_TO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_DS_PR" className="font-bold">
                    Discount %
                  </label>
                  <InputText
                    id="sh_DS_PR"
                    name="sh_DS_PR"
                    value={sh_DS_PR}
                    onChange={(e) => onchangeHeaderDisPer(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_DS_AT" className="font-bold">
                  Discount Amount
                  </label>
                  <InputText
                    id="sh_DS_AT"
                    name="sh_DS_AT"
                    value={sh_DS_AT}
                    onChange={(e) => onchangeHeaderDisAmt(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SA_PE" className="font-bold">
                    Sales Person <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="SALES_PERSON"
                      name="SALES_PERSON"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SALES_PERSON}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSalesPersonPopup}
                    ></i>
                    )}
                  </div>
                  <div style={{display:"none"}}>
                    <InputText
                      id="sh_SA_PE"
                      name="sh_SA_PE"
                      value={sh_SA_PE}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_SH_DT" className="font-bold">
                      Scheduled Date
                  </label>
                  <Calendar
                    id="sh_SH_DT"
                    name="sh_SH_DT"
                    value={moment(data.sh_SH_DT, "YYYYMMDD").isValid() ? moment(data.sh_SH_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "sh_SH_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_PR_DT" className="font-bold">
                    Promised Date
                  </label>
                  <Calendar
                    id="sd_PR_DT"
                    name="sd_PR_DT"
                    value={moment(data.sd_PR_DT, "YYYYMMDD").isValid() ? moment(data.sd_PR_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "sd_PR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                </div>
              </div>
      <div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addTableRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteTableRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
            <table className="custom-table" id="SalesOrder" style={multirowstyle}>
              <thead>
                <tr>
                  <th style={{display:"none"}}>UKNO</th>
                  <th>Line No</th>
                  <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>UM</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Discount %</th>
                  <th>Discount Amount</th>
                  <th>Net Amount</th>
                  <th>Requested Date</th>
                  <th style={{display:"none"}}>Promised Date</th>
                  <th>Foreign Price</th>
                  <th>Foreign Amount</th>
                  <th>Foreign Discount</th>
                  <th>Foreign Net Amount</th>
                  <th>Tax Y/N</th>
                  <th>Tax Rate</th>
                  <th>Tax</th>
                  <th>Unit Cost</th>
                  <th>Cost Amount</th>
                  <th>Margin %</th>
                  <th>Margin Amount</th>
                  <th>Available Quantity</th>
                  <th>Lump Sum Discount</th>
                  <th>Status</th>
                  <th style={{display:"none"}}>Cost Center</th>
                </tr>
              </thead>
              <tbody>
                {SalesOrderData.map((row, index) => (
                  <tr key={index}>
                     <td style={{display:"none"}}>
                      <InputText
                        style={{ width: "90px" }}
                        id={`sd_UK_NO_${index}`}
                        name={`sd_UK_NO_${index}`}
                        defaultValue={row.sd_UK_NO}
                        readOnly
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`sdLnNo_${index}`}
                        name={`sdLnNo_${index}`}
                        value={index + 1}
                        readOnly
                      />
                    </td>
                    <td >
                      <div style={{ display: "flex" }}> 
                      <InputText
                        style={{ width: "10rem" }}
                        id={`sd_IT_NM_${index}`}
                        name={`sd_IT_NM_${index}`}
                        // defaultValue={sd_IT_NM || row.sd_IT_NM}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.sd_IT_NM ? row.sd_IT_NM: row[`${"sd_IT_NM"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                      </div>
                    </td>
                    <td>
                    <InputText disabled
                        style={{ width: "15rem" }}
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        // defaultValue={im_IT_DS  || row.im_IT_DS}
                        defaultValue={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                        readOnly
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "10rem" }}
                        id={`sd_OR_QT_${index}`}
                        name={`sd_OR_QT_${index}`}
                        defaultValue={row.sd_OR_QT ? row.sd_OR_QT : row[`${"sd_OR_QT"}_${index}`]}
                        // onChange={(e) => onchangedata(e)}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                  <Dropdown style={{width:"7rem"}}
                    id={`sd_TR_UM_${index}`}
                    name={`sd_TR_UM_${index}`}
                    value={ data[`sd_TR_UM_${index}`] ? data[`sd_TR_UM_${index}`]: row.sd_TR_UM}
                    options={sd_TR_UMOptions}
                    onChange={(e) =>handleFormDataChange(`sd_TR_UM_${index}`, e.value)}
                    placeholder="Select UM"
                  />
                  </td>
                    <td>
                      <InputText
                        style={{ width: "10rem" }}
                        id={`sd_UT_PR_${index}`}
                        name={`sd_UT_PR_${index}`}
                        defaultValue={row.sd_UT_PR ? row.sd_UT_PR : row[`${"sd_UT_PR"}_${index}`]}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`sd_EX_PR_${index}`}
                        name={`sd_EX_PR_${index}`}
                        defaultValue={row.sd_EX_PR ? row.sd_EX_PR : row[`${"sd_EX_PR"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "10rem" }}
                        id={`sd_DS_PR_${index}`}
                        name={`sd_DS_PR_${index}`}
                        defaultValue={row.sd_DS_PR ? row.sd_DS_PR : row[`${"sd_DS_PR"}_${index}`]}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`sd_DS_AT_${index}`}
                        name={`sd_DS_AT_${index}`}
                        defaultValue={row.sd_DS_AT ? row.sd_DS_AT : row[`${"sd_DS_AT"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        defaultValue={row.net_AMOUNT ? row.net_AMOUNT : row[`${"net_AMOUNT"}_${index}`]}
                      />
                    </td>
                    <td>
                  
                     <Calendar 
                      style={{width:"10rem"}}
                      id={`sd_RQ_DT_${index}`}
                      name={`sd_RQ_DT_${index}`}
                      value={currentDate}
                      onChange={(e) => {setCurrentDate(e.value);
                      onDateChange("sd_RQ_DT", index, e.value);
                      }}
                      dateFormat="dd/mm/yy"
                    />

                    </td>
                    <td style={{display:"none"}}>
                      <Calendar style={{width:"10rem"}}
                        id={`sd_PR_DT_${index}`}
                        name={`sd_PR_DT_${index}`}
                        value={row.sd_PR_DT? moment(row.sd_PR_DT, "YYYYMMDD").toDate(): null}
                        onChange={(e) => onDateChange({ target: { name: "sd_PR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                    </td>
                    <td>
                      <InputText
                        style={{ width: "10rem" }}
                        id={`sd_FR_PR_${index}`}
                        name={`sd_FR_PR_${index}`}
                        defaultValue={row.sd_FR_PR ? row.sd_FR_PR : row[`${"sd_FR_PR"}_${index}`]}
                        onChange={(e) => onchangeFormUnitPrice(e,index)}
                      />
                    </td>
                    
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`sd_FR_AT_${index}`}
                        name={`sd_FR_AT_${index}`}
                        defaultValue={row.sd_FR_AT ? row.sd_FR_AT : row[`${"sd_FR_AT"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`sd_FR_DS_${index}`}
                        name={`sd_FR_DS_${index}`}
                        defaultValue={row.sd_FR_DS ? row.sd_FR_DS : row[`${"sd_FR_DS"}_${index}`]}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`fr_NET_AMOUNT_${index}`}
                        name={`fr_NET_AMOUNT_${index}`}
                        defaultValue={row.fr_NET_AMOUNT ? row.fr_NET_AMOUNT : row[`${"fr_NET_AMOUNT"}_${index}`]}
                      />
                    </td>
                    <td>
                     
                      <InputText
                        style={{ width: "7rem" }}
                        id={`sd_TX_AP_${index}`}
                        name={`sd_TX_AP_${index}`}
                        defaultValue={row.sd_TX_AP ? row.sd_TX_AP : row[`${"sd_TX_AP"}_${index}`]}
                        onChange={(e) => onchangeOfTaxAplMul(e,index)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`sd_TX_PE_${index}`}
                        name={`sd_TX_PE_${index}`}
                        defaultValue={row.sd_TX_PE ? row.sd_TX_PE : row[`${"sd_TX_PE"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_TX_AM_${index}`}
                        name={`sd_TX_AM_${index}`}
                        defaultValue={row.sd_TX_AM ? row.sd_TX_AM : row[`${"sd_TX_AM"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* unit cost */}
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_UT_CT_${index}`}
                        name={`sd_UT_CT_${index}`}
                        defaultValue={row.sd_UT_CT ? row.sd_UT_CT : row[`${"sd_UT_CT"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* cost amt */}
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`cost_AMOUNT_${index}`}
                        name={`cost_AMOUNT_${index}`}
                        defaultValue={row.cost_AMOUNT ? row.cost_AMOUNT : row[`${"cost_AMOUNT"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* margin % */}
                    <td>
                      <InputText disabled
                        style={{ width: "8rem" }}
                        id={`sd_MR_PR_${index}`}
                        name={`sd_MR_PR_${index}`}
                        defaultValue={row.sd_MR_PR ? row.sd_MR_PR : row[`${"sd_MR_PR"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* margin amt */}
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_MR_AT_${index}`}
                        name={`sd_MR_AT_${index}`}
                        defaultValue={row.sd_MR_AT ? row.sd_MR_AT : row[`${"sd_MR_AT"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* ava qty */}
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`is_AV_QT_${index}`}
                        name={`is_AV_QT_${index}`}
                        defaultValue={row.is_AV_QT ? row.is_AV_QT : row[`${"is_AV_QT"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    {/* lum sum dis. */}
                    <td>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_HD_DS_${index}`}
                        name={`sd_HD_DS_${index}`}
                        defaultValue={row.sd_HD_DS ? row.sd_HD_DS : row[`${"sd_HD_DS"}_${index}`]}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>

                    <td>
                    <InputText disabled
                        style={{ width: "8rem" }}
                        id={`sd_ST_CD_${index}`}
                        name={`sd_ST_CD_${index}`}
                        value={ row.sd_ST_CD ? row.sd_ST_CD : sd_ST_CD}
                      />
                    </td>
                    <td style={{display:"none"}}>
                      <InputText disabled
                        style={{ width: "7rem" }}
                        id={`sd_CO_CT_${index}`}
                        name={`sd_CO_CT_${index}`}
                        value={sd_CO_CT}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div style={inboxstyle} >
        <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              value={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Discount
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              value={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              value={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              value={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="TOTAL" className="font-bold">
              Total
            </label>
            <InputText disabled
              id="total"
              name="total"
              value={total}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore.Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              value={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_DISCOUNT_AMOUNT" className="font-bold">
              Fore.Discount
            </label>
            <InputText disabled
              id="fr_DISCOUNT_AMOUNT"
              name="fr_DISCOUNT_AMOUNT"
              value={fr_DISCOUNT_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_NET_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="fr_NET_AMOUNT"
              name="fr_NET_AMOUNT"
              value={fr_NET_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="FR_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              value={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_TOTAL" className="font-bold">
              Fore.Total
            </label>
            <InputText disabled
              id="fr_TOTAL"
              name="fr_TOTAL"
              value={fr_TOTAL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
      </div>
     
            </TabPanel>
            <TabPanel  header="Additional Information" className="tab-header">
            <div  style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_TX_TY" className="font-bold">
                    Tax Type
                </label>
                <Dropdown
                    id="sh_TX_TY"
                    name="sh_TX_TY"
                    value={sh_TX_TY}
                    options={sh_TX_TYOptions}
                    onChange={(e) => onchangeDropDownFormData8("sh_TX_TY", e.value)}
                    placeholder="Select Tax Type"
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_TX_AR" className="font-bold">
                    Tax Area
                </label>
                <Dropdown
                    id="sh_TX_AR"
                    name="sh_TX_AR"
                    value={sh_TX_AR}
                    options={sh_TX_AROptions}
                    onChange={(e) => onchangeDropDownFormData9("sh_TX_AR", e.value)}
                    placeholder="Select Tax Area"
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_AS_TO" className="font-bold">
                    Assigned To
                  </label>
                  <Dropdown
                    id="sh_AS_TO"
                    name="sh_AS_TO"
                    value={sh_AS_TO}
                    options={sh_AS_TOOptions}
                    onChange={(e) => onchangeDropDownFormData10("sh_AS_TO", e.value)}
                    placeholder="Select Assigned To"
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_RE_MK" className="font-bold">
                    Remarks
                </label>
                <InputText
                  id="sh_RE_MK"
                  name="sh_RE_MK"
                  value={sh_RE_MK}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_BA_AC" className="font-bold">
                    Bank Account<span style={{color:"red"}}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="bank_NAME"
                    name="bank_NAME"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={bank_NAME}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openBankPopup}
                  ></i>
                  )}
                </div>
                <InputText style={{display:"none"}}
                  id="sh_BA_AC"
                  name="sh_BA_AC"
                  value={sh_BA_AC}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_RF_NO" className="font-bold">
                    Enquiry Reference
                </label>
                <InputText
                  id="sh_RF_NO"
                  name="sh_RF_NO"
                  value={sh_RF_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_PR_RF" className="font-bold">
                    Project Reference
                </label>
                <InputText
                  id="sh_PR_RF"
                  name="sh_PR_RF"
                  value={sh_PR_RF}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_CO_PE" className="font-bold">
                    Contact Person
                </label>
                <InputText
                  id="sh_CO_PE"
                  name="sh_CO_PE"
                  value={sh_CO_PE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_DL_DT" className="font-bold">
                    Delivery Details
                </label>
                <InputText
                  id="sh_DL_DT"
                  name="sh_DL_DT"
                  value={sh_DL_DT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_HD_CD" className="font-bold">
                      Hold Code
                  </label>
                  <InputText
                    id="sh_HD_CD"
                    name="sh_HD_CD"
                    value={sh_HD_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_VL_TY" className="font-bold">
                      Validity
                  </label>
                  <InputText
                    id="sh_VL_TY"
                    name="sh_VL_TY"
                    value={sh_VL_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sh_LD_TM" className="font-bold">
                      Lead Time
                  </label>
                  <InputText
                    id="sh_LD_TM"
                    name="sh_LD_TM"
                    value={sh_LD_TM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sd_CN_DT" className="font-bold">
                  Cancelled Date
                  </label>
                  <Calendar
                    id="sd_CN_DT"
                    name="sd_CN_DT"
                    value={moment(data.sd_CN_DT, "YYYYMMDD").isValid() ? moment(data.sd_CN_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "sd_CN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
            </div>
          </div>
            </TabPanel>
          </TabView>
    </div>

      {/* CUSTOMER */}
       <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* ship to */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeSalesPerson}
        setFormData={setFormData}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
        {/* sales person */}
        <AddressBookPopup
        visible={SalespersonPopupVisible}
        onClose={() => setSalespersonPopupVisible(false)}
        onDataSelect={handleSelectSalesPerson}
        formData={data}
        addressType={addressTypeSalesPerson}
        setFormData={setFormData}
      />
      {/* BANK ACOUNT */}
      <BankAccountPopup
        visible={BankPopupVisible}
        onClose={() => setBankPopupVisible(false)}
        onDataSelect={handleSelectBank}
        formData={data}
      />
    </Dialog>
  );
}
