import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import axios from "axios";
import LeasePopup from "../Popups/LeasePopup";
import UnitPopup from "../Popups/UnitPopup";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ManualBillingDialog({
  open,
  handleClose,
  data,
  setFormData,
  handleFormSubmit,
  handleConfirm,
  onchangeFormData,
  addRow,
  deleteRow,
  ManualBillingtable,
  setManualBillingtable,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  be_BS_CR,
  be_DO_TY,
  be_DO_CO,
  be_UN_TS,
  be_PY_TR,
  be_TR_CR,
  be_IN_DT,
  payee,
  setpayename,
  be_FL_NO,
  be_BL_NO,
  bu_DESC,
  be_PR_NO,
  pr_DESC,
  // be_LE_TY,
  be_DO_NO,
}) {
  const { 
  be_UK_NO,
  be_LE_TY,
  beLeNo,
  be_TE_NT,
  tenant_NAME,
  be_EX_RT,
  be_PA_NO,
  payee_NAME,
  be_RF_CE,
  be_GL_DT,
  be_DU_DT,
  be_FR_DT,
  be_LE_CO,
  be_TO_DT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,
    cancelstyle,confirmstyle,Dialogstyle} = ButtonStyles();
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange9 = (name, value) => {
    const i = name.split('_')[3];
    onchangeDropDownFormData9(name, value, i);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange6 = (name, value,e) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const handleFormDataChange4 = (name, value) => {
    onchangeDropDownFormData4(name, value);
  };
  const handleFormDataChange5 = (name, value) => {
    onchangeDropDownFormData5(name, value);
  };
  const onchangedata = (e) => {
    const { name, value } = e.target;
    onchangeFormData(name, value);
  };
  const onChangeDetails = (e) => {
    const { name, value } = e.target;
    const i = name.split('_')[3];
    const table = document.getElementById("ManualBillingtable");
    const exData={
      "be_TR_CR": be_TR_CR,
      "be_BS_CR": document.getElementById("be_BS_CR").value,
      "be_EX_RT": document.getElementById("be_EX_RT").value,
      "be_EX_PR": document.getElementById("be_EX_PR_"+i).value,
      "be_DS_PE": document.getElementById("be_DS_PE_"+i).value,
      "be_TX_PE": table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
      "row_NUM": i,
      "be_FR_AM": document.getElementById("be_FR_AM_"+i).value,
    }
    axios.post(`${domain}/api/ManualBilling/GetPriceDetailsForManualBilling`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const result = resp.data.result;
        if (result) {
          document.getElementById("be_EX_PR_"+i).value = result.be_EX_PR;
          document.getElementById("be_DS_AM_"+i).value = result.be_DS_AM;
          document.getElementById("be_TX_AM_"+i).value = result.be_TX_AM;
          document.getElementById("be_FR_DS_"+i).value = result.be_FR_DS;
          document.getElementById("be_FR_TX_"+i).value = result.be_FR_TX;
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchPayTerm } = PayTerm();
  const [be_BL_CDOptions, setbe_BL_CDOptions] = useState([]);
  const [be_TX_PEOptions, setbe_TX_PEOptions] = useState([]);
  const [be_PY_TROptions, setbe_PY_TROptions] = useState([]);
  const [be_TR_CROptions, setbe_TR_CROptions] = useState([]);
  const [be_TX_APOptions, setbe_TX_APOptions] = useState([]);

  useEffect(() => {
    fetchpay();
    fetchtranscurr();
    fetchBillCode();
    fetchtaxapplicable();
    fetchtaxpercentage();
  }, []);

  const fetchpay = async () => {
    const Options = await fetchPayTerm();
    setbe_PY_TROptions(Options);
  };
  const fetchtranscurr = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbe_TR_CROptions(Options);
  };
  const fetchBillCode = async () => {
    const Options = await fetchClassCodeDS("BL/CD");
    setbe_BL_CDOptions(Options);
  };
  const fetchtaxapplicable = async () => {
    const Options = await fetchClassCodeDS("TX/AP");
    setbe_TX_APOptions(Options);
  };
  const fetchtaxpercentage = async () => {
    const Options = await fetchClassCodeDS("TX/PE");
    setbe_TX_PEOptions(Options);
  };
  const [LeaseNoPopupVisible, setLeaseNoPopupVisible] = useState(false);
  const openLeaseNoPopup = () => {
    setLeaseNoPopupVisible(true);
  };
  const handleDataSelect = async (data) => {
    document.getElementById("beLeNo").value =  data.liLeNo;
    document.getElementById("be_LE_TY").value =  data.li_LE_TY;
    document.getElementById("be_LE_CO").value =  data.li_LE_CO;
    document.getElementById("be_BS_CR").value =  data.li_BS_CR;
    document.getElementById("be_TE_NT").value =  data.li_TE_NT;
    document.getElementById("tenant_NAME").value =  data.teanat_NAME;
    // document.getElementById("be_PA_NO").value =  data.li_PA_NO;
    // document.getElementById("payee_NAME").value =  data.payee_NAME;
    try {
      const response = await axios.get(
        `${domain}/api/LeaseInformation/Select/${data.li_UK_NO}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const LeaseInformationData = response.data.leaseInformations;
        const led = [];
        const size = LeaseInformationData.length;
        for(let i = 0 ;  i < size ; i++) {
        const s = {
          "be_UN_TS" : data.li_UN_TS,
          "be_FL_NO" : data.li_FL_NO,
          "be_BL_NO" : data.li_BL_NO,
          "be_PR_NO" : data.li_PR_NO,
          "pr_DESC"  : LeaseInformationData[0].pr_DESC,
          "bu_DESC"  : LeaseInformationData[0].bu_DESC,
        }
        led.push(s);
        }
        setManualBillingtable(led);
    
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
// customer, pay term, currency
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
      "cu_CU_NO": data.li_TE_NT
    }
    try {
      axios.post(Url, requestData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              const dataCuMt = resp.data.Result;
              if (dataCuMt) {
                onchangeDropDownFormData4("be_PY_TR", dataCuMt.cu_PT_TM);
                onchangeDropDownFormData5("be_TR_CR", dataCuMt.cu_CR_CD);

                const due={
                  "pt_PY_CO": dataCuMt.cu_PT_TM,
                  "inp_DATE": be_IN_DT
                }

                axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "http://localhost:3000",
                        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                      },
                    })
                    .then((resp) => {
                      const dueDate = resp.data.inp1;
                      if (dueDate) {
                        onchangeFormData("be_DU_DT", dueDate.out_DATE);

                        const exData={
                          "cr_TO_CR": data.li_BS_CR,
                          "cr_FR_CR": dataCuMt.cu_CR_CD,
                          "cr_EF_DT": be_IN_DT
                        }
                        exchangeRate (exData);
                      }
                    })
                    .catch((error) => {
                      console.error("Error saving data:", error);
                    });
              }
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLeaseNoPopupVisible(false);
    setShowSearchIcon(false);
  };
  function exchangeRate (exData){
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("be_EX_RT").value = dataExRt.cr_CO_MR;
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [UnitPopupVisible, setUnitPopupVisible] = useState(false);
  const openUnitPopup = (e) => {
    setUnitPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (content) => {
    document.getElementById (`be_UN_TS_${SelectedRowIndex}`).value = content.ui_UN_NO;
    document.getElementById (`be_FL_NO_${SelectedRowIndex}`).value =  content.ui_FL_NO;
    document.getElementById (`be_BL_NO_${SelectedRowIndex}`).value =  content.ui_BL_NO;
    document.getElementById (`bu_DESC_${SelectedRowIndex}`).value =  content.bu_DESC;
    document.getElementById (`be_PR_NO_${SelectedRowIndex}`).value =  content.ui_PR_NO;
    document.getElementById (`pr_DESC_${SelectedRowIndex}`).value =  content.pr_DESC;
    setUnitPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [payeeVisible, setpayeeVisible] = useState(false);
  const openPayeePopup = () => {
    setpayeeVisible(true);
  };
  const [addressTypeC, setaddressTypeC] =useState("C");
  const handleDataSelect2 = (data) => {
    setpayename( data.ab_AD_NM);
    onchangeFormData("be_PA_NO", data.abAdNo);
    setpayeeVisible(false);
    setShowSearchIcon(false);
  };
const isUpdateMode = Boolean(be_UK_NO);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{be_UK_NO ? "Revise Manual Billing" : "Add Manual Billing"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end',}}>
        <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit} 
             tooltip="Save"
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        {isUpdateMode && (
          <Button 
            style={confirmstyle}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
        )}
      </div>
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_DO_NO" className="font-bold">
                Invoice Number
                </label>
                <InputText disabled
                  id="be_DO_NO"
                  name="be_DO_NO"
                  value={be_DO_NO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_DO_TY" className="font-bold">
                Invoice Type
                </label>
                <InputText disabled
                  id="be_DO_TY"
                  name="be_DO_TY"
                  defaultValue={be_DO_TY}
                /> 
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                <label htmlFor="be_DO_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="be_DO_CO"
                  name="be_DO_CO"
                  value={be_DO_CO}
                />
              </div> 
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="beLeNo" className="font-bold">
                    Lease Number <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                <InputText
                  id="beLeNo"
                  name="beLeNo"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={beLeNo}
                />
                {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={openLeaseNoPopup}
                ></i>
                )}
                </div>
              </div> 
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_LE_TY" className="font-bold">
                Lease Type
                </label>
                <InputText disabled
                  id="be_LE_TY"
                  name="be_LE_TY"
                  defaultValue={be_LE_TY}
                />
              </div> 
              <div style={{ display:"none" }} >
                <label htmlFor="be_LE_CO" className="font-bold">
                  Lease Company
                </label>
                <InputText
                  id="be_LE_CO"
                  name="be_LE_CO"
                  defaultValue={be_LE_CO}
                />
              </div> 
              <div style={{ display: "none" }} >
                <label htmlFor="be_TE_NT" className="font-bold">
                  Tenant 
                </label>
                <InputText disabled
                  id="be_TE_NT"
                  name="be_TE_NT"
                  defaultValue={be_TE_NT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>     
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="tenant_NAME" className="font-bold">
                Tenant 
                </label>
                <InputText disabled
                  id="tenant_NAME"
                  name="tenant_NAME"
                  defaultValue={tenant_NAME}
                  readOnly
                />
              </div> 
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_FR_DT" className="font-bold">
                From Date
                </label>
                <Calendar
                  id="be_FR_DT"
                  name="be_FR_DT"
                  value={moment(data.be_FR_DT, "YYYYMMDD").isValid() ? moment(data.be_FR_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "be_FR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_TO_DT" className="font-bold">
                To Date
                </label>
                <Calendar
                  id="be_TO_DT"
                  name="be_TO_DT"
                  value={moment(data.be_TO_DT, "YYYYMMDD").isValid() ? moment(data.be_TO_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "be_TO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>  
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_IN_DT" className="font-bold">
                Invoice Date
                </label>
                <Calendar
                  id="be_IN_DT"
                  name="be_IN_DT"
                  value={moment(data.be_IN_DT, "YYYYMMDD").isValid() ? moment(data.be_IN_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "be_IN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>         
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_PY_TR" className="font-bold">
                Pay Term
                </label>
                <Dropdown
                  name="be_PY_TR"
                  value={be_PY_TR}
                  options={be_PY_TROptions}
                  onChange={(e) => handleFormDataChange4("be_PY_TR", e.value)}
                  placeholder="Select Pay Term"
                /> 
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_GL_DT" className="font-bold">
                GL Date
                </label>
                <Calendar
                  id="be_GL_DT"
                  name="be_GL_DT"
                  value={moment(data.be_GL_DT, "YYYYMMDD").isValid() ? moment(data.be_GL_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "be_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_DU_DT" className="font-bold">
                Due Date
                </label>
                <Calendar
                  id="be_DU_DT"
                  name="be_DU_DT"
                  value={moment(data.be_DU_DT, "YYYYMMDD").isValid() ? moment(data.be_DU_DT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "be_DU_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_TR_CR" className="font-bold">
                Trans. Currency 
                </label>
                <Dropdown
                  name="be_TR_CR"
                  value={be_TR_CR}
                  options={be_TR_CROptions}
                  onChange={(e) => handleFormDataChange5("be_TR_CR", e.value)}
                  placeholder="Select Trans Currency"
                /> 
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_EX_RT" className="font-bold">
                Exchange Rate
                </label>
                <InputText disabled
                  id="be_EX_RT"
                  name="be_EX_RT"
                  value={be_EX_RT}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_BS_CR" className="font-bold">
                Base Currency
                </label>
                <InputText disabled 
                  id="be_BS_CR"
                  name="be_BS_CR"
                  value={be_BS_CR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="be_UN_TS" className="font-bold">
              Unit <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText
                  id="be_UN_TS"
                  name="be_UN_TS"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={be_UN_TS}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openUnitPopup}
                  ></i>
                  )}
            </div>
              </div> */}
              <div style={{display : "none"}} >
                <label htmlFor="be_PA_NO" className="font-bold">
                  Payee Number
                </label>
                <InputText
                  id="be_PA_NO"
                  name="be_PA_NO"
                  value={be_PA_NO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_PA_NO" className="font-bold">
                  Payee Number<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText
                  id="be_PA_NO"
                  name="be_PA_NO"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={payee}
                />
              {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openPayeePopup}
                ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_RF_CE" className="font-bold">
                  Reference 
                </label>
                <InputText
                  id="be_RF_CE"
                  name="be_RF_CE"
                  value={be_RF_CE}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="be_FL_NO" className="font-bold">
                Floor
                </label>
                <InputText disabled
                  id="be_FL_NO"
                  name="be_FL_NO"
                  value={be_FL_NO}
                  readOnly
                />
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_BL_NO" className="font-bold">
                Building
                </label>
                <InputText style={{display : "none"}}
                  id="be_BL_NO"
                  name="be_BL_NO"
                  value={be_BL_NO}
                  readOnly
                />
                <InputText disabled
                  id="bu_DESC"
                  name="bu_DESC"
                  value={bu_DESC}
                  readOnly
                />
              </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="be_PR_NO" className="font-bold">
                Property
                </label>
                <InputText style={{display : "none"}}
                  id="be_PR_NO"
                  name="be_PR_NO"
                  value={be_PR_NO}
                  readOnly
                />
                <InputText disabled
                  id="pr_DESC"
                  name="pr_DESC"
                  value={pr_DESC}
                  readOnly
                />
              </div> */}
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="ManualBillingtable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th>Unit <span style={{color:"red"}}>*</span></th>
            <th>Floor</th>
            <th style={{ display: "none" }} >Buliding</th>
            <th>Buliding</th>
            <th style={{ display: "none" }} >Property</th>
            <th>Property</th>
            <th>Billing Code</th>    
            <th>Amount</th>
            <th>Discount %</th>
            <th>Discount</th>
            <th>Tax Applicable</th>
            <th>Tax %</th>
            <th>Tax Amount</th>
            <th>Fore. Amount</th>
            <th>Fore. Dicount</th>
            <th>Fore. Tax</th>
          </tr>
        </thead>
        <tbody>
        {ManualBillingtable.map((row, index) => (
            <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`be_UK_NO_${index}`}
                name={`be_UK_NO_${index}`}
                defaultValue={row.be_UK_NO}
              />
            </td>
            <td>
            <div style={{ display: "flex" }}>
            <InputText style={{width:"10rem"}}
                id={`be_UN_TS_${index}`}
                name={`be_UN_TS_${index}`}
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.be_UN_TS ? row.be_UN_TS : row[`${'be_UN_TS'}_${index}`]}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={(e) => openUnitPopup(index)}
                ></i>
              )}
              </div>
            </td>
            <td>
              <InputText disabled style={{width:"10rem"}}
              id={`be_FL_NO_${index}`}
              name={`be_FL_NO_${index}`}
              defaultValue={row.be_FL_NO}
            />
            </td>
            <td  style={{ display: "none" }} >
              <InputText 
              id={`be_BL_NO_${index}`}
              name={`be_BL_NO_${index}`}
              value={row.be_BL_NO}
            />
            </td>
            <td>
              <InputText disabled style={{width:"10rem"}}
              id={`bu_DESC_${index}`}
              name={`bu_DESC_${index}`}
              defaultValue={row.bu_DESC}
            />
            </td>
            <td  style={{ display: "none" }} >
              <InputText
              id={`be_PR_NO_${index}`}
              name={`be_PR_NO_${index}`}
              value={row.be_PR_NO}
            />
            </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
              id={`pr_DESC_${index}`}
              name={`pr_DESC_${index}`}
              defaultValue={row.pr_DESC}
            />
            </td>
            {/* <td>
            <Dropdown
                id={`be_PR_NO_${index}`}
                name={`be_PR_NO_${index}`}
                value={data[`be_PR_NO_${index}`] ? data[`be_PR_NO_${index}`]:row.be_PR_NO }
                // value={FormData.et_SH_FT}
                options={be_PR_NOOptions}
                onChange={(e) => handleFormDataChange8(`be_PR_NO_${index}`, e.value)}
                placeholder="Select provider"
              />
            </td>*/}
              <td>
                <Dropdown style={{width:"10rem"}}
                  id={`be_BL_CD_${index}`}
                  name={`be_BL_CD_${index}`}
                  value={data[`be_BL_CD_${index}`] ? data[`be_BL_CD_${index}`]:row.be_BL_CD }
                  options={be_BL_CDOptions}
                  onChange={(e) => handleFormDataChange1(`be_BL_CD_${index}`, e.value)}
                  placeholder="Select Billing Code"
                />
              </td>
              <td>
              <InputText  style={{width:"10rem"}}
                type="number"
                disabled={be_TR_CR !== be_BS_CR}
                id={`be_EX_PR_${index}`}
                name={`be_EX_PR_${index}`}
                defaultValue={row.be_EX_PR}
                onChange={(e) => onChangeDetails(e)}
              />
              </td>
              <td>
              <InputText style={{width:"10rem"}}
                id={`be_DS_PE_${index}`}
                name={`be_DS_PE_${index}`}
                defaultValue={row.be_DS_PE}
                onChange={(e) => onChangeDetails(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`be_DS_AM_${index}`}
                name={`be_DS_AM_${index}`}
                defaultValue={row.be_DS_AM}
              />
              </td>
              <td>
              <Dropdown style={{width:"10rem"}}
                id={`be_TX_AP_${index}`}
                name={`be_TX_AP_${index}`}
                value={data[`be_TX_AP_${index}`] ? data[`be_TX_AP_${index}`]:row.be_TX_AP }
                options={be_TX_APOptions}
                onChange={(e) => handleFormDataChange9(`be_TX_AP_${index}`, e.value)}
                placeholder="Select Tax Applicable"
              />
              </td>
              <td>
                <Dropdown style={{width:"10rem"}}
                  id={`be_TX_PE_${index}`}
                  name={`be_TX_PE_${index}`}
                  value={data[`be_TX_PE_${index}`] ? data[`be_TX_PE_${index}`]:row.tax_PER }
                  options={be_TX_PEOptions}
                  onChange={(e) => handleFormDataChange6(`be_TX_PE_${index}`, e.value,e)}
                  placeholder="Select Tax %"
                />
              </td>
               <td>
                <InputText disabled style={{width:"10rem"}}
                  id={`be_TX_AM_${index}`}
                  name={`be_TX_AM_${index}`}
                  defaultValue={row.be_TX_AM}
                />
              </td>
              <td>
              <InputText style={{width:"10rem"}}
                disabled={be_TR_CR === be_BS_CR}
                id={`be_FR_AM_${index}`}
                name={`be_FR_AM_${index}`}
                defaultValue={row.be_FR_AM}
                onChange={(Event) => onChangeDetails(Event)}
              />
              </td>
              <td>
                <InputText style={{width:"10rem"}}
                  id={`be_FR_DS_${index}`}
                  name={`be_FR_DS_${index}`}
                  defaultValue={row.be_FR_DS}
                />
            </td>
            <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`be_FR_TX_${index}`}
                name={`be_FR_TX_${index}`}
                defaultValue={row.be_FR_TX}
              />
            </td>
            </tr>
            ))}
        </tbody>
      </table>
      </div>
    </div>
  </div>   
      <LeasePopup
        visible={LeaseNoPopupVisible}
        onClose={() => setLeaseNoPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        leaseType={be_LE_TY}
      />
      <UnitPopup
        visible={UnitPopupVisible}
        onClose={() => setUnitPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
      <AddressBookPopup
        visible={payeeVisible}
        onClose={() => setpayeeVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
        addressType={addressTypeC}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
