import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
export default function SupplierAdvanceDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeReceivedate,
  generate,
  ph_CR_CD,
  // ph_CR_CO,
  ph_PD_TY,
  ph_DO_CO,
  ph_PY_NO,
  ph_UK_NO,
  setph_PY_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  auto_POST,
  AutoPostDropdown
}) {
  const {
  ph_PA_AM,
  ph_PA_FR,
  ph_GL_DT,
  ph_CR_RT,
  ph_CQ_NO,
  ph_GL_BA,
  ph_AC_BL,
  name,
  ph_PM_NO,
  ph_PY_DT,
  ph_RM_EX,    
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [auto_POSTOptions, setauto_POSTOptions] = useState([]);
  const [ph_CR_COOptions, setph_CR_COOptions] = useState([]);
  const [em_EM_DSOptions, setem_EM_DSOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [bankFilter, setbankFilter] = useState("BA");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;
  const parameter2 = "ME/PL";
  const finalUrl2 = `${url}/${parameter2}`;

 const parameter3 =  "ME/PL";
 const finalUrl3 = `${url}/${parameter3}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setauto_POSTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // Currency
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // Autopost
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_EM_DSOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const generateAndSubmit = async () => {
    try {
      await handleFormSubmit();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const [addressTypeSup, setaddressTypeSup] = useState("S");
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO1(data.ab_AD_NM);
    setph_PY_NO(data.abAdNo);
    const Url = `${domain}/api/SupplierMaster/GetById`;
    const requestData={
        "su_SU_NO": data.abAdNo
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataCuMt = response.data.Result;
        if (dataCuMt) {
          onchangeDropDownFormData1("ph_CR_CO", dataCuMt.su_CR_CD);
          const exData={
            "cr_TO_CR": document.getElementById("ph_CR_CD").value, //base curr
            "cr_FR_CR": dataCuMt.ph_CR_CO, //currency
            "cr_EF_DT": data.ph_PY_DT
          }
          exchangeRate (exData);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    setSupplierPopupVisible(false);
  };
  function exchangeRate(exData) {
      axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("ph_CR_RT").value = dataExRt.cr_CO_MR;
          }
    
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO(data.am_AC_DS);
    onchangeFormData("ph_GL_BA", data.account);
    setAccountPopupVisible(false);
  };
  const isUpdateMode = Boolean(ph_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ph_UK_NO ? "Revise Supplier Advance" : "Add Supplier Advance"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  const [showSearchIcon, setShowSearchIcon] = useState("");
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle} >
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="ph_UK_NO" className="font-bold">
              UKNO
            </label>
            <InputText
              id="ph_UK_NO"
              name="ph_UK_NO"
              value={ph_UK_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_PM_NO" className="font-bold">
            Payment No
            </label>
            <InputText disabled
              id="ph_PM_NO"
              name="ph_PM_NO"
              value={ph_PM_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_PD_TY" className="font-bold">
            Type
            </label>
            <InputText disabled
              id="ph_PD_TY"
              name="ph_PD_TY"
              value={ph_PD_TY}
            />
          </div>    
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_DO_CO" className="font-bold">
            Company
          </label>
          <InputText disabled
            id="ph_DO_CO"
            name="ph_DO_CO"
            value={ph_DO_CO}
          />
        </div>  
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
        <label htmlFor="ph_PY_DT" className="font-bold">
        Payment Date
        </label>
        <Calendar
          id="ph_PY_DT"
          name="ph_PY_DT"
          value={moment(data.ph_PY_DT, "YYYYMMDD").isValid() ? moment(data.ph_PY_DT, "YYYYMMDD").toDate() : null}
          onChange={(e) => onchangeReceivedate({ target: {name: "ph_PY_DT",value: moment(e.value).format("YYYYMMDD"), }, }) }
          dateFormat="dd/mm/yy"
        /> 
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_GL_DT" className="font-bold">
          GL Date
          </label>
          <Calendar
            id="ph_GL_DT"
            name="ph_GL_DT"
            value={moment(data.ph_GL_DT, "YYYYMMDD").isValid() ? moment(data.ph_GL_DT, "YYYYMMDD").toDate() : null}
            onChange={(e) => onchangedata({ target: { name: "ph_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />    
        </div>
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
        <label htmlFor="ph_PY_NO" className="font-bold">
        Supplier  <span style={{ color: 'red' }}>*</span>
        </label>
        <div style={{ display: "flex" }}>
          <InputText
            id="name"
            name="name"
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={SelectedemEMNO1}
          />
            {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openSupplierPopup}
          ></i>
          )}
        </div>
        <div style={{ display: "none" }}>
          <InputText
            id="ph_PY_NO"
            name="ph_PY_NO"
            value={ph_PY_NO}
            onChange={(Event) => onchangedata(Event)}
          />
        </div>
            </div>  
      
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_CR_CO" className="font-bold">
              Currency
              </label>
              <Dropdown
                id="ph_CR_CO"
                name="ph_CR_CO"
                value={data.ph_CR_CO}
                options={ph_CR_COOptions}
                onChange={(e) => onchangeDropDownFormData1("ph_CR_CO", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_CR_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
                id="ph_CR_RT"
                name="ph_CR_RT"
                value={ph_CR_RT}
              readOnly
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_CR_CD" className="font-bold">
              Base Currency
              </label>
              <InputText disabled
                id="ph_CR_CD"
                name="ph_CR_CD"
                value={ph_CR_CD}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_GL_BA" className="font-bold">
              Bank Account <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="am_AC_DS"
                  name="am_AC_DS"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                  {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="ph_GL_BA"
                  name="ph_GL_BA"
                  value={ph_GL_BA}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="ph_PA_AM" className="font-bold">
          Amount
          </label>
            <InputText 
              id="ph_PA_AM"
              name="ph_PA_AM"
              value={data.ph_CR_CO !== ph_CR_CD ? 0 : ph_PA_AM}
              onChange={(e) => onchangedata(e)}
              disabled={data.ph_CR_CO !== ph_CR_CD}
            />
          </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PA_FR" className="font-bold">
              Foreign Amount
              </label>
              <InputText 
                id="ph_PA_FR"
                name="ph_PA_FR"
                value={data.ph_CR_CO === ph_CR_CD ? 0 : ph_PA_FR}
                onChange={(e) => onchangedata(e)}
                disabled={data.ph_CR_CO === ph_CR_CD}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_CQ_NO" className="font-bold">
              NFT/Cheque
              </label>
              <InputText
                id="ph_CQ_NO"
                name="ph_CQ_NO"
                defaultValue={ph_CQ_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_RM_EX" className="font-bold">
              Supplier Invoice
              </label>
              <InputText
                id="ph_RM_EX"
                name="ph_RM_EX"
                defaultValue={ph_RM_EX}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="auto_POST" className="font-bold">
                    Auto Post
                  </label>
                  <Dropdown
                    id="auto_POST"
                    name="auto_POST"
                    value={auto_POST}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => AutoPostDropdown("auto_POST", e.value)}
                    placeholder="Select Auto Post"
                  />
                </div>
          </div>
        </div>
      </div>    
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
        <AddressBookPopup
          visible={SupplierPopupVisible}
          onClose={() => setSupplierPopupVisible(false)}
          onDataSelect={handleDataSelect}
          formData={data}
          addressType={addressTypeSup}
          setFormData={setFormData}
          bankFilter={bankFilter}
        />
    </Dialog>
  );
}
