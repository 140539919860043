import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function RoleCreationDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  RoleCreationTableData,
  setRoleCreationTableData,
  enabled,
  setenabled,
 
}) {
  const {
    id,
  } = data;
  const {borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeCD } = ClassCode();
  const [enabledOptions, setenabledOptions] = useState([]);


  // enabled
  const EnabledDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setenabled(value);
  };




  useEffect(() => {
    Enabled();

  }, []);


  const Enabled = async () => {
    const Options = await fetchClassCodeCD("FO/EN");
    setenabledOptions(Options);
  };




  const addRow = () => {
    setRoleCreationTableData([...RoleCreationTableData, {}]);
  };

  const deleteRow = () => {
    if (RoleCreationTableData.length > 0) {
      const updatedData = [...RoleCreationTableData];
      updatedData.pop();
      setRoleCreationTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(id);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{id ? "Revise Role Creation" : "Add Role Creation"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="RoleCreationtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Role</th>
                  <th>Description</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                {RoleCreationTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`id_${index}`}
                        name={`id_${index}`}
                        value={row.id}
                      />
                    </td>
                    <td >
                      <InputText 
                        id={`role_${index}`}
                        name={`role_${index}`}
                        defaultValue={row.role}
                        onChange={(e) => onchangedata(e)}
                        
                      />
                    </td>
                    <td >
                      <InputText 
                        id={`description_${index}`}
                        name={`description_${index}`}
                        defaultValue={row.description}
                        onChange={(e) => onchangedata(e)}
                        
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`enabled_${index}`}
                        name={`enabled_${index}`}
                        value={data[`enabled_${index}`] ? data[`enabled_${index}`] : row.ep_LO_CN}
                        options={enabledOptions}
                        onChange={(e) => EnabledDropdown(`enabled_${index}`, e.value)}
                        placeholder="Enabled"
                        
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
