import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Pagination from "views/common/Pagination";
import ButtonStyles from "views/common/ButtonStyles";
import '../../common/TabStyles.css';
const initialFilters = {
    cd_CS_CD: { value: "", operation: "" },
    cd_CS_NM: { value: "", operation: "" },
    cd_UN_TS: { value: "", operation: "" },
    cd_EF_FR: { value: "", operation: "" },
    cd_EF_TO: { value: "", operation: "" },
    cd_CO_ST: { value: "", operation: "" },
    cd_CS_PR: { value: "", operation: "" },
};
const CompetencyDetailsPopup = ({ visible, onClose, onDataSelect}) => {
  const [tableData, setTableData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const toast = useRef(null);
  const {PopupStyle} = ButtonStyles();

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'abAdNo') {
          constructedColumn = 'abAdNo';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  // useEffect(() => {
  //   setOpen(visible);
  //   handleSearch();
  //   setCurrentPage(0);
  // }, [currentPage, pageSize, filters, visible]);
  useEffect(() => {
    if (visible) {
      setOpen(true);
      setCurrentPage(0);
    } else {
      setOpen(false); 
    }
  }, [visible]);
  
  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open, currentPage, pageSize, filters]);
  
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const Url = `${domain}/api/CompetencyDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(Url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  return (
    <Dialog id="dialog-popup-style"
      visible={open}
      onHide={handleClose}
      style={PopupStyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Competency Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["cd_CO_CD"] ? filters["cd_CO_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_CO_CD",
                        e.target.value,
                        filters["cd_CO_CD"]
                          ? filters["cd_CO_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_CO_TY"] ? filters["cd_CO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_CO_TY",
                        e.target.value,
                        filters["cd_CO_TY"]
                          ? filters["cd_CO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cd_LV_EL"] ? filters["cd_LV_EL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cd_LV_EL",
                        e.target.value,
                        filters["cd_LV_EL"]
                          ? filters["cd_LV_EL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Competency Code</th>
                <th style={tableHeaderStyle}>Competency Type</th>
                <th style={tableHeaderStyle}>Level</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                      key={rowData.cdUkNo}
                      onClick={() => onDataSelect(rowData)}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "aliceblue" : "#ffffff",
                      }}
                      className="table-row"
                    >
                    <td style={tableCellStyle}>{rowData.cd_CO_CD}</td>
                    <td style={tableCellStyle}>{rowData.type}</td>
                    <td style={tableCellStyle}>{rowData.levelDES}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
        />    
      </div>
    </Dialog>
  );
};

CompetencyDetailsPopup.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default CompetencyDetailsPopup;
