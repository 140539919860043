import React,{ useState,useEffect, useRef } from 'react'
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const CreateFolderPopup = ({
    handleCreateFolder,
    visible,
    onClose,
}) => {

  const valuenames = {
    Folder_name:"",
  }
  const [FormData, setFormData] = useState(valuenames);


  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (visible) {
        setFormData(valuenames);
    }
  }, [visible]);

  return (
    <Dialog style={{width:'20rem',height:'20rem'}}
        visible={visible}
        onHide={onClose}
    >
      <div className="field col-lg-6 col-md-6 col-12 pe-md-2">
        <label htmlFor="Folder_name" className="font-bold">
            Folder Name
        </label>
        <InputText
            id="Folder_name"
            name="Folder_name"
            value={FormData.Folder_name}
            onChange={(e) => onchangedata(e)}
        />
      </div> 
    {/* buttons */}
      <div className="flex justify-content-between mt-4">
        <Button 
          label="Create Folder" 
          onClick={handleCreateFolder} 
          className="p-button-primary"
        />
        <Button 
          label="Cancel" 
          onClick={onClose} 
          className="p-button-secondary"
        />
      </div>

    </Dialog>
  )
}

export default CreateFolderPopup