import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue, Collapse } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

export function SidebarLinks(props) {
  const location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("darkModeValue", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes, onItemClick, isLoggedIn } = props;
  const activeRouteName = location.pathname.toString().substring(6, location.pathname.length);

  const [openCategories, setOpenCategories] = useState([]);

  const findParentCategories = (routes, path, parents = []) => {
    for (let route of routes) {
      if (route.category && route.items) {
        const found = route.items.some(item => item.path === path || findParentCategories(route.items, path, parents));
        if (found) {
          parents.push(route.name);
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    const parents = [];
    findParentCategories(routes, activeRouteName, parents);
    setOpenCategories(parents);
  }, [isLoggedIn,location, routes]);

  const toggleCategory = (categoryName) => {
    setOpenCategories(prev => 
      prev.includes(categoryName) ? prev.filter(name => name !== categoryName) : [...prev, categoryName]
    );
  };

  const isCategoryOpen = (categoryName) => openCategories.includes(categoryName);

  const activeRoute = (routeName) => {
    return activeRouteName === routeName;
  };

  const handleItemClick = () => {
    if (onItemClick) {
      onItemClick();
    }
  };


  const createLinks = (routes, level = 0) => {
    return routes.map((route, index) => {
      if (route.category) {
        const canOpen = isCategoryOpen(route.name);
        return (
          <div key={index} style={{ marginLeft: `${level * 10}px` }}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{ sm: "10px",xl: "16px", }}
              pt="18px"
              pb="12px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={() => toggleCategory(route.name)}
              cursor="pointer"
            >
              <span>{route.name}</span>
              {(canOpen ? <ChevronDownIcon /> : <ChevronUpIcon />)}
            </Text>
            <Collapse in={canOpen}>
              <div>{createLinks(route.items, level + 1)}</div>
            </Collapse>
          </div>
        );
      } else if (route.layout === "/admin") {
        const isActive = activeRoute(route.path);

        return (
          <div key={index} style={{ marginLeft: `${level * 0}px` }}>   {/* 4th level */}
         
            <NavLink
              to={route.layout + route.path}
              style={{
                textDecoration: "none",
                color: isActive ? activeColor : textColor,
                fontWeight: isActive ? "bold" : "normal",
                //marginLeft: `${level * 10}px` // Adjust the margin as needed
              }}
              onClick={handleItemClick}
            >
              <Box>
                <HStack
                  spacing={isActive ? "22px" : "26px"}
                  py="5px"
                  ps="10px"
                >
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box color={isActive ? activeIcon : textColor} me="18px">
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={isActive ? activeColor : textColor}
                      fontWeight={isActive ? "bold" : "normal"}
                    >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h="36px"
                    w="4px"
                    bg={isActive ? brandColor : "transparent"}
                    borderRadius="5px"
                  />
                </HStack>
              </Box>
            </NavLink>
          </div>
        );
      }
    });
  };
  return createLinks(routes);
}
export default SidebarLinks;
