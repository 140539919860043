import React, { useState, useRef, useEffect } from 'react';
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";


const CreateFolderPopup = ({ isOpen, onClose, onCreateFolder,newMainFolderName,setnewMainFolderName }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [error, setError] = useState('');
  const [folderName, setFolderName] = useState('');
  const [SubFolderName, setSubFolderName] = useState('');
  const [OtherFolderName, setOtherFolderName] = useState('');
  // const [ setNewMainFolderName] = useState('');
  const [FormData, setFormData] = useState("");
  const toast = useRef(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [newMainFolderNameOptions, setnewMainFolderNameOptions] = useState([]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(typeof onCreateFolder);
    if (newFolderName.trim() !== '') {
      onCreateFolder(newFolderName.trim());
      setNewFolderName('');
    } else {
      toast.current.show({ severity: "warn", summary: "Warning", detail: "Folder name cannot be empty." });
    }
  };

  const handleFolderNameChange = (value) => {
    setnewMainFolderName(value); // Update the folder name state
  };

  const onchangeDropDownFormData3 = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setnewMainFolderName(value);
  };


  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  const urlcost = `${domain}/api/MainFolder/DropDown`;
  const finalUrl = `${urlcost}`;

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.mf_SF_NM,
        value: item.mf_SF_NM,
      }));
      setnewMainFolderNameOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  return (
    isOpen && (

      <div className="popup-overlay">
        <Toast ref={toast} />
        <div className="popup-content">
          <h2>Create New Folder</h2>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="newMainFolderName" className="font-bold">
            Main Folder
            </label>
            <Dropdown 
              id="newMainFolderName"
              name="newMainFolderName"
              value={newMainFolderName}              
              options={newMainFolderNameOptions}
              onChange={(e) => onchangeDropDownFormData3("newMainFolderName", e.value)}
              placeholder="Enter Folder Name"
            />
          </div> 
         

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="SubFolderName" className="font-bold">
              Creation Sub folder
            </label>
            <InputText style={{ width: "10rem" }}
              id="SubFolderName"
              name="SubFolderName"
              defaultValue={SubFolderName}
              placeholder="Enter Folder Name"
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="OtherFolderName" className="font-bold">
              Creation Other folder
            </label>
            <InputText style={{ width: "10rem" }}
              id="OtherFolderName"
              name="OtherFolderName"
              defaultValue={OtherFolderName}
              placeholder="Enter Folder Name"
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          </div>
          <form onSubmit={handleSubmit}>
            {error && <div className="error-message">{error}</div>}
            <button type="submit">Create</button>
            <button type="button" onClick={onClose} aria-label="Cancel creation of folder">Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default CreateFolderPopup;
