import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "../Popups/AddressBookPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function InspectionDetailsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  Inspectiondetailstable,
  setInspectiondetailstable,
  SelectedEquipment,
  setSelectedEquipment,
  SelectedCustomer,
  setSelectedCustomer,
  SelectedInspection,
  setSelectedInspection,
  id_CO_CT,
  setid_CO_CT,
  id_TX_AP,
  setid_TX_AP,
  id_TR_UM,
  setid_TR_UM,
  id_TR_NO,
  id_TR_TY,
  id_DO_CO,
  onchangeDropdownData1,
  SelectedParts,
  setSelectedParts
}) {
  const {
    idUkNo,
    id_EQ_NM,
    id_CU_NO,
    id_TR_DT,
    id_RG_NO,
    id_DS_PE,
    id_DS_AM,
    id_IN_NM,
    id_PA_NO,
    // footer
    material_COST,
    labour_COST,
    discount,
    total_COST,
    tax_AMOUNT,
    total,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle,Dialogstyle
  } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [id_CO_CTOptions, setid_CO_CTOptions] = useState([]);
  const [id_TX_APOptions, setid_TX_APOptions] = useState([]);
  const [id_TR_UMOptions, setid_TR_UMOptions] = useState([]);
  // Tax Applicable
  const onchangeDropdownData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setid_TX_AP(value);
  };
 // UM
  const onchangeDropDownFormData3 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setid_TR_UM(value);
  };
  useEffect(() => {
    Costcentre();
    TaxApplicable();
    fetchUM();
  }, []);
  const Costcentre = async () => {
    const Options = await fetchCostCenter();
    setid_CO_CTOptions(Options);
  };
  const TaxApplicable = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setid_TX_APOptions(Options);
  };
  const fetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setid_TR_UMOptions(Options);
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [EquipmentPopupVisible, setEquipmentPopupVisible] = useState(false);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [InspectionPopupVisible, setInspectionPopupVisible] = useState(false);
  const [PartsPopupVisible, setPartsPopupVisible] = useState(false);
  const [addresstypeCust, setaddresstypeCust] = useState("C");
  const openEquipmentPopup = () => {
    setEquipmentPopupVisible(true);
  };
  const handleSelectEquipment = async (data) => {
    setSelectedEquipment(data.em_EQ_DS);
    onchangeFormData("id_EQ_NM", data.em_EQ_NM);
    const Url = `${domain}/api/EquipmentMaster/GetByEquipmentName`;
    const requestData={
      "em_EQ_NM":data.em_EQ_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataEQ = response.data.result;
      if (dataEQ) {
        // onchangeFormData("sl_AD_L1", dataLead.ab_AD_L1);
        document.getElementById("id_CU_NO").value = dataEQ.em_CU_NO;
        document.getElementById("SelectedCustomer").value = dataEQ.customer_NAME;
        // onchangeDropdownData2("id_TX_AP", dataEQ.ab_AD_CT);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEquipmentPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const handleSelectCustomer = async (data) => {
    setSelectedCustomer(data.ab_AD_NM);
    onchangeFormData("id_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openInspectionPopup = (e) => {
    setInspectionPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectInspection = (data) => {
    setSelectedInspection(data.im_IT_DS);
    document.getElementById(`id_IN_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`SelectedInspection_${SelectedRowIndex}`).value = data.im_IT_DS;
    setInspectionPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openPartsPopup = (e) => {
    setPartsPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectParts = async (data) => {
    setSelectedParts(data.im_IT_DS);
    document.getElementById(`id_PA_NO_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`SelectedParts_${SelectedRowIndex}`).value = data.im_IT_DS;
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":data.im_IT_NM
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        onchangeDropDownFormData3(`id_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setPartsPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setInspectiondetailstable([...Inspectiondetailstable, {}]);
  };
  const deleteRow = () => {
    if (Inspectiondetailstable.length > 0) {
      const updatedData = [...Inspectiondetailstable];
      updatedData.pop();
      setInspectiondetailstable(updatedData);
    }
  };  
  // confirm
  const handleConfirm = async() => {
    const Url = `${domain}/api/InspectionDetails/ConfirmDetailsInspectionDetails`;
    const request ={
      id_TR_NO : document.getElementById("id_TR_NO").value,
      id_TR_TY : document.getElementById("id_TR_TY").value,
    }   
    try {
      const response =await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      handleClose();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // generate
  const handleGenerate = async() => {
    const Url = `${domain}/api/InspectionDetails/GenerateWODetails`;
    const request ={
      id_TR_NO : document.getElementById("id_TR_NO").value,
      id_TR_TY : document.getElementById("id_TR_TY").value,
    }   
    try {
      const response =await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // footer values
  const handlefooter = async (SelectedRowIndex) => {
    const parseValue = (value) => {
      return value !== undefined && value !== null && !isNaN(value) ? parseFloat(value) : 0;
    };

    const getElementValue = (id) => {
      const element = document.getElementById(id);
      return element ? parseValue(element.value) : 0;
    };

    const id_TR_QT = getElementValue(`id_TR_QT_${SelectedRowIndex}`);
    const id_LB_HR = getElementValue(`id_LB_HR_${SelectedRowIndex}`);
    const id_TX_PE = getElementValue(`id_TX_PE_${SelectedRowIndex}`);
    const id_UN_PR = getElementValue(`id_UN_PR_${SelectedRowIndex}`);
    const id_UN_RT = getElementValue(`id_UN_RT_${SelectedRowIndex}`);
    const id_DS_PE = getElementValue("id_DS_PE");

    const Url = `${domain}/api/InspectionDetails/GetPopulateNetAmount`;
    const request = {
      "inspectionDetails": [
        {
          "id_DS_PE": id_DS_PE,
          "id_LB_HR": id_LB_HR,
          "id_TR_QT": id_TR_QT,
          "id_TX_PE": id_TX_PE,
          "id_UN_PR": id_UN_PR,
          "id_UN_RT": id_UN_RT,
        }
      ]
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataFT = response.data.Result;
      if (dataFT) {
        document.getElementById("material_COST").value = dataFT.material_COST;
        document.getElementById("labour_COST").value = dataFT.labour_COST;
        document.getElementById("discount").value = dataFT.discount;
        document.getElementById("total_COST").value = dataFT.netAmount; //total_COST
        document.getElementById("tax_AMOUNT").value = dataFT.tax_AMOUNT;
        document.getElementById("total").value = dataFT.total;
      } else {
        console.error("Invalid API response:", dataFT);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleInputChange = (e, index) => {
    const { id, value } = e.target;
    const updatedTable = [...Inspectiondetailstable];
    updatedTable[index][id.split('_')[0]] = value;
    setInspectiondetailstable(updatedTable); 
    handlefooter(index); 
  };
// date default
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "id_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};

const isUpdateMode = Boolean(idUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
    <h5>{idUkNo ? "Revise Inspection Details" : "Add Inspection Details"}</h5>
    <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
    <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    {isUpdateMode && (
      <div style={{ display: 'flex' }}>
        <Button 
          style={{ marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
          icon="pi pi-check-circle" 
          onClick={handleConfirm} 
          tooltip={"Confirm"}>
        </Button>
        <Button 
          style={{backgroundColor:"transparent",color:"green",border:"none"}}
          icon="pi pi-book" 
          variant="outlined" 
          onClick={handleGenerate}
          tooltip="Generate"
        ></Button>
      </div>
      )}
      
    </div>
  </div>
);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={borderbox}>
        <div style={inboxstyle}  >
          <div className="formgrid grid">
          <div style={{display:"none"}}>
            <label htmlFor="idUkNo" className="font-bold">
            UK_no
            </label>
            <InputText
              id="idUkNo"
              name="idUkNo"
              value={idUkNo}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="id_TR_NO" className="font-bold">
            Trans. No
            </label>
            <InputText disabled
              id="id_TR_NO"
              name="id_TR_NO"
              defaultValue={id_TR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="id_TR_TY" className="font-bold">
            Type 
            </label>
            <InputText disabled
              id="id_TR_TY"
              name="id_TR_TY"
              value={id_TR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="id_DO_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
              id="id_DO_CO"
              name="id_DO_CO"
              value={id_DO_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="id_EQ_NM" className="font-bold">
                Equipment Name <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="SelectedEquipment"
                name="SelectedEquipment"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}                      
                value={SelectedEquipment}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openEquipmentPopup}
                ></i>
              )}
            </div>
            <InputText style={{ display: "none" }}
              id="id_EQ_NM"
              name="id_EQ_NM"
              value={id_EQ_NM}
              onChange={(Event) => onchangedata(Event)}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_CU_NO" className="font-bold">
                  Customer <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="SelectedCustomer"
                    name="SelectedCustomer"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}                      
                    value={SelectedCustomer}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
                  <InputText style={{ display: "none" }}
                    id="id_CU_NO"
                    name="id_CU_NO"
                    defaultValue={id_CU_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_TR_DT" className="font-bold">
                Date
                </label>
                <Calendar
                id="id_TR_DT"
                name="id_TR_DT"
                value={moment(id_TR_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_CO_CT" className="font-bold">
                  Cost Centre
                </label>
                <Dropdown
                  id="id_CO_CT"
                  name="id_CO_CT"
                  value={id_CO_CT}
                  options={id_CO_CTOptions}
                  onChange={(e) => onchangeDropdownData1("id_CO_CT", e.value)}
                  placeholder="Select Cost Centre"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_TX_AP" className="font-bold">
                  Tax Applicable
                </label>
                  <Dropdown
                    id="id_TX_AP"
                    name="id_TX_AP"
                    value={id_TX_AP}
                    // options={id_TX_APOptions}
                    options={[ { label: "Yes", value: "y" },  { label: "No", value: "n" }, ]}
                    onChange={(e) => onchangeDropdownData2("id_TX_AP", e.value)}
                    placeholder="Select Tax Ap."
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_RG_NO" className="font-bold">
                    Registration No
                </label>
                <InputText
                    id="id_RG_NO"
                    name="id_RG_NO"
                    value={id_RG_NO}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_DS_PE" className="font-bold">
                Discount %
                </label>
                <InputText
                  id="id_DS_PE"
                  name="id_DS_PE"
                  value={id_DS_PE}
                  onChange={(e) => {
                    onchangedata(e);
                    handlefooter();
                  }}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="id_DS_AM" className="font-bold">
                Discount Amount
                </label>
                <InputText
                  id="id_DS_AM"
                  name="id_DS_AM"
                  value={id_DS_AM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>    
            </div>
        </div>  
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="InspectionDetailsTable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th style={multitableheader} > Inspection <span style={{color:"red"}}>*</span></th>
                <th style={multitableheader} > Description</th>
                <th style={multitableheader} > Parts Number <span style={{color:"red"}}>*</span></th>
                <th style={multitableheader} > Parts Name</th>
                <th style={multitableheader} > Quantity</th>
                <th style={multitableheader} > UM</th>
                <th style={multitableheader} > Unit Price</th>
                <th style={multitableheader} > Amount</th>
                <th style={multitableheader} > Labour Hrs </th>
                <th style={multitableheader} > Unit Rate</th>
                <th style={multitableheader} > Amount</th>
                <th style={multitableheader} > Discount %</th>
                <th style={multitableheader} > Dis. Amount</th>
                <th style={multitableheader} > Tax %</th>
                <th style={multitableheader} > Tax Amount</th>
                <th style={multitableheader} > Remarks </th>
              </tr>
            </thead>
            <tbody>
              {Inspectiondetailstable.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`idUkNo_${index}`}
                      name={`idUkNo_${index}`}
                      value={row.idUkNo}
                    />
                  </td>
                  <td>
                  <div style={{display:"flex"}}>
                  <InputText 
                      style={{width:"170px"}}
                      id={`id_IN_NM_${index}`}
                      name={`id_IN_NM_${index}`}
                      onChange={(e) => onchangedata('id_IN_NM',e.value,index)}
                      onFocus={() => setShowSearchIcon(true)}
                      defaultValue={row.id_IN_NM ? row.id_IN_NM : row[`${'id_IN_NM'}_${index}`]}
                    />
                    {showSearchIcon && (
                        <i 
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openInspectionPopup(index)}
                      ></i>
                      )}
                  </div>
                  </td>
                  <td>
                  <InputText  disabled
                    style={{width:"250px"}}
                    id={`SelectedInspection_${index}`}
                    name={`SelectedInspection_${index}`}
                    value={row.SelectedInspection ? row.SelectedInspection : row[`${"SelectedInspection"}_${index}`]}

                    // onChange={(e) => onchangedata('SelectedInspection',e.value,index)}
                    // value={row.SelectedInspection ? row.SelectedInspection : row[`${'SelectedInspection'}_${index}`]}
                  />
                  </td>
                  <td>
                  <div style={{display:"flex"}}>
                    <InputText style={{width:"170px"}}
                      id={`id_PA_NO_${index}`}
                      name={`id_PA_NO_${index}`}
                      onChange={(e) => onchangedata('id_PA_NO',e.value,index)}
                      onFocus={() => setShowSearchIcon(true)} 
                      defaultValue={row.id_PA_NO ? row.id_PA_NO : row[`${"id_PA_NO"}_${index}`]}
                    />
                    {showSearchIcon && (
                        <i 
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "7%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openPartsPopup(index)}
                      ></i>
                      )}
                  </div>
                  </td>
                  <td >
                  <InputText disabled
                    style={{width:"250px"}}
                    id={`SelectedParts_${index}`}
                    name={`SelectedParts_${index}`}
                    value={row.SelectedParts ? row.SelectedParts : row[`${"SelectedParts"}_${index}`]}
                    // onChange={(e) => onchangedata('SelectedParts',e.value,index)}
                    // value={row.SelectedParts ? row.SelectedParts : row[`${'SelectedParts'}_${index}`]}
                  />
                  </td>
                  <td >
                    <InputText
                      style={{width:"150px"}}
                      id={`id_TR_QT_${index}`}
                      name={`id_TR_QT_${index}`}
                      defaultValue={row.id_TR_QT}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`id_TR_UM_${index}`}
                      name={`id_TR_UM_${index}`}
                      value={data[`id_TR_UM_${index}`] ? data[`id_TR_UM_${index}`] : row.id_TR_UM}
                      options={id_TR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData3(`id_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                  </td>
                  <td>
                    <InputText 
                    style={{width:"90px"}}
                      id={`id_UN_PR_${index}`}
                      name={`id_UN_PR_${index}`}
                      defaultValue={row.id_UN_PR}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                  <InputText 
                  style={{width:"90px"}}
                      id={`id_LD_AM_${index}`}
                      name={`id_LD_AM_${index}`}
                      defaultValue={row.id_LD_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"90px"}}
                      id={`id_LB_HR_${index}`}
                      name={`id_LB_HR_${index}`}
                      defaultValue={row.id_LB_HR}
                      // onChange={(e) =>{ 
                      //   onchangedata(e);
                      //   handlefooter(index);
                      // }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"90px"}}
                      id={`id_UN_RT_${index}`}
                      name={`id_UN_RT_${index}`}
                      defaultValue={row.id_UN_RT}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                  <InputText 
                      style={{width:"90px"}}
                      id={`id_LB_AM_${index}`}
                      name={`id_LB_AM_${index}`}
                      defaultValue={row.id_LB_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"90px"}}
                      id={`id_DS_PE_${index}`}
                      name={`id_DS_PE_${index}`}
                      defaultValue={row.id_DS_PE}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"90px"}}
                      id={`id_DS_AM_${index}`}
                      name={`id_DS_AM_${index}`}
                      defaultValue={row.id_DS_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                  <InputText 
                      style={{width:"90px"}}
                      id={`id_TX_PE_${index}`}
                      name={`id_TX_PE_${index}`}
                      defaultValue={row.id_TX_PE}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"90px"}}
                      id={`id_TX_AM_${index}`}
                      name={`id_TX_AM_${index}`}
                      defaultValue={row.id_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <InputText 
                      style={{width:"250px"}}
                      id={`id_RM_RK_${index}`}
                      name={`id_RM_RK_${index}`}
                      defaultValue={row.id_RM_RK}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
        {/* footer */}
        <div style={inboxstyle} >
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="material_COST" className="font-bold">
                Material Cost
                </label>
                <InputText disabled
                  id="material_COST"
                  name="material_COST"
                  value={material_COST}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="labour_COST" className="font-bold">
                Labour Cost 
                </label>
                <InputText disabled
                  id="labour_COST"
                  name="labour_COST"
                  value={labour_COST}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="discount" className="font-bold">
                Discount
                </label>
                <InputText disabled
                  id="discount"
                  name="discount"
                  value={discount}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="total_COST" className="font-bold">
                Total Cost
                </label>
                <InputText disabled
                  id="total_COST"
                  name="total_COST"
                  value={total_COST}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="tax_AMOUNT" className="font-bold">
                  Tax Amount
                </label>
                <InputText disabled
                  id="tax_AMOUNT"
                  name="tax_AMOUNT"
                  value={tax_AMOUNT}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="total" className="font-bold">
                Total
                </label>
                <InputText disabled
                    id="total"
                    name="total"
                    value={total}
                    onChange={(e) => onchangedata(e)}
                  />
              </div>   
            </div>
        </div> 
      </div>

      <EquipmentPopup
        visible={EquipmentPopupVisible}
        onClose={() => setEquipmentPopupVisible(false)}
        onDataSelect={handleSelectEquipment}
        formData={data}
      />
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleSelectCustomer}
        addressType={addresstypeCust}
        formData={data}
      />
      {/* inspection */}
      <ItemPopup
        visible={InspectionPopupVisible}
        onClose={() => setInspectionPopupVisible(false)}
        onDataSelect={handleSelectInspection}
        formData={data}
      /> 
      {/* partsnumber */}
      <ItemPopup
        visible={PartsPopupVisible}
        onClose={() => setPartsPopupVisible(false)}
        onDataSelect={handleSelectParts}
        formData={data}
      /> 
    </Dialog>
  );
}
