//MoveFromFolderPopup
import React, { useState, useRef, useEffect } from 'react';
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";


const MoveFromFolderPopup = ({ isOpen, onClose, onCreateFolder,newMainFolderName,setnewMainFolderName }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [error, setError] = useState('');
  const [FormData, setFormData] = useState("");
  const toast = useRef(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [newMainFolderNameOptions, setnewMainFolderNameOptions] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(typeof onCreateFolder);
    if (newFolderName.trim() !== '') {
      onCreateFolder(newFolderName.trim());
      setNewFolderName('');
    } else {
      toast.current.show({ severity: "warn", summary: "Warning", detail: "Folder name cannot be empty." });
    }
  };


  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setnewMainFolderName(value);
  };


  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  const urlcost = `${domain}/api/FileManagementSystem/FetchMoveToFolder`;
  const finalUrl = `${urlcost}`;

  const fetchDropdownOptions = async () => {
    const requestPayload = {
      "uk_Number":8
    }
    try {
      const response = await axios.get(finalUrl,requestPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.fd_NA_ME,
        value: item.fd_NA_ME,
      }));
      setnewMainFolderNameOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const handleOKClick = async (option) => {
    const Request = {
      "fdUkNo": option.fdUkNo,
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/FileMoveToFolder`, Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Rename successfully.",
        });
      } else {
        throw new Error("Failed to Rename.");
      }
    } catch (error) {
      console.error("Error Rename:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
    setPopupVisible(false); // Close popup after action
  };
  return (
    isOpen && (

      <div className="popup-overlay">
        <Toast ref={toast} />
        <div className="popup-content">
          <h2>Move to folder</h2>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="newMainFolderName" className="font-bold">
             Folder
            </label>
            <Dropdown 
              id="newMainFolderName"
              name="newMainFolderName"
              value={newMainFolderName}              
              options={newMainFolderNameOptions}
              onChange={(e) => onchangeDropDownFormData3("newMainFolderName", e.value)}
              placeholder="Select Folder Name"
            />
          </div> 
          <form onSubmit={handleSubmit}>
            {error && <div className="error-message">{error}</div>}
            <button type="submit">OK</button>
            <button type="button" onClick={onClose} aria-label="Cancel creation of folder">Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default MoveFromFolderPopup;
