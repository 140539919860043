import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
export default function ProcessRulesDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  pr_GP_01,
  setpr_GP_01,
  pr_GP_02,
  setpr_GP_02,
  pr_GP_03,
  setpr_GP_03,
  pr_OR_GP,
  setpr_OR_GP,
  pr_PA_IN,
  setpr_PA_IN,
  pr_PA_MO,
  setpr_PA_MO,
  pr_PK_IN,
  setpr_PK_IN,
  pr_PK_MO,
  setpr_PK_MO,
  pr_RP_IN,
  setpr_RP_IN,
  pr_RP_MO,
  setpr_RP_MO,
  pr_RL_CO,
  pr_RL_TY,
  pr_CO_CT,
  setpr_CO_CT,
}) {
  const {
    prUkNo,
    pr_RL_ID,
  } = data;
  const { borderbox, inboxstyle, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  // dropdowns

  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [pr_GP_01Options, setpr_GP_01Options] = useState([]);
  const [pr_GP_02Options, setpr_GP_02Options] = useState([]);
  const [pr_GP_03Options, setpr_GP_03Options] = useState([]);
  const [pr_OR_GPOptions, setpr_OR_GPOptions] = useState([]);
  const [pr_PA_INOptions, setpr_PA_INOptions] = useState([]);
  const [pr_PA_MOOptions, setpr_PA_MOOptions] = useState([]);
  const [pr_PK_INOptions, setpr_PK_INOptions] = useState([]);
  const [pr_PK_MOOptions, setpr_PK_MOOptions] = useState([]);
  const [pr_RP_INOptions, setpr_RP_INOptions] = useState([]);
  const [pr_RP_MOOptions, setpr_RP_MOOptions] = useState([]);
  const [pr_CO_CTOptions, setpr_CO_CTOptions] = useState([]);

  const Group1Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_GP_01(value);
  };

  const Group2Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_GP_02(value);
  };

  const Group3Dropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_GP_03(value);
  };

  const OrderGroupDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_OR_GP(value);
  };

  const PutawayDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_PA_IN(value);
  };

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_PA_MO(value);
  };

  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_PK_IN(value);
  };

  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_PK_MO(value);
  };

  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_RP_IN(value);
  };

  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_RP_MO(value);
  };

  const CostCentreDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_CO_CT(value);
  };

  useEffect(() => {
    Group1();
    Group2();
    Group3();
    OrderGroup();
    Putaway();
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();
    fetchDropdownDM();
    CostCentre();

  }, []);

  const Group1 = async () => {
    const Options = await fetchClassCodeDS("IN/W1");
    setpr_GP_01Options(Options);
  };

  const Group2 = async () => {
    const Options = await fetchClassCodeDS("IN/W2");
    setpr_GP_02Options(Options);
  };

  const Group3 = async () => {
    const Options = await fetchClassCodeDS("IN/W3");
    setpr_GP_03Options(Options);
  };

  const OrderGroup = async () => {
    const Options = await fetchClassCodeDS("OR/GP");
    setpr_OR_GPOptions(Options);
  };

  const Putaway = async () => {
    const Options = await fetchClassCodeDS("PU/IN");
    setpr_PA_INOptions(Options);
  };

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("PU/MO");
    setpr_PA_MOOptions(Options);
  };

  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("PK/IN");
    setpr_PK_INOptions(Options);
  };

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("PK/MO");
    setpr_PK_MOOptions(Options);
  };

  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("RP/IN");
    setpr_RP_INOptions(Options);
  };

  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeDS("RP/MO");
    setpr_RP_MOOptions(Options);
  };

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setpr_CO_CTOptions(Options);
  };

  const isUpdateMode = Boolean(prUkNo);


  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{prUkNo ? "Revise Process Rules" : "Add Process Rules"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />

        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RL_ID" className="font-bold">
                Rule ID
              </label>
              <InputText disabled
                id="pr_RL_ID"
                name="pr_RL_ID"
                value={pr_RL_ID}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RL_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="pr_RL_TY"
                name="pr_RL_TY"
                value={pr_RL_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RL_CO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="pr_RL_CO"
                name="pr_RL_CO"
                value={pr_RL_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                name="pr_CO_CT"
                value={pr_CO_CT}
                options={pr_CO_CTOptions}
                onChange={(e) => CostCentreDropDown("pr_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_GP_01" className="font-bold">
                Process Group 1
              </label>
              <Dropdown
                name="pr_GP_01"
                value={pr_GP_01}
                options={pr_GP_01Options}
                onChange={(e) => Group1Dropdown("pr_GP_01", e.value)}
                placeholder="Select Process Group 1"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_GP_02" className="font-bold">
                Process Group 2
              </label>
              <Dropdown
                name="pr_GP_02"
                value={pr_GP_02}
                options={pr_GP_02Options}
                onChange={(e) => Group2Dropdown("pr_GP_02", e.value)}
                placeholder="Select Process Group 2"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_GP_03" className="font-bold">
                Process Group 3
              </label>
              <Dropdown
                name="pr_GP_03"
                value={pr_GP_03}
                options={pr_GP_03Options}
                onChange={(e) => Group3Dropdown("pr_GP_03", e.value)}
                placeholder="Select Process Group 3"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_OR_GP" className="font-bold">
                Order Group
              </label>
              <Dropdown
                name="pr_OR_GP"
                value={pr_OR_GP}
                options={pr_OR_GPOptions}
                onChange={(e) => OrderGroupDropdown("pr_OR_GP", e.value)}
                placeholder="Select Order Group"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_PA_IN" className="font-bold">
                Putaway Instruction
              </label>
              <Dropdown
                name="pr_PA_IN"
                value={pr_PA_IN}
                options={pr_PA_INOptions}
                onChange={(e) => PutawayDropdown("pr_PA_IN", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_PA_MO" className="font-bold">
                Putaway Mode
              </label>
              <Dropdown
                name="pr_PA_MO"
                value={pr_PA_MO}
                options={pr_PA_MOOptions}
                onChange={(e) => onchangeDropDownFormData1("pr_PA_MO", e.value)}
                placeholder="Select Putaway Mode"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_PK_IN" className="font-bold">
                Picking Instruction
              </label>
              <Dropdown
                name="pr_PK_IN"
                value={pr_PK_IN}
                options={pr_PK_INOptions}
                onChange={(e) => onchangeDropDownFormData2("pr_PK_IN", e.value)}
                placeholder="Select Picking Instruction"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_PK_MO" className="font-bold">
                Picking Mode
              </label>
              <Dropdown
                name="pr_PK_MO"
                value={pr_PK_MO}
                options={pr_PK_MOOptions}
                onChange={(e) => onchangeDropDownFormData3("pr_PK_MO", e.value)}
                placeholder="Select Picking Mode"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RP_IN" className="font-bold">
                Replenishment Instruction
              </label>
              <Dropdown
                name="pr_RP_IN"
                value={pr_RP_IN}
                options={pr_RP_INOptions}
                onChange={(e) => onchangeDropDownFormData4("pr_RP_IN", e.value)}
                placeholder="Select Replenishment Instruction"
              />
            </div>

            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="pr_RP_MO" className="font-bold">
                Replenishment Mode
              </label>
              <Dropdown
                name="pr_RP_MO"
                value={pr_RP_MO}
                options={pr_RP_MOOptions}
                onChange={(e) => onchangeDropDownFormData5("pr_RP_MO", e.value)}
                placeholder="Select Replenishment Mode"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
