import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import EnterTestResultsDialog from "./EnterTestResultsDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  trTiId:"",
  tr_OR_NO:"",
  tr_OR_TY:"",
  tr_OR_Co:"",   
  tr_IT_NM:"",
  item_DESC:"",
  tr_CO_CT:"",   
  tr_LO_TN:"",
   tr_TS_DT:"", 
   tr_TS_ID:"",   
  test_DESC:"",   
  tr_AL_MI:"",   
  tr_AL_MX:"",   
  tr_TR_VL:"",   
  tr_RS_VL:"",   
  tr_PA_FA:"",
  tr_RS_VA:"",
  tr_VI_VA:"",
  tr_SA_SZ:"",         
  tr_DE_CT:"",   
  tr_CU_SE:"",   
  tr_TS_ER:"",   
  tester_DESC:"",   
  tr_CU_NO:"",   
  cus_DESC:"",  
};

const initialFilters = {
  tr_OR_NO: { value: "", operation: "" },
  tr_OR_TY: { value: "", operation: "" },
  tr_OR_Co: { value: "", operation: "" },   
  tr_IT_NM: { value: "", operation: "" },
  item_DESC: { value: "", operation: "" },
  tr_CO_CT: { value: "", operation: "" },   
  tr_LO_TN: { value: "", operation: "" },
   tr_TS_DT: { value: "", operation: "" }, 
   tr_TS_ID: { value: "", operation: "" },   
  test_DESC: { value: "", operation: "" },   
  tr_AL_MI: { value: "", operation: "" },   
  tr_AL_MX: { value: "", operation: "" },   
  tr_TR_VL: { value: "", operation: "" },   
  tr_RS_VL: { value: "", operation: "" },  
  tr_PA_FA: { value: "", operation: "" }, 
  tr_RS_VA: { value: "", operation: "" }, 
  tr_VI_VA : { value: "", operation: "" },    
  tr_SA_SZ: { value: "", operation: "" },         
  tr_DE_CT: { value: "", operation: "" },   
  tr_CU_SE: { value: "", operation: "" },   
  tr_TS_ER: { value: "", operation: "" },   
  tester_DESC: { value: "", operation: "" },   
  tr_CU_NO: { value: "", operation: "" },   
  cus_DESC: { value: "", operation: "" },  
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [tr_CO_CT, settr_CO_CT ] = useState("");
  const [sp_ST_CD, setsp_ST_CD ] = useState("");
  const [sp_OP_SQ, setsp_OP_SQ ] = useState("");

  const [trTiId, settrTiId ] = useState("");
  const [tr_OP_SQ, settr_OP_SQ ] = useState("");

  
// AdvanceSearch
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [selectedAbAdNM, setselectedAbAdNM ] = useState("");
  
  

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [EnterTestResultsTableData, setEnterTestResultsTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setEnterTestResultsTableData([{}]);
    setsp_ST_CD(null);
    settr_CO_CT(null);
    settr_OP_SQ(null);
    setselectedAbAdNM(null);
    setSelectedITNM(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/TestResult/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const EnterTestResultss = response.data;
          const updatedEnterTestResultss = EnterTestResultss.testResultList.map((target) => { 

            const UpdatedworkData = { ...target };
            delete UpdatedworkData.trTiId;
            return UpdatedworkData;
          });
          setEnterTestResultsTableData(updatedEnterTestResultss);
          setFormData(updatedEnterTestResultss[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault(); 
  }, [currentPage, pageSize, filters]);
  const [tr_OR_CO , settr_OR_CO] = useState("");


  const handleDefault = async () => {
    settr_OR_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TestResult/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TestResult/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const EnterTestResultss = response.data;
        setEnterTestResultsTableData(EnterTestResultss.testResultList);
        setFormData(EnterTestResultss.testResultList[0]);
        settr_CO_CT(EnterTestResultss.tr_CO_CT);
        settr_OP_SQ(EnterTestResultss.testResultList.tr_OP_SQ);
        settrTiId(EnterTestResultss.testResultList.trTiId)
        setSelectedITNM(EnterTestResultss.testResultList.sp_IT_NM)
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("EnterTestResultstable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const testResultList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const RQ_DT = document.getElementsByName("ep_EF_DT_" + i)[0];
    const details = {
        trTiId : document.getElementById("trTiId_" + i).value,
        tr_TS_ID : document.getElementById("tr_TS_ID_" + i).value,
        tr_TS_DS : document.getElementById("tr_TS_DS_" + i).value,
        tr_AL_MI : document.getElementById("tr_AL_MI_" + i).value,
        tr_AL_MX : document.getElementById("tr_AL_MX_" + i).value,
        tr_TR_VL : document.getElementById("tr_TR_VL_" + i).value,
        tr_RS_VL : document.getElementById("tr_RS_VL_" + i).value,
        tr_PA_FA : document.getElementById("tr_PA_FA_" + i).value,
        tr_SA_SZ : document.getElementById("tr_SA_SZ_" + i).value,
        tr_DE_CT : document.getElementById("tr_DE_CT_" + i).value,
        tr_CU_SE : document.getElementById("tr_CU_SE_" + i).value,
        tr_RM_RK : document.getElementById("tr_RM_RK_" + i).value,
    };
    testResultList.push(details);
  }

  
 requestBody = {
  testResultList,
  trTiId   : FormData.trTiId,   
  tr_OR_NO :document.getElementById("tr_OR_NO").value,
  tr_OR_TY :document.getElementById("tr_OR_TY").value,
  tr_CO_CT : FormData.tr_CO_CT,
  tr_IT_NM : FormData.tr_IT_NM,
  tr_TS_DT : FormData.tr_TS_DT,
  tr_LO_TN : document.getElementById("tr_LO_TN").value,
  tr_CU_NO : FormData.tr_CU_NO,
  tr_OP_SQ : FormData.tr_OP_SQ,
  tr_OR_CO : tr_OR_CO,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.trTiId) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/TestResult/Update`;
      if (confirm) {
        axios
          .put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.tr_OR_CO = company;
      axios
        .post(`${domain}/api/TestResult/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
 
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tr_OR_NO"] ? filters["tr_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_OR_NO",
                        e.target.value,
                        filters["tr_OR_NO"]
                          ? filters["tr_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tr_OR_TY"] ? filters["tr_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_OR_TY",
                        e.target.value,
                        filters["tr_OR_TY"] ? filters["tr_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tr_OR_CO"] ? filters["tr_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_OR_CO",
                        e.target.value,
                        filters["tr_OR_CO"]
                          ? filters["tr_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["tr_IT_NM"] ? filters["tr_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_IT_NM",
                        e.target.value,
                        filters["tr_IT_NM"]
                          ? filters["tr_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["item_DESC"] ? filters["item_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ts_AL_MI",
                        e.target.value,
                        filters["item_DESC"] ? filters["item_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tr_CO_CT"] ? filters["tr_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_CO_CT",
                        e.target.value,
                        filters["tr_CO_CT"]
                          ? filters["tr_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["tr_LO_TN"] ? filters["tr_LO_TN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_LO_TN",
                        e.target.value,
                        filters["tr_LO_TN"]
                          ? filters["tr_LO_TN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["tr_TS_DT"] ? filters["tr_TS_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_TS_DT",
                        e.target.value,
                        filters["tr_TS_DT"]
                          ? filters["tr_TS_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  

                <td>
                  <input
                    type="text"
                    value={filters["tr_TS_ID"] ? filters["tr_TS_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_TS_ID",
                        e.target.value,
                        filters["tr_TS_ID"]
                          ? filters["tr_TS_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["test_DESC"] ? filters["test_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "test_DESC",
                        e.target.value,
                        filters["test_DESC"]
                          ? filters["test_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_AL_MI"] ? filters["tr_AL_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_AL_MI",
                        e.target.value,
                        filters["tr_AL_MI"]
                          ? filters["tr_AL_MI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_AL_MX"] ? filters["tr_AL_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_AL_MX",
                        e.target.value,
                        filters["tr_AL_MX"]
                          ? filters["tr_AL_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_TR_VL"] ? filters["tr_TR_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_TR_VL",
                        e.target.value,
                        filters["tr_TR_VL"]
                          ? filters["tr_TR_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_RS_VL"] ? filters["tr_RS_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_RS_VL",
                        e.target.value,
                        filters["tr_RS_VL"]
                          ? filters["tr_RS_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_PA_FA"] ? filters["tr_PA_FA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_PA_FA",
                        e.target.value,
                        filters["tr_PA_FA"]
                          ? filters["tr_PA_FA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_VI_VA"] ? filters["tr_VI_VA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_VI_VA",
                        e.target.value,
                        filters["tr_VI_VA"]
                          ? filters["tr_VI_VA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle} 
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_RS_VA"] ? filters["tr_RS_VA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_RS_VA",
                        e.target.value,
                        filters["tr_RS_VA"]
                          ? filters["tr_RS_VA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_SA_SZ"] ? filters["tr_SA_SZ"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_SA_SZ",
                        e.target.value,
                        filters["tr_SA_SZ"]
                          ? filters["tr_SA_SZ"].operation 
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={filters["tr_DE_CT"] ? filters["tr_DE_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_DE_CT",
                        e.target.value,
                        filters["tr_DE_CT"]
                          ? filters["tr_DE_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  

                <td>
                  <input
                    type="text"
                    value={filters["tr_CU_SE"] ? filters["tr_CU_SE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_CU_SE",
                        e.target.value,
                        filters["tr_CU_SE"]
                          ? filters["tr_CU_SE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_TS_ER"] ? filters["tr_TS_ER"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_TS_ER",
                        e.target.value,
                        filters["tr_TS_ER"]
                          ? filters["tr_TS_ER"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tester_DESC"] ? filters["tester_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tester_DESC",
                        e.target.value,
                        filters["tester_DESC"]
                          ? filters["tester_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["tr_CU_NO"] ? filters["tr_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tr_CU_NO",
                        e.target.value,
                        filters["tr_CU_NO"]
                          ? filters["tr_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["cus_DESC"] ? filters["cus_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cus_DESC",
                        e.target.value,
                        filters["cus_DESC"]
                          ? filters["cus_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>  
            
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No	</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Item Name</th>  
                <th style={tableHeaderStyle}>Description</th>   
                <th style={tableHeaderStyle}>Cost Centre</th>  
                <th style={tableHeaderStyle}>Batch No	</th>       
                <th style={tableHeaderStyle}>Date</th>       
                <th style={tableHeaderStyle}>Test ID</th>       
                <th style={tableHeaderStyle}>Description</th>       
                <th style={tableHeaderStyle}>Minimum</th>       
                <th style={tableHeaderStyle}>Maximum</th>       
                <th style={tableHeaderStyle}>Target</th>       
                <th style={tableHeaderStyle}>Result Value</th>       
                <th style={tableHeaderStyle}>Pass/Fail</th>       
                <th style={tableHeaderStyle}>Value</th>       
                <th style={tableHeaderStyle}>Visual Result</th>       
                <th style={tableHeaderStyle}>Sample Size</th>       
                <th style={tableHeaderStyle}>Defect</th>  
                <th style={tableHeaderStyle}>Cause</th>       
                <th style={tableHeaderStyle}>Tester</th>       
                <th style={tableHeaderStyle}>Name</th>       
                <th style={tableHeaderStyle}>Customer</th>       
                <th style={tableHeaderStyle}>Name</th>    
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.trTiId}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.trTiId}
                        checked={selectedRows.includes(rowData.trTiId)}
                        onChange={() => handleRowSelect(rowData.trTiId)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.tr_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.tr_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.tr_OR_Co}</td>   
                    <td style={tableCellStyle}>{rowData.tr_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_DESC}</td>
                    <td style={tableCellStyle}>{rowData.tr_CO_CT}</td>   
                    <td style={tableCellStyle}>{rowData.tr_LO_TN}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.tr_TS_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.tr_TS_ID}</td>   
                    <td style={tableCellStyle}>{rowData.test_DESC}</td>   
                    <td style={tableCellStyle}>{rowData.tr_AL_MI}</td>   
                    <td style={tableCellStyle}>{rowData.tr_AL_MX}</td>   
                    <td style={tableCellStyle}>{rowData.tr_TR_VL}</td>   
                    <td style={tableCellStyle}>{rowData.tr_RS_VL}</td>   
                    <td style={tableCellStyle}>{rowData.tr_PA_FA}</td>  
                    <td style={tableCellStyle}>{rowData.tr_VI_VA}</td> 
                    <td style={tableCellStyle}>{rowData.tr_RS_VA}</td>    
                    <td style={tableCellStyle}>{rowData.tr_SA_SZ}</td>         
                    <td style={tableCellStyle}>{rowData.tr_DE_CT}</td>   
                    <td style={tableCellStyle}>{rowData.tr_CU_SE}</td>   
                    <td style={tableCellStyle}>{rowData.tr_TS_ER}</td>   
                    <td style={tableCellStyle}>{rowData.tester_DESC}</td>   
                    <td style={tableCellStyle}>{rowData.tr_CU_NO}</td>   
                    <td style={tableCellStyle}>{rowData.cus_DESC}</td>   
            

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <EnterTestResultsDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        EnterTestResultsTableData={EnterTestResultsTableData}
        setEnterTestResultsTableData={setEnterTestResultsTableData}
   
    
        sp_ST_CD={sp_ST_CD}
        setsp_ST_CD={setsp_ST_CD}
        sp_OP_SQ={sp_OP_SQ}
        setsp_OP_SQ={setsp_OP_SQ}
        trTiId={trTiId}
        settrTiId={settrTiId}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        tr_CO_CT={tr_CO_CT}
        settr_CO_CT={settr_CO_CT}
        tr_OP_SQ={tr_OP_SQ}
        settr_OP_SQ={settr_OP_SQ}
        selectedAbAdNM={selectedAbAdNM}
        setselectedAbAdNM={setselectedAbAdNM}
        tr_OR_CO={tr_OR_CO}
        settr_OR_CO={settr_OR_CO}
      />
    </div>
  );
};

export default WorkCentreMain;
