import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ImportLeadDialog from "./ImportLeadDialog";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  li_DO_NO:"",
  li_DO_TY:"",
  li_DO_CO:"",
  Li_TR_DT:"",
  li_LN_NO:"",
  li_LD_SC:"",
  li_CU_NM:"",
  li_AD_L1:"",
  li_PH_NO:"",
  li_EM_ID:"",
  li_WB_SI:"",
  li_IN_RY:"",
  in_VE_CL:"",
  in_CI_TY:"",
  in_CN_RY:"",
  in_CO_PE:"",
  in_ST_AT:"",
  liUkNo:"",
};
const initialFilters = {
  li_DO_NO: { value: "", operation: "" },
  li_DO_TY: { value: "", operation: "" },
  li_DO_CO: { value: "", operation: "" },
  Li_TR_DT: { value: "", operation: "" },
  li_LN_NO: { value: "", operation: "" },
  li_LD_SC: { value: "", operation: "" },
  li_CU_NM: { value: "", operation: "" },
  li_AD_L1: { value: "", operation: "" },
  li_PH_NO: { value: "", operation: "" },
  li_EM_ID: { value: "", operation: "" },
  li_WB_SI: { value: "", operation: "" },
  li_IN_RY: { value: "", operation: "" },
  in_VE_CL: { value: "", operation: "" },
  in_CI_TY: { value: "", operation: "" },
  in_CN_RY: { value: "", operation: "" },
  in_CO_PE: { value: "", operation: "" },
  li_TI_TL: { value: "", operation: "" },
  in_ST_AT: { value: "", operation: "" },
};

const ImportLeadMain = () => {

  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,ListDesc, copyButtonStyles,checkboxStyle,tableHeaderStyle,inputStyle,tableCellStyle} = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData1, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [ImportLeadtable, setImportLeadtable] = useState([{}]);

  const [li_DO_NO, setli_DO_NO] = useState("");
  const [ImportLeadData, setImportLeadData] = useState("");

  // const [Process, setProcess] = useState("");
  // const buildSearchRequestDto = () => {
  //   return Object.keys(filters)
  //     .filter((column) => filters[column].value)
  //     .map((column) => ({
  //       column: column.toUpperCase(),
  //       value: filters[column].value,
  //       operation: filters[column].operation,
  //     }));
  // };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'li_DO_NO') {
          constructedColumn = 'li_DO_NO';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setImportLeadtable([{}]);
    // setFormData(initialValue);
    // setProcess(null);   
    setli_DO_NO(null); 
    setOpen(true);
  };

  const handleClose = () => {
    setImportLeadtable([{}]);
    setFormData(initialValue);
    // setProcess(null);
    setli_DO_NO(null);
       setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setImportLeadData([...ImportLeadData, {}]);
  };
  const deleteRow = () => {
    if (ImportLeadtable.length > 0) {
      const updatedData = [...ImportLeadtable];
      updatedData.pop();
      setImportLeadData(updatedData);
    }
  }; 
  const[li_DO_CO, setli_DO_CO] = useState("");
  const[li_DO_TY, setli_DO_TY] = useState("");
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setli_DO_CO(`${company}`)
            if(fName === "LI_DO_TY"){
              setli_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // handleSearch();
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ImportLead/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ImportLeadData = response.data;
          delete ImportLeadData.liUkNo;
          // ImportLeadtable(ImportLeadData.importLeadList);
          
          setImportLeadtable(ImportLeadData.importLeadList);
         
          setFormData(ImportLeadData);  
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ImportLead/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData1, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData1, [name]: value });
  };
  const onchangeFormData1= (name, value, index= null) => {
    setFormData({ ...FormData1, [name]: value });
    const fieldName=(FormData1 && FormData1.li_DO_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    ImportLeadData[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ImportLead/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      

      if (response.status === 200) {
        
        const ImportLeadData = response.data;
        // ImportLeadtable(ImportLeadData.importLeadList);
        
        setImportLeadtable(ImportLeadData.importLeadList);
        setli_DO_NO(response.data.li_DO_NO);
      
        setFormData(ImportLeadData);                       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = (type) => {  
    const UK_NO = FormData1.liUkNo;
          const DO_NO = document.getElementById("li_DO_NO");
          const DO_TY = document.getElementById("li_DO_TY");
          const DO_CO = document.getElementById("li_DO_CO");
          const TR_DT = FormData1.li_TR_DT;
      const LeaseHeader = {
      }; 
    const table1 = document.getElementById("ImportLeadtable");
    const totalRowCount1 = table1.tBodies[0].rows.length;
    const importLeadList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const UK_NO = document.getElementById("liUkNo_" + i);
      const LN_NO = document.getElementById("li_LN_NO_" + i);
      const LD_SC = document.getElementById("li_LD_SC_" + i);
      const CU_NM = document.getElementById("li_CU_NM_" + i);
      const AD_L1 = document.getElementById("li_AD_L1_" + i);
      const PH_NO = document.getElementById("li_PH_NO_" + i);
      const EM_ID = document.getElementById("li_EM_ID_" + i);
      const WB_SI = document.getElementById("li_WB_SI_" + i);
      const IN_RY = document.getElementById("li_IN_RY_" + i);
      const VE_CL = document.getElementById("in_VE_CL_" + i);
      const CI_TY = document.getElementById("in_CI_TY_" + i);
      const CN_RY = document.getElementById("in_CN_RY_" + i);
      const CO_PE = document.getElementById("in_CO_PE_" + i);

      const TI_TL = document.getElementById("li_TI_TL_" + i);
      const CO_EM = document.getElementById("li_CO_EM_" + i);
      const CO_PH = document.getElementById("li_CO_PH_" + i);

      const ST_AT = document.getElementById("in_ST_AT_" + i);
      const Details = {
        liUkNo: UK_NO.value,
        li_TI_TL: TI_TL.value,
        li_CO_EM: CO_EM.value,
        li_CO_PH: CO_PH.value,
        li_LN_NO: LN_NO.value,
        li_LD_SC: LD_SC.value,
        li_CU_NM: CU_NM.value,
        li_AD_L1: AD_L1.value,
        li_PH_NO: PH_NO.value,
        li_EM_ID: EM_ID.value,
        li_WB_SI: WB_SI.value,
        li_IN_RY: IN_RY.value,
        in_VE_CL: VE_CL.value,
        in_CI_TY: CI_TY.value,
        in_CN_RY: CN_RY.value,
        in_CO_PE: CO_PE.value,
        in_ST_AT: ST_AT.value,
      };
      importLeadList.push(Details);
    }

    const requestBody = {
      LeaseHeader ,
      importLeadList,
      liUkNo: UK_NO,
      li_DO_NO: DO_NO.value,
      li_DO_TY: DO_TY.value,
      li_DO_CO: DO_CO.value,
      li_TR_DT: TR_DT,
    };
   
    if (FormData1.li_DO_NO) {
      if (FormData1.liUkNo && type=="") {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ImportLead/Update`;
        if (confirm) {
          FormData1.li_DO_TY= li_DO_TY;
         
          axios
            .put(update, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      }
      else if (FormData1.liUkNo && type=="process") {
        const confirm = window.confirm("Do you want to Process ?");
        const process = `${domain}/api/ImportLead/ProcessInterface`;
        if (confirm) {
          FormData1.li_DO_TY= li_DO_TY;
         
          axios
            .post(process, requestBody, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        FormData1.li_DO_TY= li_DO_TY;
        axios
          .post(`${domain}/api/ImportLead/Add`, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }
     } else {
      const TR_DT = FormData1.li_TR_DT;
        if (TR_DT) {
        const currentYear = TR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
      const urlNextNumber = `${domain}/api/DocumentNumber/All`;
      const parameters = `QT/${secondHalf}/1000/${firstHalf}`; //SalesOrder-SO   SALESQUOTE-SQ
      const finalUrl1 = `${urlNextNumber}/${parameters}`;
      fetch(finalUrl1, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(`Request failed with status: ${resp.status}`);
          }
          return resp.json();
        })
        .then((resp) => {
          onchangeFormData1("li_DO_NO", resp.dn_DO_NO);
          setli_DO_NO(resp.dn_DO_NO);
          setFormData({ ...FormData1, ["li_DO_NO"]: resp.dn_DO_NO });
          axios
              .post(`${domain}/api/ImportLead/Add`, requestBody, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error saving data:", error);
              });

        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      }
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>    

        <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div
        style={{
          background: "white !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>  
    </React.Fragment>
  );
  const tableHeaderStyleE = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"100%",
  };
  const inputStyleE = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };
  
  // upload function
  const url = `${domain}/api/FileUpload/ImportLead`;

  const UploadExcel = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (fileExtension === "csv" || fileExtension === "xlsx") {
        const formData = new FormData();
        formData.append('file', file); // Append the file
        formData.append('type', 'I2'); // Assuming 'type' is a required parameter

        try {
          fetch(url, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`, // Assuming token is defined elsewhere
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"), // Assuming company is defined elsewhere
            },
          })
            .then((resp) => {
              if (!resp.ok) {
                throw new Error('Failed to upload file');
              }
              return resp.json();
            })
            .then((resp) => {
              // Assuming you have a toast mechanism like toast.current.show
              toast.current.show({
                severity: "success",
                summary: "Import Successful",
              });
              // Assuming handleSearch is a function to trigger search after import
              handleSearch();
            })
            .catch((error) => {
              console.error("Error importing file:", error);
              toast.current.show({
                severity: "error",
                summary: "Import Error",
                detail: "Failed to import. Please try again later.",
              });
            });
        } catch (error) {
          console.error("Error parsing imported data:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail:
              "Invalid data format. Please ensure the imported file is in the correct format.",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Unsupported File Type",
          detail: "Please select a CSV or XLSX file for Upload.",
        });
      }
    }
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        type="button"
        icon="pi pi-download"
        className="p-button-download"
        tooltip="Upload"
        rounded
        onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }}
        data-pr-tooltip="Upload"
      />
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={UploadExcel}
        style={{ display: "none" }}
      />
      
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px"}} >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_DO_NO"] ? filters["li_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_DO_NO", 
                        e.target.value,
                        filters["li_DO_NO"]
                          ? filters["li_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_DO_TY"] ? filters["li_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_DO_TY",
                        e.target.value,
                        filters["li_DO_TY"]
                          ? filters["li_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_DO_CO"] ? filters["li_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_DO_CO",
                        e.target.value,
                        filters["li_DO_CO"]
                          ? filters["li_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["Li_TR_DT"] ? filters["Li_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "Li_TR_DT",
                        e.target.value,
                        filters["Li_TR_DT"]
                          ? filters["Li_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["li_LN_NO"] ? filters["li_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LN_NO",
                        e.target.value,
                        filters["li_LN_NO"]
                          ? filters["li_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LD_SC"] ? filters["li_LD_SC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LD_SC",
                        e.target.value,
                        filters["li_LD_SC"]
                          ? filters["li_LD_SC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["li_CU_NM"] ? filters["li_CU_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_CU_NM",
                        e.target.value,
                        filters["li_CU_NM"]
                          ? filters["li_CU_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_AD_L1"] ? filters["li_AD_L1"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_AD_L1",
                        e.target.value,
                        filters["li_AD_L1"]
                          ? filters["li_AD_L1"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_PH_NO"] ? filters["li_PH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_PH_NO",
                        e.target.value,
                        filters["li_PH_NO"]
                          ? filters["li_PH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EM_ID"] ? filters["li_EM_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EM_ID",
                        e.target.value,
                        filters["li_EM_ID"]
                          ? filters["li_EM_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_WB_SI"] ? filters["li_WB_SI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_WB_SI",
                        e.target.value,
                        filters["li_WB_SI"]
                          ? filters["li_WB_SI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_IN_RY"] ? filters["li_IN_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_IN_RY",
                        e.target.value,
                        filters["li_IN_RY"]
                          ? filters["li_IN_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["in_VE_CL"] ? filters["in_VE_CL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "in_VE_CL",
                        e.target.value,
                        filters["in_VE_CL"]
                          ? filters["in_VE_CL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["in_CI_TY"] ? filters["in_CI_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "in_CI_TY",
                        e.target.value,
                        filters["in_CI_TY"]
                          ? filters["in_CI_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["in_CN_RY"] ? filters["in_CN_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "in_CN_RY",
                        e.target.value,
                        filters["in_CN_RY"]
                          ? filters["in_CN_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["in_CO_PE"] ? filters["in_CO_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "in_CO_PE",
                        e.target.value,
                        filters["in_CO_PE"]
                          ? filters["in_CO_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TI_TL"] ? filters["li_TI_TL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TI_TL",
                        e.target.value,
                        filters["li_TI_TL"]
                          ? filters["li_TI_TL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["in_ST_AT"] ? filters["in_ST_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "in_ST_AT",
                        e.target.value,
                        filters["in_ST_AT"]
                          ? filters["in_ST_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleE}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyleE}>Doc. No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Date</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Source</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Address</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>E-Mail</th>
                <th style={tableHeaderStyle}>WebSite</th>
                <th style={tableHeaderStyle}>Industry</th>
                <th style={tableHeaderStyle}>Verticals</th>
                <th style={tableHeaderStyle}>City</th>
                <th style={tableHeaderStyle}>Country</th>
                <th style={tableHeaderStyle}>Contact Person</th>
                <th style={tableHeaderStyle}>Title</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.liUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.liUkNo}
                        checked={selectedRows.includes(rowData.liUkNo)}
                        onChange={() => handleRowSelect(rowData.liUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.li_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.li_DO_TY}</td>  
                    <td style={tableCellStyle}>{rowData.li_DO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.li_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.li_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.li_LD_SC}</td>
                    <td style={tableCellStyle}>{rowData.li_CU_NM}</td>
                    <td style={tableCellStyle}>{rowData.li_AD_L1}</td>
                    <td style={tableCellStyle}>{rowData.li_PH_NO}</td>
                    <td style={tableCellStyle}>{rowData.li_EM_ID}</td>
                    <td style={tableCellStyle}>{rowData.li_WB_SI}</td>
                    <td style={tableCellStyle}>{rowData.li_IN_RY}</td>

                    <td style={tableCellStyle}>{rowData.in_VE_CL}</td>
                    <td style={tableCellStyle}>{rowData.in_CI_TY}</td>
                    <td style={tableCellStyle}>{rowData.in_CN_RY}</td>
                    <td style={tableCellStyle}>{rowData.in_CO_PE}</td>
                    <td style={tableCellStyle}>{rowData.li_TI_TL}</td>
                    <td style={tableCellStyle}>{rowData.in_ST_AT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        
        {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ImportLeadDialog
        open={open}
        handleClose={handleClose}
        data={FormData1}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData} 
        
        li_DO_TY={li_DO_TY}
        li_DO_CO={li_DO_CO}
        li_DO_NO={li_DO_NO}
        ImportLeadtable={ImportLeadtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default ImportLeadMain;
