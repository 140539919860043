import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import IpdQueueDialog from "./IpdQueueDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  idUkNo: "",
  id_PA_ID: "",
  id_PA_NM: "",
  id_VI_NO: "",
  id_VI_DT: moment(moment().toDate()).format("YYYYMMDD"),
  id_PH_NO: "",
  id_DO_NO: "",

};

const initialFilters = {
  id_PA_ID: { value: "", operation: "" },
  id_PA_NM: { value: "", operation: "" },
  id_VI_NO: { value: "", operation: "" },
  id_VI_DT: { value: "", operation: "" },
  id_PH_NO: { value: "", operation: "" },
  id_DO_NO: { value: "", operation: "" },

};

const IpdQueueMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles, rightalined,
    copyButtonStyles,
    checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle, inputStyleH, tableCellStyleH, tableHeaderStyleH, inputStyle1, formatSavedTime
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns
  const [pt_ST_CD, setpt_ST_CD] = useState("");
  const [id_CO_NO, setid_CO_NO] = useState("");

  const [tb_UK_NO, settb_UK_NO] = useState("");
  const [pt_UK_NO, setpt_UK_NO] = useState("");
  
  // Advance Search
  const [IpdQueueTable, setIpdQueueTable] = useState([{}]);
  const [IpdQueueTable1, setIpdQueueTable1] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };


  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpt_ST_CD(value);
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setIpdQueueTable([{}]);
    // CostSimulationLabourTable([{}]);
    setFormData(initialValue);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIpdQueueTable([{}]);
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);



  const handleDefault = async () => {
    setid_CO_NO(`${company}`)

    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;

            // if(fName === "SD_BS_CR"){
            //   setsd_BS_CR(dataDefault.versionIds[i].default_VALUE)
            // }
            // if(fName === "SD_OR_TY"){
            //   setsd_OR_TY(dataDefault.versionIds[i].default_VALUE)
            // }

          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/IPDQueue/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    const fieldName = (FormData && FormData.tb_UK_NO) ? `${name}` : `${name}_${index}`;
    if (index != null)
      IpdQueueTable[index][fieldName] = value;
    const fieldName1=(FormData && FormData.pt_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
      IpdQueueTable1[index][fieldName1]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/IPDQueue/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const IpdQueueData = response.data;
        setIpdQueueTable(IpdQueueData.tpBpList);
        setIpdQueueTable1(IpdQueueData.patientTreatmentList);
        setFormData(IpdQueueData);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/CostSimulationComponent/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const IpdQueueData = response.data;
          delete IpdQueueData.IpdQueues[0].idUkNo;
          for (let i = 0; i < IpdQueueData.IpdQueues.length; i++) {
            delete IpdQueueData.IpdQueues[i].idUkNo;
          }
          setIpdQueueTable(IpdQueueData.IpdQueues);
          setFormData(IpdQueueData.IpdQueues[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  var requestBody = {};
  function values() {
    const table = document.getElementById("IpdQueueTable1");
    if (!table) {
      console.error("Table 'PatientTreatmentList' not found in the DOM.");
      return; // Exit if the table is not found
    }
    const totalRowCount = table.tBodies[0].rows.length;
    const patientTreatmentList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const TR_DT = document.getElementsByName("tb_TR_DT_" + i)[0];
      const TR_TM = document.getElementById("tb_TR_TM_" + i);
      const details = {
        pt_UK_NO: document.getElementById("pt_UK_NO_" + i).value,
        pt_MD_NM: document.getElementById("pt_MD_NM_" + i).value,
        tb_TR_DT: TR_DT.value ? moment(TR_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        tb_TR_TM: TR_TM.value.replaceAll(":", ""),
        pt_ST_CD: table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        pt_CO_NO: company,
      };
      patientTreatmentList.push(details);
    }
    const table1 = document.getElementById("TpBpList");
    const totalRowCount1 = table1.tBodies[0].rows.length;
    const tpBpList = [];
    for (let i = 0; i < totalRowCount1; i++) {
      const TB_UK = document.getElementById("tb_UK_NO_" + i);
      const TR_DT = document.getElementsByName("tb_TR_DT_" + i)[0];
      const TR_TM = document.getElementById("tb_TR_TM_" + i);
      const PL_SE = document.getElementById("tb_PL_SE_" + i);
      const BL_PR = document.getElementById("tb_BL_PR_" + i);
      const IN_KT = document.getElementById("tb_IN_TK_" + i);
      const OU_PT = document.getElementById("tb_OU_PT_" + i);
      const SalesDrive = {
        tb_UK_NO: TB_UK.value,
        tb_TR_DT: TR_DT.value ? moment(TR_DT.value, "DD/MM/YYYY").format("YYYYMMDD") : null,
        tb_TR_TM: TR_TM.value.replaceAll(":", ""),
        tb_PL_SE: PL_SE.value,
        tb_BL_PR: BL_PR.value,
        tb_IN_TK: IN_KT.value,
        tb_OU_PT: OU_PT.value,
        tb_CO_NO: company,
      };
      tpBpList.push(SalesDrive);
    }
    requestBody = {
      patientTreatmentList,
      tpBpList,
      idUkNo: document.getElementById("idUkNo").value,
      id_VI_NO: document.getElementById("id_VI_NO").value,
      id_PA_NM: document.getElementById("id_PA_NM").value,
      id_CO_NO: company,

    };
  }
  const handleFormSubmit = () => {
    values();
    const ukNo = FormData.idUkNo;
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/IPDQueue/Update`;

      if (confirm) {
        axios.put(update, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {

    }

  };



  const handleConfirm = async () => {
    const Url = `${domain}/api/IpdQueue/RNCNConfirmDetails`
    const request = {
      "rc_TR_NO": FormData.rc_TR_NO,
      "rc_TR_TY": document.getElementById("rc_TR_TY").value,
      "id_CO_NO": document.getElementById("id_CO_NO").value,
    }
    axios.post(Url, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  };


  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Select
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", border: "none", backgroundColor: "#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{ width: '40px' }}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_PA_ID"] ? filters["id_PA_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_PA_ID",
                        e.target.value,
                        filters["id_PA_ID"]
                          ? filters["id_PA_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_PA_NM"] ? filters["id_PA_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_PA_NM",
                        e.target.value,
                        filters["id_PA_NM"] ? filters["id_PA_NM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_VI_NO"] ? filters["id_VI_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_VI_NO",
                        e.target.value,
                        filters["id_VI_NO"]
                          ? filters["id_VI_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_VI_DT"] ? filters["id_VI_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_VI_DT",
                        e.target.value,
                        filters["id_VI_DT"] ? filters["id_VI_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_PH_NO"] ? filters["id_PH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_PH_NO",
                        e.target.value,
                        filters["id_PH_NO"]
                          ? filters["id_PH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["id_DO_NO"] ? filters["id_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "id_DO_NO",
                        e.target.value,
                        filters["id_DO_NO"]
                          ? filters["id_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Patient ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Visit No</th>
                <th style={tableHeaderStyle}>Visit Date</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Doctor</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.idUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.idUkNo}
                        checked={selectedRows.includes(rowData.idUkNo)}
                        onChange={() => handleRowSelect(rowData.idUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.id_PA_ID}</td>
                    <td style={tableCellStyle}>{rowData.id_PA_NM}</td>
                    <td style={tableCellStyle}>{rowData.id_VI_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.id_VI_DT)}</td>
                    <td style={tableCellStyle}>{rowData.id_PH_NO}</td>
                    <td style={tableCellStyle}>{rowData.id_DO_NO}</td>



                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>

      <IpdQueueDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        IpdQueueTable={IpdQueueTable}
        setIpdQueueTable={setIpdQueueTable}
        IpdQueueTable1={IpdQueueTable1}
        setIpdQueueTable1={setIpdQueueTable1}
        id_CO_NO={id_CO_NO}
        setid_CO_NO={setid_CO_NO}
        onchangeDropDownFormDataStatus={onchangeDropDownFormDataStatus}
        tb_UK_NO={tb_UK_NO}
        settb_UK_NO={settb_UK_NO}
        pt_UK_NO={pt_UK_NO}
        setpt_UK_NO={setpt_UK_NO}
      />
    </div>
  );
};

export default IpdQueueMain;
