import React, { useState, useRef, useEffect } from 'react';
import { Toast } from "primereact/toast";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";

const UploadFilePopup = ({ isOpen, onClose, onUploadFile, handleResponse, newMainFolderName, setnewMainFolderName, SubFolderName, setSubFolderName, file, setFile }) => {
  const [formDataState, setFormDataState] = useState(""); // Renamed here
  const [error, setError] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [newMainFolderNameOptions, setnewMainFolderNameOptions] = useState([]);
  const [SubFolderNameOptions, setSubFolderNameOptions] = useState([]);
  const toast = useRef(null);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangeDropDownFormData = (name, value) => {
    setFormDataState(prevFormData => ({ ...prevFormData, [name]: value }));
    setSubFolderName(value);
  };

  const onchangeDropDownFormData3 = (name, value) => {
    setFormDataState(prevFormData => ({ ...prevFormData, [name]: value }));
    setnewMainFolderName(value);
    fetchDropdownOptions1(value);
  };

  useEffect(() => {
    fetchDropdownOptions();
  }, []);

  const urlcost = `${domain}/api/MainFolder/DropDown`;
  const finalUrl = `${urlcost}`;



  const fetchDropdownOptions = async () => {
   
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, 
          "x-flag": localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.mf_SF_NM,
        value: item.mf_SF_NM,
      }));
      setnewMainFolderNameOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const urlSub = `${domain}/api/SubFolder/OnChangeOfMainFolderSubFolder`;
  const finalSubUrl = `${urlSub}`;

  const fetchDropdownOptions1 = async (value) => {
    const requestPayload = {
      "mf_MF_NM": value,
    }
    try {
      const response = await axios.post(finalSubUrl,requestPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, 
          "x-flag": localStorage.getItem("userType"),
        },
      });

      const options = response.data.Result.map((item) => ({
        label: item.sf_SF_NM,
        value: item.sf_SF_NM,
      }));
      setSubFolderNameOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newFolderName.trim() !== '') {
      onUploadFile(newFolderName.trim());
      setNewFolderName('');
    } else {
      toast.current.show({ severity: "warn", summary: "Warning", detail: "Folder name cannot be empty." });
    }

    if (file) {
      onUploadFile(file); // Pass the file to parent for API call
      setFile(null);
    }

  };

  return (
    isOpen && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Upload File</h2>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="newMainFolderName" className="font-bold">
              Main Folder
            </label>
            <Dropdown style={{ width: "10rem" }}
              id="newMainFolderName"
              name="newMainFolderName"
              value={newMainFolderName}              
              options={newMainFolderNameOptions}
              onChange={(e) => onchangeDropDownFormData3("newMainFolderName", e.value)}
              placeholder="Select Main Folder "
            />
          </div> 
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="SubFolderName" className="font-bold">
              Sub Folder
            </label>
            <Dropdown style={{ width: "10rem" }}
              id="SubFolderName"
              name="SubFolderName"
              value={SubFolderName}              
              options={SubFolderNameOptions}
              onChange={(e) => onchangeDropDownFormData("SubFolderName", e.value)}
              placeholder="Select Sub folder "
            />
          </div> 
          <form onSubmit={handleSubmit}>
            {error && <div className="error-message">{error}</div>}
            <input type="file" onChange={handleFileChange} multiple />
            <button type="submit">Upload</button>
            <button type="button" onClick={onClose} aria-label="Cancel creation of folder">Cancel</button>
          </form>
          <Toast ref={toast} /> {/* Add this line to include the Toast component */}
        </div>
      </div>
    )
  );
};

export default UploadFilePopup;
