import React, { useState, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import "./login.css";
import {
  Box,
  Button,
  Flex,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Radio,
  RadioGroup
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import elementVideo from "./element.mp4";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const isProduction = process.env.NODE_ENV === 'production';
const doma = isProduction ? "https://api-" + window.location.host : "http://localhost:8182";
const feDomain = isProduction ? "https://" + window.location.host : "http://localhost:3000";

function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const passwordInputRef = useRef(null);

  const [typeCE, settypeCE] = useState('E');


  // const dom = window.location.host;
  // const beDom = "https://api-";
  // // const doma = beDom + dom;
  
  // const doma="http://localhost:8182";

  const handleClick = () => setShow(!show);
  const handleSignIn = async () => {
    try {
      const data = {
        username: username,
        password: password,
        rememberMe: rememberMe,
      };

      fetch(`${doma}/api/User/login`, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),

        headers: {
          "content-type": "application/json",
          // "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-flag" : typeCE
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.valid === true) {
            localStorage.setItem("token", resp.token);
            localStorage.setItem("company", resp.company);
            localStorage.setItem("domain",doma);
            localStorage.setItem("username",username);
            localStorage.setItem("userType",typeCE);
            toast.success("Logged in successfully!");
            // history.push("/admin/default");
            // window.location.replace(`${feDomain}/admin`);
            if(typeCE==="C"){
              window.location.replace(`${feDomain}/admin/CustomerPortal`);
            }
            else{
              window.location.replace(`${feDomain}/admin`);
            }
            //window.location.replace("http://localhost:3000/admin/default");
          } else {
            toast.error("Invalid credentials..");
          }
        });
    } catch (error) {
      console.error("Error while signing in:", error);
    }
  };
  return (
    <Flex className="login-container" direction={{ base: "column", md: "row" }} p={{ base: "20px", md: "40px" }} gap="20px" justify="center">
   
        <div className="loginAnimation col-12 col-md-6" style={{ padding: "3%" }} id="loginvideo">
          <div className="headermobile"> 
            <Heading color={textColor} fontSize="50px" mb="25px" marginLeft="10%">
              <Flex justify="center" className="doheader"
                    align="center"
                    wrap="nowrap" 
                    direction="row"
                    style={{ whiteSpace: "nowrap",textAlign:'center',width:'100%' }}
              >
                <span className="Do" style={{ color: "#2690af", fontSize: "100%" }}>
                  {" "}
                  Do
                </span>
                <span className="fort" style={{ color: "#a92424", fontSize: "100%" }}>
                  {" "}
                  Fort
                </span>
              </Flex>
            </Heading>
            <Heading className="takebusiness" color={textColor} fontSize="30px" mb="15px" marginLeft="10%" style={{ fontSize: "180%" }} >
              Explore Generative AI Capabilities in DoFort's Solution
            </Heading>
          </div>

         {/* Video Section - Only visible on medium and larger screens */}
          <div className="video-container d-none d-md-block col-md-12" style={{ padding: "3%" }}>
            <video width="800" height="300" autoPlay loop muted>
              <source src={elementVideo} type="video/mp4" />
            </video>
          </div>
        </div>

        {/* Login Details Section */}
        <div className="login-details-container col-12 col-md-6" style={{ padding: "3%" }} id="loginbox">
          <div className="loginDetails" style={{ marginTop: "5%" }}>
            <Flex className="inputtypes" style={{ padding: "5%" }}>
              <FormControl style={{ padding: "40px", backgroundColor: "white",marginTop:'2rem'}} id="formbox">
                {/* radio buttons */}
              <div className="row justify-content-end">
                <div className="col-12 col-md-6">  
                  <RadioGroup onChange={settypeCE} value={typeCE} mb="24px">
                    <Flex justifyContent="end">
                      <div className="row">
                        <div className="col-12 col-md-6 mb-2"> 
                          <Radio value="C" name="type" me="10px" colorScheme="brandScheme">
                            Customer
                          </Radio>
                        </div>
                        <div className="col-12 col-md-6 mb-2"> 
                          <Radio value="E" name="type" colorScheme="brandScheme">
                            Employee
                          </Radio>
                        </div>
                      </div>
                    </Flex>
                  </RadioGroup>
                </div>
              </div>

      
                <FormLabel display="flex" ms="7px" fontSize="120%" fontWeight="1000" color={textColor} mb="10px">
                  Username<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired
                  variant="auth"
                  fontSize="sm"
                  type="text"
                  placeholder="Enter UserName"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  id="field-username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <FormLabel ms="4px" fontSize="120%" fontWeight="1000" color={textColor} display="flex">
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Enter Password"
                    mb="24px"
                    size="lg"
                    fontWeight="500"
                    type={show ? "text" : "password"}
                    variant="auth"
                    id="field-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSignIn();
                      }
                    }}
                    ref={passwordInputRef}
                  />
                  <InputRightElement display="flex" alignItems="center" mt="4px">
                    <Icon color={textColorSecondary} _hover={{ cursor: "pointer" }} as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye} onClick={handleClick} />
                  </InputRightElement>
                </InputGroup>
      

                <Flex justifyContent="space-between" align="center" mb="24px">
                  <div className="row w-100 justify-content-between">
                    <div className="col-12 col-md-6 d-flex align-items-center mb-2 mb-md-0">
                      <FormControl display="flex" alignItems="center">
                        <Checkbox id="remember-login" colorScheme="brandScheme" me="10px" />
                        <FormLabel className="remember" htmlFor="remember-login" mb="0" fontWeight="normal" color={textColor} fontSize="105%">
                          Remember Me
                        </FormLabel>
                      </FormControl>
                    </div>

                    <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                      <NavLink to="/auth/forgot-password">
                        <Text className="forgot" color={textColorBrand} fontSize="100%" w="124px" fontWeight="500">
                          Forgot password ?
                        </Text>
                      </NavLink>
                    </div>
                  </div>
                </Flex>

      
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button className="signinbtn" onClick={handleSignIn} fontSize="sm" variant="brand" fontWeight="500" w="100%" mb="24px">
                    Sign In
                  </Button>
                </div>
                <ToastContainer />
              </FormControl>
            </Flex>
          </div>
        </div>

    </Flex>
  
  );
}
export default SignIn;



