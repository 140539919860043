import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import SpecificationPopup from "../Popups/SpecificationPopup";
import ItemPopup from "../Popups/ItemPopup";
import CustomerPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function EnterTestResultsDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  EnterTestResultsTableData,
  setEnterTestResultsTableData,
  
  SelectedITNM,
  setSelectedITNM,
  tr_CO_CT,
  setsp_CO_CT,
  tr_OP_SQ,
  setsp_PR_GP,
  sp_ST_CD,
  setsp_ST_CD,
  sp_OP_SQ,
  setsp_OP_SQ,
  selectedAbAdNM,
  setselectedAbAdNM,
  tr_OR_CO,
  
}) {
  const {
    trTiId,
    tr_CU_NO,
    tr_IT_NM,
    tr_OR_NO,
    tr_OR_TY,
    tr_LO_TN,
    tr_TS_DT,
    tr_TS_ID,
    tr_TS_DS,
    tr_AL_MI,
    tr_AL_MX,
    tr_TR_VL,
    tr_RS_VL,
    tr_PA_FA,
    tr_SA_SZ,


  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader , Dialogstyle, cancelstyle, savestyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter, fetchCostCenterP } = Costcenter();
  const [sp_CO_CTOptions, setsp_CO_CTOptions] = useState([]);
  const [sp_ST_CDOptions, setsp_ST_CDOptions] = useState([]);
  const [sp_OP_SQOptions, setsp_OP_SQOptions] = useState([]);
  const [sp_PR_GPOptions, setsp_PR_GPOptions] = useState([]);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);


  // Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_CO_CT(value);
  };
  // Cost Centre
  const onchangeDropDownFormDataProductGroup = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_PR_GP(value);
  };

  // Status
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_ST_CD(value);
  };
  // UM
  const OperSeqDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_OP_SQ(value);
  };




  useEffect(() => {
    fetchDropdownCost();
    Status();
    OperSeq();
    ProductGroup();

  }, []);

  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    setsp_CO_CTOptions(Options);
  };
  const OperSeq = async () => {
    const Options = await fetchClassCodeDS("TE/ST");
    setsp_OP_SQOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeCD("SP/ST");
    setsp_ST_CDOptions(Options);
  };
  const ProductGroup = async () => {
    const Options = await fetchClassCodeCD("IN/PD");
    setsp_PR_GPOptions(Options);
  };



  const addRow = () => {
    setEnterTestResultsTableData([...EnterTestResultsTableData, {}]);
  };

  const deleteRow = () => {
    if (EnterTestResultsTableData.length > 0) {
      const updatedData = [...EnterTestResultsTableData];
      updatedData.pop();
      setEnterTestResultsTableData(updatedData);
    }
  };

 

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [addressType, setaddressType] = useState("C");

  const [SpecificationVisible, setISpecificationVisible] = useState(false);

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  // const handleDataSelect = async (data) => {
  //   //setselectedSupplier(data.ab_AD_NM);
  //   document.getElementById(`ep_PR_SU_${SelectedRowIndex}`).value = data.abAdNo;
  //   document.getElementById(`sup_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
  //   setSupplierVisible(false);
  //   setShowSearchIcon(false);
  // };
  const handleSpecification = async (data) => {
    document.getElementById(`sp_SP_ID_${SelectedRowIndex}`).value = data.ts_SP_ID;
    document.getElementById(`spec_DESC_${SelectedRowIndex}`).value = data.ts_SP_DS;
    onchangeDropDownFormDataCostCentre("tr_CO_CT", data.ts_CO_CT);
    onchangeDropDownFormDataStatus("ts_TS_ST", data.ts_TS_ST);

    setISpecificationVisible(false);
    setShowSearchIcon(false);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const handleDataSelect1 = (data) => {
    
    setselectedAbAdNM(data.ab_AD_NM);
    document.getElementById("tr_CU_NO").value = data.abAdNo;
    // onchangeFormData("tr_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("tr_IT_NM").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const openSpecificationMasterPopup = (e) => {
    setISpecificationVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(trTiId);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{trTiId ? "Revise Test Result" : "Add  Test Result"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
         <div style={borderbox}>
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
            <label htmlFor="trTiId" className="font-bold">
              UKNO
            </label>
            <InputText
              id="trTiId"
              name="trTiId"
              value={trTiId}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_OR_NO" className="font-bold">
              Order No
            </label>
            <InputText
              id="tr_OR_NO"
              name="tr_OR_NO"
              defaultValue={tr_OR_NO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_OR_TY" className="font-bold">
              Type 
            </label>
            <InputText
              id="tr_OR_TY"
              name="tr_OR_TY"
              defaultValue={tr_OR_TY}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_OR_CO" className="font-bold">
             Company
            </label>
            <InputText
              id="tr_OR_CO"
              name="tr_OR_CO"
              defaultValue={tr_OR_CO}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_CO_CT" className="font-bold">
              Cost Centre
            </label>
            <Dropdown
              name="tr_CO_CT"
              value={tr_CO_CT}
              options={sp_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormDataCostCentre("tr_CO_CT", e.value)}
              placeholder="Cost Centre"
              disabled
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="pr_IT_NM" className="font-bold">
              Item Number<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedITNM}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openItemPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="tr_IT_NM"
                name="tr_IT_NM"
                defaultValue={tr_IT_NM}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="tr_TS_DT" className="font-bold">
                  Trans. Date
                  </label>
                   <Calendar
                      id="tr_TS_DT"
                      name="tr_TS_DT"
                      value={moment(data.tr_TS_DT, "YYYYMMDD").isValid() ? moment(data.tr_TS_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "tr_TS_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_LO_TN" className="font-bold">
            Batch No
            </label>
            <InputText
              id="tr_LO_TN"
              name="tr_LO_TN"
              defaultValue={tr_LO_TN}
              onChange={(Event) => onchangedata(Event)}
            />
          </div>
       

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="emEmNo" className="font-bold">
                  Customer<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openCustomerPopup}
                    ></i>
                     )}
                  </div>
                 
                  <div  style={{ display: "none" }}>
                    <InputText
                      id="tr_CU_NO"
                      name="tr_CU_NO"
                      value={tr_CU_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="tr_OP_SQ" className="font-bold">
            Operation
            </label>
            <Dropdown
              name="tr_OP_SQ"
              value={tr_OP_SQ}
              options={sp_PR_GPOptions}
              onChange={(e) => onchangeDropDownFormDataProductGroup("tr_OP_SQ", e.value)}
              placeholder="Operation"

            />
          </div>
        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="EnterTestResultstable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Test ID</th>
                <th>Description</th>
                <th>Minimum</th>
                <th>Maximum</th>
                <th>Target</th>
                <th>Results</th>
                <th>Pass/Fail</th>
                <th>Sample Size	</th>
                <th>Defect</th>
                <th>Cause</th>
                <th>Remarks</th>
                <th style={{ display: "none" }}>Company</th>
              </tr>
            </thead>
            <tbody>
              {EnterTestResultsTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`trTiId_${index}`}
                      name={`trTiId_${index}`}
                      value={row.trTiId}
                    />
                  </td>
                  <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_TS_ID_${index}`}
                    name={`tr_TS_ID_${index}`}
                    defaultValue={row.tr_TS_ID}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_TS_DS_${index}`}
                    name={`tr_TS_DS${index}`}
                    defaultValue={row.tr_TS_DS}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_AL_MI_${index}`}
                    name={`tr_AL_MI_${index}`}
                    defaultValue={row.tr_AL_MI}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_AL_MX_${index}`}
                    name={`tr_AL_MX_${index}`}
                    defaultValue={row.tr_AL_MX}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_TR_VL_${index}`}
                    name={`tr_TR_VL_${index}`}
                    defaultValue={row.tr_TR_VL}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_RS_VL_${index}`}
                    name={`tr_RS_VL_${index}`}
                    defaultValue={row.tr_RS_VL}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_PA_FA_${index}`}
                    name={`tr_PA_FA_${index}`}
                    defaultValue={row.tr_PA_FA}
                    onChange={(e) => onchangedata(e)}
                    disabled
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_SA_SZ_${index}`}
                    name={`tr_SA_SZ_${index}`}
                    defaultValue={row.tr_SA_SZ}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_DE_CT_${index}`}
                    name={`tr_DE_CT_${index}`}
                    defaultValue={row.tr_DE_CT}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_CU_SE_${index}`}
                    name={`tr_CU_SE_${index}`}
                    defaultValue={row.tr_CU_SE}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                <td >
                  <InputText style={{width:"100px"}}
                    id={`tr_RM_RK_${index}`}
                    name={`tr_RM_RK_${index}`}
                    defaultValue={row.tr_RM_RK}
                    onChange={(e) => onchangedata(e)}
                    
                  />
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </div>

      <SpecificationPopup
        visible={SpecificationVisible}
        onClose={() => setISpecificationVisible(false)}
        onDataSelect={handleSpecification}
        formData={data}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
      <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressType}
      />

    </Dialog>
  );
}
