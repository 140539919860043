import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ProductDialog from "./ActivityDialog";
import axios from "axios";
import saveAs from "file-saver";
import '../../../components/ListStyle/ListStyle.css';
import { Tooltip } from "primereact/tooltip";
import LeadIDPopup from "./LeadIDPopup";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
const initialValue = {
  la_AC_OW: "",
  la_AC_SD: "",
  la_AC_TY: "",
  la_AC_VT: "",
  la_DO_NO: "",
  la_AC_CD: "",
  la_AC_ED: "",
  la_ST_CD: "",
  la_AC_RE: "",
};
const ActivityPlanMain = () => {
  const initialFilters = {
    cc_EM_NM: { value: "", operation: "" },
    cl_AC_TY: { value: "", operation: "" },
    cl_AC_VT: { value: "", operation: "" },
    la_DO_NO: { value: "", operation: "" },
    la_AC_CU: { value: "", operation: "" },
    la_AC_CD: { value: "", operation: "" },
    ab_AD_NM: { value: "", operation: "" },
    cl_ST_CD: { value: "", operation: "" },
    cl_AC_RE: { value: "", operation: "" },
  };
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,copyButtonStyles,ListOwnerDesc,
  checkboxStyle,tableHeaderStyle,inputStyle,tableCellStyle } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [la_AC_TY, setla_AC_TY] = useState("");
  const [la_AC_VT, setla_AC_VT] = useState("");
  const [la_ST_CD, setla_ST_CD] = useState("");
  const [la_AC_RE, setla_AC_RE] = useState("");
  const [la_DO_NO, setla_DO_NO] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [laUkNo, setlaUkNo] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const[ selectedSLLDID,setSelectedSLLDID] = useState("");
  const [customer, setCustomer] = useState("");
  const [Status, setStatus] = useState("");
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [selectedowner, setSelectedowner] = useState("");
  const [Owner, setOwner] = useState("");
  const [FDate, setFDate] = useState("");
  const [EDate, setEDate] = useState("");
  const [so_OP_STOptions, setso_OP_STOptions] = useState([]);
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [LeadIDPopupVisible, setLeadIDPopupVisible] = useState(false);
  const [selectedcustmer, setSelectedcustmer] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [addressTypeCust, setaddressTypecust] = useState("C");
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");

  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };

  const url11 = `${domain}/api/ClassCode/All`;
  const parameter2 = "AV/ST";
  const finalUrl2 = `${url11}/${parameter2}`;
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setso_OP_STOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      la_AC_OW: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters1 = (sts) => {
    let updatedFilters = { ...filters };
  if (sts !== null) {
    updatedFilters = {
      ...updatedFilters,
      la_ST_CD: {
        value: sts,
        operation: "LIKE",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (own) => {
    let updatedFilters = { ...filters };
  if (own !== null) {
    updatedFilters = {
      ...updatedFilters,
      la_AC_CU: {
        value: own,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;

        if (column === 'slLdId') {
          constructedColumn = 'slLdId';
        } else {
          constructedColumn = column.toUpperCase();
        }

        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setlaUkNo(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setla_AC_TY(null);
    setla_AC_VT(null);
    setla_AC_RE(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setla_AC_TY(null);
    setla_AC_VT(null);
    setla_AC_RE(null);
    setOpen(false);
  };
  const handleDataSelect = (data) => {
    setSelectedowner(data.ab_AD_NM);
    setOwner( data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
    updateFilters (data.abAdNo);
  };
  const handleDataSelect1 = (data) => {
    setSelectedcustmer(data.ab_AD_NM);
    setCustomer(data.abAdNo);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
    updateFilters2 (data.abAdNo);
  };
  const handleDataSelectID = (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    onchangeFormData("la_AC_OW", data.abAdNo);
    setLeadIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setStatus(value);
    updateFilters1 (value);
    console.log(value);
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ActivityPlan/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ActivityPlanData = response.data;
          delete ActivityPlanData.laUkNo;
          setFormData(ActivityPlanData);
          setSelectedAbAdNN(ActivityPlanData.ab_AD_NM);
          setSelectedAbAdNM(ActivityPlanData.cc_EM_NM);
          setla_AC_TY(ActivityPlanData.la_AC_TY);
          setla_AC_VT(ActivityPlanData.la_AC_VT);
          setla_ST_CD(ActivityPlanData.la_ST_CD);
          setla_AC_RE(ActivityPlanData.la_AC_RE);
          setlaUkNo(null);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [la_DO_CO, setla_DO_CO] = useState("");
  const [la_DO_TY, setla_DO_TY] = useState("");
  const [la_AC_OW, setla_AC_OW] = useState("");
  const [la_AC_CU, setla_AC_CU] = useState("");

  const handleDefault = async () => {
    setla_DO_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "LA_DO_TY"){
              setla_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "LA_ST_CD"){
              onchangeDropDownFormData2("la_ST_CD",dataDefault.versionIds[i].default_VALUE);
              setla_ST_CD(dataDefault.versionIds[i].default_VALUE);
            } 
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
    fetchDropdownOptions2();
  }, [currentPage, pageSize, filters]);

  const onPage = (event) => {
    setCurrentPage(event.first / pageSize + 1);
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ActivityPlan/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name = "la_DO_NO") {
      setla_DO_NO(value);
    }
  };
  const onchangeDropDownFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setla_AC_TY(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setla_AC_VT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setla_ST_CD(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setla_AC_RE(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ActivityPlan/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ActivityPlanData = response.data;
        setFormData(ActivityPlanData);
        setSelectedAbAdNN(response.data.ab_AD_NM);
        setSelectedAbAdNM(response.data.cc_EM_NM);
        setla_AC_TY(response.data.la_AC_TY);
        setla_AC_VT(response.data.la_AC_VT);
        setla_ST_CD(response.data.la_ST_CD);
        setla_AC_RE(response.data.la_AC_RE);
        setlaUkNo(ActivityPlanData.laUkNo);
        setla_DO_NO(response.data.la_DO_NO);
        setla_AC_OW(response.data.la_AC_OW);
        setla_AC_CU(response.data.la_AC_CU);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    const requestBodyInput = {
      "laUkNo" : document.getElementById("laUkNo").value,
      "la_DO_NO" : document.getElementById("la_DO_NO").value,
      "la_DO_TY" : document.getElementById("la_DO_TY").value,
      "la_DO_CO" : company,
      "la_AC_OW" : document.getElementById("la_AC_OW").value,
      "la_AC_CU" : document.getElementById("la_AC_CU").value,
      "la_ST_CD" : la_ST_CD,
      "la_AC_SD" : FormData.la_AC_SD,
      "la_AC_ED" : FormData.la_AC_ED,
      "la_AC_CD" : FormData.la_AC_CD,
      "la_AC_TY" : la_AC_TY,
      "la_AC_VT" : la_AC_VT,
      "la_AC_RE" : la_AC_RE
    }
    if (document.getElementById("laUkNo").value) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/ActivityPlan/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios
        .post(`${domain}/api/ActivityPlan/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data; 
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
    <Tooltip target=".update-button" position="bottom" mouseTrack>
      <div style={{ background: "red !important", color: "white", fontSize: "10px" }}>
        Update
      </div>
    </Tooltip>
    <Button
      style={buttonStyles}
      icon="pi pi-check"
      outlined
      className="mr-2 update-button"
      onClick={handleUpdate}
      disabled={!isRecordSelected}
    ></Button>
    {/* <Tooltip target=".search-button" position="bottom" mouseTrack>
      <div
        style={{
          backgroundColor: "transparent !important",
          color: "white",
          fontSize: "10px",
        }}
      >
        Search
      </div>
    </Tooltip>
    <Button
      style={searchButtonStyles}
      icon="pi pi-search"
      outlined
      className="mr-2 search-button"
    ></Button> */}
    <Tooltip target=".add-button" position="bottom" mouseTrack>
      <div  style={{ background: "white !important", color: "white",fontSize: "10px" }} >
        Add
      </div>
    </Tooltip>
    <Button
      style={addButtonStyles}
      icon="pi pi-plus"
      outlined
      className="mr-2 add-button"
      onClick={handleAddButtonClick}
    ></Button>
    <Tooltip target=".copy-button" position="bottom" mouseTrack>
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }} >
        Copy
      </div>
    </Tooltip>
    <Button
      style={copyButtonStyles}
      icon="pi pi-copy"
      outlined
      className="mr-2 copy-button"
      onClick={handleCopyData}
    ></Button>
  </React.Fragment>
  );
  const tableHeaderStyle1 = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
    width:"0%"
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const ListDesc = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"200PX",
    backgroundColor:"#f5f4f4"
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button style={{height: "35px",backgroundColor:"#1ed01e",border:"none"}}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const handleClearButton=()=>{
    setSelectedcustmer("");
    setSelectedowner("");
    setCustomer("");
    setOwner("");
    setStatus("");
    setFilters("");
  };
  const AdvanceSearch = (
      <div className="formgrid grid">
        <div className="field col" >
            <label htmlFor="customer" className="font-bold">
              Customer <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              style={{display:"none"}}
                id="customer"
                name="customer"
                value={customer}
              />
               <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                readOnly
                style={{backgroundColor:"#ffffff"}}
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedcustmer}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
               )}
            </div>
          </div>
      <div className="field col">
        <label htmlFor="Owner" className="font-bold">
          Owner <span style={{ color: 'red' }}>*</span>
        </label>
        <div style={{ display: "flex" }}>
          <InputText
          style={{display:"none"}}
            id="Owner"
            name="Owner"
            value={Owner}
            onChange={(Event) => onchangedata(Event)}
          />
           <InputText
            id="cc_EM_NM"
            name="cc_EM_NM"
            readOnly
            style={{backgroundColor:"#ffffff"}}
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={selectedowner}
          />
           {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openLeadOwnerPopup}
          ></i>
           )}
        </div>
      </div>
        <div className="field col"style={{display:"grid"}} >
          <label htmlFor="Status" className="font-bold">
            Status
          </label>
          <Dropdown
            style={{width:"130px"}}
            id="Status"
            name="Status"
            value={Status}
            options={so_OP_STOptions}
            onChange={(e) => onchangeDropDown("Status", e.value)}
            placeholder="Select Status"
          />
        </div>
        
    </div>
  );
  const rightClear = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-times"
        className="p-button-help"
        link
        onClick={handleClearButton}
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar className="p-mb-4" left={leftToolbarTemplate}  right={rightToolbarTemplate}/>
        <div style={{ marginTop: "2%"}}>
        <Toolbar className="p-mb-4"  left={AdvanceSearch} right={rightClear} />
        </div>
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse", width: "100%",  marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td >
                  <input
                    width="100%"
                    type="text"
                    readOnly
                    style={ListOwnerDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["la_DO_NO"] ? filters["la_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "la_DO_NO",
                        e.target.value,
                        filters["la_DO_NO"]
                          ? filters["la_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_AC_TY"] ? filters["cl_AC_TY"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_AC_TY",
                        e.target.value,
                        filters["cl_AC_TY"]
                          ? filters["cl_AC_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_AC_VT"] ? filters["cl_AC_VT"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_AC_VT",
                        e.target.value,
                        filters["cl_AC_VT"]
                          ? filters["cl_AC_VT"].operation
                          : "equals"
                      )
                    }
                    style={ListOwnerDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["la_AC_SD"] ? filters["la_AC_SD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "la_AC_SD",
                        e.target.value,
                        filters["la_AC_SD"]
                          ? filters["la_AC_SD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["la_AC_ED"] ? filters["la_AC_ED"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "la_AC_ED",
                        e.target.value,
                        filters["la_AC_ED"]
                          ? filters["la_AC_ED"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["la_AC_CD"] ? filters["la_AC_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "la_AC_CD",
                        e.target.value,
                        filters["la_AC_CD"]
                          ? filters["la_AC_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td >
                  <input
                   readOnly
                    width="100%"
                    type="text"
                    style={ListDesc}
                  />
                </td>
                <td >
                  <input
                   readOnly
                    width="100%"
                    type="text"
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_AC_RE"] ? filters["cl_AC_RE"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_AC_RE",
                        e.target.value,
                        filters["cl_AC_RE"]
                          ? filters["cl_AC_RE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle1}></th>
                <th style={tableHeaderStyle}>Lead Owner</th>
                <th style={tableHeaderStyle}>Lead ID</th>
                <th style={tableHeaderStyle}>Activity Type</th>
                <th style={tableHeaderStyle}>Activity Plan</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Compl. Date</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Activity Result</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.laUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        type="checkbox"
                        value={rowData.laUkNo}
                        checked={selectedRows.includes(rowData.laUkNo)}
                        onChange={() => handleRowSelect(rowData.laUkNo)}
                      />
                    </td>

                    <td style={tableCellStyle}>{rowData.cc_EM_NM}</td>
                    <td style={tableCellStyle}>{rowData.la_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_AC_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_AC_VT}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.la_AC_SD)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.la_AC_ED)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.la_AC_CD)}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={tableCellStyle}>{rowData.cl_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.cl_AC_RE}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
        {/* Customer */}
        <AddressBookPopup
          visible={CustomerPopupVisible}
          onClose={() => setCustomerPopupVisible(false)}
          onDataSelect={handleDataSelect1}
          formData={FormData}
          addressType={addressTypeCust}
          setFormData={setFormData}
        />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect}
        formData={FormData}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <LeadIDPopup
        visible={LeadIDPopupVisible}
        onClose={() => setLeadIDPopupVisible(false)}
        onDataSelect={handleDataSelectID}
        // formData={data}
      />
      <ProductDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        onchangeDropDownFormData={onchangeDropDownFormData}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        la_AC_TY={la_AC_TY}
        la_AC_VT={la_AC_VT}
        la_ST_CD={la_ST_CD}
        la_AC_RE={la_AC_RE}
        la_DO_CO={la_DO_CO}
        la_DO_TY={la_DO_TY}
        la_DO_NO={la_DO_NO}
        la_AC_OW={la_AC_OW}
        la_AC_CU={la_AC_CU}
        setla_DO_NO={setla_DO_NO}
        setla_AC_OW={setla_AC_OW}
        setla_AC_CU={setla_AC_CU}
        selectedAbAdNM={selectedAbAdNM}
        selectedSLLDID={selectedSLLDID}
        selectedAbAdNN={selectedAbAdNN}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedAbAdNN={setSelectedAbAdNN}
        setSelectedSLLDID={setSelectedSLLDID}
        laUkNo={laUkNo}
      />
    </div>
  );
};

export default ActivityPlanMain;
