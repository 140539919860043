import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function DistributionPostingInstructionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  DistributionPostingInstructionTableData,
  setDistributionPostingInstructionTableData,
  dp_CO_NO,
  SelectedITNM,
  setSelectedITNM,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  dp_CO_NM,
  setdp_CO_NM,
  dp_PO_IT,
  setdp_PO_IT,
  dp_DO_TY,
  setdp_DO_TY,
  dp_OR_TY,
  setdp_OR_TY,
  dp_GL_CD,
  setdp_GL_CD,
  dp_TY_PE,
  setdp_TY_PE,
}) {
  const { dpUkNo, } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton,
     tablestyle, multitablestyle, savestyle, cancelstyle, Dialogstyle } = ButtonStyles();

  // Dropdown
  const { fetchClassCodeDS,  } = ClassCode();
  const { fetchCostCenter,  } = Costcenter();

  const [dp_PO_ITOptions, setdp_PO_ITOptions] = useState([]);
  const [dp_CO_NMOptions, setdp_CO_NMOptions] = useState([]);
  const [dp_OR_TYOptions, setdp_OR_TYOptions] = useState([]);
  const [dp_DO_TYOptions, setdp_DO_TYOptions] = useState([]);
  const [dp_GL_CDOptions, setdp_GL_CDOptions] = useState([]);
  const [dp_TY_PEOptions, setdp_TY_PEOptions] = useState([]);
  // Order Type
  const onchangeDropDownFormDataOrderType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_OR_TY(value);
  };
  // Document Type
  const onchangeDropDownFormDataDocumentType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_DO_TY(value);
  };
  // PostinItem
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_PO_IT(value);
  };
  // CategoryGL
  const onchangeDropDownFormDataCategoryGL = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_GL_CD(value);
  };
  // Cost Centre
  const onchangeDropDownFormDataCostCentre = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_CO_NM(value);
  };
  // Type
  const onchangeDropDownFormDataType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdp_TY_PE(value);
  };

  useEffect(() => {
    Costcenter1();
    PostingItem();
    OrderType();
    DocumentType();
    CategoryGL();
    Type();
  }, []);
  const OrderType = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setdp_OR_TYOptions(Options);
  };
  const DocumentType = async () => {
    const Options = await fetchClassCodeDS("FO/DT");
    setdp_DO_TYOptions(Options);
  };
  const PostingItem = async () => {
    const Options = await fetchClassCodeDS("PO/IN");
    setdp_PO_ITOptions(Options);
  };
  const CategoryGL = async () => {
    const Options = await fetchClassCodeDS("IN/GC");
    setdp_GL_CDOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("DI/TY");
    setdp_TY_PEOptions(Options);
  };
  const Costcenter1 = async () => {
    const Options = await fetchCostCenter();
    setdp_CO_NMOptions(Options);
  };
  const addRow = () => {
    setDistributionPostingInstructionTableData([...DistributionPostingInstructionTableData, {}]);
  };
  const deleteRow = () => {
    if (DistributionPostingInstructionTableData.length > 0) {
      const updatedData = [...DistributionPostingInstructionTableData];
      updatedData.pop();
      setDistributionPostingInstructionTableData(updatedData);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("dp_PO_IT").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(dpUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{dpUkNo ? "Revise Distribution Posting Instruction" : "Add Distribution Posting Instruction"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="dp_PO_IT" className="font-bold">
                Posting Item Number
              </label>
              <Dropdown
                name="dp_PO_IT"
                value={dp_PO_IT}
                options={dp_PO_ITOptions}
                onChange={(e) => onchangeDropDownFormData("cr_TO_CR", e.value)}
                placeholder=" Posting Item Number"
              />
            </div>
            <div style={{ display: "none" }} className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="dp_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="dp_CO_NO"
                name="dp_CO_NO"
                value={dp_CO_NO}
              />
            </div>

          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="DistributionPostingInstructiontable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Company</th>
                  <th>Order Type</th>
                  <th>Document Type</th>
                  <th>Category - G/L</th>
                  <th>Cost Center</th>
                  <th>Object Account</th>
                  <th>Subsidiary </th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {DistributionPostingInstructionTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`dpUkNo_${index}`}
                        name={`dpUkNo_${index}`}
                        value={row.dpUkNo}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }} disabled
                        id={`dp_CO_NO_${index}`}
                        name={`dp_CO_NO_${index}`}
                        defaultValue={dp_CO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`dp_OR_TY_${index}`}
                        name={`dp_OR_TY_${index}`}
                        value={data[`dp_OR_TY_${index}`] ? data[`dp_OR_TY_${index}`] : row.dp_OR_TY}
                        options={dp_OR_TYOptions}
                        onChange={(e) => onchangeDropDownFormDataOrderType(`dp_OR_TY_${index}`, e.value)}
                        placeholder="Order Type"
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`dp_DO_TY_${index}`}
                        name={`dp_DO_TY_${index}`}
                        value={data[`dp_DO_TY_${index}`] ? data[`dp_DO_TY_${index}`] : row.dp_DO_TY}
                        options={dp_DO_TYOptions}
                        onChange={(e) => onchangeDropDownFormDataDocumentType(`dp_DO_TY_${index}`, e.value)}
                        placeholder="Document Type"
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`dp_GL_CD_${index}`}
                        name={`dp_GL_CD_${index}`}
                        value={data[`dp_GL_CD_${index}`] ? data[`dp_GL_CD_${index}`] : row.dp_GL_CD}
                        options={dp_GL_CDOptions}
                        onChange={(e) => onchangeDropDownFormDataCategoryGL(`dp_GL_CD_${index}`, e.value)}
                        placeholder="Category - G/L"
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`dp_CO_NM_${index}`}
                        name={`dp_CO_NM_${index}`}
                        value={data[`dp_CO_NM_${index}`] ? data[`dp_CO_NM_${index}`] : row.dp_CO_NM}
                        options={dp_CO_NMOptions}
                        onChange={(e) => onchangeDropDownFormDataCostCentre(`dp_CO_NM_${index}`, e.value)}
                        placeholder="Cost center"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }}
                        id={`dp_OB_AC_${index}`}
                        name={`dp_OB_AC_${index}`}
                        defaultValue={row.dp_OB_AC}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "10rem" }}
                        id={`dp_OB_SB_${index}`}
                        name={`dp_OB_SB_${index}`}
                        defaultValue={row.dp_OB_SB}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown style={{ width: "10rem" }}
                        id={`dp_TY_PE_${index}`}
                        name={`dp_TY_PE_${index}`}
                        value={data[`dp_TY_PE_${index}`] ? data[`dp_TY_PE_${index}`] : row.dp_TY_PE}
                        options={dp_TY_PEOptions}
                        onChange={(e) => onchangeDropDownFormDataType(`dp_TY_PE_${index}`, e.value)}
                        placeholder="Type"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      { /*Posting Item Number */}
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
