import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ManagerPopup from "../Popups/ManagerPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function WorkOrderRoutingDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  WorkOrderRoutingTable,
  setWorkOrderRoutingTable,
  wr_OR_CO,
  item_DESC,
  setitem_DESC,
  wr_CO_CT,
  setwr_CO_CT,
  wr_WR_TY,
  setwr_WR_TY,
  wr_BT_UM,
  setwr_BT_UM,
  wr_TB_CD,
  setwr_TB_CD,

}) {
  const {
    wr_UK_NO,
    wrOrNo,
    wr_IT_NM,
    wr_BT_QT,
  } = data;
  // buttonstyles
  const {plusstyle,minusstyle,tablebutton,tablestyle,borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [wr_CO_CTOptions, setwr_CO_CTOptions] = useState([]);
  const [wr_WR_TYOptions, setwr_WR_TYOptions] = useState([]);
  const [wr_BT_UMOptions, setwr_BT_UMOptions] = useState([]);
  const [wr_TB_CDOptions, setwr_TB_CDOptions] = useState([]);
  // Cost Center
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwr_CO_CT(value);
  };
  // type
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwr_WR_TY(value);
  };
  // UM 
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwr_BT_UM(value);
  };
  // Time Basis
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setwr_TB_CD(value);
  };
  useEffect(() => {
    FetchCostCenter();
    FetchType();
    FetchUM();
    FetchTimeBasis();
  }, []);
  const FetchCostCenter = async () => {
    const Options = await fetchCostCenter();
    setwr_CO_CTOptions(Options);
  };
  const FetchType = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setwr_WR_TYOptions(Options);
  };
  const FetchUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setwr_BT_UMOptions(Options);
  };
  const FetchTimeBasis = async () => {
    const Options = await fetchClassCodeDS("EX/ST");
    setwr_TB_CDOptions(Options);
  };

  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedEmployee, setSelectedEmployee] = useState("");
  const [SelectedManager, setSelectedManager] = useState("");
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ManagerPopupVisible, setManagerPopupVisible] = useState(false);
  const openItemPopup = () => {
    setEmployeePopupVisible(true);
  }
  const openManagerPopup = () => {
    setManagerPopupVisible(true);
  }
  const handleEmployee = async (data) => {
    setSelectedEmployee(data.ab_AD_NM);
    onchangeFormData("er_EM_NO", data.abAdNo);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleManager = async (data) => {
    setSelectedManager(data.ab_AD_NM);
    onchangeFormData("er_MN_NO", data.abAdNo);
    setManagerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setWorkOrderRoutingTable([...WorkOrderRoutingTable, {}]);
  };
  const deleteRow = () => {
    if (WorkOrderRoutingTable.length > 0) {
      const updatedData = [...WorkOrderRoutingTable];
      updatedData.pop();
      setWorkOrderRoutingTable(updatedData);
    }
  };  
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{wr_UK_NO ? "Revise WO Routing" : "Add WO Routing"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
          <div style={inboxstyle}>
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wrOrNo" className="font-bold">
              Order No
              </label>
              <InputText disabled
                id="wrOrNo"
                name="wrOrNo"
                value={wrOrNo}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wr_WR_TY" className="font-bold">
              Order Type 
              </label>
              <InputText disabled
                id="wr_WR_TY"
                name="wr_WR_TY"
                value={wr_WR_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wr_OR_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="wr_OR_CO"
                name="wr_OR_CO"
                value={wr_OR_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wr_IT_NM" className="font-bold">
                Item Number <span style={{ color: 'red' }}>*</span>
              </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="item_DESC"
                    name="item_DESC"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}                      
                    value={item_DESC}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openItemPopup}
                  ></i>
                  )}
                </div>
                <InputText style={{ display: "block" }}
                  id="wr_IT_NM"
                  name="wr_IT_NM"
                  value={wr_IT_NM}
                  onChange={(Event) => onchangedata(Event)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wr_CO_CT" className="font-bold">
              Cost Center 
              </label>
              <Dropdown
                name="wr_CO_CT"
                value={wr_CO_CT}
                options={wr_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData1("wr_CO_CT", e.value)}
                placeholder="Select Cost Center"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="wr_WR_TY" className="font-bold">
            Type
            </label>
            <Dropdown
              name="wr_WR_TY"
              value={wr_WR_TY}
              options={wr_WR_TYOptions}
              onChange={(e) => onchangeDropDownFormData2("wr_WR_TY", e.value)}
              placeholder="Select Type"
            />
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="wr_BT_UM" className="font-bold">
              UM
              </label>
                <Dropdown
                  name="wr_BT_UM"
                  value={wr_BT_UM}
                  options={wr_BT_UMOptions}
                  onChange={(e) => onchangeDropDownFormData3("wr_BT_UM", e.value)}
                  placeholder="Select Expense Type"
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="wr_BT_QT" className="font-bold">
                  Quantity
                  </label>
                  <InputText
                    id="wr_BT_QT"
                    name="wr_BT_QT"
                    value={wr_BT_QT}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div> 
            </div>
          </div>
          {/*TABLE  */}
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              <Button
                icon="pi pi-plus"
                onClick={addRow}
                style={plusstyle}
                tooltip="Add"
              />
              <Button
                icon="pi pi-minus"
                onClick={deleteRow}
                style={minusstyle}
                tooltip="Delete"
              />
            </div>
            <div style={{display:"flex",overflow:"auto"}}>
            <table className="custom-table" id="WorkOrderRoutingTable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th> Line No </th>
                  <th> Work Center </th>
                  <th> Description </th>
                  <th> Oper. Seq. </th>
                  <th> Description </th>
                  <th> Instruction </th>
                  <th> Labour Hrs </th>
                  <th> Machine Hrs </th>
                  <th> Setup Hrs </th>
                  <th> Time Basis </th>
                  <th> From Date </th>
                  <th> To Date </th>
                </tr>
              </thead>
              <tbody>
                {WorkOrderRoutingTable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`wr_UK_NO_${index}`}
                        name={`wr_UK_NO_${index}`}
                        value={row.wr_UK_NO}
                      />
                    </td>
                    <td>
                    <InputText disabled
                      style={{ width: "7rem" }}
                      id={`wr_LN_NO_${index}`}
                      name={`wr_LN_NO_${index}`}
                      value={index + 1}
                    />
                    </td>
                  <td>
                    <InputText style={{ width: "10rem" }}
                        id={`wr_WK_CT_${index}`}
                        name={`wr_WK_CT_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.wr_WK_CT ? row.wr_WK_CT: row[`${"wr_WK_CT"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "15rem" }}
                        id={`workcenter_DESC_${index}`}
                        name={`workcenter_DESC_${index}`}
                        defaultValue={row.workcenter_DESC ? row.workcenter_DESC : row[`${"workcenter_DESC"}_${index}`]}
                      />
                    </td>
                    <td>
                    <InputText style={{ width: "10rem" }}
                        id={`wr_OP_DS_${index}`}
                        name={`wr_OP_DS_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.wr_OP_DS ? row.wr_OP_DS: row[`${"wr_OP_DS"}_${index}`]}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "15rem" }}
                        id={`opersque_DESC_${index}`}
                        name={`opersque_DESC_${index}`}
                        defaultValue={row.opersque_DESC ? row.opersque_DESC : row[`${"opersque_DESC"}_${index}`]}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}}
                        id={`wr_IN_ST_${index}`}
                        name={`wr_IN_ST_${index}`}
                        value={row.wr_IN_ST}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{width:"10rem"}}
                        id={`wr_LB_HR_${index}`}
                        name={`wr_LB_HR_${index}`}
                        value={row.wr_LB_HR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText style={{width:"10rem"}}
                        id={`wr_MC_HR_${index}`}
                        name={`wr_MC_HR_${index}`}
                        value={row.wr_MC_HR}
                        onChange={(e) => onchangedata(e)}
                      />
                      </td>
                    <td >
                      <InputText style={{width:"10rem"}}
                        id={`wr_ST_HR_${index}`}
                        name={`wr_ST_HR_${index}`}
                        value={row.wr_ST_HR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown style={{width:"10rem"}}
                        id={`wr_TB_CD_${index}`}
                        name={`wr_TB_CD_${index}`}
                        value={ data[`wr_TB_CD_${index}`] ? data[`wr_TB_CD_${index}`]: row.wr_TB_CD}
                        options={wr_TB_CDOptions}
                        onChange={(e) =>onchangeDropDownFormData4(`wr_TB_CD_${index}`, e.value)}
                        placeholder="Select Time Basis"
                      />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                      id={`wr_FR_DT_${index}`}
                      name={`wr_FR_DT_${index}`}
                      value={row.wr_FR_DT? moment(row.wr_FR_DT, "YYYYMMDD").toDate(): null}
                      onChange={(e) => onDateChange({ target: { name: "wr_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                    </td>
                    <td>
                    <Calendar style={{width:"150px"}}
                      id={`wr_T0_DT_${index}`}
                      name={`wr_T0_DT_${index}`}
                      value={row.wr_T0_DT? moment(row.wr_T0_DT, "YYYYMMDD").toDate(): null}
                      onChange={(e) => onDateChange({ target: { name: "wr_T0_DT", value: moment(e.value).format("YYYYMMDD") } })}
                      dateFormat="dd/mm/yy"
                    />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
      </div>
      <ItemPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleEmployee}
        formData={data}
      />
      <ManagerPopup
        visible={ManagerPopupVisible}
        onClose={() => setManagerPopupVisible(false)}
        onDataSelect={handleManager}
        formData={data}
      />
    </Dialog>
  );
}
