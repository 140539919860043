import React, { useState, useEffect , useRef} from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { InputMask } from "primereact/inputmask";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import '../../common/TabStyles.css';
import { TabView, TabPanel } from "primereact/tabview";
export default function CampaignDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  FormData,
  setFormData,
  cp_BI_NG,
  setcp_BI_NG,
  cp_AC_ON,
  setcp_AC_ON,
  cp_LO_CN,
  setcp_LO_CN,
  cp_LN_GE,
  setcp_LN_GE,
  cp_DM_PH,
  setcp_DM_PH,
  cp_FB_OB,
  setcp_FB_OB,
  cp_BU_TY,
  setcp_BU_TY,
  cp_CA_CY,
  setcp_CA_CY,
  cp_FB_PO,
  setcp_FB_PO,
  cp_OP_GO,
  setcp_OP_GO,
  cp_BI_EV,
  setcp_BI_EV,
  cp_ST_AT,
  setcp_ST_AT,
  cp_BU_GT,
  setcp_BU_GT,
  cp_GE_DR,
  setcp_GE_DR,
  cp_DS_UN,
  setcp_DS_UN,
  cp_PL_FB,
  setcp_PL_FB,
  cp_PL_IN,
  setcp_PL_IN,
  cp_PL_AN,
  setcp_PL_AN,
  cp_PL_MS,
  setcp_PL_MS,
  cp_CI_TY,
  setcp_CI_TY,
  cp_RE_GN,
  setcp_RE_GN,
  cp_BD_TY,
  setcp_BD_TY,
  cp_CA_AC,
  setcp_CA_AC,
  cp_CO_NO,
  cp_CP_NO,
  cp_CP_TY,
  cq_TY_PE,
  setcq_TY_PE,
  cq_QU_TN,
  setcq_QU_TN,
  QuenstionTableData,
  setQuenstionTableData,
  AnswerTableData,
  setAnswerTableData,
  onChangeQuestionData,
}) {
  const {
    cpUkNo,
    cp_CP_NM,
    cp_IM_ON,
    cp_CL_CK,
    cp_CN_CT,
    cp_CT_PL,
    cp_CV_RT,
    cp_RT_IV,
    cp_BD_AM,cp_MX_AG,cp_MI_AG,cp_RA_DI,
    cp_DA_BU,cp_CA_ME,cp_WE_LI,cp_PR_PO,cp_FO_WU,cp_ST_TM,cp_EN_TM
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,
    plusstyle,minusstyle,tablebutton,tablestyle,TabBoxStyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // dropdowns
  const { fetchClassCodeDS } = ClassCode();
  const [cp_BI_NGOptions, setcp_BI_NGOptions] = useState([]);
  const [cp_AC_ONOptions, setcp_AC_ONOptions] = useState([]);
  const [cp_LO_CNOptions, setcp_LO_CNOptions] = useState([]);
  const [cp_LN_GEOptions, setcp_LN_GEOptions] = useState([]);
  const [cp_DM_PHOptions, setcp_DM_PHOptions] = useState([]);
  const [cp_FB_OBOptions, setcp_FB_OBOptions] = useState([]);
  const [cp_BU_TYOptions, setcp_BU_TYOptions] = useState([]);
  const [cp_CA_CYOptions, setcp_CA_CYOptions] = useState([]);
  const [cp_FB_POOptions, setcp_FB_POOptions] = useState([]);
  const [cp_OP_GOOptions, setcp_OP_GOOptions] = useState([]);
  const [cp_BI_EVOptions, setcp_BI_EVOptions] = useState([]);
  const [cp_ST_ATOptions, setcp_ST_ATOptions] = useState([]);
  const [cp_CA_ACOptions, setcp_CA_ACOptions] = useState([]);
  const [cq_TY_PEOptions, setcq_TY_PEOptions] = useState([]);
  const [cq_QU_TNOptions, setcq_QU_TNOptions] = useState([]);
  const [cp_BD_TYOptions, setcp_BD_TYOptions] = useState([]);
  const [cp_GE_DROptions, setcp_GE_DROptions] = useState([]);

  const [cp_PL_FBOptions, setcp_PL_FBOptions] = useState([]);
  const [cp_PL_INOptions, setcp_PL_INOptions] = useState([]);
  const [cp_PL_ANOptions, setcp_PL_ANOptions] = useState([]);
  const [cp_PL_MSOptions, setcp_PL_MSOptions] = useState([]);
  const [cp_DS_UNOptions, setcp_DS_UNOptions] = useState([]);
  const [cp_CI_TYOptions, setcp_CI_TYOptions] = useState([]);
  const [cp_RE_GNOptions, setcp_RE_GNOptions] = useState([]);

// BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_BI_NG(value);
  };
// Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_AC_ON(value);
  };
// loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_LO_CN(value);
  };
// lang
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_LN_GE(value);
  };
// demogr
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_DM_PH(value);
  };
// Objective
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_FB_OB(value);
  };
  // buying type
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_BU_TY(value);
  };
  // Country
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_CA_CY(value);
  };
  // FacebookPO
  const onchangeDropDownFormData9 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_FB_PO(value);
  };
  // Op Goal
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_OP_GO(value);
  };
  // billing Event
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_BI_EV(value);
  };
  // Status
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_ST_AT(value);
  };
  // Call To action
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_CA_AC(value);
  };
  // Type
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcq_TY_PE(value);
  };
  // Question
  const onchangeDropDownFormData15 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcq_QU_TN(value);
  };
  // BUDGET
  const onchangeDropDownFormData16 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_BD_TY(value);
  };
  // gENDER
  const onchangeDropDownFormData17 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_GE_DR(value);
  };
// fb
  const onchangeDropDownFormData18 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_PL_FB(value);
  };
  const onchangeDropDownFormData19 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_PL_IN(value);
  };
  const onchangeDropDownFormData20 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_PL_AN(value);
  };
  const onchangeDropDownFormData21 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_PL_MS(value);
  };
  const onchangeDropDownFormData22 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_DS_UN(value);
  };
  const onchangeDropDownFormData23 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_CI_TY(value);
  };
  const onchangeDropDownFormData24 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setcp_RE_GN(value);
  };

  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
    fetchDropdownLG();
    fetchDropdownDM();
    fetchDropdownOB();
    fetchDropdownBTY();
    fetchDropdownCN();
    fetchDropdownFP();
    fetchDropdownOGL();
    fetchDropdownBEV();
    fetchDropdownST();
    fetchDropdownCA();
    fetchDropdownTYP();
    fetchDropdownQUS();
    fetchDropdownBud();
    fetchDropdownGen();
    fetchDropdownCT();
    fetchDropdownRG();
    fetchDropdownFBPL();
    fetchDropdownINPL();
    fetchDropdownANPL();
    fetchDropdownMSPL();
    fetchDropdownDST();
  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("CP/BD");
    setcp_BI_NGOptions(Options);
  };
  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("CP/AQ");
    setcp_AC_ONOptions(Options);
  };
  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("CP/LN");
    setcp_LO_CNOptions(Options);
  };
  const fetchDropdownLG = async () => {
    const Options = await fetchClassCodeDS("CP/LG");
    setcp_LN_GEOptions(Options);
  };
  const fetchDropdownDM = async () => {
    const Options = await fetchClassCodeDS("CP/DG");
    setcp_DM_PHOptions(Options);
  };
  const fetchDropdownOB = async () => {
    const Options = await fetchClassCodeDS("CP/OB");
    setcp_FB_OBOptions(Options);
  };
  const fetchDropdownBTY = async () => {
    const Options = await fetchClassCodeDS("BY/TY");
    setcp_BU_TYOptions(Options);
  };
  const fetchDropdownCN = async () => {
    const Options = await fetchClassCodeDS("CP/CN");
    setcp_CA_CYOptions(Options);
  };
  const fetchDropdownFP = async () => {
    const Options = await fetchClassCodeDS("FB/PO");
    setcp_FB_POOptions(Options);
  };
  const fetchDropdownOGL = async () => {
    const Options = await fetchClassCodeDS("CP/GL");
    setcp_OP_GOOptions(Options);
  };
  const fetchDropdownBEV = async () => {
    const Options = await fetchClassCodeDS("BL/EV");
    setcp_BI_EVOptions(Options);
  };
  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("CP/ST");
    setcp_ST_ATOptions(Options);
  };
  const fetchDropdownCA = async () => {
    const Options = await fetchClassCodeDS("CP/AC");
    setcp_CA_ACOptions(Options);
  };
  const fetchDropdownTYP = async () => {
    const Options = await fetchClassCodeDS("CP/TY");
    setcq_TY_PEOptions(Options);
  };
  const fetchDropdownQUS = async () => {
    const Options = await fetchClassCodeDS("CP/AC");
    setcq_QU_TNOptions(Options);
  };
  const fetchDropdownBud = async () => {
    const Options = await fetchClassCodeDS("CP/BG");
    setcp_BD_TYOptions(Options);
  };
  const fetchDropdownGen = async () => {
    const Options = await fetchClassCodeDS("EM/GN");
    setcp_GE_DROptions(Options);
  };
  const fetchDropdownCT = async () => {
    const Options = await fetchClassCodeDS("CP/CI");
    setcp_CI_TYOptions(Options);
  };
  const fetchDropdownRG = async () => {
    const Options = await fetchClassCodeDS("CP/RG");
    setcp_RE_GNOptions(Options);
  };
  const fetchDropdownFBPL = async () => {
    const Options = await fetchClassCodeDS("CP/FB");
    setcp_PL_FBOptions(Options); 
  };
  const fetchDropdownINPL = async () => {
    const Options = await fetchClassCodeDS("CP/IN");
    setcp_PL_INOptions(Options);
  };
  const fetchDropdownANPL = async () => {
    const Options = await fetchClassCodeDS("CP/AN");
    setcp_PL_ANOptions(Options);
  };
  const fetchDropdownMSPL = async () => {
    const Options = await fetchClassCodeDS("CP/MS");
    setcp_PL_MSOptions(Options);
  };
  const fetchDropdownDST = async () => {
    const Options = await fetchClassCodeDS("CP/UN");
    setcp_DS_UNOptions(Options);
  };
  const fetchDropdownbUDTY = async () => {
    const Options = await fetchClassCodeDS("CP/UN");
    setcp_DS_UNOptions(Options);
  };


  // TAB THINGS
  const [activeTab, setActiveTab] = useState(0);
  const addRow = () => {
    setQuenstionTableData([...QuenstionTableData, {}]);
  };
  const deleteRow = () => {
    if (QuenstionTableData.length > 0) {
      const updatedData = [...QuenstionTableData];
      updatedData.pop();
      setQuenstionTableData(updatedData);
    }
  };
  const addRowANS = () => {
    setAnswerTableData([...AnswerTableData, {}]);
    const nextSequence = AnswerTableData.length + 1;
    const newRow = {
      caUkNo: "",
      ca_QU_NO: nextSequence,
      ca_AN_NO: "",
      ca_CP_NO: "",
      ca_CP_TY: "",
    };
    setAnswerTableData([...AnswerTableData, newRow]);
  };
  const deleteRowANS = () => {
    if (AnswerTableData.length > 0) {
      const updatedData = [...AnswerTableData];
      updatedData.pop();
      setAnswerTableData(updatedData);
    }
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const onRowSelect = (index) => {
    setSelectedRowIndex(index); 
  };

  const questionselect = async (sequenceValue) => {
    if (!sequenceValue) return;
    const Url = `${domain}/api/Campaign/GetAnswers`;
    const requestData = {
      "campaignAnswersList": {
        "ca_QU_NO": sequenceValue,
        "ca_CP_NO": document.getElementById("cp_CP_NO").value,
        "ca_CP_TY": document.getElementById("cp_CP_TY").value
      },
    };
    try {
      console.log("getting answers" + requestData)
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      console.log("API response:", response.data);
      setAnswerTableData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Handle form data change
  const onChangeTime=(name,value,index)=>{
    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
  };
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

    const handleCheckboxChange = async (index) => {
    if (index == selectedCheckbox) {
      setSelectedCheckbox(null);
      setAnswerTableData([]);
    }
    else{
      const sequenceValues = AnswerTableData.map((item) => item?.ca_QU_NO);
      const sequenceValue = sequenceValues[index];
      setSelectedCheckbox(index);
      await questionselect(sequenceValue);
      addRowANS(); 
    }
  };
  const [CamLeadDetailData, setCamLeadDetailData] = useState([{}]);
  const addRowLeadDetail = () => {
    setCamLeadDetailData([...CamLeadDetailData, {}]);
  };
  
  const deleteRowLeadDetail = () => {
    if (CamLeadDetailData.length > 0) {
      const updatedData = [...CamLeadDetailData];
      updatedData.pop();
      setCamLeadDetailData(updatedData);
    }
  };


  const isUpdateMode = Boolean(cpUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{cpUkNo ? "Revise Campaign" : "Add Campaign"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
      )}
       {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
       )}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General" className="tab-header">
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CP_NO" className="font-bold">
                      Campaign No
                    </label>
                    <InputText  disabled
                      id="cp_CP_NO"
                      name="cp_CP_NO"
                      value={cp_CP_NO}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CP_TY" className="font-bold">
                      Campaign Type
                    </label>
                    <InputText disabled
                      id="cp_CP_TY"
                      name="cp_CP_TY"
                      value={cp_CP_TY}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CP_NM" className="font-bold">
                    Campaign Name
                    </label>
                    <InputText 
                      id="cp_CP_NM"
                      name="cp_CP_NM"
                      value={cp_CP_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BI_NG" className="font-bold">
                    Bidding Type
                    </label>
                    <Dropdown
                      id="cp_BI_NG"
                      name="cp_BI_NG"
                      value={cp_BI_NG}
                      options={cp_BI_NGOptions}
                      onChange={(e) => onchangeDropDownFormData1("cp_BI_NG", e.value)}
                      placeholder="Select Bidding Type"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_AC_ON" className="font-bold">
                      Acquisition
                    </label>
                    <Dropdown
                      id="cp_AC_ON"
                      name="cp_AC_ON"
                      value={cp_AC_ON}
                      options={cp_AC_ONOptions}
                      onChange={(e) => onchangeDropDownFormData2("cp_AC_ON", e.value)}
                      placeholder="Select Acquisition"
                    />
                  </div>
                  {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_LO_CN" className="font-bold">
                      Location
                    </label>
                    <Dropdown
                      id="cp_LO_CN"
                      name="cp_LO_CN"
                      value={cp_LO_CN}
                      options={cp_LO_CNOptions}
                      onChange={(e) => onchangeDropDownFormData3("cp_LO_CN", e.value)}
                      placeholder="Select Location"
                    />
                  </div> */}
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_LN_GE" className="font-bold">
                        Language
                    </label>
                    <Dropdown
                      id="cp_LN_GE"
                      name="cp_LN_GE"
                      value={cp_LN_GE}
                      options={cp_LN_GEOptions}
                      onChange={(e) => onchangeDropDownFormData4("cp_LN_GE", e.value)}
                      placeholder="Select Language"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_DM_PH" className="font-bold">
                      Demogrphic
                    </label>
                    <Dropdown
                      id="cp_DM_PH"
                      name="cp_DM_PH"
                      value={cp_DM_PH}
                      options={cp_DM_PHOptions}
                      onChange={(e) => onchangeDropDownFormData5("cp_DM_PH", e.value)}
                      placeholder="Select Demographic"
                    />
                  </div>                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_ST_DT" className="font-bold">
                      Start Date
                    </label>
                    <Calendar
                      id="cp_ST_DT"
                      name="cp_ST_DT"
                      value={moment(data.cp_ST_DT, "YYYYMMDD").isValid() ? moment(data.cp_ST_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "cp_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_EN_DT" className="font-bold">
                      End Date
                    </label>
                    <Calendar
                      id="cp_EN_DT"
                      name="cp_EN_DT"
                      value={moment(data.cp_EN_DT, "YYYYMMDD").isValid() ? moment(data.cp_EN_DT, "YYYYMMDD").toDate() : null}
                      onChange={(e) => onchangedata({ target: {name: "cp_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_ST_TM" className="font-bold">
                      Start Time
                    </label>
                      <InputMask
                        id={"cp_ST_TM"}
                        name={"cp_ST_TM"}
                        mask="99:99:99"
                        onBlur={(e) => { onChangeTime("cp_ST_TM",e.target.value,);}}
                        value={moment(cp_ST_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_EN_TM" className="font-bold">
                      End Time
                    </label>
                      <InputMask
                        id={"cp_EN_TM"}
                        name={"cp_EN_TM"}
                        mask="99:99:99"
                        onBlur={(e) => { onChangeTime("cp_EN_TM",e.target.value,);}}
                        value={moment(cp_EN_TM, "HHmmss").format("HH:mm:ss")}
                      />
                    </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BD_TY" className="font-bold">
                      Budget
                    </label>
                    <Dropdown
                      id="cp_BD_TY"
                      name="cp_BD_TY"
                      value={cp_BD_TY}
                      options={cp_BD_TYOptions}
                      onChange={(e) => onchangeDropDownFormData16("cp_BD_TY", e.value)}
                      placeholder="Select Budget Type"
                    />
                  </div>   
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BD_TY" className="font-bold">
                      Budget Amount
                    </label>
                      <InputText 
                      id="cp_BU_GT"
                      name="cp_BU_GT"
                      value={cp_BU_GT}
                      onChange={(e) => onchangedata(e)}
                    />
                    </div>                   
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_FB_OB" className="font-bold">
                      Objective
                    </label>
                    <Dropdown
                      id="cp_FB_OB"
                      name="cp_FB_OB"
                      value={cp_FB_OB}
                      options={cp_FB_OBOptions}
                      onChange={(e) => onchangeDropDownFormData6("cp_FB_OB", e.value)}
                      placeholder="Select Objective"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BU_TY" className="font-bold">
                      Buying Type
                    </label>
                    <Dropdown
                      id="cp_BU_TY"
                      name="cp_BU_TY"
                      value={cp_BU_TY}
                      options={cp_BU_TYOptions}
                      onChange={(e) => onchangeDropDownFormData7("cp_BU_TY", e.value)}
                      placeholder="Select Buying Type"
                    />
                  </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BD_AM" className="font-bold">
                      Bid Amount
                    </label>
                    <InputText
                      id="cp_BD_AM"
                      name="cp_BD_AM"
                      value={cp_BD_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_DA_BU" className="font-bold">
                      Daily Budget
                    </label>
                    <InputText
                      id="cp_DA_BU"
                      name="cp_DA_BU"
                      value={cp_DA_BU}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_OP_GO" className="font-bold">
                      Optimization Goal
                    </label>
                    <Dropdown
                      id="cp_OP_GO"
                      name="cp_OP_GO"
                      value={cp_OP_GO}
                      options={cp_OP_GOOptions}
                      onChange={(e) => onchangeDropDownFormData10("cp_OP_GO", e.value)}
                      placeholder="Select Optimization Goal"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_BI_EV" className="font-bold">
                      Billing Event
                    </label>
                    <Dropdown
                      id="cp_BI_EV"
                      name="cp_BI_EV"
                      value={cp_BI_EV}
                      options={cp_BI_EVOptions}
                      onChange={(e) => onchangeDropDownFormData11("cp_BI_EV", e.value)}
                      placeholder="Select Billing Event"
                    />
                  </div> 
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_FB_PO" className="font-bold">
                      Facebook Positions
                    </label>
                    <Dropdown
                      id="cp_FB_PO"
                      name="cp_FB_PO"
                      value={cp_FB_PO}
                      options={cp_FB_POOptions}
                      onChange={(e) => onchangeDropDownFormData9("cp_FB_PO", e.value)}
                      placeholder="Select FB Position"
                    />
                  </div>

                </div>
            </div>
            <div style={inboxstyle} >
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CA_CY" className="font-bold">
                      Country
                    </label>
                    <Dropdown
                      id="cp_CA_CY"
                      name="cp_CA_CY"
                      value={cp_CA_CY}
                      options={cp_CA_CYOptions}
                      onChange={(e) => onchangeDropDownFormData8("cp_CA_CY", e.value)}
                      placeholder="Select Country"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CI_TY" className="font-bold">
                      City
                    </label>
                    <Dropdown
                      id="cp_CI_TY"
                      name="cp_CI_TY"
                      value={cp_CI_TY}
                      options={cp_CI_TYOptions}
                      onChange={(e) => onchangeDropDownFormData23("cp_CI_TY", e.value)}
                      placeholder="Select City"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_RE_GN" className="font-bold">
                      Region
                    </label>
                    <Dropdown
                      id="cp_RE_GN"
                      name="cp_RE_GN"
                      value={cp_RE_GN}
                      options={cp_RE_GNOptions}
                      onChange={(e) => onchangeDropDownFormData24("cp_RE_GN", e.value)}
                      placeholder="Select Region"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_ST_AT" className="font-bold">
                      Status
                    </label>
                    <Dropdown
                      id="cp_ST_AT"
                      name="cp_ST_AT"
                      value={cp_ST_AT}
                      options={cp_ST_ATOptions}
                      onChange={(e) => onchangeDropDownFormData12("cp_ST_AT", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_CA_ME" className="font-bold">
                      Message
                    </label>
                    <InputText
                      id="cp_CA_ME"
                      name="cp_CA_ME"
                      value={cp_CA_ME}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_WE_LI" className="font-bold">
                      Link
                    </label>
                    <InputText
                      id="cp_WE_LI"
                      name="cp_WE_LI"
                      value={cp_WE_LI}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                 
                </div>
            </div>
            <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_MI_AG" className="font-bold">
                    Min Age
                  </label>
                  <InputText
                    id="cp_MI_AG"
                    name="cp_MI_AG"
                    value={cp_MI_AG}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_MX_AG" className="font-bold">
                    Max Age
                  </label>
                  <InputText
                    id="cp_MX_AG"
                    name="cp_MX_AG"
                    value={cp_MX_AG}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_GE_DR" className="font-bold">
                    Gender
                  </label>
                  <Dropdown
                    id="cp_GE_DR"
                    name="cp_GE_DR"
                    value={cp_GE_DR}
                    options={cp_GE_DROptions}
                    onChange={(e) => onchangeDropDownFormData17("cp_GE_DR", e.value)}
                    placeholder="Select Gender"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_DS_UN" className="font-bold">
                    Distance Unit
                  </label>
                  <Dropdown
                    id="cp_DS_UN"
                    name="cp_DS_UN"
                    value={cp_DS_UN}
                    options={cp_DS_UNOptions}
                    onChange={(e) => onchangeDropDownFormData22("cp_DS_UN", e.value)}
                    placeholder="Select Distance"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_RA_DI" className="font-bold">
                    Radius
                  </label>
                  <InputText
                    id="cp_RA_DI"
                    name="cp_RA_DI"
                    value={cp_RA_DI}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cp_CA_AC" className="font-bold">
                    Call To Action
                  </label>
                  <Dropdown
                    id="cp_CA_AC"
                    name="cp_CA_AC"
                    value={cp_CA_AC}
                    options={cp_CA_ACOptions}
                    onChange={(e) => onchangeDropDownFormData13("cp_CA_AC", e.value)}
                    placeholder="Select Action"
                  />
                </div>
              </div>
            </div>
            <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_PL_FB" className="font-bold">
                      Facebook Platform
                    </label>
                    <Dropdown
                      id="cp_PL_FB"
                      name="cp_PL_FB"
                      value={cp_PL_FB}
                      options={cp_PL_FBOptions}
                      onChange={(e) => onchangeDropDownFormData18("cp_PL_FB", e.value)}
                      placeholder="Select Platform"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_PL_IN" className="font-bold">
                      Instgram Platform
                    </label>
                    <Dropdown
                      id="cp_PL_IN"
                      name="cp_PL_IN"
                      value={cp_PL_IN}
                      options={cp_PL_INOptions}
                      onChange={(e) => onchangeDropDownFormData19("cp_PL_IN", e.value)}
                      placeholder="Select Instagram"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_PL_AN" className="font-bold">
                      Social Network
                    </label>
                    <Dropdown
                      id="cp_PL_AN"
                      name="cp_PL_AN"
                      value={cp_PL_AN}
                      options={cp_PL_ANOptions}
                      onChange={(e) => onchangeDropDownFormData20("cp_PL_AN", e.value)}
                      placeholder="Select Social Network"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_PL_MS" className="font-bold">
                      Messenger
                    </label>
                    <Dropdown
                      id="cp_PL_MS"
                      name="cp_PL_MS"
                      value={cp_PL_MS}
                      options={cp_PL_MSOptions}
                      onChange={(e) => onchangeDropDownFormData21("cp_PL_MS", e.value)}
                      placeholder="Select Messenger"
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_PR_PO" className="font-bold">
                      Privacy Policy
                    </label>
                    <InputText
                      id="cp_PR_PO"
                      name="cp_PR_PO"
                      value={cp_PR_PO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                    <label htmlFor="cp_FO_WU" className="font-bold">
                      Follow UP
                    </label>
                    <InputText
                      id="cp_FO_WU"
                      name="cp_FO_WU"
                      value={cp_FO_WU}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
            <div style={inboxstyle} >
                  <div className="formgrid grid">
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_CT_PL" className="font-bold">
                        Cost Per Lead
                      </label>
                      <InputText
                        id="cp_CT_PL"
                        name="cp_CT_PL"
                        value={cp_CT_PL}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_CV_RT" className="font-bold">
                        Coversion Rate
                      </label>
                      <InputText
                        id="cp_CV_RT"
                        name="cp_CV_RT"
                        value={cp_CV_RT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_RT_IV" className="font-bold">
                          ROI
                      </label>
                      <InputText
                        id="cp_RT_IV"
                        name="cp_RT_IV"
                        value={cp_RT_IV}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_IM_ON" className="font-bold">
                        Impression
                      </label>
                      <InputText
                        id="cp_IM_ON"
                        name="cp_IM_ON"
                        value={cp_IM_ON}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_CL_CK" className="font-bold">
                        Clicks
                      </label>
                      <InputText
                        id="cp_CL_CK"
                        name="cp_CL_CK"
                        value={cp_CL_CK}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="cp_CN_CT" className="font-bold">
                        Contacts
                      </label>
                      <InputText
                        id="cp_CN_CT"
                        name="cp_CN_CT"
                        value={cp_CN_CT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                  </div>
            </div>
            <div  style={tablestyle} >
                <div className="table-buttons" style={tablebutton} >
                {(
                <Button icon="pi pi-plus"  onClick={addRowLeadDetail} style={plusstyle} tooltip="Add"  />
                )}
                  {(
                <Button icon="pi pi-minus" onClick={deleteRowLeadDetail} style={minusstyle} tooltip="Delete" />
                )}
                </div>
                <div  style={multitablestyle}>
                  <table className="custom-table" id="OvertimeTable" >
                    <thead>
                      <tr>
                        <th>Name </th>
                        <th>Email </th>
                        <th>Phone No </th>
                      </tr>
                    </thead>
                    <tbody>
                      {CamLeadDetailData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <InputText disabled
                              id={`eo_EM_NO_${index}`}
                              name={`eo_EM_NO_${index}`}
                              defaultValue={row.eo_OV_TM}
                              onChange={(e) => onchangedata(e)}
                            />
                          </td>
                          <td>
                            <InputText disabled
                              id={`eo_OV_TM_${index}`}
                              name={`eo_OV_TM_${index}`}
                              defaultValue={row.eo_OV_TM}
                              onChange={(e) => onchangedata(e)}
                            />
                          </td>
                          <td >
                            <InputText disabled
                              id={`eo_HO_TM_${index}`}
                              name={`eo_HO_TM_${index}`}
                              defaultValue={row.eo_HO_TM}
                              onChange={(e) => onchangedata(e)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>    
                  </table>  
                </div> 
            </div> 
       
          {/* 2nd tab things */}
          {/* <div style={inboxstyle}>
          <div style={tablestyle}>
            <div className="table-buttons" style={tablebutton}>
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            </div>
            <div style={{border:"1px solid #e8e8e8",
                        padding: "16px", borderRadius: "15px",
                        backgroundColor: "aliceblue", overflow: "auto",
                        position: "relative", height:'15rem'}}>
              <table className="custom-table" id="CampaignQuenstionTable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th> </th>
                    <th>Question No</th>
                    <th>Type</th>
                    <th>Question</th>
                  </tr>
                </thead>
                <tbody>
                  {QuenstionTableData.map((row, index) => {
                    const isCustomType = data[`cq_TY_PE_${index}`] === "CUSTOM";

                    return (
                      <tr key={index} onClick={() => onRowSelect(index)}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`cqUkNo_${index}`}
                            name={`cqUkNo_${index}`}
                            value={row.cqUkNo}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            id={`radio_${index}`}
                            name={`radio_${index}`}
                            checked={selectedCheckbox === index}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`cq_QU_NO_${index}`}
                            name={`cq_QU_NO_${index}`}
                            value={row.cq_QU_NO ? row.cq_QU_NO : index + 1}     
                            onChange={(e) => onChangeQuestionData(index,'cq_QU_NO',e.target.value,)}                  
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cq_TY_PE_${index}`}
                            name={`cq_TY_PE_${index}`}
                            value={data[`cq_TY_PE_${index}`] ? data[`cq_TY_PE_${index}`] : row.cq_TY_PE}
                            options={cq_TY_PEOptions}
                            onChange={(e) => onchangeDropDownFormData14(`cq_TY_PE_${index}`, e.value)}
                            placeholder="Select Type"
                          />
                        </td>
                        <td>
                          <InputText
                            id={`cq_QU_TN_${index}`}
                            name={`cq_QU_TN_${index}`}
                            value={row.cq_QU_TN ? row.cq_QU_TN : row[`${"cq_QU_TN"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                            disabled={!isCustomType}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {QuenstionTableData.some((row, index) => data[`cq_TY_PE_${index}`] === "CUSTOM") && (
            <div style={tablestyle}>
              <div className="table-buttons" style={tablebutton}>
                <Button icon="pi pi-plus" onClick={addRowANS} style={plusstyle} tooltip="Add" />
                <Button icon="pi pi-minus" onClick={deleteRowANS} style={minusstyle} tooltip="Delete" />
              </div>
              <div style={{border:"1px solid #e8e8e8",
                        padding: "16px", borderRadius: "15px",
                        backgroundColor: "aliceblue", overflow: "auto",
                        position: "relative", height:'15rem'}}>
                <table className="custom-table" id="CampaignAnswerTable">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Question No</th>
                      <th>Answers</th>
                      <th>Campaign No</th>
                      <th>Campaign Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AnswerTableData.map((row, index) => (
                      <tr key={index} onClick={() => onRowSelect(index)}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`caUkNo_${index}`}
                            name={`caUkNo_${index}`}
                            value={row.caUkNo}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`ca_QU_NO_${index}`}
                            name={`ca_QU_NO_${index}`}
                            value={row.ca_QU_NO ? row.ca_QU_NO : row[`${"ca_QU_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`ca_AN_NO_${index}`}
                            name={`ca_AN_NO_${index}`}
                            value={row.ca_AN_NO ? row.ca_AN_NO : row[`${"ca_AN_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText
                            id={`ca_CP_NO_${index}`}
                            name={`ca_CP_NO_${index}`}
                            value={row.ca_CP_NO ? row.ca_CP_NO : row[`${"ca_CP_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText
                            id={`ca_CP_TY_${index}`}
                            name={`ca_CP_TY_${index}`}
                            value={row.ca_CP_TY ? row.ca_CP_TY : row[`${"ca_CP_TY"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          </div> */}

        </TabPanel>
         <TabPanel header="Question" className="tab-header">

          <div style={tablestyle}>
            <div className="table-buttons" style={tablebutton}>
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            </div>
            <div style={{border:"1px solid #e8e8e8",
                        padding: "16px", borderRadius: "15px",
                        backgroundColor: "aliceblue", overflow: "auto",
                        position: "relative", height:'15rem'}}>
              <table className="custom-table" id="CampaignQuenstionTable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th> </th>
                    <th>Question No</th>
                    <th>Type</th>
                    <th>Question</th>
                  </tr>
                </thead>
                <tbody>
                  {QuenstionTableData.map((row, index) => {
                    // Determine if the current row's type is CUSTOM
                    const isCustomType = data[`cq_TY_PE_${index}`] === "CUSTOM";

                    return (
                      <tr key={index} onClick={() => onRowSelect(index)}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`cqUkNo_${index}`}
                            name={`cqUkNo_${index}`}
                            value={row.cqUkNo}
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            id={`radio_${index}`}
                            name={`radio_${index}`}
                            checked={selectedCheckbox === index}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`cq_QU_NO_${index}`}
                            name={`cq_QU_NO_${index}`}
                            value={row.cq_QU_NO ? row.cq_QU_NO : index + 1}     
                            onChange={(e) => onChangeQuestionData('cq_QU_NO',e.value,index)}                  
                          />
                        </td>
                        <td>
                          <Dropdown
                            id={`cq_TY_PE_${index}`}
                            name={`cq_TY_PE_${index}`}
                            value={data[`cq_TY_PE_${index}`] ? data[`cq_TY_PE_${index}`] : row.cq_TY_PE}
                            options={cq_TY_PEOptions}
                            onChange={(e) => onchangeDropDownFormData14(`cq_TY_PE_${index}`, e.value)}
                            placeholder="Select Type"
                          />
                        </td>
                        <td>
                          <InputText
                            id={`cq_QU_TN_${index}`}
                            name={`cq_QU_TN_${index}`}
                            value={row.cq_QU_TN ? row.cq_QU_TN : row[`${"cq_QU_TN"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                            disabled={!isCustomType}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

           {/* Conditional displaying Answer Table based on value of "CUSTOM" type  */}
          {QuenstionTableData.some((row, index) => data[`cq_TY_PE_${index}`] === "CUSTOM") && (
            <div style={tablestyle}>
              <div className="table-buttons" style={tablebutton}>
                <Button icon="pi pi-plus" onClick={addRowANS} style={plusstyle} tooltip="Add" />
                <Button icon="pi pi-minus" onClick={deleteRowANS} style={minusstyle} tooltip="Delete" />
              </div>
              <div style={{border:"1px solid #e8e8e8",
                        padding: "16px", borderRadius: "15px",
                        backgroundColor: "aliceblue", overflow: "auto",
                        position: "relative", height:'15rem'}}>
                <table className="custom-table" id="CampaignAnswerTable">
                  <thead>
                    <tr>
                      <th style={{ display: "none" }}>UK NO</th>
                      <th>Question No</th>
                      <th>Answers</th>
                      <th>Campaign No</th>
                      <th>Campaign Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AnswerTableData.map((row, index) => (
                      <tr key={index} onClick={() => onRowSelect(index)}>
                        <td style={{ display: "none" }}>
                          <InputText
                            id={`caUkNo_${index}`}
                            name={`caUkNo_${index}`}
                            value={row.caUkNo}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`ca_QU_NO_${index}`}
                            name={`ca_QU_NO_${index}`}
                            value={row.ca_QU_NO ? row.ca_QU_NO : row[`${"ca_QU_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText disabled
                            id={`ca_AN_NO_${index}`}
                            name={`ca_AN_NO_${index}`}
                            value={row.ca_AN_NO ? row.ca_AN_NO : row[`${"ca_AN_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText
                            id={`ca_CP_NO_${index}`}
                            name={`ca_CP_NO_${index}`}
                            value={row.ca_CP_NO ? row.ca_CP_NO : row[`${"ca_CP_NO"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                        <td>
                          <InputText
                            id={`ca_CP_TY_${index}`}
                            name={`ca_CP_TY_${index}`}
                            value={row.ca_CP_TY ? row.ca_CP_TY : row[`${"ca_CP_TY"}_${index}`]}
                            onChange={(e) => onchangedata(e)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}


        </TabPanel> 
      </TabView>
      </div>
    </Dialog>
  );
}
