import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import JournalEntryDialog from "./JournalEntryDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  gl_DO_NO: "",
  gl_DO_TY: "",
  gl_DO_CO: "",
  gl_BA_DY:"",
  gl_BA_TY:"",
  gl_GL_DT:moment(moment().toDate()).format("YYYYMMDD"),
  gl_CR_CD:"",
  gl_CR_RT:"",
  gl_EX_PL:"",
  gl_JE_LE:"",
  gl_AC_NO:"",
  acc_DESC:"",
  glUkNo:"",
  gl_AC_AM:"",
  gl_TA_AP:"",
  gl_TX_CD:"",
  gl_TA_AM: "",
  gl_SB_TY:"",
  gl_SB_LE:"",
  name: "",
  gl_RM_MK:"",
  gl_GL_PO:"",
  gl_BA_NO:"",
  gl_AC_ID:"",
  gl_CO_NM:"",
  gl_OB_AC:"",
  gl_OB_SB:"", 
  gl_PE_NO:"",
  gl_CT_RY:"",
  gl_FY_YR:"", 
};
const initialFilters = {
  gl_DO_CO: { value: "", operation: "" },
  gl_DO_TY: { value: "", operation: "" },
  gl_DO_NO: { value: "", operation: "" },
  gl_GL_DT: { value: "", operation: "" },
  gl_JE_LE: { value: "", operation: "" },
  gl_GL_PO: { value: "", operation: "" },
  gl_BA_NO: { value: "", operation: "" },
  gl_BA_TY: { value: "", operation: "" },
  gl_AC_ID: { value: "", operation: "" },
  gl_AC_NO: { value: "", operation: "" },
  acc_DESC: { value: "", operation: "" },
  gl_CO_NM: { value: "", operation: "" },
  gl_OB_AC: { value: "", operation: "" },
  gl_OB_SB: { value: "", operation: "" },
  gl_SB_LE: { value: "", operation: "" },
  gl_LE_TY: { value: "", operation: "" },
  gl_PE_NO: { value: "", operation: "" },
  gl_CT_RY: { value: "", operation: "" },
  gl_FY_YR: { value: "", operation: "" },
  gl_CR_CD: { value: "", operation: "" },
  gl_CR_RT: { value: "", operation: "" },
  gl_AC_AM: { value: "", operation: "" },
  gl_EX_PL: { value: "", operation: "" },
};

const JournalEntryMain = () => {    
// buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,ListDesc,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,inputStyle1,inputStyle,tableCellStyle,tableHeaderStyleAC,
    rightalined,tableHeaderStyleH,tableCellStyleH } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [gl_DO_CO, setgl_DO_CO] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
 
  const [gl_DO_TY,setgl_DO_TY] =useState("");
  const [gl_BA_TY,setgl_BA_TY] = useState("");
  const [gl_CR_CD, setgl_CR_CD] = useState("");
  const [gl_TA_AP, setgl_TA_AP] = useState("");
  const [gl_TX_CD, setgl_TX_CD] = useState("");
  const [gl_GL_DT, setgl_GL_DT] = useState("");
  const [gl_SB_TY, setgl_SB_TY] = useState("");
  const [gl_BA_NO, setgl_BA_NO] = useState("");
  const [gl_DO_NO, setgl_DO_NO] = useState("");
  const [gl_BS_CR,setgl_BS_CR] = useState("");
  const [gl_SB_LE, setgl_SB_LE] = useState("");
  const [gl_AC_NO, setgl_AC_NO] = useState("");
  const [acc_DESC, setacc_DESC] = useState("");
  const [gl_CR_RT, setgl_CR_RT] = useState("");
  const [gl_TA_AM, setgl_TA_AM] = useState("");
  const [gl_TX_AM, setgl_TX_AM] = useState("");
  const [gl_CN_FM, setgl_CN_FM] = useState("");
  
  const [JournalEntrytable, setJournalEntrytable] = useState([{}]);
  const [SelectedNAME, setSelectedNAME] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }
    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setgl_TA_AP(null);
    setgl_TX_CD(null);
    setgl_SB_TY(null);
    setgl_SB_LE(null);
    setgl_AC_NO(null);
    setacc_DESC(null);
    //setgl_CR_CD(null);
    setSelectedNAME(null);
    setgl_CN_FM(10);
    setJournalEntrytable([{}]);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setgl_TA_AP(null);
    setgl_TX_CD(null);
    setgl_SB_TY(null);
    setgl_SB_LE(null);
    setgl_AC_NO(null);
    setacc_DESC(null);
    setgl_CN_FM(null);
    setOpen(false);
  };
  const addRow = () => {
    setJournalEntrytable([...JournalEntrytable, {}]);
  };
  const deleteRow = () => {
    if (JournalEntrytable.length > 0) {
      const updatedData = [...JournalEntrytable];
      updatedData.pop();
      setJournalEntrytable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/JournalEntry/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const JournalEntryData = response.data;
          delete JournalEntryData.glUkNo;
          delete JournalEntryData.gl_DO_NO;
          for (let i = 0; i < JournalEntryData.journalEntryList.length; i++) {
            delete JournalEntryData.journalEntryList[i].glUkNo;
            delete JournalEntryData.journalEntryList[i].gl_DO_NO;
          }
          setFormData(JournalEntryData.journalEntryList[0]);
          setJournalEntrytable(JournalEntryData.journalEntryList);
          setgl_TA_AP(null);
          setgl_TX_CD(null);
          setgl_SB_TY(null);
          setgl_CN_FM(10);
          setgl_SB_LE(null);
          setSelectedNAME(null);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const onchangegldate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    currencychnage(gl_CR_CD,value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_CR_CD(value);
    currencychnage(value, FormData.gl_GL_DT);
  };
  const apicurr = `${domain}/api/ExChangeRate/Rate`
  const currencychnage = async (curr,gldate) => {
    const requestData={
      "cr_FR_CR": curr,
      "cr_TO_CR": gl_BS_CR,
      "cr_EF_DT": gldate,
    }
    try {
      const response = await axios.post(apicurr, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const datacur = response.data.Result;
      if (datacur) {
        onchangeFormData("gl_CR_RT", datacur.cr_CO_MR);
        setgl_CR_RT(datacur.cr_CO_MR);
      }
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setgl_DO_CO(`${company}`);
            if(fName === "GL_DO_TY"){
              setgl_DO_TY(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "GL_BA_TY"){
              setgl_BA_TY(dataDefault.versionIds[i].default_VALUE);
            }
            if(fName === "GL_CR_CD"){
               onchangeDropDownFormData3("gl_CR_CD",dataDefault.versionIds[i].default_VALUE);
               setgl_CR_CD(dataDefault.versionIds[i].default_VALUE);
               currencychnage(dataDefault.versionIds[i].default_VALUE, moment(moment().toDate()).format("YYYYMMDD"));
            } 
            if(fName === "GL_BS_CR"){
              setgl_BS_CR(dataDefault.versionIds[i].default_VALUE);
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JournalEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.glUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
    JournalEntrytable[index][fieldName]=value;
  };
  const onchangeJournalEntryData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_DO_TY(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_BA_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_TA_AP(value);
  };
  const onchangeDropDownFormData5= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_TX_CD(value);
  };
  const onchangeDropDownFormData6= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setgl_SB_TY(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/JournalEntry/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const JournalEntryData = response.data;
        setJournalEntrytable(JournalEntryData.journalEntryList);
        setgl_GL_DT(JournalEntryData.gl_GL_DT);
        setFormData(JournalEntryData);
        setgl_TA_AM(response.data.gl_TA_AM);
        setgl_TX_AM(response.data.gl_TX_AM);
        setgl_CN_FM(response.data.gl_CN_FM);
        setgl_TA_AP(JournalEntryData.journalEntryList[0].gl_TA_AP);
        setgl_TX_CD(JournalEntryData.journalEntryList[0].gl_TX_CD);
        setgl_SB_TY(JournalEntryData.journalEntryList[0].gl_SB_TY);
        setgl_CR_CD(JournalEntryData.journalEntryList[0].gl_CR_CD)
        setSelectedNAME(JournalEntryData.journalEntryList[0].name);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values(){
    const table = document.getElementById("JournalEntrytable");
    const totalRowCount = table.tBodies[0].rows.length;
    const journalEntryList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const Details = {
      glUkNo : document.getElementById("glUkNo_" + i).value,
      gl_JE_LE : document.getElementById("gl_JE_LE_" + i).value,
      gl_AC_NO : document.getElementById("gl_AC_NO_" + i).value,
      acc_DESC : document.getElementById("acc_DESC_" + i).value,
      credit_AMOUNT : document.getElementById ("gl_CR_AM_" + i).value, 
      debit_AMOUNT : document.getElementById ("gl_DE_AM_" + i).value, 
      gl_TA_AP: table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
      gl_SB_TY: table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
      gl_TX_CD: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
      gl_TA_AM : document.getElementById("gl_TA_AM_" + i).value,
      gl_SB_LE : document.getElementById("gl_SB_LE_" + i).value,
      gl_RM_MK : document.getElementById("gl_RM_MK_" + i).value, 
      gl_TX_AM : document.getElementById("gl_TX_AM_" + i).value,
    };
        journalEntryList.push(Details);
      }
      requestBodyInput ={
        journalEntryList,
        glUkNo : FormData.glUkNo,
        gl_DO_NO : document.getElementById("gl_DO_NO").value,
        gl_DO_TY : document.getElementById("gl_DO_TY").value,
        gl_DO_CO : document.getElementById("gl_DO_CO").value,
        gl_BA_NO : document.getElementById("gl_BA_NO").value,
        gl_BA_TY : document.getElementById("gl_BA_TY").value,
        gl_GL_DT : FormData.gl_GL_DT,
        gl_CR_CD : gl_CR_CD,
        gl_CR_RT : document.getElementById("gl_CR_RT").value,
        gl_EX_PL : document.getElementById("gl_EX_PL").value
      }
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.gl_DO_NO) {
    if (FormData.glUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/JournalEntry/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/JournalEntry/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const GL_DT = FormData.gl_GL_DT;
        if(GL_DT){
          const currentYear = GL_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${gl_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setgl_DO_NO(resp.dn_DO_NO);
              document.getElementById("gl_DO_NO").value = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/JournalEntry/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" , backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
      
    </React.Fragment>
  );
  const handleConfirm = () => {
    values();
    const orNo = document.getElementById("gl_BA_NO").value;
    const ukNo = FormData.glUkNo;
    if (orNo) {
      if (ukNo) {
        const confirm = window.confirm("Do you want to Confirm ?");
        const update = `${domain}/api/JournalEntry/ConfirmJournalEntry`;
        if (confirm) {
          axios
            .put(update, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } 
    }
  };
  const handlePost = () => {  
    const requestpost ={
            "glUkNo":FormData.glUkNo
            }
      const post = window.confirm("Do you want to Post ?");
      const PostUrl = `${domain}/api/JournalEntry/PostErrorBatch`;
      if (post) {
        axios.post(PostUrl, requestpost, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
            handleSearch();
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
        }
  };
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{  borderCollapse: "collapse",  width: "100%", marginTop: "20px" }}>
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_CO"] ? filters["gl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_CO",
                        e.target.value,
                        filters["gl_DO_CO"]
                          ? filters["gl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_TY"] ? filters["gl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_TY",
                        e.target.value,
                        filters["gl_DO_TY"]
                          ? filters["gl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_NO"] ? filters["gl_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_NO",
                        e.target.value,
                        filters["gl_DO_NO"]
                          ? filters["gl_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_GL_DT"] ? filters["gl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_GL_DT",
                        e.target.value,
                        filters["gl_GL_DT"]
                          ? filters["gl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["gl_JE_LE"] ? filters["gl_JE_LE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_JE_LE",
                        e.target.value,
                        filters["gl_JE_LE"]
                          ? filters["gl_JE_LE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>           
                <td>
                  <input
                    type="text"
                    value={filters["gl_GL_PO"] ? filters["gl_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_GL_PO",
                        e.target.value,
                        filters["gl_GL_PO"]
                          ? filters["gl_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_BA_NO"] ? filters["gl_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_BA_NO",
                        e.target.value,
                        filters["gl_BA_NO"]
                          ? filters["gl_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_BA_TY"] ? filters["gl_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_BA_TY",
                        e.target.value,
                        filters["gl_BA_TY"]
                          ? filters["gl_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_AC_ID"] ? filters["gl_AC_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_AC_ID",
                        e.target.value,
                        filters["gl_AC_ID"]
                          ? filters["gl_AC_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_AC_NO"] ? filters["gl_AC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_AC_NO",
                        e.target.value,
                        filters["gl_AC_NO"]
                          ? filters["gl_AC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["acc_DESC"] ? filters["acc_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "acc_DESC",
                        e.target.value,
                        filters["acc_DESC"]
                          ? filters["acc_DESC"].operation
                          : "equals"
                      )
                    }
                    style={ListDesc}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_CO_NM"] ? filters["gl_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_CO_NM",
                        e.target.value,
                        filters["gl_CO_NM"]
                          ? filters["gl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_OB_AC"] ? filters["gl_OB_AC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_OB_AC",
                        e.target.value,
                        filters["gl_OB_AC"]
                          ? filters["gl_OB_AC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_OB_SB"] ? filters["gl_OB_SB"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_OB_SB",
                        e.target.value,
                        filters["gl_OB_SB"]
                          ? filters["gl_OB_SB"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_SB_LE"] ? filters["gl_SB_LE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_SB_LE",
                        e.target.value,
                        filters["gl_SB_LE"]
                          ? filters["gl_SB_LE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_SB_TY"] ? filters["gl_SB_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_SB_TY",
                        e.target.value,
                        filters["gl_SB_TY"]
                          ? filters["gl_SB_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_PE_NO"] ? filters["gl_PE_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_PE_NO",
                        e.target.value,
                        filters["gl_PE_NO"]
                          ? filters["gl_PE_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_CT_RY"] ? filters["gl_CT_RY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_CT_RY",
                        e.target.value,
                        filters["gl_CT_RY"]
                          ? filters["gl_CT_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_FY_YR"] ? filters["gl_FY_YR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_FY_YR",
                        e.target.value,
                        filters["gl_FY_YR"]
                          ? filters["gl_FY_YR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_CR_CD"] ? filters["gl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_CR_CD",
                        e.target.value,
                        filters["gl_CR_CD"]
                          ? filters["gl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_CR_RT"] ? filters["gl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_CR_RT",
                        e.target.value,
                        filters["gl_CR_RT"]
                          ? filters["gl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_AC_AM"] ? filters["gl_AC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_AC_AM",
                        e.target.value,
                        filters["gl_AC_AM"]
                          ? filters["gl_AC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_EX_PL"] ? filters["gl_EX_PL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_EX_PL",
                        e.target.value,
                        filters["gl_EX_PL"]
                          ? filters["gl_EX_PL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
              <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Doc. No</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Post Code</th>
                <th style={tableHeaderStyle}>Batch No</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Account ID</th>
                <th style={tableHeaderStyleAC}>Account Number</th>
                <th style={tableHeaderStyle}>Account Description</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Account</th>
                <th style={tableHeaderStyle}>Subsidiary</th>
                <th style={tableHeaderStyle}>Sub Ledger</th>
                <th style={tableHeaderStyle}>Ledger Type</th>
                <th style={tableHeaderStyle}>Period</th>
                <th style={tableHeaderStyle}>Century</th>
                <th style={tableHeaderStyle}>Fiscal Year</th>
                <th style={tableHeaderStyle}>Currency Code</th>
                <th style={tableHeaderStyle}>Currency Rate</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Explanation</th>
                <th style={tableHeaderStyleH}>Confirm</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.glUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.glUkNo}
                        checked={selectedRows.includes(rowData.glUkNo)}
                        onChange={() => handleRowSelect(rowData.glUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.gl_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.gl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.gl_DO_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.gl_GL_DT)}</td>
                    <td style={tableCellStyle}>{rowData.gl_JE_LE}</td>
                    <td style={tableCellStyle}>{rowData.gl_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.gl_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.gl_BA_TY}</td>
                    <td style={tableCellStyle}>{rowData.gl_AC_ID}</td>
                    <td style={tableCellStyle}>{rowData.gl_AC_NO}</td>
                    <td style={tableCellStyle}>{rowData.acc_DESC}</td>
                    <td style={tableCellStyle}>{rowData.gl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.gl_OB_AC}</td>
                    <td style={tableCellStyle}>{rowData.gl_OB_SB}</td>
                    <td style={tableCellStyle}>{rowData.gl_SB_LE}</td>
                    <td style={tableCellStyle}>{rowData.gl_LE_TY}</td>
                    <td style={tableCellStyle}>{rowData.gl_PE_NO}</td>
                    <td style={tableCellStyle}>{rowData.gl_CT_RY}</td>
                    <td style={tableCellStyle}>{rowData.gl_FY_YR}</td>
                    <td style={tableCellStyle}>{rowData.gl_CR_CD}</td>
                    <td style={rightalined}>{rowData.gl_CR_RT}</td>
                    <td style={rightalined}>{rowData.gl_AC_AM}</td>
                    <td style={tableCellStyle}>{rowData.gl_EX_PL}</td>
                    <td style={tableCellStyleH}>{rowData.gl_CN_FM}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
           {/* pagination */}
     <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>
      <JournalEntryDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData} 
        onchangeDropDownFormData1={onchangeDropDownFormData1} 
        onchangeDropDownFormData2={onchangeDropDownFormData2}  
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        gl_DO_TY={gl_DO_TY}
        gl_BA_TY={gl_BA_TY}
        gl_SB_TY={gl_SB_TY}
        gl_CR_CD={gl_CR_CD}
        gl_TA_AP={gl_TA_AP}
        gl_TX_CD={gl_TX_CD}
        gl_DO_CO={gl_DO_CO}
        gl_DO_NO={gl_DO_NO}
        gl_BA_NO={gl_BA_NO}
        gl_BS_CR={gl_BS_CR}
        gl_SB_LE={gl_SB_LE}
        gl_AC_NO={gl_AC_NO}
        acc_DESC={acc_DESC}
        gl_CR_RT={gl_CR_RT}
        setFormData={setFormData}
        JournalEntrytable={JournalEntrytable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        onchangeJournalEntryData={onchangeJournalEntryData}
        onchangegldate={onchangegldate}
        handleConfirm={handleConfirm}
        handlePost={handlePost}
        gl_TA_AM={gl_TA_AM}
        gl_TX_AM={gl_TX_AM}
        gl_CN_FM={gl_CN_FM}
        // handletaxdtls={handletaxdtls}
      />
    </div>
  );
};

export default JournalEntryMain;

