import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PostVouchersDialog from "./PostVouchersDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sl_UK_NO:"",
  slDoNo:"",
  sl_DO_TY:"",
  sl_DO_CO:"",
  sl_DO_PI:"",
  sl_SU_NO:"",
  name:"",
  sl_GL_DT:"",
  sl_IN_DT:"",
  sl_DD_DT:"",
  sl_PY_CO:"",
  sl_GL_PO:"",
  sl_PA_ST:"",
  sl_GR_AM:"",
  sl_OP_AM:"",
  sl_TA_AM:"",
  sl_TX_AM:"",
  sl_PD_AM:"",
  sl_ST_DT:"",
  sl_CR_CD:"",
  sl_CR_RT:"",
  sl_OP_FR:"",
  sl_TA_FR:"",
  sl_TX_FR:"",
  sl_OR_NO:"",
  sl_OR_TY:"",
  sl_OR_CO:"",
  sl_SU_IN:"",
  sl_CO_NM:"",
  sl_BA_TY:"",
  sl_BA_NO:"",
  sl_BA_DT:"",
  sl_ST_CD:"",
  sl_DO_TY:"",

  sl_TX_CD:"",
  sl_DI_AM:"",
  sl_GR_AM:"",
  sl_DI_FR:"",
  sl_DO_PI:"",
  sl_TX_AM:"",
  sl_DI_AM:"",
  sl_GR_AM:"",
  sl_TA_FR:"",
  sl_TX_FR:"",
  sl_DI_FR:"",
  sl_OR_NO:"",
  
  sl_OR_CO:"",
};
const initialFilters = {
  rc_CO_NO: { value: "", operation: "" },
  rc_AD_NO: { value: "", operation: "" },
  rc_CO_TY: { value: "", operation: "" },
  rc_CO_CD: { value: "", operation: "" },
  rc_CO_LV: { value: "", operation: "" },
  rc_CS_PR: { value: "", operation: "" },
  rc_CE_CD: { value: "", operation: "" },
  rc_ST_DT: { value: "", operation: "" },
  rc_EN_DT: { value: "", operation: "" },
  rc_GR_DE: { value: "", operation: "" },
  rc_LO_CN: { value: "", operation: "" },
  rc_DL_TY: { value: "", operation: "" },
  rc_UN_TS: { value: "", operation: "" },
  rc_CO_ST: { value: "", operation: "" },
  rc_TR_RE: { value: "", operation: "" },
};
const PostVouchersMain = () => {
// buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");
  const [SelectedemEMNOA, setSelectedemEMNOA] = useState("");
  const [sl_SU_NO, setsl_SU_NO] = useState("");
  const [sl_PY_CO, setsl_PY_CO] = useState("");
  const [sl_CR_CD, setsl_CR_CD] = useState("");
  const [sl_CO_NM, setsl_CO_NM] = useState("");
  const [rh_CR_CO, setrh_CR_CO] = useState("");
  const [PostVoucherstable, setPostVoucherstable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setPostVoucherstable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setsl_CO_NM(null);
    setsl_CR_CD(null);
    setsl_PY_CO(null);
    setrh_CR_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setPostVoucherstable([...PostVoucherstable, {}]);
  };

  const deleteRow = () => {
    if (PostVoucherstable.length > 0) {
      const updatedData = [...PostVoucherstable];
      updatedData.pop();
      setPostVoucherstable(updatedData);
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SupplierLedger/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_CO_NM(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
    setsl_CR_CD(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_PY_CO(value);
  };

  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/SupplierLedger/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PostVouchersData = response.data;
        setPostVoucherstable(PostVouchersData.supplierLedgerList);
        setFormData(PostVouchersData.supplierLedgerList[0]);
        setSelectedemEMNO(PostVouchersData.supplierLedgerList[0].sl_SU_NO);
        setSelectedemEMNOE(PostVouchersData.supplierLedgerList[0].name);
        // setsl_SU_NO(PostVouchersData.sl_SU_NO);
        setsl_CO_NM(response.data.sl_CO_NM);
        setsl_CR_CD(response.data.sl_CR_CD);
        setsl_PY_CO(response.data.sl_PY_CO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = async () => {
    const Url1 = `${domain}/api/PostVoucher/PostReceiptsToGAGL`;
    const Url2 = `${domain}/api/PostVoucher/PostReceiptsToGABL`;
    const request = {
        "sl_UK_NO": FormData.sl_UK_NO,
    };
  
    try {
      const response1 = await axios.post(Url1, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response1.data);

      const response2 = await axios.post(Url2, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response2.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
   // width:"100%",
    backgroundColor:"#f5f4f4"
  };

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table  style={{ borderCollapse: "collapse",width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["slDoNo"] ? filters["slDoNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "slDoNo",
                        e.target.value,
                        filters["slDoNo"]
                          ? filters["slDoNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_TY"] ? filters["sl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_TY",
                        e.target.value,
                        filters["sl_DO_TY"]
                          ? filters["sl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_CO"] ? filters["sl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_CO",
                        e.target.value,
                        filters["sl_DO_CO"]
                          ? filters["sl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_PI"] ? filters["sl_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_PI",
                        e.target.value,
                        filters["sl_DO_PI"]
                          ? filters["sl_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["sl_SU_NO"] ? filters["sl_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_SU_NO",
                        e.target.value,
                        filters["sl_SU_NO"]
                          ? filters["sl_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GL_DT"] ? filters["sl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GL_DT",
                        e.target.value,
                        filters["sl_GL_DT"]
                          ? filters["sl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_IN_DT"] ? filters["sl_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_IN_DT",
                        e.target.value,
                        filters["sl_IN_DT"]
                          ? filters["sl_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DD_DT"] ? filters["sl_DD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DD_DT",
                        e.target.value,
                        filters["sl_DD_DT"]
                          ? filters["sl_DD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PY_CO"] ? filters["sl_PY_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PY_CO",
                        e.target.value,
                        filters["sl_PY_CO"]
                          ? filters["sl_PY_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GL_PO"] ? filters["sl_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GL_PO",
                        e.target.value,
                        filters["sl_GL_PO"]
                          ? filters["sl_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PA_ST"] ? filters["sl_PA_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PA_ST",
                        e.target.value,
                        filters["sl_PA_ST"]
                          ? filters["sl_PA_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GR_AM"] ? filters["sl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GR_AM",
                        e.target.value,
                        filters["sl_GR_AM"]
                          ? filters["sl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OP_AM"] ? filters["sl_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OP_AM",
                        e.target.value,
                        filters["sl_OP_AM"]
                          ? filters["sl_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TA_AM"] ? filters["sl_TA_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TA_AM",
                        e.target.value,
                        filters["sl_TA_AM"]
                          ? filters["sl_TA_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TX_AM"] ? filters["sl_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TX_AM",
                        e.target.value,
                        filters["sl_TX_AM"]
                          ? filters["sl_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PD_AM"] ? filters["sl_PD_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PD_AM",
                        e.target.value,
                        filters["sl_PD_AM"]
                          ? filters["sl_PD_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_ST_DT"] ? filters["sl_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_ST_DT",
                        e.target.value,
                        filters["sl_ST_DT"]
                          ? filters["sl_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CR_CD"] ? filters["sl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CR_CD",
                        e.target.value,
                        filters["sl_CR_CD"]
                          ? filters["sl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CR_RT"] ? filters["sl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CR_RT",
                        e.target.value,
                        filters["sl_CR_RT"]
                          ? filters["sl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GR_AM"] ? filters["sl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GR_AM",
                        e.target.value,
                        filters["sl_GR_AM"]
                          ? filters["sl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OP_FR"] ? filters["sl_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OP_FR",
                        e.target.value,
                        filters["sl_OP_FR"]
                          ? filters["sl_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TA_FR"] ? filters["sl_TA_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TA_FR",
                        e.target.value,
                        filters["sl_TA_FR"]
                          ? filters["sl_TA_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TX_FR"] ? filters["sl_TX_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TX_FR",
                        e.target.value,
                        filters["sl_TX_FR"]
                          ? filters["sl_TX_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_NO"] ? filters["sl_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_NO",
                        e.target.value,
                        filters["sl_OR_NO"]
                          ? filters["sl_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_TY"] ? filters["sl_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_TY",
                        e.target.value,
                        filters["sl_OR_TY"]
                          ? filters["sl_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_CO"] ? filters["sl_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_CO",
                        e.target.value,
                        filters["sl_OR_CO"]
                          ? filters["sl_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_SU_IN"] ? filters["sl_SU_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_SU_IN",
                        e.target.value,
                        filters["sl_SU_IN"]
                          ? filters["sl_SU_IN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CO_NM"] ? filters["sl_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CO_NM",
                        e.target.value,
                        filters["sl_CO_NM"]
                          ? filters["sl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_NO"] ? filters["sl_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_NO",
                        e.target.value,
                        filters["sl_BA_NO"]
                          ? filters["sl_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_TY"] ? filters["sl_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_TY",
                        e.target.value,
                        filters["sl_BA_TY"]
                          ? filters["sl_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_DT"] ? filters["sl_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_DT",
                        e.target.value,
                        filters["sl_BA_DT"]
                          ? filters["sl_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_ST_CD"] ? filters["sl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_ST_CD",
                        e.target.value,
                        filters["sl_ST_CD"]
                          ? filters["sl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              
 
  
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Document Number</th>
                <th style={tableHeaderStyle}>Document Type</th>
                <th style={tableHeaderStyle}>Document Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Supplier Number</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>G/L Date</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
                <th style={tableHeaderStyle}>Due Date</th>
                <th style={tableHeaderStyle}>Pay Term</th>
                <th style={tableHeaderStyle}>Post Code</th>
                <th style={tableHeaderStyle}>Pay Status</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Taxable Amount</th>
                <th style={tableHeaderStyle}>Tax Amount</th>
                <th style={tableHeaderStyle}>PDC Amount</th>
                <th style={tableHeaderStyle}>Maturity Date</th>
                <th style={tableHeaderStyle}>Currency </th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Foreign Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign Open Amount</th>
                <th style={tableHeaderStyle}>Foreign Taxable Amount</th>
                <th style={tableHeaderStyle}>Foreign Amount</th>
                <th style={tableHeaderStyle}>Rel. Number</th>
                <th style={tableHeaderStyle}>Rel. Type</th>
                <th style={tableHeaderStyle}>Rel. Company</th>
                <th style={tableHeaderStyle}>Supplier PO</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Batch Number</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sl_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sl_UK_NO}
                        checked={selectedRows.includes(rowData.sl_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sl_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.slDoNo}</td>
                    <td style={tableCellStyle}>{rowData.sl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.sl_DO_PI}</td>
                    <td style={tableCellStyle}>{rowData.sl_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_GL_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sl_IN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sl_DD_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sl_PY_CO}</td>
                    <td style={tableCellStyle}>{rowData.sl_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.sl_PA_ST}</td>
                    <td style={tableCellStyle}>{rowData.sl_GR_AM}</td>
                    <td style={tableCellStyle}>{rowData.sl_OP_AM}</td>
                    <td style={tableCellStyle}>{rowData.sl_TA_AM}</td>
                    <td style={tableCellStyle}>{rowData.sl_TX_AM}</td>
                    <td style={tableCellStyle}>{rowData.sl_PD_AM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_ST_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sl_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.sl_CR_RT}</td>
                    <td style={tableCellStyle}>{rowData.sl_GR_AM}</td>
                    <td style={tableCellStyle}>{rowData.sl_OP_FR}</td>
                    <td style={tableCellStyle}>{rowData.sl_TA_FR}</td>
                    <td style={tableCellStyle}>{rowData.sl_TX_FR}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sl_SU_IN}</td>
                    <td style={tableCellStyle}>{rowData.sl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.sl_BA_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_BA_NO}</td>
                    <td style={tableCellStyle}>{rowData.sl_BA_DT}</td>
                    <td style={tableCellStyle}>{rowData.sl_ST_CD}</td>
                   
                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <PostVouchersDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        rh_CR_CO={rh_CR_CO}
        sl_SU_NO={sl_SU_NO}
        sl_CO_NM={sl_CO_NM}
        sl_CR_CD={sl_CR_CD}
        sl_PY_CO={sl_PY_CO}
        setsl_CO_NM={setsl_CO_NM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNOA={SelectedemEMNOA}
        PostVoucherstable={PostVoucherstable} 
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        setSelectedemEMNOA={setSelectedemEMNOA}
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default PostVouchersMain;
