import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function BedDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  handleDischarge,
  setFormData,

  bd_ST_CD,
  setbd_ST_CD,
  bd_DE_PT,
  setbd_DE_PT,
  bd_RM_TY,
  setbd_RM_TY,
  //bdUkNo,
}) {
  const {
    bdUkNo,
    bd_PA_ID,
    bd_PA_NM,
    bd_VI_NO,
    bd_BD_NO,
    bd_BD_ST,
    bd_CH_RG,
  } = data;
  const { borderbox, inboxstyle, savestyle, cancelstyle, Dialogstyle, } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [bd_RM_TYOptions, setbd_RM_TYOptions] = useState([]);
  const [bd_DE_PTOptions, setbd_DE_PTOptions] = useState([]);
  const [bd_ST_CDOptions, setbd_ST_CDOptions] = useState([]);
  // ad name
  const onchangeDropDownFormDataDepartment = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_DE_PT(value);
  };
  const onchangeDropDownFormDataRoomType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_RM_TY(value);
  };
  const onchangeDropDownFormDataStatus = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_ST_CD(value);
  };
  useEffect(() => {
    fetchDropdownDepartment();
    fetchDropdownRoomType();
    fetchDropdownStatus();
  }, []);

  const fetchDropdownDepartment = async () => {
    const Options = await fetchClassCodeDS("BD/DP");
    setbd_DE_PTOptions(Options);
  };
  const fetchDropdownRoomType = async () => {
    const Options = await fetchClassCodeDS("BD/RT");
    setbd_RM_TYOptions(Options);
  };
  const fetchDropdownStatus = async () => {
    const Options = await fetchClassCodeDS("BT/ST");
    setbd_ST_CDOptions(Options);
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const isUpdateMode = Boolean(bdUkNo);
  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{bdUkNo ? "  Bed Details" : "Add Bed Details"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-plus"
          onClick={handleFormSubmit}
          tooltip={isUpdateMode ? "Admit" : "Submit"}
        />
        
        <Button
          style={savestyle}
          onClick={() => handleFormSubmit("transfer")}
          tooltip={isUpdateMode ? "Transfer" : "Submit"}
        >
          <span style={{ marginRight: '10px' }}>⇆</span>
          {isUpdateMode ? "Transfer" : "Submit"}
        </Button>

        <Button
          style={savestyle}
          onClick={() => handleDischarge("discharge")}
          tooltip={isUpdateMode ? "Discharge" : "Submit"}
        >
          <span style={{ marginRight: '30px' }}>−</span>
          {isUpdateMode ? "Discharge" : "Submit"}
        </Button>


        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={borderbox}>
          <div className="row">
            {/* Details */}
            <div className="col-lg-4 col-md-12">
              <div style={inboxstyle}>
                {/* <h3 className="font-bold" style={{ color: "black", fontSize: "large", textDecoration: "underline" }}>Details</h3> */}
                <div className="formgrid grid">
                  <div style={{ width: "20rem" }}>
                    <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                      <label htmlFor="bdUkNo" className="font-bold">
                        ukNo
                      </label>
                      <InputText
                        id="bdUkNo"
                        name="bdUkNo"
                        defaultValue={bdUkNo}
                        style={{ display: 'none' }}
                        disabled
                      />
                    </div>

                    {/* Department */}
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="ad_IT_GP" className="font-bold">Department</label>
                      <Dropdown
                        id="bd_DE_PT"
                        name="bd_DE_PT"
                        value={bd_DE_PT}
                        options={bd_DE_PTOptions}
                        onChange={(e) => onchangeDropDownFormDataDepartment("bd_DE_PT", e.value)}
                        placeholder="Department"

                      />
                    </div>
                    {/* Room Type */}
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="bd_RM_TY" className="font-bold">Room Type</label>
                      <Dropdown
                        id="bd_RM_TY"
                        name="bd_RM_TY"
                        value={bd_RM_TY}
                        options={bd_RM_TYOptions}
                        onChange={(e) => onchangeDropDownFormDataRoomType("bd_RM_TY", e.value)}
                        placeholder="Room Type"

                      />
                    </div>
                    {/* Status */}
                    <div className="field col-12 mb-3 d-flex justify-content-between align-items-center">
                      <label htmlFor="bd_ST_CD" className="font-bold">Status</label>
                      <Dropdown
                        id="bd_ST_CD"
                        name="bd_ST_CD"
                        value={bd_ST_CD}
                        options={bd_ST_CDOptions}
                        onChange={(e) => onchangeDropDownFormDataStatus("bd_ST_CD", e.value)}
                        placeholder="Status"
                        style={{ width: '140px' }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Patient Details*/}
            <div className="col-lg-4 col-md-12">
              <div style={inboxstyle}>
                <h3 className="font-bold" style={{ color: "black", fontSize: "large", textDecoration: "underline" }}>Patient Details</h3>
                <div className="formgrid grid">
                  <div style={{ width: "20rem" }}>
                    {/* Patient ID  */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_PA_ID" className="font-bold" style={{ marginRight: '10px' }}> Patient ID</label>
                      <InputText
                        id="bd_PA_ID"
                        name="bd_PA_ID"
                        value={bd_PA_ID}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                    {/* Name */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_PA_NM" className="font-bold" style={{ marginRight: '10px' }}> Name</label>
                      <InputText
                        id="bd_PA_NM"
                        name="bd_PA_NM"
                        value={bd_PA_NM}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                    {/* Visit No */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_VI_NO" className="font-bold" style={{ marginRight: '10px' }}> Visit No</label>
                      <InputText
                        id="bd_VI_NO"
                        name="bd_VI_NO"
                        value={bd_VI_NO}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* DDPP */}
            <div className="col-lg-4 col-md-12">
              <div style={inboxstyle}>
                <h3 className="font-bold" style={{ color: "black", fontSize: "large", textDecoration: "underline" }}>Bed Details</h3>
                <div className="formgrid grid">
                  <div style={{ width: "20rem" }}>
                    {/* Bed No */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_BD_NO" className="font-bold" style={{ marginRight: '10px' }}>  Bed No</label>
                      <InputText
                        id="bd_BD_NO"
                        name="bd_BD_NO"
                        value={bd_BD_NO}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                    {/* Status */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_BD_ST" className="font-bold" style={{ marginRight: '10px' }}>Status</label>
                      <InputText
                        id="bd_BD_ST"
                        name="bd_BD_ST"
                        value={bd_BD_ST}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                    {/* Charge */}
                    <div className="field col-4 mb-3 d-flex align-items-center">
                      <label htmlFor="bd_CH_RG" className="font-bold" style={{ marginRight: '10px' }}>Charge</label>
                      <InputText
                        id="bd_CH_RG"
                        name="bd_CH_RG"
                        value={bd_CH_RG}
                        onChange={(e) => onchangedata(e)}
                        style={{ width: '300px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              backgroundColor: 'white',
              padding: 10,
              border: '1px solid black',
              color: "black",
              width: '150px',
              fontSize: '18px',
              textAlign: 'center'
            }}>
              Available
            </div>
            <div style={{
              backgroundColor: '#33cc33',
              padding: 10,
              border: '1px solid black',
              color: "black",
              width: '150px',
              fontSize: '18px',
              textAlign: 'center'
            }}>
              Occupied
            </div>
            <div style={{
              backgroundColor: 'red',
              padding: 10,
              border: '1px solid black',
              color: "black",
              width: '150px',
              fontSize: '18px',
              textAlign: 'center'
            }}>
              Lost
            </div>
            <div style={{
              backgroundColor: '#3399ff',
              padding: 10,
              border: '1px solid black',
              width: '150px',
              color: "black",
              fontSize: '18px',
              textAlign: 'center'
            }}>
              Isolation
            </div>
          </div>

        </div>

      </div>


    </Dialog>
  );
}
