import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
export default function CustomerAdvanceDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,Getamt,
  onchangeDropDownFormData2,
  onchangeReceivedate,
  rh_CR_CD,
  // rh_CR_CO,
  rh_RD_TY,
  rh_DO_CO,
  rh_CU_NO,
  setrh_CU_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNO1,
  setSelectedemEMNO1,
  rh_RC_NO,
  rh_UK_NO,
  rh_RC_AM,
  setrh_RC_AM,
}) {
  const {
  // rh_RC_AM,
  rh_RC_FR,
  rh_GL_DT,
  rh_CR_RT,
  rh_CQ_NO,
  rh_RM_EX,
  rh_GL_BA,
  name,
  rh_RC_DT,
  ph_SU_IN,
  rh_CR_CO
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [rh_CR_COOptions, setrh_CR_COOptions] = useState([]);
  const [em_EM_DSOptions, setem_EM_DSOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;
  // designation
  const parameter2 = "ME/PL";
  const finalUrl2 = `${url}/${parameter2}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);
  // Currency
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setrh_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // Autopost
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setem_EM_DSOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "rh_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = async (data) => {
  setSelectedemEMNO1(data.ab_AD_NM);
  // onchangeFormData("rh_CU_NO", data.abAdNo);
  setrh_CU_NO(data.abAdNo);
  const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
  const requestData={
      "cu_CU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData1("rh_CR_CO", dataCuMt.cu_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("rh_CR_CD").value, //base curr
          "cr_FR_CR": dataCuMt.rh_CR_CO, //currency
          "cr_EF_DT": data.rh_RC_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios.post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rh_CR_RT").value = dataExRt.cr_CO_MR;
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNO(data.am_AC_DS);
    onchangeFormData("rh_GL_BA", data.account);
    setAccountPopupVisible(false);
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const isUpdateMode = Boolean(rh_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{rh_UK_NO ? "Revise Customer Advance" : "Add Customer Advance"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
        <div  style={{display:"none"}}>
        <label htmlFor="rh_UK_NO" className="font-bold">
          UKNO
        </label>
        <InputText
          id="rh_UK_NO"
          name="rh_UK_NO"
          value={rh_UK_NO}
          onChange={(e) => onchangedata(e)}
        />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rh_RC_NO" className="font-bold">
          Payment No
          </label>
          <InputText disabled
            id="rh_RC_NO"
            name="rh_RC_NO"
            value={rh_RC_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rh_RD_TY" className="font-bold">
          Type
          </label>
          <InputText disabled
            id="rh_RD_TY"
            name="rh_RD_TY"
            value={rh_RD_TY}
            onChange={(e) => onchangedata(e)}
          />              
        </div>    
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rh_DO_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="rh_DO_CO"
            name="rh_DO_CO"
            value={rh_DO_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>  
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rh_RC_DT" className="font-bold">
          Payment Date
          </label>
          <Calendar
            id="rh_RC_DT"
            name="rh_RC_DT"
            value={moment(data.rh_RC_DT, "YYYYMMDD").isValid() ? moment(data.rh_RC_DT, "YYYYMMDD").toDate() : null}
            onChange={(e) => { onchangeReceivedate({ target: { name: "rh_RC_DT", value: moment(e.value).format("YYYYMMDD") } }); }}
            dateFormat="dd/mm/yy"
          /> 
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rh_GL_DT" className="font-bold">
          GL Date
          </label>
          <Calendar
              id="rh_GL_DT"
              name="rh_GL_DT" 
              value={moment(data.rh_GL_DT, "YYYYMMDD").isValid() ? moment(data.rh_GL_DT, "YYYYMMDD").toDate() : null}
              onChange={handleDateChange}
              dateFormat="dd/mm/yy"
            />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
        <label htmlFor="rh_CU_NO" className="font-bold">
        Customer  <span style={{ color: 'red' }}>*</span>
        </label>
        <div style={{ display: "flex" }}>
          <InputText
            id="ab_AD_NM"
            name="ab_AD_NM"
            onChange={(Event) => onchangedata(Event)}
            onFocus={() => setShowSearchIcon(true)}
            value={SelectedemEMNO1}
          />
            {showSearchIcon && (
          <i
            className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
            }}
            onClick={openCustomerPopup}
          ></i>
          )}
        </div>
        <div style={{ display: "none" }}>
          <InputText
            id="rh_CU_NO"
            name="rh_CU_NO"
            defaultValue={rh_CU_NO}
            onChange={(Event) => onchangedata(Event)}
          />          
        </div>
        </div>  
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_CR_CO" className="font-bold">
              Currency
              </label>
              <Dropdown
                id="rh_CR_CO"
                name="rh_CR_CO"
                value={data.rh_CR_CO}
                options={rh_CR_COOptions}
                onChange={(e) => onchangeDropDownFormData1("rh_CR_CO", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_CR_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
                id="rh_CR_RT"
                name="rh_CR_RT"
                value={rh_CR_RT}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_CR_CD" className="font-bold">
              Base Currency
              </label>
              <InputText disabled
                id="rh_CR_CD"
                name="rh_CR_CD"
                value={rh_CR_CD}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_GL_BA" className="font-bold">
              Bank Account <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="am_AC_DS"
                  name="am_AC_DS"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedemEMNO}
                />
                  {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
                )}
              </div>
        <div style={{ display: "none" }}>
          <InputText
            id="rh_GL_BA"
            name="rh_GL_BA"
            value={rh_GL_BA}
            onChange={(Event) => onchangedata(Event)}
          />
        </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_RC_AM" className="font-bold">
                Amount
              </label>
              <InputText 
              id="rh_RC_AM"
              name="rh_RC_AM"
              defaultValue={data.rh_CR_CO !== rh_CR_CD ? 0 : rh_RC_AM}
              onChange={(e) => onchangedata(e)}
              disabled={data.rh_CR_CO !== rh_CR_CD}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_RC_FR" className="font-bold">
              Foreign Amount
              </label>
              {/* <InputText 
                id="rh_RC_FR"
                name="rh_RC_FR"
                value={data.rh_CR_CO === rh_CR_CD ? 0 : rh_RC_FR}
                onChange={(e) => { onchangedata(e); Getamt(); }}
                disabled={data.rh_CR_CO === rh_CR_CD}
              /> */}
               <InputText
                  id="rh_RC_FR"
                  name="rh_RC_FR"
                  value={rh_CR_CO === rh_CR_CD ? "0" : rh_RC_FR}
                  onChange={(e) => { onchangedata(e); Getamt(); }}
                  disabled={rh_CR_CO === rh_CR_CD}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_CQ_NO" className="font-bold">
              NFT/Cheque
              </label>
              <InputText
                id="rh_CQ_NO"
                name="rh_CQ_NO"
                defaultValue={rh_CQ_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rh_RM_EX" className="font-bold">
            Reference
              </label>
              <InputText
                id="rh_RM_EX"
                name="rh_RM_EX"
                defaultValue={rh_RM_EX}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="auto" className="font-bold">
                Auto Post
              </label>
              <InputText
                id="auto"
                name="auto"
                value={"Yes"}
                disabled              
              />
            </div>
          </div>
        </div>
      </div>    
      <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      />
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
