import React, { useState } from "react";
import PropTypes from "prop-types";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";

const LeadIDPopupData = ({
  tableData,
  filters,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
  handleFilterChange,
  toast,
  handleClose,
  onDataSelect,
  handleSearch,
  onchangedata,
  handleCloseContact,
  openContact,
}) => {
  const tableHeaderStyle = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#e1e1e1",
  };
  const {formatDate ,Dialogstyle} = ButtonStyles();

  const inputStyle = {
    width: "100%",
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
  };

  const tableCellStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  };
  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
    width:"100%",
    backgroundColor:"#f5f4f4"
  };
  const tableCellStyleH = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
    display:"none"
  };
  return (
    <Dialog id="dialog-style"
      visible={openContact}
      onHide={handleCloseContact}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header="Lead Details"
      modal
      className="p-fluid"
    >
      <Toast ref={toast} />
      <div className="card">
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    value={filters["slLdId"] ? filters["slLdId"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "slLdId",
                        e.target.value,
                        filters["slLdId"]
                          ? filters["slLdId"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input 
                    type="text"
                    value={filters["ab_AD_NM"] ? filters["ab_AD_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ab_AD_NM",
                        e.target.value,
                        filters["ab_AD_NM"]
                          ? filters["ab_AD_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TR_DT"] ? filters["sl_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TR_DT",
                        e.target.value,
                        filters["sl_TR_DT"]
                          ? filters["sl_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_LD_OW"] ? filters["sl_LD_OW"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_LD_OW",
                        e.target.value,
                        filters["sl_LD_OW"]
                          ? filters["sl_LD_OW"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CO_NM"] ? filters["sl_CO_NM"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CO_NM",
                        e.target.value,
                        filters["sl_CO_NM"]
                          ? filters["sl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CN_NO"] ? filters["sl_CN_NO"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CN_NO",
                        e.target.value,
                        filters["sl_CN_NO"]
                          ? filters["sl_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TI_TL"] ? filters["sl_TI_TL"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TI_TL",
                        e.target.value,
                        filters["sl_TI_TL"]
                          ? filters["sl_TI_TL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cc_PH_NO"] ? filters["cc_PH_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cc_PH_NO",
                        e.target.value,
                        filters["cc_PH_NO"]
                          ? filters["cc_PH_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_AD_EM"] ? filters["sl_AD_EM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_AD_EM",
                        e.target.value,
                        filters["sl_AD_EM"]
                          ? filters["sl_AD_EM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_IN_RY"] ? filters["sl_IN_RY"].value : ""}
                    readOnly
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_IN_RY",
                        e.target.value,
                        filters["sl_IN_RY"]
                          ? filters["sl_IN_RY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}>Lead ID</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Date</th>  
                <th style={tableHeaderStyle}>Lead Owner</th>                 
                <th style={{display:"none"}} >Customer</th>
                <th style={tableHeaderStyle}>Customer</th>
                <th style={{display:"none"}}>Contact number</th>
                <th style={tableHeaderStyle}>Contact</th>
                <th style={tableHeaderStyle}>Title</th>
                <th style={tableHeaderStyle}>Phone</th>
                <th style={tableHeaderStyle}>Email</th>
                <th style={tableHeaderStyle}>Industry</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => {
                  return (
                    <tr
                key={rowData.cc_UK_NO}
                onClick={() => onDataSelect(rowData)}
                style={{
                  backgroundColor:
                    index % 2 === 0 ? "aliceblue" : "#ffffff",
                }}
                className="table-row"
              >
                      <td style={tableCellStyle}>{rowData.slLdId}</td>
                      <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                      <td style={tableCellStyle}>{formatDate(rowData.sl_TR_DT)}</td>                     
                    <td style={tableCellStyle}>{rowData.cc_EM_NM}</td>                    
                    <td style={tableCellStyleH}>{rowData.sl_LD_OW}</td>                   
                    <td  style={{display:"none"}}>{rowData.sl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.ab_AD_NM}</td>
                    <td style={{display:"none"}}>{rowData.sl_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.cc_EM_CO}</td>
                    {/* <td style={tableCellStyle}>{rowData.sl_CO_PE}</td> */}
                    <td style={tableCellStyle}>{rowData.cc_DE_GN}</td>
                    <td style={tableCellStyle}>{rowData.cc_PH_NO}</td>
                    <td style={tableCellStyle}>{rowData.sl_AD_EM}</td>
                    <td style={tableCellStyle}>{rowData.industry}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="Pagination" style={{ marginLeft: "85%" }}>
          <span
            onClick={handleFirstPage}
            style={{
              fontSize: "200%",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            &laquo;{" "}
          </span>{" "}
          <span
            onClick={handlePreviousPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Prev{" "}
          </span>{" "}
          <span style={{ whiteSpace: "pre-wrap", fontSize: "111%" }}> </span>{" "}
          <span
            onClick={handleNextPage}
            style={{
              cursor: "pointer",
              fontSize: "111%",
              whiteSpace: "pre-wrap",
            }}
          >
            Next{" "}
          </span>{" "}
          <span
            onClick={handleLastPage}
            style={{ fontSize: "200%", cursor: "pointer" }}
          >
            &raquo;
          </span>
        </div>
      </div>
    </Dialog>
  );
};

LeadIDPopupData.propTypes = {
  tableData: PropTypes.array,
  filters: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  toast: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDataSelect: PropTypes.func,
};

export default LeadIDPopupData;
