import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import { InputMask } from "primereact/inputmask";
export default function TableReservationDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  setFormData,
  td_CO_CT,
  settd_CO_CT,
  td_BL_CK,
  settd_BL_CK,
  td_ST_CD,
  settd_ST_CD,
  td_TB_NO,
  settd_TB_NO
}) {
  const {
    tdUkNo,
    td_TR_DT,
    td_IN_TM,
    td_OU_TM,
  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [td_CO_CTOptions, settd_CO_CTOptions] = useState([]);
  const [td_BL_CKOptions, settd_BL_CKOptions] = useState([]);
  const [td_ST_CDOptions, settd_ST_CDOptions] = useState([]);
  const [td_TB_NOOptions, settd_TB_NOOptions] = useState([]);
// Cost Centre
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_CO_CT(value);
  };
// Block
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_BL_CK(value);
  };
// Table NO
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_TB_NO(value);
  };
// Status
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settd_ST_CD(value);
  };
  useEffect(() => {
    fetchDropdownCost();
    fetchDropdownBlck();
    fetchDropdownTable();
    fetchDropdownStatus();
  }, []);

  const fetchDropdownCost = async () => {
    const Options = await fetchCostCenter();
    settd_CO_CTOptions(Options);
  };
  const fetchDropdownBlck = async () => {
    const Options = await fetchClassCodeDS("TA/BL");
    settd_BL_CKOptions(Options);
  };
  const fetchDropdownTable = async () => {
    const Options = await fetchClassCodeDS("CP/LN");
    settd_TB_NOOptions(Options);
  };
  const fetchDropdownStatus = async () => {
    const Options = await fetchClassCodeDS("TB/ST");
    settd_ST_CDOptions(Options);
  };
  
// Handle form data change
const onChangeTime=(name,value,index)=>{
  value= moment(value, "HH:mm:ss").format("HHmmss");
  onchangeFormData(name, value,index);
};
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "td_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};
  const isUpdateMode = Boolean(tdUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "64rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={tdUkNo ? "Revise TableReservation" : "Add TableReservation"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div style={inboxstyle}>
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_CO_CT" className="font-bold">
                     Cost Centre
                    </label>
                    <Dropdown
                      name="td_CO_CT"
                      value={td_CO_CT}
                      options={td_CO_CTOptions}
                      onChange={(e) => onchangeDropDownFormData1("td_CO_CT", e.value)}
                      placeholder="Select Cost Centre"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_BL_CK" className="font-bold">
                     Block
                    </label>
                    <Dropdown
                      name="td_BL_CK"
                      value={td_BL_CK}
                      options={td_BL_CKOptions}
                      onChange={(e) => onchangeDropDownFormData2("td_BL_CK", e.value)}
                      placeholder="Select Block"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_TB_NO" className="font-bold">
                      Table No
                    </label>
                    <Dropdown
                      name="td_TB_NO"
                      value={td_TB_NO}
                      options={td_TB_NOOptions}
                      onChange={(e) => onchangeDropDownFormData3("td_TB_NO", e.value)}
                      placeholder="Select Table No"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_IN_TM" className="font-bold">
                      In Time
                    </label>
                    <InputMask
                      id={td_IN_TM}
                      name={td_IN_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("td_IN_TM",e.target.value,);}}
                      value={moment(td_IN_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_OU_TM" className="font-bold">
                        Out Time
                    </label>
                    <InputMask
                      id={td_OU_TM}
                      name={td_OU_TM}
                      mask="99:99:99"
                      onBlur={(e) => { onChangeTime("td_OU_TM",e.target.value,);}}
                      value={moment(td_OU_TM, "HHmmss").format("HH:mm:ss")}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_TR_DT" className="font-bold">
                     Date
                    </label>
                    <Calendar
                      id="td_TR_DT"
                      name="td_TR_DT"
                      value={moment(td_TR_DT, "YYYYMMDD").toDate()}
                      onChange={handleDateChange}
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="td_ST_CD" className="font-bold">
                       Status
                    </label>
                    <Dropdown
                      id="td_ST_CD"
                      name="td_ST_CD"
                      value={td_ST_CD}
                      options={td_ST_CDOptions}
                      onChange={(e) => onchangeDropDownFormData4("td_ST_CD", e.value)}
                      placeholder="Select Status"
                    />
                  </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
