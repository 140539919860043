// ReNamePopup.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";


const ReNamePopup = ({ isOpen, onClose, onCreateFile }) => {
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');

  const toast = useRef(null);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [popupVisible, setPopupVisible] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileName.trim() === '') return;
    onCreateFile(fileName, fileContent); 
    setFileName('');
    setFileContent('');
  };

   // Rename
   const handleOptionClick = async (option) => {
    const Request = {
      "fdUkNo": option.fdUkNo,
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/ReNameFolderAndFile`, Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Rename successfully.",
        });
      } else {
        throw new Error("Failed to Rename.");
      }
    } catch (error) {
      console.error("Error Rename:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
    setPopupVisible(false); // Close popup after action
  };

  return (
    isOpen && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Rename</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              autoFocus
            />
            <button type="submit">OK</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default ReNamePopup;
