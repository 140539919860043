import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import AppointmentBookingDialog from "./AppointmentBookingDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  adUkNo:"",
  ad_PA_ID:"",
  ad_AP_TM:"",
  ad_DE_PT:"",
  ad_TK_NO:"",
  ad_DR_NO:"",
  ad_AP_DT:"",
  ad_AP_TM:"",
 
};

const initialFilters = {
  ad_PA_ID: { value: "", operation: "" },
  ad_AP_TM: { value: "", operation: "" },
  ad_DE_PT: { value: "", operation: "" },
  ad_TK_NO: { value: "", operation: "" },
  ad_DR_NO: { value: "", operation: "" },
  ad_AP_DT: { value: "", operation: "" },
  ad_AP_TM: { value: "", operation: "" },
 
};

const AppointmentBookingMain = () => {
  // buttonstyles
  const {formatDate,formatSavedTime, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [specialization, setspecialization ] = useState("");
  const [qualification, setqualification ] = useState("");  
  const [ad_DE_PT , setad_DE_PT] = useState("");
  const [ad_AP_TM , setad_AP_TM] = useState("");
  const [day , setday] = useState("");
  
// Advancesearch
const [SelectedItem, setselectedItem ] = useState("");
const [selectedSymptom, setselectedSymptom ] = useState("");

// Next No
  const [ad_PA_ID, setad_PA_ID ] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [AppointmentBookingTableData, setAppointmentBookingTableData] = useState([{}]);

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setAppointmentBookingTableData([{}]);
    setspecialization(null);
    setqualification(null);
    setad_DE_PT(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/AppointmentBooking/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const WorkCentreData = response.data;
          delete WorkCentreData.AppointmentBookingList[0].ad_PA_ID;
          delete WorkCentreData.AppointmentBookingList[0].adUkNo;
          for (let i = 0; i < WorkCentreData.AppointmentBookingList.length; i++) {
            delete WorkCentreData.AppointmentBookingList[i].pdUkNo;
          }
          setAppointmentBookingTableData(WorkCentreData.AppointmentBookingList);
          setFormData(WorkCentreData.AppointmentBookingList[0]);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);


  const handleDefault = async () => {
    setad_DE_PT(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "ad_AP_TM"){
              setad_AP_TM(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/AppointmentBooking/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/AppointmentBooking/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const WorkCentreData = response.data;
        setAppointmentBookingTableData(WorkCentreData.AppointmentBookingList);
        setFormData(WorkCentreData.AppointmentBookingList[0]);
        setad_DE_PT(response.data.ad_DE_PT);
        setqualification(response.data.qualification);
        setspecialization(WorkCentreData.AppointmentBookingList[0].specialization);
        setad_AP_TM(response.data.ad_AP_TM);
       
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBody = {};
function values () {
  const table = document.getElementById("operationtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const AppointmentBookingList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const RQ_DT = document.getElementsByName("er_TR_DT_" + i)[0];
    const details = {
        adUkNo : document.getElementById("adUkNo_" + i).value,
        er_EX_CT : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
        er_TR_DT : RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        er_CO_CT : table.tBodies[0].rows[i].cells[3].children[0].children[1].children[0].value,
        er_LO_CN : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
        er_TR_QT : document.getElementById("er_TR_QT_" + i).value,
        er_RA_TE : document.getElementById("er_RA_TE_" + i).value,
        er_EX_AM : document.getElementById("er_EX_AM_" + i).value,
        er_TR_CR :table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
        er_AL_AM : document.getElementById("er_AL_AM_" + i).value,
    };
    AppointmentBookingList.push(details);
  }
  requestBody = {
  AppointmentBookingList,
    adUkNo   : FormData.adUkNo,
    ad_PA_ID : document.getElementById("ad_PA_ID").value,
    ad_AP_TM : document.getElementById("ad_AP_TM").value,
    ad_DE_PT : document.getElementById("ad_DE_PT").value,
    ad_DR_NO : document.getElementById("ad_DR_NO").value,
    er_MN_NO : document.getElementById("er_MN_NO").value,
    ad_TK_NO : FormData.ad_TK_NO,
    ad_DE_PT : FormData.ad_DE_PT,
    qualification : FormData.qualification,
    specialization : FormData.specialization,
    ad_AP_TM : FormData.ad_AP_TM,
    er_BU_PR : document.getElementById("er_BU_PR").value,
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = document.getElementById("ad_PA_ID").value;
  const ukNo = FormData.adUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/AppointmentBooking/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/AppointmentBooking/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  } else {
      
      const OR_DT = FormData.ad_TK_NO;
      if (OR_DT) {
        const currentYear = OR_DT.toString();
        const firstHalf = currentYear.substring(0, 2);
        const secondHalf = currentYear.substring(2, 4);
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${ad_AP_TM}/${secondHalf}/1000/${firstHalf}`;
        const finalUrl1 = `${urlNextNumber}/${parameters}`;
        fetch(finalUrl1, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        })
          .then((resp) => {
            if (!resp.ok) {
              throw new Error(`Request failed with status: ${resp.status}`);
            }
            return resp.json();
          })
          .then((resp) => {
            setad_PA_ID(resp.dn_DO_NO);
            document.getElementById("ad_PA_ID").value = resp.dn_DO_NO;

            values();
            axios.post(`${domain}/api/AppointmentBooking/Add`, requestBody, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error saving data:", error);
                });

          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_PA_ID"] ? filters["ad_PA_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_PA_ID",
                        e.target.value,
                        filters["ad_PA_ID"]
                          ? filters["ad_PA_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_AP_TM"] ? filters["ad_AP_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AP_TM",
                        e.target.value,
                        filters["ad_AP_TM"] ? filters["ad_AP_TM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_DE_PT"] ? filters["ad_DE_PT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_DE_PT",
                        e.target.value,
                        filters["ad_DE_PT"]
                          ? filters["ad_DE_PT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["ad_TK_NO"] ? filters["ad_TK_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_TK_NO",
                        e.target.value,
                        filters["ad_TK_NO"]
                          ? filters["ad_TK_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_DR_NO"] ? filters["ad_DR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_DR_NO",
                        e.target.value,
                        filters["ad_DR_NO"] ? filters["ad_DR_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ad_AP_DT"] ? filters["ad_AP_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AP_DT",
                        e.target.value,
                        filters["ad_AP_DT"]
                          ? filters["ad_AP_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["ad_AP_TM"] ? filters["ad_AP_TM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ad_AP_TM",
                        e.target.value,
                        filters["ad_AP_TM"]
                          ? filters["ad_AP_TM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td> 
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Patient ID</th>
                <th style={tableHeaderStyle}>Trans Date</th>
                <th style={tableHeaderStyle}>Department</th>  
                <th style={tableHeaderStyle}>Token No</th>   
                <th style={tableHeaderStyle}>Doctor</th>  
                <th style={tableHeaderStyle}>Appointment Date</th> 
                <th style={tableHeaderStyle}>Appointment Time</th> 
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.adUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.adUkNo}
                        checked={selectedRows.includes(rowData.adUkNo)}
                        onChange={() => handleRowSelect(rowData.adUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.ad_PA_ID}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ad_AP_TM)}</td>
                    <td style={tableCellStyle}>{rowData.ad_DE_PT}</td>   
                    <td style={tableCellStyle}>{rowData.ad_TK_NO}</td>   
                    <td style={tableCellStyle}>{rowData.ad_DR_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.ad_AP_DT)}</td>
                    <td style={tableCellStyle}>{formatSavedTime(rowData.ad_AP_TM)}</td>             
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <AppointmentBookingDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        AppointmentBookingTableData={AppointmentBookingTableData}
        setAppointmentBookingTableData={setAppointmentBookingTableData}
        ad_AP_TM={ad_AP_TM}
        qualification={qualification}
        ad_PA_ID={ad_PA_ID}
        setqualification={setqualification}
        specialization={specialization}
        setspecialization={setspecialization}
        ad_DE_PT={ad_DE_PT}
        setad_DE_PT={setad_DE_PT}
        setad_AP_TM={setad_AP_TM}
        selectedSymptom={selectedSymptom}
        setselectedSymptom={setselectedSymptom}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        day={day}
        setday={setday}
      />
    </div>
  );
};

export default AppointmentBookingMain;
