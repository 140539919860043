// CreateFilePopup.js
import React, { useState } from 'react';

const CreateFilePopup = ({ isOpen, onClose, onCreateFile }) => {
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileName.trim() === '') return;
    onCreateFile(fileName, fileContent); // Pass file name and content to parent
    setFileName('');
    setFileContent('');
  };

  return (
    isOpen && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Create New File</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter file name"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              autoFocus
            />
            {/* <textarea
              placeholder="Enter file content"
              value={fileContent}
              onChange={(e) => setFileContent(e.target.value)}
            /> */}
            <button type="submit">Create</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </form>
        </div>
      </div>
    )
  );
};

export default CreateFilePopup;
