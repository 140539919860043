import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import PurchaseQuoteDialog from "./PurchaseQuoteDialog";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import axios from "axios";
import saveAs from "file-saver";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  pdUkNo:"",
  pd_OR_NO: "",
  pd_OR_TY: "",
  pd_OR_CO: "",
  pd_LN_NO:"",
  pd_HL_CD:"",
  pd_SU_NO:"",
  ph_OR_DT:"",
  sup_NAME:"",
  pd_CR_CD:"",
  pd_IT_NM: "",
  im_IT_DS: "",
  pd_OR_DT: "",
  pd_RQ_DT:"",
  pd_OR_QT:"",
  pd_TR_UM:"",
  pd_BS_UP:"",
  pd_OR_AT: "",
  pd_FR_UP: "",
  pd_FR_AT: "",
  pd_PY_TR:"",
  pd_RQ_NO:"",
  pd_RQ_TY:"",
  pd_ST_CD:"",
  ph_TR_CR:"",
  ph_CO_CT:"",
  ph_PY_TR:"",
  ph_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),
  ph_SU_NO:"",
  ph_OR_NO:"",
  phUkNO:"",
  ph_RL_NO:"",
  ph_SH_NO:"", 
  ph_RL_CO:"",
  ph_HL_CD:"",
};

const initialFilters = {
  pd_OR_NO: { value: "", operation: "" },
  pd_OR_TY: { value: "", operation: "" },
  pd_OR_CO: { value: "", operation: "" },
  pd_LN_NO: { value: "", operation: "" },
  pd_HL_CD: { value: "", operation: "" },
  pd_SU_NO: { value: "", operation: "" },
  sup_NAME: { value: "", operation: "" },
  pd_CR_CD: { value: "", operation: "" },
  pd_IT_NM: { value: "", operation: "" },
  im_IT_DS: { value: "", operation: "" },
  pd_OR_DT: { value: "", operation: "" },
  pd_RQ_DT: { value: "", operation: "" },
  pd_OR_QT: { value: "", operation: "" },
  pd_TR_UM: { value: "", operation: "" },
  pd_BS_UP: { value: "", operation: "" },
  pd_OR_AT: { value: "", operation: "" },
  pd_FR_UP: { value: "", operation: "" },
  pd_FR_AT: { value: "", operation: "" },
  pd_PY_TR: { value: "", operation: "" },
  pd_RQ_NO: { value: "", operation: "" },
  pd_RQ_TY: { value: "", operation: "" },
  pd_ST_CD: { value: "", operation: "" },
};

const PurchaseQuoteMain = () => {
  // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,cancelButtonStyles,inputStyle1,ListOrder
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [cancelFlag, setcancelFlag] = useState(false);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
// Dropdown
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl = `${urlcost}`;
  useEffect(() => {
    fetchDropdownOptions();
    
  }, [currentPage, pageSize, filters]);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };

  const OnchangeTableData = (key, value, index) => {
    const updatedData = [...PurchaseQuotetable];
    updatedData[index][key] = value;
    setPurchaseQuotetable(updatedData);
  };

  const [supplier, setSupplier] = useState("");
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [selectedAbAdNN, setSelectedAbAdNN] = useState("");
  const [selectedAbAdNNA, setSelectedAbAdNNA] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_PY_TR, setph_PY_TR] =  useState("");
  const [ph_OR_NO, setph_OR_NO] = useState("");
  const [ph_CO_CT, setph_CO_CT] = useState("");
  const [ph_TR_CR, setph_TR_CR] = useState("");
  const [ph_TX_AP, setph_TX_AP] = useState("");
  const [pd_TR_UM, setpd_TR_UM] = useState("");
  const [pd_SC_UM, setpd_SC_UM] = useState("");
  const [pd_CR_CD, setpd_CR_CD] = useState("");
  const [pd_TX_AP, setpd_TX_AP] = useState("");
  const [ph_SU_NO, setph_SU_NO] = useState("");
  const [ph_EX_RT, setph_EX_RT] =  useState("");
  const [ph_SH_NO, setph_SH_NO] =  useState("");
  const [pd_SU_NO, setpd_SU_NO] =  useState("");
  const [sup_NAME, setsup_NAME] =  useState("");
  const [CostCenter, setCostCenter] = useState("");
  const [selecteditem, setSelecteditem] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [item, setitem] = useState("");
  const [PurchaseQuotetable, setPurchaseQuotetable] = useState([{}]);
  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [total , settotal] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");
  const [fr_TOTAL , setfr_TOTAL] = useState("");
const [ph_RL_CO, setph_RL_CO] = useState(""); 
const [ph_RL_TY, setph_RL_TY] = useState("");
const [ph_HL_CD, setph_HL_CD] = useState("");
const [pd_CO_CT, setpd_CO_CT] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openSupplierPopup = (e) => {
    setSupplierPopupVisible(true);
   
  };
  const handleDataSelect = (data) => {
    setSelectedSupplier(data.ab_AD_NM);
    setSupplier(data.abAdNo);
    setSupplierPopupVisible(false);
    updateFilters (data.abAdNo);
  };

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const openitemPopup = (e) => {
    setItemPopupVisible(true);
   
  };
  const handleDataSelect1 = (data) => {
    setSelecteditem(data.im_IT_DS);
    setitem(data.im_IT_NM);
    setItemPopupVisible(false);
    updateFilters1 (data.im_IT_NM);
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  function onChnageExchangeRate (frCr,orderDate) {
    const exData={
      "cr_TO_CR": document.getElementById("ph_BS_CR").value, 
      "cr_FR_CR": frCr,
      "cr_EF_DT": orderDate
    }
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
            setph_EX_RT(dataExRt.cr_CO_MR);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setPurchaseQuotetable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNNA(null); 
    setcancelFlag(false);
    setph_EX_RT("");
    setph_PY_TR(null);
    setph_TR_CR(null);
    setph_TX_AP(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setpd_CR_CD(null);
    setpd_TX_AP(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setph_RL_TY(null);
    setph_RL_CO(null);
    setOpen(true);
  };
  const handleClearButton=()=>{
    setitem("");
    setSelecteditem("");
    setFilters("");
    setSupplier("");
    setSelectedSupplier("");
    setCostCenter("");
  }
  const handleClose = () => {
    setPurchaseQuotetable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setSelectedAbAdNM(null);
    setSelectedAbAdNN(null);
    setSelectedAbAdNNA(null);
    setph_PY_TR(null);
    setph_TR_CR(null);
    setph_TX_AP(null);
    setpd_TR_UM(null);
    setpd_SC_UM(null);
    setpd_CR_CD(null);
    setpd_TX_AP(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    settotal(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setfr_TOTAL(null);
    setph_RL_TY(null);
    setph_RL_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      // handleSearch();
      handleDefault();
    }
  };
  const addRow = () => {
    setPurchaseQuotetable([...PurchaseQuotetable, {"pd_SU_NO":FormData["pd_SU_NO"],
    "sup_NAME":FormData.sup_NAME,"pd_CR_CD":FormData["pd_CR_CD"]}]);
  };

  const deleteRow = () => {
    if (PurchaseQuotetable.length > 0) {
      const updatedData = [...PurchaseQuotetable];
      updatedData.pop();
      setPurchaseQuotetable(updatedData);
    }
  };
  const handleCopyData = async () => {
    setcancelFlag(false)
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/PurchaseQuote/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const PurchaseQuoteData = response.data;
          delete PurchaseQuoteData.purchaseQuotes.ph_OR_NO;
          delete PurchaseQuoteData.purchaseQuotes.phUkNO;
          for (let i = 0; i < PurchaseQuoteData.purchaseOrders.length; i++) {
            delete PurchaseQuoteData.purchaseOrders[i].pdUkNo;
            PurchaseQuoteData.purchaseOrders[i].pd_ST_CD = pd_ST_CD;
          }
          setFormData(PurchaseQuoteData.purchaseQuotes);
          setPurchaseQuotetable(PurchaseQuoteData.purchaseOrders);
          setSelectedemEMNO(PurchaseQuoteData.purchaseQuotes.sup_NAME); 
          setSelectedAbAdNM(PurchaseQuoteData.purchaseQuotes.shipto_NAME); 
          setSelectedAbAdNN(PurchaseQuoteData.purchaseOrders.sup_NAME);
          setph_TR_CR(PurchaseQuoteData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(PurchaseQuoteData.purchaseQuotes.ph_TX_AP);
          setph_PY_TR(PurchaseQuoteData.purchaseQuotes.ph_PY_TR);
          setpd_TR_UM(PurchaseQuoteData.pd_TR_UM);
          setpd_SC_UM(PurchaseQuoteData.pd_SC_UM);
          setpd_CR_CD(PurchaseQuoteData.pd_CR_CD);
          setpd_TX_AP(PurchaseQuoteData.pd_TX_AP);

          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_TAX_AMOUNT(response.data.purchaseQuotes.fr_TAX_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pd_SU_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters1 = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      pd_IT_NM: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const updateFilters2 = (sts) => {
    let updatedFilters = { ...filters };
    if (sts !== null) {
      updatedFilters = {
        ...updatedFilters,
        ph_CO_CT: {
          value: sts,
          operation: "LIKE",
        },
      };
    }

    setFilters(updatedFilters);
  };

  const HoldCodeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_HL_CD(value);
  };
  const [ph_OR_CO , setph_OR_CO ] = useState("");
  const [ph_OR_TY, setph_OR_TY] = useState("");
  const [ph_BS_CR, setph_BS_CR] = useState("");
  const [pd_ST_CD, setpd_ST_CD] = useState("");
  const [pd_IT_NM, setpd_IT_NM] = useState("");
  const [availability_AMOUNT, setavailability_AMOUNT] = useState("");
  const handleDefault = async () => {
    setph_OR_CO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
             
              if(fName === "PH_OR_TY"){
                setph_OR_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_BS_CR"){
                setph_BS_CR(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_CO_CT"){
                onchangeDropDownFormData1("ph_CO_CT",dataDefault.versionIds[i].default_VALUE);
                setph_CO_CT(dataDefault.versionIds[i].default_VALUE);
  
              }
              if(fName === "PD_ST_CD"){
                setpd_ST_CD(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PD_CO_CT"){
                setpd_CO_CT(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "PH_HL_CD"){
                HoldCodeDropdown("ph_HL_CD",dataDefault.versionIds[i].default_VALUE);
                setph_HL_CD(dataDefault.versionIds[i].default_VALUE);
  
              }
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
    handleDefault();
    
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
   
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PurchaseQuote/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangeDropDown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setCostCenter(value);
    updateFilters2(value);
  };

  const onchangeFieldSetFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };
  const onchangeOrderdate = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
    onChnageExchangeRate (ph_TR_CR,value);
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const fieldName=(FormData && FormData.pdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      PurchaseQuotetable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_CO_CT(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_TR_CR(value);
    
    const bsCr = document.getElementById("ph_BS_CR").value;
    const table = document.getElementById("PurchaseQuotetable");
    const totalRowCount = table.tBodies[0].rows.length;
    if(bsCr === value){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("pd_BS_UP_" + i).removeAttribute('disabled');
        document.getElementById("pd_FR_UP_" + i).setAttribute('disabled','disabled');
        document.getElementById("pd_FR_AT_" + i).value = '0';
        document.getElementById("pd_FR_DS_" + i).value = '0';
        document.getElementById("fr_NET_AMOUNT_" + i).value = '0';
      }
    } else {
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById("pd_FR_UP_" + i).removeAttribute('disabled');
        document.getElementById("pd_BS_UP_" + i).setAttribute('disabled','disabled');
      }
    }
    onChnageExchangeRate (value, FormData.ph_OR_DT);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setph_TX_AP(value);
   
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_TR_UM(value);

  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setpd_SC_UM(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpd_CR_CD(value);
  };
  const onchangeDropDownFormData7= (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setpd_TX_AP(value);
  };
  const onchangeDropDownFormData8= (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setph_PY_TR(value);
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseQuote/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseQuoteData = response.data;
        setPurchaseQuotetable(PurchaseQuoteData.purchaseOrders);
        setFormData(PurchaseQuoteData.purchaseQuotes);
         setSelectedemEMNO(PurchaseQuoteData.purchaseQuotes.sup_NAME);
         setSelectedAbAdNM(PurchaseQuoteData.purchaseQuotes.shipto_NAME); 
         setSelectedAbAdNN(PurchaseQuoteData.purchaseOrders.sup_NAME); 
          setph_PY_TR(PurchaseQuoteData.purchaseQuotes.ph_PY_TR);
          onchangeFormData("ph_TR_CR",PurchaseQuoteData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(response.data.ph_TX_AP);
          setpd_TR_UM(response.data.pd_TR_UM);
          setpd_SC_UM(response.data.pd_SC_UM);
          setpd_CR_CD(response.data.pd_CR_CD);
          setpd_TX_AP(response.data.pd_TX_AP);
          setpd_ST_CD(response.data.purchaseOrders[0].pd_ST_CD);
          setavailability_AMOUNT(response.data.purchaseOrders[0].availability_AMOUNT);
          setph_RL_TY(response.data.purchaseQuotes.ph_RL_TY);
          setph_RL_CO(response.data.purchaseQuotes.ph_RL_CO);
          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_TAX_AMOUNT(response.data.purchaseQuotes.fr_TAX_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancel = async () => {
    setcancelFlag(true)
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PurchaseQuote/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PurchaseQuoteData = response.data;
        setPurchaseQuotetable(PurchaseQuoteData.purchaseOrders);
        setFormData(PurchaseQuoteData.purchaseQuotes);
         setSelectedemEMNO(PurchaseQuoteData.purchaseQuotes.sup_NAME);
         setSelectedAbAdNM(PurchaseQuoteData.purchaseQuotes.shipto_NAME); 
         setSelectedAbAdNN(PurchaseQuoteData.purchaseOrders.sup_NAME); 
          setph_PY_TR(PurchaseQuoteData.purchaseQuotes.ph_PY_TR);
          onchangeFormData("ph_TR_CR",PurchaseQuoteData.purchaseQuotes.ph_TR_CR);
          setph_TX_AP(response.data.ph_TX_AP);
          setpd_TR_UM(response.data.pd_TR_UM);
          setpd_SC_UM(response.data.pd_SC_UM);
          setpd_CR_CD(response.data.pd_CR_CD);
          setpd_TX_AP(response.data.pd_TX_AP);
          setph_RL_TY(response.data.purchaseQuotes.ph_RL_TY);
        setph_RL_CO(response.data.purchaseQuotes.ph_RL_CO);
          setprice_AMOUNT(response.data.purchaseQuotes.price_AMOUNT);
          setdiscount_AMOUNT(response.data.purchaseQuotes.discount_AMOUNT);
          setnet_AMOUNT(response.data.purchaseQuotes.taxable_AMOUNT);
          settax_AMOUNT(response.data.purchaseQuotes.tax_AMOUNT);
          settotal(response.data.purchaseQuotes.total);
          setfr_PRICE_AMOUNT(response.data.purchaseQuotes.fr_PRICE_AMOUNT);
          setfr_DISCOUNT_AMOUNT(response.data.purchaseQuotes.fr_DISCOUNT_AMOUNT);
          setfr_NET_AMOUNT(response.data.purchaseQuotes.fr_TAXABLE_AMOUNT);
          setfr_TAX_AMOUNT(response.data.purchaseQuotes.fr_TAX_AMOUNT);
          setfr_TOTAL(response.data.purchaseQuotes.fr_TOTAL);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyInput = {};
  function values () {
      const purchaseQuotes = {
        phUkNO  : FormData.phUkNO,
        ph_OR_NO: FormData.ph_OR_NO,
        ph_OR_TY: ph_OR_TY,
        ph_OR_CO: ph_OR_CO,
        ph_OR_DT: FormData.ph_OR_DT,
        ph_RQ_DT: FormData.ph_RQ_DT,
        ph_TX_AP: FormData.ph_TX_AP,
        ph_SU_NO: ph_SU_NO,
        ph_PY_TR: ph_PY_TR,
        ph_SH_NO: ph_SH_NO,
        ph_CO_CT: ph_CO_CT,
        ph_TR_CR: ph_TR_CR,
        ph_EX_RT: ph_EX_RT,
        ph_BS_CR: ph_BS_CR,
        ph_DS_AM: FormData.ph_DS_AM,
        ph_DS_PR: FormData.ph_DS_PR,
        ph_RL_NO: FormData.ph_RL_NO,
        ph_RL_TY: FormData.ph_RL_TY,
        ph_RL_CO: FormData.ph_RL_CO,
        ph_HL_CD: ph_HL_CD,
      };
      const table = document.getElementById("PurchaseQuotetable");
      const totalRowCount = table.tBodies[0].rows.length;
      const purchaseOrders = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const Details = {
          pdUkNo:  document.getElementById("pdUkNo_" + i).value,
          pd_LN_NO: document.getElementById("pd_LN_NO_" + i).value,
          pd_IT_NM: document.getElementById("pd_IT_NM_" + i).value,
          im_IT_DS: document.getElementById("im_IT_DS_" + i).value,
          pd_OR_QT: document.getElementById("pd_OR_QT_" + i).value,
          pd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          availability_AMOUNT :document.getElementById("availability_AMOUNT_" + i).value,
          pd_LS_PR: document.getElementById("pd_LS_PR_" + i).value,
          pd_SU_NO :document.getElementById("pd_SU_NO_" + i).value,
          sup_NAME :document.getElementById("sup_NAME_" + i).value,
          pd_CR_CD :table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
          pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
          pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
          pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
          pd_DS_AM :document.getElementById("pd_DS_AM_" + i).value,
          net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
          pd_FR_UP :document.getElementById("pd_FR_UP_" + i).value,
          pd_FR_AT :document.getElementById("pd_FR_AT_" + i).value,
          pd_FR_DS :document.getElementById("pd_FR_DS_" + i).value,
          fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
          pd_TX_AP : document.getElementById("pd_TX_AP_" + i).value,
          pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
          pd_TX_AM: document.getElementById("pd_TX_AM_" + i).value,
          pd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_SC_QT: document.getElementById("pd_SC_QT_" + i).value,
          pd_SC_UM: table.tBodies[0].rows[i].cells[25].children[0].children[1].children[0].value,
          pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
          pd_CN_DT: CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_ST_CD: document.getElementById("pd_ST_CD_" + i).value,
          pd_CO_CT: document.getElementById("pd_CO_CT_" + i).value,
        };
        purchaseOrders.push(Details);
      }
      requestBodyInput = {
        purchaseQuotes,
        purchaseOrders,
      };
    }

    const handleFormSubmit = () => {
      values();
      const orNo = FormData.ph_OR_NO;
      const ukNo = FormData.phUkNO;
      if (orNo) {
        if (ukNo) {
          const confirm = window.confirm("Do you want to Update ?");
          const update = `${domain}/api/PurchaseQuote/Update`;
      
          if (confirm) {
            axios.put(update, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error updating data:", error);
              });
          }
        } else {
          axios.post(`${domain}/api/PurchaseQuote/Add`, requestBodyInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error adding data:", error);
            });
        }
      } else {
        const IN_DT = FormData.ph_OR_DT;
        if(IN_DT){
          const currentYear = IN_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${ph_OR_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;
  
          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setph_OR_NO(resp.dn_DO_NO);
            FormData.ph_OR_NO = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/PurchaseQuote/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
      }; 


      const PurchaseOrder = () => {    
        values();
        const orNo = FormData.ph_OR_NO;
        const ukNo = FormData.phUkNO;
        if (orNo) {
          if (ukNo) {
            const confirm = window.confirm("Do you want to Generate PO ?");
            const update = `${domain}/api/PurchaseRequisition/GeneratePurchaseOrder`;
            if (confirm) {
              axios
                .post(update, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error updating data:", error);
                });
            }
          } 
        }
    } ;

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".refund-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Cancel
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-times"  
        outlined
        className="mr-2 cancel-button"
        onClick={handleCancel}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  const AdvanceSearch = (
      <div className="formgrid grid">
         <div className="field col" >
    <label htmlFor="supplier" className="font-bold">
    Supplier <span style={{ color: 'red' }}>*</span>
    </label>
    <div style={{ display: "flex" }}>
      <InputText
      style={{display:"none"}}
        id="supplier"
        name="supplier"
        value={supplier}
      />
      <InputText
        id="sup_NAME"
        name="sup_NAME"
        readOnly
        style={{backgroundColor:"#ffffff"}}
        onChange={(Event) => onchangedata(Event)}
        onFocus={() => setShowSearchIcon(true)}
        value={selectedSupplier}
      />
       {showSearchIcon && (
      <i
        className="pi pi-search"
        style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "4%",
          cursor: "pointer",
        }}
        onClick={openSupplierPopup}
      ></i>
       )}
    </div>
    </div>
      
              <div className="field col" >
              <label htmlFor="item" className="font-bold">
              Item <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                style={{display:"none"}}
                  id="item"
                  name="item"
                  value={item}
                />
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  readOnly
                  style={{backgroundColor:"#ffffff"}}
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selecteditem}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openitemPopup}
                ></i>
                )}
              </div>
              </div>
            <div className="field col"   >
          <label htmlFor="CostCenter" className="font-bold">
          Cost Center
          </label>
          <Dropdown
            style={{ width: "90%" }}
            id="CostCenter"
            name="CostCenter"
            value={CostCenter}
            options={ph_CO_CTOptions}
            onChange={(e) => onchangeDropDown("CostCenter", e.value)}
            placeholder="Select Cost Centre"
          />
        </div>
            </div>
            

  );
  const rightClear = (
    <React.Fragment>
    <Tooltip  target=".clear-button" position="bottom" mouseTrack>
    <div>Clear Filter</div>
    </Tooltip>
    
    <Button
    style={{ color: "blue", transition: "color 0.3s", backgroundColor: "transparent" }}
    icon="pi pi-times"
    className="mr-2 clear-button"
    link
    onClick={handleClearButton}
    />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
         <div style={{ marginTop: "2%"}}>
        <Toolbar
          className="p-mb-4"
          left={AdvanceSearch}
          right={rightClear}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_NO"] ? filters["pd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_NO",
                        e.target.value,
                        filters["pd_OR_NO"]
                          ? filters["pd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_TY"] ? filters["pd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_TY",
                        e.target.value,
                        filters["pd_OR_TY"]
                          ? filters["pd_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_CO"] ? filters["pd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_CO",
                        e.target.value,
                        filters["pd_OR_CO"]
                          ? filters["pd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_LN_NO"] ? filters["pd_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_LN_NO",
                        e.target.value,
                        filters["pd_LN_NO"]
                          ? filters["pd_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["pd_HL_CD"] ? filters["pd_HL_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_HL_CD",
                        e.target.value,
                        filters["pd_HL_CD"]
                          ? filters["pd_HL_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_SU_NO"] ? filters["pd_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_SU_NO",
                        e.target.value,
                        filters["pd_SU_NO"]
                          ? filters["pd_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={ListOrder}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["sup_NAME"] ? filters["sup_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sup_NAME",
                        e.target.value,
                        filters["sup_NAME"]
                          ? filters["sup_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_CR_CD"] ? filters["pd_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_CR_CD",
                        e.target.value,
                        filters["pd_CR_CD"]
                          ? filters["pd_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_IT_NM"] ? filters["pd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_IT_NM",
                        e.target.value,
                        filters["pd_IT_NM"]
                          ? filters["pd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["im_IT_DS"] ? filters["im_IT_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "im_IT_DS",
                        e.target.value,
                        filters["im_IT_DS"]
                          ? filters["im_IT_DS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_DT"] ? filters["pd_OR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_DT",
                        e.target.value,
                        filters["pd_OR_DT"]
                          ? filters["pd_OR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_RQ_DT"] ? filters["pd_RQ_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_RQ_DT",
                        e.target.value,
                        filters["pd_RQ_DT"]
                          ? filters["pd_RQ_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_QT"] ? filters["pd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_QT",
                        e.target.value,
                        filters["pd_OR_QT"]
                          ? filters["pd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_TR_UM"] ? filters["pd_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_TR_UM",
                        e.target.value,
                        filters["pd_TR_UM"]
                          ? filters["pd_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_BS_UP"] ? filters["pd_BS_UP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_BS_UP",
                        e.target.value,
                        filters["pd_BS_UP"]
                          ? filters["pd_BS_UP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_OR_AT"] ? filters["pd_OR_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_OR_AT",
                        e.target.value,
                        filters["pd_OR_AT"]
                          ? filters["pd_OR_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_FR_UP"] ? filters["pd_FR_UP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_FR_UP",
                        e.target.value,
                        filters["pd_FR_UP"]
                          ? filters["pd_FR_UP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_FR_AT"] ? filters["pd_FR_AT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_FR_AT",
                        e.target.value,
                        filters["pd_FR_AT"]
                          ? filters["pd_FR_AT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pd_PY_TR"] ? filters["pd_PY_TR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_PY_TR",
                        e.target.value,
                        filters["pd_PY_TR"]
                          ? filters["pd_PY_TR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["pd_ST_CD"] ? filters["pd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pd_ST_CD",
                        e.target.value,
                        filters["pd_ST_CD"]
                          ? filters["pd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Hold Code</th>
                <th style={tableHeaderStyle}>Supplier No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Item Number</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Order Date</th>
                <th style={tableHeaderStyle}>Req. Date</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Amount</th>
                <th style={tableHeaderStyle}>Fore.price</th>
                <th style={tableHeaderStyle}>Fore.Amount</th>
                <th style={tableHeaderStyle}>Pay Term</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.pdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.pdUkNo}
                        checked={selectedRows.includes(rowData.pdUkNo)}
                        onChange={() => handleRowSelect(rowData.pdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.pd_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.pd_HL_CD}</td>
                    <td style={tableCellStyle}>{rowData.pd_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.sup_NAME}</td>
                    <td style={tableCellStyle}>{rowData.pd_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.pd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.im_IT_DS}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.pd_OR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.pd_RQ_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.pd_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.pd_TR_UM}</td>
                    <td style={tableCellStyle}>{rowData.pd_BS_UP}</td>
                    <td style={tableCellStyle}>{rowData.pd_OR_AT}</td>
                    <td style={tableCellStyle}>{rowData.pd_FR_UP}</td>
                    <td style={tableCellStyle}>{rowData.pd_FR_AT}</td>
                    <td style={tableCellStyle}>{rowData.pd_PY_TR}</td>
                    <td style={tableCellStyle}>{rowData.pd_ST_CD}</td>
                   
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />

      </div>
          {/* supplier */}
          <AddressBookPopup
            visible={SupplierPopupVisible}
            onClose={() => setSupplierPopupVisible(false)}
            onDataSelect={handleDataSelect}
            formData={FormData}
            addressType={addressTypeSUP}
            setFormData={setFormData}
          />
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelect1}
      />
        <PurchaseQuoteDialog
          open={open}
          handleClose={handleClose}
          data={FormData}
          handleFormSubmit={handleFormSubmit}
          onchangedata={onchangedata}
          onchangeFormData={onchangeFormData}    
          onchangeDropDownFormData1={onchangeDropDownFormData1}
          onchangeDropDownFormData2={onchangeDropDownFormData2}
          onchangeDropDownFormData3={onchangeDropDownFormData3}
          onchangeDropDownFormData4={onchangeDropDownFormData4}
          onchangeDropDownFormData5={onchangeDropDownFormData5}
          onchangeDropDownFormData6={onchangeDropDownFormData6}
          onchangeDropDownFormData7={onchangeDropDownFormData7}
          onchangeDropDownFormData8={onchangeDropDownFormData8}
          ph_OR_TY={ph_OR_TY}
          ph_OR_CO={ph_OR_CO}
          ph_BS_CR={ph_BS_CR}
          ph_CO_CT={ph_CO_CT}
          ph_TR_CR={ph_TR_CR}
          ph_TX_AP={ph_TX_AP}
          pd_TR_UM={pd_TR_UM}
          pd_SC_UM={pd_SC_UM}
          pd_CR_CD={pd_CR_CD}
          pd_TX_AP={pd_TX_AP}
          ph_SU_NO={ph_SU_NO}
          ph_EX_RT={ph_EX_RT}
          ph_PY_TR={ph_PY_TR}
          ph_SH_NO={ph_SH_NO}
          pd_SU_NO={pd_SU_NO}
          sup_NAME={sup_NAME}
          pd_ST_CD={pd_ST_CD}
          pd_IT_NM={pd_IT_NM}
          setpd_IT_NM={setpd_IT_NM}
          setph_PY_TR={setph_PY_TR}
          setph_TR_CR={setph_TR_CR}
          setph_EX_RT={setph_EX_RT}
          setph_SU_NO={setph_SU_NO}
          setsup_NAME={setsup_NAME}
          setpd_SU_NO={setpd_SU_NO}
          setph_SH_NO={setph_SH_NO}
          SelectedemEMNO={SelectedemEMNO}
          selectedAbAdNM={selectedAbAdNM}
          selectedAbAdNN={selectedAbAdNN}
          selectedAbAdNNA={selectedAbAdNNA}
          setSelectedemEMNO={setSelectedemEMNO}
          setSelectedAbAdNM={setSelectedAbAdNM}
          setSelectedAbAdNN={setSelectedAbAdNN}
          setSelectedAbAdNNA={setSelectedAbAdNNA}
          onchangeOrderdate={onchangeOrderdate}
          PurchaseQuotetable={PurchaseQuotetable} 
          addRow={addRow} 
          deleteRow={deleteRow} 
          cancelFlag={cancelFlag}
          PurchaseOrder={PurchaseOrder}
          price_AMOUNT={price_AMOUNT}
          setprice_AMOUNT={setprice_AMOUNT}
          discount_AMOUNT={discount_AMOUNT}
          setdiscount_AMOUNT={setdiscount_AMOUNT}
          setnet_AMOUNT={setnet_AMOUNT}
          net_AMOUNT={net_AMOUNT}
          settax_AMOUNT={settax_AMOUNT}
          tax_AMOUNT={tax_AMOUNT}
          settotal={settotal}
          total={total}
          fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
          setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
          fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
          setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
          fr_NET_AMOUNT={fr_NET_AMOUNT}
          setfr_NET_AMOUNT={setfr_NET_AMOUNT}
          fr_TAX_AMOUNT={fr_TAX_AMOUNT}
          setfr_TAX_AMOUNT={setfr_TAX_AMOUNT}
          fr_TOTAL={fr_TOTAL}
          setfr_TOTAL={setfr_TOTAL}
          OnchangeTableData={OnchangeTableData}
          onchangeFieldSetFormData={onchangeFieldSetFormData}
          ph_RL_TY={ph_RL_TY}
          setph_RL_TY={setph_RL_TY}
          setFormData={setFormData}
          ph_RL_CO={ph_RL_CO}
          setph_RL_CO={setph_RL_CO}
          ph_HL_CD={ph_HL_CD}
          setph_HL_CD={setph_HL_CD}
          pd_CO_CT={pd_CO_CT}
          HoldCodeDropdown={HoldCodeDropdown}
          availability_AMOUNT={availability_AMOUNT}
        />
        </div>
     </div>
  );
};

export default PurchaseQuoteMain;
