import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
export default function AccountMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  onchangeDropDownFormData10,
  onchangeDropDownFormData11,
  onchangeDropDownFormData12,
  onchangeDropDownFormData13,
  onchangeDropDownFormData14,
  onchangeDropDownFormData15,
  am_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
 
}) {
  const {
    am_OB_SB,
    am_OB_AC,
    am_CO_NM,
    am_AC_DS,
    am_AC_LD,
    am_CR_CD,
    am_PA_PN,
    am_PE_CD,
    am_CL_01,
    am_CL_02,
    am_CL_03,
    am_CL_04,
    am_CL_05,
    am_CL_06,
    am_CL_07,
    am_CL_08,
    am_CL_09,
    am_CL_10,
    amUkNo,
  } = data;
  const {TabBoxStyle,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [am_CO_NMOptions, setam_CO_NMOptions] = useState([]);
  const [am_AC_LDOptions, setam_AC_LDOptions] = useState([]);
  const [am_CR_CDOptions, setam_CR_CDOptions] = useState([]);
  const [am_PA_PNOptions, setam_PA_PNOptions] = useState([]);
  const [am_CL_01Options, setam_CL_01Options] = useState([]);
  const [am_CL_02Options, setam_CL_02Options] = useState([]);
  const [am_CL_03Options, setam_CL_03Options] = useState([]);
  const [am_CL_04Options, setam_CL_04Options] = useState([]);
  const [am_CL_05Options, setam_CL_05Options] = useState([]);
  const [am_CL_06Options, setam_CL_06Options] = useState([]);
  const [am_CL_07Options, setam_CL_07Options] = useState([]);
  const [am_CL_08Options, setam_CL_08Options] = useState([]);
  const [am_CL_09Options, setam_CL_09Options] = useState([]);
  const [am_CL_10Options, setam_CL_10Options] = useState([]);
  const [am_PE_CDOptions, setam_PE_CDOptions] = useState([]);
 
  const [activeTab, setActiveTab] = useState(0);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "CO/CT";
  const finalUrl1 = `${url}/${parameter1}`;
  // designation
  const parameter2 = "LE/VL";
  const finalUrl2 = `${url}/${parameter2}`;
  // departmemt
  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;
  // pay class
  const parameter4 = "PO/CD";
  const finalUrl4 = `${url}/${parameter4}`;
    // class1
  const parameter5 = "AM/1";
  const finalUrl5 = `${url}/${parameter5}`;
    //  class2
  const parameter6 = "AM/2";
  const finalUrl6 = `${url}/${parameter6}`;
    //  class3
  const parameter7= "AM/3";
  const finalUrl7 = `${url}/${parameter7}`;
    //  class4
  const parameter8 = "AM/4";
  const finalUrl8 = `${url}/${parameter8}`;
    //  class5
  const parameter9 = "AM/5";
  const finalUrl9 = `${url}/${parameter9}`;
    //  class6
  const parameter10 = "AM/6";
  const finalUrl10 = `${url}/${parameter10}`;
    //  class7
  const parameter11 = "AM/7";
  const finalUrl11 = `${url}/${parameter11}`;
    //  class8
  const parameter12 = "AM/8";
  const finalUrl12 = `${url}/${parameter12}`;
    //  class9
  const parameter13 = "AM/9";
  const finalUrl13 = `${url}/${parameter13}`;
    //  class10
  const parameter14 = "AM/10";
  const finalUrl14 = `${url}/${parameter14}`;

  const parameter15 = "AB/ST";
  const finalUrl15 = `${url}/${parameter15}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    fetchDropdownOptions10();
    fetchDropdownOptions11();
    fetchDropdownOptions12();
    fetchDropdownOptions13();
    fetchDropdownOptions14();
    fetchDropdownOptions15();
  }, []);
const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setam_CO_NMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_AC_LDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setam_CR_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_PA_PNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_01Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_02Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_03Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions8 = async () => {
  try {
    const response = await axios.get(finalUrl8, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_04Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions9= async () => {
  try {
    const response = await axios.get(finalUrl9, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_05Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions10= async () => {
  try {
    const response = await axios.get(finalUrl10, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_06Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions11= async () => {
  try {
    const response = await axios.get(finalUrl11, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_07Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions12= async () => {
  try {
    const response = await axios.get(finalUrl12, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_08Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions13= async () => {
  try {
    const response = await axios.get(finalUrl13, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_09Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions14= async () => {
  try {
    const response = await axios.get(finalUrl14, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_CL_10Options(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const fetchDropdownOptions15= async () => {
  try {
    const response = await axios.get(finalUrl15, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setam_PE_CDOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{amUkNo ? "Revise Account Master" : "Add Account Master"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
    {activeTab === 0 && (
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      /> )}
    {activeTab === 0 && (
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />)}
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="am_CO_NO"
                    name="am_CO_NO"
                    value={am_CO_NO}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CO_NM" className="font-bold">
                    Cost Center
                  </label>
                  <Dropdown
                    name="am_CO_NM"
                    value={am_CO_NM}
                    options={am_CO_NMOptions}
                    onChange={(e) => onchangeDropDownFormData1("am_CO_NM", e.value)}
                    placeholder="Select Cost Center"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_OB_AC" className="font-bold">
                    Account Object
                  </label>
                  <InputText
                    id="am_OB_AC"
                    name="am_OB_AC"
                    value={am_OB_AC}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_OB_SB" className="font-bold">
                    Account Subsidiary
                  </label>
                  <InputText
                    id="am_OB_SB"
                    name="am_OB_SB"
                    value={am_OB_SB}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_AC_DS" className="font-bold">
                    Account Description
                  </label>
                  <InputText
                    id="am_AC_DS"
                    name="am_AC_DS"
                    value={am_AC_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                </div>
                <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_AC_LD" className="font-bold">
                    Level Details
                  </label>
                  <Dropdown
                    name="am_AC_LD"
                    value={am_AC_LD}
                    options={am_AC_LDOptions}
                    onChange={(e) => onchangeDropDownFormData2("am_AC_LD", e.value)}
                    placeholder="Select Leave Details"

                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CR_CD" className="font-bold">
                   Currency Code
                  </label>
                  <Dropdown
                    name="am_CR_CD"
                    value={am_CR_CD}
                    options={am_CR_CDOptions}
                    onChange={(e) => onchangeDropDownFormData3("am_CR_CD", e.value)}
                    placeholder="Select Currency Code"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_PA_PN" className="font-bold">
                  Posting Code
                  </label>
                  <Dropdown
                    name="am_PA_PN"
                    value={am_PA_PN}
                    options={am_PA_PNOptions}
                    onChange={(e) => onchangeDropDownFormData4("am_PA_PN", e.value)}
                    placeholder="Select Posting Code"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_PE_CD" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    name="am_PE_CD"
                    value={am_PE_CD}
                    options={am_PE_CDOptions}
                    onChange={(e) => onchangeDropDownFormData15("am_PE_CD", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                </div>
              </div>
          </TabPanel>
          <TabPanel header="Class Code">
            <div style={inboxstyle} >
             <div className="formgrid grid">
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_01" className="font-bold">
                  Class Code 1
                  </label>
                  <Dropdown
                    name="am_CL_01"
                    value={am_CL_01}
                    options={am_CL_01Options}
                    onChange={(e) => onchangeDropDownFormData5("am_CL_01", e.value)}
                    placeholder="Select Class Code 01"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_02" className="font-bold">
                  Class Code 2
                  </label>
                  <Dropdown
                    name="am_CL_02"
                    value={am_CL_02}
                    options={am_CL_02Options}
                    onChange={(e) => onchangeDropDownFormData6("am_CL_02", e.value)}
                    placeholder="Select Class Code 02"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_03" className="font-bold">
                  Class Code 3
                  </label>
                  <Dropdown
                    name="am_CL_03"
                    value={am_CL_03}
                    options={am_CL_03Options}
                    onChange={(e) => onchangeDropDownFormData7("am_CL_03", e.value)}
                    placeholder="Select Class Code 03"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_04" className="font-bold">
                  Class Code 4
                  </label>
                  <Dropdown
                    name="am_CL_04"
                    value={am_CL_04}
                    options={am_CL_04Options}
                    onChange={(e) => onchangeDropDownFormData8("am_CL_04", e.value)}
                    placeholder="Select Class Code 04"
                  />
                  
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_05" className="font-bold">
                  Class Code 5
                  </label>
                  <Dropdown
                    name="am_CL_05"
                    value={am_CL_05}
                    options={am_CL_05Options}
                    onChange={(e) => onchangeDropDownFormData9("am_CL_05", e.value)}
                    placeholder="Select Class Code 05"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_06" className="font-bold">
                  Class Code 6
                  </label>
                  <Dropdown
                    name="am_CL_06"
                    value={am_CL_06}
                    options={am_CL_06Options}
                    onChange={(e) => onchangeDropDownFormData10("am_CL_06", e.value)}
                    placeholder="Select Class Code 06"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_07" className="font-bold">
                  Class Code 7
                  </label>
                  <Dropdown
                    name="am_CL_07"
                    value={am_CL_07}
                    options={am_CL_07Options}
                    onChange={(e) => onchangeDropDownFormData11("am_CL_07", e.value)}
                    placeholder="Select Class Code 07"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_08" className="font-bold">
                  Class Code 8
                  </label>
                  <Dropdown
                    name="am_CL_08"
                    value={am_CL_08}
                    options={am_CL_08Options}
                    onChange={(e) => onchangeDropDownFormData12("am_CL_08", e.value)}
                    placeholder="Select Class Code 08"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_09" className="font-bold">
                  Class Code 9
                  </label>
                  <Dropdown
                    name="am_CL_09"
                    value={am_CL_09}
                    options={am_CL_09Options}
                    onChange={(e) => onchangeDropDownFormData13("am_CL_09", e.value)}
                    placeholder="Select Class Code 09"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="am_CL_10" className="font-bold">
                  Class Code 10
                  </label>
                  <Dropdown
                    name="am_CL_10"
                    value={am_CL_10}
                    options={am_CL_10Options}
                    onChange={(e) => onchangeDropDownFormData14("am_CL_10", e.value)}
                    placeholder="Select Class Code 10"
                  />
                </div>
              </div>
              </div>
          </TabPanel>
      </TabView>
      </div>    
     
    </Dialog>
  );
}
