import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import ItemPopup from "../Popups/ItemPopup";
import ButtonStyles from "views/common/ButtonStyles";
import Costcenter from "views/common/CostCentreDrpDwn";
import TestMasterPopup from "../Popups/TestMasterPopup";
export default function AttachTestToItemDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AttachTestToItemTableData,
  setAttachTestToItemTableData,
  ep_TR_CR,
  setep_TR_CR,
  ti_CO_CT,
  setti_CO_CT,
  ti_TS_UM,
  setti_TS_UM,
  ep_EX_TY,
  setep_EX_TY,
  ep_LO_CN,
  setep_LO_CN,
  selectedSupplier,
  setselectedSupplier,
  ep_CO_NO,
  SelectedITNM,
  setSelectedITNM,
}) {
  const { itUkNo, ti_TS_ST, ti_IT_NM, tr_OR_NO } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle,
    multitablestyle, savestyle, cancelstyle, Dialogstyle } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [ti_CO_CTOptions, setti_CO_CTOptions] = useState([]);
  const [ti_TS_UMOptions, setti_TS_UMOptions] = useState([]);

  // Policy Name
  const CostCentreDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setti_CO_CT(value);
  };

  // UM
  const UMDropdown = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setti_TS_UM(value);
  };

  useEffect(() => {
    CostCentre();
    UM();
  }, []);
  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setti_CO_CTOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setti_TS_UMOptions(Options);
  };

  const addRow = () => {
    setAttachTestToItemTableData([...AttachTestToItemTableData, {}]);
  };
  const deleteRow = () => {
    if (AttachTestToItemTableData.length > 0) {
      const updatedData = [...AttachTestToItemTableData];
      updatedData.pop();
      setAttachTestToItemTableData(updatedData);
    }
  };
  const [SupplierVisible, setSupplierVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [TestVisible, setITestVisible] = useState(false);
  const [addressTypeSUP, setaddressTypeSUP] = useState("S");

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("ti_IT_NM").value = Result.im_IT_NM;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleDataSelect = async (data) => {
    setselectedSupplier(data.ab_AD_NM);
    document.getElementById(`ep_PR_SU_${SelectedRowIndex}`).value = data.abAdNo;
    document.getElementById(`sup_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    setSupplierVisible(false);
    setShowSearchIcon(false);
  };
  const openSupplierPopup = (e) => {
    setSupplierVisible(true);
    setSelectedRowIndex(e);
  };
  const openTestMasterPopup = (e) => {
    setITestVisible(true);
    setSelectedRowIndex(e);
  };

  const handleTest = async (data) => {
    document.getElementById(`ti_TS_ID_${SelectedRowIndex}`).value = data.tm_TS_ID;
    document.getElementById(`ti_TS_DS_${SelectedRowIndex}`).value = data.tm_TS_DS;
    setITestVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(itUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{itUkNo ? "Revise Attach Test to Item" : "Add Attach Test to Item"}</h5>
      <div style={{ width: '30rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ti_IT_NM" className="font-bold">
                Item Number<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: 'flex' }}>
                <InputText
                  id="im_IT_DS"
                  name="im_IT_DS"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedITNM}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openItemPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <InputText
                  id="ti_IT_NM"
                  name="ti_IT_NM"
                  defaultValue={ti_IT_NM}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ti_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown
                name="ti_CO_CT"
                value={ti_CO_CT}
                options={ti_CO_CTOptions}
                onChange={(e) => CostCentreDropdown("ti_CO_CT", e.value)}
                placeholder="	Cost Centre"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ti_TS_ST" className="font-bold">
                Status
              </label>
              <InputText
                id="ti_TS_ST"
                name="ti_TS_ST"
                defaultValue={ti_TS_ST}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="AttachTestToItemtable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Test ID <span style={{ color: 'red' }}>*</span> </th>
                  <th>Description</th>
                  <th>Allowed Minimum</th>
                  <th>Preferred Minimum</th>
                  <th>Target</th>
                  <th>Allowed Maximum</th>
                  <th>Preferred Maximum</th>
                  <th>UM</th>
                  <th>From Date</th>
                  <th>To Date</th>
                </tr>
              </thead>
              <tbody>
                {AttachTestToItemTableData.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`itUkNo_${index}`}
                        name={`itUkNo_${index}`}
                        value={row.itUkNo}
                      />
                    </td>
                    <td
                      style={{ display: "flex" }}>
                      <InputText
                        style={{ width: "110px" }}
                        id={`ti_TS_ID_${index}`}
                        name={`ti_TS_ID_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.ti_TS_ID ? row.ti_TS_ID : row[`${"ti_TS_ID"}_${index}`]}

                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "15%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openTestMasterPopup(index)}
                        ></i>
                      )}
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "200px" }}
                        id={`ti_TS_DS_${index}`}
                        name={`ti_TS_DS_${index}`}
                        value={row.ti_TS_DS ? row.ti_TS_DS : row[`${"ti_TS_DS"}_${index}`]}
                        readOnly
                      />
                    </td>
                    <td >
                      <InputText
                        id={`ti_AL_MI_${index}`}
                        name={`ti_AL_MI_${index}`}
                        defaultValue={row.ti_AL_MI}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText
                        id={`ti_PR_MI_${index}`}
                        name={`ti_PR_MI_${index}`}
                        defaultValue={row.ti_PR_MI}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText
                        id={`ti_TR_VL_${index}`}
                        name={`ti_TR_VL_${index}`}
                        defaultValue={row.ti_TR_VL}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText
                        id={`ti_PR_MX_${index}`}
                        name={`ti_PR_MX_${index}`}
                        defaultValue={row.ti_PR_MX}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText
                        id={`ti_AL_MX_${index}`}
                        name={`ti_AL_MX_${index}`}
                        defaultValue={row.ti_AL_MX}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`ti_TS_UM_${index}`}
                        name={`ti_TS_UM_${index}`}
                        value={data[`ti_TS_UM_${index}`] ? data[`ti_TS_UM_${index}`] : row.ti_TS_UM}
                        options={ti_TS_UMOptions}
                        onChange={(e) => UMDropdown(`ti_TS_UM_${index}`, e.value)}
                        placeholder="UM"
                      />
                    </td>
                    <td>
                      <Calendar
                        id={`ti_FR_DT_${index}`}
                        name={`ti_FR_DT_${index}`}
                        value={row.ti_FR_DT ? moment(row.ti_FR_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onDateChange({ target: { name: "ti_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                    </td>
                    <td>
                      <Calendar
                        id={`ti_TO_DT_${index}`}
                        name={`ti_TO_DT_${index}`}
                        value={row.ti_TO_DT ? moment(row.ti_TO_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onDateChange({ target: { name: "ti_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                        dateFormat="dd/mm/yy"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={SupplierVisible}
        onClose={() => setSupplierVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypeSUP}
        setFormData={setFormData}
      />
      <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
      <TestMasterPopup
        visible={TestVisible}
        onClose={() => setITestVisible(false)}
        onDataSelect={handleTest}
        formData={data}
      />
    </Dialog>
  );
}
