import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { InputMask } from "primereact/inputmask";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
export default function DoctorMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  DoctorMasterTableData,
  setDoctorMasterTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  dm_QA_FN,
  setdm_QA_FN,
  dm_EX_CE,
  setdm_EX_CE,
  dm_SP_ON,
  setdm_SP_ON,
  dm_CO_NO,
  da_AV_DA,
  setda_AV_DA,
}) {
  const {
    dmUkNo,
    dm_CN_FE,
    ep_EF_DT,
    os_SQ_DS,
    da_IN_TM,
  } = data;
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [dm_SP_ONOptions, setdm_SP_ONOptions] = useState([]);
  const [dm_QA_FNOptions, setdm_QA_FNOptions] = useState([]);
  const [dm_EX_CEOptions, setdm_EX_CEOptions] = useState([]);
  const [da_AV_DAOptions, setda_AV_DAOptions] = useState([]);

  const onChangeTime=(name,value,index)=>{

    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
}


    //Type
    const TypeDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setda_AV_DA(value);
  };
  // BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdm_QA_FN(value);
  };
// Acq
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdm_SP_ON(value);
  };
// loc
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setdm_EX_CE(value);
  };
  useEffect(() => {
    Type();
    fetchDropdownBT();
    fetchDropdownAQ();
    fetchDropdownLN();
  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeDS("DO/AV");
    setda_AV_DAOptions(Options);
  };

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("DO/QA");
    setdm_QA_FNOptions(Options);
  };

  const fetchDropdownAQ = async () => {
    const Options = await fetchClassCodeDS("DO/SP");
    setdm_SP_ONOptions(Options);
  };

  const fetchDropdownLN = async () => {
    const Options = await fetchClassCodeDS("DO/EX");
    setdm_EX_CEOptions(Options);
  };


  const addRow = () => {
    setDoctorMasterTableData([...DoctorMasterTableData, {}]);
  };
  
  const deleteRow = () => {
    if (DoctorMasterTableData.length > 0) {
      const updatedData = [...DoctorMasterTableData];
      updatedData.pop();
      setDoctorMasterTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(dmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstype] = useState("E");
  const handleItem =async (data) => {
    setselectedItem(data.abAdNo)
    setselectedDesc(data.ab_AD_NM)
    onchangeFormData("dm_AD_NO", data.abAdNo);
    onchangeFormData("dname", data.ab_AD_NM);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dmUkNo ? "Revise Doctor Master" : "Add Doctor Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_AD_NO" className="font-bold">
                 Doctor No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText 
                      id="dm_AD_NO"
                      name="dm_AD_NO"
                      onChange={(Event) => onchangedata(Event)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={SelectedItem}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                    )}
                  </div>
                </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dname" className="font-bold">
                  Description
                  </label>
                  <InputText disabled
                    id="dname"
                    name="dname"
                    value={SelectedDesc}
                   readOnly
                  />
            </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="dm_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="dm_CO_NO"
                    name="dm_CO_NO"
                    value={dm_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="dm_QA_FN" className="font-bold">
             Qualification
            </label>
            <Dropdown
            name="dm_QA_FN"
            value={dm_QA_FN}
            options={dm_QA_FNOptions}
            onChange={(e) => onchangeDropDownFormData1("dm_QA_FN", e.value)}
            placeholder="Select Qualification"
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="dm_SP_ON" className="font-bold">
              Specialization
            </label>
            <Dropdown
            name="dm_SP_ON"
            value={dm_SP_ON}
            options={dm_SP_ONOptions}
            onChange={(e) => onchangeDropDownFormData2("dm_SP_ON", e.value)}
            placeholder="Select Specialization"
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="dm_EX_CE" className="font-bold">
              Experience
            </label>
            <Dropdown
            name="dm_EX_CE"
            value={dm_EX_CE}
            options={dm_EX_CEOptions}
            onChange={(e) => onchangeDropDownFormData3("dm_EX_CE", e.value)}
            placeholder="Select Experience"
          />
          </div>

          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
        <label htmlFor="dm_CN_FE" className="font-bold">
        Consulation Fee
        </label>
        <InputText 
          id="dm_CN_FE"
          name="dm_CN_FE"
          value={dm_CN_FE}
          onChange={(Event) => onchangedata(Event)}
        />
            </div>
          </div>
          </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="DoctorMastertable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Available Day</th>
              <th>In Time</th>
              <th>Out Time</th>
            </tr>
          </thead>
          <tbody>
            {DoctorMasterTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`da_UK_NO_${index}`}
                    name={`da_UK_NO_${index}`}
                    value={row.da_UK_NO}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`da_AV_DA_${index}`}
                    name={`da_AV_DA_${index}`}
                    value={ data[`da_AV_DA_${index}`] ? data[`da_AV_DA_${index}`]: row.da_AV_DA}
                    options={da_AV_DAOptions}
                    onChange={(e) =>TypeDropdown(`da_AV_DA_${index}`, e.value)}
                    placeholder="Select Available Days"
                  />
                  </td>
                  <td>
                  <InputMask  
                    id={`da_IN_TM_${index}`}
                    name={`da_IN_TM_${index}`}
                    mask="99:99:99"
                    value={moment(row.da_IN_TM, "HHmmss").format("HH:mm:ss")}
                    onBlur={(e) => {
                      onChangeTime("da_IN_TM",e.target.value,index);
                    }}
                  />
                </td>
                
                <td>
                  <InputMask 
                    id={`da_OT_TM_${index}`}
                    name={`da_OT_TM_${index}`}
                    mask="99:99:99"
                    onBlur={(e) => {
                      onChangeTime("da_OT_TM",e.target.value,index);
                    }}
                    value={moment(row.da_OT_TM, "HHmmss").format("HH:mm:ss")}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      <AddressBookPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
        addressType={AddresstypeC}
        setFormData= {setFormData}
      />
    </Dialog>
  );
}
