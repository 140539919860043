import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import RentalItemPopup from "../Popups/RentalItemPopup";
import  Costcenter  from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import RentalConrtactPopup from "../Popups/RentalContractPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function RentalContractDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  RentalContractTable,
  setRentalContractTable,
  CurrencyDropDown,
  onchangegldate,
  rc_TR_TY,
  rc_TR_CO,
  rc_BS_CR,
  rc_EX_RT,
  setrc_EX_RT,
  rc_CO_CT,
  setrc_CO_CT,
  rc_PY_TR,
  setrc_PY_TR,
  rc_BL_FR,
  setrc_BL_FR,
  rc_TX_AP,
  setrc_TX_AP,
  setrc_LO_CN,
  setrc_TR_UM,
  setselectedItem,
  setselectedDesc,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedemEMNOC,
  setSelectedemEMNOC,
  SelectedemEMNOD,
  setSelectedemEMNOD,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  selectedAbAdNM,
  setSelectedAbAdNM,
  rc_SH_TO,
  setrc_SH_TO,
  rc_HD_ST,
  setrc_HD_ST,
  rc_ST_CD,
  rc_LO_CN,
  rc_ZN_CD,
  setrc_ZN_CD,
  rc_MD_TR,
  setrc_MD_TR,
  rc_RT_CD,
  setrc_RT_CD,
  handleConfirm,
  price_AMOUNT,
  discount_AMOUNT,
  net_AMOUNT,
  tax_AMOUNT,
  total,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  fr_TOTAL,
  setselectedSeNO,
  setselectedLoc,
  CostCentreDropDown,
  StatusDropdown,
  setrc_DV_RT,
  rc_DV_RT,
}) {
  const {
    rcUkNo,
    rc_CU_NO,
    rc_TR_NO,
    rc_RL_NO,
    rc_TR_DT,
    rc_RQ_DT,
    rc_ST_DT,
    rc_EN_DT,
    rc_DS_PE,
    rc_DS_AM,
    rc_RF_NC,
    rc_SL_PR,
    rc_OR_QT,
    rc_CR_NO,
    rc_IN_TR,
    rc_TR_CR,
    rc_RL_CO,
    rc_RL_TY,
    rc_IT_NM,
    rc_UN_PR,
    sd_OR_QT,
  } = data;

  const { footergridstyle,footerfieldstyle,borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle } = ButtonStyles();

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [rc_PY_TROptions, setrc_PY_TROptions] = useState([]);
  const [rc_BL_FROptions, setrc_BL_FROptions] = useState([]);
  const [rc_CO_CTOptions, setrc_CO_CTOptions] = useState([]);
  const [rc_TR_CROptions, setrc_TR_CROptions] = useState([]);
  const [rc_HD_STOptions, setrc_HD_STOptions] = useState([]);
  const [rc_LO_CNOptions, setrc_LO_CNOptions] = useState([]);
  const [rc_TR_UMOptions, setrc_TR_UMOptions] = useState([]);
  const [rc_TX_APOptions, setrc_TX_APOptions] = useState([]);
  const [rc_ZN_CDOptions, setrc_ZN_CDOptions] = useState([]);
  const [rc_MD_TROptions, setrc_MD_TROptions] = useState([]);
  const [rc_RT_CDOptions, setrc_RT_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const PaymentTermDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_PY_TR(value);
  };
  const BillingFrequencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_BL_FR(value);
  };
  const FrequencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_BL_FR(value);
  };
  const ApplicableDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TX_AP(value);
  };
  const UMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TR_UM(value);
  };
  const LocationDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_LO_CN(value);
  };
  const TaxDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_TX_AP(value);
  };
  const ZoneDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_ZN_CD(value);
  };
  const TransportDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_MD_TR(value);
  };
  const RouteDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setrc_RT_CD(value);
  };
  useEffect(() => {
    CostCentre();
    Currency();
    PaymentTerm();
    HoldCode();
    Frequency();
    Applicable();
    UM();
    Status();
    Type();
    Tax();
    Zone();
    Transport();
    Route();
  }, []);

  const CostCentre = async () => {
    const Options = await fetchCostCenter();
    setrc_CO_CTOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setrc_TR_CROptions(Options);
  };
  const PaymentTerm = async () => {
    const Options = await fetchPayTerm();
    setrc_PY_TROptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setrc_BL_FROptions(Options);
  };
  const Frequency = async () => {
    const Options = await fetchClassCodeDS("RN/TY");
    setrc_BL_FROptions(Options);
  };
  const Applicable = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setrc_TX_APOptions(Options);
  };
  const UM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setrc_TR_UMOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setrc_HD_STOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setrc_LO_CNOptions(Options);
  };
  const Tax = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setrc_TX_APOptions(Options);
  };
  const Zone = async () => {
    const Options = await fetchClassCodeDS("ZO/NO");
    setrc_ZN_CDOptions(Options);
  };
  const Transport = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setrc_MD_TROptions(Options);
  };
  const Route = async () => {
    const Options = await fetchClassCodeDS("FO/RC");
    setrc_RT_CDOptions(Options);
  };
  // Advance Search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  const [AddresstypeE, setAddresstypeE] = useState("E");
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("rc_CU_NO", data.abAdNo);

    setSelectedAbAdNM(data.ab_AD_NM);
    setrc_SH_TO( data.abAdNo);


    setSelectedemEMNOD(data.ab_AD_NM);
    setrc_DV_RT( data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData={
    "cu_CU_NO":  data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCuMt = response.data.Result;
    if (dataCuMt) {
      CurrencyDropDown(`rc_TR_CR`, dataCuMt.cu_CR_CD);
      PaymentTermDropDown(`rc_PY_TR`, dataCuMt.cu_PT_TM);
      ApplicableDropdown(`rc_TX_AP`, dataCuMt.cu_TX_AP);
      const exData={
        "cr_TO_CR": document.getElementById("rc_BS_CR").value, //base curr
        "cr_FR_CR": dataCuMt.cu_CR_CD, //currency
        "cr_EF_DT": data.rc_TR_DT
      }
      exchangeRate (exData);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const openShipToPopup = (e) => {
    setShipToPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedAbAdNM(data.ab_AD_NM);
    setrc_SH_TO( data.abAdNo);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.data) {
        setSelectedAbAdNM(data.data.ab_AD_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setShipToPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("rc_EX_RT").value = dataExRt.cr_CO_MR;
          setrc_EX_RT(dataExRt.cr_CO_MR);
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.ri_IT_NO);
    setselectedDesc(data.item_DES);
    setselectedSeNO(data.ri_SR_NO);
    setselectedLoc(data.ri_LO_CN);
    // onchangeFormData("rc_IT_NM", data.ri_IT_NO, SelectedRowIndex);
    // onchangeFormData("itemDES", data.item_DES, SelectedRowIndex);
    document.getElementById(`itemDES_${SelectedRowIndex}`).value = data.item_DES;
    document.getElementById(`rc_IT_NM_${SelectedRowIndex}`).value = data.ri_IT_NO;
    onchangeFormData("rc_SR_NO", data.ri_SR_NO, SelectedRowIndex);
    LocationDropdown(`rc_LO_CN_${SelectedRowIndex}`, data.ri_LO_CN);
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
      "im_IT_NM":data.ri_IT_NO
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      UMDropdown(`rc_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      // TaxDropdown(`rc_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      document.getElementById (`rc_TX_AP_${SelectedRowIndex}`).value =  dataItMt.im_TX_AP;
      document.getElementById (`rc_TX_PE_${SelectedRowIndex}`).value =  dataItMt.im_VA_TX;
      onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const onchangeFormUnitPrice = async(e,index) => {
    onchangedata(e);
    onChangeOfPrice(index);
  };
  const  onChangeOfPrice= async(SelectedRowIndex) => {
    const UrlCalulate = `${domain}/api/RentalContract/GetAmountDetails`;
    const priceInput = {
      "exChangeRate": document.getElementById ("rc_EX_RT").value,
      "rentalContracts":[{
              "unitPrice":document.getElementById (`rc_UN_PR_${SelectedRowIndex}`).value,
              "quantity":document.getElementById (`rc_OR_QT_${SelectedRowIndex}`).value,
              "taxPercentage":document.getElementById (`rc_TX_PE_${SelectedRowIndex}`).value,
              "unitCost":document.getElementById (`rc_UN_CT_${SelectedRowIndex}`).value,
              "mulDisPercentage":document.getElementById (`rc_DS_PE_${SelectedRowIndex}`).value,
              "foreUnitPrice":document.getElementById (`rc_FR_UP_${SelectedRowIndex}`).value,
         } ]
   }
    try {
      const response =await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCalulate = response.data.Result;
      if (dataCalulate ) {
        document.getElementById (`rc_UP_AM_${SelectedRowIndex}`).value =  dataCalulate.matUnit;
        document.getElementById (`rc_UC_AM_${SelectedRowIndex}`).value =  dataCalulate.matCost;
        document.getElementById (`rc_NT_MT_${SelectedRowIndex}`).value =  dataCalulate.matnetAmtMul;
        document.getElementById (`rc_TX_AM_${SelectedRowIndex}`).value =  dataCalulate.matTaxamt;
        document.getElementById (`rc_DS_AM_${SelectedRowIndex}`).value =  dataCalulate.matDisamt;
        document.getElementById (`rc_FR_AM_${SelectedRowIndex}`).value =  dataCalulate.foreAmount;
        document.getElementById (`rc_FR_DS_${SelectedRowIndex}`).value =  dataCalulate.forematDisamt;
        document.getElementById (`rc_FN_MT_${SelectedRowIndex}`).value =  dataCalulate.forenetAmtMul;
        document.getElementById (`rc_UN_PR_${SelectedRowIndex}`).value =  dataCalulate.unitPrice;

        
        const table = document.getElementById("RentalContractTable");
        const totalRowCount = table.tBodies[0].rows.length;
        const rentalContracts = [];
        for (let i = 0; i < totalRowCount; i++) {
          const ST_DT = document.getElementsByName("rc_ST_DT_" + i)[0];
          const EN_DT = document.getElementsByName("rc_EN_DT_" + i)[0];
          const details = {
              rcUkNo : document.getElementById("rcUkNo_" + i).value,
              rc_LN_NO : document.getElementById("rc_LN_NO_" + i).value,
              rc_IT_NM : document.getElementById("rc_IT_NM_" + i).value,
              itemDES : document.getElementById("itemDES_" + i).value,
              rc_LO_CN : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
              rc_SR_NO : document.getElementById("rc_SR_NO_" + i).value,
              rc_OR_QT : document.getElementById("rc_OR_QT_" + i).value,
              rc_TR_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
              rc_UN_PR : document.getElementById("rc_UN_PR_" + i).value,
              rc_UP_AM : document.getElementById("rc_UP_AM_" + i).value,
              rc_UN_CT : document.getElementById("rc_UN_CT_" + i).value,
              rc_UC_AM : document.getElementById("rc_UC_AM_" + i).value,
              rc_DS_PE : document.getElementById("rc_DS_PE_" + i).value,
              rc_DS_AM : document.getElementById("rc_DS_AM_" + i).value,
              rc_NT_MT : document.getElementById("rc_NT_MT_" + i).value,
              rc_TX_AP : document.getElementById("rc_TX_AP_" + i).value,
              rc_TX_PE : document.getElementById("rc_TX_PE_" + i).value,
              rc_TX_AM : document.getElementById("rc_TX_AM_" + i).value,
              rc_FR_UP : document.getElementById("rc_FR_UP_" + i).value,
              rc_FR_AM : document.getElementById("rc_FR_AM_" + i).value,
              rc_FR_DS : document.getElementById("rc_FR_DS_" + i).value,
              rc_FN_MT : document.getElementById("rc_FN_MT_" + i).value,
              rc_ST_DT: ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
              rc_EN_DT: EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
              rc_BL_FR : table.tBodies[0].rows[i].cells[24].children[0].children[1].children[0].value,
              rc_ST_CD : document.getElementById("rc_ST_CD_" + i).value,
          };
          rentalContracts.push(details);
        }
        const requestBodyNet = {
          rentalContracts,
          rc_TR_CR : rc_TR_CR,
          rc_EX_RT : document.getElementById("rc_EX_RT").value,
          rc_BS_CR : document.getElementById("rc_BS_CR").value,
          rc_DS_AM : document.getElementById("rc_DS_AM").value,
        };
        axios.post(`${domain}/api/RentalContract/GetPopulateNetAmount`, requestBodyNet, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((respNet) => {
          document.getElementById("fr_PRICE_AMOUNT").value = respNet.data.header.foreAmount;
          document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.data.header.forematDisamt;
          document.getElementById("fr_NET_AMOUNT").value = respNet.data.header.frNET_AMOUNT;
          document.getElementById("tax_AMOUNT").value = respNet.data.header.taxAmt;
          document.getElementById("discount_AMOUNT").value = respNet.data.header.disAmt;
          document.getElementById("price_AMOUNT").value = respNet.data.header.matUnit;
          document.getElementById("net_AMOUNT").value = respNet.data.header.net_AMOUNT;
          document.getElementById("fr_TAX_AMOUNT").value = respNet.data.header.f_TAX;
          document.getElementById("total").value = respNet.data.header.total_AMOUNT;
          document.getElementById("fr_TOTAL").value = respNet.data.header.f_TOTAL_AMOUNT;
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });

      } else {
        console.error("Invalid API response:", dataCalulate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangebspr = async(SelectedRowIndex) => {
    const table = document.getElementById("RentalContractTable");
      const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
        const priceInput = {
          "bp_CO_CT" : rc_CO_CT,
          "bp_IT_NM" : document.getElementById (`rc_IT_NM_${SelectedRowIndex}`).value,
          "bp_FR_DT" : rc_TR_DT,
          "bp_TO_DT" : rc_TR_DT,
          "bp_CU_NO" : document.getElementById("rc_CU_NO").value,
          "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[7].children[0].children[1].children[0].value,        
          "bp_CR_CD" : rc_TR_CR,
        }
          try {
            const response =await axios.post(UrlPrice, priceInput, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });
        
            if (response.status !== 200) {
              throw new Error(`API request failed with status: ${response.status}`);
            }
            const dataItMt = response.data.price;
            if (dataItMt ) {
              document.getElementById (`rc_UN_PR_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
              onChangeOfPrice(SelectedRowIndex);
            } else {
              console.error("Invalid API response:", data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
  }; 
  const onchangeHeaderDisAmt = async(e) => {
    const table = document.getElementById("RentalContractTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const rentalContracts = [];
    for (let i = 0; i < totalRowCount; i++) {
      const ST_DT = document.getElementsByName("rc_ST_DT_" + i)[0];
      const EN_DT = document.getElementsByName("rc_EN_DT_" + i)[0];
      const details = {
          rcUkNo : document.getElementById("rcUkNo_" + i).value,
          rc_LN_NO : document.getElementById("rc_LN_NO_" + i).value,
          rc_IT_NM : document.getElementById("rc_IT_NM_" + i).value,
          itemDES : document.getElementById("itemDES_" + i).value,
          rc_LO_CN : table.tBodies[0].rows[i].cells[4].children[0].children[1].children[0].value,
          rc_SR_NO : document.getElementById("rc_SR_NO_" + i).value,
          rc_OR_QT : document.getElementById("rc_OR_QT_" + i).value,
          rc_TR_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
          rc_UN_PR : document.getElementById("rc_UN_PR_" + i).value,
          rc_UP_AM : document.getElementById("rc_UP_AM_" + i).value,
          rc_UN_CT : document.getElementById("rc_UN_CT_" + i).value,
          rc_UC_AM : document.getElementById("rc_UC_AM_" + i).value,
          rc_DS_PE : document.getElementById("rc_DS_PE_" + i).value,
          rc_DS_AM : document.getElementById("rc_DS_AM_" + i).value,
          rc_NT_MT : document.getElementById("rc_NT_MT_" + i).value,
          rc_TX_AP : document.getElementById("rc_TX_AP_" + i).value,
          rc_TX_PE : document.getElementById("rc_TX_PE_" + i).value,
          rc_TX_AM : document.getElementById("rc_TX_AM_" + i).value,
          rc_FR_UP : document.getElementById("rc_FR_UP_" + i).value,
          rc_FR_AM : document.getElementById("rc_FR_AM_" + i).value,
          rc_FR_DS : document.getElementById("rc_FR_DS_" + i).value,
          rc_FN_MT : document.getElementById("rc_FN_MT_" + i).value,
          rc_ST_DT : ST_DT.value?moment(ST_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          rc_EN_DT : EN_DT.value?moment(EN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          rc_BL_FR : table.tBodies[0].rows[i].cells[24].children[0].children[1].children[0].value,
          rc_ST_CD : document.getElementById("rc_ST_CD_" + i).value,
      };
      rentalContracts.push(details);
    }
        const requestBodyNet = {
          rentalContracts,
          rc_TR_CR : rc_TR_CR,
          rc_EX_RT : document.getElementById("rc_EX_RT").value,
          rc_BS_CR : document.getElementById("rc_BS_CR").value,
          rc_DS_AM : e.currentTarget.value,
        };
       
        const response = await axios.post(`${domain}/api/RentalContract/GetPopulateNetAmount`, requestBodyNet, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const respNet = response.data.header;
        if (respNet ) {
          document.getElementById("fr_PRICE_AMOUNT").value = respNet.foreAmount;
          document.getElementById("fr_DISCOUNT_AMOUNT").value = respNet.forematDisamt;
          document.getElementById("fr_NET_AMOUNT").value = respNet.frNET_AMOUNT;
          document.getElementById("tax_AMOUNT").value = respNet.taxAmt;
          document.getElementById("discount_AMOUNT").value = respNet.disAmt;
          document.getElementById("price_AMOUNT").value = respNet.matUnit;
          document.getElementById("net_AMOUNT").value = respNet.net_AMOUNT;
          document.getElementById("fr_TAX_AMOUNT").value = respNet.foreTaxamt;
          document.getElementById("total").value = respNet.total_AMOUNT;
          document.getElementById("fr_TOTAL").value = respNet.f_TOTAL_AMOUNT;
    } else {
      console.error("Invalid API response:", data);
    }
    onchangedata(e); 
  };
  const onchangeHeaderDisPer = async(e) => {
    const disPer = e.currentTarget.value;
    const table = document.getElementById("RentalContractTable");
    const totalRowCount = table.tBodies[0].rows.length;
  
    if(disPer){
      for (let i = 0; i < totalRowCount; i++) { 
        const disPerMul = document.getElementById ("rc_DS_PE_" + i).value;
        if(!disPerMul || disPerMul === '0'){
          document.getElementById ("rc_DS_PE_" + i).value = disPer;
          onChangeOfPrice(i);
        }
      }
    }
    onchangedata(e);
  }; 
  const onchangeOfHeaderTaxApl = async(name,value) => {
    const txAP = value;
    const table = document.getElementById("RentalContractTable");
    const totalRowCount = table.tBodies[0].rows.length;

    if(txAP.toUpperCase() === 'Y'){
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("rc_TX_AP_" + i).value = 'y';
        const item = document.getElementById ("rc_IT_NM_" + i).value;
        const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
        const requestData={
            "im_IT_NM": item
        }
        try {
          const response =await axios.post(Url, requestData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.Result;
          if (dataItMt ) {
            document.getElementById ("rc_TX_PE_" + i).value =  dataItMt.im_VA_TX;
            onChangeOfPrice(i);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {    
      for (let i = 0; i < totalRowCount; i++) { 
        document.getElementById ("rc_TX_AP_" + i).value = 'n';
        document.getElementById ("rc_TX_PE_" + i).value = '0';
        onChangeOfPrice(i);
      }
    }
    ApplicableDropdown("rc_TX_AP", value);
  };
  const onchangeOfTaxAplMul = async(e,index) => {
    const txAP = e.currentTarget.value;
    const item = document.getElementById (`rc_IT_NM_${index}`).value;
    if(txAP.toUpperCase() === 'Y'){
      const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
      const requestData={
          "im_IT_NM": item
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data.Result;
        if (dataItMt ) {
          document.getElementById (`rc_TX_PE_${index}`).value =  dataItMt.im_VA_TX;
          onChangeOfPrice(index);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      document.getElementById (`rc_TX_PE_${index}`).value = '0';
      onChangeOfPrice(index);
    }
  };
  const [SalesPersonPopupVisible, setSalesPersonPopupVisible] = useState(false);
  const openSalesPersonPopup = () => {
    setSalesPersonPopupVisible(true);
  };
  const handleDataSelectE = async (data) => {
    setSelectedemEMNOE(data.ab_AD_NM);
    onchangeFormData("rc_SL_PR", data.abAdNo);
    setSalesPersonPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false);
  const [ManagerPopupVisible, setManagerPopupVisible] = useState(false);
  const openEmployeePopup = () => {
    setEmployeePopupVisible(true);
  };
  const openManagerPopup = () => {
    setManagerPopupVisible(true);
  };
  const handleDataSelectD = async (data) => {
    setSelectedemEMNOD(data.ab_AD_NM);
    setrc_DV_RT( data.abAdNo);
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const data = response.data;
      if (data && data.data) {
        setSelectedemEMNOD(data.data.ab_AD_NM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectC = async (data) => {
    setSelectedemEMNOC(data.ab_AD_NM);
    onchangeFormData("rc_CR_NO", data.abAdNo);
    setManagerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setRentalContractTable([...RentalContractTable, {}]); 
  };
  const deleteRow = () => {
    if (RentalContractTable.length > 0) {
      const updatedData = [...RentalContractTable];
      updatedData.pop();
      setRentalContractTable(updatedData);
    }
  };
  const [RentalContractPopupVisible, setRentalContractPopupVisible] = useState(false);
  const openRentalContractPopup = () => {
    setRentalContractPopupVisible(true);
  };
  const handleRentalContract = (data ) => {
    onchangeFormData("rc_RL_NO", data.rc_TR_NO);
    onchangeFormData("rc_RL_TY", data.rc_TR_TY);
    onchangeFormData("rc_RL_CO", data.rc_TR_CO);
    setRentalContractPopupVisible(false);
    setShowSearchIcon(false);
  };

const isUpdateMode = Boolean(rcUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{rcUkNo ? "Revise Rental Contract" : "Add Rental Contract"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
      {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          <Button 
            style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
        </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
          <label htmlFor="rcUkNo" className="font-bold">
          UK NO
          </label>
          <InputText disabled
            id="rcUkNo"
            name="rcUkNo"
            defaultValue={rcUkNo}
            onChange={(e) => onchangedata(e)}
          />
        </div>
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_TR_NO" className="font-bold">
           Contract No
          </label>
          <InputText disabled
            id="rc_TR_NO"
            name="rc_TR_NO"
            value={rc_TR_NO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_TR_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="rc_TR_TY"
            name="rc_TR_TY"
            value={rc_TR_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_TR_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="rc_TR_CO"
            name="rc_TR_CO"
            value={rc_TR_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_RL_NO" className="font-bold">
            Parent Contract No <span style={{ color: 'red' }}>*</span>
          </label>
            <div style={{display:"flex"}}>
              <InputText
                id="rc_RL_NO"
                name="rc_RL_NO"
                onChange={(e) => onchangedata(e)}
                onFocus={() => setShowSearchIcon(true)}
                defaultValue={rc_RL_NO}
              />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openRentalContractPopup}
                ></i>
                )}
            </div>
          </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_RL_TY" className="font-bold">
           Type 
          </label>
          <InputText disabled
            id="rc_RL_TY"
            name="rc_RL_TY"
            value={rc_RL_TY}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_RL_CO" className="font-bold">
          Company
          </label>
          <InputText disabled
            id="rc_RL_CO"
            name="rc_RL_CO"
            value={rc_RL_CO}
            onChange={(e) => onchangedata(e)}
          />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="rc_TR_DT" className="font-bold">
            Order Date
          </label>
          <Calendar
            id="rc_TR_DT"
            name="rc_TR_DT"
            value={moment(data.rc_TR_DT, "YYYYMMDD").isValid() ? moment(data.rc_TR_DT, "YYYYMMDD").toDate() : null}
            onChange={(e) => {
              onchangedata({ target: { name: "rc_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "" } });
              onchangegldate({ target: { name: "rc_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "" } });
            }}
            dateFormat="dd/mm/yy"
          />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_RQ_DT" className="font-bold">
                Req. Date
              </label>
              <Calendar
                id="rc_RQ_DT"
                name="rc_RQ_DT"
                value={moment(data.rc_RQ_DT, "YYYYMMDD").isValid() ? moment(data.rc_RQ_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "rc_RQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              /> 
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_CO_CT" className="font-bold">
                Cost Centre
              </label>
              <Dropdown disabled
                name="rc_CO_CT"
                value={rc_CO_CT}
                options={rc_CO_CTOptions}
                onChange={(e) => CostCentreDropDown("rc_CO_CT", e.value)}
                placeholder="Select Cost Centre"
              />
            </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText 
                id="cus_NAME"
                name="cus_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
              <InputText style={{ display: "none" }}
                id="rc_CU_NO"
                name="rc_CU_NO"
                defaultValue={rc_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_PY_TR" className="font-bold">
              Payment Term
            </label>
            <Dropdown
              name="rc_PY_TR"
              value={rc_PY_TR}
              options={rc_PY_TROptions}
              onChange={(e) => PaymentTermDropDown("rc_PY_TR", e.value)}
              placeholder="Select Payment Term"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_TX_AP" className="font-bold">
            Tax Applicable
            </label>
            <Dropdown
            name="rc_TX_AP"
            value={rc_TX_AP}
            options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
            onChange={(e) => onchangeOfHeaderTaxApl("rc_TX_AP", e.value)}
            placeholder="Select Applicable"
          />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_TR_CR" className="font-bold">
            Currency
            </label>
            <Dropdown 
              name="rc_TR_CR"
              value={rc_TR_CR}
              options={rc_TR_CROptions}
              onChange={(e) =>CurrencyDropDown("rc_TR_CR", e.value)}
              placeholder="Select Currency"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_EX_RT" className="font-bold">
              Exchange Rate
            </label>
            <InputText disabled
              id="rc_EX_RT"
              name="rc_EX_RT"
              value={rc_EX_RT}
              onChange={(e) => onchangedata(e)}
            />
            </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_BS_CR" className="font-bold">
              Base Currency
            </label>
            <InputText disabled
              id="rc_BS_CR"
              name="rc_BS_CR"
              value={rc_BS_CR}
              onChange={(e) => onchangedata(e)}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_SH_TO" className="font-bold">
              Ship To <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="shipto_NAME"
                name="shipto_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={selectedAbAdNM}
              />
              {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openShipToPopup}
              ></i>
              )}
            </div>
              <InputText style={{display:"none"}}
                id="rc_SH_TO"
                name="rc_SH_TO"
                value={rc_SH_TO}
                onChange={(Event) => onchangedata(Event)}
              />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_SL_PR" className="font-bold">
              Sales Person <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                    <InputText
                      id="sales_PERSON"
                      name="sales_PERSON"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}                      
                      value={SelectedemEMNOE}
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSalesPersonPopup}
                    ></i>
                    )}
                  </div>
                    <InputText style={{ display: "none" }}
                      id="rc_SL_PR"
                      name="rc_SL_PR"
                      value={rc_SL_PR}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rc_BL_FR" className="font-bold">
                  Billing Frequency
                  </label>
                  <Dropdown
                    name="rc_BL_FR"  
                    value={rc_BL_FR}
                    options={rc_BL_FROptions}
                    onChange={(e) => BillingFrequencyDropDown("rc_BL_FR", e.value)}
                    placeholder="Select Billing Frequency"
                  />
                </div> */}
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="rc_HD_ST" className="font-bold">
                  Status
                  </label>
                  <Dropdown
                    name="rc_HD_ST"  
                    value={rc_HD_ST}
                    options={rc_HD_STOptions}
                    onChange={(e) => StatusDropdown("rc_HD_ST", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_DV_RT" className="font-bold">
              Deliver To <span style={{ color: 'red' }}>*</span>
              </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="deliver_TO"
                    name="deliver_TO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}                      
                    defaultValue={SelectedemEMNOD}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openEmployeePopup}
                  ></i>
                  )}
                </div>
                  <InputText style={{ display: "none" }}
                    id="rc_DV_RT"
                    name="rc_DV_RT"
                    value={rc_DV_RT}
                    onChange={(Event) => onchangedata(Event)}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_CR_NO" className="font-bold">
              Carrier No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="carrier_NO"
                  name="carrier_NO"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}                      
                  value={SelectedemEMNOC}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openManagerPopup}
                ></i>
                )}
              </div>
                <InputText style={{ display: "none" }}
                  id="rc_CR_NO"
                  name="rc_CR_NO"
                  value={rc_CR_NO}
                  defaultValue={(Event) => onchangedata(Event)}
                />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_ZN_CD" className="font-bold">
              Zone No
              </label>
              <Dropdown 
                name="rc_ZN_CD"
                value={rc_ZN_CD}
                options={rc_ZN_CDOptions}
                onChange={(e) =>ZoneDropDown("rc_ZN_CD", e.value)}
                placeholder="Select  Zone No"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_MD_TR" className="font-bold">
              Mode Of Transport
              </label>
              <Dropdown 
                name="rc_MD_TR"
                value={rc_MD_TR}
                options={rc_MD_TROptions}
                onChange={(e) =>TransportDropDown("rc_MD_TR", e.value)}
                placeholder="Select Mode Of transport"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_RT_CD" className="font-bold">
              Route Code
              </label>
              <Dropdown 
                name="rc_RT_CD"
                value={rc_RT_CD}
                options={rc_RT_CDOptions}
                onChange={(e) =>RouteDropDown("rc_RT_CD", e.value)}
                placeholder="Select Route Code"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_DS_PE" className="font-bold">
                Discount %
              </label>
              <InputText
                id="rc_DS_PE"
                name="rc_DS_PE"
                value={rc_DS_PE}
                onChange={(e) => onchangeHeaderDisPer(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_DS_AM" className="font-bold">
                Dis.Amount
              </label>
              <InputText
                id="rc_DS_AM"
                name="rc_DS_AM"
                defaultValue={rc_DS_AM}
                onChange={(e) => onchangeHeaderDisAmt(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_RF_NC" className="font-bold">
                Reference
              </label>
              <InputText 
                id="rc_RF_NC"
                name="rc_RF_NC"
                value={rc_RF_NC}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_IN_TR" className="font-bold">
              Instructions
              </label>
                <InputText 
                  id="rc_IN_TR"
                  name="rc_IN_TR"
                  value={rc_IN_TR}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
          </div>
        </div>  
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
        </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="RentalContractTable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line No</th>
                <th>Item Name</th>
                <th>Description</th>
                <th>Location</th>
                <th>Serial No</th>
                <th>Quantity</th>
                <th>UM</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>Unit Cost</th>
                <th>Amount</th>
                <th>Discount %</th>
                <th>Discount Amount</th>
                <th>Net Amount</th>
                <th>Tax Y/N</th>
                <th>Tax %</th>
                <th>Tax Amount</th>
                <th>Fore.Unit Price</th>
                <th>Fore.Amount</th>
                <th>Fore. Discount</th>
                <th>Fore. Net Amount</th>
                <th>Start Date</th>
                <th>End Date</th>
                {/* <th>Billing Start Date</th>
                <th>Billing End Date</th> */}
                <th>Billing Frequency</th>
                <th>Status</th>
                {/* <th>Cost Centre</th> */}
              </tr>
            </thead>
            <tbody>
              {RentalContractTable.map((row, index) => (
                <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`rcUkNo_${index}`}
                          name={`rcUkNo_${index}`}
                          defaultValue={row.rcUkNo}
                        />
                      </td>
                      <td >
                        <InputText disabled style={{width:"90px"}}
                          id={`rc_LN_NO_${index}`}
                          name={`rc_LN_NO_${index}`}
                          value={index+1}
                          readOnly
                        />
                      </td>
                      <td >
                        <div style={{ display: "flex" }}>
                        <InputText 
                        style={{ width: "110px" }}
                        id={`rc_IT_NM_${index}`}
                        name={`rc_IT_NM_${index}`}
                        onChange={(e) => onchangeFormData(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.rc_IT_NM ? row.rc_IT_NM: row[`${"rc_IT_NM"}_${index}`]}
                        />
                        {showSearchIcon && (
                      <i
                      className="pi pi-search"
                      style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "15%",
                      cursor: "pointer",
                      }}
                      onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                      </div>
                    </td>
                      <td>
                      <InputText disabled
                      style={{ width: "200px" }}
                      id={`itemDES_${index}`}
                      name={`itemDES_${index}`}
                      defaultValue={row.itemDES ? row.itemDES : row[`${"itemDES"}_${index}`]}
                      />
                      </td>
                    <td>
                      <Dropdown 
                        id={`rc_LO_CN_${index}`}
                        name={`rc_LO_CN_${index}`}
                        value={ data[`rc_LO_CN_${index}`] ? data[`rc_LO_CN_${index}`]: row.rc_LO_CN}
                        options={rc_LO_CNOptions}
                        onChange={(e) =>LocationDropdown(`rc_LO_CN_${index}`, e.value)}
                        placeholder="Select Location"
                      />
                    </td>
                    <td >
                    <InputText  disabled  style={{width:"90px"}}
                      id={`rc_SR_NO_${index}`}
                      name={`rc_SR_NO_${index}`}
                      defaultValue={row.rc_SR_NO ? row.rc_SR_NO : row[`${"rc_SR_NO"}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"90px"}}
                      id={`rc_OR_QT_${index}`}
                      name={`rc_OR_QT_${index}`}
                      defaultValue={row.rc_OR_QT}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`rc_TR_UM_${index}`}
                      name={`rc_TR_UM_${index}`}
                      value={ data[`rc_TR_UM_${index}`] ? data[`rc_TR_UM_${index}`]: row.rc_TR_UM}
                      options={rc_TR_UMOptions}
                      onChange={(e) =>UMDropdown(`rc_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                    />
                    </td>
                  <td >
                    <InputText  style={{width:"90px"}}
                      disabled={data.rc_TR_CR==rc_BS_CR?false:true}
                      id={`rc_UN_PR_${index}`}
                      name={`rc_UN_PR_${index}`}
                      value={data.rc_TR_CR==rc_BS_CR ? row.rc_UN_PR:0}
                      // onChange={(e) => onchangedata(e)}
                      onChange={(e) => {
                        onchangeFormUnitPrice(e, index);
                      }}       
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}          
                      id={`rc_UP_AM_${index}`}
                      name={`rc_UP_AM_${index}`}
                      defaultValue={data.rc_TR_CR==rc_BS_CR ? row.rc_UP_AM:0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"90px"}}
                      id={`rc_UN_CT_${index}`}
                      name={`rc_UN_CT_${index}`}
                      defaultValue={row.rc_UN_CT}
                      onChange={(e) => {
                        onchangeFormUnitPrice(e, index);
                      }}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`rc_UC_AM_${index}`}
                      name={`rc_UC_AM_${index}`}
                      defaultValue={data.rc_TR_CR==rc_BS_CR ? row.rc_UC_AM:0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"90px"}}
                      id={`rc_DS_PE_${index}`}
                      name={`rc_DS_PE_${index}`}
                      defaultValue={row.rc_DS_PE}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`rc_DS_AM_${index}`}
                      name={`rc_DS_AM_${index}`}
                      defaultValue={data.rc_TR_CR==rc_BS_CR ? row.rc_DS_AM:0}
                    />
                  </td>
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`rc_NT_MT_${index}`}
                      name={`rc_NT_MT_${index}`}
                      defaultValue={row.rc_NT_MT}
                    />
                  </td>
                  <td>
                  <InputText
                    style={{ width: "60px" }}
                    id={`rc_TX_AP_${index}`}
                    name={`rc_TX_AP_${index}`}
                    defaultValue={row.rc_TX_AP}
                    onChange={(e) => onchangeOfTaxAplMul(e,index)}
                  />
                  {/* <Dropdown
                    id={`rc_TX_AP_${index}`}
                    name={`rc_TX_AP_${index}`} 
                    value={data[`rc_TX_AP_${index}`] ? data[`rc_TX_AP_${index}`] : row.rc_TX_AP} 
                    options={[
                    { label: "Yes", value: "y" },
                    { label: "No", value: "n" },
                    ]}
                    onChange={(e) => TaxDropdown(`rc_TX_AP_${index}`, e.value)} 
                    placeholder="Select Tax Applicable "
                    /> */}
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`rc_TX_PE_${index}`}
                    name={`rc_TX_PE_${index}`}
                    defaultValue={row.rc_TX_PE}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText disabled style={{width:"90px"}}
                    id={`rc_TX_AM_${index}`}
                    name={`rc_TX_AM_${index}`}
                    defaultValue={row.rc_TX_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText style={{width:"90px"}}
                  disabled={data.rc_TR_CR!=rc_BS_CR?false:true}
                    id={`rc_FR_UP_${index}`}
                    name={`rc_FR_UP_${index}`}
                    defaultValue={data.rc_TR_CR!=rc_BS_CR ? row.rc_FR_UP:0}
                    onChange={(e) => onchangeFormUnitPrice(e,index)}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`rc_FR_AM_${index}`}
                    name={`rc_FR_AM_${index}`}
                    defaultValue={data.rc_TR_CR!=rc_BS_CR ? row.rc_FR_AM:0}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                <InputText disabled style={{width:"90px"}}
                  id={`rc_FR_DS_${index}`}
                  name={`rc_FR_DS_${index}`}
                  defaultValue={data.rc_TR_CR!=rc_BS_CR ? row.rc_FR_DS:0}
                  onChange={(e) => onchangedata(e)}
                />
              </td>
              <td >
              <InputText  disabled style={{width:"90px"}}
                id={`rc_FN_MT_${index}`}
                name={`rc_FN_MT_${index}`}
                defaultValue={data.rc_TR_CR!=rc_BS_CR ? row.rc_FN_MT:0}
                onChange={(e) => onchangedata(e)}
              />
            </td>
            <td>
            <Calendar style={{width:"150px"}}
              id={`rc_ST_DT_${index}`}
              name={`rc_ST_DT_${index}`}
              value={moment(row.rc_ST_DT, "YYYYMMDD").isValid() ? moment(row.rc_ST_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onDateChange({ target: {name: "rc_ST_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
            </td>
            <td>
            <Calendar style={{width:"150px"}}
              id={`rc_EN_DT_${index}`}
              name={`rc_EN_DT_${index}`}
              value={moment(row.rc_EN_DT, "YYYYMMDD").isValid() ? moment(row.rc_EN_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onDateChange({ target: {name: "rc_EN_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
            />
            </td>   
            
            {/* <td>
              <Calendar style={{width:"150px"}}
              id={`rc_BL_SD_${index}`}
              name={`rc_BL_SD_${index}`}
              value={moment(row.rc_BL_SD, "YYYYMMDD").isValid() ? moment(row.rc_BL_SD, "YYYYMMDD").toDate() : null}
              onChange={(e) => onDateChange({ target: {name: "rc_BL_SD", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <Calendar style={{width:"150px"}}
              id={`rc_BL_ED_${index}`}
              name={`rc_BL_ED_${index}`}
              value={moment(row.rc_BL_ED, "YYYYMMDD").isValid() ? moment(row.rc_BL_ED, "YYYYMMDD").toDate() : null}
              onChange={(e) => onDateChange({ target: {name: "rc_BL_ED", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
              dateFormat="dd/mm/yy"
              />
              </td>*/}
            <td>
              <Dropdown
                id={`rc_BL_FR_${index}`}
                name={`rc_BL_FR_${index}`}
                value={ data[`rc_BL_FR_${index}`] ? data[`rc_BL_FR_${index}`]: row.rc_BL_FR}
                options={rc_BL_FROptions}
                onChange={(e) =>FrequencyDropDown(`rc_BL_FR_${index}`, e.value)}
                placeholder="Select Billing Frequency"
              />
            </td>
            <td >
              <InputText disabled style={{width:"90px"}}
                id={`rc_ST_CD_${index}`}
                name={`rc_ST_CD_${index}`}
                defaultValue={rc_ST_CD}
              />
            </td>
              {/* <td >
                <InputText disabled style={{width:"90px"}}
                  id={`rc_CO_CT_${index}`}
                  name={`rc_CO_CT_${index}`}
                  defaultValue={rc_CO_CT}
                />
              </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        <div style={inboxstyle} >
        <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="price_AMOUNT" className="font-bold">
              Amount
            </label>
            <InputText disabled
              id="price_AMOUNT"
              name="price_AMOUNT"
              defaultValue={price_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="discount_AMOUNT" className="font-bold">
              Discount
            </label>
            <InputText disabled
              id="discount_AMOUNT"
              name="discount_AMOUNT"
              defaultValue={discount_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="net_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="net_AMOUNT"
              name="net_AMOUNT"
              defaultValue={net_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="tax_AMOUNT" className="font-bold">
              Tax
            </label>
            <InputText disabled
              id="tax_AMOUNT"
              name="tax_AMOUNT"
              defaultValue={tax_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="total" className="font-bold">
              Total
            </label>
            <InputText disabled
              id="total"
              name="total"
              defaultValue={total}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          <div className="formgrid grid" style={footergridstyle}>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
              Fore.Amount
            </label>
            <InputText disabled
              id="fr_PRICE_AMOUNT"
              name="fr_PRICE_AMOUNT"
              defaultValue={fr_PRICE_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_DISCOUNT_AMOUNT" className="font-bold">
              Fore.Discount
            </label>
            <InputText disabled
              id="fr_DISCOUNT_AMOUNT"
              name="fr_DISCOUNT_AMOUNT"
              defaultValue={fr_DISCOUNT_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_NET_AMOUNT" className="font-bold">
              Net Amount
            </label>
            <InputText disabled
              id="fr_NET_AMOUNT"
              name="fr_NET_AMOUNT"
              defaultValue={fr_NET_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
              Fore.Tax
            </label>
            <InputText disabled
              id="fr_TAX_AMOUNT"
              name="fr_TAX_AMOUNT"
              defaultValue={fr_TAX_AMOUNT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field" style={footerfieldstyle} >
            <label htmlFor="fr_TOTAL" className="font-bold">
              Fore.Total
            </label>
            <InputText disabled
              id="fr_TOTAL"
              name="fr_TOTAL"
              defaultValue={fr_TOTAL}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
        </div>
      </div>

        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      
      <AddressBookPopup
         visible={ShipToPopupVisible}
         onClose={() => setShipToPopupVisible(false)}
         onDataSelect={handleDataSelect1}
         formData={data}
         addressType={AddresstypeE}
       />

        <AddressBookPopup
          visible={SalesPersonPopupVisible}
          onClose={() => setSalesPersonPopupVisible(false)}
          onDataSelect={handleDataSelectE}
          formData={data}
          addressType={AddresstypeE}
        />

        <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelectD}
        formData={data}
        addressType={AddresstypeC}
      />

        <AddressBookPopup
        visible={ManagerPopupVisible}
        onClose={() => setManagerPopupVisible(false)}
        onDataSelect={handleDataSelectC}
        formData={data}
        addressType={AddresstypeC}
      />

      <RentalItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <RentalConrtactPopup
        visible={RentalContractPopupVisible}
        onClose={() => setRentalContractPopupVisible(false)}
        onDataSelect={handleRentalContract}
        formData={data}
      />


    </Dialog>
  );
}
