import React, { useState, useEffect } from "react";
import "components/ListStyle/ListStyle.css";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const LeaseNearExpiry = ({
    visible,
    onClose,
    selectedYear,
    selectedMonth,
}) => {

// buttonstyles
const {formatDate, tableHeaderStyle, tableCellStyle, Dialogstyle} = ButtonStyles();
const initialFilters = {
    li_UN_TS: { value: "", operation: "" },
    li_FL_NO: { value: "", operation: "" },
    li_BL_NO: { value: "", operation: "" },
    li_PR_NO: { value: "", operation: "" },
    liLeNo: { value: "", operation: "" },
    li_LE_TY: { value: "", operation: "" },
    li_ST_DT: { value: "", operation: "" },
    li_EN_DT: { value: "", operation: "" },
    li_TE_NT: { value: "", operation: "" },
    teanat_NAME: { value: "", operation: "" },
    li_MN_RN: { value: "", operation: "" },
    li_AN_RN: { value: "", operation: "" },
    };
const [filters, setFilters] = useState(initialFilters);
const handleFilterChange = (column, value) => {
    let updatedOperation = "";
    let updatedValue = value;

    if (value.startsWith(">")) {
        updatedOperation = "GREATER_THAN";
        updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
        updatedOperation = "LESS_THAN";
        updatedValue = value.substring(1);
    } else if (value.includes(",")) {
        updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
        updatedOperation = "EQUAL";
    } else {
        updatedOperation = "LIKE";
    }

    setFilters((prevFilters) => ({
        ...prevFilters,
        [column]: {
        value: updatedValue,
        operation: updatedOperation,
        },
    }));
    };
const [open, setOpen] = useState(false);
const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const [selecteddays, setSelecteddays] = useState("");
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
const [pageSize, setPageSize] = useState(10);

const [initialData, setInitialData] = useState([]);
const [filteredData, setFilteredData] = useState([]);

const ListLease = `${domain}/api/LeaseInformation/Lists/${currentPage}/${pageSize}`;
const ListLeaseExpiry= async (days) => {
    const requestBody = {
        "searchRequestDto":[],
        "input":days,
    };
    try {
        const response = await axios.post(ListLease, requestBody, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
        });
        // const data = response.data.leaseInformationPage.content;
        // setDashboardData(data);
        const data = response.data;
        if (days) {
            setFilteredData(data.leasesNearingExpiry);
        } else {
            setInitialData(data.leaseInformationPage.content);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const handleClose = () => {
    onClose();
    setOpen(false);
};
useEffect(() => {
    ListLeaseExpiry();
    setOpen(visible);
}, [visible, selectedMonth, selectedYear,]);
const days = [
    { label: "< 10 Days", value: 10 },
    { label: "< 30 Days", value: 30 },
    { label: "< 60 Days", value: 60 },
    { label: "< 90 Days", value: 90 }
    ];
    const handledayChange = (e) => {
    setSelecteddays(e.value);
    ListLeaseExpiry(e.value);
    };
    const displayData = selecteddays ? filteredData : initialData;
    const dialogHeader = (
        <div style={{ display:'flex' }}>
            <h5>{ "Lease Expiry" }</h5>
            <Dropdown
                style={{border:"none",backgroundColor:"#bcf0ff",marginLeft:"5%",paddingTop: "8px", paddingBottom: "0", }}
                id="days"
                value={selecteddays}
                // options={days}
                options={[{ label: "N/A", value: null }, ...days]}
                onChange={handledayChange}
                placeholder="Select Days"
            />
        </div>
    );

    return (
    <Dialog id="dialog-style"
        visible={open}
        onHide={handleClose}
        // style={{ width: "80rem", height: "53rem",}}
        style={Dialogstyle}
        header={dialogHeader}
    >
        <div style={{ display: "flex", }}>
            <div style={{ flex: "1 0 48%",  borderRadius: "10px", flexDirection: "column" }}>
                {/* <div style={{ textAlign: "center", fontSize: "150%", fontWeight: "bold", marginBottom: "1%" }}>Lease Near Expiry</div> */}
                <div style={{ overflow: "auto" }}>
                    <table id="Table2" style={{ border: "1px solid", borderRadius: "10px", width: "100%", tableLayout: "fixed", height: "auto" }}>
                        <thead style={{position: "sticky", top: "0", zIndex: "1", backgroundColor: "#e1e1e1"}}>
                        {/* <tr>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_UN_TS"] ? filters["li_UN_TS"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_UN_TS",
                                        e.target.value,
                                        filters["li_UN_TS"]
                                        ? filters["li_UN_TS"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_FL_NO"] ? filters["li_FL_NO"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_FL_NO",
                                        e.target.value,
                                        filters["li_FL_NO"]
                                        ? filters["li_FL_NO"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_BL_NO"] ? filters["li_BL_NO"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_BL_NO",
                                        e.target.value,
                                        filters["li_BL_NO"]
                                        ? filters["li_BL_NO"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_PR_NO"] ? filters["li_PR_NO"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_PR_NO",
                                        e.target.value,
                                        filters["li_PR_NO"]
                                        ? filters["li_PR_NO"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["liLeNo"] ? filters["liLeNo"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "liLeNo",
                                        e.target.value,
                                        filters["liLeNo"]
                                        ? filters["liLeNo"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_LE_TY"] ? filters["li_LE_TY"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_LE_TY",
                                        e.target.value,
                                        filters["li_LE_TY"]
                                        ? filters["li_LE_TY"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_ST_DT"] ? filters["li_ST_DT"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_ST_DT",
                                        e.target.value,
                                        filters["li_ST_DT"]
                                        ? filters["li_ST_DT"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_EN_DT",
                                        e.target.value,
                                        filters["li_EN_DT"]
                                        ? filters["li_EN_DT"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_MN_RN"] ? filters["li_MN_RN"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_MN_RN",
                                        e.target.value,
                                        filters["li_MN_RN"]
                                        ? filters["li_MN_RN"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_AN_RN"] ? filters["li_AN_RN"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_AN_RN",
                                        e.target.value,
                                        filters["li_AN_RN"]
                                        ? filters["li_AN_RN"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={filters["li_BL_FR"] ? filters["li_BL_FR"].value : ""}
                                    onChange={(e) =>
                                    handleFilterChange(
                                        "li_BL_FR",
                                        e.target.value,
                                        filters["li_BL_FR"]
                                        ? filters["li_BL_FR"].operation
                                        : "equals"
                                    )
                                    }
                                    style={inputStyle}
                                />
                            </td>
                        </tr> */}
                            <tr>
                                <th style={tableHeaderStyle} >Unit</th>
                                <th style={tableHeaderStyle} >Floor</th>
                                <th style={tableHeaderStyle} >Building</th>
                                <th style={tableHeaderStyle} >Property</th>
                                <th style={tableHeaderStyle} >Lease No</th>
                                <th style={tableHeaderStyle} >Type</th>
                                <th style={tableHeaderStyle} >From Date</th>
                                <th style={tableHeaderStyle} >To Date</th>
                                <th style={tableHeaderStyle} >Tenant</th>
                                <th style={tableHeaderStyle} >Name</th>
                                <th style={tableHeaderStyle} >Monthly Rent</th>
                                <th style={tableHeaderStyle} >Annual Rent</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "white",overflowY: "auto", maxHeight: "calc(80vh - 80px)" }}>
                        {displayData.map((rowData, index) => (
                                <tr key={index} className="table-row" style={{ backgroundColor:index % 2 === 0 ? "aliceblue" : "#ffffff",}}>
                                    <td style={tableCellStyle} >{rowData.li_UN_TS}</td>
                                    <td style={tableCellStyle} >{rowData.li_FL_NO}</td>
                                    <td style={tableCellStyle} >{rowData.li_BL_NO}</td>
                                    <td style={tableCellStyle} >{rowData.li_PR_NO}</td>
                                    <td style={tableCellStyle} >{rowData.liLeNo}</td>
                                    <td style={tableCellStyle} >{rowData.li_LE_TY}</td>
                                    <td style={tableCellStyle} >{formatDate(rowData.li_ST_DT)}</td>
                                    <td style={tableCellStyle} >{formatDate(rowData.li_EN_DT)}</td>
                                    <td style={tableCellStyle} >{rowData.li_TE_NT}</td>
                                    <td style={tableCellStyle} >{rowData.teanat_NAME}</td>
                                    <td style={tableCellStyle} >{rowData.li_MN_RN}</td>
                                    <td style={tableCellStyle} >{rowData.li_AN_RN}</td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            {/* pagination */}
            <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            />
    </Dialog>
);
};

export default LeaseNearExpiry;

