import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import Webcam from "react-webcam";
import { Container, Row, Col } from "react-bootstrap";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ContactOwnerPopup from "./ContactOwnerPopup";
import LeadIDPopup from "./LeadIDPopup";
import { InputMask } from "primereact/inputmask";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
export default function VisitDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  setFormData,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData,
  selectedAbAdNM,
  setselectedAbAdNM, //CONTACT
  selectedAbAdNN,
  setselectedAbAdNN, //CUSTOMER
  selectedvrEmNo,
  setselectedvrEmNo, // LEAD OWNER
  vr_VI_TY,
  vr_VI_CO,
  vr_CN_NO,
  vr_EM_NO,
  vr_LD_NO,
  vr_CN_CO,
  vr_LD_TY,
  vr_LD_CO,
  vrUkNo,
  vr_VI_PR,
  vr_VI_NO,
  vr_IN_TM,
  vr_OT_TM,
  cc_EM_IL,
  setcc_EM_IL,
  vr_IN_LO,
  vr_OT_LO,
  cc_PH_NO,
  setcc_PH_NO,
  ab_CL_11,
  ab_CL_12,
  vr_EM_IL,
  vr_AD_P1,
  setvr_CN_NO,
  setvr_EM_NO,
  setvr_CN_CO,
  setvr_AD_P1,
  setvr_EM_IL,
  setab_CL_11,
  setab_CL_12,
  capturedImage,
  setCapturedImage,
  capturedImageSave,
  setcapturedImageSave,
  vr_FD_ST,
  setvr_FD_ST,
  vr_NX_AC,
  setvr_NX_AC,
}) {
  const {
    vr_TR_DT,
    vr_PR_NO,vr_FB_SU
      } = data;

  const {borderbox,inboxstyle,TabBoxStyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [vr_VI_PROptions, setvr_VI_PROptions] = useState([]);
   // dropdowns
   const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
   const [vr_FD_STOptions, setvr_FD_STOptions] = useState([]);
   const [vr_NX_ACOptions, setvr_NX_ACOptions] = useState([]);

  // status
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setvr_FD_ST(value);
  };
  // next action
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setvr_NX_AC(value);
  };
  useEffect(() => {
    fetchDropdownSt();
    fetchDropdownNxt();
  }, []);
  const fetchDropdownSt = async () => {
    const Options = await fetchClassCodeDS("FD/ST");
    setvr_FD_STOptions(Options);
  };
  const fetchDropdownNxt = async () => {
    const Options = await fetchClassCodeDS("NX/AC");
    setvr_NX_ACOptions(Options);
  };
  const webcamRef = useRef(null);

  const handleTakePhoto = () => {
    try {
      console.log("Take Photo button clicked");
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      var imageSRCV1 = imageSrc;
      imageSRCV1 = imageSRCV1.replace(/^data:image\/[a-z]+;base64,/, "");
      setcapturedImageSave(imageSRCV1);
      if (imageSRCV1) {
        console.log("Captured Image:", imageSrc);
      } else {
        console.log("Image capture failed");
      }
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  var videoConstraints = { facingMode: "user" };

  const [showCamera, setShowCamera] = useState(false);
  const handleOnCamera = () => {
    setShowCamera(true);
  };
  const handleOffCamera = () => {
    setShowCamera(false);
  };
  const handleFlipCamera = () => {
    if(videoConstraints.facingMode === "user"){
      videoConstraints = { facingMode: { exact: "environment" } };
    } else {
      videoConstraints = { facingMode: "user" };
    }
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [ContactOwnerPopupVisible, setContactOwnerPopupVisible] = useState(false);
  const [LeadIDPopupVisible, setLeadIDPopupVisible] = useState(false);
  const [LeadOwnerPopupVisible, setLeadOwnerVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ab_CL_11Options, setab_CL_11Options] = useState([]);
  const [addressTypeCust, setaddressTypecust] = useState("C");
  const [addressTypeLDOW, setaddressTypeLDOW] = useState("E");
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameterIndustry = "AB/11";
  const finalUrlIndustry = `${url}/${parameterIndustry}`;
  const fetchDropdownOptionsIndustry = async () => {
    try {
      const response = await axios.get(finalUrlIndustry, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_CL_11Options(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const parameter4 = "AB/12";
  const finalUrl4 = `${url}/${parameter4}`;
  const [ab_CL_12Options, setab_CL_12Options] = useState([]);
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        }
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setab_CL_12Options(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const openContactOwnerPopup = () => {
    setContactOwnerPopupVisible(true);
  };
  const openCustomerPopup = () => {
    setCustomerPopupVisible(true);
  };
  const openLeadIDPopup = () => {
    setLeadIDPopupVisible(true);
  };
  const openLeadOwnerPopup = () => {
    setLeadOwnerVisible(true);
  };
  const handleDataSelect3 = (data) => {
    setselectedvrEmNo(data.ab_AD_NM);
    setvr_EM_NO(data.abAdNo);
    setLeadOwnerVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect1 =async (cusData) => {
    const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
    const requestData={
      "abAdNo":cusData.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const customerdata = response.data;
      if (customerdata) {
        setvr_AD_P1(customerdata.data.ab_AD_P1);
        setvr_EM_IL(customerdata.data.ab_AD_EM);
        setab_CL_12(customerdata.data.ab_CL_12);
        setselectedAbAdNN(customerdata.data.ab_AD_NM);
        setvr_CN_NO(customerdata.data.abAdNo);
        setab_CL_11(customerdata.data.ab_CL_11);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelect2 =async (contdata) => {
    const Url = `${domain}/api/Contact/onChangeOfContactPerson`;
    const requestData={
      "ccCnNo":contdata.ccCnNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const contactdata = response.data;
      if (contactdata) {
        setcc_PH_NO(contactdata.contact.cc_PH_NO);
        setcc_EM_IL(contactdata.contact.cc_EM_IL);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setContactOwnerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectID =async (data) => {
    onchangeFormData("vr_LD_NO", data.slLdId);
    const Url = `${domain}/api/SalesLead/GetLeadId`;
    const requestData={
      "slLdId" : data.slLdId
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const saLdData = response.data.inp1;
      if (saLdData) {
        setselectedAbAdNM(saLdData.contact_NAME);
        setselectedAbAdNN(saLdData.cus_NAME);
        setselectedvrEmNo(saLdData.lead_NAME);
        setvr_CN_NO(saLdData.sl_CO_NM);//CUSTOMER
        setvr_EM_NO(saLdData.sl_LD_OW);//OWNER
        setvr_CN_CO(saLdData.sl_CN_NO);//CONTACT
        setvr_AD_P1(saLdData.sl_AD_P1);
        setvr_EM_IL(saLdData.sl_AD_EM);
        
// onchangecustomer
        const cusData = {
          "abAdNo": saLdData.sl_CO_NM,
        }
        await handleDataSelect1(cusData);
// onchangecontact
        const contdata = {
          "ccCnNo": saLdData.sl_CN_NO
        }
        await handleDataSelect2(contdata);

      } else {
        console.error("Invalid API response:", saLdData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLeadIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  const parameter1 = "DV/PR";
  const finalUrl1 = `${url}/${parameter1}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptionsIndustry();
    fetchDropdownOptions4();
  }, []);
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setvr_VI_PROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [activeTab, setActiveTab] = useState(0);
  const customStyles = `
  .marker-button {
    background-color: #ffffff;
    font-size: 0rem !important;
    color: #000000;
    display:inline;
    border:none;
  }
`;
const [hours, setHours] = useState(0);
const [minutes, setMinutes] = useState(0);
const [seconds, setSeconds] = useState(0);
const [checkoutHours, setCheckoutHours] = useState(0);
const [checkoutMinutes, setCheckoutMinutes] = useState(0);
const [checkoutSeconds, setCheckoutSeconds] = useState(0);
const [checkInLocation, setCheckInLocation] = useState("");
const [checkOutLocation, setCheckOutLocation] = useState("");

const handleCheckIn = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const location = `${latitude}, ${longitude}`;
        setCheckInLocation(location);
        onchangedata({ target: { name: "vr_IN_LO", value: location } });

        const currentTime = new Date();
        const newTime = currentTime.getHours() * 10000 +
                        currentTime.getMinutes() * 100 +
                        currentTime.getSeconds();
        setHours(currentTime.getHours());
        setMinutes(currentTime.getMinutes());
        setSeconds(currentTime.getSeconds());

        onchangedata({ target: { name: "vr_IN_TM", value: newTime } });
      },
      (error) => {
        console.error("Error getting geolocation:", error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};
const handleCheckOut = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const location = `${latitude}, ${longitude}`;
        setCheckOutLocation(location);
        onchangedata({ target: { name: "vr_OT_LO", value: location } });

        const currentTime = new Date();
        const newTime = currentTime.getHours() * 10000 +
                        currentTime.getMinutes() * 100 +
                        currentTime.getSeconds();
        setCheckoutHours(currentTime.getHours());
        setCheckoutMinutes(currentTime.getMinutes());
        setCheckoutSeconds(currentTime.getSeconds());

        onchangedata({ target: { name: "vr_OT_TM", value: newTime } });
      },
      (error) => {
        console.error("Error getting geolocation:", error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
};
const [isHovered, setIsHovered] = useState(false); 
const [selectedDate, setSelectedDate] = useState(moment().toDate());
const handleDateChange = (e) => {
  setSelectedDate(e.value);
  onchangedata({ target: { name: "vr_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
};
// DIALOG HEADER
const isUpdateMode = Boolean(vrUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{vrUkNo ? "Revise Visit Details" : "Add Visit Details"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
    {activeTab === 0 && (
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />)}
    {activeTab === 0 && (
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    )}
    </div>
  </div>
);
return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
    <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab}  onTabChange={(e) => setActiveTab(e.index)} >
        <TabPanel header="General">
          <div style={{display:"flex"}}>
          <style dangerouslySetInnerHTML={{__html: customStyles}}></style>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <label>Check In</label>
          <Button
            icon="pi pi-map-marker" 
            outlinedcheck
            className="mr-2 marker-button"
            onClick={handleCheckIn}
          ></Button>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center",marginLeft:"2%" }}>
            <label>Check Out</label>
          <Button
            // icon="pi pi-check"
            icon="pi pi-map-marker"
            outlinedcheck
            className="mr-2 marker-button"
            onClick={handleCheckOut}
          ></Button>
          </div>
          </div>
          <div
            style={{
              marginTop: "2%",
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display : "none"}}>
                <label htmlFor="vrUkNo" className="font-bold">
                vrUkNo
                </label>
                <InputText
                  id="vrUkNo"
                  name="vrUkNo"
                  value={vrUkNo}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{ backgroundColor: "#f5f5f5" ,border: isHovered ? "none" : "1px solid #ced4da",  outline: "none",}}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_VI_NO" className="font-bold">
                  Visit No
                </label>
                <InputText disabled
                  id="vr_VI_NO"
                  name="vr_VI_NO"
                  value={vr_VI_NO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_VI_TY" className="font-bold">
                  Type
                </label>
                <InputText disabled
                  id="vr_VI_TY"
                  name="vr_VI_TY"
                  value={vr_VI_TY}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                <label htmlFor="vr_VI_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="vr_VI_CO"
                  name="vr_VI_CO"
                  value={vr_VI_CO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_LD_TY" className="font-bold">
                  Lead Type
                </label>
                <InputText disabled
                  id="vr_LD_TY"
                  name="vr_LD_TY"
                  value={vr_LD_TY}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_VI_PR" className="font-bold">
                  Visit Purpose
                </label>
                <Dropdown
                  id="vr_VI_PR"
                  name="vr_VI_PR"
                  value={vr_VI_PR}
                  options={vr_VI_PROptions}
                  onChange={(e) => onchangeDropDownFormData("vr_VI_PR", e.value)}
                  placeholder="Select Purpose"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_TR_DT" className="font-bold">
                  Date
                </label>
                <Calendar
                    id="vr_TR_DT"
                    name="vr_TR_DT"
                    value={moment(vr_TR_DT, "YYYYMMDD").toDate()}
                    onChange={handleDateChange}
                    dateFormat="dd/mm/yy"
                  />
              </div>
             
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_LD_NO" className="font-bold">
                  Lead No <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                <InputText
                  id="vr_LD_NO"
                  name="vr_LD_NO"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={vr_LD_NO}
                />
                  {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadIDPopup}
                ></i>
                  )}
            </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_IN_TM" className="font-bold">
                  In Time
                </label>
                <InputMask
                  id={vr_IN_TM}
                  name={vr_IN_TM}
                  mask="99:99:99"
                  value={moment(vr_IN_TM, "HHmmss").format("HH:mm:ss")}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_IN_LO" className="font-bold">
                  In Geo Location
                </label>
                <InputText
                  id="vr_IN_LO"
                  name="vr_IN_LO"
                  defaultValue={vr_IN_LO}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="vr_LD_CO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="vr_LD_CO"
                  name="vr_LD_CO"
                  value={vr_LD_CO}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_OT_TM" className="font-bold">
                  Out Time
                </label>
                <InputMask
                  id={vr_OT_TM}
                  name={vr_OT_TM}
                  mask="99:99:99"
                  value={moment(vr_OT_TM, "HHmmss").format("HH:mm:ss")}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_OT_LO" className="font-bold">
                  Out Geo Location
                </label>
                <InputText
                  id="vr_OT_LO"
                  name="vr_OT_LO"
                  defaultValue={vr_OT_LO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_PR_NO" className="font-bold">
                    Reference
                </label>
                <InputText
                  id="vr_PR_NO"
                  name="vr_PR_NO"
                  defaultValue={vr_PR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_CN_NO" className="font-bold">
                  Customer <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNN}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openCustomerPopup}
                ></i>
                )}
                </div>
                <div  style={{ display: "none" }} >
                <InputText
                  id="vr_CN_NO"
                  name="vr_CN_NO"
                  defaultValue={vr_CN_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_AD_P1" className="font-bold">
                  Contact No 
                </label>
                <InputText
                  id="vr_AD_P1"
                  name="vr_AD_P1"
                  value={vr_AD_P1}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_EM_IL" className="font-bold">
                  Email
                </label>
                <InputText
                  id="vr_EM_IL"
                  name="vr_EM_IL"
                  value={vr_EM_IL}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_EM_NO" className="font-bold">
                  Lead Owner <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM"
                  name="ab_AD_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedvrEmNo}
                />
                  {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openLeadOwnerPopup}
                ></i>
                  )}
            </div>
            <div   >
                <InputText style={{ display: "none" }}
                  id="vr_EM_NO"
                  name="vr_EM_NO"
                  value={vr_EM_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_11" className="font-bold">
                  Industry
                </label>
                <Dropdown disabled
                  id="ab_CL_11"
                    name="ab_CL_11"
                    value={ab_CL_11}
                    options={ab_CL_11Options}
                    placeholder="Select Industry"
                  />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ab_CL_12" className="font-bold">
                  Verticals
                </label>
                <Dropdown disabled
                  id="ab_CL_12"
                  name="ab_CL_12"
                  value={ab_CL_12}
                  options={ab_CL_12Options}
                  placeholder="Select Verticals"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="vr_CN_CO" className="font-bold">
                  Contact Person <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                <InputText
                  id="cc_CN_NM"
                  name="cc_CN_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={selectedAbAdNM}
                />
                  {showSearchIcon && (
                  <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openContactOwnerPopup}
                ></i>
                  )}
            </div>
            <div  >
                <InputText style={{ display: "none" }}
                  id="vr_CN_CO"
                  name="vr_CN_CO"
                  value={vr_CN_CO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_PH_NO" className="font-bold">
                  Contact No
                </label>
                <InputText
                  id="cc_PH_NO"
                  name="cc_PH_NO"
                  value={cc_PH_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="cc_EM_IL" className="font-bold">
                  Email
                </label>
                <InputText
                  id="cc_EM_IL"
                  name="cc_EM_IL"
                  value={cc_EM_IL}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Snap Shot">
          <div
            style={{
              marginTop: "2%",
              border: "1px solid #e8e8e8",
              padding: "16px",
              borderRadius: "15px",
              backgroundColor: "aliceblue",
              overflow: "auto",
            }}
          >
            <Container className="camera">
              <style dangerouslySetInnerHTML={{__html: customStyles}}></style>
              <Row>
                <Col>
                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                      id="on-camera"
                        className="on-camera-button"
                        variant="primary"
                        onClick={handleOnCamera}
                      >On Camera</Button>
                      </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                      id="capture-camera"
                        variant="primary"
                        onClick={handleTakePhoto}
                      >
                        Take a Photo
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                      id="flip-btn"
                        variant="primary"
                        onClick={handleFlipCamera}
                      >
                        Flip Camera
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-end">
                      <Button
                      id="off-camera"
                        variant="primary"
                        onClick={handleOffCamera}
                      >
                        Off Camera
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-9">
                  <Row className="mt-3" style={{ marginTop: "20% ! important" }}>
                  <Col className="text-center">
                    <div style={{ display: "flex" }}>
                      {showCamera && <Webcam
                        style={{
                          border: "1px solid blue",
                          width: "240px",
                          marginLeft: "5%",
                        }}
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        width={600}
                      />}
                      {capturedImage && (<img
                        src={capturedImage}
                        alt="Captured Image"
                        style={{
                          border: "1px solid blue",
                          width: "240px",
                          marginLeft: "5%",
                        }}
                        height={400}
                        width={600}
                      />)}
                    </div>
                    <video id="camera-stream" class="border border-5 border-danger"></video>
                    <canvas hidden="hidden" id="canvas" class="bg-light shadow border border-5 border-success">
                  </canvas>
                  </Col>
                </Row>
                </Col>
              </Row>
                              
            </Container>
          </div>
        </TabPanel>
        <TabPanel header="Additional Info">
        <div style={borderbox} >
          <div style={inboxstyle} >
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
                  <label htmlFor="vr_NX_MT" className="font-bold" >
                  Date 
                  </label>
                  {/* next meeting */}
                  <Calendar
                  id="vr_NX_MT"
                  name="vr_NX_MT"
                  value={moment(data.vr_NX_MT, "YYYYMMDD").isValid() ? moment(data.vr_NX_MT, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "vr_NX_MT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="vr_FD_ST" className="font-bold">
                    Status
                  </label>
                  <Dropdown
                    id="vr_FD_ST"
                    name="vr_FD_ST"
                    value={vr_FD_ST}
                    options={vr_FD_STOptions}
                    onChange={(e) => onchangeDropDownFormData1("vr_FD_ST", e.value)}
                    placeholder="Select Status"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="vr_NX_AC" className="font-bold">
                    Next Action
                  </label>
                  <Dropdown
                    id="vr_NX_AC"
                    name="vr_NX_AC"
                    value={vr_NX_AC}
                    options={vr_NX_ACOptions}
                    onChange={(e) => onchangeDropDownFormData2("vr_NX_AC", e.value)}
                    placeholder="Select Next Action"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2 d-flex flex-column">
                  <label htmlFor="vr_FB_SU" className="font-bold">
                    Remarks
                  </label>
                  <InputText 
                    style={{ width: '80ch' }}
                    id="vr_FB_SU"
                    name="vr_FB_SU"
                    value={vr_FB_SU}
                    onChange={(e) => onchangedata(e)}
                  />

                {/* <textarea 
                  id="vr_FB_SU"
                  name="vr_FB_SU"
                  value={vr_FB_SU}
                  onChange={(e) => onchangedata(e)}
                  rows={2}  // Set height to 2 rows
                  style={{ width: '100ch' }}  // Set width to 100 characters
                /> */}
                </div>
              </div>
          </div>
    </div>
        </TabPanel>
      </TabView>
    </div>
      {/* Customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        addressType={addressTypeCust}
        setFormData={setFormData}
      />
      {/* Lead Owner */}
      <AddressBookPopup
        visible={LeadOwnerPopupVisible}
        onClose={() => setLeadOwnerVisible(false)}
        onDataSelect={handleDataSelect3}
        formData={data}
        addressType={addressTypeLDOW}
        setFormData={setFormData}
      />
      <ContactOwnerPopup
        visible={ContactOwnerPopupVisible}
        onClose={() => setContactOwnerPopupVisible(false)}
        onDataSelect={handleDataSelect2}
        formData={data}
      />
      <LeadIDPopup
        visible={LeadIDPopupVisible}
        onClose={() => setLeadIDPopupVisible(false)}
        onDataSelect={handleDataSelectID}
        formData={data}
      />
  </Dialog>
);
}

