import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AccessGroupPopupFMS from "views/admin/Popups/AccessGroupPopupFMS";
import ButtonStyles from "views/common/ButtonStyles";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";

const SharedWithMePopup = ({ 
  visible,
  onClose,optionsSelected,handleResponse,documentType,setoptionsSelected,addressType
  }) => {

    const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,borderbox,
    savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

    const [operationtabledata, setoperationtabledata] = useState([{}])
    const addRow = () => {
      setoperationtabledata([...operationtabledata, {}]);
    };
    
    const deleteRow = () => {
      if (operationtabledata.length > 0) {
        const updatedData = [...operationtabledata];
        updatedData.pop();
        setoperationtabledata(updatedData);
      }
    };

  const valuenames = {
    Access_Grp:"",
    Employee_ID:"",
  }
  const [FormData, setFormData] = useState(valuenames);


  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [AccessGrpPopupVisible, setAccessGrpPopupVisible] =useState(false);
  const [EmployeePopupVisible, setEmployeePopupVisible] =useState(false);


  const openEmployeeIdPopup = () => {
    setEmployeePopupVisible(true);
  };

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [emEmNo, setemEmNo] = useState("");

  const handleSelectEmployee = (data) => {
    setSelectedAbAdNM(data.ag_NA_ME);
    document.getElementById("emEmNo").value = data.ag_GP_ID;
    setemEmNo(data.ag_GP_ID);
  setEmployeePopupVisible(false);
  setShowSearchIcon(false);
  };

  const handleShareWithEmployeeClick = async (option) => {
    const fileDetails = {
      "fdUkNo" : optionsSelected
    };
    const aData =  {
      "fa_AC_TY" : "E",
      "fa_GP_ID" : document.getElementById("emEmNo").value
    }
  
    const accessTableList = [];
    accessTableList.push(aData);

    const inputShare = {
      "accessTableList" : accessTableList,
      "fileDetails" : fileDetails
    };
    const url = `${domain}/api/AccessTable/Add`;
    try {
      const response = await axios.post(url, inputShare, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data;
      handleResponse(listItemsData);
      onClose();
      setSelectedAbAdNM(null);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Dialog style={{width:'30rem',height:'30rem'}}
        visible={visible}
        onHide={onClose}
    >

    <div style={borderbox}> 

      <div className="formgrid grid" style={{display:'flex'}}>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="emEmNo" className="font-bold">
                    Group<span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ab_AD_NM"
                      name="ab_AD_NM"
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={selectedAbAdNM}
                    />
                     {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openEmployeeIdPopup}
                    ></i>
                     )}
                  </div>
                 
                  <div style={{ display: "none" }}>
                    <InputText
                      id="emEmNo"
                      name="emEmNo"
                      value={emEmNo}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
      </div>

      {/* <div  style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th> Operation Sequence</th>
              <th> Description </th>
            </tr>
          </thead>
          <tbody>
            {operationtabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`osUkNo_${index}`}
                    name={`osUkNo_${index}`}
                    value={row.osUkNo}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_OP_SQ_${index}`}
                    name={`os_OP_SQ_${index}`}
                    defaultValue={row.os_OP_SQ}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_SQ_DS_${index}`}
                    name={`os_SQ_DS_${index}`}
                    defaultValue={row.os_SQ_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>  */}
      
      <button style={{width: "100%",backgroundColor : "white", color : 'black', height: "45px"}} className="btn btn-primary"  
                    onClick={() => handleShareWithEmployeeClick()}>Share to Group</button>

    </div>

      {/* employeeid */}
      <AccessGroupPopupFMS
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleSelectEmployee}
        formData={FormData}
        addressType={addressType}
        setFormData={setFormData}
      />

    </Dialog>
  )
}

export default SharedWithMePopup