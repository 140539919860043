import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import JewelleryAcknowledgementDialog from "./JewelleryAcknowledgementDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  jtUkNo:"",
  jt_OR_NO:"",
  jt_OR_TY:"",
  jt_OR_CO:"",
  jt_BG_NO:"",
  jt_IT_NM:"",
  item_NAME:"",
  jt_WO_QT:"",
  jt_TR_UM:"",   
  jt_LO_CN:"",  
  jt_TO_LO:"", 
  jt_TR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  jt_CO_QT:"",   
  jt_RL_QT:"",
  jt_BL_QT:"",    
  //jt_TR_UM:"", 
  jt_AK_PS:"",   
  ack_DESC:"", 
  jt_AK_DT: moment(moment().toDate()).format("YYYYMMDD"),
};

const initialFilters = {
  jt_OR_NO: { value: "", operation: "" },
  jt_OR_TY: { value: "", operation: "" },
  jt_OR_CO: { value: "", operation: "" },
  jt_BG_NO: { value: "", operation: "" },
  jt_IT_NM: { value: "", operation: "" },
  item_NAME: { value: "", operation: "" },
  jt_WO_QT: { value: "", operation: "" },
  jt_TR_UM: { value: "", operation: "" },   
  jt_LO_CN: { value: "", operation: "" },  
  jt_TO_LO: { value: "", operation: "" }, 
  jt_TR_DT: { value: "", operation: "" },
  jt_CO_QT: { value: "", operation: "" },   
  jt_RL_QT: { value: "", operation: "" },
  jt_BL_QT: { value: "", operation: "" },    
  jt_TR_UM: { value: "", operation: "" }, 
  jt_AK_PS: { value: "", operation: "" },   
  ack_DESC: { value: "", operation: "" }, 
};

const JewelleryAcknowledgementMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,inputStyleH,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null); 
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  // drodpdowns

  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjt_BG_NO(value);
  };

  
  const [jt_BG_NO, setjt_BG_NO ] = useState("");


  
 // Advance Search

  const [SelectedAcknowledgingPerson, setSelectedAcknowledgingPerson ] = useState("");
  const [SelectedTransferPerson, setSelectedTransferPerson ] = useState("");
  const [SelectedITNM, setSelectedITNM ] = useState("");

  const [JewelleryAcknowledgementTable, setJewelleryAcknowledgementTable] = useState([{}]);
  
  
  
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleAddButtonClick = () => {
    setJewelleryAcknowledgementTable([{}]);
    setFormData(initialValue);
    setSelectedAcknowledgingPerson(null);
    setSelectedTransferPerson(null);
    setSelectedITNM(null);
    setjt_BG_NO(null);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setJewelleryAcknowledgementTable([{}]);
    setFormData(initialValue);

    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const [jt_OR_CO, setjt_OR_CO] = useState("")


  const handleDefault = async () => {
    setjt_OR_CO(`${company}`)
    
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
           
          
           
        
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JewelleryAcknowledgement/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.jtUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      JewelleryAcknowledgementTable[index][fieldName]=value;
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/JewelleryAcknowledgement/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const JewelleryAcknowledgementData = response.data;
      //  setJewelleryAcknowledgementTable(JewelleryAcknowledgementData.loadBuildingDetailsList);
        setFormData(JewelleryAcknowledgementData);
        setSelectedITNM(JewelleryAcknowledgementData.item_NAME);
        setSelectedAcknowledgingPerson(JewelleryAcknowledgementData.ack_DESC); 
        setSelectedTransferPerson(JewelleryAcknowledgementData.transferPerson_EDSC); 
        setjt_BG_NO(JewelleryAcknowledgementData.jt_BG_NO); 
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


var requestBody = {};
function values () {
  requestBody = {
    jtUkNo   : FormData.jtUkNo,
    jt_OR_NO : document.getElementById("jt_OR_NO").value,
    jt_OR_TY : document.getElementById("jt_OR_TY").value,
    jt_OR_CO : document.getElementById("jt_OR_CO").value,
    jt_TR_DT : FormData.jt_TR_DT,
    jt_IT_NM : document.getElementById("jt_IT_NM").value,
    jt_WO_QT : document.getElementById("jt_WO_QT").value,
    jt_BG_NO : jt_BG_NO,
    jt_CO_QT : document.getElementById("jt_CO_QT").value,
    jt_LO_CN : document.getElementById("jt_LO_CN").value,
    jt_TO_LO : document.getElementById("jt_TO_LO").value,
    jt_RL_PS : document.getElementById("jt_RL_PS").value,
    jt_WT_QT : document.getElementById("jt_WT_QT").value,
    jt_RL_QT : document.getElementById("jt_RL_QT").value,
    jt_AK_DT : FormData.jt_AK_DT,
    jt_AK_PS : document.getElementById("jt_AK_PS").value,
    jt_OR_CO : document.getElementById("jt_OR_CO").value,
    
    
  };
}
const handleFormSubmit = () => {
  values();
  const orNo = FormData.jt_OR_NO;
  const ukNo = FormData.jtUkNo;
  if (orNo) {
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/JewelleryAcknowledgement/Update`;
  
      if (confirm) {
        axios.put(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } 
  } 
  };




   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>

      
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_OR_NO"] ? filters["jt_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_OR_NO",
                        e.target.value,
                        filters["jt_OR_NO"]
                          ? filters["jt_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_OR_TY"] ? filters["jt_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_OR_TY",
                        e.target.value,
                        filters["jt_OR_TY"] ? filters["jt_OR_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_OR_CO"] ? filters["jt_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_OR_CO",
                        e.target.value,
                        filters["jt_OR_CO"]
                          ? filters["jt_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_BG_NO"] ? filters["jt_BG_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_BG_NO",
                        e.target.value,
                        filters["jt_BG_NO"] ? filters["jt_BG_NO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              
                <td>
                  <input
                    type="text"
                    value={filters["jt_IT_NM"] ? filters["jt_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_IT_NM",
                        e.target.value,
                        filters["jt_IT_NM"]
                          ? filters["jt_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input 
                    type="text"
                    value={filters["Item_Name"] ? filters["Item_Name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "Item_Name",
                        e.target.value,
                        filters["Item_Name"]
                          ? filters["Item_Name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["jt_WO_QT"] ? filters["jt_WO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_WO_QT",
                        e.target.value,
                        filters["jt_WO_QT"]
                          ? filters["jt_WO_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td >
                  <input
                    type="text"
                    value={filters["jt_TR_UM"] ? filters["jt_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_TR_UM",
                        e.target.value,
                        filters["jt_TR_UM"]
                          ? filters["jt_TR_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["jt_LO_CN"] ? filters["jt_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_LO_CN",
                        e.target.value,
                        filters["jt_LO_CN"] ? filters["jt_LO_CN"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_TO_LO"] ? filters["jt_TO_LO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_TO_LO",
                        e.target.value,
                        filters["jt_TO_LO"] ? filters["jt_TO_LO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_TR_DT"] ? filters["jt_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_TR_DT",
                        e.target.value,
                        filters["jt_TR_DT"] ? filters["jt_TR_DT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["jt_CO_QT"] ? filters["jt_CO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_CO_QT",
                        e.target.value,
                        filters["jt_CO_QT"] ? filters["jt_CO_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_RL_QT"] ? filters["jt_RL_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_RL_QT",
                        e.target.value,
                        filters["jt_RL_QT"] ? filters["jt_RL_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_BL_QT"] ? filters["jt_BL_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_BL_QT",
                        e.target.value,
                        filters["jt_BL_QT"] ? filters["jt_BL_QT"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_TR_UM"] ? filters["jt_TR_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_TR_UM",
                        e.target.value,
                        filters["jt_TR_UM"] ? filters["jt_TR_UM"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jt_AK_PS"] ? filters["jt_AK_PS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jt_AK_PS",
                        e.target.value,
                        filters["jt_AK_PS"] ? filters["jt_AK_PS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["ack_DESC"] ? filters["ack_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "ack_DESC",
                        e.target.value,
                        filters["ack_DESC"] ? filters["ack_DESC"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
             
              
             
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>   
                <th style={tableHeaderStyle}>Bag No</th> 
                <th style={tableHeaderStyle}>Item Number</th> 
                <th style={tableHeaderStyle}>Description</th> 
                <th style={tableHeaderStyle}>Order Quantity</th> 
                <th style={tableHeaderStyle}>UM</th>  
                <th style={tableHeaderStyle}>From Location</th> 
                <th style={tableHeaderStyle}>To Location</th> 
                <th style={tableHeaderStyle}>Transfer Date	</th>  
                <th style={tableHeaderStyle}>Transffered Quantity</th> 
                <th style={tableHeaderStyle}>Received Quantity</th> 
                <th style={tableHeaderStyle}>Balance Quantity</th> 
                <th style={tableHeaderStyle}>UM</th> 
                <th style={tableHeaderStyle}>Ack. Person</th> 
                <th style={tableHeaderStyle}>Name</th>         
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.jtUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.jtUkNo}
                        checked={selectedRows.includes(rowData.jtUkNo)}
                        onChange={() => handleRowSelect(rowData.jtUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.jt_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.jt_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.jt_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.jt_BG_NO}</td>
                    <td style={tableCellStyle}>{rowData.jt_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.item_NAME}</td>
                    <td style={tableCellStyle}>{rowData.jt_WO_QT}</td>
                    <td style={tableCellStyle}>{rowData.jt_TR_UM}</td>   
                    <td style={tableCellStyle}>{rowData.jt_LO_CN}</td>  
                    <td style={tableCellStyle}>{rowData.jt_TO_LO}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.jt_TR_DT)}</td>
                    <td style={tableCellStyle}>{rowData.jt_CO_QT}</td>   
                    <td style={tableCellStyle}>{rowData.jt_RL_QT}</td>
                    <td style={tableCellStyle}>{rowData.jt_BL_QT}</td>    
                    <td style={tableCellStyle}>{rowData.jt_TR_UM}</td> 
                    <td style={tableCellStyle}>{rowData.jt_AK_PS}</td>   
                    <td style={tableCellStyle}>{rowData.ack_DESC}</td> 
         



                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <JewelleryAcknowledgementDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        JewelleryAcknowledgementTable={JewelleryAcknowledgementTable}
        setJewelleryAcknowledgementTable={setJewelleryAcknowledgementTable}

        SelectedAcknowledgingPerson={SelectedAcknowledgingPerson}
        setSelectedAcknowledgingPerson={setSelectedAcknowledgingPerson}
        SelectedTransferPerson={SelectedTransferPerson}
        setSelectedTransferPerson={setSelectedTransferPerson}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        jt_OR_CO={jt_OR_CO}
        setjt_OR_CO={setjt_OR_CO}
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        jt_BG_NO={jt_BG_NO}
        setjt_BG_NO={setjt_BG_NO}
      />
    </div>
  );
};

export default JewelleryAcknowledgementMain;
