import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Container, Row, Col } from 'react-bootstrap';
import moment from "moment";
import axios from "axios";
import { Calendar } from "primereact/calendar";
import ButtonStyles from "views/common/ButtonStyles";
export default function FiscalDatePatternDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  fy_CO_NO,
  
}) {
  const {
    fyUkNo,
    fy_YE_AR,
    fy_DT_TY,
    fy_BD_DT,
    fy_PN_01,
    fy_BD_01,
    fy_ED_01,
    fy_PN_02,
    fy_BD_02,
    fy_ED_02,
    fy_PN_03,
    fy_BD_03,
    fy_ED_03,
    fy_PN_04,
    fy_BD_04,
    fy_ED_04,
    fy_PN_05,
    fy_BD_05,
    fy_ED_05,
    fy_PN_06,
    fy_BD_06,
    fy_ED_06,
    fy_PN_07,
    fy_BD_07,
    fy_ED_07,
    fy_PN_08,
    fy_BD_08,
    fy_ED_08,
    fy_PN_10,
    fy_BD_10,
    fy_ED_10,
    fy_PN_09,
    fy_BD_09,
    fy_ED_09,
    fy_PN_11,
    fy_BD_11,
    fy_ED_11,
    fy_PN_12,
    fy_BD_12,
    fy_ED_12,    
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [fy_YE_AROptions, setfy_YE_AROptions] = useState([]);
  const [fy_DT_TYOptions, setfy_DT_TYOptions] = useState([]);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  // Year
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
  // Financial Year
  const parameter3 = "FO/YT";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfy_YE_AROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfy_DT_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const isUpdateMode = Boolean(fyUkNo);
  // const dialogHeader = (
  //   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  //     <h5>{fyUkNo ? "Revise Fiscal Date Pattern" : "Add Fiscal Date Pattern"}</h5>
  //     <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
  //       <Button
  //         style={savestyle}
  //         icon="pi pi-check"
  //         onClick={handleFormSubmit} 
  //          tooltip="Save"
  //       />
  //       <Button
  //         style={cancelstyle}
  //         icon="pi pi-times"
  //         onClick={handleClose}
  //         tooltip="Cancel"
  //       />
  //     </div>
  //   </div>
  // );
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      {/* Header Title on the left */}
      <h5 style={{ marginRight: 'auto' }}>
        {fyUkNo ? "Revise Fiscal Date Pattern" : "Add Fiscal Date Pattern"}
      </h5>
      
      {/* Button container on the right */}
      <div style={{
        display: 'flex', 
        justifyContent: 'flex-start',
        width: '65%' 
      }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="fy_DT_TY" className="font-bold">
                Type
              </label>
              <Dropdown
                name="fy_DT_TY"
                value={fy_DT_TY}
                options={fy_DT_TYOptions}
                onChange={(e) => onchangeDropDownFormData2("fy_DT_TY", e.value)}
                placeholder="Select Type"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="fy_YE_AR" className="font-bold">
                Year
              </label>
              <Dropdown
                name="fy_YE_AR"
                value={fy_YE_AR}
                options={fy_YE_AROptions}
                onChange={(e) => onchangeDropDownFormData1("fy_YE_AR", e.value)}
                placeholder="Select Year"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="fy_BD_DT" className="font-bold">
                FY Beginning Date
              </label>
              <Calendar
                id="fy_BD_DT"
                name="fy_BD_DT"
                value={moment(data.fy_BD_DT, "YYYYMMDD").isValid() ? moment(data.fy_BD_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "fy_BD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="fy_CO_NO" className="font-bold">
              Company 
              </label>
              <InputText disabled
                id="fy_CO_NO"
                name="fy_CO_NO"
                value={fy_CO_NO}
                readOnly
              />
            </div>
          </div>
        </div>
        <div style={inboxstyle}>
          <Container style={{width:"134%"}}>
            <Row>
              <Col md={3}>
                  <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                    Period
                  </label>
              </Col>
              <Col md={3}>
                    <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                      Beginning Date
                    </label>
              </Col>
              <Col md={3}>
                    <label htmlFor="vertical" className="font-bold" style={{marginTop:"10px"}}>
                      End Date
                    </label>
              </Col>
            </Row>
            {/* 2nd */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_01"
                    name="fy_PN_01"
                    value={1}
                  readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_01"
                  name="fy_BD_01"
                  value={moment(data.fy_BD_01, "YYYYMMDD").isValid() ? moment(data.fy_BD_01, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_01", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_01"
                  name="fy_ED_01"
                  value={moment(data.fy_ED_01, "YYYYMMDD").isValid() ? moment(data.fy_ED_01, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_01", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 3rd */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_02"
                    name="fy_PN_02"
                    value={2}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_02"
                  name="fy_BD_02"
                  value={moment(data.fy_BD_02, "YYYYMMDD").isValid() ? moment(data.fy_BD_02, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_02", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_ED_02"
                  name="fy_ED_02"
                  value={moment(data.fy_ED_02, "YYYYMMDD").isValid() ? moment(data.fy_ED_02, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_02", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 4th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_03"
                    name="fy_PN_03"
                    value={3}
                    readOnly
                  />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_BD_03"
                  name="fy_BD_03"
                  value={moment(data.fy_BD_03, "YYYYMMDD").isValid() ? moment(data.fy_BD_03, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_03", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_03"
                  name="fy_ED_03"
                  value={moment(data.fy_ED_03, "YYYYMMDD").isValid() ? moment(data.fy_ED_03, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_03", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 5th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_04"
                    name="fy_PN_04"
                    value={4}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_04"
                  name="fy_BD_04"
                  value={moment(data.fy_BD_04, "YYYYMMDD").isValid() ? moment(data.fy_BD_04, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_04", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_04"
                  name="fy_ED_04"
                  value={moment(data.fy_ED_04, "YYYYMMDD").isValid() ? moment(data.fy_ED_04, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_04", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 6th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_05"
                    name="fy_PN_05"
                    value={5}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_05"
                  name="fy_BD_05"
                  value={moment(data.fy_BD_05, "YYYYMMDD").isValid() ? moment(data.fy_BD_05, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_05", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_05"
                  name="fy_ED_05"
                  value={moment(data.fy_ED_05, "YYYYMMDD").isValid() ? moment(data.fy_ED_05, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_05", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 7th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_06"
                    name="fy_PN_06"
                    value={6}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_06"
                  name="fy_BD_06"
                  value={moment(data.fy_BD_06, "YYYYMMDD").isValid() ? moment(data.fy_BD_06, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_06", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_06"
                  name="fy_ED_06"
                  value={moment(data.fy_ED_06, "YYYYMMDD").isValid() ? moment(data.fy_ED_06, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_06", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>  
            {/*8th  */}
            <Row style={{marginTop:"2%"}} >
            <Col md={3}>
            <InputText disabled
                  id="fy_PN_07"
                  name="fy_PN_07"
                  value={7}
                  readOnly
                />
            </Col>
            <Col md={3}>
              <Calendar
                id="fy_BD_07"
                name="fy_BD_07"
                value={moment(data.fy_BD_07, "YYYYMMDD").isValid() ? moment(data.fy_BD_07, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "fy_BD_07", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </Col>
            <Col md={3}>
              <Calendar
                id="fy_ED_07"
                name="fy_ED_07"
                value={moment(data.fy_ED_07, "YYYYMMDD").isValid() ? moment(data.fy_ED_07, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "fy_ED_07", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              />
            </Col>
            </Row>
            {/* 9th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_08"
                    name="fy_PN_08"
                    value={8}
                    readOnly
                  />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_BD_08"
                  name="fy_BD_08"
                  value={moment(data.fy_BD_08, "YYYYMMDD").isValid() ? moment(data.fy_BD_08, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_08", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_08"
                  name="fy_ED_08"
                  value={moment(data.fy_ED_08, "YYYYMMDD").isValid() ? moment(data.fy_ED_08, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_08", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 10th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_09"
                    name="fy_PN_09"
                    value={9}
                    readOnly
                  />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_BD_09"
                  name="fy_BD_09"
                  value={moment(data.fy_BD_09, "YYYYMMDD").isValid() ? moment(data.fy_BD_09, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_09", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_ED_09"
                  name="fy_ED_09"
                  value={moment(data.fy_ED_09, "YYYYMMDD").isValid() ? moment(data.fy_ED_09, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_09", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 11th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_10"
                    name="fy_PN_10"
                    value={10}
                    readOnly
                  />
              </Col>
              <Col md={3}>
              <Calendar
                  id="fy_BD_10"
                  name="fy_BD_10"
                  value={moment(data.fy_BD_10, "YYYYMMDD").isValid() ? moment(data.fy_BD_10, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_10", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_10"
                  name="fy_ED_10"
                  value={moment(data.fy_ED_10, "YYYYMMDD").isValid() ? moment(data.fy_ED_10, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_10", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 12th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_11"
                    name="fy_PN_11"
                    value={11}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_11"
                  name="fy_BD_11"
                  value={moment(data.fy_BD_11, "YYYYMMDD").isValid() ? moment(data.fy_BD_11, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_11", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_11"
                  name="fy_ED_11"
                  value={moment(data.fy_ED_11, "YYYYMMDD").isValid() ? moment(data.fy_ED_11, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_11", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
            {/* 13th */}
            <Row style={{marginTop:"2%"}} >
              <Col md={3}>
              <InputText disabled
                    id="fy_PN_12"
                    name="fy_PN_12"
                    value={12}
                    readOnly
                  />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_BD_12"
                  name="fy_BD_12"
                  value={moment(data.fy_BD_12, "YYYYMMDD").isValid() ? moment(data.fy_BD_12, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_BD_12", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
              <Col md={3}>
                <Calendar
                  id="fy_ED_12"
                  name="fy_ED_12"
                  value={moment(data.fy_ED_12, "YYYYMMDD").isValid() ? moment(data.fy_ED_12, "YYYYMMDD").toDate() : null}
                  onChange={(e) => onchangedata({ target: {name: "fy_ED_12", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                  dateFormat="dd/mm/yy"
                />
              </Col>
            </Row>
          </Container>  
        </div>
      </div> 
    </Dialog>
  );
}
