import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function FinancialBudgetDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  fb_DO_CO,
  fb_CT_RY,
  fb_FI_YR,
  addRow,
  deleteRow,
  FinancialBudgettable,
  setFinancialBudgettable,
  account,
  am_OB_AC,
  setaccount,
  setam_OB_AC,
}) {
  const { 
  fbUkNo,
  fb_CO_CT,
  fb_OB_AC,
  fb_OB_SB,
  fb_AC_ID,
  fb_AC_DS,
  fb_LV_DT,
  fb_PE_CD,
  fb_BU_AM,
  fb_ST_CD,
  fb_PE_01,
  fb_PE_02,
  fb_PE_03,
  fb_PE_04,
  fb_PE_05,
  fb_PE_06,
  fb_PE_07,
  fb_PE_08,
  fb_PE_09,
  fb_PE_10,
  fb_PE_11,
  fb_PE_12,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [fb_CT_RYOptions, setfb_CT_RYOptions] = useState([]);
  const [fb_FI_YROptions, setfb_FI_YROptions] = useState([]);
  const [fb_ST_CDOptions, setfb_ST_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData3(name, value, index);
  };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CN/RY";
  const finalUrl1 = `${url}/${parameter1}`;
  // comp level
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
  // provider
  const parameter3 = "FA/ST";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_CT_RYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setfb_FI_YROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setfb_ST_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const handleOnChangeRate =async () => {
    const Url = `${domain}/api/AccountMaster/GetByAccountRange`;
    const requestData={
      "am_OB_AC":document.getElementById("am_OB_AC").value,
      "account":document.getElementById("account").value,
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataLead = response.data.Result;

      if (dataLead && dataLead.length > 0) {
        setFinancialBudgettable(dataLead.map(item => ({
          fbUkNo: item.amUkNo,
          fb_CO_CT: item.am_CO_NM,
          fb_OB_AC: item.am_OB_AC,
          fb_OB_SB: item.am_OB_SB,
          fb_AC_ID: item.am_AC_ID,
          fb_AC_DS: item.am_AC_DS,
          fb_LV_DT: item.am_AC_LD,
          fb_PE_CD: item.am_PE_CD,
        })));

      } else {
        console.error("Invalid API response:" );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleBudget =async (SelectedRowIndex) => {
    const Url = `${domain}/api/FinancialBudget/budgetChange`;
    const requestData={
      "cu_BU_AM":document.getElementById (`fb_BU_AM_${SelectedRowIndex}`).value
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        document.getElementById (`fb_PE_01_${SelectedRowIndex}`).value =  dataItMt.fb_PE_01;
        document.getElementById (`fb_PE_02_${SelectedRowIndex}`).value =  dataItMt.fb_PE_02;
        document.getElementById (`fb_PE_03_${SelectedRowIndex}`).value =  dataItMt.fb_PE_03;
        document.getElementById (`fb_PE_04_${SelectedRowIndex}`).value =  dataItMt.fb_PE_04;
        document.getElementById (`fb_PE_05_${SelectedRowIndex}`).value =  dataItMt.fb_PE_05;
        document.getElementById (`fb_PE_06_${SelectedRowIndex}`).value =  dataItMt.fb_PE_06;
        document.getElementById (`fb_PE_07_${SelectedRowIndex}`).value =  dataItMt.fb_PE_07;
        document.getElementById (`fb_PE_08_${SelectedRowIndex}`).value =  dataItMt.fb_PE_08;
        document.getElementById (`fb_PE_09_${SelectedRowIndex}`).value =  dataItMt.fb_PE_09;
        document.getElementById (`fb_PE_10_${SelectedRowIndex}`).value =  dataItMt.fb_PE_10;
        document.getElementById (`fb_PE_11_${SelectedRowIndex}`).value =  dataItMt.fb_PE_11;
        document.getElementById (`fb_PE_12_${SelectedRowIndex}`).value =  dataItMt.fb_PE_12;
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isUpdateMode = Boolean(fbUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{fbUkNo ? "Revise Financial Budget" : "Add Financial Budget"}</h5>
      <div style={{ display:'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%'}}>
      {isUpdateMode && (
        <div style={{ display: 'flex',width:"10%" }}>
          <Button 
            style={{ width:"50%", marginRight: "8px",backgroundColor:"transparent",color:"blue",border:'none'}}
            icon="pi pi-check-circle" 
            onClick={handleConfirm} 
            tooltip={"Confirm"}>
          </Button>
        </div>
        )}
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fb_CT_RY" className="font-bold">
              Century
              </label>
              <Dropdown
                id="fb_CT_RY"
                name="fb_CT_RY"
                value={fb_CT_RY}
                options={fb_CT_RYOptions}
                onChange={(e) => onchangeDropDownFormData1("fb_CT_RY", e.value)}
                placeholder="Select Century"
              />
            </div> 
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fb_FI_YR" className="font-bold">
            Financial Year
              </label>
              <Dropdown
                id="fb_FI_YR"
                name="fb_FI_YR"
                value={fb_FI_YR}
                options={fb_FI_YROptions}
                onChange={(e) => onchangeDropDownFormData2("fb_FI_YR", e.value)}
                placeholder="Select financial Year"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="fb_DO_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="fb_DO_CO"
                name="fb_DO_CO"
                value={fb_DO_CO}
                onChange={(e) => onchangedata(e)}
              />
            
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="am_OB_AC" className="font-bold">
              From Account
              </label>
              <InputText 
                id="am_OB_AC"
                name="am_OB_AC"
                value={am_OB_AC}
                onChange={(e) => { 
                  setam_OB_AC(e.target.value); 
                  handleOnChangeRate(); 
                  // handlePeriod();
                }}
              />
            
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="account" className="font-bold">
              To Account
              </label>
              <InputText 
                id="account"
                name="account"
                value={account}
                onChange={(e) => { 
                  setaccount(e.target.value); 
                  handleOnChangeRate(); 
                  // handlePeriod();
                }}
              />
            
            </div>
          </div>
        </div>
        {/* table */}
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="FinancialBudgettable" style={{overflow:"auto"}}>
        <thead>
          <tr>
            <th style={{ display: "none" }} >UK_NO</th>
            <th>Cost Center</th>
            <th>Account</th>
            <th>Subsidiary</th>
            <th>Account ID</th>
            <th>Account Description</th>
            <th>Level </th>
            <th>Post Code</th>
            <th>Budget Amount</th>
            <th>Status</th>
            <th>Period 01</th>
            <th>Period 02</th>
            <th>Period 03</th>
            <th>Period 04</th>
            <th>Period 05</th>
            <th>Period 06</th>
            <th>Period 07</th>
            <th>Period 08</th>
            <th>Period 09</th>
            <th>Period 10</th>
            <th>Period 11</th>
            <th>Period 12</th>
          </tr>
        </thead>
        <tbody>
        {FinancialBudgettable.map((row, index) => (
                <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`fbUkNo_${index}`}
                name={`fbUkNo_${index}`}
                defaultValue={row.fbUkNo}
              />
            </td>
            <td>
            <InputText disabled style={{width:"90px"}}
              id={`fb_CO_CT_${index}`}
              name={`fb_CO_CT_${index}`}
              defaultValue={row.fb_CO_CT}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"90px"}}
              id={`fb_OB_AC_${index}`}
              name={`fb_OB_AC_${index}`}
              defaultValue={row.fb_OB_AC}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"90px"}}
              id={`fb_OB_SB_${index}`}
              name={`fb_OB_SB_${index}`}
              defaultValue={row.fb_OB_SB}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_AC_ID_${index}`}
              name={`fb_AC_ID_${index}`}
              defaultValue={row.fb_AC_ID}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"140px"}}
              id={`fb_AC_DS_${index}`}
              name={`fb_AC_DS_${index}`}
              defaultValue={row.fb_AC_DS}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"90px"}}
              id={`fb_LV_DT_${index}`}
              name={`fb_LV_DT_${index}`}
              defaultValue={row.fb_LV_DT}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"90px"}}
              id={`fb_PE_CD_${index}`}
              name={`fb_PE_CD_${index}`}
              defaultValue={row.fb_PE_CD}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText style={{width:"120px"}}
              id={`fb_BU_AM_${index}`}
              name={`fb_BU_AM_${index}`}
              defaultValue={row.fb_BU_AM}
              onChange={(event) => {
                onchangedata(event);
                handleBudget(index); // Call handleBudget with the index parameter
              }}
            />
            </td>
              <td>
              <Dropdown
                id={`fb_ST_CD_${index}`}
                name={`fb_ST_CD_${index}`}
                value={data[`fb_ST_CD_${index}`] ? data[`fb_ST_CD_${index}`]:row.fb_ST_CD }
                options={fb_ST_CDOptions}
                onChange={(e) => handleFormDataChange(`fb_ST_CD_${index}`, e.value)}
                placeholder="Select Status"
              />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_01_${index}`}
              name={`fb_PE_01_${index}`}
              defaultValue={row.fb_PE_01}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_02_${index}`}
              name={`fb_PE_02_${index}`}
              defaultValue={row.fb_PE_02}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_03_${index}`}
              name={`fb_PE_03_${index}`}
              defaultValue={row.fb_PE_03}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_04_${index}`}
              name={`fb_PE_04_${index}`}
              defaultValue={row.fb_PE_04}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_05_${index}`}
              name={`fb_PE_05_${index}`}
              defaultValue={row.fb_PE_05}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_06_${index}`}
              name={`fb_PE_06_${index}`}
              defaultValue={row.fb_PE_06}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_07_${index}`}
              name={`fb_PE_07_${index}`}
              defaultValue={row.fb_PE_07}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_08_${index}`}
              name={`fb_PE_08_${index}`}
              defaultValue={row.fb_PE_08}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_09_${index}`}
              name={`fb_PE_09_${index}`}
              defaultValue={row.fb_PE_09}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_10_${index}`}
              name={`fb_PE_10_${index}`}
              defaultValue={row.fb_PE_10}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_11_${index}`}
              name={`fb_PE_11_${index}`}
              defaultValue={row.fb_PE_11}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            <td>
            <InputText disabled style={{width:"110px"}}
              id={`fb_PE_12_${index}`}
              name={`fb_PE_12_${index}`}
              defaultValue={row.fb_PE_12}
              onChange={(Event) => onchangedata(Event)}
            />
            </td>
            </tr>
            ))}
        </tbody>
      </table>   
      
      </div>
        </div>   
      </div>
    </Dialog>
  );
}
