import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
export default function ProcedureMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  FormData,
  setFormData,
  pm_PR_NM,
  setpm_PR_NM,
  pm_CO_NO,
}) {
  const {
    lmUkNo,
    pm_PR_CD,
    pm_PR_DS,
    pm_CH_RG,
    pm_SU_NM,

  } = data;
  const {borderbox,inboxstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [pm_PR_NMOptions, setpm_PR_NMOptions] = useState([]);


  const procedureDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_PR_NM(value);
  };
  



  useEffect(() => {
    procedure();

  }, []);

  const procedure = async () => {
    const Options = await fetchClassCodeDS("PR/NM");
    setpm_PR_NMOptions(Options);
  };



const isUpdateMode = Boolean(lmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={lmUkNo ? "Revise Procedure Master" : "Add Procedure Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_PR_CD" className="font-bold">
                    Procedure Code
                    </label>
                    <InputText 
                      id="pm_PR_CD"
                      name="pm_PR_CD"
                      value={pm_PR_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_PR_NM" className="font-bold">
                    Procedure Name
                    </label>
                    <Dropdown
                    name="pm_PR_NM"
                    value={pm_PR_NM}
                    options={pm_PR_NMOptions}
                    onChange={(e) => procedureDropdown("pm_PR_NM", e.value)}
                    placeholder="Select Procedure Name"
                  />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="pm_CO_NO"
                      name="pm_CO_NO"
                      value={pm_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_SU_NM" className="font-bold">
                    Procedure SubName
                    </label>
                    <InputText
                      id="pm_SU_NM"
                      name="pm_SU_NM"
                      value={pm_SU_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_PR_DS" className="font-bold">
                   Description
                    </label>
                    <InputText
                      id="pm_PR_DS"
                      name="pm_PR_DS"
                      value={pm_PR_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="pm_CH_RG" className="font-bold">
                   Charges
                    </label>
                    <InputText
                      id="pm_CH_RG"
                      name="pm_CH_RG"
                      value={pm_CH_RG}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
            </div>
      </div>
    </Dialog>
  );
}
