import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import EquipmentPopup from "../Popups/EquipmentPopup";
import CashPopup from "./CashPopup";
import CardPopup from "./CardPopup";
import CouponPopup from "./CouponPopup";
import CreditPopup from "./CreditPopup";
import OkPopup from "./OkPopup";
export default function HospitalPaymentDetailsDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,onchangeFormData,
  FormData,
  setFormData,
  pr_RG_ST,
  setpr_RG_ST,
  SelectedEQNO,
  setSelectedEQNO,
  SelectedSTNO,
  setSelectedSTNO,
  SelectedCUSTNO,
  setSelectedCUSTNO,
  SelectedDLNO,
  setSelectedDLNO,
  SelectedITNM,
  setSelectedITNM,
  SelectedASNM,
  setSelectedASNM,
  onchangeDropDownFormData3,
  sp_TR_CR,
  PaymentTableData,
  setPaymentTableData,
  sp_BS_CR,
  hb_CO_NO
}) {
  const {
    hbUkNo,
    hb_PA_ID,
   // hb_CO_NO,
    hb_VI_NO,
    hb_LB_AM,
    hb_CN_AM,
    hb_OT_AM,
    hb_MS_AM,
    hb_CO_AM,
    hb_BL_AM,
    hb_PR_AM,
    hb_DS_AM,
    hb_TO_AM,
    pr_EQ_NM,
    pr_ST_NO,
    pr_CU_NO,
    pr_DE_AL,
    pr_SR_NO,
    pr_IT_NM,
    pr_PD_MD,
    pr_PD_FM,
    pr_LT_NO,
    pr_IN_DT,
    pr_SE_DT,
    pr_AQ_DT,
    pr_AS_NO,
    pr_EQ_DS,
    PAID_BALANCE,
    PAID_TOTAL,
    finalDesc,
   // sp_BS_CR,
    sp_EX_RT,
    hb_VI_DT,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [pr_RG_STOptions, setpr_RG_STOptions] = useState([]);
// BIDDING type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpr_RG_ST(value);
  };
  useEffect(() => {
    fetchDropdownRGST();
    fetchDropdownCR();
  }, []);
  const fetchDropdownRGST = async () => {
    const Options = await fetchClassCodeDS("EQ/RS");
    setpr_RG_STOptions(Options);
  };

  const fetchDropdownCR = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setsp_TR_CROptions(Options);
  };

  const [CashPopupVisible, setCashPopupVisible] = useState(false);
const [CardPopupVisible, setCardPopupVisible] = useState(false);
const [CouponPopupVisible, setCouponPopupVisible] = useState(false);
const [CreditPopupVisible, setCreditPopupVisible] = useState(false);
const [OkPopupVisible, setOkPopupVisible] = useState(false);
const openCashPopup = () => {
  setCashPopupVisible(true);
};
const openCardPopup = () => {
  setCardPopupVisible(true);
};
const openCouponPopup = () => {
  setCouponPopupVisible(true);
};
const openCreditPopup = () => {
  setCreditPopupVisible(true);
};
const openOkPopup = () => {
  setOkPopupVisible(true);
};
// advance searchs
const [EQnoPopupVisible, setEQnoPopupVisible] = useState(false);
const [SitemNoPopupVisible, setSitemNoPopupVisible] =useState(false);
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const [DealerPopupVisible, setDealerPopupVisible] =useState(false);
const [ItemPopupVisible, setItemPopupVisible] =useState(false);
const [AccessorPopupVisible, setAccessorPopupVisible] =useState(false);
const [addressTypecust, setaddressTypecust] = useState("C");
const [addressTypesite, setaddressTypesite] = useState("F");
const [addressTypedelaer, setaddressTypedelaer] = useState("S");
const [addressTypeaccessor, setaddressTypeaccessor] = useState("E");
const [showSearchIcon, setShowSearchIcon] = useState(false);
const [discountVisible, setdiscountVisible] = useState("");
const [sp_TR_CROptions, setsp_TR_CROptions] = useState([]);
const [selecttotal, setselecttotal] = useState("");

const token = localStorage.getItem("token");
const company = localStorage.getItem("company");
const domain = localStorage.getItem("domain");

const openEqNoPopup = () => {
  setEQnoPopupVisible(true);
};
const handleDataSelectEqNO = async (data) => {
  setSelectedEQNO(data.em_EQ_DS);
  onchangeFormData("pr_EQ_DS", data.em_EQ_DS);
  // onchangeFormData("pr_EQ_NM", data.em_EQ_NM);
  document.getElementById("pr_EQ_NM").value = data.em_EQ_NM;
  const Url = `${domain}/api/EquipmentMaster/GetByEquipmentName`;
const requestData={
  "em_EQ_NM":data.em_EQ_NM
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });
  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }

  const dataEQ = response.data.result;

  if (dataEQ ) {
    document.getElementById("pr_ST_NO").value = dataEQ.em_ST_NO;
    document.getElementById("ab_AD_NM").value = dataEQ.site_NAME;
    document.getElementById("pr_CU_NO").value = dataEQ.em_CU_NO;
    document.getElementById("SelectedCUSTNO").value = dataEQ.customer_NAME;
    document.getElementById("pr_DE_AL").value = dataEQ.em_DE_AL;
    document.getElementById("SelectedDLNO").value = dataEQ.delaer_NAME;
    document.getElementById("pr_SR_NO").value = dataEQ.em_SR_NO;
    document.getElementById("pr_IT_NM").value = dataEQ.em_IT_NM;
    document.getElementById("im_IT_DS").value = dataEQ.item_NAME;
    document.getElementById("pr_PD_MD").value = dataEQ.em_PD_MD;
    document.getElementById("pr_PD_FM").value = dataEQ.em_PD_FM;
    document.getElementById("pr_IN_DT").value = dataEQ.em_IN_DT; 
    document.getElementById("pr_SE_DT").value = dataEQ.em_SE_DT;
    onchangeFormData("pr_AQ_DT", dataEQ.em_AQ_DT);
    document.getElementById("pr_AS_NO").value = dataEQ.em_AS_NO;
    document.getElementById("SelectedASNM").value = dataEQ.accessor_NAME;
    onchangeDropDownFormData("pr_RG_ST", dataEQ.em_RG_ST);
  } else {
    console.error("Invalid API response:", data);
  }
} catch (error) {
  console.error("Error fetching data:", error);
}
  setEQnoPopupVisible(false);
  setShowSearchIcon(false);
};
const openSitePopup = () => {
  setSitemNoPopupVisible(true);
};
const handleDataSelectSite = (data) => {
  setSelectedSTNO(data.ab_AD_NM);
  document.getElementById("pr_ST_NO").value = data.abAdNo;
  setSitemNoPopupVisible(false);
  setShowSearchIcon(false);
};
const openCustomerPopup = () => {
  setCustomerPopupVisible(true);
};
const handleDataSelectCust = (data) => {
  setSelectedCUSTNO(data.ab_AD_NM);
  document.getElementById("pr_CU_NO").value = data.abAdNo;
  setCustomerPopupVisible(false);
  setShowSearchIcon(false);
};
const openDealerPopup = () => {
  setDealerPopupVisible(true);
};
const handleDataSelectDealer = (data) => {
  setSelectedDLNO(data.ab_AD_NM);
  document.getElementById("pr_DE_AL").value = data.abAdNo;
  setDealerPopupVisible(false);
  setShowSearchIcon(false);
};
const openItemPopup = () => {
  setItemPopupVisible(true);
};
const handleDataSelectItem = (Result) => {
  setSelectedITNM(Result.im_IT_DS);
  document.getElementById("pr_IT_NM").value = Result.im_IT_NM;
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
const openAccessorPopup = () => {
  setAccessorPopupVisible(true);
};
const handleDataSelectAccessor = (data) => {
  setSelectedASNM(data.ab_AD_NM);
  document.getElementById("pr_AS_NO").value = data.abAdNo;
  setAccessorPopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(hbUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{hbUkNo ? "Revise Billing Details" : "Add Billing Details"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

// payment table
const [Cashsp_TR_CR, setCashsp_TR_CR ] = useState(sp_BS_CR);
function handlebal (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CASH",
          sp_PY_AM: document.getElementById("CashPaid_amt").value,
          sp_TR_CR: Cashsp_TR_CR,
          sp_EX_RT: document.getElementById("CashExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CashPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CashExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };

  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
   // setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// Card
const [Cardsp_TR_CR, setCardsp_TR_CR ] = useState(sp_BS_CR);
function handlebalCard (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "CARD",
          sp_PY_AM: document.getElementById("CardPaid_amt").value,
          sp_TR_CR: Cardsp_TR_CR,
          sp_EX_RT: document.getElementById("CardExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CardPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CardExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    //setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
// credit
const [Creditsp_TR_CR, setCreditsp_TR_CR ] = useState(sp_BS_CR);
function handlebalCredit (){
  var inputIndex = document.getElementById("PaymentTable").tBodies[0].rows.length;
      var itemIndex = inputIndex - 1;
      const item = document.getElementById(`sp_PY_MT_${itemIndex}`).value;

      setPaymentTableData(prevData => {
      const updatedData = [...prevData];
      if(item){
        updatedData.push({
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        });
      } else {
        updatedData[itemIndex] = {...updatedData[itemIndex],
          sp_PY_MT: "Credit",
          sp_PY_AM: document.getElementById("CreditPaid_amt").value,
          sp_TR_CR: Creditsp_TR_CR,
          sp_EX_RT: document.getElementById("CreditExchangeRate").value,
        };
      }
      return updatedData;
    });

    const salesDetail = [];
    const newRow = {
      payAmount: document.getElementById("CreditPaid_amt").value,
      grossAmount: document.getElementById("TOTAL").value,
      exchangeRate: document.getElementById("CreditExchangeRate").value
    }
    salesDetail.push(newRow);

    const table = document.getElementById("PaymentTable");
    const totalRowCount = table.tBodies[0].rows.length;
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        payAmount: document.getElementById("sp_PY_AM_" + i).value,
        grossAmount: document.getElementById("TOTAL").value,
        exchangeRate: document.getElementById("sp_EX_RT_" + i).value
      };
      salesDetail.push(Details);
    }
    const BalInput = {
      salesDetail,
    };
  axios.post(`${domain}/api/HospitalitySales/GetCalculatePaidTotal`, BalInput, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((resp) => {
    const balanceResult = resp.data;
    document.getElementById("PAID_BALANCE").value = balanceResult.balance;
    document.getElementById("PAID_TOTAL").value = balanceResult.paid;
    //setselectPaidtotal(balanceResult.paid);
  })
  .catch((error) => {
    console.error("Error saving data:", error);
  });
};
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
            <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_PA_ID" className="font-bold">
                  Patient ID
                  </label>
                  <InputText
                  id="hb_PA_ID"
                  name="hb_PA_ID"
                  defaultValue={hb_PA_ID}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText
                  id="hb_CO_NO"
                  name="hb_CO_NO"
                  defaultValue={hb_CO_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_VI_NO" className="font-bold">
                  Visit No
                  </label>
                  <InputText
                  id="hb_VI_NO"
                  name="hb_VI_NO"
                  defaultValue={hb_VI_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>   
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_VI_DT" className="font-bold">
                  Visit Date
                  </label>
                  <Calendar
                    id="hb_VI_DT"
                    name="hb_VI_DT"
                    value={moment(data.hb_VI_DT, "YYYYMMDD").isValid() ? moment(data.hb_VI_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangedata({ target: {name: "hb_VI_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_LB_AM" className="font-bold">
                  Lab Amount
                  </label>
                  <InputText
                  id="hb_LB_AM"
                  name="hb_LB_AM"
                  defaultValue={hb_LB_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_PR_AM" className="font-bold">
                  Procedure Amount
                  </label>
                  <InputText
                  id="hb_PR_AM"
                  name="hb_PR_AM"
                  defaultValue={hb_PR_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_CN_AM" className="font-bold">
                  Consumables Amount
                  </label>
                  <InputText
                  id="hb_CN_AM"
                  name="hb_CN_AM"
                  defaultValue={hb_CN_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_OT_AM" className="font-bold">
                  Other Charges
                  </label>
                  <InputText
                  id="hb_OT_AM"
                  name="hb_OT_AM"
                  defaultValue={hb_OT_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_MS_AM" className="font-bold">
                  Mis Charges
                  </label>
                  <InputText
                  id="hb_MS_AM"
                  name="hb_MS_AM"
                  defaultValue={hb_MS_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_CO_AM" className="font-bold">
                  Consulting Charges
                  </label>
                  <InputText
                  id="hb_CO_AM"
                  name="hb_CO_AM"
                  defaultValue={hb_CO_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_BL_AM" className="font-bold">
                  Bill Amount
                  </label>
                  <InputText
                  id="hb_BL_AM"
                  name="hb_BL_AM"
                  defaultValue={hb_BL_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_DS_AM" className="font-bold">
                  Discount Amount
                  </label>
                  <InputText
                  id="hb_DS_AM"
                  name="hb_DS_AM"
                  defaultValue={hb_DS_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="hb_TO_AM" className="font-bold">
                  Total Amount
                  </label>
                  <InputText
                  id="hb_TO_AM"
                  name="hb_TO_AM"
                  defaultValue={hb_TO_AM}
                  onChange={(Event) => onchangedata(Event)}
                />
                </div>
            </div>
             {/* row 4 */}
        <div className="row bordered-div">
          <div className="col-6 ">
          <div className="table-container d-flex flex-column">
          <div className="d-flex">
            <div style={{maxHeight:"220px",overflowY:"scroll"}}>
              <table className="table table-bordered flex-grow-1" id="PaymentTable">
                <thead>
                  <tr>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Exchange Rate</th>
                  </tr>
                </thead>
                <tbody>
                {PaymentTableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <InputText disabled
                        //style={{ width: "90px" }}
                        id={`sp_PY_MT_${index}`}
                        name={`sp_PY_MT_${index}`}
                        defaultValue={row.sp_PY_MT}
                      />
                      </td>
                    <td>
                      <InputText disabled
                        //style={{ width: "70px" }}
                        id={`sp_PY_AM_${index}`}
                        name={`sp_PY_AM_${index}`}
                        defaultValue={row.sp_PY_AM}
                      />
                    </td>
                    <td>
                      <Dropdown disabled
                          id={`sp_TR_CR_${index}`}
                          name={`sp_TR_CR_${index}`}
                          value={data[`sp_TR_CR_${index}`] ? data[`sp_TR_CR_${index}`] : row.sp_TR_CR} 
                          options={sp_TR_CROptions}
                          onChange={(e) =>onchangeDropDownFormData3(`sp_TR_CR_${index}`, e.value)}
                          placeholder="Select Currency"
                        />
                    </td>
                    <td >
                      <InputText disabled
                        s//tyle={{ width: "70px" }}
                        id={`sp_EX_RT_${index}`}
                        name={`sp_EX_RT_${index}`}
                        defaultValue={row.sp_EX_RT}
                      />
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            <div className="button-container" style={{width:"20%"}}>
              <div className="d-flex flex-column" style={{width:"12rem"}}>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCashPopup}> Cash </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCardPopup}> Card </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCouponPopup}> Coupon </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openCreditPopup}> Credit </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={openOkPopup}> OK </button>
                <button className="btn btn-primary w-50 ml-3 mb-1" onClick={handleClose}> Cancel </button>
              </div>
            </div>
          </div>
          <div className="table table-bordered mt-3 d-flex justify-content-between align-items-center p-1 w-75">
            <label htmlFor="PAID_TOTAL" className="font-bold">
              Total
            </label>
            <InputText disabled
              style={{width:"10rem"}}
              id="PAID_TOTAL"
              name="PAID_TOTAL"
              defaultValue={PAID_TOTAL}
              onChange={(e) => onchangedata(e)}
            />
            <label htmlFor="PAID_BALANCE" className="font-bold">
              Balance
            </label>
            <InputText disabled
              style={{width:"10rem"}}
              id="PAID_BALANCE"
              name="PAID_BALANCE"
              defaultValue={PAID_BALANCE}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          </div>
          </div>
          <div className="col-2 ">
            {/* Placeholder for Discount */}
           {/* <div style={{  marginTop: "1rem",  padding: "1rem",   border: "1px solid #ddd",  borderRadius: "8px",
                height:  "20rem", // Fixed height when visible
                overflowY: discountVisible ? "auto" : "hidden", // Enable vertical scrolling
                transition: "height 0.3s ease",
              }}>
                {discountVisible && (
                <div style={{ maxHeight: "140px", overflowY: "auto" }} >
                 {Array.isArray(finalDesc) ? (
                   finalDesc.map((line, index) => (
                <p key={index}>{line}</p>
                    ))
                  ): (  <p> </p> )}
              </div> )}
            </div> */}
          </div>
        </div>
        </div>
      </div>
      {/* equipment */}
      <EquipmentPopup
        visible={EQnoPopupVisible}
        onClose={() => setEQnoPopupVisible(false)}
        onDataSelect={handleDataSelectEqNO}
        formData={data}
      />
      {/* site */}
      <AddressBookPopup
        visible={SitemNoPopupVisible}
        onClose={() => setSitemNoPopupVisible(false)}
        onDataSelect={handleDataSelectSite}
        formData={data}
        addressType={addressTypesite}
        setFormData={setFormData}
      />
      {/* custoemr */}
        <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelectCust}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* dealer */}
        <AddressBookPopup
        visible={DealerPopupVisible}
        onClose={() => setDealerPopupVisible(false)}
        onDataSelect={handleDataSelectDealer}
        formData={data}
        addressType={addressTypedelaer}
        setFormData={setFormData}
      />
      {/* accessor */}
        <AddressBookPopup
        visible={AccessorPopupVisible}
        onClose={() => setAccessorPopupVisible(false)}
        onDataSelect={handleDataSelectAccessor}
        formData={data}
        addressType={addressTypeaccessor}
        setFormData={setFormData}
      />
      {/* item */}
       <ItemPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />

       {/* payment popups */}
       <CashPopup
        visible={CashPopupVisible}
        onClose={() => setCashPopupVisible(false)}
        formData={data}
        grossAmount={hb_TO_AM}
        paidTotal={PAID_TOTAL}
        sp_BS_CR={sp_BS_CR}
        sp_EX_RT={sp_EX_RT}
        hb_VI_DT={hb_VI_DT}
        handlebal={handlebal}
        Cashsp_TR_CR={Cashsp_TR_CR}
        setCashsp_TR_CR={setCashsp_TR_CR}
      />
      <CardPopup
        visible={CardPopupVisible}
        onClose={() => setCardPopupVisible(false)}
        formData={data}
        grossAmount={hb_TO_AM}
        paidTotal={PAID_TOTAL}
        sp_BS_CR={sp_BS_CR}
        sp_EX_RT={sp_EX_RT}
        hb_VI_DT={hb_VI_DT}
        handlebalCard={handlebalCard}
        Cardsp_TR_CR={Cardsp_TR_CR}
        setCardsp_TR_CR={setCardsp_TR_CR}
      />
       <CouponPopup
        visible={CouponPopupVisible}
        onClose={() => setCouponPopupVisible(false)}
      />
     <CreditPopup
        visible={CreditPopupVisible}
        onClose={() => setCreditPopupVisible(false)}
        formData={data}
        grossAmount={hb_TO_AM}
        paidTotal={hb_TO_AM}
        sp_BS_CR={sp_BS_CR}
        sp_EX_RT={sp_EX_RT}
        hb_VI_DT={hb_VI_DT}
        handlebalCredit={handlebalCredit}
        Creditsp_TR_CR={Creditsp_TR_CR}
        setCreditsp_TR_CR={setCreditsp_TR_CR}
      />
      <OkPopup
        visible={OkPopupVisible}
        onClose={() => setOkPopupVisible(false)}
      />
    </Dialog>
  );
}
