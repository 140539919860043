import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LeaseInformationDialog from "./LeaseInformationDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
import TerminatePopup from "./TerminatePopup";
const initialValue = {
  liLeNo: "",
  li_TY_PE: "",
  li_LE_CO: "",
  li_TE_NT:"",
  name:"",
  li_ST_DT:"",
  li_EN_DT:"",
  li_LE_ST:"",
  li_BL_NO:"",
  li_UN_TS:"",   
  li_RN_AR:"",    
  li_US_AR:"",
  li_SE_PA:"",
  li_UN_TY:"",
  li_UN_US:"",
  li_AN_RN:"",
  li_MN_RN:"",
  li_BS_RN:"",
  li_OT_RN:"",
  li_UN_GL:"",
  li_SE_PA:"",
  li_UN_TY:"",
  li_UN_US:"",
  li_LG_NO:"",
  li_PA_NO:"",
  li_UK_NO:"",
  LI_LE_MA:"",
  li_LE_TM:"",
  li_PR_NO:"",
  li_FL_NO:"",
  li_BL_TO:"",
  li_RN_TY:"",
  li_BL_ST:"",
  li_BL_FR:"",
  li_TX_AP:"",
  li_TX_PR:"",
  li_PY_TR:"",
  li_TR_DT:"",
  li_TR_RS:"",
  li_TR_PE:"",
};
const initialFilters = {
  liLeNo: { value: "", operation: "" },
  li_TY_PE: { value: "", operation: "" },
  li_LE_CO: { value: "", operation: "" },
  li_TE_NT: { value: "", operation: "" },
  name: { value: "", operation: "" },
  li_ST_DT: { value: "", operation: "" },
  li_EN_DT: { value: "", operation: "" },
  li_LE_ST: { value: "", operation: "" },
};
const LeaseInformationMain = () => {
  // terminate popup
  const [TerminatePopupVisible, setTerminatePopupVisible] = useState(false);
  const openTerminatePopup = () => {
    setTerminatePopupVisible(true);
  };

  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,poststyles,tableCellStyleH,tableHeaderStyleH
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [li_LE_ST, setli_LE_ST] = useState("");
  const [li_LE_TM, setli_LE_TM] = useState("");
  const [li_UN_TY, setli_UN_TY] = useState("");
  const [li_IN_US, setli_IN_US] = useState("");
  const [li_RN_TY, setli_RN_TY] = useState("");
  const [li_BL_ST, setli_BL_ST] = useState("");
  const [li_TX_AP, setli_TX_AP] = useState("");
  const [li_TX_PR, setli_TX_PR] = useState("");
  const [li_PR_NO, setli_PR_NO] = useState("");
  const [li_BL_NO, setli_BL_NO] = useState("");
  const [li_FL_NO, setli_FL_NO] = useState("");
  const [li_PY_TR, setli_PY_TR] = useState("");
  const [li_TR_CR, setli_TR_CR] = useState("");
  const [li_UN_TS, setli_UN_TS] = useState("");
  const [li_TE_NT, setli_TE_NT] =useState("");
  const [li_PA_NO, setli_PA_NO] =useState("");
  const [li_SE_PA, setli_SE_PA] =useState("");

  const [SelectedliTwNt, setSelectedliTwNt] = useState("");
  const [SelectedliPaNo, setSelectedliPaNo] = useState("");

  const [SelectedliSePa, setSelectedliSePa] = useState("");
  const [SelectedliUnTs, setSelectedliUnTs] = useState("");

  const [LeaseInformationtable, setResourceCompetencytable] = useState([{}]);
  const [liLeNo, setliLeNo] = useState("");
  const [PaymentTerm, setPaymentTerm] = useState(""); 

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setResourceCompetencytable([{}]);
    setli_LE_TM(null);
    // setli_UN_TY(null);
    setli_IN_US(null);
    setli_RN_TY(null);
    setli_BL_ST(null);
    setli_TX_AP(null);
    setli_TX_PR(null);
    setli_TR_CR(null);
    setli_PY_TR(null);
    setSelectedliTwNt(null);
    setSelectedliPaNo(null);
    setSelectedliSePa(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setResourceCompetencytable([{}]);
    setli_LE_TM(null);
    // setli_UN_TY(null);
    setli_IN_US(null);
    setli_RN_TY(null);
    setli_BL_ST(null);
    setli_TX_AP(null);
    setli_TR_CR(null);
    setli_TX_PR(null);
    setli_PY_TR(null);
    setSelectedliTwNt(null);
    setSelectedliPaNo(null);
    setSelectedliSePa(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setResourceCompetencytable([...LeaseInformationtable, {}]);
  };
  const deleteRow = () => {
    if (LeaseInformationtable.length > 0) {
      const updatedData = [...LeaseInformationtable];
      updatedData.pop();
      setResourceCompetencytable(updatedData);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/LeaseInformation/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          delete FormData.li_UK_NO;
          const LeaseInformationData = response.data;
          delete LeaseInformationData.li_UK_NO;
          delete LeaseInformationData.leaseInformations[0].liLeNo;
          for (let i = 0; i < LeaseInformationData.leaseInformations.length; i++) {
            delete LeaseInformationData.leaseInformations[i].li_UK_NO;
          }
          setResourceCompetencytable(LeaseInformationData.leaseInformations);
          setFormData(LeaseInformationData.leaseInformations[0]);
          setli_TR_CR(response.data.li_TR_CR);
          setli_LE_ST(response.data.li_LE_ST);
          setli_PY_TR(response.data.li_PY_TR);
          setli_LE_TM(response.data.li_LE_TM);
          setli_TE_NT(response.data.li_TE_NT);
          setSelectedliTwNt(response.data.teanat_NAME);
          setli_SE_PA(response.data.li_SE_PA);
          setSelectedliSePa(response.data.sales_NAME);
          setli_PA_NO(response.data.li_PA_NO);
          setSelectedliPaNo(response.data.payee_NAME);
          delete FormData.li_UK_NO;
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [li_LE_CO , setli_LE_CO] = useState("");
  const [li_LE_TY , setli_LE_TY] = useState("");
  const [li_BS_CR , setli_BS_CR] = useState("");
  const [li_HL_CD , setli_HL_CD] = useState("");
    const handleDefault = async () => {
      setli_LE_CO(`${company}`);
      const linkPath = String(window.location);
      const l_array = linkPath.split("=");
      const LinkId = l_array[1];
      
      const requestBody = {
        "version_ID" : LinkId
      };
      const paginationUrl = `${domain}/api/LinkId/Select`;
      try {
        const response = await axios.post(paginationUrl, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataDefault = response.data;
        const listDefault = [];
        var count = 0;
        if (dataDefault.versionIds != null) {
          for (let i = 0; i < dataDefault.versionIds.length; i++) {
            if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
              const fName = dataDefault.versionIds[i].field_ID;
              if(fName === "LI_LE_TY"){
                setli_LE_TY(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "LI_BS_CR"){
                setli_BS_CR(dataDefault.versionIds[i].default_VALUE)
              }
              if(fName === "LI_HL_CD"){
                setli_HL_CD(dataDefault.versionIds[i].default_VALUE)
              }
			        if(fName === "LI_LE_ST"){
                onchangeDropDownFormData1("li_LE_ST",dataDefault.versionIds[i].default_VALUE);
                setli_LE_ST(dataDefault.versionIds[i].default_VALUE);
              }
            }
            if(dataDefault.versionIds[i].field_TYPE === "LIST"){
              count++;
              const def = {
                "column" : dataDefault.versionIds[i].field_ID ,
                "value" : dataDefault.versionIds[i].default_VALUE,
                "operation" : "EQUAL"
              }
              listDefault.push(def);
            }
          }
          handleSearch(listDefault,count);
        } else {
          handleSearch(listDefault,count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/LeaseInformation/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });

    if(name === 'li_ST_DT'){
      const exData={
        "cr_TO_CR": document.getElementById("li_BS_CR").value, //base curr
        "cr_FR_CR": li_TR_CR, //currency
        "cr_EF_DT": value
      }
  
      exchangeRate (exData);
    }
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    if(name=="li_TE_NT") {
      setli_TE_NT(value);
    }
    if(name=="li_PA_NO") {
      setli_PA_NO(value);
    } 
    if(name=="li_SE_PA") {
      setli_SE_PA(value);
    } 
  };
  const onchangeDropDownFormData1 = (name, value) => {
    // setFormData({ ...FormData, [name]: value });
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setli_LE_ST(value);
  };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_LE_TM(value);
  };
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_UN_TY(value);
  };
  const onchangeDropDownFormData14 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    //setli_UN_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_IN_US(value);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_RN_TY(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_BL_ST(value);
  };
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_TX_AP(value);
  };
  const onchangeDropDownFormData8 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_TX_PR(value);
  };
  const onchangeDropDownFormData9 = (name, value,index=null) => {
    const fieldName=(FormData && FormData.li_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
    LeaseInformationtable[index][fieldName]=value;
  };
  const onchangeDropDownFormData10 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_BL_NO(value);
  };
  const onchangeDropDownFormData11 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_FL_NO(value);
  };
  const onChangeOfUnitSelect = (content) => {
    setli_PR_NO(content.ui_PR_NO);
    setli_BL_NO(content.ui_BL_NO);
    setli_FL_NO(content.ui_FL_NO);
    setli_UN_TS(content.ui_UN_NO);
  };
  const onchangeDropDownFormData12 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_PY_TR(value);
    setPaymentTerm(value);
  };
  const onchangeDropDownFormData13 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setli_TR_CR(value);

    const exData={
      "cr_TO_CR": document.getElementById("li_BS_CR").value, //base curr
      "cr_FR_CR": value, //currency
      "cr_EF_DT": FormData.li_ST_DT
    }

    exchangeRate (exData);
  };

  function exchangeRate (exData){
    axios
        .post(`${domain}/api/ExChangeRate/Rate`, exData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dataExRt = resp.data.Result;
          if (dataExRt) {
            document.getElementById("li_EX_RT").value = dataExRt.cr_CO_MR;
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  }
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/LeaseInformation/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const LeaseInformationData = response.data;
        setResourceCompetencytable(LeaseInformationData.leaseInformations);
        setFormData(LeaseInformationData.leaseInformations[0]);
        setli_TR_CR(response.data.li_TR_CR);
        setli_LE_ST(response.data.li_LE_ST);
        setli_PY_TR(response.data.li_PY_TR);
        setli_LE_TM(response.data.li_LE_TM);
        setli_TE_NT(response.data.li_TE_NT);
        setSelectedliTwNt(response.data.teanat_NAME);
        setli_SE_PA(response.data.li_SE_PA);
        setSelectedliSePa(response.data.sales_NAME);
        setli_PA_NO(response.data.li_PA_NO);
        setSelectedliPaNo(response.data.payee_NAME);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const Close = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }

    const requestBodyAPIInput = {
      "li_UK_NO" : `${selectedRows[0]}`,
    }
    axios
    .post(`${domain}/api/LeaseInformation/CloseLease`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const Terminate = async (formData,handleClose) => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    const requestBody = {
      "li_UK_NO" : `${selectedRows[0]}`,
      // "li_TR_DT" :  formData.li_TR_DT,
      // "li_TR_RS" :  formData.li_TR_RS,
      // "li_TR_PE" :  formData.li_TR_PE,


      "li_TE_NT" : formData.li_TE_NT,
      "li_ST_DT" : formData.li_ST_DT,
      "li_EN_DT" : formData.li_EN_DT,
      "li_EX_DT" : formData.li_EX_DT,
      "li_TR_BY" : formData.li_TR_BY,
      "li_TR_RS" : formData.li_TR_RS,
      "li_TR_PN" : formData.li_TR_PN,
      "li_TR_TX" : formData.li_TR_TX,
      "li_TR_AM" : formData.li_TR_AM,
    }
    try{
      const response = await axios.post(`${domain}/api/LeaseInformation/TerminateLease`, requestBody, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    handleResponse(response.data);
    handleClose(); // Close the popup
    } catch (error) {
    console.error("Error adding data:", error);
}
  };
  var requestBodyAPIInput = {};
  function values () {

const table = document.getElementById("LeaseInformationtable");
const totalRowCount1 = table.tBodies[0].rows.length;

const leaseInformations = [];
for (let i = 0; i < totalRowCount1; i++) {
  
  const Details = {
      li_UK_NO: document.getElementById("li_UK_NO_"+i).value,
      li_BL_NO: document.getElementById("li_BL_NO_"+i).value,
      li_UN_TS: document.getElementById("li_UN_TS_"+i).value,
      li_RN_AR: document.getElementById("li_RN_AR_"+i).value,
      li_US_AR: document.getElementById("li_US_AR_"+i).value,
      li_UN_GL: table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
      li_UN_TY: table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
      li_UN_US: table.tBodies[0].rows[i].cells[11].children[0].children[1].children[0].value,
      li_AN_RN: document.getElementById("li_AN_RN_"+i).value,
      li_MN_RN: document.getElementById("li_MN_RN_"+i).value,
      li_BS_RN: document.getElementById("li_BS_RN_"+i).value,
      li_OT_RN: document.getElementById("li_OT_RN_"+i).value,
      li_PR_NO: document.getElementById("li_PR_NO_"+i).value,
      li_FL_NO: document.getElementById("li_FL_NO_"+i).value,
      li_RN_TY: table.tBodies[0].rows[i].cells[16].children[0].children[1].children[0].value,
      li_BL_ST: table.tBodies[0].rows[i].cells[17].children[0].children[1].children[0].value,
      li_TX_AP: table.tBodies[0].rows[i].cells[18].children[0].children[1].children[0].value,
      li_TX_PR: table.tBodies[0].rows[i].cells[19].children[0].children[1].children[0].value,
  };
  leaseInformations.push(Details);
  }

  requestBodyAPIInput = {
    leaseInformations,
    li_UK_NO: FormData.li_UK_NO,
      liLeNo: FormData.liLeNo,
    li_HL_CD: document.getElementById("li_HL_CD").value,
    li_LE_TY: li_LE_TY,
    li_LE_CO: li_LE_CO,
    li_TE_NT: document.getElementById("li_TE_NT").value,
    li_PY_TR: li_PY_TR,
    li_LE_ST: li_LE_ST,
    li_TR_CR: li_TR_CR,
    li_EX_RT: document.getElementById("li_EX_RT").value,
    li_BS_CR: document.getElementById("li_BS_CR").value,
    li_SE_PA: document.getElementById("li_SE_PA").value,
    li_LG_NO: document.getElementById("li_LG_NO").value,
    li_PA_NO: document.getElementById("li_PA_NO").value,
    li_ST_DT: FormData.li_ST_DT,
    li_LE_TM: FormData.li_LE_TM,
    li_EN_DT: FormData.li_EN_DT,
  };

  };
  const handleFormSubmit = () => {
    values();
    if (FormData.liLeNo) {
    if (FormData.li_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/LeaseInformation/Update`;
      if (confirm) {
        axios.put(update, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/LeaseInformation/Add`, requestBodyAPIInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstHalf = Math.floor(currentYear / 100);
        const secondHalf = String(currentYear % 100).padStart(2, "0");
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${li_LE_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setliLeNo(resp.dn_DO_NO);
            FormData.liLeNo = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/LeaseInformation/Add`, requestBodyAPIInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        
      }
  };
  // confirmbutton
  const handleConfirm = () => {    
    values();
    if (FormData.liLeNo) {
      if (FormData.li_UK_NO) {
        const update = `${domain}/api/LeaseInformation/ConfirmLease`;
     try{     
        axios.post(update, requestBodyAPIInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
          }
            catch(error){
              console.error("Error updating data:", error);
            };
      } 
      
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
      <Tooltip target=".close-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Close
      </div>
      </Tooltip>
      <Button 
        style={{backgroundColor:"#0099FF",color:'white',border:"none",lineHeight: "10%",}}
        icon="pi pi-times"
        outlined
        className="mr-2 close-button"
        onClick={Close}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".terminate-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
      Terminate
      </div>
    </Tooltip>
      <Button 
        style={{backgroundColor:"#0099FF",color:'white',border:"none",lineHeight: "10%",}}
        icon="pi pi-ban"
        outlined
        className="mr-2 terminate-button"
        onClick={openTerminatePopup}
        disabled={!isRecordSelected}
      ></Button>
      {/* securitydeposit */}
      {/* <Tooltip target=".securitydeposit-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Security Deposit
      </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 securitydeposit-button"
        // onClick={handleTerminate}
        disabled={!isRecordSelected}
      >
    </Button>
      {/* deposit refund */}
      {/* <Tooltip target=".depositrefund-button" position="bottom" mouseTrack >
      <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
           Depsoit Refund
      </div>
      </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 depositrefund-button" */}
        {/* // onClick={Terminate}
        disabled={!isRecordSelected}
      ></Button>  */}

    </React.Fragment>
  );
  const tableHeaderStyleC = {
    border: "1px solid #eef2ff",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#e1e1e1",
    width:"0%",

  };
  const tableCellStyleC = {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width:"0%",
  };
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />

    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["liLeNo"] ? filters["liLeNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "liLeNo",
                        e.target.value,
                        filters["liLeNo"]
                          ? filters["liLeNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TY_PE"] ? filters["li_TY_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TY_PE",
                        e.target.value,
                        filters["li_TY_PE"]
                          ? filters["li_TY_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_CO"] ? filters["li_LE_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_CO",
                        e.target.value,
                        filters["li_LE_CO"]
                          ? filters["li_LE_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_TE_NT"] ? filters["li_TE_NT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TE_NT",
                        e.target.value,
                        filters["li_TE_NT"]
                          ? filters["li_TE_NT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_ST_DT"] ? filters["li_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_ST_DT",
                        e.target.value,
                        filters["li_ST_DT"]
                          ? filters["li_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_EN_DT"] ? filters["li_EN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_EN_DT",
                        e.target.value,
                        filters["li_EN_DT"]
                          ? filters["li_EN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["li_LE_ST"] ? filters["li_LE_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_LE_ST",
                        e.target.value,
                        filters["li_LE_ST"]
                          ? filters["li_LE_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["li_TR_DT"] ? filters["li_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TR_DT",
                        e.target.value,
                        filters["li_TR_DT"]
                          ? filters["li_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["li_TR_RS"] ? filters["li_TR_RS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TR_RS",
                        e.target.value,
                        filters["li_TR_RS"]
                          ? filters["li_TR_RS"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["li_TR_PE"] ? filters["li_TR_PE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "li_TR_PE",
                        e.target.value,
                        filters["li_TR_PE"]
                          ? filters["li_TR_PE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>

              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Lease No</th>
                <th style={tableHeaderStyleC}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Tenant</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>Start Date</th>
                <th style={tableHeaderStyle}>End Date</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyleH}>Date</th>
                <th style={tableHeaderStyleH}>Reason</th>
                <th style={tableHeaderStyleH}>Penality</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.li_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.li_UK_NO}
                        checked={selectedRows.includes(rowData.li_UK_NO)}
                        onChange={() => handleRowSelect(rowData.li_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.liLeNo}</td>
                    <td style={tableCellStyleC}>{rowData.li_LE_TY}</td>
                    <td style={tableCellStyle}>{rowData.li_LE_CO}</td>
                    <td style={tableCellStyle}>{rowData.li_TE_NT}</td>
                    <td style={tableCellStyle}>{rowData.teanat_NAME}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.li_ST_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.li_EN_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.status}</td>
                    <td style={tableCellStyleH}>{formatDate(rowData.li_TR_DT)}</td> 
                    <td style={tableCellStyleH}>{rowData.li_TR_RS}</td>
                    <td style={tableCellStyleH}>{rowData.li_TR_PE}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {TerminatePopupVisible && (
      <TerminatePopup
        visibleTerminate={TerminatePopupVisible}
        onCloseTerminate={() => setTerminatePopupVisible(false)}
        data={FormData}
        handleResponse={handleResponse}
        onchangedata={onchangedata}
        Terminate={Terminate}
        onchangeFormData={onchangeFormData}  
        handleUpdate={handleUpdate}
      />
      )}
      <LeaseInformationDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}
        onchangeDropDownFormData3={onchangeDropDownFormData3}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        onchangeDropDownFormData7={onchangeDropDownFormData7}
        onchangeDropDownFormData8={onchangeDropDownFormData8}
        onchangeDropDownFormData9={onchangeDropDownFormData9}
        onchangeDropDownFormData10={onchangeDropDownFormData10}
        onchangeDropDownFormData11={onchangeDropDownFormData11}
        onchangeDropDownFormData12={onchangeDropDownFormData12}
        onchangeDropDownFormData13={onchangeDropDownFormData13}
        onchangeDropDownFormData14={onchangeDropDownFormData14}
        li_LE_ST={li_LE_ST}
        li_LE_TM={li_LE_TM}
        li_UN_TY={li_UN_TY}
        li_IN_US={li_IN_US}
        li_RN_TY={li_RN_TY}
        li_BL_ST={li_BL_ST}
        li_TX_AP={li_TX_AP}
        li_TX_PR={li_TX_PR}
        li_PR_NO={li_PR_NO}
        li_BL_NO={li_BL_NO}
        li_FL_NO={li_FL_NO} 
        li_PY_TR={li_PY_TR}    
        li_TR_CR={li_TR_CR}  
        li_LE_CO={li_LE_CO} 
        li_LE_TY={li_LE_TY}
        li_BS_CR={li_BS_CR}
        li_UN_TS={li_UN_TS}
        li_TE_NT={li_TE_NT}
        li_PA_NO={li_PA_NO}
        li_SE_PA={li_SE_PA}
        li_HL_CD={li_HL_CD}
		    setli_LE_ST={setli_LE_ST}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        LeaseInformationtable={LeaseInformationtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        SelectedliTwNt={SelectedliTwNt}
        setSelectedliTwNt={setSelectedliTwNt}
        SelectedliPaNo={SelectedliPaNo}
        setSelectedliPaNo={setSelectedliPaNo}
        SelectedliSePa={SelectedliSePa}
        setSelectedliSePa={setSelectedliSePa}
        SelectedliUnTs={SelectedliUnTs}
        setSelectedliUnTs={setSelectedliUnTs}
        onChangeOfUnitSelect={onChangeOfUnitSelect}
        handleConfirm={handleConfirm}
      />
    </div>
  );
};

export default LeaseInformationMain;
