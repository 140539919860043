import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function LeadQualificationDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  addLeadTable1Row,
  deleteLeadTable1Row,
  // addLeadTable2Row,
  deleteLeadTable2Row,
  LeadTableData1,
  LeadTableData2,
  setLeadTableData2,
  sq_UK_NO,
  sqQfNm,
  sq_LD_TH,
  sq_OP_TH,
}) {
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [showSecondTable, setShowSecondTable] = useState(false);
  const questionselect = async (sequenceValue) => {
    if (!sequenceValue) return;
    const qualificationName = sqQfNm;
    const Url = `${domain}/api/LeadQualification/GetAnswers`;
    const requestData = {
      "qualificationAnswer": {
        "sa_QF_NM": qualificationName,
        "sa_QU_SQ": sequenceValue,
      },
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      console.log("API response:", response.data.Result);
      setLeadTableData2(response.data.Result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCheckboxChange = async (index) => {
    if (index == selectedCheckbox) {
      setSelectedCheckbox(null);
      setLeadTableData2([]);
      setShowSecondTable(false);
    }
    else{
      const sequenceValues = LeadTableData2.map((item) => item?.sa_AN_SQ);
      const sequenceValue = sequenceValues[index];
      console.log("Sequence Value:", sequenceValue);
      setSelectedCheckbox(index);
      setShowSecondTable(true);
      await questionselect(sequenceValue);
      addLeadTable2Row(); 
    }
    // const sequenceValues = LeadTableData2.map((item) => item?.sa_AN_SQ);
    //   const sequenceValue = sequenceValues[index];
    //   console.log("Sequence Value:", sequenceValue);
    //   setSelectedCheckbox(index);
    //   setShowSecondTable(true);
    //   await questionselect(sequenceValue);
    //   if (index !== selectedCheckbox) {
    //     setSelectedCheckbox(null);
    //     setLeadTableData2([]);
    //   }
    //   addLeadTable2Row(); 
  };
  const addLeadTable2Row = () => {
    console.log("LeadTableData2 before adding row:", LeadTableData2);  
    const nextSequence = LeadTableData2.length + 1;
    console.log("Next sequence:", nextSequence);
    const newRow = {
      sa_UK_NO: "",
      sa_AN_SQ: nextSequence,
      sa_WE_PE: "",
      sa_QA_AN: "",
    };
    setLeadTableData2([...LeadTableData2, newRow]);
    console.log("LeadTableData2 after adding row:", LeadTableData2); 
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sq_UK_NO ? "Revise Lead Qualification" : "Add Lead Qualification"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sqQfNm" className="font-bold">
                Qualification Name
              </label>
              <InputText
                id="sqQfNm"
                name="sqQfNm"
                defaultValue={sqQfNm}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sq_LD_TH" className="font-bold">
                Lead Threshhold %
              </label>
              <InputText
                id="sq_LD_TH"
                name="sq_LD_TH"
                value={sq_LD_TH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="sq_OP_TH" className="font-bold">
                Opportunity Threshhold %
              </label>
              <InputText
                id="sq_OP_TH"
                name="sq_OP_TH"
                value={sq_OP_TH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addLeadTable1Row} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteLeadTable1Row} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="LeadTable1">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th></th>
                <th>Sequence</th>
                <th>Question Type</th>
                <th>Description </th>
                <th>Weightage</th>
                <th>Qualification</th>
              </tr>
            </thead>
            <tbody>
              {LeadTableData1.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`sq_UK_NO_${index}`}
                      name={`sq_UK_NO_${index}`}
                      defaultValue={row.sq_UK_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <input
                      type="radio"
                      id={`radio_${index}`}
                      name={`radio_${index}`}
                      checked={selectedCheckbox === index}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sqSqNo_${index}`}
                      name={`sqSqNo_${index}`}
                      value={index + 1}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sq_QA_TY_${index}`}
                      name={`sq_QA_TY_${index}`}
                      defaultValue={row.sq_QA_TY}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sq_QA_DS_${index}`}
                      name={`sq_QA_DS_${index}`}
                      defaultValue={row.sq_QA_DS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sq_QA_WT_${index}`}
                      name={`sq_QA_WT_${index}`}
                      defaultValue={row.sq_QA_WT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sq_QA_WT_${index}`}
                      name={`sq_QA_WT_${index}`}
                      defaultValue={row.sq_QA_WT}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
        {selectedCheckbox !== null && showSecondTable && (
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addLeadTable2Row} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteLeadTable2Row} style={minusstyle} tooltip="Delete" />
            )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="LeadTable2">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK_NO</th>
                    <th>Sequence</th>
                    <th>Weightage %</th>
                    <th>Answer </th>
                  </tr>
                </thead>
                <tbody>
                  {LeadTableData2.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`sa_UK_NO_${index}`}
                          name={`sa_UK_NO_${index}`}
                          defaultValue={row.sa_UK_NO}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        <InputText
                          id={`sa_AN_SQ_${index}`}
                          name={`sa_AN_SQ_${index}`}
                          value={index + 1}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        <InputText
                          id={`sa_WE_PE_${index}`}
                          name={`sa_WE_PE_${index}`}
                          defaultValue={row.sa_WE_PE}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        <InputText
                          id={`sa_QA_AN_${index}`}
                          name={`sa_QA_AN_${index}`}
                          defaultValue={row.sa_QA_AN}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
