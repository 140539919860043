import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ContractBillingDetailsPopup from "../Popups/ContractBillingDetailsPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function MileStoneBillingDialog({
  open,
  handleClose,
  data,
  setFormData,
  FormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  MileStoneBillingTableData,
  setMileStoneBillingTableData, 
  bd_ST_AG,
  setbd_ST_AG,
  cb_ST_CD,
  setcb_ST_CD,
  bd_CR_CD,
  setbd_CR_CD,
  bd_BL_TY,
  setbd_BL_TY,
  SelectedemEMNO,
  setSelectedemEMNO,
  SelectedProjectMaster,
  setSelectedProjectMaster,
  bd_EX_RT,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  bd_BL_ST,
  setbd_BL_ST,
  bd_DO_TY,
  price_AMOUNT,
  discount_AMOUNT,
  net_AMOUNT,
  tax_AMOUNT,
  fr_PRICE_AMOUNT,
  fr_DISCOUNT_AMOUNT,
  fr_NET_AMOUNT,
  fr_TAX_AMOUNT,
  bd_CN_NO,
  setbd_CN_NO,
  bd_DS_PE,
  setbd_DS_PE,
  setbd_EX_RT,
  ep_CO_NO,
}) {
  const {
    bdUkNo,
    bd_CU_NO,
    bd_BL_AM,
    bd_TX_PE,
    bd_CN_TY,
    bd_CN_CO,
    bd_BS_CR,
    bd_PE_CT,
    bd_AM_DM,
    bd_DS_AM,
    bd_IN_DT,
    bd_DO_NO,
  } = data;
  const { borderbox,inboxstyle,footergridstyle,footerfieldstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();  

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const [bd_ST_AGOptions, setbd_ST_AGOptions] = useState([]);
  const [cb_ST_CDOptions, setcb_ST_CDOptions] = useState([]);
  const [bd_CR_CDOptions, setbd_CR_CDOptions] = useState([]);
  const [bd_BL_TYOptions, setbd_BL_TYOptions] = useState([]);
  const [bd_BL_STOptions, setbd_BL_STOptions] = useState([]);
  const [bd_DO_TYOptions, setbd_DO_TYOptions] = useState([]);

  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_CR_CD(value);
  };
  const StageDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_ST_AG(value);
  };
  const StatusDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_BL_ST(value);
  };
  const BillTypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_BL_TY(value);
  };
  useEffect(() => {
    ProjectType();
    ContractBillingDetailsStatus();
    Currency();
    BillType();
    Status();
    Type();
  }, []);
  const ProjectType = async () => {
    const CostOptions = await fetchClassCodeDS("BL/NM");
    setbd_ST_AGOptions(CostOptions);
  };
  const ContractBillingDetailsStatus = async () => {
    const Options = await fetchClassCodeDS("PR/ST");
    setcb_ST_CDOptions(Options);
  };
  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbd_CR_CDOptions(Options);
  };
  const BillType = async () => {
    const Options = await fetchClassCodeCD("BG/TY");
    setbd_BL_TYOptions(Options);
  };
  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setbd_DO_TYOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("BL/ST");
    setbd_BL_STOptions(Options);
  };
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [AddresstypeC, setAddresstypeC] = useState("C");
  // Advance Search
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData("bd_CU_NO", data.abAdNo);
    setCustomerPopupVisible(false);
  };
  const [ContractBillingDetailsPopupVisible, setContractBillingDetailsPopupVisible] = useState(false);
  const openContractBillingDetailsPopup = () => {
    setContractBillingDetailsPopupVisible(true);
  };
  const handleDataSelect1 = (data) => {
    document.getElementById("bd_CN_NO").value =  data.cb_CN_NO;
    onchangeFormData("bd_CN_TY", data.cb_CN_TY);
    onchangeFormData("bd_CN_CO", data.cb_CN_CO);
    onchangeFormData("bd_BS_CR", data.cb_BS_CR);
    setSelectedemEMNO(data.name);
    onchangeFormData("bd_CU_NO", data.cb_CU_NO);
    setselectedItem(data.cb_BL_NM);
    setselectedDesc(data.itemDES);
    CurrencyDropDown(`bd_CR_CD`, data.cb_CR_CD);
    document.getElementById("bd_EX_RT").value =  data.cb_EX_RT;
    BillTypeDropdown(`bd_BL_TY`, data.cb_BL_TY);
    onchangeFormData("bd_BL_AM", data.cb_BL_AM);
    setbd_DS_PE(data.cb_DS_PE);
    document.getElementById("bd_TX_PE").value =  data.cb_TX_PE;
    setContractBillingDetailsPopupVisible(false);
    setShowSearchIcon(false)
  };
  const addRow = () => {
    setMileStoneBillingTableData([...MileStoneBillingTableData, {}]);
  };
  const deleteRow = () => {
    if (MileStoneBillingTableData.length > 0) {
      const updatedData = [...MileStoneBillingTableData];
      updatedData.pop();
      setMileStoneBillingTableData(updatedData);
    }
  };
  const [selectedTRDate, setSelectedTRDate] = useState(moment().toDate());
  const handleDateTRChange = (e) => {
    setSelectedTRDate(e.value);
    onchangedata({ target: { name: "se_TR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  const onchangeFormPercentage = async(e,index) => {
    onchangedata(e);
    document.getElementById(`bd_AM_DM_${index}`).value = "";
    document.getElementById(`bd_AM_FR_${index}`).value = "";
    GetCalculate(index);
  };
  const onchangeFormFrAmount = async(e,index) => {
    onchangedata(e);
    document.getElementById(`bd_PE_CT_${index}`).value = "";
    document.getElementById(`bd_AM_DM_${index}`).value = "";
    GetCalculate(index);
  };
  const onchangeFormAmount = async(e,index) => {
    onchangedata(e);
    document.getElementById(`bd_PE_CT_${index}`).value = "";
    document.getElementById(`bd_AM_FR_${index}`).value = "";
    GetCalculate(index);
  };
  const GetCalculate = async(index) => {
    const table = document.getElementById("MileStoneBillingtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const billingInvoiceGenerationList = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const Details = {
        bd_PE_CT: document.getElementById(`bd_PE_CT_${i}`).value,
        bd_AM_DM: document.getElementById(`bd_AM_DM_${i}`).value,
        bd_AM_FR: document.getElementById(`bd_AM_FR_${i}`).value,
      };
      billingInvoiceGenerationList.push(Details);
    }
    const requestBodyNet = {
      billingInvoiceGenerationList,
      bd_TX_PE: document.getElementById("bd_TX_PE").value,
      bd_DS_PE: document.getElementById("bd_DS_PE").value,
      bd_BL_AM: document.getElementById("bd_BL_AM").value,
      bd_EX_RT: document.getElementById("bd_EX_RT").value,
    };
    const Url = `${domain}/api/FixedBilling/GetCalculateAndUpdateTaxDetails`;
    try {
    const response =await axios.post(Url, requestBodyNet, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const datatax = response.data.Result;
    if (datatax ) {
      for(let i = 0; i < MileStoneBillingTableData.length; i++) {
        if (index === i) {
        document.getElementById(`bd_AM_DM_${i}`).value = datatax[i].BD_AM_DM;
        document.getElementById(`bd_PE_CT_${i}`).value = datatax[i].BD_PE_CT;
        document.getElementById(`bd_TX_AM_${i}`).value = datatax[i].BD_TX_AM;
        document.getElementById(`bd_DS_AM_${i}`).value = datatax[i].BD_DS_AM;
        document.getElementById(`bd_DS_FR_${i}`).value = datatax[i].BD_DS_FR;
        document.getElementById(`bd_TX_FR_${i}`).value = datatax[i].BD_TX_FR;
        document.getElementById(`bd_AM_FR_${i}`).value = datatax[i].BD_AM_FR;
        }
      }
    } else {
      console.error("Invalid API response:", data);
    }
    await GetFooter();
    } catch (error) {
    console.error("Error fetching data:", error);
    }
  
  };
  const GetFooter = async (index) => {
    const UrlCalulate = `${domain}/api/ProgressBilling/GetUpdateSummaryDetails`;
    const billingInvoiceGenerationList = [];
  
    for (let i = 0; i < MileStoneBillingTableData.length; i++) {
      billingInvoiceGenerationList.push({
        "bd_AM_DM": document.getElementById(`bd_AM_DM_${i}`).value,
        "bd_DS_AM": document.getElementById(`bd_DS_AM_${i}`).value,
        "bd_TX_AM": document.getElementById(`bd_TX_AM_${i}`).value,
        "bd_AM_FR": document.getElementById(`bd_AM_FR_${i}`).value,
        "bd_DS_FR": document.getElementById(`bd_DS_FR_${i}`).value,
        "bd_TX_FR": document.getElementById(`bd_TX_FR_${i}`).value,
      });
    }
  
    const priceInput = {
      "billingInvoiceGenerationList": billingInvoiceGenerationList
    };
  
    try {
      const response = await axios.post(UrlCalulate, priceInput, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      
      const datatax = response.data.Result;
      if (datatax) {
        document.getElementById ("price_AMOUNT").value =  datatax.total_AMOUNT;
        document.getElementById ("discount_AMOUNT").value =  datatax.total_DISCOUNT; 
        document.getElementById ("net_AMOUNT").value =  datatax.total_TAXABLE_AMOUNT;
        document.getElementById ("tax_AMOUNT").value =  datatax.total_TAX;    
        
        document.getElementById ("fr_PRICE_AMOUNT").value =  datatax.total_FOREIGN_AMOUNT;
        document.getElementById ("fr_DISCOUNT_AMOUNT").value =  datatax.total_FOREIGN_DISCOUNT; 
        document.getElementById ("fr_NET_AMOUNT").value =  datatax.total_FOREIGN_TAXABLE_AMOUNT;
        document.getElementById ("fr_TAX_AMOUNT").value =  datatax.total_FOREIGN_TAX;       
      } else {
        console.error("Invalid API response:", datatax);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const handleItem =async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("bd_BL_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(bdUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{bdUkNo ? "Revise MileStone Billing" : "Add MileStone Billing"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2"  style={{display:"none"}}>
            <label htmlFor="bdUkNo" className="font-bold">
              UK_NO
            </label>
            <InputText disabled
              id="bdUkNo"
              name="bdUkNo"
              value={bdUkNo}
              onChange={(e) => onchangedata(e)}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_CN_NO" className="font-bold">
              Contract No <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="bd_CN_NO"
                    name="bd_CN_NO"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={bd_CN_NO}
                  />
                   {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openContractBillingDetailsPopup}
                    ></i>
                   )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_CN_TY" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="bd_CN_TY"
                name="bd_CN_TY"
                value={bd_CN_TY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_CN_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
                id="bd_CN_CO"
                name="bd_CN_CO"
                value={bd_CN_CO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled
                id="name"
                name="name"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="bd_CU_NO"
                name="bd_CU_NO"
                defaultValue={bd_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
            </div>
            </div>  
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_BL_NM" className="font-bold">
                Item No <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                  id="bd_BL_NM"
                  name="bd_BL_NM"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedItem}
                />
                {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openItemPopup}
                ></i>
                )}
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="itemDES" className="font-bold">
              Description
              </label>
              <InputText disabled
                id="itemDES"
                name="itemDES"
                value={SelectedDesc}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_CR_CD" className="font-bold">
              Currency
              </label>
              <Dropdown disabled
                name="bd_CR_CD"
                value={bd_CR_CD}
                options={bd_CR_CDOptions}
                onChange={(e) =>CurrencyDropDown("bd_CR_CD", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bd_EX_RT" className="font-bold">
                   Exchange Rate
                  </label>
                  <InputText disabled
                    id="bd_EX_RT"
                    name="bd_EX_RT"
                    defaultValue={bd_EX_RT}
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_BS_CR" className="font-bold">
                Base Currency
              </label>
              <InputText disabled
                id="bd_BS_CR"
                name="bd_BS_CR"
                value={bd_BS_CR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_BL_TY" className="font-bold">
                Bill Type
              </label>
              <Dropdown disabled
                name="bd_BL_TY"
                value={bd_BL_TY}
                options={bd_BL_TYOptions}
                onChange={(e) => BillTypeDropdown("bd_BL_TY", e.value)}
                placeholder="Select Bill Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_BL_AM" className="font-bold">
              Bill Amount 
              </label>
              <InputText disabled
                id="bd_BL_AM"
                name="bd_BL_AM"
                defaultValue={bd_BL_AM}
                onChange={() => GetCalculate()}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_TX_PE" className="font-bold">
              Tax %
              </label>
              <InputText disabled
                id="bd_TX_PE"
                name="bd_TX_PE"
                defaultValue={bd_TX_PE}
                onChange={() => GetCalculate()}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="bd_DS_PE" className="font-bold">
              Discount %
              </label>
              <InputText disabled
                id="bd_DS_PE"
                name="bd_DS_PE"
                defaultValue={bd_DS_PE}
                onChange={() => GetCalculate()}
              />
            </div>
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
          <div  style={multitablestyle}>
          <table className="custom-table" id="MileStoneBillingtable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Billing Stages</th>
                <th>Percentage</th>
                <th>Amount</th>
                <th>Discount Amount</th>
                <th>Tax Amount</th>
                <th>Amount-Foreign</th>
                <th>Discount Foreign</th>
                <th>Tax Foreign</th>
                <th>Status</th>
                <th>Completion Date</th>
                <th>Invoice Date</th>
                <th>Invoice No</th>
                <th>Invoice Type</th>
              </tr>
            </thead>
            <tbody>
              {MileStoneBillingTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`bdUkNo_${index}`}
                      name={`bdUkNo_${index}`}
                      value={row.bdUkNo}
                    />
                    </td>
                    <td>
                    <Dropdown
                      id={`bd_ST_AG_${index}`}
                      name={`bd_ST_AG_${index}`}
                      value={ data[`bd_ST_AG_${index}`] ? data[`bd_ST_AG_${index}`]: row.bd_ST_AG}
                      options={bd_ST_AGOptions}
                      onChange={(e) =>StageDropdown(`bd_ST_AG_${index}`, e.value)}
                      placeholder="Select Stage"
                    />
                    </td>
                  <td >
                    <InputText style={{width:"90px"}}
                      id={`bd_PE_CT_${index}`}
                      name={`bd_PE_CT_${index}`}
                      defaultValue={row.bd_PE_CT}
                      onChange={(e) => onchangeFormPercentage(e,index)}
                    />
                  </td>
                  <td >
                    <InputText  style={{width:"90px"}}
                    disabled={data.bd_CR_CD==bd_BS_CR?false:true}
                      id={`bd_AM_DM_${index}`}
                      name={`bd_AM_DM_${index}`}
                      defaultValue={row.bd_AM_DM}
                      onChange={(e) => onchangeFormAmount(e,index)}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`bd_DS_AM_${index}`}
                      name={`bd_DS_AM_${index}`}
                      value={row.bd_DS_AM}
                      onChange={(e) => onchangedata(e)}
                    
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`bd_TX_AM_${index}`}
                      name={`bd_TX_AM_${index}`}
                      defaultValue={row.bd_TX_AM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText   style={{width:"90px"}}
                    disabled={data.bd_CR_CD!=bd_BS_CR?false:true}
                      id={`bd_AM_FR_${index}`}
                      name={`bd_AM_FR_${index}`}
                      defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_AM_FR:0}
                      onChange={(e) => onchangeFormFrAmount(e,index)}
                    />
                  </td>
                  <td >
                    <InputText disabled  style={{width:"90px"}}
                      id={`bd_DS_FR_${index}`}
                      name={`bd_DS_FR_${index}`}
                      defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_DS_FR:0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText disabled  style={{width:"90px"}}
                      id={`bd_TX_FR_${index}`}
                      name={`bd_TX_FR_${index}`}
                      defaultValue={data.bd_CR_CD!=bd_BS_CR ? row.bd_TX_FR:0}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_BL_ST_${index}`}
                      name={`bd_BL_ST_${index}`}
                      value={ data[`bd_BL_ST_${index}`] ? data[`bd_BL_ST_${index}`]: row.bd_BL_ST}
                      options={bd_BL_STOptions}
                      onChange={(e) =>StatusDropdown(`bd_BL_ST_${index}`, e.value)}
                      placeholder="Select Status"
                    />
                    </td>
                  <td>
                  <Calendar style={{width:"150px"}}
                  id={`bd_AC_CP_${index}`}
                  name={`bd_AC_CP_${index}`}
                  value={row.bd_AC_CP? moment(row.bd_AC_CP, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "bd_AC_CP", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <Calendar disabled
                    style={{width:"150px"}}
                    id={`bd_IN_DT_${index}`}
                    name={`bd_IN_DT_${index}`}
                    value={row.bd_IN_DT}
                    onChange={(e) => {//setCurrentDate(e.value);
                    onDateChange("bd_IN_DT", index, e.value);
                    }}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td >
                    <InputText disabled style={{width:"90px"}}
                      id={`bd_DO_NO_${index}`}
                      name={`bd_DO_NO_${index}`}
                      defaultValue={row.bd_DO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText  disabled style={{width:"90px"}}
                      id={`bd_DO_TY_${index}`}
                      name={`bd_DO_TY_${index}`}
                      defaultValue={bd_DO_TY}
                    />
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        <div style={inboxstyle}>
          <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                defaultValue={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="discount_AMOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="discount_AMOUNT"
                name="discount_AMOUNT"
                defaultValue={discount_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="net_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="net_AMOUNT"
                name="net_AMOUNT"
                defaultValue={net_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                defaultValue={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Fore.Amount
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                defaultValue={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_DISCOUNT_AMOUNT" className="font-bold">
                Fore.Discount
              </label>
              <InputText disabled
                id="fr_DISCOUNT_AMOUNT"
                name="fr_DISCOUNT_AMOUNT"
                defaultValue={fr_DISCOUNT_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_NET_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="fr_NET_AMOUNT"
                name="fr_NET_AMOUNT"
                defaultValue={fr_NET_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
                Fore.Tax
              </label>
              <InputText disabled
                id="fr_TAX_AMOUNT"
                name="fr_TAX_AMOUNT"
                defaultValue={fr_TAX_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
      </div> 
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={AddresstypeC}
      />
      


<ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <ContractBillingDetailsPopup
        visible={ContractBillingDetailsPopupVisible}
        onClose={() => setContractBillingDetailsPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        billType="M"
      />
    </Dialog>
  );
}
