import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import GLDistribution from "./GLDistribution";
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function CustomerLedgerDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  setFormData,
  // onchangedata,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData4,  
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  handleDefault,
  addRow,
  cl_DO_CO,
  cl_DO_TY,
  deleteRow,
  CustomerLedgertable,
  setCustomerLedgertable,
  onchangeFormData,
  setSelectedemEMNO,
  SelectedemEMNO,
  cl_PY_CO,
  cl_CR_CD,
  cl_TX_CD,
  cl_CR_CO,
  cl_CO_NM,
  setcl_CO_NM,
  setcl_PY_CO,
  cl_CU_NO,
  setcl_CU_NO,
  isGLSaved,
  cl_ST_CD
}) {
  const {
    clUkNo,
    cl_DO_NO,
    cl_CR_RT,
    cl_SU_IN,
    cl_RE_FR,
    cl_BA_TY,
    cl_BA_NO,
    cl_DD_DT

  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,confirmstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [cl_CO_NMOptions, setcl_CO_NMOptions] = useState([]);
  const [cl_OR_TYOptions, setcl_OR_TYOptions] = useState([]);
  const [cl_CR_CDOptions, setcl_CR_CDOptions] = useState([]);
  const [cl_PY_COOptions, setcl_PY_COOptions] = useState([]);
  const [cl_TX_CDOptions, setcl_TX_CDOptions] = useState([]);
  const [cl_IN_DT, setcl_IN_DT] = useState("");
  const [cl_GL_DT, setcl_GL_DT] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [transCurr, settransCurr] = useState("");
  const [PaymentTerm, setPaymentTerm] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // const [open, setOpen] = useState(false);
  const [GLDistributionVisible, setGLDistributionVisible] = useState(false);
  const openGLDistribution = () => {
      setGLDistributionVisible(true);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split("_")[3];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange4 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
    setFormData({ ...FormData, [name]: value });
    setPaymentTerm(value);
  };
  const onchangedata = (e, index) => {
    const { name, value } = e.target;
    const updatedTable = [...CustomerLedgertable];
    updatedTable[index] = { ...updatedTable[index], [name]: value };
    setCustomerLedgertable(updatedTable);
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    setcl_CU_NO(data.abAdNo);
    const Url = `${domain}/api/CustomerMaster/onChangeOfCustomer`;
    const requestData = {
      "cu_CU_NO": data.abAdNo
    };
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
  
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        // Update FormData state with the received values
        onchangeDropDownFormData5("cl_PY_CO", dataCuMt.cu_PT_TM);
        onchangeDropDownFormData4("cl_CR_CD", dataCuMt.cu_CR_CD);  
        onchangeDropDownFormData6("cl_TX_CD", dataCuMt.cu_TX_CD);
        setPaymentTerm(dataCuMt.cu_PT_TM);
        settransCurr(dataCuMt.cu_CR_CD);
        setcl_PY_CO(dataCuMt.cu_PT_TM);
        const due={
          "pt_PY_CO": dataCuMt.cu_PT_TM,
          "inp_DATE": cl_IN_DT
        }

        axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              const dueDate = resp.data.inp1;
              if (dueDate) {
                onchangeFormData("be_DU_DT", dueDate.out_DATE);
                  const exData = {
                    "cr_TO_CR": document.getElementById("cl_CR_CO").value,
                    "cr_FR_CR": dataCuMt.cu_CR_CD,
                    "cr_EF_DT": cl_IN_DT
                  };
        exchangeRate(exData);
      }
    });
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  
    setCustomerPopupVisible(false);
  };
  function exchangeRate(exData) {
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("cl_CR_RT").value = dataExRt.cr_CO_MR;
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const onCHangeOfTaxableAmount = async(SelectedRowIndex) => {
    const flag = 'T'
    document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("CustomerLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    onchangetax(SelectedRowIndex,flag,taxCodeValue);
  };
  const onChnageOfGrossAmount = async(SelectedRowIndex) => {
    const flag = 'G'
    document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("CustomerLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    onchangetax(SelectedRowIndex,flag,taxCodeValue);
  };
  const handleFormDataChange5 = (name, value,SelectedRowIndex) => {
    const index = name.split("_")[3];
    onchangeDropDownFormData6(name, value, index);
    onChangeOfTaxCode(SelectedRowIndex,value);
  };
  const onChangeOfTaxCode = async(SelectedRowIndex,taxCodeValue) => {
	  if(cl_CR_CD === cl_CR_CO){
		document.getElementById (`cl_TX_AM_${SelectedRowIndex}`).value =  ''; 
		const grossAmount  = document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value;
		  const taxableAmount = document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value;
		if(grossAmount) { 
		  document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value =  '';
		} else if(taxableAmount){
		  document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value =  ''; 
		}
		const flag = 'C';
		onchangetax(SelectedRowIndex,flag,taxCodeValue);
	  } else {
		document.getElementById (`cl_TX_FR_${SelectedRowIndex}`).value =  ''; 
		const grossAmount  = document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value;
		const taxableAmount = document.getElementById (`cl_TA_FR_${SelectedRowIndex}`).value;
		if(grossAmount) { 
		  document.getElementById (`cl_TA_FR_${SelectedRowIndex}`).value =  '';
		} else if(taxableAmount){
		  document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value =  ''; 
		}
		const flag = 'C';
		calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
	  }
  };
  const onchangetax = async(SelectedRowIndex,flag,taxCodeValue) => {
    const Urltax = `${domain}/api/CustomerLedger/GetTaxDetails`;
        const taxInput = {
          "cl_GR_AM": parseFloat(document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value),
          "cl_TX_CD": taxCodeValue,
          "cl_TA_AM": parseFloat(document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value),
        }
          try {
            const response =await axios.post(Urltax, taxInput, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });
        
            if (response.status !== 200) {
              throw new Error(`API request failed with status: ${response.status}`);
            }
            const datatax = response.data.Result;
            if (datatax ) {
              document.getElementById (`cl_TX_AM_${SelectedRowIndex}`).value =  datatax.cl_TX_AM; 
              if(flag === 'G' || flag === 'C'){
                document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value =  datatax.cl_TA_AM;
              }
              if(flag === 'T' || flag === 'C'){
                document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value =  datatax.cl_GR_AM;
              }
                 
              document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value =  '';
              document.getElementById (`cl_TX_FR_${SelectedRowIndex}`).value =  '';
              document.getElementById (`cl_TA_R_${SelectedRowIndex}`).value =  '';          
             
            } else {
              console.error("Invalid API response:", data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
  };
  const onChangeOfForeTaxableAmount = async(SelectedRowIndex) => {
    const flag = 'T'
    document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value =  '';
	const table = document.getElementById("CustomerLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value; 
    calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
  };
  const onChnageOfForeGrossAmount = async(SelectedRowIndex) => {
    const flag = 'G'
    document.getElementById (`cl_TA_FR_${SelectedRowIndex}`).value =  ''; 
	const table = document.getElementById("CustomerLedgertable");
	const taxCodeValue = table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value;
    calculateTaxOnFore(SelectedRowIndex,flag,taxCodeValue);
  };
  const calculateTaxOnFore = async(SelectedRowIndex,flag,taxCodeValue) => {
      const Urltaxfore = `${domain}/api/CustomerLedger/GetOnChangeOfForeignTaxableAmount`;
        const taxInput = {
          "cl_CR_RT" : document.getElementById ("cl_CR_RT").value,
          "cl_CR_AM": document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value,
          "cl_TX_CD": taxCodeValue,
          "cl_TA_FR": document.getElementById (`cl_TA_FR_${SelectedRowIndex}`).value,
        }
          try {
            const response =await axios.post(Urltaxfore, taxInput, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            });
        
            if (response.status !== 200) {
              throw new Error(`API request failed with status: ${response.status}`);
            }
            const datataxfore = response.data.Result;
            if (datataxfore ) { 
              document.getElementById (`cl_TX_FR_${SelectedRowIndex}`).value =  datataxfore.cl_TX_FR; 
              if(flag === 'G' || flag === 'C'){
                document.getElementById (`cl_TA_FR_${SelectedRowIndex}`).value =  datataxfore.cl_TA_FR;
              }
              if(flag === 'T' || flag === 'C'){
                document.getElementById (`cl_CR_AM_${SelectedRowIndex}`).value =  datataxfore.cl_CR_AM;
              }
              document.getElementById (`cl_GR_AM_${SelectedRowIndex}`).value =  datataxfore.cl_GR_AM;
              document.getElementById (`cl_TA_AM_${SelectedRowIndex}`).value =  datataxfore.cl_TA_AM;
              document.getElementById (`cl_TX_AM_${SelectedRowIndex}`).value =  datataxfore.cl_TX_AM;
            } else {
              console.error("Invalid API response:", data);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
  };
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl4 = `${urlpayterm}`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;

  const url = `${domain}/api/ClassCode/All`;
  const parameter2 = "FO/DT";
  const finalUrl2 = `${url}/${parameter2}`;
  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;
  // Pay Frequency
  const parameter5 = "TX/RT";
  const finalUrl5 = `${url}/${parameter5}`;
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    setcl_GL_DT(moment(moment().toDate()).format("YYYYMMDD"));
    setcl_IN_DT(moment(moment().toDate()).format("YYYYMMDD"));
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setcl_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setcl_OR_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rdd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setcl_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setcl_PY_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setcl_TX_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "cl_IN_DT", value: moment(e.value).format("YYYYMMDD") } });
	setcl_IN_DT(e.value);
  };
// gl date
const [selectedDateGL, setSelectedDateGL] = useState(moment().toDate());
const handleDateChangeGL = (e) => {
  setSelectedDateGL(e.value);
  onchangedata({ target: { name: "cl_GL_DT", value: moment(e.value).format("YYYYMMDD") } });
  setcl_GL_DT(e.value);
};
const handleInputChange = (index, field, value) => {
  const updatedTable = [...CustomerLedgertable];
  updatedTable[index][field] = value;
  setCustomerLedgertable(updatedTable);
};
const isDisabled = cl_ST_CD === 20;

const isUpdateMode = Boolean(clUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{clUkNo ? "Revise Customer Ledger" : "Add Customer Ledger"}</h5>
    <div style={{ display:'flex', justifyContent: 'flex-start'}}>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip="Save"
          disabled={!isGLSaved}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
        <Button
        style={{background:"transparent",border:"none",color:'violet'}}
        icon="pi pi-plus"
        variant="outlined"
        onClick={openGLDistribution}
        tooltip="GL"
        disabled={!isUpdateMode}
      ></Button>
    </div>
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_DO_NO" className="font-bold">
              Doc No
            </label>
            <InputText disabled
              id="cl_DO_NO"
              name="cl_DO_NO"
              value={cl_DO_NO}
             />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_DO_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="cl_DO_TY"
              name="cl_DO_TY"
              value={cl_DO_TY}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_DO_CO" className="font-bold">
            Company
            </label>
            <InputText  disabled
              id="cl_DO_CO"
              name="cl_DO_CO"
              defaultValue={cl_DO_CO}
            />
          </div>
      <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
        <label htmlFor="cl_IN_DT" className="font-bold">
            Invoice Date
        </label>
           <Calendar disabled={isDisabled}
            id="cl_IN_DT"
            name="cl_IN_DT"
            value={moment(cl_IN_DT, "YYYYMMDD").toDate()}
            onChange={handleDateChange}
            dateFormat="dd/mm/yy"
          />
      </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_GL_DT" className="font-bold">
                GL Date
            </label>
            <Calendar disabled={isDisabled}
              id="cl_GL_DT"
              name="cl_GL_DT"
              value={moment(cl_GL_DT, "YYYYMMDD").toDate()}
              onChange={handleDateChangeGL}
              dateFormat="dd/mm/yy"
            />
        </div>
        <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
          <label htmlFor="cl_DD_DT" className="font-bold">
              Due Date
          </label>
            <Calendar disabled={isDisabled}
                id="cl_DD_DT"
                name="cl_DD_DT"
                value={moment(data.cl_DD_DT, "YYYYMMDD").isValid() ? moment(data.cl_DD_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: {name: "cl_DD_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                dateFormat="dd/mm/yy"
              /> 
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_CU_NO" className="font-bold">
              Customer  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText disabled={isDisabled}
                id="ab_AD_NM"
                name="ab_AD_NM"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openCustomerPopup}
              ></i>
              )}
            </div>
            <div style={{display:"none"}}>
              <InputText disabled={isDisabled}
                id="cl_CU_NO"
                name="cl_CU_NO"
                value={cl_CU_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_PY_CO" className="font-bold">
              Pay Term
            </label>
            <Dropdown disabled={isDisabled}
              id="cl_PY_CO"
              name="cl_PY_CO"
              value={cl_PY_CO}
              options={cl_PY_COOptions}
              onChange={(e) => handleFormDataChange4("cl_PY_CO", e.value)}
              placeholder="Select Pay Term"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_CO_NM" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled={isDisabled}
              id="cl_CO_NM"
              name="cl_CO_NM"
              value={cl_CO_NM}
              options={cl_CO_NMOptions}
              onChange={(e) => { setcl_CO_NM(e.value);
                onchangeDropDownFormData2("cl_CO_NM", e.value); 
              }}
              placeholder="Select Cost Centre"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_CR_CD" className="font-bold">
              Currency
            </label>
            <Dropdown disabled={isDisabled}
              id="cl_CR_CD"
              name="cl_CR_CD"
              value={cl_CR_CD}
              options={cl_CR_CDOptions}
              onChange={(e) => onchangeDropDownFormData4("cl_CR_CD", e.value)}
              placeholder="Select Currency"
            />
            
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_CR_RT" className="font-bold">
              Exchange Rate
            </label>
            <InputText disabled
              id="cl_CR_RT"
              name="cl_CR_RT"
              defaultValue={cl_CR_RT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_CR_CO" className="font-bold">
              Base Currency
            </label>
            <InputText disabled id="cl_CR_CO" name="cl_CR_CO" value={cl_CR_CO}/>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="cl_SU_IN" className="font-bold">
              Customer PO
            </label>
            <InputText disabled={isDisabled}
              id="cl_SU_IN"
              name="cl_SU_IN"
              defaultValue={cl_SU_IN}
              onChange={(e) => onchangedata(e)}
            />
          </div>          
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
            <table className="custom-table" id="CustomerLedgertable" style={{ overflow: "auto" }}>
              <thead>
                <tr>
                  <th style={{ display: "none" }} >UK_NO</th>
                  <th>Line No</th>
                  <th>Gross Amount</th>
                  <th>Taxable Amount</th>
                  <th>Tax</th>
                  <th>Tax Code</th>
                  <th>Discount</th>
                  <th>Fore. Gross Amount</th>
                  <th>Fore. Taxable Amount</th>
                  <th>Fore. Tax</th>
                  <th>Fore. Discount</th>
                  <th>Rel. No</th>
                  <th>Rel. Type</th>
                  <th>Rel. Co</th>
                </tr>
              </thead>
              <tbody>
                {CustomerLedgertable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }} >
                      <InputText
                        id={`clUkNo_${index}`}
                        name={`clUkNo_${index}`}
                        defaultValue={row.clUkNo}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`cl_DO_PI_${index}`}
                        name={`cl_DO_PI_${index}`}
                        value={index + 1}
                        onChange={(Event) => onchangedata(Event)}
                      />
                    </td>
                    <td>
                      <InputText
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD==cl_CR_CO?false:true}
                          style={{ width: "90px" }}
                          id={`cl_GR_AM_${index}`}
                          name={`cl_GR_AM_${index}`}
                          defaultValue={data[`cl_GR_AM_${index}`] ? data[`cl_GR_AM_${index}`] : row.cl_GR_AM}

                          onChange={(e) => {
                            onchangedata(e);
                            onChnageOfGrossAmount(index);                      
                          }}
                        />
                      </td>
                    <td>
                    <InputText
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD==cl_CR_CO?false:true}
                      style={{ width: "110px" }}
                      id={`cl_TA_AM_${index}`}
                      name={`cl_TA_AM_${index}`}
                      defaultValue={data[`cl_TA_AM_${index}`] ? data[`cl_TA_AM_${index}`] : row.cl_TA_AM}
                      onChange={(e) => {
                        onchangedata(e);
                        onCHangeOfTaxableAmount(index);                      
                      }}
                    />
                    </td>
                    <td>
                      <InputText disabled 
                        style={{ width: "90px" }}
                        id={`cl_TX_AM_${index}`}
                        name={`cl_TX_AM_${index}`}
                        defaultValue={data[`cl_TX_AM_${index}`] ? data[`cl_TX_AM_${index}`] : row.cl_TX_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                    <Dropdown disabled={isDisabled}
                      id={`cl_TX_CD_${index}`}
                      name={`cl_TX_CD_${index}`}
                      value={ data[`cl_TX_CD_${index}`] ? data[`cl_TX_CD_${index}`]: row.cl_TX_CD}
                      options={cl_TX_CDOptions}
                      onChange={(e) =>handleFormDataChange5(`cl_TX_CD_${index}`, e.value,index)}
                      placeholder="Tax Code"
                    />
                    </td>

                    <td>
                      <InputText style={{ width: "90px" }}
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD==cl_CR_CO?false:true}
                        id={`cl_DI_AM_${index}`}
                        name={`cl_DI_AM_${index}`}
                        defaultValue={row.cl_DI_AM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText 
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD!=cl_CR_CO?false:true}  
                        style={{ width: "90px" }}
                        id={`cl_CR_AM_${index}`}
                        name={`cl_CR_AM_${index}`}
                        defaultValue={data[`cl_CR_AM_${index}`] ? data[`cl_CR_AM_${index}`] : row.cl_CR_AM}
                        onChange={(e) => {
                          onchangedata(e);
                          onChnageOfForeGrossAmount(index);                      
                        }}
                      />
                    </td>
                    <td>
                      <InputText 
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD!=cl_CR_CO?false:true}
                        style={{ width: "90px" }}
                        id={`cl_TA_FR_${index}`}
                        name={`cl_TA_FR_${index}`}
                        defaultValue={data[`cl_TA_FR_${index}`] ? data[`cl_TA_FR_${index}`] : row.cl_TA_FR}
                        onChange={(e) => {
                          onchangedata(e);
                          onChangeOfForeTaxableAmount(index);                      
                        }}
                      />
                    </td>
                    <td>
                      <InputText disabled
                        style={{ width: "90px" }}
                        id={`cl_TX_FR_${index}`}
                        name={`cl_TX_FR_${index}`}
                        defaultValue={data[`cl_TX_FR_${index}`] ? data[`cl_TX_FR_${index}`] : row.cl_TX_FR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText 
                      disabled={cl_ST_CD != "20" && data.cl_CR_CD!=cl_CR_CO?false:true}
                        style={{ width: "90px" }}
                        id={`cl_DI_FR_${index}`}
                        name={`cl_DI_FR_${index}`}
                        defaultValue={data[`cl_DI_FR_${index}`] ? data[`cl_DI_FR_${index}`] : row.cl_DI_FR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <InputText style={{ width: "90px" }}
            disabled={isDisabled}
                        id={`cl_OR_NO_${index}`}
                        name={`cl_OR_NO_${index}`}
                        defaultValue={row.cl_OR_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
            disabled={isDisabled}
                      id={`cl_OR_TY_${index}`}
                      name={`cl_OR_TY_${index}`}
                      value={ data[`cl_OR_TY_${index}`] ? data[`cl_OR_TY_${index}`]: row.cl_OR_TY}
                      options={cl_OR_TYOptions}
                    onChange={(e) =>handleFormDataChange1(`cl_OR_TY_${index}`, e.value)}
                      placeholder="Rel Type"
                    />
                    </td>
                    <td>
                      <InputText style={{ width: "90px" }}
            disabled={isDisabled}
                        id={`cl_OR_CO_${index}`}
                        name={`cl_OR_CO_${index}`}
                        defaultValue={row.cl_OR_CO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>
       <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      <GLDistribution
          // open={open}
          // setOpen={setOpen}
          visible={GLDistributionVisible}
          onClose={() => setGLDistributionVisible(false)}
          data={data}
          gl_DO_NO={cl_DO_NO}
          gl_DO_TY={cl_DO_TY}
          gl_DO_CO={cl_DO_CO}
		  isDisabled={isDisabled}
          handleCloseMain={handleClose}
          handleDefault={handleDefault}
          onchangeFormData
          onchangeDropDownFormData1
          onchangeDropDownFormData2
          onchangeDropDownFormData3
          onchangeDropDownFormData4
          onchangeDropDownFormData5
          onchangeDropDownFormData6
          setSelectedDesc
          SelectedDesc
          setSelectedAcc
          SelectedAcc
          addRow
          deleteRow
          JournalEntrytable  
      />
    </Dialog>
  );
}

