import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import { Button } from "primereact/button";

const SharedWithMePopup = ({ 
  visible,
  onClose,
  }) => {
  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,borderbox,
    savestyle,cancelstyle,Dialogstyle} = ButtonStyles();

    const [operationtabledata, setoperationtabledata] = useState([{}])
    const addRow = () => {
      setoperationtabledata([...operationtabledata, {}]);
    };
    
    const deleteRow = () => {
      if (operationtabledata.length > 0) {
        const updatedData = [...operationtabledata];
        updatedData.pop();
        setoperationtabledata(updatedData);
      }
    };

  const valuenames = {
    Access_Grp:"",
    Employee_ID:"",
  }
  const [FormData, setFormData] = useState(valuenames);


  const onchangedata = (event) => {
    const { value, name } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [AccessGrpPopupVisible, setAccessGrpPopupVisible] =useState(false);
  const [EmployeePopupVisible, setEmployeePopupVisible] =useState(false);

  const [addressType, setaddressType] = useState("C");
  const openAccessGrpPopup = () => {
    setAccessGrpPopupVisible(true);
  };
  const openEmployeeIdPopup = () => {
    setAccessGrpPopupVisible(true);
  };
  const handleSelectAccessGrp = (data) => {
    document.getElementById("Access_Grp").value = data.abAdNo;
    setAccessGrpPopupVisible(false);
    setShowSearchIcon(false);
  };

  const handleSelectEmployee = (data) => {
    document.getElementById("Employee_ID").value = data.abAdNo;
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };

  return (
    <Dialog style={{width:'60rem',height:'50rem'}}
        visible={visible}
        onHide={onClose}
    >

    <div style={borderbox}> 

      <div className="formgrid grid" style={{display:'flex'}}>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="Access_Grp" className="font-bold">
              Access Group <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
                <InputText
                  id="Access_Grp"
                  name="Access_Grp"
                  onChange={(event) => onchangedata(event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={FormData.Access_Grp}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openAccessGrpPopup}
                  ></i>
                )}
              </div>
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
          <label htmlFor="Employee_ID" className="font-bold">
            Dealer<span style={{ color: 'red' }}>*</span>
          </label>
          <div style={{ display: 'flex' }}>
              <InputText
                id="Employee_ID"
                name="Employee_ID"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={FormData.Employee_ID}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openEmployeeIdPopup}
                ></i>
              )}
            </div>
          </div>
      </div>

      <div  style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="operationtable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th> Operation Sequence</th>
              <th> Description </th>
            </tr>
          </thead>
          <tbody>
            {operationtabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`osUkNo_${index}`}
                    name={`osUkNo_${index}`}
                    value={row.osUkNo}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_OP_SQ_${index}`}
                    name={`os_OP_SQ_${index}`}
                    defaultValue={row.os_OP_SQ}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <InputText
                    id={`os_SQ_DS_${index}`}
                    name={`os_SQ_DS_${index}`}
                    defaultValue={row.os_SQ_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 

    </div>

      {/* employeeid */}
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleSelectEmployee}
        formData={FormData}
        addressType={addressType}
        setFormData={setFormData}
      />
      {/* Accessgrp */}
      <AddressBookPopup
        visible={AccessGrpPopupVisible}
        onClose={() => setAccessGrpPopupVisible(false)}
        onDataSelect={handleSelectAccessGrp}
        formData={FormData}
        addressType={addressType}
        setFormData={setFormData}
      />

    </Dialog>
  )
}

export default SharedWithMePopup