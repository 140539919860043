import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import UnitPopup from "../Popups/UnitPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function UnitAttributesDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SelectedemEMNO,
  setSelectedemEMNO,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeDropDownFormData9,
  ua_PR_NO,
  ua_FL_NO,
  ua_BL_NO,
  ua_UN_NO, 
  ua_AB_01,
  ua_AB_02,
  ua_AB_07,
  ua_AB_08,
  ua_AB_09,
  ua_AB_10,
  onChangeOfUnitSelect,
  PRname,
  BLname,
}) {
  const {
    uaUkNo,
    ua_AB_11,
    ua_AB_12,
    ua_AB_13,
    ua_AB_14,
    ua_AB_15,
    ua_AB_16,
    ua_AB_17,
    ua_AB_18,
    ua_AB_19,
    ua_AB_20,
    ua_AB_21,
    ua_AB_22,
    ua_AB_23,
    ua_AB_24,
    ua_AB_25,
    ua_AB_26,
    ua_AB_27,
    ua_AB_28,
    ua_AB_29,
    ua_AB_30,
  } = data;
  const {borderbox,inboxstyle,TabBoxStyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ua_AB_01Options, setua_AB_01Options] = useState([]);
  const [ua_AB_02Options, setua_AB_02Options] = useState([]);
  const [ua_AB_07Options, setua_AB_07Options] = useState([]);
  const [ua_AB_08Options, setua_AB_08Options] = useState([]);
  const [ua_AB_09Options, setua_AB_09Options] = useState([]);
  const [ua_AB_10Options, setua_AB_10Options] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    fetchbedrooms();
    fetchbathrooms();
    fetchelevators();
    fetchparking();
    fetchkitchen();
    fetchparkstills();
  }, []);
  
  const fetchbedrooms = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_01Options(Options);
  };
  const fetchbathrooms = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_02Options(Options);
  };
  const fetchelevators = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_07Options(Options);
  };
  const fetchparking = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_08Options(Options);
  };
  const fetchkitchen = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_09Options(Options);
  };
  const fetchparkstills = async () => {
    const Options = await fetchClassCodeDS("UN/AB");
    setua_AB_10Options(Options);
  };
  const [UnitPopupVisible, setUnitPopupVisible] = useState(false);
  const openUnitPopup = () => {
    setUnitPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    onChangeOfUnitSelect(data);
    setUnitPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(uaUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{uaUkNo ? "Revise Unit Attributes" : "Add  Unit Attributes"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle} >
      <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="General">
            {/* tab1 general */}
            <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_UN_NO" className="font-bold">
                    Unit <span style={{ color: 'red' }}>*</span>
                    </label>
                    <div style={{ display: "flex" }}>
                    <InputText 
                        id="ua_UN_NO"
                        name="ua_UN_NO"
                        onChange={(Event) => onchangedata(Event)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={ua_UN_NO}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "4%",
                          cursor: "pointer",
                        }}
                        onClick={openUnitPopup}
                      ></i>
                      )}
                      </div>
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_FL_NO" className="font-bold">
                      Floor
                    </label>
                    <InputText disabled
                      id="ua_FL_NO"
                      name="ua_FL_NO"
                      value={ua_FL_NO}
                    />               
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_BL_NO" className="font-bold">
                      Building
                    </label>
                    <InputText
                      style={{display:"none"}}
                      id="ua_BL_NO"
                      name="ua_BL_NO"
                      value={ua_BL_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                      <InputText disabled
                      id="BLname"
                      name="BLname"
                      value={BLname}
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_PR_NO" className="font-bold">
                    Property
                    </label>
                    <InputText
                      style={{display:"none"}}
                      id="ua_PR_NO"
                      name="ua_PR_NO"
                      value={ua_PR_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />  
                      <InputText disabled
                      id="PRname"
                      name="PRname"
                      value={PRname}
                    />               
                  </div>
                </div>
                <div className="formgrid grid">  
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_AB_01" className="font-bold">
                    Number of Bedrooms
                    </label>
                      <Dropdown
                      id="ua_AB_01"
                      name="ua_AB_01"
                      value={ua_AB_01}
                      options={ua_AB_01Options}
                      onChange={(e) => onchangeDropDownFormData4("ua_AB_01", e.value)}
                      placeholder="Select Bedrooms"
                    />
                  </div>
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                    <label htmlFor="ua_AB_02" className="font-bold">
                    Number of Bathrooms
                    </label>
                    <Dropdown
                      id="ua_AB_02"
                      name="ua_AB_02"
                      value={ua_AB_02}
                      options={ua_AB_02Options}
                      onChange={(e) => onchangeDropDownFormData5("ua_AB_02", e.value)}
                      placeholder="Select Bathrooms"
                    />
                  </div>              
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_07" className="font-bold">
                  Number of Elevators
                  </label>
                  <Dropdown
                    id="ua_AB_07"
                    name="ua_AB_07"
                    value={ua_AB_07}
                    options={ua_AB_07Options}
                    onChange={(e) => onchangeDropDownFormData6("ua_AB_07", e.value)}
                    placeholder="Select Elevators"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_08" className="font-bold">
                   Number of Parking
                  </label>
                  <Dropdown
                    id="ua_AB_08"
                    name="ua_AB_08"
                    value={ua_AB_08}
                    options={ua_AB_08Options}
                    onChange={(e) => onchangeDropDownFormData7("ua_AB_08", e.value)}
                    placeholder="Select Parking"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_09" className="font-bold">
                  Kitchen
                  </label>
                  <Dropdown
                    id="ua_AB_09"
                    name="ua_AB_09"
                    value={ua_AB_09}
                    options={ua_AB_09Options}
                    onChange={(e) => onchangeDropDownFormData8("ua_AB_09", e.value)}
                    placeholder="Select Kitchen"
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_10" className="font-bold">
                  Number of Parking Stalls
                  </label>
                  <Dropdown
                    id="ua_AB_10"
                    name="ua_AB_10"
                    value={ua_AB_10}
                    options={ua_AB_10Options}
                    onChange={(e) => onchangeDropDownFormData9("ua_AB_10", e.value)}
                    placeholder="Select Parking Stalls"
                  />
                </div>
                </div>
            </div>
          </TabPanel>
          <TabPanel header="Additional info">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_11" className="font-bold">
                  Attributes 11
                  </label>
                    <InputText
                      id="ua_AB_11"
                      name="ua_AB_11"
                      value={ua_AB_11}
                      onChange={(Event) => onchangedata(Event)}
                    />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_12" className="font-bold">
                  Attributes 12
                  </label>
                  <InputText
                    id="ua_AB_12"
                    name="ua_AB_12"
                    value={ua_AB_12}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_13" className="font-bold">
                  Attributes 13
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_13"
                      name="ua_AB_13"
                      value={ua_AB_13}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_14" className="font-bold">
                  Attributes 14
                  </label>
                  <InputText
                    id="ua_AB_14"
                    name="ua_AB_14"
                    value={ua_AB_14}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_15" className="font-bold">
                  Attributes 15
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_15"
                      name="ua_AB_15"
                      value={ua_AB_15}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_16" className="font-bold">
                  Attributes 16
                  </label>
                  <InputText
                    id="ua_AB_16"
                    name="ua_AB_16"
                    value={ua_AB_16}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_17" className="font-bold">
                  Attributes 17
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_17"
                      name="ua_AB_17"
                      value={ua_AB_17}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_18" className="font-bold">
                  Attributes 18
                  </label>
                  <InputText
                    id="ua_AB_18"
                    name="ua_AB_18"
                    value={ua_AB_18}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_19" className="font-bold">
                  Attributes 19
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_19"
                      name="ua_AB_19"
                      value={ua_AB_19}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_20" className="font-bold">
                  Attributes 20
                  </label>
                  <InputText
                    id="ua_AB_20"
                    name="ua_AB_20"
                    value={ua_AB_20}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_21" className="font-bold">
                  Attributes 21
                  </label>
                  <InputText
                    id="ua_AB_21"
                    name="ua_AB_21"
                    value={ua_AB_21}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_22" className="font-bold">
                  Attributes 22
                  </label>
                  <InputText
                    id="ua_AB_22"
                    name="ua_AB_22"
                    value={ua_AB_22}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_23" className="font-bold">
                  Attributes 23
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_23"
                      name="ua_AB_23"
                      value={ua_AB_23}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_24" className="font-bold">
                  Attributes 24
                  </label>
                  <InputText
                    id="ua_AB_24"
                    name="ua_AB_24"
                    value={ua_AB_24}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_25" className="font-bold">
                  Attributes 25
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_25"
                      name="ua_AB_25"
                      value={ua_AB_25}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_26" className="font-bold">
                  Attributes 26
                  </label>
                  <InputText
                    id="ua_AB_26"
                    name="ua_AB_26"
                    value={ua_AB_26}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_27" className="font-bold">
                  Attributes 27
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_27"
                      name="ua_AB_27"
                      value={ua_AB_27}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_28" className="font-bold">
                  Attributes 28
                  </label>
                  <InputText
                    id="ua_AB_28"
                    name="ua_AB_28"
                    value={ua_AB_28}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_29" className="font-bold">
                  Attributes 29
                  </label>
                  <div style={{ display: "flex" }}>
                    <InputText
                      id="ua_AB_29"
                      name="ua_AB_29"
                      value={ua_AB_29}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AB_30" className="font-bold">
                  Attributes 30
                  </label>
                  <InputText
                    id="ua_AB_30"
                    name="ua_AB_30"
                    value={ua_AB_30}
                    onChange={(Event) => onchangedata(Event)}
                  />
                </div>
                </div> 

              </div>
          </TabPanel>   
      </TabView>
      </div>    

      <UnitPopup
        visible={UnitPopupVisible}
        onClose={() => setUnitPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    
    </Dialog>
  );
}
