import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import BillofMaterialDialog from "./BillofMaterialDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialValue = {
  bmItNm: "",
  DES: "",
  bm_BT_QT: "",
  bm_BT_UM: "",
  bm_BL_TY: "",
  bm_CO_CT: "",
  bm_FR_DT: moment(moment().toDate()).format("YYYYMMDD"),
  bm_TO_DT: moment(moment().toDate()).format("YYYYMMDD"),
  bm_CO_NM: "",
  des: "",
  bm_CO_QT: "",
  bm_CO_UM: "",
  bmLnNo: "",
  bm_FX_VA: "",
  bm_IS_TY: "",
  bm_OP_SQ: "",
  bm_UN_CT: "",
  bm_RE_VR: "",
  bm_ST_TY: "",
  bm_UK_NO:"",
};
const initialFilters = {
  bmItNm: { value: "", operation: "" },
  DES: { value: "", operation: "" },
  bm_BT_QT: { value: "", operation: "" },
  bm_BT_UM: { value: "", operation: "" },
  bm_BL_TY: { value: "", operation: "" },
  bm_CO_CT: { value: "", operation: "" },
  bm_FR_DT: { value: "", operation: "" },
  bm_TO_DT: { value: "", operation: "" },
  bm_CO_NM: { value: "", operation: "" },
  des: { value: "", operation: "" },
  bm_CO_QT: { value: "", operation: "" },
  bm_CO_UM: { value: "", operation: "" },
  bmLnNo: { value: "", operation: "" },
  bm_FX_VA: { value: "", operation: "" },
  bm_IS_TY: { value: "", operation: "" },
  bm_OP_SQ: { value: "", operation: "" },
  bm_UN_CT: { value: "", operation: "" },
  bm_RE_VR: { value: "", operation: "" },
  bm_ST_TY: { value: "", operation: "" },
};
const BillofMaterialMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
     copyButtonStyles,exportButtonStyles,
     checkboxStyle,tableHeaderStyle,rightalined,
     inputStyle,tableCellStyle,inputStyle1
    } = ButtonStyles();

  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const [bm_CO_UM, setbm_CO_UM ] = useState("");
  const [bm_FX_VA, setbm_FX_VA ] = useState("");
  const [bm_IS_TY, setbm_IS_TY ] = useState("");
  const [bm_ST_TY, setbm_ST_TY ] = useState("");
  const [bm_SC_UM, setbm_SC_UM ] = useState("");
  const [bm_SE_TY, setbm_SE_TY ] = useState("");
  const [bm_SE_MT, setbm_SE_MT ] = useState("");
  const [BillMaterialtable, setBillMaterialtable] = useState([{}]);
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const [parentname, setparentname] = useState("");
  const [Componentname, setComponentname] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setBillMaterialtable([{}]);
    setparentname(null);
    setComponentname(null);
    setbm_BT_UM(null);
    setbm_CO_UM(null);
    setbm_ST_TY(null);
    setbm_SC_UM(null);
    setbm_SE_TY(null);
    setbm_SE_MT(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const [bm_BL_TY, setbm_BL_TY] = useState("");
  const [bm_CO_NO, setbm_CO_NO] = useState("");
  const [bm_CO_CT, setbm_CO_CT] = useState("");
  const [bm_BT_UM, setbm_BT_UM] = useState("");

  // F/V
const onchangeDropDownFormData4 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_FX_VA(value);
};
// issue type
const onchangeDropDownFormData5 = (name, value) => {
  // setFormData({ ...FormData, [name]: value });
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_IS_TY(value);
};
const handleDefault = async () => {
  const linkPath = String(window.location);
  const l_array = linkPath.split("=");
  const LinkId = l_array[1];
  
  const requestBody = {
    "version_ID" : LinkId
  };
  const paginationUrl = `${domain}/api/LinkId/Select`;
  try {
    const response = await axios.post(paginationUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataDefault = response.data;
    const listDefault = [];
    var count = 0;
    if (dataDefault.versionIds != null) {
      for (let i = 0; i < dataDefault.versionIds.length; i++) {
        if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
          const fName = dataDefault.versionIds[i].field_ID;
          setbm_CO_NO(`${company}`);
          if(fName === "BM_BL_TY"){
              setbm_BL_TY(dataDefault.versionIds[i].default_VALUE);
          }
          if(fName === "BM_CO_CT"){
              setbm_CO_CT(dataDefault.versionIds[i].default_VALUE);
          }
          if(fName === "BM_FX_VA"){
            onchangeDropDownFormData4("bm_FX_VA",dataDefault.versionIds[i].default_VALUE);
            setbm_FX_VA(dataDefault.versionIds[i].default_VALUE);
          }
          if(fName === "BM_IS_TY"){
            onchangeDropDownFormData5("bm_IS_TY",dataDefault.versionIds[i].default_VALUE);
            setbm_IS_TY(dataDefault.versionIds[i].default_VALUE);
          }
          }
        if(dataDefault.versionIds[i].field_TYPE === "LIST"){
          count++;
          const def = {
            "column" : dataDefault.versionIds[i].field_ID ,
            "value" : dataDefault.versionIds[i].default_VALUE,
            "operation" : "EQUAL"
          }
          listDefault.push(def);
        }
      }
      handleSearch(listDefault,count);
    } else {
      handleSearch(listDefault,count);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();

    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/BillOfMaterial/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value,}));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/BillOfMaterial/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const BillMaterialData = response.data;
        setBillMaterialtable(BillMaterialData.equipmentPartLists);
        setFormData(BillMaterialData.equipmentPartLists[0]);
        setbm_CO_CT(BillMaterialData.bm_CO_CT);
        setbm_BT_UM(BillMaterialData.bm_BT_UM);
        setparentname(BillMaterialData.parent_DES);
        setComponentname(BillMaterialData.equipmentPartLists[0].component_DES);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/BillOfMaterial/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const BillMaterialData = response.data;
          delete BillMaterialData.bm_UK_NO;
          delete BillMaterialData.equipmentPartLists[0].bm_UK_NO;
          setBillMaterialtable(BillMaterialData.equipmentPartLists);
          setFormData(BillMaterialData.equipmentPartLists[0]);
          setbm_CO_CT(BillMaterialData.bm_CO_CT);
          setbm_BT_UM(BillMaterialData.bm_BT_UM);
          setparentname(BillMaterialData.parent_DES);
          setComponentname(BillMaterialData.equipmentPartLists[0].component_DES);
          setFormData(BillMaterialData);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
var requestBodyInput = {};
const values = () => {
    const table = document.getElementById("BillMaterialtable");
    const totalRowCount = table.tBodies[0].rows.length;
    const equipmentPartLists = [];
    for (let i = 0; i < totalRowCount; i++) {  
      const FR_DT = document.getElementsByName("bm_FR_DT_" + i)[0];
      const TO_DT = document.getElementsByName("bm_TO_DT_" + i)[0];
      const Details = {
        bm_UK_NO: document.getElementById("bm_UK_NO_" + i).value,
        bmLnNo: document.getElementById("bmLnNo_" + i).value,
        bm_CO_NM: document.getElementById("bm_CO_NM_" + i).value,
        bm_CO_QT: document.getElementById("bm_CO_QT_" + i).value,
        bm_CO_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
        bm_FX_VA: table.tBodies[0].rows[i].cells[6].children[0].children[1].children[0].value,
        bm_IS_TY: table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
        bm_OP_SQ: document.getElementById("bm_OP_SQ_" + i).value,
        bm_UN_CT: document.getElementById("bm_UN_CT_" + i).value,
        bm_ST_TY: table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value, 
        bm_SC_QT: document.getElementById("bm_SC_QT_" + i).value,
        bm_SC_UM: table.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value,
        bm_SE_TY: table.tBodies[0].rows[i].cells[13].children[0].children[1].children[0].value,
        bm_SE_MT: table.tBodies[0].rows[i].cells[14].children[0].children[1].children[0].value,
        bm_LB_RT: document.getElementById("bm_LB_RT_" + i).value,
        bm_FR_DT: FR_DT.value?moment(FR_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        bm_TO_DT: TO_DT.value?moment(TO_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        bm_UP_TL: document.getElementById("bm_UP_TL_" + i).value,
        bm_LW_TL: document.getElementById("bm_LW_TL_" + i).value,
      };
      equipmentPartLists.push(Details);
    }
    requestBodyInput = {
      bm_UK_NO: document.getElementById("bm_UK_NO").value,
      bmItNm :  document.getElementById("bmItNm").value,
      bm_BL_TY: bm_BL_TY,
      bm_CO_CT: bm_CO_CT,
      bm_BT_UM: bm_BT_UM,
      bm_BT_QT: FormData.bm_BT_QT,
      bm_RE_VR: document.getElementById("bm_RE_VR").value,
      equipmentPartLists,
    };
};

  const handleFormSubmit = () => {
  values();
  const ukNo = document.getElementById("bm_UK_NO").value;
    if (ukNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/BillOfMaterial/Update`;
  
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/BillOfMaterial/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  
  };
  // header buttons starts
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
        <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
        <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={exportButtonStyles}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
// header button ends

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar  className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}  />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px", }}>
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bmItNm"] ? filters["bmItNm"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bmItNm",
                        e.target.value,
                        filters["bmItNm"]
                          ? filters["bmItNm"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["parent_DES"] ? filters["parent_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "parent_DES",
                        e.target.value,
                        filters["parent_DES"]
                          ? filters["parent_DES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_BT_QT"] ? filters["bm_BT_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_BT_QT",
                        e.target.value,
                        filters["bm_BT_QT"]
                          ? filters["bm_BT_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_BT_UM"] ? filters["bm_BT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_BT_UM",
                        e.target.value,
                        filters["bm_BT_UM"]
                          ? filters["bm_BT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["bm_BL_TY"] ? filters["bm_BL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_BL_TY",
                        e.target.value,
                        filters["bm_BL_TY"]
                          ? filters["bm_BL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_CO_CT"] ? filters["bm_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_CO_CT",
                        e.target.value,
                        filters["bm_CO_CT"]
                          ? filters["bm_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_FR_DT"] ? filters["bm_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_FR_DT",
                        e.target.value,
                        filters["bm_FR_DT"]
                          ? filters["bm_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_TO_DT"] ? filters["bm_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_TO_DT",
                        e.target.value,
                        filters["bm_TO_DT"]
                          ? filters["bm_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_CO_NM"] ? filters["bm_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_CO_NM",
                        e.target.value,
                        filters["bm_CO_NM"]
                          ? filters["bm_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["component_DES"] ? filters["component_DES"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "component_DES",
                        e.target.value,
                        filters["component_DES"]
                          ? filters["component_DES"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_CO_QT"] ? filters["bm_CO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_CO_QT",
                        e.target.value,
                        filters["bm_CO_QT"]
                          ? filters["bm_CO_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_CO_UM"] ? filters["bm_CO_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_CO_UM",
                        e.target.value,
                        filters["bm_CO_UM"]
                          ? filters["bm_CO_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bmLnNo"] ? filters["bmLnNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bmLnNo",
                        e.target.value,
                        filters["bmLnNo"]
                          ? filters["bmLnNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_FX_VA"] ? filters["bm_FX_VA"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_FX_VA",
                        e.target.value,
                        filters["bm_FX_VA"]
                          ? filters["bm_FX_VA"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_IS_TY"] ? filters["bm_IS_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_IS_TY",
                        e.target.value,
                        filters["bm_IS_TY"]
                          ? filters["bm_IS_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_OP_SQ"] ? filters["bm_OP_SQ"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_OP_SQ",
                        e.target.value,
                        filters["bm_OP_SQ"]
                          ? filters["bm_OP_SQ"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_UN_CT"] ? filters["bm_UN_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_UN_CT",
                        e.target.value,
                        filters["bm_UN_CT"]
                          ? filters["bm_UN_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_RE_VR"] ? filters["bm_RE_VR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_RE_VR",
                        e.target.value,
                        filters["bm_RE_VR"]
                          ? filters["bm_RE_VR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bm_ST_TY"] ? filters["bm_ST_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bm_ST_TY",
                        e.target.value,
                        filters["bm_ST_TY"]
                          ? filters["bm_ST_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Parent Item</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Batch Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
                <th style={tableHeaderStyle}>Component Item</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Quantity</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>F/V</th>
                <th style={tableHeaderStyle}>Issue Type</th>
                <th style={tableHeaderStyle}>Oper. Seq.</th>
                <th style={tableHeaderStyle}>Unit Cost</th>
                <th style={tableHeaderStyle}>Revision</th>
                <th style={tableHeaderStyle}>Stocking Type</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.bm_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.bm_UK_NO}
                        checked={selectedRows.includes(rowData.bm_UK_NO)}
                        onChange={() => handleRowSelect(rowData.bm_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.bmItNm}</td>
                    <td style={tableCellStyle}>{rowData.parent_DES}</td>
                    <td style={tableCellStyle}>{rowData.bm_BT_QT}</td>
                    <td style={tableCellStyle}>{rowData.bm_BT_UM}</td>
                    <td style={tableCellStyle}>{rowData.bm_BL_TY}</td>
                    <td style={tableCellStyle}>{rowData.bm_CO_CT}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.bm_FR_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.bm_TO_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.bm_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.component_DES}</td>
                    <td style={rightalined}>{rowData.bm_CO_QT}</td>
                    <td style={tableCellStyle}>{rowData.bm_CO_UM}</td>
                    <td style={tableCellStyle}>{rowData.bmLnNo}</td>
                    <td style={tableCellStyle}>{rowData.bm_FX_VA}</td>
                    <td style={tableCellStyle}>{rowData.bm_IS_TY}</td>
                    <td style={tableCellStyle}>{rowData.bm_OP_SQ}</td>
                    <td style={rightalined}>{rowData.bm_UN_CT}</td>
                    <td style={tableCellStyle}>{rowData.bm_RE_VR}</td>
                    <td style={tableCellStyle}>{rowData.bm_ST_TY}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <BillofMaterialDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        onchangedata={onchangedata}
        handleFormSubmit={handleFormSubmit}
        onchangeFormData={onchangeFormData}
        BillMaterialtable={BillMaterialtable}
        setBillMaterialtable={setBillMaterialtable}
        bm_CO_NO={bm_CO_NO}
        bm_BL_TY={bm_BL_TY}
        setbm_BL_TY={setbm_BL_TY}
        bm_CO_CT={bm_CO_CT}
        setbm_CO_CT={setbm_CO_CT}
        bm_BT_UM={bm_BT_UM}
        setbm_BT_UM={setbm_BT_UM}
        bm_CO_UM={bm_CO_UM}
        setbm_CO_UM={setbm_CO_UM}
        bm_FX_VA={bm_FX_VA}
        setbm_FX_VA={setbm_FX_VA}
        bm_IS_TY={bm_IS_TY}
        setbm_IS_TY={setbm_IS_TY}
        bm_ST_TY={bm_ST_TY}
        setbm_ST_TY={setbm_ST_TY}
        bm_SC_UM={bm_SC_UM}
        setbm_SC_UM={setbm_SC_UM}
        bm_SE_TY={bm_SE_TY}
        setbm_SE_TY={setbm_SE_TY}
        bm_SE_MT={bm_SE_MT}
        setbm_SE_MT={setbm_SE_MT}
        parentname={parentname}
        setparentname={setparentname}
        Componentname={Componentname}
        setComponentname={setComponentname}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
      />
    </div>
  );
};

export default BillofMaterialMain;
