import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { saveAs } from "file-saver";
import ButtonStyles from "views/common/ButtonStyles";
import './FileManagementApplication.css';
import CreateFolderPopup from "./CreateFolderPopup";
import UploadPopup from "./UploadPopup";
import DotsPopup from "./DotsPopup";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV,faFolder, faFile,
  faFilePdf, faFileExcel, faFileWord, faFilePowerpoint, faFileAlt ,faFileCode, faImage} from '@fortawesome/free-solid-svg-icons';
  import Buttonstyles from "views/common/ButtonStyles";

const FileManagementMain = () => {
  const {tableHeaderStyle} = ButtonStyles();
  const [CreateFolderVisible, setCreateFolderVisible] = useState(false);
  const [UploadPopupVisible, setUploadPopupVisible] = useState(false);
  const openCreateFolder = () => {
    setCreateFolderVisible(true);
  };
  const openUploadFile = () => {
    setUploadPopupVisible(true);
  };
  const {formatDate ,formatSavedTime} = ButtonStyles();
  const [items, setItems] = useState([]);
  const toast = useRef(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [currentFolder, setcurrentFolder] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(9999950);
  
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      setCreateFolderVisible(false);
      fetchItems(currentFolder);
    }
  };
  // fetch list
  const fetchItems = async (ukNo) => {
    setcurrentFolder(ukNo);
    const requestBody = {
      searchRequestDto: []
    };
    const url = `${domain}/api/FileManagementSystem/List/${ukNo}/${currentPage}/${pageSize}`;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      const listItemsData = response.data.content || []; 
      setItems(listItemsData);
        const pathurl = `${domain}/api/FileManagementSystem/FetchPath`
        const requestpath = {
          "fdUkNo": ukNo
        };
        try {
          const response = await axios.post(pathurl, requestpath, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": company,
              "x-flag": localStorage.getItem("userType"),
            },
          });      
          const Data = response.data; 
          setCurrentPath(Data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // file path access
  const handleAccesspath = async (ukNo) =>{
    setcurrentFolder(ukNo);
    if (ukNo === 0 ){     
      fetchItems(ukNo);
      setCurrentPath([{ fd_NA_ME: "Root", fdUkNo: 0 }]);
    }else {
    const pathurl = `${domain}/api/FileManagementSystem/CheckAccessOfPath`
    const requestpath = {
      "fdUkNo": ukNo
    };
    try {
      const response = await axios.post(pathurl, requestpath, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": company,
          "x-flag": localStorage.getItem("userType"),
        },
      });      
      const Data = response.data; 
      if (Data.flag === true) {

        const requestBody = {
          searchRequestDto: []
        };

        const url = `${domain}/api/FileManagementSystem/List/${ukNo}/${currentPage}/${pageSize}`;
        setLoading(true);
        setError(null);
        try {
          const response = await axios.post(url, requestBody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": company,
              "x-flag": localStorage.getItem("userType"),
            },
          });
          
          const listItemsData = response.data.content || []; 
          setItems(listItemsData);
          // alert(ukNo);
            const pathurl = `${domain}/api/FileManagementSystem/FetchPath`
            const requestpath = {
              "fdUkNo": ukNo
            };
            try {
              const response = await axios.post(pathurl, requestpath, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": company,
                  "x-flag": localStorage.getItem("userType"),
                },
              });      
              const Data = response.data; 
              setCurrentPath(Data)
            } catch (error) {
              console.error("Error fetching data:", error);
            }
    
        } catch (error) {
          console.error("Error fetching data:", error);
        }

      } else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Access is Not there for this folder",
        });
      }    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    }
  };



  useEffect(() => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("path=");
     const LinkId = l_array[1];
     if (LinkId) {
      fetchItems(LinkId);
    } else {
      fetchItems(0);
    }
  }, [currentPage]); 

  const [documentType, setdocumentType] = useState ("");
  const [addressType, setaddressType] = useState ("");
  
  useEffect(() => {
    handleDefault();
  }, []);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, 
          "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "DocumentType"){
              setdocumentType(dataDefault.versionIds[i].default_VALUE);
          }
          if(fName === "AB_AD_TY"){
            setaddressType(dataDefault.versionIds[i].default_VALUE);
          }
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        // handleSearch(listDefault,count);
      } else {
        // handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // download api
  const handleFileClick = async (UK_NO,fileName) => {
    const Request = {
      "fdUkNo": UK_NO
    }
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/DownloadFile`,Request,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
          responseType: "arraybuffer",
        }
      );
      saveAs(new Blob([response.data]), fileName);
      if (response.status == 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File downloaded successfully.",
        });
      } else {
        throw new Error("Failed to download.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };
  // Create Folder
  const handleCreateFolder = async () => {
    const RequestCreate = {
      "mainFolder": currentFolder,
      "folderName": document.getElementById("Folder_name").value,
      "documentType":documentType
    };  
    try {
      const response = await axios.post(
        `${domain}/api/FileManagementSystem/CreateFolder`,
        RequestCreate,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType"),
          },
        }
      );
  
      const Createfolderdata = response.data;
      handleResponse(Createfolderdata);
      
    } catch (error) {
      console.error("Error Occurred while Creating folder", error);
    }
  };
  const [selectedFiles, setSelectedFiles] = useState([]);
  // upload the file
  const handleUpload = async (event) => { 
    const file = event.target.files[0];   
    const formDatacheck = new FormData();
      formDatacheck.append('file', file );
      formDatacheck.append('mainFolder', currentFolder); 

      const accessCheckUrl = `${domain}/api/FileManagementSystem/UploadFileCheck`;      
      const UploadUrl = `${domain}/api/FileManagementSystem/UploadFile`;
    
      try {
        fetch(accessCheckUrl, {
          method: "POST",
          body: formDatacheck,
          headers: {
            Authorization: `Bearer ${token}`, // Assuming token is defined elsewhere
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"), // Assuming company is defined elsewhere
          },
        })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error('Failed to upload file');
          }
          return resp.json();
        })
        .then((resp) => {
          const Accessdata = resp;
          if (Accessdata.accessFlag === true ) {
            if (Accessdata.fileExistFlag === true ) {
    
            } else {
              const formData = new FormData();
              formData.append('file', file );
              formData.append('documentType', documentType);
              formData.append('mainFolder', currentFolder);
              try {
                fetch(UploadUrl, {
                  method: "POST",
                  body: formData,
                  headers: {
                    Authorization: `Bearer ${token}`, // Assuming token is defined elsewhere
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"), // Assuming company is defined elsewhere
                  },
                })
                  .then((resp) => {
                    if (!resp.ok) {
                      throw new Error('Failed to upload file');
                    }
                    return resp.json();
                  })
                  .then((resp) => {
                    toast.current.show({
                      severity: "success",
                      summary: "Upload Successful",
                    });
                    fetchItems(currentFolder);
                  })
                  .catch((error) => {
                    console.error("Error importing file:", error);
                    toast.current.show({
                      severity: "error",
                      summary: "Import Error",
                      detail: "Failed to import. Please try again later.",
                    });
                  });
              } catch (error) {
                console.error("Error parsing imported data:", error);
                toast.current.show({
                  severity: "error",
                  summary: "Import Error",
                  detail:
                    "Invalid data format. Please ensure the imported file is in the correct format.",
                });
              }
            }
          } else{
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Access is Not there for this folder",
            });
          }
        })
        
        .catch((error) => {
          console.error("Error importing file:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail: "Failed to import. Please try again later.",
          });
        });
        fetchItems(currentFolder);
        } catch (error) {
          console.error("Error parsing imported data:", error);
          toast.current.show({
            severity: "error",
            summary: "Import Error",
            detail:
              "Invalid data format. Please ensure the imported file is in the correct format.",
          });
        }
  };
  // file icons based on extension
  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase(); // Get the file extension
  
    switch (extension) {
      case 'pdf':
        return faFilePdf;
      case 'xls':
      case 'xlsx':
        return faFileExcel;
      case 'doc':
      case 'docx':
        return faFileWord;
      case 'ppt':
      case 'pptx':
        return faFilePowerpoint;
      case 'txt':
        return faFileAlt;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return faImage; // Image files
      case 'jsp':
        return faFileCode; // .jsp files (web development)
      default:
        return faFile; // Default to a general file icon
    }
  };
  // Function to determine color based on file type
  const getFileColor = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
  
    switch (extension) {
      case 'pdf':
        return '#D32F2F'; // Red for PDF
      case 'xls':
      case 'xlsx':
        return '#388E3C'; // Green for Excel
      case 'doc':
      case 'docx':
        return '#1976D2'; // Blue for Word
      case 'ppt':
      case 'pptx':
        return '#F57C00'; // Orange for PowerPoint
      case 'txt':
        return '#757575'; // Gray for Text file
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return '#0288D1'; // Light Blue for Images
      case 'jsp':
        return '#8E24AA'; // Purple for .jsp (web development)
      default:
        return '#000000'; // Default black color for other file types
    }
  };

  // back function 
  const handleBack = async () => {
    if (currentPath.length > 1) {
      const parentFolder = currentPath[currentPath.length - 2].fdUkNo; 
      fetchItems(parentFolder);
    } else {
      toast.current.show({
        severity: "info",
        summary: "You are at the root folder.",
        detail: "There are no more folders to go back to.",
      });
    }
  };  
  //home function 
  const handleHome = async () => {
    fetchItems(0);
  };



  // dotspopup
  const [popupVisible, setPopupVisible] = useState(false);
  const [optionsSelected, setoptionsSelected] = useState("");
  const handleDotsClick = (item) => {
    setPopupVisible(true);
    setoptionsSelected(item);
  };
  return (
    <div >
      <Toast ref={toast} />

      <div style={{padding:'10px',boxShadow:'2px 2px 5px #8dc0c0',backgroundColor:'#e8fef8a1',height:'2.5rem'}} className="TopDiv">
        <div className="row" style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
          
          {/* Path for file */}
          <div className="pathforfile" style={{flex: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
            {currentPath.map((file) => (
              <button key={file.fdUkNo} onClick={() => handleAccesspath(file.fdUkNo)} style={{ marginRight: '5px' }}>
                {file.fd_NA_ME} &gt;
              </button>
            ))}
          </div>

          {/* Buttons Container */}
          <div className="fourbuttons" style={{ display: 'flex', flexDirection: 'row',  justifyContent: 'flex-end',marginTop:'-26px' }}>
            {/* Create folder */}
            <div style={{ margin: '0 10px 10px 0' }}  >
              <button className="btn btn-primary" onClick={openCreateFolder} style={{ textAlign: 'center', height: '25px', padding: '2px 16px' }}>
                Create Folder
              </button>
            </div>
            {/* Upload button */}
            <div style={{ margin: '0 10px 10px 0' }}>
              <button
                style={{ height: '25px', padding: '2px 16px' }}
                className="btn btn-primary"
                onClick={() => {
                  const input = document.getElementById('fileInput');
                  input.click();
                }}
              >
                Upload File
              </button>
              <input id="fileInput" type="file" onChange={handleUpload} style={{ display: 'none' }} />
            </div>
            {/* Home button */}
            <div style={{ margin: '0 10px 10px 0' }}>
              <Button className="btn1"
                onClick={handleHome}
                icon="pi pi-home"
                style={{
                  color: '#0000ffa8',
                  backgroundColor: '#e0f7fa', // Light cyan shade
                  border: '1px solid #ddd', // Light border
                  padding: '8px 16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  transition: 'background-color 0.3s',
                  height: '25px'
                  
                }}
              >
                Home
              </Button>
            </div>
            {/* Back button */}
            <div  style={{ margin: '0 10px 10px 0' }} >
              <Button className="btn1"
                onClick={handleBack}
                icon="pi pi-arrow-left"
                style={{
                  color: '#0000ffa8',
                  backgroundColor: '#f0f8ff', // Light blue shade
                  border: '1px solid #ddd', // Light border
                  padding: '8px 16px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  marginRight: '10px',
                  transition: 'background-color 0.3s',
                  height: '25px'
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>


      {/* <div style={{padding:'5px',display: 'flex', gap: '10px'}}> 
        <button className="btn btn-primary" onClick={openCreateFolder} > Create Folder </button>
       <button className="btn btn-primary" onClick={() => {
          const input = document.getElementById("fileInput");
          input.click();
        }} > Upload File </button>
      <input
        id="fileInput"
        type="file"
        onChange={handleUpload}
        style={{ display: "none" }}
      />
      </div> */}

      <div className="row">
        <div className="col-12" style={{height : "55vh", overflow : "auto"}}>
        <table className="table table-borderless transparent-table"  >
          <thead>
            <tr>
              <th style={tableHeaderStyle}> File Name </th>
              <th style={tableHeaderStyle}> Owner </th>
              <th style={tableHeaderStyle}> Creation Date</th>
              <th style={tableHeaderStyle}> Creation Time</th>
              <th style={tableHeaderStyle}> Modified Date</th>
              <th style={tableHeaderStyle}> Modified Time</th>
              <th style={tableHeaderStyle}> </th>
            </tr>
          </thead>
          <tbody >
            {items.map((item, index) => (
              <tr key={item.id} style={{ backgroundColor: index % 2 === 0 ? "aliceblue" : "#ffffff",}} >
                <td key={item.fdUkNo} >
                  <div className="d-flex align-items-center" _hover={{ cursor: "pointer" }}
                  style={{ cursor: item.fd_TY_PE === 'FI' ? 'pointer' : 'default',
                 
                 }}
                    onClick={() => {
                      if (item.fd_TY_PE === 'FI') {
                        handleFileClick(item.fdUkNo, item.fd_NA_ME);
                      }else if(item.fd_TY_PE === 'FO') {
                        // handleAccesspath(item.fdUkNo);
                        fetchItems(item.fdUkNo);
                      }
                    }}>
                      <FontAwesomeIcon 
                        icon={item.fd_TY_PE === 'FO' ? faFolder : getFileIcon(item.fd_NA_ME)}
                        style={{ width: '24px', height: '24px', marginRight: '8px',
                        color: item.fd_TY_PE === 'FO' ? '#F8D775' : getFileColor(item.fd_NA_ME) 
                          }} 
                      />
                      {item.fd_NA_ME}
                      
                  </div>
                </td>
                <td>{item.owner}</td>
                <td>{formatDate(item.fd_FI_DT)}</td>
                <td>{formatSavedTime(item.fd_FI_TI)}</td>
                <td>{formatDate(item.last_UPDATED_DATE)}</td>
                <td>{formatSavedTime(item.last_UPDATED_TIME)}</td>
                <td className="text-end">
                    <div 
                      style={{ cursor: 'pointer' }} 
                      onClick={() => handleDotsClick(item.fdUkNo)}
                    >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      
      <CreateFolderPopup
        visible={CreateFolderVisible}
        onClose={() => setCreateFolderVisible(false)}
        handleCreateFolder={handleCreateFolder}
      />
      <UploadPopup
        visible={UploadPopupVisible}
        onClose={() => setUploadPopupVisible(false)}
        handleUpload={handleUpload}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
      <DotsPopup
        visible={popupVisible}
        onClose={() => setPopupVisible(false)}
        optionsSelected={optionsSelected}
        handleResponse={handleResponse}
        documentType={documentType}
        setoptionsSelected={setoptionsSelected}
        addressType={addressType}
      />
    </div>
  );
};
export default FileManagementMain;
