import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Calendar } from "primereact/calendar";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function OverTimeDialog({
  open,
  handleClose,
  initialData, 
  data,
  handleFormSubmit,
  // onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  addRow,
  deleteRow,
  OvertimeTableData
}) {
  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  // search
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const { 
    eo_OV_DT,
    eoUkNo,
  } = data;
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false); 
  const [addressType, setaddressType] = useState("E");
  const [eo_SH_FTOptions, seteo_SH_FTOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const onchangedata = (e) => {
    const { name, value } = e.target;
    if (name === "eo_OV_DT" ) {
      console.log(`Received date for ${name}:`, value);
      const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
      console.log(`Formatted date for ${name}:`, formattedDate);

      if (moment(formattedDate, "YYYYMMDD").isValid()) {
        onchangeFormData(name, formattedDate);
      } else {
        console.error("Invalid date format:", value);
      }
    } else {
      onchangeFormData(name, value);
    }
  };
  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "EM/SH";
  const finalUrl1 = `${url}/${parameter1}`;
  useEffect(() => {
    fetchDropdownOptions1();
  }, []);
    // 1st fetchdropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      seteo_SH_FTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM); 
    onchangeFormData("eo_EM_NO", data.abAdNo,SelectedRowIndex);
    onchangeFormData("name", data.ab_AD_NM,SelectedRowIndex);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  // endsearchpopup
  const isUpdateMode = Boolean(eoUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{eoUkNo ? "Revise Over Time" : "Add Over Time"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
  <div  style={tablestyle} >
    <div className="table-buttons" style={tablebutton} >
      {(
      <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
      )}
        {(
      <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
      )}
      </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="OvertimeTable">
        <thead>
          <tr>
          <th style={{ display: "none" }} >UK NO</th>
            <th style={{ display: "none" }} >Employee ID </th>
            <th>Employee <span style={{ color: 'red' }}>*</span></th>
            <th>Date</th>
            <th>Shift</th>
            <th>Normal OT</th>
            <th>Holiday OT</th>
          </tr>
        </thead>
        <tbody>
              {OvertimeTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }} >
                    <InputText
                      id={`eoUkNo_${index}`}
                      name={`eoUkNo_${index}`}
                      defaultValue={row.eoUkNo}
                    />
                  </td>
                  <td style={{display:"none"}}>
                    <InputText
                      id={`eo_EM_NO_${index}`}
                      name={`eo_EM_NO_${index}`}
                      value={row.eo_EM_NO ? row.eo_EM_NO : row[`${'eo_EM_NO'}_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                  <div style={{display:"flex",marginTop:"10px"}}>
                    <InputText
                      id={`name_${index}`}
                      name={`name_${index}`}
                      onChange={(e) => onchangedata(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.name ? row.name : row[`${'name'}_${index}`]}
                    
                    />
                    {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "7%",
                        cursor: "pointer",
                      }}
                      onClick={(e)=>openEmployeePopup(index)}
                    ></i>
                      )}
                  </div>
                  </td>
                  <td>
                  <Calendar
                    id={`eo_OV_DT_${index}`}
                    name={`eo_OV_DT_${index}`}
                    value={ row.eo_OV_DT ? moment(row.eo_OV_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange("eo_OV_DT", index, e.value)}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td >
                  <Dropdown
                    id={`eo_SH_FT_${index}`}
                    name={`eo_SH_FT_${index}`}
                    value={data[`eo_SH_FT_${index}`] ? data[`eo_SH_FT_${index}`]:row.eo_SH_FT }
                    // value={FormData.et_SH_FT}
                    options={eo_SH_FTOptions}
                    onChange={(e) => handleFormDataChange(`eo_SH_FT_${index}`, e.value)}
                    placeholder="Select SHIFT"
                  />
                  </td>
                  <td>
                    <InputText
                      id={`eo_OV_TM_${index}`}
                      name={`eo_OV_TM_${index}`}
                      defaultValue={row.eo_OV_TM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td >
                    <InputText
                      id={`eo_HO_TM_${index}`}
                      name={`eo_HO_TM_${index}`}
                      defaultValue={row.eo_HO_TM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>    
          </table>  
        </div> 
  </div>    
  <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressType}
      />
    </Dialog>
  );
}
