import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import JewelleryRejectionDialog from "./JewelleryRejectionDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  jdUkNo:"",
  jd_OR_NO:"",
  jd_OR_TY:"",
  jd_OR_CO:"",   
  jd_BG_NO:"", 
  jd_IT_NM:"",  
  item_NAME:"",
  jd_OR_QT:"",
  jd_WT_UM:"",   
  jd_LO_CN:"",
  jd_TR_DT:moment(moment().toDate()).format("YYYYMMDD"), 
  jd_BG_QT:"",
  jd_CO_QT:"",
  jd_HD_BK:"", 
  jd_OR_DT:moment(moment().toDate()).format("YYYYMMDD"),   
  jd_RQ_DT:moment(moment().toDate()).format("YYYYMMDD"),   
};

const initialFilters = {
  jd_OR_NO: { value: "", operation: "" },
  jd_OR_TY: { value: "", operation: "" },
  jd_OR_CO: { value: "", operation: "" },   
  jd_BG_NO: { value: "", operation: "" }, 
  jd_IT_NM: { value: "", operation: "" },  
  item_NAME: { value: "", operation: "" },
  jd_OR_QT: { value: "", operation: "" },  
  jd_WT_UM: { value: "", operation: "" },   
  jd_LO_CN: { value: "", operation: "" },
  jd_TR_DT: { value: "", operation: "" },   
  jd_BG_QT: { value: "", operation: "" },
  jd_CO_QT: { value: "", operation: "" },
  jd_HD_BK: { value: "", operation: "" },    
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,rightalined
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [jd_CO_CT, setjd_CO_CT ] = useState("");
  const [jd_BK_RS, setjd_BK_RS ] = useState("");
  const [jd_TR_UM, setjd_TR_UM ] = useState("");
  const [ep_EX_CT, setep_EX_CT ] = useState("");
  const [ep_EX_TY, setep_EX_TY ] = useState("");
  const [ep_LO_CN, setep_LO_CN ] = useState("");


  const [bd_TO_LO, setbd_TO_LO ] = useState("");

  const [jd_SC_UM, setjd_SC_UM ] = useState("");
  const [jd_LO_CN, setjd_LO_CN ] = useState("");
  const [bd_CO_CT, setbd_CO_CT ] = useState("");
  const [bd_DM_RS, setbd_DM_RS ] = useState("");
  const [jd_TO_LO, setjd_TO_LO ] = useState("");
  const [bd_OR_NO, setbd_OR_NO ] = useState("");

  const [jd_BG_NO, setjd_BG_NO ] = useState("");
  
// AdvanceSearch
const [SelectedTransferPerson, setSelectedTransferPerson ] = useState("");
  const [selectedSupplier, setselectedSupplier ] = useState("");
  const [SelectedITNM, setSelectedITNM ] = useState("");
  const [ep_PR_SU, setep_PR_SU ] = useState("");
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [JewelleryRejectionTableData, setJewelleryRejectionTableData] = useState([{}]);


  const LocationDropdown = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setbd_TO_LO(value);
  };
  const onchangeDropDownFormDataBagNo = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setjd_BG_NO(value);
  };

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };



  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setJewelleryRejectionTableData([{}]);
    setjd_CO_CT(null);
    setjd_BK_RS(null);
    setjd_TR_UM(null);
    setjd_SC_UM(null);
    setbd_CO_CT(null);
    setjd_TO_LO(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };


  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [jd_OR_CO , setjd_OR_CO] = useState("");
  const [jd_TR_CO , setjd_TR_CO] = useState("");
  const [jd_RL_CO , setjd_RL_CO] = useState("");

  const handleDefault = async () => {
    setjd_OR_CO(`${company}`)
    setjd_TR_CO(`${company}`)
    setjd_RL_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JewelTransferDetails/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/JewelleryBreakage/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );
      if (response.status === 200) {
        const CashFlowData = response.data;
        setJewelleryRejectionTableData(CashFlowData.jewelTransferDetailsList);
        setFormData(CashFlowData.jewelTransferDetailsList[0]);
        setSelectedITNM(CashFlowData.item_DESC);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const handleUpdate = async () => {
  //   setFormData(initialValue);
  //   if(selectedRows){
  //     const requestBodyInput = {
  //         jdUkNo:selectedRows[0]
  //       };
  //   console.log(requestBodyInput);
  //   try {
  //     const response = await axios.post(
  //       `${domain}/api/JewelTransferDetails/Select`,requestBodyInput,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": "http://localhost:3000",
  //           "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const CashFlowData = response.data.Result;
  //       setJewelleryRejectionTableData(CashFlowData.jewelTransferDetailsList);
  //       setFormData(CashFlowData.jewelTransferDetailsList[0]); 
  //       setSelectedTransferPerson(CashFlowData.transferPerson_DESC);
  //       setSelectedITNM(CashFlowData.item_DESC);
  //       handleClickOpen();
  //     } else {
  //       console.error(`API request failed with status: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  // };
var requestBody = {};
function values () {
  const table = document.getElementById("JewelleryRejectiontable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const jewelTransferDetailsList = [];
  for (let i = 0; i < totalRowCount1; i++) {
   
    const details = {
      jdUkNo : document.getElementById("jdUkNo_" + i).value,
      jd_LN_NO : document.getElementById("jd_LN_NO_" + i).value,
      jd_IT_NM : document.getElementById("jd_IT_NM_" + i).value,
      item_DESC : document.getElementById("item_DESC_" + i).value,
      jd_OR_QT : document.getElementById("jd_OR_QT_" + i).value,
      jd_TR_UM : table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
      jd_SC_QT : document.getElementById("jd_SC_QT_" + i).value,
      jd_SC_UM : table.tBodies[0].rows[i].cells[7].children[0].children[1].children[0].value,
      jd_LO_CN : table.tBodies[0].rows[i].cells[8].children[0].children[1].children[0].value,
      jd_CO_CT : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
      jd_UN_CT : document.getElementById("jd_UN_CT_" + i).value,
      jd_EX_CT : document.getElementById("jd_EX_CT_" + i).value,
      jd_FR_CT : document.getElementById("jd_FR_CT_" + i).value,
      jd_FR_AM : document.getElementById("jd_FR_AM_" + i).value,
    };
    jewelTransferDetailsList.push(details);
  }
 requestBody = {
  jewelTransferDetailsList,
  jdUkNo:FormData.jdUkNo,
  jd_TR_DT:FormData.jd_TR_DT,
  jd_OR_NO: document.getElementById("jd_OR_NO").value,
  jd_OR_TY: document.getElementById("jd_OR_TY").value,
  jd_OR_CO: document.getElementById("jd_OR_CO").value,
  jd_OR_DT: FormData.jd_OR_DT,
  jd_RL_NO: document.getElementById("jd_RL_NO").value,
  jd_RL_TY: document.getElementById("jd_RL_TY").value,
  jd_RL_CO: document.getElementById("jd_RL_CO").value,
  jd_IT_NM: document.getElementById("jd_IT_NM").value,
  jd_BG_NO: document.getElementById("jd_BG_NO").value,
  jd_BG_QT: document.getElementById("jd_BG_QT").value,
  jd_RQ_DT :FormData.jd_RQ_DT,

  jd_BK_RS: jd_BK_RS,
  };
}
  const handleFormSubmit = () => {
    values ();
    if (FormData.jdUkNo) {
      const confirm = window.confirm("Do you want to Rejection ?");
      const update = `${domain}/api/JewelleryBreakage/JewelleryRejection`;
      if (confirm) {
        axios
          .post(update, requestBody, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } 
  };
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Rejection
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      {/* <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button> */}
       {/* <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button> */}
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
            <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_OR_NO"] ? filters["jd_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_OR_NO",
                        e.target.value,
                        filters["jd_OR_NO"]
                          ? filters["jd_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_OR_TY"] ? filters["jd_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_OR_TY",
                        e.target.value,
                        filters["jd_OR_TY"]
                          ? filters["jd_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_OR_CO"] ? filters["jd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_OR_CO",
                        e.target.value,
                        filters["jd_OR_CO"]
                          ? filters["jd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              
                <td>
                  <input
                    type="text"
                    value={filters["jd_BG_NO"] ? filters["jd_BG_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_BG_NO",
                        e.target.value,
                        filters["jd_BG_NO"]
                          ? filters["jd_BG_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_IT_NM"] ? filters["jd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_IT_NM",
                        e.target.value,
                        filters["jd_IT_NM"]
                          ? filters["jd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["item_NAME"] ? filters["item_NAME"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "item_NAME",
                        e.target.value,
                        filters["item_NAME"]
                          ? filters["item_NAME"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_OR_QT"] ? filters["jd_OR_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_OR_QT",
                        e.target.value,
                        filters["jd_OR_QT"]
                          ? filters["jd_OR_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_WT_UM"] ? filters["jd_WT_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_WT_UM",
                        e.target.value,
                        filters["jd_WT_UM"]
                          ? filters["jd_WT_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_LO_CN"] ? filters["jd_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_LO_CN",
                        e.target.value,
                        filters["jd_LO_CN"]
                          ? filters["jd_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_TR_DT"] ? filters["jd_TR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_TR_DT",
                        e.target.value,
                        filters["jd_TR_DT"]
                          ? filters["jd_TR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_BG_QT"] ? filters["jd_BG_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_BG_QT",
                        e.target.value,
                        filters["jd_BG_QT"]
                          ? filters["jd_BG_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_CO_QT"] ? filters["jd_CO_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_CO_QT",
                        e.target.value,
                        filters["jd_CO_QT"]
                          ? filters["jd_CO_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["jd_HD_BK"] ? filters["jd_HD_BK"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "jd_HD_BK",
                        e.target.value,
                        filters["jd_HD_BK"]
                          ? filters["jd_HD_BK"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Type</th>  
                <th style={tableHeaderStyle}>Company</th>  
                <th style={tableHeaderStyle}>Bag No</th>
                <th style={tableHeaderStyle}>Item Name</th>  
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Order Quantity</th>   
                <th style={tableHeaderStyle}>UM</th>   
                <th style={tableHeaderStyle}>Location</th>   
                <th style={tableHeaderStyle}>Transaction Date</th>  
                <th style={tableHeaderStyle}>Bag Quantity</th> 
                <th style={tableHeaderStyle}>Completed  Quantity</th>  
                <th style={tableHeaderStyle}>Breakage Quantity</th> 

              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.jdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.jdUkNo}
                        checked={selectedRows.includes(rowData.jdUkNo)}
                        onChange={() => handleRowSelect(rowData.jdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.jd_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.jd_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.jd_OR_CO}</td>   
                    <td style={tableCellStyle}>{rowData.jd_BG_NO}</td> 
                    <td style={tableCellStyle}>{rowData.jd_IT_NM}</td>  
                    <td style={tableCellStyle}>{rowData.item_NAME}</td>
                    <td style={tableCellStyle}>{rowData.jd_OR_QT}</td>
                    <td style={tableCellStyle}>{rowData.jd_WT_UM}</td>   
                    <td style={tableCellStyle}>{rowData.jd_LO_CN}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.jd_TR_DT)}</td>   
                    <td style={tableCellStyle}>{rowData.jd_BG_QT}</td>
                    <td style={tableCellStyle}>{rowData.jd_CO_QT}</td>
                    <td style={tableCellStyle}>{rowData.jd_HD_BK}</td>                  

                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <JewelleryRejectionDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        JewelleryRejectionTableData={JewelleryRejectionTableData}
        setJewelleryRejectionTableData={setJewelleryRejectionTableData}
        selectedSupplier={selectedSupplier}
        setselectedSupplier={setselectedSupplier}
        SelectedITNM={SelectedITNM}
        setSelectedITNM={setSelectedITNM}
        bd_TO_LO={bd_TO_LO}
        setbd_TO_LO={setbd_TO_LO}
        jd_TR_UM={jd_TR_UM}
        setjd_TR_UM={setjd_TR_UM}
        jd_SC_UM={jd_SC_UM}
        setjd_SC_UM={setjd_SC_UM}
        jd_LO_CN={jd_LO_CN}
        setjd_LO_CN={setjd_LO_CN}
        bd_CO_CT={bd_CO_CT}
        setbd_CO_CT={setbd_CO_CT}
        bd_DM_RS={bd_DM_RS}
        setbd_DM_RS={setbd_DM_RS}
        jd_TO_LO={jd_TO_LO}
        setjd_TO_LO={setjd_TO_LO}
        LocationDropdown={LocationDropdown}
        bd_OR_NO={bd_OR_NO}
        setbd_OR_NO={setbd_OR_NO}
        onchangeDropDownFormDataBagNo={onchangeDropDownFormDataBagNo}
        jd_BG_NO={jd_BG_NO}
        setjd_BG_NO={setjd_BG_NO}
        SelectedTransferPerson={SelectedTransferPerson}
        setSelectedTransferPerson={setSelectedTransferPerson}
        jd_CO_CT={jd_CO_CT}
        setjd_CO_CT={setjd_CO_CT}
        jd_BK_RS={jd_BK_RS}
        setjd_BK_RS={setjd_BK_RS}
        jd_TR_CO={jd_TR_CO}
        jd_OR_CO={jd_OR_CO}
        jd_RL_CO={jd_RL_CO}
      />
    </div>
  );
};

export default WorkCentreMain;
