import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import WorkOrderEntryPopup from "../Popups/WorkOrderEntryPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function JewelleryTransferDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  JewelleryTransferTableData,
  setJewelleryTransferTableData,
  SelectedITNM,
  setSelectedITNM,
  SelectedTransferPerson,
  setSelectedTransferPerson,
  jd_TR_UM,
  setjd_TR_UM,
  jd_SC_UM,
  setjd_SC_UM,
  jd_LO_CN,
  setjd_LO_CN,
  jd_TO_LO,
  setjd_TO_LO,
  jd_CO_CT,
  setjd_CO_CT,
  jd_CO_CT2,
  setjd_CO_CT2,
  jd_RL_CO,
  jd_OR_CO,
  jd_TR_CO,
}) {
  const {
    jdUkNo,
    jd_TR_NO,
    jd_TR_TY,
    jd_TR_DT,
    jd_OR_NO,
    jd_OR_TY,
    jd_GL_DT,
    jd_RL_NO,
    jd_RL_TY,
   // jd_RL_CO,
    jd_IT_NM,
    jd_BG_QT,
    jd_BG_NO,
    jd_HD_TR,
    jd_TR_WT,
    jd_RL_PS,
    jd_TR_QT,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader, Dialogstyle, cancelstyle, savestyle } = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [jd_CO_CTOptions, setjd_CO_CTOptions] = useState([]);
  const [jd_CO_CT2Options, setjd_CO_CT2Options] = useState([]);
  const [jd_TR_UMOptions, setjd_TR_UMOptions] = useState([]);
  const [jd_SC_UMOptions, setjd_SC_UMOptions] = useState([]);
  const [jd_LO_CNOptions, setjd_LO_CNOptions] = useState([]);
  const [jd_TO_LOOptions, setjd_TO_LOOptions] = useState([]);
  const [bd_OR_TY, setbd_OR_TY] = useState([]);


  // UM
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_CO_CT(value);
  };

  // UM
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_CO_CT2(value);
  };

  // FrLocation
  const onchangeDropDownFormData3 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_TR_UM(value);
  };
  //Cost Center
  const onchangeDropDownFormData4 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_SC_UM(value);
  };

  const onchangeDropDownFormData5 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_LO_CN(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setjd_TO_LO(value);
  };

  useEffect(() => {
    DropDownFormData1();
    DropDownFormData2();
    DropDownFormData3();
    DropDownFormData4();
    DropDownFormData5();
    DropDownFormData6();


  }, []);


  const DropDownFormData1 = async () => {
    const Options = await fetchCostCenter();
    setjd_CO_CTOptions(Options);
  };
    const DropDownFormData2 = async () => {
    const Options = await fetchCostCenter();
    setjd_CO_CT2Options(Options);
  };
  const DropDownFormData3 = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setjd_TR_UMOptions(Options);
  };
  
  const DropDownFormData4 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setjd_SC_UMOptions(Options);
  };
  const DropDownFormData5 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setjd_LO_CNOptions(Options);
  };

  const DropDownFormData6 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setjd_TO_LOOptions(Options);
  };


  const addRow = () => {
    setJewelleryTransferTableData([...JewelleryTransferTableData, {}]);
  };

  const deleteRow = () => {
    if (JewelleryTransferTableData.length > 0) {
      const updatedData = [...JewelleryTransferTableData];
      updatedData.pop();
      setJewelleryTransferTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(jdUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
        tooltip={isUpdateMode ? "Transfer" : "Submit"}>
      </Button>
    </React.Fragment>
  );

  const onChangeOfOrderNo = async (wd_WO_NO, wd_WO_TY) => {
    //  setSelectedAbAdNM(data.ab_AD_NM);
    //  setsh_SH_TO(data.abAdNo);

    const Url = `${domain}/api/BagDetails/GetDetailsByWoNumber`;
    const requestData = {
      "bd_OR_NO": wd_WO_NO,
      "bd_OR_TY": wd_WO_TY
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const Resultdata = response.data;
      if (Resultdata) {
        document.getElementById("bd_TR_DT").value = Resultdata.WorkOrderEntryResult.wd_TR_DT;
        document.getElementById("bd_RL_NO").value = Resultdata.WorkOrderEntryResult.wd_RL_NO;
        document.getElementById("bd_RL_TY").value = Resultdata.WorkOrderEntryResult.wd_RL_TY;
        document.getElementById("bd_RL_CO").value = Resultdata.WorkOrderEntryResult.wd_RL_CO;
        //onchangeFormData("bd_OR_DT", Resultdata.WorkOrderEntryResult.wd_OR_DT);
        document.getElementById("bd_DE_NO").value = Resultdata.WorkOrderEntryResult.wd_IT_NM;//it_DES
        // onchangeFormData("bd_RQ_DT", Resultdata.WorkOrderEntryResult.wd_RQ_DT);
        document.getElementById("bd_WO_QT").value = Resultdata.WorkOrderEntryResult.wd_WO_QT;
        document.getElementById("bd_CO_QT").value = Resultdata.WorkOrderEntryResult.wd_CO_QT;
        // LocationDropdown("bd_TO_LO", Resultdata.WorkOrderEntryResult.wd_LO_CN);
        document.getElementById("bd_BG_NO").value = Resultdata.Result.bd_BG_NO;
      } else {
        console.error("Invalid API response:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setShipToPopupVisible(false);
    setShowSearchIcon(false);
    onChangeOfOrderNo1(wd_WO_NO, wd_WO_TY)
  };
  const onChangeOfOrderNo1 = async (wd_WO_NO, wd_WO_TY) => {
    //  setSelectedAbAdNM(data.ab_AD_NM);
    //  setsh_SH_TO(data.abAdNo);

    const Url = `${domain}/api/BagDetails/GetPartListDetailsByWoNumber`;
    const requestData = {
      "bd_OR_NO": wd_WO_NO,
      "bd_OR_TY": wd_WO_TY
    }
    try {
      const response = await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const Resultdata = response.data.Result;

      if (Resultdata && Array.isArray(Resultdata)) {//
        const result = Resultdata[0];

        document.getElementById(`jd_IT_NM_${SelectedRowIndex}`).value = result.bd_IT_NM;
        document.getElementById(`item_DESC_${SelectedRowIndex}`).value = result.bd_IT_NM;
        //onchangeDropDownFormData1(`jd_TR_UM_${SelectedRowIndex}`, result.bd_TR_UM);
       
        document.getElementById(`bd_SC_QT_${SelectedRowIndex}`).value = result.bd_SC_QT;
        // onchangeDropDownFormData2(`jd_SC_UM_${SelectedRowIndex}`, result.bd_SC_UM);
        onchangeDropDownFormData3(`jd_LO_CN_${SelectedRowIndex}`, result.bd_FR_LO);
        document.getElementById(`bd_AV_QT_${SelectedRowIndex}`).value = result.bd_AV_QT;
        onchangeDropDownFormData4(`bd_CO_CT_${SelectedRowIndex}`, result.bd_CO_CT);
        //document.getElementById(`bd_CO_CT_${SelectedRowIndex}`).value = result.bd_CO_CT;
        onchangeDropDownFormData5(`bd_DM_RS_${SelectedRowIndex}`, result.bd_DM_RS);
        onchangeDropDownFormData6(`bd_LN_NO_${SelectedRowIndex}`, result.bd_LN_NO);
        // document.getElementById(`bd_LN_NO_${SelectedRowIndex}`).value = result.bd_LN_NO;



      } else {
        console.error("Invalid API response:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };

  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [CarrierType, setCarrierType] = useState("E");
  const [TransferPersonPopupVisible, setTransferPersonPopupVisible] = useState(false);
  const [DesignPopupVisible, setDesignPopupVisible] = useState(false);



  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DESC", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("jd_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const openDesignPopup = () => {
    setDesignPopupVisible(true);
  };
  const handleDataSelectDesign = (Result) => {
    setSelectedITNM(Result.im_IT_DS);
    document.getElementById("jd_IT_NM").value = Result.im_IT_NM;
    setDesignPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openTransferPerson = () => {
    setTransferPersonPopupVisible(true);
  };

  const handleDataSelectTransferPerson = (data) => {
    setSelectedTransferPerson(data.ab_AD_NM);
    onchangeFormData("jd_RL_PS", data.abAdNo);
    setTransferPersonPopupVisible(false);
    setShowSearchIcon(false);
  };

  // DIALOG HEADER
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{jdUkNo ? "Revise Bagging Transfer" : "Add Bagging Transfer"}</h5>
      <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip={isUpdateMode ? "Transfer" : "Submit"}
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_NO" className="font-bold">
              Trans. No
            </label>
            <InputText disabled
              id="jd_TR_NO"
              name="jd_TR_NO"
              value={jd_TR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_TY" className="font-bold">
              Trans. Type
            </label>
            <InputText disabled
              id="jd_TR_TY"
              name="jd_TR_TY"
              value={jd_TR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_CO" className="font-bold">
              Trans. Company
            </label>
            <InputText disabled
              id="jd_TR_CO"
              name="jd_TR_CO"
              value={jd_TR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_DT" className="font-bold">
              Transaction Date
            </label>
            <Calendar
              id="jd_TR_DT"
              name="jd_TR_DT"
              value={moment(data.jd_TR_DT, "YYYYMMDD").isValid() ? moment(data.jd_TR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jd_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_NO" className="font-bold">
              Order No
            </label>
            <InputText disabled
              id="jd_OR_NO"
              name="jd_OR_NO"
              value={jd_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_TY" className="font-bold">
              Order Type
            </label>
            <InputText disabled
              id="jd_OR_TY"
              name="jd_OR_TY"
              value={jd_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_OR_CO" className="font-bold">
              Order Company
            </label>
            <InputText disabled
              id="jd_OR_CO"
              name="jd_OR_CO"
              value={jd_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_GL_DT" className="font-bold">
              GL Date
            </label>
            <Calendar
              id="jd_GL_DT"
              name="jd_GL_DT"
              value={moment(data.jd_GL_DT, "YYYYMMDD").isValid() ? moment(data.jd_GL_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "jd_GL_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_NO" className="font-bold">
              Related Order
            </label>
            <InputText disabled
              id="jd_RL_NO"
              name="jd_RL_NO"
              value={jd_RL_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_TY" className="font-bold">
              Related Type
            </label>
            <InputText disabled
              id="jd_RL_TY"
              name="jd_RL_TY"
              value={jd_RL_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_CO" className="font-bold">
              Related Company
            </label>
            <InputText disabled
              id="jd_RL_CO"
              name="jd_RL_CO"
              value={jd_RL_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_IT_NM" className="font-bold">
              Itme Number <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText disabled
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedITNM}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openDesignPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="jd_IT_NM"
                name="jd_IT_NM"
                defaultValue={jd_IT_NM}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>

          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_BG_NO" className="font-bold">
              Bag No
            </label>
            <InputText disabled
              id="jd_BG_NO"
              name="jd_BG_NO"
              value={jd_BG_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_BG_QT" className="font-bold">
              Bag Quantity
            </label>
            <InputText disabled
              id="jd_BG_QT"
              name="jd_BG_QT"
              value={jd_BG_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_CO_CT" className="font-bold">
              From CostCentre
            </label>
            <Dropdown disabled
              name="jd_CO_CT"
              value={jd_CO_CT}
              options={jd_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("jd_CO_CT", e.value)}
              placeholder="	From CostCentre"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_CO_CT2" className="font-bold">
              To CostCentre
            </label>
            <Dropdown disabled
              name="jd_CO_CT2"
              value={jd_CO_CT2}
              options={jd_CO_CT2Options}
              onChange={(e) => onchangeDropDownFormData2("jd_CO_CT2", e.value)}
              placeholder="	 To CostCentre"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_HD_TR" className="font-bold">
              Transferred Qty.
            </label>
            <InputText disabled
              id="jd_HD_TR"
              name="jd_HD_TR"
              value={jd_HD_TR}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_WT" className="font-bold">
              Weight
            </label>
            <InputText disabled
              id="jd_TR_WT"
              name="jd_TR_WT"
              value={jd_TR_WT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_RL_PS" className="font-bold">
              Transfer Person<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
                id="ab_AD_NM1"
                name="ab_AD_NM1"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedTransferPerson}
              />
              {showSearchIcon && (
                <i className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openTransferPerson}
                ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="jd_RL_PS"
                name="jd_RL_PS"
                value={jd_RL_PS}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="jd_TR_QT" className="font-bold">
              Transfer Quantity
            </label>
            <InputText
              id="jd_TR_QT"
              name="jd_TR_QT"
              value={jd_TR_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>


        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="JewelleryTransfertable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line No</th>
                <th>Item Name<span style={{ color: 'red' }}>*</span></th>
                <th>Description</th>
                <th>Transfer Quantity</th>
                <th>UM</th>
                <th>Sec. Quantity</th>
                <th>UM</th>
                <th>From Location</th>
                <th>Stock</th>
                <th>To Location</th>
              </tr>
            </thead>
            <tbody>
              {JewelleryTransferTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`jdUkNo_${index}`}
                      name={`jdUkNo_${index}`}
                      value={row.jdUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "90px" }}
                      id={`jd_LN_NO_${index}`}
                      name={`jd_LN_NO_${index}`}
                      value={row.jd_LN_NO}
                      readOnly
                    />
                  </td>
                  <td style={{ display: "flex" }}>
                    <InputText disabled
                      style={{ width: "110px" }}
                      id={`jd_IT_NM_${index}`}
                      name={`jd_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.jd_IT_NM ? row.jd_IT_NM : row[`${"jd_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup1(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DESC_${index}`}
                      name={`item_DESC_${index}`}
                      value={row.item_DESC ? row.item_DESC : row[`${"item_DESC"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`jd_OR_QT_${index}`}
                      name={`jd_OR_QT_${index}`}
                      defaultValue={row.jd_OR_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_TR_UM_${index}`}
                      name={`jd_TR_UM_${index}`}
                      value={data[`jd_TR_UM_${index}`] ? data[`jd_TR_UM_${index}`] : row.jd_TR_UM}
                      options={jd_TR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData3(`jd_TR_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`jd_SC_QT_${index}`}
                      name={`jd_SC_QT_${index}`}
                      defaultValue={row.jd_SC_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_SC_UM_${index}`}
                      name={`jd_SC_UM_${index}`}
                      value={data[`jd_SC_UM_${index}`] ? data[`jd_SC_UM_${index}`] : row.jd_SC_UM}
                      options={jd_SC_UMOptions}
                      onChange={(e) => onchangeDropDownFormData4(`jd_SC_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_LO_CN_${index}`}
                      name={`jd_LO_CN_${index}`}
                      value={data[`jd_LO_CN_${index}`] ? data[`jd_LO_CN_${index}`] : row.jd_LO_CN}
                      options={jd_LO_CNOptions}
                      onChange={(e) => onchangeDropDownFormData5(`jd_LO_CN_${index}`, e.value)}
                      placeholder="From Location"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`jd_AV_QT_${index}`}
                      name={`jd_AV_QT_${index}`}
                      defaultValue={row.jd_AV_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`jd_TO_LO_${index}`}
                      name={`jd_TO_LO_${index}`}
                      value={data[`jd_TO_LO_${index}`] ? data[`jd_TO_LO_${index}`] : row.jd_TO_LO}
                      options={jd_TO_LOOptions}
                      onChange={(e) => onchangeDropDownFormData6(`jd_TO_LO_${index}`, e.value)}
                      placeholder=" TO Location"
                      disabled
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      {/* Design  */}
      <ItemPopup
        visible={DesignPopupVisible}
        onClose={() => setDesignPopupVisible(false)}
        onDataSelect={handleDataSelectDesign}
        formData={data}
      />
      {/* TransferPerson */}
      <AddressBookPopup
        visible={TransferPersonPopupVisible}
        onClose={() => setTransferPersonPopupVisible(false)}
        onDataSelect={handleDataSelectTransferPerson}
        formData={data}
        addressType={CarrierType}
        setFormData={setFormData}
      />
    </Dialog>
  );
}
