import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ProgressBillingDialog from "./ProgressBillingDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  bdUkNo:"",
  bd_CN_NO:"",
  bd_CN_TY:"",
  bd_CN_CO:"",
  bd_CU_NO:"",
  bd_CR_CD:"",
  bd_EX_RT:"",
  bd_BS_CR:"",
  bd_BL_NM:"",
  bd_BL_TY:"",
  bill_TYPE:"",
  bd_ST_AG:"",
  stage:"",
  bd_BL_AM:"",
  bd_PE_CT:"",
  bd_BL_AM:"",
  bd_AM_FR:"",
  bd_AC_CP:"",
  bd_EX_CP:"",
  bd_BL_ST:"",
  status:"",
  bd_DS_AM:"",
  bd_AM_DM:"",
};

const initialFilters = {
  bd_CN_NO: { value: "", operation: "" },
  bd_CN_TY: { value: "", operation: "" },
  bd_CN_CO: { value: "", operation: "" },
  bd_CU_NO: { value: "", operation: "" },
  bd_CR_CD: { value: "", operation: "" },
  bd_EX_RT: { value: "", operation: "" },
  bd_BS_CR: { value: "", operation: "" },
  bd_BL_NM: { value: "", operation: "" },
  bd_BL_TY: { value: "", operation: "" },
  bill_TYPE: { value: "", operation: "" },
  bd_ST_AG: { value: "", operation: "" },
  stage: { value: "", operation: "" },
  bd_BL_AM: { value: "", operation: "" },
  bd_PE_CT: { value: "", operation: "" },
  bd_BL_AM: { value: "", operation: "" },
  bd_AM_FR: { value: "", operation: "" },
  bd_AC_CP: { value: "", operation: "" },
  bd_BL_ST: { value: "", operation: "" },
  status: { value: "", operation: "" },
  bd_EX_CP: { value: "", operation: "" },
};

const WorkCentreMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles,
    checkboxStyle,tableHeaderStyle,
    inputStyle,tableCellStyle,tableHeaderStyleH,
    inputStyleH,tableCellStyleH,
    } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  // drodpdowns
  const [bd_ST_AG, setbd_ST_AG] = useState([]);
  const [cb_ST_CD, setcb_ST_CD] = useState([]);
  const [bd_CR_CD, setbd_CR_CD ] = useState("");
  const [bd_CN_TY, setbd_CN_TY] = useState("");
  const [bd_CN_CO, setbd_CN_CO] = useState("");
  const [cb_EX_RT, setcb_EX_RT] = useState("");
  const [bd_BL_TY, setbd_BL_TY] = useState("");
  const [bd_BL_ST, setbd_BL_ST] = useState("");
  const [bd_DO_TY, setbd_DO_TY] = useState("");
  const [bd_EX_RT, setbd_EX_RT] = useState("");
  const [bd_BS_CR, setbd_BS_CR] = useState("");
  const [bd_CN_NO, setbd_CN_NO] = useState("");
  const [bd_DS_PE, setbd_DS_PE] = useState("");
  const [bd_DS_FR, setbd_DS_FR] = useState("");
  const [price_AMOUNT , setprice_AMOUNT] = useState("");
  const [discount_AMOUNT , setdiscount_AMOUNT] = useState("");
  const [net_AMOUNT , setnet_AMOUNT] = useState("");
  const [tax_AMOUNT , settax_AMOUNT] = useState("");
  const [fr_PRICE_AMOUNT , setfr_PRICE_AMOUNT] = useState("");
  const [fr_DISCOUNT_AMOUNT , setfr_DISCOUNT_AMOUNT] = useState("");
  const [fr_NET_AMOUNT , setfr_NET_AMOUNT] = useState("");
  const [fr_TAX_AMOUNT , setfr_TAX_AMOUNT] = useState("");
// AdvanceSearch
const [SelectedemEMNO, setSelectedemEMNO ] = useState("");
const [SelectedItem, setselectedItem ] = useState("");
const [SelectedDesc, setselectedDesc ] = useState("");
const [SelectedProjectMaster, setSelectedProjectMaster ] = useState("");
  const [ep_PR_SU, setep_PR_SU ] = useState("");
  const [bd_DO_NO, setbd_DO_NO] = useState("");

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [ProgressBillingTableData, setProgressBillingTableData] = useState([{}]);





  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setProgressBillingTableData([{}]);
    setSelectedemEMNO(null);
    setbd_BL_TY(null);
    setbd_CR_CD(null);
    setbd_CN_TY(null);
    setbd_CN_CO(null);
    setbd_BS_CR(null);
    setbd_EX_RT(null);
    setbd_CN_NO(null);
    setbd_DS_PE(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setProgressBillingTableData([{}]);
    setbd_BL_TY(null);
    setbd_CR_CD(null);
    setbd_CN_TY(null);
    setbd_CN_CO(null);
    setbd_BS_CR(null);
    setbd_EX_RT(null); 
    setbd_CN_NO(null);
    setbd_DS_PE(null);
    setselectedItem(null);
    setselectedDesc(null);
    setprice_AMOUNT(null);
    setdiscount_AMOUNT(null);
    setnet_AMOUNT(null);
    settax_AMOUNT(null);
    setfr_PRICE_AMOUNT(null);
    setfr_DISCOUNT_AMOUNT(null);
    setfr_NET_AMOUNT(null);
    setfr_TAX_AMOUNT(null);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ProgressBilling/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );
        if (response.status === 200) {
          const CashFlowData = response.data;
          delete CashFlowData.billingInvoiceGenerationList[0].bdUkNo;
          for (let i = 0; i < CashFlowData.billingInvoiceGenerationList.length; i++) {
            delete CashFlowData.billingInvoiceGenerationList[i].bdUkNo;
            delete CashFlowData.billingInvoiceGenerationList[i].bd_DO_NO;
          }
          setProgressBillingTableData(CashFlowData.billingInvoiceGenerationList);
          setFormData(CashFlowData.billingInvoiceGenerationList[0]);
          setSelectedemEMNO(CashFlowData.billingInvoiceGenerationList[0].name);
          setbd_CN_NO(CashFlowData.billingInvoiceGenerationList[0].bd_CN_NO);
          setbd_BL_TY(CashFlowData.billingInvoiceGenerationList[0].bd_BL_TY);
          setbd_CR_CD(CashFlowData.billingInvoiceGenerationList[0].bd_CR_CD);
          setbd_CN_TY(CashFlowData.billingInvoiceGenerationList[0].bd_CN_TY);
          setbd_CN_CO(CashFlowData.billingInvoiceGenerationList[0].bd_CN_CO);
          setbd_BS_CR(CashFlowData.billingInvoiceGenerationList[0].bd_BS_CR);
          setbd_EX_RT(CashFlowData.billingInvoiceGenerationList[0].bd_EX_RT);
          setbd_DS_PE(CashFlowData.billingInvoiceGenerationList[0].bd_DS_PE);
          setselectedItem(CashFlowData.billingInvoiceGenerationList[0].bd_BL_NM);
          setselectedDesc(CashFlowData.billingInvoiceGenerationList[0].itemDES);
          setbd_ST_AG(CashFlowData.billingInvoiceGenerationList.bd_ST_AG);

          setprice_AMOUNT(CashFlowData.total_AMOUNT);
          setdiscount_AMOUNT(CashFlowData.total_DISCOUNT);
          setnet_AMOUNT(CashFlowData.total_TAXABLE_AMOUNT);
          settax_AMOUNT(CashFlowData.total_TAX);
          setfr_PRICE_AMOUNT(CashFlowData.total_FOREIGN_AMOUNT);
          setfr_DISCOUNT_AMOUNT(CashFlowData.total_FOREIGN_DISCOUNT);
          setfr_NET_AMOUNT(CashFlowData.total_FOREIGN_TAXABLE_AMOUNT);
          setfr_TAX_AMOUNT(CashFlowData.total_FOREIGN_TAX);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [ep_CO_NO , setep_CO_NO] = useState("");

  const handleDefault = async () => {
    setep_CO_NO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "BD_DO_TY"){
              setbd_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ProgressBilling/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onchangeFormData = (name, value, index) => {
    setFormData((prevData) => ({...prevData, [name]: value, }));
    const fieldName=(FormData && FormData.bdUkNo)?`${name}`:`${name}_${index}`;
    if(index!=null)
      ProgressBillingTableData[index][fieldName]=value;
    
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ProgressBilling/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const CashFlowData = response.data;
          setProgressBillingTableData(CashFlowData.billingInvoiceGenerationList);
          setFormData(CashFlowData.billingInvoiceGenerationList[0]);
          setSelectedemEMNO(CashFlowData.billingInvoiceGenerationList[0].name);
          setbd_BL_TY(CashFlowData.billingInvoiceGenerationList[0].bd_BL_TY);
          setbd_CR_CD(CashFlowData.billingInvoiceGenerationList[0].bd_CR_CD);
          setbd_CN_TY(CashFlowData.billingInvoiceGenerationList[0].bd_CN_TY);
          setbd_CN_CO(CashFlowData.billingInvoiceGenerationList[0].bd_CN_CO);
          setbd_BS_CR(CashFlowData.billingInvoiceGenerationList[0].bd_BS_CR);
          setbd_EX_RT(CashFlowData.billingInvoiceGenerationList[0].bd_EX_RT);
          setbd_CN_NO(CashFlowData.billingInvoiceGenerationList[0].bd_CN_NO);
          setbd_DS_PE(CashFlowData.billingInvoiceGenerationList[0].bd_DS_PE);
          setbd_DS_FR(response.data.bd_DS_FR);
          setselectedItem(CashFlowData.billingInvoiceGenerationList[0].bd_BL_NM);
          setselectedDesc(CashFlowData.billingInvoiceGenerationList[0].itemDES);
          setbd_ST_AG(CashFlowData.billingInvoiceGenerationList.bd_ST_AG);

          setprice_AMOUNT(CashFlowData.total_AMOUNT);
          setdiscount_AMOUNT(CashFlowData.total_DISCOUNT);
          setnet_AMOUNT(CashFlowData.total_TAXABLE_AMOUNT);
          settax_AMOUNT(CashFlowData.total_TAX);
          setfr_PRICE_AMOUNT(CashFlowData.total_FOREIGN_AMOUNT);
          setfr_DISCOUNT_AMOUNT(CashFlowData.total_FOREIGN_DISCOUNT);
          setfr_NET_AMOUNT(CashFlowData.total_FOREIGN_TAXABLE_AMOUNT);
          setfr_TAX_AMOUNT(CashFlowData.total_FOREIGN_TAX);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
var requestBodyInput = {};
function values () {
  const table = document.getElementById("ProgressBillingtable");
  const totalRowCount1 = table.tBodies[0].rows.length;
  const billingInvoiceGenerationList = [];
  for (let i = 0; i < totalRowCount1; i++) {
    const AC_CP = document.getElementsByName("bd_AC_CP_" + i)[0];
    const EX_CP = document.getElementsByName("bd_EX_CP_" + i)[0];
    const IN_DT = document.getElementsByName("bd_IN_DT_" + i)[0];
    const details = {
        bdUkNo : document.getElementById("bdUkNo_" + i).value,
        bd_ST_AG : table.tBodies[0].rows[i].cells[1].children[0].children[1].children[0].value,
        bd_PE_CT : document.getElementById("bd_PE_CT_" + i).value,
        bd_AM_DM : document.getElementById("bd_AM_DM_" + i).value,
        bd_DS_AM : document.getElementById("bd_DS_AM_" + i).value,
        bd_TX_AM : document.getElementById("bd_TX_AM_" + i).value,
        bd_AM_FR : document.getElementById("bd_AM_FR_" + i).value,
        bd_DS_FR : document.getElementById("bd_DS_FR_" + i).value,
        bd_TX_FR : document.getElementById("bd_TX_FR_" + i).value,
        bd_BL_ST : table.tBodies[0].rows[i].cells[9].children[0].children[1].children[0].value,
        bd_AC_CP : AC_CP.value?moment(AC_CP.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        bd_EX_CP : EX_CP.value?moment(EX_CP.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        bd_IN_DT : IN_DT.value?moment(IN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
        bd_DO_NO : FormData["bd_DO_NO_" + i],
        bd_DO_TY : document.getElementById("bd_DO_TY_" + i).value,
    };
    billingInvoiceGenerationList.push(details);
  }
  requestBodyInput = {
  billingInvoiceGenerationList,
    bdUkNo:FormData.bdUkNo,
    bd_CN_NO : document.getElementById("bd_CN_NO").value,
    bd_CN_TY : document.getElementById("bd_CN_TY").value,
    bd_CN_CO : document.getElementById("bd_CN_CO").value,
    bd_CU_NO : document.getElementById("bd_CU_NO").value,
    bd_BL_NM : document.getElementById("bd_BL_NM").value,
    bd_CR_CD : FormData.bd_CR_CD,
    bd_EX_RT : document.getElementById("bd_EX_RT").value,
    bd_BS_CR : document.getElementById("bd_BS_CR").value,
    bd_BL_TY : FormData.bd_BL_TY,
    bd_BL_AM : document.getElementById("bd_BL_AM").value,
    bd_TX_PE : document.getElementById("bd_TX_PE").value,
    bd_DS_PE : document.getElementById("bd_DS_PE").value,
  };
}
const handleFormSubmit = () => {
  const i = 0;
  values();
  if (FormData["bd_DO_NO_" + i]) {
  if (FormData.bdUkNo) {
    const confirm = window.confirm("Do you want to Update ?");
    const update = `${domain}/api/ProgressBilling/Update`;
    if (confirm) {
      axios
        .put(update, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  } else {
    axios.post(`${domain}/api/ProgressBilling/Add`, requestBodyInput, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        handleResponse(resp.data);
      })
      .catch((error) => {
        console.error("Error adding data:", error);
      });
  }
    } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstHalf = Math.floor(currentYear / 100);
        const secondHalf = String(currentYear % 100).padStart(2, "0");
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${bd_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
        const finalUrl1 = `${urlNextNumber}/${parameters}`;

        axios.get(finalUrl1, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
            setbd_DO_NO(resp.data.dn_DO_NO);
            FormData["bd_DO_NO_" + i] = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/ProgressBilling/Add`, requestBodyInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
  
    }
  };
   
   

  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };

  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",border:"none",backgroundColor:"#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }} >
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_CN_NO"] ? filters["bd_CN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_CN_NO",
                        e.target.value,
                        filters["bd_CN_NO"]
                          ? filters["bd_CN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_CN_TY"] ? filters["bd_CN_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_CN_TY",
                        e.target.value,
                        filters["bd_CN_TY"] ? filters["bd_CN_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_CN_CO"] ? filters["bd_CN_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_CN_CO",
                        e.target.value,
                        filters["bd_CN_CO"]
                          ? filters["bd_CN_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>      
                
                <td>
                  <input
                    type="text"
                    value={filters["bd_CU_NO"] ? filters["bd_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_CU_NO",
                        e.target.value,
                        filters["bd_CU_NO"]
                          ? filters["bd_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_CR_CD"] ? filters["bd_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_CR_CD",
                        e.target.value,
                        filters["bd_CR_CD"] ? filters["bd_CR_CD"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["bd_EX_RT"] ? filters["bd_EX_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_EX_RT",
                        e.target.value,
                        filters["bd_EX_RT"]
                          ? filters["bd_EX_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>     
                <td>
                  <input
                    type="text"
                    value={filters["bd_BS_CR"] ? filters["bd_BS_CR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BS_CR",
                        e.target.value,
                        filters["bd_BS_CR"]
                          ? filters["bd_BS_CR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_BL_NM"] ? filters["bd_BL_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_NM",
                        e.target.value,
                        filters["bd_BL_NM"]
                          ? filters["bd_BL_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["bd_BL_TY"] ? filters["bd_BL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_TY",
                        e.target.value,
                        filters["bd_BL_TY"]
                          ? filters["bd_BL_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["bill_TYPE"] ? filters["bill_TYPE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bill_TYPE",
                        e.target.value,
                        filters["bill_TYPE"]
                          ? filters["bill_TYPE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>    
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["bd_ST_AG"] ? filters["bd_ST_AG"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_ST_AG",
                        e.target.value,
                        filters["bd_ST_AG"]
                          ? filters["bd_ST_AG"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["stage"] ? filters["stage"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "stage",
                        e.target.value,
                        filters["stage"]
                          ? filters["stage"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_BL_AM"] ? filters["bd_BL_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_AM",
                        e.target.value,
                        filters["bd_BL_AM"]
                          ? filters["bd_BL_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_PE_CT"] ? filters["bd_PE_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_PE_CT",
                        e.target.value,
                        filters["bd_PE_CT"]
                          ? filters["bd_PE_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_BL_AM"] ? filters["bd_BL_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_AM",
                        e.target.value,
                        filters["bd_BL_AM"]
                          ? filters["bd_BL_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_AM_FR"] ? filters["bd_AM_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_AM_FR",
                        e.target.value,
                        filters["bd_AM_FR"]
                          ? filters["bd_AM_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td>
                  <input
                    type="text"
                    value={filters["bd_EX_CP"] ? filters["bd_EX_CP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_EX_CP",
                        e.target.value,
                        filters["bd_EX_CP"]
                          ? filters["bd_EX_CP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                <td style={{display:"none"}}>
                  <input
                    type="text"
                    value={filters["bd_BL_ST"] ? filters["bd_BL_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_BL_ST",
                        e.target.value,
                        filters["bd_BL_ST"]
                          ? filters["bd_BL_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>  
                <td>
                  <input
                    type="text"
                    value={filters["status"] ? filters["status"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "status",
                        e.target.value,
                        filters["status"]
                          ? filters["status"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>    
                <td>
                  <input
                    type="text"
                    value={filters["bd_AC_CP"] ? filters["bd_AC_CP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "bd_AC_CP",
                        e.target.value,
                        filters["bd_AC_CP"]
                          ? filters["bd_AC_CP"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>   
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Contract No </th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Currency</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Base Currency</th>
                <th style={tableHeaderStyle}>Billing Item</th>
                <th style={tableHeaderStyleH}>Bill Type</th> 
                <th style={tableHeaderStyle}>Bill Type</th> 
                <th style={tableHeaderStyleH}>Stage</th>  
                <th style={tableHeaderStyle}>Stage</th>    
                <th style={tableHeaderStyle}>Bill Amount</th>   
                <th style={tableHeaderStyle}>%</th>  
                <th style={tableHeaderStyle}>Progress Amount</th> 
                <th style={tableHeaderStyle}>Progress Amount -Foreign</th>   
                <th style={tableHeaderStyle}>Exp Compl Date</th> 
                <th style={tableHeaderStyleH}>Status</th> 
                <th style={tableHeaderStyle}>Status</th>      
                <th style={tableHeaderStyle}>Completion Date</th>            
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.bdUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.bdUkNo}
                        checked={selectedRows.includes(rowData.bdUkNo)}
                        onChange={() => handleRowSelect(rowData.bdUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.bd_CN_NO}</td>
                    <td style={tableCellStyle}>{rowData.bd_CN_TY}</td>
                    <td style={tableCellStyle}>{rowData.bd_CN_CO}</td>
                    <td style={tableCellStyle}>{rowData.bd_CU_NO}</td>   
                    <td style={tableCellStyle}>{rowData.bd_CR_CD}</td>   
                    <td style={tableCellStyle}>{rowData.bd_EX_RT}</td>
                    <td style={tableCellStyle}>{rowData.bd_BS_CR}</td>
                    <td style={tableCellStyle}>{rowData.bd_BL_NM}</td>    
                    <td style={tableCellStyleH}>{rowData.bd_BL_TY}</td>
                    <td style={tableCellStyle}>{rowData.bill_TYPE}</td>
                    <td style={tableCellStyleH}>{rowData.bd_ST_AG}</td>
                    <td style={tableCellStyle}>{rowData.stage}</td>    
                    <td style={tableCellStyle}>{rowData.bd_BL_AM}</td>
                    <td style={tableCellStyle}>{rowData.bd_PE_CT}</td>    
                    <td style={tableCellStyle}>{rowData.bd_BL_AM}</td>
                    <td style={tableCellStyle}>{rowData.bd_AM_FR}</td>   
                    <td style={tableCellStyle}>{formatDate(rowData.bd_EX_CP)}</td>
                    <td style={tableCellStyleH}>{rowData.bd_BL_ST}</td> 
                    <td style={tableCellStyle}>{rowData.status}</td>       
                    <td style={tableCellStyle}>{formatDate(rowData.bd_AC_CP)}</td>        
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <ProgressBillingDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        ProgressBillingTableData={ProgressBillingTableData}
        setProgressBillingTableData={setProgressBillingTableData}
        ep_CO_NO={ep_CO_NO}
        ep_PR_SU={ep_PR_SU}
        bd_ST_AG={bd_ST_AG}
        setbd_ST_AG={setbd_ST_AG}
        cb_ST_CD={cb_ST_CD}
        setcb_ST_CD={setcb_ST_CD}
        bd_CR_CD={bd_CR_CD}
        setbd_CR_CD={setbd_CR_CD}
        cb_EX_RT={cb_EX_RT}
        setcb_EX_RT={setcb_EX_RT}
        bd_BL_TY={bd_BL_TY}
        setbd_BL_TY={setbd_BL_TY}
        bd_BL_ST={bd_BL_ST}
        setbd_BL_ST={setbd_BL_ST}
        SelectedProjectMaster={SelectedProjectMaster}
        setSelectedProjectMaster={setSelectedProjectMaster}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedItem={SelectedItem}
        setselectedItem={setselectedItem}
        SelectedDesc={SelectedDesc}
        setselectedDesc={setselectedDesc}
        bd_DO_TY={bd_DO_TY}
        setbd_DO_TY={setbd_DO_TY}
        price_AMOUNT={price_AMOUNT}
        setprice_AMOUNT={setprice_AMOUNT}
        discount_AMOUNT={discount_AMOUNT}
        setdiscount_AMOUNT={setdiscount_AMOUNT}
        setnet_AMOUNT={setnet_AMOUNT}
        net_AMOUNT={net_AMOUNT}
        settax_AMOUNT={settax_AMOUNT}
        tax_AMOUNT={tax_AMOUNT}
        fr_PRICE_AMOUNT={fr_PRICE_AMOUNT}
        setfr_PRICE_AMOUNT={setfr_PRICE_AMOUNT}
        fr_DISCOUNT_AMOUNT={fr_DISCOUNT_AMOUNT}
        setfr_DISCOUNT_AMOUNT={setfr_DISCOUNT_AMOUNT}
        fr_NET_AMOUNT={fr_NET_AMOUNT}
        setfr_NET_AMOUNT={setfr_NET_AMOUNT}
        fr_TAX_AMOUNT={fr_TAX_AMOUNT}
        setfr_TAX_AMOUNT={setfr_TAX_AMOUNT}
        bd_CN_TY={bd_CN_TY}
        bd_CN_CO={bd_CN_CO}
        bd_EX_RT={bd_EX_RT}
        bd_BS_CR={bd_BS_CR}
        bd_CN_NO={bd_CN_NO}
        setbd_CN_NO={setbd_CN_NO}
        bd_DS_PE={bd_DS_PE}
        setbd_DS_PE={setbd_DS_PE}
        bd_DS_FR={bd_DS_FR}
        setbd_DS_FR={setbd_DS_FR}
      />
    </div>
  );
};

export default WorkCentreMain;
