import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
export default function PaymentTermDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  pt_CO_NO,

}) {
  const { 
    ptUkNo,
    pt_PY_CO,
    pt_PY_DS,
    pt_NO_DA,
  } = data;
  const {borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const isUpdateMode = Boolean(ptUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ptUkNo ? "Revise Payment Term" : "Add Payment Term"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_PY_CO" className="font-bold">
                    Payment Term
                  </label>
                  <InputText
                    id="pt_PY_CO"
                    name="pt_PY_CO"
                    value={pt_PY_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_PY_DS" className="font-bold">
                    Description
                  </label>
                  <InputText
                    id="pt_PY_DS"
                    name="pt_PY_DS"
                    value={pt_PY_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_NO_DA" className="font-bold">
                    No. of Days
                  </label>
                  <InputText
                    id="pt_NO_DA"
                    name="pt_NO_DA"
                    value={pt_NO_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
            
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="pt_CO_NO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="pt_CO_NO"
                    name="pt_CO_NO"
                    value={pt_CO_NO}
                    readOnly={true}
                    
                  />
                </div>
              </div>
         </div>
      </div>  
    </Dialog>
  );
}
