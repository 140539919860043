import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function AlternativeDescriptionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  AlternativeDescriptionTableData,
  setAlternativeDescriptionTableData,
  SelectedItem,
  setselectedItem,
  SelectedDesc,
  setselectedDesc,
  ad_AD_TY,
  setad_AD_TY,
  ad_CO_NO,
}) {
  const {
    adUkNo,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [ad_AD_TYOptions, setad_AD_TYOptions] = useState([]);
  //Type
  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setad_AD_TY(value);
  };
  useEffect(() => {
    Type();
  }, []);
  const Type = async () => {
    const Options = await fetchClassCodeCD("IN/AD");
    setad_AD_TYOptions(Options);
  };
  const addRow = () => {
    setAlternativeDescriptionTableData([...AlternativeDescriptionTableData, {}]);
  };

  const deleteRow = () => {
    if (AlternativeDescriptionTableData.length > 0) {
      const updatedData = [...AlternativeDescriptionTableData];
      updatedData.pop();
      setAlternativeDescriptionTableData(updatedData);
    }
  };
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const handleItem = async (data) => {
    setselectedItem(data.im_IT_NM)
    setselectedDesc(data.im_IT_DS)
    onchangeFormData("ad_IT_NM", data.im_IT_NM);
    onchangeFormData("itemDES", data.im_IT_DS);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  const isUpdateMode = Boolean(adUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{adUkNo ? "Revise Alternative Description" : "Add Alternative Description"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div style={inboxstyle}>
            <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ad_IT_NM" className="font-bold">
                  Item No <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText
                    id="ad_IT_NM"
                    name="ad_IT_NM"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedItem}
                  />
                  {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openItemPopup}
                    ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="itemDES" className="font-bold">
                  Description
                </label>
                <InputText disabled
                  id="itemDES"
                  name="itemDES"
                  value={SelectedDesc}
                  readOnly
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ad_CO_NO" className="font-bold">
                  Company
                </label>
                <InputText disabled
                  id="ad_CO_NO"
                  name="ad_CO_NO"
                  value={ad_CO_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>

            </div>
          </div>
            <div  style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
                {(
                <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                )}
                {(
                <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="AlternativeDescriptiontable">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK NO</th>
                    <th>Type</th>
                    <th>Alternative Description</th>
                  </tr>
                </thead>
                <tbody>
                  {AlternativeDescriptionTableData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`adUkNo_${index}`}
                          name={`adUkNo_${index}`}
                          value={row.adUkNo}
                        />
                      </td>
                      <td>
                        <Dropdown 
                          id={`ad_AD_TY_${index}`}
                          name={`ad_AD_TY_${index}`}
                          value={data[`ad_AD_TY_${index}`] ? data[`ad_AD_TY_${index}`] : row.ad_AD_TY}
                          options={ad_AD_TYOptions}
                          onChange={(e) => TypeDropdown(`ad_AD_TY_${index}`, e.value)}
                          placeholder="Select type"
                        />
                      </td>
                      <td >
                        <InputText
                          id={`ad_IT_AD_${index}`}
                          name={`ad_IT_AD_${index}`}
                          defaultValue={row.ad_IT_AD}
                          onChange={(e) => onchangedata(e)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
      </div>
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
