import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
export default function UpdateARDialog({
  open,
  handleClose,
  data,
  setFormData,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  addRow,
  deleteRow,
  UpdateARtable,
  SelectedemEMNO,
  SelectedemEMNOE,
  setSelectedemEMNO,
  setSelectedemEMNOE,
}) {
  const { 
  sd_UK_NO,
  shUkNo,
  sd_GL_DT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
  const [addressTypecust, setaddressTypecust] = useState("C");
  const openCustomerPopup = (e) => {
    setCustomerPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect = async (data) => {
    setSelectedemEMNO(data.abAdNo);
    setSelectedemEMNOE(data.ab_AD_NM);
    onchangeFormData("sh_CU_NO", data.abAdNo);
    onchangeFormData("cus_NAME", data.ab_AD_NM);
    setCustomerPopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(shUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{shUkNo ? "Revise Update AR" : "Add Update AR"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}> 
            <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                <label htmlFor="shUkNo" className="font-bold">
                  UKNO
                </label>
                <InputText
                  id="shUkNo"
                  name="shUkNo"
                  value={shUkNo}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="sh_CU_NO" className="font-bold">
                Customer<span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{display:"flex"}}>
                <InputText disabled
                  id="sh_CU_NO"
                  name="sh_CU_NO"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  defaultValue={SelectedemEMNO}
                />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                  </div>
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="cus_NAME" className="font-bold">
                  Name
                  </label>
                  <InputText disabled
                    id="cus_NAME"
                    name="cus_NAME"
                    value={SelectedemEMNOE}
                  />
                </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="sd_GL_DT" className="font-bold">
                  GL Date
                </label>
                <Calendar
                  id="sd_GL_DT"
                  name="sd_GL_DT"
                  value={moment(sd_GL_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => onchangedata({ target: { name: "sd_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="UpdateARtable" style={{overflow:"auto"}}>
            <thead>
              <tr>
                <th style={{ display: "none" }} >UK_NO</th>
                  <th >Order No</th>
                  <th >Order Type</th>
                  <th >Order Company</th>
                  <th >Line No</th>
                  <th >Item Name</th>
                  <th >Item Description</th>
                  <th >Shipped Quantity</th>
                  <th >UM</th>
                  <th >Location</th>
                  <th >Lot No</th>
                  <th >Unit Price</th>
                  <th >Price Amount</th>
                  <th >Pick No</th>
                  <th >Shipment No</th>
                  <th >Cost Center</th>
                  <th >Invoice No</th>
                  <th >Invoice type</th>
                  <th >Invoice Company</th>
                  <th >Invoice Date</th>
                  <th >Status</th>
              </tr>
            </thead>
            <tbody>
            {UpdateARtable.map((row, index) => (
                <tr key={index}>
                    <td style={{ display: "none" }} >
                    <InputText style={{width:'8rem'}}
                      id={`sd_UK_NO_${index}`}
                      name={`sd_UK_NO_${index}`}
                      defaultValue={row.sd_UK_NO}
                    />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sdOrNo_${index}`}
                    name={`sdOrNo_${index}`}
                    defaultValue={row.sdOrNo}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sdOrTy_${index}`}
                    name={`sdOrTy_${index}`}
                    defaultValue={row.sdOrTy}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_OR_CO_${index}`}
                    name={`sd_OR_CO_${index}`}
                    defaultValue={row.sd_OR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_LN_NO_${index}`}
                    name={`sd_LN_NO_${index}`}
                    defaultValue={row.sd_LN_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_IT_NM_${index}`}
                    name={`sd_IT_NM_${index}`}
                    defaultValue={row.sd_IT_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`im_IT_DS_${index}`}
                    name={`im_IT_DS_${index}`}
                    defaultValue={row.im_IT_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_SH_QT_${index}`}
                    name={`sd_SH_QT_${index}`}
                    defaultValue={row.sd_SH_QT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_TR_UM_${index}`}
                    name={`sd_TR_UM_${index}`}
                    defaultValue={row.sd_TR_UM}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:'8rem'}}
                    id={`sd_LO_CN_${index}`}
                    name={`sd_LO_CN_${index}`}
                    defaultValue={row.sd_LO_CN}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:'8rem'}}
                    id={`sd_LO_NO_${index}`}
                    name={`sd_LO_NO_${index}`}
                    defaultValue={row.sd_LO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <InputText disabled style={{width:'8rem'}}
                    id={`sd_UT_PR_${index}`}
                    name={`sd_UT_PR_${index}`}
                    defaultValue={row.sd_UT_PR}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_EX_PR_${index}`}
                    name={`sd_EX_PR_${index}`}
                    defaultValue={row.sd_EX_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_PK_NO_${index}`}
                    name={`sd_PK_NO_${index}`}
                    defaultValue={row.sd_PK_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_SH_NO_${index}`}
                    name={`sd_SH_NO_${index}`}
                    defaultValue={row.sd_SH_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_CO_CT_${index}`}
                    name={`sd_CO_CT_${index}`}
                    defaultValue={row.sd_CO_CT}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_DO_NO_${index}`}
                    name={`sd_DO_NO_${index}`}
                    defaultValue={row.sd_DO_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_DO_TY_${index}`}
                    name={`sd_DO_TY_${index}`}
                    defaultValue={row.sd_DO_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_DO_CO_${index}`}
                    name={`sd_DO_CO_${index}`}
                    defaultValue={row.sd_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  <td>
                  <Calendar  disabled style={{width:'8rem'}}
                    id={`sd_IN_DT_${index}`}
                    name={`sd_IN_DT_${index}`}
                    value={ row.sd_IN_DT ? moment(row.sd_IN_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "sd_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                  </td>
                  <td>
                  <InputText  disabled style={{width:'8rem'}}
                    id={`sd_ST_CD_${index}`}
                    name={`sd_ST_CD_${index}`}
                    defaultValue={row.sd_ST_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                  </td>
                  </tr>
                ))}
            </tbody>
          </table>
      </div>
        </div>  
      </div> 
      {/* customer */}
      <AddressBookPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={addressTypecust}
        setFormData={setFormData}
      />
      {/* <ShipToPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
      /> */}
    </Dialog>
  );
}
