import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ItemPopup from "../Popups/ItemPopup";
import VehicleMasterPopup from "../Popups/VehicleMasterPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ClassCode from "views/common/ClassCode";
import PayTerm from "views/common/PayTermDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
import { InputMask } from "primereact/inputmask";
export default function LoadBuildingDetailsDialog({
  open,
  handleClose,
  data,
  FormData,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  LoadBuildingDetailsTable,
  setLoadBuildingDetailsTable,

  handleConfirm,
  f_WEIGHT,
  f_VOLUME,
  // ADSVANCSE SEACRH
  SelectedSold,
  setSelectedSold,
  SelectedShip,
  setSelectedShip,
  SelectedCarrier,
  setSelectedCarrier,
  lb_PL_DE,
  setlb_PL_DE,
  lb_VE_TY,
  setlb_VE_TY,
  lb_ST_AT,
  setlb_ST_AT,
  lb_RT_CD,
  setlb_RT_CD,
  lb_ZN_CD,
  setlb_ZN_CD,
  lb_MD_TR,
  setlb_MD_TR,
  lb_CN_RY,
  setlb_CN_RY,
  lb_CI_TY,
  setlb_CI_TY,
  lb_ST_CD,
  setlb_ST_CD,
  lb_WT_UM,
  setlb_WT_UM,
  lb_VL_UM,
  setlb_VL_UM,
  lb_OR_DE,
  setlb_OR_DE,
  lb_SH_DE,
  setlb_SH_DE,
  lb_DE_DE,
  setlb_DE_DE,
  lb_DS_GP,
  setlb_DS_GP,
  SelectedVehicleID,
  setSelectedVehicleID,
  shipto_NAME,
  setshipto_NAME,
  lb_LD_TY,
  setlb_LD_TY,
  la_SH_TM,
  lb_VH_ID,
  lb_CO_NO,
}) {
  const {
    lbUkNo,
    lb_LD_NO,
    lb_CR_NO,
    la_SH_DT,
    lb_SH_NO,
    lb_PO_CD,
    lb_WE_GT,
    lb_VL_ME,
    lb_DL_DT,
    lb_DL_TM,
    lb_SH_TO,
  } = data || {};

  const { footergridstyle, footerfieldstyle ,borderbox,inboxstyle,savestyle,
     plusstyle,tablebutton, minusstyle,cancelstyle,Dialogstyle,
     tablestyle, multitablestyle,} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "la_SH_DT", value: moment(e.value).format("YYYYMMDD") } });
  };

  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const { fetchPayTerm } = PayTerm();
  const [lb_PL_DEOptions, setlb_PL_DEOptions] = useState([]);
  const [lb_VE_TYOptions, setlb_VE_TYOptions] = useState([]);
  const [lb_ST_ATOptions, setlb_ST_ATOptions] = useState([]);

  const [lb_RT_CDOptions, setlb_RT_CDOptions] = useState([]);
  const [lb_ZN_CDOptions, setlb_ZN_CDOptions] = useState([]);
  const [lb_MD_TROptions, setlb_MD_TROptions] = useState([]);
  const [lb_CN_RYOptions, setlb_CN_RYOptions] = useState([]);
  const [lb_CI_TYOptions, setlb_CI_TYOptions] = useState([]);
  const [lb_ST_CDOptions, setlb_ST_CDOptions] = useState([]);
  const [lb_WT_UMOptions, setlb_WT_UMOptions] = useState([]);
  const [lb_VL_UMOptions, setlb_VL_UMOptions] = useState([]);
  const [lb_OR_DEOptions, setlb_OR_DEOptions] = useState([]);
  const [lb_SH_DEOptions, setlb_SH_DEOptions] = useState([]);
  const [lb_DE_DEOptions, setlb_DE_DEOptions] = useState([]);
  const [lb_DS_GPOptions, setlb_DS_GPOptions] = useState([]);
  const [lb_LD_TYOptions, setlb_LD_TYOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onchangeDropDownFormDataType = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_LD_TY(value);
  };
  //PlanningDepot
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_PL_DE(value);
  };
  //Vehicle Type
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_VE_TY(value);
  };
  //Status
  const onchangeDropDownFormData3 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_ST_AT(value);
  };
  //RouteCode
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_RT_CD(value);
  };
  // Zone
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_ZN_CD(value);
  };
  // Mod of Transport
  const onchangeDropDownFormData6 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_MD_TR(value);
  };
  //Country
  const onchangeDropDownFormData7 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_CN_RY(value);
  };
  // City
  const OnChaneDropdownFormDateCity = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_CI_TY(value);
  };
  //State
  const onchangeDropDownFormDataState = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_ST_CD(value);
  };
  //WeigthUM
  const onchangeDropDownFormDataWeightUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_WT_UM(value);
  };
  //VolumeUM       
  const onchangeDropDownFormDataVolumeUM = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_VL_UM(value);
  };
  //Shipment Depot
  const onchangeDropDownFormDataShipmentDepot = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_SH_DE(value);
  };
  //Origin Depot
  const onchangeDropDownFormDataOriginDepot = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_OR_DE(value);
  };
  //DestinationDepot
  const onchangeDropDownFormDataDestinationDepot = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_DE_DE(value);
  };
  //DispatchGroup
  const onchangeDropDownFormDataDispatchGroup = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setlb_DS_GP(value);
  };
  useEffect(() => {
    PlanningDepot();
    VehicleType();
    Status();
    RouteCode();
    Country();
    City();
    Route();
    Zone();
    State();
    WeightUM();
    VolumeUM();
    OriginDepot();
    ShipmentDepot();
    DestinationDepot();
    DispatchGroup();
    ModeOfTransport();
    Type();

  }, []);
  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setlb_LD_TYOptions(Options);
  };
  const PlanningDepot = async () => {
    const Options = await fetchClassCodeDS("PL/DP");
    setlb_PL_DEOptions(Options);
  };
  const VehicleType = async () => {
    const Options = await fetchClassCodeDS("VH/TY");
    setlb_VE_TYOptions(Options);
  };
  const Status = async () => {
    const Options = await fetchClassCodeDS("SH/ST");
    setlb_ST_ATOptions(Options);
  };
  const RouteCode = async () => {
    const Options = await fetchClassCodeDS("RT/CD");
    setlb_RT_CDOptions(Options);
  };
  const City = async () => {
    const Options = await fetchClassCodeDS("FO/CI");
    setlb_CI_TYOptions(Options);
  };
  const Zone = async () => {
    const Options = await fetchClassCodeDS("ZO/NO");
    setlb_ZN_CDOptions(Options);
  };
  const ModeOfTransport = async () => {
    const Options = await fetchClassCodeDS("TR/MO");
    setlb_MD_TROptions(Options);
  };
  const Country = async () => {
    const Options = await fetchClassCodeDS("FO/CY");
    setlb_CN_RYOptions(Options);
  };
  const State = async () => {
    const Options = await fetchClassCodeDS("FO/ST");
    setlb_ST_CDOptions(Options);
  };
  const OriginDepot = async () => {
    const Options = await fetchCostCenter();
    setlb_OR_DEOptions(Options);
  };
  const ShipmentDepot = async () => {
    const Options = await fetchClassCodeDS("SH/DP");
    setlb_SH_DEOptions(Options);
  };
  const DestinationDepot = async () => {
    const Options = await fetchCostCenter();
    setlb_DE_DEOptions(Options);
  };
  const DispatchGroup = async () => {
    const Options = await fetchClassCodeDS("DS/GP");
    setlb_DS_GPOptions(Options);
  };
  const WeightUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setlb_WT_UMOptions(Options);
  };
  const VolumeUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setlb_VL_UMOptions(Options);
  };
  const Route = async () => {
    const Options = await fetchClassCodeDS("RT/CD");
    setlb_RT_CDOptions(Options);
  };
  // Advance Search
  const [SoldToPopupVisible, setSoldToPopupVisible] = useState(false);
  const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
  const [CarrierPopupVisible, setCarrierPopupVisible] = useState(false);
  const [VehicleIDPopupVisible, setVehicleIDPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const [CarrierType, setCarrierType] = useState("E");
  const [ShipToType, setShipToType] = useState("E");
  const [SoldTotype, setSoldTotype] = useState("C");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [VehicleID, setVehicleID] = useState("C");
  const [addressType, setaddressType] = useState("E");

  const [shiptoPopupVisible, setshiptoPopupVisible] = useState(false);

  const openSoldToPopup = () => {
    setSoldToPopupVisible(true);
  };
  const openVehicleIDPopup = () => {
    setVehicleIDPopupVisible(true);
  };
  const openShipToPopup = () => {
    setShipToPopupVisible(true);
  };
  const openCarrierPopup = () => {
    setCarrierPopupVisible(true);
  };
  // const openCarrierPopup = () => {
  //   setShipToPopupVisible(true);
  // };
  const openshiptoPopup = (e) => {
    setshiptoPopupVisible(true);
    setSelectedRowIndex(e);
    setShowSearchIcon(false);
  };
  const handleSelectshipto = (data) => {
    setshipto_NAME(data.ab_AD_NM);
    document.getElementById(`shipto_NAME_${SelectedRowIndex}`).value = data.ab_AD_NM;
    document.getElementById(`lb_SH_TO_${SelectedRowIndex}`).value = data.abAdNo;
    setshiptoPopupVisible(false);
    setShowSearchIcon(false);
  };
  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedSold(data.ab_AD_NM);
    onchangeFormData("sd_SO_TO", data.abAdNo);
    // document.getElementById("sd_SH_TO").value = data.abAdNo;
    setSoldToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectShip = (data) => {
    setSelectedShip(data.ab_AD_NM);
    onchangeFormData("lb_CR_NO", data.abAdNo);
    setShipToPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectCarrier = (data) => {
    setSelectedCarrier(data.ab_AD_NM);
    onchangeFormData("lb_CR_NO", data.abAdNo);
    setCarrierPopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleItem = async (data) => {
    onchangeFormData("item_DES", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("sd_IT_NO", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  const handleVehicleID = (data) => {
    setSelectedVehicleID(data.vm_VH_DS);
    onchangeFormData("lb_VH_ID", data.vm_VH_ID);
    setVehicleIDPopupVisible(false);
    setShowSearchIcon(false);
  };
  const addRow = () => {
    setLoadBuildingDetailsTable([...LoadBuildingDetailsTable, {}]);
  };
  const deleteRow = () => {
    if (LoadBuildingDetailsTable.length > 0) {
      const updatedData = [...LoadBuildingDetailsTable];
      updatedData.pop();
      setLoadBuildingDetailsTable(updatedData);
    }
  };
  const [activeTab, setActiveTab] = useState(0);
  const isUpdateMode = Boolean(lbUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
      <Button
        style={{
          width: "5%",
          backgroundColor: "transparent",
          color: "blue",
          marginLeft: "82%", marginBottom: "-1%", marginTop: "-4%",
          display: isUpdateMode ? 'block' : 'none'
        }}
        icon="pi pi-check-circle"
        onClick={handleConfirm}
        tooltip={"Confirm"}>
      </Button>
    </React.Fragment>
  );

  const onChangeOfPrice = async (e) => {
    //const table = document.getElementById("RentalContractTable");
    //const totalRowCount = table.tBodies[0].rows.length;
    // rentalContracts = [];
    const table = document.getElementById("LoadBuildingDetailsTable");
    const totalRowCount = table.tBodies[0].rows.length;
    const LoadBuildingDetailsList = [];
    for (let i = 0; i < totalRowCount; i++) {
      const details = {
        lbUkNo: document.getElementById("lbUkNo_" + i).value,
        sd_EX_PR: document.getElementById("sd_EX_PR_" + i).value,
        sd_EX_CT: document.getElementById("sd_EX_CT_" + i).value,
        sd_FR_PR: document.getElementById("sd_FR_PR_" + i).value,
      };
      LoadBuildingDetailsList.push(details);
    }
    const requestBodyNet = {
      LoadBuildingDetailsList,
    };

    const response = await axios.post(`${domain}/api/LoadBuildingDetails/GetCalculateTotalPrices`, requestBodyNet, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
      },
    });
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const respNet = response.data.header;
    if (respNet) {
      document.getElementById("f_WEIGHT").value = respNet.f_EXT_PRICE;
      document.getElementById("f_VOLUME").value = respNet.f_EXT_COST;
      document.getElementById("F_FORE_PRICE").value = respNet.f_FORE_PRICE;

    } else {
      console.error("Invalid API response:", data);
    }
    //onchangedata(e); 
  };
  // Handle form data change
  const onChangeTime = (name, value, index) => {
    value = moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value, index);
  };
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{lbUkNo ? "Revise Load Building Details" : "Add Load Building Details"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
      )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{ display: "none" }}>
              <label htmlFor="lbUkNo" className="font-bold">
                UK NO
              </label>
              <InputText disabled
                id="lbUkNo"
                name="lbUkNo"
                defaultValue={lbUkNo}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_LD_NO" className="font-bold">
                Load No
              </label>
              <InputText disabled
                id="lb_LD_NO"
                name="lb_LD_NO"
                value={lb_LD_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_LD_TY" className="font-bold">
                Type
              </label>
              <Dropdown
                name="lb_LD_TY"
                value={lb_LD_TY}
                options={lb_LD_TYOptions}
                onChange={(e) => onchangeDropDownFormDataType("lb_LD_TY", e.value)}
                placeholder="Select  Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_CO_NO" className="font-bold">
                Company
              </label>
              <InputText
                id="lb_CO_NO"
                name="lb_CO_NO"
                value={lb_CO_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_PL_DE" className="font-bold">
                Planning Depot
              </label>
              <Dropdown
                name="lb_PL_DE"
                value={lb_PL_DE}
                options={lb_PL_DEOptions}
                onChange={(e) => onchangeDropDownFormData1("lb_PL_DE", e.value)}
                placeholder="Select  Planning Depot"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_VE_TY" className="font-bold">
                Vehicle Type
              </label>
              <Dropdown
                name="lb_VE_TY"
                value={lb_VE_TY}
                options={lb_VE_TYOptions}
                onChange={(e) => onchangeDropDownFormData2("lb_VE_TY", e.value)}
                placeholder="Select  Vehicle Type"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_ST_AT" className="font-bold">
                Status
              </label>
              <Dropdown
                name="lb_ST_AT"
                value={lb_ST_AT}
                options={lb_ST_ATOptions}
                onChange={(e) => onchangeDropDownFormData3("lb_ST_AT", e.value)}
                placeholder="Select Status"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_RT_CD" className="font-bold">
                Route Code
              </label>
              <Dropdown
                name="lb_RT_CD"
                value={lb_RT_CD}
                options={lb_RT_CDOptions}
                onChange={(e) => onchangeDropDownFormData4("lb_RT_CD", e.value)}
                placeholder="Select Route Code"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_ZN_CD" className="font-bold">
                Zone
              </label>
              <Dropdown
                name="lb_ZN_CD"
                value={lb_ZN_CD}
                options={lb_ZN_CDOptions}
                onChange={(e) => onchangeDropDownFormData5("lb_ZN_CD", e.value)}
                placeholder=" Zone"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_MD_TR" className="font-bold">
                Mod of Transport
              </label>
              <Dropdown
                name="lb_MD_TR"
                value={lb_MD_TR}
                options={lb_MD_TROptions}
                onChange={(e) => onchangeDropDownFormData6("lb_MD_TR", e.value)}
                placeholder=" Mod of Transport"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_CN_RY" className="font-bold">
                Country
              </label>
              <Dropdown
                name="lb_CN_RY"
                value={lb_CN_RY}
                options={lb_CN_RYOptions}
                onChange={(e) => onchangeDropDownFormData7("lb_CN_RY", e.value)}
                placeholder=" Country"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_SH_DT" className="font-bold">
                Ship Date
              </label>
              <Calendar
                id="la_SH_DT"
                name="la_SH_DT"
                value={moment(la_SH_DT, "YYYYMMDD").toDate()}
                onChange={handleDateChange}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="la_SH_TM" className="font-bold">
                Actual Delivery Time
              </label>
              <InputMask
                id={la_SH_TM}
                name={la_SH_TM}
                mask="99:99:99"
                onBlur={(e) => { onChangeTime("la_SH_TM", e.target.value,); }}
                value={moment(la_SH_TM, "HHmmss").format("HH:mm:ss")}
              />
            </div>
            {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
      <label htmlFor="la_SH_TM" className="font-bold"> 
      Ship Time
      </label>
      <InputText 
        id="la_SH_TM"
        name="la_SH_TM"
        value={la_SH_TM}
        onChange={(e) => onchangedata(e)}
      />
    </div>  */}
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_VH_ID" className="font-bold">
                Vehicle ID <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="vm_VH_DS"
                  name="vm_VH_DS"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedVehicleID}
                />
                {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openVehicleIDPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="lb_VH_ID"
                  name="lb_VH_ID"
                  value={lb_VH_ID}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="lb_CR_NO" className="font-bold">
                Carrier No<span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="ab_AD_NM1"
                  name="ab_AD_NM1"
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedCarrier}
                />
                {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCarrierPopup}
                  ></i>
                )}
              </div>
              <div style={{ display: "none" }}>
                <InputText
                  id="lb_CR_NO"
                  name="lb_CR_NO"
                  value={lb_CR_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
        {(
          <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
        )}
        {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
      </div>
      <div style={multitablestyle}>
            <table className="custom-table" id="LoadBuildingDetailsTable">
              <thead>
                <tr>
                  <th style={{ display: "none" }}>UK NO</th>
                  <th>Shipment No</th>
                  <th>Ship To<span style={{ color: 'red' }}>*</span></th>
                  {/* <th>Description </th> */}
                  <th>City</th>
                  <th>State</th>
                  <th>Postal Code</th>
                  <th>Weight</th>
                  <th>UM</th>
                  <th>Volume</th>
                  <th>UM</th>
                  <th>Origin Depot</th>
                  <th>Shipment Depot</th>
                  <th>Destination Depot</th>
                  <th>Delivery Date</th>
                  <th>Delivery Time</th>
                  <th>Dispatch Group</th>

                </tr>
              </thead>
              <tbody>
                {LoadBuildingDetailsTable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }}>
                      <InputText
                        id={`lbUkNo_${index}`}
                        name={`lbUkNo_${index}`}
                        defaultValue={row.lbUkNo}
                      />
                    </td>


                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`lb_SH_NO_${index}`}
                        name={`lb_SH_NO_${index}`}
                        defaultValue={row.lb_SH_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex" }}>
                        <InputText
                          style={{ width: "200px" }}
                          id={`shipto_NAME_${index}`}
                          name={`shipto_NAME_${index}`}
                          onFocus={() => setShowSearchIcon(true)}
                          //  onChange={(Event) => onchangedata(Event)}
                          onChange={(e) => onchangedata('shipto_NAME', e.value, index)}
                          defaultValue={row.shipto_NAME ? row.shipto_NAME : row[`${'shipto_NAME'}_${index}`]}
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "7%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openshiptoPopup(index)}
                          ></i>
                        )}
                      </div>
                      <InputText
                        style={{ display: "none" }}
                        id={`lb_SH_TO_${index}`}
                        name={`lb_SH_TO_${index}`}
                        onChange={(Event) => onchangedata(Event)}
                        defaultValue={row.lb_SH_TO ? row.lb_SH_TO : row[`${'lb_SH_TO'}_${index}`]}
                      />

                    </td>


                    <td>
                      <Dropdown
                        id={`lb_CI_TY_${index}`}
                        name={`lb_CI_TY_${index}`}
                        value={data[`lb_CI_TY_${index}`] ? data[`lb_CI_TY_${index}`] : row.sd_WT_UM}
                        options={lb_CI_TYOptions}
                        onChange={(e) => OnChaneDropdownFormDateCity(`lb_CI_TY_${index}`, e.value)}
                        placeholder="City"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_ST_CD_${index}`}
                        name={`lb_ST_CD_${index}`}
                        value={data[`lb_ST_CD_${index}`] ? data[`lb_ST_CD_${index}`] : row.sd_WT_UM}
                        options={lb_ST_CDOptions}
                        onChange={(e) => onchangeDropDownFormDataState(`lb_ST_CD_${index}`, e.value)}
                        placeholder="State"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`lb_PO_CD_${index}`}
                        name={`lb_PO_CD_${index}`}
                        defaultValue={row.lb_PO_CD}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`lb_WE_GT_${index}`}
                        name={`lb_WE_GT_${index}`}
                        defaultValue={row.lb_WE_GT}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_WT_UM_${index}`}
                        name={`lb_WT_UM_${index}`}
                        value={data[`lb_WT_UM_${index}`] ? data[`lb_WT_UM_${index}`] : row.lb_WT_UM}
                        options={lb_WT_UMOptions}
                        onChange={(e) => onchangeDropDownFormDataWeightUM(`lb_WT_UM_${index}`, e.value)}
                        placeholder="UM"
                      />
                    </td>
                    <td >
                      <InputText style={{ width: "100px" }}
                        id={`lb_VL_ME_${index}`}
                        name={`lb_VL_ME_${index}`}
                        defaultValue={row.lb_VL_ME}
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_VL_UM_${index}`}
                        name={`lb_VL_UM_${index}`}
                        value={data[`lb_VL_UM_${index}`] ? data[`lb_VL_UM_${index}`] : row.lb_VL_UM}
                        options={lb_VL_UMOptions}
                        onChange={(e) => onchangeDropDownFormDataVolumeUM(`lb_VL_UM_${index}`, e.value)}
                        placeholder="UM"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_OR_DE_${index}`}
                        name={`lb_OR_DE_${index}`}
                        value={data[`lb_OR_DE_${index}`] ? data[`lb_OR_DE_${index}`] : row.lb_OR_DE}
                        options={lb_OR_DEOptions}
                        onChange={(e) => onchangeDropDownFormDataOriginDepot(`lb_OR_DE_${index}`, e.value)}
                        placeholder="Origin Depot"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_SH_DE_${index}`}
                        name={`lb_SH_DE_${index}`}
                        value={data[`lb_SH_DE_${index}`] ? data[`lb_SH_DE_${index}`] : row.lb_SH_DE}
                        options={lb_SH_DEOptions}
                        onChange={(e) => onchangeDropDownFormDataShipmentDepot(`lb_SH_DE_${index}`, e.value)}
                        placeholder="Shipment Depot"
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_DE_DE_${index}`}
                        name={`lb_DE_DE_${index}`}
                        value={data[`lb_DE_DE_${index}`] ? data[`lb_DE_DE_${index}`] : row.lb_DE_DE}
                        options={lb_DE_DEOptions}
                        onChange={(e) => onchangeDropDownFormDataDestinationDepot(`lb_DE_DE_${index}`, e.value)}
                        placeholder="Destination  Depot"
                      />
                    </td>
                    <td>
                      <Calendar style={{ width: "100px" }}
                        id={`lb_DL_DT_${index}`}
                        name={`lb_DL_DT_${index}`}
                        value={row.lb_DL_DT ? moment(row.lb_DL_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onDateChange("lb_DL_DT", index, e.value)}
                        dateFormat="dd/mm/yy"
                      />
                    </td>

                    <td>
                      <InputMask style={{ width: "100px" }}
                        id={`lb_DL_TM_${index}`}
                        name={`lb_DL_TM_${index}`}
                        mask="99:99:99"
                        value={moment(row.lb_DL_TM, "HHmmss").format("HH:mm:ss")}
                        onBlur={(e) => {
                          onChangeTime("lb_DL_TM", e.target.value, index);

                          //calINtime(SelectedRowIndex);
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        id={`lb_DS_GP_${index}`}
                        name={`lb_DS_GP_${index}`}
                        value={data[`lb_DS_GP_${index}`] ? data[`lb_DS_GP_${index}`] : row.lb_DS_GP}
                        options={lb_DS_GPOptions}
                        onChange={(e) => onchangeDropDownFormDataDispatchGroup(`lb_DS_GP_${index}`, e.value)}
                        placeholder="Dispatch Group"
                      />
                    </td>



                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={inboxstyle} >
          <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="f_WEIGHT" className="font-bold">
                Weight
              </label>
              <InputText disabled
                id="f_WEIGHT"
                name="f_WEIGHT"
                defaultValue={f_WEIGHT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="f_VOLUME" className="font-bold">
                Volume
              </label>
              <InputText disabled
                id="f_VOLUME"
                name="f_VOLUME"
                defaultValue={f_VOLUME}
                onChange={(e) => onchangedata(e)}
              />
            </div>


          </div>
        </div>
      </div>
      {/* SOLD TO POPUP */}
      <AddressBookPopup
        visible={SoldToPopupVisible}
        onClose={() => setSoldToPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
        addressType={SoldTotype}
        setFormData={setFormData}
      />
      {/* SHIP TO POPUP */}
      <AddressBookPopup
        visible={ShipToPopupVisible}
        onClose={() => setShipToPopupVisible(false)}
        onDataSelect={handleDataSelectShip}
        formData={data}
        addressType={ShipToType}
        setFormData={setFormData}
      />
      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
      {/* Carrier  No POPUP */}
      <AddressBookPopup
        visible={CarrierPopupVisible}
        onClose={() => setCarrierPopupVisible(false)}
        onDataSelect={handleDataSelectCarrier}
        formData={data}
        addressType={CarrierType}
        setFormData={setFormData}
      />
      {/* Vehicle ID */}
      <VehicleMasterPopup
        visible={VehicleIDPopupVisible}
        onClose={() => setVehicleIDPopupVisible(false)}
        onDataSelect={handleVehicleID}
        formData={data}
        addressType={VehicleID}
        setFormData={setFormData}
      />
      <AddressBookPopup
        visible={shiptoPopupVisible}
        onClose={() => setshiptoPopupVisible(false)}
        onDataSelect={handleSelectshipto}
        formData={data}
        addressType={addressType}
      />

    </Dialog>

  );
}
