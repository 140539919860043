import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
export default function UpdateAttendenceDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  UpdateAttendencetable,
  setUpdateAttendencetable,
  // addRow,
  // deleteRow,
  emEMNOList,
  ua_PE_NO,
  handleConfirm
}) {
  //const [UpdateAttendencetable, setUpdateAttendencetable] = useState([]);
  const { 
    uaUkNo,
    name,
    em_CC_NM,
    em_DE_PT,
    ua_CO_NO,
    ua_EM_NO,
    ua_AV_DA,
    ua_TO_DT,
    ua_FY_YR,
    ua_FR_DT,
    ua_EL_DA,
    ua_CT_RY,
    ua_HL_DA,
    ua_AB_DA,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyleW,cancelstyleW,Dialogstyle} = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [em_CC_NMOptions, setem_CC_NMOptions] = useState([]);
  const [ua_FY_YROptions, setua_FY_YROptions] = useState([]);
  const [ua_PE_NOOptions, setua_PE_NOOptions] = useState([]);
  const [em_DE_PTOptions, setem_DE_PTOptions] = useState([]);

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData1(name, value, index);
  };

  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData2(name, value, index);
  };

  const onchangedata = (e) => {
    const { name, value } = e.target;
      onchangeFormData(name, value);
  };
  
  const isUpdateMode = Boolean(uaUkNo);
  const urlcost = `${domain}/api/CostCenter/DropDown`;

  const finalUrl1 = `${urlcost}`;
  const url = `${domain}/api/ClassCode/All`;
  // Year
  const parameter2 = "YE/AR";
  const finalUrl2 = `${url}/${parameter2}`;
  // month
  const parameter3 = "MN/TH";
  const finalUrl3 = `${url}/${parameter3}`;
  // DEpartment
  const parameter4 = "EM/DP";
  const finalUrl4 = `${url}/${parameter4}`;

useEffect(() => {
  fetchDropdownOptions1();
  fetchDropdownOptions2();
  fetchDropdownOptions3();
  fetchDropdownOptions4();
}, []);
// 1st dropdown
const fetchDropdownOptions1 = async () => {
  try {
    const response = await axios.get(finalUrl1, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cc_CC_NM,
      value: item.cc_CC_NM,
    }));
    setem_CC_NMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 2nd dropdown
const fetchDropdownOptions2 = async () => {
  try {
    const response = await axios.get(finalUrl2, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setua_FY_YROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 3rd dropdown
const fetchDropdownOptions3 = async () => {
  try {
    const response = await axios.get(finalUrl3, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setua_PE_NOOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};
// 4TH DROPDOWN
const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_DS,
      value: item.cl_CL_CD,
    }));
    setem_DE_PTOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
}; 
const days = `${domain}/api/UpdateAttendance/GetPopulateDetails`
  const avdays = async (fromDate,toDate) => {
    if(fromDate!="" && toDate!=""){
      const req = {
        "ua_FR_DT": fromDate,
        "ua_TO_DT": toDate
      };
      try {
        const response = await axios.post(days, req, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
        const dataday = response.data.Result;
        if (dataday) {
          onchangeFormData("ua_AV_DA", dataday.ua_AV_DA);
          onchangeFormData("ua_FY_YR", dataday.ua_FY_YR);
          onchangeDropDownFormData3("ua_PE_NO", dataday.ua_PE_NO);
        }
      } catch (error) {
        console.error("Error in Formatted Dates", error);
      }
    }
  };
  
  const productDialogFooter = (
    <React.Fragment>
    <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'space-between' : 'flex-end', width: '100%' }}>
    {isUpdateMode && (
     <div style={{ display: 'flex',width:"5%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check-circle" 
       onClick={handleConfirm} 
       tooltip={"Confirm"}>
     </Button>
     </div>
    )}
     {/* <div style={{ display: 'flex',width:"10%" }}>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-times" 
       variant="outlined" 
       onClick={handleClose}
       tooltip="Cancel"
     >
     </Button>
     <Button 
       style={{width:"100%"}}
       icon="pi pi-check" 
       onClick={handleFormSubmit} 
        tooltip="Save">
     </Button>
     </div> */}
 </div>
</React.Fragment>
  );

  const addInitialRows = (rowsToAdd) => {
    const updatedRows = [...UpdateAttendencetable, ...rowsToAdd];
    setUpdateAttendencetable(updatedRows);
  };
  
  useEffect(() => {
    // const initialRows = emEMNOList.map((emEMNO,index) => ({
    //   ua_EM_NO: emEMNO,
    //   em_CC_NM: '',
    //   em_DE_PT: '',
    // }));
    // addInitialRows(initialRows);
  }, [emEMNOList]);

  const addRow = () => {
    setUpdateAttendencetable([...UpdateAttendencetable, {}]);
  };
  
  const deleteRow = (index) => {
    const updatedTable = [...UpdateAttendencetable];
    updatedTable.splice(index, 1);
    setUpdateAttendencetable(updatedTable);
  };

  const [leaveDaysList, setLeaveDaysList] = useState(UpdateAttendencetable.map(() => ''));
  const dialogHeader = (
    <div className="d-flex justify-content-between align-items-center">
      <span>{uaUkNo ? "Revise Update Attendance" : "Add Update Attendance"}</span>
      <div style={{ display: 'flex', width: '50%' }}>
        {isUpdateMode && (
          <div style={{ display: 'flex',width:"5%" }}>
            <Button 
              style={{width:"100%",color:'blue',backgroundColor:"transparent",border:'none'}}
              icon="pi pi-check-circle" 
              onClick={handleConfirm} 
              tooltip={"Confirm"}>
            </Button>
          </div>
        )}
        <div style={{ display: 'flex' }}>
            <Button 
              style={cancelstyleW}
              icon="pi pi-times" 
              variant="outlined" 
              onClick={handleClose}
              tooltip="Cancel"
            >
            </Button>
            <Button 
              style={savestyleW}
              icon="pi pi-check" 
              onClick={handleFormSubmit} 
               tooltip="Save">
            </Button>
        </div>
     </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle} >
            <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_FR_DT" className="font-bold">
                  From Date
                  </label>
                  <Calendar
                    id="ua_FR_DT"
                    name="ua_FR_DT"
                    value={moment(ua_FR_DT, "YYYYMMDD").toDate()}
                    onChange={(e) => {
                      const selectedDateF = moment(e.value).format("YYYYMMDD");
                      onchangeFormData("ua_FR_DT", selectedDateF);
                      avdays(selectedDateF,ua_TO_DT);
                    }}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_TO_DT" className="font-bold">
                  To Date
                  </label>
                  <Calendar
                    id="ua_TO_DT"
                    name="ua_TO_DT"
                    // value={moment(ua_TO_DT, "YYYYMMDD").toDate()}
                    value={moment(data.ua_TO_DT, "YYYYMMDD").isValid() ? moment(data.ua_TO_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => {
                      const selectedDateT = moment(e.value).format("YYYYMMDD");
                      onchangedata({ target: { name: "ua_TO_DT", value: selectedDateT } });
                      avdays(ua_FR_DT,selectedDateT);
                    }}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_HL_DA" className="font-bold">
                    Holidays
                  </label>
                  <InputText
                    id="ua_HL_DA"
                    name="ua_HL_DA"
                    value={ua_HL_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_AV_DA" className="font-bold">
                    Available Days
                  </label>
                  <InputText disabled
                    id="ua_AV_DA"
                    name="ua_AV_DA"
                    value={ua_AV_DA}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_FY_YR" className="font-bold">
                  Year
                  </label>
                  <InputText disabled
                    id="ua_FY_YR"
                    name="ua_FY_YR"
                    value={ua_FY_YR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ua_PE_NO" className="font-bold">
                    Month
                  </label>  
                  <InputText disabled
                    id="ua_PE_NO"
                    name="ua_PE_NO"
                    value={ua_PE_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                
            </div>
          </div>
          <div style={tablestyle} >
      <div className="table-buttons" style={tablebutton} >
        {(
        <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
        )}
          {(
        <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
        )}
      </div>
      <div  style={multitablestyle}>
      <table className="custom-table" id="UpdateAttendencetable">
      <thead>
        <tr>
        <th style={{ display: "none" }}>UK_NO</th>
          <th>SL no.</th>
          <th>Employee</th>
          <th>Name</th>
          <th>Cost Centre</th>
          <th>Department</th>
          <th>Eligible Days</th>
          <th>Leave</th>
        </tr>
      </thead>
      <tbody>
      {UpdateAttendencetable.map((row, index) => (
        <tr key={index}>
        <td style={{ display: "none" }}>
        <InputText
          id={`uaUkNo_${index}`}
          name={`uaUkNo_${index}`}
          defaultValue={row.uaUkNo}
        />
        </td>
        <td>
        <InputText disabled
          id={index}
          name={index}
          value={index+1}
        />
        </td>
      <td >
          <div style={{ display: "flex" }}>
            <InputText
                id={`ua_EM_NO_${index}`}
                name={`ua_EM_NO_${index}`}
                value={row.ua_EM_NO ? row.ua_EM_NO : row[`${'ua_EM_NO'}_${index}`]}
                // value={emEMNOList[index] || ""}
                onChange={(e) => onchangedata(e)}
            />
            </div>
          </td>
          <td>
          <div style={{ display: "flex", width:"150px" }}>
              <InputText
                id={`name_${index}`}
                name={`name_${index}`}
                value={row.name ? row.name : row[`${'name'}_${index}`]}
                onChange={(e) => onchangedata(e)}
              />
          </div>
          </td>
          <td>
          <Dropdown
            id={`em_CC_NM_${index}`}
            name={`em_CC_NM_${index}`}
            value={data[`em_CC_NM_${index}`] ? data[`em_CC_NM_${index}`] : row.em_CC_NM}
            options={em_CC_NMOptions}
            onChange={(e) => handleFormDataChange(`em_DE_PT_${index}`, e.value)}
            placeholder="Select Cost Centre"
          />
          </td>
          <td>
          <Dropdown
            id={`em_DE_PT_${index}`}
            name={`em_DE_PT_${index}`}
            value={data[`em_DE_PT_${index}`] ? data[`em_DE_PT_${index}`] : row.em_DE_PT}
            options={em_DE_PTOptions}
            onChange={(e) => handleFormDataChange1(`em_DE_PT_${index}`, e.value)}
            placeholder="Select Department"
          />
          </td>
          <td>
          <InputText
              id={`ua_EL_DA_${index}`}
              name={`ua_EL_DA_${index}`}
              value={row.ua_EL_DA}
              onChange={(e) => {
                const eligibleDays = parseInt(e.target.value || 0);
                const availableDays = parseInt(data.ua_AV_DA || 0);
                const leaveDays = isNaN(availableDays - eligibleDays) ? '' : availableDays - eligibleDays;
                onchangedata({
                  target: {
                    name: `ua_EL_DA_${index}`,
                    value: eligibleDays
                  }
                });
                const updatedLeaveDaysList = [...leaveDaysList];
                updatedLeaveDaysList[index] = leaveDays;
                setLeaveDaysList(updatedLeaveDaysList);
              }}
              />
          </td>         
          <td>
            <InputText disabled
                id={`ua_AB_DA_${index}`}
                name={`ua_AB_DA_${index}`}
                value={leaveDaysList[index]}
                onChange={(e) => onchangedata(e)}
              />
          </td>
          </tr>
          ))}
      </tbody>
    </table>
    </div>       
          </div> 
      </div>
    </Dialog>
  );
}
