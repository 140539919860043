import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import { TabView, TabPanel } from "primereact/tabview";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
export default function PurchaseReceiptsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeOrderdate,
  SelectedemEMNO,
  setSelectedemEMNO,
  setSelectedAbAdNN,
  FormData,
  ph_OR_TY,
  ph_DL_NO,
  ph_OR_CO,
  ph_BS_CR,
  sup_NAME,
  ph_CO_CT,
  ph_EX_RT,
  ph_PY_TR,
  setph_PY_TR,
  onchangedata,
  setph_SU_NO,
  setph_EX_RT,
  setFormData,
  addRow,
  deleteRow,
  addRow1,
  deleteRow1,
  PurchaseReceiptstable,
  PurchaseReceiptstable1,
  setapplied,
  tax_APPLICABLE,
  settax_APPLICABLE,
  setCurrency,
  pay_to_SUPPLIER,
  setpay_to_SUPPLIER,
  setSelectedname,
  Selectedsupplier,
  setSelectedsupplier,
  setSelectedName,
  pd_LO_CN,
  setpd_LO_CN,
  onchangeFieldSetFormData
}) {
  const { 
    pdUkNo,
    phUkNO,
    ph_OR_NO,
    ph_IN_DT,
    ph_IN_NO,
    ph_SU_NO,
    ph_RQ_DT,
    ph_OR_DT,
    ph_RC_DT,
  } = data;
  const {footergridstyle,footerfieldstyle,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,
    plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();
  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_TR_CROptions, setph_TR_CROptions] = useState([]);
  // const [pd_LO_CNOptions, setpd_LO_CNOptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [pd_SC_UMOptions, setpd_SC_UMOptions] = useState([]);
  const [pd_LO_CNOptions, setpd_LO_CNOptions] = useState([]);
  const [ph_PY_TROptions, setph_PY_TROptions] = useState([]);
  const [Addresstype, setAddresstype] = useState("S");
  const [appliedOptions, setappliedOptions] = useState([]);
  const [tax_APPLICABLEOptions, settax_APPLICABLEOptions] = useState([]);
  const [CurrencyOptions, setCurrencyOptions] = useState([]);
  const [pay_to_SUPPLIEROptions, setpay_to_SUPPLIEROptions] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const AppliedDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setapplied(value);
  };
  const TaxDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settax_APPLICABLE(value);
  };
  const CurrencyDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setCurrency(value);
  };
  const SupplierDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    settax_APPLICABLE(value);
  };
  const LocationDropDown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpd_LO_CN(value);
  };

  const url = `${domain}/api/ClassCode/All`;
  
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;

  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;

  const parameter3 = "TX/AP";
  const finalUrl3 = `${url}/${parameter3}`;

  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;

  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl6 = `${urlpayterm}`;

const urlcom = `${domain}/api/LocationMaster/DropDown`;
const finalUrl7 = `${urlcom}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions7();
    Applied();
    Tax();
    Currency();
    Supplier();
  }, []);

  const Applied = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setappliedOptions(Options);
  };

  const Tax = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    settax_APPLICABLEOptions(Options);
  };

  const Currency = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setCurrencyOptions(Options);
  };

  const Supplier = async () => {
    const Options = await fetchClassCodeDS("ME/PL");
    setpay_to_SUPPLIEROptions(Options);
  };


const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
};

const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
};

const fetchDropdownOptions4 = async () => {
  try {
    const response = await axios.get(finalUrl4, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_TR_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const fetchDropdownOptions5 = async () => {
  try {
    const response = await axios.get(finalUrl5, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.cl_CL_CD,
      value: item.cl_CL_CD,
    }));
    setpd_SC_UMOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const fetchDropdownOptions6 = async () => {
  try {
    const response = await axios.get(finalUrl6, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    const options = response.data.map((item) => ({
      label: item.pt_PY_DS,
      value: item.pt_PY_CO,
    }));
    setph_PY_TROptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};

const fetchDropdownOptions7 = async () => {
  try {
    const response = await axios.get(finalUrl7, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });
    const options = response.data.map((item) => ({
      label: item.lm_LO_CN,
      value: item.lm_LO_CN,
    }));
    setpd_LO_CNOptions(options);
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
  }
};


const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
const openSupplierhPopup = (e) => {
  setSupplierhPopupVisible(true);
  setSelectedRowIndex(e);
};


const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
// Supplier
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  setph_SU_NO(data.abAdNo);
  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData6("ph_PY_TR", dataCuMt.su_PT_TM);
         setph_PY_TR(dataCuMt.su_PT_TM);
        onchangeDropDownFormData2("ph_TR_CR", dataCuMt.su_CR_CD);
      
       
        onchangeFormData(`ph_TR_CR`, dataCuMt.su_CR_CD);
        const exData={
          "cr_TO_CR": document.getElementById("ph_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.ph_TR_CR, //currency
          "cr_EF_DT": data.ph_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setSupplierhPopupVisible(false);
};
exchangeRate(null);
function exchangeRate (exData){
  if(exData==null && ph_BS_CR!="" && data.ph_TR_CR!="" && data.ph_OR_DT!=""){
    exData={
      "cr_TO_CR": ph_BS_CR,
      "cr_FR_CR": data.ph_TR_CR,
      "cr_EF_DT": data.ph_OR_DT
    }
  }
  if(exData!=null){

    axios
    .post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
        setph_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  }
}

const handleDataSelect2 = async(Result) => {
  setSelectedAbAdNN(Result.im_IT_DS);
  setSelectedAbAdNN(Result.im_IT_NM);
  onchangeFormData(`im_IT_DS_${SelectedRowIndex}`, Result.im_IT_DS);
  onchangeFormData(`pd_IT_NM_${SelectedRowIndex}`, Result.im_IT_NM);
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeDropDownFormData5(`pd_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
   
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};

const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};

const handlesupplier =async (data) => {
  setSelectedName(data.ab_AD_NM);
  setSelectedsupplier( data.abAdNo);
  document.getElementById (`supplier_${SelectedRowIndex}`).value =  data.abAdNo;
  document.getElementById (`name_${SelectedRowIndex}`).value =  data.ab_AD_NM;
  setSupplierPopupVisible(false);
  setShowSearchIcon(false);
};

const onchangeFormUnitPrice = async(e,index) => {
  onchangedata(e);
  onChangeOfPrice(index);
};
const  onChangeOfPrice= async(SelectedRowIndex) => {
  const UrlCalulate = `${domain}/api/PurchaseReceipt/GetCalculateAmount`;
  const priceInput = {
    "purchaseQuote":{
            "ph_TR_CR": data.ph_TR_CR,
            "ph_EX_RT": ph_EX_RT,
            "ph_BS_CR": ph_BS_CR,
    },
    "purchaseOrderList":[{
            "pd_BS_UP":document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value,
            "quantity":document.getElementById (`pd_RC_QT_${SelectedRowIndex}`).value,
            "disAmount":document.getElementById (`pd_LN_DS_${SelectedRowIndex}`).value,
            "headerDisAmount":document.getElementById (`pd_HD_DS_${SelectedRowIndex}`).value,
            "frPrice":document.getElementById (`pd_FR_UP_${SelectedRowIndex}`).value,
            "foreDiscount":document.getElementById (`pd_FR_AT_${SelectedRowIndex}`).value,
       } ]
  }
  try {
    const response =await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCalulate = response.data.Result;
    if (dataCalulate ) {
      onchangeFieldSetFormData(`pd_RC_AT_${SelectedRowIndex}`,dataCalulate.recAmount);
      // onchangeFieldSetFormData(`pd_HD_DS_${SelectedRowIndex}`,dataCalulate.actualHdDis);
    } else {
      console.error("Invalid API response:", dataCalulate);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
  const isUpdateMode = Boolean(phUkNO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{phUkNO ? "Revise Purchase Receipt" : "Add Purchase Receipt"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />)}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />)}
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={TabBoxStyle}>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
        <TabPanel header="Receipt Details">
            {/* tab1 general */}
          <div style={inboxstyle} >
            <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="phUkNO" className="font-bold">
            UKNO
            </label>
            <InputText
            id="phUkNO"
            name="phUkNO"
            value={phUkNO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_OR_NO" className="font-bold">
              Order No
              </label>
              <InputText disabled
              id="ph_OR_NO"
              name="ph_OR_NO"
              value={ph_OR_NO}
              onChange={(e) => onchangedata(e)}
              />
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_OR_TY" className="font-bold">
              Type
              </label>
              <InputText disabled
              id="ph_OR_TY"
              name="ph_OR_TY"
              value={ph_OR_TY}
              onChange={(e) => onchangedata(e)}
              />
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_OR_CO" className="font-bold">
              Company
              </label>
              <InputText disabled
              id="ph_OR_CO"
              name="ph_OR_CO"
              value={ph_OR_CO}
              onChange={(e) => onchangedata(e)}
              />
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_OR_DT" className="font-bold">
              Order Date
              </label>
              <Calendar disabled
              id="ph_OR_DT"
              name="ph_OR_DT"
              value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
              onChange={(e) =>
              onchangeOrderdate({
              target: {
              name: "ph_OR_DT",
              value: moment(e.value).format("YYYYMMDD"),
              },
              })
              }
              dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_RQ_DT" className="font-bold">
              Requested Date
              </label>
              <Calendar disabled
              id="ph_RQ_DT"
              name="ph_RQ_DT"
              value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "ph_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
              />
              </div>
            

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_CO_CT" className="font-bold">
              Cost Centre
              </label>
              <Dropdown  disabled
              id="ph_CO_CT"
              name="ph_CO_CT"
              value={ph_CO_CT}
              options={ph_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("ph_CO_CT", e.value)}
              placeholder="Select Cost Centre"
              />
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_SU_NO" className="font-bold">
              Supplier  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText disabled
                id="sup_NAME"
                name="sup_NAME"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNO}
                />
                {showSearchIcon && (
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
                }}
                onClick={openSupplierhPopup}
                ></i>
                )}
                </div>
                <div style={{ display: "none" }}>
                <InputText
                id="ph_SU_NO"
                name="ph_SU_NO"
                value={ph_SU_NO}
                onChange={(Event) => onchangedata(Event)}
                />
                <i
                className="pi pi-search"
                style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "4%",
                cursor: "pointer",
                }}
                onClick={openSupplierhPopup}
                ></i>
                </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_PY_TR" className="font-bold">
                  Pay Term
                </label>
                <Dropdown disabled
                  id="ph_PY_TR"
                  name="ph_PY_TR"
                  value={ph_PY_TR}
                  options={ph_PY_TROptions}
                  onChange={(e) => onchangeDropDownFormData6("ph_PY_TR", e.value)}
                  placeholder="Select Pay Term"
                />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="auto_POST" className="font-bold">
                Auto Post
                </label>
                <InputText disabled
                        id="auto_POST"
                        name="auto_POST"
                        value={"Yes"}
                />
              </div>
            
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_TR_CR" className="font-bold">
                Currency
                </label>
                <Dropdown disabled
                id="ph_TR_CR"
                name="ph_TR_CR"
                value={data.ph_TR_CR}
                options={ph_TR_CROptions}
                onChange={(e) => onchangeDropDownFormData2("ph_TR_CR", e.value)}
                placeholder="Select Currency"
                />
                </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
              <label htmlFor="ph_EX_RT" className="font-bold">
                Exchange Rate
                </label>
                <InputText disabled
                id="ph_EX_RT"
                name="ph_EX_RT"
                value={ph_EX_RT}
                onChange={(e) => onchangedata(e)}
                />
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
              <label htmlFor="ph_BS_CR" className="font-bold">
              Base Currency
              </label>
              <InputText disabled
              id="ph_BS_CR"
              name="ph_BS_CR"
              value={ph_BS_CR}
              onChange={(e) => onchangedata(e)}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_RC_DT" className="font-bold">
            Receipt Date
              </label>
              <Calendar
              id="ph_RC_DT"
              name="ph_RC_DT"
              value={moment(ph_RC_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "ph_RC_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_IN_DT" className="font-bold">
                Delivery date
                </label>
                <Calendar
              id="ph_IN_DT"
              name="ph_IN_DT"
              value={moment(ph_IN_DT, "YYYYMMDD").toDate()}
              onChange={(e) => onchangedata({ target: { name: "ph_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
              />
              </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_IN_NO" className="font-bold">
                Delivery No
                </label>
                <InputText
                  id="ph_IN_NO"
                  name="ph_IN_NO"
                  value={ph_IN_NO}
                  onChange={(Event) => onchangedata(Event)}
                />
              </div>
              
                
            
            </div>
          </div>
          <div  style={tablestyle} >
              <div className="table-buttons" style={tablebutton} >
                {(
                <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                )}
                {(
                <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                )}
              </div>
              <div  style={multitablestyle}>
            <table className="custom-table" id="PurchaseReceiptstable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th>Line No</th>
              <th>Item <span style={{ color: 'red', }}>*</span></th>
              <th>Description </th>
              <th>Open Quantity</th>
              <th>Order Quantity</th>
              <th>UM</th>
              <th>Ordered Amount</th>
              <th>Receipt Quantity</th>
              <th>Receipt Amount</th>
              <th>Unit Price</th>
              <th>Discount</th>
              <th>LumpSum Discount</th>
              <th>Tax %</th>
              <th>Tax Amount</th>
              <th>Fore. Unit price</th>
              <th>Fore. Amount</th>
              <th>Fore. Discount</th>
              <th>Sec Quantity</th>
              <th>Sec UM</th>
              <th>Landed Cost</th>
              <th>Status</th>
              <th>Location</th>
              <th>Lot Number</th>
              <th>Supplier Reference</th>
              <th>Expiry Date</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
          {PurchaseReceiptstable.map((row, index) => (
                  <tr key={index}>
                <td style={{ display: "none" }} >
                <InputText
                  id={`pdUkNo_${index}`}
                  name={`pdUkNo_${index}`}
                  defaultValue={row.pdUkNo}
                />
              </td>
                  <td>
                  <InputText disabled
                  style={{ width: "90px" }}
                  id={`pd_LN_NO_${index}`}
                  name={`pd_LN_NO_${index}`}
                  value={index + 1}
                  readOnly
                />
                  </td>
              <td> 
              <div style={{ display: "flex", width:"10rem"}}>
              <InputText disabled
              id={`pd_IT_NM_${index}`}
              name={`pd_IT_NM_${index}`}
              onChange={(e) => onchangedata(e)}
              onFocus={() => setShowSearchIcon(true)}
              value={row.pd_IT_NM ? row.pd_IT_NM: data[`${"pd_IT_NM"}_${index}`]}
              />
              {showSearchIcon && (
                <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "7%",
              cursor: "pointer",
              }}
              onClick={(e) => openItemPopup(index)}
              ></i>
              )}
              </div>
            
              </td>
              <td >
              <div style={{ display: "flex",width:"15rem"}}>
              <InputText disabled
              id={`im_IT_DS_${index}`}
              name={`im_IT_DS_${index}`}
              value={row.im_IT_DS ? row.im_IT_DS : data[`${"im_IT_DS"}_${index}`]}
              onChange={(e) => onchangedata(e)}
              />
              </div>
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`pd_OP_QT_${index}`}
                name={`pd_OP_QT_${index}`}
                defaultValue={row.pd_OP_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_OR_QT_${index}`}
                name={`pd_OR_QT_${index}`}
                defaultValue={row.pd_OR_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown disabled style={{width:"7rem"}}
                id={`pd_TR_UM_${index}`}
                name={`pd_TR_UM_${index}`}
                value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
                options={pd_TR_UMOptions}
                onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_OR_AT_${index}`}
                name={`pd_OR_AT_${index}`}
                defaultValue={row.pd_OR_AT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              <td>
              <InputText style={{width:"10rem"}}
                id={`pd_RC_QT_${index}`}
                name={`pd_RC_QT_${index}`}
                value={data[`pd_RC_QT_${index}`] ? data[`pd_RC_QT_${index}`] : row.pd_RC_QT}
                onChange={(e) => onchangeFormUnitPrice(e,index)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`pd_RC_AT_${index}`}
                name={`pd_RC_AT_${index}`}
                value={data[`pd_RC_AT_${index}`] ? data[`pd_RC_AT_${index}`] : row.pd_RC_AT}
                onChange={(e) => onchangedata(e)}
              
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_BS_UP_${index}`}
                name={`pd_BS_UP_${index}`}
                defaultValue={row.pd_BS_UP}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_LN_DS_${index}`}
                name={`pd_LN_DS_${index}`}
                defaultValue={row.pd_LN_DS}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`pd_HD_DS_${index}`}
                name={`pd_HD_DS_${index}`}
                defaultValue={row.pd_HD_DS}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_TX_PE_${index}`}
                name={`pd_TX_PE_${index}`}
                defaultValue={row.pd_TX_PE}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_TX_AM_${index}`}
                name={`pd_TX_AM_${index}`}
                defaultValue={row.pd_TX_AM}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_FR_UP_${index}`}
                name={`pd_FR_UP_${index}`}
                defaultValue={row.pd_FR_UP}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_FR_AT_${index}`}
                name={`pd_FR_AT_${index}`}
                defaultValue={row.pd_FR_AT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_FR_DS_${index}`}
                name={`pd_FR_DS_${index}`}
                defaultValue={row.pd_FR_DS}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              
              <td>
              <InputText style={{width:"10rem"}}
                id={`pd_SC_QT_${index}`}
                name={`pd_SC_QT_${index}`}
                defaultValue={row.pd_SC_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown style={{width:"7rem"}}
                id={`pd_SC_UM_${index}`}
                name={`pd_SC_UM_${index}`}
                value={ data[`pd_SC_UM_${index}`] ? data[`pd_SC_UM_${index}`]: row.pd_SC_UM}
                options={pd_SC_UMOptions}
                onChange={(e) =>handleFormDataChange1(`pd_SC_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_LD_CT_${index}`}
                name={`pd_LD_CT_${index}`}
                defaultValue={row.pd_LD_CT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
            
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`pd_ST_CD_${index}`}
                name={`pd_ST_CD_${index}`}
                defaultValue={row.pd_ST_CD}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Dropdown style={{width:"7rem"}}
                id={`pd_LO_CN_${index}`}
                name={`pd_LO_CN_${index}`}
                value={ data[`pd_LO_CN_${index}`] ? data[`pd_LO_CN_${index}`]: row.pd_LO_CN}
                options={pd_LO_CNOptions}
                onChange={(e) =>LocationDropDown(`pd_LO_CN_${index}`, e.value)}
                placeholder="Select Location"
              />
              </td>
            
              <td>
                <InputText
                style={{ width: "10rem" }}
                id={`pd_LT_NO_${index}`}
                name={`pd_LT_NO_${index}`}
                defaultValue={row.pd_LT_NO}
                onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText
                style={{ width: "15rem" }}
                id={`pd_SU_RF_${index}`}
                name={`pd_SU_RF_${index}`}
                defaultValue={row.pd_SU_RF}
                onChange={(e) => onchangedata(e)}
                />
                </td>
              
                <td>
              <Calendar style={{width:"10rem"}}
              id={`pd_EX_DT_${index}`}
              name={`pd_EX_DT_${index}`}
              value={row.pd_EX_DT? moment(row.pd_EX_DT, "YYYYMMDD").toDate(): null}
              onChange={(e) => onDateChange({ target: { name: "pd_EX_DT", value: moment(e.value).format("YYYYMMDD") } })}
              dateFormat="dd/mm/yy"
              />
              </td>
                
              <td>
              <InputText  style={{width:"18rem"}}
                id={`pd_RE_MK_${index}`}
                name={`pd_RE_MK_${index}`}
                defaultValue={row.pd_RE_MK}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              </tr>
              ))}
          </tbody>
        </table>   
        
        </div>
          </div>  
        </TabPanel>
        <TabPanel header="Landed Cost">
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow1} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow1} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div  style={multitablestyle}>
        <table className="custom-table" id="PurchaseReceiptstable1" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Landed Item</th>
          <th>Description </th>
          <th>Applied</th>
          <th>Landed Cost</th>
          <th>Tax Applicable</th>
          <th>Tax %</th>
          <th>Tax Amount</th>
          <th>Pay to Supplier</th>
          <th style={{ display: "none" }}>Supplier</th>
          <th>Supplier <span style={{ color: 'red', }}>*</span></th>
          <th>Currency</th>
          <th>Rate</th>
        </tr>
      </thead>
      <tbody>
      {PurchaseReceiptstable1.map((row, index) => (
              <tr key={index}>
            <td style={{ display: "none" }} >
            <InputText
              id={`pdUkNo_${index}`}
              name={`pdUkNo_${index}`}
              defaultValue={row.pdUkNo}
            />
          </td>
          <td>
          <InputText disabled style={{width:"12rem"}}
            id={`landed_ITEM_${index}`}
            name={`landed_ITEM_${index}`}
            defaultValue={row.landed_ITEM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText disabled style={{width:"12rem"}}
            id={`description_${index}`}
            name={`description_${index}`}
            defaultValue={row.description}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <Dropdown style={{width:"7rem"}}
          id={`applied_${index}`}
          name={`applied_${index}`} 
          value={data[`applied_${index}`] ? data[`applied_${index}`] : row.applied} 
          options={[
          { label: "Yes", value: "y" },
          { label: "No", value: "n" },
          ]}
          onChange={(e) => AppliedDropDown(`applied_${index}`, e.value)} 
          placeholder="Select Applied Y/N "
          />
          </td>
          <td>
          <InputText style={{width:"10rem"}}
            id={`cost_AMOUNT_${index}`}
            name={`cost_AMOUNT_${index}`}
            defaultValue={row.cost_AMOUNT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Dropdown style={{width:"12rem"}}
          id={`tax_APPLICABLE_${index}`}
          name={`tax_APPLICABLE_${index}`} 
          value={data[`tax_APPLICABLE_${index}`] ? data[`tax_APPLICABLE_${index}`] : row.tax_APPLICABLE} 
          options={[
          { label: "Yes", value: "y" },
          { label: "No", value: "n" },
          ]}
          onChange={(e) => TaxDropDown(`tax_APPLICABLE_${index}`, e.value)} 
          placeholder="Select Tax Y/N "
          />
          </td>
          <td>
          <InputText  disabled style={{width:"10rem"}}
            id={`tax_PERCENTAGE_${index}`}
            name={`tax_PERCENTAGE_${index}`}
            defaultValue={row.tax_PERCENTAGE}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText  disabled style={{width:"10rem"}}
            id={`tax_AMOUNT_${index}`}
            name={`tax_AMOUNT_${index}`}
            defaultValue={row.tax_AMOUNT}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          <td>
          <Dropdown disabled style={{width:"12rem"}}
          id={`pay_to_SUPPLIER_${index}`}
          name={`pay_to_SUPPLIER_${index}`} 
          value={data[`pay_to_SUPPLIER_${index}`] ? data[`pay_to_SUPPLIER_${index}`] : row.pay_to_SUPPLIER} 
          options={[
          { label: "Yes", value: "y" },
          { label: "No", value: "n" },
          ]}
          onChange={(e) => SupplierDropDown(`pay_to_SUPPLIER_${index}`, e.value)} 
          placeholder="Select Pay to Supplier "
          />
          </td>
          <td style={{ display: "none" }}> 
          <InputText 
          id={`supplier_${index}`}
          name={`supplier_${index}`}
          value={row.supplier ? row.supplier: data[`${"supplier"}_${index}`]}
          onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <div style={{ display: "flex",width:"15rem"}}>
          <InputText 
          id={`name_${index}`}
          name={`name_${index}`}
          onChange={(e) => onchangedata(e)}
          onFocus={() => setShowSearchIcon(true)}
          value={row.name ? row.name : data[`${"name"}_${index}`]}
          />
          {showSearchIcon && (
           <i
          className="pi pi-search"
          style={{
          color: "var(--primary-color)",
          fontSize: "119%",
          marginTop: "7%",
          cursor: "pointer",
          }}
          onClick={(e) => openSupplierPopup(index)}
          ></i>
          )}
          </div>
          </td>
          <td>
           <Dropdown  style={{width:"7rem"}}
            id={`Currency_${index}`}
            name={`Currency_${index}`}
            value={ data[`Currency_${index}`] ? data[`Currency_${index}`]: row.Currency}
            options={CurrencyOptions}
            onChange={(e) =>CurrencyDropDown(`Currency_${index}`, e.value)}
            placeholder="Select Currency"
           />
          </td>
          <td>
          <InputText  disabled style={{width:"10rem"}}
            id={`rate_${index}`}
            name={`rate_${index}`}
            defaultValue={row.rate}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
  </div>  
        </TabPanel>
      </TabView>
      </div> 
      {/* supplier h */}
       <AddressBookPopup
          visible={SupplierhPopupVisible}
          onClose={() => setSupplierhPopupVisible(false)}
          onDataSelect={handleDataSelect}
          formData={data}
          addressType={Addresstype}
          setFormData={setFormData}
        />
       <ItemPopup
          visible={ItemPopupVisible}
          onClose={() => setItemPopupVisible(false)}
          onDataSelect={handleDataSelect2}
          formData={data}
        />
        {/* supplier */}
        <AddressBookPopup
          visible={SupplierPopupVisible}
          onClose={() => setSupplierPopupVisible(false)}
          onDataSelect={handlesupplier}
          formData={data}
          addressType={Addresstype}
          setFormData={setFormData}
        />
      
    </Dialog>
  );
}
