import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
export default function BedMasterDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BedMasterTableData,
  setBedMasterTableData,
  bm_DE_PT,
  setbm_DE_PT,
  bm_ST_CD,
  setbm_ST_CD,
  bm_RM_TY,
  setbm_RM_TY,
  bm_CO_NO,
}) {
  const {
    bmUkNo,
  } = data;

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      const formattedDate1 = moment(selectedDate).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      const formattedDate1 = moment(value).format("DD/MM/YYYY");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const [bm_DE_PTOptions, setbm_DE_PTOptions] = useState([]);
  const [bm_RM_TYOptions, setbm_RM_TYOptions] = useState([]);
  const [bm_ST_CDOptions, setbm_ST_CDOptions] = useState([]);

  const onChangeTime=(name,value,index)=>{

    value= moment(value, "HH:mm:ss").format("HHmmss");
    onchangeFormData(name, value,index);
}


    //Type
    const TypeDropdown = (name, value) => {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setbm_RM_TY(value);
  };


const StatusDropdown = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setbm_ST_CD(value);
};


  // BIDDING type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbm_DE_PT(value);
  };

  useEffect(() => {
    Type();
    Status();
    fetchDropdownBT();
  }, []);

  const Type = async () => {
    const Options = await fetchClassCodeDS("BD/RT");
    setbm_RM_TYOptions(Options);
  };

  const Status = async () => {
    const Options = await fetchClassCodeDS("BD/ST");
    setbm_ST_CDOptions(Options);
  };

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeDS("BD/DP");
    setbm_DE_PTOptions(Options);
  };




  const addRow = () => {
    setBedMasterTableData([...BedMasterTableData, {}]);
  };
  
  const deleteRow = () => {
    if (BedMasterTableData.length > 0) {
      const updatedData = [...BedMasterTableData];
      updatedData.pop();
      setBedMasterTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(bmUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );


  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "75rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={bmUkNo ? "Revise Bed Master" : "Add Bed Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bm_DE_PT" className="font-bold">
                  Department
                  </label>
                  <Dropdown
                  name="bm_DE_PT"
                  value={bm_DE_PT}
                  options={bm_DE_PTOptions}
                  onChange={(e) => onchangeDropDownFormData1("bm_DE_PT", e.value)}
                  placeholder="Select Department"
                />
                </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="bm_CO_NO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="bm_CO_NO"
                    name="bm_CO_NO"
                    value={bm_CO_NO}
                    onChange={(Event) => onchangedata(Event)}
                  />
                  </div>
                </div>
                </div>  
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
          marginTop: "2%",
        }}
        >
      
        <div
          className="table-buttons"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 10px 10px 0",
            marginTop: "2%",
          }}
        >
          <Button
            icon="pi pi-plus"
            onClick={addRow}
            style={{
              marginRight: "8px",
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Add"
          />
          <Button
            icon="pi pi-minus"
            onClick={deleteRow}
            style={{
              backgroundColor: "transparent",
              color: "black",
              border: "none",
            }}
            tooltip="Delete"
          />
        </div>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="BedMastertable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Seq No</th>
              <th>Room Type</th>
              <th>Bed No</th>
              <th>Description</th>
              <th>Rate</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {BedMasterTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`bmUkNo_${index}`}
                    name={`bmUkNo_${index}`}
                    value={row.bmUkNo}
                  />
                </td>
                <td >
                  <InputText  disabled style={{width:"90px"}}
                    id={`bm_SQ_NO_${index}`}
                    name={`bm_SQ_NO_${index}`}
                    defaultValue={index+1}
                  />
                </td>
                  
                <td>
                  <Dropdown
                    id={`bm_RM_TY_${index}`}
                    name={`bm_RM_TY_${index}`}
                    value={ data[`bm_RM_TY_${index}`] ? data[`bm_RM_TY_${index}`]: row.bm_RM_TY}
                    options={bm_RM_TYOptions}
                    onChange={(e) =>TypeDropdown(`bm_RM_TY_${index}`, e.value)}
                    placeholder="Select Room Type"
                  /> 
                  </td>
                  <td >
                  <InputText  style={{width:"120px"}}
                    id={`bm_BD_NO_${index}`}
                    name={`bm_BD_NO_${index}`}
                    defaultValue={row.bm_BD_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"150px"}}
                    id={`bm_BD_DS_${index}`}
                    name={`bm_BD_DS_${index}`}
                    defaultValue={row.bm_BD_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText  style={{width:"90px"}}
                    id={`bm_CH_RG_${index}`}
                    name={`bm_CH_RG_${index}`}
                    defaultValue={row.bm_CH_RG}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <Calendar style={{width:"150px"}}
                id={`bm_EX_DT_${index}`}
                name={`bm_EX_DT_${index}`}
                value={row.bm_EX_DT? moment(row.bm_EX_DT, "YYYYMMDD").toDate(): null}
                onChange={(e) => onDateChange({ target: { name: "bm_EX_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                  <Dropdown
                    id={`bm_ST_CD_${index}`}
                    name={`bm_ST_CD_${index}`}
                    value={ data[`bm_ST_CD_${index}`] ? data[`bm_ST_CD_${index}`]: row.bm_ST_CD}
                    options={bm_ST_CDOptions}
                    onChange={(e) =>StatusDropdown(`bm_ST_CD_${index}`, e.value)}
                    placeholder="Select Status"
                  /> 
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

    </Dialog>
  );
}
