import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import CustomerPopup from "./CustomerPopup";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function PostInvoiceDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  addRow,
  deleteRow,
  cl_CU_NO,
  cl_PY_CO,
  setcl_PY_CO,
  PostInvoicetable,
  SelectedemEMNOE,
  setSelectedemEMNOE,
  SelectedemEMNO,
  setSelectedemEMNO,
}) {
  const { 
  clUkNo,
  cl_DO_NO,
  cl_DO_TY,
  cl_DO_CO,
  cl_CR_CD,
  cl_CO_NM,
  cl_SU_IN,
  cl_CR_RT,
  cl_GL_DT,
  cl_IN_DT,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [cl_CO_NMOptions, setcl_CO_NMOptions] = useState([]);
  const [cl_CR_CDOptions, setcl_CR_CDOptions] = useState([]);
  const [cl_PY_COOptions, setcl_PY_COOptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const url = `${domain}/api/ClassCode/All`;
  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl4 = `${urlpayterm}`;
  const finalUrl1 = `${urlcost}`;
  const parameter3 = "FO/CR";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setcl_CO_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rdd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setcl_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setcl_PY_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
const [CustomerPopupVisible, setCustomerPopupVisible] = useState(false);
const openCustomerPopup = (e) => {
  setCustomerPopupVisible(true);
  setSelectedRowIndex(e);
};
//  startsearchpopup
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.abAdNo);
  setSelectedemEMNOE(data.ab_AD_NM);
  onchangeFormData("cl_CU_NO", data.abAdNo);
  onchangeFormData("name", data.ab_AD_NM);
  setCustomerPopupVisible(false);
};
  const isUpdateMode = Boolean(clUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{clUkNo ? "Revise Post Invoice" : "Add Post Invoice"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle} >
          <div className="formgrid grid">
          <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_DO_NO" className="font-bold">
                Document No
              </label>
              <InputText disabled
                id="cl_DO_NO"
                name="cl_DO_NO"
                value={cl_DO_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_DO_TY" className="font-bold">
                Document Type
              </label>
              <InputText disabled
                id="cl_DO_TY"
                name="cl_DO_TY"
                value={cl_DO_TY}
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_DO_CO" className="font-bold">
                Document Company
              </label>
              <InputText  disabled
                id="cl_DO_CO"
                name="cl_DO_CO"
                value={cl_DO_CO}
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
                <label htmlFor="cl_CU_NO" className="font-bold">
                  Customer No <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText disabled
                    id="cl_CU_NO"
                    name="cl_CU_NO"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedemEMNO}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openCustomerPopup}
                  ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
                <label htmlFor="name" className="font-bold">
                Name
                </label>
                <InputText disabled
                  id="name"
                  name="name"
                  value={SelectedemEMNOE}
                />
              </div>
              <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
                <label htmlFor="cl_GL_DT" className="font-bold">
                    GL Date
                </label>  
                <Calendar disabled
                  id="cl_GL_DT"
                  name="cl_GL_DT"
                  value={moment(cl_GL_DT, "YYYYMMDD").toDate()}
                  onChange={(e) => onchangedata({ target: { name: "cl_GL_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_IN_DT" className="font-bold">
                  Invoice Date
              </label>
              <Calendar disabled
                id="cl_IN_DT"
                name="cl_IN_DT"
                value={moment(cl_IN_DT, "YYYYMMDD").toDate()}
                onChange={(e) => onchangedata({ target: { name: "cl_IN_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_CO_NM" className="font-bold">
                  Cost Centre
              </label>
              <Dropdown disabled
                id="cl_CO_NM"
                name="cl_CO_NM"
                value={cl_CO_NM}
                options={cl_CO_NMOptions}
                onChange={(e) => onchangeDropDownFormData1("cl_CO_NM", e.value)}
                placeholder="Select Cost Centre"
              />
              {/* <InputText disabled
                id="cl_CO_NM"
                name="cl_CO_NM"
                defaultValue={cl_CO_NM}
                onChange={(e) => onchangedata(e)}
              /> */}
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_CR_CD" className="font-bold">
                Currency
              </label>
              <Dropdown disabled
                id="cl_CR_CD"
                name="cl_CR_CD"
                value={cl_CR_CD}
                options={cl_CR_CDOptions}
                onChange={(e) => onchangeDropDownFormData4("cl_CR_CD", e.value)}
                placeholder="Select Currency"
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_PY_CO" className="font-bold">
                Pay Term
              </label>
              <Dropdown disabled
                id="cl_PY_CO"
                name="cl_PY_CO"
                value={cl_PY_CO}
                options={cl_PY_COOptions}
                onChange={(e) => onchangeDropDownFormData5("cl_PY_CO", e.value)}
                placeholder="Select Pay Term"
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_CR_RT" className="font-bold">
                Exchange Rate
              </label>
              <InputText disabled
                id="cl_CR_RT"
                name="cl_CR_RT"
                defaultValue={cl_CR_RT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-4 col-12 pe-md-2">
              <label htmlFor="cl_SU_IN" className="font-bold">
                Customer PO
              </label>
              <InputText disabled
                id="cl_SU_IN"
                name="cl_SU_IN"
                value={cl_SU_IN}
                onChange={(e) => onchangedata(e)}
              />
            </div> 
          </div>
        </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
          <div  style={multitablestyle}>
        <table className="custom-table" id="PostInvoicetable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th>Pay Item</th>
              <th>Due Date</th>
              <th>Gross Amount</th>
              <th>Discount Given</th>
              <th>Fore. Gross Amount</th>
              <th>Fore. Discount Given</th>
              <th>Parent No</th>
              <th>Payor No</th>
              <th>Pay Status</th>
              <th>G/L Post Code</th>
              <th>Batch No</th>
              <th>Batch Type</th>
              <th>Sales Doc No</th>
              <th>Sales Doc Co</th>
              <th>Sales Doc Type</th>
              <th>Units</th>
              <th>Reference</th>
              <th>GL Code</th>
              <th>Void Flag</th>
              <th>Void Date</th>
            </tr>
          </thead>
          <tbody>
          {PostInvoicetable.map((row, index) => (
            <tr key={index}>
              <td style={{ display: "none" }} >
                <InputText
                  id={`clUkNo_${index}`}
                  name={`clUkNo_${index}`}
                  defaultValue={row.clUkNo}
                />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`cl_DO_PI_${index}`}
                name={`cl_DO_PI_${index}`}
                defaultValue={row.cl_DO_PI}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              
              <td>
              <Calendar disabled style={{width:"10rem"}}
                id={`cl_DD_DT_${index}`}
                name={`cl_DD_DT_${index}`}
                value={ row.cl_DD_DT_ ? moment(row.cl_DD_DT_, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "cl_DD_DT_", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <InputText disabled style={{width:"10rem"}}
                id={`cl_GR_AM_${index}`}
                name={`cl_GR_AM_${index}`}
                defaultValue={row.cl_GR_AM}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_DI_AM_${index}`}
                name={`cl_DI_AM_${index}`}
                defaultValue={row.cl_DI_AM}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_CR_AM_${index}`}
                name={`cl_CR_AM_${index}`}
                defaultValue={row.cl_CR_AM}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_DI_FR_${index}`}
                name={`cl_DI_FR_${index}`}
                defaultValue={row.cl_DI_FR}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_PA_NO_${index}`}
                name={`cl_PA_NO_${index}`}
                defaultValue={row.cl_PA_NO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_PY_NO_${index}`}
                name={`cl_PY_NO_${index}`}
                defaultValue={row.cl_PY_NO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_PA_ST_${index}`}
                name={`cl_PA_ST_${index}`}
                defaultValue={row.cl_PA_ST}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_GL_PO_${index}`}
                name={`cl_GL_PO_${index}`}
                defaultValue={row.cl_GL_PO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_BA_NO_${index}`}
                name={`cl_BA_NO_${index}`}
                defaultValue={row.cl_BA_NO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_BA_TY_${index}`}
                name={`cl_BA_TY_${index}`}
                defaultValue={row.cl_BA_TY}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`sales_doc_${index}`}
                name={`sales_doc_${index}`}
                defaultValue={row.sales_doc}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`sales_doc_co_${index}`}
                name={`sales_doc_co_${index}`}
                defaultValue={row.sales_doc_co}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`sales_doc_type_${index}`}
                name={`sales_doc_type_${index}`}
                defaultValue={row.sales_doc_type}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_UN_QT_${index}`}
                name={`cl_UN_QT_${index}`}
                defaultValue={row.cl_UN_QT}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_RE_FR_${index}`}
                name={`cl_RE_FR_${index}`}
                defaultValue={row.cl_RE_FR}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_GL_CO_${index}`}
                name={`cl_GL_CO_${index}`}
                defaultValue={row.cl_GL_CO}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <InputText  disabled style={{width:"10rem"}}
                id={`cl_VD_FL_${index}`}
                name={`cl_VD_FL_${index}`}
                defaultValue={row.cl_VD_FL}
                onChange={(e) => onchangedata(e)}
              />
              </td>
              <td>
              <Calendar disabled style={{width:"10rem"}}
                id={`cl_VD_DT_${index}`}
                name={`cl_VD_DT_${index}`}
                value={ row.cl_VD_DT_ ? moment(row.cl_VD_DT_, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "cl_VD_DT_", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              </tr>
              ))}
          </tbody>
        </table>   
    
    </div>
        </div>
      </div>
      <CustomerPopup
        visible={CustomerPopupVisible}
        onClose={() => setCustomerPopupVisible(false)}
        onDataSelect={handleDataSelect}
        formData={data}
      />
    </Dialog>
  );
}
