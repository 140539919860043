import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import ProcessRulesDialog from "./ProcessRulesDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  prUkNo:"",
  pr_RL_ID:"",
  pr_RL_TY:"",
  pr_RL_CO:"",
  pr_CO_CT:"",
  pr_GP_01:"",
  pr_GP_02:"",
  pr_GP_03:"",
  pr_PA_IN:"",
  pr_PK_IN:"",
  pr_RP_IN:"",
  pr_OR_GP:"",
};

const initialFilters = {
  pr_RL_ID: { value: "", operation: "" },
  pr_RL_TY: { value: "", operation: "" },
  pr_RL_CO: { value: "", operation: "" },
  pr_CO_CT: { value: "", operation: "" },
  pr_GP_01: { value: "", operation: "" },
  pr_GP_02: { value: "", operation: "" },
  pr_GP_03: { value: "", operation: "" },
  pr_PA_IN: { value: "", operation: "" },
  pr_PK_IN: { value: "", operation: "" },
  pr_RP_IN: { value: "", operation: "" },
  pr_OR_GP: { value: "", operation: "" },
};

const ProcessRulesMain = () => {
    // buttonstyles
    const {formatDate, buttonStyles, addButtonStyles,
      copyButtonStyles, checkboxStyle,tableHeaderStyle,
      inputStyle,tableCellStyle, } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
// drodpdowns
  const [pr_GP_01, setpr_GP_01 ] = useState("");
  const [pr_GP_02, setpr_GP_02 ] = useState("");
  const [pr_GP_03, setpr_GP_03 ] = useState("");
  const [pr_OR_GP, setpr_OR_GP ] = useState("");
  const [pr_PA_IN, setpr_PA_IN ] = useState("");
  const [pr_PA_MO, setpr_PA_MO ] = useState("");
  const [pr_PK_IN, setpr_PK_IN ] = useState("");
  const [pr_PK_MO, setpr_PK_MO ] = useState("");
  const [pr_RP_IN, setpr_RP_IN ] = useState("");
  const [pr_RP_MO, setpr_RP_MO ] = useState("");
  const [pr_CO_CT, setpr_CO_CT ] = useState("");
  const [pr_RL_ID, setpr_RL_ID ] = useState("");
  const [pr_RL_TY, setpr_RL_TY ] = useState("");
  
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    setpr_GP_01(null);
    setpr_GP_02(null);
    setpr_GP_03(null);
    setpr_OR_GP(null);
    setpr_PA_IN(null);
    setpr_PA_MO(null);
    setpr_PK_IN(null);
    setpr_PK_MO(null);
    setpr_RP_IN(null);
    setpr_RP_MO(null);
    setpr_CO_CT(null);
    setOpen(true);
  };
  const handleClose = () => {
    setFormData(initialValue);
    setpr_GP_01(null);
    setpr_GP_02(null);
    setpr_GP_03(null);
    setpr_OR_GP(null);
    setpr_PA_IN(null);
    setpr_PA_MO(null);
    setpr_PK_IN(null);
    setpr_PK_MO(null);
    setpr_RP_IN(null);
    setpr_RP_MO(null);
    setpr_CO_CT(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
       handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/ProcessRules/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const ProcessRulesData = response.data;
          delete ProcessRulesData.prUkNo;
          delete ProcessRulesData.pr_RL_ID;
          setFormData(ProcessRulesData);
          setpr_GP_01(ProcessRulesData.pr_GP_01);
          setpr_GP_02(ProcessRulesData.pr_GP_02);
          setpr_GP_03(ProcessRulesData.pr_GP_03);
          setpr_OR_GP(ProcessRulesData.pr_OR_GP);
          setpr_PA_IN(ProcessRulesData.pr_PA_IN);
          setpr_PA_MO(ProcessRulesData.pr_PA_MO);
          setpr_PK_IN(ProcessRulesData.pr_PK_IN);
          setpr_PK_MO(ProcessRulesData.pr_PK_MO);
          setpr_RP_IN(ProcessRulesData.pr_RP_IN);
          setpr_RP_MO(ProcessRulesData.pr_RP_MO);
          setpr_CO_CT(ProcessRulesData.pr_CO_CT);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const [pr_RL_CO , setpr_RL_CO] = useState("");
  const handleDefault = async () => {
    setpr_RL_CO(`${company}`)
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            if(fName === "PR_RL_TY"){
              setpr_RL_TY(dataDefault.versionIds[i].default_VALUE)
            }
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/ProcessRules/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/ProcessRules/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const ProcessRulesData = response.data;
        setFormData(ProcessRulesData);
        setpr_GP_01(ProcessRulesData.pr_GP_01);
        setpr_GP_02(ProcessRulesData.pr_GP_02);
        setpr_GP_03(ProcessRulesData.pr_GP_03);
        setpr_OR_GP(ProcessRulesData.pr_OR_GP);
        setpr_PA_IN(ProcessRulesData.pr_PA_IN);
        setpr_PA_MO(ProcessRulesData.pr_PA_MO);
        setpr_PK_IN(ProcessRulesData.pr_PK_IN);
        setpr_PK_MO(ProcessRulesData.pr_PK_MO);
        setpr_RP_IN(ProcessRulesData.pr_RP_IN);
        setpr_RP_MO(ProcessRulesData.pr_RP_MO);
        setpr_CO_CT(ProcessRulesData.pr_CO_CT);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  var requestBodyAPIInput = {};
  function values () {
        const CO_CT = FormData.pr_CO_CT;
        const GP_01 = FormData.pr_GP_01; 
        const GP_02 = FormData.pr_GP_02;
        const GP_03 = FormData.pr_GP_03; 
        const PA_IN = FormData.pr_PA_IN;
        const PA_MO = FormData.pr_PA_MO; 
        const PK_IN = FormData.pr_PK_IN;
        const PK_MO = FormData.pr_PK_MO; 
        const RP_IN = FormData.pr_RP_IN;
        const RP_MO = FormData.pr_RP_MO;
        const OR_GP = FormData.pr_OR_GP;
        requestBodyAPIInput = {
            prUkNo: FormData.prUkNo,
          pr_RL_ID: FormData.pr_RL_ID,
          pr_RL_TY: pr_RL_TY,
          pr_RL_CO: pr_RL_CO,
          pr_CO_CT: CO_CT,
          pr_GP_01: GP_01,
          pr_GP_02: GP_02,
          pr_GP_03: GP_03,
          pr_PA_IN: PA_IN,
          pr_PA_MO: PA_MO,
          pr_PK_IN: PK_IN,
          pr_PK_MO: PK_MO,
          pr_RP_IN: RP_IN,
          pr_RP_MO: RP_MO,
          pr_OR_GP: OR_GP,
        };

  };
  const handleFormSubmit = () => {  
    values ();
    if (FormData.pr_RL_ID) {
      if (FormData.prUkNo) {
        const confirm = window.confirm("Do you want to Update ?");
        const update = `${domain}/api/ProcessRules/Update`;
        if (confirm) {
          axios
            .put(update, requestBodyAPIInput, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            })
            .then((resp) => {
              handleResponse(resp.data);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        }
      } else {
        axios
          .post(`${domain}/api/ProcessRules/Add`, requestBodyAPIInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
          handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error adding data:", error);
          });
      }

    } else {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const firstHalf = Math.floor(currentYear / 100);
        const secondHalf = String(currentYear % 100).padStart(2, "0");
        const urlNextNumber = `${domain}/api/DocumentNumber/All`;
        const parameters = `${pr_RL_TY}/${secondHalf}/${company}/${firstHalf}`;  
        const finalUrl1 = `${urlNextNumber}/${parameters}`;

        axios.get(finalUrl1, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
            setpr_RL_ID(resp.data.dn_DO_NO);
            FormData.pr_RL_ID = resp.data.dn_DO_NO;
            if(resp.data.dn_DO_NO ===undefined)
            {
              handleResponse(resp.data);
            }else{
              values();
              axios.post(`${domain}/api/ProjectMaster/Add`, requestBodyAPIInput, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "http://localhost:3000",
                  "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                },
              })
              .then((resp) => {
                handleResponse(resp.data);
              })
              .catch((error) => {
                console.error("Error adding data:", error);
              });
            }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}>
            <thead>
              <tr>
                <td style={{width:"40px"}}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RL_ID"] ? filters["pr_RL_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RL_ID",
                        e.target.value,
                        filters["pr_RL_ID"]
                          ? filters["pr_RL_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RL_TY"] ? filters["pr_RL_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RL_TY",
                        e.target.value,
                        filters["pr_RL_TY"] ? filters["pr_RL_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RL_CO"] ? filters["pr_RL_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RL_CO",
                        e.target.value,
                        filters["pr_RL_CO"]
                          ? filters["pr_RL_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_CO_CT"] ? filters["pr_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_CO_CT",
                        e.target.value,
                        filters["pr_CO_CT"]
                          ? filters["pr_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_GP_01"] ? filters["pr_GP_01"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_GP_01",
                        e.target.value,
                        filters["pr_GP_01"]
                          ? filters["pr_GP_01"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_GP_02"] ? filters["pr_GP_02"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_GP_02",
                        e.target.value,
                        filters["pr_GP_02"]
                          ? filters["pr_GP_02"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_GP_03"] ? filters["pr_GP_03"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_GP_03",
                        e.target.value,
                        filters["pr_GP_03"] ? filters["pr_GP_03"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_PA_IN"] ? filters["pr_PA_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_PA_IN",
                        e.target.value,
                        filters["pr_PA_IN"] ? filters["pr_PA_IN"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_PK_IN"] ? filters["pr_PK_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_PK_IN",
                        e.pr_PK_IN.value,
                        filters["pr_PK_IN"] ? filters["pr_PK_IN"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_RP_IN"] ? filters["pr_RP_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_RP_IN",
                        e.target.value,
                        filters["pr_RP_IN"] ? filters["pr_RP_IN"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["pr_OR_GP"] ? filters["pr_OR_GP"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "pr_OR_GP",
                        e.target.value,
                        filters["pr_OR_GP"] ? filters["pr_OR_GP"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
               
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Rule ID</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
                <th style={tableHeaderStyle}>Process Group 1</th>
                <th style={tableHeaderStyle}>Process Group 2</th>
                <th style={tableHeaderStyle}>Process Group 3</th>
                <th style={tableHeaderStyle}>Putaway Instruction</th>
                <th style={tableHeaderStyle}>Picking Instruction</th>
                <th style={tableHeaderStyle}>Replenishment Instruction</th>
                <th style={tableHeaderStyle}>Order Group</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.prUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.prUkNo}
                        checked={selectedRows.includes(rowData.prUkNo)}
                        onChange={() => handleRowSelect(rowData.prUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.pr_RL_ID}</td>
                    <td style={tableCellStyle}>{rowData.pr_RL_TY}</td>
                    <td style={tableCellStyle}>{rowData.pr_RL_CO}</td>
                    <td style={tableCellStyle}>{rowData.pr_CO_CT}</td>
                    <td style={tableCellStyle}>{rowData.pr_GP_01}</td> 
                    <td style={tableCellStyle}>{rowData.pr_GP_02}</td>
                    <td style={tableCellStyle}>{rowData.pr_GP_03}</td>    
                    <td style={tableCellStyle}>{rowData.pr_PA_IN}</td>
                    <td style={tableCellStyle}>{rowData.pr_PK_IN}</td> 
                    <td style={tableCellStyle}>{rowData.pr_RP_IN}</td>
                    <td style={tableCellStyle}>{rowData.pr_OR_GP}</td>                
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <ProcessRulesDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        pr_RL_CO={pr_RL_CO}
        pr_GP_01={pr_GP_01}
        setpr_GP_01={setpr_GP_01}
        pr_GP_02={pr_GP_02}
        setpr_GP_02={setpr_GP_02}
        pr_GP_03={pr_GP_03}
        setpr_GP_03={setpr_GP_03}
        pr_OR_GP={pr_OR_GP}
        setpr_OR_GP={setpr_OR_GP}
        pr_PA_IN={pr_PA_IN}
        setpr_PA_IN={setpr_PA_IN}
        pr_PA_MO={pr_PA_MO}
        setpr_PA_MO={setpr_PA_MO}
        pr_PK_IN={pr_PK_IN}
        setpr_PK_IN={setpr_PK_IN}
        pr_PK_MO={pr_PK_MO}
        setpr_PK_MO={setpr_PK_MO}
        pr_RP_IN={pr_RP_IN}
        setpr_RP_IN={setpr_RP_IN}
        pr_RP_MO={pr_RP_MO}
        setpr_RP_MO={setpr_RP_MO}
        pr_CO_CT={pr_CO_CT}
        setpr_CO_CT={setpr_CO_CT}
        pr_RL_ID={pr_RL_ID}
        pr_RL_TY={pr_RL_TY}
      />
    </div>
  );
};

export default ProcessRulesMain;
