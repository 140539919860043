import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ItemPopup from "../Popups/ItemPopup";
import Costcenter  from "views/common/CostCentreDrpDwn";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import WorkCentrePopup from "../Popups/WorkCentrePopup";
import OperationSequencePopup from "../Popups/OperationSequencePopup";
export default function RoutingMasterDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  onchangedata,
  setRoutingMastertable,
  RoutingMastertable,
  routingItemName,
  setroutingItemName,
  rt_CO_CT,
  setrt_CO_CT,
  rt_BT_UM,
  setrt_BT_UM,
  rt_RT_TY,
  setrt_RT_TY,
  rt_TB_CD,
  setrt_TB_CD,
  rtWK_dESC,
  setrtWK_dESC,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData4,
}) {
  const { 
    rtUkNo,
    rt_IT_NM,
    rt_BT_QT,
    rt_WK_CT, 
    rt_OP_SQ, 
    rt_OP_DS,
    rt_LB_HR,
    rt_MC_HR,
    rt_ST_HR,
    rt_LN_NO,
  } = data;
  const { borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle }= ButtonStyles();
  // from main page
  const addRow = () => {
    setRoutingMastertable([...RoutingMastertable, {}]);
  };
  const deleteRow = () => {
    if (RoutingMastertable.length > 0) {
      const updatedData = [...RoutingMastertable];
      updatedData.pop();
      setRoutingMastertable(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [RoutingItemPopupVisible, setRoutingItemPopupVisible] = useState(false);
  const openRoutingItemPopup = () => {
    setRoutingItemPopupVisible(true);
  };
  const handleDataSelectItem = (data) => {
    setroutingItemName(data.im_IT_DS);
    onchangeFormData("rt_IT_NM", data.im_IT_NM);
    setRoutingItemPopupVisible(false);
    setShowSearchIcon(false);
  };
  const [WorkCentrePopupVisible, setWorkCentrePopupVisible] = useState(false);
  const [OperSequPopupVisible, setOperSequPopupVisible] = useState(false);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const openWorkcentrePopup = (e) => {
    setWorkCentrePopupVisible(true);
    setSelectedRowIndex(e);
  };
  const openOperationPopup = (e) => {
    setOperSequPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelectWKC = (content) => {
    setrtWK_dESC(content.wc_WC_DS);
    document.getElementById(`rtWK_dESC_${SelectedRowIndex}`).value = content.wc_WC_DS;
    document.getElementById(`rt_WK_CT_${SelectedRowIndex}`).value = content.wc_WK_CT;
    setWorkCentrePopupVisible(false);
    setShowSearchIcon(false);
  };
  const handleDataSelectOpSq = (content) => {
    document.getElementById(`rt_OP_DS_${SelectedRowIndex}`).value = content.os_SQ_DS;
    document.getElementById(`rt_OP_SQ_${SelectedRowIndex}`).value = content.os_OP_SQ;
    setOperSequPopupVisible(false);
    setShowSearchIcon(false);
  };

// class code Dropdowns
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
const { fetchCostCenter } = Costcenter();
const [rt_CO_CTOptions, setrt_CO_CTOptions] = useState([]);
const [rt_BT_UMOptions, setrt_BT_UMOptions] = useState([]);
const [rt_RT_TYOptions, setrt_RT_TYOptions] = useState([]);
const [rt_TB_CDOptions, setrt_TB_CDOptions] = useState([]);

// UM
const onchangeDropDownFormData3 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setrt_BT_UM(value);
};
useEffect(() => {
  fetchCostCentre();
  fetchDropdownDataUM();
  fetchDropdownDataType();
  fetchDropdownTimeBasis();
}, []);
// COST center
const fetchCostCentre = async () => {
  const UMOptions = await fetchCostCenter();
  setrt_CO_CTOptions(UMOptions);
};
// UM
const fetchDropdownDataUM = async () => {
  const UMOptions = await fetchClassCodeCD("IN/UM");
  setrt_BT_UMOptions(UMOptions);
};
// Type
const fetchDropdownDataType = async () => {
  const FVOptions = await fetchClassCodeDS("MF/TY");
  setrt_RT_TYOptions(FVOptions);
};
// Time basis
const fetchDropdownTimeBasis = async () => {
  const Options = await fetchClassCodeDS("MF/TB");
  setrt_TB_CDOptions(Options);
};

// DATES DEFAULT MULTIROW
const [rt_FR_DT, setrt_FR_DT] = useState(moment().toDate());
const [rt_TO_DT, setrt_TO_DT] = useState(moment().toDate());

const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{rtUkNo ? "Revise Routing Master" : "Add Routing Master"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
return (
  <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
    modal
    className="p-fluid"
  >
    <div style={borderbox}>
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="rtUkNo" className="font-bold">
              uK
            </label>
            <InputText disabled
              id="rtUkNo"
              name="rtUkNo"
              value={rtUkNo}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_IT_NM" className="font-bold">
              Parent Item <span style={{ color: 'red' }}>*</span>
            </label>
              <div style={{display:"flex"}}>
                <InputText 
                  id="rt_IT_NM"
                  name="rt_IT_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={rt_IT_NM}
                />
                {showSearchIcon && (
                  <i className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openRoutingItemPopup}
                  ></i>
                )}
              </div>
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_IT_NM" className="font-bold">
              Description 
            </label>
            <InputText disabled
              id="routingItemName"
              name="routingItemName"
              onChange={(e) => onchangedata(e)}
              value={routingItemName}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_RT_TY" className="font-bold">
            Type
            </label>
            <Dropdown
              id="rt_RT_TY"
              name="rt_RT_TY"
              value={rt_RT_TY}
              options={rt_RT_TYOptions}
              onChange={(e) => onchangeDropDownFormData2("rt_RT_TY", e.value)}
              placeholder="Select Type"
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled
              id="rt_CO_CT"
              name="rt_CO_CT"
              value={rt_CO_CT}
              options={rt_CO_CTOptions}
              onChange={(e) => onchangeDropDownFormData1("rt_CO_CT", e.value)}
              placeholder="Select Cost Centre"
            />
          </div> 
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_BT_QT" className="font-bold">
            Batch Quantity
            </label>
            <InputText
              id="rt_BT_QT"
              name="rt_BT_QT"
              value={rt_BT_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
            <label htmlFor="rt_BT_UM" className="font-bold">
            UM
            </label>
            <Dropdown
            id="rt_BT_UM"
            name="rt_BT_UM"
            value={rt_BT_UM}
            options={rt_BT_UMOptions}
            onChange={(e) => onchangeDropDownFormData3("rt_BT_UM", e.value)}
            placeholder="Select UM"
          />
          </div>
        </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="RoutingMastertable" style={{overflow:"auto"}}>
            <thead>
              <tr>
                <th style={{display:"none"}}>UK_NO</th>
                <th>Line No</th>
                <th>Work Centre <span style={{ color: 'red' }}>*</span> </th>
                <th>Description</th>
                <th>Oper. Seq. <span style={{ color: 'red' }}>*</span> </th>
                <th>Description</th>
                <th>Instruction</th>
                <th>Labour Hrs</th>
                <th>Machine Hrs</th>
                <th>Setup Hrs</th>
                <th>Time Basis</th>
                <th>From Date</th>
                <th>To Date</th>
              </tr>
            </thead>
            <tbody>
            {RoutingMastertable.map((row, index) => (
              <tr key={index}>
                <td style={{display:"none"}}>
                  <InputText
                    id={`rtUkNo_${index}`}
                    name={`rtUkNo_${index}`}
                    defaultValue={row.rtUkNo}
                  />
                </td>
                <td>
                  <InputText disabled
                    style={{width:"5rem"}}
                    id={`rt_LN_NO_${index}`}
                    name={`rt_LN_NO_${index}`}
                    value={index+1}
                  />
                </td>
                <td>
              <div style={{display:"flex"}}>
                <InputText style={{width:"10rem"}}
                  id={`rt_WK_CT_${index}`}
                  name={`rt_WK_CT_${index}`}               
                  onChange={(Event) => onchangedata(Event)}
                  onFocus={() => setShowSearchIcon(true)}  
                  defaultValue={row.rt_WK_CT ? row.rt_WK_CT: row[`${"rt_WK_CT"}_${index}`]} 
                  // value={row.rt_WK_CT}
                />
                {showSearchIcon && (
                <i className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={() => openWorkcentrePopup(index)}
                  ></i>)}
                  </div>
                </td>
                <td>
                <InputText style={{width:"15rem"}} disabled
                  id={`rtWK_dESC_${index}`}
                  name={`rtWK_dESC_${index}`}
                  defaultValue={row.rtWK_dESC ? row.rtWK_dESC : row[`${"rtWK_dESC"}_${index}`]}
                />
                </td>
                <td>
                <div style={{display:'flex'}}>
                  <InputText style={{width:"10rem"}}
                    id={`rt_OP_SQ_${index}`}
                    name={`rt_OP_SQ_${index}`}
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={row.rt_OP_SQ}
                  />
                  {showSearchIcon && (
                    <i className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={() => openOperationPopup(index)}
                    ></i>)}
                  </div>
                </td>
                <td>
                <InputText style={{width:"15rem"}}
                  id={`rt_OP_DS_${index}`}
                  name={`rt_OP_DS_${index}`}
                  defaultValue={row.rt_OP_DS}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"8rem"}}
                  id={`rt_IN_ST_${index}`}
                  name={`rt_IN_ST_${index}`}
                  value={row.rt_IN_ST}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"8rem"}}
                  id={`rt_LB_HR_${index}`}
                  name={`rt_LB_HR_${index}`}
                  value={row.rt_LB_HR}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"8rem"}}
                  id={`rt_MC_HR_${index}`}
                  name={`rt_MC_HR_${index}`}
                  value={row.rt_MC_HR}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <InputText style={{width:"8rem"}}
                  id={`rt_ST_HR_${index}`}
                  name={`rt_ST_HR_${index}`}
                  value={row.rt_ST_HR}
                  onChange={(e) => onchangedata(e)}
                />
                </td>
                <td>
                <Dropdown
                  id={`rt_TB_CD_${index}`}
                  name={`rt_TB_CD_${index}`}
                  value={data[`rt_TB_CD_${index}`] ? data[`rt_TB_CD_${index}`] : rt_TB_CD}
                  options={rt_TB_CDOptions}
                  onChange={(e) => onchangeDropDownFormData4(`rt_TB_CD_${index}`, e.value)}
                  placeholder="Select Time Basis"
                />
                </td>
                <td>
                {/* <Calendar style={{width:"7rem"}}
                  id={`rt_FR_DT_${index}`}
                  name={`rt_FR_DT_${index}`}
                  value={row.rt_FR_DT? moment(row.rt_FR_DT, "YYYYMMDD").toDate(): null}
                  onChange={(e) => onDateChange({ target: { name: "rt_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                  dateFormat="dd/mm/yy"
                /> */}
                <Calendar style={{width:"7rem"}}
                  id={`rt_FR_DT_${index}`}
                  name={`rt_FR_DT_${index}`}
                  value={rt_FR_DT}
                  onChange={(e) => {setrt_FR_DT(e.value); onDateChange("rt_FR_DT", index, e.value);  }}
                  dateFormat="dd/mm/yy"
                />
                </td>
                <td>
                <Calendar style={{width:"7rem"}}
                  id={`rt_TO_DT_${index}`}
                  name={`rt_TO_DT_${index}`}
                  value={rt_TO_DT}
                  onChange={(e) => {setrt_TO_DT(e.value); onDateChange("rt_TO_DT", index, e.value);  }}
                  dateFormat="dd/mm/yy"
                />
                </td>
                </tr>
                ))}
            </tbody>
          </table>  
        </div>
      </div>
    </div>
    <ItemPopup
        visible={RoutingItemPopupVisible}
        onClose={() => setRoutingItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
    <WorkCentrePopup
        visible={WorkCentrePopupVisible}
        onClose={() => setWorkCentrePopupVisible(false)}
        onDataSelect={handleDataSelectWKC}
        formData={data}
      />
       <OperationSequencePopup
        visible={OperSequPopupVisible}
        onClose={() => setOperSequPopupVisible(false)}
        onDataSelect={handleDataSelectOpSq}
        formData={data}
      />
  </Dialog>
  );  
}
