import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import WorkOrderEntryPopup from "../Popups/WorkOrderEntryPopup";
import Costcenter from "views/common/CostCentreDrpDwn";
import ItemPopup from "../Popups/ItemPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function BaggingTransferDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BaggingTransferTableData,
  setBaggingTransferTableData,
  SelectedITNM,
  setSelectedITNM,
  bd_TO_LO,
  setbd_TO_LO,
  bd_TR_UM,
  setbd_TR_UM,
  bd_SC_UM,
  setbd_SC_UM,
  bd_FR_LO,
  setbd_FR_LO,
  bd_CO_CT,
  setbd_CO_CT,
  bd_DM_RS,
  setbd_DM_RS,
  bd_DM_LO,
  setbd_DM_LO,
  LocationDropdown,
  bd_OR_NO,

}) {
  const {
    bdUkNo,
   // bd_OR_NO,
    //bd_OR_TY,
    bd_OR_CO,
    bd_TR_DT,
    bd_RL_NO,
    bd_RL_TY,
    bd_RL_CO,
    bd_OR_DT,
    bd_DE_NO,
    bd_RQ_DT,
    bd_WO_QT,
    bd_CO_QT,
    bd_BG_QT,
    bd_BL_QT,
    bd_SC_QT,
    bd_WT_QT,
    bd_BG_NO,
    ch_CO_IT,
  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle, multitablestyle, multitableheader,Dialogstyle ,cancelstyle,savestyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    } else if (value) {
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // Dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const { fetchCostCenter } = Costcenter();

  const [bd_TO_LOOptions, setbd_TO_LOOptions] = useState([]);
  const [bd_TR_UMOptions, setbd_TR_UMOptions] = useState([]);
  const [bd_SC_UMOptions, setbd_SC_UMOptions] = useState([]);
  const [bd_FR_LOOptions, setbd_FR_LOOptions] = useState([]);
  const [bd_CO_CTOptions, setbd_CO_CTOptions] = useState([]);
  const [bd_DM_RSOptions, setbd_DM_RSOptions] = useState([]);
  const [bd_DM_LOOptions, setbd_DM_LOOptions] = useState([]);
  const [bd_OR_TY, setbd_OR_TY] = useState([]);

  
  // UM
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_TR_UM(value);
  };

  // UM
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_SC_UM(value);
  };

  // FrLocation
  const onchangeDropDownFormData3 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_FR_LO(value);
  };
  //Cost Center
  const onchangeDropDownFormData4 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_CO_CT(value);
  };

  const onchangeDropDownFormData5 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_DM_RS(value);
  };
  const onchangeDropDownFormData6 = (name, value) => {
    const index = name.split("_")[3];
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbd_DM_LO(value);
  };
  // Location
  // const LocationDropdown = (name, value) => {
  //   const index = name.split("_")[3];
  //   setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  //   setbd_TO_LO(value);
  // };

  useEffect(() => {
    Location();
    DropDownFormData1();
    DropDownFormData2();
    DropDownFormData3();
    DropDownFormData4();
    DropDownFormData5();
    DropDownFormData6();
   // LocationDropdown();


  }, []);

  const DropDownFormData1 = async () => {
    const Options = await fetchClassCodeDS("IN/UM");
    setbd_TR_UMOptions(Options);
  };

  const DropDownFormData2 = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setbd_SC_UMOptions(Options);
  };
  const DropDownFormData3 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setbd_FR_LOOptions(Options);
  };
  const DropDownFormData4 = async () => {
    const Options = await fetchCostCenter();
    setbd_CO_CTOptions(Options);
  };
  const DropDownFormData5 = async () => {
    const Options = await fetchClassCodeDS("JW/DM");
    setbd_DM_RSOptions(Options);
  };
  const DropDownFormData6 = async () => {
    const Options = await fetchClassCodeDS("LO/JB");
    setbd_DM_LOOptions(Options);
  };
  const Location = async () => {
    const Options = await fetchClassCodeDS("EX/LN");
    setbd_TO_LOOptions(Options);
  };


  const addRow = () => {
    setBaggingTransferTableData([...BaggingTransferTableData, {}]);
  };

  const deleteRow = () => {
    if (BaggingTransferTableData.length > 0) {
      const updatedData = [...BaggingTransferTableData];
      updatedData.pop();
      setBaggingTransferTableData(updatedData);
    }
  };
  const isUpdateMode = Boolean(bdUkNo);


  const onChangeOfOrderNo = async (wd_WO_NO ,wd_WO_TY) => {
  //  setSelectedAbAdNM(data.ab_AD_NM);
  //  setsh_SH_TO(data.abAdNo);

    const Url = `${domain}/api/BagDetails/GetDetailsByWoNumber`;
    const requestData={
      "bd_OR_NO":wd_WO_NO,
      "bd_OR_TY":wd_WO_TY
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const Resultdata = response.data;
      if (Resultdata) { 
        document.getElementById("bd_TR_DT").value = Resultdata.WorkOrderEntryResult.wd_TR_DT; 
        document.getElementById("bd_RL_NO").value = Resultdata.WorkOrderEntryResult.wd_RL_NO; 
        document.getElementById("bd_RL_TY").value = Resultdata.WorkOrderEntryResult.wd_RL_TY;
        document.getElementById("bd_RL_CO").value = Resultdata.WorkOrderEntryResult.wd_RL_CO; 
        //onchangeFormData("bd_OR_DT", Resultdata.WorkOrderEntryResult.wd_OR_DT);
        document.getElementById("bd_DE_NO").value = Resultdata.WorkOrderEntryResult.wd_IT_NM;//it_DES
       // onchangeFormData("bd_RQ_DT", Resultdata.WorkOrderEntryResult.wd_RQ_DT);
        document.getElementById("bd_WO_QT").value = Resultdata.WorkOrderEntryResult.wd_WO_QT;
        document.getElementById("bd_CO_QT").value = Resultdata.WorkOrderEntryResult.wd_CO_QT; 
       // LocationDropdown("bd_TO_LO", Resultdata.WorkOrderEntryResult.wd_LO_CN);
        document.getElementById("bd_BG_NO").value = Resultdata.Result.bd_BG_NO; 
      } else {
        console.error("Invalid API response:", Resultdata);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //setShipToPopupVisible(false);
    setShowSearchIcon(false);
    onChangeOfOrderNo1(wd_WO_NO ,wd_WO_TY)
  };
  const onChangeOfOrderNo1 = async (wd_WO_NO ,wd_WO_TY) => {
    //  setSelectedAbAdNM(data.ab_AD_NM);
    //  setsh_SH_TO(data.abAdNo);
  
      const Url = `${domain}/api/BagDetails/GetPartListDetailsByWoNumber`;
      const requestData={
        "bd_OR_NO":wd_WO_NO,
        "bd_OR_TY":wd_WO_TY
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });  
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const Resultdata = response.data.Result; 

        if (Resultdata && Array.isArray(Resultdata) ) {//
          const result = Resultdata[0];
      
            document.getElementById(`bd_IT_NM_${SelectedRowIndex}`).value = result.bd_IT_NM;
            document.getElementById(`item_DESC_${SelectedRowIndex}`).value = result.bd_IT_NM;
            //onchangeDropDownFormData1(`bd_TR_UM_${SelectedRowIndex}`, result.bd_TR_UM);
            document.getElementById(`bd_TR_UM_${SelectedRowIndex}`).value = result.bd_TR_UM;
            document.getElementById(`bd_SC_QT_${SelectedRowIndex}`).value = result.bd_SC_QT;
           // onchangeDropDownFormData2(`bd_SC_UM_${SelectedRowIndex}`, result.bd_SC_UM);
            document.getElementById(`bd_SC_UM_${SelectedRowIndex}`).value = result.bd_SC_UM;
            onchangeDropDownFormData3(`bd_FR_LO_${SelectedRowIndex}`, result.bd_FR_LO);
           // document.getElementById(`bd_FR_LO_${SelectedRowIndex}`).value = result.bd_FR_LO;
            document.getElementById(`bd_AV_QT_${SelectedRowIndex}`).value = result.bd_AV_QT;
            onchangeDropDownFormData4(`bd_CO_CT_${SelectedRowIndex}`, result.bd_CO_CT);
            //document.getElementById(`bd_CO_CT_${SelectedRowIndex}`).value = result.bd_CO_CT;
            onchangeDropDownFormData5(`bd_DM_RS_${SelectedRowIndex}`, result.bd_DM_RS);
            onchangeDropDownFormData6(`bd_LN_NO_${SelectedRowIndex}`, result.bd_LN_NO); 
           // document.getElementById(`bd_LN_NO_${SelectedRowIndex}`).value = result.bd_LN_NO;
          

          
        } else {
          console.error("Invalid API response:", Resultdata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      //setShipToPopupVisible(false);
      setShowSearchIcon(false);
    };

  const [ItemPopupVisible, setItemPopupVisible] = useState(false);
  const [ItemVisible, setItemVisible] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");

  const openItemPopup1 = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem = async (data) => {
    onchangeFormData("item_DESC", data.im_IT_DS, SelectedRowIndex);
    onchangeFormData("bd_IT_NM", data.im_IT_NM, SelectedRowIndex);
    setItemVisible(false);
    setShowSearchIcon(false);
  };

  const openItemPopup = () => {
    setItemPopupVisible(true);
  };
  const handleDataSelectItem = (Result) => {
    setSelectedITNM(Result.wd_WO_NO);
    document.getElementById("bd_OR_NO").value = Result.wd_WO_NO;
    setbd_OR_TY(Result.wd_WO_TY);
    document.getElementById("bd_OR_CO").value =Result.wd_WO_CO;
    setItemPopupVisible(false);
    setShowSearchIcon(false);
    onChangeOfOrderNo(Result.wd_WO_NO,Result.wd_WO_TY)
  };
    // DIALOG HEADER
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{bdUkNo ?"Revise Bagging Transfer" : "Add Bagging Transfer"}</h5>
        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
            tooltip={isUpdateMode ? "Transfer" : "Submit"}
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
      </div>
    );
  return (
    <Dialog id="dialog-style"
    visible={open}
    onHide={handleClose}
    style={Dialogstyle}
    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
    header={dialogHeader}
      modal
      className="p-fluid"
      
    >
      <div style={inboxstyle}>
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_OR_NO" className="font-bold">
            Order No<span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: 'flex' }}>
              <InputText
                id="im_IT_DS"
                name="im_IT_DS"
                onChange={(event) => onchangedata(event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedITNM}
              />
              {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: 'var(--primary-color)',
                    fontSize: '119%',
                    marginTop: '4%',
                    cursor: 'pointer',
                  }}
                  onClick={openItemPopup}
                ></i>
              )}
            </div>
            <div style={{ display: 'none' }}>
              <InputText
                id="bd_OR_NO"
                name="bd_OR_NO"
                defaultValue={bd_OR_NO}
                onChange={(event) => onchangedata(event)}
              />
            </div>
          </div>
          {/* <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_OR_NO" className="font-bold">
           
            </label>
            <InputText
              id="bd_OR_NO"
              name="bd_OR_NO"
              value={bd_OR_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div> */}
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_OR_TY" className="font-bold">
              Type
            </label>
            <InputText disabled
              id="bd_OR_TY"
              name="bd_OR_TY"
              value={bd_OR_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_OR_CO" className="font-bold">
              Company
            </label>
            <InputText disabled
              id="bd_OR_CO"
              name="bd_OR_CO"
              value={bd_OR_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_TR_DT" className="font-bold">
              Transaction Date
            </label>
            <Calendar
              id="bd_TR_DT"
              name="bd_TR_DT"
              value={moment(data.bd_TR_DT, "YYYYMMDD").isValid() ? moment(data.bd_TR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "bd_TR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_RL_NO" className="font-bold">
              Sales Order
            </label>
            <InputText disabled
              id="bd_RL_NO"
              name="bd_RL_NO"
              value={bd_RL_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_RL_TY" className="font-bold">
              Sales Type
            </label>
            <InputText disabled
              id="bd_RL_TY"
              name="bd_RL_TY"
              value={bd_RL_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_RL_CO" className="font-bold">
              Sales Company
            </label>
            <InputText disabled
              id="bd_RL_CO"
              name="bd_RL_CO"
              value={bd_RL_CO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_OR_DT" className="font-bold">
              Order Date
            </label>
            <Calendar
              id="bd_OR_DT"
              name="bd_OR_DT"
              value={moment(data.bd_OR_DT, "YYYYMMDD").isValid() ? moment(data.bd_OR_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "bd_OR_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_DE_NO" className="font-bold">
              Design No
            </label>
            <InputText disabled
              id="bd_DE_NO"
              name="bd_DE_NO"
              value={bd_DE_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_RQ_DT" className="font-bold">
              Requested  Date
            </label>
            <Calendar disabled
              id="bd_RQ_DT"
              name="bd_RQ_DT"
              value={moment(data.bd_RQ_DT, "YYYYMMDD").isValid() ? moment(data.bd_RQ_DT, "YYYYMMDD").toDate() : null}
              onChange={(e) => onchangedata({ target: { name: "bd_RQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_WO_QT" className="font-bold">
              Order Quantity
            </label>
            <InputText disabled
              id="bd_WO_QT"
              name="bd_WO_QT"
              value={bd_WO_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_CO_QT" className="font-bold">
              Completed Quantity
            </label>
            <InputText disabled
              id="bd_CO_QT"
              name="bd_CO_QT"
              value={bd_CO_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_BG_QT" className="font-bold">
              Bag Quantity
            </label>
            <InputText 
              id="bd_BG_QT"
              name="bd_BG_QT"
              value={bd_BG_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_BL_QT" className="font-bold">
              Balance Quantity
            </label>
            <InputText disabled
              id="bd_BL_QT"
              name="bd_BL_QT"
              value={bd_BL_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_SC_QT" className="font-bold">
              Stones
            </label>
            <InputText disabled
              id="bd_SC_QT"
              name="bd_SC_QT"
              value={bd_SC_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_WT_QT" className="font-bold">
              Weight
            </label>
            <InputText disabled
              id="bd_WT_QT"
              name="bd_WT_QT"
              value={bd_WT_QT}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_BG_NO" className="font-bold">
              Bag No
            </label>
            <InputText disabled
              id="bd_BG_NO"
              name="bd_BG_NO"
              value={bd_BG_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="bd_TO_LO" className="font-bold">
              Location
            </label>
            <Dropdown disabled
              name="bd_TO_LO"
              value={bd_TO_LO}
              options={bd_TO_LOOptions}
              onChange={(e) => LocationDropdown("bd_TO_LO", e.value)}
              placeholder="	Location"
            />
          </div>

        </div>
      </div>
      <div style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
            <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
          )}
          {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div style={multitablestyle}>
          <table className="custom-table" id="BaggingTransfertable">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK NO</th>
                <th>Line No</th>
                <th>Item Name<span style={{ color: 'red' }}>*</span></th>
                <th>Description</th>
                <th>Quantity</th>
                <th>UM</th>
                <th>Stones</th>
                <th>UM</th>
                <th>Location</th>
                <th>Available Qty</th>
                <th>CostCentre</th>
                <th>Carat</th>
                <th>Stones</th>
                <th>Reason</th>
                <th>To Location</th>
                <th>Unit Cost</th>
                <th>Amount</th>
                <th>Fore. Cost</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {BaggingTransferTableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`bdUkNo_${index}`}
                      name={`bdUkNo_${index}`}
                      value={row.bdUkNo}
                    />
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "90px" }}
                      id={`bd_LN_NO_${index}`}
                      name={`bd_LN_NO_${index}`}
                      value={row.bd_LN_NO}
                      readOnly
                    />
                  </td>
                  <td style={{ display: "flex" }}>
                    <InputText disabled
                      style={{ width: "110px" }}
                      id={`bd_IT_NM_${index}`}
                      name={`bd_IT_NM_${index}`}
                      onChange={(e) => onchangeFormData(e)}
                      onFocus={() => setShowSearchIcon(true)}
                      value={row.bd_IT_NM ? row.bd_IT_NM : row[`${"bd_IT_NM"}_${index}`]}
                    />
                    {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup1(index)}
                      ></i>
                    )}
                  </td>
                  <td>
                    <InputText disabled
                      style={{ width: "200px" }}
                      id={`item_DESC_${index}`}
                      name={`item_DESC_${index}`}
                      value={row.item_DESC ? row.item_DESC : row[`${"item_DESC"}_${index}`]}
                      readOnly
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`bd_OR_QT_${index}`}
                      name={`bd_OR_QT_${index}`}
                      defaultValue={row.bd_OR_QT}
                      onChange={(e) => onchangedata(e)}
                      
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_TR_UM_${index}`}
                      name={`bd_TR_UM_${index}`}
                      value={data[`bd_TR_UM_${index}`] ? data[`bd_TR_UM_${index}`] : row.bd_TR_UM}
                      options={bd_TR_UMOptions}
                      onChange={(e) => onchangeDropDownFormData1(`bd_TR_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`bd_SC_QT_${index}`}
                      name={`bd_SC_QT_${index}`}
                      defaultValue={row.bd_SC_QT}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_SC_UM_${index}`}
                      name={`bd_SC_UM_${index}`}
                      value={data[`bd_SC_UM_${index}`] ? data[`bd_SC_UM_${index}`] : row.bd_SC_UM}
                      options={bd_SC_UMOptions}
                      onChange={(e) => onchangeDropDownFormData2(`bd_SC_UM_${index}`, e.value)}
                      placeholder="UM"
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_FR_LO_${index}`}
                      name={`bd_FR_LO_${index}`}
                      value={data[`bd_FR_LO_${index}`] ? data[`bd_FR_LO_${index}`] : row.bd_FR_LO}
                      options={bd_FR_LOOptions}
                      onChange={(e) => onchangeDropDownFormData3(`bd_FR_LO_${index}`, e.value)}
                      placeholder="From Location"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "100px" }}
                      id={`bd_AV_QT_${index}`}
                      name={`bd_AV_QT_${index}`}
                      defaultValue={row.bd_AV_QT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_CO_CT_${index}`}
                      name={`bd_CO_CT_${index}`}
                      value={data[`bd_CO_CT_${index}`] ? data[`bd_CO_CT_${index}`] : row.bd_CO_CT}
                      options={bd_CO_CTOptions}
                      onChange={(e) => onchangeDropDownFormData4(`bd_CO_CT_${index}`, e.value)}
                      placeholder="Cost Center"
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_CT_TR_${index}`}
                      name={`bd_CT_TR_${index}`}
                      defaultValue={row.bd_CT_TR}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_ST_TR_${index}`}
                      name={`bd_ST_TR_${index}`}
                      defaultValue={row.bd_ST_TR}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_DM_RS_${index}`}
                      name={`bd_DM_RS_${index}`}
                      value={data[`bd_DM_RS_${index}`] ? data[`bd_DM_RS_${index}`] : row.bd_DM_RS}
                      options={bd_DM_RSOptions}
                      onChange={(e) => onchangeDropDownFormData5(`bd_DM_RS_${index}`, e.value)}
                      placeholder="Reason"
                      disabled
                    />
                  </td>
                  <td>
                    <Dropdown
                      id={`bd_DM_LO_${index}`}
                      name={`bd_DM_LO_${index}`}
                      value={data[`bd_DM_LO_${index}`] ? data[`bd_DM_LO_${index}`] : row.bd_DM_LO}
                      options={bd_DM_LOOptions}
                      onChange={(e) => onchangeDropDownFormData6(`bd_DM_LO_${index}`, e.value)}
                      placeholder=" TO Location"
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_UN_CT_${index}`}
                      name={`bd_UN_CT_${index}`}
                      defaultValue={row.bd_UN_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_EX_CT_${index}`}
                      name={`bd_EX_CT_${index}`}
                      defaultValue={row.bd_EX_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_FR_CT_${index}`}
                      name={`bd_FR_CT_${index}`}
                      defaultValue={row.bd_FR_CT}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>
                  <td >
                    <InputText style={{ width: "90px" }}
                      id={`bd_FR_AM_${index}`}
                      name={`bd_FR_AM_${index}`}
                      defaultValue={row.bd_FR_AM}
                      onChange={(e) => onchangedata(e)}
                      disabled
                    />
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* item */}
      <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />

      <WorkOrderEntryPopup
        visible={ItemPopupVisible}
        onClose={() => setItemPopupVisible(false)}
        onDataSelect={handleDataSelectItem}
        formData={data}
      />
    </Dialog>
  );
}
