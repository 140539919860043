import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import EquipmentPopup from "../Popups/EquipmentPopup";
import ClassCode from "views/common/ClassCode";
import '../../common/TabStyles.css';
export default function PreventiveMaintenanceDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  setFormData,
  pm_SR_TY,
  setpm_SR_TY,
  SelectedParent,
  setSelectedParent,
  onchangeFormData,
}) {
  const {
    pmUkNo,
    pm_FR_CY,
    pm_EQ_NM,
    pm_SR_DT,

  } = data;
  const { borderbox, inboxstyle, savestyle, cancelstyle,Dialogstyle } = ButtonStyles();
  // dropdowns
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [pm_SR_TYOptions, setpm_SR_TYOptions] = useState([]);

  // advance search
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [ParentPopupVisible, setParentPopupVisible] = useState(false);

  // Service type
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setpm_SR_TY(value);
  };


  useEffect(() => {
    fetchDropdownST();

  }, []);

  const fetchDropdownST = async () => {
    const Options = await fetchClassCodeDS("EM/TY");
    setpm_SR_TYOptions(Options);
  };

  const isUpdateMode = Boolean(pmUkNo);
  const productDialogFooter = (
    <React.Fragment>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-times"
        variant="outlined"
        onClick={handleClose}
        tooltip="Cancel"
      >
      </Button>
      <Button
        style={{ width: "5%" }}
        icon="pi pi-check"
        onClick={handleFormSubmit}
         tooltip="Save">
      </Button>
    </React.Fragment>
  );

  const openParentPopup = () => {
    setParentPopupVisible(true);
  };

  const handleSelectParent = (data) => {
    setSelectedParent(data.em_EQ_DS);
    onchangeFormData("pm_EQ_NM", data.em_EQ_NM);
    setParentPopupVisible(false);
    setShowSearchIcon(false);
  };

    // DIALOG HEADER
    const dialogHeader = (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <h5>{pmUkNo ? "Revise Preventive Maintenancee" : "Add Preventive Maintenance"}</h5>
        <div style={{ width: '50rem', display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            style={savestyle}
            icon="pi pi-check"
            onClick={handleFormSubmit}
             tooltip="Save"
          />
          <Button
            style={cancelstyle}
            icon="pi pi-times"
            onClick={handleClose}
            tooltip="Cancel"
          />
        </div>
      </div>
    );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_EQ_NM" className="font-bold">
                Equipment Name<span style={{ color: "red" }}> *</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="pm_EQ_NM"
                  name="pm_EQ_NM"
                  onChange={(e) => onchangedata(e)}
                  onFocus={() => setShowSearchIcon(true)}
                  value={SelectedParent}
                />
                {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openParentPopup}
                  ></i>
                )}
              </div>
              <InputText style={{ display: "none" }}
                id="pm_EQ_NM"
                name="pm_EQ_NM"
                value={pm_EQ_NM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_SR_TY" className="font-bold">
                Service Type
              </label>
              <Dropdown
                name="pm_SR_TY"
                value={pm_SR_TY}
                options={pm_SR_TYOptions}
                onChange={(e) => onchangeDropDownFormData1("pm_SR_TY", e.value)}
                placeholder="Service Type"
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_SR_DT" className="font-bold">
                Service Days
              </label>
              <InputText
                id="pm_SR_DT"
                name="pm_SR_DT"
                value={pm_SR_DT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_SC_DT" className="font-bold">
                Scheduled Date
              </label>
              <Calendar
                id="pm_SC_DT"
                name="pm_SC_DT"
                value={moment(data.pm_SC_DT, "YYYYMMDD").isValid() ? moment(data.pm_SC_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "pm_SC_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_FR_CY" className="font-bold">
                Frequency
              </label>
              <InputText
                id="pm_FR_CY"
                name="pm_FR_CY"
                value={pm_FR_CY}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_CO_DT" className="font-bold">
                Last Completed Date
              </label>
              <Calendar
                id="pm_CO_DT"
                name="pm_CO_DT"
                value={moment(data.pm_CO_DT, "YYYYMMDD").isValid() ? moment(data.pm_CO_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "pm_CO_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="pm_AQ_DT" className="font-bold">
                Acquired Date
              </label>
              <Calendar
                id="pm_AQ_DT"
                name="pm_AQ_DT"
                value={moment(data.pm_AQ_DT, "YYYYMMDD").isValid() ? moment(data.pm_AQ_DT, "YYYYMMDD").toDate() : null}
                onChange={(e) => onchangedata({ target: { name: "pm_AQ_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", }, })}
                dateFormat="dd/mm/yy"
              />
            </div>

          </div>
        </div>
      </div>
      {/* EquipmentName */}
      <EquipmentPopup
        visible={ParentPopupVisible}
        onClose={() => setParentPopupVisible(false)}
        onDataSelect={handleSelectParent}
        formData={data}
      />
    </Dialog>
  );
}
