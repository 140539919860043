import React, {  useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
export default function SymptomMasterDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  sm_CO_NO,
}) {
  const {
    smUkNo,
    sm_SY_CD,
    sm_SY_NM,
    sm_SY_DS,
  } = data;
  const {borderbox,} = ButtonStyles();  

const isUpdateMode = Boolean(smUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={smUkNo ? "Revise Symptom Master" : "Add Symptom Master"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div style={borderbox} >
            <div
                style={{
                  marginTop: "2%",
                  border: "1px solid #e8e8e8",
                  padding: "16px",
                  borderRadius: "15px",
                  backgroundColor: "aliceblue",
                  overflow: "auto",
                }}
              >
                <div className="formgrid grid">
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                    <label htmlFor="sm_CO_NO" className="font-bold" >
                     Company
                    </label>
                    <InputText  disabled
                      id="sm_CO_NO"
                      name="sm_CO_NO"
                      value={sm_CO_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sm_SY_CD" className="font-bold">
                     Symptom Code
                    </label>
                    <InputText 
                      id="sm_SY_CD"
                      name="sm_SY_CD"
                      value={sm_SY_CD}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sm_SY_NM" className="font-bold">
                     Symptom Name
                    </label>
                    <InputText 
                      id="sm_SY_NM"
                      name="sm_SY_NM"
                      value={sm_SY_NM}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
                    <label htmlFor="sm_SY_DS" className="font-bold">
                     Description
                    </label>
                    <InputText 
                      id="sm_SY_DS"
                      name="sm_SY_DS"
                      value={sm_SY_DS}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                  
                </div>
            </div>
      </div>
    </Dialog>
  );
}
