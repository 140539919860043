import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import AssetPopup from "views/admin/Popups/AssetPopup";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function AccountCostDialog({
  open,
  handleClose,
  data,
  onchangeFormData,
  handleResponse,
  SelectedAsset,
  setselectedAsset,
  SelectedDesc,
  setselectedDesc,
  Account,
  setAccount,
  selectedAccount,
  setSelectedAccount,
}) {
  const { fmUkNo } = data;
  const initialValue = {
    glUkNo: "",
    gl_DO_TY: "",
    gl_DO_CO: "",
    gl_DO_TY: "",
    acc_DESC: "",
    gl_GL_DT: "",
    gl_AC_AM: "",
  };
  const initialFilters = {
    gl_DO_NO: { value: "", operation: "" },
    gl_DO_TY: { value: "", operation: "" },
    gl_DO_CO: { value: "", operation: "" },
    acc_DESC: { value: "", operation: "" },
    gl_GL_DT: { value: "", operation: "" },
    gl_AC_AM: { value: "", operation: "" },
  };
  // buttonstyles
  const {borderbox,inboxstyle,formatDate,checkboxStyle,tableHeaderStyle,savestyle,cancelstyle,Dialogstyle,inputStyle,tableCellStyle } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(initialFilters);
  const [isRecordSelected, setIsRecordSelected] = useState(false);  
  const [selectedRows, setSelectedRows] = useState([]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const onchangedata = (e) => {
    const { name, value } = e.target;
      onchangeFormData(name, value);
  };

  const handleFormSubmit =async () => {
    const requestBodyAPIInput = {
      "journalEntryList":[{
        "gl_AT_NO":document.getElementById("gl_AT_NO").value,
        "glUkNo":`${selectedRows[0]}`,
      } ]
    }
    axios
    .post(`${domain}/api/AssetCost/FABACreateAccountCost`, requestBodyAPIInput, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
    handleResponse(resp.data);
    })
    .catch((error) => {
      console.error("Error adding data:", error);
    });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/JournalEntry/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  // Advance Search 
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
  };
  const handleDataSelect = (data) => {
    setSelectedAccount(data.am_AC_DS);
    setAccount(data.account);
    setAccountPopupVisible(false);
    updateFilters (data.account);
  };
  const updateFilters = (cus) => {
    let updatedFilters = { ...filters };
  if (cus !== null) {
    updatedFilters = {
      ...updatedFilters,
      gl_AC_NO: {
        value: cus,
        operation: "EQUAL",
      },
    };
  }
  setFilters(updatedFilters);
  };
  const [AssetVisible, setAssetVisible] = useState(false);
  const handleAsset =async (data) => {
    setselectedAsset(data.fm_AT_NO)
    setselectedDesc(data.fm_AT_D1)
    onchangeFormData("gl_AT_NO", data.fm_AT_NO);
    onchangeFormData("asset_DES", data.fm_AT_D1);
    setAssetVisible(false);
    setShowSearchIcon(false);
  };
  const openAssetPopup = (e) => {
    setAssetVisible(true);
  };
  const isUpdateMode = Boolean(fmUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{fmUkNo ? "Revise Asset Cost" : "Add  Asset Cost"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
        <div style={inboxstyle}>
          <div className="formgrid grid">
              <div className="field col-lg-5 col-md-6 col-12 pe-md-2" >
              <label htmlFor="Account" className="font-bold">
                Asset Cost Account  <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
                <InputText
                  id="Account"
                  name="Account"
                  onFocus={() => setShowSearchIcon(true)}
                  value={Account}
                />
                  {showSearchIcon && (
                <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "4%",
                    cursor: "pointer",
                  }}
                  onClick={openAccountPopup}
                ></i>
                )}
              </div>
              </div>
              <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
              <label htmlFor="fm_AT_D1" className="font-bold">
              Description
              </label>
                <InputText disabled
                  id="fm_AT_D1"
                  name="fm_AT_D1"
                  value={selectedAccount}
                />
              </div>
          </div>
          <div className="formgrid grid">
              <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                <label htmlFor="gl_AT_NO" className="font-bold">
                  Asset No <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{ display: "flex" }}>
                  <InputText 
                    id="gl_AT_NO"
                    name="gl_AT_NO"
                    onChange={(Event) => onchangedata(Event)}
                    onFocus={() => setShowSearchIcon(true)}
                    value={SelectedAsset}
                  />
                  {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "4%",
                      cursor: "pointer",
                    }}
                    onClick={openAssetPopup}
                  ></i>
                  )}
                </div>
              </div>
              <div className="field col-lg-5 col-md-6 col-12 pe-md-2">
                <label htmlFor="asset_DES" className="font-bold">
                    Description
                </label>
                <InputText disabled
                  id="asset_DES"
                  name="asset_DES"
                  value={SelectedDesc}
                />
              </div>
          </div>
        </div>
        <table style={{ borderCollapse: "collapse",  width: "100%", marginTop: "20px", }} >
            <thead>
              <tr>
                <td>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_NO"] ? filters["gl_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_NO",
                        e.target.value,
                        filters["gl_DO_NO"]
                          ? filters["gl_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_TY"] ? filters["gl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_TY",
                        e.target.value,
                        filters["gl_DO_TY"] ? filters["gl_DO_TY"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_DO_CO"] ? filters["gl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_DO_CO",
                        e.target.value,
                        filters["gl_DO_CO"] ? filters["gl_DO_CO"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_GL_DT"] ? filters["gl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_GL_DT",
                        e.target.value,
                        filters["gl_GL_DT"]
                          ? filters["gl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_AC_NO"] ? filters["gl_AC_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_AC_NO",
                        e.target.value,
                        filters["gl_AC_NO"]
                          ? filters["gl_AC_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["acc_DESC"] ? filters["acc_DESC"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "acc_DESC",
                        e.target.value,
                        filters["acc_DESC"]
                          ? filters["acc_DESC"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["gl_AC_AM"] ? filters["gl_AC_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "gl_AC_AM",
                        e.target.value,
                        filters["gl_AC_AM"]
                          ? filters["gl_AC_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Document No</th>
                <th style={tableHeaderStyle}>Type</th>
                <th style={tableHeaderStyle}>Company</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Account</th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.fmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.glUkNo}
                        checked={selectedRows.includes(rowData.glUkNo)}
                        onChange={() => handleRowSelect(rowData.glUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.gl_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.gl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.gl_DO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.gl_GL_DT)}</td>  
                    <td style={tableCellStyle}>{rowData.gl_AC_NO}</td>
                    <td style={tableCellStyle}>{rowData.acc_DESC}</td>
                    <td style={tableCellStyle}>{rowData.gl_AC_AM}</td>    
                  </tr>
                ))}
            </tbody>
        </table>
      </div>
        <AccountNoPopup
          visible={AccountPopupVisible}
          onClose={() => setAccountPopupVisible(false)}
          onDataSelect={handleDataSelect}
        />
        <AssetPopup
          visible={AssetVisible}
          onClose={() => setAssetVisible(false)}
          onDataSelect={handleAsset}
          formData={data}
        />
    </Dialog>
  );
}
