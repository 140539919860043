import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ItemPopup from "../Popups/ItemPopup";
import moment from "moment";
import axios from "axios";
import { TabView, TabPanel } from "primereact/tabview";
import ButtonStyles from "views/common/ButtonStyles";
import ClassCode from "views/common/ClassCode";
export default function PurchaseQuoteDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  setFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  onchangeDropDownFormData7,
  onchangeDropDownFormData8,
  onchangeOrderdate,
  SelectedemEMNO,
  setSelectedemEMNO,
  setph_SH_NO,
  ph_PY_TR,
  setph_PY_TR,
  selectedAbAdNN,
  selectedAbAdNNA,
  selectedAbAdNM,
  setSelectedAbAdNN,
  setSelectedAbAdNNA,
  setSelectedAbAdNM,
  ph_OR_TY,
  ph_OR_CO,
  ph_BS_CR,
  ph_SU_NO,
  ph_SH_NO,
  ph_CO_CT,
  ph_EX_RT,
  setpd_IT_NM,
  pd_ST_CD,
  setpd_SU_NO,
  setsup_NAME,
  setph_SU_NO,
  setph_EX_RT,
  addRow,
  deleteRow,
  cancelFlag,
  PurchaseQuotetable,
  PurchaseOrder,
  onchangeFieldSetFormData,
  OnchangeTableData,
  ph_RL_TY,
  setph_RL_TY,
  ph_RL_CO,
  setph_RL_CO,
  ph_HL_CD,
  pd_CO_CT,
  HoldCodeDropdown,
  availability_AMOUNT,
}) {
  const { 
    phUkNO,
    ph_OR_NO,
    ph_OR_DT,
    ph_RQ_DT,
    ph_TX_AP,
    ph_DS_AM,
    ph_DS_PR,
    ph_RL_NO,
    price_AMOUNT,
    fr_PRICE_AMOUNT,
    fr_DISCOUNT_AMOUNT,
    fr_NET_AMOUNT,
    fr_TAX_AMOUNT,
    fr_TOTAL,
    discount_AMOUNT,
    net_AMOUNT,
    tax_AMOUNT,
    total,
  } = data;

  const isProtected = pd_ST_CD > 200;
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
  const {footergridstyle,footerfieldstyle,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,
    plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,GenerateStyles} = ButtonStyles();
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [currentDate, setCurrentDate] = useState(moment().toDate());
  const [ph_CO_CTOptions, setph_CO_CTOptions] = useState([]);
  const [ph_TR_CROptions, setph_TR_CROptions] = useState([]);
  const [ph_TX_APOptions, setph_TX_APOptions] = useState([]);
  const [pd_TR_UMOptions, setpd_TR_UMOptions] = useState([]);
  const [pd_SC_UMOptions, setpd_SC_UMOptions] = useState([]);
  const [pd_CR_CDOptions, setpd_CR_CDOptions] = useState([]);
  const [ph_PY_TROptions, setph_PY_TROptions] = useState([]);
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ph_RL_TYOptions, setph_RL_TYOptions] = useState([]);
  const [ph_RL_COOptions, setph_RL_COOptions] = useState([]);
  const [ph_HL_CDOptions, setph_HL_CDOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const handleFormDataChange = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData4(name, value, index);
  };
  const handleFormDataChange1 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData5(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split('_')[1];
    onchangeDropDownFormData6(name, value, index);
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const url = `${domain}/api/ClassCode/All`;
  const urlcost = `${domain}/api/CostCenter/DropDown`;
  const finalUrl1 = `${urlcost}`;
//Currency
  const parameter2 = "FO/CR";
  const finalUrl2 = `${url}/${parameter2}`;
//Tax Applicable
  const parameter3 = "TX/AP";
  const finalUrl3 = `${url}/${parameter3}`;
//UM
  const parameter4 = "IN/UM";
  const finalUrl4 = `${url}/${parameter4}`;
//UM
  const parameter5 = "IN/UM";
  const finalUrl5 = `${url}/${parameter5}`;
//Currency
  const parameter6 = "FO/CR";
  const finalUrl6 = `${url}/${parameter6}`;
// traiaining reason
  const parameter7 = "ME/PL";
  const finalUrl7 = `${url}/${parameter7}`;

  const urlpayterm = `${domain}/api/PaymentTerm/GetAll`;
  const finalUrl8 = `${urlpayterm}`;

  const urlcom = `${domain}/api/CompanyMaster/GetActiveCompaniesByClient`;
  const finalUrl9 = `${urlcom}`;

  const TypeDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_RL_TY(value);
  };
  const CompanyDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setph_RL_CO(value);
  };
  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
    fetchDropdownOptions8();
    fetchDropdownOptions9();
    Type();
    HoldCode();
  }, []);
  const Type = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setph_RL_TYOptions(Options);
  };
  const HoldCode = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setph_HL_CDOptions(Options);
  };
  const fetchDropdownOptions9 = async () => {
    try {
      const response = await axios.get(finalUrl9, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.coCoNo,
        value: item.coCoNo,
      }));
      setph_RL_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cc_CC_NM,
        value: item.cc_CC_NM,
      }));
      setph_CO_CTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_TR_CROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setph_TX_APOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpd_TR_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpd_SC_UMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 6th dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setpd_CR_CDOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
// 8th dropdown
  const fetchDropdownOptions8 = async () => {
    try {
      const response = await axios.get(finalUrl8, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      const options = response.data.map((item) => ({
        label: item.pt_PY_DS,
        value: item.pt_PY_CO,
      }));
      setph_PY_TROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
const [SupplierhPopupVisible, setSupplierhPopupVisible] = useState(false);
const openSupplierhPopup = (e) => {
  setSupplierhPopupVisible(true);
  setSelectedRowIndex(e);
};
const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
const openSupplierPopup = (e) => {
  setSupplierPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ShipToPopupVisible, setShipToPopupVisible] = useState(false);
const [addressTypeship, setaddressTypeship] = useState("F");
const [addressTypeSUP, setaddressTypeSUP] = useState("S");
const openShipToPopup = (e) => {
  setShipToPopupVisible(true);
  setSelectedRowIndex(e);
};
const [ItemPopupVisible, setItemPopupVisible] = useState(false);
const openItemPopup = (e) => {
  setItemPopupVisible(true);
  setSelectedRowIndex(e);
};
// Supplier
const handleDataSelect = async (data) => {
  setSelectedemEMNO(data.ab_AD_NM);
  setph_SU_NO(data.abAdNo);

  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);

  setSelectedAbAdNNA(data.ab_AD_NM);
  const Url = `${domain}/api/SupplierMaster/GetById`;
  const requestData={
      "su_SU_NO": data.abAdNo
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataCuMt = response.data.Result;
      if (dataCuMt) {
        onchangeDropDownFormData8("ph_PY_TR", dataCuMt.su_PT_TM);
        setph_PY_TR(dataCuMt.su_PT_TM);
        onchangeDropDownFormData2("ph_TR_CR", dataCuMt.su_CR_CD);
        onchangeDropDownFormData3("ph_TX_AP", dataCuMt.su_TX_AP);

        onchangeFormData(`pd_SU_NO`, data.abAdNo);
        onchangeFormData(`ph_TR_CR`, dataCuMt.su_CR_CD);
        onchangeFormData(`sup_NAME`, data.ab_AD_NM);
        onchangeFormData(`pd_CR_CD`, dataCuMt.su_CR_CD);
        for(var i=0;i<PurchaseQuotetable.length;i++){
          onchangeFormData(`pd_SU_NO_${i}`, data.abAdNo);
          onchangeFormData(`sup_NAME_${i}`, data.ab_AD_NM);
          onchangeFormData(`pd_CR_CD_${i}`, dataCuMt.su_CR_CD);
        }
        const exData={
          "cr_TO_CR": document.getElementById("ph_BS_CR").value, //base curr
          "cr_FR_CR": dataCuMt.ph_TR_CR, //currency
          "cr_EF_DT": data.ph_OR_DT
        }
        exchangeRate (exData);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  setSupplierhPopupVisible(false);
};
exchangeRate(null);
function exchangeRate (exData){
  if(exData==null && ph_BS_CR!="" && data.ph_TR_CR!="" && data.ph_OR_DT!=""){
    exData={
      "cr_TO_CR": ph_BS_CR,
      "cr_FR_CR": data.ph_TR_CR,
      "cr_EF_DT": data.ph_OR_DT
    }
  }
  if(exData!=null){

    axios
    .post(`${domain}/api/ExChangeRate/Rate`, exData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    })
    .then((resp) => {
      const dataExRt = resp.data.Result;
      if (dataExRt) {
        document.getElementById("ph_EX_RT").value = dataExRt.cr_CO_MR;
        setph_EX_RT(dataExRt.cr_CO_MR);
      }

    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
  }
};
const handleDataSelect1 = async (data) => {
  setSelectedAbAdNM(data.ab_AD_NM);
  setph_SH_NO( data.abAdNo);
  const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
  const requestData={
    "abAdNo":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = response.data;
    if (data && data.data) {
      setSelectedAbAdNM(data.data.ab_AD_NM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setShipToPopupVisible(false);
};
const onchangeFormUnitPrice = async(e,index) => {
  onchangedata(e);
  onChangeOfPrice(index);
};
const  onChangeOfPrice= async(SelectedRowIndex) => {
  const UrlCalulate = `${domain}/api/RentalContract/GetAmountDetails`;
  const priceInput = {
    "exChangeRate": document.getElementById("ph_EX_RT").value,
    "rentalContracts":[{
            "unitPrice":document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value,
            "quantity":document.getElementById (`pd_OR_QT_${SelectedRowIndex}`).value,
            "taxPercentage":document.getElementById (`pd_TX_PE_${SelectedRowIndex}`).value,
            "mulDisPercentage":document.getElementById (`pd_DS_PR_${SelectedRowIndex}`).value,
            "foreUnitPrice":document.getElementById (`pd_FR_UP_${SelectedRowIndex}`).value,
       } ]
  }
  try {
    const response =await axios.post(UrlCalulate, priceInput, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataCalulate = response.data.Result;
    if (dataCalulate ) {
      onchangeFieldSetFormData(`pd_DS_AM_${SelectedRowIndex}`,dataCalulate.matDisamt);
      onchangeFieldSetFormData(`pd_BS_UP_${SelectedRowIndex}`,dataCalulate.unitPrice);
      onchangeFieldSetFormData(`net_AMOUNT_${SelectedRowIndex}`,dataCalulate.matnetAmtMul);
      onchangeFieldSetFormData(`pd_FR_DS_${SelectedRowIndex}`,dataCalulate.forematDisamt);
      onchangeFieldSetFormData(`pd_FR_AT_${SelectedRowIndex}`,dataCalulate.foreAmount);
      onchangeFieldSetFormData(`fr_NET_AMOUNT_${SelectedRowIndex}`,dataCalulate.forenetAmtMul);
      onchangeFieldSetFormData(`pd_OR_AT_${SelectedRowIndex}`,dataCalulate.matUnit);
      onchangeFieldSetFormData(`pd_TX_AM_${SelectedRowIndex}`,dataCalulate.matTaxamt);
      // COPY
      const purchaseQuotes = {
        ph_TR_CR: data.ph_TR_CR,
        ph_EX_RT: document.getElementById("ph_EX_RT").value,
        ph_BS_CR: document.getElementById("ph_BS_CR").value,
        ph_CO_CT: ph_CO_CT
      };
     
      const table = document.getElementById("PurchaseQuotetable");
      const totalRowCount = table.tBodies[0].rows.length;
      const purchaseOrders = [];
      for (let i = 0; i < totalRowCount; i++) {  
        const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const Details = {
          pdUkNo:  document.getElementById("pdUkNo_" + i).value,
          pd_LN_NO: document.getElementById("pd_LN_NO_" + i).value,
          pd_IT_NM: document.getElementById("pd_IT_NM_" + i).value,
          im_IT_DS: document.getElementById("im_IT_DS_" + i).value,
          pd_OR_QT: document.getElementById("pd_OR_QT_" + i).value,
          pd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          availability_AMOUNT :document.getElementById("availability_AMOUNT_" + i).value,
          pd_LS_PR: document.getElementById("pd_LS_PR_" + i).value,
          pd_SU_NO :document.getElementById("pd_SU_NO_" + i).value,
          sup_NAME :document.getElementById("sup_NAME_" + i).value,
          pd_CR_CD :table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
          pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
          pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
          pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
          pd_DS_AM :document.getElementById("pd_DS_AM_" + i).value,
          net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
          pd_FR_UP :document.getElementById("pd_FR_UP_" + i).value,
          pd_FR_AT :document.getElementById("pd_FR_AT_" + i).value,
          pd_FR_DS :document.getElementById("pd_FR_DS_" + i).value,
          fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
          pd_TX_AP : document.getElementById("pd_TX_AP_" + i).value,
          pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
          pd_TX_AM: document.getElementById("pd_TX_AM_" + i).value,
          pd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_SC_QT: document.getElementById("pd_SC_QT_" + i).value,
          pd_SC_UM: table.tBodies[0].rows[i].cells[25].children[0].children[1].children[0].value,
          pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
          pd_CN_DT: CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_ST_CD: document.getElementById("pd_ST_CD_" + i).value,
          pd_CO_CT: document.getElementById("pd_CO_CT_" + i).value,
        };
        purchaseOrders.push(Details);
      }
      const requestBodyNet = {
        purchaseOrders,
        purchaseQuotes
      };
      axios.post(`${domain}/api/PurchaseQuote/GetPopulateNetAmount`, requestBodyNet, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((respNet) => {
        onchangeFieldSetFormData("price_AMOUNT", respNet.data.header.price_AMOUNT);
        onchangeFieldSetFormData("fr_PRICE_AMOUNT", respNet.data.header.fr_PRICE_AMOUNT);
        onchangeFieldSetFormData("fr_DISCOUNT_AMOUNT", respNet.data.header.fr_DISCOUNT_AMOUNT);
        onchangeFieldSetFormData("fr_NET_AMOUNT", respNet.data.header.fr_NET_AMOUNT);
        onchangeFieldSetFormData("tax_AMOUNT", respNet.data.header.tax_AMOUNT);
        onchangeFieldSetFormData("discount_AMOUNT", respNet.data.header.discount_AMOUNT);
        onchangeFieldSetFormData("net_AMOUNT", respNet.data.header.net_AMOUNT);
        onchangeFieldSetFormData("fr_TAX_AMOUNT", respNet.data.header.fr_TAX_AMOUNT);
        onchangeFieldSetFormData("total", respNet.data.header.total);
        onchangeFieldSetFormData("fr_TOTAL", respNet.data.header.fr_TOTAL);
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });


    } else {
      console.error("Invalid API response:", dataCalulate);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}
const onchangeHeaderDisAmt = async(e) => {
  const purchaseQuotes = {
    ph_TR_CR: data.ph_TR_CR,
    ph_EX_RT: document.getElementById("ph_EX_RT").value,
    ph_BS_CR: document.getElementById("ph_BS_CR").value,
    ph_DS_AM: e.currentTarget.value,
  };
  const table = document.getElementById("PurchaseQuotetable");
  const totalRowCount = table.tBodies[0].rows.length;
  const purchaseOrders = [];
  for (let i = 0; i < totalRowCount; i++) {  
    const RQ_DT = document.getElementsByName("pd_RQ_DT_" + i)[0];
        const CN_DT = document.getElementsByName("pd_CN_DT_" + i)[0];
        const Details = {
          pdUkNo:  document.getElementById("pdUkNo_" + i).value,
          pd_LN_NO: document.getElementById("pd_LN_NO_" + i).value,
          pd_IT_NM: document.getElementById("pd_IT_NM_" + i).value,
          im_IT_DS: document.getElementById("im_IT_DS_" + i).value,
          pd_OR_QT: document.getElementById("pd_OR_QT_" + i).value,
          pd_TR_UM: table.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value,
          availability_AMOUNT :document.getElementById("availability_AMOUNT_" + i).value,
          pd_LS_PR: document.getElementById("pd_LS_PR_" + i).value,
          pd_SU_NO :document.getElementById("pd_SU_NO_" + i).value,
          sup_NAME :document.getElementById("sup_NAME_" + i).value,
          pd_CR_CD :table.tBodies[0].rows[i].cells[10].children[0].children[1].children[0].value,
          pd_BS_UP : document.getElementById("pd_BS_UP_" + i).value,
          pd_OR_AT : document.getElementById("pd_OR_AT_" + i).value,
          pd_DS_PR : document.getElementById("pd_DS_PR_" + i).value,
          pd_DS_AM :document.getElementById("pd_DS_AM_" + i).value,
          net_AMOUNT: document.getElementById("net_AMOUNT_" + i).value,
          pd_FR_UP :document.getElementById("pd_FR_UP_" + i).value,
          pd_FR_AT :document.getElementById("pd_FR_AT_" + i).value,
          pd_FR_DS :document.getElementById("pd_FR_DS_" + i).value,
          fr_NET_AMOUNT: document.getElementById("fr_NET_AMOUNT_" + i).value,
          pd_TX_AP : document.getElementById("pd_TX_AP_" + i).value,
          pd_TX_PE : document.getElementById("pd_TX_PE_" + i).value,
          pd_TX_AM: document.getElementById("pd_TX_AM_" + i).value,
          pd_RQ_DT: RQ_DT.value?moment(RQ_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_SC_QT: document.getElementById("pd_SC_QT_" + i).value,
          pd_SC_UM: table.tBodies[0].rows[i].cells[25].children[0].children[1].children[0].value,
          pd_CN_QT : document.getElementById("pd_CN_QT_" + i).value,
          pd_CN_DT: CN_DT.value?moment(CN_DT.value, "DD/MM/YYYY").format("YYYYMMDD"):null,
          pd_ST_CD: document.getElementById("pd_ST_CD_" + i).value,
          pd_CO_CT: document.getElementById("pd_CO_CT_" + i).value,
        };
    purchaseOrders.push(Details);
  }
  const requestBodyNet = {
    purchaseQuotes,
        purchaseOrders,
  };
  axios.post(`${domain}/api/PurchaseQuote/GetPopulateNetAmount`, requestBodyNet, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((respNet) => {
    onchangeFieldSetFormData("price_AMOUNT", respNet.data.header.price_AMOUNT);
    onchangeFieldSetFormData("fr_PRICE_AMOUNT", respNet.data.header.fr_PRICE_AMOUNT);
    onchangeFieldSetFormData("fr_DISCOUNT_AMOUNT", respNet.data.header.fr_DISCOUNT_AMOUNT);
    onchangeFieldSetFormData("fr_NET_AMOUNT", respNet.data.header.fr_NET_AMOUNT);
    onchangeFieldSetFormData("tax_AMOUNT", respNet.data.header.tax_AMOUNT);
    onchangeFieldSetFormData("discount_AMOUNT", respNet.data.header.discount_AMOUNT);
    onchangeFieldSetFormData("net_AMOUNT", respNet.data.header.net_AMOUNT);
    onchangeFieldSetFormData("fr_TAX_AMOUNT", respNet.data.header.fr_TAX_AMOUNT);
    onchangeFieldSetFormData("total", respNet.data.header.total);
    onchangeFieldSetFormData("fr_TOTAL", respNet.data.header.fr_TOTAL);
  })
  .catch((error) => {
    console.error("Error updating data:", error);
  });

  onchangedata(e);
};
const onchangeHeaderDisPer = async(e) => {
  const disPer = e.currentTarget.value;
  const table = document.getElementById("PurchaseQuotetable");
  const totalRowCount = table.tBodies[0].rows.length;

  if(disPer){
    for (let i = 0; i < totalRowCount; i++) { 
      const disPerMul = document.getElementById ("pd_DS_PR_" + i).value;
      if(!disPerMul || disPerMul === '0'){
        document.getElementById ("pd_DS_PR_" + i).value = disPer;
        onChangeOfPrice(i);
      }
    }
  }
  onchangedata(e);
}; 
const onchangebspr = async(SelectedRowIndex) => {
	const table = document.getElementById("PurchaseQuotetable");
    const UrlPrice = `${domain}/api/BasePrice/GetUnitPriceForSales`;
      const priceInput = {
        "bp_CO_CT" : ph_CO_CT,
        "bp_IT_NM" : document.getElementById (`pd_IT_NM_${SelectedRowIndex}`).value,
        "bp_FR_DT" : ph_OR_DT,
        "bp_TO_DT" : ph_OR_DT,
        "bp_CU_NO" : document.getElementById("ph_SU_NO").value,
        "bp_PR_UM" : table.tBodies[0].rows[SelectedRowIndex].cells[5].children[0].children[1].children[0].value,        
        "bp_CR_CD" : data.ph_TR_CR,
      }
        try {
          const response =await axios.post(UrlPrice, priceInput, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          });
      
          if (response.status !== 200) {
            throw new Error(`API request failed with status: ${response.status}`);
          }
          const dataItMt = response.data.price;
          if (dataItMt ) {
            document.getElementById (`pd_BS_UP_${SelectedRowIndex}`).value =  dataItMt.bp_UN_PR;
            onChangeOfPrice(SelectedRowIndex);
          } else {
            console.error("Invalid API response:", data);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
};
const handleDataSelect2 = async(Result) => {
  setSelectedAbAdNN(Result.im_IT_DS);
  setpd_IT_NM(Result.im_IT_NM);
  OnchangeTableData("pd_IT_NM", Result.im_IT_NM, SelectedRowIndex);
  OnchangeTableData("im_IT_DS", Result.im_IT_DS, SelectedRowIndex);
  const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
  const requestData={
      "im_IT_NM":Result.im_IT_NM
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });

    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const dataItMt = response.data.Result;
    if (dataItMt ) {
      onchangeFieldSetFormData(`pd_TX_AP_${SelectedRowIndex}`, dataItMt.im_TX_AP);
      onchangeFieldSetFormData(`pd_TX_PE_${SelectedRowIndex}`, dataItMt.im_VA_TX);
      onchangeDropDownFormData5(`pd_SC_UM_${SelectedRowIndex}`, dataItMt.im_SC_UM);
      onchangeDropDownFormData4(`pd_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      onchangebspr(SelectedRowIndex);
    } else {
      console.error("Invalid API response:", data);
    }
    await Price(Result.im_IT_NM);
    await LastPrice(Result.im_IT_NM);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setItemPopupVisible(false);
  setShowSearchIcon(false);
};
const LastPrice =async (im_IT_NM) => {
  const requestBodyNet = {
    "is_IT_NM": im_IT_NM,
    "is_CO_CT": pd_CO_CT,
  };
  axios.post(`${domain}/api/StockAvailability/GetAvailableStock`, requestBodyNet, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  })
  .then((respNet) => {
    onchangeFieldSetFormData(`pd_LS_PR_${SelectedRowIndex}`,respNet.data.Result);
  })
  .catch((error) => {
    console.error("Error updating data:", error);
  });
};
const Price =async (im_IT_NM) => {
  const Url = `${domain}/api/PurchaseReceipt/GetLastPrice`;
  const requestData={
    "pr_IT_NM": im_IT_NM,
    "pr_CO_CT": pd_CO_CT,
}
try {
  const response =await axios.post(Url, requestData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
    },
  });

  if (response.status !== 200) {
    throw new Error(`API request failed with status: ${response.status}`);
  }
  const dataItMt = response.data.result;
  if (dataItMt ) {
    onchangeFieldSetFormData(`availability_AMOUNT_${SelectedRowIndex}`,dataItMt.pr_UT_CT);
  } else {
    console.error("Invalid API response:", data);
  }
} catch (error) {
  console.error("Error fetching data:", error);
}
 
};
const handleDataSelect3 =async (data) => {
  setSelectedAbAdNNA(data.ab_AD_NM);
  onchangeFormData(`pd_SU_NO_${SelectedRowIndex}`, data.abAdNo);
  setpd_SU_NO( data.abAdNo);
  onchangeFormData(`sup_NAME_${SelectedRowIndex}`, data.ab_AD_NM);
  setsup_NAME(data.abAdNo);
  const Url = `${domain}/api/AddressBook/onChangeOfAddressNo`;
  const requestData={
    "abAdNo":data.abAdNo
  }
  try {
    const response =await axios.post(Url, requestData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
      },
    });  
    if (response.status !== 200) {
      throw new Error(`API request failed with status: ${response.status}`);
    }
    const data = response.data;
    if (data && data.data) {
      setSelectedAbAdNNA(data.data.ab_AD_NM);
    } else {
      console.error("Invalid API response:", data);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  setSupplierPopupVisible(false);
  setShowSearchIcon(false);
};
const onchangeOfHeaderTaxApl = async(name,value) => {
  const txAP = value;
  const table = document.getElementById("PurchaseQuotetable");
  const totalRowCount = table.tBodies[0].rows.length;

  if(txAP.toUpperCase() === 'Y'){
    for (let i = 0; i < totalRowCount; i++) { 
      document.getElementById ("pd_TX_AP_" + i).value = 'y';
      const item = document.getElementById ("pd_IT_NM_" + i).value;
      const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
      const requestData={
          "im_IT_NM": item
      }
      try {
        const response =await axios.post(Url, requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        });
    
        if (response.status !== 200) {
          throw new Error(`API request failed with status: ${response.status}`);
        }
        const dataItMt = response.data.Result;
        if (dataItMt ) {
          document.getElementById ("pd_TX_PE_" + i).value =  dataItMt.im_VA_TX;
          onChangeOfPrice(i);
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  } else {    
    for (let i = 0; i < totalRowCount; i++) { 
      document.getElementById ("pd_TX_AP_" + i).value = 'n';
      document.getElementById ("pd_TX_PE_" + i).value = '0';
      onChangeOfPrice(i);
    }
  }
  onchangeDropDownFormData3("pd_TX_AP", value);
};
const onchangeOfTaxAplMul = async(e,index) => {
  const txAP = e.currentTarget.value;
  const item = document.getElementById (`pd_IT_NM_${index}`).value;
  if(txAP.toUpperCase() === 'Y'){
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM": item
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        onchangeFieldSetFormData(`pd_TX_PE_${index}`, dataItMt.im_VA_TX);
        onChangeOfPrice(index);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    document.getElementById (`pd_TX_PE_${index}`).value = '0';
    onChangeOfPrice(index);
  }
};
const [selectedRqDate, setSelectedRqDate] = useState(moment().toDate());
const handleRQDateChange = (e) => {
  const newDate = e.value;
  setSelectedRqDate(newDate);
  setCurrentDate(newDate); 
  onchangedata({ target: { name: "ph_RQ_DT", value: moment(newDate).format("YYYYMMDD") } });
};
  const isUpdateMode = Boolean(phUkNO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{phUkNO ? "Revise Purchase Quote" : "Add Purchase Quote"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      {activeTab === 0 && (
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
      )}
      {activeTab === 0 && (
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      )}
       {isUpdateMode && (
          <div style={{ display: 'flex',width:"10%" }}>
            {activeTab === 0 && (
            <Button
              style={GenerateStyles}
              icon="pi pi-file"
              variant="outlined"
              onClick={PurchaseOrder}
              tooltip="Generate PO"
            />
          )}
          </div>
         )}
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    > 
      <div style={TabBoxStyle} >
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Quote Details">
              {/* tab1 general */}
              <div style={inboxstyle}>
              <div className="formgrid grid">
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
              <label htmlFor="phUkNO" className="font-bold">
              UKNO
              </label>
              <InputText
              id="phUkNO"
              name="phUkNO"
              value={phUkNO}
              onChange={(e) => onchangedata(e)}
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_NO" className="font-bold">
            Order No
            </label>
            <InputText disabled
            id="ph_OR_NO"
            name="ph_OR_NO"
            value={ph_OR_NO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_TY" className="font-bold">
            Type
            </label>
            <InputText disabled
            id="ph_OR_TY"
            name="ph_OR_TY"
            value={ph_OR_TY}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_CO" className="font-bold">
            Company
            </label>
            <InputText disabled
            id="ph_OR_CO"
            name="ph_OR_CO"
            value={ph_OR_CO}
            onChange={(e) => onchangedata(e)}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_OR_DT" className="font-bold">
            Order Date
            </label>
            <Calendar
            id="ph_OR_DT"
            name="ph_OR_DT"
            value={moment(ph_OR_DT, "YYYYMMDD").toDate()}
            onChange={(e) =>
            onchangeOrderdate({
            target: {
            name: "ph_OR_DT",
            value: moment(e.value).format("YYYYMMDD"),
            },
            })
            }
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_RQ_DT" className="font-bold">
            Requested Date
            </label>
            <Calendar
            id="ph_RQ_DT"
            name="ph_RQ_DT"
            value={moment(ph_RQ_DT, "YYYYMMDD").toDate()}
            onChange={handleRQDateChange}
            dateFormat="dd/mm/yy"
            disabled={cancelFlag || isProtected}
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_CO_CT" className="font-bold">
            Cost Centre
            </label>
            <Dropdown disabled
            id="ph_CO_CT"
            name="ph_CO_CT"
            value={ph_CO_CT}
            options={ph_CO_CTOptions}
            onChange={(e) => onchangeDropDownFormData1("ph_CO_CT", e.value)}
            placeholder="Select Cost Centre"            
            />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ph_SU_NO" className="font-bold">
            Supplier  <span style={{ color: 'red' }}>*</span>
            </label>
            <div style={{ display: "flex" }}>
              <InputText
              id="sup_NAME"
              name="sup_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={SelectedemEMNO}
              disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              )}
              </div>
              <div style={{ display: "none" }}>
              <InputText
              id="ph_SU_NO"
              name="ph_SU_NO"
              value={ph_SU_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openSupplierhPopup}
              ></i>
              </div>
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PY_TR" className="font-bold">
                Pay Term
              </label>
              <Dropdown
                id="ph_PY_TR"
                name="ph_PY_TR"
                value={ph_PY_TR}
                options={ph_PY_TROptions}
                onChange={(e) => onchangeDropDownFormData8("ph_PY_TR", e.value)}
                placeholder="Select Pay Term"
                disabled={cancelFlag || isProtected}
              />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_SH_NO" className="font-bold">
              Ship To <span style={{ color: 'red' }}>*</span>
              </label>
              <div style={{ display: "flex" }}>
              <InputText
              id="shipto_NAME"
              name="shipto_NAME"
              onChange={(Event) => onchangedata(Event)}
              onFocus={() => setShowSearchIcon(true)}
              value={selectedAbAdNM}
              disabled={cancelFlag || isProtected}
              />
              {showSearchIcon && (
              <i
              className="pi pi-search"
              style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "4%",
              cursor: "pointer",
              }}
              onClick={openShipToPopup}
              ></i>
              )}
              </div>
              <div style={{display:"none"}}>
              <InputText
              id="ph_SH_NO"
              name="ph_SH_NO"
              value={ph_SH_NO}
              onChange={(Event) => onchangedata(Event)}
              />
              </div>
              </div>

              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TX_AP" className="font-bold">
              Tax Applicable
              </label>
              <Dropdown
              id="ph_TX_AP"
              name="ph_TX_AP"
              value={ph_TX_AP}
              options={[
              { label: "Yes", value: "y" },
              { label: "No", value: "n" },
              ]}
              onChange={(e) => onchangeOfHeaderTaxApl("ph_TX_AP", e.value)}
              placeholder="Select Tax Applicable"
              disabled={cancelFlag || isProtected}
              />
              </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_DS_PR" className="font-bold">
                Discount %
              </label>
              <InputText
                id="ph_DS_PR"
                name="ph_DS_PR"
                value={ph_DS_PR}
                onChange={(e) => onchangeHeaderDisPer(e)}
                disabled={cancelFlag || isProtected}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_DS_AM" className="font-bold">
                Dis.Amount
              </label>
              <InputText
                id="ph_DS_AM"
                name="ph_DS_AM"
                value={ph_DS_AM}
                onChange={(e) => onchangeHeaderDisAmt(e)}
                disabled={cancelFlag || isProtected}
              />
            </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_TR_CR" className="font-bold">
              Currency
              </label>
              <Dropdown
              id="ph_TR_CR"
              name="ph_TR_CR"
              value={data.ph_TR_CR}
              options={ph_TR_CROptions}
              onChange={(e) => onchangeDropDownFormData2("ph_TR_CR", e.value)}
              placeholder="Select Currency"
              disabled={cancelFlag || isProtected}
              />
              </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_EX_RT" className="font-bold">
              Exchange Rate
              </label>
              <InputText disabled
              id="ph_EX_RT"
              name="ph_EX_RT"
              value={ph_EX_RT}
              onChange={(e) => onchangedata(e)}
              />
            </div>

            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" >
            <label htmlFor="ph_BS_CR" className="font-bold">
            Base Currency
            </label>
            <InputText disabled
            id="ph_BS_CR"
            name="ph_BS_CR"
            value={ph_BS_CR}
            onChange={(e) => onchangedata(e)}
            />
            </div>
          
          </div>
              </div>
                <div  style={tablestyle} >
                  <div className="table-buttons" style={tablebutton} >
                    {(
                    <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
                    )}
                    {(
                    <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
                    )}
                  </div>
                  <div  style={multitablestyle}>
                  <table className="custom-table" id="PurchaseQuotetable" style={{overflow:"auto"}}>
                <thead>
                  <tr>
                    <th style={{ display: "none" }} >UK_NO</th>
                    <th>Line No</th>
                    <th>Item Number <span style={{ color: 'red' }}>*</span> </th>
                    <th>Description</th>
                    <th> Order   Quantity</th>
                    <th>UM</th>
                    <th>Available Quantity</th>
                    <th>Last in Price</th>
                    <th style={{ display: "none" }}>Supplier</th>
                    <th>Supplier <span style={{ color: 'red', }}>*</span></th>
                    <th>Currency</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                    <th>Discount %</th>
                    <th>Discount Amount</th>
                    <th>Net Amount</th>
                    <th>Fore.Price</th>
                    <th>Fore.Amount</th>
                    <th>Fore. Discount Amt</th>
                    <th>Fore. Net Amount</th>
                    <th>Tax Applicable</th>
                    <th>Tax%</th>
                    <th>Tax</th>
                    <th>Req.Date</th>
                    <th>Sec. Quantity</th>
                    <th>UM</th>
                    <th>Can. Quantity</th>
                    <th>Can. Date</th>
                    <th>Status</th>
                    <th>Cost Centre</th>
                  </tr>
                </thead>
                <tbody>
                {PurchaseQuotetable.map((row, index) => (
                        <tr key={index}>
                      <td style={{ display: "none" }} >
                      <InputText
                        id={`pdUkNo_${index}`}
                        name={`pdUkNo_${index}`}
                        defaultValue={row.pdUkNo}
                      />
                    </td>
                    <td>
                    <InputText disabled
                    style={{ width: "7rem" }}
                    id={`pd_LN_NO_${index}`}
                    name={`pd_LN_NO_${index}`}
                    value={index + 1}
                    readOnly
                  />
                    </td>
                    <td >
                      <div style={{ display: "flex" }}> 
                      <InputText
                        style={{ width: "10rem" }}
                        id={`pd_IT_NM_${index}`}
                        name={`pd_IT_NM_${index}`}
                        // defaultValue={pd_IT_NM || row.pd_IT_NM}
                        onChange={(e) =>  OnchangeTableData('pd_IT_NM',e.value,index)}
                        onFocus={() => setShowSearchIcon(true)}
                        value={row.pd_IT_NM ? row.pd_IT_NM: row[`${"pd_IT_NM"}_${index}`]}
                        disabled={cancelFlag || isProtected}
                      />
                      {showSearchIcon && (
                      <i
                        className="pi pi-search"
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "119%",
                          marginTop: "15%",
                          cursor: "pointer",
                        }}
                        onClick={(e) => openItemPopup(index)}
                      ></i>
                      )}
                      </div>
                    </td>
                    <td>
                    <InputText disabled
                        style={{ width: "15rem" }}
                        id={`im_IT_DS_${index}`}
                        name={`im_IT_DS_${index}`}
                        value={row.im_IT_DS ? row.im_IT_DS : row[`${"im_IT_DS"}_${index}`]}
                        onChange={(e) =>  OnchangeTableData('im_IT_DS',e.value,index)}
                      />
                    </td>
                    <td>
                    <InputText style={{width:"10rem"}}
                      id={`pd_OR_QT_${index}`}
                      name={`pd_OR_QT_${index}`}
                      defaultValue={data[`pd_OR_QT_${index}`] ? data[`pd_OR_QT_${index}`] : row.pd_OR_QT}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <Dropdown style={{width:'7rem'}}
                      id={`pd_TR_UM_${index}`}
                      name={`pd_TR_UM_${index}`}
                      value={ data[`pd_TR_UM_${index}`] ? data[`pd_TR_UM_${index}`]: row.pd_TR_UM}
                      options={pd_TR_UMOptions}
                      onChange={(e) =>handleFormDataChange(`pd_TR_UM_${index}`, e.value)}
                      placeholder="Select UM"
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td> 
                    <InputText disabled style={{width:"10rem"}}
                      id={`availability_AMOUNT_${index}`}
                      name={`availability_AMOUNT_${index}`}
                      value={data[`availability_AMOUNT_${index}`] ? data[`availability_AMOUNT_${index}`] : row.availability_AMOUNT}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td> 
                    <InputText disabled style={{width:"10rem"}}
                      id={`pd_LS_PR_${index}`}
                      name={`pd_LS_PR_${index}`}
                      value={data[`pd_LS_PR_${index}`] ? data[`pd_LS_PR_${index}`] : row.pd_LS_PR}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td style={{ display: "none" }}> 
                    <InputText
                    id={`pd_SU_NO_${index}`}
                    name={`pd_SU_NO_${index}`}
                    defaultValue={row.pd_SU_NO ? row.pd_SU_NO: data[`${"pd_SU_NO"}_${index}`]}
                    onChange={(e) =>  OnchangeTableData('pd_SU_NO',e.value,index)}
                    disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <div style={{ display: "flex", width:"15rem"}}>
                    <InputText
                    id={`sup_NAME_${index}`}
                    name={`sup_NAME_${index}`}
                    onChange={(e) =>  OnchangeTableData('sup_NAME',e.value,index)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={row.sup_NAME ? row.sup_NAME : data[`${"sup_NAME"}_${index}`]}
                    disabled={cancelFlag || isProtected}
                    />
                    {showSearchIcon && (
                    <i
                    className="pi pi-search"
                    style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                    }}
                    onClick={(e) => openSupplierPopup(index)}
                    ></i>
                    )}
                    </div>
                    </td>
                    <td>
                    <Dropdown style={{width:"7rem"}}
                      id={`pd_CR_CD_${index}`}
                      name={`pd_CR_CD_${index}`}
                      value={data[`pd_CR_CD_${index}`] ? data[`pd_CR_CD_${index}`]:row.pd_CR_CD }
                      options={pd_CR_CDOptions}
                      onChange={(e) => handleFormDataChange2(`pd_CR_CD_${index}`, e.value)}
                      placeholder="Select Currency"
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <InputText   style={{width:"10rem"}}
                      id={`pd_BS_UP_${index}`}
                      name={`pd_BS_UP_${index}`}
                      value={data[`pd_BS_UP_${index}`] ? data[`pd_BS_UP_${index}`] : row.pd_BS_UP}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                      disabled={cancelFlag || isProtected}
                    />
                    </td>
                    <td>
                    <InputText  disabled style={{width:"10rem"}}
                      id={`pd_OR_AT_${index}`}
                      name={`pd_OR_AT_${index}`}
                      value={data[`pd_OR_AT_${index}`] ? data[`pd_OR_AT_${index}`] : row.pd_OR_AT}
                      onChange={(e) => onchangedata(e)}
                    />
                    </td>
                    <td>
                      <InputText
                      style={{ width: "10rem" }}
                      id={`pd_DS_PR_${index}`}
                      name={`pd_DS_PR_${index}`}
                      value={data[`pd_DS_PR_${index}`] ? data[`pd_DS_PR_${index}`] : row.pd_DS_PR}
                      onChange={(e) => onchangeFormUnitPrice(e,index)}
                      disabled={cancelFlag || isProtected}
                      />
                      </td>
                      <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`pd_DS_AM_${index}`}
                        name={`pd_DS_AM_${index}`}
                        value={data[`pd_DS_AM_${index}`] ? data[`pd_DS_AM_${index}`] : row.pd_DS_AM}
                      />
                      </td>
                      <td>
                      <InputText disabled
                        style={{ width: "10rem" }}
                        id={`net_AMOUNT_${index}`}
                        name={`net_AMOUNT_${index}`}
                        value={data[`net_AMOUNT_${index}`] ? data[`net_AMOUNT_${index}`] : row.net_AMOUNT}
                      />
                      </td>             
                        <td>
                        <InputText style={{width:"10rem"}}
                          id={`pd_FR_UP_${index}`}
                          name={`pd_FR_UP_${index}`}
                          value={data[`pd_FR_UP_${index}`] ? data[`pd_FR_UP_${index}`] : row.pd_FR_UP}
                          onChange={(e) => onchangeFormUnitPrice(e,index)}
                          disabled={cancelFlag || isProtected}
                        />
                        </td>

                        <td>
                        <InputText disabled style={{width:"10rem"}}
                          id={`pd_FR_AT_${index}`}
                          name={`pd_FR_AT_${index}`}
                          value={data[`pd_FR_AT_${index}`] ? data[`pd_FR_AT_${index}`] : row.pd_FR_AT}
                          onChange={(e) => onchangedata(e)}
                        />
                        </td>
                        <td>
                        <InputText disabled
                          style={{ width: "7rem" }}
                          id={`pd_FR_DS_${index}`}
                          name={`pd_FR_DS_${index}`}
                          value={data[`pd_FR_DS_${index}`] ? data[`pd_FR_DS_${index}`] : row.pd_FR_DS}
                          // onChange={(e) => onchangedata(e)}
                        />
                      </td>
                        <td>
                          <InputText disabled
                            style={{ width: "10rem" }}
                            id={`fr_NET_AMOUNT_${index}`}
                            name={`fr_NET_AMOUNT_${index}`}
                            value={data[`fr_NET_AMOUNT_${index}`] ? data[`fr_NET_AMOUNT_${index}`] : row.fr_NET_AMOUNT}
                          />
                        </td>
                          <td>
                          <InputText  style={{width:"10rem"}}
                              id={`pd_TX_AP_${index}`}
                              name={`pd_TX_AP_${index}`}
                              value={data[`pd_TX_AP_${index}`] ? data[`pd_TX_AP_${index}`] : row.pd_TX_AP}
                              onChange={(e) => onchangeOfTaxAplMul(e,index)}
                              disabled={cancelFlag || isProtected}
                          />
                          </td>

                            <td>
                            <InputText disabled style={{width:"10rem"}}
                              id={`pd_TX_PE_${index}`}
                              name={`pd_TX_PE_${index}`}
                              value={data[`pd_TX_PE_${index}`] ? data[`pd_TX_PE_${index}`] : row.pd_TX_PE}
                              onChange={(e) => onchangedata(e)}
                            />
                            </td>
                            <td>
                            <InputText disabled style={{width:"10rem"}}
                              id={`pd_TX_AM_${index}`}
                              name={`pd_TX_AM_${index}`}
                              value={data[`pd_TX_AM_${index}`] ? data[`pd_TX_AM_${index}`] : row.pd_TX_AM}
                              onChange={(e) => onchangedata(e)}
                            />
                            </td>
                            <td>
                            <Calendar 
                              style={{width:"10rem"}}
                              id={`pd_RQ_DT_${index}`}
                              name={`pd_RQ_DT_${index}`}
                              value={currentDate}
                              onChange={(e) => {setCurrentDate(e.value);
                              onDateChange("pd_RQ_DT", index, e.value);
                              }}
                              dateFormat="dd/mm/yy"
                              disabled={cancelFlag || isProtected}
                            />
                            </td>
                            <td>
                            <InputText style={{width:"10rem"}}
                              id={`pd_SC_QT_${index}`}
                              name={`pd_SC_QT_${index}`}
                              value={data[`pd_SC_QT_${index}`] ? data[`pd_SC_QT_${index}`] : row.pd_SC_QT}
                              onChange={(e) => onchangedata(e)}
                              disabled={cancelFlag || isProtected}
                            />
                            </td>
                            <td>
                            <Dropdown style={{width:"7rem"}}
                              id={`pd_SC_UM_${index}`}
                              name={`pd_SC_UM_${index}`}
                              value={ data[`pd_SC_UM_${index}`] ? data[`pd_SC_UM_${index}`]: row.pd_SC_UM}
                              options={pd_SC_UMOptions}
                              onChange={(e) =>handleFormDataChange1(`pd_SC_UM_${index}`, e.value)}
                              placeholder="Select UM"
                              disabled={cancelFlag || isProtected}
                            />
                            </td>
                            <td>
                            <InputText style={{width:"10rem"}}
                              id={`pd_CN_QT_${index}`}
                              name={`pd_CN_QT_${index}`}
                              value={data[`pd_CN_QT_${index}`] ? data[`pd_CN_QT_${index}`] : row.pd_CN_QT}
                              onChange={(e) => onchangedata(e)}
                              disabled={!cancelFlag || isProtected}
                            />
                            </td>
                            <td>
                            <Calendar 
                              style={{width:"10rem"}}
                              id={`pd_CN_DT_${index}`}
                              name={`pd_CN_DT_${index}`}
                              value={currentDate}
                              onChange={(e) => {setCurrentDate(e.value);
                              onDateChange("pd_CN_DT", index, e.value);
                              }}
                              dateFormat="dd/mm/yy"
                              disabled={!cancelFlag || isProtected}
                            />
                            </td>
                              <td>
                              <InputText disabled
                              style={{ width: "10rem" }}
                              id={`pd_ST_CD_${index}`}
                              name={`pd_ST_CD_${index}`}
                              value={ row.pd_ST_CD ? row.pd_ST_CD : pd_ST_CD}
                              />
                            </td>
                            <td>
                              <InputText disabled
                              style={{ width: "7rem" }}
                              id={`pd_CO_CT_${index}`}
                              name={`pd_CO_CT_${index}`}
                              value={pd_CO_CT}
                              />
                            </td>
                    </tr>
                    ))}
                </tbody>
              </table>   
              
              </div>
                </div>   
        <div style={inboxstyle} >
          <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="price_AMOUNT" className="font-bold">
                Amount
              </label>
              <InputText disabled
                id="price_AMOUNT"
                name="price_AMOUNT"
                defaultValue={price_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="discount_AMOUNT" className="font-bold">
                Discount
              </label>
              <InputText disabled
                id="discount_AMOUNT"
                name="discount_AMOUNT"
                defaultValue={discount_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="net_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="net_AMOUNT"
                name="net_AMOUNT"
                defaultValue={net_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="tax_AMOUNT" className="font-bold">
                Tax
              </label>
              <InputText disabled
                id="tax_AMOUNT"
                name="tax_AMOUNT"
                defaultValue={tax_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="total" className="font-bold">
                Total
              </label>
              <InputText disabled
                id="total"
                name="total"
                defaultValue={total}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            </div>
            <div className="formgrid grid" style={footergridstyle}>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_PRICE_AMOUNT" className="font-bold">
                Fore.Amount
              </label>
              <InputText disabled
                id="fr_PRICE_AMOUNT"
                name="fr_PRICE_AMOUNT"
                defaultValue={fr_PRICE_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_DISCOUNT_AMOUNT" className="font-bold">
                Fore.Discount
              </label>
              <InputText disabled
                id="fr_DISCOUNT_AMOUNT"
                name="fr_DISCOUNT_AMOUNT"
                defaultValue={fr_DISCOUNT_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_NET_AMOUNT" className="font-bold">
                Net Amount
              </label>
              <InputText disabled
                id="fr_NET_AMOUNT"
                name="fr_NET_AMOUNT"
                defaultValue={fr_NET_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TAX_AMOUNT" className="font-bold">
                Fore.Tax
              </label>
              <InputText disabled
                id="fr_TAX_AMOUNT"
                name="fr_TAX_AMOUNT"
                defaultValue={fr_TAX_AMOUNT}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field" style={footerfieldstyle} >
              <label htmlFor="fr_TOTAL" className="font-bold">
                Fore.Total
              </label>
              <InputText disabled
                id="fr_TOTAL"
                name="fr_TOTAL"
                defaultValue={fr_TOTAL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
          </TabPanel>
          <TabPanel header="Additional Details">
            <div style={inboxstyle} >
              <div className="formgrid grid">
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RL_NO" className="font-bold">
                Ref. Order No
                  </label>
                  <InputText 
                  id="ph_RL_NO"
                  name="ph_RL_NO"
                  value={ph_RL_NO}
                  onChange={(e) => onchangedata(e)}
                  disabled={cancelFlag || isProtected}
                />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                <label htmlFor="ph_RL_TY" className="font-bold">
                Type
                </label>
                <Dropdown
                    id="ph_RL_TY"
                    name="ph_RL_TY"
                    value={ph_RL_TY}
                    options={ph_RL_TYOptions}
                    onChange={(e) => TypeDropdown("ph_RL_TY", e.value)}
                    placeholder="Select Type"
                    disabled={cancelFlag || isProtected}
                  />
                </div> 
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RL_CO" className="font-bold">
                Company
                  </label>
                  <Dropdown
                    id="ph_RL_CO"
                    name="ph_RL_CO"
                    value={ph_RL_CO}
                    options={ph_RL_COOptions}
                    onChange={(e) => CompanyDropdown("ph_RL_CO", e.value)}
                    placeholder="Select Company"
                    disabled={cancelFlag || isProtected}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_HL_CD" className="font-bold">
                  Hold Code
                  </label>
                  <Dropdown
                    id="ph_HL_CD"
                    name="ph_HL_CD"
                    value={ph_HL_CD}
                    options={ph_HL_CDOptions}
                    onChange={(e) => HoldCodeDropdown("ph_HL_CD", e.value)}
                    placeholder="Select  Hold Code"
                    disabled={cancelFlag || isProtected}
                  />
                </div>
              </div>
              </div>
          </TabPanel>
        </TabView>
      </div> 
         {/*shipto  */}
         <AddressBookPopup
            visible={ShipToPopupVisible}
            onClose={() => setShipToPopupVisible(false)}
            onDataSelect={handleDataSelect1}
            formData={data}
            addressType={addressTypeship}
            setFormData={setFormData}
          />
          {/* supplierH */}
          <AddressBookPopup
            visible={SupplierhPopupVisible}
            onClose={() => setSupplierhPopupVisible(false)}
            onDataSelect={handleDataSelect}
            formData={data}
            addressType={addressTypeSUP}
            setFormData={setFormData}
          />
          {/* supplier */}
          <AddressBookPopup
            visible={SupplierPopupVisible}
            onClose={() => setSupplierPopupVisible(false)}
            onDataSelect={handleDataSelect3}
            formData={data}
            addressType={addressTypeSUP}
            setFormData={setFormData}
          />
          <ItemPopup
            visible={ItemPopupVisible}
            onClose={() => setItemPopupVisible(false)}
            onDataSelect={handleDataSelect2}
            formData={data}
          />
    </Dialog>
  );
}
