import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import TestMasterDialog from "./TestMasterDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  tmUkNo: "",
  tm_TS_ID: "",
  tm_TS_DS: "",
  tm_TS_ST: "",
  tm_FR_DT:moment(moment().toDate()).format("YYYYMMDD"),
  tm_TO_DT:moment(moment().toDate()).format("YYYYMMDD"),
  tm_AL_MI: "",
  tm_PR_MI: "",
  tm_PR_MX: "",
  tm_AL_MX: "",
  tm_TR_VL: "",
  tm_SM_UM: "",
  tm_CO_CT: "",

};

const initialFilters = {
  tm_TS_ID: { value: "", operation: "" },
  tm_TS_DS: { value: "", operation: "" },
  tm_TS_ST: { value: "", operation: "" },
  tm_FR_DT: { value: "", operation: "" },
  tm_TO_DT: { value: "", operation: "" },
  tm_AL_MI: { value: "", operation: "" },
  tm_PR_MI: { value: "", operation: "" },
  tm_PR_MX: { value: "", operation: "" },
  tm_AL_MX: { value: "", operation: "" },
  tm_TR_VL: { value: "", operation: "" },
  tm_SM_UM: { value: "", operation: "" },
  tm_CO_CT: { value: "", operation: "" },
};

const TestMasterMain = () => {
  // buttonstyles
  const { formatDate, buttonStyles, addButtonStyles,
    copyButtonStyles, checkboxStyle, tableHeaderStyle,
    inputStyle, tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [selectedAbAdNM, setSelectedAbAdNM] = useState("");
  const [SelectedemEMNO, setSelectedemEMNO] = useState("");

  const [tm_CO_CT, settm_CO_CT] = useState([]);
  const [tm_TS_ST, settm_TS_ST] = useState([]);
  const [tm_TS_TY, settm_TS_TY] = useState([]);
  const [tm_EV_TS, settm_EV_TS] = useState([]);
  const [tm_TS_MT, settm_TS_MT] = useState([]);
  const [tm_SM_UM, settm_SM_UM] = useState([]);
  const [tm_TS_UM, settm_TS_UM] = useState([]);
  const [tm_CL_01, settm_CL_01] = useState([]);
  const [tm_CL_02, settm_CL_02] = useState([]);
  const [tm_CL_03, settm_CL_03] = useState([]);
  const [tm_CL_04, settm_CL_04] = useState([]);
  const [tm_CL_05, settm_CL_05] = useState([]);



  const [em_EM_DS, setem_EM_DS] = useState([]);
  const [em_DE_PT, setem_DE_PT] = useState([]);
  const [ec_PY_CL, setec_PY_CL] = useState([]);
  const [em_GR_DE, setem_GR_DE] = useState([]);
  const [em_PY_FR, setem_PY_FR] = useState([]);
  const [em_GR_EF, setem_GR_EF] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };

  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setFormData(initialValue);
    settm_CO_CT(null);
    settm_TS_ST(null);
    settm_TS_TY(null);
    settm_EV_TS(null);
    settm_TS_MT(null);
    settm_SM_UM(null);
    settm_TS_UM(null);
    settm_CL_01(null);
    settm_CL_02(null);
    settm_CL_03(null);
    settm_CL_04(null);
    settm_CL_05(null);

    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialValue);
    setOpen(false);
  };

  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;

    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }

    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }

    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }

    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const handleCopyData = async () => {
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/TestMaster/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const TestMasterData = response.data;
          delete TestMasterData.tmUkNo;
          setem_EM_DS(TestMasterData.em_EM_DS);
          setem_DE_PT(TestMasterData.em_DE_PT);
          setem_GR_DE(TestMasterData.em_GR_DE);
          setem_PY_FR(TestMasterData.em_PY_FR);
          setec_PY_CL(TestMasterData.ec_PY_CL);
          setFormData(TestMasterData);
          setSelectedemEMNO(TestMasterData.name);

          // setSelectedAbAdNM(TestMasterData.cc_EM_NM);
          // setec_CC_NM(TestMasterData.ec_CC_NM);
          // setem_EM_DS(TestMasterData.em_EM_DS);
          // setem_DE_PT(TestMasterData.em_DE_PT);
          // setec_PY_CL(TestMasterData.ec_PY_CL);
          // setem_GR_DE(TestMasterData.em_GR_DE);
          // setem_PY_FR(TestMasterData.em_PY_FR);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [tm_TR_CO, settm_TR_CO] = useState("");

  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleDefault = async () => {
    settm_TR_CO(`${company}`);
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];

    const requestBody = {
      "version_ID": LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if (dataDefault.versionIds[i].field_TYPE === "SCREEN") {
            const fName = dataDefault.versionIds[i].field_ID;

          }
          if (dataDefault.versionIds[i].field_TYPE === "LIST") {
            count++;
            const def = {
              "column": dataDefault.versionIds[i].field_ID,
              "value": dataDefault.versionIds[i].default_VALUE,
              "operation": "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault, count);
      } else {
        handleSearch(listDefault, count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = async (defaultLists, nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for (let i = 0; i < nos; i++) {
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/TestMaster/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };

  const onchangeFormData = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value, }));
  };



  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/TestMaster/Select/${selectedRows[0]}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const TestMasterData = response.data;
        setFormData(TestMasterData);
        setSelectedAbAdNM(response.data.ab_AD_NM);
        setSelectedemEMNO(response.data.name);
        //  setec_CC_NM(response.data.ec_CC_NM);
        setem_EM_DS(response.data.em_EM_DS);
        setem_DE_PT(response.data.em_DE_PT);
        setec_PY_CL(response.data.ec_PY_CL);
        setem_GR_DE(response.data.em_GR_DE);
        setem_PY_FR(response.data.em_PY_FR);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // user acess for add,update,view
  const [useraccess, setuseraccess] = useState("");
  useEffect(() => {
    AccessCheck();
  }, []);
  const AccessCheck = async () => {
    try {
      const request = {
        "us_FN_NM": "Add",
        "us_AP_NM": "compensationdetails"
      }
      const response = await axios.post(`${domain}/api/UserSecurity/CheckSecurity`, request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        }
      );
      const responseValue = response.data.result.us_FL_AG;
      setuseraccess(responseValue === "YES");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = () => {
    values ();
    if (FormData.tmUkNo) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/TestMaster/Update`;
      if (confirm) {
        axios
          .put(update, FormData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      FormData.tm_TR_CO = company;
      axios
        .post(`${domain}/api/TestMaster/Add`, requestBody, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag": localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
  };

  var requestBody = {};
  function values () {
  requestBody = {
    tmUkNo   : FormData.tmUkNo,    
    tm_TS_ID :document.getElementById("tm_TS_ID").value,
    tm_TS_DS :document.getElementById("tm_TS_DS").value,
    tm_CO_CT : FormData.tm_CO_CT,
    tm_FR_DT : FormData.tm_FR_DT,//document.getElementById("tm_FR_DT").value,
    tm_TO_DT : FormData.tm_TO_DT,//document.getElementById("tm_TO_DT").value,
    tm_TS_ST : tm_TS_ST,
    tm_TS_TY : tm_TS_TY,
    tm_EV_TS : tm_EV_TS,
    tm_TS_MT : tm_TS_MT, 
    tm_TS_DR :document.getElementById("tm_TS_DR").value,
    tm_SM_NO :document.getElementById("tm_SM_NO").value,
    tm_SM_PR :document.getElementById("tm_SM_PR").value,
    tm_SM_SZ :document.getElementById("tm_SM_SZ").value,
    tm_SM_UM : tm_SM_UM,
    tm_CL_CD :document.getElementById("tm_CL_CD").value,
    tm_AL_MI :document.getElementById("tm_AL_MI").value,
    tm_PR_MI :document.getElementById("tm_PR_MI").value,
    tm_TR_VL :document.getElementById("tm_TR_VL").value,
    tm_AL_MX :document.getElementById("tm_AL_MX").value,
    tm_PR_MX :document.getElementById("tm_PR_MX").value,
    tm_TS_UM : tm_TS_UM,
    tm_CL_01 : tm_CL_01,
    tm_CL_02 :tm_CL_02,
    tm_CL_03 : tm_CL_03,
    tm_CL_04 : tm_CL_04,
    tm_CL_05 :tm_CL_05,
    tm_TR_CO : company,
    };
  }
  const leftToolbarTemplate = (
    <React.Fragment>
      <Tooltip target=".update-button" position="bottom" mouseTrack >
        <div style={{ background: "red !important", color: "white", fontSize: "10px" }} >
          Update
        </div>
      </Tooltip>
      <Button
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Add
        </div>
      </Tooltip>
      <Button
        style={addButtonStyles}
        icon="pi pi-plus"
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
      <Tooltip target=".copy-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
          Copy
        </div>
      </Tooltip>
      <Button
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px", border: "none", backgroundColor: "#1ed01e" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{ width: '40px' }}>
                  <input
                    type="text"
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_TS_ID"] ? filters["tm_TS_ID"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_TS_ID",
                        e.target.value,
                        filters["tm_TS_ID"]
                          ? filters["tm_TS_ID"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_TS_DS"] ? filters["tm_TS_DS"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_TS_DS",
                        e.target.value,
                        filters["tm_TS_DS"] ? filters["tm_TS_DS"].operation : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_TS_ST"] ? filters["tm_TS_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_TS_ST",
                        e.target.value,
                        filters["tm_TS_ST"]
                          ? filters["tm_TS_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_FR_DT"] ? filters["tm_FR_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_FR_DT",
                        e.target.value,
                        filters["tm_FR_DT"]
                          ? filters["tm_FR_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_TO_DT"] ? filters["tm_TO_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_TO_DT",
                        e.target.value,
                        filters["tm_TO_DT"]
                          ? filters["tm_TO_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_AL_MI"] ? filters["tm_AL_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_AL_MI",
                        e.target.value,
                        filters["tm_AL_MI"]
                          ? filters["tm_AL_MI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_PR_MI"] ? filters["tm_PR_MI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_PR_MI",
                        e.target.value,
                        filters["tm_PR_MI"]
                          ? filters["tm_PR_MI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_PR_MX"] ? filters["tm_PR_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_PR_MX",
                        e.target.value,
                        filters["tm_PR_MX"]
                          ? filters["tm_PR_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_AL_MX"] ? filters["tm_AL_MX"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_AL_MX",
                        e.target.value,
                        filters["tm_AL_MX"]
                          ? filters["tm_AL_MX"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_TR_VL"] ? filters["tm_TR_VL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_TR_VL",
                        e.target.value,
                        filters["tm_TR_VL"]
                          ? filters["tm_TR_VL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_SM_UM"] ? filters["tm_SM_UM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_SM_UM",
                        e.target.value,
                        filters["tm_SM_UM"]
                          ? filters["tm_SM_UM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["tm_CO_CT"] ? filters["tm_CO_CT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "tm_CO_CT",
                        e.target.value,
                        filters["tm_CO_CT"]
                          ? filters["tm_CO_CT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Test ID </th>
                <th style={tableHeaderStyle}>Description</th>
                <th style={tableHeaderStyle}>Statue</th>
                <th style={tableHeaderStyle}>From Date</th>
                <th style={tableHeaderStyle}>To Date</th>
                <th style={tableHeaderStyle}>Allowed Minimum</th>
                <th style={tableHeaderStyle}>Preferred Minimum</th>
                <th style={tableHeaderStyle}>Preferred Maximum</th>
                <th style={tableHeaderStyle}>Allowed Maximum</th>
                <th style={tableHeaderStyle}>Target</th>
                <th style={tableHeaderStyle}>UM</th>
                <th style={tableHeaderStyle}>Cost Centre</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.tmUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.tmUkNo}
                        checked={selectedRows.includes(rowData.tmUkNo)}
                        onChange={() => handleRowSelect(rowData.tmUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.tm_TS_ID}</td>
                    <td style={tableCellStyle}>{rowData.tm_TS_DS}</td>
                    <td style={tableCellStyle}>{rowData.tm_TS_ST}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.tm_FR_DT)}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.tm_TO_DT)}</td>
                    <td style={tableCellStyle}>{rowData.tm_AL_MI}</td>
                    <td style={tableCellStyle}>{rowData.tm_PR_MI}</td>
                    <td style={tableCellStyle}>{rowData.tm_PR_MX}</td>
                    <td style={tableCellStyle}>{rowData.tm_AL_MX}</td>
                    <td style={tableCellStyle}>{rowData.tm_TR_VL}</td>
                    <td style={tableCellStyle}>{rowData.tm_SM_UM}</td>
                    <td style={tableCellStyle}>{rowData.tm_CO_CT}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />

      </div>

      <TestMasterDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}
        setFormData={setFormData}
        tm_CO_CT={tm_CO_CT}
        settm_CO_CT={settm_CO_CT}
        em_EM_DS={em_EM_DS}
        em_DE_PT={em_DE_PT}
        ec_PY_CL={ec_PY_CL}
        em_GR_DE={em_GR_DE}
        em_PY_FR={em_PY_FR}
        tm_TR_CO={tm_TR_CO}
        em_GR_EF={em_GR_EF}
        selectedAbAdNM={selectedAbAdNM}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedAbAdNM={setSelectedAbAdNM}
        setSelectedemEMNO={setSelectedemEMNO}
        useraccess={useraccess}
        tm_TS_ST={tm_TS_ST}
        settm_TS_ST={settm_TS_ST}
        tm_TS_TY={tm_TS_TY}
        settm_TS_TY={settm_TS_TY}
        tm_EV_TS={tm_EV_TS}
        settm_EV_TS={settm_EV_TS}
        tm_TS_MT={tm_TS_MT}
        settm_TS_MT={settm_TS_MT}
        tm_SM_UM={tm_SM_UM}
        settm_SM_UM={settm_SM_UM}
        tm_TS_UM={tm_TS_UM}
        settm_TS_UM={settm_TS_UM}
        tm_CL_01={tm_CL_01}
        settm_CL_01={settm_CL_01}
        tm_CL_02={tm_CL_02}
        settm_CL_02={settm_CL_02}
        tm_CL_03={tm_CL_03}
        settm_CL_03={settm_CL_03}
        tm_CL_04={tm_CL_04}
        settm_CL_04={settm_CL_04}
        tm_CL_05={tm_CL_05}
        settm_CL_05={settm_CL_05}
      />
    </div>
  );
};

export default TestMasterMain;
