import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ButtonStyles from "views/common/ButtonStyles";
import axios from "axios";
export default function OutPatientDepartmentDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  HealthdetailData,
  setHealthdetailData,
}) {
  const {
    odUkNo,od_VI_NO,od_VI_DT,od_PA_NM,od_PA_ID,od_DO_NO,
    pd_GN_DR,pd_PA_AG,SPECIALIZATION,pd_BL_GP,
    od_HT_CM,od_WT_KG,od_BP_LV,od_BP_HI,
    pa_AL_GY,pc_CH_NC,
    // 5th tab
    im_ST_TY,
    // 6th tab
    od_OT_CH,od_MS_CH,od_DS_PR,od_CN_FE,od_BL_AM,od_DS_AM,TOTAL,od_RE_MK

  } = data;
  const {borderboxdir,inboxstyle,savestyle,cancelstyle,Dialogstyle,TabBoxStyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle} = ButtonStyles();

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [selectedRows, setSelectedRows] = useState([]);
  const [sysmptomData , setsysptomData ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const Sysmptomlist = `${domain}/api/SymptomMaster/Lists`;
      const request = {
        searchRequestDto: []
      };
      try {
        const response = await axios.post(Sysmptomlist, request, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`,
            "x-flag": localStorage.getItem("userType")
          }
        });
        const sysdata = response.data;
        if (sysdata && Array.isArray(sysdata)) {
          setsysptomData(sysdata);
        } else {
          console.error("Invalid API response:", sysdata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [domain, token, company]);
  const handleRowSelect = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      const isRowSelected = prevSelectedRows.includes(rowId);
      if (isRowSelected) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };
  // getting diagnosis list 
  const handleDiagnosis = async () => {
    if (selectedRows.length === 0) {
      alert("Please select symptoms before proceeding.");
      return;
    }
    try {
      const diagnosisApiUrl = `${domain}/api/DiagnosisMaster/GetDiagnosisBySymptom`;
      const request = {
          "symptomMaster":{       
            "uk_NOS" : document.getElementById(``)
          }
        }
      const response = await axios.post(diagnosisApiUrl, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`,
          "x-flag": localStorage.getItem("userType"),
        },
      });
      // Process the response from the diagnosis API
      console.log("Diagnosis Result:", response.data);
      alert("Diagnosis Completed!");
    } catch (error) {
      console.error("Error calling diagnosis API:", error);
    }
  };

  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{odUkNo ? "Revise OP Department" : "Add OP Department"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div className="p-grid" style={borderboxdir} >
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="odUkNo" className="font-bold">
                        uk_no
                      </label>
                      <InputText 
                        id="odUkNo"
                        name="odUkNo"
                        value={odUkNo}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="od_VI_NO" className="font-bold">
                        Visit No
                      </label>
                      <InputText 
                        id="od_VI_NO"
                        name="od_VI_NO"
                        value={od_VI_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="od_VI_DT" className="font-bold">
                        Visit Date
                      </label>
                      <Calendar
                        id="od_VI_DT"
                        name="od_VI_DT"
                        value={moment(data.od_VI_DT, "YYYYMMDD").isValid() ? moment(data.od_VI_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onchangedata({ target: {name: "od_VI_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="pd_RG_DT" className="font-bold">
                        Reg. Date
                      </label>
                      <Calendar
                        id="pd_RG_DT"
                        name="pd_RG_DT"
                        value={moment(data.pd_RG_DT, "YYYYMMDD").isValid() ? moment(data.pd_RG_DT, "YYYYMMDD").toDate() : null}
                        onChange={(e) => onchangedata({ target: {name: "pd_RG_DT", value: moment(e.value).isValid() ? moment(e.value).format("YYYYMMDD") : "", },}) }
                        dateFormat="dd/mm/yy"
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="od_PA_ID" className="font-bold">
                        Patient ID
                      </label>
                      <InputText 
                        id="od_PA_ID"
                        name="od_PA_ID"
                        value={od_PA_ID}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="od_PA_NM" className="font-bold">
                        Name
                      </label>
                      <InputText 
                        id="od_PA_NM"
                        name="od_PA_NM"
                        value={od_PA_NM}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="pd_GN_DR" className="font-bold">
                        Gender
                      </label>
                      <InputText  disabled
                        id="pd_GN_DR"
                        name="pd_GN_DR"
                        value={pd_GN_DR}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="pd_PA_AG" className="font-bold">
                        Age
                      </label>
                      <InputText disabled
                        id="pd_PA_AG"
                        name="pd_PA_AG"
                        value={pd_PA_AG}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="od_DO_NO" className="font-bold">
                        Doctor
                      </label>
                      <InputText disabled
                        id="od_DO_NO"
                        name="od_DO_NO"
                        value={od_DO_NO}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="SPECIALIZATION" className="font-bold">
                        Specialization
                      </label>
                      <InputText disabled
                        id="SPECIALIZATION"
                        name="SPECIALIZATION"
                        value={SPECIALIZATION}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                    <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                      <label htmlFor="pd_BL_GP" className="font-bold">
                        Blood Group
                      </label>
                      <InputText disabled
                        id="pd_BL_GP"
                        name="pd_BL_GP"
                        value={pd_BL_GP}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Allery</h1>
                  <div className="formgrid grid">
                    <div className="field col-lg-12 col-md-6 col-sm-12 mb-3">
                      <InputText 
                        id="pa_AL_GY"
                        name="pa_AL_GY"
                        value={pa_AL_GY}
                        onChange={(e) => onchangedata(e)}
                      />
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}>Chronic</h1>
                  <div className="formgrid grid">
                      <div className="field col-lg-12 col-md-6 col-sm-12 mb-3">
                        <InputText 
                          id="pc_CH_NC"
                          name="pc_CH_NC"
                          value={pc_CH_NC}
                          onChange={(e) => onchangedata(e)}
                        />
                      </div>
                  </div>
              </div>
            </div>
        </div>
    {/* Second Container: TabView with 6 Tabs */}
    <div  style={TabBoxStyle} >
      <TabView>
        <TabPanel header="Health Details">
        <div class="row" style={inboxstyle}>
          <div class="col-lg-5 col-md-12 col-sm-12 mb-3">
              <div className="formgrid grid">
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="od_HT_CM" className="font-bold">
                  Height (CM)
                  </label>
                  <InputText 
                    id="od_HT_CM"
                    name="od_HT_CM"
                    value={od_HT_CM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                  <label htmlFor="od_WT_KG" className="font-bold">
                    Weight (KG)
                  </label>
                  <InputText 
                    id="od_WT_KG"
                    name="od_WT_KG"
                    value={od_WT_KG}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-3 col-md-6 col-12 pe-md-2" >
                  <label htmlFor="od_BP_LV" className="font-bold">
                    BP
                  </label>
                  <div style={{display:"flex"}}> 
                    <InputText style={{width:'10rem'}}
                      id="od_BP_LV"
                      name="od_BP_LV"
                      value={od_BP_LV}
                      onChange={(e) => onchangedata(e)}
                    />
                    <InputText style={{width:'10rem'}}
                      id="od_BP_HI"
                      name="od_BP_HI"
                      value={od_BP_HI}
                      onChange={(e) => onchangedata(e)}
                    />
                  </div>
                </div>
              </div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12 mb-3">
              <table className="custom-table" id="Healthdetail">
                <thead>
                  <tr> 
                    <th>Specialized Health Details</th>
                    <th>Answers</th>
                  </tr>
                </thead>
                <tbody>
                {HealthdetailData.map((row, index) => (
                  <tr>
                    <td> 
                        <InputText
                          id={`ph_QA_DS_${index}`}
                          name={`ph_QA_DS_${index}`}
                          value={row.ph_QA_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                    </td>
                    <td>
                        <InputText
                          id={`ph_AN_DS_${index}`}
                          name={`ph_AN_DS_${index}`}
                          value={row.ph_AN_DS}
                          onChange={(e) => onchangedata(e)}
                        />
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
        </TabPanel>
        <TabPanel header="Prescription">
          <div class="row" style={inboxstyle}>
            {/*  */}
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid" style={{height:'20rem'}}>
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Symptoms</th>
                      </tr>
                    </thead>
                    <tbody>
                    {sysmptomData && sysmptomData.map((rowData) => (
                      
                      <tr  key={rowData.odUkNo} className="table-row" >
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={rowData.odUkNo}
                            checked={selectedRows.includes(rowData.odUkNo)}
                            onChange={() => handleRowSelect(rowData.odUkNo)}
                          />
                        </td>
                        <td> 
                        {rowData.sm_SY_DS.split(',').map((symptom, idx) => (
                          <div key={idx}>
                            <InputText value={symptom} disabled />
                          </div>
                        ))}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
                <Button onClick={handleDiagnosis}
                  style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                  label="Get Daignosis"
                  icon="pi pi-times"
                  tooltip="Cancel"
                />
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Diagnosis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={"odUkNo"}
                          />
                        </td>
                        <td> 
                          <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Get Medicines"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                  <table className="custom-table">
                    <thead>
                      <tr> 
                        <th></th>
                        <th>Medicines</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'1rem !important'}}> 
                          <input
                            type="checkbox"
                            value={"odUkNo"}
                          />
                        </td>
                        <td> 
                          <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Medicines"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <div className="formgrid grid">
                <h1 style={{fontSize:"large",color:"skyblue",fontWeight:600,padding:"0% 0% 3% 0%"}}> Doctor Note </h1>
                  <textarea
                    id="fa_FL_DS"
                    name="fa_FL_DS"
                    value={"fa_FL_DS"}
                    onChange={(e) => onchangedata(e)}
                    style={{ width: "100%",height:'9rem' }}
                  /> 
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            {/* table 2nd tab */}
            <div style={inboxstyle} >
              <table className="custom-table">
                <thead>
                  <tr> 
                    <th>Medicine</th>
                    <th>Dosage</th>
                    <th>Dosage Note</th>
                    <th>Quantity</th>
                    <th>UM</th>
                    <th>Available Qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> 
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td> 
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                    <td>
                      <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </TabPanel>
          {/* 3rd tab */}
        <TabPanel header="Lab Test">
          <div class="row" style={inboxstyle}>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="od_HT_CM" className="font-bold">
                  Select Lab Test Type
                </label>
                <InputText  style={{width:'20rem'}}
                  id="od_HT_CM"
                  name="od_HT_CM"
                  value={od_HT_CM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Test Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"odUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Tests"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th> </th>
                      <th>Test Type</th>
                      <th>Test Name</th>
                      <th>Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input type="checkbox" value={"odUkNo"}/>
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="od_HT_CM" className="font-bold">
                  Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="od_HT_CM"
                  name="od_HT_CM"
                  value={od_HT_CM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <Button
                style={{backgroundColor:'darkcyan',border:'none',width:'12rem'}}
                label="Open Reports"
                icon="pi pi-times"
                tooltip="Cancel"
              />
            </div>
          </div>
        </TabPanel>
          {/* 4th tab */}
        <TabPanel header="Procedure">
          <div class="row" style={inboxstyle}>
            <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="od_HT_CM" className="font-bold">
                  Select Procedure
                </label>
                <InputText  style={{width:'20rem'}}
                  id="od_HT_CM"
                  name="od_HT_CM"
                  value={od_HT_CM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Sub Procedure Name</th>
                      <th>Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"odUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Procedure"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th>Procedure Name</th>
                      <th>Sub Procedure Name</th>
                      <th>Charge</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="od_HT_CM" className="font-bold">
                  Grand Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="od_HT_CM"
                  name="od_HT_CM"
                  value={od_HT_CM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        {/* 5th */}
        <TabPanel header="Consumables" >
        <div class="row" style={inboxstyle}>
            <div class="col-lg-5 col-md-12 col-sm-12 mb-3">
              <div className="field col-lg-12 col-md-6 col-12 pe-md-2" style={{display:'flex'}}>
                <label htmlFor="im_ST_TY" className="font-bold">
                  Stock Type
                </label>
                <InputText  style={{width:'20rem'}}
                  id="im_ST_TY"
                  name="im_ST_TY"
                  value={im_ST_TY}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th></th>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Price </th>
                      <th>UM</th>
                      <th>Available Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{width:'1rem !important'}}> 
                        <input
                          type="checkbox"
                          value={"odUkNo"}
                        />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
                  <Button
                    style={{backgroundColor:'darkcyan',border:'none',marginTop:'1rem'}}
                    label="Add Consumables"
                    icon="pi pi-times"
                    tooltip="Cancel"
                  />
              </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 mb-3">
              <div style={inboxstyle} >
                <table className="custom-table">
                  <thead>
                    <tr> 
                      <th>Stock Type</th>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>UM</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td> 
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                      <td>
                        <InputText  id="od_VI_NO"   name="od_VI_NO"  value={od_VI_NO}  onChange={(e) => onchangedata(e)} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
                <label htmlFor="od_HT_CM" className="font-bold">
                  Total
                </label>
                <InputText  style={{width:'20rem'}}
                  id="od_HT_CM"
                  name="od_HT_CM"
                  value={od_HT_CM}
                  onChange={(e) => onchangedata(e)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        {/* 6th tab */}
        <TabPanel header="Charges">
          <div class="row" style={inboxstyle}>
            
          <div className="formgrid grid">
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_OT_CH" className="font-bold">
                Other Charges
              </label>
              <InputText 
                id="od_OT_CH"
                name="od_OT_CH"
                value={od_OT_CH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_MS_CH" className="font-bold">
                Mis. Charges
              </label>
              <InputText 
                id="od_MS_CH"
                name="od_MS_CH"
                value={od_MS_CH}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_DS_PR" className="font-bold">
                Discount %
              </label>
              <InputText 
                id="od_DS_PR"
                name="od_DS_PR"
                value={od_DS_PR}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_HT_CM" className="font-bold">
                Discount Amt
              </label>
              <InputText 
                id="od_HT_CM"
                name="od_HT_CM"
                value={od_HT_CM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_CN_FE" className="font-bold">
                Consultation Fee
              </label>
              <InputText 
                id="od_CN_FE"
                name="od_CN_FE"
                value={od_CN_FE}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_BL_AM" className="font-bold">
                Bill Amount
              </label>
              <InputText 
                id="od_BL_AM"
                name="od_BL_AM"
                value={od_BL_AM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_DS_AM" className="font-bold">
                Discount
              </label>
              <InputText 
                id="od_DS_AM"
                name="od_DS_AM"
                value={od_DS_AM}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="TOTAL" className="font-bold">
                Grand Total
              </label>
              <InputText 
                id="TOTAL"
                name="TOTAL"
                value={TOTAL}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-3 col-md-6 col-12 pe-md-2">
              <label htmlFor="od_RE_MK" className="font-bold">
                Remarks
              </label>
              <InputText 
                id="od_RE_MK"
                name="od_RE_MK"
                value={od_RE_MK}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>

          </div>
        </TabPanel>
      </TabView>
    </div>
      </div>
    </Dialog>
  );
}
