import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonStyles from "views/common/ButtonStyles";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import axios from "axios";
import ClassCode from "views/common/ClassCode";
import ItemPopup from "../Popups/ItemPopup";
import MaintainanceOrders from "../Popups/MaintainOrdersPopup";
export default function SparePartsEntryDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeFormData,
  FormData,
  setFormData,
  SparePartsEntryTableData,
  setSparePartsEntryTableData,
  sp_TR_UM,
  setsp_TR_UM,
  Componentname,
  setComponentname,
  sp_ST_CD,
}) {
  const {
    spUkNo,
    sp_OR_NO,
    sp_OR_TY,
    sp_OR_CO,
    sp_EQ_NM,
    sp_CO_NM,
    sp_OR_QT,
    sp_IS_QT,
    sp_LO_CN,
    sp_LT_NO,
    sp_RQ_DT,
    sp_IS_DT,
  } = data;
  const addRow = () => {
    setSparePartsEntryTableData([...SparePartsEntryTableData, {}]);
  };

  const deleteRow = () => {
    if (SparePartsEntryTableData.length > 0) {
      const updatedData = [...SparePartsEntryTableData];
      updatedData.pop();
      setSparePartsEntryTableData(updatedData);
    }
  };
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,multitableheader,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  // dropdowns
  const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [sp_TR_UMOptions, setsp_TR_UMOptions] = useState([]);
  // UM
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setsp_TR_UM(value);
  };

  useEffect(() => {
    fetchDropdownUM();
  }, []);

  const fetchDropdownUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    setsp_TR_UMOptions(Options);
  };

//advance search
const [ComponentPopupVisible, setComponentPopupVisible] = useState(false);
const [OrderPopupVisible, setOrderPopupVisible] = useState(false);
const [showSearchIcon, setShowSearchIcon] = useState("");
const [SelectedRowIndex, setSelectedRowIndex] = useState("");

const openOrderPopup = () => {
  setOrderPopupVisible(true);
  setShowSearchIcon(false);
};

const handleSelectOrder = (content) => {
  onchangeFormData("sp_OR_NO", content.wm_OR_NO);
  onchangeFormData("sp_OR_TY", content.wm_OR_TY);
  onchangeFormData("sp_OR_CO", content.wm_OR_CO);
  onchangeFormData("sp_EQ_NM", content.wm_EQ_NM);
  setOrderPopupVisible(false);
  setShowSearchIcon(false);
};

const openComponentPopup = (e) => {
  setComponentPopupVisible(true);
  setSelectedRowIndex(e);
  setShowSearchIcon(false);
};

  const handleSelectComponent = async (data) => {
    // setComponentname(data.im_IT_DS);
    document.getElementById(`itemDES_${SelectedRowIndex}`).value = data.im_IT_DS;
    document.getElementById(`sp_CO_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    const Url = `${domain}/api/ItemMaster/onChangeOfItemNumber`;
    const requestData={
        "im_IT_NM":data.im_IT_NM
    }
    try {
      const response =await axios.post(Url, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataItMt = response.data.Result;
      if (dataItMt ) {
        onchangeDropDownFormData1(`sp_TR_UM_${SelectedRowIndex}`, dataItMt.im_PR_UM);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setComponentPopupVisible(false);
    setShowSearchIcon(false);
  };

  const isUpdateMode = Boolean(spUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{spUkNo ? "Revise Spare Parts Entry" : "Add Spare Parts Entry"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
          <div style={inboxstyle}>
              <div className="formgrid grid">
              <div style={{display:"none"}}>
                  <label htmlFor="spUkNo" className="font-bold">
                uk_no
                  </label>
                  <InputText 
                    id="spUkNo"
                    name="spUkNo"
                    defaultValue={spUkNo}
                  />
                </div>
              <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sp_OR_NO" className="font-bold">
                  Order No <span style={{color:"red"}}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText 
                    id="sp_OR_NO"
                    name="sp_OR_NO"
                    onFocus={() => setShowSearchIcon(true)}                      
                    onChange={(e) => onchangedata(e)}
                    value={sp_OR_NO}
                  />
                    {showSearchIcon && (
                    <i 
                    className="pi pi-search"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "119%",
                      marginTop: "7%",
                      cursor: "pointer",
                      
                    }}
                    onClick={(e) => openOrderPopup(e)}
                  ></i>
                    )}
                    </div>
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sp_OR_TY" className="font-bold">
                    Type
                  </label>
                  <InputText disabled
                    id="sp_OR_TY"
                    name="sp_OR_TY"
                    value={sp_OR_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sp_OR_CO" className="font-bold">
                    Company
                  </label>
                  <InputText disabled
                    id="sp_OR_CO"
                    name="sp_OR_CO"
                    value={sp_OR_CO}
                  />
                </div>  
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="sp_EQ_NM" className="font-bold">
                    Equipment Name
                  </label>
                  <InputText disabled
                    id="sp_EQ_NM"
                    name="sp_EQ_NM"
                    value={sp_EQ_NM}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>             
              </div>
          </div>
          <div style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
              {(
              <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
              )}
              {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
              )}
            </div>
            <div  style={multitablestyle}>
            <table className="custom-table" id="SparePartsEntryTable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }} >UK_NO</th>
              <th style={multitableheader} >Component <span style={{color:"red"}}>*</span> </th>    
              <th  >Description</th>
              <th style={multitableheader} >Req. Quantity </th>
              <th style={multitableheader} >UM</th>    
              <th style={multitableheader} >Request Date</th>
              <th style={multitableheader} >Status</th>
              
            </tr>
          </thead>
          <tbody>
          {SparePartsEntryTableData.map((row, index) => (
            <tr key={index}>
              <td style={{ display: "none" }} >
              <InputText
                id={`spUkNo_${index}`}
                name={`spUkNo_${index}`}
                value={row.spUkNo}
              />
              </td>
              <td>
                <div style={{display:"flex"}}>
              <InputText 
                id={`sp_CO_NM_${index}`}
                name={`sp_CO_NM_${index}`}
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={row.sp_CO_NM ? row.sp_CO_NM : row[`${'sp_CO_NM'}_${index}`]}
              />
              {showSearchIcon && (
              <i
                  className="pi pi-search"
                  style={{
                    color: "var(--primary-color)",
                    fontSize: "119%",
                    marginTop: "7%",
                    cursor: "pointer",
                  }}
                  onClick={(e)=>openComponentPopup(index)}
                ></i>
              )}
                </div>
              </td>
              <td >
              <InputText  disabled
                id={`itemDES_${index}`}
                name={`itemDES_${index}`}
                defaultValue={row.itemDES}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <InputText 
                id={`sp_RQ_QT_${index}`}
                name={`sp_RQ_QT_${index}`}
                defaultValue={row.sp_RQ_QT}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              <td>
              <Dropdown
                id={`sp_TR_UM_${index}`}
                name={`sp_TR_UM_${index}`}
                value={data[`sp_TR_UM_${index}`] ? data[`sp_TR_UM_${index}`] : row.sp_TR_UM}
                options={sp_TR_UMOptions}
                onChange={(e) => onchangeDropDownFormData1(`sp_TR_UM_${index}`, e.value)}
                placeholder="Select UM"
              />
              </td>
              <td>
              <Calendar
                id={`sp_RQ_DT_${index}`}
                name={`sp_RQ_DT_${index}`}
                value={ row.sp_RQ_DT ? moment(row.sp_RQ_DT, "YYYYMMDD").toDate() : null }
                onChange={(e) => onDateChange({ target: { name: "sp_RQ_DT", value: moment(e.value).format("YYYYMMDD") } })}
                dateFormat="dd/mm/yy"
              />
              </td>
              <td>
              <InputText disabled
                id={`sp_ST_CD_${index}`}
                name={`sp_ST_CD_${index}`}
                defaultValue={sp_ST_CD}
                onChange={(Event) => onchangedata(Event)}
              />
              </td>
              </tr>
              ))}
          </tbody>
        </table> 
      </div>
          </div>
      </div>
      <ItemPopup
        visible={ComponentPopupVisible}
        onClose={() => setComponentPopupVisible(false)}
        onDataSelect={handleSelectComponent}
        formData={data}
      /> 
       <MaintainanceOrders
        visible={OrderPopupVisible}
        onClose={() => setOrderPopupVisible(false)}
        onDataSelect={handleSelectOrder}
        formData={data}
      />  
    </Dialog>
  );
}
