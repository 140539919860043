import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import "./table.css";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
export default function SalesMethodologyDialog({
  open,
  handleClose,
  data,
  onchangedata,
  handleFormSubmit,
  onchangeDropDownFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  salesMethodologyData,
  addSalesCycleRow,
  deleteSalesCycleRow,
  addSalesDriverRow,
  deleteSalesDriverRow,
  salesDriverData,
  smMeNm,
  sm_UK_NO,
}) {
  const {plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,borderbox,inboxstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [sm_NA_MEOptions, setsm_NA_MEOptions] = useState([]);
  const [sm_AC_PLOptions, setsm_AC_PLOptions] = useState([]);
  const [sd_DR_NMptions, setcc_CN_NMOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");
  const [showSalesCycleTable, setShowSalesCycleTable] = useState(true);

  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData1(name, value, index);
  };

  const handleFormDataChange1 = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const handleFormDataChange2 = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData1(name, value, index);
  };
  const url = `${domain}/api/ClassCode/All`;
  const parameter = "SA/CY";
  const finalUrl = `${url}/${parameter}`;

  const parameter1 = "ME/PL";
  const finalUrl1 = `${url}/${parameter1}`;

  const parameter2 = "ME/DR"; //ME/IM
  const finalUrl2 = `${url}/${parameter2}`;

  useEffect(() => {
    fetchDropdownOptions();
    fetchDropdownOptions1();
    fetchDropdownOptions2();
  }, []);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axios.get(finalUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsm_NA_MEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setsm_AC_PLOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setcc_CN_NMOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const leftToolbarTemplate = (
    <React.Fragment>
      <Button
        label="SalesCycle"
        className={`p-button-success p-mr-2 ${
          showSalesCycleTable ? "p-button-text p-highlight" : ""
        }`}
        onClick={() => setShowSalesCycleTable(true)}
      ></Button>

      <Button
        style={{ marginLeft: "5%" }}
        label="SalesDriver"
        className={`p-button-success p-mr-2 ${
          !showSalesCycleTable ? "p-button-text p-highlight" : ""
        }`}
        onClick={() => setShowSalesCycleTable(false)}
      ></Button>
    </React.Fragment>
  );
  const isUpdateMode = Boolean(sm_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{sm_UK_NO ? "Revise Methodology" : "Add Methodology"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}> 
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
              <label htmlFor="smMeNm" className="font-bold">
                Methodology
              </label>
              <InputText
                id="smMeNm"
                name="smMeNm"
                defaultValue={smMeNm}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
        </div>
          <div  style={tablestyle} >
            <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addSalesCycleRow} style={plusstyle} tooltip="Add"  />
            )}
              {(
            <Button icon="pi pi-minus" onClick={deleteSalesCycleRow} style={minusstyle} tooltip="Delete" />
            )}
            </div>
            <div  style={multitablestyle}>
              <table className="custom-table" id="SalesCycle">
                <thead>
                  <tr>
                    <th style={{ display: "none" }}>UK_NO</th>
                    <th>Sequence</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Probability</th>
                    <th>Time to Close</th>
                    <th>Action Plan</th>
                  </tr>
                </thead>
                <tbody>
                  {salesMethodologyData.map((row, index) => (
                    <tr key={index}>
                      <td style={{ display: "none" }}>
                        <InputText
                          id={`sm_UK_NO_${index}`}
                          name={`sm_UK_NO_${index}`}
                          defaultValue={row.sm_UK_NO}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      
                      <td>
                        {/* <InputText
                          id={`smSqNo_${index}`}
                          name={`smSqNo_${index}`}
                          defaultValue={row.smSqNo}
                          onChange={(Event) => onchangedata(Event)}
                        /> */}
                          <InputText  disabled
                            id={`smSqNo_${index}`}
                            name={`smSqNo_${index}`}
                            value={index + 1}
                            readOnly
                          />
                      </td>
                      <td>
                        {/* <select
                          id={`sm_NA_ME_${index}`}
                          name={`sm_NA_ME_${index}`}
                          value={FormData[`sm_NA_ME_${index}`]}
                          onChange={(Event) =>
                            onchangeDropDownFormData(
                              `sm_NA_ME_${index}`,
                              Event.target.value
                            )
                          }
                        >
                          <option value="">Select Name</option>
                          {sm_NA_MEOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select> */}
                      <Dropdown 
                        id={`sm_NA_ME_${index}`}
                        name={`sm_NA_ME_${index}`}
                        value={ data[`sm_NA_ME_${index}`] ? data[`sm_NA_ME_${index}`]: row.sm_NA_ME}
                        options={sm_NA_MEOptions}
                        onChange={(e) =>handleFormDataChange(`sm_NA_ME_${index}`, e.value)}
                        placeholder="Select Name"
                      />
                      </td>
                      <td>
                        <InputText
                          id={`sm_DS_ON_${index}`}
                          name={`sm_DS_ON_${index}`}
                          defaultValue={row.sm_DS_ON}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        <InputText 
                          id={`sm_PR_TY_${index}`}
                          name={`sm_PR_TY_${index}`}
                          defaultValue={row.sm_PR_TY}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        <InputText 
                          id={`sm_ET_TM_${index}`}
                          name={`sm_ET_TM_${index}`}
                          defaultValue={row.sm_ET_TM}
                          onChange={(Event) => onchangedata(Event)}
                        />
                      </td>
                      <td>
                        {/* <select
                          id={`sm_AC_PL_${index}`}
                          name={`sm_AC_PL_${index}`}
                          value={FormData[`sm_AC_PL_${index}`]}
                          onChange={(Event) =>
                            onchangeDropDownFormData1(
                              `sm_AC_PL_${index}`,
                              Event.target.value
                            )
                          }
                        >
                          <option value="">Select Action Plan</option>
                          {sm_AC_PLOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select> */}
                      <Dropdown 
                        id={`sm_AC_PL_${index}`}
                        name={`sm_AC_PL_${index}`}
                        value={ data[`sm_AC_PL_${index}`] ? data[`sm_AC_PL_${index}`]: row.sm_AC_PL}
                        options={sm_AC_PLOptions}
                        onChange={(e) =>handleFormDataChange1(`sm_AC_PL_${index}`, e.value)}
                        placeholder="Select Activity Plan"
                      />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addSalesDriverRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteSalesDriverRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
          <table className="custom-table" id="SalesDriver">
            <thead>
              <tr>
                <th style={{ display: "none" }}>UK_NO</th>
                <th>Sales Driver</th>
                <th>Description</th>
                <th>Importance</th>
              </tr>
            </thead>
            <tbody>
              {salesDriverData.map((row, index) => (
                <tr key={index}>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`sd_UK_NO_${index}`}
                      name={`sd_UK_NO_${index}`}
                      defaultValue={row.sd_UK_NO}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    {/* <select
                      id={`sd_DR_NM_${index}`}
                      name={`sd_DR_NM_${index}`}
                      value={FormData[`sd_DR_NM_${index}`]}
                      onChange={(Event) =>
                        onchangeDropDownFormData2(
                          `sd_DR_NM_${index}`,
                          Event.target.value
                        )
                      }
                    >
                      <option value="">Select Driver</option>
                      {sd_DR_NMptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                    <Dropdown 
                    id={`sd_DR_NM_${index}`}
                    name={`sd_DR_NM_${index}`}
                    value={ data[`sd_DR_NM_${index}`] ? data[`sd_DR_NM_${index}`]: row.sd_DR_NM_}
                    options={sd_DR_NMptions}
                    onChange={(e) =>handleFormDataChange2(`sd_DR_NM_${index}`, e.value)}
                    placeholder="Select Driver"
                  />
                  </td>
                  <td>
                    <InputText
                      id={`sd_DR_DS_${index}`}
                      name={`sd_DR_DS_${index}`}
                      defaultValue={row.sd_DR_DS}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                  <td>
                    <InputText
                      id={`sd_DR_IM_${index}`}
                      name={`sd_DR_IM_${index}`}
                      defaultValue={row.sd_DR_IM}
                      onChange={(Event) => onchangedata(Event)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
