import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function IndustryGroupingDialog({
  open,
  handleClose,
  data,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ig_CO_NO,
  Industrytable,
  setIndustrytable,
  setFormData,
  ig_IN_RY,
  setig_IN_RY,
}) {
  const { igUkNo,ig_IN_TY} = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const addRow = () => {
    setIndustrytable([...Industrytable, {}]);
  };
  const deleteRow = () => {
    if (Industrytable.length > 0) {
      const updatedData = [...Industrytable];
      updatedData.pop();
      setIndustrytable(updatedData);
    }
  };
// drodpdowns
const {fetchClassCodeDS, fetchClassCodeCD} = ClassCode();
  const [ig_IN_RYOptions, setig_IN_RYOptions] = useState([]);
// INDUSTRY
const onchangeDropDownFormData1 = (name, value) => {
  setFormData({ ...data, [name]: value });
  setig_IN_RY(value);
};
  useEffect(() => {
    fetchDropdownIN();
  }, []);
  const fetchDropdownIN = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setig_IN_RYOptions(Options);
  };
const isUpdateMode = Boolean(igUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{igUkNo ? "Revise Industry Grouping" : "Add Industry Grouping"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle} >
          <div className="formgrid grid"> 
            <div style={{display:"none"}}>
              <label htmlFor="igUkNo" className="font-bold">
                uk_no
              </label>
              <InputText disabled
                id="igUkNo"
                name="igUkNo"
                value={igUkNo}
              />
            </div>             
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ig_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ig_CO_NO"
                name="ig_CO_NO"
                defaultValue={ig_CO_NO}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ig_IN_RY" className="font-bold">
                Industry
              </label>
              <Dropdown
                id="ig_IN_RY"
                name="ig_IN_RY"
                value={ig_IN_RY}
                options={ig_IN_RYOptions}
                onChange={(e) => onchangeDropDownFormData1("ig_IN_RY", e.value)}
                placeholder="Select Industry"
              />
            </div>
          </div>
        </div>
      </div> 
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
            {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
          </div>
        <div  style={multitablestyle}>
            <table className="custom-table" id="Industrytable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{display:"none"}}>UK_NO</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
          {Industrytable.map((row, index) => (
            <tr key={index}>
              <td  style={{display:"none"}}>
                <InputText
                  id={`igUkNo_${index}`}
                  name={`igUkNo_${index}`}
                  value={row.igUkNo}
                />
              </td>
              <td>
              <InputText
                  id={`ig_IN_TY_${index}`}
                  name={`ig_IN_TY_${index}`}
                  defaultValue={row.ig_IN_TY ? row.ig_IN_TY : row[`ig_IN_TY_${index}`]}
                  onChange={(e) => onchangedata(e)} 
                />
              </td>
              </tr>
              ))}
          </tbody>
        </table>  
      </div>
    </div>
    </Dialog>
  );
}
