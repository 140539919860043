import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
import  Costcenter  from "views/common/CostCentreDrpDwn";
export default function SalesTablesDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  SalesTablesTableData,
  setSalesTablesTableData,
  st_CO_CT,
  setst_CO_CT,
  st_BL_CK,
  setst_BL_CK,
  st_CO_NO,
}) {
  const {
    stUkNo,
    st_TB_NO,
    st_TB_DS,
    st_NO_ST, 
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,multitablestyle,multitableheader} = ButtonStyles();
  // Dropdown
  const { fetchClassCodeDS} = ClassCode();
  const { fetchCostCenter } = Costcenter();
  const [st_CO_CTOptions, setst_CO_CTOptions] = useState([]);
  const [st_BL_CKOptions, setst_BL_CKOptions] = useState([]);

// Cost Cntre
const onchangeDropDownFormData1 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setst_CO_CT(value);
};
// Block
const onchangeDropDownFormData2 = (name, value) => {
  setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  setst_BL_CK(value);
};

  useEffect(() => {
    fetchDropdownCostcntr();
    fetchDropdownBlck();
  }, []);

  const fetchDropdownCostcntr = async () => {
    const Options = await fetchCostCenter();
    setst_CO_CTOptions(Options);
  };

  const fetchDropdownBlck = async () => {
    const Options = await fetchClassCodeDS("TB/BL");
    setst_BL_CKOptions(Options);
  };


  const addRow = () => {
    setSalesTablesTableData([...SalesTablesTableData, {}]);
  };
  
  const deleteRow = () => {
    if (SalesTablesTableData.length > 0) {
      const updatedData = [...SalesTablesTableData];
      updatedData.pop();
      setSalesTablesTableData(updatedData);
    }
  };
const isUpdateMode = Boolean(stUkNo);
  const productDialogFooter = (
    <React.Fragment>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-times" 
    variant="outlined" 
    onClick={handleClose}
    tooltip="Cancel"
    >
    </Button>
    <Button 
    style={{width:"5%"}}
    icon="pi pi-check" 
    onClick={handleFormSubmit} 
     tooltip="Save">
    </Button>
  </React.Fragment>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={{ width: "70rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={stUkNo ? "Revise Sales Tables" : "Add Sales Tables"}
      modal
      className="p-fluid"
      footer={productDialogFooter}
    >
      <div
        style={{
          border: "1px solid #e8e8e8",
          padding: "16px",
          borderRadius: "15px",
          backgroundColor: "aliceblue",
        }}
      >
         <div className="formgrid grid">
         <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
          <label htmlFor="st_CO_CT" className="font-bold">
            Cost Centre
          </label>
          <Dropdown
            name="st_CO_CT"
            value={st_CO_CT}
            options={st_CO_CTOptions}
            onChange={(e) => onchangeDropDownFormData1("st_CO_CT", e.value)}
            placeholder="Select Cost Centre"
          />
          </div>
          <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="st_BL_CK" className="font-bold">
            Block
            </label>
            <Dropdown
              name="st_BL_CK"
              value={st_BL_CK}
              options={st_BL_CKOptions}
              onChange={(e) => onchangeDropDownFormData2("st_BL_CK", e.value)}
              placeholder="Select Block"
            />
          </div>
            <div className="field col-lg-4 col-md-6 col-12 pe-md-2">
            <label htmlFor="st_CO_NO" className="font-bold">
            Company
            </label>
              <InputText disabled
                id="st_CO_NO"
                name="st_CO_NO"
                value={st_CO_NO}
                onChange={(Event) => onchangedata(Event)}
              />
            </div>
          </div>
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="SalesTablesTable" style={{overflow:"auto"}}>
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Table No</th>
              <th>Description </th>
              <th>No Of Seats</th>
            </tr>
          </thead>
          <tbody>
            {SalesTablesTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`stUkNo_${index}`}
                    name={`stUkNo_${index}`}
                    value={row.stUkNo}
                  />
                </td> 
                <td>
                   <InputText
                      id={`st_TB_NO_${index}`}
                      name={`st_TB_NO_${index}`}
                      defaultValue={row.st_TB_NO}
                      onChange={(e) => onchangedata(e)}
                    />
                </td>
                  <td>
                  <InputText
                    id={`st_TB_DS_${index}`}
                    name={`st_TB_DS_${index}`}
                    defaultValue={row.st_TB_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
               
                <td>
                  <InputText
                    id={`st_NO_ST_${index}`}
                    name={`st_NO_ST_${index}`}
                    defaultValue={row.st_NO_ST}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </Dialog>
  );
}
