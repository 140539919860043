import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import PostInvoiceDialog from "./PostInvoiceDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  clUkNo:"",
  cl_DO_NO:"",
  cl_DO_TY:"",
  cl_DO_CO:"",
  cl_DO_PI:"",
  cl_CU_NO:"",
  name: "",
  cl_GL_DT: "",
  cl_IN_DT: "",
  cl_BA_TY:"",
  cl_BA_NO:"",
  cl_BA_DT:"",
  cl_GL_CO:"",
  cl_PY_NO: "",
  cl_GL_PO: "",
  cl_PA_ST: "",
  cl_GR_AM: "",
  cl_OP_AM: "",
  cl_DI_AM: "",
  cl_CR_CD: "",
  cl_CR_RT: "",
  cl_CR_AM: "",
  cl_OP_FR: "",
  cl_DI_FR: "",
  cl_CO_NM: "",
  cl_SB_LT: "",
  cl_SB_LE: "",
  cl_PY_CO: "",
  cl_DD_DT: "",
  cl_OR_NO: "",
  cl_OR_TY: "",
  cl_OR_CO: "",
  cl_RE_FR: "",
  cl_UN_QT: "",
  cl_CR_MO: "",
  cl_SU_IN: "",
  cl_VD_DT: "",
  cl_VD_FL: "",
  cl_ST_CD: "",
};

const initialFilters = {
  cl_DO_NO: { value: "", operation: "" },
  cl_DO_TY: { value: "", operation: "" },
  cl_DO_CO: { value: "", operation: "" },
  cl_DO_PI: { value: "", operation: "" },
  cl_CU_NO: { value: "", operation: "" },
      name: { value: "", operation: "" },
  cl_GL_DT: { value: "", operation: "" },
  cl_IN_DT: { value: "", operation: "" },
  cl_BA_TY: { value: "", operation: "" },
  cl_BA_NO: { value: "", operation: "" },
  cl_BA_DT: { value: "", operation: "" },
  cl_GL_CO: { value: "", operation: "" },
  cl_PY_NO: { value: "", operation: "" },
  cl_GL_PO: { value: "", operation: "" },
  cl_PA_ST: { value: "", operation: "" },
  cl_GR_AM: { value: "", operation: "" },
  cl_OP_AM: { value: "", operation: "" },
  cl_DI_AM: { value: "", operation: "" },
  cl_CR_CD: { value: "", operation: "" },
  cl_CR_RT: { value: "", operation: "" },
  cl_CR_AM: { value: "", operation: "" },
  cl_OP_FR: { value: "", operation: "" },
  cl_DI_FR: { value: "", operation: "" },
  cl_CO_NM: { value: "", operation: "" },
  cl_SB_LT: { value: "", operation: "" },
  cl_SB_LE: { value: "", operation: "" },
  cl_PY_CO: { value: "", operation: "" },
  cl_DD_DT: { value: "", operation: "" },
  cl_OR_NO: { value: "", operation: "" },
  cl_OR_TY: { value: "", operation: "" },
  cl_OR_CO: { value: "", operation: "" },
  cl_RE_FR: { value: "", operation: "" },
  cl_UN_QT: { value: "", operation: "" },
  cl_CR_MO: { value: "", operation: "" },
  cl_SU_IN: { value: "", operation: "" },
  cl_VD_DT: { value: "", operation: "" },
  cl_VD_FL: { value: "", operation: "" },
  cl_ST_CD: { value: "", operation: "" },
};

const PostInvoiceMain = () => {
      // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [cl_CO_NM, setcl_CO_NM] = useState("");
  const [cl_CR_CD, setcl_CR_CD] = useState("");
  const [cl_CU_NO, setcl_CU_NO] = useState("");
  const [cl_PY_CO, setcl_PY_CO] = useState("");
  const [rc_CO_LV, setrc_CO_LV] = useState("");
  const [rc_CS_PR, setrc_CS_PR] = useState("");
  const [rc_GR_DE, setrc_GR_DE] = useState("");
  const [rc_DL_TY, setrc_DL_TY] = useState("");
  const [rc_TR_RE, setrc_TR_RE] = useState("");
  const [PostInvoicetable, setPostInvoicetable] = useState([{}]);
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");

  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setPostInvoicetable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setcl_CO_NM(null);
    setcl_CR_CD(null);
    setcl_PY_CO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setPostInvoicetable([...PostInvoicetable, {}]);
  };
  const deleteRow = () => {
    if (PostInvoicetable.length > 0) {
      const updatedData = [...PostInvoicetable];
      updatedData.pop();
      setPostInvoicetable(updatedData);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/PostInvoice/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };

  const onchangeFormData = (name, value) => {
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setcl_CO_NM(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setcl_CR_CD(value);
};
const onchangeDropDownFormData5 = (name, value) => {
  setFormData({ ...FormData, [name]: value });
  setcl_PY_CO(value);
  
};
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/PostInvoice/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const PostInvoiceData = response.data;
        setPostInvoicetable(PostInvoiceData.customerLedgerList);
        setFormData(PostInvoiceData.customerLedgerList[0]);
        setSelectedemEMNO(PostInvoiceData.customerLedgerList[0].cl_CU_NO);
        setSelectedemEMNOE(PostInvoiceData.customerLedgerList[0].name); 
        setcl_CO_NM(PostInvoiceData.cl_CO_NM);
        setcl_CR_CD(PostInvoiceData.cl_CR_CD);
        setcl_PY_CO(PostInvoiceData.customerLedgerList[0].cl_PY_CO);
        setcl_CU_NO(response.data.cl_CU_NO);
        setrc_CO_LV(response.data.rc_CO_LV);
        setrc_CS_PR(response.data.rc_CS_PR);
        setrc_GR_DE(response.data.rc_GR_DE);
        setrc_DL_TY(response.data.rc_DL_TY);
        setrc_TR_RE(response.data.rc_TR_RE);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = async () => {
    const Url1 = `${domain}/api/PostInvoice/PostReceiptsToGAGL`;
    const Url2 = `${domain}/api/PostInvoice/PostReceiptsToGABL`;
    const request = {
      "clUkNo": FormData.clUkNo,
    };
  
    try {
      const response1 = await axios.post(Url1, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response1.data);

      const response2 = await axios.post(Url2, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response2.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  const inputStyleH = {
    border: "6px solid #a7d6ff8c",
    textAlign: "left",
    borderRadius: "4px",
   // width:"100%",
    backgroundColor:"#f5f4f4"
  };
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_NO"] ? filters["cl_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_NO",
                        e.target.value,
                        filters["cl_DO_NO"]
                          ? filters["cl_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_TY"] ? filters["cl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_TY",
                        e.target.value,
                        filters["cl_DO_TY"]
                          ? filters["cl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_CO"] ? filters["cl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_CO",
                        e.target.value,
                        filters["cl_DO_CO"]
                          ? filters["cl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DO_PI"] ? filters["cl_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DO_PI",
                        e.target.value,
                        filters["cl_DO_PI"]
                          ? filters["cl_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["cl_CU_NO"] ? filters["cl_CU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CU_NO",
                        e.target.value,
                        filters["cl_CU_NO"]
                          ? filters["cl_CU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyleH}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GL_DT"] ? filters["cl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GL_DT",
                        e.target.value,
                        filters["cl_GL_DT"]
                          ? filters["cl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_IN_DT"] ? filters["cl_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_IN_DT",
                        e.target.value,
                        filters["cl_IN_DT"]
                          ? filters["cl_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_TY"] ? filters["cl_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_TY",
                        e.target.value,
                        filters["cl_BA_TY"]
                          ? filters["cl_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_NO"] ? filters["cl_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_NO",
                        e.target.value,
                        filters["cl_BA_NO"]
                          ? filters["cl_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_BA_DT"] ? filters["cl_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_BA_DT",
                        e.target.value,
                        filters["cl_BA_DT"]
                          ? filters["cl_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GL_CO"] ? filters["cl_GL_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GL_CO",
                        e.target.value,
                        filters["cl_GL_CO"]
                          ? filters["cl_GL_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PY_NO"] ? filters["cl_PY_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PY_NO",
                        e.target.value,
                        filters["cl_PY_NO"]
                          ? filters["cl_PY_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GL_PO"] ? filters["cl_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GL_PO",
                        e.target.value,
                        filters["cl_GL_PO"]
                          ? filters["cl_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PA_ST"] ? filters["cl_PA_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PA_ST",
                        e.target.value,
                        filters["cl_PA_ST"]
                          ? filters["cl_PA_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_GR_AM"] ? filters["cl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_GR_AM",
                        e.target.value,
                        filters["cl_GR_AM"]
                          ? filters["cl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OP_AM"] ? filters["cl_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OP_AM",
                        e.target.value,
                        filters["cl_OP_AM"]
                          ? filters["cl_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DI_AM"] ? filters["cl_DI_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DI_AM",
                        e.target.value,
                        filters["cl_DI_AM"]
                          ? filters["cl_DI_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_CD"] ? filters["cl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_CD",
                        e.target.value,
                        filters["cl_CR_CD"]
                          ? filters["cl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_RT"] ? filters["cl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_RT",
                        e.target.value,
                        filters["cl_CR_RT"]
                          ? filters["cl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_AM"] ? filters["cl_CR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_AM",
                        e.target.value,
                        filters["cl_CR_AM"]
                          ? filters["cl_CR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>


                <td>
                  <input
                    type="text"
                    value={filters["cl_OP_FR"] ? filters["cl_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OP_FR",
                        e.target.value,
                        filters["cl_OP_FR"]
                          ? filters["cl_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DI_FR"] ? filters["cl_DI_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DI_FR",
                        e.target.value,
                        filters["cl_DI_FR"]
                          ? filters["cl_DI_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CO_NM"] ? filters["cl_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CO_NM",
                        e.target.value,
                        filters["cl_CO_NM"]
                          ? filters["cl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_SB_LT"] ? filters["cl_SB_LT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_SB_LT",
                        e.target.value,
                        filters["cl_SB_LT"]
                          ? filters["cl_SB_LT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_SB_LE"] ? filters["cl_SB_LE"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_SB_LE",
                        e.target.value,
                        filters["cl_SB_LE"]
                          ? filters["cl_SB_LE"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_PY_CO"] ? filters["cl_PY_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_PY_CO",
                        e.target.value,
                        filters["cl_PY_CO"]
                          ? filters["cl_PY_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_DD_DT"] ? filters["cl_DD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_DD_DT",
                        e.target.value,
                        filters["cl_DD_DT"]
                          ? filters["cl_DD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_NO"] ? filters["cl_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_NO",
                        e.target.value,
                        filters["cl_OR_NO"]
                          ? filters["cl_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_TY"] ? filters["cl_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_TY",
                        e.target.value,
                        filters["cl_OR_TY"]
                          ? filters["cl_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_OR_CO"] ? filters["cl_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_OR_CO",
                        e.target.value,
                        filters["cl_OR_CO"]
                          ? filters["cl_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_RE_FR"] ? filters["cl_RE_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_RE_FR",
                        e.target.value,
                        filters["cl_RE_FR"]
                          ? filters["cl_RE_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_UN_QT"] ? filters["cl_UN_QT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_UN_QT",
                        e.target.value,
                        filters["cl_UN_QT"]
                          ? filters["cl_UN_QT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_CR_MO"] ? filters["cl_CR_MO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_CR_MO",
                        e.target.value,
                        filters["cl_CR_MO"]
                          ? filters["cl_CR_MO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_SU_IN"] ? filters["cl_SU_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_SU_IN",
                        e.target.value,
                        filters["cl_SU_IN"]
                          ? filters["cl_SU_IN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_VD_DT"] ? filters["cl_VD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_VD_DT",
                        e.target.value,
                        filters["cl_VD_DT"]
                          ? filters["cl_VD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_VD_FL"] ? filters["cl_VD_FL"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_VD_FL",
                        e.target.value,
                        filters["cl_VD_FL"]
                          ? filters["cl_VD_FL"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["cl_ST_CD"] ? filters["cl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "cl_ST_CD",
                        e.target.value,
                        filters["cl_ST_CD"]
                          ? filters["cl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Document No</th>
                <th style={tableHeaderStyle}>Document Type</th>
                <th style={tableHeaderStyle}>Document Company</th>
                <th style={tableHeaderStyle}>Pay Item</th>
                <th style={tableHeaderStyle}>Customer No</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>GL Date</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Batch No</th>
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>G/L Code</th>
                <th style={tableHeaderStyle}>Payor No</th>
                <th style={tableHeaderStyle}>G/L Post Code</th>
                <th style={tableHeaderStyle}>Pay Status</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Discount Amount</th>
                <th style={tableHeaderStyle}>Currency Code</th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Foreign Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign open Amount</th>
                <th style={tableHeaderStyle}>Foreign Discount Amount</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Subledger Type</th>
                <th style={tableHeaderStyle}>Subledger No</th>
                <th style={tableHeaderStyle}>Payment Terms</th>
                <th style={tableHeaderStyle}>Due Date</th>
                <th style={tableHeaderStyle}>Sales Doc No</th>
                <th style={tableHeaderStyle}>Sales Doc Type</th>
                <th style={tableHeaderStyle}>Sales Doc Company</th>
                <th style={tableHeaderStyle}>Reference</th>
                <th style={tableHeaderStyle}>Units</th>
                <th style={tableHeaderStyle}>Currency Mode</th>
                <th style={tableHeaderStyle}>Customer PO</th>
                <th style={tableHeaderStyle}>Void Date</th>
                <th style={tableHeaderStyle}>Void Flag</th>
                <th style={tableHeaderStyle}>Status</th>
                
           
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.clUkNo}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.clUkNo}
                        checked={selectedRows.includes(rowData.clUkNo)}
                        onChange={() => handleRowSelect(rowData.clUkNo)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.cl_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_DO_PI}</td>
                    <td style={tableCellStyle}>{rowData.cl_CU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_GL_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.cl_IN_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cl_BA_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_BA_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_BA_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cl_GL_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_PY_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.cl_PA_ST}</td>
                    <td style={tableCellStyle}>{rowData.cl_GR_AM}</td>
                    <td style={tableCellStyle}>{rowData.cl_OP_AM}</td>
                    <td style={tableCellStyle}>{rowData.cl_DI_AM}</td>
                    <td style={tableCellStyle}>{rowData.cl_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.cl_CR_RT}</td>
                    <td style={tableCellStyle}>{rowData.cl_CR_AM}</td>
                    <td style={tableCellStyle}>{rowData.cl_OP_FR}</td>
                    <td style={tableCellStyle}>{rowData.cl_DI_FR}</td>
                    <td style={tableCellStyle}>{rowData.cl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.cl_SB_LT}</td>
                    <td style={tableCellStyle}>{rowData.cl_SB_LE}</td>
                    <td style={tableCellStyle}>{rowData.cl_PY_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.cl_DD_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.cl_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.cl_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.cl_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.cl_RE_FR}</td>
                    <td style={tableCellStyle}>{rowData.cl_UN_QT}</td>
                    <td style={tableCellStyle}>{rowData.cl_CR_MO}</td>
                    <td style={tableCellStyle}>{rowData.cl_SU_IN}</td>
                    <td style={tableCellStyle}>{rowData.cl_VD_DT}</td>
                    <td style={tableCellStyle}>{rowData.cl_VD_FL}</td>
                    <td style={tableCellStyle}>{rowData.cl_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <PostInvoiceDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        cl_CU_NO={cl_CU_NO}
        cl_CO_NM={cl_CO_NM}
        rc_CO_LV={rc_CO_LV}
        rc_CS_PR={rc_CS_PR}
        rc_GR_DE={rc_GR_DE}
        rc_DL_TY={rc_DL_TY}
        rc_TR_RE={rc_TR_RE}
        cl_CR_CD={cl_CR_CD}
        cl_PY_CO={cl_PY_CO}
        setcl_PY_CO={setcl_PY_CO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        PostInvoicetable={PostInvoicetable} 
        SelectedemEMNOE={SelectedemEMNOE}
        setSelectedemEMNOE={setSelectedemEMNOE}
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default PostInvoiceMain;
