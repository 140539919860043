import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ItemPopup from "../Popups/ItemPopup";
import ClassCode from "views/common/ClassCode";
import { Dropdown } from "primereact/dropdown";
import ButtonStyles from "views/common/ButtonStyles";
import { Calendar } from "primereact/calendar";
import moment from "moment";
export default function BasePriceItemDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  BasePricedata,
  setBasePricedata,
  bp_CR_CD,
  setbp_CR_CD, 
  bp_PR_UM,
  setbp_PR_UM,
  bp_TR_CO,
}) {
  const {
  bpUkNO,
  bp_IT_NM,
  bp_IT_DS,  
  bp_UN_PR,   
  bp_CR_PR,
  bp_EX_MR,
  bp_AL_DS,
  bp_FR_DT,
  bp_TO_DT,
  bp_ST_CD,   
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,
    tablestyle,multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const addRow = () => {
    setBasePricedata([...BasePricedata, {}]);
  };
  
  const deleteRow = () => {
    if (BasePricedata.length > 0) {
      const updatedData = [...BasePricedata];
      updatedData.pop();
      setBasePricedata(updatedData);
    }
  };
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };

  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [ItemVisible, setItemVisible] = useState(false);

  const openItemPopup = (e) => {
    setItemVisible(true);
    setSelectedRowIndex(e);
  };

  const handleItem =async (data) => {
    document.getElementById(`bp_IT_NM_${SelectedRowIndex}`).value = data.im_IT_NM;
    document.getElementById(`item_DES_${SelectedRowIndex}`).value = data.im_IT_DS;
    setItemVisible(false);
    setShowSearchIcon(false);
  };
  //dropdown
  const { fetchClassCodeDS, fetchClassCodeCD } = ClassCode();
  const [bp_CR_CDOptions, setbp_CR_CDOptions] = useState([]);
  const [bp_PR_UMOptions, setbp_PR_UMOptions] = useState([]);
  

//type
  const onchangeDropDownFormData = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setbp_CR_CD(value);   
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));   
    setbp_PR_UM(value);
  };
  
  useEffect(() => {
    fetchDropdownBT();
    fetchDropdownBTPRUM();

  }, []);

  const fetchDropdownBT = async () => {
    const Options = await fetchClassCodeCD("FO/CR");
    setbp_CR_CDOptions(Options);   
  };

  const fetchDropdownBTPRUM = async () => {    
    const Options = await fetchClassCodeCD("IN/UM");
    setbp_PR_UMOptions(Options);
  };
  const isUpdateMode = Boolean(bpUkNO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{bpUkNO ? "Revise Item Base Price" : "Add Item Base Price"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="BasePrice">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Item Number <span style={{color:'red'}}>*</span> </th>
              <th>Description</th>
              <th>Currency</th>
              <th>Unit Price</th>
              <th>UM</th>
              <th>Unit Cost</th>
              <th>Margin %</th>
              <th>Discount %</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>Status</th>
              <th>Company</th>              
            </tr>
          </thead>
          <tbody>
            {BasePricedata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`bpUkNO_${index}`}
                    name={`bpUkNO_${index}`}
                    value={row.bpUkNO}
                  />
                </td>
                <td style={{ display: "flex" }}>
              <InputText 
              disabled={!!bpUkNO}
              style={{ width: "110px" }}
              id={`bp_IT_NM_${index}`}
              name={`bp_IT_NM_${index}`}
              onChange={(e) => onchangeFormData(e)}
              onFocus={() => setShowSearchIcon(true)}
              defaultValue={row.bp_IT_NM ? row.bp_IT_NM: row[`${"bp_IT_NM"}_${index}`]}
              />
              {showSearchIcon && (
            <i
            className="pi pi-search"
            style={{
            color: "var(--primary-color)",
            fontSize: "119%",
            marginTop: "15%",
            cursor: "pointer",
            }}
            onClick={(e) => openItemPopup(index)}
            ></i>
            )}
                </td> 
                <td>
                <InputText disabled
                style={{ width: "200px" }}
                id={`item_DES_${index}`}
                name={`item_DES_${index}`}
                defaultValue={row.item_DES ? row.item_DES : row[`${"item_DES"}_${index}`]}
                />
                </td>
                <td>                        
                <Dropdown 
                  id={`bp_CR_CD_${index}`}
                  name={`bp_CR_CD_${index}`}
                  value={data[`bp_CR_CD_${index}`] ? data[`bp_CR_CD_${index}`] : row.bp_CR_CD}
                  options={bp_CR_CDOptions}
                  onChange={(e) => onchangeDropDownFormData(`bp_CR_CD_${index}`, e.value)}
                  placeholder="Currency"
                />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_UN_PR_${index}`}
                    name={`bp_UN_PR_${index}`}
                    defaultValue={row.bp_UN_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>               
                <td>                        
                  <Dropdown 
                    id={`bp_PR_UM_${index}`}
                    name={`bp_PR_UM_${index}`}
                    value={data[`bp_PR_UM_${index}`] ? data[`bp_PR_UM_${index}`] : row.bp_PR_UM}
                    options={bp_PR_UMOptions}
                    onChange={(e) => onchangeDropDownFormData1(`bp_PR_UM_${index}`, e.value)}
                    placeholder="UM"
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_CR_PR_${index}`}
                    name={`bp_CR_PR_${index}`}
                    defaultValue={row.bp_CR_PR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_EX_MR_${index}`}
                    name={`bp_EX_MR_${index}`}
                    defaultValue={row.bp_EX_MR}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_AL_DS_${index}`}
                    name={`bp_AL_DS_${index}`}
                    defaultValue={row.bp_AL_DS}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`bp_FR_DT_${index}`}
                    name={`bp_FR_DT_${index}`}
                    value={ row.bp_FR_DT ? moment(row.bp_FR_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "bp_FR_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                  <Calendar style={{width:"150px"}}
                    id={`bp_TO_DT_${index}`}
                    name={`bp_TO_DT_${index}`}
                    value={ row.bp_TO_DT ? moment(row.bp_TO_DT, "YYYYMMDD").toDate() : null }
                    onChange={(e) => onDateChange({ target: { name: "bp_TO_DT", value: moment(e.value).format("YYYYMMDD") } })}
                    dateFormat="dd/mm/yy"
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}}
                    id={`bp_ST_CD_${index}`}
                    name={`bp_ST_CD_${index}`}
                    defaultValue={row.bp_ST_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                <InputText style={{width:"100px"}} disabled
                    id={`bp_TR_CO_${index}`}
                    name={`bp_TR_CO_${index}`}
                    defaultValue={bp_TR_CO}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div> 
      </div>
       {/* item */}
    <ItemPopup
        visible={ItemVisible}
        onClose={() => setItemVisible(false)}
        onDataSelect={handleItem}
        formData={data}
      />
    </Dialog>
  );
}
