import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import UpdateARDialog from "./UpdateARDialog";
import axios from "axios";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";

const initialValue = {
  sdOrNo: "",
  sdOrTy: "",
  sd_OR_CO: "",
  sd_UK_NO:"",
  sd_LN_NO:"",
  sd_IT_NM:"",
  sd_LO_CN:"",
  sd_LO_NO:"",
  sd_ST_CD:"",
  sd_UT_PR: "",
  sd_EX_PR: "",
  sd_DO_NO: "",
  sd_DO_TY:"",
  sd_DO_CO:"",
  sd_IN_DT:"",
};
const initialFilters = {
  sdOrNo: { value: "", operation: "" },
  sdOrTy: { value: "", operation: "" },
  sd_OR_CO: { value: "", operation: "" },
  sd_LN_NO: { value: "", operation: "" },
  sd_IT_NM: { value: "", operation: "" },
  sd_LO_CN: { value: "", operation: "" },
  sd_LO_NO: { value: "", operation: "" },
  sd_ST_CD: { value: "", operation: "" },
  sd_UT_PR: { value: "", operation: "" },
  sd_EX_PR: { value: "", operation: "" },
  sd_DO_NO: { value: "", operation: "" },
  sd_DO_TY: { value: "", operation: "" },
  sd_DO_CO: { value: "", operation: "" },
  sd_IN_DT: { value: "", operation: "" },
};
const UpdateARMain = () => {
      // buttonstyles
const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [SelectedemEMNOE, setSelectedemEMNOE] = useState("");
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [sh_CU_NO, setsh_CU_NO] = useState("");
  const [UpdateARtable, setUpdateARtable] = useState([{}]);
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => ({
        column: column.toUpperCase(),
        value: filters[column].value,
        operation: filters[column].operation,
      }));
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setUpdateARtable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setsh_CU_NO(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  };
  const addRow = () => {
    setUpdateARtable([...UpdateARtable, {}]);
  };
  const deleteRow = () => {
    if (UpdateARtable.length > 0) {
      const updatedData = [...UpdateARtable];
      updatedData.pop();
      setUpdateARtable(updatedData);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
          
          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/InvoiceGeneration/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData({ ...FormData, [name]: value });
  };
  const onchangeFormData = (name, value ,index) => {
    setFormData({ ...FormData, [name]: value });
    // const fieldName=(FormData && FormData.sd_UK_NO)?`${name}`:`${name}_${index}`;
    // if(index!=null)
    // UpdateARtable[index][fieldName]=value;
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData((prevData) => ({ ...prevData,[name]: value,}));
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);
  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };
  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    try {
      const response = await axios.get(
        `${domain}/api/InvoiceGeneration/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const UpdateARData = response.data;
        setFormData(UpdateARData.salesHeader);
        setUpdateARtable(UpdateARData.salesDetail);
        setSelectedemEMNO(UpdateARData.salesHeader.sh_CU_NO); 
        // setSelectedemEMNO1(UpdateARData.salesHeader.shipto_NAME); 
        setSelectedemEMNOE(UpdateARData.salesHeader.cus_NAME); 
        setsh_CU_NO(UpdateARData.salesHeader.sh_CU_NO);
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleFormSubmit = async () => {
    const Url = `${domain}/api/InvoiceGeneration/PostInvoice`;
    const request = {
        "sd_GL_DT": FormData.sd_GL_DT,
    };
    try {
      const response = await axios.post(Url, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      handleResponse(response.data);
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
     
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px",backgroundColor:"#1ed01e",border:"none" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );
  return (
    <div className="tableData" style={{ marginTop: "5%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td style={{width:'40px'}}>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrNo"] ? filters["sdOrNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrNo",
                        e.target.value,
                        filters["sdOrNo"]
                          ? filters["sdOrNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sdOrTy"] ? filters["sdOrTy"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sdOrTy",
                        e.target.value,
                        filters["sdOrTy"]
                          ? filters["sdOrTy"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_OR_CO"] ? filters["sd_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_OR_CO",
                        e.target.value,
                        filters["sd_OR_CO"]
                          ? filters["sd_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_LN_NO"] ? filters["sd_LN_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LN_NO",
                        e.target.value,
                        filters["sd_LN_NO"]
                          ? filters["sd_LN_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["sd_IT_NM"] ? filters["sd_IT_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IT_NM",
                        e.target.value,
                        filters["sd_IT_NM"]
                          ? filters["sd_IT_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_LO_CN"] ? filters["sd_LO_CN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LO_CN",
                        e.target.value,
                        filters["sd_LO_CN"]
                          ? filters["sd_LO_CN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_LO_NO"] ? filters["sd_LO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_LO_NO",
                        e.target.value,
                        filters["sd_LO_NO"]
                          ? filters["sd_LO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_ST_CD"] ? filters["sd_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_ST_CD",
                        e.target.value,
                        filters["sd_ST_CD"]
                          ? filters["sd_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_UT_PR"] ? filters["sd_UT_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_UT_PR",
                        e.target.value,
                        filters["sd_UT_PR"]
                          ? filters["sd_UT_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_EX_PR"] ? filters["sd_EX_PR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_EX_PR",
                        e.target.value,
                        filters["sd_EX_PR"]
                          ? filters["sd_EX_PR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_DO_NO"] ? filters["sd_DO_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_DO_NO",
                        e.target.value,
                        filters["sd_DO_NO"]
                          ? filters["sd_DO_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_DO_TY"] ? filters["sd_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_DO_TY",
                        e.target.value,
                        filters["sd_DO_TY"]
                          ? filters["sd_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_DO_CO"] ? filters["sd_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_DO_CO",
                        e.target.value,
                        filters["sd_DO_CO"]
                          ? filters["sd_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sd_IN_DT"] ? filters["sd_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sd_IN_DT",
                        e.target.value,
                        filters["sd_IN_DT"]
                          ? filters["sd_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Order No</th>
                <th style={tableHeaderStyle}>Order Type</th>
                <th style={tableHeaderStyle}>Order Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Item Name</th>
                <th style={tableHeaderStyle}>Location</th>
                <th style={tableHeaderStyle}>Lot No</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Unit Price</th>
                <th style={tableHeaderStyle}>Price Amount</th>
                <th style={tableHeaderStyle}>Invoice No</th>
                <th style={tableHeaderStyle}>Invoice type</th>
                <th style={tableHeaderStyle}>Invoice Company</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
              
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sd_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sd_UK_NO}
                        checked={selectedRows.includes(rowData.sd_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sd_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.sdOrNo}</td>
                    <td style={tableCellStyle}>{rowData.sdOrTy}</td>
                    <td style={tableCellStyle}>{rowData.sd_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sd_LN_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_IT_NM}</td>
                    <td style={tableCellStyle}>{rowData.sd_LO_CN}</td>
                    <td style={tableCellStyle}>{rowData.sd_LO_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_ST_CD}</td>
                    <td style={tableCellStyle}>{rowData.sd_UT_PR}</td>
                    <td style={tableCellStyle}>{rowData.sd_EX_PR}</td>
                    <td style={tableCellStyle}>{rowData.sd_DO_NO}</td>
                    <td style={tableCellStyle}>{rowData.sd_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.sd_DO_CO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sd_IN_DT)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

       {/* pagination */}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>

      <UpdateARDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        setFormData={setFormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        sh_CU_NO={sh_CU_NO}
        setsh_CU_NO={setsh_CU_NO}
        SelectedemEMNO={SelectedemEMNO}
        SelectedemEMNOE={SelectedemEMNOE}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO={setSelectedemEMNO}
        setSelectedemEMNOE={setSelectedemEMNOE}
        setSelectedemEMNO1={setSelectedemEMNO1}
        UpdateARtable={UpdateARtable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
  
      />
    </div>
  );
};

export default UpdateARMain;
