import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClassCode from "views/common/ClassCode";
import Costcenter from "views/common/CostCentreDrpDwn";
import ButtonStyles from "views/common/ButtonStyles";
export default function ApprovalSetupDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  ApprovalSetupTableData,
  setApprovalSetupTableData,
  ap_OR_TY,
  setap_OR_TY,
  ap_RT_CD,
  setap_RT_CD,
  ap_AP_LV,
  setap_AP_LV,
  ap_AP_TY,
  setap_AP_TY,
  ap_RL_ST,
  setap_RL_ST,
  selectedSupplier,
  setselectedSupplier,
  ep_CO_NO,
  SelectedemEMNO,
  setSelectedemEMNO,
  ap_CO_CT,
  setap_CO_CT,
}) {
  const { apUkNo, ep_EF_DT, } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // Dropdown
  const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();

  const { fetchCostCenter,  } = Costcenter();
  const [ap_RT_CDOptions, setap_RT_CDOptions] = useState([]);
  const [ap_OR_TYOptions, setap_OR_TYOptions] = useState([]);
  const [ap_CO_CTOptions, setap_CO_CTOptions] = useState([]);
  const [ap_AP_LVOptions, setap_AP_LVOptions] = useState([]);
  const [ap_AP_TYOptions, setap_AP_TYOptions] = useState([]);
  const [ap_RL_STOptions, setap_RL_STOptions] = useState([]);
  // Route Code
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setap_RT_CD(value);
  };
  // ORDER TYPE
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    setap_OR_TY(value);
  };
 // Cost Centre
  const onchangeDropDownFormData3 = (name, value) => {
    const index = name.split("_")[3];
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setap_CO_CT(value);
  };
  // Approval Level
  const onchangeDropDownFormData4 = (name, value) => {
    const index = name.split("_")[3];
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setap_AP_LV(value);
  };
  // TYPE
  const onchangeDropDownFormData5 = (name, value) => {
    const index = name.split("_")[3];
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setap_AP_TY(value);
  };
  //Group
  const onchangeDropDownFormData6 = (name, value) => {
    const index = name.split("_")[3];
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
      setap_RL_ST(value);
  };
  useEffect(() => {
    fetchDropdown1();
    fetchDropdown2();
    fetchDropdown3();
    fetchDropdown4();
    fetchDropdown5();
    fetchDropdown6();
  }, []);
  const fetchDropdown1 = async () => {
    const Options = await fetchClassCodeDS("FO/HD");
    setap_RT_CDOptions(Options);
  };
  const fetchDropdown2 = async () => {
    const Options = await fetchClassCodeCD("FO/DT");
    setap_OR_TYOptions(Options);
  };
  const fetchDropdown3 = async () => {
    const Options = await fetchCostCenter();
    setap_CO_CTOptions(Options);
  };
  const fetchDropdown4 = async () => {
    const Options = await fetchClassCodeDS("AP/LV");
    setap_AP_LVOptions(Options);
  };
  const fetchDropdown5 = async () => {
    const Options = await fetchClassCodeDS("AP/TY");
    setap_AP_TYOptions(Options);
  };
  const fetchDropdown6 = async () => {
    const Options = await fetchClassCodeDS("AP/GP");
    setap_RL_STOptions(Options);
  };
  const addRow = () => {
    setApprovalSetupTableData([...ApprovalSetupTableData, {}]);
  };
  const deleteRow = () => {
    if (ApprovalSetupTableData.length > 0) {
      const updatedData = [...ApprovalSetupTableData];
      updatedData.pop();
      setApprovalSetupTableData(updatedData);
    }
  };

   const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  
  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false); 
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    document.getElementById(`ap_AP_VR_${SelectedRowIndex}`).value = data.abAdNo;
    // onchangeFormData(`ld_EM_NO_${SelectedRowIndex}`, data.abAdNo);
    // onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
    document.getElementById(`SelectedemEMNO_${SelectedRowIndex}`).value = data.ab_AD_NM;

    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };
  const isUpdateMode = Boolean(apUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{apUkNo ? "Revise Approval Setup" : "Add Approval Setup"}</h5>
      <div style={{width:'30rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox}>
          <div  style={inboxstyle}>
         <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="ap_RT_CD" className="font-bold">
            Route Code
            </label>
            <Dropdown
              name="ap_RT_CD"
              value={ap_RT_CD}
              options={ap_RT_CDOptions}
              onChange={(e) => onchangeDropDownFormData1("ap_RT_CD", e.value)}
              placeholder="Route Code"
            />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ap_OR_TY" className="font-bold">
              Order Type
              </label>
              <Dropdown
                name="ap_OR_TY"
                value={ap_OR_TY}
                options={ap_OR_TYOptions}
                onChange={(e) => onchangeDropDownFormData2("ap_OR_TY", e.value)}
                placeholder="Order Type"
              />
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ap_CO_CT" className="font-bold">
              Cost Centre
              </label>
              <Dropdown
                name="ap_CO_CT"
                value={ap_CO_CT}
                options={ap_CO_CTOptions}
                onChange={(e) => onchangeDropDownFormData3("ap_CO_CT", e.value)}
                placeholder="Cost Centre"
              />
          </div>
          </div>
          </div>  
          <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="ApprovalSetuptable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>Approval Level</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Group</th>
              <th style={{ display: "none" }}>Approver </th>
                <th>Approver <span style={{ color: "red" }}>*</span> </th>
            </tr>
          </thead>
          <tbody>
            {ApprovalSetupTableData.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`apUkNo_${index}`}
                    name={`apUkNo_${index}`}
                    value={row.apUkNo}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`ap_AP_LV_${index}`}
                    name={`ap_AP_LV_${index}`}
                    value={ data[`ap_AP_LV_${index}`] ? data[`ap_AP_LV_${index}`]: row.ap_AP_LV}
                    options={ap_AP_LVOptions}
                    onChange={(e) =>onchangeDropDownFormData4(`ap_AP_LV_${index}`, e.value)}
                    placeholder="Approval Level"
                  />
                  </td>
                  <td>
                  <Dropdown
                    id={`ap_AP_TY_${index}`}
                    name={`ap_AP_TY_${index}`}
                    value={ data[`ap_AP_TY_${index}`] ? data[`ap_AP_TY_${index}`]: row.ap_AP_TY}
                    options={ap_AP_TYOptions}
                    onChange={(e) =>onchangeDropDownFormData5(`ap_AP_TY_${index}`, e.value)}
                    placeholder="Type"
                  />
                  </td>
                  <td >
                  <InputText 
                    id={`ap_AL_AM_${index}`}
                    name={`ap_AL_AM_${index}`}
                    defaultValue={row.ap_AL_AM}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown
                    id={`ap_RL_ST_${index}`}
                    name={`ap_RL_ST_${index}`}
                    value={ data[`ap_RL_ST_${index}`] ? data[`ap_RL_ST_${index}`]: row.ap_RL_ST}
                    options={ap_RL_STOptions}
                    onChange={(e) =>onchangeDropDownFormData6(`ap_RL_ST_${index}`, e.value)}
                    placeholder="Select Location"
                  />
                  </td>
                  <td style={{ display: "none" }}>
                    <InputText
                      id={`ap_AP_VR_${index}`}
                      name={`ap_AP_VR_${index}`}
                      value={row.ap_AP_VR ? row.ap_AP_VR : row[`ap_AP_VR_${index}`]}
                      onChange={(e) => onchangedata(e)}
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex"}}>
                      <InputText
                        id={`SelectedemEMNO_${index}`}
                        name={`SelectedemEMNO_${index}`}
                        onChange={(e) => onchangedata(e)}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.SelectedemEMNO ? row.SelectedemEMNO : row[`SelectedemEMNO_${index}`] }
                      />
                      {showSearchIcon && (
                        <i
                          className="pi pi-search"
                          style={{
                            color: "var(--primary-color)",
                            fontSize: "119%",
                            marginTop: "7%",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openEmployeePopup(index)}
                        ></i>
                      )}
                    </div>
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
          </div>
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        formData={data}
      />
    </Dialog>
  );
}
