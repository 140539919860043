import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import SupplierPopup from "./SupplierPopup";
import AccountNoPopup from 'views/admin/Popups/AccountNoPopup';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
export default function PaymentDetailsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeReceivedate,
  ph_CR_CD,
  ph_CR_CO,
  ph_PD_TY,
  ph_DO_CO,
  addRow,
  deleteRow,
  setph_CR_CO,
  SelectedemEMNOA,
  setSelectedemEMNOA,
  PaymentDetailstable,
  setPaymentDetailstable,
  handleResponse,
  auto_POST,
  AutoPostDropdown
}) {
  const { 
  ph_UK_NO,
  ph_PA_AM,
  ph_PA_FR,
  ph_GL_DT,
  ph_PY_NO,
  ph_CR_RT,
  ph_CQ_NO,
  ph_RM_EX,
  ph_GL_BA,
  ph_AC_BL,
  name,
  ph_PM_NO,
  ph_PY_DT,
  pd_UK_NO,
  pd_PY_ID,
  pd_PA_AM,
  pd_DI_AM,
  pd_GR_AM,
  pd_DO_NO,
  pd_DO_TY,
  pd_DO_CO,
  pd_DO_PI,
  pd_PA_FR,
  pd_Di_FR,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,confirmstyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [auto_POSTOptions, setauto_POSTOptions] = useState([]);
  const [ph_CR_COOptions, setph_CR_COOptions] = useState([]);
  const [bankFilter, setbankFilter] = useState("BA");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const url = `${domain}/api/ClassCode/All`;
  const parameter1 = "FO/CR";
  const finalUrl1 = `${url}/${parameter1}`;
  const parameter3 =  "ME/PL";
  const finalUrl3 = `${url}/${parameter3}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions3();
  }, []);
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setauto_POSTOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  //Currency dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setph_CR_COOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);
  const openAccountPopup = (e) => {
    setAccountPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const handleDataSelect1 = async (data) => {
    setSelectedemEMNOA(data.am_AC_DS);
    onchangeFormData("ph_GL_BA", data.account);
    setAccountPopupVisible(false);
  };
  const [SupplierPopupVisible, setSupplierPopupVisible] = useState(false);
  const openSupplierPopup = (e) => {
    setSelectedRowsInvoices([]);
    setSupplierPopupVisible(true);
    setSelectedRowIndex(e);
  };
  const [selectedRowsInvoices, setSelectedRowsInvoices] = useState([]);
  const handleDataSelect = async() => {
    setPaymentDetailstable([{}]);
    const ledgerDtls = [];
    if(selectedRowsInvoices){
      const ukNos = selectedRowsInvoices.toString().split(",");
      const size = ukNos.length;
      const leng = size - 1;
      var cus = '';
      var cur = '';
      for (let i = 0; i < size; i++){
        try {
          const response = await axios.get(`${domain}/api/SupplierLedger/Selects/${ukNos[i]}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
              },
            }
          );

          if (response.status === 200) {
            const dataCuMt = response.data;
            if(i === 0){
              cus = dataCuMt.sl_SU_NO;
              cur = dataCuMt.sl_CR_CD;
            }
            if(cus !== dataCuMt.sl_SU_NO){
              const errors = {
                "errorMessage" : ["Invoices of Multiple Customers included."]
              }
              handleResponse(errors);
            } else if(cur !== dataCuMt.sl_CR_CD){
              const errors = {
                "errorMessage" : ["Invoices of Multiple Currencies included."]
              }
              handleResponse(errors);
            } else {
              onchangeFormData("ph_PY_NO", dataCuMt.sl_SU_NO);
              document.getElementById("ph_PY_NO").value =  dataCuMt.sl_SU_NO;
              document.getElementById("name").value =  dataCuMt.name;
              document.getElementById("ph_CR_RT").value =  dataCuMt.sl_CR_RT;
              setph_CR_CO(dataCuMt.sl_CR_CD);
              const dtls = {
                    "pd_DO_NO" : dataCuMt.slDoNo,
                    "pd_DO_TY" : dataCuMt.sl_DO_TY,
                    "pd_DO_CO" : dataCuMt.sl_DO_CO,
                    "pd_GR_AM" : dataCuMt.sl_GR_AM,
                    "pd_OP_AM" : dataCuMt.sl_OP_AM,
                    "pd_DO_PI" : dataCuMt.slDoPi
              } 
              ledgerDtls.push(dtls);
              if(i === leng){
                setPaymentDetailstable(ledgerDtls);
                setSupplierPopupVisible(false);
              }
            }
          } else {
            console.error(`API request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      
    } else {
      setPaymentDetailstable([{}]);
    }
  };
  const  urlcalc = `${domain}/api/PaymentEntry/GetCalculateHeaderAmt`
  const domescal = async (SelectedRowIndex) => {
  const requestbody ={
    "paymentEntry": {
      "ph_CR_RT": document.getElementById("ph_CR_RT").value,
    },
    "paymentDetailList": PaymentDetailstable.map((row, index) => ({
        "pd_PA_FR": document.getElementById(`pd_PA_FR_${index}`).value,
        "pd_PA_AM": document.getElementById(`pd_PA_AM_${index}`).value,
    })),
  };

  try {
      const response = await axios.post(urlcalc, requestbody, {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
      });
      const datacal = response.data.Result;
      if (datacal) {
        document.getElementById("ph_PA_AM").value = datacal.ph_PA_AM;
        document.getElementById("ph_PA_FR").value = datacal.ph_PA_FR;
        document.getElementById (`pd_PA_AM_${SelectedRowIndex}`).value =  datacal.ph_PA_AM; 
    
        let sum = 0;
        for (let i = 0; i < PaymentDetailstable.length; i++) {
            const pd_PA_AM = parseFloat(document.getElementById(`pd_PA_AM_${i}`).value) || 0;
            sum += pd_PA_AM;
        }
        document.getElementById("ph_PA_AM").value = sum;
    }
    
  } catch (error) {
      console.error("Error fetching dropdown options:", error);
  }
  };
// sum row fields
const [sumAmount, setSumAmount] = useState(0);
const calculateSumAmount = () => {
  let sum = 0;
  PaymentDetailstable.forEach((row) => {
    sum += parseFloat(row.pd_PA_AM) || 0;
  });
  return sum;
};

useEffect(() => {
  calculateSumAmount();
}, [PaymentDetailstable]);

const handleRowValueChange = (index, value) => {
  const updatedPaymentDetailstable = [...PaymentDetailstable];
  updatedPaymentDetailstable[index].pd_PA_AM = value;
  setPaymentDetailstable(updatedPaymentDetailstable);
  calculateSumAmount();
};
  const isUpdateMode = Boolean(ph_UK_NO);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{ph_UK_NO ? "Revise Payment Details" : "Add Payment Details"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={borderbox}> 
      <div style={inboxstyle} >
        <div className="formgrid grid">
         <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
            <label htmlFor="ph_UK_NO" className="font-bold">
              UKNO
            </label>
            <InputText
              id="ph_UK_NO"
              name="ph_UK_NO"
              value={ph_UK_NO}
              onChange={(e) => onchangedata(e)}
            />
          </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ph_PM_NO" className="font-bold">
             Payment No
              </label>
              <InputText disabled
                id="ph_PM_NO"
                name="ph_PM_NO"
                value={ph_PM_NO}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PD_TY" className="font-bold">
                  Type
                  </label>
                  <InputText disabled
                    id="ph_PD_TY"
                    name="ph_PD_TY"
                    value={ph_PD_TY}
                    onChange={(e) => onchangedata(e)}
                  />
                  
          </div>    
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_DO_CO" className="font-bold">
                  Company
                  </label>
                  <InputText disabled
                    id="ph_DO_CO"
                    name="ph_DO_CO"
                    value={ph_DO_CO}
                    onChange={(e) => onchangedata(e)}
                  />
          </div>    
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PY_DT" className="font-bold">
                 Payment Date
                  </label>
                  <Calendar
                    id="ph_PY_DT"
                    name="ph_PY_DT"
                    value={moment(data.ph_PY_DT, "YYYYMMDD").isValid() ? moment(data.ph_PY_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangeReceivedate({ target: {  name: "ph_PY_DT",value: moment(e.value).format("YYYYMMDD"),}, })  }
                    dateFormat="dd/mm/yy"
                  /> 
                </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2" style={{display:"none"}}>
                  <label htmlFor="ph_PY_NO" className="font-bold">
                  Supplier
                  </label>
                  <InputText
                    id="ph_PY_NO"
                    name="ph_PY_NO"
                    value={ph_PY_NO}
                    readOnly
                  />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="name" className="font-bold">
                  Supplier <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{display:"flex"}}>
                  <InputText
                    id="name"
                    name="name"
                    onChange={(e) => onchangedata(e)}
                    onFocus={() => setShowSearchIcon(true)}
                    defaultValue={name}
                  />
                  {showSearchIcon && (
                    <i
                      className="pi pi-search"
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "119%",
                        marginTop: "4%",
                        cursor: "pointer",
                      }}
                      onClick={openSupplierPopup}
                    ></i>
                   )}
            </div>
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_GL_DT" className="font-bold">
                  GL Date
                  </label>
                  <Calendar
                    id="ph_GL_DT"
                    name="ph_GL_DT"
                    value={moment(data.ph_GL_DT, "YYYYMMDD").isValid() ? moment(data.ph_GL_DT, "YYYYMMDD").toDate() : null}
                    onChange={(e) => onchangeReceivedate({ target: {  name: "ph_GL_DT",value: moment(e.value).format("YYYYMMDD"),}, })  }
                    dateFormat="dd/mm/yy"
                  />    
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2-lg-4 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PA_AM" className="font-bold">
                  Amount
                  </label>
                  <InputText disabled
                    id="ph_PA_AM"
                    name="ph_PA_AM"
                    defaultValue={sumAmount}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_PA_FR" className="font-bold">
                  Foreign Amount
                  </label>
                  <InputText disabled
                    id="ph_PA_FR"
                    name="ph_PA_FR"
                    defaultValue={ph_PA_FR}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="auto_POST" className="font-bold">
                    Auto Post
                  </label>
                  <Dropdown
                    id="auto_POST"
                    name="auto_POST"
                    value={auto_POST}
                    options={[
                      { label: "Yes", value: "y" },
                      { label: "No", value: "n" },
                      ]}
                    onChange={(e) => AutoPostDropdown("auto_POST", e.value)}
                    placeholder="Select Auto Post"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CR_CO" className="font-bold">
                  Currency
                  </label>
                  <Dropdown
                    id="ph_CR_CO"
                    name="ph_CR_CO"
                    value={ph_CR_CO}
                    options={ph_CR_COOptions}
                    onChange={(e) => onchangeDropDownFormData1("ph_CR_CO", e.value)}
                    placeholder="Select Currency"
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CR_RT" className="font-bold">
                  Exchange Rate
                  </label>
                  <InputText
                    id="ph_CR_RT"
                    name="ph_CR_RT"
                    value={ph_CR_RT}
                   readOnly
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CR_CD" className="font-bold">
                  Base Currency
                  </label>
                  <InputText disabled
                    id="ph_CR_CD"
                    name="ph_CR_CD"
                    value={ph_CR_CD}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_CQ_NO" className="font-bold">
                  NFT/Cheque
                  </label>
                  <InputText
                    id="ph_CQ_NO"
                    name="ph_CQ_NO"
                    defaultValue={ph_CQ_NO}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_RM_EX" className="font-bold">
                  Customer Bank
                  </label>
                  <InputText
                    id="ph_RM_EX"
                    name="ph_RM_EX"
                    value={ph_RM_EX}
                    onChange={(e) => onchangedata(e)}
                  />
                 
                </div>
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_GL_BA" className="font-bold">
                  Bank Account <span style={{ color: 'red' }}>*</span>
                  </label>
                  <div style={{ display: "flex" }}>
              <InputText
                id="accountDes"
                name="accountDes"
                onChange={(Event) => onchangedata(Event)}
                onFocus={() => setShowSearchIcon(true)}
                value={SelectedemEMNOA}
              />
               {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
              )}
            </div>
            <div style={{ display: "none" }}>
              <InputText
                id="ph_GL_BA"
                name="ph_GL_BA"
                value={ph_GL_BA}
                onChange={(Event) => onchangedata(Event)}
              />
              <i
                className="pi pi-search"
                style={{
                  color: "var(--primary-color)",
                  fontSize: "119%",
                  marginTop: "4%",
                  cursor: "pointer",
                }}
                onClick={openAccountPopup}
              ></i>
            </div>
                </div>
               
                <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="ph_AC_BL" className="font-bold">
                  Account Balance
                  </label>
                  <InputText
                    id="ph_AC_BL"
                    name="ph_AC_BL"
                    value={ph_AC_BL}
                    onChange={(e) => onchangedata(e)}
                  />
                </div>
                {/* <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
                  <label htmlFor="auto" className="font-bold">
                    Auto Post
                  </label>
                  <InputText
                    id="auto"
                    name="auto"
                    value={"Yes"}
                    disabled              
                  />
                </div> */}
        </div>
      </div>
        <div  style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
            <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
            )}
            {(
            <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="PaymentDetailstable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>          
          <th>Line No</th>
          <th>Payment ID</th>
          <th>Payment Amount</th>
          <th>Discount Amount</th>
          <th>Gross Amount</th>
          <th>Open Amount</th>
          <th>Doc No</th>
          <th>Doc Type</th>
          <th>Doc Co.</th>
          <th>Foreign Payment</th>
          <th>Foreign Discount</th>
        </tr>
      </thead>
      <tbody>
      {PaymentDetailstable.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`pd_UK_NO_${index}`}
              name={`pd_UK_NO_${index}`}
              defaultValue={row.pd_UK_NO}
            />
          </td>
          <td>
          <InputText disabled
            id={`pd_DO_PI_${index}`}
            name={`pd_DO_PI_${index}`}
            defaultValue={row.pd_DO_PI}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`pd_PY_ID_${index}`}
            name={`pd_PY_ID_${index}`}
            defaultValue={row.pd_PY_ID}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`pd_PA_AM_${index}`}
            name={`pd_PA_AM_${index}`}
            defaultValue={row.pd_PA_AM}
            disabled={ph_CR_CO!=ph_CR_CD}
            onChange={(Event) => {
              onchangedata(Event);
              domescal(index); 
            }}
          />
          </td>
          <td>
          <InputText 
            id={`pd_DI_AM_${index}`}
            name={`pd_DI_AM_${index}`}
            value={row.pd_DI_AM}
            disabled={ph_CR_CO!=ph_CR_CD}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          <td>
          <InputText 
            id={`pd_GR_AM_${index}`}
            name={`pd_GR_AM_${index}`}
            defaultValue={row.pd_GR_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText 
            id={`pd_OP_AM_${index}`}
            name={`pd_OP_AM_${index}`}
            defaultValue={row.pd_OP_AM}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText 
            id={`pd_DO_NO_${index}`}
            name={`pd_DO_NO_${index}`}
            defaultValue={row.pd_DO_NO}
          />
          </td>
          <td>
          <InputText 
            id={`pd_DO_TY_${index}`}
            name={`pd_DO_TY_${index}`}
            defaultValue={row.pd_DO_TY}
          />
          </td>
          <td>
          <InputText 
            id={`pd_DO_CO_${index}`}
            name={`pd_DO_CO_${index}`}
            defaultValue={row.pd_DO_CO}
          />
          </td>       
          <td>
          <InputText 
            id={`pd_PA_FR_${index}`}
            name={`pd_PA_FR_${index}`}
            defaultValue={row.pd_PA_FR}
            disabled={ph_CR_CO==ph_CR_CD}
            onChange={(event) => {
              onchangedata(event);
              domescal(index); 
            }}
          />
          </td>
          <td>
          <InputText 
            id={`pd_Di_FR_${index}`}
            name={`pd_Di_FR_${index}`}
            value={row.pd_Di_FR}
            disabled={ph_CR_CO==ph_CR_CD}
            onChange={(Event) => onchangedata(Event)}
          />
          </td>
          
          </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
        </div> 
    </div>  
  <AccountNoPopup
        visible={AccountPopupVisible}
        onClose={() => setAccountPopupVisible(false)}
        onDataSelect={handleDataSelect1}
        formData={data}
        bankFilter={bankFilter}
      />
<SupplierPopup
        visible={SupplierPopupVisible}
        onClose={() => setSupplierPopupVisible(false)}
        formData={data}
        opensubmit={handleDataSelect}
        selectedRowsInvoices={selectedRowsInvoices}
        setSelectedRowsInvoices={setSelectedRowsInvoices}
      />
    </Dialog>
  );
}
