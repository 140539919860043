import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
import ButtonStyles from "views/common/ButtonStyles";
import AddressBookPopup from "../Popups/AddressBookPopup";
import CourseDetailsPopup from "../Popups/CourseDetailsPopup";
import CompetencyDetailsPopup from "../Popups/CompetencyDetailsPopup";
export default function ResourceCompetencyDetailsDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  onchangedata,
  onchangeFormData,
  onchangeDropDownFormData1,
  onchangeDropDownFormData2,
  onchangeDropDownFormData3,
  onchangeDropDownFormData4,
  onchangeDropDownFormData5,
  onchangeDropDownFormData6,
  addRow,
  deleteRow,
  ResourceCompetencytable,
  rc_DL_TY,
  rc_GR_DE,
  rc_CS_PR,
  rc_TR_RE,
  SelectedResource,
  setSelectedResource,
  setFormData,
  rc_CO_TY,
  rc_CO_LV,
  Selectedprdv,
  setSelectedprdv,
}) {
  const { 
  rc_AD_NO,
  rc_CO_NO,
  rcUkNo,
  rc_LO_CN,
  rc_EN_DT,
  rc_ST_DT,
  rc_UN_TS,
  rc_CO_ST,
  rc_CE_CD,
  rc_CO_CD,
  } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle} = ButtonStyles();
  const [rc_CO_TYOptions, setrc_CO_TYOptions] = useState([]);
  const [rc_CO_LVOptions, setrc_CO_LVOptions] = useState([]);
  const [rc_CS_PROptions, setrc_CS_PROptions] = useState([]);
  const [rc_GR_DEOptions, setrc_GR_DEOptions] = useState([]);
  const [rc_DL_TYOptions, setrc_DL_TYOptions] = useState([]);
  const [rc_TR_REOptions, setrc_TR_REOptions] = useState([]);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

  const onDateChange = (name, index, value) => {
    if (value && value.target) {
      const selectedDate = value.target.value;
      const formattedDate = moment(selectedDate).format("YYYYMMDD");
      onchangeFormData(name, formattedDate,index);
    } else if (value){
      const formattedDate = moment(value).format("YYYYMMDD");
      onchangeFormData(name, formattedDate, index);
    }
  };
  // const onchangedata = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "rc_ST_DT"  || name === "rc_EN_DT") {
  //     console.log(`Received date for ${name}:`, value);
  //     const formattedDate = moment(value, "DD/MM/YYYY", true).format("YYYYMMDD");
  //     console.log(`Formatted date for ${name}:`, formattedDate);
  
  //     if (moment(formattedDate, "YYYYMMDD").isValid()) {
  //       onchangeFormData(name, formattedDate);
  //     } else {
  //       console.error("Invalid date format:", value);
  //     }
  //   } else {
  //     onchangeFormData(name, value);
  //   }
  // };
  const url = `${domain}/api/ClassCode/All`;
  // comp type
  const parameter1 = "CM/TY";
  const finalUrl1 = `${url}/${parameter1}`;
// comp level
  const parameter2 = "CO/LV";
  const finalUrl2 = `${url}/${parameter2}`;
// provider
  const parameter3 = "CO/PR";
  const finalUrl3 = `${url}/${parameter3}`;
// grade
  const parameter4 = "EM/GR";
  const finalUrl4 = `${url}/${parameter4}`;
// delivery type
  const parameter5 = "DL/TY";
  const finalUrl5 = `${url}/${parameter5}`;
// traiaining reason
  const parameter6 = "TR/RE";
  const finalUrl6 = `${url}/${parameter6}`;

  useEffect(() => {
    fetchDropdownOptions1();
    fetchDropdownOptions2();
    fetchDropdownOptions3();
    fetchDropdownOptions4();
    fetchDropdownOptions5();
    fetchDropdownOptions6();
  }, []);

  // 1st dropdown
  const fetchDropdownOptions1 = async () => {
    try {
      const response = await axios.get(finalUrl1, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_CO_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 2nd dropdown
  const fetchDropdownOptions2 = async () => {
    try {
      const response = await axios.get(finalUrl2, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_CO_LVOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 3rd dropdown
  const fetchDropdownOptions3 = async () => {
    try {
      const response = await axios.get(finalUrl3, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_CD,
        value: item.cl_CL_CD,
      }));
      setrc_CS_PROptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 4th dropdown
  const fetchDropdownOptions4 = async () => {
    try {
      const response = await axios.get(finalUrl4, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_GR_DEOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 5th dropdown
  const fetchDropdownOptions5 = async () => {
    try {
      const response = await axios.get(finalUrl5, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_DL_TYOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
  // 6th dropdown
  const fetchDropdownOptions6 = async () => {
    try {
      const response = await axios.get(finalUrl6, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      const options = response.data.map((item) => ({
        label: item.cl_CL_DS,
        value: item.cl_CL_CD,
      }));
      setrc_TR_REOptions(options);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };
    
  const handleFormDataChange = (name, value) => {
    const index = name.split("_")[1];
    onchangeDropDownFormData3(name, value, index);
  };
// advance searchs
const [showSearchIcon, setShowSearchIcon] = useState(false);
const [SelectedRowIndex, setSelectedRowIndex] = useState("");
const [ResourcePopupVisible, setResourcePopupVisible] = useState(false);
const [CompetencyPopupVisible, setCompetencyPopupVisible] = useState(false);
const [CoursePopupVisible, setCoursePopupVisible] = useState(false);
const [ProviderPopupVisible, setProviderPopupVisible] = useState(false);
const [addressTypePrvd, setaddressTypePrvd] = useState("S");
const [addressTypeResource, setaddressTypeResource] = useState("E");
const openProviderPopup = (e) => {
  setProviderPopupVisible(true);
  setSelectedRowIndex(e);
};
const handleDataSelectProvider = (data) => {
  setSelectedprdv(data.ab_AD_NM);
  document.getElementById(`rc_CS_PR_${SelectedRowIndex}`).value = data.abAdNo;
  document.getElementById(`Selectedprdv_${SelectedRowIndex}`).value = data.ab_AD_NM;
  setProviderPopupVisible(false);
  setShowSearchIcon(false);
};
const openResourcePopup = () => {
  setResourcePopupVisible(true);
};
const openCompetencyPopup = () => {
  setCompetencyPopupVisible(true);
};
const openCoursePopup = (e) => {
  setCoursePopupVisible(true);
  setSelectedRowIndex(e);
  setShowSearchIcon(false);
};
const handleSelectResource = (data) => {
  setSelectedResource(data.ab_AD_NM);
  document.getElementById("rc_AD_NO").value = data.abAdNo;
  setResourcePopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectCompetency = (data) => {  
  document.getElementById("rc_CO_CD").value = data.cd_CO_CD;
  document.getElementById("rc_CO_TY").value = data.type;
  document.getElementById("rc_CO_LV").value = data.levelDES;
  setCompetencyPopupVisible(false);
  setShowSearchIcon(false);
};
const handleSelectCourse = (data) => {
  document.getElementById(`rc_CE_CD_${SelectedRowIndex}`).value = data.cd_CS_NM;
  document.getElementById(`rc_CS_PR_${SelectedRowIndex}`).value = data.cd_CS_PR;
  // onchangeDropDownFormData3(`rc_CS_PR_${SelectedRowIndex}`, data.cd_CS_PR);
  onchangeFormData(`rc_ST_DT_${SelectedRowIndex}`, data.cd_EF_FR);
  onchangeFormData(`rc_EN_DT_${SelectedRowIndex}`, data.cd_EF_TO);
  // document.getElementById(`rc_ST_DT_${SelectedRowIndex}`).value = data.cd_EF_FR;
  // document.getElementById(`rc_EN_DT_${SelectedRowIndex}`).value = data.cd_EF_TO;
  document.getElementById(`rc_UN_TS_${SelectedRowIndex}`).value = data.cd_UN_TS;
  document.getElementById(`rc_CO_ST_${SelectedRowIndex}`).value = data.cd_CO_ST;
  setCoursePopupVisible(false);
  setShowSearchIcon(false);
};
const isUpdateMode = Boolean(rcUkNo);
const dialogHeader = (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <h5>{rcUkNo ? "Revise Resource Competency" : "Add Resource Competency"}</h5>
    <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
      <Button
        style={savestyle}
        icon="pi pi-check"
        onClick={handleFormSubmit} 
         tooltip="Save"
      />
      <Button
        style={cancelstyle}
        icon="pi pi-times"
        onClick={handleClose}
        tooltip="Cancel"
      />
    </div>
  </div>
);

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
    <div style={borderbox} >
      <div style={inboxstyle} >
        <div className="formgrid grid">
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="rc_AD_NO" className="font-bold">
              Resource <span style={{color:"red"}}>*</span>
              </label>
              <div style={{display:"flex"}}>
              <InputText
                id="ab_AD_NM"
                name="ab_AD_NM"
                onFocus={() => setShowSearchIcon(true)}
                onChange={(e) => onchangedata(e)}
                value={SelectedResource}
              />
               {showSearchIcon && (
                  <i
                    className="pi pi-search"
                    style={{
                      color: 'var(--primary-color)',
                      fontSize: '119%',
                      marginTop: '4%',
                      cursor: 'pointer',
                    }}
                    onClick={openResourcePopup}
                  ></i>
                )}
              </div>
              <div style={{ display: 'none' }}>
                <InputText
                  id="rc_AD_NO"
                  name="rc_AD_NO"
                  defaultValue={rc_AD_NO}
                  onChange={(event) => onchangedata(event)}
                />
              </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_GR_DE" className="font-bold">
              Grade
            </label>
            <InputText disabled
              id="rc_GR_DE"
              name="rc_GR_DE"
              defaultValue={rc_GR_DE}
              onChange={(e) => onchangedata(e)}
            />
          </div> 
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_CO_CD" className="font-bold">
            Competency Code <span style={{color:"red"}}>*</span>
            </label>
            <div style={{display:"flex"}}>
            <InputText
              id="rc_CO_CD"
              name="rc_CO_CD"
              onFocus={() => setShowSearchIcon(true)}
              onChange={(e) => onchangedata(e)}
              defaultValue={rc_CO_CD}
            />
            {showSearchIcon && (
              <i
                className="pi pi-search"
                style={{
                  color: 'var(--primary-color)',
                  fontSize: '119%',
                  marginTop: '4%',
                  cursor: 'pointer',
                }}
                onClick={openCompetencyPopup}
              ></i>
            )}
          </div>
          </div>
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_CO_TY" className="font-bold">
            Competency Type
            </label>
            <InputText disabled
              id="rc_CO_TY"
              name="rc_CO_TY"
              defaultValue={rc_CO_TY}
              onChange={(e) => onchangedata(e)}
            />
          </div> 
          <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
            <label htmlFor="rc_CO_LV" className="font-bold">
            Competency Level
            </label>
            <InputText disabled
              id="rc_CO_LV"
              name="rc_CO_LV"
              defaultValue={rc_CO_LV}
              onChange={(e) => onchangedata(e)}
            />
          </div>
        </div>
      </div>
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <table className="custom-table" id="ResourceCompetencytable" style={{overflow:"auto"}}>
      <thead>
        <tr>
          <th style={{ display: "none" }} >UK_NO</th>
          <th>Course <span style={{color:"red"}} >*</span></th>
          <th>Provider</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Units</th>
          <th>Cost</th>
          <th>Location</th>
          <th>Delivery Type</th>
          <th>Training Reason</th>
        </tr>
      </thead>
      <tbody>
      {ResourceCompetencytable.map((row, index) => (
        <tr key={index}>
          <td style={{ display: "none" }} >
            <InputText
              id={`rcUkNo_${index}`}
              name={`rcUkNo_${index}`}
              defaultValue={row.rcUkNo}
            />
          </td>
          <td>
          <div style={{display:"flex"}}>
          <InputText style={{width:"90px"}}
            id={`rc_CE_CD_${index}`}
            name={`rc_CE_CD_${index}`}
            onFocus={() => setShowSearchIcon(true)}
            onChange={(Event) => onchangedata(Event)}
            defaultValue={row.rc_CE_CD}
          />
          {showSearchIcon && (
            <i className="pi pi-search"
            style={{
              color: "var(--primary-color)",
              fontSize: "119%",
              marginTop: "7%",
              cursor: "pointer",
            }}
            onClick={(e) => openCoursePopup(index)}
          ></i>
            )}
          </div>
          </td>
          <td>
            {/* <Dropdown
              id={`rc_CS_PR_${index}`}
              name={`rc_CS_PR_${index}`}
              value={data[`rc_CS_PR_${index}`] ? data[`rc_CS_PR_${index}`]:row.rc_CS_PR }
              options={rc_CS_PROptions}
              onChange={(e) => handleFormDataChange(`rc_CS_PR_${index}`, e.value)}
              placeholder="Select Provider"
            /> */}
          <div style={{display:"flex"}}>
            <InputText style={{width:"90px"}}
              id={`Selectedprdv_${index}`}
              name={`Selectedprdv_${index}`}
              onFocus={() => setShowSearchIcon(true)}
              onChange={(Event) => onchangedata(Event)}
              defaultValue={row.Selectedprdv}
            />
            {showSearchIcon && (
              <i className="pi pi-search"
              style={{
                color: "var(--primary-color)",
                fontSize: "119%",
                marginTop: "7%",
                cursor: "pointer",
              }}
              onClick={(e) => openProviderPopup(index)}
            ></i>
              )}
              <InputText  style={{ display: 'block' }}
               id={`rc_CS_PR_${index}`}
               name={`rc_CS_PR_${index}`}
               defaultValue={row.rc_CS_PR ? row.rc_CS_PR : row[`${"rc_CS_PR"}_${index}`]}
              />
          </div>
          </td>
          <td>
          <Calendar style={{width:"150px"}}
            id={`rc_ST_DT_${index}`}
            name={`rc_ST_DT_${index}`}
            value={ row.rc_ST_DT ? moment(row.rc_ST_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "rc_ST_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <Calendar style={{width:"150px"}}
            id={`rc_EN_DT_${index}`}
            name={`rc_EN_DT_${index}`}
            value={ row.rc_EN_DT ? moment(row.rc_EN_DT, "YYYYMMDD").toDate() : null }
            onChange={(e) => onDateChange({ target: { name: "rc_EN_DT", value: moment(e.value).format("YYYYMMDD") } })}
            dateFormat="dd/mm/yy"
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled
            id={`rc_UN_TS_${index}`}
            name={`rc_UN_TS_${index}`}
            defaultValue={row.rc_UN_TS}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <InputText style={{width:"90px"}} disabled
            id={`rc_CO_ST_${index}`}
            name={`rc_CO_ST_${index}`}
            defaultValue={row.rc_CO_ST}
            onChange={(e) => onchangedata(e)}
          />
          </td>
         
          <td>
          <InputText style={{width:"90px"}}
            id={`rc_LO_CN_${index}`}
            name={`rc_LO_CN_${index}`}
            defaultValue={row.rc_LO_CN}
            onChange={(e) => onchangedata(e)}
          />
          </td>
          <td>
          <Dropdown style={{width:"120px"}}
            id={`rc_DL_TY_${index}`}
            name={`rc_DL_TY_${index}`}
            value={data[`rc_DL_TY_${index}`] ? data[`rc_DL_TY_${index}`]:row.rc_DL_TY }
            options={rc_DL_TYOptions}
            onChange={(e) => onchangeDropDownFormData5(`rc_DL_TY_${index}`, e.value)}
            placeholder="Select Del. Type"
          />
          </td>
          <td>
          <Dropdown style={{width:"120px"}}
            id={`rc_TR_RE_${index}`}
            name={`rc_TR_RE_${index}`}
            value={data[`rc_TR_RE_${index}`] ? data[`rc_TR_RE_${index}`]:row.rc_TR_RE }
            options={rc_TR_REOptions}
            onChange={(e) => onchangeDropDownFormData6(`rc_TR_RE_${index}`, e.value)}
            placeholder="Select Tr. Reason"
          />
          </td>
        </tr>
           ))}
      </tbody>
    </table>   
    
    </div>
      </div>   
    </div>
     {/* Provider */}
     <AddressBookPopup
        visible={ProviderPopupVisible}
        onClose={() => setProviderPopupVisible(false)}
        onDataSelect={handleDataSelectProvider}
        formData={data}
        addressType={addressTypePrvd}
        setFormData={setFormData}
      /> 
      <AddressBookPopup
        visible={ResourcePopupVisible}
        onClose={() => setResourcePopupVisible(false)}
        onDataSelect={handleSelectResource}
        formData={data}
        addressType={addressTypeResource}
        setFormData={setFormData}
      />
      <CompetencyDetailsPopup
        visible={CompetencyPopupVisible}
        onClose={() => setCompetencyPopupVisible(false)}
        onDataSelect={handleSelectCompetency}
        formData={data}
      />
      <CourseDetailsPopup
        visible={CoursePopupVisible}
        onClose={() => setCoursePopupVisible(false)}
        onDataSelect={handleSelectCourse}
        formData={data}
      />
    </Dialog>
  );
}
