import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import AddressBookPopup from "views/admin/Popups/AddressBookPopup";
import ButtonStyles from "views/common/ButtonStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
import axios from "axios";
export default function AccessGroupDialog({
  open,
  handleClose,
  data,
  handleFormSubmit,
  handleConfirm,
  handleRun,
  onchangedata,
  onchangeFormData,
  ac_DO_TY,
  ac_DO_CO,
  addRow,
  deleteRow,
  AccessGrouptable,
  ac_ST_US,
  ac_SA_TE,
  SelectedemEMNO,
  setSelectedemEMNO,
  ag_GP_ID,
  type,
  ag_CO_NO,
}) {
  const {
    agUkNo,
    ag_NA_ME,

  } = data;
  const { borderbox, inboxstyle, plusstyle, minusstyle, tablebutton, tablestyle,
    multitablestyle, RunButtonStyles, Dialogstyle, savestyle, cancelstyle, confirmstyle } = ButtonStyles();
  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");


  useEffect(() => {
  }, []);

  const [SelectedRowIndex, setSelectedRowIndex] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState("");
  const [AccountPopupVisible, setAccountPopupVisible] = useState(false);

  const [EmployeePopupVisible, setEmployeePopupVisible] = useState(false); 
  const [addressType, setaddressType] = useState("E");
  const openEmployeePopup = (e) => {
    setEmployeePopupVisible(true);
    setSelectedRowIndex(e);
  };
  //  startsearchpopup
  const handleDataSelect = (data) => {
    setSelectedemEMNO(data.ab_AD_NM);
    onchangeFormData(`ag_EM_ID_${SelectedRowIndex}`, data.abAdNo);
    onchangeFormData(`name_${SelectedRowIndex}`, data.ab_AD_NM);
    document.getElementById(`ag_EM_ID_${SelectedRowIndex}`).value = data.abAdNo;
    document.getElementById(`name_${SelectedRowIndex}`).value = data.ab_AD_NM;
    // onchangeFormData("name", data.ab_AD_NM, SelectedRowIndex);
    setEmployeePopupVisible(false);
    setShowSearchIcon(false);
  };


  // date default
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const handleDateChange = (e) => {
    setSelectedDate(e.value);
    onchangedata({ target: { name: "ac_FR_DT", value: moment(e.value).format("YYYYMMDD") } });
  };
  // disabled after confirm
  const isDisabled = ac_ST_US === "C";

  const isUpdateMode = Boolean(agUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
      <h5>{agUkNo ? "Revise Access Group" : "Add Access Group"}</h5>
      <div style={{ display: 'flex', justifyContent: isUpdateMode ? 'flex-start' : 'flex-start', width: '70%' }}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit}
          tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      
      </div>
    </div>
  );

  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div style={borderbox} >
        <div style={inboxstyle}>
          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ag_GP_ID" className="font-bold">
                Group ID
              </label>
              <InputText disabled
                id="ag_GP_ID"
                name="ag_GP_ID"
                value={ag_GP_ID}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="type" className="font-bold">
                Type
              </label>
              <InputText disabled
                id="type"
                name="type"
                value={type}
                onChange={(e) => onchangedata(e)}
              />
            </div>
            <div className="field col-lg-2 col-md-6 col-12 pe-md-2">
              <label htmlFor="ag_CO_NO" className="font-bold">
                Company
              </label>
              <InputText disabled
                id="ag_CO_NO"
                name="ag_CO_NO"
                value={ag_CO_NO}
              />
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col-lg-2 col-md-2 col-2 pe-md-2">
              <label htmlFor="ag_NA_ME" className="font-bold">
                Group Name
              </label>
              <InputText
                id="ag_NA_ME"
                name="ag_NA_ME"
                value={ag_NA_ME}
                onChange={(e) => onchangedata(e)}
              />
            </div>
          </div>
        </div>
        <div style={tablestyle} >
          <div className="table-buttons" style={tablebutton} >
            {(
              <Button icon="pi pi-plus" onClick={addRow} style={plusstyle} tooltip="Add" />
            )}
            {(
              <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
            )}
          </div>
          <div style={multitablestyle}>
            <table className="custom-table" id="AccessGrouptable" style={{ overflow: "auto" }}>
              <thead>
                <tr>
                  <th style={{ display: "none" }} >UK_NO</th>
                  <th>Employee<span style={{ color: "red" }}>*</span> </th>
                </tr>
              </thead>
              <tbody>
                {AccessGrouptable.map((row, index) => (
                  <tr key={index}>
                    <td style={{ display: "none" }} >
                      <InputText
                        id={`agUkNo_${index}`}
                        name={`agUkNo_${index}`}
                        defaultValue={row.agUkNo}
                      />
                    </td>

                    {/* <td >
            <InputText   
              id={`ag_NA_ME_${index}`}
              name={`ag_NA_ME_${index}`}
              defaultValue={row.ag_NA_ME}
              onChange={(e) => onchangedata(e)}
            />
            </td> */}

                    <td style={{ display: "none" }}>
                      <InputText
                        id={`ag_EM_ID_${index}`}
                        name={`ag_EM_ID_${index}`}
                        onFocus={() => setShowSearchIcon(true)}
                        defaultValue={row.ag_EM_ID ? row.ag_EM_ID : data[`${"ag_EM_ID"}_${index}`]} 
                        onChange={(e) => onchangedata(e)}
                      />
                    </td>
                    <td>
                      <div style={{ display: "flex", width: "200px" }}>
                        <InputText
                          id={`name_${index}`}
                          name={`name_${index}`}
                          onChange={(e) => onchangedata(e)}
                          onFocus={() => setShowSearchIcon(true)}
                          defaultValue={row.employeeDESC ? row.employeeDESC : data[`${"name"}_${index}`]}
                        />
                        {showSearchIcon && (
                          <i
                            className="pi pi-search"
                            style={{
                              color: "var(--primary-color)",
                              fontSize: "119%",
                              marginTop: "7%",
                              cursor: "pointer",
                            }}
                            onClick={(e) => openEmployeePopup(index)}
                          ></i>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>
      <AddressBookPopup
        visible={EmployeePopupVisible}
        onClose={() => setEmployeePopupVisible(false)}
        onDataSelect={handleDataSelect}
        addressType={addressType}
        formData={data}
      />

    </Dialog>
  );
}
