import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import ClassCode from "views/common/ClassCode";
import ButtonStyles from "views/common/ButtonStyles";
export default function StandardUMConversionDialog({
  open,
  handleClose,
  data,
  setFormData,
  onchangedata,
  onchangeFormData,
  handleFormSubmit,
  StandardUMConversiontabledata,
  setStandardUMConversiontabledata,
  seter_EX_TY,
  seter_AD_CR,
  cs_CO_NO,
}) {
  const { csUkNo } = data;
  const {borderbox,inboxstyle,plusstyle,minusstyle,tablebutton,tablestyle,
    multitablestyle,savestyle,cancelstyle,Dialogstyle } = ButtonStyles();
   // Dropdown
   const { fetchClassCodeDS ,fetchClassCodeCD } = ClassCode();
   const [er_EX_TYOptions, seter_EX_TYOptions] = useState([]);
   const [er_ST_ATOptions, seter_ST_ATOptions] = useState([]);
   const [er_EX_CTOptions, seter_EX_CTOptions] = useState([]);
     //From UM
  const FromUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_EX_TY(value);
  };
  // To UM
  const ToUMDropdown = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    seter_AD_CR(value);
  };
  useEffect(() => {
    FromUM();
    ToUM();
  }, []);
  const FromUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seter_ST_ATOptions(Options);
  };
  const ToUM = async () => {
    const Options = await fetchClassCodeCD("IN/UM");
    seter_EX_CTOptions(Options);
  };
  const addRow = () => {
    setStandardUMConversiontabledata([...StandardUMConversiontabledata, {}]);
  };
  const deleteRow = () => {
    if (StandardUMConversiontabledata.length > 0) {
      const updatedData = [...StandardUMConversiontabledata];
      updatedData.pop();
      setStandardUMConversiontabledata(updatedData);
    }
  };
  const isUpdateMode = Boolean(csUkNo);
  const dialogHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <h5>{csUkNo ? "Revise Standard UM Conversion" : "Add Standard UM Conversion"}</h5>
      <div style={{width:'50rem',display:'flex', justifyContent:'flex-start'}}>
        <Button
          style={savestyle}
          icon="pi pi-check"
          onClick={handleFormSubmit} 
           tooltip="Save"
        />
        <Button
          style={cancelstyle}
          icon="pi pi-times"
          onClick={handleClose}
          tooltip="Cancel"
        />
      </div>
    </div>
  );
  return (
    <Dialog id="dialog-style"
      visible={open}
      onHide={handleClose}
      style={Dialogstyle}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={dialogHeader}
      modal
      className="p-fluid"
    >
      <div  style={tablestyle} >
        <div className="table-buttons" style={tablebutton} >
          {(
          <Button icon="pi pi-plus"  onClick={addRow} style={plusstyle} tooltip="Add"  />
          )}
           {(
          <Button icon="pi pi-minus" onClick={deleteRow} style={minusstyle} tooltip="Delete" />
          )}
        </div>
        <div  style={multitablestyle}>
        <div style={{display:"flex",overflow:"auto"}}>
        <table className="custom-table" id="StandardUMConversiontable">
          <thead>
            <tr>
              <th style={{ display: "none" }}>UK NO</th>
              <th>1</th>
              <th>From UM</th>
              <th>=</th>
              <th>Conversion Factor</th>
              <th>To UM</th>
              <th>Inverse Conversion</th>
            </tr>
          </thead>
          <tbody>
            {StandardUMConversiontabledata.map((row, index) => (
              <tr key={index}>
                <td style={{ display: "none" }}>
                  <InputText
                    id={`csUkNo_${index}`}
                    name={`csUkNo_${index}`}
                    value={row.csUkNo}
                  />
                </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown 
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_EX_TYOptions}
                    onChange={(e) =>FromUMDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
                <td>
                  <Dropdown 
                    id={`er_CO_CT_${index}`}
                    name={`er_CO_CT_${index}`}
                    value={ data[`er_CO_CT_${index}`] ? data[`er_CO_CT_${index}`]: row.er_CO_CT}
                    options={er_EX_TYOptions}
                    onChange={(e) =>ToUMDropdown(`er_CO_CT_${index}`, e.value)}
                    placeholder="Select Cost Centre"
                  />
                  </td>
                <td >
                  <InputText 
                    id={`ep_DA_AL_${index}`}
                    name={`ep_DA_AL_${index}`}
                    defaultValue={row.ep_DA_AL}
                    onChange={(e) => onchangedata(e)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
      </div> 
      
    </Dialog>
  );
}
