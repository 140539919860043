import React, { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import SupplierLedgerDialog from "./SupplierLedgerDialog";
import axios from "axios";
import moment from "moment";
import saveAs from "file-saver";
import { Tooltip } from "primereact/tooltip";
import ButtonStyles from "views/common/ButtonStyles";
import Pagination from "views/common/Pagination";
const initialValue = {
  sl_UK_NO:"",
  slDoNo:"",
  sl_DO_TY:"",
  sl_DO_CO:"",
  sl_DO_PI:"",
  sl_SU_NO:"",
  sl_GL_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sl_IN_DT: moment(moment().toDate()).format("YYYYMMDD"),
  sl_DD_DT:"",
  sl_PY_CO:"",
  sl_GL_PO:"",
  sl_PA_ST:"",
  sl_GR_AM:"",
  sl_OP_AM:"",
  sl_TA_AM:"",
  sl_TX_AM:"",
  sl_PD_AM:"",
  sl_ST_DT:"",
  sl_CR_CD:"",
  sl_CR_RT:"",
  sl_OP_FR:"",
  sl_TA_FR:"",
  sl_TX_FR:"",
  sl_OR_NO:"",
  sl_OR_TY:"",
  sl_OR_CO:"",
  sl_SU_IN:"",
  sl_CO_NM:"",
  sl_BA_TY:"",
  sl_BA_NO:"",
  sl_BA_DT:"",
  sl_ST_CD:"",
  sl_DO_TY:"",
  sl_TX_CD:"",
  sl_DI_AM:"",
  sl_GR_AM:"",
  sl_DI_FR:"",
  sl_DO_PI:"",
  sl_TX_AM:"",
  sl_DI_AM:"",
  sl_GR_AM:"",
  sl_TA_FR:"",
  sl_TX_FR:"",
  sl_DI_FR:"",
  sl_OR_NO:"",  
  sl_OR_CO:"",
};

const initialFilters = {
  slDoNo: { value: "", operation: "" },
  sl_DO_TY: { value: "", operation: "" },
  sl_DO_CO: { value: "", operation: "" },
  slDoPi: { value: "", operation: "" },
  sl_SU_NO: { value: "", operation: "" },
  name: { value: "", operation: "" },
  sl_GL_DT: { value: "", operation: "" }, 
  sl_IN_DT: { value: "", operation: "" }, 
  sl_DD_DT: { value: "", operation: "" }, 
  sl_PY_CO: { value: "", operation: "" },
  sl_GL_PO: { value: "", operation: "" },
  sl_PA_ST: { value: "", operation: "" },
  sl_GR_AM: { value: "", operation: "" },
  sl_OP_AM: { value: "", operation: "" },
  sl_TA_AM: { value: "", operation: "" },
  sl_TX_AM: { value: "", operation: "" },
  sl_PD_AM: { value: "", operation: "" },
  sl_ST_DT: { value: "", operation: "" }, 
  sl_CR_CD: { value: "", operation: "" },
  sl_CR_RT: { value: "", operation: "" },
  sl_GR_AM: { value: "", operation: "" },
  sl_OP_FR: { value: "", operation: "" },
  sl_TA_FR: { value: "", operation: "" },
  sl_TX_FR: { value: "", operation: "" },
  sl_OR_NO: { value: "", operation: "" },
  sl_OR_TY: { value: "", operation: "" },
  sl_OR_CO: { value: "", operation: "" },
  sl_SU_IN: { value: "", operation: "" },
  sl_CO_NM: { value: "", operation: "" },
  sl_BA_TY: { value: "", operation: "" },
  sl_BA_NO: { value: "", operation: "" },
  sl_BA_DT: { value: "", operation: "" },
  sl_ST_CD: { value: "", operation: "" },
};

const SupplierLedgerMain = () => {
  // buttonstyles
  const {formatDate, buttonStyles, addButtonStyles,
  copyButtonStyles,checkboxStyle,tableHeaderStyle,
  inputStyle,tableCellStyle,inputStyle1,rightalined
  } = ButtonStyles();
  const [tableData, setTableData] = useState(null);
  const [FormData, setFormData] = useState(initialValue);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const toast = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const [SelectedemEMNO, setSelectedemEMNO] = useState("");
  const [slDoNo, setslDoNo] = useState("");

  const [transCurr, settransCurr] = useState("");
  const [PaymentTerm, setPaymentTerm] = useState("");
  const [isGLSaved, setIsGLSaved] = useState(true);

  const token = localStorage.getItem("token");
  const company = localStorage.getItem("company");
  const domain = localStorage.getItem("domain");

 
  const [SelectedemEMNO1, setSelectedemEMNO1] = useState("");
  const [sl_SU_NO, setsl_SU_NO] = useState("");
  const [sl_PY_CO, setsl_PY_CO] = useState("");
  const [sl_CO_NM, setsl_CO_NM] = useState("");
  const [sl_IN_DT, setsl_IN_DT] = useState("");
  const [sl_OR_TY, setsl_OR_TY] = useState("");
  const [sl_CR_CD, setsl_CR_CD] = useState("");
  const [sl_GL_DT, setsl_GL_DT] = useState("");
  const [sl_DD_DT, setsl_DD_DT] = useState("");
  const [sl_TX_CD, setsl_TX_CD] = useState("");
  const [SupplierLedgertable, setSupplierLedgertable] = useState([{}]);
  const [sl_ST_CD, setsl_ST_CD] = useState("");
 
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [taxcode, settaxcode] = useState("");
  const buildSearchRequestDto = () => {
    return Object.keys(filters)
      .filter((column) => filters[column].value)
      .map((column) => {
        let constructedColumn = column;
  
        if (column === 'slDoNo') {
          constructedColumn = 'slDoNo';
        } else if(column === 'sl_DO_TY') {
          constructedColumn = 'sl_DO_TY';
        } else {
          constructedColumn = column.toUpperCase();
        }
  
        return {
          column: constructedColumn,
          value: filters[column].value,
          operation: filters[column].operation,
        };
      });
  };
  const handleFilterChange = (column, value, operation) => {
    let updatedOperation = operation;
    let updatedValue = value;

    if (value.startsWith(">")) {
      updatedOperation = "GREATER_THAN";
      updatedValue = value.substring(1);
    } else if (value.startsWith("<")) {
      updatedOperation = "LESS_THAN";
      updatedValue = value.substring(1);
    } else if (value.includes(",")) {
      updatedOperation = "BETWEEN";
    } else if (!isNaN(value)) {
      updatedOperation = "EQUAL";
    } else {
      if (value === value.toUpperCase()) {
        updatedOperation = "LIKE";
      } else {
        updatedOperation = "LIKE";
      }
    }

    const updatedFilters = { ...filters };
    updatedFilters[column] = {
      value: updatedValue,
      operation: updatedOperation,
    };
    setFilters(updatedFilters);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAddButtonClick = () => {
    setSupplierLedgertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO("");
    settaxcode(null)
    setSelectedemEMNO(null);
    setsl_IN_DT(null);
    setsl_OR_TY(null);
    setsl_CR_CD(null);
    setsl_PY_CO(null);
    setsl_TX_CD(null);
    setsl_ST_CD(10);
    setIsGLSaved(true);
    setOpen(true);
  };
  const handleClose = () => {
    setSupplierLedgertable([{}]);
    setFormData(initialValue);
    setSelectedemEMNO(null);
    setsl_IN_DT(null);
    setsl_OR_TY(null);
    setsl_CR_CD(null);
    setsl_PY_CO(null);
    setsl_TX_CD(null);
    setsl_ST_CD(null);
    setPaymentTerm(null);
    setOpen(false);
  };
  const handleResponse = (data) => {
    const { warnings = [], errorMessage = [], infos = [] } = data;
  
    if (warnings.length > 0) {
      const warningMessage = warnings.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: warningMessage,
      });
    }
  
    if (infos.length > 0) {
      const infoMessage = infos.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: infoMessage,
      });
    }
  
    if (errorMessage.length > 0) {
      const errorMessages = errorMessage.map((item) => `${item}\n`).join("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessages,
      });
    }
  
    if (errorMessage.length === 0) {
      handleClose();
      handleDefault();
    }
  }; 
  const addRow = () => {
      setSupplierLedgertable([...SupplierLedgertable, {"sl_TX_CD":FormData.sl_TX_CD}]);
  };
  const deleteRow = () => {
    if (SupplierLedgertable.length > 0) {
      const updatedData = [...SupplierLedgertable];
      updatedData.pop();
      setSupplierLedgertable(updatedData);
    }
  };
  const handleCopyData = async () => {
    setIsGLSaved(true);
    if (selectedRows.length === 1) {
      try {
        const response = await axios.get(
          `${domain}/api/SupplierLedger/Select/${selectedRows[0]}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          }
        );

        if (response.status === 200) {
          const SupplierLedgerData = response.data;
          delete SupplierLedgerData.sl_UK_NO;
          delete SupplierLedgerData.slDoNo;
          for (let i = 0; i < SupplierLedgerData.supplierLedgerList.length; i++) {
            delete SupplierLedgerData.supplierLedgerList[i].sl_UK_NO;
            delete SupplierLedgerData.supplierLedgerList[i].slDoNo;
          }
          setSupplierLedgertable(SupplierLedgerData.supplierLedgerList);
          setFormData(SupplierLedgerData.supplierLedgerList[0]);
          setsl_CO_NM(SupplierLedgerData.sl_CO_NM);
          setsl_OR_TY(SupplierLedgerData.sl_OR_TY);
          setsl_CR_CD(SupplierLedgerData.sl_CR_CD);
          setsl_PY_CO(SupplierLedgerData.sl_PY_CO);
          setsl_SU_NO(SupplierLedgerData.sl_SU_NO);
          setsl_TX_CD(SupplierLedgerData.sl_TX_CD);
          setsl_ST_CD(10);
          setSelectedemEMNO(SupplierLedgerData.supplierLedgerList[0].name);
          setsl_SU_NO(SupplierLedgerData.sl_SU_NO);
          handleClickOpen();
        } else {
          console.error(`API request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const [sl_DO_CO , setsl_DO_CO] = useState("");
  const [sl_DO_TY , setsl_DO_TY] = useState("");
  const [sl_CR_CO, setsl_CR_CO] = useState("");
  const handleDefault = async () => {
    const linkPath = String(window.location);
    const l_array = linkPath.split("=");
    const LinkId = l_array[1];
    
    const requestBody = {
      "version_ID" : LinkId
    };
    const paginationUrl = `${domain}/api/LinkId/Select`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });
      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }
      const dataDefault = response.data;
      const listDefault = [];
      var count = 0;
      if (dataDefault.versionIds != null) {
        for (let i = 0; i < dataDefault.versionIds.length; i++) {
          if(dataDefault.versionIds[i].field_TYPE === "SCREEN"){
            const fName = dataDefault.versionIds[i].field_ID;
            setsl_DO_CO(`${company}`)
            if(fName === "SL_DO_TY"){
              setsl_DO_TY(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SL_CR_CO"){
              setsl_CR_CO(dataDefault.versionIds[i].default_VALUE)
            }
            if(fName === "SL_CO_NM"){
              onchangeDropDownFormData2("sl_CO_NM",dataDefault.versionIds[i].default_VALUE);
              setsl_CO_NM(dataDefault.versionIds[i].default_VALUE)
            }

          }
          if(dataDefault.versionIds[i].field_TYPE === "LIST"){
            count++;
            const def = {
              "column" : dataDefault.versionIds[i].field_ID ,
              "value" : dataDefault.versionIds[i].default_VALUE,
              "operation" : "EQUAL"
            }
            listDefault.push(def);
          }
        }
        handleSearch(listDefault,count);
      } else {
        handleSearch(listDefault,count);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    handleDefault();
  }, [currentPage, pageSize, filters]);

  const handleSearch = async (defaultLists,nos) => {
    const searchRequestDto = buildSearchRequestDto();
    for(let i = 0; i < nos ; i++){
      searchRequestDto.push(defaultLists[i]);
    }
    const requestBody = {
      searchRequestDto,
    };
    const paginationUrl = `${domain}/api/SupplierLedger/List/${currentPage}/${pageSize}`;
    try {
      const response = await axios.post(paginationUrl, requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      });

      if (response.status !== 200) {
        throw new Error(`API request failed with status: ${response.status}`);
      }

      const data = response.data;

      if (data && data.content && Array.isArray(data.content)) {
        setTableData(data.content);
        setTotalPages(data.totalPages);
      } else {
        console.error("Invalid API response:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function onChnageExchangeRate(frCr, invoiceDate) {
    const exData = {
      "cr_TO_CR": document.getElementById("sl_CR_CO").value,
      "cr_FR_CR": frCr,
      "cr_EF_DT": invoiceDate
    }
    axios
      .post(`${domain}/api/ExChangeRate/Rate`, exData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
        },
      })
      .then((resp) => {
        const dataExRt = resp.data.Result;
        if (dataExRt) {
          document.getElementById("sl_CR_RT").value = dataExRt.cr_CO_MR;
        }

      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

  };
  const onchangedata = (Event) => {
    const { value, name } = Event.target;
    setFormData((prevFormData) => ({...prevFormData, [name]: value}));
  };
  const onchangeFormData = (name, value) => {
    setFormData((prevFormData) => ({...prevFormData, [name]: value }));
    if(name=="sl_IN_DT")
      onChnageExchangeRate(transCurr, value);
  };
  // const onchangeDropDownFormData1 = (name, value) => {
  //   setFormData({ ...FormData, [name]: value });
  //   setrc_CO_TY(value);
  // };
  const onchangeDropDownFormData2 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_CO_NM(value);
  };
  const onchangeDropDownFormData1 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_OR_TY(value);
  };
  const onchangeDropDownFormData4 = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setsl_CR_CD(value);
    onChnageExchangeRate(value, FormData.sl_IN_DT);
  };
  const onchangeDropDownFormData5 = (name, value) => {
    setFormData({ ...FormData, [name]: value });
    setsl_PY_CO(value);
    setPaymentTerm(value);
    const due={
      "pt_PY_CO": value,
      "inp_DATE": FormData.sl_IN_DT
    }
    axios.post(`${domain}/api/PaymentTerm/onChangeOfPaymentTerm`, due, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          const dueDate = resp.data.inp1;
          if (dueDate) {
            onchangeFormData("sl_DD_DT", dueDate.out_DATE);
          }
  
        })
        .catch((error) => {
          console.error("Error saving data:", error);
        });
  };
  const [isRecordSelected, setIsRecordSelected] = useState(false);

  const handleRowSelect = (rowId) => {
    const isRowSelected = selectedRows.includes(rowId);
    if (!isRowSelected) {
      setSelectedRows([rowId]);
    } else {
      setSelectedRows([]);
    }
    setIsRecordSelected(!isRowSelected);
  };

  const onchangeDropDownFormData6 = (name, value, index=null) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setsl_TX_CD(value);
    const fieldName=(FormData && FormData.sl_UK_NO)?`${name}`:`${name}_${index}`;
    if(index!=null)
      SupplierLedgertable[index][fieldName]=value;
    else{
      for(var i=0;i<SupplierLedgertable.length;i++){
        SupplierLedgertable[i][name]=value;
        setFormData((prevData) => ({
          ...prevData,
          [`${name}_${i}`]: value,
        }));
      }
    }
  };

  const handleUpdate = async () => {
    if (!isRecordSelected || selectedRows.length > 1) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: isRecordSelected
          ? "Select a single record to edit."
          : "Select only one record to edit.",
        life: 3000,
      });
      return;
    }
    setFormData(initialValue);
    setIsGLSaved(true);
    try {
      const response = await axios.get(
        `${domain}/api/SupplierLedger/Select/${selectedRows[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        }
      );

      if (response.status === 200) {
        const SupplierLedgerData = response.data;
        setSupplierLedgertable(SupplierLedgerData.supplierLedgerList);
        setFormData(SupplierLedgerData.supplierLedgerList[0]);
        setsl_CO_NM(response.data.sl_CO_NM);
        setsl_OR_TY(response.data.sl_OR_TY);
        setsl_CR_CD(response.data.sl_CR_CD);
        setsl_PY_CO(response.data.sl_PY_CO);
        setsl_TX_CD(response.data.sl_TX_CD);
        setsl_ST_CD(response.data.sl_ST_CD);
        setSelectedemEMNO(SupplierLedgerData.supplierLedgerList[0].name);
        setsl_SU_NO(SupplierLedgerData.sl_SU_NO);

          const gaGlDisplay = {
            "gl_DO_TY": SupplierLedgerData.supplierLedgerList[0].sl_DO_TY,
            "gl_DO_NO": SupplierLedgerData.supplierLedgerList[0].slDoNo
          };
          axios.post(`${domain}/api/JournalEntry/GetGAGLToDisplay`, gaGlDisplay, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  const len = resp.data.header.length;
                  if(len > 0){
                    setIsGLSaved(false);
                  } else {
                    setIsGLSaved(true);
                  }
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
      
        
        handleClickOpen();
      } else {
        console.error(`API request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  var requestBodyInput = {};
  function values (){
  const UK_NO = FormData.sl_UK_NO;
        const DO_NO = document.getElementById("slDoNo");
        const DO_TY = document.getElementById("sl_DO_TY");
        const DO_CO = document.getElementById("sl_DO_CO");
        const IN_DT = FormData.sl_IN_DT;
        const GL_DT = FormData.sl_GL_DT;
        const DD_DT = FormData.sl_DD_DT;
        const CR_CD = FormData.sl_CR_CD;
        const CR_RT = document.getElementById("sl_CR_RT");
        const CR_CO = document.getElementById("sl_CR_CO");
        const SU_IN = document.getElementById("sl_SU_IN");
        const CO_NM = sl_CO_NM;
          

            const table1 = document.getElementById("SupplierLedgertable");
            const totalRowCount1 = table1.tBodies[0].rows.length;
           
            const supplierLedgerList = [];
            for (let i = 0; i < totalRowCount1; i++) {
              const UK_NO = document.getElementById("sl_UK_NO_" + i);
              const DO_PI = document.getElementById("sl_DO_PI_" + i);
              const GR_AM = document.getElementById("sl_GR_AM_" + i);
              const TA_AM = document.getElementById("sl_TA_AM_" + i);
              const TX_AM = document.getElementById("sl_TX_AM_" + i);
              const TX_CD = table1.tBodies[0].rows[i].cells[5].children[0].children[1].children[0].value;
              const DI_AM = document.getElementById("sl_DI_AM_" + i);
              const CR_AM = document.getElementById("sl_CR_AM_" + i);
              const TA_FR = document.getElementById("sl_TA_FR_" + i);
              const TX_FR = document.getElementById("sl_TX_FR_" + i);
              const DI_FR = document.getElementById("sl_DI_FR_" + i);
              const OR_NO = document.getElementById("sl_OR_NO_" + i);
              const OR_TY = table1.tBodies[0].rows[i].cells[12].children[0].children[1].children[0].value;
              const OR_CO = document.getElementById("sl_OR_CO_" + i);
              const Details = {
                sl_UK_NO: UK_NO.value,
                sl_DO_PI: DO_PI.value,
                sl_GR_AM: GR_AM.value,
                sl_TA_AM: TA_AM.value,
                sl_TX_AM: TX_AM.value,
                sl_TX_CD: TX_CD,
                sl_DI_AM: DI_AM.value,
                sl_CR_AM: CR_AM.value,
                sl_TA_FR: TA_FR.value,
                sl_TX_FR: TX_FR.value,
                sl_DI_FR: DI_FR.value,
                sl_OR_NO: OR_NO.value,
                sl_OR_TY: OR_TY,
                sl_OR_CO: OR_CO.value,
                sl_CO_NM: sl_CO_NM,
              };

              supplierLedgerList.push(Details);
          }
          requestBodyInput = {
            supplierLedgerList,
            sl_UK_NO: FormData.sl_UK_NO,
            slDoNo: FormData.slDoNo,
            sl_DO_TY: sl_DO_TY,
            sl_DO_CO: sl_DO_CO,
            sl_SU_NO: document.getElementById("sl_SU_NO").value,
            sl_PY_CO: sl_PY_CO,
            sl_IN_DT: IN_DT,
            sl_GL_DT: GL_DT,
            sl_DD_DT: DD_DT,
            sl_CR_CD: CR_CD,
            sl_CR_RT: document.getElementById("sl_CR_RT").value,
            sl_CR_CO: CR_CO.value,
            sl_SU_IN: SU_IN.value,
            sl_CO_NM: sl_CO_NM,
           
          };
  };
  const handleFormSubmit = () => {
    values();
    if (FormData.slDoNo) {
    if (FormData.sl_UK_NO) {
      const confirm = window.confirm("Do you want to Update ?");
      const update = `${domain}/api/SupplierLedger/Update`;
      if (confirm) {
        axios.put(update, requestBodyInput, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            handleResponse(resp.data);
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      }
    } else {
      axios.post(`${domain}/api/SupplierLedger/Add`, requestBodyInput, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
          },
        })
        .then((resp) => {
          handleResponse(resp.data);
        })
        .catch((error) => {
          console.error("Error adding data:", error);
        });
    }
      } else {
        const IN_DT = FormData.sl_IN_DT;
        if(IN_DT){
          const currentYear = IN_DT.toString();
          const firstHalf = currentYear.substring(0, 2);
          const secondHalf = currentYear.substring(2, 4);
          const urlNextNumber = `${domain}/api/DocumentNumber/All`;
          const parameters = `${sl_DO_TY}/${secondHalf}/${company}/${firstHalf}`;  
          const finalUrl1 = `${urlNextNumber}/${parameters}`;

          axios.get(finalUrl1, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
            },
          })
          .then((resp) => {
            setslDoNo(resp.data.dn_DO_NO);
            FormData.slDoNo = resp.data.dn_DO_NO;
              if(resp.data.dn_DO_NO ===undefined)
              {
                handleResponse(resp.data);
              }else{
                values();
                axios.post(`${domain}/api/SupplierLedger/Add`, requestBodyInput, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "x-company": `${company}`, "x-flag":  localStorage.getItem("userType"),
                  },
                })
                .then((resp) => {
                  handleResponse(resp.data);
                })
                .catch((error) => {
                  console.error("Error adding data:", error);
                });
              }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        }
      }
  };
  const leftToolbarTemplate = ( 
    <React.Fragment>
       <Tooltip target=".update-button" position="bottom" mouseTrack >
       <div style={{ background: "red !important", color: "white", fontSize: "10px"}} >
        Update
      </div>  
      </Tooltip>
        <Button   
        style={buttonStyles}
        icon="pi pi-check"
        outlined
        className="mr-2 update-button"
        onClick={handleUpdate}
        disabled={!isRecordSelected}
      ></Button>
    
      <Tooltip target=".add-button" position="bottom" mouseTrack >
        <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Add
        </div>
    </Tooltip>
      <Button 
        style={addButtonStyles}
        icon="pi pi-plus"     
        outlined
        className="mr-2 add-button"
        onClick={handleAddButtonClick}
      ></Button>
       <Tooltip target=".copy-button" position="bottom" mouseTrack >
       <div style={{ background: "white !important", color: "white", fontSize: "10px" }}>
        Copy
        </div>
    </Tooltip>
      <Button 
        style={copyButtonStyles}
        icon="pi pi-copy"
        outlined
        className="mr-2 copy-button"
        onClick={handleCopyData}
      ></Button>
    </React.Fragment>
  );
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tableData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "products_export.xlsx");
    });
  };
  const rightToolbarTemplate = (
    <React.Fragment>
      <Button
        style={{ height: "35px" }}
        icon="pi pi-upload"
        className="p-button-help"
        rounded
        onClick={exportExcel}
        data-pr-tooltip="XLS"
      />
    </React.Fragment>
  );

  return (
    <div className="tableData" style={{ marginTop: "6%" }}>
      <Toast ref={toast} />
      <div className="card">
        <Toolbar
          className="p-mb-4"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <div style={{ overflowX: "auto", maxWidth: "100%" }}>
          <table
            style={{
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <td>
                  <input
                    style={checkboxStyle}
                    readOnly
                    onMouseDown={(e) => e.preventDefault()}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["slDoNo"] ? filters["slDoNo"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "slDoNo",
                        e.target.value,
                        filters["slDoNo"]
                          ? filters["slDoNo"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_TY"] ? filters["sl_DO_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_TY",
                        e.target.value,
                        filters["sl_DO_TY"]
                          ? filters["sl_DO_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_CO"] ? filters["sl_DO_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_CO",
                        e.target.value,
                        filters["sl_DO_CO"]
                          ? filters["sl_DO_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DO_PI"] ? filters["sl_DO_PI"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DO_PI",
                        e.target.value,
                        filters["sl_DO_PI"]
                          ? filters["sl_DO_PI"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>              
                <td>
                  <input
                    type="text"
                    value={filters["sl_SU_NO"] ? filters["sl_SU_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_SU_NO",
                        e.target.value,
                        filters["sl_SU_NO"]
                          ? filters["sl_SU_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input disabled
                    type="text"
                    value={filters["name"] ? filters["name"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "name",
                        e.target.value,
                        filters["name"]
                          ? filters["name"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GL_DT"] ? filters["sl_GL_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GL_DT",
                        e.target.value,
                        filters["sl_GL_DT"]
                          ? filters["sl_GL_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_IN_DT"] ? filters["sl_IN_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_IN_DT",
                        e.target.value,
                        filters["sl_IN_DT"]
                          ? filters["sl_IN_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_DD_DT"] ? filters["sl_DD_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_DD_DT",
                        e.target.value,
                        filters["sl_DD_DT"]
                          ? filters["sl_DD_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PY_CO"] ? filters["sl_PY_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PY_CO",
                        e.target.value,
                        filters["sl_PY_CO"]
                          ? filters["sl_PY_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GL_PO"] ? filters["sl_GL_PO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GL_PO",
                        e.target.value,
                        filters["sl_GL_PO"]
                          ? filters["sl_GL_PO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PA_ST"] ? filters["sl_PA_ST"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PA_ST",
                        e.target.value,
                        filters["sl_PA_ST"]
                          ? filters["sl_PA_ST"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GR_AM"] ? filters["sl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GR_AM",
                        e.target.value,
                        filters["sl_GR_AM"]
                          ? filters["sl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OP_AM"] ? filters["sl_OP_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OP_AM",
                        e.target.value,
                        filters["sl_OP_AM"]
                          ? filters["sl_OP_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TA_AM"] ? filters["sl_TA_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TA_AM",
                        e.target.value,
                        filters["sl_TA_AM"]
                          ? filters["sl_TA_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TX_AM"] ? filters["sl_TX_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TX_AM",
                        e.target.value,
                        filters["sl_TX_AM"]
                          ? filters["sl_TX_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_PD_AM"] ? filters["sl_PD_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_PD_AM",
                        e.target.value,
                        filters["sl_PD_AM"]
                          ? filters["sl_PD_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_ST_DT"] ? filters["sl_ST_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_ST_DT",
                        e.target.value,
                        filters["sl_ST_DT"]
                          ? filters["sl_ST_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CR_CD"] ? filters["sl_CR_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CR_CD",
                        e.target.value,
                        filters["sl_CR_CD"]
                          ? filters["sl_CR_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CR_RT"] ? filters["sl_CR_RT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CR_RT",
                        e.target.value,
                        filters["sl_CR_RT"]
                          ? filters["sl_CR_RT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_GR_AM"] ? filters["sl_GR_AM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_GR_AM",
                        e.target.value,
                        filters["sl_GR_AM"]
                          ? filters["sl_GR_AM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OP_FR"] ? filters["sl_OP_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OP_FR",
                        e.target.value,
                        filters["sl_OP_FR"]
                          ? filters["sl_OP_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TA_FR"] ? filters["sl_TA_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TA_FR",
                        e.target.value,
                        filters["sl_TA_FR"]
                          ? filters["sl_TA_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_TX_FR"] ? filters["sl_TX_FR"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_TX_FR",
                        e.target.value,
                        filters["sl_TX_FR"]
                          ? filters["sl_TX_FR"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_NO"] ? filters["sl_OR_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_NO",
                        e.target.value,
                        filters["sl_OR_NO"]
                          ? filters["sl_OR_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_TY"] ? filters["sl_OR_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_TY",
                        e.target.value,
                        filters["sl_OR_TY"]
                          ? filters["sl_OR_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                
                <td>
                  <input
                    type="text"
                    value={filters["sl_OR_CO"] ? filters["sl_OR_CO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_OR_CO",
                        e.target.value,
                        filters["sl_OR_CO"]
                          ? filters["sl_OR_CO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_SU_IN"] ? filters["sl_SU_IN"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_SU_IN",
                        e.target.value,
                        filters["sl_SU_IN"]
                          ? filters["sl_SU_IN"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle1}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_CO_NM"] ? filters["sl_CO_NM"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_CO_NM",
                        e.target.value,
                        filters["sl_CO_NM"]
                          ? filters["sl_CO_NM"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_NO"] ? filters["sl_BA_NO"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_NO",
                        e.target.value,
                        filters["sl_BA_NO"]
                          ? filters["sl_BA_NO"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_TY"] ? filters["sl_BA_TY"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_TY",
                        e.target.value,
                        filters["sl_BA_TY"]
                          ? filters["sl_BA_TY"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_BA_DT"] ? filters["sl_BA_DT"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_BA_DT",
                        e.target.value,
                        filters["sl_BA_DT"]
                          ? filters["sl_BA_DT"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={filters["sl_ST_CD"] ? filters["sl_ST_CD"].value : ""}
                    onChange={(e) =>
                      handleFilterChange(
                        "sl_ST_CD",
                        e.target.value,
                        filters["sl_ST_CD"]
                          ? filters["sl_ST_CD"].operation
                          : "equals"
                      )
                    }
                    style={inputStyle}
                  />
                </td>
              </tr>
              <tr>
                <th style={tableHeaderStyle}></th>
                <th style={tableHeaderStyle}>Doc. No</th>
                <th style={tableHeaderStyle}>Doc. Type</th>
                <th style={tableHeaderStyle}>Doc. Company</th>
                <th style={tableHeaderStyle}>Line No</th>
                <th style={tableHeaderStyle}>Supplier</th>
                <th style={tableHeaderStyle}>Name</th>
                <th style={tableHeaderStyle}>G/L Date</th>
                <th style={tableHeaderStyle}>Invoice Date</th>
                <th style={tableHeaderStyle}>Due Date</th>
                <th style={tableHeaderStyle}>Pay Term</th>
                <th style={tableHeaderStyle}>Post Code</th>
                <th style={tableHeaderStyle}>Pay Status</th>
                <th style={tableHeaderStyle}>Gross Amount</th>
                <th style={tableHeaderStyle}>Open Amount</th>
                <th style={tableHeaderStyle}>Taxable Amount</th>
                <th style={tableHeaderStyle}>Tax Amount</th>
                <th style={tableHeaderStyle}>PDC Amount</th>
                <th style={tableHeaderStyle}>Maturity Date</th>
                <th style={tableHeaderStyle}>Currency </th>
                <th style={tableHeaderStyle}>Exchange Rate</th>
                <th style={tableHeaderStyle}>Foreign Gross Amount</th>
                <th style={tableHeaderStyle}>Foreign Open Amount</th>
                <th style={tableHeaderStyle}>Foreign Taxable Amount</th>
                <th style={tableHeaderStyle}>Foreign Amount</th>
                <th style={tableHeaderStyle}>Rel. Number</th>
                <th style={tableHeaderStyle}>Rel. Type</th>
                <th style={tableHeaderStyle}>Rel. Company</th>
                <th style={tableHeaderStyle}>Supplier PO</th>
                <th style={tableHeaderStyle}>Cost Center</th>
                <th style={tableHeaderStyle}>Batch Number</th>
                <th style={tableHeaderStyle}>Batch Type</th>
                <th style={tableHeaderStyle}>Batch Date</th>
                <th style={tableHeaderStyle}>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData &&
                tableData.map((rowData, index) => (
                  <tr
                    key={rowData.sl_UK_NO}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "aliceblue" : "#ffffff",
                    }}
                    className="table-row"
                  >
                    <td style={tableCellStyle}>
                      <input
                        style={{ width: "10% !important" }}
                        type="checkbox"
                        value={rowData.sl_UK_NO}
                        checked={selectedRows.includes(rowData.sl_UK_NO)}
                        onChange={() => handleRowSelect(rowData.sl_UK_NO)}
                      />
                    </td>
                    <td style={tableCellStyle}>{rowData.slDoNo}</td>
                    <td style={tableCellStyle}>{rowData.sl_DO_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_DO_CO}</td>
                    <td style={tableCellStyle}>{rowData.slDoPi}</td>
                    <td style={tableCellStyle}>{rowData.sl_SU_NO}</td>
                    <td style={tableCellStyle}>{rowData.name}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_GL_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sl_IN_DT)}</td> 
                    <td style={tableCellStyle}>{formatDate(rowData.sl_DD_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sl_PY_CO}</td>
                    <td style={tableCellStyle}>{rowData.sl_GL_PO}</td>
                    <td style={tableCellStyle}>{rowData.sl_PA_ST}</td>
                    <td style={rightalined}>{rowData.sl_GR_AM}</td>
                    <td style={rightalined}>{rowData.sl_OP_AM}</td>
                    <td style={rightalined}>{rowData.sl_TA_AM}</td>
                    <td style={rightalined}>{rowData.sl_TX_AM}</td>
                    <td style={rightalined}>{rowData.sl_PD_AM}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_ST_DT)}</td> 
                    <td style={tableCellStyle}>{rowData.sl_CR_CD}</td>
                    <td style={tableCellStyle}>{rowData.sl_CR_RT}</td>
                    <td style={rightalined}>{rowData.sl_GR_AM}</td>
                    <td style={rightalined}>{rowData.sl_OP_FR}</td>
                    <td style={rightalined}>{rowData.sl_TA_FR}</td>
                    <td style={rightalined}>{rowData.sl_TX_FR}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_NO}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_OR_CO}</td>
                    <td style={tableCellStyle}>{rowData.sl_SU_IN}</td>
                    <td style={tableCellStyle}>{rowData.sl_CO_NM}</td>
                    <td style={tableCellStyle}>{rowData.sl_BA_TY}</td>
                    <td style={tableCellStyle}>{rowData.sl_BA_NO}</td>
                    <td style={tableCellStyle}>{formatDate(rowData.sl_BA_DT)}</td>
                    <td style={tableCellStyle}>{rowData.sl_ST_CD}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

      {/* pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      </div>
      <SupplierLedgerDialog
        open={open}
        handleClose={handleClose}
        data={FormData}
        handleFormSubmit={handleFormSubmit}
        onchangedata={onchangedata}
        onchangeFormData={onchangeFormData}    
        onchangeDropDownFormData1={onchangeDropDownFormData1}
        onchangeDropDownFormData2={onchangeDropDownFormData2}       
        onchangeDropDownFormData4={onchangeDropDownFormData4}
        onchangeDropDownFormData5={onchangeDropDownFormData5}
        onchangeDropDownFormData6={onchangeDropDownFormData6}
        sl_CO_NM={sl_CO_NM}
        sl_OR_TY={sl_OR_TY}
        sl_CR_CD={sl_CR_CD}
        sl_PY_CO={sl_PY_CO}
        sl_DO_TY={sl_DO_TY}
        sl_DO_CO={sl_DO_CO}
        sl_SU_NO={sl_SU_NO}
        setsl_SU_NO={setsl_SU_NO}
        sl_GL_DT={sl_GL_DT}
        sl_DD_DT={sl_DD_DT}
        sl_TX_CD={sl_TX_CD}
        sl_CR_CO={sl_CR_CO}
        setsl_PY_CO={setsl_PY_CO}
        SelectedemEMNO={SelectedemEMNO}
        setSelectedemEMNO={setSelectedemEMNO}
        SelectedemEMNO1={SelectedemEMNO1}
        setSelectedemEMNO1={setSelectedemEMNO1}
        SupplierLedgertable={SupplierLedgertable} 
        addRow={addRow} 
        deleteRow={deleteRow} 
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
        setFormData={setFormData}
        setsl_CO_NM={setsl_CO_NM}
        isGLSaved={isGLSaved}
        sl_ST_CD={sl_ST_CD}
      />
    </div>
  );
};

export default SupplierLedgerMain;

